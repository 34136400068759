class OptometristsDetailsSurveyHistoryHelperClass {
    constructor() {

    }

    getByKey(object, key) {
        if(typeof object === 'undefined') {
            return ''
        }

        return object[object.findIndex(o => {
            return o.key === key
        })].value
    }
}

export const OptometristsDetailsSurveyHistoryHelper = new OptometristsDetailsSurveyHistoryHelperClass()