<template lang="pug">
    div(style="position:relative")
        .row
            .col-xs-12
                table.table.table-striped.middle
                    thead
                        tr
                            th Id
                            th {{'settings.offer.translations.attributes.table.NAME'|translate}}
                            th {{'settings.offer.translations.attributes.table.TRANSLATION'|translate}}
                            th
                            th

                    tbody
                        tr(v-for="(attribute, key) in attributes" :key="attribute.id"
                            :class="{'bg-separator': key % 2}")
                            td
                                a.link(:class="{'text-animated-alert': !attribute.active || !attribute.description}"
                                    @click="dialog = attribute") {{attribute.id}}
                            td
                                a.link(:class="{'text-animated-alert': !attribute.active || !attribute.description}"
                                    @click="dialog = attribute") {{attribute.name}}
                            td(style="width:300px")
                                input.form-control.text-center(v-model.lazy="attribute.description")
                            td.text-center(style="width:70px")
                                OptiAutoSave(endpoint="settings/offer/translations/attributes/save" fields="description"
                                    :model="attribute" :key="attribute.id" @saved="saved")
                            td.text-center(style="width:50px")
                                label.btn.btn-danger(v-show="!attribute.active" :for="'delete_attribute_' + attribute.id")
                                    i.fa.fa-trash

                                OptiButton.hide(:id="'delete_attribute_' + attribute.id"
                                    text="settings.offer.translations.attributes.delete" @confirmed="del(attribute.id)")
                                    template(#icon)
                                        i.fa.fa-trash

                        tr(v-show="!attributes.length")
                            td.text-muted.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

            OptiProductsListDialog(v-if="dialog"
                endpoint="settings/offer/translations/attributes/get-products"
                :params="dialog"

                @close="dialog = null")
                template(#header)
                    i.fa.mr3(:class="{'fa-venus-mars': !type, 'fa-crop': type}")
                    span.mr30 {{'settings.offer.translations.card.' + (type ? 'SHAPES' : 'GENDER')|translate}}

                    span.mr30 Id - {{dialog.id}}
                    span.mr30 {{'settings.offer.translations.attributes.table.NAME'|translate}} - {{dialog.name}}
                    span
                        span.mr3 {{'settings.offer.translations.attributes.table.TRANSLATION'|translate}} -
                        span(v-if="dialog.description") {{dialog.description}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsOfferTranslationsDetailsGenderShape',
        components: {
            OptiAutoSave,
            OptiButton,
            OptiProductsListDialog,
            OptiDimmer
        },
        props: {
            type: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                attributes: [],

                dialog: null,
                loading: true
            }
        },
        mounted() {
            API.get('settings/offer/translations/attributes/' + this.type, API.id(Math.random())).then(res => {
                this.attributes = res.data

                this.countAttributes()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.offer.translations.attributes.notify.error.LOAD')
            })
        },
        methods: {
            saved() {
                this.countAttributes()

                this.$emit('attribute-was-updated')
            },
            del(id) {
                this.loading = true

                API.delete('settings/offer/translations/attributes/delete/' + id).then(res => {
                    this.attributes = res.data

                    this.$notify.success('settings.offer.translations.attributes.notify.DELETE')
                }).finally(() => {
                    this.countAttributes()

                    this.$emit('attribute-was-updated')

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.offer.translations.attributes.notify.error.DELETE')
                })
            },
            countAttributes() {
                let filled = this.attributes.filter(c => c.description).length,
                    inactive = this.attributes.filter(c => !c.active).length,
                    not_filled = this.attributes.length - filled

                this.$emit('update-count', inactive + not_filled)
            }
        }
    }
</script>