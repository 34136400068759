<template lang="pug">
    .schedule
        table.table.table-striped.middle.text-center.table-schedule.table-schedule-week
            thead
                tr
                    th(v-once) {{'schedule.directive.view.header.DAY'|translate}}
                    th.day-cell.btn.btn-default(v-for="day in weekdays" @click="updateDay(day)")
                        div {{ day.label.long }}
                        .warn(style='font-size:.8em' v-if='day.holiday') {{ day.holiday | translate }}
                        div(style='color: navy; font-size: 0.8em' v-if='day.special') {{ day.special }}
                    th(rowspan="4" v-once)
                        div {{'schedule.summary.WORKINGTIME'|translate}}
                        small tydzień/narastająco
                    //th(rowspan="4" v-once)
                        div {{'schedule.summary.WORKINGTIME'|translate}}
                        small ({{'schedule.summary.BYCUMULATION'|translate}})
                    th(rowspan="4")
                        div {{'schedule.summary.WORKINGTIME'|translate}}
                        small ({{'schedule.summary.BYQUARTER'|translate}})
                tr(v-if="isManager")
                    th(v-once) {{'schedule.directive.view.header.ACTIVITY'|translate}}
                    th(v-for="day in weekdays") {{ day.activity }}
                tr(v-if="isPast")
                    th(v-once) {{'schedule.directive.view.header.MOVEMENTS'|translate}}
                    th(v-for="day in weekdays") {{ day.movements }}
                tr
                    th(v-once) {{'schedule.directive.view.header.MOVEMENTS_LAST_YEAR'|translate}}
                    th(v-for="day in weekdays") {{ day.movementsLastYear }}
            //tbody
                OptiScheduleWeekRow(v-for="user in users" :user="user")
            tbody
                template(v-for="user in events")
                    tr
                        td.schedule-td-opacity(style="padding:0"
                            :class="['schedule-td-color-' + user.color, {'schedule-td-opacity': true, 'schedule-no-active': !user.active}]")
                            a(style="display:flex;align-items:center;justify-content:space-between;padding:8px"
                                :href="'#/app/user/' + user.id + '/edit'" target="_blank")
                                OptiReportOffersRate(:user-id="user.id")

                                div
                                    div {{user.name.split(' ')[0]}}
                                    div {{user.name.split(' ')[1]}}
                                    small(style="display:block" v-show="user.exchange") {{'schedule.EXCHANGE'|translate}} {{user.exchange}}
                                    small(style="display:block") {{user.department}}

                                div
                                    div(style="font-style:italic") ({{user.turn_over}})
                                    .fa.fa-sun-o(v-if="user.sun")
                        td.event-empty.event-week(
                            v-for="(user_events, index) in user.data"
                            @click="editCell(user, user_events, weekdays[index].label.day)"
                            :class="{ 'current-cell': isCurrentCell(user, user_events) }")
                            div(v-if="isCurrentCell(user, user_events)")
                                .current-cell-backdrop
                                .current-cell-content
                                    OptiScheduleEditor(:events="user_events" :workingHours="workingHours")
                            OptiScheduleEditor(:events="user_events" :workingHours="workingHours")
                        td.bg-separator.bold
                            span {{user.hours}}
                            span &nbsp;/&nbsp;
                            span {{user.week_hours}}
                        td.bg-separator.bold
                            div
                                span(:class="{'text-success': user.quarter_hours.work + user.quarter_hours.leave + user.quarter_hours.l4 + user.quarter_hours.care > user.quarter_working_hours, 'warn': user.quarter_hours.work + user.quarter_hours.leave + user.quarter_hours.l4 + user.quarter_hours.care < user.quarter_working_hours}") {{user.quarter_hours.work + user.quarter_hours.leave + user.quarter_hours.l4 + user.quarter_hours.care}}
                                span &nbsp;/&nbsp;
                                span {{user.quarter_working_hours}}
                            div
                                small(style="color:deeppink" v-show="user.quarter_hours.leave")
                                    b U&nbsp;
                                    span {{user.quarter_hours.leave}}

                                span(v-show="user.quarter_hours.leave && user.quarter_hours.l4") &nbsp;/&nbsp;

                                small(style="color:red" v-show="user.quarter_hours.l4")
                                    b L4&nbsp;
                                    span {{user.quarter_hours.l4}}

                                span(v-show="(user.quarter_hours.leave || user.quarter_hours.l4) && user.quarter_hours.care") &nbsp;/&nbsp;

                                small(style="color:#ff0381" v-show="user.quarter_hours.care")
                                    b O&nbsp;
                                    span {{user.quarter_hours.care}}

                    tr(v-if="currentUser === user")
                        td.editor-cell(colspan="10")
                            .editor
                                .panel.panel-default
                                    .panel-body
                                        OptiScheduleDayPainter(
                                            :user="currentUser"
                                            :events="currentCell"
                                            :workingHours="workingHours"
                                            :departmentId="departmentId"
                                            :date="currentDate"

                                            @cancelled="cancelEdit"
                                            @saved="saveEdit")

        .legend
            table
                tbody
                    tr
                        td.legend-block.schedule-color-0
                        td Praca niehandlowa
                    tr
                        td.legend-block.schedule-color-1
                        td Praca handlowa
                    tr
                        td.legend-block.schedule-color-2
                        td L4
                    tr
                        td.legend-block.schedule-color-3
                        td Urlop
                    tr
                        td.legend-block.schedule-color-4
                        td Urlop na żądanie
                    tr
                        td.legend-block.schedule-color-13
                        td Opieka
                    tr
                        td.legend-block.schedule-color-5
                        td Szkolenie
                    tr
                        td.legend-block.schedule-color-10
                        td Wymiana
                    tr
                        td.legend-block(style='border: 3px solid red')
                        td(colspan='3') Posiada dane poza godzinami otwarcia salonu
</template>

<script>
    import OptiScheduleEditor from '@/js/app/vue/components/Schedule/Week/OptiScheduleEditor'
    import moment from 'moment'
    import TextCumulative from '@/js/app/vue/components/Text/TextCumulative'
    import OptiScheduleWeekRow from '@/js/app/vue/components/Schedule/Week/OptiScheduleWeekRow'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiScheduleDayPainter from '@/js/app/vue/components/Schedule/Week/OptiScheduleDayPainter'
    import Vue from 'vue'
    import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'

    export default {
        name: 'OptiScheduleWeek',
        components: {
            OptiReportOffersRate,
            OptiScheduleDayPainter, OptiButton, OptiScheduleWeekRow, TextCumulative, OptiScheduleEditor},
        props: {
            days: {
                type: undefined,
                required: true
            },
            activity: {
                type: undefined,
                required: true
            },
            movements: {
                type: undefined,
                required: true
            },
            movementsLastYear: {
                type: undefined,
                required: true
            },
            events: {
                type: undefined,
                required: true
            },
            workingHours: {
                type: undefined,
                required: true
            },
            quarterWorkingHours: {
                type: undefined,
                required: true
            },
            view: {
                type: undefined,
                required: true
            },
            departmentId: {
                type: undefined,
                required: true
            }
        },
        data() {
            return {
                currentUser: false,
                currentCell: false,
                currentDate: false
            }
        },
        mounted() {
            window.wtf = this
        },
        computed: {
            users() {
                return this.events.map(user => {
                    user.workingHours          = this.workingHours
                    user.quarter_working_hours = this.quarterWorkingHours

                    return user
                })
            },
            isManager() {
                return this.$uac.hasRole('schedule.manager')
            },
            isPast() {
                if (!this.view || !this.view.start) return false

                return this.view.start.diff(moment(), 'days') < 0
            },
            weekdays() {
                if (!this.days) return []
                if (!this.activity || !this.movements || !this.movementsLastYear || !this.events || !this.workingHours) return []

                return this.days.map(item => {
                    let day = item.label.day

                    item.activity          = this.activity[day] || '-'
                    item.movements         = this.movements[day] || '-'
                    item.movementsLastYear = this.movementsLastYear[day] || '-'

                    return item
                })
            }
        },
        methods: {
            editCell(user, cell, day) {
                if (this.isCurrentCell(user, cell)) {
                    this.cancelEdit()
                    return
                }

                this.cancelEdit()
                Vue.nextTick(() => {
                    this.currentUser = user
                    this.currentCell = cell
                    this.currentDate = day
                })
            },
            cancelEdit() {
                this.currentUser = false
                this.currentCell = false
                this.currentDate = false
            },
            saveEdit(events) {
                this.currentCell.length = 0
                _.forEach(events, event => {
                    this.currentCell.push(event)
                })
            },
            isCurrentCell(user, cell) {
                return this.currentUser === user && this.currentCell === cell
            },
            updateDay(day) {
                this.view.type = 'day'
                this.view.start = moment(day.label.day)

                this.$emit('render-navigation')
            }
        }
    }
</script>