<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-default
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.forecast.range.HEADING'|translate}}

                .panel-body(style="position:relative")
                    .row
                        .col-xs-12
                            OptiSelectQuarterOrSnapshot(
                                :quarter="quarter"
                                :week="week"

                                @select-weeks-was-loaded="loading.select = false")

                    OptiDimmer(:active="is_loading")

                .panel-footer.text-center
                    OptiButton(type="success" :disabled="is_loading" @click.native="getChartsData"
                        ) {{'report.discount.CALCULATE'|translate}}
                        template(#icon)
                            i.fa.fa-pie-chart

        .col-xs-12(v-if="show_data")
            i.fa.fa-calendar.mr3
            small.text-muted {{label}}

            a.btn.btn-default.pull-right(:href="'https://wiki.eopti.pl/oprogramowanie:system_eopti:discount_chart'"
                target="_blank") {{'report.discount.WIKI'|translate}}

        .col-xs-12(v-if="show_data")
            OptiCharDiscountsPie(:height="300" :chart-data="centralData")

        .col-xs-12(v-if="show_data")
            .departments
                OptiCharDiscountsPie(
                    v-for="chartData in charts"
                    :key="chartData.departmentId"
                    :chart-data="chartData"
                    :class="{border: chartData.departmentId === $uac.user.activeDepartmentId}")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectQuarterOrSnapshot from '@/js/app/vue/components/Select/OptiSelectQuarterOrSnapshot'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCharDiscountsPie from '@/js/app/vue/components/Chart/OptiCharDiscountsPie'

    export default {
        name: 'OptiChartDiscounts',
        components: {
            OptiSelectQuarterOrSnapshot,
            OptiDimmer,
            OptiButton,
            OptiCharDiscountsPie
        },
        props: {
            data: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                week: {
                    is_current: false,

                    from: {},
                    to: {}
                },
                quarter: {
                    from: {
                        q: null,
                        y: null
                    },
                    to: {
                        q: null,
                        y: null
                    }
                },

                label: null,
                centralData: false,
                border: false,

                loading: {
                    main: false,
                    select: true
                }
            }
        },
        methods: {
            getChartsData() {
                this.loading.main = true
                this.label = null

                API.post('reports/snapshots/chartDiscount', {
                    weekFrom: this.week.from.id,
                    weekTo: this.week.to.id
                }).then(res => {
                    this.centralData = this.getItem(res.data.central)
                    this.charts = res.data.departments.map(item => this.getItem(item))

                    this.label = this.week.from.start + ' - ' + this.week.to.end
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('report.discount.notify.error.LOAD')
                })
            },
            getItem(item) {
                let obj = {
                    labels: [],
                    datasets: [],
                    departmentId: item.departmentId,
                    departmentLogin: item.department,
                }

                obj.labels.push('Cena sprzedaży', 'Rabat')
                _.each(item.sum, (v, k) => {

                    let weight = 2
                    if(k === 'other') {
                        weight = 1
                    } else if(k === 'summary') {
                        weight = 4
                    }

                    obj.datasets.push({
                        data: [
                            k === 'summary' ? v.realPercent : v.percent,
                            k === 'summary' ? 100-v.realPercent : 100-v.percent
                        ],
                        borderWidth: k === 'summary' ? 0 : 0.2,
                        weight: weight,
                        sale: v.sale,
                        discount: v.discount,
                        wholesale: v.wholesale,
                        discountActions: v.discountActions,
                        catalog: v.catalog,
                        percent: v.percent,
                        realPercent: v.realPercent,
                        tasksAll: v.tasksAll,
                        tasksSC: v.tasksSC,
                        SCPercent: v.SCPercent,
                        SCMoney: v.SCMoney,
                        SCMoneyPrc: v.SCMoneyPrc,
                        backgroundColor: [
                            k === 'summary' ? 'rgba(0,0,0,0.7)' : 'rgba(150,0,0, 0.7)',
                            k === 'summary' ? 'rgba(0,0,0,0)' : 'rgba(0, 100, 0, 0.7)'
                        ]
                    })
                })
                return obj
            },
        },
        computed: {
            is_loading() {
                return this.loading.main || this.loading.select
            },
            show_data() {
                return this.week.from.id && this.week.to.id && this.centralData
            }
        }
    }
</script>

<style scoped lang="less">
    .departments {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        justify-items: center;
        align-items: center;

        & .border {
            border: double red;
        }

        & .pie {
            width: 200px;
        }
    }
</style>