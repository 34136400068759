<template lang="pug">
    .row
        .col-sm-4(v-show="!loading")
            select.form-control(v-model="supplier_id")
                option(v-for="supplier in suppliers" :value="supplier.id") {{supplier.name}}
        .col-sm-8.text-right(v-show="!loading")
            OptiButton.mr3(type="info" :disabled="!selected.length" text="supply.assign.confirm" @confirmed="goAssign"
                ) {{'supply.assign.confirm.BTN'|translate}}
                template(#icon)
                    i.fa.fa-truck

            OptiButton(type="danger" :disabled="!selected.length" text="supply.cancel.confirm" @confirmed="goCancel"
                ) {{'supply.cancel.confirm.BTN'|translate}}
                template(#icon)
                    i.fa.fa-remove

        .col-xs-12(style="margin-top:10px" v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'supply.assign.HEADING'|translate}}

                    .pull-right(v-show="supplies.length")
                        .label.label-danger.mr3(v-show="selected.length") {{selected.length}}
                        .label.label-inverse {{supplies.length}}

                .table-responsive
                    table.table.table-condensed.middle
                        thead
                            tr
                                th
                                    .checkbox.c-checkbox.checkbox-primary.inline(v-show="supplies.length")
                                        label
                                            input(type="checkbox" v-model="all" @change="selectedAll")
                                            span.fa.fa-check
                                th {{'supply.table.PRODUCT'|translate}}
                                th {{'supply.table.TASK_ID'|translate}}
                                th {{'supply.table.EXTRA_DESC'|translate}}
                                th {{'supply.table.ORDERED'|translate}}
                                th {{'supply.table.COMMENTS'|translate}}

                        tbody
                            tr(v-for="supply in supplies")
                                td
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" v-model="supply.selected")
                                            span.fa.fa-check
                                td {{supply.name}}
                                td
                                    a.link(:href="$state.href('app.task.detail', {id: supply.taskId})" target="_blank"
                                        ) {{supply.taskId}}
                                td(style="white-space:pre") {{supply.extraDescription}}
                                td {{supply.date}}
                                td {{supply.comments}}
                                
                            tr(v-show="!supplies.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSupplyOrderByAdminAssign',
        components: {
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                suppliers: [],
                supplier_id: 12,

                supplies: [],

                all: false,
                loading: true
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.loading = true

                API.get('warehouse/order-by-admin/assign-list').then(res => {
                    this.setData(res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('supply.assign.notify.error.LOAD')
                })
            },
            setData(data) {
                this.suppliers = data.suppliers
                this.supplies = data.supplies
            },
            selectedAll() {
                this.supplies.forEach(supply => {
                    this.$set(supply, 'selected', this.all)
                })
            },
            goAssign() {
                this.loading = true

                API.post('warehouse/order-by-admin/go-assign', {
                    supplier_id: this.supplier_id,
                    ids: this.selected
                }).then(res => {
                    this.setData(res.data)

                    this.$notify.success('supply.assign.notify.ASSIGNED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('supply.assign.notify.error.ASSIGNED')
                })
            },
            goCancel() {
                this.loading = true

                API.post('warehouse/order-by-admin/go-cancel-unassigned', {
                    supplier_id: this.supplier_id,
                    ids: this.selected
                }).then(res => {
                    this.setData(res.data)

                    this.$notify.success('supply.cancel.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('supply.cancel.notify.error.CANCEL')
                })
            }
        },
        computed: {
            selected() {
                let _ids = []

                this.supplies.forEach(supply => {
                    if(supply.selected) {
                        _ids.push(supply.id)
                    }
                })

                this.all = this.supplies.length && this.supplies.length === _ids.length

                return _ids
            }
        }
    }
</script>