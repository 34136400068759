<template lang="pug">
    OptiDialog.prescription-task
        template(#header)
            i.fa.fa-briefcase.mr3
            span {{'prescription.OFFER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .dialog-contents
                .row(style="margin-bottom:15px")
                    .col-sm-6
                        .form.form-horizontal
                            .form-group
                                label.col-sm-5.control-label(for="offer" :class="{'star-required': offers.length}"
                                    ) {{'prescription.OFFER'|translate}}

                                .col-sm-7
                                    select#offer.form-control(:disabled="offers.length === 1"
                                        v-model="offer" @change="initTaskType")
                                        option(v-for="o in offers" :value="o") \#{{o.id}} {{o.created_at.split(' ')[0]}}
                                    label.warn(for="offer" v-show="!offer") {{'validation.formValidate.required'|translate}}

                    .col-sm-6.text-center
                        OptiButton(@click.native="dialog = true") {{'patient.offer.PREVIEW_SELECTED'|translate}}
                            template(#icon)
                                i.fa.fa-search

                        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                            OptiCatalogOfferPreviewDialog(
                                :id="offer.id"

                                @closed="dialog = false")

                .row
                    .col-sm-6(style="margin-bottom:15px" :class="{'col-md-4': offer.products.length < 4, 'col-md-3': offer.products.length === 4}"
                        v-for="product in offer.products" :key="product.id")
                        OptiProductInfo(
                            :product="product"
                            :department-id="offer.department_id"

                            :is-offer="true")

                        div
                            label.control-label.star-required(:for="'type_' + product.id") {{'tasks.TYPE'|translate}}
                            select.form-control(:id="'type_' + product.id" :name="'type_' + product.id"
                                :class="{'placeholder': !product.task_type}"
                                v-model="product.task_type" @change="setTaskType(product.id)")
                                option(v-for="(type, type_key) in types"
                                    :class="{'hide': !type_key}" :disabled="!type_key"
                                    :value="type_key") {{type|translate}}
                            label.warn(:for="'type_' + product.id" v-show="!product.task_type") {{'validation.formValidate.required'|translate}}

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButton.pull-left(type="danger" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove

            OptiButton(type="success" :disabled="loading || !!offer.products.filter(p => !p.task_type).length"
                text="patient.offer.temporary_task_create" @confirmed="generate"
                ) {{'patient.offer.temporary_task_create.BTN'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferPreviewDialog
        from '@/js/app/vue/components/Catalog/Offer/Preview/OptiCatalogOfferPreviewDialog'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsPrescriptionDialogOffer',
        components: {
            OptiDialog,
            OptiButton,
            OptiCatalogOfferPreviewDialog,
            OptiProductInfo,
            OptiDimmer
        },
        props: {
            prescription: {
                type: Object,
                required: true
            },
            offers: {
                type: Array,
                required: false,
                default: () => []
            },
            offerId: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                offer: null,

                types: [
                    'patient.offer.SELECT_TASK_TYPE',
                    'tasks.type.SVNEAR',
                    'tasks.type.SVFAR'
                ],

                dialog: false,
                loading: false
            }
        },
        beforeMount() {
            if(this.prescription.leAdd || this.prescription.reAdd) {
                this.types = this.types.concat(['tasks.type.BIFOCAL', 'tasks.type.PROGRESIVE'])
            }

            let filtered = this.offers.filter(o => o.id === this.offerId)

            if(filtered.length) {
                this.offer = filtered[0]

                this.initTaskType()
            }
        },
        methods: {
            generate() {
                this.loading = true

                API.post('prescription/offer', {
                    offer_id: this.offer.id,
                    prescription_id: this.prescription.id,
                    products: this.offer.products.map(function(p) {
                        return {
                            id: p.id,
                            task_type: p.task_type
                        }
                    })
                }).then(res => {
                    this.$state.go('app.catalog.offer-temporary-task', {
                        id: res.data[0]
                    })
                }).finally(() => {
                    this.loading = false

                    this.$emit('closed')
                }).catch(() => {
                    this.$notify.error('prescription.notify.ERRORTASK')
                })
            },
            setTaskType(product_id) {
                let task_type = this.offer.products.filter(p => p.id === product_id)[0].task_type

                this.offer.products.filter(p => !p.task_type).forEach(p => p.task_type = task_type)
            },
            initTaskType() {
                this.offer.products.forEach(p => this.$set(p, 'task_type', 0))
            }
        }
    }
</script>