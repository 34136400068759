<template lang="pug">
    .table-responsive
        table.table.table-striped.middle(style="margin-bottom:0")
            tbody
                tr(v-show="heading && id > -1")
                    td(colspan="5")
                        i.fa.fa-navicon.mr3
                        span {{'optometrist.correction.STEREO'|translate}}
                tr(v-show="id > -1")
                    td
                        img(style="width:70px" src="img/optometrist/screening-test/stereo.svg")
                    td(colspan="2")
                        span(v-show="id > 0") {{id}}s
                    td {{'optometrist.result.CORRECTION'|translate}} - {{description_stereo()}}
                    td.text-right(v-show="icon")
                        i.fa.fa-2x.fa-remove.warn(v-show="!id")
                        span.fa.fa-2x.fa-check.text-success(v-show="id")
                tr(v-show="noResult && id === -1")
                    td.text-center.warn.bold(colspan="5") {{'optometrist.correction.NORESULT'|translate}}
</template>

<script>
    export default {
        name: 'OptiOptometristsDescriptionsStereo',
        components: {

        },
        props: {
            heading: {
                type: Boolean,
                required: false,
                default: false
            },
            icon: {
                type: Boolean,
                required: false,
                default: false
            },
            noResult: {
                type: Boolean,
                required: false,
                default: false
            },

            id: {
                type: Number,
                required: true
            }
        },
        mounted() {

        },
        methods: {
            description_stereo() {
                if(this.id) {
                    return 'Widzenie stereoskopowe'
                }

                return 'Brak widzenia stereoskopowego'
            }
        }
    }
</script>