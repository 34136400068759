<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formSeason" @submit.prevent="edit")
                .form-group
                    label.col-sm-4.control-label {{'dictionary.seasons.table.DEPARTMENT'|translate}}
                    .col-sm-8
                        .form-control-static {{season.department.name}}
                .form-group
                    label.col-sm-4.control-label {{'dictionary.seasons.table.YEAR'|translate}}
                    .col-sm-8
                        .form-control-static {{season.year}}
                .form-group
                    label.col-sm-4.control-label {{'dictionary.seasons.table.QUARTER'|translate}}
                    .col-sm-8
                        .form-control-static {{season.quarter}}

                .form-group
                    label.col-sm-4.control-label.star-required(for="tasks") {{'dictionary.seasons.table.TASKS'|translate}}
                    .col-sm-8
                        OptiNumber#tasks(:header="'dictionary.seasons.table.TASKS'|translate"
                            :value="season.tasks" @confirmed="t => season.tasks = t"
                            :float="true" :required="true") {{season.tasks|number(2)}}
                        OptiValidate(f="tasks" :data="$v.season.tasks")
                .form-group
                    label.col-sm-4.control-label.star-required(for="sales") {{'dictionary.seasons.table.SALES'|translate}}
                    .col-sm-8
                        OptiNumber#sales(:header="'dictionary.seasons.table.SALES'|translate"
                            :value="season.sales" @confirmed="s => season.sales = s"
                            :float="true" :required="true") {{season.sales|number(2)}}
                        OptiValidate(f="sales" :data="$v.season.sales")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.season.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionarySeasonsEdit',
        components: {
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                season: {
                    department: {},
                    year: 0,
                    quarter: 0,
                    tasks: 0,
                    sales: 0
                },

                loading: true
            }
        },
        validations: {
            season: {
                tasks: {
                    noZero
                },
                sales: {
                    noZero
                }
            }
        },
        mounted() {
            API.get('dictionary/season/' + this.$state.params.id).then(res => {
                this.season = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('dictionary.seasons.notify.error.LOAD')
            })
        },
        methods: {
            edit() {
                this.loading = true

                API.post('dictionary/season/edit', this.season).then(() => {
                    this.$state.go('app.dictionary.seasons.table')

                    this.$notify.success('dictionary.seasons.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dictionary.seasons.notify.error.EDIT')
                })
            }
        }
    }
</script>