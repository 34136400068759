<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-loyality-event.form-horizontal.form-validate(name="formEvent" @submit.prevent="editLoyalityEvent()")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'cards.event.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="LoyalityCardLogType" :value="form.typeId" @update-enum="updateType")
                        OptiValidate(f="type" :data="$v.form.typeId")
                .form-group
                    label.col-sm-4.control-label.star-required {{'default.REFERENCEID'|translate}}
                    .col-sm-8
                        OptiNumber(:header="'default.REFERENCEID'|translate" :value="form.refId"
                            @confirmed="updateRefId" :required="true") {{form.refId}}
                        OptiValidate(f="ref_id" :data="$v.form.refId")
                .form-group
                    label.col-sm-4.control-label.star-required(for="description") {{'cards.event.DESCRIPTION'|translate}}
                    .col-sm-8
                        textarea#description.form-control(v-model="form.description" name="description")
                        OptiValidate(f="description" :data="$v.form.description")
                .form-group
                    label.col-sm-4.control-label.star-required {{'cards.event.PRICE'|translate}}
                    .col-sm-8
                        OptiNumber(:header="'cards.event.PRICE'|translate" :value="form.price" :float="true"
                            @confirmed="updatePrice" :required="true") {{form.price|currency}}
                        OptiValidate(f="price" :data="$v.form.price")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="!(form.typeId !== null && form.refId && form.description && form.price)")
        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsLoyalityCardsEventEdit',
        components: {
            OptiSelectEnum,
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    typeId: null,
                    refId: 0,
                    description: '',
                    price: 0,
                    cardId: parseInt(this.$state.params.cardId)
                },

                loading: true
            }
        },
        validations: {
            form: {
                typeId: {
                    required
                },
                refId: {
                    noZero
                },
                description: {
                    required
                },
                price: {
                    noZero
                }
            }
        },
        mounted() {
            API.get('patient/loyalitycard/event/' + this.$state.params.eventId).then(res => {
                this.form = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('cards.notify.event.LOADEVENT')
            })
        },
        methods: {
            editLoyalityEvent() {
                this.loading = true

                API.post('loyality-card/event/edit', this.form).then(res => {
                    this.$notify.success('cards.notify.EDITEVENT')

                    this.$state.go('app.patient.detail', {
                        id: res.data.loyality.patient.id,
                        '#': 'loyality'
                    })
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('cards.notify.event.ERROREDITEVENT')
                })
            },

            updateType(type) {
                this.form.type = type
            },
            updateRefId(refId) {
                this.form.refId = refId
            },
            updatePrice(price) {
                this.form.price = price
            }
        }
    }
</script>
