<template lang="pug">
    .table-responsive
        table.table.table-striped.text-center.middle
            thead
                tr
                    th(colspan="9") {{'optometrist.correction.CORRECTION'|translate}}
            tbody
                tr
                    th {{'optometrist.acuity.FAR'|translate}}
                    td SPH
                    td(v-show="correctionCylExists") CYL
                    td(v-show="correctionAxisExists") AXS
                    td(v-show="correctionPrismExists") Prism
                    td(v-show="correctionBaseExists") Angle
                    td(v-show="correctionAddExists") Add
                    td Visus
                    td BinVisus
                tr
                    th {{'prescription.RIGHT'|translate}}
                    td {{interview.dist_r_sphere|plus}}
                    td(v-show="correctionCylExists") {{interview.dist_r_cylinder|plus}}
                    td(v-show="correctionAxisExists")
                        span(v-show="interview.dist_r_cylinder") {{interview.dist_r_axis}}
                    td(v-show="correctionPrismExists") {{interview.dist_r_prd|number(2)}}
                    td(v-show="correctionBaseExists")
                        span(v-show="interview.dist_r_prd") {{interview.dist_r_base}}
                    td(v-show="correctionAddExists") {{interview.dist_r_add|number(2)}}
                    td {{interview.dist_r_visus}}
                    td(rowspan="2") {{interview.dist_binvisus}}
                tr
                    th {{'prescription.LEFT'|translate}}
                    td {{interview.dist_l_sphere|plus}}
                    td(v-show="correctionCylExists") {{interview.dist_l_cylinder|plus}}
                    td(v-show="correctionAxisExists")
                        span(v-show="interview.dist_l_cylinder") {{interview.dist_l_axis}}
                    td(v-show="correctionPrismExists") {{interview.dist_l_prd|number(2)}}
                    td(v-show="correctionBaseExists")
                        span(v-show="interview.dist_l_prd") {{interview.dist_l_base}}
                    td(v-show="correctionAddExists") {{interview.dist_l_add|number(2)}}
                    td {{interview.dist_l_visus}}

                tr
                    th {{'optometrist.acuity.NEAR'|translate}}
                    td SPH
                    td(v-show="correctionCylExists") CYL
                    td(v-show="correctionAxisExists") AXS
                    td(v-show="correctionPrismExists") Prism
                    td(v-show="correctionBaseExists") Angle
                    td(v-show="correctionAddExists")
                    td Visus
                    td BinVisus
                tr
                    th {{'prescription.RIGHT'|translate}}
                    td {{interview.near_r_sphere|plus}}
                    td(v-show="correctionCylExists") {{interview.near_r_cylinder|plus}}
                    td(v-show="correctionAxisExists")
                        span(v-show="interview.near_r_cylinder") {{interview.near_r_axis}}
                    td(v-show="correctionPrismExists") {{interview.near_r_prd|number(2)}}
                    td(v-show="correctionBaseExists")
                        span(v-show="interview.near_r_prd") {{interview.near_r_base}}
                    td(v-show="correctionAddExists")
                    td {{interview.near_r_visus}}
                    td(rowspan="2") {{interview.near_binvisus}}
                tr
                    th {{'prescription.LEFT'|translate}}
                    td {{interview.near_l_sphere|plus}}
                    td(v-show="correctionCylExists") {{interview.near_l_cylinder|plus}}
                    td(v-show="correctionAxisExists")
                        span(v-show="interview.near_l_cylinder") {{interview.near_l_axis}}
                    td(v-show="correctionPrismExists") {{interview.near_l_prd|number(2)}}
                    td(v-show="correctionBaseExists")
                        span(v-show="interview.near_l_prd") {{interview.near_l_base}}
                    td(v-show="correctionAddExists")
                    td {{interview.near_l_visus}}

            tbody(v-show="!correctionAllExists")
                tr
                    td.text-center.warn(colspan="9")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'optometrist.notify.NOCORRECTION'|translate}}
</template>

<script>

    export default {
        name: 'OptiOptometristsDetailsSummaryCorrection',
        components: {
        },
        props: {
            interview: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            correctionCylDistExists() {
                return this.interview.dist_r_cylinder || this.interview.dist_l_cylinder
            },
            correctionCylNearExists() {
                return this.interview.near_r_cylinder || this.interview.near_l_cylinder
            },
            correctionCylExists() {
                return this.correctionCylDistExists || this.correctionCylNearExists
            },

            correctionAxisDistExists() {
                return this.interview.dist_r_axis !== 'brak' || this.interview.dist_l_axis !== 'brak'
            },
            correctionAxisNearExists() {
                return this.interview.near_r_axis !== 'brak' || this.interview.near_l_axis !== 'brak'
            },
            correctionAxisExists() {
                return this.correctionAxisDistExists || this.correctionAxisNearExists
            },

            correctionPrismDistExists() {
                return this.interview.dist_r_prd || this.interview.dist_l_prd
            },
            correctionPrismNearExists() {
                return this.interview.near_r_prd || this.interview.near_l_prd
            },
            correctionPrismExists() {
                return this.correctionPrismDistExists || this.correctionPrismNearExists
            },

            correctionBaseExists() {
                return parseInt(this.interview.dist_r_base) || parseInt(this.interview.dist_l_base) ||
                    parseInt(this.interview.near_r_base) || parseInt(this.interview.near_l_base)
            },
            correctionAddExists() {
                return this.interview.dist_r_add || this.interview.dist_l_add
            },
            correctionAllExists() {
                let sphExists = this.interview.dist_r_sphere || this.interview.dist_l_sphere ||
                    this.interview.near_r_sphere || this.interview.near_l_sphere

                return sphExists || this.correctionCylExists || this.correctionAxisExists ||
                    this.correctionPrismExists || this.correctionBaseExists || this.correctionAddExists
            }
        }
    }
</script>