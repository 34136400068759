<template lang="pug">
    .col-xs-12(v-show="allowEditConfirmed || task.comments || task.references.length")
        .table-responsive(style="overflow-x:visible")
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tr.separate-top
                    td(style="padding:5px" colspan="3")
                        .text-center
                            label(style="font-weight:normal" for="comments") {{'tasks.left.COMMENTS'|translate}}
                        textarea#comments.form-control(name="comments" rows="3" v-show="allowEditConfirmed"
                            v-model.lazy="task.comments" @change="$emit('summary-was-updated')")
                        div(v-show="task.comments && !allowEditConfirmed" v-html="$options.filters.nl2br(task.comments)")

                tr(v-show="!isOffer")
                    td.text-center(style="width:60%" colspan="2")
                        .form-group(v-if="!block")
                            .container-fluid
                                .row
                                    .col-xs-12.text-center
                                        label(style="margin-bottom:0" for="task-connect") {{'tasks.left.CONNECT'|translate}}
                                .row(style="padding-top:5px;padding-bottom:5px")
                                    .col-xs-4.text-center
                                        select.form-control#task-connect(style="margin-left:5px;height:43px" name="referenceTmpType"
                                            v-model="referenceTmp.type" @change="options = []")
                                            option(v-for="type in typesList" :value="type.id") {{type.name|translate}}
                                    .col-xs-8
                                        OptiTaskDetailsSummarySelectReferences(
                                            :task-id="task.id"
                                            :reference-tmp="referenceTmp"
                                            :options="options"

                                            @update-options="o => options = o"
                                            @selected="updateReference")

                    td(style="text-align:left")
                        table(style="margin:0 auto;margin-bottom:10px" v-show="task.references.length")
                            tr(v-for="reference in task.references" v-show="reference.id !== 0")
                                td(style="padding-right:8px")
                                    a.link(style="cursor:pointer" :href="goToReference(reference)"
                                        ) {{types.filter(t => reference.type_to === t.id)[0].name|translate}}
                                td(style="padding-right:8px;text-align:left")
                                    a.link(style="cursor:pointer" :href="goToReference(reference)") {{reference.to}}
                                td
                                    button.btn.btn-danger(style="margin-top:5px" v-show="!block"
                                        @click="removeReference(reference)")
                                        i.fa.fa-remove
</template>

<script>
    import {EnumReferenceType} from '@/js/app/vue/enums/Enums'

    import OptiTaskDetailsSummarySelectReferences
        from '@/js/app/vue/components/Tasks/Details/Summary/Select/OptiTaskDetailsSummarySelectReferences'

    export default {
        name: 'OptiTaskDetailsSummaryCommentsReferences',
        components: {
            OptiTaskDetailsSummarySelectReferences
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            }
        },
        data() {
            let _types = EnumReferenceType.simpleItems(),
                _typesList = _.cloneDeep(_types)

            _typesList.splice(1, 1)

            return {
                types: _types,
                typesList: _typesList,
                options: [],
                referenceTmp: {
                    type: 0,
                    result: null
                }
            }
        },
        methods: {
            goToReference(reference) {
                let link = ['', 'app.task.detail', 'app.tasks.reclamation.details',
                    'app.task.detail', 'app.sale.details', 'app.patient.detail']

                if(Object.keys(link).includes(reference.type_to.toString())) {
                    let params = {
                        id: reference.to
                    }

                    if(reference.type_to === 5) {
                        params['#'] = 'loyality'
                    }

                    return this.$state.href(link[reference.type_to], params)
                }

                return ''
            },
            updateReference(reference) {
                this.options = []

                this.referenceTmp.result = reference

                if(this.referenceTmp.result.id > 0) {
                    this.task.references.push({
                        id: 0,
                        to: this.referenceTmp.result.id,
                        type_from: 3,
                        type_to: this.referenceTmp.type
                    })

                    this.referenceTmp = {
                        type: 0,
                        result: null
                    }

                    this.$emit('summary-was-updated')
                }
            },
            removeReference(reference) {
                _.remove(this.task.references, {
                    id: reference.id
                })

                this.$emit('summary-was-updated')
            }
        }
    }
</script>