import { render, staticRenderFns } from "./OptiSaleSpecificationCreate.vue?vue&type=template&id=532ef7ca&scoped=true&lang=pug&"
import script from "./OptiSaleSpecificationCreate.vue?vue&type=script&lang=js&"
export * from "./OptiSaleSpecificationCreate.vue?vue&type=script&lang=js&"
import style0 from "./OptiSaleSpecificationCreate.vue?vue&type=style&index=0&id=532ef7ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532ef7ca",
  null
  
)

export default component.exports