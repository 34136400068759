import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class PermutationsServiceClass {
    constructor() {

    }

    load() {
        return new Promise((resolve, reject) => {
            let unique = Math.floor(Math.random() * window.__env.optometrist.shuffle.permutations),
                shuffle = [],
                selected = {}

            for(let i = 0; i < window.__env.optometrist.shuffle.permutations; ++i) {
                shuffle.push(i)
            }

            API.get('optometrist/interview/permutations').then(res => {
                let i = unique,
                    per = res.data

                selected = {
                    circle: per[0][i].circle,
                    hs: per[0][i].hs,
                    letter: per[0][i].letter,
                    number: per[0][i].number,
                    kids: per[0][i].kids,
                    mirror: per[1][i],
                    package: per,
                    active: i
                }

                resolve([unique, shuffle, selected])
            }).catch(() => {
                Notify.error('optometrist.notify.error.LOADRANDOM')

                reject()
            })
        })
    }

    updatePermutation(permutation) {
        let i = permutation.selected.active,
            per = permutation.selected.package

        permutation.selected.circle = per[0][i].circle
        permutation.selected.hs = per[0][i].hs
        permutation.selected.letter = per[0][i].letter
        permutation.selected.number = per[0][i].number
        permutation.selected.kids = per[0][i].kids
        permutation.selected.mirror = per[1][i]
    }
}

export const PermutationsService = new PermutationsServiceClass()