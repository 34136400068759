<template lang="pug">
    table(v-if="eventColumns" style="width:100%" :class="{ 'week-day-alert': eventColumns.alert }")
        tr(style='')
            td(
                v-for="(column, index) in eventColumns.columns"
                :class="column.class"
                :key="index"
            ) &nbsp;
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'

    export default {
        name: 'OptiScheduleWeekEvents',
        props: {
            events: {
                type: undefined,
                required: true
            },
            workingHours: {
                type: undefined,
                required: true
            }
        },
        mounted() {

        },
        computed: {
            eventColumns() {
                let startHour = parseInt(this.workingHours.start.hour)
                let endHour   = parseInt(this.workingHours.end.hour)
                let columns   = endHour - startHour
                let result    = {
                    alert: false,
                    columns: {}
                }

                if (!this.events.length) {
                    return false
                }

                let key = ''
                for (let i = startHour - 1; i < endHour + 1; i++) {
                    key                 = (i < 10) ? '0' + i : '' + i
                    key = 'h' + key
                    result.columns[key] = {
                        class: 'schedule-color-off',
                        events: []
                    }
                }

                _.forEach(this.events, function (event) {
                    let start = moment(event.start).hour(),
                        end   = moment(event.end).clone().add(-1, 'm').hour()

                    let key = ''
                    for (let i = start; i <= end; i++) {
                        key = (i < 10) ? '0' + i : '' + i
                        key = 'h' + key
                        if (result.columns[key]) {
                            result.columns[key].class = 'schedule-color-' + event.type
                            result.columns[key].events.push(event)
                        } else {
                            result.alert = true
                        }
                    }
                })

                return result
            }
        }
    }
</script>

<style scoped>
    .popup-editor {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>
