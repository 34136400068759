import {API} from '@/js/app/vue/api'
import {UAC} from '@/js/app/vue/UserAccessControl'
import {loadLanguageAsync} from '@/js/app/vue/i18n-setup'
import {i18n} from '@/js/app/vue/i18n-setup'

import Vue from 'vue'
import helper from '@/js/app/vue/store/offer/helper'

import Notify from '@/js/app/vue/helpers/Notify'

export default {
    init({commit}) {
        let cached_state = JSON.parse(localStorage.getItem('offer'))

        if(cached_state) {
            let updated_at = moment(cached_state.updated_at)

            if(moment().isSame(updated_at, 'day') || (updated_at.hour() === 23 && moment().diff(updated_at, 'days') === 1)) {
                commit('resetState', cached_state)
            } else {
                localStorage.removeItem('offer')

                commit('resetState')
            }
        } else {
            commit('resetState')
        }
    },

    loadQuestions({commit, state}, refresh_department_id) {
        if(state.questions.frames.length) {
            commit('setLoading', {
                type: 'questions',
                bool: false
            })

            this.dispatch('offer/loadProducts', Vue.$router.currentRoute.params.page)
        } else {
            API.get('catalog/offer/questions-and-filters').then(res => {
                commit('setQuestionsAndFilters', res.data)

                if(refresh_department_id) {
                    commit('setDepartmentId', refresh_department_id)
                }
            }).finally(() => {
                commit('setLoading', {
                    type: 'questions',
                    bool: false
                })

                this.dispatch('offer/loadProducts', Vue.$router.currentRoute.params.page)
            }).catch(() => {
                Notify.error('catalog.offer.notify.error.load.QUESTIONS')
            })
        }
    },

    loadProducts({commit, state}, page) {
        commit('setLoading', {
            type: 'products',
            bool: true
        })

        commit('setPage', page)

        API.post('catalog/offer/products', {
            questions: state.questions.frames,
            filters: state.filters.selected,
            department_id: state.department_id,
            page: state.paginator.page
        }, API.id(Math.random())).then(res => {
            commit('setProducts', res.data)
        }).finally(() => {
            commit('setLoading', {
                type: 'products',
                bool: false
            })
        }).catch(() => {
            Notify.error('catalog.offer.notify.error.load.FRAMES')
        })
    },

    loadHoya({commit, state}) {
        commit('setMode', 'filters')

        commit('setLoading', {
            type: 'hoya',
            bool: true
        })

        let selected = _.cloneDeep(state.selected_questions_hoya)
        selected[selected.length - 1] = state.selected_current_hoya_option

        API.post('catalog/offer/hoya', {
            selected: selected
        }, API.id(Math.random())).then(res => {
            commit('setColors', {
                colors: res.data.colors,
                colors_header: res.data.colors_header
            })
        }).finally(() => {
            commit('setLoading', {
                type: 'hoya',
                bool: false
            })
        }).catch(() => {
            Notify.error('catalog.offer.notify.error.load.HOYA')
        })
    },

    finish({commit, state, getters}) {
        commit('setLoading', {
            type: 'main',
            bool: true
        })

        loadLanguageAsync(UAC.getUser().lang + '_' + UAC.getUser().lang.toUpperCase())

        API.post('catalog/offer/finish', {
            patient_id: state.patient.id,
            filters: getters.getSelected,
            count_products: state.products_count,
            products: state.chose_products,
            favorite_ids: state.favorites.map(f => f.id)
        }).then(() => {
            Notify.success('catalog.offer.choice.notify.SAVE')

            Vue.$router.push({
                name: 'app.patient.detail',
                params: {
                    id: state.patient.id,
                    '#': 'prescriptions'
                }
            })
        }).finally(() => {
            commit('setLoading', {
                type: 'main',
                bool: false
            })

            localStorage.removeItem('offer')
        }).catch(() => {
            Notify.error('catalog.offer.choice.notify.error.SAVE')
        })
    },

    updateQuestionFramesOption({commit, state}, {key, bool}) {
        if(bool) {
            let keys = Object.keys(state.questions.frames[state.index_question_frames].options)

            if(key === 'all') {
                keys.forEach(option_key => {
                    if(option_key !== 'all') {
                        commit('setQuestionFramesOption', {
                            key: option_key,
                            bool: false
                        })
                    }
                })
            } else if(keys.includes('all')) {
                commit('setQuestionFramesOption', {
                    key: 'all',
                    bool: false
                })
            }
        }

        commit('setQuestionFramesOption', {
            key: key,
            bool: bool
        })
    },

    updateFilterValue({commit, state}, {type, key, value}) {
        let commit_name = type === 'name' ? 'name' : 'value'

        commit('setFilter' + commit_name.charAt(0).toUpperCase() + commit_name.slice(1), {type: type, key: key, value: value})
    },

    updateDepartmentId({commit, state}, department_id) {
        if(state.department_id !== department_id) {
            if(state.index_question_frames > 0 && state.questions.frames[0].options.salon.selected) {
                if(confirm(i18n.t('catalog.offer.confirm_reset.change_department.MESSAGE'))) {
                    commit('resetState')
                    this.dispatch('offer/loadQuestions', department_id)
                } else {
                    let department_id = state.department_id
                    commit('setDepartmentIdOnlyId', -1)
                    Vue.nextTick(() => commit('setDepartmentIdOnlyId', department_id))
                }
            } else {
                commit('setDepartmentId', department_id)

                this.dispatch('offer/loadProducts')
            }
        }
    },

    goChangeHelp({commit, state}, index) {
        if(index >= 0 && index <= state.help.position.all - 1) {
            commit('setHelp', {
                index_question_frames: state.index_question_frames,
                option_key: Object.keys(state.questions.frames[state.index_question_frames].options)[index]
            })
        }
    },

    goFinish({commit, state}) {
        if(state.changed) {
            this.dispatch('offer/loadProducts')
        }

        commit('setMode', 'filters')
    },

    createNewOffer({}, patient) {
        let state = helper.getDefaultState()

        state.patient = patient
        state.department_id = UAC.getUser().activeDepartmentId
        state.updated_at = moment()

        helper.cache(state)

        Vue.$router.push('app.catalog.offer')
    },

    saveProductByClient({commit, state}, product) {
        if(state.chose_products.map(p => p.product.id).includes(product.id)) {
            Notify.warning('catalog.offer.choice.byClient.notify.warning.JUST_ADDED')
        } else if(state.products.map(p => p.id).includes(product.id) && state.chose_products.length === 3) {
            Notify.warning('catalog.offer.choice.byClient.notify.warning.EXISTS_ON_LIST_AND_LIMIT')
        } else {
            let choice_by_client = true

            if(state.products.map(p => p.id).includes(product.id)) {
                choice_by_client = false

                Notify.warning('catalog.offer.choice.byClient.notify.warning.EXISTS_ON_LIST')
            }

            commit('setChoseProduct', {product: product, choice_by_client: choice_by_client})

            commit('setDialog', {type: 'by_client', bool: false})
        }
    },

    clickOption({state}, disable) { //TODO: wartość disabled jest tymczasowa
        if(disable) {
            Notify.warning('Wyłączyliśmy okulary przeciwsłoneczne. Pod przyciskiem "Korekcyjne" znajdują się wszystkie okulary!', false)
        } else if(state.loading.products) {
            Notify.warning('catalog.offer.notify.warning.DISABLED_QUESTION')
        }
    }
}
