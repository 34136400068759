<template lang="pug">
    .feedback.feedback-right(v-show="show" @click="goToTop")
        i.fa.fa-arrow-up.mr3
        span {{'default.GOTOTOP'|translate}}
</template>

<script>
    export default {
        name: 'OptiScrollTop',
        data() {
            return {
                show: false
            }
        },
        mounted() {
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        },
        methods: {
            onScroll() {
                this.show = window.top.scrollY > 200
            },
            goToTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>
