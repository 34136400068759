import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'
import Vue from 'vue'

import general from '@/js/app/vue/store/carts/helper/general'

let helper = {
    general: general
}

export default {
    setActive({commit}, index) {
        commit('setActive', index)

        window.localStorage.setItem('cart-active', index)
    },
    orderProblems({commit}) {
        API.get('carts/getOrderProblems', API.id('order-problems-' + Math.random())).then(res => {
            commit('setCartOrderProblems', res.data)
        })
    },
    fetch({commit}) {
        commit('setLoading', true)

        API.get('carts/get').then(res => {
            ['sale', 'order', 'sendback'].forEach(type => {
                commit('setCart', {
                    type: type,
                    data: res.data[type]
                })

                commit('setTotals', type)
            })

            this.dispatch('carts/orderProblems')
        }).finally(() => {
            commit('setLoading', false)
        })
    },

    updatePatientExists({state, commit}, patient_exists) {
        commit('setPatientExists', patient_exists)

        if(patient_exists === 2) {
            this.dispatch('carts/updatePatient', {})
        }

        commit('setPending', true)

        API.post('carts/sale/update', {
            id: state.carts.sale.id,
            patient_exists: state.carts.sale.patient_exists
        }).then(res => {
            if(!res.data.status) {
                Notify.error('carts.sale.notify.error.SAVEPATIENTEXISTS')

                this.dispatch('carts/fetch')
            }
        }).finally(() => {
            commit('setPending', false)
        }).catch(() => {
            this.dispatch('carts/fetch')

            Notify.error('carts.sale.notify.error.SAVEPATIENTEXISTS')
        })
    },

    updatePatient({state, commit}, patient) {
        commit('setPatient', patient)

        let id = null
        if(patient && Object.keys(patient).length) {
            id = patient.id
        }

        if(state.carts.sale.patient_id !== id) {
            commit('changedPatient', patient)

            commit('setTotals', 'sale')

            commit('setPending', true)

            API.post('carts/sale/update', {
                id: state.carts.sale.id,
                patient_id: state.carts.sale.patient_id ? state.carts.sale.patient_id : 0
            }).then(res => {
                if(!res.data.status) {
                    Notify.error('carts.sale.notify.error.SAVEPATIENTEXISTS')

                    this.dispatch('carts/fetch')
                }
            }).finally(() => {
                commit('setPending', false)
            }).catch(() => {
                this.dispatch('carts/fetch')

                Notify.error('carts.sale.notify.error.SAVEPATIENTEXISTS')
            })
        }
    },

    updateItems({commit}, {type, items}) {
        commit('setItems', {
            type: type,
            items: items
        })

        if(type === 'order') {
            this.dispatch('carts/orderProblems')
        }

        commit('setCheckboxAll', {
            type: type,
            bool: false
        })

        commit('refreshHighlight', type)

        commit('setDisabledBtnDelete', type)
    },

    addProduct({state, commit}, {type, product}) {
        if(!helper.general.stock(state.carts, type, product)) {
            Notify.warning('carts.notify.NOPRODUCTINSTOCK')

            return false
        } else if(helper.general.duplicate(state.carts, type, product)) {
            Notify.warning('carts.notify.error.DUPLICATE2')

            return false
        }

        let parse = { //TODO: usunąć parse jak będzie wszystko w VUE
            'sale': 1,
            'order': 2,
            'sendback': 3
        }

        commit('setPending', true)

        API.post('carts/add', {
            cart: parse[type],
            product: product.id
        }).then(res => {
            this.dispatch('carts/updateItems', {
                type: type,
                items: res.data.items
            })

            Vue.nextTick(() => {  // chodzi o to by autosave się odpalił gdy jest rabat
                if(type === 'sale') {
                    commit('setDiscountForProduct', product.id)
                }

                commit('setTotals', type)

                commit('setHighlight', {
                    type: type,
                    product_id: product.id
                })

                Notify.success('carts.notify.SAVE')
            })
        }).finally(() => {
            commit('setPending', false)
        }).catch(() => {
            this.dispatch('carts/fetch')

            Notify.error('carts.notify.error.SAVE')
        })
    },

    updateQuantity({state, commit}, {type, key, quantity}) {
        commit('setQuantity', {
            type: type,
            key: key,
            quantity: quantity
        })

        if(type === 'order') {
            this.dispatch('carts/orderProblems')
        }

        commit('setTotals', type)
    },
    updateDiscountPercent({state, commit}, {type, key, discount}) {
        commit('setDiscountPercent', {
            type: type,
            key: key,
            discount: discount
        })

        commit('setTotals', type)
    },
    updateDiscount({state, commit}, {type, key, discount}) {
        commit('setDiscount', {
            type: type,
            key: key,
            discount: discount
        })

        commit('setTotals', type)
    },

    updateCheckbox({state, commit}, {type, key, bool}) {
        commit('setCheckbox', {
            type: type,
            key: key,
            bool: bool
        })

        let checkbox_all = true
        state.carts[type].items.forEach(item => {
            if(!item.checkbox) {
                checkbox_all = false
            }
        })

        commit('setCheckboxAll', {
            type: type,
            bool: checkbox_all
        })

        commit('setDisabledBtnDelete', type)
    },
    updateCheckboxAll({state, commit}, {type, bool}) {
        state.carts[type].items.forEach((item, key) => {
            commit('setCheckbox', {
                type: type,
                key: key,
                bool: bool
            })
        })

        commit('setCheckboxAll', {
            type: type,
            bool: bool
        })

        commit('setDisabledBtnDelete', type)
    },

    deleteItems({state, commit}, {type, item_ids}) {
        commit('setPending', true)

        API.post('carts/items/remove', {
            type: type,
            item_ids: item_ids
        }).then(res => {
            this.dispatch('carts/updateItems', {
                type: type,
                items: res.data.items
            })

            commit('setTotals', type)

            Notify.success('carts.notify.delete.' + (item_ids.length > 1 ? 'FEW' : 'SIMPLE'))
        }).finally(() => {
            commit('setPending', false)
        }).catch(() => {
            this.dispatch('carts/fetch')

            Notify.error('carts.notify.delete.error.' + (item_ids.length > 1 ? 'FEW' : 'SIMPLE'))
        })
    },
    deleteItemsFromCheckbox({state}, type) {
        let item_ids = []

        state.carts[type].items.forEach(item => {
            if(item.checkbox) {
                item_ids.push(item.id)
            }
        })

        this.dispatch('carts/deleteItems', {
            type: type,
            item_ids: item_ids
        })
    },

    updateComments({state, commit}, {key, comments}) {
        commit('setComments', {
            key: key,
            comments: comments
        })
    },
    updateCodes({state, commit}, {key, codes}) {
        commit('setCodes', {
            key: key,
            codes: codes
        })
    },

    updateWarningError({state, commit}, item) {
        commit('setWarningError', {
            id: item.id,
            warning: item.warning,
            error: item.error
        })
    }
}
