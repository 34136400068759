<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-dropbox.mr3
            span {{'product.depository.STOCKS'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row(v-if="!loading")
                .col-sm-4
                    OptiProductInfo(:product="product")
                .col-sm-8
                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th #
                                    th {{'product.depository.DEPARTMENT'|translate}}
                                    th {{'product.depository.QUANTITY'|translate}}
                            tbody
                                tr
                                    td.text-right 1
                                    td {{'product.depository.WAREHOUSE'|translate}}
                                    td
                                        span.mr3 {{product.quantity}}
                                        small ({{product.threshold}} {{'product.depository.THRESHOLD'|translate}})
                                tr(v-for="(depository, key) in product.depositories")
                                    td.text-right(nowrap) {{key + 2}}
                                    td {{depository.department.name}}
                                    td {{depository.stock}}

        template(#footer)
            OptiButton.pull-right(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogDepository',
        components: {
            OptiDialog,
            OptiProductInfo,
            OptiDimmer,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            productId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                product: {},

                loading: true
            }
        },
        mounted() {
            API.get('warehouse/products/depository/' + this.productId).then(res => {
                this.product = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('product.depository.notify.error.LOAD')
            })
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 100%;
    }
</style>