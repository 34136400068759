<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'user.training.DOWNLOADING'|translate}}

            .label.label-inverse.pull-right {{downloading.length}}

        table.table.table-striped.middle
            thead
                tr
                    th.text-right #
                    th
                    th

            tbody
                tr(v-for="(download, key) in downloading" :key="key")
                    td.text-right {{key + 1}}
                    td
                        OptiSelectDownload(:download="download" @selected="d => updateDownload(key, d)")
                    td.text-center
                        .btn.btn-danger(@click="deleteDownload(key)")
                            i.fa.fa-trash

                tr(v-show="!downloading.length")
                    td.text-center.text-muted(colspan="3")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'default.table.EMPTY'|translate}}

        .panel-footer.text-right
            OptiButton(type="success" :disabled="invalidDownloading"
                @click.native="addDownload") {{'dictionary.trainingtype.ADDDOWNLOAD'|translate}}
                template(#icon)
                    i.fa.fa-plus
</template>

<script>
    import OptiSelectDownload from '@/js/app/vue/components/Select/OptiSelectDownloading'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDictionaryTrainingTypeDownloading',
        components: {
            OptiSelectDownload,
            OptiButton,
        },
        props: {
            downloading: {
                type: Array,
                required: true
            },
            invalidDownloading: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            addDownload() {
                this.downloading.push({
                    id: 0,
                    name: ''
                })
            },
            updateDownload(key, download) {
                this.$set(this.downloading, key, download)
            },
            deleteDownload(key) {
                this.downloading.splice(key, 1)
            }
        }
    }
</script>