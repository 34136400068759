<template lang="pug">
    .row
        .col-xs-12
            legend {{'shipments.new.RECEIVER'|translate}}

        .col-md-4
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="0" @change="$emit('change-address')")
                    span
                    | {{'shipments.new.receiver.HEADQUARTERS'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="1" @change="$emit('change-address')")
                    span
                    | {{'shipments.new.receiver.PATIENT'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="4" @change="$emit('change-address')")
                    span
                    | {{'shipments.new.receiver.SPECIALISTS'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="3" @change="$emit('change-address')")
                    span
                    | {{'shipments.new.receiver.OTHER'|translate}}

        .col-md-8
            div(v-show="receiver.type === 0")
                .form-group
                    label(for="headquarters") {{'shipments.new.receiver.headquarters.SELECT'|translate}}
                    .controls
                        select#headquarters.form-control(v-model="model.department" :disabled="selectDepartments.length === 1"
                            @change="$emit('change-address')")
                            option(v-for="department in selectDepartments" :value="department") {{department.firstName}}
            div(v-show="receiver.type === 1")
                .form-group
                    label(for="patient") {{'shipments.new.receiver.patient.PATIENTID'|translate}}
                    .controls
                        OptiSelectPatient#patient(:init-result="{}" @selected="updatePatient")
            div(v-show="receiver.type === 4")
                .form-group
                    label(for="specialists") {{'shipments.new.receiver.specialists.SELECT'|translate}}
                    .controls
                        select#specialists.form-control(v-model="model.specialist" @change="$emit('change-address')")
                            option(v-for="spec in specialists" :value="spec") {{spec.firstName}} {{spec.lastName}}
            form(v-show="receiver.type === 3")
                .row
                    .col-xs-12
                        .form-group
                            label(for="lastReceivers") {{'shipments.new.receiver.other.LASTRECEIVERS'|translate}}
                            .controls
                                select#lastReceivers.form-control(v-model="model.custom" @change="$emit('change-address')")
                                    option(v-for="l in last_receivers" :value="l") {{l.firstName}} {{l.lastName}}
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="name") {{'shipments.new.receiver.other.NAME'|translate}}
                            .controls
                                input#name.form-control(v-model="model.custom.firstName" name="firstName" required)
                                OptiValidate(f="name" :data="vModelCustom.firstName")
                    .col-md-6
                        .form-group
                            label(for="surname") {{'shipments.new.receiver.other.SURNAME'|translate}}
                            .controls
                                input#surname.form-control(v-model="model.custom.lastName" name="lastName")
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="zipCode") {{'shipments.new.receiver.other.POSTALCODE'|translate}}
                            .controls
                                input#zipCode.form-control(v-model="model.custom.zipCode" name="zipCode" required)
                                OptiValidate(f="zipCode" :data="vModelCustom.zipCode")
                    .col-md-6
                        .form-group
                            label.star-required(for="city") {{'shipments.new.receiver.other.CITY'|translate}}
                            .controls
                                input#city.form-control(v-model="model.custom.city" name="city" required)
                                OptiValidate(f="city" :data="vModelCustom.city")
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="street") {{'shipments.new.receiver.other.STREET'|translate}}
                            .controls
                                input#street.form-control(v-model="model.custom.street" name="street" required)
                                OptiValidate(f="street" :data="vModelCustom.street")
                    .col-md-6
                        .form-group
                            label(for="telephone") {{'shipments.new.receiver.other.TELEPHONE'|translate}}
                            .controls
                                input#telephone.form-control(v-model="model.custom.cellPhoneNumber" name="telephone")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiShippingCreateStepReceivers',
        components: {
            OptiSelectPatient,
            OptiValidate
        },
        props: {
            model: {
                type: Object,
                required: true
            },
            departments: {
                type: Array,
                required: true
            },
            specialists: {
                type: Array,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },

            vModelCustom: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                last_receivers: []
            }
        },
        mounted() {
            API.get('shipment/last-receivers').then(res => {
                this.last_receivers = res.data
            }).catch(() => {
                this.$notify.error('shipments.notify.error.LASTRECEIVERS')
            })
        },
        methods: {
            updatePatient(patient) {
                this.model.patient = patient

                this.$emit('change-address')
            }
        },
        computed: {
            selectDepartments() {
                let filtered = []

                this.departments.forEach(department => {
                    if(department.id !== 49) {
                        filtered.push(department)
                    }
                })

                if(filtered.length === 1) {
                    this.model.department = filtered[0]

                    this.$emit('change-address')
                }

                return filtered
            }
        }
    }
</script>