<template lang="pug">
    .pie
        PieChart(
            :chart-data="chartData"
            :options="options"
            :styles="styles"
            ref="chart"
        )
        OptiChartTooltip(
            :model="check"
            :chart-data="chartData"
            :chart="$refs.chart"
        )
            template(#header="{header}")
                div {{ chartData.departmentLogin }}
            template(#default="{body}")
                table.table.table-striped.table-bordered.table-condensed
                    thead
                        tr
                            th
                            th.text-right(v-if="chartData.datasets[1].catalog !== '--'") Katalog
                            th.text-right(v-if="chartData.datasets[1].catalog !== '--'") Hurt
                            th.text-right(v-if="chartData.datasets[1].catalog !== '--'") Obrót
                            th.text-right Rabaty i przeceny
                            th.text-right(v-if="chartData.datasets[1].catalog !== '--'") %
                            th.text-center Obniżona marża
                            th.text-right(v-if="chartData.datasets[1].catalog !== '--'") %
                    tbody
                        tr
                            th Prace
                            td.text-right.no-brake(v-if="chartData.datasets[1].catalog !== '--'") {{ val(chartData.datasets[1].catalog) }}
                            td.text-right.no-brake(v-if="chartData.datasets[1].wholesale !== '--'") {{ val(chartData.datasets[1].wholesale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[1].sale !== '--'") {{ val(chartData.datasets[1].sale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[1].discount !== '--'") {{ val(chartData.datasets[1].discount)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[1].percent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[1].discount === '--'") %
                            td.text-right.no-break(v-if="chartData.datasets[1].discountActions !== '--'") {{ val(chartData.datasets[1].discountActions)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[1].realPercent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[1].discountActions === '--'") %
                        tr
                            th Słońce
                            td.text-right.no-brake(v-if="chartData.datasets[2].catalog !== '--'") {{ val(chartData.datasets[2].catalog) }}
                            td.text-right.no-brake(v-if="chartData.datasets[2].wholesale !== '--'") {{ val(chartData.datasets[2].wholesale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[2].sale !== '--'") {{ val(chartData.datasets[2].sale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[2].discount !== '--'") {{ val(chartData.datasets[2].discount)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[2].percent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[2].discount === '--'") %
                            td.text-right.no-break(v-if="chartData.datasets[2].discountActions !== '--'") {{ val(chartData.datasets[2].discountActions)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[2].realPercent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[2].discountActions === '--'") %
                        tr
                            th Inne
                            td.text-right.no-brake(v-if="chartData.datasets[3].catalog !== '--'") {{ val(chartData.datasets[3].catalog) }}
                            td.text-right.no-brake(v-if="chartData.datasets[3].wholesale !== '--'") {{ val(chartData.datasets[3].wholesale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[3].sale !== '--'") {{ val(chartData.datasets[3].sale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[3].discount !== '--'") {{ val(chartData.datasets[3].discount)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[3].percent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[3].discount === '--'") %
                            td.text-right.no-break(v-if="chartData.datasets[3].discountActions !== '--'") {{ val(chartData.datasets[3].discountActions)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[3].realPercent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[3].discountActions === '--'") %
                        tr
                            th Suma
                            td.text-right.no-brake(v-if="chartData.datasets[0].catalog !== '--'") {{ val(chartData.datasets[0].catalog) }}
                            td.text-right.no-brake(v-if="chartData.datasets[0].wholesale !== '--'") {{ val(chartData.datasets[0].wholesale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[0].sale !== '--'") {{ val(chartData.datasets[0].sale) }}
                            td.text-right.no-brake(v-if="chartData.datasets[0].discount !== '--'") {{ val(chartData.datasets[0].discount)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[0].percent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[0].discount === '--'") %
                            td.text-right.no-break(v-if="chartData.datasets[0].discountActions !== '--'") {{ val(chartData.datasets[0].discountActions)}}
                            td.text-right.no-brake.percent {{ chartData.datasets[0].realPercent | currency({fractionCount: 0})}}
                                span(v-if="chartData.datasets[0].discountActions === '--'") %
                div
                    | Umowa serwisowa podpisana w
                    b &nbsp;{{val(chartData.datasets[0].SCPercent)}}%
                    | &nbsp;wszystkich prac (
                    b {{chartData.datasets[0].tasksSC}} z {{chartData.datasets[0].tasksAll}}
                    | ).
                div
                    | Marża podniesiona o&nbsp;
                    b {{val(chartData.datasets[0].SCMoneyPrc)}}%
                    b(v-if="$uac.user.activeRole.admin || $uac.user.activeRole.id === 16 && chartData.departmentId === $uac.user.activeDepartmentId") &nbsp;({{chartData.datasets[0].SCMoney | currency({fractionCount: 1})}}tys.zł)
                    | .

        .departmentName
            div {{ chartData.departmentLogin }}
                p(style="color:red; margin:1px") -{{countSummary}}%
                p(style="color:green; margin:1px") +{{prcSum}}%
        .productType(v-if="chartData.departmentId === 91")
            div.discount Obniżona marża
            div Ramki
            div Słońce
</template>

<script>
    import PieChart from "@/js/app/vue/components/Chart/Base/PieChart";
    import OptiChartTooltip from "@/js/app/vue/components/Chart/Base/OptiChartTooltip";

    export default {
        name: "OptiCharDiscountsPie",
        components: {OptiChartTooltip, PieChart},
        props: {
            chartData: {
                type: Object,
                default() {
                    return {}
                }
            },
            height: {
                type: Number,
                default: 200
            },
        },
        methods: {
            val(value, round = 1) {
                if (value !== '--') {
                    return this.$filters.currency(value, '', ' ', round, ',').trim()
                }
                return ''
            }
        },
        asyncComputed: {
            countSum() {
                this.countSummary = Math.round(this.chartData.datasets[0].realPercent*100)/100
                this.prcSum = Math.round(this.chartData.datasets[0].SCMoneyPrc*100)/100
            },
        },
        data() {
            return {
                check: null,
                countSummary: 0,
                prcSum: 0,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    cutoutPercentage: 40,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        mode: 'index',
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        },
                    },
                },
                styles: {
                    position: 'relative',
                    height: `${this.height}px`
                },
            }
        },
    }
</script>

<style scoped lang="less">
    .pie {
        position: relative;
        padding: 5px;
    }
    .productType {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        text-align: center;
        color: @text-color;


        & div {
            position: relative;
            top: 6%;
            right: 1.5em;
            margin-bottom: 3px;
            text-shadow: white;
        }

        div.discount {
            right: 3.5em;
        }
    }

    .departmentName {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        pointer-events: none;

        & div {
            flex: 1;
            flex-grow: 0;
            font-weight: bold;
            text-align: center;
            word-break: keep-all;
        }
    }

    p.header {
        color: black;
        font-weight: bold;
        padding: 2px 10px;
    }
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }
</style>