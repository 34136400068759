<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.icon.icon-clock.mr3
            span {{'dashboard.FORECAST'|translate}}

        .table-responsive
            table.table.table-striped.table-hover.table-dashboard.text-expressive
                thead
                    tr
                        th
                        th.text-center {{'dashboard.forecast.heading.SK'|translate}}
                        th.text-right {{'dashboard.forecast.heading.ALL'|translate}}
                        th.text-center {{'dashboard.forecast.heading.PRO'|translate}}
                tbody
                    tr(v-for="previous in previousAssemblies")
                        td.text-left.middle
                            span.text-muted.small(style="width:33px;display:inline-block") {{previous.date|stringTimeWithoutYear}}
                            span {{weekdays[previous.weekday]}}
                        td.text-right.middle
                        td.text-right.middle(style="border-right:1px solid #eee")
                        td.text-right.middle(:style="getForecastStyle(previous)")
                            span.text-muted(v-show="previous.positive") {{previous.abraded}}

                    tr(v-for="item in taskAssemblyForecast.slice(0, forecastLimit)")
                        td.text-left.middle
                            span.text-muted.small(style="width:33px;display:inline-block") {{item.date|stringTimeWithoutYear}}
                            span {{weekdays[item.weekday]}}
                        td.text-right.middle
                            a(:href="getHrefWithParams(item.srefCompleted)") {{item.completed}}
                        td.text-right.middle(style="border-right:1px solid #eee")
                            a(:href="getHrefWithParams(item.sref)") {{item.total}}
                        td.text-right.middle(:style="getForecastStyle(item)")
                            span(v-show="!item.positive") {{item.forecast}}
                            span(style="cursor:pointer" v-show="item.positive"
                                @click="$store.dispatch('dashboard/fetchPreviousAssemblies')")
                                span.mr3 {{item.abraded}}
                                i.fa.fa-flag-checkered
                    tr
                        td
                            a(style="cursor:pointer" @click="forecastLimit = (forecastLimit === 7 ? 5000 : 7)")
                                span(v-show="forecastLimit === 7") {{'dashboard.forecast.toggle.ALL'|translate}}
                                span(v-show="forecastLimit !== 7") {{'dashboard.forecast.toggle.ONLY'|translate}} 7
                        td.text-right.middle {{sum('completed')}}
                        td.text-right.middle(style="border-right:1px solid #eee") {{sum('total')}}
                        td
</template>

<script>


    export default {
        name: 'OptiDashboardForecast',
        components: {

        },
        props: {
            previousAssemblies: {
                type: Array,
                required: true
            },
            taskAssemblyForecast: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                forecastLimit: 7,
                weekdays: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']
            }
        },
        mounted() {

        },
        methods: {
            getHrefWithParams(str) {
                let regex = /(?<sref>[^(]+)\((?<params>.*)\)/,
                    found = str.match(regex)

                return this.$state.href(found.groups.sref, JSON.parse(found.groups.params.replace('search', '"search"')))
            },
            getForecastStyle(item) {
                let alpha = 0

                if(item.positive) {
                    return {}
                }

                alpha = Math.max(0, (0.5 + (parseInt(item.forecast) / 100)))

                return {
                    'background-color': 'rgba(255, 0, 0, ' + alpha + ')'
                }
            },
            sum(field) {
                let total = 0

                for(let i = 0; i < this.taskAssemblyForecast.length; i++) {
                    if(this.taskAssemblyForecast[i][field]) {
                        total += this.taskAssemblyForecast[i][field]
                    }
                }

                return total
            }
        }
    }
</script>