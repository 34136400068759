<template lang="pug">
    .col-xs-12
        .alert.alert-danger.text-center(style="margin-bottom:0" v-show="task.status <= 3 && task.turn_back && permission.turn_back")
            i.fa.fa-reply.mr3
            span {{task.turn_back_name}}
        .alert.alert-danger.text-center(style="margin-bottom:0" v-show="task.status <= 3 && task.turn_back && task.turn_back !== 5 && permission.turn_back === false")
            i.fa.fa-reply.mr3
            span {{task.turn_back_name}}

        .row(v-if="permission.turn_back && task.turn_back === 5")
            .col-sm-8.col-sm-offset-2
                OptiDimmer(:active="loading")
                form.form.form-horizontal
                    .form-group
                        .col-xs-12
                            .radio.c-radio.c-radio-nofont
                                label.text-bold
                                    input(type="radio" v-model="turnBack.option" name="turnBack" :value="6")
                                    span
                                    | {{'tasks.turnback.HOYAERROR'|translate}}
                    .form-group
                        .col-xs-12
                            .radio.c-radio.c-radio-nofont
                                label.text-bold
                                    input(type="radio" v-model="turnBack.option" name="turnBack" :value="7")
                                    span
                                    | {{'tasks.turnback.DEPARTMENTERROR'|translate}}
                    .form-group
                        .col-xs-12
                            textarea.form-control(rows="4" :placeholder="'tasks.optic.putback.change.PLACEHOLDER'|translate"
                                v-model="turnBack.comments")
                    .form-group
                        .col-xs-12.text-right
                            OptiButton(type="success"
                                text="tasks.optic.putback.change.confirm" @confirmed="goPutBack"
                                ) {{'tasks.optic.putback.change.confirm.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskDetailsSummaryTurnBackInfo',
        components: {
            OptiDimmer,
            OptiButton
        },
        props: {
            task: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                turnBack: {
                    task_id: this.task.id,
                    option: 6,
                    comments: ''
                },

                permission: {
                    turn_back: this.$uac.permission('tasks.turn_back'),
                    no_turn_back: !!this.$uac.permission('tasks.turn_back')
                },
                loading: false
            }
        },
        methods: {
            goPutBack() {
                this.loading = true

                API.post('task/optic/change-turn-back', this.turnBack).then(() => {
                    this.$emit('summary-was-updated')

                    this.$notify.success('tasks.optic.notify.putback.CHANGED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.optic.notify.error.putback.CHANGED')
                })
            }
        }
    }
</script>