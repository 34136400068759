<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-if="!loading")
			form.form-horizontal.form-validate(name="formReclamationService" @submit.prevent="editReclamationService")
				.form-group
					label.col-sm-4.control-label.star-required(for="name") {{'dictionary.reclamationservice.table.NAME'|translate}}
					.col-sm-8
						input#name.form-control(:placeholder="'dictionary.reclamationservice.table.NAME'|translate"
							v-model="reclamationservice.name" name="name")
						OptiValidate(f="name" :data="$v.reclamationservice.name")
				.form-group
					label.col-sm-4.control-label(for="type") {{'dictionary.reclamationservice.table.TYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#type(enum="ReclamationServiceType"
							:value="reclamationservice.type" @update-enum="v => reclamationservice.type = v")
				.form-group
					label.col-sm-4.control-label(for="reclamation_type") {{'dictionary.reclamationservice.table.RECLAMATIONTYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#reclamation_type(enum="ReclamationServiceReclamationType"
							:value="reclamationservice.reclamation_type" @update-enum="v => reclamationservice.reclamation_type = v")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.dictionary.reclamationservices.table')"
						) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.reclamationservice.$invalid")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'
	import {required} from 'vuelidate/lib/validators'

	import OptiValidate from '../../OptiValidate'
	import OptiDimmer from '../../Blocks/OptiDimmer'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiButton from '../../Button/OptiButton'
	import OptiSelectEnum from '../../Select/OptiSelectEnum'

	export default {
		name: 'OptiDictionaryReclamationServicesEdit',
		components: {
			OptiValidate,
			OptiDimmer,
			OptiButtonSave,
			OptiButton,
			OptiSelectEnum,
		},
		data() {
			return {
				reclamationservice: {},
				loading: true
			}
		},
		validations: {
			reclamationservice: {
				name: {
					required
				}
			}
		},
		mounted() {
			API.get('dictionary/reclamation-service/action/' + this.$state.params.id).then(res => {
				this.reclamationservice = res.data
			}).finally(() => {
				this.loading = false
			}).catch(() => {
				this.$notify.error('dictionary.reclamationservice.notify.error.EDIT')
			})
		},
		methods: {
			editReclamationService() {
				this.loading = true

				API.put('dictionary/reclamation-service/action', this.reclamationservice).then(() => {
					this.$notify.success('dictionary.reclamationservice.notify.EDIT')

					this.$state.go('app.dictionary.reclamationservices.table')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('dictionary.reclamationservice.notify.error.EDIT')
				})
			}
		}
	}
</script>