<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.correction.MADDOX'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="cancelled()")

        template(#body)
            OptiOptometristsDisplayControls

            .row
                .col-xs-12.text-center
                    div(style="padding:10px 0;font-size:18px")
                        i.fa.fa-eye.mr3
                        span {{'optometrist.correction.MADDOX'|translate}}
                        button.btn.btn-info(type="button" style="margin-left:3px" :class="{'btn-warning active': help}"
                            @click="help_selected('main')") {{'default.HELP'|translate}}

                    OptiOptometristsTips(style="padding-left:15px" v-if="help" :data="tips[help]")

                .col-xs-12.text-center(v-show="!help")
                    .row(style="padding-left:10px;padding-right:10px")
                        .col-xs-6
                            .row
                                .col-xs-12.text-center.text-expressive(style="font-size:20px" :class="{'bold': r.r.prism}")
                                    span {{'prescription.RIGHT'|translate}} ({{'prescription.PRISM'|translate}}
                                    span(style="padding-left:3px") {{r.r.prism|number(2)}}
                                    span(style="padding-left:3px") {{'prescription.PRISMANGLE'|translate}} {{r.r.angle|number(0)}})
                                .col-xs-12.text-center(style="padding-top:10px")
                                    span.bold(style="font-size:20px") RMH
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': help === 'rmh'}"
                                        @click="help_selected('rmh')") {{'default.HELP'|translate}}
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': video === 'rmh'}"
                                        @click="video_selected('rmh')") {{'optometrist.video.DISPLAY'|translate}}
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="l" :maddox="m" :data="m.r_l" :validate="['r_v', 'r_r']"
                                        @update-maddox="v => updateMaddox('r_l', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="v" :maddox="m" :data="m.r_v" :validate="['r_l', 'r_r']"
                                        @update-maddox="v => updateMaddox('r_v', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="r" :maddox="m" :data="m.r_r" :validate="['r_l', 'r_v']"
                                        @update-maddox="v => updateMaddox('r_r', v)")

                                .col-xs-12.text-center(style="padding-top:10px")
                                    span.bold(style="font-size:20px") RMV
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': help === 'rmv'}"
                                        @click="help_selected('rmv')") {{'default.HELP'|translate}}
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': video === 'rmv'}"
                                        @click="video_selected('rmv')") {{'optometrist.video.DISPLAY'|translate}}
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="t" :maddox="m" :data="m.r_t" :validate="['r_h', 'r_b']"
                                        @update-maddox="v => updateMaddox('r_t', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="h" :maddox="m" :data="m.r_h" :validate="['r_t', 'r_b']"
                                        @update-maddox="v => updateMaddox('r_h', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="b" :maddox="m" :data="m.r_b" :validate="['r_t', 'r_h']"
                                        @update-maddox="v => updateMaddox('r_b', v)")
                                .col-xs-12.text-center
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input#r_suppression(type="checkbox" v-model="m.r_suppression"
                                                name="r_suppression" @change="changeSuppression('r')")
                                            span.fa.fa-check
                                    label(for="r_suppression") {{'optometrist.correction.maddox.SUPPRESSION'|translate}}
                        .col-xs-6.bg-separator
                            .row
                                .col-xs-12.text-center.text-expressive(style="font-size:20px" :class="{'bold': r.l.prism}")
                                    span {{'prescription.LEFT'|translate}} ({{'prescription.PRISM'|translate}}
                                    span(style="padding-left:3px") {{r.l.prism|number(2)}}
                                    span(style="padding-left:3px") {{'prescription.PRISMANGLE'|translate}} {{r.l.angle|number(0)}})
                                .col-xs-12.text-center(style="padding-top:10px")
                                    span.bold(style="font-size:20px") WMH
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': help === 'wmh'}"
                                        @click="help_selected('wmh')") {{'default.HELP'|translate}}
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': video === 'wmh'}"
                                        @click="video_selected('wmh')") {{'optometrist.video.DISPLAY'|translate}}
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="l" :maddox="m" :data="m.l_l" :validate="['l_v', 'l_r']"
                                        @update-maddox="v => updateMaddox('l_l', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="v" :maddox="m" :data="m.l_v" :validate="['l_l', 'l_r']"
                                        @update-maddox="v => updateMaddox('l_v', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="r" :maddox="m" :data="m.l_r" :validate="['l_l', 'l_v']"
                                        @update-maddox="v => updateMaddox('l_r', v)")

                                .col-xs-12.text-center(style="padding-top:10px")
                                    span.bold(style="font-size:20px") WMV
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': help === 'wmv'}"
                                        @click="help_selected('wmv')") {{'default.HELP'|translate}}
                                    button.btn.btn-info(style="padding:2px 16px;margin:0 0 6px 6px"
                                        :class="{'btn-warning active': video === 'wmv'}"
                                        @click="video_selected('wmv')") {{'optometrist.video.DISPLAY'|translate}}
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="t" :maddox="m" :data="m.l_t" :validate="['l_h', 'l_b']"
                                        @update-maddox="v => updateMaddox('l_t', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="h" :maddox="m" :data="m.l_h" :validate="['l_t', 'l_b']"
                                        @update-maddox="v => updateMaddox('l_h', v)")
                                .col-xs-4
                                    OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField(
                                        type="b" :maddox="m" :data="m.l_b" :validate="['l_t', 'l_h']"
                                        @update-maddox="v => updateMaddox('l_b', v)")
                                .col-xs-12.text-center
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input#l_suppression(type="checkbox" v-model="m.l_suppression"
                                                name="l_suppression" @change="changeSuppression('l')")
                                            span.fa.fa-check
                                    label(for="l_suppression") {{'optometrist.correction.maddox.SUPPRESSION'|translate}}

        template.text-right(#footer)
            OptiButton(type="success" @click.native="confirmed()") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
            OptiButton(type="danger" @click.native="cancelled()") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'
    import {Maddox} from '@/js/app/vue/components/Optometrists/Helpers/Maddox'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'
    import OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/ScreeningTests/OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField'

    export default {
        name: 'OptiOptometristsDetailsRefractionDialogScreeningTestMaddox',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips,
            OptiOptometristsDisplayControls,
            OptiOptometristsDetailsRefractionDialogScreeningTestMaddoxField,
        },
        props: {
            maddox: {
                type: Object,
                required: true
            },
            result: {
                type: Object,
                required: true
            }
        },
        data() {
            let prefix = 'optometrist.tips.maddox.',
                generate_tips = type => {
                    let prefix_type = 'optometrist.tips.maddox-' + type + '.'

                    return {
                        info: [],
                        instruction: _.map(_.range(1, 8), i => prefix_type + 'instruction.' + i),
                        procedure: _.map(_.range(1, 8), i => prefix_type + 'procedure.' + i)
                    }
                }

            return {
                unique: Math.random().toString(36).substr(2, 9),

                m: _.clone(this.maddox),
                r: _.cloneDeep(this.result),

                video: false,

                help: false,
                tips:  {
                    main: {
                        info: _.map(_.range(1, 3), i => prefix + 'info.' + i),
                        instruction: _.map(_.range(1, 4), i => prefix + 'instruction.' + i),
                        procedure: _.map(_.range(1, 6), i => prefix + 'procedure.' + i)
                    },
                    rmh: generate_tips('rmh'),
                    rmv: generate_tips('rmv'),
                    wmh: generate_tips('wmh'),
                    wmv: generate_tips('wmv')
                }
            }
        },
        mounted() {
            setTimeout(() => {
                OptometristsDisplay.message.open('/optometrist/screen/maddox')
            }, 1000)

            Maddox.reset(this.m)

            SurveyLogs.save(this.$state.params.id, this.unique, 'maddox', '', 'start', '50%',
                'Rozpoczęto test', [], {result: this.result, maddox: this.maddox})
        },
        methods: {
            help_selected(type) {
                if(this.help) {
                    this.help = false
                } else {
                    this.help = type
                }
            },
            video_selected(type) {
                if(this.video !== type) {
                    this.video = type

                    OptometristsDisplay.message.open('/optometrist/screen/maddox/help/' + type)
                } else {
                    this.video = false

                    OptometristsDisplay.message.open('/optometrist/screen/maddox')
                }
            },

            updateMaddox(field, value) {
                this.m[field] = value

                Maddox.compute(this.m, this.r)
            },
            changeSuppression(side) {
                if(side === 'r' && this.m.r_suppression) {
                    this.m.l_suppression = false
                } else if(side === 'l' && this.m.l_suppression) {
                    this.m.r_suppression = false
                }

                if(this.m.r_suppression || this.m.l_suppression) {
                    this.m.l_b = 0
                    this.m.l_h = false
                    this.m.l_l = 0
                    this.m.l_r = 0
                    this.m.l_t = 0
                    this.m.l_v = false

                    this.m.r_b = 0
                    this.m.r_h = false
                    this.m.r_l = 0
                    this.m.r_r = 0
                    this.m.r_t = 0
                    this.m.r_v = false
                }
            },

            displayClose() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')
            },
            confirmed() {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'maddox', '', 'end', '50%',
                    'Zakończono test', [], {result: this.r, maddox: this.m})

                this.$emit('confirmed', {result: this.r, maddox: this.m})
            },
            cancelled() {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'maddox', '', 'cancel', '50%',
                    'Anulowano test', [], {data: null})

                this.$emit('cancelled')
            }
        }
    }
</script>