<template lang="pug">
    .opti-select-product
        VueMultiSelect(
            id="adding"
            :options="data"
            :value="option"
            :clearable="false"
            label="key"
            placeholder="wprowadź wartość"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="adding"
            track-by="key"
            group-label="type"
            group-values="data"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            :disabled="!!disabled"

            @search-change="loadData"
            @input="d => $emit('selected', d)")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option.value }}
                small.pull-right(v-show="props.option.value !== props.option.key") {{ props.option.key }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{ props.option.value }}
                    small.pull-right(v-show="props.option.value !== props.option.key") {{ props.option.key }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectAdding',
        components: {
            VueMultiSelect
        },
        props: {
            options: {
                type: Array,
                required: true
            },
            option: {
                type: Object | null,
                required: true
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                data: [{
                    type: 'Nowy wpis',
                    data: []
                }, {
                    type: 'Zdefiniowane dane',
                    data: this.options
                }]
            }
        },
        methods: {
            loadData(value) {
                this.data[0].data = !value || this.options.filter(o => o.value === value).length ? [] : [{
                    key: value,
                    value: value
                }]

                this.data[1].data = this.options.filter(o => o.value.toLowerCase().includes(value.toLowerCase()))
            }
        }
    }
</script>

<style lang="less" scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
