<template lang="pug">
    OptiDialog
        template(#header)
            span
                i.fa.fa-edit.mr3
                span {{'catalog.product.correct.CORRECT'|translate}}
            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            form.form-horizontal(name="formCorrectWarehouse")
                .form-group
                    label.col-sm-5.control-label(for="stock")
                        span.mr3 {{'catalog.product.stock.STOCK'|translate}}
                    .col-sm-7
                        OptiNumber#stock.form-control(:header="'catalog.product.stock.STOCK'|translate" :minus="true"
                            v-model="formWarehouse.stock" @confirmed="v => formWarehouse.stock = v") {{formWarehouse.stock}}
                .form-group
                    label.col-sm-5.control-label(for="threshold")
                        span.mr3 {{'catalog.product.stock.THRESHOLD'|translate}}
                    .col-sm-7
                        OptiNumber#threshold.form-control(:header="'catalog.product.stock.THRESHOLD'|translate"
                            v-model="formWarehouse.threshold" @confirmed="t => formWarehouse.threshold = t") {{formWarehouse.threshold}}
                .form-group
                    .col-sm-7.col-sm-offset-5
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="inStock" v-model="formWarehouse.inStock")
                                    span.fa.fa-check
                            span {{'catalog.product.stock.INSTOCK'|translate}}
                .form-group
                    label.col-sm-5.control-label(for="reason") {{'catalog.product.correct.REASON'|translate}}
                    .col-sm-7
                        textarea#reason.form-control(v-model="formWarehouse.reason" name="reason" rows="3")
                        OptiValidate(f="reason" :data="$v.formWarehouse.reason")

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButtonSave(:disabled="loading || $v.formWarehouse.$invalid" @click.native="save")

</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '../../../../OptiDialog'
    import OptiNumber from '../../../../Inputs/OptiNumber'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave.vue'
    import OptiValidate from '@/js/app/vue/components/OptiValidate.vue'

    export default {
        name: 'OptiDialogProductCorrectWarehouse',
        components: {
            OptiValidate,
            OptiButtonSave,
            OptiDimmer,
            OptiNumber,
            OptiDialog
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                formWarehouse: {
                    stock: 0,
                    threshold: 0,
                    inStock: false,
                    reason: ''
                },
                loading: false
            }
        },
        validations: {
            formWarehouse: {
                reason: {
                    required
                }
            }
        },
        methods: {
            save() {
                this.loading = true

                API.post('catalog/product/warehouse/correct', {
                    stock: this.formWarehouse.stock,
                    threshold: this.formWarehouse.threshold,
                    inStock: this.formWarehouse.inStock,
                    reason: this.formWarehouse.reason,
                    product_id: this.product.id
                }).then(() => {
                    this.$notify.success('catalog.product.notify.SAVECORRECT')

                    this.$emit('reported', this.formWarehouse.stock)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.product.notify.error.SAVECORRECT')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 400px;
    }
</style>