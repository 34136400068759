<template lang="pug">
    .row
        .col-xs-12(v-if="requests && !generatedView")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'cards.NEW'|translate}}
                table.table.table-striped.table-hover.middle
                    thead
                        tr
                            th.text-center
                                .checkbox.c-checkbox.checkbox-info.inline
                                    label
                                        input(type="checkbox" @change="selectAll" v-model="allSelected")
                                        span.fa.fa-check
                            th #
                            th {{'cards.table.REQUEST_ID'|translate}}
                            th {{'cards.table.SALON'|translate}}
                            th {{'cards.table.CREATED'|translate}}
                            th {{'cards.table.COMMENTS'|translate}}
                            th {{'cards.table.CARD_TYPE'|translate}}
                            th {{'cards.table.CARD_ID'|translate}}
                            th {{'cards.table.PATIENT'|translate}}
                    tbody
                        tr(v-for="(request, index) in requests" v-if="request.status === 'new'")
                            td.text-center
                                .checkbox.c-checkbox.checkbox-info.inline
                                    label
                                        input(type="checkbox" v-model="request.selected"
                                                @click="allSelected = false"
                                            )
                                        span.fa.fa-check
                            td {{index + 1}}
                            td {{request.id}}
                            td {{request.salonName}}
                            td {{request.created}}
                            td {{request.comments}}
                            td {{request.cardType}}
                            td
                                a.link(:title="'register.return.sale.table.PATIENTDETAILS'|translate"
                                    :href="$state.href('app.patient.detail', {id: request.patientId})"
                                ) {{request.patientId}}
                            td
                                a.link(:title="'register.return.sale.table.PATIENTDETAILS'|translate"
                                    :href="$state.href('app.patient.detail', {id: request.patientId})"
                                )
                                    span.block {{request.patientName}}
                                    span.block {{request.patientPostal}} {{request.patientCity}}
                                    span.block {{request.patientStreet}}
                                    span.block tel. {{request.patientPhone}}

                .panel-footer.clearfix
                    OptiButton.pull-right(
                        type="success"
                        @click.native="generateCards"
                        ) {{'cards.table.GENERATE'|translate}}
                            template(#icon)
                                i.fa.fa-file

        .col-xs-12(v-if="generatedView")
            OptiCardsGeneratedView(
                :printed="false"
                :selected="selected"
                @save-changes="saveCard"
                )

</template>

<script>
import {API} from '@/js/app/vue/api'
import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiCardsGeneratedView from '@/js/app/vue/components/Patients/LoyalityCard/OptiCardsGeneratedView'

export default {
    name: 'OptiNewRequests',
    components: {OptiCardsGeneratedView, OptiButton},
    data() {
        return {
            allSelected: false,
            requests: null,
            generatedView: false,
            selected: []
        }
    },
    mounted() {
        this.startUp()
    },
    methods: {
        startUp(){
            API.get('loyality-card/getNewRequests').then((res) => {
                this.requests = res.data
            }).then(() => {
                this.requests.forEach( item => {
                    item.selected = false
                })
            })
        },
        saveCard(selected) {
            API.post('loyality-card/saveChanges', {
                selected: selected
            }).then(() => {
                this.selected = []
                this.generatedView = false
                this.startUp()
            }).finally(() => {
                this.$emit('card-saved')
            }).catch(() => {
                this.$notify.error('cards.notify.ERROR')
            })
        },
        generateCards() {
            this.selected = []
            let selectedIds = ''
            this.requests.forEach( item => {
                if(item.selected === true) {
                    this.selected.push(item)
                    selectedIds = selectedIds + item.id +','
                }
            })
            if(this.selected.length === 0) {
                this.$notify.error('cards.notify.NO_SELECTION')
            }
            if(this.selected.length > 0) {
                API.post('loyality-card/saveList', {
                    selectedIds: selectedIds
                }).finally(() => {
                    this.generatedView = true

                })
            }
        },
        selectAll() {
            if(this.allSelected) {
                this.requests.forEach(item => {
                    item.selected = true
                })
            }
            if(!this.allSelected) {
                this.requests.forEach(item => {
                    item.selected = false
                })
            }
        }
    }
}
</script>

<style scoped>
    .block {
        display:block;
    }
</style>