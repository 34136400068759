<template lang="pug">
    .row
        .text-center.fa-lg(style="padding:15px 0" v-if="isCompare")
            i.fa.fa-exclamation-circle.mr3
            span {{'tasks.lenses.notify.info.LENSES_TO_COMPARE'|translate}}

        OptiTaskDetailsLensesAssembly(v-else :task="task")

        OptiTaskDetailsLensesMain(
            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"

            :block="block"
            :pending="pending"

            @go-to-page="p => $emit('go-to-page', p)"
            @remove-lenses="removeLenses"
            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesFrameOverwrite(v-if="!isCompare"
            :frame="task.frame"
            :shape="shape"

            :block="block"

            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesOma(
            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"
            :increment="increment"

            :block="block"

            @pending="p => $emit('pending', p)"
            @increment-up="$emit('increment-up')"
            @register-change="$emit('register-change')"
            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesChangeShape(
            :task="task"
            :is-compare="isCompare"

            :shape="shape"

            :block="block"

            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesHoya(
            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"

            :allow-edit-confirmed="allowEditConfirmed"

            @register-change="$emit('register-change')"
            @lenses-was-updated="$emit('lenses-was-updated')")
</template>

<script>
    import {TaskHoyaHelper} from '@/js/app/vue/helpers/TaskHoyaHelper'

    import OptiTaskDetailsLensesAssembly
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesAssembly'
    import OptiTaskDetailsLensesMain from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesMain'
    import OptiTaskDetailsLensesFrameOverwrite
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesFrameOverwrite'
    import OptiTaskDetailsLensesOma from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesOma'
    import OptiTaskDetailsLensesChangeShape
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesChangeShape'
    import OptiTaskDetailsLensesHoya from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesHoya'

    export default {
        name: 'OptiTaskDetailsLensesPageSummary',
        components: {
            OptiTaskDetailsLensesAssembly,
            OptiTaskDetailsLensesMain,
            OptiTaskDetailsLensesFrameOverwrite,
            OptiTaskDetailsLensesOma,
            OptiTaskDetailsLensesChangeShape,
            OptiTaskDetailsLensesHoya
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },

            shape: {
                type: Object,
                required: true
            },
            increment: {
                type: Number,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            removeLenses() {
                ['right', 'left'].forEach(side => {
                    let field = side + 'Lens' + (this.isCompare ? '2' : '')

                    this.task[field].productId = 0
                    this.task[field].partName = 'soczewka'
                    this.task[field].manufacturer = 'klienta'
                    this.task[field].type = ''
                    this.task[field].price = 0
                })

                let field = this.isCompare ? '_2' : '';

                if(this.task['manufacturerParam1Lens' + field]) {
                    this.task['manufacturerParam1' + field] = ''
                    this.task['manufacturerParam1Lens' + field] = ''
                }

                TaskHoyaHelper.setTaskExtras(this.isCompare, {errors: [], warnings: []}, this.task)

                this.$emit('lenses-was-updated')
            }
        }
    }
</script>
