<template lang="pug">
    div
        OptiButton(style="position:relative" @click.native="dialog = true"
            ) {{'catalog.offer.edit_portrait.BTN'|translate}}
            template(#icon)
                i.fa.fa-edit

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiCatalogOfferDialogPortraitsEdit(
                :offer="offer"

                @reload="$emit('reload')"
                @close="dialog = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferDialogPortraitsEdit
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogPortraitsEdit'

    export default {
        name: 'OptiCatalogOfferBtnPortraitsEdit',
        components: {
            OptiButton,
            OptiCatalogOfferDialogPortraitsEdit
        },
        props: {
            offer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>