<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-indent.mr3
            span {{'regeneration.breadcrumb.REGENERATION'|translate}} Hoya iLog - {{'regeneration.iLog.eye.' + side + '.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .row.tables-compare.text-expressive(ref="tablesCompare" v-if="!loading")
                .col-xs-12(v-show="error.iLog")
                    .alert.alert-warning(style="margin-bottom:10px")
                        i.fa.fa-times.close.pull-right(@click="error.iLog = ''")

                        i.fa.fa-exclamation-circle.mr3
                        span {{error.iLog}}

                .col-xs-12(v-show="error.no_new_catalog")
                    .alert.alert-warning(style="margin-bottom:10px")
                        i.fa.fa-times.close.pull-right(@click="error.no_new_catalog = false")

                        i.fa.fa-exclamation-circle.mr3
                        span {{'regeneration.iLog.notify.OLD_CATALOG'|translate}}

                .col-xs-12.text-center(v-show="order_id")
                    i.fa.fa-exclamation-circle.mr3
                    span.mr3 {{'regeneration.iLog.notify.NUMBER'|translate}}
                    a.link(:title="'default.REFERENCETYPE'|translate"
                        :href="'https://pl.hoyailog.com/order/' + order_id + '#orderSummary'" target="_blank") {{order_id}}
                    span.mr3 .
                    span {{'regeneration.iLog.notify.TIP_REFRESH'|translate}}

                .col-xs-12.text-center(style="margin-top:5px" v-if="ilog.right_lens_to_calculate")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.admin.compareilog.calculate.RIGHT'|translate}}

                .col-xs-12.text-center(style="margin-top:5px" v-if="ilog.left_lens_to_calculate")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.admin.compareilog.calculate.LEFT'|translate}}

                .col-xs-12.no-ilog(v-if="forbiddenInputs")
                    .alert.alert-danger.text-center(style="margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'tasks.admin.compareilog.WRONGILOG'|translate}}
                        span(v-if="ilog.checked_grindery") "Wybrano szlifowanie" 
                        span(v-if="ilog.prism_error") "Pryzma horyzontalna/wertykalna" 
                        span(v-if="ilog.checked_slab_off") "Slab off is selected" 
                        span(v-if="ilog.patient_name") "Imię pacjenta" 
                        span(v-if="ilog.patient_sex") "Płeć pacjenta" 
                        span(v-if="ilog.patient_surname") "Nazwisko pacjenta" 
                        span(v-if="ilog.date_of_birth") "Data urodzenia pacjenta" 
                        span(v-if="ilog.weight_compensation") "Wyrównanie ciężaru" 

                .col-xs-4
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                            tr
                                th.text-center(colspan="3") {{'tasks.RIGHT'|translate}} {{'tasks.EYE'|translate}}
                        tbody
                            tr(:class="{'bg-warning': task.rightEye.sphere !== ilog.sphere_right}")
                                td.text-right {{'tasks.admin.compareilog.SPHERE'|translate}}
                                td.text-right {{task.rightEye.sphere|plus}}
                                td.text-right {{ilog.sphere_right|plus}}
                            tr(:class="{'bg-warning': task.rightEye.cylinder !== ilog.cylinder_right}")
                                td.text-right {{'tasks.admin.compareilog.CYLINDER'|translate}}
                                td.text-right {{task.rightEye.cylinder|plus}}
                                td.text-right {{ilog.cylinder_right|plus}}
                            tr(:class="{'bg-warning': task.rightEye.axis !== ilog.axis_right}")
                                td.text-right {{'tasks.admin.compareilog.AXIS'|translate}}
                                td.text-right {{task.rightEye.axis}}
                                td.text-right {{ilog.axis_right}}
                            tr(:class="{'bg-warning': task.rightEye.addition !== ilog.addition_right}"
                                v-if="task.rightEye.addition && ilog.addition_right")
                                td.text-right {{'tasks.admin.compareilog.ADDITION'|translate}}
                                td.text-right {{task.rightEye.addition|plus}}
                                td.text-right {{ilog.addition_right|plus}}
                            tr(:class="{'bg-warning': rightBt !== ilog.bt_right}")
                                td.text-right
                                    span(v-if="task.type !== 3") BT
                                    span(v-if="task.type === 3") {{'tasks.admin.compareilog.BTBIFOCAL'|translate}}
                                td.text-right {{rightBt|number}}
                                td.text-right {{ilog.bt_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.pdDist !== ilog.pd_far_right}"
                                    v-if="task.type !== 1")
                                td.text-right {{'tasks.admin.compareilog.PDDIST'|translate}}
                                td.text-right {{task.rightEye.pdDist|number}}
                                td.text-right {{ilog.pd_far_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.pdNear !== ilog.pd_far_right}"
                                    v-if="task.type === 1")
                                td.text-right {{'tasks.admin.compareilog.PDNEAR'|translate}}
                                td.text-right {{task.rightEye.pdNear|number}}
                                td.text-right {{ilog.pd_far_right|number}}
                            tr(:class="{'bg-warning': task.rightEye.prism !== ilog.angle_right}" v-if="task.rightEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISM'|translate}}
                                td.text-right {{task.rightEye.prism|number(2)}}
                                td.text-right {{ilog.angle_right|number(2)}}
                            tr(:class="{'bg-warning': task.rightEye.prismAngle !== ilog.base_right}" v-if="task.rightEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISMANGLE'|translate}}
                                td.text-right {{task.rightEye.prismAngle}}
                                td.text-right {{ilog.base_right}}
                        tbody(v-if="!task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.PRODUCTION'|translate}}
                            tr(:class="{'bg-warning':  cvdWarning}" v-if="task.cvd || task.hoyaLens.cvd")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvd|number}}
                                td.text-right {{task.hoyaLens.cvd|number}} (Δ{{task.hoyaLens.cvd_delta|number}})
                            tr(:class="{'bg-warning': paWarning || task.inclination < 0 || task.hoyaLens.pa < 0}")
                                td.text-right {{'tasks.admin.compareilog.INCLINATION'|translate}}
                                td.text-right {{task.inclination|number}}
                                td.text-right {{task.hoyaLens.pa|number}} (Δ{{task.hoyaLens.pa_delta|number}})
                            tr(:class="{'bg-warning': fffaWarning || task.fffa < 0 || task.hoyaLens.fffa < 0}")
                                td.text-right {{'tasks.admin.compareilog.FFFA'|translate}}
                                td.text-right {{task.fffa|number}}
                                td.text-right {{task.hoyaLens.fffa|number}} (Δ{{task.hoyaLens.fffa_delta|number}})
                        tbody(v-if="task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.INDIVIDUAL'|translate}}
                            tr(:class="{'bg-warning':  task.cvd !== ilog.cvd_right}" v-if="task.cvd || ilog.cvd_right")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvd|number}}
                                td.text-right {{ilog.cvd_right|number}}
                            tr(:class="{'bg-warning': (task.inclination !== ilog.inclination) || task.inclination < 0 || ilog.inclination < 0}")
                                td.text-right {{'tasks.admin.compareilog.INCLINATION'|translate}}
                                td.text-right {{task.inclination|number}}
                                td.text-right {{ilog.inclination|number}}
                            tr(:class="{'bg-warning': (task.fffa !== ilog.fffa) || task.fffa < 0 || ilog.fffa < 0}")
                                td.text-right {{'tasks.admin.compareilog.FFFA'|translate}}
                                td.text-right {{task.fffa|number}}
                                td.text-right {{ilog.fffa|number}}

                .col-xs-4
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                            tr
                                th.text-center(colspan="3") {{'tasks.LEFT'|translate}} {{'tasks.EYE'|translate}}
                        tbody
                            tr(:class="{'bg-warning': task.leftEye.sphere !== ilog.sphere_left}")
                                td.text-right {{'tasks.admin.compareilog.SPHERE'|translate}}
                                td.text-right {{task.leftEye.sphere|plus}}
                                td.text-right {{ilog.sphere_left|plus}}
                            tr(:class="{'bg-warning': task.leftEye.cylinder !== ilog.cylinder_left}")
                                td.text-right {{'tasks.admin.compareilog.CYLINDER'|translate}}
                                td.text-right {{task.leftEye.cylinder|plus}}
                                td.text-right {{ilog.cylinder_left|plus}}
                            tr(:class="{'bg-warning': task.leftEye.axis !== ilog.axis_left}")
                                td.text-right {{'tasks.admin.compareilog.AXIS'|translate}}
                                td.text-right {{task.leftEye.axis}}
                                td.text-right {{ilog.axis_left}}
                            tr(:class="{'bg-warning': task.leftEye.addition !== ilog.addition_left}"
                                v-if="task.leftEye.addition && ilog.addition_left")
                                td.text-right {{'tasks.admin.compareilog.ADDITION'|translate}}
                                td.text-right {{task.leftEye.addition|plus}}
                                td.text-right {{ilog.addition_left|plus}}
                            tr(:class="{'bg-warning': leftBt !== ilog.bt_left}")
                                td.text-right
                                    span(v-if="task.type !== 3") BT
                                    span(v-if="task.type === 3") {{'tasks.admin.compareilog.BTBIFOCAL'|translate}}
                                td.text-right {{leftBt|number}}
                                td.text-right {{ilog.bt_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.pdDist !== ilog.pd_far_left}" v-if="task.type !== 1")
                                td.text-right {{'tasks.admin.compareilog.PDDIST'|translate}}
                                td.text-right {{task.leftEye.pdDist|number}}
                                td.text-right {{ilog.pd_far_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.pdNear !== ilog.pd_far_left}" v-if="task.type === 1")
                                td.text-right {{'tasks.admin.compareilog.PDNEAR'|translate}}
                                td.text-right {{task.leftEye.pdNear|number}}
                                td.text-right {{ilog.pd_far_left|number}}
                            tr(:class="{'bg-warning': task.leftEye.prism !== ilog.angle_left}"
                                v-if="task.leftEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISM'|translate}}
                                td.text-right {{task.leftEye.prism|number(2)}}
                                td.text-right {{ilog.angle_left|number(2)}}
                            tr(:class="{'bg-warning': task.leftEye.prismAngle !== ilog.base_left}" v-if="task.leftEye.prism")
                                td.text-right {{'tasks.admin.compareilog.PRISMANGLE'|translate}}
                                td.text-right {{task.leftEye.prismAngle}}
                                td.text-right {{ilog.base_left}}
                        tbody(v-if="task.hoyaLens.individual")
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.INDIVIDUAL'|translate}}
                            tr(:class="{'bg-warning': task.cvd !== ilog.cvd_left}" v-if="task.cvd || ilog.cvd_left")
                                td.text-right {{'tasks.admin.compareilog.CVD'|translate}}
                                td.text-right {{task.cvd|number}}
                                td.text-right {{ilog.cvd_left|number}}
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.MEASURMENT'|translate}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.minThickness !== ilog.ilogMeasurments.right.minThickness) || (ilog.optiMeasurments.left.minThickness !== ilog.ilogMeasurments.left.minThickness)}")
                                td.text-right {{'tasks.admin.compareilog.MINTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.minThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.minThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.minThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.minThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.minThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.minThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.minThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.minThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.minThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.minThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.minThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.minThickness|number}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.maxThickness !== ilog.ilogMeasurments.right.maxThickness) || (ilog.optiMeasurments.left.maxThickness !== ilog.ilogMeasurments.left.maxThickness)}")
                                td.text-right {{'tasks.admin.compareilog.MAXTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.maxThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.maxThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.maxThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.maxThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.maxThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.maxThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.maxThickness === '' ") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.maxThickness !== '' ") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.maxThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.maxThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.maxThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.maxThickness|number}}
                            tr(:class="{'bg-warning': (ilog.optiMeasurments.right.centerThickness !== ilog.ilogMeasurments.right.centerThickness) || (ilog.optiMeasurments.left.centerThickness !== ilog.ilogMeasurments.left.centerThickness)}")
                                td.text-right {{'tasks.admin.compareilog.CENTERTHICK'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.centerThickness === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.centerThickness !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.optiMeasurments.right.centerThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.centerThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.centerThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.optiMeasurments.left.centerThickness|number}}
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.centerThickness ===''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.centerThickness !==''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.centerThickness|number}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.centerThickness === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.centerThickness !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.centerThickness|number}}
                            tr
                                td.text-right {{'tasks.admin.compareilog.FCRV'|translate}}
                                td.text-right
                                    span(v-if="ilog.optiMeasurments.right.fcrv === '-'") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.right.fcrv !== '-'") {{'prescription.RIGHT'|translate}}: ~ {{ilog.optiMeasurments.right.fcrv|number}}
                                    br
                                    |
                                    span(v-if="ilog.optiMeasurments.left.fcrv === '-'") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.optiMeasurments.left.fcrv !== '-'") {{'prescription.LEFT'|translate}}: ~ {{ilog.optiMeasurments.left.fcrv|number}}
                                td.text-right
                            tr
                                td.text-right {{'tasks.admin.compareilog.BC'|translate}}
                                td.text-right
                                td.text-right
                                    span(v-if="ilog.ilogMeasurments.right.bc === ''") {{'prescription.RIGHT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.right.bc !== ''") {{'prescription.RIGHT'|translate}}: {{ilog.ilogMeasurments.right.bc}}
                                    br
                                    |
                                    span(v-if="ilog.ilogMeasurments.left.bc === ''") {{'prescription.LEFT'|translate}}: brak
                                    span(v-if="ilog.ilogMeasurments.left.bc !== ''") {{'prescription.LEFT'|translate}}: {{ilog.ilogMeasurments.left.bc}}

                .col-xs-4.replace-to-col-xs-12
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-right
                                th.text-right Opti
                                th.text-right iLog
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.ORDER'|translate}}
                            tr(:class="{'bg-warning': optiMets !== ilogMets}")
                                td.text-right METS?
                                td.text-right {{optiMets}}
                                td.text-right {{ilogMets}}
                            tr(style="border-top:0" v-if="progression || ilog.progression" :class="{'bg-warning': !progression_design_exceptions.includes(ilog.design_right) && (progression !== ilog.progression)}")
                                td.text-right
                                    span {{'tasks.admin.compareilog.PROGRESSION'|translate}}
                                    span.warn(style="margin-left:3px" v-if="progression_design_exceptions.includes(ilog.design_right)") *
                                td.text-right {{progression}}
                                td.text-right
                                    span(v-if="ilog.progression") {{ilog.progression}}
                                    span(v-else) brak danych
                            tr.text-right.warn(v-if="progression_design_exceptions.includes(ilog.design_right)")
                                td(style="border-top:0" colspan="3") {{'tasks.admin.compareilog.notify.warning.PROGRESSION_EXCEPTION'|translate}}
                        tbody(v-if="ilog.near_distance || ilog.measurment_methods || task.hoyaLens.construction_params")
                            tr(:class="{'bg-warning': ilog.near_distance !== measuredNearDistance}")
                                td.text-right {{'tasks.admin.compareilog.NEARDIST'|translate}}
                                td.text-right {{measuredNearDistance}} cm
                                td.text-right
                                    span(v-if="ilog.near_distance") {{ilog.near_distance}} cm
                                    span(v-else) brak danych
                            tr(:class="{'bg-warning': ilog.measurment_methods !== 'Pomiar foropterem'}")
                                td.text-right {{'tasks.admin.compareilog.MEASMETHOD'|translate}}
                                td.text-right Pomiar foropterem
                                td.text-right
                                    span(v-if="ilog.measurment_methods") {{ilog.measurment_methods}}
                                    span(v-else) brak danych
                        tbody
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.admin.compareilog.LENS'|translate}}
                            tr(:class="{'bg-warning': task.hoyaLens.iLog_design !== ilog.design_right || task.hoyaLens.iLog_design !== ilog.design_left}")
                                td.text-right {{'tasks.admin.compareilog.DESIGN'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.hoyaLens.iLog_design}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.hoyaLens.iLog_design}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.design_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.design_left}}
                            tr(:class="{'bg-warning': task.hoyaLens.iLog_material !== ilog.material_right || task.hoyaLens.iLog_material !== ilog.material_left}")
                                td.text-right {{'tasks.admin.compareilog.MATERIAL'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.hoyaLens.iLog_material}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.hoyaLens.iLog_material}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.material_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.material_left}}
                            tr(:class="{'bg-warning': coatingWarning}")
                                td.text-right {{'tasks.admin.compareilog.COATING'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{coating}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{coating}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.coating_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.coating_left}}
                            tr(:class="{'bg-warning': color !== ilog.color_left}" v-if="color || ilog.color_left !== '-'")
                                td.text-right {{'tasks.admin.compareilog.COLOR'|translate}}
                                td.text-right {{color}}
                                td.text-right {{ilog.color_left}}
                            tr(:class="{'bg-warning': color_gradient !== ilog.gradation_color}" v-if="color_gradient || ilog.gradation_color")
                                td.text-right {{'catalog.lenses.properties.heading.gradient_type'|translate}}
                                td.text-right {{color_gradient}}
                                td.text-right {{ilog.gradation_color}}
                            tr(:class="{'bg-warning': (optiMinimalThicknessRight !== ilog.thickness_right) || (optiMinimalThicknessLeft !== ilog.thickness_left)}")
                                td.text-right {{'tasks.admin.compareilog.THICKNESS'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{optiMinimalThicknessRight|number(1)}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{optiMinimalThicknessLeft|number(1)}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.thickness_right|number(1)}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.thickness_left|number(1)}}
                            tr(:class="{'bg-warning': (task.rightLens.minFi !== ilog.diameter_right) || (task.leftLens.minFi !== ilog.diameter_left)}")
                                td.text-right {{'tasks.admin.compareilog.DIAMETER'|translate}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{task.rightLens.minFi}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{task.leftLens.minFi}}
                                td.text-right {{'prescription.RIGHT'|translate}}: {{ilog.diameter_right}}
                                    br
                                    | {{'prescription.LEFT'|translate}}: {{ilog.diameter_left}}
                            tr
                                td.text-bold.text-center(colspan="3") {{'tasks.FRAME'|translate}}
                            tr(:class="{'bg-warning': rfwWarning}")
                                td.text-right {{'tasks.frame.WIDTH'|translate}} {{'tasks.admin.compareilog.RIGHT'|translate}}
                                td.text-right {{frameWidth|number}}
                                td.text-right {{ilog.frame_width_right|number}}
                            tr(:class="{'bg-warning': rfhWarning}")
                                td.text-right {{'tasks.frame.HEIGHT'|translate}} {{'tasks.admin.compareilog.RIGHT'|translate}}
                                td.text-right {{frameHeight|number}}
                                td.text-right {{ilog.frame_height_right|number}}
                            tr(:class="{'bg-warning': lfwWarning}")
                                td.text-right {{'tasks.frame.WIDTH'|translate}} {{'tasks.admin.compareilog.LEFT'|translate}}
                                td.text-right {{frameWidth|number}}
                                td.text-right {{ilog.frame_width_left|number}}
                            tr(:class="{'bg-warning': lfhWarning}")
                                td.text-right {{'tasks.frame.HEIGHT'|translate}} {{'tasks.admin.compareilog.LEFT'|translate}}
                                td.text-right {{frameHeight|number}}
                                td.text-right {{ilog.frame_height_left|number}}
                            tr(:class="{'bg-warning': dblWarning }")
                                td.text-right {{'tasks.frame.BRIDGE'|translate}}
                                td.text-right {{task.dbl|number}}
                                td.text-right {{ilog.dbl|number}}
                            tr(:class="{'bg-warning': true}"
                                v-if="ilog.decentrationR || ilog.decentrationL")
                                td.text-right {{'tasks.admin.compareilog.DECENTRATION'|translate}}
                                td
                                td.text-right
                                    span {{'prescription.RIGHT'|translate}}: {{ilog.decentrationR}}
                                    br
                                    |
                                    span {{'prescription.LEFT'|translate}}: {{ilog.decentrationL}}

        template(#footer)
            .row
                .col-xs-6.text-left
                    OptiButton(type="primary" @click.native="goRefresh") {{'regeneration.iLog.REFRESH'|translate}}
                        template(#icon)
                            i.fa.fa-refresh

                .col-xs-6
                    OptiButton(type="success" :disabled="!ilog[(side === 'right' ? 'left' : 'right') + '_lens_to_calculate']"
                        @click.native="goConfirm") {{'regeneration.iLog.eye.' + side + '.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRegenerationDetailsHoyaiLogDialog',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer
        },
        props: {
            regenerationId: {
                type: Number,
                required: true
            },
            taskId: {
                type: Number,
                required: true
            },
            side: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                optiMinimalThicknessRight: '',
                optiMinimalThicknessLeft: '',
                optiMets: '',

                ilog: {},
                ilogMets: '',

                task: {},
                order_id: 0,

                coating: '',
                ppf: '',
                color: '',
                color_gradient: '',

                progression_design_exceptions: ['EnRoute', 'EnRoute Pro'],
                //taka sama tablica jest w HoyaEDIFromTaskHelper::getProgressionLength
                progression: 0,

                lfwWarning: false,
                lfhWarning: false,
                rfwWarning: false,
                rfhWarning: false,

                cvdWarning: false,
                paWarning: false,
                fffaWarning: false,

                dblWarning: false,

                coatingWarning: false,

                forbiddenInputs: false,

                frameHeight: '',
                frameWidth: '',

                rightBt: '',
                leftBt: '',

                error: {
                    iLog: '',
                    no_new_catalog: false
                },
                loading: true
            }
        },
        mounted() {
            API.post('regenerations/hoya/generate', {
                regenerationId: this.regenerationId,
                taskId: this.taskId,
                side: this.side
            }).then(res => {
                if(res.data.error) {
                    this.error.iLog = res.data.error
                }

                if(res.data.order_id) {
                    this.order_id = res.data.order_id
                }

                if(res.data.iLog) {
                    this.ilog = res.data.iLog
                    this.task = res.data.task

                    if(!this.task.hoya_lens_id) {
                        this.error.no_new_catalog = true
                    }

                    this.parseData()
                } else {
                    this.$notify.error('regeneration.iLog.notify.error.GENERATE')

                    if(res.data.error) {
                        this.$notify.error(res.data.error, false, true)
                    }

                    this.$emit('close')
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('regeneration.iLog.notify.error.GENERATE')

                this.$emit('close')
            })
        },
        methods: {
            goRefresh() {
                this.loading = true

                this.ilog = {}

                API.get('regenerations/hoya/refresh/' + this.task.id + '/' + this.order_id).then(res => {
                    this.ilog = res.data

                    this.parseData()
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('regeneration.iLog.notify.error.LOAD')
                })
            },
            goConfirm() {
                this.loading = true

                API.post('regenerations/hoya/confirm', {
                    regeneration_id: this.regenerationId,
                    order_id: this.order_id,
                    side: this.side,
                    iLog: this.ilog,
                    html_table: this.parseTable()
                }).then(() => {
                    this.$notify.success('regeneration.iLog.notify.CONFIRMED')

                    this.$emit('load')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('regeneration.iLog.notify.error.CONFIRMED')

                    this.$emit('close')
                })
            },

            parseData() {
                this.getSection()

                this.optiMinimalThicknessRight = this.checkThickness(1)
                this.optiMinimalThicknessLeft = this.checkThickness(2)

                this.ilogMets = this.checkMets(this.ilog.mets)
                this.optiMets = this.checkMets(this.task.hoyaLens.lens_type === 'RX')

                this.frameHeight = this.task.frame.height + this.task.heightAdditionBottom + this.task.heightAdditionTop
                this.frameWidth = this.task.frame.width + this.task.widthNasal + this.task.widthTemporal

                this.rightBt = this.task.rightEye.bt + this.task.heightAdditionBottom
                this.leftBt = this.task.leftEye.bt + this.task.heightAdditionBottom

                this.rfhWarning = this.checkFrame(this.frameHeight, this.ilog.frame_height_left)
                this.rfwWarning = this.checkFrame(this.frameWidth, this.ilog.frame_width_left)
                this.lfhWarning = this.checkFrame(this.frameHeight, this.ilog.frame_height_right)
                this.lfwWarning = this.checkFrame(this.frameWidth, this.ilog.frame_width_right)

                this.dblWarning = this.checkFrame(this.task.dbl, this.ilog.dbl)

                this.forbiddenInputs = this.checkInputs(this.ilog.checked_grindery,this.ilog.patient_name, this.ilog.patient_sex,
                    this.ilog.patient_surname, this.ilog.date_of_birth,this.ilog.checked_slab_off, this.ilog.prism_error,
                    this.ilog.weight_compensation)

                this.cvdWarning = this.checkParams(this.task.cvd, this.task.hoyaLens.cvd, this.task.hoyaLens.cvd_delta)
                this.paWarning = this.checkParams(this.task.inclination, this.task.hoyaLens.pa, this.task.hoyaLens.pa_delta)
                this.fffaWarning = this.checkParams(this.task.fffa, this.task.hoyaLens.fffa, this.task.hoyaLens.fffa_delta)

                this.coatingWarning = this.checkCoating(this.coating, this.ilog.coating_right, this.coating, this.ilog.coating_left)
            },

            getSection() {
                this.task.hoyaOptions.forEach(option => {
                    if((option.section.startsWith('coatings') || option.section.startsWith('mirror')) &&
                        !this.task.hoyaLens.name_long.includes(option.name_long)) {
                        this.coating = option.ilog_code.includes(',') ? option.ilog_code.split(',')[0] : option.ilog_code
                    } else if(option.section.startsWith('coloration')) {
                        this.color = option.name_iLog
                    } else if(option.section === 'gradient_type') {
                        this.color_gradient = option.name_iLog
                    } else if(option.section.startsWith('polarized') || option.section.startsWith('sensity')) {
                        this.ppf = option.name_iLog
                    } else if(option.section === 'progression_length') {
                        this.progression = parseInt(option.name_long)
                    }
                })
            },
            checkThickness() {
                let obj = {},
                    lens = this.task.hoyaLens

                if((!lens.minimal_thickness && lens.lens_type === 'RX') ||
                    (!lens.minimal_thickness && lens.lens_type === 'ST') ||
                    (!lens.minimal_thickness && lens.lens_type === 'ST/RX')) {
                    switch(this.task.frame.type) {
                        case 'Nylon':
                        case 'TwoPoint':
                            obj = 2
                            break

                        case 'Plastic':
                        case 'Metal':
                            obj = 1.1
                            break

                        default:
                            obj = 0
                    }
                } else if((lens.minimal_thickness && lens.lens_type === 'RX') ||
                    (lens.minimal_thickness && lens.lens_type === 'ST') ||
                    (lens.minimal_thickness && lens.lens_type === 'ST/RX')) {
                    obj = lens.minimal_thickness
                } else {
                    obj = 'brak'
                }

                return obj
            },
            checkMaterial(optiR, ilogR, optiL, ilogL) {
                if((optiR === 'HILUX 1.50' && ilogR.material_right === '1.50') || (optiL === 'HILUX 1.50' && ilogL.material_left === '1.50')){
                    return false
                } else if((optiR === 'EYAS 1.60' && ilogR.material_right === '1.60') || (optiL === 'EYAS 1.60' && ilogL.material_left === '1.60')){
                    return false
                } else if((optiR === 'EYNOA 1.67' && ilogR.material_right === '1.70') || (optiL === 'EYNOA 1.67' && ilogL.material_left === '1.70')){
                    return false
                } else if((optiR === 'EYVIA 1.74' && ilogR.material_right === '1.74') || (optiL === 'EYVIA 1.74' && ilogL.material_left === '1.74')) {
                    return false
                } else return ((optiR !== ilogR.material_right) || (optiL !== ilogL.material_left))
            },
            checkInputs(q, w, e, r, t, y, u, i) {
                return q || w || e || r || t || y || u || i
            },
            checkMets(check) {
                return check ? 'Wybrano METS' : 'Nie wybrano METS'
            },
            checkFrame(opti, ilog) {
                return !((opti >= ilog - 0.3) && (opti <= ilog + 0.3))
            },
            checkParams(opti, ilog, x) {
                return !((parseFloat(opti) >= parseFloat(ilog) - parseFloat(x)) && (parseFloat(opti) <= parseFloat(ilog) + parseFloat(x)))
            },
            checkDesign(optiR, ilogR, optiL, ilogL) {
                  return (optiR.toLowerCase() !== ilogR.toLowerCase()) || (optiL.toLowerCase() !== ilogL.toLowerCase())
            },
            checkCoating(optiR, ilogR, optiL, ilogL) {
                //to samo jest w HoyaEDIHelper self::$coating
                if((optiR === 'HVLBC' && ilogR === 'LBC') || (optiL === 'HVLBC' && ilogL === 'LBC')) {
                    return false
                } else if((optiR === 'HVSPC' && ilogR === 'HV SUN PRO (color)') || (optiL === 'HVSPC' && ilogL === 'HV SUN PRO (color)')) {
                    return false
                } else if((optiR === 'HVSPP' && ilogR === 'HV SUN PRO (polarized)') || (optiL === 'HVSPP' && ilogL === 'HV SUN PRO (polarized)')) {
                    return false
                } else if((optiR === 'HVA-R' && ilogR === 'HVC') || (optiL === 'HVA-R' && ilogL === 'HVC')) {
                    return false
                } else if((optiR === 'HVLUVC' && ilogR === 'UVL') || (optiL === 'HVLUVC' && ilogL === 'UVL')) {
                    return false
                } else if((!optiR || optiR === '-') && (!optiL || optiL === '-') && (!ilogR || ilogR === '-') && (!ilogL || ilogL === '-')) {
                    return false
                }

                return (optiR !== ilogR) || (optiL !== ilogL)
            },

            parseTable() {
                return this.$refs.tablesCompare.outerHTML
                    .replace('col-xs-4 replace-to-col-xs-12', 'col-xs-12')
                    .replaceAll('col-xs-4', 'col-lg-6')
            }
        },
        computed: {
            measuredNearDistance() {
                if(this.task.manufacturerParam1 && this.task.manufacturerParam1.split('-').length - 1 === 4) {
                    return parseInt(this.task.manufacturerParam1.split('-')[0].substring(2, 4))
                }

                return this.task.hoyaLens.construction_params ? 40 : 0
            }
        }
    }
</script>