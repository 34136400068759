import {UAC} from '@/js/app/vue/UserAccessControl'
import {i18n, loadLanguageAsync} from '@/js/app/vue/i18n-setup'

import Vue from 'vue'
import _ from 'lodash'
import getters from './getters'
import helper from '@/js/app/vue/store/offer/helper'
import connections from '@/js/app/vue/store/offer/connections'
import layout from '@/js/app/vue/store/offer/layout'
import Notify from '@/js/app/vue/helpers/Notify'

export default {
    resetState(state, init) {
        Object.assign(state, typeof init !== 'undefined' ? init : helper.getDefaultState())

        if(!state.department_id) {
            state.department_id = UAC.getUser().activeDepartmentId
        }

        if(Vue.$router.currentRoute.name === 'app.catalog.offer') {
            loadLanguageAsync(state.english_version ? 'en_EN' : UAC.getUser().lang + '_' + UAC.getUser().lang.toUpperCase())
        }

        helper.cache(state)
    },
    resetColors(state) {
        state.colors = []
        state.colors_header = ''

        state.chose_products.forEach(p => p.color = null)
    },

    setQuestionsAndFilters(state, {questions, filters}) {
        state.questions = questions
        state.filters.types = filters

        layout.filterModuleByDepartment(state)

        helper.cache(state)
    },
    setQuestionFramesOption(state, {key, bool}) {
        state.questions.frames[state.index_question_frames].options[key].selected = bool

        state.help = false
        this.commit('offer/setChanged', true)

        helper.cache(state)
    },
    setQuestionHoyaOption(state, {name, bool}) {
        state.selected_current_hoya_option = bool ? name : null

        this.commit('offer/resetColors')

        helper.cache(state)
    },
    setSelectedQuestionHoya(state) {
        state.selected_questions_hoya[state.selected_questions_hoya.length - 1] = state.selected_current_hoya_option

        state.selected_current_hoya_option = null
        state.selected_questions_hoya.push(null)

        helper.cache(state)
    },
    setSelectedQuestionHoyaBack(state) {
        state.selected_questions_hoya.pop()

        state.selected_current_hoya_option = state.selected_questions_hoya[state.selected_questions_hoya.length - 1]

        state.selected_questions_hoya[state.selected_questions_hoya.length - 1] = null

        this.commit('offer/resetColors')

        helper.cache(state)
    },
    setIndexQuestionFrames(state, index_question_frames) {
        if(!getters.disabledFetch(state, getters)) {
            Notify.warning('catalog.offer.notify.warning.PREV_NEXT_FETCH')
        } else {
            let go_to = index_question_frames,
                to_clear = [state.index_question_frames]

            if(index_question_frames === state.index_question_frames - 1) { // cofanie
                if(getters.isSkipped(state) && state.index_question_frames === 8) {
                    go_to = 4
                    to_clear = to_clear.concat(state.to_skip)
                }

                to_clear.forEach(clear_question_id => {
                    _.forEach(state.questions.frames[clear_question_id].options, option => {
                        if(option.selected) {
                            option.selected = false

                            this.commit('offer/setChanged', true)
                        }
                    })
                })
            } else {
                if(getters.isSkipped(state) && state.index_question_frames === 4) {
                    go_to = 8

                    state.to_skip.forEach(skip_question_id => {
                        _.forEach(state.questions.frames[skip_question_id].options, (option, key) => {
                            if(key === 'all' && !option.selected) {
                                option.selected = true

                                this.commit('offer/setChanged', true)
                            }
                        })
                    })

                    this.commit('offer/setQuestionProductCount')
                }
            }

            state.index_question_frames = go_to

            state.help = false

            helper.cache(state)
        }
    },

    setProducts(state, data) {
        state.paginator = {
            page: data.meta.pagination.current_page,
            pages: data.meta.pagination.total_pages
        }

        state.products_count = data.meta.pagination.total

        if(state.mode === 'questions_frames' || (state.mode === 'filters' && !state.filters.selected.length) ||
            (state.mode === 'questions_hoya' && JSON.stringify(state.selected_questions_hoya) === JSON.stringify([null]))) {
            this.commit('offer/setQuestionProductCount')
        }

        delete data.meta

        state.products = Object.values(data)

        helper.cache(state)
    },

    setColors(state, {colors, colors_header}) {
        state.colors = colors
        state.colors_header = colors_header

        state.chose_products.forEach(p => p.color = null)

        helper.cache(state)
    },
    setColor(state, {chose_product, e}) {
        let color_id = parseInt(e.target.value)

        if(!state.chose_products.filter(p => p.color !== null).length) {
            state.chose_products.forEach(p => p.color = state.colors.filter(c => c.id === color_id)[0])
        }

        chose_product.color = state.colors.filter(c => c.id === color_id)[0]

        helper.cache(state)
    },

    setChoseProduct(state, {product, choice_by_client}) {
        state.chose_products.push({
            product: product,
            choice_by_client: choice_by_client,
            color: null,
            photo: {
                filename: '',
                loading: false
            }
        })

        state.chose_products = _.sortBy(state.chose_products, ['choice_by_client'])

        helper.cache(state)
    },
    deleteChoseProduct(state, product_key) {
        state.chose_products.splice(product_key, 1)

        helper.cache(state)
    },

    setFavorite(state, product) {
        state.favorites.push(product)

        helper.cache(state)
    },
    deleteFavorite(state, product) {
        state.favorites.splice(Object.keys(state.favorites).find(key => state.favorites[key].id === product.id), 1)

        helper.cache(state)
    },

    setPatient(state, patient) {
        state.patient = patient

        helper.cache(state)
    },

    addFilter(state) {
        state.filters.selected.push({
            name: '',
            type: ''
        })

        let remaining = state.filters.types.map(t => t.name).filter(t => !state.filters.selected.map(s => s.name).includes(t))

        if(remaining.length === 1) {
            this.commit('offer/setFilterName', {key: state.filters.selected.length - 1, value: remaining[0]})
        }

        this.commit('offer/setChanged', true)

        helper.cache(state)
    },
    setFilterName(state, {key, value}) {
        state.filters.types.forEach(type => {
            let _type = _.cloneDeep(type)

            if(_type.name === value) {
                if(_type.type === 'slider') {
                    _type.from = _type.range.min
                    _type.to = _type.range.max
                }

                Vue.set(state.filters.selected, key, _type)
            }
        })

        if(value === 'module') {
            layout.filterModuleByDepartment(state)
        }

        this.commit('offer/setChanged', true)

        helper.cache(state)
    },
    setFilterValue(state, {type, key, value}) {
        Vue.set(state.filters.selected[key], type, value)

        this.commit('offer/setChanged', true)

        helper.cache(state)
    },
    deleteFilter(state, key) {
        state.filters.selected.splice(key, 1)

        this.commit('offer/setChanged', true)

        helper.cache(state)
    },

    setFilterOrAnd(state, {key, value}) {
        Vue.set(state.filters.selected[key], 'or_and', value)

        this.commit('offer/setChanged', true)

        helper.cache(state)
    },

    setDepartmentId(state, department_id) {
        state.department_id = department_id

        layout.filterModuleByDepartment(state)

        helper.cache(state)
    },
    setDepartmentIdOnlyId(state, department_id) {
        state.department_id = department_id
    },

    setPage(state, page) {
        state.paginator.page = typeof page === 'undefined' ? 1 : page

        helper.cache(state)
    },

    setPhoto(state, {key_product, type, value}) {
        state.chose_products[key_product].photo[type] = value

        helper.cache(state)
    },

    setEnglishVersion(state, bool) {
        state.english_version = bool

        loadLanguageAsync(state.english_version ? 'en_EN' : UAC.getUser().lang + '_' + UAC.getUser().lang.toUpperCase())

        helper.cache(state)
    },
    setMode(state, value) {
        if(state.mode === 'questions_frames' && value === 'questions_hoya') {
            state.selected_questions_hoya = [null]

            if(state.changed) {
                this.dispatch('offer/loadProducts')
            }
        } else if(state.mode === 'questions_hoya' && value === 'questions_frames') {
            state.selected_questions_hoya = []

            state.selected_current_hoya_option = null
        } else if(value === 'questions') {
            value = state.selected_questions_hoya.length ? 'questions_hoya' : 'questions_frames'

            if(state.filters.selected.length) {
                if(confirm(i18n.t('catalog.offer.confirm_reset.back_to_questions.MESSAGE'))) {
                    state.filters.selected = []

                    this.dispatch('offer/loadProducts')
                } else {
                    value = state.mode
                }
            }
        } else if(state.mode !== 'photos' && value === 'choice' && state.changed) {
            this.dispatch('offer/loadProducts')
        } else if(state.mode === 'questions_frames' && value === 'filters' && state.changed) {
            this.dispatch('offer/loadProducts')
        }

        if(state.mode !== value) {
            state.mode = value

            state.help = false

            helper.cache(state)
        }
    },
    setHelp(state, {index_question_frames, option_key}) {
        if(!state.loading.products) {
            if(index_question_frames === state.help.index_question_frames && option_key === state.help.option_key) {
                state.help = false
            } else {
                let options = Object.keys(state.questions.frames[index_question_frames].options),
                    help = state.questions.frames[index_question_frames].options[option_key].help

                state.help = {
                    index_question_frames: index_question_frames,
                    option_key: option_key,

                    section: state.questions.frames[index_question_frames].name,

                    position: {
                        index_option: options.indexOf(option_key),
                        all: options.length - 1 // odliczamy opcję wszystko
                    },

                    description_extra: help.description_extra,
                    description: help.description,
                    img: help.img,
                    attributes: help.attributes
                }
            }

            helper.cache(state)
        }
    },
    setQuestionProductCount(state) {
        let all = !!(state.questions.frames[state.index_question_frames].name  !== 'available' &&
            Object.keys(state.questions.frames[state.index_question_frames].options).filter(o => o === 'all').length) &&
            state.questions.frames[state.index_question_frames].options.all.selected

        state.questions.frames[state.index_question_frames].count = all ? null : state.products_count
    },
    setChanged(state, bool) {
        state.changed = bool

        if(state.changed) {
            state.chose_products = []
            state.favorites = []

            if(state.mode === 'questions_frames') {
                state.questions.frames[state.index_question_frames].count = null
            }

            connections.check(state)
        }

        helper.cache(state)
    },
    setDialog(state, {type, bool}) {
        state.dialog[type] = bool
    },
    setLoading(state, {type, bool}) {
        state.loading[type] = bool

        if(type === 'products' && !bool) {
            this.commit('offer/setChanged', false)
        }

        helper.cache(state)
    }
}
