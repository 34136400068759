<template lang="pug">
    div
        table.optometrist-table
            tbody
                tr
                    th.scale
                        label.btn.btn-default(for="cz1" :class="{'btn-warning active': mode === '0.05'}") 0,05
                        input#cz1.hide(type="radio" v-model="mode" name="cz" value="0.05" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="cz2" :class="{'btn-warning active': mode === '0.3'}") 0,1 - 0,3
                        input#cz2.hide(type="radio" v-model="mode" name="cz" value="0.3" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="cz3" :class="{'btn-warning active': mode === '0.6'}") 0,4 - 0,6
                        input#cz3.hide(type="radio" v-model="mode" name="cz" value="0.6" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="cz4" :class="{'btn-warning active': mode === '0.9'}") 0,7 - 0,9
                        input#cz4.hide(type="radio" v-model="mode" name="cz" value="0.9" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="cz5" :class="{'btn-warning active': mode === '1.5'}") 1,0 - 1,5
                        input#cz5.hide(type="radio" v-model="mode" name="cz" value="1.5" @change="sendDisplay()")

        table.optometrist-table.top-border.left-border(style="z-index:30")
            tbody
                tr(v-if="list[mode].length > 1" v-for="(scale, index) in list[mode]")
                    td(width="1px" :class="{'not-active': selected.row !== false && selected.row !== index}")
                        a.btn.btn-default.btn-xl(style="border:0" @click="selectRow(index)")
                            i.fa.fa-angle-double-right
                    td(style="width:20%" v-for="(o, index_inner) in permutation[type][scale.replace(',', '.')]"
                        :class="{'not-active': selected.row !== false && selected.row !== index}")
                        OptiOptometristsSurveysLetter(:type="type" :model="o"
                            :background="index_inner === 2 ? 'rg' : (index_inner < 2 ? 'r' : 'g')")

                    td(width="1px")
                        a.btn.btn-inverse.btn-xl {{scale}}
                tr(v-if="list[mode].length === 1")
                    td(v-for="o in permutation[type][list[mode][0].replace(',', '.')]")
                        OptiOptometristsSurveysLetter(:type="type" :model="o" background="rg")

                    td(width="1px")
                        a.btn.btn-inverse.btn-xl {{list[mode][0]}}

        .btn-group(style="width:100%;margin-top:30px;padding-left:10px" v-show="!readOnly")
            .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'C'}"
                @click="$emit('update-cz', 'C')") {{'optometrist.video.cz.RED'|translate}}

            .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'C/Z'}"
                @click="$emit('update-cz', 'C/Z')") {{'optometrist.video.cz.CZ'|translate}}

            .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'Z'}"
                @click="$emit('update-cz', 'Z')") {{'optometrist.video.cz.GREEN'|translate}}
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    import OptiOptometristsSurveysLetter
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysLetter'

    export default {
        name: 'OptiOptometristsSurveysOptotypesRedGreen',
        components: {
            OptiOptometristsSurveysLetter
        },
        props: {
            permutation: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                required: true
            },

            cz: {
                type: String,
                required: true
            },

            readOnly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                watch: {
                    optotypes: null
                },

                mode: '0.05',
                selected: {
                    row: false
                },

                list: {
                    '0.05': ['0,05'],
                    '0.3': ['0,1', '0,2', '0,3'],
                    '0.6': ['0,4', '0,5', '0,6'],
                    '0.9': ['0,7', '0,8', '0,9'],
                    '1.5': ['1,0', '1,2', '1,5']
                }
            }
        },

        activated() {
            this.mode = '0.05'

            this.sendDisplay()

            this.watch.optotypes = this.$watch('optotypes', () => {
                this.sendDisplay()
            })
        },
        deactivated() {
            this.watch.optotypes()
        },

        methods: {
            sendDisplay() {
                let mod = []

                mod.push('cz=true')
                if(this.selected.row !== false) {
                    mod.push('row=' + this.selected.row)
                }

                mod = mod.length > 0 ? '?' + mod.join('&') : ''
                OptometristsDisplay.message.open('/optometrist/screen/' + this.type + '/' + this.mode + '/' +
                    this.permutation.active + mod)
            },
            selectRow(index) {
                if(this.selected.row === index) {
                    this.selected.column = false
                    this.selected.row = false
                } else {
                    this.selected.column = false
                    this.selected.row = index
                }

                this.sendDisplay()
            }
        },
        computed: {
            optotypes() {
                return [this.permutation.active, this.type]
            }
        }
    }
</script>