<template lang="pug">
    span.form-control.text-right(:style="{cursor: disabled ? 'not-allowed' : 'pointer'}" :disabled="disabled"
        @click="open = !disabled")
        slot

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogNumber(:header="header" :minus="minus" :float="float" :required="required" :pending="pending"
                :none="none" :min="min" :max="max" :is-number="isNumber" :value="value"
                @confirmed="confirmed" @closed="open = false")
</template>

<script>
    import OptiDialogNumber from '@/js/app/vue/components/Inputs/Dialogs/OptiDialogNumber'

    export default {
        name: 'OptiNumber',
        components: {
            OptiDialogNumber
        },
        props: {
            header: {
                type: String,
                required: true,
                default: ''
            },

            minus: {
                type: Boolean,
                required: false,
                default: false
            },
            float: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            pending: {
                type: Boolean,
                required: false,
                default: false
            },
            none: {
                type: Number | Boolean,
                required: false,
                default: false
            },
            min: {
                type: Number | Boolean,
                required: false,
                default: false
            },
            max: {
                type: Number | Boolean,
                required: false,
                default: false
            },

            isNumber: {
                type: Boolean,
                required: false,
                default: true
            },
            value: {
                type: Number | String,
                default: 0,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            confirmed(value) {
                this.$emit('confirmed', value)

                this.open = false
            }
        }
    }
</script>
