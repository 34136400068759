<template lang="pug">
    .panel.panel-default(style="position: relative")
        .panel-heading(v-if="chartData && chartData.cache")
            .row
                .col-sm-4
                    select.form-control(name="sortOrder" v-model="sortOrder")
                        option(v-for="sort in select.sort" :value="sort") {{'report.budget.sort.' + sort|translate}}

                .col-sm-4.text-center
                    div
                        .text-md {{chartData.cache.quarter}}Q{{chartData.cache.year}}
                        .small.text-muted ({{'report.budget.DATADATE'|translate}}: {{chartData.cache.updated}})

                    .btn-group
                        label.btn.btn-default(v-for="yq in select.quarterYear"
                            :class="{'btn-purple active': yq.year === year && yq.quarter === quarter}") {{yq.quarter}}Q{{yq.year}}
                            input.hide(type="radio" name="yearQuarterRadio"
                                :checked="yq.year === year && yq.quarter === quarter"

                                @change="load(yq.year, yq.quarter)")

                .col-sm-4.text-right
                    OptiButtonToggle(style="margin-bottom:10px"
                        enum="BudgetQuarterName"
                        :model="quarter"

                        @change="updateQuarter")

                    .btn-group
                        label.btn.btn-default(v-for="y in select.years" :class="{'btn-purple active': year === y}") {{y}}
                            input.hide(type="radio" name="yearRadio"
                                :value="y"
                                :checked="year === y"

                                @change="updateYear(y)")

        .panel-body
            BarChart(
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

            OptiChartTooltip(
                :model="check"
                :chart-data="chartData"
                :chart="$refs.chart")
                template(#header="{header}")
                    div {{header.tooltip.title[0]}}

                template(#default="{body}")
                    table.table.table-striped.table-bordered.table-condensed
                        thead
                            tr
                                th.text-right {{'report.budget.tooltip.BUDGET'|translate}}
                                th.text-right {{'report.budget.tooltip.VALUE'|translate}}
                        tbody
                            tr
                                th.text-right {{'report.budget.tooltip.REACHEDCORRECTED'|translate}}
                                td.monospace.text-right
                                    span.text-muted.text-sm ({{reachedCorrectedAmount(body.tooltip)}})&nbsp;
                                    span {{body.tooltip.body[0].lines[0].reached+body.tooltip.body[0].lines[0].corrected+body.tooltip.body[0].lines[0].profit|currency({fractionCount: 0})}}
                            tr
                                th.text-right {{'report.budget.tooltip.CENTRAL'|translate}}
                                td.monospace.text-right {{body.tooltip.body[0].lines[0].central|currency({fractionCount: 0})}}
                            tr
                                th.text-right {{'report.budget.tooltip.DIFFERENCE'|translate}}
                                td.monospace.text-right(style="border-top:2px solid black")
                                    .percent-negative.warn(v-if="body.tooltip.body[0].lines[0].difference < 0")
                                        span {{body.tooltip.body[0].lines[0].difference|currency({fractionCount: 0})}}
                                    .percent-positive(v-else)
                                        span {{body.tooltip.body[0].lines[0].profit|currency({fractionCount: 0})}}

            OptiDimmer(:active="$asyncComputed.chartData.updating")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {draw} from 'patternomaly'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper';

    import BarChart from '@/js/app/vue/components/Chart/Base/BarChart'
    import OptiChartTooltip from '@/js/app/vue/components/Chart/Base/OptiChartTooltip'
    import OptiButtonToggle from '../Button/OptiButtonToggle'
    import OptiDimmer from '../Blocks/OptiDimmer'

    export default {
        name: 'OptiReportBudgetsDepartments',
        components: {
            BarChart,
            OptiChartTooltip,
            OptiButtonToggle,
            OptiDimmer
        },
        data() {
            let leftAxis = null,
                year = parseInt(moment().format('GGGG')),
                quarter = BudgetHelper.getQuarterByWeek(moment())

            return {
                check: null,

                select: {
                    sort: [
                        'reached_corrected_asc',
                        'reached_corrected_desc',
                        'central_asc',
                        'central_desc',
                        'difference_asc',
                        'difference_desc'
                    ],
                    years: [
                        year - 1,
                        year,
                        year + 1
                    ],
                    quarterYear: [{
                        year: year,
                        quarter: quarter
                    }, {
                        year: quarter === 4 ? year + 1 : year,
                        quarter: quarter === 4 ? 1 : quarter + 1
                    }]
                },

                sortOrder: 'reached_corrected_asc',
                year: year,
                quarter: quarter,

                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        onClick(event, legendItem) {
                            let ci      = this.chart
                            let meta    = ci.getDatasetMeta(legendItem.datasetIndex)
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[legendItem.datasetIndex].hidden : null

                            if(legendItem.datasetIndex <= 1) {
                                // pierwszy lub drugi item to togglujemy razem
                                [
                                    ci.getDatasetMeta(0),
                                    ci.getDatasetMeta(1)
                                ].forEach(m => {
                                    m.hidden = meta.hidden
                                })
                            }

                            ci.update()
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    callback(label) {
                                        return Math.round(label / 1000)
                                    }
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Budżet (w tysiącach)'
                                },
                                afterDataLimits(axis) {
                                    leftAxis = axis
                                }
                            },
                            {
                                position: 'right',
                                ticks: {
                                    callback(label) {
                                        return Math.round(label / 1000)
                                    }
                                },
                                afterDataLimits(axis) {
                                    if(leftAxis) {
                                        axis.min = leftAxis.min
                                        axis.max = leftAxis.max
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            }
                        ]
                    },
                    tooltips: {
                        mode: 'x',
                        position: 'average',
                        callbacks: {
                            label(tooltipItem, data) {
                                let index = tooltipItem.index

                                return {
                                    name: tooltipItem.label,
                                    index: index,
                                    reached: data.datasets[0].data[index] / 1000,
                                    corrected: data.datasets[1].data[index] / 1000,
                                    central: data.datasets[2].data[index] / 1000,
                                    difference: data.datasets[3].data[index] / 1000,
                                    profit: data.datasets[4].data[index] / 1000,
                                }
                            }
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    }
                },
                styles: {
                    position: 'relative',
                    height: '600px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                }
            }
        },
        asyncComputed: {
            async chartData() {
                try {
                    let {data} = await API.post('charts/budgetSummary', {
                        year: this.year,
                        quarter: this.quarter,
                        sortOrder: this.sortOrder
                    })

                    if(data.datasets) {
                        data.datasets[1].backgroundColor = draw('diagonal-right-left', 'rgba(15,32,128, 0.5)')
                    }

                    return data
                } catch (e) {
                    console.log(e)
                }
            }
        },
        methods: {
            reachedCorrectedAmount(tooltip) {
                let reached = tooltip.body[0].lines[0].reached,
                    corrected = tooltip.body[0].lines[0].corrected,
                    profit = tooltip.body[0].lines[0].profit

                if(profit > 0) {
                    if(corrected > 0) {
                        corrected += profit
                    } else {
                        reached += profit
                    }
                }

                let rc = this.$filters.currency(reached, '', ' ', 0, '') + '+ ' + this.$filters.currency(corrected, '', ' ', 0, '')

                return rc.trim()
            },

            updateYear(year) {
                this.year = parseInt(year)
            },
            updateQuarter(quarter) {
                this.quarter = parseInt(quarter)
            },
            load(year, quarter) {
                this.year = year
                this.quarter = quarter
            }
        },
    }
</script>

<style lang="less" scoped>
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .percent-negative > span:before {
        content: '▼'
    }

    .percent-positive {
        & > span:before {
            content: '▲'
        }

        color: @btn-success-bg;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }

    p.arrow {
        font-size: 20px;
    }
</style>
