<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form.form-horizontal.form-validate(name="formProduct" @submit.prevent="editProduct")
                .form-group
                    label.col-sm-4.control-label(for="barcode" :class="{'star-required': product.customerProduct.id !== 2499}"
                        ) {{'catalog.product.properties.BARCODE'|translate}}
                    .col-sm-8
                        input#barcode.form-control(name="barcode" v-model="product.barcode")
                        OptiValidate(f="barcode" :data="$v.product.barcode")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'catalog.product.properties.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="product.name")
                        OptiValidate(f="name" :data="$v.product.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="fullName") {{'catalog.product.properties.FULLNAME'|translate}}
                    .col-sm-8
                        input#fullName.form-control(name="fullName" v-model="product.fullName")
                        OptiValidate(f="fullName" :data="$v.product.fullName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'catalog.product.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum(enum="ProductType" :value="product.type" @update-enum="v => product.type = v")
                        OptiValidate(f="type" :data="$v.product.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="manufacturer") {{'catalog.product.properties.PRODUCENT'|translate}}
                    .col-sm-8
                        input#manufacturer.form-control(name="manufacturer" v-model="product.manufacturer" required)
                        OptiValidate(f="manufacturer" :data="$v.product.manufacturer")
                .form-group
                    label.col-sm-4.control-label.star-required(for="category_department") {{'catalog.product.properties.CATEGORYDEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectBrand#category_department(:extended="true" :category="product.customerProduct"
                            @selected="updateCategoryDepartment")
                        OptiValidate(f="category_department" :data="$v.product.customerProduct.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="category_warehouse") {{'catalog.product.properties.CATEGORYWAREHOUSE'|translate}}
                    .col-sm-8
                        OptiSelectCategoryWarehouse#category_warehouse(:category="product.categoryWarehouse"
                            @selected="c => product.categoryWarehouse = c")
                        OptiValidate(f="category_warehouse" :data="$v.product.categoryWarehouse.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="lp") Lp
                    .col-sm-8
                        OptiNumber#lp(header="Lp" :value="product.lp"
                            @confirmed="lp => product.lp = lp" :required="true") {{product.lp}}
                        OptiValidate(f="lp" :data="$v.product.lp")
                .form-group
                    label.col-sm-4.control-label(for="index") Index
                    .col-sm-8
                        OptiNumber#index(header="Index" :value="product.index"
                            @confirmed="index => product.index = index") {{product.index}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="expired" v-model="product.expired")
                                    span.fa.fa-check
                            span {{'product.EXPIRED'|translate}}

                        OptiButton.pull-right(
                            :disabled="!product.customerProduct.specialPrice"
                            text="smile.details.percent.btn"
                            @confirmed="deletePercent()"
                            ) {{'smile.details.percent.DELETE'|translate}}

                .form-group(style="margin-top:40px")
                    label.col-sm-4.control-label.star-required(for="price") {{'catalog.product.properties.LAUNCH_PRICE'|translate}}
                    .col-sm-8
                        OptiNumber#price(:header="'catalog.product.properties.LAUNCH_PRICE'|translate"
                            :value="product.customerProduct.launchPrice" @confirmed="p => product.customerProduct.launchPrice = p"
                            :float="true" :required="true") {{product.customerProduct.launchPrice|currency}}
                .form-group(style="margin-top:40px")
                    label.col-sm-4.control-label.star-required(for="price") {{'catalog.product.properties.OMNIBUS'|translate}}
                    .col-sm-8
                        OptiNumber#price(:header="'catalog.product.properties.OMNIBUS'|translate"
                            :value="product.customerProduct.omnibusPrice" @confirmed="p => product.customerProduct.omnibusPrice = p"
                            :float="true" :required="true") {{product.customerProduct.omnibusPrice|currency}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="price") {{'catalog.product.properties.PRICE'|translate}}
                    .col-sm-8
                        OptiNumber#price(:header="'catalog.product.properties.PRICE'|translate"
                            :value="product.customerProduct.price" @confirmed="p => product.customerProduct.price = p"
                            :float="true" :required="true") {{product.customerProduct.price|currency}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="priceWholesale") {{'catalog.product.properties.WHOLESALE'|translate}}
                    .col-sm-8
                        OptiNumber#priceWholesale(:header="'catalog.product.properties.WHOLESALE'|translate"
                            :value="product.priceWholesale" @confirmed="p => product.priceWholesale = p"
                            :float="true" :required="true") {{product.priceWholesale|currency}}
                        OptiValidate(f="priceWholesale" :data="$v.product.priceWholesale")
                .form-group
                    label.col-sm-4.control-label.star-required(for="priceSupplier") Index ZHC
                    .col-sm-8
                        OptiNumber#priceSupplier(header="Index ZHC"
                            :value="product.priceSupplier" @confirmed="p => product.priceSupplier = p"
                            :float="true" :required="true") {{product.priceSupplier|currency}}
                        OptiValidate(f="priceSupplier" :data="$v.product.priceSupplier")
                .form-group
                    label.col-sm-4.control-label(for="lastPrice") {{'catalog.product.properties.LASTPRICE'|translate}}
                    .col-sm-8
                        OptiNumber#lastPrice(:header="'catalog.product.properties.LASTPRICE'|translate"
                            :value="product.lastPrice" @confirmed="p => product.lastPrice = p"
                            :float="true") {{product.lastPrice|currency}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="vat") VAT
                    .col-sm-8
                        OptiNumber#vat(header="VAT"
                            :value="product.vat" @confirmed="vat => product.vat = vat"
                            :required="true") {{product.vat}}%
                        OptiValidate(f="vat" :data="$v.product.vat")

                .form-group(style="margin-top:40px")
                    label.col-sm-4.control-label(for="stock") {{'catalog.product.stock.STOCK'|translate}}
                    .col-sm-8
                        OptiNumber#stock(:header="'catalog.product.stock.STOCK'|translate"
                            :value="product.stock" @confirmed="updateStock") {{product.stock}}
                .form-group
                    label.col-sm-4.control-label(for="threshold") {{'catalog.product.stock.THRESHOLD'|translate}}
                    .col-sm-8
                        OptiNumber#threshold(:header="'catalog.product.stock.THRESHOLD'|translate"
                            :value="product.threshold" @confirmed="s => product.threshold = s") {{product.threshold}}
                .form-group
                    label.col-sm-4.control-label(for="reserved") {{'catalog.product.stock.RESERVED'|translate}}
                    .col-sm-8
                        OptiNumber#reserved(:header="'catalog.product.stock.RESERVED'|translate"
                            :value="product.reserved" @confirmed="s => product.reserved = s") {{product.reserved}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="inStock" v-model="product.inStock")
                                    span.fa.fa-check
                            span {{'catalog.product.stock.INSTOCK'|translate}}
                        label.pull-right(v-if="product.temp_product")
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="temp_product" v-model="product.temp_product")
                                    span.fa.fa-check
                            span {{'catalog.product.properties.TEMP_PRODUCT'|translate}}

                .form-group(style="margin-top:40px")
                    label.col-sm-4.control-label(for="comments") {{'catalog.product.logs.comments.LABEL'|translate}}
                    .col-sm-8
                       textarea#comments.form-control(name="comments" rows="4" :placeholder="'catalog.product.logs.comments.PLACEHOLDER'|translate"
                            v-model="product.comments")

                .form-group(style="margin-top:40px")
                    label.col-sm-4.control-label(for="manufacturerId") SKU
                    .col-sm-8
                        input#manufacturerId.form-control(name="manufacturerId" v-model="product.manufacturerId")
                .form-group
                    label.col-sm-4.control-label(for="manufacturerBarcode") {{'catalog.product.MFCTBARCODE'|translate}}
                    .col-sm-8
                        input#manufacturerBarcode.form-control(name="manufacturerBarcode" v-model="product.manufacturerBarcode")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="$v.product.$invalid")

        .col-xs-12(style="margin-top:20px" v-if="!loading && permission.history")
            OptiProductWarehouseHistory(:product="product")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import validations from './validations'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectBrand from '@/js/app/vue/components/Select/OptiSelectBrand'
    import OptiSelectCategoryWarehouse from '@/js/app/vue/components/Select/OptiSelectCategoryWarehouse'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiProductWarehouseHistory from '@/js/app/vue/components/Warehouse/Products/OptiProductWarehouseHistory'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiProductEdit',
        components: {
            OptiButton,
            OptiValidate,
            OptiSelectEnum,
            OptiSelectBrand,
            OptiSelectCategoryWarehouse,
            OptiNumber,
            OptiButtonSave,
            OptiProductWarehouseHistory,
            OptiDimmer
        },
        data() {
            return {
                product: {
                    id: this.$state.params.id,
                    customerProduct: {
                        id: 0,
                        name: '',
                        type: '',
                        original: ''
                    },
                    categoryWarehouse: {
                        id: 0,
                        name: '',
                        type: '',
                        short: ''
                    }
                },
                product_barcode: null,

                permission: {
                    history: this.$uac.permission('products.history')
                },
                loading: true
            }
        },
        validations() {
            return validations.get(this.product.id, this.product_barcode)
        },
        mounted() {
            API.get('catalog/product/' + this.$state.params.id).then(res => {
                this.product = res.data
                this.product_barcode = this.product.barcode

                this.product.customerProduct = {
                    id: this.product.customerProduct.categoryId,
                    name: this.product.customerProduct.category.name,
                    price: this.product.customerProduct.price ? this.product.customerProduct.price : this.product.priceRetail,
                    type: this.product.customerProduct.type,
                    omnibusPrice: this.product.customerProduct.price_omnibus,
                    launchPrice: this.product.customerProduct.launch_price,
                    original: this.product.customerProduct.original,
                    specialPrice: this.product.customerProduct.specialPrice
                }

                this.loadPrevious()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.LOAD')
            })
        },
        methods: {
            loadPrevious() {
                if(this.product.breads.length) {
                    let filter = JSON.parse(window.localStorage.getItem('category-products-filter')),
                        curve = JSON.parse(window.localStorage.getItem('category-products-curve')),
                        statuses = JSON.parse(window.localStorage.getItem('category-products-selection')),
                        sorting = JSON.parse(window.localStorage.getItem('category-products-sorting'))

                    API.post('warehouse/products/page', {
                        product_id: this.product.id,
                        catalog_id: this.product.breads[this.product.breads.length - 1].id,
                        filter: filter ? filter : {local: false, main: false},
                        curve: curve ? curve : {
                            checkbox: false,
                            range: false,
                            from: '-',
                            to: '-'
                        },
                        statuses: statuses ? statuses : {
                            all: true,
                            mh: true,
                            pi1: true,
                            pi: true,
                            ac: true,
                            po: true,
                            not_selected: true
                        },
                        sorting: sorting ? sorting : {
                            asc: true,
                            sortBy: 'lp'
                        }
                    }, API.id(Math.random())).then(res => {
                        if(res.data) {
                            this.product.breads.forEach((bread, key) => {
                                if(key === (this.product.breads.length - 1)) {
                                    bread.page = res.data
                                    bread.product_id = this.product.id
                                } else {
                                    bread.page = 1
                                }
                            })
                        }

                        this.$emit('update-breadcrumb', this.product.breads)
                    }).catch(() => {
                        this.$notify.error('catalog.product.notify.error.PAGECATALOG')
                    })
                }
            },

            updateCategoryDepartment(c) { //nie może nadpisać ceny
                this.product.customerProduct.id = c.id
                this.product.customerProduct.name = c.name
                this.product.customerProduct.type = c.type
                this.product.customerProduct.original = c.original
            },
            updateStock(v) {
                this.product.stock = v

                this.product.inStock = !!this.product.stock
            },

            editProduct() {
                this.loading = true
                this.product.upload = this.upload

                API.put('warehouse/products/edit', this.product).then(() => {
                    this.$state.go('app.catalog.product', {
                        id: this.product.id
                    })

                    this.$notify.success('catalog.product.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.product.notify.error.EDIT')
                })
            },

            deletePercent() {
                this.loading = true

                API.post('warehouse/products/deletePercent', {
                    id: this.product.id
                }).then(() => {
                    this.$state.go('app.catalog.product', {
                        id: this.product.id
                    })

                    this.$notify.success('catalog.product.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.product.notify.error.EDIT')
                })
            }
        }
    }
</script>