<template lang="pug">
	.row(v-show="!loading")
		.col-md-offset-2.col-md-8
			form.form-horizontal.form-validate(name="formStarProtocol" @submit.prevent="addStarProtocol()")
				.form-group
					label.col-sm-4.control-label.star-required(for="department") {{'star.protocol.DEPARTMENT'|translate}}
					.col-sm-8
						OptiSelectDepartment#department(
							:department-id="star.department_id"
							:simple="true"
							@selected="s => star.department_id = s")
						input(type="hidden" name="department_id" v-model="star.department_id")
						OptiValidate(f="department" :data="$v.star.department_id")
				.form-group
					label.col-sm-4.control-label.star-required(for="file") {{'star.protocol.FILE'|translate}}
					.col-sm-8
						OptiUpload#file(
							prefix="starprotocol"
							:file="star.upload"

							@update-file="u => star.upload = u"
							@update-loading ="l => loading_file = l")
				.form-group
					.col-sm-8.col-sm-offset-4(v-if="star.upload && loading_file === false")
						a.link(:href="'upload/tmp/' + star.upload" :title="'default.REFERENCETYPE'|translate" target="_blank")
							span.fa.fa-paperclip
							span {{'marketing.ATTACHMENT'|translate}}
						a.link.warn(@click="removeAttachment()"
							:title="'default.REMOVE'|translate") ({{'default.REMOVE'|translate}})
				.form-group
					.col-sm-offset-4.col-sm-8
						OptiButtonSave(:disabled="star.$invalid || !star.upload")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")

		.col-md-offset-2.col-md-8(style="padding-top:30px")
			.panel.panel-primary
				.panel-heading
					i.fa.fa-navicon.mr3
					span {{'star.table.HEADING'|translate}}
				.table-responsive
					table.table.table-striped
						thead
							tr
								th.text-right #
								th {{'star.table.DEPARTMENT'|translate}}
								th
						tbody(table-empty)
							tr(v-for="(file, index) in files")
								td.text-right {{files.length - index}}
								td
									a.link(:href="'upload/starloox/' + file.name" target="_blank"
										:title="'star.table.DOWNLOAD'|translate") {{file.department.name}}
								td.text-center
									a(:href="'upload/starloox/' + file.name" target="_blank"
										:title="'star.table.DOWNLOAD'|translate")
										span.fa.fa-2x.fa-file
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
import {API} from '../../../api'
import {noZero} from '@/js/vue.validators.js'

import OptiUpload from '../../OptiUpload'
import OptiButtonSave from '../../Button/OptiButtonSave'
import OptiDimmer from '../../Blocks/OptiDimmer'
import OptiSelectDepartment from '../../Select/OptiSelectDepartment'
import OptiValidate from '../../OptiValidate'

export default {
	name: "OptiDictionaryStarProtocolAdd",
	components: {
		OptiUpload,
		OptiButtonSave,
		OptiDimmer,
		OptiSelectDepartment,
		OptiValidate
	},
	data() {
		return {
			files: [],
			star: {
				department_id: 0,
				upload: null
			},
			loading: false,
			loading_file: false
		}
	},
	validations: {
		star: {
			department_id: {
				noZero
			}
		}
	},
	mounted() {
		API.get('dictionary/starloox/list').then((res) => {
			this.files = res.data
			this.loading = true
		}).finally(() => {
			this.loading = false
		}).catch(() => {
			this.$notify.error('star.notify.error.LIST')
		})
	},
	methods: {
		addStarProtocol() {
			this.loading = true

			API.post('dictionary/starloox', {
				department_id: this.star.department_id.id,
				upload: this.star.upload
			}).then((res) => {
				this.files = res.data

				this.star = {
					department_id: 0,
					upload: null
				}

				this.$notify.success('star.notify.SAVE')
				this.loading = false
			}).catch(() => {
				this.$notify.error('star.notify.error.SAVE')
			}).finally(() => {
				this.loading = false
			})
		},
		removeAttachment() {
			this.star.upload = null
		}
	}
}
</script>