<template lang="pug">
    .row
        .col-xs-12(style="margin-top:10px")
            .panel.panel-primary
                .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
                    i.fa.fa-navicon.mr3
                    span {{'tasks.bevel.BEVEL'|translate}}

                    i.fa.pull-right(style="padding-top:3px" :class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")

                .panel-body(v-show="toggle")
                    .row
                        .col-lg-6
                            form.form-horizontal(name="bevel")
                                .form-group
                                    label.control-label.col-sm-4(for="bevel-edge") {{'tasks.bevel.EDGE'|translate}}
                                    .col-sm-8
                                        OptiSelectEnum#bevel-edge(enum="TaskBevelEdge" :disabled="block"
                                            :value="bevel.edge" @update-enum="updateEdge")
                                .form-group
                                    label.control-label.col-sm-4(for="bevel-position") {{'tasks.bevel.POSITION'|translate}}
                                    .col-sm-8
                                        OptiSelectEnum#bevel-position(enum="TaskBevelPosition" :disabled="block || ['TwoPoint', 'Nylon'].includes(frameType)"
                                            :value="bevel.position" @update-enum="updatePosition")
                                .form-group(v-show="bevel.position === 2")
                                    label.control-label.col-sm-4 {{bevel.radio}} : {{100 - bevel.radio}}
                                    .col-sm-8.text-center(style="padding-top:7px")
                                        .col-xs-1(:title="'tasks.bevel.position.FRONT'|translate") {{'tasks.bevel.position.sub.FRONT'|translate}}
                                        .col-xs-9
                                            RangeSlider(
                                                :min="0"
                                                :max="100"
                                                :step="10"
                                                :disabled="block"
                                                v-model="bevel.radio"

                                                @change="$emit('bevel-was-updated')")
                                        .col-xs-1(:title="'tasks.bevel.position.BACK'|translate") {{'tasks.bevel.position.sub.BACK'|translate}}
</template>

<script>
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import RangeSlider from 'vue-range-slider'
    import 'vue-range-slider/dist/vue-range-slider.css'

    export default {
        name: 'OptiTaskDetailsFrameBevel',
        components: {
            OptiSelectEnum,
            RangeSlider

        },
        props: {
            bevel: {
                type: Object,
                required: true
            },
            frameType: {
                type: String,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                toggle: false
            }
        },
        mounted() {

        },
        methods: {
            updateEdge(edge) {
                this.bevel.edge = edge

                this.$emit('bevel-was-updated')
            },
            updatePosition(position) {
                this.bevel.position = position

                this.$emit('bevel-was-updated')
            }
        },
        computed: {

        }
    }
</script>