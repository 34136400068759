import {GET_ITEMS} from '@/js/app/vue/api'
import OptiStore from '@/js/app/vue/store/opti-store'

import actions from '@/js/app/vue/store/tickets/actions'
import mutations from '@/js/app/vue/store/tickets/mutations'
import getters from '@/js/app/vue/store/tickets/getters'

class TicketStore extends OptiStore {
    state() {
        return {
            ...super.state(),
            storage: null,
            items: []
        }
    }

    actions() {
        return {
            ...super.actions(),
            ...actions,
            onInit({dispatch}) {
                dispatch(GET_ITEMS)
            }
        }
    }

    mutations() {
        return {...super.mutations(), ...mutations}
    }

    getters() {
        return {...super.getters(), ...getters}
    }
}

let store = new TicketStore();
export default store.getModule()
