import Vue from 'vue'
import Vuex from 'vuex'
import tickets from '@/js/app/vue/store/tickets'
import departments from '@/js/app/vue/store/departments'
import departments_recently_closed from '@/js/app/vue/store/departments_recently_closed'
import user from './store/user'
import carts from './store/carts'
import dashboard from './store/dashboard'
import globals from './store/globals'
import offer from './store/offer'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    state: {

    },

    modules: {
        tickets: tickets,
        dashboard: dashboard,
        departments: departments,
        departments_recently_closed: departments_recently_closed,
        user: user,
        carts: carts,
        globals: globals,
        offer: offer
    },

    actions: {

    },

    mutations: {

    },

    getters: {

    },

    plugins: [].concat(globals.plugins, dashboard.plugins)
})

export default store
