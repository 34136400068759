<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogOfferRedirect',
        components: {
            OptiDimmer
        },
        mounted() {
            API.get('catalog/offer/redirect/' + this.$state.params.id).then(res => {
                this.$state.go('app.patient.detail', {id: res.data.patient_id, '#': 'offers', offerId: res.data.id})
            }).catch(() => {
                this.$notify.error('patient.offer.notify.error.LOAD_REDIRECT')
            })
        }
    }
</script>