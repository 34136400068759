<template lang="pug">
    span
        i.fa.fa-clock-o.mr3
        span {{minute}}:{{second}}
</template>

<script>
    export default {
        name: 'optiTimer',
        props: {
            data: {
                type: Number,
                required: true,
                default: 0
            }
        },
        watch: {
            data() {
                this.update()
            }
        },
        data() {
            return {
                minute: '00',
                second: '00'
            }
        },
        mounted() {
            this.update()
        },
        methods: {
            update() {
                this.minute = parseInt(parseInt(this.data) / 60)
                this.second = parseInt(this.data) % 60

                if ((this.minute).toString().length === 1) {
                    this.minute = '0' + (this.minute).toString()
                }

                if ((this.second).toString().length === 1) {
                    this.second = '0' + (this.second).toString()
                }
            }
        }
    }
</script>
