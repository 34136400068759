<template lang="pug">
    #ipad-individual.panel.panel-primary(style="position:relative")
        .panel-heading(:class="{'cursor-pointer': !isVisuServer && measurementJawroVisuId}" @click="setToggle")
            i.fa.fa-navicon.mr3
            span {{'tasks.measurement.individual.table.CAPTION'|translate}}
            i.pull-right.fa(style="margin-top:5px" :class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}"
                v-show="!isVisuServer && measurementJawroVisuId")

        .table-responsive(style="position:relative")
            table.table.table-striped.middle
                thead
                    tr(v-if="from_task")
                        th.text-center.text-info(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span.mr3 {{'tasks.measurement.individual.test.notify.FROM_TASK'|translate}}
                            a.link(:href="$state.href('app.task.detail', {id: from_task})" target="_blank") {{from_task}}
                            span .

                    tr
                        th(v-show="!isVisuServer") #
                        th
                            span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                        th
                            i.fa.fa-arrows-v(:title="'tasks.frame.HEIGHT'|translate")
                        th
                            i.fa.fa-arrows-h(:title="'tasks.frame.WIDTH'|translate")
                        th
                            div(:title="'tasks.INCLINATION'|translate") PA
                        th CVD
                        th(:title="'tasks.FFFA'|translate") FFFA
                        th {{'tasks.measurement.individual.table.thead.CREATED'|translate}}
                        th {{'tasks.measurement.individual.table.thead.SIDE'|translate}}
                        th(v-show="!isVisuServer" :title="'tasks.measurement.individual.table.thead.im.TITLE'|translate"
                            ) {{'tasks.measurement.individual.table.thead.im.IM'|translate}}

                tbody
                    template(v-for="(m, key) in measurements")
                        tr(:title="'tasks.measurement.individual.table.title.CHECK'|translate"
                            v-show="!measurementJawroVisuId || toggle || m.pass"
                            :class="{'tr-stripped': key % 2}")
                            td(v-show="!isVisuServer") {{m.order}}
                            td {{m.pd_r|number(1)}}
                            td {{m.pd_l|number(1)}}
                            td
                                .bt-row
                                    div(:title="btTitle('main', m, 'r')") {{m.bt_r|number(1)}}
                                    .text-muted(v-show="m.bt_r !== m.raw_bt_r" :title="btTitle('raw', m, 'r')") {{m.raw_bt_r|number(1)}}
                            td
                                .bt-row
                                    div(:title="btTitle('main', m, 'l')") {{m.bt_l|number(1)}}
                                    .text-muted(v-show="m.bt_l !== m.raw_bt_l" :title="btTitle('raw', m, 'l')") {{m.raw_bt_l|number(1)}}
                            td {{m.frame_height|number(1)}}
                            td {{m.frame_width|number(1)}}
                            td {{m.pa|number(1)}}
                            td
                                span
                                    span.mr3(v-show="m.cvd_r !== m.cvd_l") {{'prescription.RIGHT'|translate}}
                                    span {{m.cvd_r|number(1)}}
                                span(style="margin-left:3px" v-show="m.cvd_r !== m.cvd_l")
                                    span.mr3 {{'prescription.LEFT'|translate}}
                                    span {{m.cvd_l|number(1)}}
                            td {{m.fffa|number(1)}}
                            td {{m.created_at|noYear}}
                            td {{m.side_name|translate}}
                            td.bold(v-show="!isVisuServer")
                                i.fa.fa-check.text-success(v-show="m.pass"
                                    :title="'tasks.measurement.individual.table.title.PAIR'|translate")
                            td.text-center(style="cursor:pointer" :title="'tasks.measurement.IMAGE_SHOW'|translate"
                                v-show="!isVisuServer && m.measurement_id" @click="toggleImage(m)")
                                i.fa.fa-camera

                        tr(v-if="m.image")
                            td.text-center(colspan="100" style="border-top:0")
                                a(:href="'upload/jawro_visu/task/' + m.measurement_id + '.jpg'" target="_blank"
                                    :title="'tasks.ipad.printscreen.TITLE'|translate")
                                    img(style="width:100%" :src="'upload/jawro_visu/task/' + m.measurement_id + '.jpg?refresh=' + timestamp")

                    tr(v-show="!measurements.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

        .panel-footer
            OptiButton.hide(v-show="permission.test" :disabled="loading.test || loading.measurements || block"
                @click.native="loadTest") {{'tasks.measurement.individual.test.BTN'|translate}}
                template(#icon)
                    i.fa.fa-download

            OptiButton.pull-right(type="success" :disabled="loading.measurements || block"
                @click.native="load(false)") {{'tasks.ipad.BTNREFRESH'|translate}}
                template(#icon)
                    i.fa.fa-refresh

            OptiDimmer(:active="loading.test || loading.measurements")

            .clearfix
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Broadcast from '@/js/app/vue/Broadcast'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import {TaskMeasurementTitle} from "@/js/app/vue/components/Tasks/TaskMeasurementTitle";

    export default {
        name: 'OptiTaskDetailsIpadJawroVisuMeasurements',
        components: {
            OptiButton,
            OptiDimmer
        },
        props: {
            taskId: {
                type: Number,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            status: {
                type: Number,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            measurementJawroVisuId: {
                type: Number,
                required: true
            },
            frame: {
                type: Object,
                required: true
            },
            reloadMeasurements: {
                type: String,
                required: true
            },
            timestamp: {
                type: String,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                measurements: [],

                toggle: false,
                download: {
                    key: 0,
                    length: 0
                },
                permission: {
                    test: this.$uac.permission('tasks.measurements.test')
                },
                loading: {
                    test: false,
                    measurements: false
                }
            }
        },
        watch: {
            reloadMeasurements(assembly_method) {
                if(assembly_method) {
                    this.load(true)

                    this.$notify.warning(this.$filters.translate('tasks.measurement.notify.CHANGED_ASSEMBLY_METHOD') + ' ' +
                        assembly_method.toLowerCase(), false)
                }
            }
        },
        mounted() {
            this.load(true)
        },
        methods: {
            load(mounted) {
                if(!this.frame.type) {
                    if(!mounted) {
                        this.$notify.error('tasks.measurement.notify.error.NO_TYPE_FRAME')
                    }
                } else {
                    this.loading.measurements = true

                    API.post('task/jawro-visu/measurements', {
                        task_id: this.taskId,
                        is_offer: this.isOffer
                    }).then(res => {
                        this.measurements = res.data.measurements

                        let pair = []

                        this.measurements.forEach(measurement => {
                            if(measurement.pass > 0) {
                                pair.push(measurement.measurement_id)
                            }
                        })

                        this.$emit('update-measurement-jawro-visu', {
                            measurement_jawro_visu_id: res.data.measurement_id ? res.data.measurement_id : 0,
                            pair: pair
                        })
                    }).finally(() => {
                        this.loading.measurements = false
                    }).catch(() => {
                        this.$notify.error('tasks.measurement.individual.notify.error.LOADMEASUREMENTS')
                    })
                }
            },

            loadTest() {
                this.loading.test = true

                API.post('task/measurements/test', {
                    task_id: this.taskId,
                    is_offer: this.isOffer,
                    type: 'JawroVisu'
                }).then(res => {
                    if('error' in res.data) {
                        this.$notify.error(res.data.error, false)
                    } else if('task_id' in res.data) {
                        this.load()
                    }
                }).finally(() => {
                    this.loading.test = false
                }).catch(() => {
                    this.$notify.error('tasks.measurement.individual.test.notify.error.LOAD')
                })
            },

            setToggle() {
                if(this.measurementJawroVisuId) {
                    this.toggle = !this.toggle
                }
            },
            toggleImage(m) {
                this.$set(m, 'image', !m.image)
            },

            btTitle(type, measurement, side) {
                return TaskMeasurementTitle.title('JawroVisu', type, measurement, side)
            },

            isEmpty(obj) {
                return _.isEmpty(obj)
            }
        },
        computed: {
            isVisuServer() {
                return this.measurements.length === 2 && this.measurements.filter(m => m.pass > 0).length === 2
            },
            from_task() {
                let filtered = this.measurements.filter(m => m.from_task)

                return filtered.length ? filtered[0].from_task : 0
            }
        }
    }
</script>

<style scoped lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
</style>