<template lang="pug">
    .row
        OptiDimmer(:active="active")
        .col-md-offset-2.col-md-8
            form.form-horizontal
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'services.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="assembly.name" disabled)
                .form-group
                    label.col-sm-4.control-label.star-required(for="retailOwn") {{'services.retail.OWN'|translate}}
                    .col-sm-8
                        OptiNumber(
                            :header="'services.retail.OWN'|translate"
                            :value="assembly.retailOwn"
                            @confirmed="v => assembly.retailOwn = v"
                        ) {{assembly.retailOwn|currency}}
                        OptiValidate(f="retailOwn" :data="$v.assembly.retailOwn")
                .form-group
                    label.col-sm-4.control-label.star-required(for="wholesaleOwn") {{'services.wholesale.OWN'|translate}}
                    .col-sm-8
                        OptiNumber(
                            :header="'services.wholesale.OWN'|translate"
                            :value="assembly.wholesaleOwn"
                            @confirmed="v => assembly.wholesaleOwn = v"
                        ) {{assembly.wholesaleOwn|currency}}
                        OptiValidate(f="wholesaleOwn" :data="$v.assembly.wholesaleOwn")
                .form-group
                    label.col-sm-4.control-label.star-required(for="retailClient") {{'services.retail.CLIENT'|translate}}
                    .col-sm-8
                        OptiNumber(
                            :header="'services.retail.CLIENT'|translate"
                            :value="assembly.retailClient"
                            @confirmed="v => assembly.retailClient = v"
                        ) {{assembly.retailClient|currency}}
                        OptiValidate(f="retailClient" :data="$v.assembly.retailClient")
                .form-group
                    label.col-sm-4.control-label.star-required(for="wholesaleClient") {{'services.wholesale.CLIENT'|translate}}
                    .col-sm-8
                        OptiNumber(
                            :header="'services.wholesale.CLIENT'|translate"
                            :value="assembly.wholesaleClient"
                            @confirmed="v => assembly.wholesaleClient = v"
                        ) {{assembly.wholesaleClient|currency}}
                        OptiValidate(f="wholesaleClient" :data="$v.assembly.wholesaleClient")
                .form-group
                    label.col-sm-4.control-label(for="tips") {{'services.TIPS'|translate}}
                    .col-sm-8
                        textarea#tips.form-control(name="tips" v-model="assembly.tips" rows="7")
                .form-group
                    .col-sm-offset-4.col-sm-4.text-left
                        OptiButton(
                            type="success"
                            @click.native="updateAssembly"
                        ) Zapisz
                            template(#icon)
                                i.fa.fa-save
                    .col-sm-4.text-right
                        OptiButton(
                            @click.native="goBack"
                        ) Wróć
                            template(#icon)
                                i.fa.fa-angle-left
</template>

<script>
import {API} from '@/js/app/vue/api'
import {noZero} from '@/js/vue.validators.js'


import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
import OptiNumber from "@/js/app/vue/components/Inputs/OptiNumber";
import OptiValidate from "@/js/app/vue/components/OptiValidate";
import OptiButton from "@/js/app/vue/components/Button/OptiButton";

export default {
    name: "OptiAssembliesEdit",
    components: {OptiButton, OptiNumber, OptiDimmer, OptiValidate},
    data() {
        return {
            active: false,
            assembly: {
                id: this.$state.params.id,
                name: '',
                retailOwn: 0,
                retailClient: 0,
                wholesaleOwn: 0,
                wholesaleClient: 0
            }
        }
    },
    validations: {
        assembly: {
            retailOwn: {
                noZero
            },
            wholesaleOwn: {
                noZero
            },
            retailClient: {
                noZero
            },
            wholesaleClient: {
                noZero
            }
        }
    },
    mounted() {
        API.get('assemblies/edit/' + this.$state.params.id).then(res => {
            this.assembly = res.data
        })
    },
    methods: {
        async updateAssembly() {
            try {
                this.active = true
                await API.post('assemblies/edit/' + this.$state.params.id + '/update', {
                    assembly: this.assembly
                }).then( res => {
                    this.assembly = res.data
                    this.active = false
                    this.$notify.success('Usługa została zaktualizowana.')
                })
            } catch(e) {
                this.$notify.error('Błąd. Nie można zaktualizować usługi.')
            }

        },
        goBack() {
            this.$state.go('app.assemblies.list')
        }
    }
}
</script>

<style scoped>

</style>