<template lang="pug">
    .row.same-color
        .col-xs-12
            ul.nav.nav-pills
                li(:class="{active: card === 'task'}")
                    a(@click="card = 'task'") {{'tasks.TASK'|translate}}
                li(v-show="!isOffer" :class="{active: card === 'frame'}")
                    a(@click="card = 'frame'") {{'tasks.FRAME'|translate}}
                li(v-show="!isOffer" :class="{active: card === 'lenses'}")
                    a(@click="card = 'lenses'") {{'tasks.LENSES'|translate}}
                li.pull-right(:class="{active: card === 'prices'}" v-if="is_admin_or_manager")
                    a(@click="card = 'prices'") {{'tasks.PRICE'|translate}}

            OptiTaskDetailsLogsTask(v-show="card === 'task'"
                :is-offer="isOffer"
                :logs="logs.task"
                :loading="loading.task")
            OptiTaskDetailsLogsFrame(v-show="!isOffer && card === 'frame'"
                :logs="logs.frame"
                :loading="loading.frame")
            OptiTaskDetailsLogsLenses(v-show="!isOffer && card === 'lenses'"
                :logs="logs.lenses"
                :loading="loading.lenses")
            OptiTaskDetailsLogsPrices(v-show="card === 'prices'"
                :is-offer="isOffer"

                :logs="logs.prices"
                :loading="loading.prices")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiTaskDetailsLogsTask from '@/js/app/vue/components/Tasks/Details/Logs/OptiTaskDetailsLogsTask'
    import OptiTaskDetailsLogsFrame from '@/js/app/vue/components/Tasks/Details/Logs/OptiTaskDetailsLogsFrame'
    import OptiTaskDetailsLogsLenses from '@/js/app/vue/components/Tasks/Details/Logs/OptiTaskDetailsLogsLenses'
    import OptiTaskDetailsLogsPrices from '@/js/app/vue/components/Tasks/Details/Logs/OptiTaskDetailsLogsPrices'

    export default {
        name: 'OptiTaskDetailsLogs',
        components: {
            OptiTaskDetailsLogsPrices,
            OptiTaskDetailsLogsTask,
            OptiTaskDetailsLogsFrame,
            OptiTaskDetailsLogsLenses
        },
        props: {
            changeLogs: {
                type: Object,
                required: true
            },

            isOffer: {
                type: Boolean,
                required: true
            }
        },
        watch: {
            'changeLogs.info'() {
                this.load()
            }
        },
        data() {
            return {
                perm: null,
                card: 'task',

                logs: {
                    task: [],
                    frame: [],
                    lenses: {
                        ll: [],
                        rl: []
                    },
                    prices: {}
                },

                first_time: true,
                is_admin_or_manager: this.$uac.user.activeRole.admin || this.$uac.user.activeRole.manager,
                loading: {
                    task: false,
                    frame: false,
                    lenses: false,
                    prices: false
                }
            }
        },
        mounted() {
            window.addEventListener('hashchange', this.load)
            this.load()
        },
        destroyed() {
            window.removeEventListener('hashchange', this.load)
        },
        methods: {
            load() {
                let hash = location.hash.substr(location.hash.lastIndexOf('#') + 1)

                if(hash === 'history' && (this.first_time || this.changeLogs.info)) {
                    this.loadSection('task')
                    this.loadSection('prices')

                    if(!this.isOffer) {
                        this.loadSection('frame')
                        this.loadSection('lenses')
                    }

                    this.changeLogs.info = false
                    this.first_time = false
                }
            },
            loadSection(section) {
                this.loading[section] = true

                API.get('task/logs/' + (this.isOffer ? 'offer/' :
                    (['task', 'prices'].includes(section) ? 'task/' : '')) +
                    (section === 'task' ? '' : (section + '/')) + this.$state.params.id).then(res => {
                    this.logs[section] = res.data
                }).finally(() => {
                    this.loading[section] = false
                }).catch(() => {
                    this.$notify.error('tasks.logs.notify.error.LOAD')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    ul.nav {
        margin-bottom: 10px;
        cursor: default;

        > li > a {
            cursor: pointer;
        }
    }
</style>