<template lang="pug">
    .row
        .col-md-8.col-md-offset-2
            form.form-horizontal
                .form-group
                    label.col-sm-5.control-label.star-required(for="file") {{'product.import.FILE'|translate}}
                    .col-sm-7
                        OptiUpload#file(
                            prefix="product_import"

                            :file="file"

                            @update-loading="l => loading.file = l"
                            @update-file="f => file = f")
                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButton(type="success" :disabled="!file || loading.file || loading.main"
                            @click.native="goImport") {{'product.import.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-download

        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'product.import.table.HEADING'|translate}}

                .table-responsive(v-show="!loading.main")
                    table.table.table-striped.middle
                        thead
                            tr
                                th #
                                th {{'product.import.table.PRODUCTID'|translate}}
                                th {{'product.import.table.PRODUCTNAME'|translate}}
                                th {{'product.import.table.CATALOG'|translate}}
                                th {{'product.import.table.QUANTITY'|translate}}
                                th {{'product.import.table.PRICE'|translate}}
                                th {{'catalog.product.properties.WHOLESALE'|translate}}
                                th
                        tbody
                            tr(v-for="(product, key) in products")
                                td {{key + 1}}
                                td.text-right
                                    OptiProduct(:product="product") {{product.id}}
                                td
                                    OptiProduct(:product="product") {{product.name}}
                                    small(style="display:block") {{product.fullName}}
                                td {{product.manufacturer}}
                                td {{product.quantity}}
                                td {{product.price|currency}}
                                td {{product.priceWholesale|currency}}
                                td
                                    a(:href="$state.href('app.catalog.product', {id: product.id})" target="_blank")
                                        i.fa.fa-2x.fa-info-circle

                            tr(v-show="!products.length")
                                td.text-muted.text-center(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductImport',
        components: {
            OptiUpload,
            OptiButton,
            OptiProduct,
            OptiDimmer
        },
        data() {
            return {
                file: null,
                products: [],

                loading: {
                    main: false,
                    file: false
                }
            }
        },
        methods: {
            goImport() {
                this.loading.main = true

                this.products = []

                API.post('warehouse/products/import', {
                    file: this.file
                }).then(res => {
                    this.products = res.data.created

                    if(res.data.exists) {
                        this.$notify.error(res.data.exists, false, true)
                    } else {
                        this.$notify.success('product.import.notify.IMPORT')
                    }
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('product.import.notify.error.IMPORT')
                })
            }
        }
     }
</script>