<template lang="pug">
    #department-salon-row
        .row
            .col-sm-6
                form.form.form-horizontal
                    .form-group
                        label.control-label.col-xs-3(for="department_salon") {{'schedule.DEPARTMENT'|translate}}
                        .col-xs-9
                            OptiSelectDepartment#department_salon(
                                :department-id="department.id"
                                :filter="filterOutHeadquarters"
                                :simple="true"
                                :show-all="true"
                                :disabled="disabled"
                                @selected="updateDepartment")
        .row
            .col-md-4.navigation-budget
                OptiButtonToggle(enum="ScheduleViewType" :model="view.type" :disabled="disabled" @change="updateType")

            #navigation-budget-label.col-md-4.text-center(v-show="!edit")
                .btn.btn-default(:disabled="disabled" @click="prevTime")
                    i.fa.fa-chevron-left

                div(@click="goToEditMode")
                    .bold(style="font-size:22px")
                        span.mr3 {{view.label.main}}
                        i.fa.fa-edit
                    div(style="font-size:14px;margin-top:-5px;color:grey") {{view.label.sub}}
                    div(style="font-size:14px;margin-top:-5px;color:red"
                        v-show="view.type === 'day' && holidays[view.start.format('YYYY-MM-DD')]"
                        ) {{holidays[view.start.format('YYYY-MM-DD')]|translate}}

                .btn.btn-default(:disabled="disabled" @click="nextTime")
                    i.fa.fa-chevron-right

            OptiScheduleNavigationEdit(v-if="edit" :view="view" @accepted="dateWasEdited")

            .col-md-4.navigation-budget(style="display:flex;justify-content:space-around;padding-left:0")
                button.btn.btn-default(:disabled="disabled" @click="setToday") {{'schedule.directive.view.today'|translate}}
                button.btn.btn-default(:disabled="disabled" @click="goToVisits") {{'schedule.VISITS'|translate}}

                div(style="margin-top:5px;white-space:nowrap")
                    label.switch.switch-lg.mr3(:disabled="disabled")
                        input(type="checkbox" v-model="view.budget" @change="updateBudget" :disabled="disabled")
                        span
                    label.control-label(:class="{'text-normal': !view.budget}") {{'schedule.budget.BUDGET'|translate}}
</template>

<script>
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiButtonToggle from '@/js/app/vue/components/Button/OptiButtonToggle'
    import OptiScheduleNavigationEdit from '@/js/app/vue/components/Schedule/Navigation/OptiScheduleNavigationEdit'

    export default {
        name: 'OptiScheduleNavigation',
        components: {
            OptiSelectDepartment,
            OptiButtonToggle,
            OptiScheduleNavigationEdit
        },
        props: {
            department: {
                type: Object,
                required: true
            },
            view: {
                type: Object,
                required: true
            },
            chartDepartment: {
                type: Object,
                required: true
            },

            holidays: {
                type: Object,
                required: true
            },

            loading: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                department_disabled: [86, 91, 149, 392],

                edit: false
            }
        },
        mounted() {
            this.setDatesFromView()
            this.setLabels()
            this.changeLink()
        },
        methods: {
            filterOutHeadquarters(items) {
                let allows = [49, 60, 402]  // to samo w OptiPatientSchedule.vue:126

                this.$uac.user.department.forEach(department => {
                    if(!this.department_disabled.includes(department.departmentId)) {
                        allows.push(department.departmentId)
                    }
                })

                return items.filter(department => allows.includes(department.id))
            },

            setDatesFromView() {
                switch(this.view.type) {
                    case 'day':
                        this.view.end = this.view.start.clone()

                        break

                    case 'week':
                        this.view.start = this.view.start.startOf('w')
                        this.view.end = this.view.start.clone().endOf('w')

                        break

                    case 'month':
                        this.view.start = this.view.start.startOf('M')
                        this.view.end = this.view.start.clone().endOf('M')

                        break

                    case 'quarter':
                        let {start, end} = BudgetHelper.setQuarter(this.view.start)

                        this.view.start = start
                        this.view.end = end
                }
            },
            changeLink() {
                if(this.$state.params.department_id !== this.department.id.toString() || this.$state.params.view !== this.view.type ||
                    this.$state.params.date !== this.view.start.format('YYYY-MM-DD') || this.$state.params.budget !== (this.view.budget ? '1' : '0')) {
                    this.$state.go('app.schedule.calendar', {
                        department_id: this.department.id.toString(),
                        view: this.view.type,
                        date: this.view.start.format('YYYY-MM-DD'),
                        budget: this.view.budget ? '1' : '0'
                    }, {
                        notify: false
                    })
                }
            },
            setLabels() {
                let year = this.view.start.format('GG'),
                    week = this.view.start.format('W'),
                    day = this.view.start.format('DD-MM-YYYY'),
                    quarter = BudgetHelper.getQuarterByWeek(this.view.start),

                    quarter_string = `${quarter}Q${year}`,
                    week_string = `${week}W${year}`,
                    day_string = `${day}`

                switch(this.view.type) {
                    case 'day':
                        this.view.label.main = `${week_string} ${quarter_string}`
                        this.view.label.sub = `${day_string}, ${this.view.start.format('dddd')}`

                        break

                    case 'week':
                        this.view.label.main = `${week_string} ${quarter_string}`
                        this.view.label.sub = this.view.start.format('DD-MM-YYYY') + ' - ' + this.view.end.format('DD-MM-YYYY')

                        break

                    case 'month':
                        this.view.label.main = this.view.start.format('MMMM YYYY');
                        this.view.label.main = this.view.label.main.charAt(0).toUpperCase() +
                            this.view.label.main.substr(1, this.view.label.main.length)
                        this.view.label.sub = ''

                        break

                    case 'quarter':
                        let start = this.view.start.clone().startOf('w'),
                            end = this.view.end.clone().endOf('w')

                        this.view.label.main = `${quarter_string}`
                        this.view.label.sub = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY')

                        this.chartDepartment.year = parseInt(this.view.start.format('GGGG'))
                        this.chartDepartment.quarter = BudgetHelper.getQuarterByWeek(this.view.start)
                }
            },

            prevTime() {
                switch(this.view.type) {
                    case 'day':
                        this.view.start.subtract(1, 'd')
                        this.view.end.subtract(1, 'd')

                        break

                    case 'week':
                        this.view.start.subtract(1, 'w').startOf('w')
                        this.view.end.subtract(1, 'w').endOf('w')

                        break

                    case 'month':
                        this.view.start.subtract(1, 'M').startOf('M')
                        this.view.end.subtract(1, 'M').endOf('M')

                        break

                    case 'quarter':
                        let {start, end} = BudgetHelper.changeQuarter(this.view.start, '-')

                        this.view.start = start
                        this.view.end = end
                }

                this.setLabels()
                this.changeLink()

                this.$emit('update-data')
            },
            nextTime() {
                switch(this.view.type) {
                    case 'day':
                        this.view.start.add(1, 'd')
                        this.view.end.add(1, 'd')

                        break

                    case 'week':
                        this.view.start.add(1, 'w').startOf('w')
                        this.view.end.add(1, 'w').endOf('w')

                        break

                    case 'month':
                        this.view.start.add(1, 'M').startOf('M')
                        this.view.end.add(1, 'M').endOf('M')

                        break

                    case 'quarter':
                        let {start, end} = BudgetHelper.changeQuarter(this.view.start, '+')

                        this.view.start = start
                        this.view.end = end
                }

                this.setLabels()
                this.changeLink()

                this.$emit('update-data')
            },
            setToday() {
                switch(this.view.type) {
                    case 'day':
                        this.view.start = moment()
                        this.view.end = moment()

                        break

                    case 'week':
                        this.view.start = moment().startOf('w')
                        this.view.end = moment().endOf('w')

                        break

                    case 'month':
                        this.view.start = moment().startOf('M')
                        this.view.end = moment().endOf('M')

                        break

                    case 'quarter':
                        let {start, end} = BudgetHelper.setQuarter(moment())

                        this.view.start = start
                        this.view.end = end
                }

                this.setLabels()
                this.changeLink()

                this.$emit('update-data')
            },

            updateDepartment(department) {
                this.department.id = department.id
                this.department.login = department.login

                this.changeLink()

                this.$emit('update-department')
            },
            updateType(type) {
                this.view.type = type

                this.setDatesFromView()
                this.setLabels()
                this.changeLink()

                this.$emit('update-data')
            },
            updateBudget() {
                this.changeLink()

                this.$emit('update-data')
            },

            goToVisits() {
                this.$state.go('app.patients.schedule', {
                    department_id: this.department.id.toString(),
                    view: this.view.type === 'day' ? 'day' : 'week',
                    date: this.view.start.format('YYYY-MM-DD')
                })
            },

            goToEditMode() {
                if(this.loading === 'loading') {
                    this.$notify.warning('schedule.notify.LOAD')
                } else {
                    this.edit = true
                }
            },
            dateWasEdited() {
                this.setDatesFromView()
                this.setLabels()
                this.changeLink()

                this.edit = false

                this.$emit('update-data')
            }
        },
        computed: {
            disabled() {
                return this.loading === 'loading' || this.edit
            }
        }
    }
</script>