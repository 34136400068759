<template lang="pug">
    div
        h4.page-header(style="margin-top:0px" v-show="interviews.length > 1")
            .row
                .col-xs-12
                    a.btn.btn-default(style="margin-right:5px;margin-bottom:5px" v-for="previous in interviews.slice(0, show ? interviews.length : 1)"
                        :title="'default.REFERENCETYPE'|translate" :disabled="!previous.status && previous.id !== interviewId"
                        :class="{'previous-active active': previous.id === interviewId}"
                        :href="$state.href('app.optometrist.details', {id: previous.id})")
                        span \#{{previous.id}} {{previous.date|noYearSek}}
                        div
                            span.mr3 {{previous.user}}
                            i.fa.fa-lock(v-show="previous.status === 3" :title="'optometrist.close.TITLE'|translate")
                            i.fa.fa-stethoscope(v-show="previous.status === 2" :title="'optometrist.disease.doctor.TITLE'|translate")
                            i.fa.fa-remove(v-show="previous.status === 0" :title="'optometrist.cancel.TITLE'|translate")

                    .btn.btn-default(style="margin-right:5px;margin-bottom:5px" @click="show = !show")
                        i.fa.fa-lg(:class="{'fa-chevron-left': show, 'fa-chevron-right': !show}")
                        div
                            span.mr3(v-if="show") {{'default.toggle.HIDE'|translate}}
                            span.mr3(v-else) {{'default.toggle.SHOW'|translate}}
                            span {{interviews.length - 1}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiOptometristsDetailsPrevious',
        components: {
            OptiDimmer
        },
        data() {
            return {
                interviewId: parseInt(this.$state.params.id),
                interviews: [],

                show: false,
                loading: true
            }
        },
        mounted() {
            API.get('optometrist/interview/' + this.interviewId + '/previous').then(res => {
                this.interviews = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('optometrist.notify.error.LOAD_PREVIOUS_INTERVIEWS')
            })
        }
    }
</script>