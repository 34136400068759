<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-default
                .panel-body
                    .row.flex-wrap
                        .col-xs-12
                            OptiButton.pull-left.mr3(
                                style="margin-bottom: 5px"
                                @click.native="downloadSelection('for_more')"
                            ) Selekcja More
                                template(#icon)
                                    i.fa.fa-file-excel-o

                            OptiButton.pull-left.mr3(
                                style="margin-bottom: 5px"
                                @click.native="downloadSelection('for_smile')"
                            ) Selekcja Smile
                                template(#icon)
                                    i.fa.fa-file-excel-o

                            OptiButtonHistory.pull-right(
                                :endpoint="'selection/getSelectionHistory'"
                            )

                            OptiButton.pull-right.mr3(
                                @click.native="updateOldStatuses(1)"
                            ) Czyszczenie pliku SMILE

                            OptiButton.pull-right.mr3(
                                @click.native="updateOldStatuses(2)"
                            ) Czyszczenie pliku MORE

                            OptiButton.pull-right(
                                style="margin-right: 3px; margin-bottom: 5px"
                                @click.native="fixProductRelationships"
                            ) Aktualizuj statystyki
                                template(#icon)
                                    i.fa.fa-refresh

                        .col-xs-12
                            OptiButtonDropdown.pull-left.mr3(
                                :simple="true"
                                type="default" style="cursor: pointer; margin-bottom: 5px") {{'selection.heading.SELECTIONS'|translate}}
                                template(#icon)
                                    i.fa.fa-file-excel-o
                                template(#menu)
                                    li
                                        a(@click="downloadSelection('smile')") Smile
                                    li
                                        a(@click="downloadSelection('more')") More
                                    li
                                        a(@click="downloadSelection('kering')") Kering
                                    li
                                        a(@click="downloadSelection('thelios')") Thelios
                                    li
                                        a(@click="downloadSelection('icberlin')") ic! berlin
                                    li
                                        a(@click="downloadSelection('einar')") Einar
                                    li
                                        a(@click="downloadSelection('kaleos')") Kaleos
                                    li
                                        a(@click="downloadSelection('luxottica')") Luxottica
                                    li
                                        a(@click="downloadSelection('notselected')") Inny
                                    li
                                        a(@click="downloadSelection('all')") WSZYSTKO


                            OptiButtonDropdown.pull-right(

                                :simple="true"
                                type="default" style="cursor: pointer; margin-bottom: 5px") {{'selection.heading.SUMMARY'|translate}}
                                template(#icon)
                                    i.fa.fa-file-excel-o
                                template(#menu)
                                    li
                                        a(@click="getExcel('smile')") Smile
                                    li
                                        a(@click="getExcel('more')") More
                                    li
                                        a(@click="getExcel('kering')") Kering
                                    li
                                        a(@click="getExcel('thelios')") Thelios
                                    li
                                        a(@click="getExcel('icberlin')") ic! berlin
                                    li
                                        a(@click="getExcel('einar')") Einar
                                    li
                                        a(@click="getExcel('kaleos')") Kaleos
                                    li
                                        a(@click="getExcel('luxottica')") Luxottica
                                    li
                                        a(@click="getExcel('notselected')") Inny

        .col-xs-12
            .panel.panel-primary
                .panel-heading.clearfix
                    i.fa.fa-navicon.mr3
                    span.h4 {{'selection.heading.STATISTICS'|translate}}

                .panel-body
                    table.table.table-responsive.table-striped.table-hover.text-center
                        thead
                            tr
                                th {{'selection.table.SUPPLIER'|translate}}
                                th {{'selection.table.ACTIVE_PRODUCTS'|translate}}
                                th {{'selection.table.DATABASE'|translate}}
                                th {{'selection.table.SELECTIONS'|translate}}
                        tbody
                            tr(v-for="(value, key) in products")
                                td.text-capitalize {{key}}
                                td {{value.active}}
                                td
                                    span(style="color: green" v-show="(value.active - value.database) === 0") {{value.database}}
                                    span(v-show="(value.active - value.database) !== 0")
                                        span.warn.count-missing.mr3(v-show="value.database !== 'empty'") - {{value.active - value.database}}
                                        span.text-muted.small {{value.database === 'empty' ? 'brak danych' : '/' + value.database}}
                                td
                                    span(style="color: green" v-show="(value.active - value.selections) === 0") {{value.selections}}
                                    span(v-show="(value.active - value.selections) !== 0")
                                        span.warn.count-missing.mr3 - {{value.active - value.selections}}
                                        span.text-muted.small /{{value.selections}}
                        tfoot(v-if="summary")
                            tr
                                td
                                td {{summary.active}}
                                td
                                    span(style="color: green" v-show="(summary.database_active - summary.database) === 0") {{summary.database}}
                                    span(v-show="(summary.database_active - summary.database) !== 0")
                                        span.warn.count-missing.mr3 - {{summary.database_active - summary.database}}
                                        span.text-muted.small /{{summary.database}}
                                td
                                    span(style="color: green" v-show="(summary.active - summary.selection) === 0") {{summary.selection}}
                                    span(v-show="(summary.active - summary.selection) !== 0")
                                        span.warn.count-missing.mr3 - {{summary.active - summary.selection}}
                                        span.text-muted.small /{{summary.selection}}
        OptiProductsSelectionImport(@refreshParent="refreshMissing")

        OptiDimmer(:active="loading.main")
</template>

<script>

import {API} from '@/js/app/vue/api'

import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory.vue'
import OptiUpload from '@/js/app/vue/components/OptiUpload.vue'
import OptiButtonDropdown from '@/js/app/vue/components/Button/OptiButtonDropdown.vue'
import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave.vue'
import OptiProductsSelectionImport from "@/js/app/vue/components/Selection/OptiProductsSelectionImport.vue";

export default {
    name: 'OptiProductsSelection',
    components: {
      OptiButtonSave,
      OptiButtonDropdown,
      OptiUpload,
      OptiButtonHistory,
      OptiDimmer,
      OptiButton,
      OptiProductsSelectionImport,
    },
    data() {
        return {
            file: null,
            products: null,
            summary: null,
            loading: {
                main: false,
                file: false
            }
        }
    },
    mounted() {
        this.refreshMissing()
    },
    methods: {
        refreshMissing() {
          this.loading.main = true

          API.post('selection/getMissings', {
            type: 'simple'
          }).then(res => {
            this.products = res.data.products
            this.summary = res.data.summary
          }).then(() => {
            this.loading.main = false

          }).catch(() => {
            this.$notify.error('selection.notify.ERROR')
          })
        },
        fixProductRelationships() {
          this.loading.main = true
            API.post('selection/fixProductRelationships').then(() => {
                this.loading.main = false
                this.refreshMissing()
            }).catch(() => {
              this.$notify.error('selection.notify.ERROR')
            })
        },
        getExcel(vendor) {
            this.loading.main = true

            API.post('selection/getMissings', {
                type: vendor
            }).then( res => {
                window.open(res.data)
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('selection.notify.ERROR')
            })
        },
        downloadSelection(vendor) {
            this.loading.main = true

            API.get('selection/downloadSelection/' + vendor).then((res) => {
                window.open(res.data);
            }).finally(() =>{
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('Nie można ściągnąć selekcji!')
            })
        },
        updateOldStatuses(vendor) {
            this.loading.main = true

            API.get('selection/updateOldStatuses/' + vendor).then((res) => {
                if(res.data === 0) {
                    this.$notify.success('Wszystkie produkty miały zaktualizowany stary status.')
                } else {
                    this.$notify.success('Zmieniono ' + res.data + ' produktów.')
                }
            }).finally(() =>{
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('Coś poszło nie tak przy aktualizacji starych statusów!')
            })
        }
    },
}
</script>

<style lang="less" scoped>
.count-missing {
    font-size: 18px
}

.panel-body {
    padding: 15px;
    font-size: 16px;

    table {
        tr {
            &:first-child {
                th {
                    padding-top: 20px;
                }
            }

            td {
                padding: 15px 10px;;
            }
        }
    }
}


</style>