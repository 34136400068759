<template lang="pug">
    div(style="display:inline")
        .label.label-primary(style="white-space:normal;margin:2px;display:inline-block" v-for="(member, key) in members"
            v-show="checkAppear(key)") {{member.department.name}}

        a.link(style="white-space:normal;margin:2px;display:inline-block;position:relative"
            :title="more ? 'timeline.HIDE': 'timeline.MORE'|translate" v-show="show"
            @click.prevent.stop="toggle") {{more ? 'timeline.HIDE': 'timeline.MORE'|translate}}
</template>

<script>
    export default {
        name: 'optiMessageMember',
        components: {

        },
        props: {
            members: {
                type: Array,
                required: true,
                default: []
            },
            appear: {
                type: Number
            }
        },
        data() {
            return {
                more: false,
                show: true
            }
        },
        mounted() {
            if(typeof this.appear == 'undefined' || this.members.length <= parseInt(this.appear)) {
                this.show = false
            }
        },
        methods: {
            checkAppear(index) {
                if(!this.appear || index < this.appear || this.more) {
                    return true
                }

                return false
            },
            toggle() {
                this.more = !this.more
            }
        }
    }
</script>
