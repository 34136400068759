export class TaskHoyaHelperClass {
    constructor() {
        this.lock_saturation = {
            cf: ['50%', '75%', '85%'],
            cg: ['90%-40%']
        }
    }

    getOption(options, id) {
        let _return = {}

        options.forEach(rows => {
            _.forEach(rows, row => {
                _.forEach(row, (section, section_name) => {
                    if(isNaN(section_name)) {
                        section.forEach(option => {
                            if(option.id === id) {
                                _return = option
                            }
                        })
                    } else {
                        if(section.id === id) {
                            _return = section
                        }
                    }
                })
            })
        })

        return _return
    }

    findColor(colorations, option_id) {
        let coloration_id_minus = null,
            coloration_id = null

        _.forEach(colorations, coloration_group => {
            coloration_group.forEach(coloration => {
                if(coloration.id === option_id) {
                    coloration_id = option_id
                } else if(typeof coloration.options !== 'undefined') {
                    coloration.options.forEach(o => {
                        if(o.id === option_id) {
                            coloration_id_minus = coloration.id
                            coloration_id = option_id
                        }
                    })
                }
            })
        })

        return {
            coloration_id_minus: coloration_id_minus,
            coloration_id: coloration_id
        }
    }

    disabledColor(coloration, sub_section_name, option) {
        let is_coloration = sub_section_name.startsWith('coloration_'),

            without_cf_and_cg = coloration.without_cf_and_cg && ['coloration_full', 'coloration_gradient'].includes(sub_section_name),
            only_contrast_and_cf = coloration.only_contrast_and_cf && !['coloration_full', 'coloration_contrast'].includes(sub_section_name),
            only_cf_and_cg = coloration.only_cf_and_cg && !['coloration_full', 'coloration_gradient'].includes(sub_section_name),

            disabled_by_saturation_cf = coloration.only_contrast_and_cf && option.options &&
                !option.options.filter(o => o.id > 0 && this.lock_saturation.cf.includes(o.name)).length,

            disabled_by_saturation_cg = coloration.only_cf_and_cg && option.options &&
                !option.options.filter(o => o.id > 0 && !this.lock_saturation.cg.includes(o.name)).length

        return is_coloration && (without_cf_and_cg || only_contrast_and_cf || only_cf_and_cg ||
            disabled_by_saturation_cf || disabled_by_saturation_cg)
    }

    disabledSaturation(coloration, opt) {
        return opt.id < 0 || (coloration.only_contrast_and_cf && !this.lock_saturation.cf.includes(opt.name)) ||
            (coloration.only_cf_and_cg && this.lock_saturation.cg.includes(opt.name))
    }

    sort(data) {
        return Object.keys(data).sort((key1, key2) => {
            if(!key1.includes('undefined')) {
                let old1 = key1,
                    old2 = key2

                if(key1 === 'Konwencjonalna' && key2 === 'TrueForm') {
                    key1 = 1
                    key2 = 2
                } else if(key1 === 'TrueForm' && key2 === 'Konwencjonalna') {
                    key1 = 2
                    key2 = 1
                } else {
                    if(key1.replace(/[^.\d]/g, '')) {
                        key1 = parseFloat(key1.replace(/[^.\d]/g, ''))
                    }

                    if(key2.replace(/[^.\d]/g, '')) {
                        key2 = parseFloat(key2.replace(/[^.\d]/g, ''))
                    }
                }

                if(key1 === key2) {
                    key1 = old1
                    key2 = old2
                }
            }

            return key1 > key2 ? 1 : -1
        }).reduce((obj, key) => {
            obj[key] = data[key]

            return obj
        }, {})
    }

    setTaskExtras(isCompare, ranges, task) {
        if(!isCompare) {
            let task_extras = [...ranges.errors, ...ranges.warnings].map(r => r.task_extras)[0]

            if(task_extras) {
                task_extras.add.forEach(task_extra_id => {
                    if(!task.services.map(s => s.id).includes(task_extra_id)) {
                        task.services.push({
                            id: task_extra_id,
                            pivot: {}
                        })
                    }
                })

                task_extras.delete.forEach(task_extra_id => {
                    _.remove(task.services, s => s.id === task_extra_id)
                })
            } else { // te idiki występują też w TaskRangesPowersHelper.php
                _.remove(task.services, s => [301, 302].includes(s.hoya_option_id))
            }
        }
    }
}

export const TaskHoyaHelper = new TaskHoyaHelperClass()