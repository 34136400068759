<template lang="pug">
    .row
        .col-xs-12(v-if="showList && requests")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'cards.GENERATED'|translate}}

                table.table.table-striped.table-hover.table-responsive.middle
                    thead
                        tr
                            th #
                            th.text-center {{'cards.table.REQUEST_ID'|translate}}
                            th.text-center {{'cards.table.CREATED'|translate}}
                            th.text-center.requests {{'cards.table.CARDS'|translate}}
                            th

                        tr(v-for="(request, index) in requests" :key="request.id")
                            td {{index + 1}}
                            td.text-center {{request.id}}
                            td.text-center {{request.created}}
                            td.text-center.requests {{request.requests}}
                            td(style="cursor:pointer;" @click="openDetails(request.requests)")

                                i.fa.fa-info-circle.fa-2x.fa-fw

                .panel-footer.clearfix
                    .col-xs-12.text-center
                        OptiPaginator(
                            :paginator="paginator"
                            @changed-page="changePage"
                        )
        .col-xs-12(v-if="!showList && details")
            OptiCardsGeneratedView(
                :selected="details"
                :printed="true"
                @go-back="showList = true"
                )


</template>

<script>
    import {API} from '@/js/app/vue/api'
    import OptiPaginator from "@/js/app/vue/components/OptiPaginator";
    import OptiCardsGeneratedView from "@/js/app/vue/components/Patients/LoyalityCard/OptiCardsGeneratedView";

    export default {
        name: 'OptiGeneratedList',
        components: {OptiCardsGeneratedView, OptiPaginator},
        props: {
            reload_printed: null
        },
        data() {
            return {
                paginator: {
                    page: 1,
                    pages: 1
                },
                details: null,
                requests: null,
                showList: true
            }
        },
        mounted() {
            this.startUp()
        },
        watch: {
            reload_printed() {
                if(this.reload_printed === true) {
                    this.$emit('reloaded')
                    this.startUp()
                }
            }
        },
        methods: {
            openDetails(ids) {
                this.showList = false

                API.post('loyality-card/getPritedListDetails', {
                    ids: ids
                }).then((res) => {
                    this.details = res.data
                }).catch(() => {
                    this.$notify.error('cards.notify.ERROR')
                })
            },
            startUp() {
                API.post('loyality-card/getPrintedList', {
                    page: this.paginator.page
                }).then(res => {
                    this.requests = res.data.data
                    this.paginator.pages = res.data.pages
                }).catch(() => {
                    this.$notify.error('cards.notify.ERROR')
                })
            },
            changePage(page) {
                this.paginator.page = page
                this.startUp()
            }
        }
    }
</script>

<style scoped>
    table {
        table-layout: fixed;
    }
    td {
        word-wrap: break-word;
    }
    .requests {
        width: 65%
    }
</style>