<template lang="pug">
    div
        OptiDimmer(:active="loading")
        div(v-show="!loading")
            .panel.panel-primary(style="margin-bottom:0" v-for="department in departments")
                .panel-heading(style="cursor:pointer" @click="setToggle(department)")
                    .pull-left
                        i.fa.fa-building-o.mr3
                        span {{department.name}}
                    .pull-right
                        span.label.label-danger.mr3(v-show="department.quantity") {{department.quantity}}
                        span.label.label-inverse {{department.users.length}}
                    .clearfix

                div(v-for="(user, index) in department.users" :class="{'bg-separator': index % 2}"
                    style="padding-left:10px;padding-right:5px" v-show="department.toggle")
                    label
                        .checkbox.c-checkbox.checkbox-primary.inline
                            input(type="checkbox" v-model="user.checked" :name="'user' + index"
                                @change="setUser()" :disabled="user.disabled")
                            span.fa.fa-check(style="margin-left:0px")
                        span.mr3 {{user.name}}
                        small {{user.role}}

            div(style="margin-bottom:10px" v-if="required")
                div(v-for="error in Object.keys(required.$error)")
                    label.warn(v-show="error === 'users'") {{'search.user.validate.USERS'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import OptiDimmer from "../Blocks/OptiDimmer";

    export default {
        name: 'OptiCheckboxUser',
        components: {OptiDimmer},
        props: {
            ids: {
                type: Array,
                required: true
            },
            disabled: {
                type: Array,
                required: false
            },
            required: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                departments: [],

                loading: true
            }
        },
        watch: {
            ids(nVal) {
                if(nVal.length) {
                    this.departments.forEach(department => {
                        department.quantity = 0

                        department.users.forEach(user => {
                            if(this.ids.indexOf(user.id) > -1) {
                                user.checked = 1

                                ++department.quantity
                            }
                        })
                    })
                }
            }
        },
        mounted() {
            this.setRequired([])

            API.get('search/user').then(res => {
                this.departments = res.data

                this.watch_ids()
                this.watch_disabled()

                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        methods: {
            setRequired(ids) {
                if(this.required) {
                    this.required.$setValidity('users', ids.length > 0)
                }
            },
            setToggle(department) {
                department.toggle = !department.toggle
            },
            setUser() {
                let ids = []

                this.departments.forEach(department => {
                    department.quantity = 0

                    department.users.forEach(user => {
                        if(user.checked) {
                            ++department.quantity

                            ids.push(user.id)
                        }
                    })
                })

                this.$emit('user-ids', ids)
                this.setRequired(ids)
            },

            watch_ids() {
                let watch = this.$watch('ids', ids => {
                    if(ids.length) {
                        this.departments.forEach(department => {
                            department.quantity = 0

                            department.users.forEach(user => {
                                if(this.ids.indexOf(user.id) > -1) {
                                    user.checked = 1

                                    ++department.quantity
                                }
                            })
                        })

                        this.setRequired(ids)

                        setTimeout(() => {
                            watch()
                        }, 1)
                    }
                }, {
                    immediate: true
                })
            },
            watch_disabled() {
                let watch = this.$watch('disabled', disabled => {
                    if(typeof disabled !== 'undefined' && disabled.length > 0) {
                        let ids = this.ids

                        this.departments.forEach(department => {
                            department.users.forEach(user => {
                                if(disabled.indexOf(user.id) > -1) {
                                    if(!user.checked) {
                                        ++department.quantity

                                        ids.push(user.id)
                                    }

                                    user.checked = 1
                                    user.disabled = true
                                }
                            })
                        })

                        this.$emit('user-ids', ids)

                        setTimeout(() => {
                            watch()
                        }, 1)
                    }
                }, {
                    immediate: true
                })
            }
        }
    }
</script>
