import {required, requiredIf} from 'vuelidate/lib/validators'
import {betweenLength, barcode, unique_lp, unique} from '@/js/vue.validators.js'

export default {
    get(product_id, product_barcode) {
        return {
            product: {
                barcode: {
                    required: requiredIf(product => product.customerProduct.id !== 2499),
                    betweenLength: betweenLength(12, 13),
                    barcode: barcode(product_barcode)
                },
                name: {
                    required,
                    unique: unique('products', 'PROD_PRODUCT_NAME', product_id, 'PROD_PRODUCT_ID')
                },
                fullName: {
                    required
                },
                type: {
                    required
                },
                manufacturer: {
                    required
                },
                customerProduct: {
                    id: {
                        required
                    },
                    price: {
                        required
                    }
                },
                categoryWarehouse: {
                    id: {
                        required
                    }
                },
                lp: {
                    required,
                    unique_lp: unique_lp(product_id)
                },

                priceWholesale: {
                    required
                },
                priceSupplier: {
                    required
                },
                vat: {
                    required
                }
            }
        }
    }
}