<template lang="pug">
    .bold.warn(v-show="data.type")
        span(v-if="data.type === 'message'") {{data.text}}
        a.link.warn(v-else-if="data.type === 'sale'" :title="'default.REFERENCETYPE'|translate"
            :href="$state.href('app.sale.details', {id: data.id})" target="_blank") {{data.message}}
        a.link.warn(v-else-if="data.type === 'saleoffstock'" :title="'default.REFERENCETYPE'|translate"
            :href="$state.href('app.sale-offstock.details', {id: data.id})" target="_blank") {{data.message}}
        a.link.warn(v-else-if="data.type === 'task'" :title="'default.REFERENCETYPE'|translate"
            :href="$state.href('app.task.detail', {id: data.id})" target="_blank") {{data.message}}
        span(v-else) {{'coupons.validate.DEFAULT'|translate}}
</template>

<script>
    export default {
        name: 'OptiPaymentCouponValidate',
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>