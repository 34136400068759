<template lang="pug">
    #task-frame.same-color
        .row(v-show="task.frame.allow.allow == false")
            .col-xs-12
                .alert.alert-danger(style="margin-bottom:0")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.frame.notify.error.ALLOWFRAME1'|translate}}
                    span(v-for="task_id in task.frame.allow.task_ids")
                        a.link.mr3(style="color:#fff" :title="'default.REFERENCETYPE'|translate"
                            :href="$state.href('app.task.detail', {id: task_id})") {{task_id}}
                    span.mr3(v-for="task_cancelled_id in task.frame.allow.task_cancelled_ids_with_transfer")
                        a.link.mr3(style="color:#fff" :title="'default.REFERENCETYPE'|translate"
                            :href="$state.href('app.task.detail', {id: task_cancelled_id})"
                            ) {{task_cancelled_id}} {{'tasks.frame.notify.error.TASKTRANSFER'|translate}}
                    span {{'tasks.frame.notify.error.ALLOWFRAME2'|translate}}

        .row(v-show="task.product.expired && task.status < 10")
            .col-xs-12
                .alert.alert-danger(style="margin-bottom:0")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'product.EXPIRED'|translate}} - {{'tasks.frame.notify.error.EXPIRED'|translate}}.

        .row(style="padding-top:10px")
            .col-md-5
                form#form-frame.form-horizontal.form-validate(name="formFrame")
                    .form-group
                        label.col-xs-12 {{'tasks.frame.NAME'|translate}}
                        .col-xs-12
                            OptiSelectFrame(
                                :product="search.frame"
                                :foreign="true"
                                :disabled="block || isOffer || task.frame.blockOverwrite || taskAssembly.blockFrame(task.assemblyId) || task.packId || putBack || taskDuplicate"

                                @selected="selectedFrame")
                    .form-group(v-if="task.productOverwrite.id")
                        label.col-xs-12.warn {{'tasks.frame.OTHERSHAPE'|translate}}
                        .col.xs-12(style="padding-left:15px")
                            OptiProduct.warn(:product="task.productOverwrite") {{task.productOverwrite.name}}
                    .form-group
                        label.col-sm-2
                        .col-sm-10
                            .radio.c-radio.c-radio-nofont
                                label(:class="{'radio-disabled': !!((task.frame.productId || putBack || taskDuplicate) && task.frame.state !== 2)}")
                                    input(type="radio" v-model="task.frame.state" name="state" :value="2" @change="updateState"
                                        :disabled="!!((task.frame.productId || putBack || taskDuplicate) && task.frame.state !== 2)")
                                    span
                                    | {{'tasks.frame.state.FOREIGN'|translate}}

                            .radio.c-radio.c-radio-nofont
                                label(:class="{'radio-disabled': !!(disabledFramePurchaseDepartment && task.frame.state !== 4)}")
                                    input(type="radio" v-model="task.frame.state" name="state" :value="4" @change="updateState"
                                        :disabled="!!(disabledFramePurchaseDepartment && task.frame.state !== 4)")
                                    span
                                    | {{'tasks.frame.state.PURCHASEDDEPARTMENT'|translate}}

                            .radio.c-radio.c-radio-nofont
                                label(:class="{'radio-disabled': !!(disabledFrameDepartment && task.frame.state !== 1)}")
                                    input(type="radio" v-model="task.frame.state" name="state" :value="1" @change="updateState"
                                        :disabled="!!(disabledFrameDepartment && task.frame.state !== 1)")
                                    span
                                    | {{'tasks.frame.state.FROMDEPARTMENT'|translate}}

                            .radio.c-radio.c-radio-nofont
                                label(:class="{'radio-disabled': !!(disabledFrameWarehouse && task.frame.state !== 0)}")
                                    input(type="radio" v-model="task.frame.state" name="state" :value="0" @change="updateState"
                                        :disabled="!!(disabledFrameWarehouse && task.frame.state !== 0)")
                                    span
                                    | {{'tasks.frame.state.FROMWAREHOUSE'|translate}}

                    .form-group(v-show="task.frame.state !== 2")
                        label.col-sm-4.control-label(for="manufacturer") {{'tasks.frame.PRODUCENT'|translate}}
                        .col-sm-8
                            #manufacturer.form-control(disabled) {{task.frame.manufacturer}}
                            .warn(v-show="!task.frame.manufacturer") {{'validation.formValidate.required'|translate}}
                    .form-group
                        label.col-sm-4.control-label(for="type") {{'tasks.frame.TYPE'|translate}}
                        .col-sm-8
                            OptiSelectEnum#type.form-control(enum="TaskFrameType"
                                :disabled="!allowEditConfirmed || (task.frame.state !== 2 && task.product.type && !task.frame.change_type) || task.frame.blockOverwrite || (task.packId && task.product.type) || taskAssembly.blockFrame(task.assemblyId) || (task.product.type && !task.frame.change_type) || putBack || taskDuplicate"
                                :value="task.frame.type" @update-enum="updateType")
                            .warn(v-show="!task.frame.type") {{'validation.formValidate.required'|translate}}
                    .form-group(v-show="task.frame.state === 0 || task.frame.state === 1")
                        label.col-sm-4.control-label(for="price") {{'tasks.PRICE'|translate}}
                        .col-sm-8
                            OptiNumber#price.text-right(:header="'tasks.PRICE'|translate" :float="true"
                                :disabled="!(forSc || taskDuplicate || taskService)" :pending="pending" :required="true"
                                :value="task.frame.price" @confirmed="updatePrice") {{task.frame.price|currency}}
                            .warn.bold(v-show="!task.frame.price") {{'validation.formValidate.required'|translate}}

            .col-md-7
                .row(v-if="task.product.id")
                    .col-xs-12.frame-info
                        img(:src="'thumb/product/ids/' + (task.frame.productId ? task.frame.productId : 0) + '.jpg'")
                        OptiDepositoryInfo.frame-depository-info(:product="task.product")
                        .type.bold
                            a.link(:href="$state.href('app.catalog.product', {id: task.frame.productId, departmentId: task.departmentId})" target="_blank"
                                :title="'default.REFERENCETYPE'|translate") {{task.frame.productId}}

                .alert.alert-info(style="margin-bottom:0;border-color:none;color:#000" v-show="task.dbl || task.HBOX || task.VBOX")
                    .row
                        .col-xs-4.text-center
                            div {{'tasks.frame.HEIGHT'|translate}}
                            div {{task.frame.height|number(1)|mm}}
                        .col-xs-4.text-center
                            div {{'tasks.frame.WIDTH'|translate}}
                            div {{task.frame.width|number(1)|mm}}
                        .col-xs-4.text-center
                            div {{'tasks.extension.BRIDGE'|translate}}
                            div {{task.dbl|number(1)|mm}}

                .row(v-show="shape.direction.info")
                    .col-xs-12
                        .alert.alert-info(style="margin-bottom:0;border-color:none;color:#000")
                            i.fa.fa-info-circle.mr3
                            span {{shape.direction.info}}

                .row(style="margin-top:10px" v-show="permission.products_edit && task.frame.productId && task.frame.state !== 2")
                    .col-xs-12.text-right
                        OptiButton(type="default" @click.native="dialog.frameAttrInfo = true") {{'tasks.frame.ADDINFO'|translate}}
                            template(#icon)
                                i.fa.fa-plus

                        MountingPortal(v-if="dialog.frameAttrInfo" mountTo="#vue-modal" append)
                            OptiTaskDetailsFrameDialogInfo(
                                :product-id="task.frame.productId"
                                :frame-attr-info="shape.direction.info"

                                @frame-attr-was-updated="updateAttrWasUpdated"
                                @closed="dialog.frameAttrInfo = false")

        OptiTaskDetailsFrameBevel(
            :bevel="task.bevel"
            :frame-type="task.frame.type"

            :block="block"

            @bevel-was-updated="$emit('frame-was-updated')")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {TaskAssembly} from '../TaskAssembly'

    import OptiSelectFrame from '@/js/app/vue/components/Select/OptiSelectFrame'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsFrameDialogInfo
        from '@/js/app/vue/components/Tasks/Details/Frame/Dialogs/OptiTaskDetailsFrameDialogsInfo'
    import OptiTaskDetailsFrameBevel from '@/js/app/vue/components/Tasks/Details/Frame/OptiTaskDetailsFrameBevel'

    export default {
        name: 'OptiTaskDetailsFrame',
        components: {
            OptiSelectFrame,
            OptiProduct,
            OptiSelectEnum,
            OptiNumber,
            OptiDepositoryInfo,
            OptiButton,
            OptiTaskDetailsFrameDialogInfo,
            OptiTaskDetailsFrameBevel
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            shape: {
                type: Object,
                required: true
            },

            putBack: {
                type: Boolean,
                required: true
            },
            forSc: {
                type: Boolean,
                required: true
            },
            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                taskAssembly: TaskAssembly,

                search: {
                    frame: {
                        id: this.task.frame.productId,
                        name: this.task.frame.partName
                    }
                },

                permission: {
                    products_edit: this.$uac.permission('products.edit')
                },
                dialog: {
                    frameAttrInfo: false
                }
            }
        },
        beforeMount() {
            this.attributes()

            if(typeof this.task.bevel.id === 'undefined') {
                this.task.bevel = {
                    task_id: this.task.id,
                    edge: 0,
                    position: 0,
                    radio: 30
                }
            }
        },
        methods: {
            attributes() {
                if(this.task.frame.productId) {
                    this.resetShapeDirection(false)

                    API.get('task/frame/direction/' + this.task.frame.productId).then(res => {
                        this.shape.direction = res.data

                        if(!this.block) {
                            if(!this.shape.direction.top) this.task.heightAdditionTop = 0
                            if(!this.shape.direction.bottom) this.task.heightAdditionBottom = 0
                            if(!this.shape.direction.temporal) this.task.widthTemporal = 0
                            if(!this.shape.direction.nasal) this.task.widthNasal = 0

                            this.shape.extension = this.task.widthTemporal + this.task.widthNasal
                        }
                    }).catch(() => {
                        this.resetShapeDirection(false)

                        this.$notify.error('tasks.frame.notify.ERRORINFOFRAME')
                    })
                } else {
                    this.resetShapeDirection(this.task.frame.state === 2 && ['TwoPoint', 'Nylon'].includes(this.task.frame.type))
                }
            },
            selectedFrame(product) {
                this.search.frame = product

                this.task.frame.productId = this.search.frame.id
                this.task.frame.partName = this.search.frame.name

                this.attributes()

                this.task.frame.productIdOverwrite = 0
                this.task.frame.productNameOverwrite = ''

                this.$emit('frame-was-updated')
            },
            updateState(state) {
                if(state === 2) {
                    this.task.frame.manufacturer = ''
                } else if(state !== 0 && state !== 1) {
                    this.task.frame.price = 0
                }

                if(state === 2 && (['TwoPoint', 'Nylon'].includes(this.task.frame.type))) {
                    this.resetShapeDirection(true)
                }

                this.$emit('frame-was-updated')
            },
            updateType(type) {
                this.task.frame.type = type

                if(this.task.frame.state === 2) {
                    this.resetShapeDirection(['TwoPoint', 'Nylon'].includes(this.task.frame.type))
                }

                this.$emit('frame-was-updated')
            },
            updatePrice(price) {
                this.task.frame.price = price

                this.$emit('frame-was-updated')
            },
            updateAttrWasUpdated(info) {
                this.shape.direction.info = info

                this.dialog.frameAttrInfo = false
            },

            resetShapeDirection(bool) {
                this.shape.direction = {
                    frameInfo: '',
                    top: bool,
                    bottom: bool,
                    temporal: bool,
                    nasal: bool
                }
            }
        },
        computed: {
            disabledFramePurchaseDepartment() {
                return !this.allowEditConfirmed || !this.task.frame.productId || this.task.frame.blockOverwrite ||
                    TaskAssembly.blockFrame(this.task.assemblyId) || this.task.packId || this.putBack || this.taskDuplicate
            },
            disabledFrameDepartment() {
                return !this.allowEditConfirmed || !this.task.frame.productId || this.task.frame.blockOverwrite ||
                    TaskAssembly.blockFrame(this.task.assemblyId) || this.task.packId || this.putBack || this.taskDuplicate ||
                    ((this.task.product.quantity > 0) && this.task.product.depository.stock <= 0 && this.task.product.id) || this.task.product.expired
            },
            disabledFrameWarehouse() {
                return !this.allowEditConfirmed || !this.task.frame.productId || this.task.frame.blockOverwrite ||
                    TaskAssembly.blockFrame(this.task.assemblyId) || this.task.packId || this.putBack || this.taskDuplicate ||
                    (this.task.product.quantity <= 0 && this.task.product.id) || this.task.product.expired
            }
        }
    }
</script>

<style lang="less" scoped>
    label.radio-disabled {
        color: #808080;
        cursor: default !important;

        > span {
            cursor: default !important;
        }
    }
</style>