<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'dictionary.pictogram.icons.EDIT'|translate}} \#{{details.id}}

                .panel-body
                    .row
                        .col-xs-12(v-if="details.id")
                            .form-group
                                OptiSwitchToggleButton(:checked="details.active" @input="a => details.active = a")
                                    template(#left) {{'dictionary.pictogram.icons.ISNOTACTIVE'|translate}}
                                    template(#right)  {{'dictionary.pictogram.icons.ISACTIVE'|translate}}

                        .col-sm-4
                            .form-group
                                label(for="id") {{'dictionary.pictogram.icons.ID'|translate}}
                                input#id.form-control(v-model="details.id" name="id" disabled)
                            .form-group
                                label(for="name") {{'dictionary.pictogram.icons.NAME'|translate}}
                                input#name.form-control(v-model="details.name" name="name")
                        .col-sm-4
                            .form-group
                                label(for="group") {{'dictionary.pictogram.icons.GROUP'|translate}}
                                OptiSelectEnum#group.form-control(enum="PackGroupType"
                                    :value="details.groupId" @update-enum="g => details.groupId = g")
                            .form-group
                                label(for="subgroup") {{'dictionary.pictogram.icons.SUBGROUP'|translate}}
                                input#subgroup.form-control(v-model="details.subgroup" name="subgroup")
                        .col-sm-4
                            .form-group
                                label(for="order") {{'dictionary.pictogram.icons.ORDER'|translate}}
                                input#order.form-control(v-model="details.order" name="order")
                        .col-xs-12
                            .form-group
                                label(for="who") {{'dictionary.pictogram.icons.WHO'|translate}}
                                input#who.form-control(v-model="details.who" name="who")
                        .col-xs-12
                            .form-group
                                label(for="why") {{'dictionary.pictogram.icons.WHY'|translate}}
                                input#why.form-control(v-model="details.why" name="why")

                .panel-footer.text-right
                    OptiButtonSave(@click.native="save")

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryPictogramsIconEdit',
        components: {
            OptiSwitchToggleButton,
            OptiSelectEnum,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                details: Object,

                loading: true
            }
        },
        mounted() {
            API.get('dictionary/pictograms/icon/' + this.$state.params.id).then(res => {
                this.details = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('dictionary.pictogram.icons.notify.error.LOAD')
            })
        },
        methods: {
            save() {
                this.loading = true

                API.post('dictionary/pictograms/icon/edit', this.details).then(() => {
                    this.$notify.success('dictionary.pictogram.icons.notify.EDIT')

                    this.$state.go('app.dictionary.pictograms.icons.list')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dictionary.pictogram.icons.notify.error.EDIT')
                })
            }
        }
    }
</script>