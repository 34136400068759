<template lang="pug">
    .panel-body(style="padding-top:0")
        .row
            .col-sm-6.product(:class="{'col-md-4': products.length < 4, 'col-md-3': products.length === 4}"
                v-for="product in products" :key="product.id")
                OptiProductInfo(:class="{'opti-product-info-small': products.length === 4}"
                    :product="product"
                    :department-id="departmentId"

                    :is-offer="true")

                div(style="margin-bottom:15px" v-if="product.pivot.hoya_option_id")
                    div {{'catalog.lenses.properties.heading.' + product.pivot.hoya_option.section|translate}}
                    div(style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis"
                        :title="product.pivot.hoya_option.name") {{product.pivot.hoya_option.name}}

                a(v-if="product.pivot.photo" :href="'upload/offer/' + product.pivot.photo" target="_blank")
                    img.img-thumbnail(style="margin-bottom:5px;width:100%" :src="'upload/offer/' + product.pivot.photo")

                div
                    table.table.middle(style="width:100%" v-for="(task, key) in product.pivot.tasks" :class="{'bg-separator': key % 2}")
                        tbody
                            tr(v-show="task.offer_temporary_task_id")
                                td
                                    span.mr3 {{'catalog.offer.breadcrumb.TEMPORARY_TASK'|translate}}
                                    small.text-muted ({{task.type|translate}})
                                td
                                    div(style="display:flex;justify-content:flex-end;align-items:center")
                                        a.link.mr3(:title="'catalog.offer.breadcrumb.TEMPORARY_TASK'|translate|lower"
                                            :href="$state.href('app.catalog.offer-temporary-task', {id: task.offer_temporary_task_id})"
                                            ) {{task.offer_temporary_task_id}}

                                        OptiRateWrapper(
                                            :load="true"
                                            :ref-type="2"
                                            :ref-id="task.offer_temporary_task_id"

                                            @reload="$emit('reload')")

                            tr(v-show="task.offer_task_id_1")
                                td {{'catalog.offer.task_choice.TASK_1'|translate}}
                                td.text-right
                                    a.link(:title="'catalog.offer.task_choice.TASK_1'|translate|lower"
                                        :href="$state.href('app.task.detail', {id: task.offer_task_id_1})"
                                        ) {{task.offer_task_id_1}}

                            tr(v-show="task.offer_task_id_2")
                                td {{'catalog.offer.task_choice.TASK_2'|translate}}
                                td.text-right
                                    a.link(:title="'catalog.offer.task_choice.TASK_2'|translate|lower"
                                        :href="$state.href('app.task.detail', {id: task.offer_task_id_2})"
                                        ) {{task.offer_task_id_2}}

                .text-center.warn(style="margin-top:15px" v-show="product.pivot.choice_by_client")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'catalog.offer.choice.byClient.HEADER'|translate}}
</template>

<script>
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiRateWrapper from '@/js/app/vue/components/Rate/OptiRateWrapper'

    export default {
        name: 'OptiCatalogOfferHeaderProducts',
        components: {
            OptiProductInfo,
            OptiRateWrapper
        },
        props: {
            products: {
                type: Array,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    table {
        margin-bottom: 0 !important;

        &:first-child > tbody > tr > td,
        > tbody > tr:not(:first-child) > td {
            border: none !important;
        }
    }

    .product {
        margin-bottom: 10px;
    }
</style>