<template lang="pug">
    #settings-offer-questions-edit
        .row(v-if="!loading")
            .col-sm-3.text-center-xs(style="margin-bottom:20px")
                OptiButtonHistory(endpoint="settings/offer/questions/logs")

            .col-sm-6.text-center(style="margin-bottom:20px")
                div(style="display:flex;justify-content:space-around")
                    OptiButton(@click.native="$state.go('app.settings-offer.report-difference')") {{'sidebar.settings.offers.DIFFERENCE'|translate}}
                        template(#icon)
                            i.fa.fa-exclamation

                    OptiButtonHelp(
                        icon="google"
                        header="settings.offer.questions.help.HEADER"
                        img="img/offer/offer-survey-google-drive.jpeg"
                        description="settings.offer.questions.help.DESCRIPTION"
                        link="https://docs.google.com/document/d/1SkFA2fC0eM59e3mULFlLT1v9Y9e0zyjoPtaqspqaKUI/edit")

            .col-sm-3.text-right.text-center-xs(style="margin-bottom:20px")
                OptiButton(@click.native="toggleAllOpen") {{'settings.offer.questions.btn.all_open.' + (allOpen ? 'CLOSE' : 'OPEN')|translate}}
                    template(#icon)
                        i.fa(:class="{'fa-angle-double-up': allOpen, 'fa-angle-double-down': !allOpen}")

            .col-xs-12(v-for="(question, key_question) in questions" :key="question.name")
                h4.page-header.editable(:title="'default.toggle.' + (question.open ? 'HIDE': 'SHOW')|translate"
                    :style="[!key_question ? {'margin-top': 0} : null]" @click="question.open = !question.open")
                    small.text-muted.mr3 {{key_question + 1}}
                    span {{'catalog.offer.questions.' + question.name + '.HEADING'|translate}}
                    i.fa.fa-pencil(style="margin-left:10px" v-show="question.attributes.length")

                    i.fa.pull-right(:class="{'fa-angle-double-up': question.open, 'fa-angle-double-down': !question.open}")

                OptiSettingsOfferDifferenceQuestion(:question="question"
                    @open-dialog="d => dialog.products = d")

                .row(style="position:relative" v-show="question.open")
                    .col-xs-12(v-for="(option, key_option) in question.options" :key="option.name")
                        OptiSettingsOfferQuestionsEditAttributes(
                            :question="question"
                            :option="option"
                            :key-option="key_option"

                            @save="save")

                    OptiDimmer(:active="question.loading")

            OptiProductsListDialog(v-if="dialog.products"
                endpoint="settings/offer/questions/products"
                :params="dialog.products"

                @close="dialog.products = null")
                template(#header)
                    i.icon-eyeglasses

                    span.ml30
                        span.mr3 {{'settings.offer.questions.products.ATTRIBUTE_TYPE'|translate}}
                        span.bold {{'catalog.offer.questions.attributes.' + dialog.products.attribute_ids[0]|translate}}

                    span.ml30
                        span.mr3 {{'settings.offer.questions.products.NAME'|translate}}
                        span.bold(:class="{'warn': !dialog.products.value}") {{dialog.products.value ? dialog.products.value : $filters.translate('settings.offer.questions.difference.NO_TRANSLATE')}}

        .row(v-else)
            .col-xs-12
                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonHelp from '@/js/app/vue/components/Button/OptiButtonHelp'
    import OptiSettingsOfferDifferenceQuestion
        from '@/js/app/vue/components/Settings/Offer/OptiSettingsOfferDifferenceQuestion'
    import OptiSettingsOfferQuestionsEditAttributes
        from '@/js/app/vue/components/Settings/Offer/Questions/OptiSettingsOfferQuestionsEditAttributes'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsOfferQuestionsEdit',
        components: {
            OptiButtonHistory,
            OptiButton,
            OptiButtonHelp,
            OptiSettingsOfferDifferenceQuestion,
            OptiSettingsOfferQuestionsEditAttributes,
            OptiProductsListDialog,
            OptiDimmer
        },
        data() {
            return {
                questions: [],

                dialog: {
                    survey_google: false,
                    products: false
                },
                loading: true
            }
        },
        mounted() {
            API.get('settings/offer/questions').then(res => {
                this.questions = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.offer.questions.notify.error.QUESTIONS_LOAD')
            })
        },
        methods: {
            save(question_name, option_name, selected_to_edit) {
                let question_index = this.questions.findIndex(question => question.name === question_name)

                this.questions[question_index].loading = true

                API.post('settings/offer/question/save', {
                    question_name: question_name,
                    option_name: option_name,
                    open_options: this.questions[question_index].options.filter(o => o.open).map(o => o.name),
                    selected_to_edit: selected_to_edit
                }).then(res => {
                    this.$set(this.questions, question_index, res.data)

                    this.$notify.success('settings.offer.questions.notify.SAVE')
                }).finally(() => {
                    this.questions[question_index].loading = false
                }).catch(() => {
                    this.$notify.error('settings.offer.questions.notify.error.SAVE')
                })
            },
            toggleAllOpen() {
                let allOpen = this.allOpen

                this.questions.forEach(question => {
                    question.open = !allOpen

                    question.options.forEach(option => {
                        option.open = !allOpen
                    })
                })
            }
        },
        computed: {
            allOpen() {
                return _.isEqual([...new Set(this.questions.map(q => [q.open].concat(q.options.map(o => o.open))).flat())], [true])
            }
        }
    }
</script>