<template lang="pug">
    OptiDialog.fullscreen(:header="false")
        template(#body)
            //OptiOptometristsDisplayControls

            .correction-dialog
                .dialog-contents(v-if="!loading")
                    table.table
                        tr
                            OptiOptometristsSurveysDialogsPilotPath(
                                :view="view"

                                @change-view="changedView")

                            td.text-center(style="padding:10px")
                                .text-expressive(style="font-size:18px")
                                    i.fa.fa-eye.mr3
                                    span.mr3 {{view.name}}

                                keep-alive
                                    OptiOptometristsSurveysOptotypesVisus(
                                        v-if="view.id === 'visus'"
                                        :permutation="permutation.selected"
                                        :dirty="dirty"
                                        :type="type"
                                        :visus="viewModel[view.id].visus")

                                    OptiOptometristsSurveysOptotypesRedGreen(
                                        v-if="view.id === 'cz'"
                                        :permutation="permutation.selected"
                                        :type="type"
                                        :cz="viewModel[view.id].cz"
                                        :read-only="true")

                                    OptiOptometristsSurveysOptotypesRedGreenMirror(
                                        v-if="view.id === 'ee'"
                                        :permutation="permutation.selected"
                                        :cz="viewModel[view.id].cz"
                                        :read-only="true")

                                    OptiOptometristsSurveysOptotypesClock(
                                        v-if="view.id === 'clock'"
                                        :axis="viewModel[view.id].axis"

                                        @update-axis="v => viewModel[view.id].axis = v")

                                    OptiOptometristsSurveysOptotypesSalt(
                                        v-if="view.id === 'salt'")

                                .optometrist-clock(v-if="view.id === 'dot'")
                                    .optometrist-clock-image
                                        img(style="margin:20px auto 10px" src="img/optometrist/screening-test/maddox/screen.png")

                                .optometrist-clock(v-if="view.id === 'worth'")
                                    .optometrist-clock-image
                                        img(style="margin:20px auto 10px" src="img/optometrist/screening-test/worth/screen.png")

                            td.correction-controls
                                .form-group(v-show="['visus', 'cz', 'ee'].includes(view.id)")
                                    label.control-label.col-xs-12.text-center(style="color:#fff") {{'optometrist.video.RANDOM'|translate}}
                                    .col-xs-12(style="white-space:nowrap;display:flex;justify-content:space-between;padding-bottom:10px")
                                        template(v-for="shuffle in permutation.shuffle")
                                            label.btn.btn-default(:for="'random' + shuffle"
                                                :class="{'btn-warning active': permutation.selected.active === shuffle}") {{shuffle + 1}}
                                            input.hide(type="radio" :id="'random' + shuffle" v-model="permutation.selected.active"
                                                name="random" :value="shuffle" @change="updatePermutation()")

                                OptiOptometristsSurveysOptotypesType(
                                    v-show="view.id === 'visus' || view.id === 'cz'" :with-kids="true"
                                    :type="type" @update-type="t => type = t")

                OptiDimmer(:active="loading")
        template.text-right(#footer)
            .row
                .col-xs-12.text-right
                    OptiButton#cancelled(type="danger" @click.native="cancelled()") {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {PermutationsService} from '@/js/app/vue/components/Optometrists/Helpers/PermutationsService'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    import OptiOptometristsSurveysOptotypesType
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysOptotypesType'
    import OptiOptometristsSurveysDialogsPilotPath
        from '@/js/app/vue/components/Optometrists/Surveys/Dialogs/OptiOptometristsSurveysDialogsPilotPath'

    import OptiOptometristsSurveysOptotypesVisus
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesVisus'
    import OptiOptometristsSurveysOptotypesRedGreen
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreen'
    import OptiOptometristsSurveysOptotypesRedGreenMirror
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreenMirror'
    import OptiOptometristsSurveysOptotypesClock
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesClock'
    import OptiOptometristsSurveysOptotypesSalt
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesSalt'

    export default {
        name: 'OptiOptometristsSurveysDialogsPilot',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer,

            //OptiOptometristsDisplayControls: () => import('@/js/app/vue/components/Optometrists/Surveys/Dialogs/OptiOptometristsSurveysDialogsPilot'),
            OptiOptometristsSurveysOptotypesType,
            OptiOptometristsSurveysDialogsPilotPath,

            OptiOptometristsSurveysOptotypesVisus,
            OptiOptometristsSurveysOptotypesRedGreen,
            OptiOptometristsSurveysOptotypesRedGreenMirror,
            OptiOptometristsSurveysOptotypesClock,
            OptiOptometristsSurveysOptotypesSalt
        },
        props: {

        },
        data() {
            return {
                permutation: {
                    unique: null,
                    shuffle: [],
                    selected: {}
                },

                type: 'letter',

                views: [{
                    id: 'visus',
                    name: 'Visus',
                    path: 'rVisus1'
                }, {
                    id: 'cz',
                    name: 'Test Czerwono-Zielony',
                    path: 'rCZ1'
                }, {
                    id: 'ee',
                    name: 'Test Czerwono-Zielony EE',
                    path: 'rEE1'
                }, {
                    id: 'clock',
                    name: 'Zegar',
                    path: 'rClock'
                }, {
                    id: 'salt',
                    name: 'Solniczka',
                    path: 'rSalt0'
                }, {
                    id: 'dot',
                    name: 'Oko dominujące / Test Maddoxa',
                    path: 'dot'
                }, {
                    id: 'worth',
                    name: 'Test Wortha',
                    path: 'worth'
                }],
                viewModel: {
                    visus: {
                        visus: '0,05'
                    },
                    cz: {
                        cz: '-'
                    },
                    ee: {
                        cz: '-'
                    },
                    clock: {
                        axis: 'brak'
                    }
                },
                view: {},

                dirty: false,

                loading: true
            }
        },
        mounted() {
            this.changedView(0)

            PermutationsService.load().then(res => {
                [this.permutation.unique, this.permutation.shuffle, this.permutation.selected] = res
                this.loading = false
            })
        },
        methods: {
            changedView(index) {
                this.view = this.views[index]

                switch(index) {
                    case 5:
                        OptometristsDisplay.message.open('/optometrist/screen/maddox')

                        break

                    case 6:
                        OptometristsDisplay.message.open('/optometrist/screen/worth')
                }
            },
            cancelled() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')

                this.$emit('cancelled')
            },
            updatePermutation() {
                PermutationsService.updatePermutation(this.permutation)
            }
        }
    }
</script>