<template lang="pug">
    .panel-grid
        div(v-show="!loading && loyality.id")
            .row
                .col-xs-12
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'cards.LOYALITYCARD'|translate}}

                        .panel-body
                            .row
                                .col-sm-6 {{'cards.CREATED'|translate}}
                                .col-sm-6 {{loyality.deliveryDate}}
                            .row
                                .col-sm-6 {{'cards.LASTEVENT'|translate}}
                                .col-sm-6 {{loyality.lastActivityDate}}
                            .row
                                .col-sm-6 {{'cards.ACTIVE'|translate}}
                                .col-sm-6
                                    i.fa.fa-check(v-show="loyality.active === 'Yes'")
                            .row
                                .col-sm-6
                                    label {{'cards.TYPE'|translate}}
                                .col-sm-6.form-control-static {{loyality.typeTranslate|translate}}

            .row
                .col-xs-12
                    OptiButton(type="primary" :disabled="block" @click.native="$state.go('app.patient.loyality-order', {patientId: $state.params.id, cardId: loyality.id, cardType: loyality.typeId})"
                        ) {{'cards.order.BTNORDER'|translate}}
                        template(#icon)
                            i.fa.fa-tag

                    OptiButton.pull-right(type="primary" :disabled="block" @click.native="$state.go('app.patient.loyality-event-add', {patientId: $state.params.id, cardId: loyality.id})"
                        ) {{'cards.ADDEVENT'|translate}}
                        template(#icon)
                            i.fa.fa-plus

            .row(v-show="card_position_deleted")
                .col-xs-12.text-center(style="margin:10px 0 0")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'cards.notify.DELETED'|translate}}

            .row(v-show="loyality.events.length")
                .col-xs-12
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'cards.event.EVENTS'|translate}}

                        .table-responsive
                            table.table.table-condensed.middle
                                thead
                                    tr
                                        th #
                                        th {{'cards.event.DATE'|translate}}
                                        th {{'cards.event.TYPE'|translate}}
                                        th {{'default.REFERENCEID'|translate}}
                                        th {{'cards.event.DESCRIPTION'|translate}}
                                        th {{'cards.event.SALON'|translate}}
                                        th {{'cards.event.PRICE'|translate}}
                                        th(ng-show="allow && !block")

                                tbody
                                    template(v-for="(event, key) in loyality.events")
                                        tr(:class="{'tr-stripped': key % 2}")
                                            td {{loyality.events.length - key}}
                                            td {{event.date}}
                                            td {{event.typeName|translate}}
                                            td
                                                OptiRefId(section="loyality-card" :type-id="event.typeId" :id="event.refId")
                                            td(v-html="$options.filters.nl2br(event.description)")
                                            td {{event.department.name}}
                                            td.text-right {{event.price|currency}}
                                            td.text-center(v-show="allow && !block")
                                                a(style="margin:0 5px;text-decoration:none" :title="'cards.event.edit.TITLE'|translate"
                                                    :href="$state.href('app.patient.loyality-event-edit', {patientId: $state.params.id, eventId: event.id})")
                                                    i.fa.fa-2x.fa-edit
                                                label.warn.fa.fa-2x.fa-remove(:for="'btn-event-delete-' + key"
                                                    style="margin:0 5px;text-decoration:none;cursor:pointer")

                                                OptiButton.hide(:id="'btn-event-delete-' + key" text="cards.event.delete.confirm"
                                                    @confirmed="deleteEvent(event.id)")
                                        tr(v-if="event.notice" :class="{'tr-stripped': key % 2}")
                                            td.text-right(colspan="6" style="border-top:0")
                                                OptiReceiptNotice(:data="event.notice.all")
                                            td.text-right(style="border-top:0")
                                                OptiReceiptNoticeValue(:data="event.notice.all")

                                tfoot(v-show="loyality.events.length > 1")
                                    tr.bold.text-expressive
                                        td.text-right(colspan="6") {{'cards.event.SUM'|translate}}
                                        td.text-right {{totalPrice(loyality.events)|currency}}
                                        td(v-show="allow && !block")

        .row(v-show="!loading && !loyality.id")
            .col-xs-12
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'cards.notify.NOCARD'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {ReceiptNotice} from '@/js/app/vue/helpers/ReceiptNotice'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
    import OptiDimmer from '../../../Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsLoyalityCards',
        components: {
            OptiButton,
            OptiRefId,
            OptiReceiptNotice,
            OptiReceiptNoticeValue,
            OptiDimmer
        },
        props: {
            block: {
                type: Boolean,
                required: true
            },
            allow: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                loyality: {
                    events: []
                },

                card_position_deleted: false,

                loading: true
            }
        },
        mounted() {
            API.get('patient/loyalitycard/fromPatientId/' + this.$state.params.id).then(res => {
                this.parseData(res)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('cards.notify.ERRORCARD')
            })
        },
        methods: {
            parseData(res) {
                this.loyality = res.data.data

                this.card_position_deleted = res.data.deleted

                this.receipt()
            },
            receipt() {
                let type = {
                    5: 'sale',
                    7: 'saleoffstock',
                    8: 'salereturn',
                    9: 'regeneration_rest_to_pay'
                }

                this.loyality.events.forEach(event => {
                    if(event.typeId && Object.keys(type).includes(event.typeId.toString())) {
                        ReceiptNotice.get(type[event.typeId], event.refId).then(res => {
                            this.$set(event, 'notice', res)
                        }).catch(() => {
                            //idą te co nie mają różnic
                        })
                    }
                })
            },
            totalPrice(event) {
                let total = 0

                event.forEach(e => {
                    total += e.price ? parseFloat(e.price) : 0
                })

                return Math.round(total * 100) / 100
            },

            deleteEvent(event_id) {
                this.loading = true
                this.loyalities = []

                API.get('loyality-card/event/delete/' + event_id).then(res => {
                    this.parseData(res)

                    this.$notify.success('cards.event.delete.notify.DELETE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('cards.event.delete.notify.error.DELETE')
                })
            }
        }
    }
</script>
