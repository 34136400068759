import OptiStore from '@/js/app/vue/store/opti-store'
import {API, GET_ITEMS, SET_ITEMS} from '@/js/app/vue/api'

class DepartmentRecentlyClosedStore extends OptiStore {

    state() {
        return {
            ...super.state(),
            items: []
        };
    }

    getters() {
        return {
            ...super.getters(),
            get: (state) => {
                return (departmentId) => {
                    return state.items.find(i => {
                        return i.id === parseInt(departmentId)
                    }) || {}
                }
            },
            items: (state) => {
                return state.items
            }
        };
    }

    actions() {
        return {
            ...super.actions(),

            onInit({dispatch}) {
                dispatch(GET_ITEMS)
            },

            async [GET_ITEMS]({commit}) {
                let {data} = await API.get('department/recently-closed')
                commit(SET_ITEMS, data)
            }
        };
    }

    mutations() {
        return {
            ...super.mutations(),

            [SET_ITEMS]: (state, departments) => {
                state.items = departments || []
            }
        };
    }
}

let store = new DepartmentRecentlyClosedStore()
export default store.getModule()
