<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-copy.mr3
            span {{'tasks.action.duplicate.GODUPLICATE'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            form.form.form-horizontal(name="formDuplicate")
                .form-group(v-show="lock")
                    .col-xs-12.text-center.warn
                        i.fa.fa-exclamation-circle.mr3
                        span {{'patient.NOPHONE'|translate}}

                .form-group
                    label.col-xs-12(for="assembly_id") {{'default.SELECT'|translate}}
                    .col-xs-12
                        select#assembly_id.form-control(name="assembly_id" required
                            v-model="assembly_id" @change="prescription = null")
                            option(v-for="assembly in assemblies" :value="assembly.id") {{assembly.name}}
                        label.warn(for="assembly_id" v-show="assembly_id === null") {{'validation.formValidate.required'|translate}}

                .form-group(v-if="assembly_id === 41")
                    label.col-xs-12(for="service_task_type") {{'tasks.TASKTYPE'|translate}}
                    .col-xs-12
                        OptiSelectEnum#service_task_type(enum="TaskType" :value="task_type" @update-enum="t => task_type = t")
                        label.warn(for="service_task_type" v-show="!task_type") {{'validation.formValidate.required'|translate}}

                .form-group(v-if="assembly_id === 41")
                    label.col-xs-12(for="prescriptions") {{'prescription.PRESCRIPTION'|translate}}
                    .col-xs-12
                        select#prescriptions.form-control(name="prescriptions" v-model="prescription" required)
                            option(v-for="prescription in prescriptions" :value="prescription") {{prescription.name}}
                        label.warn(for="prescriptions" v-show="!prescription") {{'validation.formValidate.required'|translate}}

                .form-group(style="margin-bottom:0" v-if="prescription")
                    .table-responsive
                        table.table.table-presenter.table-striped
                            thead
                                tr
                                    th(style="width:20%")
                                    th {{'prescription.RIGHT'|translate}}
                                    th {{'prescription.LEFT'|translate}}
                            tbody
                                tr
                                    th {{'prescription.SPHERE'|translate}}
                                    td {{prescription.reSphere|plus}}
                                    td {{prescription.leSphere|plus}}
                                tr
                                    th {{'prescription.CYLINDER'|translate}}
                                    td {{prescription.reCylinder|plus}}
                                    td {{prescription.leCylinder|plus}}
                                tr(v-show="prescription.reCylinder || prescription.leCylinder")
                                    th {{'prescription.AXIS'|translate}}
                                    td {{prescription.reAxis}}
                                    td {{prescription.leAxis}}
                                tr
                                    th {{'prescription.ADDICTION'|translate}}
                                    td {{prescription.reAdd|number(2)}}
                                    td {{prescription.leAdd|number(2)}}
                                tr
                                    th {{'prescription.PRISM'|translate}}
                                    td {{prescription.rePrism|number(2)}}
                                    td {{prescription.lePrism|number(2)}}
                                tr(v-show="prescription.rePrism || prescription.lePrism")
                                    th {{'prescription.PRISMANGLE'|translate}}
                                    td {{prescription.rePrismAngle}}
                                    td {{prescription.lePrismAngle}}
                                tr
                                    th(rowspan="2") {{'prescription.PDDISTANCE'|translate}}
                                    td(colspan="2") {{prescription.pdDist|number(2)}}
                                tr
                                    td.text-center {{prescription.rePdDist|number(2)}}
                                    td {{prescription.lePdDist|number(2)}}
                                tr
                                    th(rowspan="2") {{'prescription.PDNEAR'|translate}}
                                    td(colspan="2") {{prescription.pdNear|number(2)}}
                                tr
                                    td.text-center {{prescription.rePdNear|number(2)}}
                                    td {{prescription.lePdNear|number(2)}}

        template(#footer)
            OptiButton.pull-left(@click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
            OptiButton(type="success" :disabled="disabled" @click.native="goDuplicate") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading.assemblies || loading.prescriptions")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {SMSHelper} from '@/js/app/vue/helpers/SMSHelper'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsSummaryDialogDuplicate',
        components: {
            OptiDialog,
            OptiSelectEnum,
            OptiButton: () => import('../../../../Button/OptiButton'),
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                assemblies: [],
                raw_prescriptions: [],

                assembly_id: null,
                prescription: null,
                task_type: this.task.type,

                lock: !this.task.patient.cellPhoneNumber && !SMSHelper.unlockToken(this.task.patient.unlock),
                loading: {
                    assemblies: true,
                    prescriptions: true
                }
            }
        },
        mounted() {
            API.get('task/assemblies').then(res => {
                this.assemblies = res.data
            }).finally(() => {
                this.loading.assemblies = false
            }).catch(() => {
                this.$notify.error('services.notify.error.TASKERROR')
            })

            API.get('task/patient/prescriptions/' + this.task.patient.id).then(res => {
                this.raw_prescriptions = res.data
            }).finally(() => {
                this.loading.prescriptions = false
            }).catch(() => {
                this.$notify.error('prescription.ERRORLOAD')
            })
        },
        methods: {
            goDuplicate() {
                this.loading.assemblies = true

                API.get('task/duplicate/' + this.task.id + '/' + this.assembly_id + '/' + (!this.assembly_id ? 1 : 0) + '/' +
                    (this.prescription ? this.prescription.id : 0) + '/' + (this.assembly_id === 41 ? this.task_type : 0)).then(res => {
                    if(Object.keys(res.data).includes('notify')) {
                        res.data.notify.forEach(notify => {
                            this.$notify.error(notify, false, true)
                        })
                    } else {
                        this.$state.go('app.task.detail', {id: res.data.id, refresh: true})

                        this.$notify.success('tasks.action.duplicate.notify.' + (this.assembly_id ? 'SERVICE' : 'CREATE'))
                    }
                }).finally(() => {
                    this.loading.assemblies = false
                }).catch(() => {
                    this.$notify.error('tasks.action.duplicate.notify.error.' + (this.assembly_id ? 'SERVICE' : 'CREATE'))
                })
            }
        },
        computed: {
            prescriptions() {
                let _prescriptions = []

                this.raw_prescriptions.forEach(prescription => {
                    prescription.name = prescription.id

                    if(prescription.id === this.task.prescriptionId) {
                        prescription.name += ' (dotychczasowa)'
                    }

                    if([1, 2].includes(this.task_type) || ([3, 4].includes(this.task_type) && prescription.reAdd && prescription.leAdd)) {
                        _prescriptions.push(prescription)
                    }
                })

                if(this.prescription && !_prescriptions.map(p => p.id).includes(this.prescription.id)) {
                    this.prescription = null
                }

                return _prescriptions
            },
            disabled() {
                return this.lock || this.assembly_id === null || (this.assembly_id === 41 && (this.prescription === null || !this.task_type))
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;
    }
</style>