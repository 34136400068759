<template lang="pug">
    OptiDialog.fullscreen(:header="false" :footer="false")
        template(#body)
            .panel.panel-primary
                .panel-heading.text-center
                    i.fa.fa-bug.mr3
                    span {{'bugtrack.BUGTRACK'|translate}}

                .panel-body
                    form.form-horizontal.row(name="formBug")
                        .col-md-6
                            .form-group
                                label.col-xs-2.control-label(for="weight") {{'bugtrack.WEIGHT'|translate}}
                                .col-xs-8
                                    OptiSelectEnum#weight(enum="ErrorPriority" :value="bug.weight"
                                        @update-enum="w => bug.weight = w")
                                .col-xs-2
                                    button.btn.btn-purple(type="button" :class="{'active': tips}"
                                        @click="tips = !tips" :title="'bugtrack.tips.TITLE'|translate")
                                        i.fa.fa-question
                            .form-group(v-show="tips")
                                .col-xs-12.text-expressive(style="text-align:left") {{('bugtrack.tips.' + bug.weight)|translate}}
                            .form-group
                                label.col-xs-2.control-label(for="type") {{'bugtrack.TYPE'|translate}}
                                .col-xs-10
                                    OptiSelectEnum#type(enum="ErrorType" :value='bug.type'
                                        @update-enum="t => bug.type = t")
                            .form-group
                                label.col-xs-2.control-label.star-required(for="topic") {{'bugtrack.TOPIC'|translate}}
                                .col-xs-10
                                    input#topic.form-control(v-model="bug.topic" name="topic" required)
                                    OptiValidate(f="topic" :data="$v.bug.topic")

                        .col-md-6
                            .form-group
                                label.col-xs-2.control-label.star-required(for="comments") {{'bugtrack.DESCRIPTION'|translate}}
                                .col-xs-10
                                    textarea#comments.form-control(v-model="bug.comments" name="comments" rows="6" required)
                                    OptiValidate(f="comments" :data="$v.bug.comments")

                .panel-footer
                    OptiButton(type="danger" :disabled="loading" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove

                    OptiButton(type="default" style="margin-left:20px" :disabled="loading || !draw.cache.length"
                        @click.native="undo") {{'bugtrack.BACKDRAW'|translate}} ({{draw.cache.length}})
                        template(#icon)
                            i.fa.fa-undo

                    OptiButton.pull-right(type="success" :disabled="disabled"
                        text="bugtrack.confirm.create" @confirmed="save") {{'bugtrack.confirm.create.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-save

            #canvas-draw(ref="canvasDraw")

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogErrorsAdd',
        components: {
            OptiDialog,
            OptiSelectEnum,
            OptiValidate,
            OptiButton,
            OptiDimmer
        },
        props: {
            canvas: {
                type: HTMLCanvasElement,
                required: true
            }
        },
        data() {
            return {
                draw: {
                    ctx: this.canvas.getContext('2d'),

                    painting: false,
                    cache: [],

                    lastx: 0,
                    lasty: 0,
                    currentx: 0,
                    currenty: 0,
                    c: 0,
                    d: 0
                },

                bug: {
                    weight: 3,
                    type: 0,
                    topic: '',
                    comments: '',
                    url: window.location.href,
                    picture: ''
                },

                tips: false,
                loading: false
            }
        },
        validations: {
            bug: {
                topic: {
                    required
                },
                comments: {
                    required
                }
            }
        },

        mounted() {
            window.scrollTo(0, 0)
            // rozwiązuje problem z safari gdy belka robi się mała i nie można rysować palcem

            this.$refs.canvasDraw.append(this.canvas)

            this.canvas.addEventListener('mousedown', this.mousedown)
            this.canvas.addEventListener('mousemove', this.mousemove)
            this.canvas.addEventListener('mouseup', this.mouseup)
            this.canvas.addEventListener('mouseleave', this.mouseup)

            this.canvas.addEventListener('touchstart', this.touchstart)
            this.canvas.addEventListener('touchmove', this.touchmove)
            this.canvas.addEventListener('touchend', this.mouseup)
            this.canvas.addEventListener('touchcancel', this.mouseup)
        },

        methods: {
            offX(e) {
                return (e.offsetX || e.pageX - $(e.target).offset().left) * this.canvas.width / this.canvas.clientWidth | 0
            },
            offY(e) {
                return (e.offsetY || e.pageY - $(e.target).offset().top) * this.canvas.height / this.canvas.clientHeight | 0
            },
            putInCache() {
                return this.draw.cache.push(this.draw.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height))
            },

            mousedown(e) {
                this.draw.lastx = this.offX(e)
                this.draw.lasty = this.offY(e)

                this.draw.painting = true
                this.draw.ctx.beginPath()
                this.putInCache()
            },
            mousemove(e) {
                if(this.draw.painting) {
                    this.draw.currentx = this.offX(e)
                    this.draw.currenty = this.offY(e)

                    this.draw.c = (this.draw.lastx + this.draw.currentx) / 2
                    this.draw.d = (this.draw.lasty + this.draw.currenty) / 2

                    this.draw.ctx.lineWidth = 10
                    this.draw.ctx.lineJoin = 'round'

                    this.draw.ctx.quadraticCurveTo(this.draw.lastx, this.draw.lasty, this.draw.c, this.draw.d)
                    this.draw.ctx.stroke()

                    this.draw.lastx = this.draw.currentx
                    this.draw.lasty = this.draw.currenty
                }
            },
            mouseup() {
                if(this.draw.painting) {
                    this.draw.painting = false
                }
            },

            getOffset(e, side) {
                let body = document.querySelector('.modal-body'),
                    offset = 0

                side = side.charAt(0).toUpperCase() + side.slice(1)

                while(e) {
                    offset += parseInt(e['offset' + side])
                    e = e.offsetParent
                }

                offset -= body['scroll' + side]

                return offset
            },

            touchstart(e) {
                this.mousedown({
                    offsetX: e.changedTouches[0].pageX - this.getOffset(e.target, 'left'),
                    offsetY: e.changedTouches[0].pageY - this.getOffset(e.target, 'top')
                })

                e.preventDefault()
            },
            touchmove(e) {
                this.mousemove({
                    offsetX: e.changedTouches[0].pageX - this.getOffset(e.target, 'left'),
                    offsetY: e.changedTouches[0].pageY - this.getOffset(e.target, 'top')
                })

                e.preventDefault()
            },

            getFromCache() {
                if(this.draw.cache.length) {
                    let cached = this.draw.cache[this.draw.cache.length - 1]

                    this.draw.cache = this.draw.cache.slice(0, this.draw.cache.length - 1)

                    return cached
                }

                return false
            },
            undo() {
                let cached = this.getFromCache()

                if(cached) {
                    this.draw.ctx.putImageData(cached, 0, 0)
                }
            },

            save() {
                this.loading = true

                this.bug.picture = this.canvas.toDataURL('image/png')

                API.post('error/add', this.bug).then(() => {
                    this.$emit('closed')

                    this.$notify.success('bugtrack.notify.SAVE')
                }).catch(() => {
                    this.loading = false

                    this.$notify.error('bugtrack.notify.error.SAVE')
                })
            }
        },
        computed: {
            disabled() {
                return this.$v.bug.$invalid || this.loading
            }
        }
    }
</script>

<style scoped>
    .panel {
        margin-bottom: 0;
        border-radius: 0;
    }

    #canvas-draw {
        background: bisque;
        padding: 10px 0;

        /deep/ canvas {
            display: block;
            margin: 0 auto;
            width: 90%;
            cursor: crosshair;
        }
    }
</style>