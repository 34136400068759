<template lang="pug">
    div
        button#btn-left.btn.btn-lg.btn-success.btn-left-right(
            :disabled="paginator.page === 1 || loading"

            @click="$emit('load', paginator.page - 1)")
            i.fa.fa-2x.fa-arrow-left

        button#btn-right.btn.btn-lg.btn-success.btn-left-right(
            :disabled="paginator.page === paginator.pages || loading"

            @click="$emit('load', paginator.page + 1)")
            i.fa.fa-2x.fa-arrow-right
</template>

<script>
    export default {
        name: 'OptiNavigator',
        props: {
            paginator: {
                type: Object,
                required: true
            },

            loading: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    .btn-left-right {
        position: absolute;
        padding: 9px 9px 9px 7px;
        top: 42%;
        z-index: 9999;

        @btn-margin: -8px;
        @btn-margin-wide: -60px;

        &#btn-left {
            left: @btn-margin;
        }

        &#btn-right {
            right: @btn-margin;
        }

        @media only screen and (min-width: 1500px) {
            &#btn-left {
                left: @btn-margin-wide;
            }

            &#btn-right {
                right: @btn-margin-wide;
            }
        }
    }
</style>