<template lang="pug">
    div
        #btn-group-patient-detail.row(v-if="!loading")
            .col-sm-4.col-xs-12.text-center-xs
                OptiButton(type="primary" @click.native="dialog_history = true") {{'default.HISTORY'|translate}}
                    template(#icon)
                        i.fa.fa-book

                    MountingPortal(v-if="dialog_history" mountTo="#vue-modal" append)
                        OptiReclamationTaskDetailsDialogHistory(
                            :logs="reclamation.logs"

                            @closed="dialog_history = false")
                OptiButtonPrint(style="margin-left:5px" :title="'reclamations.details.title.CONTROL'|translate"
                    v-show="department_id === 49 || department_id === 91"
                    url="tasks/reclamation/print/control" :id="reclamation.id") {{'reclamations.details.action.PRINTCONTROL'|translate}}
            .col-sm-4.col-xs-12.text-center
                OptiButtonSms(v-show="reclamation.status > 1" :patient="sms.patient" :tags="sms.tags")
                OptiButton(type="primary" :title="'reclamations.action.edit.TITLE'|translate" v-show="reclamation.status === 1"
                    @click.native="$state.go('app.tasks.reclamation.edit', {id: reclamation.id})") {{'reclamations.action.edit.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-edit
            .col-sm-4.col-xs-12.text-center-xs.text-right
                OptiButtonPrint(:title="'reclamations.details.title.PRINT'|translate"
                    url="tasks/reclamation/print" :id="reclamation.id") {{'reclamations.details.action.PRINT'|translate}}
                OptiButtonPrint(style="margin-left:5px" :title="'reclamations.details.title.PRINTREVIEW'|translate" v-show="reclamation.review"
                    url="tasks/reclamation/print/review" :id="reclamation.id") {{'reclamations.details.action.PRINTREVIEW'|translate}}

        .row(v-if="permission.admin && !loading")
            .col-xs-3
                a.btn.btn-success(:title="'reclamations.navi.PREV'|translate" :disabled="!navi.prev"
                    :href="$state.href('app.tasks.reclamation.details', {id: navi.prev})")
                    i.fa.fa-arrow-left
            .col-xs-6.text-center
                OptiButtonDepository(v-if="reclamation.task.frame.productId > 0"
                    :product-id="reclamation.task.frame.productId")
            .col-xs-3.text-right
                a.btn.btn-success(:title="'reclamations.navi.NEXT'|translate" :disabled="!navi.next"
                    :href="$state.href('app.tasks.reclamation.details', {id: navi.next})")
                    i.fa.fa-arrow-right

        .row(v-if="!loading")
            .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0
                .panel-grid
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'reclamations.details.RECLAMATION'|translate}}
                            .pull-right \#{{reclamation.id}}

                        .row
                            .col-sm-6 {{'reclamations.details.CREATEDAT'|translate}}
                            .col-sm-6 {{reclamation.created}}
                        .row
                            .col-sm-6 {{'reclamations.details.CREATEDBY'|translate}}
                            .col-sm-6
                                div {{reclamation.user.firstName}} {{reclamation.user.lastName}}
                                span ({{reclamation.department.name}})
                        .row
                            .col-sm-6 {{'reclamations.details.CONSIDERDATE'|translate}}
                            .col-sm-6 {{reclamation.expected}}
                        .row
                            .col-sm-6 {{'reclamations.details.declarant.PATIENT'|translate}}
                            .col-sm-6
                                OptiPatient(:data="reclamation.patient")
                        .row
                            .col-sm-6 {{'reclamations.details.declarant.ADDRESS'|translate}}
                            .col-sm-6 {{reclamation.patient.street}}, {{reclamation.patient.zipCode}} {{reclamation.patient.city}}
                        .row(v-show="reclamation.patient.phoneNumber")
                            .col-sm-6 {{'reclamations.details.declarant.PHONE'|translate}}
                            .col-sm-6 {{reclamation.patient.phoneNumber}}
                        .row(v-show="reclamation.patient.cellPhoneNumber")
                            .col-sm-6 {{'reclamations.details.declarant.CELLPHONE'|translate}}
                            .col-sm-6 {{reclamation.patient.cellPhoneNumber|phone}}
                        .row
                            .col-sm-6 {{'reclamations.details.TASKID'|translate}}
                            .col-sm-6
                                a.link(:title="'reclamations.details.title.TASK'|translate"
                                    :href="$state.href('app.task.detail', {id: reclamation.productId})" target="_blank"
                                    ) {{reclamation.productId}}
                        .row
                            .col-sm-6 {{'reclamations.details.FINISHEDTASK'|translate}}
                            .col-sm-6 {{reclamation.sellDate}}
                        .row
                            .col-sm-6 {{'reclamations.details.PRICING'|translate}}
                            .col-sm-6 {{reclamation.productPrice|currency}}
                        .row
                            .col-sm-6 {{'reclamations.details.EVIDENCE'|translate}}
                            .col-sm-6
                                OptiEnum(enum="ReclamationEvidence" :id="reclamation.refType")
                        .row(v-show="reclamation.refId")
                            .col-sm-6 {{'reclamations.details.EVIDENCEID'|translate}}
                            .col-sm-6
                                span(v-show="!reclamation.refType") {{reclamation.refId|receipt}}
                                span(v-show="reclamation.refType") {{reclamation.refId}}
                        .row(v-show="reclamation.description")
                            .col-sm-6 {{'reclamations.details.DESCRIPTION'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(reclamation.description)")
                        .row(v-show="reclamation.salon_comments")
                            .col-sm-6 {{'reclamations.details.SALONCOMMENTS'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(reclamation.salon_comments)")
                        .row
                            .col-sm-6 {{'reclamations.details.DEFECTDATE'|translate}}
                            .col-sm-6 {{reclamation.noticed}}
                        .row
                            .col-sm-6 {{'reclamations.details.DEMAND'|translate}}
                            .col-sm-6
                                OptiEnum(style="display:block" enum="ReclamationDemand1" :id="reclamation.demand1")
                                OptiEnum(enum="ReclamationDemand2" :id="reclamation.demand2")
                        .row(v-show="reclamation.review")
                            .col-sm-6 {{'reclamations.details.OPINION'|translate}}
                            .col-sm-6
                                .warn(v-show="reclamation.review === 'negative'")
                                    i.fa.fa-remove.mr3
                                    span {{'reclamations.review.NEGATIVE'|translate}}
                                .text-success(v-show="reclamation.review === 'positive'")
                                    i.fa.fa-check.mr3
                                    span {{'reclamations.review.POSITIVE'|translate}}
                                div(v-html="$options.filters.nl2br(reclamation.reviewDescription)")
                        .row(v-show="reclamation.finished && reclamation.finished !== '0000-00-00'")
                            .col-sm-6 {{'reclamations.details.FINISHED'|translate}}
                            .col-sm-6 {{reclamation.finished}}
                        .row(v-show="shipment_exists")
                            .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                            .col-sm-6
                                OptiShipmentNotice(:type="12" :id="reclamation.id"
                                    @shipment-exists="s => shipment_exists = s")
                        .row(v-show="reclamation.options.length && permission.admin")
                            .col-sm-6.col-sm-offset-6
                                div(v-for="option in reclamation.options")
                                    i.fa.fa-check.mr3
                                    span {{option.name}}
                        .row(v-show="reclamation.review")
                            .col-sm-6.col-sm-offset-6
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" name="print_image" v-model="reclamation.print_image"
                                                @change="printImage")
                                            span.fa.fa-check
                                    span {{'default.PRINTIMAGE'|translate}}

                        .panel-footer.clearfix(v-opti-empty)
                            OptiButton(type="danger" :title="'reclamations.action.cancel.BTN'|translate|lower"
                                v-if="(reclamation.status === 1 && reclamation.departmentId === department_id) || (permission.admin && reclamation.status !== 0 && reclamation.status !== 8)"
                                text="reclamations.action.cancel.confirm" @confirmed="goCancel") {{'reclamations.action.cancel.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-remove
                            OptiButton.pull-right(type="success" :title="'reclamations.admin.repair.BTN'|translate|lower"
                                v-if="reclamation.status === 3 && permission.admin"
                                text="reclamations.admin.repair.confirm" @confirmed="goRepair") {{'reclamations.admin.repair.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'reclamations.admin.ready.BTN'|translate|lower"
                                v-if="reclamation.status === 4 && permission.admin"
                                text="reclamations.admin.ready.confirm" @confirmed="goReady")  {{'reclamations.admin.ready.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'reclamations.admin.send.BTN'|translate|lower"
                                v-if="reclamation.status === 5 && permission.admin"
                                text="reclamations.admin.send.confirm" @confirmed="goSend") {{'reclamations.admin.send.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'reclamations.action.receive.TITLE'|translate"
                                v-if="(reclamation.status === 6 || (reclamation.status === 0 && reclamation.shipmentItems[0] && !reclamation.shipmentItems[0].delivered)) && reclamation.departmentId === department_id"
                                text="reclamations.action.receive.confirm" @confirmed="goReceive") {{'reclamations.action.receive.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'reclamations.action.done.TITLE'|translate"
                                v-if="reclamation.status === 7 && reclamation.departmentId === department_id"
                                text="reclamations.action.done.confirm" @confirmed="goDone") {{'reclamations.action.done.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check

        OptiReclamationServicesView(v-if="!loading && reclamation.services.length"
            :data="reclamation.services" path="task/reclamation")

        OptiReclamationTaskDetailsDecision(v-if="!loading && reclamation.status === 2 && permission.admin"
            @go-considered="goConsidered")

        .row
            .col-md-10.col-md-offset-1(v-if="!loading && permission.notes")
                OptiNotes(table="reclamation_notes" table-key="reclamation_id" :id="reclamation.id"
                    :upload="true" :notes="reclamation.notes" @update-notes="n => reclamation.notes = n")
            .col-xs-12
                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiReclamationTaskDetailsDialogHistory
        from '@/js/app/vue/components/Reclamations/Tasks/Details/OptReclamationTaskDetailsDialogHistory'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiButtonSms from '@/js/app/vue/components/Button/OptiButtonSms'
    import OptiButtonDepository from '@/js/app/vue/components/Button/OptiButtonDepository'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiReclamationServicesView from '@/js/app/vue/components/Reclamations/OptiReclamationServicesView'
    import OptiReclamationTaskDetailsDecision from '@/js/app/vue/components/Reclamations/Tasks/Details/OptiReclamationTaskDetailsDecision'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationTaskDetails',
        components: {
            OptiReclamationTaskDetailsDialogHistory,
            OptiButtonPrint,
            OptiButtonSms,
            OptiButtonDepository,
            OptiButton,
            OptiPatient,
            OptiEnum,
            OptiShipmentNotice,
            OptiReclamationServicesView,
            OptiReclamationTaskDetailsDecision,
            OptiNotes,
            OptiDimmer
        },
        data() {
            return {
                reclamation: {},
                sms: {},
                navi: {},
                department_id: this.$uac.user.activeDepartmentId,
                shipment_exists: false,

                dialog_history: false,
                permission: {
                    admin: this.$uac.permission('tasks.reclamation.admin'),
                    notes: this.$uac.permission('tasks.reclamation.notes')
                },
                loading: true
            }
        },
        mounted() {
            API.get('tasks/reclamation/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('reclamations.notify.error.LOAD')
            })

            if(this.permission.admin) {
                API.get('tasks/reclamation/navi/' + this.$state.params.id).then(res => {
                    this.navi = res.data
                }).catch(() => {
                    this.$notify.error('reclamations.navi.notify.error.LOAD')
                })
            }
        },
        methods: {
            printImage() {
                API.post('reclamation/print_image', {
                    type: 'task',
                    id: this.reclamation.id,
                    print_image: this.reclamation.print_image
                }, API.id(Math.random())).catch(() => {
                    this.$notify.error('default.notify.error.PRINTIMAGE')
                })
            },

            getData(data) {
                this.reclamation = data

                this.sms = {
                    patient: this.reclamation.patient,
                    tags: {
                        type: 6,
                        ref_id: this.reclamation.id,
                        firstName: this.reclamation.patient.firstName,
                        reclamationId: this.reclamation.id
                    }
                }

                this.$emit('update-breadcrumb', this.reclamation.statusName)
            },
            goCancel() {
                this.loading = true

                API.post('tasks/reclamation/cancelled', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.notify.error.CANCEL')
                })
            },
            goRepair() {
                this.loading = true

                API.post('tasks/reclamation/repair', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.admin.notify.REPAIR')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.admin.notify.error.REPAIR')
                })
            },
            goReady() {
                this.loading = true

                API.post('tasks/reclamation/ready', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.admin.notify.READY')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.admin.notify.error.READY')
                })
            },
            goSend() {
                this.loading = true

                API.post('tasks/reclamation/send', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.admin.notify.SEND')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.admin.notify.error.SEND')
                })
            },
            goReceive() {
                this.loading = true

                API.post('tasks/reclamation/received', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.notify.RECEIVED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.notify.error.RECEIVED')
                })
            },
            goDone() {
                this.loading = true

                API.post('tasks/reclamation/done', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.notify.DONE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.notify.error.DONE')
                })
            },

            goConsidered(form) {
                this.loading = true

                API.post('tasks/reclamation/considered', form).then(res => {
                    this.getData(res.data)

                    this.$notify.success('reclamations.admin.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.admin.notify.error.SAVE')
                })
            }
        }
    }
</script>
