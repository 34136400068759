<template lang="pug">
    .col-xs-12(v-show="showChangeShapeValue")
        .table-responsive
            table.table.table-striped.text-center.middle(style="margin-bottom:0")
                tbody
                    tr
                        td(rowspan="2")
                            div {{'tasks.extension.EXTENSION1'|translate}}
                            div {{'tasks.extension.EXTENSION2'|translate}}
                        td(:class="{'invisible': invisibleTop}")
                            label(for="heightAdditionTop") {{'tasks.extension.TOP'|translate}}
                        td(:class="{'invisible': invisibleBottom}")
                            label(for="heightAdditionBottom") {{'tasks.extension.BOTTOM'|translate}}
                        td(:class="{'invisible': invisibleTemporal}")
                            label(for="widthTemporal") {{'tasks.extension.WIDTH'|translate}}
                        td(:class="{'invisible': invisibleNasal}")
                            label(for="widthNasal") {{'tasks.extension.NASAL'|translate}}
                        td(:class="{'invisible': invisibleSigma}") &Sigma;
                    tr
                        td(:class="{'invisible': invisibleTop}")
                            select#heightAdditionTop.form-control(name="heightAdditionTop" :disabled="block"
                                v-model="task['heightAdditionTop' + field]" @change="$emit('lenses-was-updated')")
                                option(v-for="v in values" :value="v") {{v}}
                        td(:class="{'invisible': invisibleBottom}")
                            select#heightAdditionBottom.form-control(name="heightAdditionBottom" :disabled="block"
                                v-model="task['heightAdditionBottom' + field]" @change="$emit('lenses-was-updated')")
                                option(v-for="v in values" :value="v") {{v}}
                        td(:class="{'invisible': invisibleTemporal}")
                            select#widthTemporal.form-control(name="widthTemporal" :disabled="block"
                                v-model="task['widthTemporal' + field]" @change="$emit('lenses-was-updated')")
                                option(v-for="v in values" :value="v") {{v}}
                        td(:class="{'invisible': invisibleNasal}")
                            select#widthNasal.form-control(name="widthNasal" :disabled="block"
                                v-model="task['widthNasal' + field]" @change="$emit('lenses-was-updated')")
                                option(v-for="v in values" :value="v") {{v}}
                        td(:class="{'invisible': invisibleSigma}")
                            .form-control(disabled) {{task['widthTemporal' + field] + task['widthNasal' + field]|number(2)}}
</template>

<script>
    export default {
        name: 'OptiTaskDetailsLensesChangeShape',
        props: {
            task: {
                type: Object,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },

            shape: {
                type: Object,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                field: this.isCompare ? '_2' : '',

                values: _.range(-4, 4 + 0.5, 0.5)
            }
        },
        computed: {
            showChangeShapeValue() {
                let shape = this.task['heightAdditionTop' + this.field] || this.task['heightAdditionBottom' + this.field] ||
                        this.task['widthTemporal' + this.field] || this.task['widthNasal' + this.field],
                    direction = this.shape.direction.top || this.shape.direction.bottom ||
                    this.shape.direction.temporal || this.shape.direction.nasal

                return shape || (direction && !this.block)
            },

            invisibleTop() {
                return !(this.shape.direction.top || (!this.shape.direction.top && this.task['heightAdditionTop' + this.field]))
            },
            invisibleBottom() {
                return !(this.shape.direction.bottom || (!this.shape.direction.bottom && this.task['heightAdditionBottom' + this.field]))
            },
            invisibleTemporal() {
                return !(this.shape.direction.temporal || (!this.shape.direction.temporal && this.task['widthTemporal' + this.field]))
            },
            invisibleNasal() {
                return !(this.shape.direction.nasal || (!this.shape.direction.nasal && this.task['widthNasal' + this.field]))
            },
            invisibleSigma() {
                return !((this.shape.direction.temporal || this.shape.direction.nasal) ||
                    ((!this.shape.direction.nasal && this.task['widthNasal' + this.field]) ||
                        (!this.shape.direction.nasal && this.task['widthNasal' + this.field])))
            }
        }
    }
</script>

<style lang="less" scoped>
    label {
        font-weight: normal;
        margin: 0;
    }

    select {
        text-align: center;
        text-align-last: center;
    }
</style>