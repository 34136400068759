export default {
    exists: (state) => {
        return (product) => {
            let list = state.items.filter(i => {
                return i.product_id === product.id
            })
            return list.length > 0
        }
    }
}
