<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-edit.mr3
            span {{'catalog.offer.edit_portrait.BTN'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .row(style="position:relative")
                .col-sm-6.product(style="margin-bottom:15px" :class="{'col-md-4': products.length < 4, 'col-md-3': products.length === 4}"
                    v-for="(product, key_product) in products" :key="product.id")
                    OptiProductInfo(:class="{'opti-product-info-small': products.length === 4}"
                        :product="product"
                        :department-id="$uac.user.activeDepartmentId"

                        :is-offer="true")

                    div(style="margin-bottom:15px" v-if="product.pivot.hoya_option_id")
                        div {{'catalog.lenses.properties.heading.' + product.pivot.hoya_option.section|translate}}
                        div(style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis"
                            :title="product.pivot.hoya_option.name") {{product.pivot.hoya_option.name}}

                    OptiUpload(style="margin-top:10px;margin-bottom:10px"
                        :id="product.id.toString()"
                        prefix="offer_frame_photo"
                        :picture="true"

                        :file="product.pivot.photo"

                        @update-file="f => product.pivot.photo = f"
                        @update-loading="l => $set(loading.photos, key_product, l)")

                    .text-center.warn(style="margin-top:15px" v-show="product.pivot.choice_by_client")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'catalog.offer.choice.byClient.HEADER'|translate}}

            OptiDimmer(:active="loading.main")

        template(#footer)
            OptiButton.pull-left(@click.native="$emit('close')") {{'default.CLOSE'|translate}}
                template(#icon)
                    i.fa.fa-close

            OptiButton(type="success" text="catalog.offer.edit_portrait.confirm" @confirmed="save"
                :disabled="loading.main || !!loading.photos.filter(l => l).length") {{'default.SAVE'|translate}}
                template(#icon)
                    i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogOfferDialogPortraitsEdit',
        components: {
            OptiDialog,
            OptiProductInfo,
            OptiUpload,
            OptiDimmer,
            OptiButton
        },
        props: {
            offer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                products: _.cloneDeep(this.offer.products),

                loading: {
                    main: false,
                    photos: []
                }
            }
        },
        beforeMount() {
            this.products.forEach(product => {
                if(product.pivot.photo) {
                    product.pivot.photo = 'offer/' + product.pivot.photo
                }
            })
        },
        methods: {
            save() {
                this.loading.main = true

                API.post('catalog/offer/portraits/edit', {
                    id: this.offer.id,
                    products: this.products
                }).then(() => {
                    this.$notify.success('catalog.offer.edit_portrait.notify.EDITED')

                    this.$emit('reload')
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('catalog.offer.edit_portrait.notify.error.EDITED')
                })
            }
        }
    }
</script>