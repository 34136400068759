<template lang="pug">
	OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'

	import OptiDimmer from '../../Blocks/OptiDimmer'

	export default {
		name: 'OptiDictionaryRegenerationTypeDelete',
		components: {OptiDimmer},
		data() {
			return {
				loading: true
			}
		},
		mounted() {
			API.delete('dictionary/regeneration-type/action/' + this.$state.params.id).then(() => {
				this.$notify.success('dictionary.regenerationtype.notify.DELETE')
			}).then(() => {
				this.$state.go('app.dictionary.regenerationtypes.table')
				this.loading = false
			}).catch(() => {
				this.$notify.error('dictionary.regenerationtypes.notify.error.DELETE')
			})
		}
	}
</script>
