<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.correction.STEREOFULL'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="cancelled()")

        template(#body)
            .text-center(style="margin-top:5px;font-size:18px")
                i.fa.fa-eye.mr3
                span(style="margin-right:5px") {{'optometrist.correction.STEREOFULL'|translate}}
                button.btn.btn-info(type="button" :class="{'btn-warning active': help}"
                    @click="help = !help") {{'default.HELP'|translate}}

            ul.optometrist-tips(style="list-style-type:none;padding-left:15px" v-show="help")
                li {{'optometrist.tips.stereo.1'|translate}}

            .row(style="padding-left:10px;padding-right:10px" v-show="!help")
                .col-xs-3.text-center
                    .row
                        .col-xs-12
                            label.screening-hover.stereo(style="font-size:14px" for="stereo0"
                                :class="{'screening-opacity': s}") {{'optometrist.correction.NOSTEREO'|translate}}
                            input.hide(type="radio" id="stereo0" v-model="s" name="stereo" :value="0")
                        .col-xs-12
                            img(style="width:75%" src="img/optometrist/screening-test/stereo.svg")
                .col-xs-9
                    .row
                        .col-xs-4(v-for="o in options")
                            label.screening-hover.stereo(:for="'stereo' + o" :class="{'screening-opacity': o !== s}") {{o}}
                            input.hide(type="radio" :id="'stereo' + o" v-model="s" name="stereo" :value="o")

        template.text-right(#footer)
            OptiButton(type="success" @click.native="confirmed()") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(type="danger" @click.native="cancelled()") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'

    export default {
        name: 'OptiOptometristsDetailsRefractionDialogStereo',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips
        },
        props: {
            stereo: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                unique: Math.random().toString(36).substr(2, 9),

                help: false,

                options: [800, 140, 60, 400, 100, 50, 200, 80, 40],
                s: -1
            }
        },
        mounted() {
            SurveyLogs.save(this.$state.params.id, this.unique, 'stereo', '', 'start', '50%',
                'Rozpoczęto test', [], {data: this.stereo})
        },
        methods: {
            confirmed() {
                SurveyLogs.save(this.$state.params.id, this.unique, 'stereo', '', 'end', '50%',
                    'Zakończono test', [], {data: this.s})

                this.$emit('confirmed', this.s)
            },
            cancelled() {
                SurveyLogs.save(this.$state.params.id, this.unique, 'stereo', '', 'cancel', '50%',
                    'Anulowano test', [], {data: 0})

                this.$emit('cancelled')
            }
        }
    }
</script>