<template lang="pug">
    OptiDialog.fullscreen(:header="lastPage" :footer="lastPage")
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.SURVEYNEAR'|translate}}

            label.fa.fa-remove.pull-right(style="font-weight:normal;cursor:pointer" for="btn-cancel")

        template(#body)
            div(v-if="!lastPage")
                img(:src="'img/optometrist/screening-test/visus-near/' + view + '.png'" width="100%")

                div
                    button#btn-left.btn.btn-lg.btn-success(:disabled="pages[0] === view" @click="--view")
                        i.fa.fa-3x.fa-arrow-left

                    button#btn-right.btn.btn-lg.btn-success(:disabled="lastPage" @click="++view")
                        i.fa.fa-3x.fa-arrow-right

                    label#btn-remove.btn.btn-lg.btn-danger(for="btn-cancel")
                        i.fa.fa-remove

            .table-responsive(v-show="lastPage")
                table.table.table-borderless
                    thead
                        tr
                            th.text-center {{'prescription.RIGHT'|translate}}
                            th.text-center {{'prescription.LEFT'|translate}}
                            th.text-center BinVisus
                            th.text-center
                    tbody.table-visus-near
                        tr(v-for="(row, index) in rows")
                            th.center
                                label.btn.btn-default(:for="'r_visus' + index" :class="{'btn-warning active': r_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'r_visus' + index" v-model="r_visus" name="r_visus" :value="row.scale")
                            th.center
                                label.btn.btn-default(:for="'l_visus' + index" :class="{'btn-warning active': l_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'l_visus' + index" v-model="l_visus" name="l_visus" :value="row.scale")
                            th.center
                                label.btn.btn-default(:for="'bin_visus' + index" :class="{'btn-warning active': bin_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'bin_visus' + index" v-model="bin_visus" name="bin_visus" :value="row.scale")
                            td.middle
                                label
                                    div {{row.lines[0]}}
                                    div {{row.lines[1]}}

            .row
                .col-xs-4.col-xs-offset-8
                    .table-responsive(v-if="mode === 'correction'")
                        table.table.table-borderless
                            tbody
                                tr
                                    td
                                        .group
                                            a.btn.btn-default.btn-lg.bold(style="padding:5px 20px;display:block"
                                                @click="increment('r')") +0,25
                                            span.value.text-center
                                                span.text-muted.text-sm(style="display:block") SPH
                                                OptiNumberPrescription.text-center(
                                                    :header="$filters.translate('prescription.SPHERE') + ' - ' + $filters.translate('prescription.RIGHT')"
                                                    :value="r_sph" @confirmed="v => updateSph('r', v)") {{r_sph|plus}}
                                            a.btn.btn-default.btn-lg.bold(style="padding:5px 20px;display:block"
                                                @click="decrement('r')") -0,25
                                    td
                                        .group
                                            a.btn.btn-default.btn-lg.bold(style="padding:5px 20px;display:block"
                                                @click="increment('l')") +0,25
                                            span.value.text-center
                                                span.text-muted.text-sm(style="display:block") SPH
                                                OptiNumberPrescription.text-center(
                                                    :header="$filters.translate('prescription.SPHERE') + ' - ' + $filters.translate('prescription.LEFT')"
                                                    :value="l_sph" @confirmed="v => updateSph('l', v)") {{l_sph|plus}}
                                            a.btn.btn-default.btn-lg.bold(style="padding:5px 20px;display:block"
                                                @click="decrement('l')") -0,25

        template.text-right(#footer)
            .row
                .col-xs-4.text-left
                    OptiButton(type="success" @click.native="--view") {{'optometrist.video.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left
                .col-xs-4.text-center
                    OptiButton(type="success" text="optometrist.video.confirm.success"
                        :disabled="r_visus === '-' || l_visus === '-' || bin_visus === '-'" @confirmed="confirmed()"
                        ) {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton#btn-cancel(type="danger" text="optometrist.video.confirm.cancel" @confirmed="cancelled()"
                        ) {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove
</template>

<script>
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'

    export default {
        name: 'OptiOptometristsDetailsRefractionNearDialogSurvey',
        components: {
            OptiDialog,
            OptiButton,
            OptiNumberPrescription
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            mode: {
                type: String,
                required: true
            },

            rVisus: {
                type: String,
                required: true
            },
            lVisus: {
                type: String,
                required: true
            },
            binVisus: {
                type: String,
                required: true
            }

        },
        data() {
            return {
                unique: Math.random().toString(36).substr(2, 9),

                pages: [1, 2, 3, 4],
                view: 1,

                r_sph: this.interview.near_r_sphere,
                l_sph: this.interview.near_l_sphere,

                r_visus: '-',
                l_visus: '-',
                bin_visus: '-',

                rows: [{
                    scale: '0,2',
                    lines: ['W bogatej ofercie LOOX Galerii Optyki', 'znajdują się wyłącznie na markowe produkty,']
                }, {
                    scale: '0,3',
                    lines: ['które poza zapewnieniem komfortu widzenia, mają', 'nadać naszym klientom wyjątkowy charakter.']
                }, {
                    scale: '0,4',
                    lines: ['Wieloletnie doświadczenie, szeroki wybór markowych okularów', 'oraz dostępność salonów LOOX Galerii Optyki w kilkunastu']
                }, {
                    scale: '0,5',
                    lines: ['miastach Polski gwarantuje najwyższą jakość naszych produktów i usług.', 'W naszych salonach optycznych oferujemy Państwu: profesjonalne badanie']
                }, {
                    scale: '0,6',
                    lines: ['ostrości wzroku, ogromny wybór markowych modeli oprawek do okularów korekcyjnych', 'i przeciwsłonecznych najnowszych, światowych kolekcji, a także indywidualne dobrane']
                }, {
                    scale: '0,8',
                    lines: ['do każdego pacjenta soczewki okularowe, zbudowane z najlepszych materiałów i z wykorzystaniem nowoczesnych', 'technologii optymalizacyjnych. Loox Galeria Optyki oferuje w swoich salonach optycznych na terenie Polski']
                }, {
                    scale: '1,0',
                    lines: ['usługę Umowy Serwisowej. Każdy klient, który kupi okulary korecyjne (oprawę okularową i dwie soczewki okularowe)', 'w salonie optycznym Loox Galeria Optyki, ma prawo do zakupu Umowy Serwisowej za równowartość 10% wartości okularów.']
                }]
            }
        },
        mounted() {
            this.setStartLog()
        },
        methods: {
            setStartLog() {
                let object = {}

                switch(this.mode) {
                    case 'contactLens':
                        object = {
                            rVisus: this.interview.contactLens.powers[this.rVisus],
                            lVisus: this.interview.contactLens.powers[this.lVisus],
                            binVisus: this.interview.contactLens.powers[this.binVisus]
                        }

                        break

                    case 'contact_lens_correction':
                        object = {
                            rVisus: this.interview.contactLensCorrection.near_r_visus,
                            lVisus: this.interview.contactLensCorrection.near_l_visus,
                            binVisus: this.interview.contactLensCorrection.near_bin_visus
                        }

                        break

                    default:
                        object = {
                            rVisus: this.interview[this.rVisus],
                            lVisus: this.interview[this.lVisus],
                            binVisus: this.interview[this.binVisus]
                        }
                }

                if(this.mode === 'correction') {
                    object['rAdd'] = this.interview.dist_r_add
                    object['lAdd'] = this.interview.dist_l_add
                }

                SurveyLogs.save(this.interview.id, this.unique, 'visus_near_' + this.mode, '', 'start', '50%',
                    'Rozpoczęto badanie', [], object)
            },

            increment(side) {
                this[side + '_sph'] += 0.25

                if(side === 'r') {
                    this.l_sph += 0.25
                }
            },
            decrement(side) {
                this[side + '_sph'] -= 0.25

                if(side === 'r') {
                    this.l_sph -= 0.25
                }
            },
            updateSph(side, v) {
                if(side === 'r') {
                    this.l_sph += v - this.r_sph
                }

                this[side + '_sph'] = v
            },

            confirmed() {
                let result = {}

                if(this.mode === 'contactLens') {
                    this.interview.contactLens.powers[this.rVisus] = this.r_visus
                    this.interview.contactLens.powers[this.lVisus] = this.l_visus
                    this.interview.contactLens.powers[this.binVisus] = this.bin_visus

                    result = {
                        rVisus: this.interview.contactLens.powers[this.rVisus],
                        lVisus: this.interview.contactLens.powers[this.lVisus],
                        binVisus: this.interview.contactLens.powers[this.binVisus]
                    }
                } else if(this.mode === 'contact_lens_correction') {
                    this.interview.contactLensCorrection[this.rVisus] = this.r_visus
                    this.interview.contactLensCorrection[this.lVisus] = this.l_visus
                    this.interview.contactLensCorrection[this.binVisus] = this.bin_visus

                    result = {
                        rVisus: this.interview.contactLensCorrection[this.rVisus],
                        lVisus: this.interview.contactLensCorrection[this.lVisus],
                        binVisus: this.interview.contactLensCorrection[this.binVisus]
                    }
                } else {
                    this.interview[this.rVisus] = this.r_visus
                    this.interview[this.lVisus] = this.l_visus
                    this.interview[this.binVisus] = this.bin_visus

                    result = {
                        rVisus: this.interview[this.rVisus],
                        lVisus: this.interview[this.lVisus],
                        binVisus: this.interview[this.binVisus]
                    }
                }

                if(this.mode === 'correction') {
                    this.interview.near_r_sphere = this.r_sph
                    this.interview.near_l_sphere = this.l_sph

                    this.interview.dist_r_add = this.interview.near_r_sphere - this.interview.dist_r_sphere
                    this.interview.dist_l_add = this.interview.near_l_sphere - this.interview.dist_l_sphere

                    result['rAdd'] = this.interview.dist_r_add
                    result['lAdd'] = this.interview.dist_l_add
                }

                SurveyLogs.save(this.interview.id, this.unique, 'visus_near_' + this.mode, '', 'end', '50%',
                    'Zakończono badanie', [], result)

                this.$emit('confirmed', this.interview)
            },
            cancelled() {
                SurveyLogs.save(this.interview.id, this.unique, 'visus_near_' + this.mode, '', 'cancel', '50%',
                    'Anulowano badanie', [], {data: null})

                this.$emit('cancelled')
            }
        },
        computed: {
            lastPage() {
                return this.pages[this.pages.length - 1] === this.view
            }
        }
    }
</script>

<style lang="less" scoped>
    #btn-left,
    #btn-left {
        position:fixed;
        padding:20px 30px;
        left:5px;
        top:45%
    }
    #btn-right {
        position:fixed;
        padding:20px 30px;
        right:5px;
        top:45%
    }
    #btn-remove {
        position:fixed;
        right:35px;
        top:20px;
    }

    /deep/ .modal-container {
        padding: 0 !important;

        .modal-body {
            overflow: hidden !important;
        }
    }
</style>