<template lang="pug">
    div(v-if="!loading")
        .row
            .col-xs-12(style="margin-bottom:15px")
                span(v-if="allowEdit")
                    label.switch.switch-lg.mr3
                        input#edit(type="checkbox" v-model="edit")
                        span
                    label.control-label(style="cursor:pointer" for="edit" :class="{'text-normal': !edit}"
                        ) {{'catalog.lenses.properties.EDIT'|translate}}

                .pull-right.text-right
                    OptiButtonHistory(:endpoint="'dictionary/hoya/getLensesHistory'")

                    small.text-muted(style="display:block") * {{'dictionary.hoya.logs.refers.LENSES'|translate}}

            .col-xs-12
                HoyaLensesPriceEdit(:edit="edit" :categories="categories")

            .col-xs-12
                OptiHoyaPriorityTable

            .col-xs-12.text-right(style="margin-bottom:15px")
                OptiButtonHistory(:endpoint="'dictionary/hoya/getHistory'")

                small.text-muted(style="display:block") * {{'dictionary.hoya.logs.refers.EXTRAS'|translate}}

            .col-xs-12
                .panel.panel-primary(style="position:relative")
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'dictionary.hoya.panel.header.ADDS'|translate}}

                    .panel-body(style="padding:0")
                        table.table.table-responsive.middle
                            tbody
                                template(v-for="option in options")
                                    tr.main(:class="{'bg-separator': option.selected, 'bold': option.selected}"
                                        @click="option.selected = !option.selected")
                                        td
                                            span {{'catalog.lenses.properties.heading.' + option.type|translate}}
                                            i.fa.pull-right(:class="{'fa-arrow-up': option.selected, 'fa-arrow-down': !option.selected}")
                                    tr(v-if="option.selected")
                                        td(style="padding:0")
                                            OptiHoyaItemEdit(:edit="edit" :category="option")

            .col-xs-12.text-right(style="margin-bottom:20px" v-if="allowEdit && edit")
                OptiButtonSave(@click.native="save")

    .row(v-else)
        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import HoyaLensesPriceEdit from '@/js/app/vue/components/Dictionary/Hoya/HoyaLensesPriceEdit'
    import OptiButtonDropdown from '@/js/app/vue/components/Button/OptiButtonDropdown'
    import OptiHoyaItemEdit from '@/js/app/vue/components/Dictionary/Hoya/OptiHoyaItemEdit'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiHoyaPriorityTable from '@/js/app/vue/components/Dictionary/Hoya/OptiHoyaPriorityTable'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiHoyaOptionsEdit',
        components: {
            OptiHoyaPriorityTable,
            OptiButtonHistory,
            HoyaLensesPriceEdit,
            OptiButtonDropdown,
            OptiHoyaItemEdit,
            OptiDimmer,
            OptiButtonSave
        },
        data() {
            return {
                categories: null,

                options: [{
                    type: 'coatings',
                    selected: false,
                    items: []
                }, {
                    type: 'mirror',
                    selected: false,
                    items: []
                }, {
                    type: 'mirror_polar',
                    selected: false,
                    items: []
                }, {
                    type: 'mirror_light',
                    selected: false,
                    items: []
                }, {
                    type: 'mirror_light_polar',
                    selected: false,
                    items: []
                }, {
                    type: 'coatings_sensity_shine',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_contrast',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_pattern',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_full',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_gradient',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_fashion_cold',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_fashion_warm',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_fashion_mix',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_angelica_cold',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_angelica_warm',
                    selected: false,
                    items: []
                }, {
                    type: 'coloration_angelica_mix',
                    selected: false,
                    items: []
                }, {
                    type: 'polarized',
                    selected: false,
                    items: []
                }, {
                    type: 'polarized_fine',
                    selected: false,
                    items: []
                }, {
                    type: 'sensity',
                    selected: false,
                    items: []
                }, {
                    type: 'others',
                    selected: false,
                    items: []
                }],

                allowEdit: window.__env.hoya_price_edit,
                edit: false,
                loading: true
            }
        },
        mounted() {
            API.get('dictionary/hoya/categories-and-options').then(res => {
                this.categories = res.data.categories

                this.options.forEach(option => {
                    option.items.push(...res.data.options[option.type])
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('dictionary.hoya.notify.error.LOADING')
            })
        },
        methods: {
            save() {
                if(!this.hasErrors()) {
                    this.loading = true

                    API.post('dictionary/hoya/price/save', {
                        categories: this.categories,
                        options: this.options
                    }).then(res => {
                        this.categories = res.data.categories

                        this.options.forEach(option => {
                            option.selected = false
                            option.items = []
                            option.items.push(...res.data.options[option.type])
                        })

                        this.$notify.success('dictionary.hoya.notify.success.SAVED')
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('dictionary.hoya.notify.error.SAVED')
                    })
                }
            },
            hasErrors() {
                let _return = false

                _.forEach(this.categories, categories => {
                    categories.forEach(category => {
                        category.data.forEach(d => {
                            let prices = d.hoya_lens_price_group[0]

                            if(!this.checkField(d.name_long, prices)) {
                                _return = true
                            }
                        })
                    })
                })

                this.options.forEach(option => {
                    option.items.forEach(item => {
                        if(!this.checkField(item.name_long, item)) {
                            _return = true
                        }
                    })
                })

                return _return
            },
            checkField(name, prices) {
                let _return = true;

                ['brutto_price', 'netto_price', 'zhc'].forEach(field => {
                    if(!this.isInt(prices[field]) && !this.isFloat(prices[field])) {
                        this.$notify.error(this.$filters.translate('dictionary.hoya.notify.error.INVALID_VALUE') + ' ' +
                            name + ' - ' + prices[field])

                        _return = false
                    }
                });

                ['netto_percent'].forEach(field => {
                    if(!this.isInt(prices[field])) {
                        this.$notify.error(this.$filters.translate('dictionary.hoya.notify.error.INVALID_VALUE') + ' ' +
                            name + ' - ' + prices[field])

                        _return = false
                    }
                })

                return _return
            },
            isInt(n) {
                return Number(n).toString() === n.toString() && Number(n) % 1 === 0
            },
            isFloat(n) {
                return Number(n).toString() === n.toString() && Number(n) % 1 !== 0
            }
        }
    }
</script>

<style lang="less" scoped>
    tr.main {
        &:hover {
            cursor: pointer;
            font-weight: 700;
        }

        > td > i {
            margin-top: 3px;
        }
    }
</style>