<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-trash.mr3
            span Zakończ umowę
            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")
        template(#body)
            .row
                .col-xs-12(style="padding-bottom:5px") Czy na pewno chcesz zakończyć umowę?
                .col-xs-12
                    label.bold(for="comments" style="display:block") Uzasadnienie
                    textarea#comments.form-control(rows="5" name="comments" v-model="comments"
                        :placeholder="'Wpisz powód zakończenia umowy'")
                    label.bold.warn(style="display:block" for="comments" v-show="!comments") To pole jest wymagane.
        template(#footer)
            OptiButton(
                type="success"
                :disabled="!comments"
                @click.native="$emit('confirmed', comments)"
            ) Tak, zakończ umowę
                template(#icon)
                    i.fa.fa-check
            OptiButton(
                type="danger"
                @click.native="$emit('closed')"
            ) Nie
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from "@/js/app/vue/components/OptiDialog";
    import OptiButton from "@/js/app/vue/components/Button/OptiButton";

    export default {
        name: "OptiContactPlusDialogReasonCancel",
        components: {
            OptiButton,
            OptiDialog
        },
        data() {
            return {
                comments: ''
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
}
</style>