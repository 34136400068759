<template lang="pug">
	.panel.panel-primary
		.panel-heading(style="cursor:pointer" @click="show = !show")
			i.fa.fa-navicon.mr3
			span {{'catalog.product.spare_parts.HEADING'|translate}}

			.pull-right
				i.fa(:class="{'fa-arrow-up': !show, 'fa-arrow-down': show}")

		table.table.table-striped.table-responsive.middle(v-if="show && spareParts")
			tbody
				tr(v-if="edit || spareParts.front > 0")
					td {{'catalog.product.spare_parts.FRONT'|translate}}
					td
						OptiInputNumber.pull-right#front(
							:disabled="!edit"
							:allow-minus="false"
							:value="spareParts.front"

							@plus="++spareParts.front"
							@minus="subtractQty('front')")

				tr(v-if="edit || spareParts.right_temple > 0")
					td {{'catalog.product.spare_parts.RIGHT_TEMPLE'|translate}}
					td
						OptiInputNumber.pull-right#right_temple(
							:disabled="!edit"
							:allow-minus="false"
							:value="spareParts.right_temple"

							@plus="++spareParts.right_temple"
							@minus="subtractQty('right_temple')")

				tr(v-if="edit || spareParts.left_temple > 0")
					td {{'catalog.product.spare_parts.LEFT_TEMPLE'|translate}}
					td
						OptiInputNumber.pull-right#left_temple(
							:disabled="!edit"
							:allow-minus="false"
							:value="spareParts.left_temple"

							@plus="++spareParts.left_temple"
							@minus="subtractQty('left_temple')")

				tr(v-if="edit || spareParts.right_lens > 0")
					td {{'catalog.product.spare_parts.RIGHT_LENS'|translate}}
					td
						OptiInputNumber.pull-right#right_lens(
							:disabled="!edit"
							:allow-minus="false"
							:value="spareParts.right_lens"

							@plus="++spareParts.right_lens"
							@minus="subtractQty('right_lens')")

				tr(v-if="edit || spareParts.left_lens > 0")
					td {{'catalog.product.spare_parts.LEFT_LENS'|translate}}
					td
						OptiInputNumber.pull-right#left_lens(
							:disabled="!edit"
							:allow-minus="false"
							:value="spareParts.left_lens"

							@plus="++spareParts.left_lens"
							@minus="subtractQty('left_lens')")

				tr(v-if="edit || spareParts.comments")
					td(colspan="2")
						label(style="font-weight:400" for="spare_parts_comments") {{'catalog.product.spare_parts.COMMENTS'|translate}}
						input#spare_parts_comments.form-control(:disabled="!edit" v-model="spareParts.comments")

		.panel-body(v-if="!spareParts && !edit")
			.row
				.col-xs-12.text-center.text-muted
					i.fa.fa-info-circle.mr3
					span Brak części zamiennych.

		.panel-footer.clearfix(v-if="show")
			.row(v-if="!edit")
				.col-xs-12
					OptiButton.pull-right(
						v-if="$uac.permission('products.spare_parts')"
						@click.native="edit = true") {{'catalog.product.buttons.EDIT'|translate}}
						template(#icon)
							i.fa.fa-edit

					OptiButtonHistory.pull-left(
						v-if="spareParts"
						:id="product_id"
						data="spare_parts")

			.row(v-if="edit")
				.col-xs-12(v-if="!spareParts")
					OptiButton(
						style="width: 100%; margin-bottom: 5px"
						@click.native="addRow"
					) Dodaj dla tego produktu
						template(#icon)
							i.fa.fa-plus
				//.col-xs-12
				//    OptiUpload#excel(
				//        prefix="spare_parts"
				//        :file="file"
				//
				//        @update-file="f => file = f"
				//        @update-loading ="l => loading.file = l")
				//
				//    OptiButton.pull-right(
				//        style="margin-top:5px"
				//        :disabled="!file"
				//        @click.native="loadFile"
				//    ) {{'catalog.product.buttons.LOAD_FILE'|translate}}
				//        template(#icon)
				//            i.fa.fa-file-excel-o

				.col-xs-12(style="margin-top: 10px")
					OptiButton.pull-right(
						type="success"
						@click.native="updateItem") {{'catalog.product.buttons.SAVE'|translate}}
						template(#icon)
							i.fa.fa-save

					OptiButton.pull-left(
						type="danger"
						@click.native="edit = false") {{'catalog.product.buttons.BACK'|translate}}
						template(#icon)
							i.fa.fa-angle-left

			MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
				OptiSparePartsDialog(
					:product_id="product_id"
					:change="passValue"
					@closed="dialog = false")
</template>

<script>
	import {API} from '@/js/app/vue/api'

	import OptiUpload from '@/js/app/vue/components/OptiUpload'
	import OptiButton from '@/js/app/vue/components/Button/OptiButton'
	import OptiInputNumber from '@/js/app/vue/components/Inputs/OptiInputNumber'
	import OptiButtonHistory from "@/js/app/vue/components/Button/OptiButtonHistory"
	import OptiDialog from "@/js/app/vue/components/OptiDialog"
	import OptiSparePartsDialog from "@/js/app/vue/components/Button/Dialogs/OptiSparePartsDialog"

	export default {
	    name: 'OptiProductSpareParts',
	    components: {OptiSparePartsDialog, OptiDialog, OptiButtonHistory, OptiInputNumber, OptiButton, OptiUpload},
	    props: {
	        product_id: {
	            type: Number,
	            required: true
	        }
	    },
	    data() {
	        return {
	            show: false,
	            edit: false,
	            dialog: false,

	            spareParts: null,
	            passValue: false,

	            file: null,
	            loading: {
	                file: false,
	                main: false
	            }
	        }
	    },
	    mounted() {
	        this.startUp()
	    },
	    methods: {
	        startUp() {
	            API.get('warehouse/products/getSpareParts/' + this.product_id).then((res) => {

	                if(res.data[0] !== 'EMPTY') {
	                    this.spareParts = res.data
	                } else {
	                    this.spareParts = null
	                }

	            }).catch(() => {
	                this.$notify.error('Coś poszło nie tak!')
	            })
	        },
	        updateItem() {
	            API.post('warehouse/products/updateSpareParts', {
	                update: this.spareParts
	            }).then(() => {
	                this.$notify.success('Zmiany zostały wprowadzone')
	            }).then(() => {
	                this.edit = false
	                this.startUp()
	            })
	        },
	        addRow() {
	            this.spareParts = {
	                id: 0,
	                product_id: this.product_id,
	                left_lens: 0,
	                right_lens: 0,
	                left_temple: 0,
	                right_temple: 0,
	                front: 0,
	                comments: ''
	            }
	        },
	        subtractQty(x) {
	            --this.spareParts[x]
	            this.passValue = x
	            this.dialog = true
	        }
	    }
	}
</script>