<template lang="pug">
    .panel.panel-primary(style="margin-bottom:10px")
        .panel-heading
            i.fa.fa-navicon.mr3
            span.mr3 {{'eShop.customer.orders.HEADING'|translate}}
            span \#{{customer.id}}

        .table-responsive
            table.table.table-striped.middle
                thead
                    tr
                        th #
                        th {{'eShop.customer.orders.ORDER_ID'|translate}}
                        th {{'eShop.order.STATUS'|translate}}
                        th {{'eShop.CREATED_AT'|translate}}
                        th {{'eShop.customer.orders.PRICE'|translate}}
                tbody
                    tr(v-for="(order, key) in customer.orders")
                        td.text-right {{customer.orders.length - key}}
                        td.text-right
                            a.link(:href="$state.href('app.eShop.order', {id: order.id})") {{order.id}}
                        td {{order.status_name|translate}}
                        td {{order.created_at}}
                        td.text-right {{order.total_price|currency}}

                    tr(v-show="!customer.orders.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

                tfoot(v-show="customer.orders.length")
                    tr.bold
                        td(colspan="3" style="border:0")
                        td.text-right {{'eShop.customer.ORDER_TOTAL_AMOUNT'|translate}}
                        td.text-right {{customer.order_total_amount|currency}}
</template>

<script>
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'

    export default {
        name: 'OptiEShopCustomerDetailsOrdersTable',
        components: {
            OptiProduct
        },
        props: {
            customer: {
                type: Object,
                required: true
            }
        }
    }
</script>