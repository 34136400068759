<template lang="pug">
    .row(v-if="help")
        .col-sm-6
            #offer-help-header
                i.fa.fa-2x.fa-chevron-left.offer-help-header-navi(
                    :class="{'offer-help-header-navi-disabled': !help.position.index_option}"
                    @click="$store.dispatch('offer/goChangeHelp', help.position.index_option - 1)")

                #offer-help-header-title
                    .section.text-muted {{'catalog.offer.questions.' + help.section + '.HEADING'|translate}}
                    .option {{'catalog.offer.questions.' + help.section + '.options.' + help.option_key.toUpperCase()|translate}}

                i.fa.fa-2x.fa-chevron-right.offer-help-header-navi(
                    :class="{'offer-help-header-navi-disabled': help.position.index_option === help.position.all - 1}"
                    @click="$store.dispatch('offer/goChangeHelp', help.position.index_option + 1)")

            .offer-help-description(v-if="help.description_extra")
                .section.text-muted {{'catalog.offer.questions.' + help.section + '.description_extra.HEADER'|translate}}
                div {{'catalog.offer.questions.' + help.section + '.description_extra.text.' + help.option_key.toUpperCase()|translate}}

            .offer-help-description(v-if="help.description")
                .section.text-muted {{'catalog.offer.help.DESCRIPTION'|translate}}
                div(v-html="$filters.translate('catalog.offer.questions.' + help.section + '.description.' + help.option_key.toUpperCase())")

            img.img-thumbnail.img-main(v-if="help.img.main"
                :src="'img/offer/' + help.section + '/' + help.option_key + '/main.jpg'"
                :alt="'catalog.offer.questions.' + help.section + '.HEADING'|translate")

            form#offer-help-attributes(v-if="help.section !== 'styles'")
                label.section.text-muted(:class="{'star-required': help.attributes.all}"
                    ) {{'catalog.offer.help.attributes.HEADING'|translate}} - {{help.attributes.header|translate}}
                div
                    .label-offer(v-for="attribute in help.attributes.keys" :title="'catalog.offer.translations.' + attribute|translate")
                        .label.label-primary {{'catalog.offer.translations.' + attribute|translate}}
                #offer-help-attributes-all-info(v-show="help.attributes.all")
                    label.star-required.mr3
                    span.text-muted - {{'catalog.offer.help.attributes.ALL'|translate}} {{help.attributes.header|translate}}

        .col-sm-6
            .row(v-for="chunk in examples_chunk")
                div(:class="'col-' + help.img.examples.style" v-for="img in chunk" :key="img.path")
                    .thumbnail
                        img(:src="img.path")
                        .caption(v-show="img.caption") {{img.caption}}
</template>

<script>
    export default {
        name: 'OptiCatalogOfferHelp',
        computed: {
            help() {
                return this.$store.getters['offer/getHelp']
            },
            examples_chunk() {
                return this.$store.getters['offer/getExamplesChunk']
            }
        }
    }
</script>

<style lang="less" scoped>
    @margin-bottom: 20px;

    img {
        width: 100%;
        margin-bottom: @margin-bottom;
    }

    .section {
        text-align: center;
    }

    #offer-help-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: @margin-bottom;
        text-align: center;

        > #offer-help-header-title {
            min-width: 170px;

            > .option {
                font-size: 22px;
                line-height: 1em;
                font-weight: 700;
            }
        }

        > .offer-help-header-navi {
            &:not(.offer-help-header-navi-disabled) {
                cursor: pointer;
            }

            &.offer-help-header-navi-disabled {
                opacity: .2;
            }
        }
    }

    .offer-help-description {
        margin-bottom: @margin-bottom;
    }

    form#offer-help-attributes {
        margin-bottom: @margin-bottom - 5px;

        label {
            font-weight: 400;

            &.section {
                display: block;
                margin-bottom: 5px;
            }
        }

        > div {
            overflow-wrap: anywhere;
        }
    }

    .thumbnail {
        > img {
            margin-bottom: 0px;
        }

        > .caption {
            text-align: center;
            color: #000;
        }
    }
</style>