<template lang="pug">
    form.form-horizontal
        .form-group
            .col-sm-8.col-sm-offset-4
                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" name="equal" v-model="form.equal" @change="setData")
                            span.fa.fa-check
                    span {{'bookkeeping.expenses.EQUAL'|translate}}
        .form-group
            label.col-sm-4.control-label.star-required(for="value") {{'bookkeeping.expenses.table.AMOUNT'|translate}}
            .col-sm-8
                OptiNumber(:header="'bookkeeping.expenses.table.AMOUNT'|translate"
                    :value="form.value" @confirmed="updateMainValue"
                    :disabled="!form.equal" :minus="true" :float="true" :required="true") {{form.value|currency}}
                OptiValidate(f="value" :data="$v.form.value")

        .form-group
            .col-sm-8.col-sm-offset-4
                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" id="cyclical" name="cyclical" v-model="form.cyclical")
                            span.fa.fa-check
                    span {{'bookkeeping.expenses.table.CYCLICAL'|translate}}
        .form-group(v-show="form.cyclical")
            label.col-sm-4.control-label(for="cyclical_date" :class="{'star-required': form.cyclical}"
                ) {{'bookkeeping.expenses.table.CYCLICAL_DATE'|translate}}
            .col-sm-8
                OptiDate#cyclical_date(:value="form.cyclical_date" @update-date="c => form.cyclical_date = c")
                OptiValidate(f="cyclical_date" :data="v.cyclical_date")

        .form-group(style="margin-bottom:0")
            .panel.panel-primary(style="margin-bottom:0")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'bookkeeping.expenses.DEPARTMENTS'|translate}}

                    .label.label-danger.pull-right {{count}}

                .panel-body.bg-separator
                    .row
                        .col-sm-6.col-sm-offset-6(style="margin-bottom:10px")
                            OptiUpload#excel(
                                prefix="expenses"

                                :file="upload.excel"

                                @update-loading="l => upload.file_loading = l"
                                @update-file="setDataUpload")
                        .col-xs-12.col-sm-4
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="jawro_loox" v-model="filter.jawro_loox.checked"
                                        @change="filterChanged" :disabled="!filter.jawro_loox.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.jawro_loox.count}") {{'departments.filter.JAWROLOOX'|translate}}
                        .col-xs-12.col-sm-5
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="franchisee_loox" v-model="filter.franchisee_loox.checked"
                                        @change="filterChanged" :disabled="!filter.franchisee_loox.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.franchisee_loox.count}") {{'departments.filter.FRANCHISEELOOX'|translate}}
                        .col-xs-12.col-sm-3(style="padding-left:0")
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="cooperating" v-model="filter.cooperating.checked"
                                        @change="filterChanged" :disabled="!filter.cooperating.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.cooperating.count}") {{'departments.filter.COOPERATING'|translate}}
                    .row
                        .col-xs-12.col-sm-4
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="jawro_sunloox" v-model="filter.jawro_sunloox.checked"
                                        @change="filterChanged" :disabled="!filter.jawro_sunloox.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.jawro_sunloox.count}") {{'departments.filter.JAWROSUNLOOX'|translate}}
                        .col-xs-12.col-sm-5
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="franchisee_sunloox" v-model="filter.franchisee_sunloox.checked"
                                        @change="filterChanged" :disabled="!filter.franchisee_sunloox.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.franchisee_sunloox.count}") {{'departments.filter.FRANCHISEESUNLOOX'|translate}}
                        .col-xs-12.col-sm-3
                            label
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    input(type="checkbox" name="all" v-model="filter.all.checked"
                                        @change="filterChanged" :disabled="!filter.all.count")
                                    span.fa.fa-check(style="margin-left:0px")
                                span(:class="{'strikethrough': !filter.all.count}") {{'departments.filter.ALL'|translate}}

                .table-responsive
                    table.table.table-striped.middle
                        tbody
                            tr(v-for="department in form.departments")
                                td.text-center
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" :id="'department-' + department.id" :name="'department-' + department.id"
                                                v-model="department.checked" @change="setData")
                                            span.fa.fa-check
                                td
                                    label(style="margin-bottom:0" :for="'department-' + department.id") {{department.name}}
                                td
                                    OptiNumber(:header="department.name" :value="department.value" @confirmed="v => updateValue(department, v)"
                                        :disabled="!department.checked || form.equal" :minus="true" :float="true") {{department.value|currency}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'

    export default {
        name: 'OptiBookkeepingExpensesAddDepartments',
        components: {
            OptiNumber,
            OptiValidate,
            OptiDate,
            OptiUpload
        },
        props: {
            form: {
                type: Object,
                required: true
            },
            v: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filter: {
                    jawro_loox: { // type - 1, logo - 4
                        checked: false,
                        count: 0
                    },
                    jawro_sunloox: { // type - 1, logo - 5
                        checked: false,
                        count: 0
                    },
                    franchisee_loox: { // type - 3, logo - 4
                        checked: false,
                        count: 0
                    },
                    franchisee_sunloox: { // type - 3, logo - 5
                        checked: false,
                        count: 0
                    },
                    cooperating: { // type - 4
                        checked: false,
                        count: 0
                    },
                    all: {
                        checked: false,
                        count: 0
                    }
                },

                count: 0,

                upload: {
                    excel: null,
                    file_loading: false,
                    loading: false
                }
            }
        },
        validations: {
            form: {
                value: {
                    noZero
                }
            }
        },
        mounted() {
            this.form.departments.forEach(department => {
                if(department.type === 1 && department.logo === 4) {
                    ++this.filter.jawro_loox.count
                } else if(department.type === 1 && department.logo === 5) {
                    ++this.filter.jawro_sunloox.count
                } else if(department.type === 3 && department.logo === 4) {
                    ++this.filter.franchisee_loox.count
                } else if(department.type === 3 && department.logo === 5) {
                    ++this.filter.franchisee_sunloox.count
                } else if(department.type === 4) {
                    ++this.filter.cooperating.count
                }

                ++this.filter.all.count
            })
        },
        methods: {
            setFilters() {
                let jawro_loox = true,
                    jawro_sunloox = true,
                    franchisee_loox = true,
                    franchisee_sunloox = true,
                    cooperating = true,
                    all = true

                if(!this.filter.jawro_loox.count) {
                    jawro_loox = false
                }

                if(!this.filter.jawro_sunloox.count) {
                    jawro_sunloox = false
                }

                if(!this.filter.franchisee_loox.count) {
                    franchisee_loox = false
                }

                if(!this.filter.franchisee_sunloox.count) {
                    franchisee_sunloox = false
                }

                if(!this.filter.cooperating.count) {
                    cooperating = false
                }

                if(!this.filter.all.count) {
                    all = false
                }

                this.form.departments.forEach(department => {
                    if(!department.checked) {
                        if(department.type === 1 && department.logo === 4) {
                            jawro_loox = false
                        } else if(department.type === 1 && department.logo === 5) {
                            jawro_sunloox = false
                        } else if(department.type === 3 && department.logo === 4) {
                            franchisee_loox = false
                        } else if(department.type === 3 && department.logo === 5) {
                            franchisee_sunloox = false
                        } else if(department.type === 4) {
                            cooperating = false
                        }

                        all = false
                    }
                })

                this.filter.jawro_loox.checked = jawro_loox
                this.filter.jawro_sunloox.checked = jawro_sunloox
                this.filter.franchisee_loox.checked = franchisee_loox
                this.filter.franchisee_sunloox.checked = franchisee_sunloox
                this.filter.cooperating.checked = cooperating
                this.filter.all.checked = all
            },
            setCheckedCount() {
                this.count = 0

                this.form.departments.forEach(department => {
                    if(department.checked) {
                        ++this.count
                    } else {
                        department.value = 0
                    }
                })
            },
            calculateEquals() {
                let value = this.form.value / this.count

                this.form.departments.forEach(department => {
                    if(department.checked) {
                        department.value = value
                    }
                })
            }, sumValue() {
                this.form.value = 0

                this.form.departments.forEach(department => {
                    this.form.value += department.value
                })
            },
            setData() {
                this.setFilters()
                this.setCheckedCount()

                if(this.form.equal) {
                    this.calculateEquals()
                } else {
                    this.sumValue()
                }
            },

            filterChanged() {
                let all_checked_except_all = this.filter.jawro_loox.checked && this.filter.jawro_sunloox.checked &&
                    this.filter.franchisee_loox.checked && this.filter.franchisee_sunloox.checked &&
                    this.filter.cooperating.checked

                if(this.filter.all.count === this.count) {
                    if(!all_checked_except_all) {
                        this.filter.all.checked = false;
                    } else if(!this.filter.all.checked) {
                        this.filter.jawro_loox.checked = this.filter.jawro_sunloox.checked =
                        this.filter.franchisee_loox.checked = this.filter.franchisee_sunloox.checked =
                        this.filter.cooperating.checked = false
                    }
                }

                this.count = 0

                this.form.departments.forEach(department => {
                    if((this.filter.jawro_loox.checked && department.type === 1 && department.logo === 4) ||
                        (this.filter.jawro_sunloox.checked && department.type === 1 && department.logo === 5) ||
                        (this.filter.franchisee_loox.checked && department.type === 3 && department.logo === 4) ||
                        (this.filter.franchisee_sunloox.checked && department.type === 3 && department.logo === 5) ||
                        (this.filter.cooperating.checked && department.type === 4) || this.filter.all.checked) {
                        department.checked = true

                        ++this.count
                    } else {
                        department.checked = false
                    }
                })

                this.setData()
            },

            setDataUpload(file) {
                if(file && this.upload.excel !== file) {
                    this.upload.excel = file

                    let extension = this.upload.excel.split('.')[2]

                    if(!['xls', 'xlsx'].includes(extension)) {
                        this.$notify.error('bookkeeping.expenses.notify.upload.error.EXTENSION')
                    } else {
                        this.upload.loading = true

                        API.post('bookkeeping/expenses/excel', {
                            upload: this.upload.excel
                        }).then(res => {
                            let department_ids = Object.keys(res.data)

                            this.form.departments.forEach(department => {
                                if(department_ids.includes(department.id.toString())) {
                                    department.checked = true
                                    department.value = res.data[department.id]
                                }
                            })

                            this.setData()

                            this.$notify.success('bookkeeping.expenses.notify.upload.PARSE')
                        }).finally(() => {
                            this.upload.loading = false
                        }).catch(() => {
                            this.$notify.error('bookkeeping.expenses.notify.upload.error.PARSE', true, true)
                        })
                    }
                }
            },

            updateMainValue(v) {
                this.form.value = v

                this.setData()
            },
            updateValue(department, v) {
                department.value = v

                this.setData()
            }
        }
    }
</script>
