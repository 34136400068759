<template lang="pug">
    OptiButton(type="primary" @click.native="toggleHistory(true)") {{'default.HISTORY'|translate}}
        template(#icon)
            i.fa.fa-book

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogHistory(
                :endpoint="endpoint"
                :data="data"
                :id="id"

                @closed="toggleHistory(false)")
</template>

<script>
    import OptiDialogHistory from '@/js/app/vue/components/Button/Dialogs/OptiDialogHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiButtonHistory',
        components: {
            OptiDialogHistory,
            OptiButton
        },
        props: {
            endpoint: {
                type: String,
                required: false
            },

            data: {
                type: String,
                required: false
            },
            id: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            toggleHistory(bool) {
                this.open = bool
            }
        }
    }
</script>