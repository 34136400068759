<template lang="pug">
    #summaryOptometrist
        .row
            .col-sm-6
                label(style="margin-left:20px")
                    .checkbox.c-checkbox.checkbox-primary.inline
                        input(type="checkbox" name="refused_covd" v-model="interview.refused_covd" :disabled="block2"
                            @change="goRefusedCovd")
                        span.fa.fa-check
                    span {{'optometrist.refusedcovd.LABEL'|translate}}
            .col-sm-6.text-right-not-xs
                label(style="margin-left:20px")
                    .checkbox.c-checkbox.checkbox-primary.inline
                        input(type="checkbox" name="rodo" v-model="interview.rodo" :disabled="block2"
                            @change="$emit('update-interview', interview)")
                        span.fa.fa-check
                    span {{'optometrist.RODO'|translate}}

        .row.warn(style="padding-bottom:10px" v-show="interview.pills")
            .col-xs-12.text-center
                i.fa.fa-exclamation-circle.mr3
                span {{'optometrist.disease.PILLSINFO'|translate}}
        .row.warn(style="padding-bottom:10px" v-show="interview.status === 2 || interview.status === 3")
            .col-xs-12.text-center(v-show="interview.status === 2")
                i.fa.fa-exclamation-circle.mr3
                span.mr3 {{'optometrist.disease.doctor.RECOMMENDED'|translate}}
                span(v-show="interview.doctor.id") ({{interview.doctor.lastName}} {{interview.doctor.firstName}})
            .col-sm-6(v-show="(interview.recommended_visit_reason || interview.recommended_visit_return) && interview.recommended_visit_reason")
                b(style="display:block") {{'optometrist.disease.doctor.REASON'|translate}}
                span(v-html="$options.filters.nl2br(interview.recommended_visit_reason)")
            .col-sm-6(v-show="(interview.recommended_visit_reason || interview.recommended_visit_return) && interview.recommended_visit_return")
                b(style="display:block") {{'optometrist.disease.doctor.COMMENTRETURN'|translate}}
                span(v-html="$options.filters.nl2br(interview.recommended_visit_return)")

        OptiOptometristsDetailsSummaryDiseases(
            :diseases="interview.diseases")

        OptiOptometristsDetailsSummaryCOVD(
            :questionnaire="interview.questionnaire"
            :points="points"

            :block2="block2"

            @update-questionnaire="updateQuestionnaire")

        OptiOptometristsDetailsSummaryScreeningTests(
            :interview="interview"
            :maddox="maddox"
            :age="age")

        .row
            .col-xs-12
                .table-responsive
                    table.table.table-striped.text-center.middle(style="margin-bottom:0")
                        thead
                            tr
                                td(style="text-align:left" colspan="4")
                                    i.fa.fa-navicon.mr3
                                    span {{'optometrist.breadcrumb.section.REFRACTION'|translate}}

            .col-sm-5
                OptiOptometristsDetailsSummaryAcuity(
                    :interview="interview"

                    :correction="correction"

                    :active-cyl="activeCyl"
                    :active-cyl-contact-lens="activeCylContactLens")

            .col-sm-7
                OptiOptometristsDetailsSummaryCorrection(
                    :interview="interview")

                .row(v-if="!extra")
                    .col-xs-12
                        OptiButton(type="success" :disabled="disabled" @click.native="goToPrescription()"
                            ) {{'optometrist.prescription.confirm.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-file-text-o

                        MountingPortal(v-if="dialog_prescription.confirm" mountTo="#vue-modal" append)
                            OptiDialogConfirm(text="optometrist.prescription.confirm"
                                @confirmed="createPrescription(1)" @closed="dialog_prescription.confirm = false")

                        MountingPortal(v-if="dialog_prescription.difference" mountTo="#vue-modal" append)
                            OptiOptometristsDetailsSummaryDialogPrescriptionDifference(
                                :interview="interview"

                                @create-prescription="t => createPrescription(t)"
                                @cancelled="dialog_prescription.difference = false")

                        OptiButton.pull-right(type="info" :disabled="interview.status !== 3 || interview.contactLens.order_contact_lens"
                            @click.native="goToContactLens") {{'optometrist.contactlens.order.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-truck
                    .col-xs-12.text-center(style="padding-top:15px" v-show="interview.contactLens.control_visit && interview.contactLens.matching === 2")
                        OptiButton(type="success" :disabled="interview.contactLens.control_visit_exists || !interview.contactLens.order_contact_lens"
                            text="optometrist.contactlens.finish.new" @confirmed="createNewInterview") {{'optometrist.contactlens.finish.new.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-arrow-right

        .row
            .col-xs-12
                label(for="suggestions" style="font-weight:400")
                    i.fa.fa-navicon.mr3
                    span {{'prescription.SUGGESTIONS'|translate}}
                textarea#suggestions.form-control(rows="2" name="suggestions" v-model.lazy="interview.suggestions"
                    @change="$emit('update-interview', interview)" :disabled="disabled || extra")

        .row(v-show="matrix")
            .col-xs-12.bold.text-expressive.text-center(style="padding:40px") {{matrix}}

        .row(style="padding-top:10px")
            .col-xs-4
                OptiButtonHistory(data="interview" :id="interview.id")
            .col-xs-4.text-center
                OptiButton(v-show="!extra" @click.native="$state.go('app.optometrist.extra.details', {id: interview.id})"
                    ) {{'optometrist.extra.breadcrumb.OPTOMETRIST'|translate}}
                    template(#icon)
                        i.fa.fa-user-md

                OptiButton(v-show="extra" @click.native="$state.go('app.optometrist.details', {id: interview.id})"
                    ) {{'optometrist.breadcrumb.INTERVIEW'|translate}}
                    template(#icon)
                        i.fa.fa-eye
            .col-xs-4.text-right(v-show="!extra")
                OptiButton(type="danger" :disabled="disabled"
                    text="optometrist.cancelled" @confirmed="goCancel") {{'optometrist.cancelled.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-remove
</template>

<script>
    import {ValidateInterview} from '@/js/app/vue/components/Optometrists/Helpers/ValidateInterview'
    import {Activity} from '@/js/app/vue/helpers/Activity'
    import {InterviewRecommendation} from '@/js/app/vue/components/Optometrists/Helpers/InterviewRecommendation'
    import {API} from '@/js/app/vue/api'

    import OptiOptometristsDetailsSummaryDiseases
        from '@/js/app/vue/components/Optometrists/Details/Summary/OptiOptometristsDetailsSummaryDiseases'
    import OptiOptometristsDetailsSummaryCOVD
        from '@/js/app/vue/components/Optometrists/Details/Summary/OptiOptometristsDetailsSummaryCOVD'
    import OptiOptometristsDetailsSummaryScreeningTests
        from '@/js/app/vue/components/Optometrists/Details/Summary/OptiOptometristsDetailsSummaryScreenningTests'
    import OptiOptometristsDetailsSummaryAcuity
        from '@/js/app/vue/components/Optometrists/Details/Summary/OptiOptometristsDetailsSummaryAcuity'
    import OptiOptometristsDetailsSummaryCorrection
        from '@/js/app/vue/components/Optometrists/Details/Summary/OptiOptometristsDetailsSummaryCorrection'
    import OptiOptometristsDetailsSummaryDialogPrescriptionDifference
        from '@/js/app/vue/components/Optometrists/Details/Summary/Dialogs/OptiOptometristsDetailsSummaryDialogPrescriptionDifference'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogConfirm from '@/js/app/vue/components/Button/Dialogs/OptiDialogConfirm'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'

    export default {
        name: 'OptiOptometristsDetailsSummary',
        components: {
            OptiOptometristsDetailsSummaryDiseases,
            OptiOptometristsDetailsSummaryCOVD,
            OptiOptometristsDetailsSummaryScreeningTests,
            OptiOptometristsDetailsSummaryAcuity,
            OptiOptometristsDetailsSummaryCorrection,
            OptiOptometristsDetailsSummaryDialogPrescriptionDifference,

            OptiButton,
            OptiDialogConfirm,
            OptiButtonHistory
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },

            activeCyl: {
                type: String,
                required: true
            },
            activeCylContactLens: {
                type: String,
                required: true
            },

            points: {
                type: Object,
                required: true
            },
            maddox: {
                type: Object,
                required: true
            },
            age: {
                type: Number,
                required: false,
                default: 0
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                dialog_prescription: {
                    confirm: false,
                    difference: false
                }
            }
        },
        mounted() {

        },
        methods: {
            goRefusedCovd() {
                if(this.interview.refused_covd) {
                    if(!confirm(this.$filters.translate('optometrist.refusedcovd.confirm.MESSAGE'))) {
                        this.$nextTick(() => {
                            this.interview.refused_covd = false
                        })
                    }
                }

                this.$nextTick(() => {
                    this.$emit('update-interview', this.interview)
                })
            },
            updateQuestionnaire({questions, points}) {
                this.interview.questionnaire = questions
                this.$emit('update-interview', this.interview)

                this.points.covd = points
                this.$emit('update-points', this.points)
            },

            createPrescription(type) {
                this.dialog_prescription.confirm = false
                this.dialog_prescription.difference = false

                API.post('optometrist/interview/prescription', {
                    id: this.interview.id,
                    rodo: this.interview.rodo,
                    type: type
                }).then(res => {
                    if(res.data.length === 1) {
                        this.$state.go('app.patient.detail', {
                            id: this.interview.patient.id,
                            '#': 'prescriptions',
                            prescriptionId: res.data[0]
                        })
                    } else {
                        this.$state.go('app.patient.detail', {
                            id: this.interview.patient.id,
                            '#': 'prescriptions'
                        })
                    }

                    this.$notify.success('optometrist.prescription.notify.CREATE')
                }).catch(() => {
                    this.$notify.error('optometrist.prescription.notify.error.CREATE')
                })
            },
            goToPrescription() {
                setTimeout(() => {
                    let errors = ValidateInterview.checkToPrescription(this.interview, this.previouslens, this.points.covd)

                    if(errors.length) {
                        errors.forEach(error => {
                            this.$notify.error(error)
                        })
                    } else {
                        if(this.interview.dist_r_cylinder === this.interview.near_r_cylinder &&
                            this.interview.dist_l_cylinder === this.interview.near_l_cylinder &&
                            this.interview.dist_r_axis === this.interview.near_r_axis &&
                            this.interview.dist_l_axis === this.interview.near_l_axis) {
                            this.dialog_prescription.confirm = true
                        } else {
                            this.dialog_prescription.difference = true
                        }
                    }
                }, 500)
            },

            goToContactLens() {
                this.interview.contactLens.order_contact_lens = true
                this.interview.contactLens.matching_date = moment().format('YYYY-MM-DD')

                this.$emit('update-interview', this.interview)
            },

            createNewInterview() {
                API.get('optometrist/interview/generate/' + this.interview.patient.id + '/0').then(res => {
                    Activity.increaseForPatient(this.interview.patient.id)

                    this.$state.go('app.optometrist.details', {id: res.data.id})

                    this.$notify.success('optometrist.notify.CREATE')
                }).catch(() => {
                    this.$notify.error('optometrist.contactlens.controlvisit.error.NEW')
                })
            },

            goCancel() {
                this.interview.status = 0

                this.$emit('update-interview', this.interview)
            }
        },
        computed: {
            block2() {
                return [0, 2, 3].includes(this.interview.status)
            },
            extra() {
                return this.$state.current.name === 'app.optometrist.extra.details'
            },
            matrix() {
                return InterviewRecommendation.check(this.interview)
            }
        }
    }
</script>