<template lang="pug">
    .opti-select-graph-lenses
        VueMultiSelect(
            :id="id"
            track-by="id"
            :clearable="false"
            :placeholder="'search.graph-lenses.PLACEHOLDER'|translate"
            :showLabels="false"
            label="text"
            :options="lenses"

            @input="c => $emit('selected', c)")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.extra.name}}

            template(slot="option" slot-scope="props")
                div
                    span {{props.option.extra.name}}
                    .pull-right {{props.option.extra.price}}

                OptiPictograms(:pack-details="props.option.extra.pictograms")

                ul.lens-info-errors(v-show="props.option.extra.disabled.errors.length")
                    li(v-for="error in props.option.extra.disabled.errors")
                        i.fa.fa-exclamation-circle.mr3
                        span {{error.text}}

                ul.lens-info-errors.lens-info-errors-warnings(v-show="props.option.extra.disabled.warnings.length")
                    li(v-for="warning in props.option.extra.disabled.warnings")
                        i.fa.fa-exclamation-circle.mr3
                        span {{warning.text}}

            span.text-muted(slot="noResult")
                i.fa.fa-exclamation-circle.mr3
                span {{'default.notify.EMPTYSEARCH'|translate}}

            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'

    export default {
        name: 'OptiSelectGraphLenses',
        components: {
            VueMultiSelect,
            OptiPictograms
        },
        props: {
            id: {
                type: String,
                required: true
            },
            data: {
                type: Array,
                required: true
            },
            naviDirection: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            lenses() {
                this.data.forEach(lenses => {
                    lenses.$isDisabled = !!lenses.extra.disabled.errors.length
                })

                return _.orderBy(this.data, ['extra.price'], [this.naviDirection ? 'desc' : 'asc'])
            }
        }
    }
</script>

<style lang="less" scoped>
    .opti-select-graph-lenses {
        width: 1000px;

        /deep/ .multiselect__content-wrapper {
            max-height: 85vh !important;
        }

        /deep/ .multiselect__option--disabled {
            opacity: .5;
            color: inherit !important;
        }

        /deep/ .multiselect__content-wrapper {
            display: block !important;
        }

        .multiselect__option.multiselect__option--highlight > ul > li {
            color: #fff;
        }
    }
</style>