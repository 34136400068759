<template lang="pug">
    .row
        .col-xs-12.text-right(style="margin-bottom:15px" v-show="!loading")
            OptiButtonHistory(:endpoint="'dictionary/pictograms/coatings/history'")

        .col-xs-12(v-show="!loading")
            .panel.panel-primary(style="position:relative" v-for="coating in coatings")
                .panel-heading(style="cursor:pointer" @click="openEdit(coating)")
                    i.fa.fa-navicon.mr3
                    span {{coating.name_long}}

                    .pull-right
                        i.fa(:class="{'fa-arrow-down': !coating.edit, 'fa-arrow-up': coating.edit}")

                .row
                    .col-sm-6
                        OptiPictograms(v-if="coating.packDetails.length" :pack-details="coating.packDetails")

                        .text-center.text-muted(style="margin:20px" v-else)
                            i.fa.fa-exclamation-circle.mr3
                            span {{'dictionary.pictogram.NONE'|translate}}
                    .col-sm-6
                        div(style="margin:5px" v-if="coating.hoyaOptions.filter(h => h.id).length")
                             .mr3 {{'dictionary.pictogram.LINK'|translate}}
                             span.bold(style="margin-right:10px" v-for="hoyaOption in coating.hoyaOptions") {{hoyaOption.name_long}}

                        .text-center.text-muted(style="margin:20px" v-else)
                            i.fa.fa-exclamation-circle.mr3
                            span {{'dictionary.pictogram.link.notify.EMPTY'|translate}}

                .panel-body.bg-separator(style="padding-top:25px" v-show="coating.edit")
                    img.optional(v-for="pictogram in pictograms"
                        :class="{'selected': coating.packDetails.map(d => d.id).includes(pictogram.id)}"
                        :title="pictogram.name" :src="'img/task/pack/' + pictogram.id + '.png'"
                        @click="select(coating, pictogram)")

                .panel-body(style="padding-bottom:0" v-show="coating.edit")
                    .row
                        .col-sm-6(v-if="coating.hoyaOptions.length")
                            .row.row-link-padding(v-for="(hoyaOption, key) in coating.hoyaOptions" :key="hoyaOption.id"
                                :class="{'bg-separator': key % 2}")
                                label.col-xs-1.form-control-static.text-right(:for="coating.id + '_' + key") {{key + 1}}
                                .col-xs-9
                                    select.form-control(:id="coating.id + '_' + key" v-model="hoyaOption.id"
                                        @change="hoyaOption.name_long = coatings.filter(c => c.id === hoyaOption.id)[0].name_long")
                                        option(v-for="coating in coatings.filter(c => c.id === hoyaOption.id || (c.id !== coating.id && !coating.hoyaOptions.map(h => h.id).includes(c.id)))"
                                            :value="coating.id") {{coating.name_long}}
                                .col-xs-2.text-center
                                    .btn.btn-danger(@click="coating.hoyaOptions.splice(key, 1)")
                                        i.fa.fa-trash

                        .col-xs-12.text-center.text-muted(style="margin-bottom:15px" v-else)
                            i.fa.fa-exclamation-circle.mr3
                            span {{'dictionary.pictogram.link.notify.EMPTY'|translate}}

                .panel-footer(v-show="coating.edit")
                    OptiButton(type="success" :disabled="!!(coating.loading || coating.hoyaOptions.filter(h => !h.id).length)"
                        @click.native="coating.hoyaOptions.push({id: 0, name_long: ''})") {{'dictionary.pictogram.link.ADD'|translate}}
                        template(#icon)
                            i.fa.fa-plus

                    OptiButtonSave.pull-right(:disabled="!!(coating.loading || coating.hoyaOptions.filter(h => !h.id).length)"
                        @click.native="save(coating)")

                OptiDimmer(:active="!!coating.loading")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryPictogramsCoatingsEdit',
        components: {
            OptiButtonHistory,
            OptiPictograms,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                coatings: [],
                pictograms: [],

                loading: true
            }
        },
        mounted() {
            API.get('dictionary/pictograms/coatings').then(res => {
                this.coatings = res.data.coatings
                this.pictograms = res.data.pictograms
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('dictionary.pictogram.notify.error.LOAD')
            })
        },
        methods: {
            openEdit(coating) {
                this.$set(coating, 'edit', !coating.edit)
            },

            select(coating, pictogram) {
                if(coating.packDetails.map(d => d.id).includes(pictogram.id)) {
                    coating.packDetails.splice(_.findIndex(coating.packDetails, {id: pictogram.id}), 1)
                } else {
                    coating.packDetails.push(pictogram)
                    coating.packDetails = _.orderBy(coating.packDetails, ['subgroup', 'order'])
                }
            },

            save(coating) {
                this.$set(coating, 'loading', true)

                API.post('dictionary/pictograms/coatings/save', {
                    option_id: coating.id,
                    pack_detail_ids: coating.packDetails.map(p => p.id),
                    hoya_option_ids: coating.hoyaOptions.map(h => h.id)
                }).then(res => {
                    coating.packDetails = res.data.packDetails
                    coating.hoyaOptions = res.data.hoyaOptions

                    this.$notify.success('dictionary.pictogram.notify.SAVE')
                }).finally(() => {
                    coating.loading = false
                    coating.edit = false
                }).catch(() => {
                    this.$notify.error('dictionary.pictogram.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    img.optional {
        width: 80px;
        margin: 0 10px 10px 0;
        opacity: .2;

        &.selected {
            opacity: 1;
        }
    }

    .row.row-link-padding {
        padding: 10px 0;
    }
</style>