<template lang="pug">
    div(:class="{'table-responsive': previouslens.dist}")
        table.table.table-striped.text-center.middle
            thead
                tr
                    th.text-center(style="border-right:1px solid #aaa;border-top:1px solid #aaa" colspan="3") {{'optometrist.acuity.WITHOUTCORRECTION'|translate}}
                    th(style="border-top:1px solid #aaa" colspan="4") {{'optometrist.acuity.WITHCORRECTION'|translate}}
                    th(style="border-top:1px solid #aaa;text-align:right" colspan="6")
                        div(v-show="acuityAxisExists")
                            OptiButton(type="success" v-show="activeCyl === 'plus'"
                                @click.native="$emit('update-cyl', 'minus')") {{'optometrist.cylinder.MINUS'|translate}}
                                template(#icon)
                                    i.fa.fa-minus
                            OptiButton(type="success" v-show="activeCyl === 'minus'"
                                @click.native="$emit('update-cyl', 'plus')") {{'optometrist.cylinder.PLUS'|translate}}
                                template(#icon)
                                    i.fa.fa-plus
            tbody
                tr
                    th {{'optometrist.acuity.FAR'|translate}}
                    td Visus
                    td(style="border-right:1px solid #aaa") BinVisus
                    th(style="width:80px" v-show="previouslens.dist") {{'optometrist.acuity.FAR'|translate}}
                    td.text-center.warn.bold(rowspan="3" colspan="2"
                        v-show="previouslens.dist && previouslens.dist_cant_read_powers") {{'previouslens.READPOWER'|translate}}
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers") SPH
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers") CYL
                    td(v-show="previouslens.dist && acuityAxisDistExists") AXS
                    td(v-show="previouslens.dist && (interview.dist_c_r_add || interview.dist_c_l_add)") Add
                    td(v-show="previouslens.dist && (interview.dist_c_r_prism || interview.dist_c_l_prism)") Prism
                    td(v-show="previouslens.dist && acuityAngleDistExists") Angle
                    td(v-show="previouslens.dist") Visus
                    td(v-show="previouslens.dist") C/Z
                    td(v-show="previouslens.dist") BinVisus
                    td.text-center.warn.bold(rowspan="3" colspan="13" v-show="!previouslens.dist") {{'optometrist.correction.NORESULT'|translate}}
                tr
                    th
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.acuity_no_correction.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.acuity_no_correction.r" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityNoCorrection(
                                    :interview="interview"
                                    side="right"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.acuity_no_correction.r = false")
                    td
                        span.form-control(disabled) {{interview.dist_nc_r_visus}}
                    td(style="border-right:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.dist_nc_binvisus}}
                    th(v-show="previouslens.dist")
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.acuity_with_correction.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.acuity_with_correction.r" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                    :interview="interview"
                                    :data="acuity_with_correction"
                                    side="right"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.acuity_with_correction.r = false")
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].dist.r.sph|plus}}
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].dist.r.cyl|plus}}
                    td(v-show="previouslens.dist && acuityAxisDistExists")
                        span.form-control(disabled v-show="correction[activeCyl].dist.r.cyl") {{correction[activeCyl].dist.r.axs}}
                    td(v-show="previouslens.dist && (interview.dist_c_r_add || interview.dist_c_l_add)")
                        span.form-control(disabled) {{interview.dist_c_r_add|number(2)}}
                    td(v-show="previouslens.dist && (interview.dist_c_r_prism || interview.dist_c_l_prism)")
                        span.form-control(disabled) {{interview.dist_c_r_prism|number(2)}}
                    td(v-show="previouslens.dist && acuityAngleDistExists")
                        span.form-control(disabled v-show="interview.dist_c_r_prism") {{interview.dist_c_r_base}}
                    td(v-show="previouslens.dist")
                        span.form-control(disabled) {{interview.dist_c_r_visus}}
                    td(v-show="previouslens.dist")
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.dist_c_r_cz" :disabled="true")
                    td(rowspan="2" v-show="previouslens.dist")
                        span.form-control(disabled) {{interview.dist_c_binvisus}}
                tr
                    th
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.acuity_no_correction.l = true") {{'prescription.LEFT'|translate}}

                            MountingPortal(v-if="dialog.acuity_no_correction.l" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityNoCorrection(
                                    :interview="interview"
                                    side="left"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.acuity_no_correction.l = false")
                    td
                        span.form-control(disabled) {{interview.dist_nc_l_visus}}
                    th(v-show="previouslens.dist")
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.acuity_with_correction.l = true") {{'prescription.LEFT'|translate}}

                        MountingPortal(v-if="dialog.acuity_with_correction.l" mountTo="#vue-modal" append)
                            OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                :interview="interview"
                                :data="acuity_with_correction"
                                side="left"
                                @confirmed="updateInterview"
                                @cancelled="dialog.acuity_with_correction.l = false")
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].dist.l.sph|plus}}
                    td(v-show="previouslens.dist && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].dist.l.cyl|plus}}
                    td(v-show="previouslens.dist && acuityAxisDistExists")
                        span.form-control(disabled v-show="correction[activeCyl].dist.l.cyl") {{correction[activeCyl].dist.l.axs}}
                    td(v-show="previouslens.dist && (interview.dist_c_r_add || interview.dist_c_l_add)")
                        span.form-control(disabled v-show="previouslens.dist") {{interview.dist_c_l_add|number(2)}}
                    td(v-show="previouslens.dist && (interview.dist_c_r_prism || interview.dist_c_l_prism)")
                        span.form-control(disabled) {{interview.dist_c_l_prism|number(2)}}
                    td(v-show="previouslens.dist && acuityAngleDistExists")
                        span.form-control(disabled v-show="interview.dist_c_l_prism") {{interview.dist_c_l_base}}
                    td(v-show="previouslens.dist")
                        span.form-control(disabled) {{interview.dist_c_l_visus}}
                    td(v-show="previouslens.dist")
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.dist_c_l_cz" :disabled="true")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiOptometristsDetailsRefractionFarDialogAcuityNoCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogAcuityNoCorrection'
    import OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection'

    export default {
        name: 'OptiOptometristsDetailsRefractionFar',
        components: {
            OptiNumberPrescription,
            OptiNumber,
            OptiButton,
            OptiEnum,

            OptiOptometristsDetailsRefractionFarDialogAcuityNoCorrection,
            OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },

            correction: {
                type: Object,
                required: true
            },
            activeCyl: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            },
            disabledSurvey: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    acuity_no_correction: {
                        r: false,
                        l: false
                    },
                    acuity_with_correction: {
                        r: false,
                        l: false
                    }
                }
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                ['acuity_no_correction', 'acuity_with_correction'].forEach(type => {
                    this.dialog[type].r = false
                    this.dialog[type].l = false
                })

                this.$emit('update-interview', interview)
            }
        },
        computed: {
            acuity_with_correction() {
                return {
                    type: 'acuity_with_correction',
                    info: {
                        r: {
                            sph: this.interview.dist_c_r_sphere,
                            cyl: this.interview.dist_c_r_cylinder,
                            axs: this.interview.dist_c_r_axis,
                            visus: this.interview.dist_nc_r_visus,
                        },
                        l: {
                            sph: this.interview.dist_c_l_sphere,
                            cyl: this.interview.dist_c_l_cylinder,
                            axs: this.interview.dist_c_l_axis,
                            visus: this.interview.dist_nc_l_visus
                        },
                        binVisus: this.interview.dist_nc_binvisus
                    },
                    save: {
                        r: {
                            cz: 'dist_c_r_cz',
                            visus: 'dist_c_r_visus'
                        },
                        l: {
                            cz: 'dist_c_l_cz',
                            visus: 'dist_c_l_visus'
                        },
                        binVisus: 'dist_c_binvisus'
                    }
                }
            },

            acuityAxisDistExists() {
                return this.interview.dist_c_r_cylinder || this.interview.dist_c_l_cylinder
            },
            acuityAxisExists() {
                return this.acuityAxisDistExists && (this.interview.near_c_r_cylinder || this.interview.near_c_l_cylinder)
            },

            acuityAngleDistExists() {
                return this.interview.dist_c_r_prism || this.interview.dist_c_l_prism
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>