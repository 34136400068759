<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            .table-responsive
                table.table.table-striped
                    thead
                        tr
                            th #
                            th {{'tasks.logs.STATUS'|translate}}
                            th {{'tasks.logs.DESCRIPTION'|translate}}
                            th {{'tasks.logs.DATE'|translate}}
                            th {{'tasks.logs.USER'|translate}}
                    tbody
                        tr(v-for="(log, key) in logs" :class="{'opacity-background': !log.user_id}")
                            td {{logs.length - key}}
                            td {{log.status}}
                            td(v-html="log.description")
                            td {{log.created}}
                            td {{log.user}}

                        tr(v-show="!logs.length")
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsLogsFrame',
        components: {
            OptiDimmer
        },
        props: {
            logs: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
