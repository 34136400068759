<template lang="pug">
    .row
        .col-xs-12.text-center(v-if="image && !show_range")
            a(:href="'upload/' + image" target="_blank")
                img.img-thumbnail.img-responsive(:src="'upload/' + image")

        .col-xs-12
            OptiCatalogRanges(
                :categoryId="categoryId"
                :show_range="show_range"

                @change="show_range = !show_range")

        .col-xs-12
            OptiCatalogLensesPictograms(v-if="!refresh"
                :category-id="categoryId"
                :graph="graph")

        .col-xs-12
            OptiCatalogLensesProperties(
                :category-id="categoryId"

                @get-graph="g => graph = g"
                @get-image="i => image = i"
                @saved="reload")

        .col-xs-12(v-show="priceGroups.length")
            table.table.table-striped.table-bordered.middle
                caption.text-center {{'catalog.lenses.PRICEGROUPS'|translate}}
                thead.text-center
                    tr
                        th {{'catalog.lenses.PRICEGROUP'|translate}}
                        th {{'catalog.lenses.GROUPCODE'|translate}}
                        th {{'catalog.lenses.PRICE'|translate}}
                        th {{'catalog.lenses.DATECHANGE'|translate}}
                        th {{'catalog.lenses.DELIVERY_TIME'|translate}}

                tbody
                    tr(v-for="group in priceGroups")
                        td {{group.name}}
                        td {{group.code}}
                        td {{group.price|currency}}
                        td {{group.updated}}
                        td {{group.deliveryTime|number}}
</template>

<script>
    import OptiCatalogRanges from '@/js/app/vue/components/Catalog/Lenses/OptiCatalogRanges'
    import OptiCatalogLensesPictograms from '@/js/app/vue/components/Catalog/Lenses/OptiCatalogLensesPictograms'
    import OptiCatalogLensesProperties from '@/js/app/vue/components/Catalog/Lenses/Properties/OptiCatalogLensesProperties'

    export default {
        name: 'OptiCatalogLenses',
        components: {
            OptiCatalogRanges,
            OptiCatalogLensesPictograms,
            OptiCatalogLensesProperties
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            },
            priceGroups: {
                type: Array,
                required: false,
                default: []
            },
            assignedDetails: {}
        },
        data() {
            return {
                graph: [],
                image: null,
                show_range: true,

                refresh: false
            }
        },
        methods: {
            reload() {
                this.refresh = true

                setTimeout(() => {
                    this.refresh = false
                }, 500)
            }
        }
    }
</script>