<template lang="pug">
    td.correction-path(:class="{'correction-path-opened': opened}")
        svg(height="100%" :style="style" :class="{'opened': opened}" @click="toggle()")
            rect.background(x="0" y="0" width="100%" height="100%")

            text.column-text(x="30%" y="20") {{'prescription.RIGHT'|translate}}
            text.column-text(x="70%" y="20") {{'prescription.LEFT'|translate}}

            g(v-for="tier in path")
                g(v-for="dot in tier" :class="{'default-route': route.includes(dot.id)}")
                    svg(v-for="con in dot.links"
                        viewBox="0 0 100 100"
                        :x="getConX(dot, con)"
                        :y="dot.y"
                        :width="getConWidth(dot, con)"
                        :height="getConHeight(dot, con)"
                        preserveAspectRatio="none")
                        path.connection(
                            :d="getConPath(dot, con)" vector-effect="non-scaling-stroke"
                            :class="{'default-route': route.includes(dot.id) && route.includes(con.id), 'visited': visited.includes(con.id) && visited.includes(dot.id)}")
                    circle.dot(
                        :cx="dot.x"
                        :cy="dot.y"
                        :r="dot.id === view.id ? 10 : 5"
                        :class="{'active': dot.id === view.id, 'visited': visited.includes(dot.id)}")

                    svg.name(
                        :x="getNameOffset(dot)"
                        :y="dot.y - 20"
                        width="80"
                        height="50"
                        @click="changeView(dot)"
                        :class="{'disabled': !clickable(dot)}")
                        rect.name-btn(
                            x="0"
                            y="0"
                            width="100%"
                            height="100%"
                            rx="3"
                            ry="3")
                        svg.icon(v-show="dot.icon" v-html="dot.icon" x="5" y="5" width="70" height="40")
                        text(x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#fff") {{dot.name}}
</template>

<script>
    import {PathService} from '@/js/app/vue/components/Optometrists/Helpers/PathService'

    export default {
        name: 'OptiOptometristsSurveysPath',
        props: {
            view: {
                type: Object,
                required: true
            },
            path: {
                type: Array,
                required: true
            },
            route: {
                type: Array,
                required: true
            },
            visited: {
                type: Array,
                required: true
            },
            checkSides: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                opened: false,
                style: {}
            }
        },
        mounted() {

        },
        methods: {
            toggle(forceOpen) {
                if((this.opened && forceOpen) || (!this.opened && forceOpen === false)) {
                    return
                }

                this.opened = !this.opened

                this.style = this.opened ? {'width': Math.floor(window.innerWidth - 248) + 'px'} : {}
            },

            getSide(dot, link) {
                let x1 = parseInt(dot.x.replace('%', '')),
                    x2 = parseInt(link.x.replace('%', ''))

                if(x1 > x2) {
                    return 'left'
                } else if (x1 < x2) {
                    return 'right'
                } else {
                    return 'center'
                }
            },

            changeView(dot) {
                if(dot.type) {
                    let visited = _.clone(this.visited)

                    if(this.checkSides && this.view.id.charAt(0) !== dot.id.charAt(0)) {
                        return false
                    }

                    let idx = visited.indexOf(dot.id)
                    if(idx === -1) {
                        idx = visited.indexOf(PathService.alternative(dot.id))
                    }
                    if(idx === -1) {
                        idx = visited.indexOf(PathService.alternative(PathService.alternative(dot.id)))
                    }

                    if(idx !== -1 && this.view.id !== dot.id) {
                        if((['balance1', 'balance2', 'balance3'].includes(this.view.id) &&
                            ['balance1', 'balance2', 'balance3'].includes(dot.id)) ||
                            (this.view.id === PathService.alternative(dot.id))) {
                            while(visited.length > idx) {
                                visited.pop()
                            }

                            visited.push(dot.id)

                            this.$emit('update-view', [visited, dot])
                        } else {
                            if(confirm(this.$filters.translate('optometrist.video.confirm.previous.MESSAGE'))) {
                            //TODO: przemyśleć by confirm był w VUE
                                while(visited.length > idx) {
                                    visited.pop()
                                }

                                visited.push(dot.id)

                                this.$emit('update-view', [visited, dot])
                            }
                        }
                    }
                }
            },

            getNameSide(dot) {
                let x = dot.x.replace('%', '')

                return (
                    (x < 35)
                    ||
                    (x > 50 && x < 70)
                ) ? 'left' : 'right'
            },

            clickable(dot) {
                if(this.checkSides && this.view.id.charAt(0) !== dot.id.charAt(0)) {
                    return false
                }

                let idx = this.visited.indexOf(dot.id)
                if (idx === -1) {
                    idx = this.visited.indexOf(PathService.alternative(dot.id))
                }
                if (idx === -1) {
                    idx = this.visited.indexOf(PathService.alternative(PathService.alternative(dot.id)))
                }

                return idx !== -1
            },

            getNameOffset(dot) {
                if(dot.x === '50%') {
                    return '44%'
                }

                let side = this.getNameSide(dot)

                return parseInt(dot.x.replace('%', '')) + (side === 'left' ? -14 : 2) + '%'
            },
            getConPath(dot, link) {
                let alt = this.getConHeight(dot, link) > 60

                switch(this.getSide(dot, link)) {
                    case 'left':
                        return alt ? 'M 100 0 Q 50 0 50 50 Q 50 100 0 100 ' : 'M 100 0 Q 100 25 50 50 Q 0 75 0 100 '
                    case 'right':
                        return alt ? 'M 0 0 Q 50 0 50 50 Q 50 100 100 100 ' : 'M 0 0 Q 0 25 50 50 Q 100 75 100 100 '
                }

                return 'M 50 0 L 50 100'
            },
            getConX(dot, link) {
                let x1     = parseInt(dot.x.replace('%', '')),
                    x2     = parseInt(link.x.replace('%', '')),
                    offset = this.getConWidth(dot, link) === '6%' ? 3 : 0

                return (Math.min(x1, x2) - offset) + '%'
            },
            getConWidth(dot, link) {
                let x1 = parseInt(dot.x.replace('%', '')),
                    x2 = parseInt(link.x.replace('%', ''))

                let w = Math.abs(x1 - x2) + '%'
                if(w === '0%') {
                    return '6%'
                }

                return w
            },
            getConHeight(dot, link) {
                if(!dot.y || !link.y) {
                    return 60
                } else {
                    return link.y - dot.y
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    //TODO: correction.less:132 usunąć i zmienić nazwę
    .correction-path-opened {
        overflow: inherit;
    }
</style>