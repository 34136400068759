<template lang="pug">
    .col-xs-12
        .table-responsive
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tr.separate-top
                    td(style="vertical-align:top;width:30%")
                        .table-responsive
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-striped.table-condensed.table-separate
                                    thead
                                        tr
                                            th(style="text-align:center" colspan="2") {{'tasks.MEASUREMENT'|translate}}
                                    tbody(v-show="task.measurementId")
                                        tr
                                            th(NOWRAP) {{'tasks.frame.HEIGHT'|translate}}
                                            td(NOWRAP) {{task.frame.height|number(1)}} ({{task.measurement.frameB|number(1)}})
                                        tr
                                            th(NOWRAP) {{'tasks.frame.WIDTH'|translate}}
                                            td(NOWRAP) {{task.frame.width|number(1)}} ({{task.measurement.frameA|number(1)}})
                        .text-center(style="font-weight:normal" v-show="!task.measurementId") {{'tasks.measurement.NOGET'|translate}}
                        .table-responsive(v-show="task.measurementId")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-striped.table-condensed.table-separate
                                    tbody
                                        tr
                                            th(NOWRAP) {{'tasks.INCLINATION'|translate}}
                                            td(NOWRAP) {{task.inclination|number(1)}} ({{task.measurement.wpa|number(1)}})
                                        tr
                                            th(NOWRAP)
                                                span {{'tasks.CVD'|translate}}
                                                sub(v-show="task.cvd !== task.cvdLeft") {{'prescription.RIGHT'|translate}}
                                            td(NOWRAP) {{task.cvd|number(1)}} ({{task.measurement.cvd|number(1)}})
                                        tr(v-show="task.cvd !== task.cvdLeft")
                                            th(NOWRAP)
                                                span {{'tasks.CVD'|translate}}
                                                sub {{'prescription.LEFT'|translate}}
                                            td(NOWRAP) {{task.cvdLeft|number(1)}} ({{task.measurement.cvdLeft|number(1)}})
                                        tr
                                            th(NOWRAP) {{'tasks.FFFA'|translate}}
                                            td(NOWRAP) {{task.fffa|number(1)}} ({{task.measurement.fffa|number(1)}})
                    td
                        a(:href="'/api/oma/' + (isOffer ? 'offer' : 'task') + '/image/' +  + task.id + '.jpg'" target="_blank")
                            img(style="width:100%" :src="'/api/oma/' + (isOffer ? 'offer' : 'task') + '/image/' + task.id + '.jpg?refresh=' + increment + '&' + task.timestamp")
</template>

<script>
    export default {
        name: 'OptiTaskDetailsSummaryMeasurement',
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            increment: {
                type: Number,
                required: true
            }
        }
    }
</script>