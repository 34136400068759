<template lang="pug">
    button.btn.btn-labeled.btn-primary(:id="elementId" :disabled="disabled" @click="print")
        span.btn-label
            i.fa.fa-print
        span
            slot
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiButtonPrint',
        props: {
            elementId: {
                type: String,
                required: false,
                default: ''
            },

            id: {
                type: Number,
                required: true
            },
            url: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        mounted() {

        },
        methods: {
            print() {
                API.post('temporary-link', {
                    url: this.url + '/' + this.id
                }).then(res => {
                    window.open(res.data.link, '_blank')
                }).catch(() => {
                    this.$notify.error('default.notify.error.LOADDOC')
                })
            }
        },
        computed: {}
    }
</script>
