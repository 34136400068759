<template lang="pug">
    .row
        .col-xs-12
            .row(style="margin-bottom:15px")
                .col-xs-9
                    h4.filter-header.page-header {{'catalog.offer.switch.FILTERS'|translate}}

                .col-xs-3.text-right
                    OptiButton(type="success"
                        :disabled="!is_valid_filters || filters.selected.length === filters.types.length || loading_products"
                        @click.native="$store.commit('offer/addFilter')") {{'catalog.offer.filters.ADD'|translate}}
                        template(#icon)
                            i.fa.fa-plus

                .col-xs-12(v-show="filters.selected.length")
                    div(style="padding:10px 0" v-for="(filter, key) in filters.selected" :key="filter.name" :class="{'bg-separator': key % 2}")
                        .row
                            .col-xs-4
                                div(style="display:flex;align-items:center")
                                    label(style="font-weight:400;margin-bottom:0;width:25px;text-align:center"
                                        :for="'filter_name_' + key") {{key + 1}}
                                    select.form-control(:id="'filter_name_' + key" :class="{'placeholder': !filter.name}"
                                        :disabled="filters.selected.length === filters.types.length || loading_products"
                                        @change="e => $store.dispatch('offer/updateFilterValue', {type: 'name', key: key, value: e.target.value})")
                                        option.hide(value="" :selected="!filter.name" disabled
                                            ) {{'catalog.offer.filters.placeholder.FILTER'|translate}}
                                        option(v-for="type in filters.types.filter(t => {return t.name === filter.name || !filters.selected.map(f => f.name).includes(t.name)}).sort((a, b) => a.translate > b.translate ? 1 : -1)"
                                            :disabled="type.disabled" :value="type.name" :selected="filter.name === type.name") {{type.lang|translate}}

                            // operators, ranges
                            .col-xs-4(v-if="filter.type === 'operators'")
                                select.form-control(:class="{'placeholder': !filter.operator}" :disabled="loading_products"
                                    @change="e => $store.dispatch('offer/updateFilterValue', {type: 'operator', key: key, value: e.target.value})")
                                    option.hide(value="" :selected="!filter.operator" disabled
                                        ) {{'catalog.offer.filters.placeholder.OPERATOR'|translate}}
                                    option(v-for="operator in filter.operators" :value="operator" :selected="filter.operator === operator"
                                        ) {{'catalog.offer.filters.operators.types.' + operator|translate}}

                            .col-xs-2.text-center(v-if="filter.or_and")
                                .btn-group(style="width:88px")
                                    .btn.btn-default(style="padding:6px 12px" :class="{'active': filter.or_and === 'or'}"
                                        :disabled="loading_products"
                                        @click="$store.commit('offer/setFilterOrAnd', {key: key, value: 'or'})"
                                        ) {{'catalog.offer.filters.multiselect.OR'|translate}}
                                    .btn.btn-default(style="padding:6px 12px" :class="{'active': filter.or_and === 'and'}"
                                        :disabled="loading_products"
                                        @click="$store.commit('offer/setFilterOrAnd', {key: key, value: 'and'})"
                                        ) {{'catalog.offer.filters.multiselect.AND'|translate}}

                            .col-xs-7(v-if="filter.type === 'slider'")
                                OptiSliderBetween(
                                    :min="filter.range.min"
                                    :max="filter.range.max"

                                    :min-value="filter.from"
                                    :max-value="filter.to"

                                    :disabled="loading_products"

                                    @updated-from="v => $store.dispatch('offer/updateFilterValue', {type: 'from', key: key, value: v})"
                                    @updated-to="v => $store.dispatch('offer/updateFilterValue', {type: 'to', key: key, value: v})")

                            .col-xs-7(v-if="filter.type === 'select'")
                                select.form-control(:class="{'placeholder': !filter.option}" :disabled="loading_products"
                                    @change="e => $store.dispatch('offer/updateFilterValue', {type: 'option', key: key, value: e.target.value})")
                                    option.hide(:selected="!filter.option" value="" disabled) {{'default.SELECT_FROM_LIST'|translate}}
                                    option(v-for="option in filter.options" :value="option.value" :selected="filter.option === option.value"
                                        ) {{filter.prefix ? $filters.translate(filter.prefix + '.' + option.name) : option.name}}

                            div(:class="{'col-xs-7': !filter.or_and, 'col-xs-5': filter.or_and}" v-if="filter.type === 'multiselect'")
                                OptiMultiSelect(
                                    :lang="filter.prefix"

                                    :options="filter.options"
                                    :option="filter.option"

                                    :disabled="loading_products"

                                    @selected="v => $store.dispatch('offer/updateFilterValue', {type: 'option', key: key, value: v})")

                            div(:class="{'col-xs-7': !filter.or_and, 'col-xs-5': filter.or_and}" v-if="filter.type === 'multiselect-group'")
                                OptiMultiSelectGroup(
                                    :lang="filter.prefix"

                                    :options="filter.options"
                                    :option="filter.option"

                                    :disabled="loading_products"

                                    @selected="v => $store.dispatch('offer/updateFilterValue', {type: 'option', key: key, value: v})")

                            // values
                            .col-xs-3(v-if="filter.type === 'operators'")
                                OptiNumber(v-if="filter.operator !== 'from-to'" :header="'catalog.offer.filters.names.' + filter.name|translate"
                                    :float="filter.float" :disabled="loading_products"
                                    :value="filter.value" @confirmed="v => $store.dispatch('offer/updateFilterValue', {type: 'value', key: key, value: v})"
                                    ) {{filter.value|number(filter.float ? 2 : 0)}}

                                .input-group(v-else)
                                    OptiNumber.text-center(:header="$filters.translate('catalog.offer.filters.names.' + filter.name) + ' - ' + $filters.translate('catalog.offer.filters.operators.FROM')"
                                        :float="filter.float" :max="filter.to ? filter.to : false" :disabled="loading_products"
                                        :value="filter.from" @confirmed="v => $store.dispatch('offer/updateFilterValue', {type: 'from', key: key, value: v})"
                                        ) {{filter.from|number(filter.float ? 2 : 0)}}
                                    .input-group-addon -
                                    OptiNumber.text-center(:header="$filters.translate('catalog.offer.filters.names.' + filter.name) + ' - ' + $filters.translate('catalog.offer.filters.operators.TO')"
                                        :float="filter.float" :min="filter.from ? filter.from : false" :disabled="loading_products"
                                        :value="filter.to" @confirmed="v => $store.dispatch('offer/updateFilterValue', {type: 'to', key: key, value: v})"
                                        ) {{filter.to|number(filter.float ? 2 : 0)}}

                            // empty
                            .col-xs-7(v-if="!filter.type")

                            // delete
                            .col-xs-1
                                .btn.btn-danger(style="width:100%;min-width:45px"
                                    :title="'catalog.offer.filters.DELETE_TITLE'|translate" :disabled="loading_products"
                                    @click="$store.commit('offer/deleteFilter', key)")
                                    i.fa.fa-trash

                .col-xs-12.text-center.text-muted(v-show="!filters.selected.length")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'catalog.offer.filters.NONE'|translate}}

            .row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg(style="margin-top:10px")
                    OptiButton(@click.native="$store.commit('offer/setMode', 'questions')"
                        ) {{'catalog.offer.questions.navi.BACK_TO_QUESTIONS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                .col-md-4.text-center(style="display:flex;justify-content:space-around;margin-top:10px")
                    OptiButton(type="success" :disabled="disabled_fetch" @click.native="$store.dispatch('offer/loadProducts')"
                        ) {{'catalog.offer.questions.FETCH'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton(type="success" :disabled="!is_valid_filters || loading_products" @click.native="$store.commit('offer/setMode', 'choice')"
                        ) {{'catalog.offer.questions.FINISH'|translate}}
                        template(#icon)
                            i.fa.fa-check
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSliderBetween from '@/js/app/vue/components/Inputs/OptiSliderBetween'
    import OptiMultiSelect from '@/js/app/vue/components/Select/Multi/OptiMultiSelect'
    import OptiMultiSelectGroup from '@/js/app/vue/components/Select/Multi/OptiMultiSelectGroup'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiCatalogOfferFilters',
        components: {
            OptiButton,
            OptiSliderBetween,
            OptiMultiSelect,
            OptiMultiSelectGroup,
            OptiNumber
        },
        computed: {
            filters() {
                return this.$store.getters['offer/getFilters']
            },
            is_valid_filters() {
                return this.$store.getters['offer/isValidFilters']
            },

            disabled_fetch() {
                return this.$store.getters['offer/disabledFetch']
            },
            loading_products() {
                return this.$store.getters['offer/getLoading']('products')
            }
        }
    }
</script>