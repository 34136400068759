<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'notes.HEADING'|translate}}

        .row
            .col-xs-12.note(v-for="(note, index) in notes" :key="note.id")
                .media-box(:class="{'bg-separator': index % 2}")
                    .media-box-body
                        small(v-show="note.user.id") {{note.user.firstName}} {{note.user.lastName}}
                        small(v-show="note.specialist.id")
                            span.mr3(style="font-style:italic") {{'regeneration.table.SPECIALIST'|translate|lower}}
                            span {{note.specialist.first_name}} {{note.specialist.last_name}}
                        small.pull-right(v-show="note.created_at") {{note.created_at}} - {{note.status_name|translate}}

                        p.m0(v-html="$options.filters.nl2br(note.note)")
                        p.m0(style="padding-top:5px;padding-bottom:10px" v-if="note.photo")
                            a(:href="(without_id ? '' : 'regeneration/notes/') + note.photo|upload" :title="'notes.upload.TITLE'|translate" target="_blank")
                                img.img-rounded(style="width:50%" :src="(without_id ? '' : 'regeneration/notes/') + note.photo|upload")

                        p.m0.text-center(v-show="!note.userRead.id && !note.specialistRead.id && !note.user.id && note.specialist.id")
                            OptiButton(type="success" :disabled="disabled" @click.native="goCheck(note.id)") {{'timeline.action.GOCHECK'|translate}}
                                template(#icon)
                                    i.fa.fa-check

                        p.m0.text-center(v-show="without_id")
                            OptiButton(type="danger" @click.native="$emit('delete-note', index)") {{'regeneration.notes.DELETE'|translate}}
                                template(#icon)
                                    i.fa.fa-trash

                        small.m0.text-right(style="display:block" v-show="note.userRead.id")
                            i.fa.fa-check.text-success.mr3
                            span.mr3 {{note.userRead.firstName}} {{note.userRead.lastName}},
                            span {{note.read_date}}
                        small.m0.text-right(style="display:block" v-show="note.specialistRead.id")
                            i.fa.fa-check.text-success.mr3
                            span.mr3(style="font-style:italic") {{'regeneration.table.SPECIALIST'|translate|lower}}
                            span.mr3 {{note.specialistRead.first_name}} {{note.specialistRead.last_name}},
                            span {{note.read_date}}

            .col-xs-12.text-center.text-muted(style="padding:15px" v-show="!notes.length")
                i.fa.fa-info-circle.mr3
                span {{'notes.NONE'|translate}}

            .col-xs-12(style="padding:15px 30px")
                form.form.form-horizontal
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            textarea.form-control(:placeholder="'notes.PLACEHOLDER'|translate" rows="5" v-model="note")

                    .form-group(style="padding-top:10px")
                        .col-sm-8.col-sm-offset-4
                            OptiUpload#notePhoto(
                                prefix="notes"
                                :picture="true"

                                :file="note_photo"

                                @update-loading="l => loading_photo = l"
                                @update-file="f => note_photo = f")

        .panel-footer.text-right
            OptiButton(type="success" :disabled="!note || disabled || loading_photo" @click.native="addNote") {{'notes.BTN'|translate}}
                template(#icon)
                    i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiRegenerationNotes',
        components: {
            OptiUpload,
            OptiButton
        },
        props: {
            notes: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                without_id: typeof this.$state.params.id === 'undefined',

                note: '',
                note_photo: '',

                loading_photo: false,
                disabled: false
            }
        },
        methods: {
            save() {
                this.disabled = true

                API.post('regenerations/notes/add', {
                    regeneration_id: this.$state.params.id,
                    note: this.note,
                    photo: this.note_photo
                }).then(res => {
                    this.$emit('update-notes', res.data)

                    this.note = ''
                    this.note_photo = ''

                    this.$notify.success('regeneration.notes.notify.ADD')
                }).finally(() => {
                    this.disabled = false
                }).catch(() => {
                    this.$notify.error('regeneration.notes.notify.error.ADD')
                })
            },
            addNote() {
                if(this.without_id) {
                    this.$emit('update-notes', this.note, this.note_photo)

                    this.note = ''
                    this.note_photo = ''
                } else {
                    this.save()
                }
            },

            goCheck(id) {
                this.disabled = true

                API.get('regenerations/notes/read/' + id).then(res => {
                    this.$emit('update-notes', res.data)

                    this.$notify.success('regeneration.notes.notify.READ')
                }).finally(() => {
                    this.disabled = false
                }).catch(() => {
                    this.$notify.error('regeneration.notes.notify.error.READ')
                })
            },

            deleteNotePhoto() {
                this.note_photo = ''
            }
        }
    }
</script>

<style scoped lang="less">
    .note {
        &:not(:last-child) > .media-box {
            border-bottom: 1px solid #000;
        }

        > .media-box {
            padding: 10px;
        }
    }
</style>