<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryAdd',
        components: {
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/inventories/create').then(res => {
                this.$state.go('app.warehouse.inventory.details', {
                    id: res.data
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('inventory.notify.error.CREATE')
            })
        },
        methods: {

        }
    }
</script>
