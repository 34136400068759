<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:10px" v-show="!loading")
            OptiButtonHistory(data="permission_extra" :id="parseInt($state.params.id)")
        .col-md-offset-2.col-md-8(v-if="!loading")
            form.form-horizontal.form-validate(name="formPermissionExtra" @submit.prevent="editPermissionExtra")
                .form-group
                    label.col-sm-4.control-label.star-required(for="department") {{'user.permission-extra.table.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department(
                            :department-id="permission_extra.department.id" @selected="d => permission_extra.department = d")
                        OptiValidate(f="department" :data="$v.permission_extra.department.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="user") {{'user.permission-extra.table.USER'|translate}}
                    .col-sm-8
                        OptiSelectEmployeeGroupByDepartment#user(mode="central"
                            :user="permission_extra.user" @selected="u => permission_extra.user = u")
                        OptiValidate(f="user" :data="$v.permission_extra.user.id")
                .form-group
                    label.col-sm-4.control-label(for="permission") {{'user.permission-extra.table.PERMISSION'|translate}}
                    .col-sm-8
                        OptiSelectEnum#permission(enum="PermissionExtra"
                            :value="permission_extra.permission" @update-enum="p => permission_extra.permission = p")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.permission_extra.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiSelectEmployeeGroupByDepartment
        from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiSelectEnum from '../../Select/OptiSelectEnum'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersPermissionExtraEdit',
        components: {
            OptiButtonHistory,
            OptiSelectDepartment,
            OptiSelectEmployeeGroupByDepartment,
            OptiSelectEnum,
            OptiButtonSave,
            OptiValidate,
            OptiDimmer
        },
        data() {
            return {
                permission_extra: {
                    department: {
                        id: this.$uac.user.activeDepartmentId
                    },
                    user: {
                        id: 0
                    },
                    permission: 0
                },

                loading: true
            }
        },
        validations: {
            permission_extra: {
                department: {
                    id: {
                        noZero
                    }
                },
                user: {
                    id: {
                        noZero
                    }
                }
            }
        },
        mounted() {
            API.get('permission-extras/details/' + this.$state.params.id).then(res => {
                this.permission_extra = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('user.permission-extra.notify.error.LOAD')
            })
        },
        methods: {
            editPermissionExtra() {
                this.loading = true

                API.post('permission-extras/edit', this.permission_extra).then(() => {
                    this.$state.go('app.users.permission-extras.list')

                    this.$notify.success('user.permission-extra.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('user.permission-extra.notify.error.EDIT')
                })
            }
        }
    }
</script>