<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-camera-retro.mr3
                    span {{'catalog.edit.panels.HEADING'|translate}}

                .panel-body
                    form.form-horizontal(@submit.prevent="save")
                        .form-group.form-group-nested(v-for="(panel, index) in $v.panels.$each.$iter" :key="index" :class="{'bg-separator': index % 2}")
                            .col-xs-12
                                .form-group
                                    label.col-md-4.control-label.star-required(:for="'upload-' + index") {{'catalog.edit.panels.form.PANEL'|translate}}
                                    .col-md-8
                                        OptiUpload(
                                            :id="'upload-' + index"

                                            prefix="catalog_image"
                                            :picture="true"

                                            :file="panel.image.$model"

                                            @update-file="i => panel.image.$model = i")
                                        OptiValidate(f="upload" :data="panel.image")
                                .form-group
                                    label.col-md-4.control-label.star-required(:for="'priority-' + index") {{'catalog.edit.panels.form.PRIORITY'|translate}}
                                    .col-md-8
                                        OptiNumber.priority-inline(:id="'priority-' + index" :header="'catalog.edit.panels.form.PRIORITY'|translate"
                                            :value="panel.priority.$model" @confirmed="p => addPriority(p, index)"
                                            :required="true") {{panel.priority.$model}}

                                        .priority-inline.text-right
                                            OptiButton(type="danger" text="catalog.edit.panels.form.confirm.delete"
                                                @confirmed="panels.splice(index, 1)") {{'catalog.edit.panels.form.confirm.delete.BTN'|translate}}
                                                template(#icon)
                                                    i.fa.fa-trash

                                        OptiValidate(f="priority" :data="panel.priority")

                        .form-group.text-center.text-muted(style="padding-top:10px" v-show="!panels.length")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

                .panel-footer
                    OptiButton(type="success" :disabled="$v.panels.$invalid"  @click.native="add"
                        ) {{'catalog.edit.panels.form.ADD'|translate}}
                        template(#icon)
                            i.fa.fa-plus
                    OptiButtonSave.pull-right(:disabled="$v.panels.$invalid" @click.native="save")

                OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogCategoryFormPanels',
        components: {
            OptiUpload,
            OptiNumber,
            OptiValidate,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            catalogId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                panels: [],

                loading: true
            }
        },
        validations: {
            panels: {
                $each: {
                    image: {
                        required
                    },
                    priority: {
                        noZero
                    }
                }
            }
        },
        watch: {
            catalogId() {
                this.load()
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            parse(panels) {
                panels.forEach(panel => {
                    panel.image = 'category/image/' + panel.image
                })

                this.panels = panels
            },
            load() {
                this.loading = true

                API.get('catalog/images/' + this.catalogId).then(res => {
                    this.parse(res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.edit.panels.notify.error.LOAD')
                })
            },
            addPriority(priority, index) {
                let valid = true

                this.panels.forEach((panel, key) => {
                    if(key !== parseInt(index) && panel.priority === priority) {
                        valid = false
                    }
                })

                if(valid) {
                    this.panels[index].priority = priority
                } else {
                    this.$notify.error('catalog.edit.panels.notify.error.PRIORITY')
                }
            },
            add() {
                this.panels.push({
                    id: 0,
                    category_id: this.catalogId,
                    image: '',
                    priority: 0
                })
            },
            save() {
                this.loading = true

                API.post('catalog/images/save', {
                    category_id: this.catalogId,
                    panels: this.panels
                }).then(res => {
                    this.parse(res.data)

                    this.$notify.success('catalog.edit.panels.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.edit.panels.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .panel-body {
        padding: 0 15px;

        .form-group-nested {
            padding-top: 15px;
            margin-bottom: 0;

            .priority-inline {
                display: inline-block;
                width: 50%;
            }
        }
    }
</style>