<template lang="pug">
    #dashboard.row(v-if="!hide && dashboard.department_id")
        .col-xs-12.text-right(style='margin-bottom:10px')
            #simpleTypeToggle(style='display:inline-block')
                label.control-label.mr3(style="cursor:pointer" @click="setType(false)" :class="{'text-normal': type}"
                    ) {{'dashboard.type.CLASSIC'|translate}}
                label.switch.switch-lg.mr3
                    input(type="checkbox" v-model="type" @change="setType(type)")
                    span
                label.control-label(style="cursor:pointer" @click="setType(true)" :class="{'text-normal': !type}"
                    ) {{'dashboard.type.SIMPLE'|translate}}

        .col-sm-3.col-xs-12(v-show="!type && (Object.keys(dashboard).includes('tasks') || (Object.keys(dashboard).includes('regenerations') && department_id !== 49) || previousAssemblies.length || Object.keys(dashboard).includes('task_assembly_forecast'))")
            .panel.panel-primary(v-show="Object.keys(dashboard).includes('tasks')")
                .panel-heading
                    i.icon.icon-eyeglasses.mr3
                    span {{'tasks.TASKS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.tasks")
                                td
                                    a(:href="$state.href(item.sref)" :title="'tasks.status.' + item.name|translate")
                                        span {{'tasks.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('regenerations') && department_id !== 49")
                .panel-heading
                    i.icon.icon-magic-wand.mr3
                    span {{'sidebar.settings.REGENERATIONS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.regenerations")
                                td
                                    a(:href="$state.href(item.sref)" :title="'regeneration.status.' + item.name|translate")
                                        span.mr3 {{'regeneration.status.' + item.name|translate}}
                                        span.label.label-danger(style="padding-left:5px" v-show="item.unread") {{item.unread}}
                                        .pull-right(v-show="item.total") {{item.total}}

            OptiDashboardForecast(v-if="previousAssemblies.length || Object.keys(dashboard).includes('task_assembly_forecast')"
                :previousAssemblies="previousAssemblies"
                :taskAssemblyForecast="dashboard.task_assembly_forecast")

        .col-sm-3.col-xs-12(v-show="!type && (Object.keys(dashboard).includes('orders') || Object.keys(dashboard).includes('returns') || Object.keys(dashboard).includes('shipments') || Object.keys(dashboard).includes('e_shop_orders'))")
            .panel.panel-primary(v-show="Object.keys(dashboard).includes('orders')")
                .panel-heading
                    i.fa.fa-truck.mr3
                    span {{'sidebar.warehouse.ORDERS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.orders")
                                td
                                    a(:href="$state.href(item.sref)" :title="'orders.status.' + item.name|translate")
                                        span {{'orders.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('returns')")
                .panel-heading(:title="'returns.RETURNSTITLE'|translate")
                    i.fa.fa-archive.mr3
                    span {{'returns.RETURNS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.returns")
                                td
                                    a(:href="$state.href(item.sref)" :title="'returns.status.' + item.name|translate")
                                        span {{'returns.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('shipments')")
                .panel-heading
                    i.icon.icon-social-dropbox.mr3
                    span {{'sidebar.warehouse.SHIPMENTS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover.table-dashboard
                        tbody.text-expressive
                            tr(v-for="item in dashboard.shipments")
                                td {{'shipments.status.' + item.name|translate}}
                                td(:class="{out: item.total1}")
                                    a(style="width:100%" v-show="item.total1" :href="$state.href(item.sref1)"
                                        :title="$options.filters.translate('sidebar.warehouse.shipments.direction.OUTTITLE') + ' - ' + $options.filters.lower($options.filters.translate('shipments.status.' + item.name))"
                                        ) {{item.total1}}
                                td(:class="{in: item.total2}")
                                    a(style="width:100%" v-show="item.total2" :href="$state.href(item.sref2)"
                                        :title="$options.filters.translate('sidebar.warehouse.shipments.direction.INTITLE') + ' - ' + $options.filters.lower($options.filters.translate('shipments.status.' + item.name))"
                                        ) {{item.total2}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('e_shop_orders')")
                .panel-heading
                    i.fa.fa-globe.mr3
                    span {{'eShop.breadcrumb.HEADING'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.e_shop_orders")
                                td
                                    a(:href="$state.href(item.sref)" :title="'eorder.status.' + item.name|translate")
                                        span {{'eorder.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

        .col-sm-3.col-xs-12(v-show="!type && (dashboard.stock_correction || department_id === 49 || (Object.keys(dashboard).includes('downloads') && dashboard.downloads.length) || (Object.keys(dashboard).includes('wikiNews') && dashboard.wikiNews.length) || ![49, 60, 91, 149].includes(department_id) || Object.keys(dashboard).includes('transfers') || Object.keys(dashboard).includes('errors'))")
            .panel.panel-primary(v-show="dashboard.stock_correction")
                .panel-heading
                    i.fa.fa-exclamation.mr3
                    span {{'catalog.stock.breadcrumb.LIST'|translate}}

                .table-responsive
                    table.table.table-striped.table-dashboard
                        tbody
                            tr
                                td
                                    a.text-left(style="width:100%" :href="$state.href('app.catalog.stock-corrections.table')")
                                        span.warn {{'catalog.stock.status.REPORTED'|translate}}
                                        .warn.pull-right {{dashboard.stock_correction}}

            .panel.panel-primary(v-show="department_id === 49")
                .panel-heading
                    i.fa.fa-exclamation.mr3
                    span {{'sidebar.warehouse.tickets.TOPRINT'|translate}}

                .table-responsive
                    table.table.table-striped.table-dashboard
                        tbody
                            tr
                                td
                                    a.clearfix(style="width:100%" :href="$state.href('app.warehouse.tickets.table', {resetSearch: true})")
                                        .pull-left {{'ticket.table.QUANTITY'|translate}}
                                        .pull-right {{dashboard.tickets}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('downloads') && dashboard.downloads.length")
                .panel-heading
                    i.icon.icon-folder-alt.mr3
                    span {{'sidebar.settings.DOWNLOAD'|translate}}

                .table-responsive
                    table.table.table-striped.table-dashboard
                        tbody
                            tr(v-for="download in dashboard.downloads")
                                td
                                    a.clearfix(style="width:100%" :title="'dashboard.DOWNLOADTITLE'|translate"
                                        @click="openDownloadLink(download)")
                                        .pull-left {{download.name}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('wikiNews') && dashboard.wikiNews.length")
                .panel-heading
                    i.fa.fa-exclamation.mr3
                    span {{'dashboard.WIKINEWS'|translate}}

                .table-responsive
                    table.table.table-striped.table-dashboard
                        tbody
                            tr(v-for="news in dashboard.wikiNews" v-show="!news.id.includes('playground:playground')")
                                td
                                    a(:href="'https://wiki.eopti.pl/' + news.id" target="_blank" :title="'dashboard.WIKINEWS'|translate")
                                        .pull-left {{news.date}}
                                        .pull-right {{news.user}}
                                        br
                                        .pull-left
                                            span(v-show="news.id.length > 20") ...{{news.id.substr(news.id.length - 20)}}
                                            span(v-show="news.id.length <= 20") {{news.id}}
                                        .pull-right {{news.type}}

            .panel.panel-primary(v-if="![49, 60, 91, 149].includes(department_id)")
                .panel-heading
                    i.icon.icon-grid.mr3
                    span {{'layout.table.action.GRID'|translate}}

                .table-responsive
                    table.table.table-striped.table-dashboard
                        tbody
                            tr
                                td
                                    AngularComponent(:component="map")

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('transfers')")
                .panel-heading
                    i.icon.icon-social-dropbox.mr3
                    span {{'sidebar.warehouse.TRANSFERS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover.table-dashboard
                        tbody.text-expressive
                            tr(v-for="item in dashboard.transfers")
                                td {{'transfers.status.' + item.name|translate}}
                                td(:class="{out: item.total1}")
                                    a(style="width:100%" v-show="item.total1" :href="$state.href(item.sref1)"
                                        :title="$options.filters.translate('sidebar.warehouse.transfers.direction.OUTTITLE') + ' - ' + $options.filters.lower($options.filters.translate('transfers.status.' + item.name))"
                                        ) {{item.total1}}
                                td(:class="{in: item.total2}")
                                    a(style="width:100%" v-show="item.total2" :href="$state.href(item.sref2)"
                                        :title="$options.filters.translate('sidebar.warehouse.transfers.direction.INTITLE') + ' - ' + $options.filters.lower($options.filters.translate('transfers.status.' + item.name))"
                                        ) {{item.total2}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('offer') && dashboard.offer.length")
                .panel-heading
                    i.fa.fa-briefcase.mr3
                    span {{'settings.offer.session.dashboard.HEADER'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="offer in dashboard.offer")
                                td
                                    a(:href="$state.href('app.settings-offer.report-difference')"
                                        :title="'settings.offer.session.dashboard.TITLE'|translate")
                                        .warn {{offer.status|translate}}
                                        ul(style="padding-left:20px;margin-bottom:0;list-style-type:square"
                                            v-show="Object.keys(offer).includes('reasons') && offer.reasons.length")
                                            li(v-for="reason in offer.reasons") {{reason|translate}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('errors')")
                .panel-heading
                    i.fa.fa-bug.mr3
                    span {{'sidebar.settings.ERRORS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.errors")
                                td
                                    a(:href="$state.href(item.sref)" :title="'bugtrack.status.' + item.name|translate")
                                        span {{'bugtrack.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

        .col-sm-3.col-xs-12(v-show="!type && (Object.keys(dashboard).includes('reclamations') || Object.keys(dashboard).includes('sale_reclamations') || (Object.keys(dashboard).includes('regenerations') && department_id === 49))")
            .panel.panel-primary(v-show="Object.keys(dashboard).includes('reclamations')")
                .panel-heading
                    i.icon.icon-wrench.mr3
                    span {{'sidebar.warehouse.RECLAMATIONSTOTASK'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.reclamations")
                                td
                                    a(:href="$state.href(item.sref)" :title="'reclamations.status.' + item.name|translate")
                                        span {{'reclamations.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('sale_reclamations')")
                .panel-heading
                    i.icon.icon-wrench.mr3
                    span {{'sidebar.warehouse.RECLAMATIONSTOSALE'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.sale_reclamations")
                                td
                                    a(:href="$state.href(item.sref)" :title="'sale.reclamation.status.' + item.name|translate")
                                        span {{'sale.reclamation.status.' + item.name|translate}}
                                        .pull-right(v-show="item.total") {{item.total}}

            .panel.panel-primary(v-show="Object.keys(dashboard).includes('regenerations') && department_id === 49")
                .panel-heading
                    i.icon.icon-magic-wand.mr3
                    span {{'sidebar.settings.REGENERATIONS'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover
                        tbody
                            tr(v-for="item in dashboard.regenerations")
                                td
                                    a.clearfix(:href="$state.href(item.sref)" :title="'regeneration.status.' + item.name|translate")
                                        span.mr3 {{'regeneration.status.' + item.name|translate}}
                                        span.label.label-danger(style="padding-left:5px" v-show="item.unread") {{item.unread}}
                                        .pull-right(v-show="item.total") {{item.total}}

        #dashboardSimple(v-show="type")
            .clearfix
            OptiDashboardSimple(v-if="![49, 60, 149].includes(department_id)"
                :dashboard="dashboard"
                :departmentId="department_id")

            OptiDashboardSimpleGrindery(v-if="department_id === 60"
                :dashboard="dashboard"
                :previous-assemblies="previousAssemblies")

            OptiDashboardSimpleWarehouse(v-if="department_id === 49"
                :dashboard="dashboard")

            OptiDashboardSimpleShipping(v-if="department_id === 149"
                :dashboard="dashboard")

        OptiOptometristsDisplayControls(:pilot="true")
</template>

<script>
    import Broadcast from '@/js/app/vue/Broadcast'
    import {DownloadingHelper} from '@/js/app/vue/components/Downloading/DownloadingHelper'

    import OptiDashboardForecast from '@/js/app/vue/components/Dashboard/OptDashboardForecast'
    import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
    import OptiDashboardSimple from '@/js/app/vue/components/Dashboard/Simple/OptDashboardSimple'
    import OptiDashboardSimpleGrindery from '@/js/app/vue/components/Dashboard/Simple/OptDashboardSimpleGrindery'
    import OptiDashboardSimpleWarehouse from '@/js/app/vue/components/Dashboard/Simple/OptDashboardSimpleWarehouse'
    import OptiDashboardSimpleShipping from '@/js/app/vue/components/Dashboard/Simple/OptDashboardSimpleShipping'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'

    export default {
        name: 'OptiDashboard',
        components: {
            OptiDashboardForecast,
            AngularComponent,
            OptiDashboardSimple,
            OptiDashboardSimpleGrindery,
            OptiDashboardSimpleWarehouse,
            OptiDashboardSimpleShipping,
            OptiOptometristsDisplayControls
        },
        data() {
            return {
                type: (window.localStorage.getItem('dashboard-type') || 'simple') === 'simple',
                department_id: this.$uac.user ? this.$uac.user.activeDepartmentId : 0,
                hide: ['SłS', 'Katalog'].includes(this.$uac.user ? this.$uac.user.activeRole.short : ''),

                dashboard: this.$store.getters['dashboard/all']
            }
        },

        mounted() { //TODO: to jest dogrywanie po kliknięciu w flagę - pytanie czy ma wogóle sens ten channel
            if([60, 91].includes(this.department_id)) {
                Broadcast.Echo.channel('department-' + this.department_id).listen('.status-notify-forecast', () => {
                    this.$store.dispatch('dashboard/fetchPreviousAssemblies')
                })
            }
        },
        destroyed() {
            if([60, 91].includes(this.department_id)) {
                Broadcast.Echo.channel('department-' + this.department_id).unsubscribe()
            }
        },

        methods: {
            setType(bool) {
                this.type = bool

                window.localStorage.setItem('dashboard-type', bool ? 'simple' : 'classic')
            },
            openDownloadLink(download) {
                DownloadingHelper.openUri(download)
            }
        },
        computed: {
            previousAssemblies() {
                return this.$store.getters['dashboard/previousAssemblies']
            },

            map() {
                let vm = this

                return {
                    template: `<layout-map department-id="$ctrl.departmentId"></layout-map>`,
                    $ctrl: {
                        get departmentId() {return vm.department_id}
                    }
                }
            }
        }
    }
</script>
