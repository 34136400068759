<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-female.mr3
            span {{'product.style.HEADING'|translate}}

        .panel-body(v-show="!loading")
            .row(v-if="edit")
                .col-sm-6(v-for="style_edit in styles.edit" :key="style_edit.id")
                    label
                        .checkbox.c-checkbox.checkbox-primary.inline
                            label
                                input(type="checkbox" v-model="style_edit.checked")
                                span.fa.fa-check
                        span {{style_edit.name|translate}}
            div(v-else)
                ul(v-if="styles_saved_filtered.length")
                    li(v-for="style_saved in styles_saved_filtered" :key="style_saved.id")
                        i.fa.fa-check.mr3
                        span {{style_saved.name|translate}}

                .text-center.text-muted(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span {{'product.style.notify.EMPTY'|translate}}

        .panel-footer.text-right(v-if="permission")
            OptiButton(v-show="!edit" :disabled="loading" @click.native="goEdit") {{'product.style.EDIT'|translate}}
                template(#icon)
                    i.fa.fa-edit

            OptiButton.pull-left(v-show="edit" :disabled="loading" @click.native="leaveEdit") {{'default.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButtonSave(v-show="edit" :disabled="styles_are_equal || loading" @click.native="save")

        OptiDimmer(:active="loading")
</template>

<script>
	import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
	    name: 'OptiProductStyles',
	    components: {
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
	    props: {
	        productId: {
	            type: Number,
	            required: true
	        }
	    },
	    data() {
	        return {
                styles: {
                    edit: [],
                    saved: []
                },

                permission: this.$uac.permission('products.styles_edit'),
                edit: false,
                loading: true
	        }
	    },
	    mounted() {
            API.get('warehouse/product/styles/' + this.productId).then(res => {
                this.styles.saved = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('product.style.notify.error.LOAD')
            })
	    },
	    methods: {
            goEdit() {
                this.styles.edit = _.cloneDeep(this.styles.saved)

                this.edit = true
            },
            leaveEdit() {
                this.styles.edit = []

                this.edit = false
            },
            save() {
                this.loading = true

                API.post('warehouse/product/styles/save', {
                    product_id: this.productId,
                    data: this.styles.edit
                }).then(res => {
                    this.styles.saved = res.data

                    this.$notify.success('product.style.notify.SAVED')

                    this.$emit('styles-were-changed')
                }).finally(() => {
                    this.leaveEdit()

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('product.style.notify.error.SAVED')
                })
            }
	    },
        computed: {
            styles_saved_filtered() {
                return this.styles.saved.filter(s => s.checked)
            },
            styles_are_equal() {
                return _.isEqual(this.styles.saved, this.styles.edit)
            }
        }
	}
</script>

<style lang="less" scoped>
    .panel {
        position: relative;

        > .panel-body > div > ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }
</style>