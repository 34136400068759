<template lang="pug">
    .row
        .col-xs-12.text-right(style="padding-bottom:10px" v-show="permission")
            OptiButton(:disabled="!protocol" @click.native="goProtocol") {{'star.protocol.BTN'|translate}}
                template(#icon)
                    i.fa.fa-download

        .col-xs-12
            form.well.well-sm.form-horizontal(@submit.prevent="load")
                .form-group.col-lg-4(style="margin-bottom:0")
                    label.col-lg-4.control-label.star-required(for="date_from") {{'star.date.FROM'|translate}}
                    .col-lg-8
                        OptiDate#date_from(:disabled="loading" :value="date.from" @update-date="updateDateFrom")
                        OptiValidate(f="date_from" :data="$v.date.from")
                .form-group.col-lg-4(style="margin-bottom:0")
                    label.col-lg-4.control-label.star-required(for="date_to") {{'star.date.TO'|translate}}
                    .col-lg-8
                        OptiDate#date_to(:disabled="loading" :value="date.to" @update-date="updateDateTo")
                        OptiValidate(f="date_to" :data="$v.date.to")
                .clearfix

        .col-md-6(v-if="!loading")
            .clearfix
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'star.SMS'|translate}}

                table.table
                    thead
                        tr
                            th #
                            th {{'star.DEPARTMENT'|translate}}
                            th {{'star.QUANTITY'|translate}}
                            th {{'star.AVG'|translate}}
                            //th {{'star.MEDIAN'|translate}}
                            //th {{'star.DOMINANT'|translate}}
                            //th {{'star.AVGCUT'|translate}}
                    tbody
                        tr(v-for="(dep, index) in reports.sms.perDepartment" :key="index"
                            :class="{'text-bold': dep.global, 'text-muted': !dep.count}")
                            td.text-right {{dep.global ? '-' : index + 1}}
                            td {{dep.global ? 'CAŁA SIEĆ' : reports.departments[dep.departmentId]}}
                            td {{dep.count}}
                            td(:style="getStyleSMS(dep)") {{dep.average|number(2)}}
                            //td {{dep.median}}
                            //td {{dep.dominant}}
                            //td {{dep.trim_average|number(2)}}

        .col-md-6(v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'star.TASKRECLAMATION'|translate}}

                table.table
                    thead
                        tr
                            th #
                            th {{'star.DEPARTMENT'|translate}}
                            th {{'star.QUANTITY'|translate}}
                            th {{'star.QUANTITYTASK'|translate}}
                            th {{'star.POSITION'|translate}}
                            th {{'star.AVG1000'|translate}}
                    tbody
                        tr(v-for="(dep, index) in reports.reclamations.tasks.perDepartment"
                            :class="{'text-bold': dep.global, 'text-muted': !dep.count}")
                            td.text-right {{dep.global ? '-' : index + 1}}
                            td {{dep.global ? 'CAŁA SIEĆ' : reports.departments[dep.departmentId]}}
                            td {{dep.count}}
                            td {{dep.tasksCount}}
                            td {{dep.positive}} / {{dep.negative}}
                            td(:style="getStyleReclamation(dep)") {{dep.avg1000|number(2)}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportStarLoox',
        components: {
            OptiButton,
            OptiDate,
            OptiValidate,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                date: {
                    from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                },
                reports: {},

                protocol: '',

                permission: this.$uac.permission('settings.starloox'),
                loading: true
            }
        },
        validations: {
            date: {
                from: {
                    required
                },
                to: {
                    required
                }
            }
        },
        mounted() {
            if(this.permission) {
                API.get('dictionary/starloox/department').then(res => {
                    this.protocol = res.data
                }).catch(() => {
                    this.$notify.error('star.notify.error.UPLOAD')
                })
            }

            this.load()
        },
        methods: {
            load() {
                if(this.$v.date.$invalid) {
                    return false
                }

                this.loading = true

                API.post('reports/star-loox', this.date).then(res => {
                    this.reports = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('star.notify.error.LOAD')
                })
            },

            getStyleSMS(dep) {
                if(!this.reports.sms || dep.global || !dep.count) return {}

                return {
                    color: dep.average < this.reports.sms.global.average ? 'red' : 'green'
                }
            },
            getStyleReclamation(dep) {
                if(!this.reports.reclamations || dep.global || !dep.count) return {}

                return {
                    color: dep.avg1000 > this.reports.reclamations.tasks.global.avg1000 ? 'red' : 'green'
                }
            },

            updateDateFrom(date_from) {
                this.date.from = date_from

                this.load()
            },
            updateDateTo(date_to) {
                this.date.to = date_to

                this.load()
            },

            goProtocol() {
                window.open('upload/starloox/' + this.protocol)
            }
        }
    }
</script>