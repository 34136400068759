import { render, staticRenderFns } from "./OptiPatientsPrescriptionDialogTask.vue?vue&type=template&id=18ad23f1&scoped=true&lang=pug&"
import script from "./OptiPatientsPrescriptionDialogTask.vue?vue&type=script&lang=js&"
export * from "./OptiPatientsPrescriptionDialogTask.vue?vue&type=script&lang=js&"
import style0 from "./OptiPatientsPrescriptionDialogTask.vue?vue&type=style&index=0&id=18ad23f1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ad23f1",
  null
  
)

export default component.exports