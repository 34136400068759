<template lang="pug">
    span(:title="'default.title.COUNTDOWN'|translate" )
        i.fa.fa-circle-o-notch.fa-spin.mr3(v-show="counter !== 1000000000")
        span(v-bind:class="{'warn': data <= 10}" v-show="counter !== 1000000000")
            span(v-show="hour !== '00'") {{hour}}:
            span {{minute}}:{{second}}
        span(v-show="counter === 1000000000") ∞
</template>

<script>
    export default {
        name: 'optiCountdown',
        props: {
            data: {
                type: Number,
                required: true,
                default: 0
            }
        },
        watch: {
            data() {
                this.start()
            }
        },
        data() {
            return {
                hour: '00',
                minute: '00',
                second: '00',
                interval: null,
                counter: 0
            }
        },
        mounted() {
            this.start()
        },
        methods: {
            zero() {
                this.minute = '00'
                this.second = '00'
            },
            parse() {
                if (parseInt(this.counter) > 0) {
                    this.hour = parseInt(parseInt(this.counter) / 3600)
                    this.minute = parseInt(parseInt(this.counter) / 60) - this.hour * 60
                    this.second = parseInt(this.counter) % 60

                    if((this.hour).toString().length === 1) {
                        this.hour = '0' + (this.hour).toString()
                    }

                    if((this.minute).toString().length === 1) {
                        this.minute = '0' + (this.minute).toString()
                    }

                    if((this.second).toString().length === 1) {
                        this.second = '0' + (this.second).toString()
                    }
                } else {
                    this.zero()
                }
            },
            intervalFunction() {
                if(this.counter > 0) {
                    this.counter -= 1

                    this.parse()
                } else {
                    this.zero()
                    this.$emit('finished', true)

                    clearInterval(this.interval)
                    this.interval = null
                }
            },
            start() {
                this.counter = this.data

                if(this.counter > 1000000000) {
                    this.counter = 1000000000
                } else if(!this.interval && this.counter > 0) {
                    this.interval = setInterval(() => {
                        this.intervalFunction()
                    }, 1000)
                }
            },
            beforeDestroy() {
                clearInterval(this.interval)
                this.interval = null
            }
        }
    }
</script>
