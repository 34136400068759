<template lang="pug">
    .row
        .col-xs-12(v-show="mode === 'warehouse' && !loading.main")
            .row
                .col-sm-4
                    OptiUpload#csv(
                        prefix="supply_import"
                        :file="file"

                        @update-file="setFile"
                        @update-file-original-name="f => file_original_name = f"
                        @update-loading="l => loading.csv = l")
                .col-sm-2
                    OptiButton.btn-margin(type="success" :disabled="!file || loading.csv"
                        @click.native="loadInvoice") {{'supply.table.LOAD_INVOICE'|translate}}
                        template(#icon)
                            i.fa.fa-download
                .col-sm-6.text-right
                    OptiButton.btn-margin(@click.native="hide = !hide") {{'supply.table.SHOW_HIDE'|translate}}

        .col-xs-12.text-right(v-show="mode === 'order-by-admin' && !loading.main")
            OptiButton(type="danger" :disabled="!selected.length"
                text="supply.cancel.confirm" @confirmed="selectedCancel") {{'supply.cancel.confirm.BTN'|translate}}
                template(#icon)
                    i.fa.fa-remove

        .col-xs-12(style="margin-top:10px" v-show="!loading.main && (sum || notChanged.length)")
            p.text-success(v-show="sum > 0") {{'supply.expected.UPDATED'|translate}} {{sum}} {{'supply.expected.ROWS'|translate}}.

            p.warn(v-show="notChanged.length") {{'supply.expected.validation.NOFOUNDTASK'|translate}}:
            p.warn(v-for="nt in notChanged")
                span.mr3 {{'supply.expected.validation.TASK'|translate}}
                a.link.warn.mr3(:href="$state.href('app.task.detail', {id: nt.SO1})" target="_blank") {{nt.SO1}}
                span.mr3 ({{'supply.expected.validation.LENS'|translate}}: {{nt.Lens_No}},
                span.mr3 {{'supply.expected.validation.QUANTITY'|translate}}: {{nt.QTY}},
                span.mr3 {{'supply.expected.validation.POSITION'|translate}}: {{nt.LP}})

        .col-xs-12(style="margin-top:10px" v-if="!loading.main")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'supply.table.EXPECTED'|translate}} {{expected[0].description}}

                    .pull-right(v-show="expected.length")
                        .label.label-danger.mr3(v-show="selected.length") {{selected.length}}
                        .label.label-inverse {{expected.length}}

                .table-responsive
                    table.table.table-condensed.middle(:class="{'table-warehouse': mode === 'warehouse'}")
                        thead
                            tr
                                th.text-right(v-show="mode === 'warehouse'") #
                                th
                                th {{'supply.table.PRODUCT'|translate}}
                                th {{'supply.table.TASK_ID'|translate}}
                                th {{'supply.table.EXTRA_DESC'|translate}}
                                th(v-show="mode === 'warehouse'") {{'supply.table.SUPPLY_ID'|translate}}
                                th {{'supply.table.ORDERED'|translate}}
                                th {{'supply.table.COMMENTS'|translate}}
                                th(v-show="mode === 'warehouse'") {{'supply.table.INVOICE_POS'|translate}}
                                th(v-show="mode === 'warehouse'") {{'supply.table.INVOICE_PRICE'|translate}}
                        tbody
                            template(v-for="(item, index) in expected")
                                tr(v-show="item.supplyId !== '-1' || !hide")
                                    td.text-right(v-show="mode === 'warehouse'") {{index + 1}}
                                    td
                                        .checkbox.c-checkbox.checkbox-primary.inline
                                            label
                                                input(type="checkbox" v-model="item.selected")
                                                span.fa.fa-check
                                    td {{item.name}}
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: item.taskId})" target="_blank"
                                            ) {{item.taskId}}
                                    td(style="white-space:pre") {{item.extraDescription}}
                                    td(v-show="mode === 'warehouse'")
                                        input.form-control(v-model="item.supplyId")
                                    td {{item.date}}
                                    td {{item.comments}}
                                    td(style="width:7em" v-show="mode === 'warehouse'")
                                        input.form-control.text-right(v-model="item.invoicePosition")
                                    td(style="width:7em" v-show="mode === 'warehouse'")
                                        input.form-control.text-right(v-model="item.invoicePrice")

                .panel-footer(v-show="mode === 'warehouse'")
                    OptiButton(type="success" @click.native="moveSelected") {{'supply.expected.RECEIVESELECTED'|translate}}
                        template(#icon)
                            i.fa.fa-check

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSupplyExpected',
        components: {
            OptiUpload,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                mode: this.$uac.user.activeDepartmentId === 49 ? 'warehouse' : 'order-by-admin',

                expected: [],
                notChanged: [],
                sum: 0,

                file: null,
                file_original_name: null,

                hide: true,
                all: false,
                loading: {
                    main: true,
                    csv: false
                }
            }
        },
        mounted() {
            if(this.mode === 'order-by-admin') {
                this.hide = false
            }

            API.post('warehouse/supply/goToExpected/' + this.$state.params.supplier_id).then(res => {
                this.expected = res.data

                this.whiteSpace()
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('supply.expected.notify.error.LOAD')
            })
        },
        methods: {
            whiteSpace() {
                this.expected = this.expected.map(item => {
                    item.extraDescription = item.extraDescription
                        .replaceAll(' | ', "\n")
                        .replace('Sfera:', 'S:')
                        .replace('Cylinder:', 'C:')
                        .replace('Addycja:', 'Add:')
                        .replace('Barwienie:', 'B:')
                        .replace('jednoogniskowa', 'jo')
                        .replace('Progresywne(prawa soczewka)', 'prog(P)')
                        .replace('Progresywne(lewa soczewka)', 'prog(L)')

                    return item
                })
            },

            setFile(csv) {
                if(csv && this.file !== csv) {
                    this.file = csv

                    let extension = this.file.split('.')[2].toLowerCase()

                    if(!'csv'.includes(extension)) {
                        this.file = null

                        this.$notify.error('supply.expected.notify.error.WRONG_EXT')
                    }
                }
            },
            loadInvoice() {
                this.loading.main = true

                API.post('warehouse/supply/importSupply', {
                    supplierId: this.$state.params.supplier_id,
                    import_file: this.file,
                    file_original_name: this.file_original_name
                }).then(res => {
                    this.expected = res.data.expected
                    this.notChanged = res.data.notChanged
                    this.sum = res.data.changed

                    this.whiteSpace()
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('supply.expected.notify.error.INVOICE')
                })
            },

            moveSelected() {
                this.loading.main = true

                let toChange = []
                this.expected.forEach(item => {
                   if(item.selected) {
                       toChange.push(item)
                   }
                })

                API.post('warehouse/supply/change', {
                    supplierId: this.$state.params.supplier_id,
                    toChange: toChange
                }).then(res => {
                    if(res.data) {
                        this.expected = res.data

                        this.whiteSpace()
                        this.hide = true

                        this.$notify.success('supply.expected.notify.RECEIVED')
                    }
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('supply.expected.notify.error.RECEIVED')
                })
            },
            selectedCancel() {
                this.loading.main = true

                API.post('warehouse/order-by-admin/go-cancel', {
                    ids: this.selected
                }).then(res => {
                    this.expected = res.data

                    this.$notify.success('supply.cancel.notify.CANCEL')
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('supply.cancel.notify.error.CANCEL')
                })
            }
        },
        computed: {
            selected() {
                let _ids = []

                this.expected.forEach(expected => {
                    if(expected.selected) {
                        _ids.push(expected.id)
                    }
                })
                return _ids
            }
        }
    }
</script>

<style lang="less" scoped>
    .btn-margin {
        margin-top: 8px;
    }

    .table.table-warehouse {
        font-size: 12px;
    }
</style>