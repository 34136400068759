<template lang="pug">
    div(:class="{'layoutInfo': withClass}")
        div(v-for="item in layout")
            div(v-if="item.type === 'object'")
                a.link(@click="openGrid(item)")
                    span  {{item.object}} kol. {{item.column + 1}}
            div(v-if="item.type === 'supply'")
                a.link(:href="supplyLink")
                    span Zapas {{item.object ? item.object : 'salonu'}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiProductLayoutInfo',
        props: {
            id: {
                type: Number,
                required: true
            },
            department: {
                type: Number
            },

            withClass: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data: () => {
            return {
                layout: []
            }
        },
        created() {
            try {
                API.get(`layout/product/info/${this.departmentId}/${this.id}`, API.id(Math.random())).then(res => {
                    this.layout = res.data
                })
            } catch(e) {
                if(!API.isCancel(e)) {
                    console.error(e)
                }
            }
        },
        computed: {
            departmentId() {
                return this.department ||
                    this.$route.params.departmentId ||
                    this.$uac.user.activeDepartmentId ||
                    0
            },
            supplyLink() {
                return this.$state.href('app.layout.supply')
            }
        },
        methods: {
            openGrid(item) {
                this.$router.push({
                    name: 'app.layout.grid',
                    params: {
                        open: item.id,
                        departmentId: this.departmentId
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .layoutInfo {
        position: absolute;
        top: 4px;
        right: 4px;
        text-align: right;

        > div > div > a.link {
            color: #000;
            text-shadow: 0px 0px 8px #fff;
        }
    }
</style>
