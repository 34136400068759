<template lang="pug">
    // to samo w base-table.pug
    .rate(:title="'rate.TITLE'|translate" @click.stop="dialog = true")
        b.mr3(v-show="!autoOpen && header") {{'rate.table.RATE'|translate}}

        .rate-icon(v-show="!autoOpen")
            i.fa.fa-question.fa-2x(v-if="data.rate === null")
            .fa-stack(v-else)
                i.fa.fa-stack-2x(:class="{'worst': !data.rate, 'fa-star': data.rate !== rate_max[refType], 'fa-heart': data.rate === rate_max[refType]}")
                i.fa.fa-stack-1x(:class="{'best': data.rate === rate_max[refType]}") {{data.rate}}

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiRateDialog(
                :rate="data"

                :ref-type="refType"
                :ref-id="refId"

                :rate-max="rate_max"

                @reload="dialog = false;$emit('reload')"
                @closed="dialog = false")
</template>

<script>
    import OptiRateDialog from '@/js/app/vue/components/Rate/OptiRateDialog'

    export default {
        name: 'OptiRate',
        components: {
            OptiRateDialog
        },
        props: {
            autoOpen: {
                type: Boolean,
                required: false,
                default: false
            },

            header: {
                type: Boolean,
                required: false,
                default: false
            },
            rate: {
                type: Object | Array,
                required: true
            },

            refType: {
                type: Number,
                required: true
            },
            refId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                data: Object.keys(this.rate).length ? _.cloneDeep(this.rate) : {
                    rate: null,
                    description: '',
                    conditions: [],
                    user: {}
                },

                // to samo w OptiRate.vue
                rate_max: {
                    1: 2,
                    2: 3
                },

                dialog: this.autoOpen
            }
        }
    }
</script>