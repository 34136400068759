<template lang="pug">
    span.depository-info.middle
        span(v-if="product.depository && product.depository.id !== null && typeof product.depository.id !== 'undefined'")
            i.fa.fa-fw.fa-dropbox.text-gray-light
            span.mr3 {{product.depository ? product.depository.stock : 0}}
            i.fa.fa-fw.fa-envelope.text-gray-light
            span.mr3 {{product.depository ? product.depository.ordered : 0}}
            i.fa.fa-fw.fa-car.text-gray-light
            span.mr3 {{product.depository ? product.depository.expected : 0}}
        span(v-else)
            i.fa.fa-fw.fa-close.text-gray-light

        i.fa.fa-fw.fa-building-o.text-gray-light
        span.mr3 {{product.quantity}}

        span(v-if="dotColor && dotColor.color")
            .dot.mr3(:title="'Dostępność produktu'" :style="{ backgroundColor: dotColor.color}")
            span {{dotColor.week}}

        span(v-show="cartQuantity.sale")
            i.fa.fa-fw.fa-shopping-cart.text-success
            span.mr3 {{cartQuantity.sale}}
        span(v-show="cartQuantity.order")
            i.fa.fa-fw.fa-truck.text-info
            span.mr3 {{cartQuantity.order}}
        span(v-show="cartQuantity.sendback")
            i.fa.fa-archive.text-warning
            span.mr3 {{cartQuantity.sendback}}

        i.fa.fa-fw.fa-ticket.text-gray-light(v-show="ticket")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiDepositoryInfo',
        components: {},
        props: {
            product: {
                type: Object,
                required: true
            },
            dotColor: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                ticket: false
            }
        },
        mounted() { //TODO: zastanowić się nad cachowaniem tego jak całe metkowanie będzie w VUE
            API.get('warehouse/ticket/storage', API.id(Math.random())).then(res => {
                if (res.data) {
                    res.data.details.forEach(detail => {
                        if (detail.product_id === this.product.id) {
                            this.ticket = true
                        }
                    })
                }
            }).catch(() => {
                this.$notify.error('ticket.service.notify.error.LOAD')
            })
        },
        computed: {
            cartQuantity() {
                return this.$store.getters['carts/getQuantityToDepository'](this.product.id)
            }
        }
    }
</script>

<style lang="less" scoped>
    .dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-top: 5px;
    }
</style>