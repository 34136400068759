<template lang="pug">
    div(v-if="allow" :class="{'btn-cart-pill': type === 'pill'}")
        OptiButton(:type="active.color" v-if="type === 'default'"
            :disabled="pending" @click.native="addProductToCart()") {{active.text|translate}}
            template(#icon)
                i.fa(:class="active.icon")

        button.btn.btn-pill-left(type="button" v-if="type === 'pill'" :class="'btn-' + active.color"
            :disabled="pending" @click="addProductToCart()")
            i.fa.fa-fw.mr3(:class="active.icon")
            span {{active.text_pill|translate}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCartButton',
        components: {
            OptiButton
        },
        props: {
            type: {
                type: String,
                required: true
            },
            product: {
                type: Object,
                required: true
            }
        },
        methods: {
            addProductToCart() {
                this.$store.dispatch('carts/addProduct', {
                    type: this.$store.getters['carts/getActiveCartName'],
                    product: this.product
                })
            }
        },
        computed: {
            allow() {
                let edit = this.$uac.permission('carts.sale.edit') || this.$uac.permission('carts.order.edit') ||
                    this.$uac.permission('carts.sendback.edit')

                return edit && this.$store.getters['carts/allowButton'](this.product)
            },
            active() {
                return this.$store.getters['carts/getDataToButton']
            },
            pending() {
                return this.$store.getters['carts/getPending']
            }
        }
    }
</script>

<style scoped lang="less">
    .btn-cart-pill {
        position: absolute;
        right: 0;
        bottom: 12px;
    }
</style>