<template lang="pug">
    .row
        .col-xs-12(v-show="!withCorrection")
            .table-responsive
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(colspan="4") {{'optometrist.acuity.WITHOUTCORRECTION'|translate}}
                    tbody
                        tr
                            th {{'optometrist.acuity.FAR'|translate}}
                            td Visus
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{interview.dist_nc_r_visus}}
                            td(rowspan="2") {{interview.dist_nc_binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{interview.dist_nc_l_visus}}

                        tr
                            th {{'optometrist.acuity.NEAR'|translate}}
                            td Visus
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{interview.near_nc_r_visus}}
                            td(rowspan="2") {{interview.near_nc_binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{interview.near_nc_l_visus}}

        .col-xs-12(v-show="withCorrection")
            .table-responsive
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(colspan="6") {{'optometrist.acuity.WITHCORRECTION'|translate}}
                    tbody
                        tr(v-show="withCorrectionDist")
                            th {{'optometrist.acuity.FAR'|translate}}
                            td SPH
                            td CYL
                            td(v-show="acuityAxisExists") AXS
                            td Visus
                            td BinVisus
                        tr(v-show="withCorrectionDist")
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction[activeCyl].dist.r.sph|plus}}
                            td {{correction[activeCyl].dist.r.cyl|plus}}
                            td(v-show="acuityAxisExists")
                                span(v-show="correction[activeCyl].dist.r.cyl") {{correction[activeCyl].dist.r.axs}}
                            td {{interview.dist_c_r_visus}}
                            td(rowspan="2") {{interview.dist_c_binvisus}}
                        tr(v-show="withCorrectionDist")
                            th {{'prescription.LEFT'|translate}}
                            td {{correction[activeCyl].dist.l.sph|plus}}
                            td {{correction[activeCyl].dist.l.cyl|plus}}
                            td(v-show="acuityAxisExists")
                                span(v-show="correction[activeCyl].dist.l.cyl") {{correction[activeCyl].dist.l.axs}}
                            td {{interview.dist_c_l_visus}}

                        tr(v-show="withCorrectionNear")
                            th {{'optometrist.acuity.NEAR'|translate}}
                            td SPH
                            td CYL
                            td(v-show="acuityAxisExists") AXS
                            td Visus
                            td BinVisus
                        tr(v-show="withCorrectionNear")
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction[activeCyl].near.r.sph|plus}}
                            td {{correction[activeCyl].near.r.cyl|plus}}
                            td(v-show="acuityAxisExists")
                                span(v-show="correction[activeCyl].near.r.cyl") {{correction[activeCyl].near.r.axs}}
                            td {{interview.near_c_r_visus}}
                            td(rowspan="2") {{interview.near_c_binvisus}}
                        tr(v-show="withCorrectionNear")
                            th {{'prescription.LEFT'|translate}}
                            td {{correction[activeCyl].near.l.sph|plus}}
                            td {{correction[activeCyl].near.l.cyl|plus}}
                            td(v-show="acuityAxisExists")
                                span(v-show="correction[activeCyl].near.l.cyl") {{correction[activeCyl].near.l.axs}}
                            td {{interview.near_c_l_visus}}

        .col-xs-12(v-show="interview.contactLensCorrection.r_sph || interview.contactLensCorrection.l_sph || interview.contactLensCorrection.r_cyl || interview.contactLensCorrection.l_cyl")
            .table-responsive
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(colspan="6") {{'optometrist.acuity.CONTACTLENS'|translate}}
                    tbody
                        tr
                            th
                            td SPH
                            td CYL
                            td(v-show="acuityAxisContactLensExists") AXS
                            td Visus
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction.contactlens[activeCylContactLens].r.sph|plus}}
                            td {{correction.contactlens[activeCylContactLens].r.cyl|plus}}
                            td(v-show="acuityAxisContactLensExists")
                                span(v-show="correction.contactlens[activeCylContactLens].r.cyl") {{correction.contactlens[activeCylContactLens].r.axs}}
                            td {{interview.contactLensCorrection.dist_r_visus}}
                            td(rowspan="2") {{interview.contactLensCorrection.dist_binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{correction.contactlens[activeCylContactLens].l.sph|plus}}
                            td {{correction.contactlens[activeCylContactLens].l.cyl|plus}}
                            td(v-show="acuityAxisContactLensExists")
                                span(v-show="correction.contactlens[activeCylContactLens].l.cyl") {{correction.contactlens[activeCylContactLens].l.axs}}
                            td {{interview.contactLensCorrection.dist_l_visus}}
</template>

<script>

    export default {
        name: 'OptiOptometristsDetailsSummaryAcuity',
        components: {
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            correction: {
                type: Object,
                required: true
            },

            activeCyl: {
                type: String,
                required: true
            },
            activeCylContactLens: {
                type: String,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            withCorrection() {
                return this.interview.dist_c_r_sphere || this.interview.dist_c_l_sphere ||
                    this.interview.dist_c_r_cylinder || this.interview.dist_c_l_cylinder ||
                    this.interview.near_c_r_sphere || this.interview.near_c_r_cylinder ||
                    this.interview.near_c_l_sphere || this.interview.near_c_l_cylinder
            },
            withCorrectionDist() {
                return this.interview.dist_c_r_sphere || this.interview.dist_c_l_sphere ||
                    this.interview.dist_c_r_cylinder || this.interview.dist_c_l_cylinder
            },
            withCorrectionNear() {
                return interview.near_c_r_sphere || interview.near_c_r_cylinder ||
                    interview.near_c_l_sphere || interview.near_c_l_cylinder
            },

            acuityAxisDistExists() {
                return this.interview.dist_c_r_cylinder || this.interview.dist_c_l_cylinder
            },
            acuityAxisNearExists() {
                return this.interview.near_c_r_cylinder || this.interview.near_c_l_cylinder
            },
            acuityAxisExists() {
                return this.acuityAxisDistExists || this.acuityAxisNearExists
            },

            acuityAxisContactLensExists() {
                return this.interview.contactLensCorrection.r_cyl || this.interview.contactLensCorrection.l_cyl
            }
        }
    }
</script>