class MonthsClass {
    constructor() {

    }

    get() {
        let _return = []

        for(let i = 1; i <= 12; ++i) {
            _return.push(i)
        }

        return _return
    }

    getToMonth(month) {
        let _return = []

        for(let i = 1; i <=  month; ++i) {
            _return.push(i)
        }

        return _return
    }

    getFromMonth(month) {
        let _return = []

        for(let i = month; i <= 12; ++i) {
            _return.push(i)
        }

        return _return
    }

    parse(tab) {
        let _return = []

        tab.forEach(month => {
            _return.push({
                id: month,
                name: 'default.months.' + month
            })
        })

        return _return
    }
}

export const Months = new MonthsClass()