<template lang="pug">
    .row
        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'orders.validation.headers.TABLE'|translate}}
            .panel-body
                table.table.table-responsive.table-striped.middle
                    thead
                        tr
                            th.text-left #
                            th.text-center  {{'orders.validation.table.ORIGINAL_FILE'|translate}}
                            th.text-center  {{'orders.validation.table.PROCESSED_FILE'|translate}}
                            th.text-center {{'orders.validation.table.ORDER_LINE_QUANTITY'|translate}}
                            th.text-center {{'orders.validation.table.DISCREPANCIES_AMOUNT'|translate}}
                            th.text-center {{'orders.validation.table.VENDOR'|translate}}
                            th.text-left {{'orders.validation.table.USER'|translate}}
                            th.text-left  {{'orders.validation.table.DATE'|translate}}


                    tbody(v-if="validatedOrders")

                        tr(v-for="(order, index) in validatedOrders" :key="index")
                            td.text-left {{count - ((paginator.page - 1) * 10 + index)}}

                            td.text-center.bold(style="cursor:pointer" @click="downloadFile('originalFile', order.id)")
                                span {{originalFileName(order)}}
                                br
                                i.fa.fa-download

                            td.text-center.bold(style="cursor:pointer" @click="downloadFile('processedFile', order.id)")
                                span {{processedFileName(order)}}
                                br
                                i.fa.fa-download

                            td.text-center {{order.processed_lines}}
                            td.text-center {{order.amount_of_discrepancies}}
                            td.text-center {{order.vendor_name}}
                            td.text-left {{order.user_name}}
                            td.text-left {{order.created_at}}
            .panel-footer.clearfix
                .col-xs-12.text-center
                    OptiPaginator(
                        style="width: 100%"
                        :paginator="paginator"

                        @changed-page="changePage")

        OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator.vue'

    /**
     * @typedef  {Object} Order
     * @property {number} processed_lines
     * @property {number} amount_of_discrepancies
     * @property {string} vendor_name
     * @property {string} user_name
     * @property {string} created_at
     */

    export default {
        name: 'OptiOrderValidationsTable',
        components: {OptiPaginator, OptiDimmer},
        props: {
            fileUploaded: {
                type: Boolean,
                required: false,
            }
        },
        data() {
            return {
                validatedOrders: [],
                loading: false,
                paginator: {
                    pages: 1,
                    page: 1
                },
                count: null
            }
        },
        mounted() {
            this.startUp()
        },
        methods: {
            startUp() {
                this.loading = true

                API.post('orderValidation/table', {
                    page: this.paginator.page
                }).then((response) => {

                    this.validatedOrders = response.data.data
                    this.paginator.pages = response.data.pages
                    this.count = response.data.count

                }).catch(() => {
                    this.$notify.error('orders.validation.error.LOAD_TABLE')
                }).finally(() => {
                    this.loading = false
                })
            },
            changePage(page) {
                this.paginator.page = page
                this.startUp()
            },
            downloadFile(fileType, orderId) {
                API.post('orderValidation/downloadProcessedFile',  {
                    fileType: fileType,
                    orderId: orderId
                }).then((response) => {
                    window.open(response.data)
                }).catch(() => {
                    this.$notify.error('orders.validation.error.DOWNLOAD_FILE')
                })
            },
            processedFileName(order) {
                return 'plik_' + order.vendor_name.toLowerCase() + '_id_' + order.id
            },
            originalFileName(order) {
                return 'plik_' + order.vendor_name.toLowerCase() + '_original_id_' + order.id
            }
        },
        watch: {
            fileUploaded(newValue) {
                if(newValue) {
                    this.$emit('page-refreshed')
                    this.startUp()
                }
            }
        }
    };
</script>

<style scoped lang="less">
.click {
    cursor: pointer
}
</style>