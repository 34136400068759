<template lang="pug">
    .modal-mask
        .modal-wrapper
            .modal-container.bg-expressive
                .modal-header(v-show="header")
                    slot(name="header")

                .modal-body
                    slot(name="body")

                .modal-footer(v-show="footer")
                    slot(name="footer")
</template>

<script>
    export default {
        name: 'OptiDialog',
        components: {},
        props: {
            header: {
                type: Boolean,
                required: false,
                default: true
            },
            footer: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped lang="less">
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100dvh;
        background-color: rgba(0, 0, 0, .5);
        display: table;

        > .modal-wrapper {
            display: table-cell;
            vertical-align: middle;

            > .modal-container {
                margin: 0 auto;
                border-radius: 2px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, .33);

                > .modal-header {
                    padding: 5px 10px;
                }

                > .modal-body {
                    max-height: 85dvh;
                    overflow: auto;
                }
            }
        }

        &.fullscreen > .modal-wrapper > .modal-container {
            height: 100dvh;

            > .modal-body {
                padding: 0;
                max-height: 100dvh;
                height: 100%;
            }

            > .modal-footer {
                position: absolute;
                background: inherit;
                bottom: 0;
                width: 100%;
                z-index: 100;
            }
        }

        &.fullwidth > .modal-wrapper > .modal-container {
            width: 100%;
        }
    }
</style>