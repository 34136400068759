import {Maddox} from './Maddox'

class InterviewRecommendationClass {
    constructor() {
        this.less_than_25  = 'brak lub < 2,5 dptr.'
        this.more_than_25  = '> 2,5 dptr.'
        this.under20       = '< 20 pkt.'
        this.over_20       = '> 20 pkt.'
        this.ltBin         = '< od VIS oka lepiej widzącego'
        this.geBin         = '>= od VIS oka lepiej widzącego'
        this.more_than_10  = '1,0 i więcej'
        this.less_than_09  = '0,9 i mniej'
        this.diff_over_03  = 'Różnica między VIS OP i OL > 3 rzędy'
        this.hyperHypo     = 'hyper/hypo'
        this.impossibl     = 'niemożliwy do przeprowadzenia'
        this.orto_____     = 'orto'
        this.esoExo___     = 'eso/exo'
        this.less_than_075 = '< 0,75 dptr.'
        this.more_than_075 = '> 0,75 dptr.'
        this.matrix        = [
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i specjalne'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. zalecana kontrola u optometrysty - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. zalecana kontrola u optometrysty - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i specjalne'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą '],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą dodatkowo informacja o możliwym dłuższym czasie adaptacji'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne, specjalne po konsultacji z optometrystą'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. zalecana kontrola u optometrysty - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. zalecana kontrola u optometrysty - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. zalecana kontrola u optometrysty - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Okulary sferyczne, kontrola po miesiącu u optometrysty, jeżeli COVD < 20 pkt. to można robić okulary specjalne, jeżeli nadal COVD > 20 pkt. - możliwa konieczność terapii widzenia'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.less_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Okulary sferyczne i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.more_than_10, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.more_than_10, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.geBin, this.less_than_09, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.under20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe i konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, true, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 4, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.less_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.orto_____, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.orto_____, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.hyperHypo, this.esoExo___, 5, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 3, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
            [this.more_than_25, this.over_20, this.ltBin, this.diff_over_03, this.impossibl, this.impossibl, 2, false, this.more_than_075, 'Soczewki kontaktowe, kontrola po miesiącu, jeżeli COVD < 20 pkt., kontrola jak w przypadku użytkownika soczewek kontaktowych po roku, jeżeli COVD > 20 pkt. konsultacja u optometrysty'],
        ]
    }

    getCOVDPoints(interview) {
        let points  = 0,
            checked = true

        interview.questionnaire.forEach(questionnaire => {
            if(questionnaire.pivot.option !== -1) {
                points += questionnaire.pivot.option
            } else {
                checked = false
            }
        })

        return checked ? points : -1
    }

    getWorthDotsCount(interview) {
        let d = {
                2: [18],
                3: [19],
                4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                5: [13, 14, 15, 16, 17]
            },
            dots = false

        _.forEach(d, (idx, key) => {
            if(idx.includes(interview.worth_id)) {
                dots = parseInt(key)
            }
        })

        return dots
    }

    getMaddoxHorizontal(interview, side) {
        if(!side) {
            return false
        }

        if(interview.maddox[side + '_suppression']) {
            return this.impossibl
        }

        if(interview.maddox[side + '_v']) {
            return this.orto_____
        } else {
            return this.esoExo___
        }
    }

    getMaddoxVertical(interview, side) {
        // czyli RMV, czyli pion, czyli [orto|hiper/hypo]
        if (!side) {
            return false
        }

        if (interview.maddox[side + '_suppression']) {
            return this.impossibl
        }

        if (interview.maddox[side + '_h']) {
            return this.orto_____
        } else {
            return this.hyperHypo
        }
    }

    conditionsPassed(conditions, interview) {
        // anaizeikonia
        if (
            (conditions[0] === this.less_than_25 && interview.aniseikonia > 2.5)
            ||
            (conditions[0] === this.more_than_25 && interview.aniseikonia <= 2.5)
        ) {
            return false
        }

        // COVD
        if (
            (interview.covd === -1)
            ||
            (conditions[1] === this.over_20 && interview.covd <= 20)
            ||
            (conditions[1] === this.under20 && interview.covd > 20)
        ) {
            return false
        }

        // visus obuoczny
        if (
            (conditions[2] === this.geBin && interview.binVisus < interview.betterVisus)
            ||
            (conditions[2] === this.ltBin && interview.binVisus >= interview.betterVisus)
        ) {
            return false
        }

        // visus OP/OL [3]
        if (conditions[3] !== interview.visRL) {
            return false
        }

        // maddox pion [4], vertical
        if (conditions[4] !== interview.maddox.vertical) {
            return false
        }

        // maddox poziom [5], horizontal
        if (conditions[5] !== interview.maddox.horizontal) {
            return false
        }

        // worth [6]
        if (conditions[6] !== interview.worthDots) {
            return false
        }

        // stereo [7]
        if (
            (conditions[7] === true && !interview.hasStereoVision)
            ||
            (conditions[7] === false && interview.hasStereoVision)
        ) {
            return false
        }

        // zmiana korekcji [8]
        if (conditions[8] !== interview.correctionChange) {
            return false
        }


        // result [9]
        return true
    }

    getES(sphere, cylinder) {
        if(cylinder * 50 % 1 === 0) {
            return sphere + cylinder / 2
        }

        return sphere + (cylinder + 0.25) / 2
    }

    getAbsES(sphere, cylinder) {
        return Math.max(
            Math.abs(this.getES(sphere, cylinder)),
            Math.abs(this.getES(sphere, -cylinder)))
    }

    getEyeDifference(interview) {
        return Math.abs(
            this.getAbsES(interview.dist_r_sphere, interview.dist_r_cylinder) - this.getAbsES(interview.dist_l_sphere, interview.dist_l_cylinder)
        )
    }

    getVisRL(model) {
        let rows = [
            '0,05',
            '0,1',
            '0,2',
            '0,3',
            '0,4',
            '0,5',
            '0,6',
            '0,7',
            '0,8',
            '0,9',
            '1,0',
            '1,2',
            '1,5'],
            visusRight = rows.indexOf(model.dist_r_visus),
            visusLeft = rows.indexOf(model.dist_l_visus),
            floatVisusRight = parseFloat(model.dist_r_visus.replace(',', '.')),
            floatVisusLeft = parseFloat(model.dist_l_visus.replace(',', '.'))

        if(visusLeft === -1 || visusRight === -1) {
            return false
        }

        let difference = Math.abs(visusRight - visusLeft)

        if(difference > 3) {
            return this.diff_over_03
        }

        if(floatVisusRight >= 1 || floatVisusLeft >= 1) {
            return this.more_than_10
        }

        return this.less_than_09
    }

    getCorrectionSideChange(interview, side) {
        return Math.abs(
            this.getAbsES(
                interview['dist_' + side + '_sphere'],
                interview['dist_' + side + '_cylinder'])
            -
            this.getAbsES(
                interview['dist_c_' + side + '_sphere'],
                interview['dist_c_' + side + '_cylinder']))
    }

    getCorrectionChange(interview) {
        let diffRight = this.getCorrectionSideChange(interview, 'r'),
            diffLeft = this.getCorrectionSideChange(interview, 'l')

        if(diffRight <= 0.75 && diffLeft <= 0.75) {
            return this.less_than_075
        }

        return this.more_than_075
    }

    check(interview) {
        let result = false,

        maddoxLeft = Maddox.check(interview.maddox, 'l'),
        maddoxRight = Maddox.check(interview.maddox, 'r'),
        maddoxSide = (maddoxLeft || maddoxRight) ? (maddoxLeft ? 'l' : 'r') : false,

        data = {
            covd: this.getCOVDPoints(interview),
            betterVisus: Math.max(
                parseFloat(interview.dist_r_visus.replace(',', '.')),
                parseFloat(interview.dist_l_visus.replace(',', '.'))
            ),
            binVisus: parseFloat(interview.dist_binvisus.replace(',', '.')),
            hasStereoVision: !!interview.stereo,
            worthDots: this.getWorthDotsCount(interview),
            maddox: {
                horizontal: this.getMaddoxHorizontal(interview, maddoxSide),
                vertical: this.getMaddoxVertical(interview, maddoxSide)
            },
            aniseikonia: this.getEyeDifference(interview),
            visRL: this.getVisRL(interview),
            correctionChange: this.getCorrectionChange(interview)
        }

        this.matrix.forEach(conditions => {
            if(!result && this.conditionsPassed(conditions, data)) {
                result = conditions[9]
            }
        })

        return result
    }
}

export const InterviewRecommendation = new InterviewRecommendationClass()