<template lang="pug">
    .row
        .col-xs-12(v-show="!is_loading")
            OptiPatientsDetailsRelationships(@relationships="updateRelationships")

        .col-xs-12.text-center(style="margin-bottom:15px" v-show="!is_loading && is_offer")
            OptiButton(type="success" text="patient.offer" @confirmed="setPatientToOffer") {{'patient.offer.BTN'|translate}}
                template(#icon)
                    i.fa.fa-briefcase.mr3

        .col-xs-5(style="margin-top:5px;margin-bottom:5px" v-if="!is_loading")
            h4.page-header(style="margin-top:0px")
                span.mr3 {{patient.firstName}} {{patient.lastName}}
                small.text-muted.hidden-xs {{tab[active_tab]|translate}}
        .col-xs-7.text-right(style="margin-top:5px;margin-bottom:5px" v-if="!is_loading")
            OptiButton.mr3(type="primary" :title="'patient.contactplus.BTN'|translate" :disabled="block"
                text="patient.contactplus.print.confirm" @confirmed="contactPlusPrint") {{'patient.contactplus.BTN'|translate}}
                template(#icon)
                    i.fa.fa-print

            .pull-right.text-center(style="padding:0 5px;display:inline-block" v-if="unlockLeft")
                div(style="font-size:12px") {{'patient.token.unlock.TIMER'|translate}}
                OptiCountdown(:data="unlockLeft" @finished="getData")
            OptiButton(type="primary" v-if="!unlockLeft && relationships.length" @click.native="goUnlock") {{'patient.token.unlock.BTN'|translate}}
                template(#icon)
                    i.fa.fa-lock

            MountingPortal(v-if="dialog_sms" mountTo="#vue-modal" append)
                OptiDialogSmsToken(
                    :country="patient.cellPhoneNumberCountry"
                    :phone="cell_phone_number"
                    mode="unlock"

                    @token-success="successUnlock"
                    @closed="dialog_sms = false")

        .col-xs-12(v-if="!is_loading")
            ul.nav.nav-tabs.nav-justified.icons-header-tab
                li(:class="{'active': active_tab === 'detail'}")
                    a(:title="tab.detail|translate" @click="setActive('detail')")
                        i.visible-xs-inline.fa-lg.fa.fa-user.mr3
                        i.hidden-xs.fa.fa-user
                        span.visible-xs-inline.tab-title {{tab.detail|translate}}

                li(:class="{'active': active_tab === 'offers'}")
                    a(:title="tab.offers|translate" @click="setActive('offers')")
                        i.visible-xs-inline.fa-lg.fa.fa-briefcase.mr3
                        i.hidden-xs.fa.fa-briefcase.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.offers|translate}}
                        span.badge(v-show="count.offers") {{count.offers}}

                li(:class="{'active': active_tab === 'tasks'}")
                    a(:title="tab.tasks|translate" @click="setActive('tasks')")
                        i.visible-xs-inline.fa.fa-gear.mr3
                        i.hidden-xs.fa.fa-gear.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.tasks|translate}}
                        span.badge(v-show="count.tasks") {{count.tasks}}

                li(:class="{'active': active_tab === 'prescriptions'}")
                    a(:title="tab.prescriptions|translate" @click="setActive('prescriptions')")
                        i.visible-xs-inline.fa-lg.fa.fa-file-text-o.mr3
                        i.hidden-xs.fa.fa-file-text-o.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.prescriptions|translate}}
                        span.badge(v-show="count.prescriptions") {{count.prescriptions}}

                li(:class="{'active': active_tab === 'loyality'}")
                    a(:title="tab.loyality|translate" @click="setActive('loyality')")
                        i.visible-xs-inline.fa-lg.fa.fa-tags.mr3
                        i.hidden-xs.fa.fa-tags
                        span.visible-xs-inline.tab-title {{tab.loyality|translate}}

                li(:class="{'active': active_tab === 'smses_and_emails'}")
                    a(:title="tab.smses_and_emails|translate" @click="setActive('smses_and_emails')")
                        i.visible-xs-inline.fa-lg.fa.fa-envelope.mr3
                        i.hidden-xs.fa.fa-envelope.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.smses_and_emails|translate}}
                        span.badge(v-show="count.smses_and_emails") {{count.smses_and_emails}}

                li(:class="{'active': active_tab === 'reclamations'}")
                    a(:title="tab.reclamations|translate" @click="setActive('reclamations')")
                        i.visible-xs-inline.fa-lg.fa.fa-wrench.mr3
                        i.hidden-xs.fa.fa-wrench.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.reclamations|translate}}
                        span.badge(v-show="count.reclamations") {{count.reclamations}}

                li(:class="{'active': active_tab === 'previousLenses'}")
                    a(:title="tab.previousLenses|translate" @click="setActive('previousLenses')")
                        i.visible-xs-inline.fa-lg.fa.fa-history.mr3
                        i.hidden-xs.fa.fa-history.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.previousLenses|translate}}
                        span.badge(v-show="count.previousLenses") {{count.previousLenses}}

                li(:class="{'active': active_tab === 'interviews'}")
                    a(:title="tab.interviews|translate" @click="setActive('interviews')")
                        i.visible-xs-inline.fa-lg.fa.fa-eye.mr3
                        i.hidden-xs.fa.fa-eye.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.interviews|translate}}
                        span.badge(v-show="count.interviews") {{count.interviews}}

                li(:class="{'active': active_tab === 'services'}")
                    a(:title="tab.services|translate" @click="setActive('services')")
                        i.visible-xs-inline.fa-lg.fa.fa-gears.mr3
                        i.hidden-xs.fa.fa-gears.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.services|translate}}
                        span.badge(v-show="count.services") {{count.services}}

                li(:class="{'active': active_tab === 'covd'}")
                    a(:title="tab.covd|translate" @click="setActive('covd')")
                        i.visible-xs-inline.fa-lg.fa.fa-pencil-square.mr3
                        i.hidden-xs.fa.fa-pencil-square.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.covd|translate}}
                        span.badge(v-show="count.covd") {{count.covd}}

                li(:class="{'active': active_tab === 'notes'}")
                    a(:title="tab.notes|translate" @click="setActive('notes')")
                        i.visible-xs-inline.fa-lg.fa.fa-comments.mr3
                        i.hidden-xs.fa.fa-comments.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.notes|translate}}
                        span.badge(v-show="count.notes") {{count.notes}}

            .tab-content
                OptiPatientsDetailsMain.tab-pane(:class="{'active': active_tab === 'detail'}"
                    :patient="patient"
                    :block="block")

                OptiPatientsDetailsOffers.tab-pane(:class="{'active': active_tab === 'offers'}"
                    :department-id="patient.departmentId"

                    :patient="patient"

                    @set-offers="setOffers"
                    @go-to-prescriptions="goToPrescription"
                    @update-count="updateCount"

                    @reload="offerId => getData(offerId)")

                OptiPatientsDetailsTasks.tab-pane(:class="{'active': active_tab === 'tasks'}"
                    v-if="!refresh.tasks"

                    @reload-previous-lenses="reloadPreviousLenses"
                    @reload-all="getData"
                    @update-count="updateCount")

                OptiPatientsDetailsPrescriptions.tab-pane(:class="{'active': active_tab === 'prescriptions'}"
                    :department-id="patient.departmentId"
                    :block="block"

                    :offers="offers"
                    :offer-id="offer_id"

                    @reload-covd="reload('covd')"
                    @update-count="updateCount")

                OptiPatientsDetailsLoyalityCards.tab-pane(:class="{'active': active_tab === 'loyality'}"
                    :block="block"
                    :allow="allow")

                OptiPatientsDetailsSmsesAndEmails.tab-pane(:class="{'active': active_tab === 'smses_and_emails'}"
                    :patient="patient"
                    :block="block"

                    @update-count="updateCount")

                OptiPatientsDetailsReclamations.tab-pane(:class="{'active': active_tab === 'reclamations'}"
                    @update-count="updateCount")

                OptiPatientsDetailsPreviousLenses.tab-pane(:class="{'active': active_tab === 'previousLenses'}"
                    v-if="!refresh.previous_lenses"

                    :patient="patient"
                    :block="block"

                    @reload-tasks="reload('tasks')"
                    @reload-all="getData"
                    @update-count="updateCount")

                OptiPatientsDetailsInterviews.tab-pane(:class="{'active': active_tab === 'interviews'}"
                    :block="block"

                    @update-count="updateCount")

                OptiPatientsDetailsServices.tab-pane(:class="{'active': active_tab === 'services'}"
                    @update-count="updateCount")

                OptiPatientsDetailsCOVD.tab-pane(:class="{'active': active_tab === 'covd'}"
                    v-if="!refresh.covd"

                    @update-count="updateCount")

                OptiNotes.tab-pane(:class="{'active': active_tab === 'notes'}"
                    table="patient_notes"
                    table-key="patient_id"

                    :id="patient.id"
                    :notes="patient.notes"
                    :upload="true"

                    @update-notes="updateNotes")

        .col-xs-12
            OptiDimmer(:active="is_loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {Activity} from '@/js/app/vue/helpers/Activity'
    import {SMSHelper} from '@/js/app/vue/helpers/SMSHelper'

    import OptiPatientsDetailsRelationships
        from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsRelationships'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCountdown from '@/js/app/vue/components/Timer/OptiCountdown'
    import OptiDialogSmsToken from '@/js/app/vue/components/Patients/Dialogs/OptiDialogSmsToken'

    import OptiPatientsDetailsMain from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsMain'
    import OptiPatientsDetailsOffers from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsOffers'
    import OptiPatientsDetailsTasks from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsTasks'
    import OptiPatientsDetailsPrescriptions
        from '@/js/app/vue/components/Patients/Details/Prescriptions/OptiPatientsDetailsPrescriptions'
    import OptiPatientsDetailsLoyalityCards
        from '@/js/app/vue/components/Patients/Details/LoyalityCards/OptiPatientsDetailsLoyalityCards'
    import OptiPatientsDetailsSmsesAndEmails from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsSmsesAndEmails'
    import OptiPatientsDetailsReclamations
        from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsReclamations'
    import OptiPatientsDetailsPreviousLenses
        from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsPreviousLenses'
    import OptiPatientsDetailsInterviews from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsInterviews'
    import OptiPatientsDetailsServices from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsServices'
    import OptiPatientsDetailsCOVD from '@/js/app/vue/components/Patients/Details/OptiPatientsDetailsCOVD'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetails',
        components: {
            OptiPatientsDetailsRelationships,
            OptiButton,
            OptiCountdown,
            OptiDialogSmsToken,

            OptiPatientsDetailsMain,
            OptiPatientsDetailsOffers,
            OptiPatientsDetailsTasks,
            OptiPatientsDetailsPrescriptions,
            OptiPatientsDetailsLoyalityCards,
            OptiPatientsDetailsSmsesAndEmails,
            OptiPatientsDetailsReclamations,
            OptiPatientsDetailsPreviousLenses,
            OptiPatientsDetailsInterviews,
            OptiPatientsDetailsServices,
            OptiPatientsDetailsCOVD,
            OptiNotes,

            OptiDimmer
        },
        props: {
            initHash: {
                type: String,
                required: true
            }
        },
        data() {
            let prefix = 'patient.card.'

            return {
                active_tab: 'detail',

                tab: {
                    detail: prefix + 'DETAIL',
                    offers: prefix + 'OFFERS',
                    tasks: prefix + 'TASKS',
                    prescriptions: prefix + 'PRESCRIPTIONS',
                    loyality: prefix + 'LOYALITY',
                    smses_and_emails: prefix + 'SMSES_AND_EMAILS',
                    reclamations: prefix + 'RECLAMATIONS',
                    previousLenses: prefix + 'PREVIOUSLENSES',
                    interviews: prefix + 'INTERVIEWS',
                    services: prefix + 'SERVICES',
                    covd: prefix + 'COVD',
                    notes: prefix + 'NOTES'
                },

                count: {
                    prescriptions: 0,
                    offers: 0,
                    tasks: 0,
                    smses_and_emails: 0,
                    reclamations: 0,
                    previousLenses: 0,
                    interviews: 0,
                    services: 0,
                    covd: 0,
                    notes: 0
                },

                patient: {},
                relationships: [],

                offers: [],
                offer_id: 0,

                allow: false,
                unlockLeft: false,
                block: true,

                dialog_sms: false,
                cell_phone_number: '',

                refresh: {
                    tasks: false,
                    previous_lenses: false,
                    covd: false
                },

                loading: {
                    main: true,
                    relationships: true
                }
            }
        },
        beforeMount() {
            this.$store.dispatch('offer/init')
        },
        mounted() {
            this.setActive(this.initHash ? this.initHash : 'detail')

            this.getData()
        },
        methods: {
            setActive(hash) {
                this.active_tab = hash

                this.$emit('breadcrumb-change-last', hash, this.tab[hash])
            },
            setOffers(offers) {
                this.offers = offers

                if(!this.offer_id && this.offers.length) {
                    this.offer_id = this.offers[0].id
                }
            },
            goToPrescription(offer_id) {
                this.offer_id = offer_id

                this.setActive('prescriptions')
            },

            getData(offerId) {
                if(offerId) {
                    this.$state.params.offerId = offerId
                }

                this.loading.main = true

                API.get('patients/' + this.$state.params.id).then(res => {
                    this.patient = res.data

                    this.allow = this.patient.department.id === this.$uac.user.activeDepartmentId ||
                        this.$uac.user.activeDepartmentId === 91

                    this.unlockLeft = SMSHelper.unlockToken(this.patient.unlock)
                    this.block = !this.patient.cellPhoneNumber && !this.unlockLeft

                    this.count.notes = this.patient.notes.length

                    this.$emit('update-memory', {
                        id: this.patient.id,
                        name: this.patient.firstName + ' ' + this.patient.lastName
                    })

                    Activity.increaseForPatient(this.patient.id)
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('patient.notify.NOTLOAD')
                })
            },

            contactPlusPrint() {
                API.post('patient/contact-plus/check/' + this.patient.id).then(res => {
                    if(res.data === 0) {
                        API.post('patient/contact-plus/add/' + this.patient.id).then(res => {
                            this.$state.go('app.patients.contact-plus-add', {
                                id: res.data
                            })
                        })
                    } else {
                        this.$notify.error('patient.notify.error.CONTACTPLUS')
                    }
                })
            },

            getCellPhoneNumber() {
                let _return = ''

                this.relationships.forEach(patient => {
                    if((!patient.cellPhoneNumberCountryId || patient.cellPhoneNumberCountryId === 171) && patient.cellPhoneNumber) {
                        _return = patient.cellPhoneNumber
                    }
                })

                return _return
            },
            goUnlock() {
                this.cell_phone_number = this.getCellPhoneNumber()

                if(!this.cell_phone_number) {
                    this.$notify.error('patient.token.notify.error.NOPHONE')
                } else {
                    this.dialog_sms = true
                }
            },
            successUnlock() {
                this.dialog_sms = false

                API.post('patient/unlock', {
                    patients: this.relationships
                }).then(() => {
                    this.getData()

                    this.$notify.success('patient.token.unlock.notify.UNLOCK')
                }).catch(() => {
                    this.$notify.error('patient.token.unlock.notify.error.UNLOCK')
                })
            },

            reload(type) {
                this.refresh[type] = true

                setTimeout(() => {
                    this.refresh[type] = false
                }, 500)
            },
            reloadPreviousLenses(patient) {
                if(typeof patient !== 'undefined') {
                    this.patient.previous_glasses = patient.previous_glasses.map(i => i.toString())
                    this.patient.glasses.first = patient.glasses_first
                    this.patient.glasses.none = patient.glasses_none
                }

                this.reload('previous_lenses')
            },

            updateRelationships(relationships) {
                this.relationships = relationships

                this.loading.relationships = false
            },
            updateNotes(notes) {
                this.patient.notes = notes

                this.updateCount('notes', this.patient.notes.length)
            },
            updateCount(type, count) {
                this.count[type] = count
            },

            setPatientToOffer() {
                this.$store.commit('offer/setPatient', this.patient)

                this.$state.go('app.catalog.offer')
            }
        },
        computed: {
            is_offer() {
                return this.$store.getters['offer/isFindOrCreatePatient']
            },
            is_loading() {
                return this.loading.main || this.loading.relationships
            }
        }
    }
</script>
