<template lang="pug">
    div
        .input-group
            .input-group-btn
                select.form-control(style="width:100px" :disabled="disabled" :required="required"
                    v-model="model.cash_register_id" @change="$emit('receipt-was-updated')")
                    option(v-for="c in cash_registers" :value="c.id") {{c.name}}
            .input-group(style="width:100%")
                input.form-control(v-if="taskId" :id="id" :name="id" :placeholder="'settings.cash.PLACEHOLDER'|translate"
                    :disabled="disabled" :required="required"
                    v-model.lazy="model.receipt" @change="$emit('receipt-was-updated')")
                input.form-control(v-else :id="id" :name="id" :placeholder="'settings.cash.PLACEHOLDER'|translate"
                    :disabled="disabled" :required="required"
                    v-model="model.receipt" @change="$emit('receipt-was-updated')")

        OptiValidate(:f="id" :data="$v.model")
        OptiValidate(:f="id" :data="$v.model.cash_register_id")
        OptiValidate(:f="id" :data="$v.model.receipt")
        OptiReceiptValidate(:id="id" :data="validate" :no-cash-register="$v.model.cash_register_id.noCashRegister"
            @add-prefix="addPrefix")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {requiredIf} from 'vuelidate/lib/validators'
    import {noZeroIf} from '@/js/vue.validators.js'
    import {noCashRegister, bill} from './ReceiptValidator'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiReceiptValidate from '@/js/app/vue/components/Register/Receipt/OptiReceiptValidate'

    export default {
        name: 'OptiReceipt',
        components: {
            OptiValidate,
            OptiReceiptValidate

        },
        props: {
            id: {
                type: String,
                required: true
            },

            model: {
                type: Object,
                required: true
            },

            taskId: {
                type: Number,
                required: false,
                default: 0
            },
            departmentId: {
                type: Number,
                required: false,
                default: 0
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                cash_registers: [],
                department_id: this.departmentId ? this.departmentId : this.$uac.user.activeDepartmentId,

                validate: {
                    type: ''
                }
            }
        },
        watch: {
            '$v.model.$invalid'(invalid) {
                this.$emit('invalid', invalid)
            }
        },
        validations: {
            model: {
                bill: bill(),
                cash_register_id: {
                    required: noZeroIf(function() {return this.cash_registers.length && !this.disabled && this.required}), // nie działa jako () =>
                    noCashRegister: noCashRegister()
                },
                receipt: {
                    required: requiredIf(function() {return this.model.cash_register_id && this.cash_registers.length && !this.disabled && this.required})
                }
            }
        },
        mounted() {
            API.get('register/cash-register/get/active/' + this.department_id).then(res => {
                this.cash_registers = res.data

                if(!this.model.cash_register_id) {
                    let _default = this.cash_registers.filter(register => register.default)
                    if(_default.length) {
                        this.model.cash_register_id = _default[0].id
                    }
                }
            }).catch(() => {
                this.$notify.error('settings.cash.notify.error.LIST')
            })
        },
        methods: {
            addPrefix(receipt) {
                this.model.receipt = receipt

                this.$emit('receipt-was-updated')
            }
        }
    }
</script>