<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-group-department.form-horizontal(name="formDepartmentGroup" @submit.prevent="edit")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'departments.groups.form.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="departmentGroup.name" required)
                        OptiValidate(f="name" :data="$v.departmentGroup.name")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.departmentGroup.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDepartmentsGroupEdit',
        components: {
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                departmentGroup: {
                    name: ''
                },

                loading: true
            }
        },
        validations() {
            return {
                departmentGroup: {
                    name: {
                        required,
                        unique: unique('salon_groups', 'GROUP_NAME', this.departmentGroup.id, 'ID')
                    }
                }
            }
        },
        mounted() {
            API.get('departments/group/' + this.$state.params.id).then(res => {
                this.departmentGroup = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('departments.groups.notify.error.LOAD')
            })
        },
        methods: {
            edit() {
                this.loading = true

                API.put('departments/group', this.departmentGroup).then(() => {
                    this.$state.go('app.departments.groups.list')

                    this.$notify.success('departments.groups.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('departments.groups.notify.error.EDIT')
                })
            }
        }
    }
</script>