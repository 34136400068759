<template lang="pug">
    form#cart-sale(name="cartSale")

        #cart-sale-patient.row(v-show="edit")
            .col-sm-7
                .col-xs-5
                    .form-group
                        .col-xs-12.control-label
                            .radio.c-radio.c-radio-nofont
                                label.text-bold(:class="{'star-required': sale.patient_exists === 1}")
                                    input(type="radio" name="patientExists" v-model="patient_exists" :value="1")
                                    span
                                    | {{'sale.offstock.table.head.PATIENTID'|translate}}
                .col-xs-7(style="padding-right:0")
                    OptiSelectPatient(id="patient" :init-result="sale.patient_search"
                        :disabled="sale.patient_exists !== 1" :required="sale.patient_exists !== 1"
                        @selected="updatePatient")
                    .bold.warn#patient(v-show="sale.patient_exists === 1 && !sale.patient_search.id"
                        ) {{'validation.formValidate.required'|translate}}
            .col-sm-5
                .form-group
                    .radio.c-radio.c-radio-nofont
                        label.text-bold
                            input(type="radio" name="patientExists" v-model="patient_exists" :value="2")
                            span
                            | {{'sale.offstock.table.head.PATIENTNOEXISTS'|translate}}

        #cart-sale-product.row(v-show="edit && (sale.patient_exists === 1 || sale.patient_exists === 2)")
            .col-xs-6
                OptiSelectProduct(:product="sale.productIdBarcode" :auto-select="true" @selected="updateProduct")

                MountingPortal(v-if="product" mountTo="#vue-modal" append)
                    OptiCartDialogProductConfirm(type="sale" :product="product"
                        @confirmed="addProduct" @closed="product = null")
            .col-xs-6.text-right
                OptiButton(type="success" text="carts.confirm.gosale" :disabled="disabled" @confirmed="goSale") {{'carts.sale.GOSALE'|translate}}
                    template(#icon)
                        i.fa.fa-shopping-cart

        #cart-sale-table.row
                .col-xs-12
                    .panel.panel-success(v-show="sale.patient_exists === 1 || sale.patient_exists === 2")
                        .panel-heading
                            i.fa.fa-shopping-cart.mr3
                            span.mr3 {{'carts.type.fullname.SALE'|translate}}
                            span(v-if="Object.keys(sale.patient).length")
                                span {{sale.patient.firstName}} {{sale.patient.lastName}}, id {{sale.patient.id}}
                                span(v-show="sale.patient.loyality.typeId > 0")
                                    span.mr3(style="margin-left:3px") - {{'carts.type.fullname.CARD'|translate}}
                                    span.mr3 {{sale.patient.loyality.typeTranslate|translate}}
                                    span ({{getDiscountCard(sale.patient.loyality.typeId)}}%)
                            span.warn(v-show="!Object.keys(sale.patient).length && sale.patient_exists === 1") {{'carts.type.fullname.NOPATIENT'|translate}}
                            span(v-show="sale.patient_exists === 2") {{'carts.type.fullname.NEWCLIENT'|translate}}

                        .table-responsive
                            table.table.middle
                                thead
                                    tr
                                        th(v-show="edit")
                                            .checkbox.c-checkbox.checkbox-green
                                                label
                                                    input(type="checkbox" v-model="checkbox_all")
                                                    span.fa.fa-check
                                        th #
                                        th {{'carts.PRODUCTID'|translate}}
                                        th {{'carts.NAME'|translate}}
                                        th {{'carts.PRICE'|translate}}
                                        th {{'carts.QUANTITY'|translate}}
                                        th {{'carts.VAT'|translate}}
                                        th {{'carts.VALUE'|translate}}
                                        th {{'carts.DISCOUNT'|translate}}
                                        th(NOWRAP) {{'carts.AFTERDISCOUNT'|translate}}
                                        th(v-show="edit")

                                tbody
                                    template(v-for="(item, key) in sale.items")
                                        tr(:class="{'tr-stripped': key % 2}")
                                            td(v-show="edit")
                                                .checkbox.c-checkbox.checkbox-green
                                                    label
                                                        input(type="checkbox" :checked="item.checkbox"
                                                            @change="e => updateCheckbox(key, e)")
                                                        span.fa.fa-check
                                            td {{sale.items.length - key}}
                                            td.text-right
                                                OptiProduct(:product="item.product") {{item.product.id}}
                                            td
                                                OptiProduct(:product="item.product") {{item.product.name}}
                                                .label.label-success.pull-right(v-show="item.highlight") {{'carts.NEW'|translate}}
                                                small(style="display:block") {{item.product.fullName}}
                                            td.text-right {{item.product.price|currency}}
                                            td.col-xs-1.text-right
                                                OptiNumber(v-show="edit" :header="'carts.QUANTITY'|translate" :required="true"
                                                    :value="item.quantity" @confirmed="quantity => updateQuantity(key, quantity)"
                                                    ) {{item.quantity}}
                                                span(v-show="!edit") {{item.quantity}}
                                            td.text-right(NOWRAP) {{item.product.vat}}%
                                            td.text-right {{item.product.price * item.quantity|currency}}
                                            td.text-right(NOWRAP)
                                                OptiNumber(style="display:inline-block;width:85px;margin-right:5px"
                                                    :class="{'border-input': !item.mode}" v-show="edit"
                                                    :header="'carts.DISCOUNT'|translate" :float="true" :value="item.percentDiscount"
                                                    @confirmed="discount => updateDiscountPercent(key, discount)"
                                                    ) {{item.percentDiscount|number(2)}}%
                                                span.mr3(v-show="!edit") {{item.percentDiscount|number(2)}}%

                                                OptiNumber(style="display:inline-block;width:85px"
                                                    :class="{'border-input': item.mode}" v-show="edit"
                                                    :header="'carts.DISCOUNT'|translate" :float="true" :value="item.discount"
                                                    @confirmed="discount => updateDiscount(key, discount)"
                                                    ) {{item.discount|currency}}
                                                span(v-show="!edit") {{item.discount|currency}}
                                            td.text-right(:class="{'warn': item.product.price * item.quantity - item.discount < 0}"
                                                ) {{item.product.price * item.quantity - item.discount|currency}}
                                            td.text-center(style="width:105px" v-show="edit")
                                                label.btn.btn-danger(:for="'cart-sale-remove-' + key")
                                                    i.fa.fa-fw.fa-remove
                                                OptiButton.hide(:id="'cart-sale-remove-' + key" text="carts.confirm.remove"
                                                    @confirmed="deleteItems([item.id])")

                                        tr(:class="{'tr-stripped': key % 2}")
                                            td(v-show="!edit")
                                            td.b0(:colspan="edit ? 4 : 2")
                                                .text-warning(v-show="item.product.depository.stock - item.quantity < 0")
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'carts.notify.error.nostock.LOCAL'|translate}}
                                                .warn(v-show="item.product.price * item.quantity - item.discount < 0")
                                                    i.fa.fa-exclamation-circle.mr3
                                                    span {{'carts.sale.notify.error.MINUSVALUE'|translate}}
                                            td.b0.text-right.bold
                                                label(:for="'comments' + item.id" v-show="!item.is_coupon") {{'carts.COMMENTS'|translate}}
                                                label(:for="'coupon' + item.id" v-show="item.is_coupon") {{'carts.sale.coupons.CODE'|translate}}
                                            td.b0(colspan="5")
                                                input.form-control(:id="'comments' + item.id" v-show="edit && !item.is_coupon"
                                                    :model="'comments' + key" :value="item.comments"
                                                    @input="e => updateComments(key, e)" maxlength="100")
                                                span(v-show="!edit") {{item.comments}}
                                                OptiCartSaleCoupon(v-if="edit && item.is_coupon" :id="item.id"
                                                    :product-id="item.productId" :quantity="item.quantity" :key="item.id"
                                                    :comments="item.comments" :codes="item.codes"
                                                    @valid-codes="e => validCouponCodes(key, e)")

                                            td.b0.text-center(v-show="edit")
                                                OptiAutoSave(endpoint="carts/update" fields="quantity,mode,percentDiscount,discount,comments"
                                                    :model="item" :key="item.id" @saved="i => updateAfterSaved(key, i)")

                                    tr(v-show="!sale.items.length")
                                        td.text-center.text-muted(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}

                                tfoot
                                    tr.align-middle
                                        th(colspan="4" v-show="edit")
                                            OptiButton(type="danger" :disabled="sale.disabled_btn_delete"
                                                text="carts.confirm.remove.all" @confirmed="deleteItemsFromCheckbox"
                                                ) {{'carts.DELETEITEMS'|translate}}
                                                template(#icon)
                                                    i.fa.fa-remove
                                        th(colspan="3" v-show="!edit")
                                        th.text-right {{'carts.TOTALS'|translate}}
                                        th.text-right {{sale.totals.quantity}}
                                        th
                                        th.text-right {{sale.totals.value|currency}}
                                        th.text-right ({{sale.totals.percentDiscount|number(2)}}%) {{sale.totals.discount|currency}}
                                        th.text-right(NOWRAP) {{sale.totals.afterDiscount|currency}}
                                        td(v-show="edit")
                                    tr.align-middle
                                        td(colspan="3")
                                        td(v-show="edit")
                                        td(colspan="3")
                                        th.text-right {{'carts.TOTALVAT'|translate}}
                                        td.text-right
                                            div(v-for="(sum, vat) in sale.totals.vat") {{vat}}%
                                        th.text-right
                                            div(v-for="(sum, vat) in sale.totals.vat") {{sum|currency}}<br/>
                                        td(v-show="edit")
</template>

<script>
    import saleHelper from '@/js/app/vue/store/carts/helper/sale'

    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCartDialogProductConfirm from '@/js/app/vue/components/Carts/Dialogs/OptiCartDialogProductConfirm'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiCartSaleCoupon from '@/js/app/vue/components/Carts/OptiCartSaleCoupons'

    export default {
        name: 'OptiCartSale',
        components: {
            OptiSelectPatient,
            OptiSelectProduct,
            OptiButton,
            OptiCartDialogProductConfirm,
            OptiNumber,
            OptiProduct,
            OptiAutoSave,
            OptiCartSaleCoupon
        },
        props: {
            sale: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('carts.sale.edit'),

                product: null
            }
        },
        mounted() {

        },
        methods: {
            updatePatient(patient) {
                this.$store.dispatch('carts/updatePatient', patient)
            },

            updateProduct(product) {
                this.product = product
            },
            addProduct() {
                this.$store.dispatch('carts/addProduct', {
                    type: 'sale',
                    product: this.product
                })

                this.product = null
            },

            getDiscountCard(typeId) {
                return saleHelper.getDiscountCard(typeId)
            },

            updateQuantity(key, quantity) {
                this.$store.dispatch('carts/updateQuantity', {
                    type: 'sale',
                    key: key,
                    quantity: quantity
                })
            },
            updateDiscountPercent(key, discount) {
                this.$store.dispatch('carts/updateDiscountPercent', {
                    type: 'sale',
                    key: key,
                    discount: discount
                })
            },
            updateDiscount(key, discount) {
                this.$store.dispatch('carts/updateDiscount', {
                    type: 'sale',
                    key: key,
                    discount: discount
                })
            },

            updateCheckbox(key, event) {
                this.$store.dispatch('carts/updateCheckbox', {
                    type: 'sale',
                    key: key,
                    bool: event.target.checked
                })
            },

            deleteItems(item_ids) {
                this.$store.dispatch('carts/deleteItems', {
                    type: 'sale',
                    item_ids: item_ids
                })
            },
            deleteItemsFromCheckbox() {
                this.$store.dispatch('carts/deleteItemsFromCheckbox', 'sale')
            },

            updateComments(key, event) {
                this.$store.dispatch('carts/updateComments', {
                    key: key,
                    comments: event.target.value
                })
            },
            validCouponCodes(key, input) {
                this.$store.dispatch('carts/updateComments', {
                    key: key,
                    comments: input
                })
            },

            updateAfterSaved(key, item) {
                if(item.is_coupon) {
                    this.$store.dispatch('carts/updateCodes', {
                        key: key,
                        codes: item.codes
                    })
                }
            },

            goSale() {
                this.$emit('toggled')
            }
        },
        computed: {
            disabled() {
                return this.$store.getters['carts/getDisabled']('sale')
            },
            patient_exists: {
                get() {
                    return this.$store.getters['carts/getPatientExists']
                },
                set(value) {
                    this.$store.dispatch('carts/updatePatientExists', value)
                }
            },
            checkbox_all: {
                get() {
                    return this.$store.getters['carts/getCheckboxAll']('sale')
                },
                set(value) {
                    this.$store.dispatch('carts/updateCheckboxAll', {
                        type: 'sale',
                        bool: value
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    #cart-sale-patient,
    #cart-sale-product {
        margin-bottom: 10px;
    }
</style>