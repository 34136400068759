<template lang="pug">
    .opti-select-city
        VueMultiSelect(
            id="city"
            :options="cities"
            :value="city"
            :clearable="false"
            label="name"
            :placeholder="'search.city.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            :loading="loading"
            group-label="type"
            group-values="cities"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            :disabled="false"

            @input="updateSelected"
            @search-change="loadData")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel") {{ props.option }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectCity',
        components: {VueMultiSelect},
        props: {
            city: {
                type: String,
                required: true,
                default: ''
            }
        },
        data() {
            return {
                cities: [],

                queue: null,
                loading: false
            }
        },
        methods: {
            async loadData(data) {
                clearTimeout(this.queue)

                if(data.length >= 3) {
                    this.loading = true

                    this.queue = setTimeout(() => {
                        API.post('search/city', {
                            name: data
                        }).then(res => {
                            let found = false

                            res.data.forEach(city => {
                                if(data === city) {
                                    found = true
                                }
                            })

                            if(!found) {
                                this.cities = [{
                                    type: 'Nowy wpis',
                                    cities: [data]
                                }]
                            }

                            this.cities.push({
                                type: 'Miasta z bazy',
                                cities: res.data
                            })

                            this.loading = false
                        }).finally(() => {
                            this.loading = false
                        }).catch(error => {
                            console.log(error)
                        })
                    }, 2000)
                }
            },
            updateSelected(city) {
                this.$emit('selected', city)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
