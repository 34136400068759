<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-edit.mr3
            span {{'catalog.product.attributes.edit.EDIT'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            form.form-horizontal(name="formAttributes")
                .form-group(v-for="attribute in attributes")
                    label.col-sm-5.control-label(:for="attribute.key") {{attribute.name}}

                    .col-sm-7
                        input.form-control(v-if="attribute.valueType === 0" :id="attribute.key" :name="attribute.key"
                            v-model="form[attribute.id]")
                        select.form-control(v-if="attribute.valueType === 1" :id="attribute.key" :name="attribute.key"
                            v-model="form[attribute.id]" ng-options="o.id as o.name for o in attribute.options")
                            option(v-for="o in attribute.options" :value="o.id") {{o.name}}
                        input.form-control(type="number" v-if="attribute.valueType === 2" :id="attribute.key" :name="attribute.key"
                            v-model="form[attribute.id]")

                        .checkbox.c-checkbox.checkbox-primary.inline(v-if="attribute.valueType === 3")
                            label
                                input(type="checkbox" :id="attribute.key" :name="attribute.key"
                                    :disabled="['Plastic', 'Metal'].includes(form[7]) && ['shape_change_top', 'shape_change_bottom', 'shape_change_temporal', 'shape_change_nasal'].includes(attribute.key)" v-model="form[attribute.id]")
                                span.fa.fa-check

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButtonSave(:disabled="loading" @click.native="save")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogProductAttributes',
        components: {
            OptiDialog,
            OptiNumber,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                form: {},

                attributes: {},

                loading: true
            }
        },

        mounted() {
            API.get('warehouse/products/attributes/type/' + this.product.type).then(res => {
                this.attributes = res.data
                this.product.attributes.forEach(attribute => {
                    this.attributes.forEach(attr => {
                        if(attribute.isFilled) {
                            this.form[attribute.attributeId] = attribute.value
                        }

                        if(!attribute.isFilled && attr.id === attribute.attributeId) {
                            this.form[attr.id] = attr.default
                        }
                    })
                })

                this.attributes.forEach(attr => {
                    if(attr.valueType === 1) {
                        attr.options = this.parseOptions(attr.options)
                    } else if(attr.valueType === 3) {
                        this.form[attr.id] = !!parseInt(this.form[attr.id])
                    }
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.product.attributes.load.ERROR')
            })
        },

        methods: {
            parseOptions(options) {
                let result = []

                options.split('&&').forEach(option => {
                    let value = option.split('/')

                    if(value[0] && value[1]) {
                        result.push({
                            id: value[0].trim(),
                            name: value[1].trim()
                        })
                    }
                })

                return result
            },
            save() {
                this.loading = true

                API.post('warehouse/products/attributes/save', {
                    productId: this.product.id,
                    form: this.form
                }).then(res => {
                    this.$notify.success('attributes.product.action.EDITED')

                    this.$emit('update-attributes', res.data.attributes, res.data.logs)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('attributes.product.error.EDITED')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
    }
</style>