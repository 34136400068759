<template lang="pug">
    .panel.panel-default(style="position:relative")
        .panel-body.text-center.hover-me(style="cursor:pointer" @click="$emit('scroll')")
            .row
                .col-xs-4
                    span.text-muted.mb0 Model:
                .col-xs-8(v-if="rotation.product")
                    h4.mt0 {{rotation.product}}
                .col-xs-8(v-else)
                    span Brak danych

            .row
                .col-xs-4
                    span.text-muted.mb0 Kolekcja:
                .col-xs-8
                    h4.mt0 {{rotation.category}}

            .row
                .col-xs-4
                    span.text-muted.mb0 Mar/m:
                .col-xs-8
                    h4.mt0 {{turnout_per_place}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductDetailedStatistic',
        components: {
            OptiDimmer
        },
        props: {
            productId: {
                type: Number,
                required: true
            },
            categoryId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                rotation: {
                    product: null,
                    category: null
                },
                turnout_per_place: null,

                loading: false
            }
        },
        mounted() {
            if(this.categoryId) {
                API.post('warehouse/product/getProductDetailedStats', {
                    product_id: this.productId,
                    category_id: this.categoryId
                }).then(res => {
                    this.rotation = res.data.rotation
                    this.turnout_per_place = res.data.turnout_per_place
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('Nie można załadować danych o rotacji!')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .hover-me:hover {
        background-color: rgba(189, 184, 173, 0.5)
    }
</style>