<template lang="pug">
    .task-offer-switch(:class="{'selected': selected}")
        .task-offer-switch-img
            img(:title="product.name" :src="'thumb/product/ids/' + product.id + '.jpg'")

        .task-offer-switch-info
            div
                span(:title="product.name") {{product.name}}

            div(v-for="task in product.pivot.tasks" :title="(task.offer_temporary_task_id !== offerTemporaryTaskId ? 'catalog.offer.GO_TO_TEMPORARY' : '')|translate"
                :class="{'selected': task.offer_temporary_task_id === offerTemporaryTaskId}"
                @click="select(task.offer_temporary_task_id)")
                div(style="display:flex;align-items:center")
                    .mr3
                        span.mr3 {{task.offer_temporary_task_id}}
                        small.text-muted ({{task.type|translate}})
                    OptiRateWrapper(v-if="task.offer_temporary_task_id !== offerTemporaryTaskId || (task.offer_temporary_task_id === offerTemporaryTaskId && !rateSave)"
                        :load="task.offer_temporary_task_id !== offerTemporaryTaskId"
                        :rate="rate"
                        :ref-type="2"
                        :ref-id="task.offer_temporary_task_id"

                        @reload="$emit(task.offer_temporary_task_id === offerTemporaryTaskId ? 'rate-save' : 'reload')")

                div
                    span(:title="task.offer_task.lenses.translated"
                        :class="{'warn': task.offer_task.lenses.none}"
                        ) {{task.offer_task.lenses.translated}}

                div
                    span(:title="task.offer_task.lenses_to_compare.translated"
                        :class="{'warn': task.offer_task.lenses_to_compare.none}"
                        ) {{task.offer_task.lenses_to_compare.translated}}
</template>

<script>
    import OptiRateWrapper from '@/js/app/vue/components/Rate/OptiRateWrapper'

    export default {
        name: 'OptiTaskDetailsOfferProduct',
        components: {
            OptiRateWrapper
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            offerTemporaryTaskId: {
                type: Number,
                required: true
            },
            rateSave: {
                required: true
            },

            rate: {
                type: Object | Array,
                required: true
            }
        },
        methods: {
            select(offerTemporaryTaskId) {
                if(offerTemporaryTaskId !== this.offerTemporaryTaskId) {
                    this.$emit('selected', offerTemporaryTaskId)
                }
            }
        },
        computed: {
            selected() {
                return !!this.product.pivot.tasks.filter(t => t.offer_temporary_task_id === this.offerTemporaryTaskId).length
            }
        }
    }
</script>

<style lang="less" scoped>
    @opacity: .65;

    .task-offer-switch {
        margin-bottom: 5px;

        > .task-offer-switch-img {
            text-align: center;

            > img {
                width: 50%;
                opacity: @opacity;
            }
        }

        > .task-offer-switch-info {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 5px;

            > div {
                padding: 3px;
                opacity: @opacity;

                &:first-child {
                    text-align: center;
                    font-weight: 700;
                }

                &:not(:first-child) {
                    &.selected {
                        border: 3px solid darkorange;
                        margin-top: -2px;
                        opacity: 1;
                    }

                    &:hover:not(.selected) {
                        cursor: pointer;
                        opacity: 1;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #aaa;
                }

                > div {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        &.selected {
            > .task-offer-switch-img > img,
            > .task-offer-switch-info > div:first-child {
                opacity: 1;
            }
        }
    }
</style>