<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryTasksInProgress',
        components: {
            OptiDimmer
        },
        props: {

        },
        data() {
            return {

            }
        },
        mounted() {
            let filename = 'prace_w_toku_' + moment().format('YYYY-MM-DD') + '_' + parseInt(Math.random() * 100000) + '.xls'

            EventBus.$emit('ng:emit', {
                name: 'downloadable',
                data: {
                    name: 'warehouse/inventories/tasks_in_progress/' + filename,
                    status: true
                }
            })

            API.post('warehouse/inventories/tasks-in-progress', {
                filename: filename
            }).finally(() => {
                this.$state.previous()
            }).catch(() => {
                this.$notify.error('inventory.tasks.notify.error.LOAD')
            })
        },
        methods: {

        }
    }
</script>
