<template lang="pug">
    .row
        .col-xs-12
            .table-responsive
                table.table.table-striped.middle(style="margin-bottom:0")
                    tbody
                        tr
                            td(style="white-space:nowrap")
                                i.fa.fa-navicon.mr3
                                span {{'optometrist.breadcrumb.section.QUESTIONNAIRE'|translate}}
                                button.btn.btn-info(style="padding:2px 10px;margin:0 0 2px 7px"
                                    type="button" :class="{'btn-warning active': help}" @click="help = !help") {{'default.HELP'|translate}}
                            td(colspan="2")
                                button.btn.btn-info(type="button" @click="dialog = true")
                                    span(v-show="points.covd === -1") &lt; {{'optometrist.questionnaire.GO'|translate}} &gt;
                                    span(v-show="points.covd !== -1") {{'optometrist.questionnaire.SUM'|translate}}: {{points.covd}}

                                    MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                                        OptiOptometristsDetailsSummaryDialogCOVD(
                                            :questionnaire="questionnaire"

                                            :disabled="block2 || extra"

                                            @confirmed="updateQuestionnaire"
                                            @cancelled="dialog = false")

                                button.btn.btn-info(type="button" style="margin-left:10px"
                                    v-show="points.covd !== -1" :class="{'btn-warning active': answers}"
                                    @click="answers = !answers") {{'optometrist.questionnaire.ANSWERS'|translate}}
                            td(v-show="points.covd !== -1")
                                span(v-show="ok") {{'optometrist.result.covd.OK'|translate}}
                                span(v-show="!ok") {{'optometrist.result.covd.FAIL'|translate}}
                            td.text-right(ng-show="points.covd !== -1")
                                i.fa.fa-2x.fa-remove.warn(v-show="!ok")
                                i.fa.fa-2x.fa-check.text-success(v-show="ok")
                        tr(v-show="help")
                            td(colspan="5")
                                OptiOptometristsTips(:data="tips")
                        tr(v-show="answers")
                            td(colspan="5")
                                ul(style="list-style-type:none;padding-left:0px")
                                    li(style="padding-top:5px" v-for="question in questionnaire" v-show="question.pivot.option")
                                        span.mr3 {{question.pl}} -
                                        OptiEnum(style="font-style:italic;text-transform:lowercase"
                                            enum="InterviewQuestionnaireOption" :id="question.pivot.option")
</template>

<script>
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiOptometristsDetailsSummaryDialogCOVD
        from '@/js/app/vue/components/Optometrists/Details/Summary/Dialogs/OptiOptometristsDetailsSummaryDialogCOVD'

    export default {
        name: 'OptiOptometristsDetailsSummaryCOVD',
        components: {
            OptiOptometristsTips,
            OptiEnum,
            OptiOptometristsDetailsSummaryDialogCOVD
        },
        props: {
            questionnaire: {
                type: Array,
                required: true
            },
            points: {
                type: Object,
                required: true
            },

            block2: {
                type: Boolean,
                required: true
            }
        },
        data() {
            let prefix = 'optometrist.tips.covd.'

            return {
                help: false,
                answers: false,
                dialog: false,

                tips: {
                    info: [
                        prefix + 'info.' + 1,
                        prefix + 'info.' + 2,
                        prefix + 'info.' + 3,
                        prefix + 'info.' + 4,
                        prefix + 'info.' + 5,
                        prefix + 'info.' + 6,
                    ],
                    instruction: [
                        prefix + 'instruction.' + 1,
                        prefix + 'instruction.' + 2
                    ],
                    procedure: [
                        prefix + 'procedure.' + 1,
                        prefix + 'procedure.' + 2,
                        prefix + 'procedure.' + 3
                    ]
                }
            }
        },
        mounted() {

        },
        methods: {
            updateQuestionnaire(questionnaire) {
                this.$emit('update-questionnaire', questionnaire)

                this.dialog = false
            }
        },
        computed: {
            ok() {
                return this.points.covd !== -1 && this.points.covd <= 20
            },
            extra() {
                return this.$state.current.name === 'app.optometrist.extra.details'
            }
        }
    }
</script>