<template lang="pug">
    .opti-search-recent-item.bg-expressive(@click="changeRoute")
        .opti-search-recent-item__icon
            span(:class="icon")
        .opti-search-recent-item__body
            .opti-search-recent-item__id {{item.id}}
            .opti-search-recent-item__name {{item.name}}
        .opti-search-recent-item__date {{item.date}}
</template>

<script>
export default {
    name: 'OptiSearchRecentItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            validator: p => ['task', 'patient', 'product'].includes(p)
        }
    },
    computed: {
        icon() {
            let types = {
                task: 'icon-eyeglasses',
                patient: 'icon-users',
                product: 'icon-handbag'
            }

            return types[this.type]
        }
    },
    methods: {
        changeRoute() {
            let types = {
                task: 'app.task.detail',
                patient: 'app.patient.detail',
                product: 'app.catalog.product'
            }

            this.$router.push({
                name: this.item.is_offer ? 'app.catalog.offer-temporary-task' : types[this.type],
                params: {
                    id: this.item.id
                }
            })

            this.$emit('close')
        }
    },
}
</script>

<style scoped lang="less">
    .opti-search-recent-item {
        position: relative;
        padding: 4px;
        margin: 4px;
        cursor: pointer;
        min-width: 400px;

        @media only screen and (max-width: 767px) {
            min-width: 80%;
        }

        &__icon {
            position: absolute;
            top: 0;
            left: 2px;
            bottom: 0;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 30px;
        }

        &__body {
            margin-left: 30px;
        }

        &__id {
            font-weight: bold;
        }

        &__name {

        }

        &__date {
            position: absolute;
            top: 0;
            right: 4px;
            font-size: 0.8em;
            color: gray;
        }
    }
</style>
