<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            form.form.form-horizontal(name="formRanking")
                .form-group
                    .col-xs-4.bold
                        .form-control-static {{'report.ranking.type.HEADING'|translate}}
                    .col-xs-4
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" v-model="search.type" name="type" value="date")
                                span
                                | {{'report.ranking.type.DATE'|translate}}
                    .col-xs-4
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" v-model="search.type" name="type" value="week")
                                span
                                | {{'report.ranking.type.WEEK'|translate}}

                .form-group
                    .col-xs-4.bold(style="padding-top:27px") {{'report.ranking.CURRENT'|translate}}
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label.star-required(for="current_from") {{'report.ranking.FROM'|translate}}
                            .col-xs-10
                                OptiDate#current_from(v-show="search.type === 'date'"
                                    :value="search.date.current.from" @update-date="d => search.date.current.from = d")

                                OptiSelectSnapshot(v-show="search.type === 'week'" type="from"
                                    :date="search.week.current.from" :opposite="search.week.current.to"
                                    @selected="d => search.week.current.from = d")

                                OptiValidate(f="current_from" :data="$v.search.date.current.from")
                                OptiValidate(f="current_from" :data="$v.search.week.current.from")

                        .form-group
                            label.col-xs-2.control-label.star-required(for="current_to") {{'report.ranking.TO'|translate}}
                            .col-xs-10
                                OptiDate#current_to(v-show="search.type === 'date'"
                                    :value="search.date.current.to" @update-date="d => search.date.current.to = d")

                                OptiSelectSnapshot(v-show="search.type === 'week'" type="to"
                                    :date="search.week.current.to" :opposite="search.week.current.from"
                                    @selected="d => search.week.current.to = d")

                                OptiValidate(f="current_to" :data="$v.search.date.current.to")
                                OptiValidate(f="current_to" :data="$v.search.week.current.to")

                .form-group
                    .col-xs-4.bold(style="padding-top:27px") {{'report.ranking.REFERENCE'|translate}}
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label.star-required(for="reference_from") {{'report.ranking.FROM'|translate}}
                            .col-xs-10
                                OptiDate#reference_from(v-show="search.type === 'date'"
                                    :value="search.date.reference.from" @update-date="d => search.date.reference.from = d")

                                OptiSelectSnapshot(v-show="search.type === 'week'" type="from"
                                    :date="search.week.reference.from" :opposite="search.week.reference.to"
                                    :year-before="true" @selected="d => search.week.reference.from = d")

                                OptiValidate(f="reference_from" :data="$v.search.date.reference.from")
                                OptiValidate(f="reference_from" :data="$v.search.week.reference.from")

                        .form-group
                            label.col-xs-2.control-label.star-required(for="reference_to") {{'report.ranking.TO'|translate}}
                            .col-xs-10
                                OptiDate#reference_to(v-show="search.type === 'date'"
                                    :value="search.date.reference.to" @update-date="d => search.date.reference.to = d")

                                OptiSelectSnapshot(v-show="search.type === 'week'" type="to"
                                    :date="search.week.reference.to" :opposite="search.week.reference.from"
                                    :year-before="true" @selected="d => search.week.reference.to = d")

                                OptiValidate(f="reference_to" :data="$v.search.date.reference.to")
                                OptiValidate(f="reference_to" :data="$v.search.week.reference.to")

                .form-group
                    .col-xs-8.col-xs-offset-4
                        .form-group
                            .col-xs-10.col-xs-offset-2
                                OptiButton(type="success" :disabled="$v.search.$invalid"
                                    @click.native="goGenerate") {{'report.ranking.DOWNLOAD'|translate}}
                                    template(#icon)
                                        i.fa.fa-download
</template>

<script>
    import {requiredIf} from 'vuelidate/lib/validators'
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectSnapshot from '@/js/app/vue/components/Select/OptiSelectSnapshot'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReportRanking',
        components: {
            OptiDate,
            OptiSelectSnapshot,
            OptiValidate,
            OptiButton
        },
        props: {

        },
        data() {
            return {
                search: {
                    type: 'date',
                    date: {
                        current: {
                            from: moment().startOf('month').format('Y-MM-DD'),
                            to: moment().endOf('month').format('Y-MM-DD')
                        },
                        reference: {
                            from: moment().add(-1, 'year').startOf('month').format('Y-MM-DD'),
                            to: moment().add(-1, 'year').endOf('month').format('Y-MM-DD')
                        }
                    },
                    week: {
                        current: {
                            from: {},
                            to: {}
                        },
                        reference: {
                            from: {},
                            to: {}
                        }
                    }
                }
            }
        },
        validations: {
            search: {
                date: {
                    current: {
                        from: {
                            required: requiredIf(function() { return this.search.type === 'date' })
                        },
                        to: {
                            required: requiredIf(function() { return this.search.type === 'date' })
                        }
                    },
                    reference: {
                        from: {
                            required: requiredIf(function() { return this.search.type === 'date' })
                        },
                        to: {
                            required: requiredIf(function() { return this.search.type === 'date' })
                        }
                    }
                },
                week: {
                    current: {
                        from: {
                            required: requiredIf(function() { return this.search.type === 'week' })
                        },
                        to: {
                            required: requiredIf(function() { return this.search.type === 'week' })
                        }
                    },
                    reference: {
                        from: {
                            required: requiredIf(function() { return this.search.type === 'week' })
                        },
                        to: {
                            required: requiredIf(function() { return this.search.type === 'week' })
                        }
                    }
                }
            }
        },
        methods: {
            goGenerate() {
                let filename = 'ranking/ranking_salonów_' + parseInt(Math.random() * 100000) + '.xls'

                DownloadFileHelper.post('reports/departments-ranking', filename, this.search)
            }
        }
    }
</script>