import {datadogRum} from '@datadog/browser-rum'

const globals = {}

class Logger {
    constructor() {
        let LOGGER = window.__env.logger

        if (LOGGER.enabled) {
            datadogRum.init({
                applicationId: LOGGER.appKey,
                clientToken: LOGGER.appTok,
                datacenter: 'us',
                sampleRate: LOGGER.sample,
                resourceSampleRate: LOGGER.resourceSample,
                trackInteractions: LOGGER.trackUI,
                env: LOGGER.env
            })

            this.setGlobal('usr', {
                id: 0,
                depId: 0
            })
            this.setGlobal('rout', {
                hash: '#'
            })

            this.enabled = true
        } else {
            this.enabled = false
        }
    }

    setGlobal(name, context) {
        if (this.enabled) {
            globals[name] = context
            datadogRum.setRumGlobalContext(globals)
        }
    }

    log(name, context) {
        if (this.enabled) {
            datadogRum.addUserAction(name, context)
        }
    }
}

const Instance = new Logger();
export default Instance
