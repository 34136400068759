<template lang="pug">
    .row
        .col-xs-12(style="padding:10px 30px")
            OptiButton(type="info" :disabled="disabled"
                @click.native="dialog.maddox = true") {{'optometrist.correction.MADDOX'|translate}}
                template(#icon)
                    i.fa.fa-eye

            MountingPortal(v-if="dialog.maddox" mountTo="#vue-modal" append)
                OptiOptometristsDetailsRefractionDialogScreeningTestMaddox(
                    :maddox="interview.maddox"
                    :result="results.maddox"

                    @confirmed="updateMaddox"
                    @cancelled="dialog.maddox = false")
        .col-xs-12
            OptiOptometristsDescriptionsMaddox(style="border-bottom:1px solid #aaa"
                :maddox="interview.maddox" :result="results.maddox" :no-result="true")

        .col-xs-12(style="padding:10px 30px")
            OptiButton.mr3(type="info" :disabled="disabled" @click.native="dialog.worth = true") {{'optometrist.correction.WORTH'|translate}}
                template(#icon)
                    i.fa.fa-eye

            MountingPortal(v-if="dialog.worth" mountTo="#vue-modal" append)
                OptiOptometristsDetailsRefractionDialogScreeningTestWorth(
                    :worth-id="interview.worth_id"

                    @confirmed="updateWorth"
                    @cancelled="dialog.worth = false")
            span ({{'prescription.RIGHT'|translate}}
            span.screening-circle.red(style="margin:0 2px")
            span RL, {{'prescription.LEFT'|translate}}
            span.screening-circle.green(style="margin:0 2px")
            span GL)
        .col-xs-12
            OptiOptometristsDescriptionsWorth(:id="interview.worth_id" :no-result="true")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiOptometristsDetailsRefractionDialogScreeningTestMaddox
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/ScreeningTests/OptiOptometristsDetailsRefractionDialogScreeningTestMaddox'
    import OptiOptometristsDescriptionsMaddox
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsMaddox'
    import OptiOptometristsDetailsRefractionDialogScreeningTestWorth
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/ScreeningTests/OptiOptometristsDetailsRefractionDialogScreeningTestWorth'
    import OptiOptometristsDescriptionsWorth
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsWorth'
    import {Maddox} from "@/js/app/vue/components/Optometrists/Helpers/Maddox";

    export default {
        name: 'OptiOptometristsDetailsRefractionScreeningTests',
        components: {
            OptiButton,
            OptiOptometristsDetailsRefractionDialogScreeningTestMaddox,
            OptiOptometristsDescriptionsMaddox,
            OptiOptometristsDetailsRefractionDialogScreeningTestWorth,
            OptiOptometristsDescriptionsWorth
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            results: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    maddox: false,
                    worth: false
                }
            }
        },
        mounted() {
            Maddox.compute(this.interview.maddox, this.results.maddox)
        },
        methods: {
            updateMaddox(data) {
                this.interview.maddox = data.maddox
                this.results.maddox = data.result

                this.$emit('update-interview', this.interview)

                this.dialog.maddox = false
            },
            updateWorth(worth_id) {
                this.interview.worth_id = worth_id

                this.$emit('update-interview', this.interview)

                this.dialog.worth = false
            }
        }
    }
</script>