import {ADD_ITEM, API, DELETE_ITEM, GET_ITEMS, SET_ITEMS} from '@/js/app/vue/api'

export default {
    async [GET_ITEMS]({commit, state}, product) {
        let {data} = await API.get('warehouse/ticket/storage')
        commit(SET_ITEMS, data)
    },

    async [ADD_ITEM]({commit, state}, product) {
        let {data} = await API.post('warehouse/ticket/storage/add', {
            product_id: product.id,
            product_name: product.name,
            barcode: product.barcode,
            price: product.price
        })
        commit(SET_ITEMS, data)
    },

    async [DELETE_ITEM]({commit, state}, product) {
        let {data} = await API.post('warehouse/ticket/storage/delete', {
            product_id: product.id
        })
        commit(SET_ITEMS, data)
    }
}
