<template lang="pug">
    #refractionOptometrist
        OptiOptometristsDetailsRefractionAutorefraktometr(
            :autorefraktometr="interview.autorefraktometr"
            :disabled="disabled"
            @update-autorefraktometr="updateAutorefraktometr")

        OptiOptometristsDetailsRefractionSurveys(
            :interview="interview"
            :previous="previous"
            :results="results"

            :previouslens="previouslens"
            :correction="correction"

            :active-cyl="activeCyl"
            :active-cyl-contact-lens="activeCylContactLens"

            @update-cyl="v => $emit('update-cyl', v)"
            @update-cyl-contact-lens="v => $emit('update-cyl-contact-lens', v)"

            :disabled="disabled"
            :disabled-survey="disabledSurvey"

            @update-interview="v => $emit('update-interview', v)")

        OptiOptometristsDetailsRefractionAmplitude(
            :interview="interview"
            :age="age"

            :disabled="disabled"

            @update-interview="v => $emit('update-interview', v)")
</template>

<script>
    import OptiOptometristsDetailsRefractionAutorefraktometr
        from "@/js/app/vue/components/Optometrists/Details/Refraction/OptiOptometristsDetailsRefractionAutorefraktometr";
    import OptiOptometristsDetailsRefractionSurveys
        from '@/js/app/vue/components/Optometrists/Details/Refraction/OptiOptometristsDetailsRefractionSurveys'
    import OptiOptometristsDetailsRefractionAmplitude
        from '@/js/app/vue/components/Optometrists/Details/Refraction/OptiOptometristsDetailsRefractionAmplitude'

    export default {
        name: 'OptiOptometristsDetailsRefraction',
        components: {
            OptiOptometristsDetailsRefractionAutorefraktometr,
            OptiOptometristsDetailsRefractionSurveys,
            OptiOptometristsDetailsRefractionAmplitude,
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            previous: {
                type: Object,
                required: true
            },
            results: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },

            activeCyl: {
                type: String,
                required: true
            },
            activeCylContactLens: {
                type: String,
                required: true
            },

            age: {
                type: Number,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            },
            disabledSurvey: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            updateAutorefraktometr(autorefraktometr) {
                let pd2 = Math.round(autorefraktometr.r_pd * 50) / 100

                this.interview.dist_r_pd = pd2
                this.interview.dist_l_pd = pd2

                if(this.interview.dist_r_pd >= 2) {
                    this.interview.near_r_pd = this.interview.dist_r_pd - 2
                }

                if(this.interview.dist_l_pd >= 2) {
                    this.interview.near_l_pd = this.interview.dist_l_pd - 2
                }

                this.interview.autorefraktometr = autorefraktometr

                this.$emit('update-interview', this.interview)
            }
        }
    }
</script>