<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .footer-buttons
                OptiButton(@click.native="show = !show") {{'tasks.offer.toggle.' + (show ? 'HIDE' : 'SHOW')|translate}}
                    template(#icon)
                        i.fa(:class="{'fa-angle-double-up': show, 'fa-angle-double-down': !show}")

                OptiCatalogOfferBtnMail(
                    :offer="offer"
                    :patient="patient")

                OptiCatalogOfferBtnPortraitsEdit(
                    :offer="offer"

                    @reload="load")

                OptiButtonHistory(data="offer" :id="offer.id")

                OptiButton(@click.native="dialog_compare = true") {{'tasks.offer.thickness.HEADING'|translate}}
                    template(#icon)
                        i.fa.fa-eye

                    MountingPortal(v-if="dialog_compare" mountTo="#vue-modal" append)
                        OptiTaskDetailsOfferDialogsCompare(
                            :products="products"
                            :rate-save="rateSave"

                            :rate="rate"

                            @rate-save="() => {load();$emit('rate-save')}"
                            @reload-offer="() => {load();$emit('rate-save')}"
                            @reload-history="$emit('reload-history')"
                            @closed="closed")

        .col-xs-12(v-if="!loading && show")
            OptiCatalogOfferHeader#opti-catalog-offer-header(
                :selected="offer.filters"
                :is-skipped="!!(offer.filters.questions.frames[4] && JSON.stringify(offer.filters.questions.frames[4].options.map(o => o.key)) === JSON.stringify(['sunglasses']))")

        .col-xs-12(v-if="!loading && show")
            .row.text-center
                .col-sm-6.pb
                    b.mr3 {{'patient.offer.table.COUNT_PRODUCTS'|translate}}
                    span {{offer.count_products}}

                .col-sm-6.pb
                    div(style="display:flex;justify-content:space-around")
                        OptiCatalogOfferFavorites(
                            :favorites="offer.favorites"
                            :selected="offer.products.map(p => p.id)"
                            :department-id="offer.department_id")

                        OptiRate(:header="true"
                            :rate="offer.rate"
                            :ref-type="1"
                            :ref-id="offer.id"
                            @reload="load")

        .col-xs-12(v-if="!loading" :class="{'col-sm-4': products.length < 4, 'col-sm-3': products.length === 4}" v-for="product in products" :key="product.id")
            OptiTaskDetailsOfferProduct(
                :product="product"
                :offer-temporary-task-id="offer_temporary_task_id"
                :rate-save="rateSave"

                :rate="rate"

                @selected="offer_temporary_task_id => $state.go('app.catalog.offer-temporary-task', {id: offer_temporary_task_id})"
                @rate-save="$emit('rate-save')"
                @reload="load")

            div(style="margin-bottom:15px" v-if="show && product.pivot.hoya_option_id")
                div {{'catalog.lenses.properties.heading.' + product.pivot.hoya_option.section|translate}}
                div(style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis"
                    :title="product.pivot.hoya_option.name") {{product.pivot.hoya_option.name}}

            a(v-if="show && product.pivot.photo" :href="'upload/offer/' + product.pivot.photo" target="_blank")
                img.img-thumbnail(style="margin-bottom:20px;width:100%" :src="'upload/offer/' + product.pivot.photo")

            .text-center.warn(style="margin-bottom:10px" v-if="show && product.pivot.choice_by_client")
                i.fa.fa-exclamation-circle.mr3
                span {{'catalog.offer.choice.byClient.HEADER'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferBtnMail from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnMail'
    import OptiCatalogOfferBtnPortraitsEdit
        from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnPortraitsEdit'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiCatalogOfferHeader from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeader'
    import OptiCatalogOfferFavorites
        from '@/js/app/vue/components/Catalog/Offer/Favorites/OptiCatalogOfferFavorites'
    import OptiRate from '@/js/app/vue/components/Rate/OptiRate'
    import OptiTaskDetailsOfferDialogsCompare
        from '@/js/app/vue/components/Tasks/Details/Offer/Dialogs/OptiTaskDetailsOfferDialogsCompare'
    import OptiTaskDetailsOfferProduct
        from '@/js/app/vue/components/Tasks/Details/Offer/OptiTaskDetailsOfferProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsOffer',
        components: {
            OptiDimmer,
            OptiButton,
            OptiCatalogOfferBtnMail,
            OptiCatalogOfferBtnPortraitsEdit,
            OptiButtonHistory,
            OptiCatalogOfferHeader,
            OptiCatalogOfferFavorites,
            OptiRate,
            OptiTaskDetailsOfferDialogsCompare,
            OptiTaskDetailsOfferProduct
        },
        props: {
            offer: {
                type: Object,
                required: true
            },
            task: {
                type: Object,
                required: true
            },
            rateSave: {
                required: true
            },

            rate: {
                type: Object | Array,
                required: true
            }
        },
        data() {
            return {
                offer_temporary_task_id: parseInt(this.$state.params.id),
                patient: _.cloneDeep(this.task.patient),

                dialog_compare: false,
                show: false,
                loading: false
            }
        },
        methods: {
            load() {
                this.loading = true

                API.get('get-offer-from-temporary-task-id/' + this.$state.params.id).then(res => {
                    this.$emit('update-offer', res.data)
                }).finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 2500)
                }).catch(() => {
                    this.$notify.error('catalog.offer.notify.error.LOAD')
                })
            },
            closed(refresh) {
                if(refresh) {
                    this.$emit('refresh')
                }

                this.dialog_compare = false
            }
        },
        computed: {
            products() {
                let products = this.offer.products

                products.forEach(product => {
                    (['lenses', 'lenses_to_compare']).forEach(type => {
                        product.pivot.tasks.forEach(task => {
                            let value = task.offer_temporary_task_id === this.offer_temporary_task_id ?
                                this.task['rightLens' + (type === 'lenses_to_compare' ? '2' : '')].partName : task.offer_task[type].name

                            this.$set(task.offer_task[type], 'translated', value && value !== 'soczewka' ? value :
                                this.$filters.translate('tasks.offer.lenses.none.' + type.toUpperCase()))
                            this.$set(task.offer_task[type], 'none', !value || value === 'soczewka')
                        })
                    })
                })

                return products
            }
        }
    }
</script>

<style lang="less" scoped>
    .footer-buttons {
        display: flex;
        justify-content: space-between;

        > div, button {
            margin-bottom: 10px;
        }

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    #opti-catalog-offer-header /deep/ .table {
        margin-bottom: 0 !important;
    }

    .pb {
        padding-bottom: 5px;
    }
</style>