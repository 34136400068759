<template lang="pug">
    .row
        .col-md-10.col-md-offset-1
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'sale.reclamation.change.HEADING'|translate}}

                .panel-body
                    form.form.form-horizontal(name="formDecisionChange")
                        .form-group
                            label.col-sm-4.control-label.star-required(for="accepted_change") {{'sale.reclamation.admin.ACCEPTED'|translate}}
                            .col-sm-8
                                select.form-control#accepted_change(v-model="form.accepted" name="accepted")
                                    option(v-for="option in options" :value="option.id") {{option.name|translate}}
                        .form-group(style="margin-bottom:0")
                            .col-sm-8.col-sm-offset-4
                                OptiButton(type="success" :title="'sale.reclamation.change.action.BTN'|translate"
                                    :disabled="this.decisionId === this.form.accepted"
                                    text="sale.reclamation.change.action" @confirmed="$emit('go-change-decision', form)"
                                    ) {{'sale.reclamation.change.action.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-save
</template>

<script>
    import {EnumReclamationDecision} from '@/js/app/vue/enums/Enums'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReclamationSaleDetailsChangeDecision',
        components: {
            OptiButton
        },
        props: {
            decisionId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                options: [],
                form: {
                    id: this.$state.params.id,
                    accepted: this.decisionId
                }
            }
        },
        mounted() {
            EnumReclamationDecision.simpleItems().forEach(decision => {
                if([3, 5].includes(decision.id)) {
                    this.options.push(decision)
                }
            })
        },
        methods: {

        }
    }
</script>
