<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'tasks.logs.lenses.type.LL'|translate}}

                .table-responsive
                    table.table.table-striped
                        thead
                            tr
                                th #
                                th {{'tasks.logs.STATUS'|translate}}
                                th {{'tasks.logs.DESCRIPTION'|translate}}
                                th {{'tasks.logs.DATE'|translate}}
                                th {{'tasks.logs.USER'|translate}}
                        tbody
                            tr(v-for="(log, key) in logs.ll" :class="{'opacity-background': !log.user_id}")
                                td {{logs.ll.length - key}}
                                td {{log.status}}
                                td(v-html="log.description")
                                td {{log.created}}
                                td {{log.user}}

                            tr(v-show="!logs.ll.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'tasks.logs.lenses.type.RL'|translate}}

                .table-responsive
                    table.table.table-striped
                        thead
                            tr
                                th #
                                th {{'tasks.logs.STATUS'|translate}}
                                th {{'tasks.logs.DESCRIPTION'|translate}}
                                th {{'tasks.logs.DATE'|translate}}
                                th {{'tasks.logs.USER'|translate}}
                        tbody
                            tr(v-for="(log, key) in logs.rl" :class="{'opacity-background': !log.user_id}")
                                td {{logs.rl.length - key}}
                                td {{log.status}}
                                td(v-html="log.description")
                                td {{log.created}}
                                td {{log.user}}

                            tr(v-show="!logs.rl.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsLogsLenses',
        components: {
            OptiDimmer
        },
        props: {
            logs: {
                type: Object,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
