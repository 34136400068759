<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'user.training.breadcrumb.TRAININGS'|translate}}

            .label.label-danger.pull-right(v-show="count.all" :title="'user.training.users.COUNTTOOLTIP'|translate")
                span {{count.without_cancelled}}
                span(style="margin-left:3px" v-show="count.without_cancelled !== count.all") ({{count.all}})

        .row(v-show="!loading")
            .col-xs-12.training(v-for="(training, index) in trainings" :key="training.id")
                .media-box(:class="{'bg-separator': index % 2}")
                    .media-box-body(:class="{'opacity': training.hide}")
                        a.link.header.mr3(:href="$state.href('app.user.training.details', {id: training.id})" target="_blank"
                            ) {{training.name}} \#{{training.id}}

                        .warn(style="display:inline-block" v-show="training.is_cancelled") {{'user.training.status.CANCELLED'|translate}}

                        .text-center.warn(style="padding:10px 0"
                            v-show="!training.document && training.status === 3 && !training.employee_status")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'user.training.notify.NODOC'|translate}}

                        .row(v-show="!training.hide")
                            .col-sm-6
                                div
                                    label {{'user.training.table.TYPE'|translate}}
                                    div {{training.type_name}}
                                div
                                    label {{'user.training.table.date.DATE'|translate}}
                                    div {{training.date}}
                                div
                                    label {{'user.training.table.date.hours.HOURS'|translate}}
                                    div {{training.hours}}
                                div
                                    label {{'user.training.table.STATUS'|translate}}
                                    div(:class="{'warn': training.status === 4}") {{training.status_name|translate}}
                                div
                                    label {{'user.training.users.STATE'|translate}}
                                    OptiEnum(style="display:block" :class="{'warn': [1, 2].includes(training.employee_status)}"
                                        enum="TrainingUserStatus" :id="training.employee_status")
                                div(v-show="training.employee_reason_of_cancelled")
                                    label {{'user.training.users.REASONOFCANCELLED'|translate}}
                                    .warn {{training.employee_reason_of_cancelled}}
                            .col-sm-6
                                div(v-show="training.links.length")
                                    label {{'user.training.DOWNLOADING'|translate}}
                                    div(v-for="link in training.links")
                                        a.link(@click="openDownloadLink(link)")
                                            i.fa.fa-paperclip.mr3
                                            span {{link.name}}
                                div(v-if="$uac.permission('user.documents_opinions') && training.document")
                                    label
                                        OptiEnum(enum="UserDocumentType" :id="9")
                                    div
                                        a.link(:href="'user_documents/' + training.document|upload" target="_blank")
                                            i.fa.fa-paperclip.mr3
                                            span {{'user.training.DOWNLOAD'|translate}}

                    .btn-toggle(v-show="training.is_cancelled" @click="training.hide = !training.hide")
                        i.fa.mr3(:class="{'fa-chevron-down': training.hide, 'fa-chevron-up': !training.hide}")
                        span(v-show="training.hide") {{'user.training.users.toggle.SHOW'|translate}}
                        span(v-show="!training.hide") {{'user.training.users.toggle.HIDE'|translate}}

            .col-xs-12.text-center.text-muted(style="padding:15px" v-if="!trainings.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'user.training.notify.NONE'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {DownloadingHelper} from '@/js/app/vue/components/Downloading/DownloadingHelper'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersEditTrainings',
        components: {
            OptiEnum,
            OptiDimmer
        },
        props: {
            userId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                trainings: [],
                count: {
                    without_cancelled: 0,
                    all: 0
                },

                loading: true
            }
        },
        mounted() {
            API.get('trainings/user/' + this.userId).then(res => {
                this.trainings = res.data

                this.setCount()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('user.training.notify.error.LIST')
            })
        },
        methods: {
            setCount() {
                this.trainings.forEach(training => {
                    if(training.status !== 4 && training.employee_status !== 2) {
                        training.is_cancelled = false
                        this.$set(training, 'hide', false)

                        ++this.count.without_cancelled
                    } else {
                        training.is_cancelled = true
                        this.$set(training, 'hide', true)
                    }

                    ++this.count.all
                })
            },
            openDownloadLink(download) {
                DownloadingHelper.openUri(download)
            }
        }
    }
</script>

<style lang="less" scoped>
    .header {
        padding-bottom: 5px;
        font-size: 18px;
    }

    label {
        margin: 0;
    }

    .training {
        &:not(:last-child) > .media-box {
            border-bottom: 1px solid #000;
        }

        > .media-box {
            padding: 10px;
            position: relative;

            > .media-box-body.opacity {
                opacity: .35;
            }

            > .btn-toggle {
                position: absolute;
                top: 0;
                right: 0;
                padding: 3px 10px;
                background: #232735;
                color: whitesmoke;
                cursor: pointer;
                font-size: 12px;
            }
        }
    }
</style>