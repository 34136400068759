<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-supplier.form-horizontal(name="formSupplier" @submit.prevent="add")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'suppliers.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="supplier.name")
                        OptiValidate(f="name" :data="$v.supplier.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="fullName") {{'suppliers.FULLNAME'|translate}}
                    .col-sm-8
                        input#fullName.form-control(name="fullName" v-model="supplier.fullName")
                        OptiValidate(f="fullName" :data="$v.supplier.fullName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="country") {{'suppliers.COUNTRY'|translate}}
                    .col-sm-8
                        OptiSelectCountry(
                            :country="supplier.country"

                            @selected="loadCountry")
                        OptiValidate(f="country" :data="$v.supplier.countryId")
                .form-group
                    label.col-sm-4.control-label.star-required(for="city") {{'suppliers.CITY'|translate}}
                    .col-sm-8
                        OptiSelectCity#city(v-show="supplier.country.id === 171"
                            :city="supplier.city"

                            @selected="c => supplier.city = c")
                        input#city.form-control(v-if="supplier.country.id !== 171" name="city" v-model="supplier.city")
                        OptiValidate(f="city" :data="$v.supplier.city")
                .form-group
                    label.col-sm-4.control-label.star-required(for="zipCode") {{'suppliers.POSTALCODE'|translate}}
                    .col-sm-8
                        input#zipCode.form-control(name="zipCode" v-model="supplier.zipCode")
                        OptiValidate(f="zipCode" :data="$v.supplier.zipCode")
                .form-group
                    label.col-sm-4.control-label.star-required(for="street") {{'suppliers.STREET'|translate}}
                    .col-sm-8
                        input#street.form-control(name="street" v-model="supplier.street" required)
                        OptiValidate(f="street" :data="$v.supplier.street")
                .form-group
                    label.col-sm-4.control-label.star-required(for="contactPerson") {{'suppliers.CONTACT_PERSON'|translate}}
                    .col-sm-8
                        input#contactPerson.form-control(name="contactPerson" v-model="supplier.contactPerson")
                        OptiValidate(f="contactPerson" :data="$v.supplier.contactPerson")
                .form-group
                    label.col-sm-4.control-label.star-required(for="contactPhone") {{'suppliers.PHONE'|translate}}
                    .col-sm-8
                        OptiCellPhoneInput(
                            :country="supplier.contactPhoneCountry"
                            :phone="supplier.contactPhone"

                            @country="c => supplier.contactPhoneCountry = c"
                            @phone="p => supplier.contactPhone = p")
                        OptiValidate(f="contactPhone" :data="$v.supplier.contactPhone")
                .form-group
                    label.col-sm-4.control-label.star-required(for="contactEmail") {{'suppliers.EMAIL'|translate}}
                    .col-sm-8
                        input#contactEmail.form-control(type="email" name="contactEmail" v-model="supplier.contactEmail" )
                        OptiValidate(f="contactEmail" :data="$v.supplier.contactEmail")
                .form-group
                    label.col-sm-4.control-label.star-required(for="clientId") {{'suppliers.CLIENT_ID'|translate}}
                    .col-sm-8
                        input#clientId.form-control(name="clientId" v-model="supplier.clientId")
                        OptiValidate(f="clientId" :data="$v.supplier.clientId")
                .form-group
                    label.col-sm-4.control-label.star-required(for="ourContactPerson") {{'suppliers.our.PERSON'|translate}}
                    .col-sm-8
                        input#ourContactPerson.form-control(name="ourContactPerson" v-model="supplier.ourContactPerson")
                        OptiValidate(f="ourContactPerson" :data="$v.supplier.ourContactPerson")
                .form-group
                    label.col-sm-4.control-label.star-required(for="ourContactPhone") {{'suppliers.our.PHONE'|translate}}
                    .col-sm-8
                        OptiCellPhoneInput(
                            :country="supplier.ourContactPhoneCountry"
                            :phone="supplier.ourContactPhone"

                            @country="c => supplier.ourContactPhoneCountry = c"
                            @phone="p => supplier.ourContactPhone = p")
                        OptiValidate(f="ourContactPhone" :data="$v.supplier.ourContactPhone")
                .form-group
                    label.col-sm-4.control-label.star-required(for="ourContactEmail") {{'suppliers.our.EMAIL'|translate}}
                    .col-sm-8
                        input#ourContactEmail.form-control(type="email" name="ourContactEmail" v-model="supplier.ourContactEmail")
                        OptiValidate(f="ourContactEmail" :data="$v.supplier.ourContactEmail")
                .form-group
                    label.col-sm-4.control-label.star-required(for="homepage") {{'suppliers.HOMEPAGE'|translate}}
                    .col-sm-8
                        input#homepage.form-control(type="url" name="homepage" v-model="supplier.homepage" required)
                        OptiValidate(f="homepage" :data="$v.supplier.homepage")
                .form-group
                    label.col-sm-4.control-label.star-required(for="NIP") {{'suppliers.NIP'|translate}}
                    .col-sm-8
                        input#NIP.form-control(name="NIP" v-model="supplier.NIP" required)
                        OptiValidate(f="NIP" :data="$v.supplier.NIP")
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="supplierLens" v-model="supplier.supplierLens")
                                    span.fa.fa-check
                            span {{'suppliers.supplier.LENS'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="supplierContacts" v-model="supplier.supplierContacts")
                                    span.fa.fa-check
                            span {{'suppliers.supplier.CONTACTS'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="$v.supplier.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import validations from './validations'

    import OptiSelectCountry from '@/js/app/vue/components/Select/OptiSelectCountry'
    import OptiSelectCity from '@/js/app/vue/components/Select/OptiSelectCity'
    import OptiCellPhoneInput from '@/js/app/vue/components/Inputs/OptiCellPhoneInput'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSuppliersAdd',
        components: {
            OptiSelectCountry,
            OptiSelectCity,
            OptiCellPhoneInput,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                supplier: {
                    name: '',
                    fullName: '',
                    countryId: 171,
                    city: '',
                    zipCode: '',
                    street: '',
                    contactPerson: '',
                    contactPhone: '',
                    contactEmail: '',
                    clientId: '',
                    ourContactPerson: '',
                    ourContactPhone: '',
                    ourContactEmail: '',
                    homepage: '',
                    NIP: '',
                    supplierLens: '',
                    supplierContacts: '',
                    country: {
                        id: 171,
                        pl: 'Polska',
                        phoneCode: 48
                    },
                    contactPhoneCountry: {
                        id: 171,
                        pl: 'Polska',
                        phoneCode: 48
                    },
                    ourContactPhoneCountry: {
                        id: 171,
                        pl: 'Polska',
                        phoneCode: 48
                    }
                },

                loading: false
            }
        },
        validations() {
            return validations
        },

        methods: {
            loadCountry(country) {
                this.supplier.country = country
                this.supplier.countryId = country.id
            },

            add() {
                this.loading = true

                API.post('suppliers', this.supplier).then(() => {
                    this.$state.go('app.suppliers.list')

                    this.$notify.success('suppliers.notify.ADDED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('suppliers.notify.error.ADDED')
                })
            }
        }
    }
</script>