<template lang="pug">
    .col-xs-12
        .table-responsive(style="overflow-x:visible")
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tbody
                    tr.separate-top.separate-bottom
                        td(style="padding:0" colspan="2")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-striped.table-condensed.table-separate
                                    thead
                                        tr
                                            th(style="text-align:center" colspan="2")
                                                span.mr3 {{'tasks.FRAME'|translate}} -
                                                OptiEnum(enum="TaskFrameState" :id="task.frame.state")
                                    tbody
                                        tr
                                            th {{'tasks.frame.NAME'|translate}}
                                            td
                                                OptiProduct(v-if="task.product.id" :product="task.product") {{task.frame.partName}}
                                                span(v-else) {{task.frame.partName}}
                                        tr
                                            th {{'tasks.frame.PRODUCENT'|translate}}
                                            td {{task.frame.manufacturer}}
                                        tr(v-if="task.productOverwrite.id")
                                            th.warn {{'tasks.frame.OTHERSHAPE'|translate}}
                                            td
                                                OptiProduct.warn(:product="task.productOverwrite") {{task.productOverwrite.name}}
                        td(style="padding:0")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-striped.table-condensed.table-separate
                                    tbody
                                        tr
                                            td.text-center(style="border-top:0" colspan="2") {{task.frame.statusName|translate}}
                                        tr
                                            th {{'tasks.frame.ID'|translate}}
                                            td
                                                OptiProduct(v-if="task.product.id" :product="task.product") {{task.frame.productId}}
                                                span(v-show="!task.product.id") {{task.frame.productId}}
                                        tr
                                            th {{'tasks.frame.TYPE'|translate}}
                                            td {{task.frame.typeName|translate}}
                        td.white(nowrap style="padding:0;vertical-align:middle") {{task.frame.price|currency}}

                    tr.separate-top
                        th
                        th.text-center(colspan="2")
                            b {{'tasks.LENSES'|translate}}
                            span(style="margin-left:3px" v-show="task.packId")
                                a.link.mr3(style="font-style:italic"
                                    :href="$state.href('app.packs.list', {search: ['id:=:' + task.packId]})" target="_blank") ({{task.pack.pack_name1}})
                                span(style="padding-left:5px" v-show="task.rLensPriceGroup.deliveryTime") {{task.rLensPriceGroup.deliveryTime|day}}
                        td.white

                    tr(v-show="task.manufacturerCustomId")
                        th {{'tasks.MANUFACTURERCUSTOMID'|translate}}
                        td.text-center(colspan="2")
                            a.link(@click="openHoyaPage()") {{task.manufacturerCustomId}}
                        td.white
                    tr(v-show="task.manufacturerParam1")
                        th iDentifier
                        td.text-center(colspan="2") {{task.manufacturerParam1}}
                        td.white
                    tr
                        th {{'tasks.lenses.STATUS'|translate}}
                        td(:colspan="task.packId ? 2 : 1")
                            div {{task.rightLens.stateName|translate}}
                            div {{task.rightLens.statusName|translate}}
                        td(v-show="!task.packId")
                            div {{task.leftLens.stateName|translate}}
                            div {{task.leftLens.statusName|translate}}
                        td.white.middle
                            .text-center(v-if="permission.turn_back && [1, 2].includes(task.status) && !isOffer")
                                OptiButton(@click.native="change_dialog = true") {{'tasks.admin.change_status.HEADER'|translate}}

                                MountingPortal(v-if="change_dialog" mountTo="#vue-modal" append)
                                    OptiTaskDetailsSummaryFrameLensPaymentFrameChange(
                                        :task="task"

                                        @statuses-were-updated="$emit('summary-was-updated')"
                                        @closed="change_dialog = false")
                    tr(v-show="!task.packId && task.pack.pack_name1")
                        th {{'tasks.lenses.ID'|translate}}
                        td {{task.pack.pack_name1}}
                        td {{task.pack.pack_name1}}
                        th.white
                    tr(v-show="task.rightLens.partName || task.leftLens.partName")
                        th {{'tasks.lenses.NAME'|translate}}
                        td(:colspan="task.packId || (task.rightLens.partName === task.leftLens.partName) ? 2 : 1")
                            small
                                a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.rLensPriceGroup.categoryId"
                                    :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                    target="_blank") {{task.rightLens.partName}}
                                span(v-else) {{task.rightLens.partName}}
                        td(v-show="!(task.packId || (task.rightLens.partName === task.leftLens.partName))")
                            small
                                a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.lLensPriceGroup.categoryId"
                                    :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.lLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                    target="_blank") {{task.leftLens.partName}}
                                span(v-else) {{task.leftLens.partName}}
                        td.white
                    tr(v-show="task.pictograms.length")
                        td.text-center(style="padding:0" colspan="3")
                            OptiPictograms(:pack-details="task.pictograms")
                        td.white
                    tr(v-show="!task.packId && (task.rightLens.type || task.leftLens.type)")
                        th {{'tasks.lenses.TYPE'|translate}}
                        td(:colspan="task.packId || (task.rightLens.type === task.leftLens.type) ? 2 : 1") {{task.rightLens.typeName|translate}}
                        td(v-show="!task.packId && !(task.rightLens.type === task.leftLens.type)") {{task.leftLens.typeName|translate}}
                        td.white
                    tr
                        th(style="width:15%")
                            small {{'tasks.MINFI'|translate}}
                        td(style="vertical-align:middle;width:40%") {{task.rightLens.minFi|mm}}
                        td(style="vertical-align:middle") {{task.leftLens.minFi|mm}}
                        td.white
                    tr(v-for="service in task.services" v-show="service.hoya_option_id")
                        th
                        td.text-center(colspan="2") {{service.name}}
                        td.white(nowrap) {{service.pivot.price|currency}}
                    tr.separate-bottom
                        th {{'tasks.lenses.PRICE'|translate}}
                        td
                            span(v-show="!task.packId")  {{task.rightLens.price|currency}}
                        td
                            span(v-show="!task.packId") {{task.leftLens.price|currency}}
                        td.white(nowrap) {{task.rightLens.price + task.leftLens.price|currency}}

                    tr(v-show="task.lensColor.id")
                        th {{'tasks.pack.saturation.SATURATION'|translate}}
                        td.text-center(colspan="2") {{task.lensColor.translatedName}} - {{saturationTranslate[task.lensColor.category]}} - {{task.coveringSaturation}}
                        td.white {{task.coveringPrice|currency}}
                    tr(v-show="task.assemblyId")
                        th {{'services.SERVICE'|translate}}
                        td.text-center(colspan="2") {{task.taskAssembly.name}}
                        td.white {{task.taskAssembly.price|currency}}
                    tr(v-for="(service, key) in task.services" v-show="service.id !== 1 && !service.hoya_option_id")
                        th
                            span(v-show="task.services.findIndex(s => !s.hoya_option_id) === key && key") {{'dictionary.taskextra.breadcrumb.EXTRA'|translate}}
                        td.text-center(colspan="2")
                            span {{service.name}}
                            span(style="margin-left:3px" v-show="service.id === 2 && service.pivot.description") ({{service.pivot.description}})
                        td.white(nowrap :class="{'star-info': !service.us}") {{service.pivot.price|currency}}
                    tr(v-show="taskExtraUsInfo")
                        th
                        td.color-blue(colspan="2")
                            i.star-info.mr3
                            span(style="font-weight:400") - {{'tasks.registry.notify.TASKEXTRAUS'|translate}}

                    tr.separate-top
                        td.text-center(style="vertical-align:middle" colspan="2")
                            div(style="margin-top:7px" v-show="!isOffer")
                                label.btn.btn-purple.btn-task-print.mr3(for="printMain" :title="'tasks.AGREEMENT'|translate"
                                    :disabled="!task.expected")
                                    i.fa.fa-print
                                    div {{'tasks.AGREEMENT'|translate}}
                                OptiButtonPrint.hide(element-id="printMain" url="task/print" :id="task.id")

                                label.btn.btn-purple.btn-task-print.mr3(for="printServiceContract" :title="'tasks.US'|translate"
                                    :disabled="!task.expected" v-show="task.hasServiceContract")
                                    i.fa.fa-print
                                    div {{'tasks.US'|translate}}
                                OptiButtonPrint.hide(element-id="printServiceContract" url="task/print/service-contract" :id="task.id")

                                label.btn.btn-purple.btn-task-print.mr3(for="printAcceptanceOfRisk" :title="'tasks.RISK'|translate"
                                    v-show="task.frame.state === 2")
                                    i.fa.fa-print.mr3
                                    div {{'tasks.RISK'|translate}}
                                OptiButtonPrint.hide(element-id="printAcceptanceOfRisk" url="task/print/acceptance-of-risk" :id="task.id")

                                label.btn.btn-purple.btn-task-print.mr3(for="printSatisfaction" :title="'tasks.SATISFACTION'|translate"
                                    v-if="task.satisfaction_guarantee" :disabled="!task.expected")
                                    i.fa.fa-print
                                    div GS
                                OptiButtonPrint.hide(element-id="printSatisfaction" url="task/print/satisfaction-guarantee" :id="task.id")

                                label.btn.btn-purple.btn-task-print.mr3(for="printSatisfactionGuaranteeRegulations" :title="'tasks.SATISFACTIONREGULATION'|translate"
                                    v-if="task.satisfaction_guarantee" :disabled="!task.expected")
                                    i.fa.fa-print
                                    div GSR
                                OptiButtonPrint.hide(element-id="printSatisfactionGuaranteeRegulations" url="task/print/satisfaction-guarantee-regulations" :id="task.id")

                                label.btn.btn-purple.btn-task-print(style="width:85px" for="printControl" :title="'tasks.CONTROL'|translate"
                                    :disabled="!task.expected" v-show="permission.turn_back")
                                    i.fa.fa-print
                                    div {{'tasks.CONTROL'|translate}}
                                OptiButtonPrint.hide(element-id="printControl" url="task/print/control" :id="task.id")

                                label.btn.btn-purple.btn-task-print(style="margin-left:30px" for="opti-button-sms")
                                    i.fa.fa-phone
                                    div SMS
                                OptiButtonSms.hide(:patient="task.patient" :tags="{type: 5, ref_id: task.id, firstName: task.firstName}")

                            .bold.warn(v-show="!task.expected && !isOffer") {{'tasks.notify.error.DATEEXPECTED'|translate}}

                            .form-group(style="margin-top:10px")
                                .col-xs-12(v-if="task.satisfaction_guarantee")
                                    label
                                        .checkbox.c-checkbox.checkbox-primary.inline
                                            label
                                                input(type="checkbox" disabled name="satisfaction_guarantee"
                                                    v-model="task.satisfaction_guarantee")
                                                span.fa.fa-check
                                        span {{'tasks.SATISFACTION'|translate}}
                                    //jak się zmienia id to w TasksSaveController.php:1041 także podmieć
                                .col-xs-12
                                    .row
                                        label.col-xs-4.form-control-static.text-right(for="marketingAction") {{'tasks.left.PROMOTION'|translate}}
                                        .col-xs-8
                                            select#marketingAction.form-control.text-expressive(name="promotion"
                                                v-if="!block && !task.fromServiceContract.id"
                                                v-model="task.marketingActionId" @change="$emit('summary-was-updated')")
                                                option(v-for="p in promotions" :value="p.id") {{p.name}}
                                            span.form-control.text-left.bold.text-expressive(disabled v-else) {{task.marketing.name || 'Brak'}}
                                .col-xs-12(style="text-align:left;margin-top:10px;margin-bottom:-20px" v-show="task.marketingErrors.length")
                                    .alert.alert-danger
                                        div
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'tasks.marketing.NOTALLOWED'|translate}}
                                        ul
                                            li(v-for="e in task.marketingErrors") {{e}}

                                .col-xs-12(style="text-align:left;margin-top:10px" v-show="task.marketing.description")
                                    .alert.alert-info
                                        i.fa.fa-gift.mr3
                                        span {{task.marketing.description}}

                        td.white(style="padding:0")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-presenter-bold.table-striped.table-condensed.first-no-border-top
                                    tbody
                                        //tr musi mieć v-ifa dla klasy first-no-border-top
                                        tr(v-if="task.registry.pricingWithoutDiscount")
                                            th {{'tasks.registry.TOTAL'|translate}}
                                        tr(v-if="task.registry.discountType !== 'none'")
                                            th(style="text-align:right" v-show="task.registry.discountType === 'amount'"
                                                ) {{'tasks.registry.DISCOUNT'|translate}} ({{task.registry.discountPercent|number(2)}}%)
                                            th(style="text-align:right" v-show="task.registry.discountType === 'percent'"
                                                ) {{'tasks.registry.DISCOUNT'|translate}} ({{task.registry.discountValue|number(2)}}%)
                                        tr(v-if="task.registry.discountType !== 'none'")
                                            th {{'tasks.registry.PRICING'|translate}}
                                        tr(v-if="task.registry.hasServiceContract")
                                            th
                                                span.mr3 US
                                                i.fa.fa-check
                                        tr(v-if="task.registry.amountToPay")
                                            th {{'tasks.registry.AMOUNTTOPAY'|translate}}
                                        tr(v-if="notice.account")
                                            th
                                                OptiReceiptNotice(:data="notice.account.all" :deposit="true")
                                        tr
                                            th {{'tasks.registry.ACCOUNT'|translate}}
                                        tr(v-if="notice.rest")
                                            th
                                                OptiReceiptNotice(:data="notice.rest.all" :rest="true")
                                        tr
                                            th {{'tasks.registry.RESTTOPAY'|translate}}
                        td.white(style="padding:0;text-align:left")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-presenter-bold.table-striped.table-condensed.first-no-border-top
                                    tbody
                                        //tr musi mieć v-ifa dla klasy first-no-border-top
                                        tr(v-if="task.registry.pricingWithoutDiscount")
                                            td(nowrap) {{task.registry.pricingWithoutDiscount|currency}}
                                        tr(v-if="task.registry.discountType !== 'none'")
                                            td(nowrap :class="{'star-info': discountPercentWithShipping}") {{task.registry.discountAmount|currency}}
                                        tr(v-if="task.registry.discountType !== 'none'")
                                            td(nowrap :class="{'warn': task.registry.pricing < 0}") {{task.registry.pricing|currency}}
                                        tr(v-if="task.registry.hasServiceContract")
                                            td(nowrap :class="{'warn': task.registry.serviceContractPrice < 0}") {{task.registry.serviceContractPrice|currency}}
                                        tr(v-if="task.registry.amountToPay")
                                            td(nowrap :class="{'warn': task.registry.amountToPay < 0}") {{task.registry.amountToPay|currency}}
                                        tr(v-if="notice.account")
                                            td
                                                OptiReceiptNoticeValue(:data="notice.account.all")
                                        tr
                                            td(nowrap) {{task.registry.account|currency}}
                                        tr(v-if="notice.rest")
                                            td
                                                OptiReceiptNoticeValue(:data="notice.rest.all")
                                        tr
                                            td(nowrap :class="{'warn': task.registry.restToPay < 0}") {{task.registry.restToPay|currency}}

                    tr.text-right(v-show="discountPercentWithShipping")
                        td.color-blue.white(style="font-weight:400" colspan="100")
                            i.star-info.mr3
                            span - {{'tasks.registry.notify.DISCOUNT_PERCENT_WITH_SHIPPING'|translate}}
</template>

<script>
    import {HoyaHelper} from '@/js/app/vue/helpers/HoyaHelper'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiButtonSms from '@/js/app/vue/components/Button/OptiButtonSms'
    import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsSummaryFrameLensPaymentFrameChange from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryFrameLensPaymentFrameChange'

    export default {
        name: 'OptiTaskDetailsSummaryFrameLensPayment',
        components: {
            OptiButton,
            OptiEnum,
            OptiProduct,
            OptiPictograms,
            OptiButtonPrint,
            OptiButtonSms,
            OptiReceiptNotice,
            OptiReceiptNoticeValue,
            OptiTaskDetailsSummaryFrameLensPaymentFrameChange
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            saturationTranslate: {
                type: Object,
                required: true
            },
            taskExtraUsInfo: {
                type: Boolean,
                required: true
            },
            discountPercentWithShipping: {
                type: Boolean,
                required: true
            },
            promotions: {
                type: Array,
                required: true
            },
            notice: {
                type: Object,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                permission: {
                    turn_back: this.$uac.permission('tasks.turn_back')
                },
                change_dialog: false
            }
        },
        methods: {
            openHoyaPage() {
                HoyaHelper.openHoyaPage(this.task.manufacturerCustomId)
            }
        }
    }
</script>

<style lang="less" scoped>
    table.first-no-border-top {
        tr:first-child > th,
        tr:first-child > td {
            border-top: 0 !important;
        }
    }

    .btn-task-print {
        margin-top: 3px;
        width: 75px;
    }
</style>