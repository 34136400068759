import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class PathServiceClass {
    constructor() {

    }

    setY(path) {
        _.forEach(path, (tier, index) => {
            _.forEach(tier, dot => {
                dot.y = 35 + (index * 52)
            })
        })
    }
    load(type) {
        return new Promise((resolve, reject) => {
            API.get('optometrist/interview/path/' + type).then(res => {
                _.forEach(res.data, p => {
                    _.forEach(p, element => {
                        if(Object.keys(element).includes('links')) {
                            _.forEach(element.links, (link, link_key) => {
                                _.forEach(res.data, obj => {
                                    _.forEach(obj, (o, key) => {
                                        if(key === link) {
                                            element.links[link_key] = o
                                        }
                                    })
                                })
                            })
                        }
                    })
                })

                let _return = res.data.reverse()
                this.setY(_return)

                resolve(_return)
            }).catch(() => {
                Notify.error('optometrist.video.notify.error.PATH')

                reject()
            })
        })
    }

    findPoint(path, dot_id) {
        let found = false

        path.forEach(tier => {
            Object.values(tier).forEach(dot => {
                if(dot.id === dot_id) {
                    found = dot
                }
            })
        })

        return found
    }

    setupFor(path, side) {
        let rVisus1   = this.findPoint(path, 'rVisus1'),
            lVisus1   = this.findPoint(path, 'lVisus1'),
            lVisus3   = this.findPoint(path, 'lVisus3'),
            rVisus3   = this.findPoint(path, 'rVisus3'),
            binVisus1 = this.findPoint(path, 'binVisus1'),
            lCZ1      = this.findPoint(path, 'lCZ1'),
            rCZ1      = this.findPoint(path, 'rCZ1'),
            lEE1      = this.findPoint(path, 'lEE1'),
            rEE1      = this.findPoint(path, 'rEE1')

        if(side === 'right') {
            rVisus3.links = []
            lVisus3.links = []
            lVisus3.links.push(binVisus1)

            lVisus1.links = [lCZ1, lEE1, rVisus3]
            rVisus1.links = [rCZ1, rEE1]
        } else if(side === 'left') {
            lVisus3.links = []
            rVisus3.links = []
            rVisus3.links.push(binVisus1)

            lVisus1.links = [lCZ1, lEE1]
            rVisus1.links = [rCZ1, rEE1, lVisus3]
        }
    }

    setupForAcuityNoCorrection(path, side) {
        let visus1 = this.findPoint(path, 'visus1'),
            visus2 = this.findPoint(path, 'visus2')

        if(side === 'left') {
            visus1.x = '70%'
            visus2.x = '30%'
        }
    }

    setupForAcuityWithCorrection(path, side) {
        let visus1 = this.findPoint(path, 'visus1'),
            visus2 = this.findPoint(path, 'visus2'),
            cz1    = this.findPoint(path, 'CZ1'),
            cz2    = this.findPoint(path, 'CZ2'),
            ee1    = this.findPoint(path, 'EE1'),
            ee2    = this.findPoint(path, 'EE2')

        if(side === 'left') {
            visus1.x = '70%'
            cz1.x    = '65%'
            ee1.x    = '75%'
            visus2.x = '30%'
            cz2.x    = '25%'
            ee2.x    = '35%'
        }
    }

    alternative(base) {
        //TODO: opracować by wyciągnąć to z configa a nie hardcodować

        let replace = {
            rSaltVisus: 'rSalt',
            rSalt: 'rSaltVisus',
            lSaltVisus: 'lSalt',
            lSalt: 'lSaltVisus'
        }

        if(Object.keys(replace).includes(base)) {
            return replace[base]
        }

        let matches = {
            CZ: 'EE',
            EE: 'CZ',
            Salt0: 'Clock',
            Clock: 'Salt0',
            balance1: 'balance2',
            balance2: 'balance3',
            balance3: 'balance1'
        }

        return base.replace(/(CZ|EE|Salt0|Clock|balance[1-3])/g, match => {
            return matches[match]
        })
    }
}

export const PathService = new PathServiceClass()