<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            .table-responsive(v-if="logs")
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th
                            th {{'tasks.prices.PRODUCTNAME'|translate}}
                            th(v-if="is_admin") ZHC
                            th {{'tasks.prices.WHOLESALEPRICE'|translate}}
                            th {{'tasks.prices.RETAILPRICE'|translate}}

                    tbody
                        tr(v-if="logs.frame && !logs.frame.id")
                            td.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{logs.frame.name}}

                        tr(v-if="logs.frame &&  logs.frame.id > 0")
                            td.bold {{'tasks.frame.NAME'|translate}}
                            td
                                OptiProduct(:product="logs.frame.product") {{logs.frame.name}}
                            td(v-if="is_admin") {{logs.frame.zhc|currency}}
                            td
                                span(v-if="logs.frame.state !== 2 && logs.frame.state !== 4") {{logs.frame.wholesale|currency}}
                                span(v-else) {{0|currency}}
                            td
                                span(v-if="logs.frame.state !== 2 && logs.frame.state !== 4") {{logs.frame.retail|currency}}
                                span(v-else) {{0|currency}}

                        tr(v-if="logs.lenses  && !logs.lenses.id")
                            td.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{logs.lenses.name}}

                        template(v-if="logs.lenses && logs.lenses.id > 0")
                            tr
                                th(:rowspan="logs.span") {{'prescription.RIGHT'|translate}}
                                td
                                    a.link(:title="'default.title.LENSES'|translate"
                                        :href="$state.href('app.catalog.category', {id: logs.lenses.category_id, isOffer: isOffer ? 1 : 0, taskId: $state.params.id})"
                                        target="_blank") {{logs.lenses.name}}
                                td(v-if="is_admin") {{logs.lenses.zhc|currency}}
                                td {{logs.lenses.wholesale|currency}}
                                td {{logs.lenses.retail|currency}}

                            tr(v-for="option in logs.options" v-if="logs.options")
                                td {{option.name}}
                                td(v-if="is_admin") {{option.zhc|currency}}
                                td {{option.wholesale|currency}}
                                td {{option.retail|currency}}

                            tr
                                th(:rowspan="logs.span") {{'prescription.LEFT'|translate}}
                                td
                                    a.link(:title="'default.title.LENSES'|translate"
                                        :href="$state.href('app.catalog.category', {id: logs.lenses.category_id, isOffer: isOffer ? 1 : 0, taskId: $state.params.id})"
                                        target="_blank") {{logs.lenses.name}}
                                td(v-if="is_admin") {{logs.lenses.zhc|currency}}
                                td {{logs.lenses.wholesale|currency}}
                                td {{logs.lenses.retail|currency}}

                            tr(v-for="option in logs.options" v-if="logs.options")
                                td {{option.name}}
                                td(v-if="is_admin") {{option.zhc|currency}}
                                td {{option.wholesale|currency}}
                                td {{option.retail|currency}}

                            tr(v-for="task_extra in logs.task_extras")
                                td(colspan="2") {{task_extra.name}}
                                td(v-if="is_admin") {{task_extra.zhc|currency}}
                                td {{task_extra.wholesale|currency}}
                                td {{task_extra.retail|currency}}

                        tr(v-if="logs.sum")
                            td
                            td.bold(style="text-align:right") Suma
                            td.bold(v-if="is_admin") {{logs.sum.zhc|currency}}
                            td.bold {{logs.sum.wholesale|currency}}
                            td.bold {{logs.sum.retail|currency}}
        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsLogsPrices',
        components: {
            OptiProduct,
            OptiDimmer
        },
        props: {
            isOffer: {
                type: Boolean,
                required: true
            },

            logs: {
                type: Object,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                is_admin: this.$uac.user.activeRole.admin
            }
        }
    }
</script>