<template lang="pug">
	div
		.row(v-if="!loading")
			.col-sm-8.col-sm-offset-2
				.panel-grid
					.panel.panel-primary(style="margin-bottom:5px")
						.panel-heading
							i.fa.fa-navicon.mr3
							span {{'inventory.details.HEADING'|translate}}
							.pull-right \#{{inventory.id}}
						.row
							.col-sm-6 {{'inventory.details.USER'|translate}}
							.col-sm-6
								span {{inventory.user.firstName}} {{inventory.user.lastName}} ({{inventory.department.name}})
						.row
							.col-sm-6 {{'inventory.details.CREATEDAT'|translate}}
							.col-sm-6 {{inventory.created_at}}
						.row
							.col-sm-6 {{'inventory.details.STATUS'|translate}}
							.col-sm-6 {{inventory.status_name|translate}}
						.row
							.col-sm-6 {{'inventory.details.DIFF'|translate}}
							.col-sm-6(:class="{'warn': inventory.diff}") {{inventory.diff}}
						.row
							.col-sm-6 {{'inventory.details.PRODUCTS'|translate}}
							.col-sm-6
								div(v-for="data in inventory.summary")
									span.bold.mr3 {{data.name}}
									span {{data.stock}} szt.
									span(style="font-style:italic;padding-left:5px") {{data.price|currency}}

		#btn-group-patient-detail.row(v-show="!loading")
			.col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
				OptiButton(type="danger" style="margin-right:10px" v-show="!inventory.status"
					text="inventory.cancel.confirm" @confirmed="goCancel") {{'inventory.cancel.BTN'|translate}}
					template(#icon)
						i.fa.fa-remove

				OptiButtonDropdown(type="info") {{'inventory.print.BTN'|translate}}
					template(#icon)
						i.fa.fa-print
					template(#menu)
						li
							a(:href="'api/warehouse/inventory/pdf/' + inventory.id + '/0'" target="_blank") PDF
						li
							a(:href="'api/warehouse/inventory/pdf/' + inventory.id + '/1'" target="_blank") PDF {{'inventory.print.DIFF'|translate}}
						li
							a(@click="goPrintCSV(0)") CSV
						li
							a(@click="goPrintCSV(1)") CSV {{'inventory.print.DIFF'|translate}}
			.col-md-4.text-center(v-show="!inventory.status")
				OptiButton(type="info" @click.native="dialog = true") {{'inventory.dialog.BTN'|translate}}
					template(#icon)
						i.fa.fa-dropbox

					MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
						OptiInventoryDetailsDialog(
							:inventory-id="inventory.id"
							@closed="reload")
			.col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg(v-show="!inventory.status")
				OptiButton(type="success" text="inventory.finish.confirm" @confirmed="goFinish") {{'inventory.finish.BTN'|translate}}
					template(#icon)
						i.fa.fa-check

		.row(style="padding-top:10px" v-if="!loading")
			.col-xs-12
				.panel.panel-primary
					.panel-heading
						i.fa.fa-navicon.mr3
						span {{'inventory.details.SUMMARY'|translate}}

					.table-responsive
						table.table.middle
							thead
								tr
									th {{'inventory.summary.CATEGORYNAME'|translate}}
									th {{'inventory.summary.STATE'|translate}}
									th {{'inventory.summary.INVENTORY'|translate}}
									th {{'inventory.summary.DIFF'|translate}}
									th
							tbody
								tr(v-for="data in inventory.details" :class="data.color"
									v-show="data.checked" @click="toggleCategory(data.is_category_id)")
									td
										OptiProduct(:product="data.product" v-if="data.product") {{data.product.name}}
										span(v-show="!data.product") {{data.name}}
									td {{data.state}}
									td {{data.inventory}}
									td {{data.diff}}
									td.text-center.bg-expressive
										i.fa.fa-2x.fa-check-circle.text-success(v-show="!data.diff_rows")
										i.fa.fa-2x.fa-minus-circle.warn(v-show="data.diff_rows")
								tr(v-show="!inventory.details.length")
									td.text-center(colspan="100")
										i.fa.fa-exclamation-circle.mr3
										span {{'default.table.EMPTY'|translate}}

		.row
			.col-xs-12
				OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiInventoryDetailsDialog
        from '@/js/app/vue/components/Warehouse/Inventories/Dialogs/OptiInventoryDetailsDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonDropdown from '@/js/app/vue/components/Button/OptiButtonDropdown'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryDetails',
        components: {
            OptiInventoryDetailsDialog,
            OptiButton,
            OptiButtonDropdown,
            OptiProduct,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                inventory: {},

                dialog: false,
                loading: true
            }
        },
        mounted() {
            this.reload()
        },
        methods: {
            reload() {
                this.loading = true
                this.dialog = false

                API.get('warehouse/inventory/' + this.$state.params.id).then(res => {
                    this.inventory = res.data

                    if(!this.inventory.status && !moment(this.inventory.created_at).isSame(moment(), 'day') &&
                        !confirm(this.$filters.translate('inventory.continue.MESSAGE'))) {
                            this.$state.go('app.warehouse.inventories.table')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('inventory.notify.error.DETAILS')
                })
            },

            goFinish() {
                this.loading = true

                API.get('warehouse/inventory/finish/' + this.$state.params.id).then(res => {
                    this.inventory = res.data

                    this.$notify.success('inventory.notify.FINISH')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('inventory.notify.error.FINISH')
                })
            },
            goCancel() {
                this.loading = true

                API.get('warehouse/inventory/cancel/' + this.$state.params.id).then(res => {
                    this.inventory = res.data

                    this.$notify.success('inventory.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('inventory.notify.error.CANCEL')
                })
            },
            goPrintCSV(filter) {
	            this.loading = true

                API.get('warehouse/inventory/csv/' + this.$state.params.id + '/' + filter).then(res => {
                    window.open(window.__env.uploadHost + '/inventory/' + res.data + '.csv')
                }).finally(() => {
					this.loading = false
                }).catch(() => {
                    this.$notify.error('inventory.notify.error.PRINT')
                })
            },
            toggleCategory(category_id) {
                if(typeof category_id !== 'undefined') {
                    this.inventory.details.forEach(detail => {
                        if(detail.category_id === category_id) {
                            detail.checked = !detail.checked
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .table-responsive .bg-info {
        cursor: pointer;
    }
</style>
