<template lang="pug">
    div
        .panel.panel-primary(style="position:relative")
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'register.return.deposit.CREATERETURNDEPOSIT'|translate}}

            .panel-body
                .row
                    .col-sm-4.col-xs-12
                        .form-group
                            label.col-xs-12.control-label(for="task") {{'register.return.deposit.TASKID'|translate}}
                            .col-xs-12(style="padding-bottom:10px")
                                OptiSelectTask#task(:init-result="{}" @selected="t => task = t")

                    .col-sm-4.col-xs-6(v-if="task.id")
                        .form-group
                            label {{'register.return.deposit.STATUS'|translate}}
                            .form-control-static {{task.statusName|translate}}
                        .form-group
                            label {{'register.return.deposit.DEPARTMENT'|translate}}
                            .form-control-static {{task.departmentName}}
                        .form-group
                            label {{'register.return.deposit.VALUEDEPOSIT'|translate}}
                            .form-control-static {{task.registry.account|currency}}
                        .form-group
                            label {{'register.return.deposit.PATIENT'|translate}}
                            .form-control-static
                                a.link(:href="$state.href('app.patient.detail', {id: task.patientId})" target="_blank"
                                    :title="'register.return.deposit.PATIENTTITLE'|translate") {{task.patientName}}

                    .col-sm-4.col-xs-6(v-if="task.id")
                        .form-group
                            label {{'register.return.deposit.PRESCRIPTIONID'|translate}}
                            .form-control-static
                                OptiButtonPrescription(v-show="task.prescriptionId > 0" :id="task.prescriptionId")
                        .form-group
                            label {{'register.return.deposit.EMPLOYEE'|translate}}
                            .form-control-static {{task.employee.firstName}} {{task.employee.lastName}}
                        .form-group
                            label {{'register.return.deposit.PAYMENTTYPE'|translate}}
                            .form-control-static {{task.registry.accountPaymentTypeName|translate}}

            OptiDimmer(:active="loading")

        .row(v-if="task.id && !(!task.status && [2, 3].includes(task.highest_status))")
            .col-xs-12
                .alert.alert-warning
                    i.fa.fa-exclamation-circle.mr3
                    span {{'register.return.deposit.notify.WARNINGSTATUS'|translate}}

        .row(v-if="task.id && !task.status && [2, 3].includes(task.highest_status)")
            .col-xs-12.text-right
                OptiButton(type="success" :disabled="!task.account || task.account === '0' || loading"
                    text="register.return.deposit.confirm" @confirmed="goReturnDeposit"
                    ) {{'register.return.deposit.confirm.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-undo
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectTask from '@/js/app/vue/components/Select/OptiSelectTask'
    import OptiButtonPrescription from '@/js/app/vue/components/Button/OptiButtonPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRegisterReturnTaskAccount',
        components: {
            OptiSelectTask,
            OptiButtonPrescription,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                task: {},

                loading: false
            }
        },
        methods: {
            goReturnDeposit() {
                this.loading = true

                API.post('register/return/deposit/add', {
                    id: this.task.id
                }).then(res => {
                    if(res.data.status) {
                        this.$state.go('app.register.current')

                        this.$notify.success('register.return.deposit.notify.SAVERETURNDEPOSIT')
                    } else {
                        this.$notify.error('register.return.deposit.notify.error.SAVERETURNDEPOSIT')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('register.return.deposit.notify.error.SAVERETURNDEPOSIT')
                })
            }
        }
    }
</script>