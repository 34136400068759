<template lang="pug">
    div(style="padding:8px 8px 3px")
        span(v-for="pack_detail in packDetails")
            img(:title="pack_detail.name" :src="'img/task/pack/' + pack_detail.id + '.png'"
                @click="open = pack_detail")

        MountingPortal(v-if="open.id" mountTo="#vue-modal" append)
            OptiPictogramsDialog(
                :department-id="$uac.user.activeDepartmentId"
                :pack-detail="open"

                @closed="open = {}")
</template>

<script>
    import OptiPictogramsDialog from '@/js/app/vue/components/Pictograms/Dialogs/OptiPictogramsDialog'

    export default {
        name: 'OptiPictograms',
        components: {
            OptiPictogramsDialog
        },
        props: {
            packDetails: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                open: {}
            }
        }
    }
</script>

<style lang="less" scoped>
    img {
        cursor: pointer;
        width: 60px;
        margin: 0 5px 5px 0;
    }
</style>