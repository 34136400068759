<template lang="pug">
    .row
        .col-xs-12(v-if="office")
            table.table.table-striped.middle.text-center.table-schedule.table-schedule-day(style="margin-bottom:100px"
                :class="{'table-schedule-medical': office.type}")
                caption
                    span {{office.name}}
                    .pull-right {{office.department_name}}
                thead
                    tr
                        th {{'schedule.directive.view.header.HOURS'|translate}}
                        th.text-left(v-for="hour in hours" :colspan="colspan") {{hour}}

                tbody
                    tr(v-for="optometrist in office.optometrists" :key="optometrist.id")
                        td(:class="optometrist.class" @click="goToUser(optometrist.id)")
                            div(style="display:flex;align-items:center;justify-content:space-between")
                                OptiReportOffersRate(:user-id="optometrist.id")

                                div
                                    div {{optometrist.name.split(' ')[0]}}
                                    div {{optometrist.name.split(' ')[1]}}
                                    small(style="display:block") {{optometrist.department_name}}

                                div(v-show="!office.type") ({{office.turnovers[optometrist.id] ? office.turnovers[optometrist.id] : 0}})

                        td(v-for="event in optometrist.data" :colspan="event.colspan" :class="event.event_class")
                            .event-working-hours-11 {{event.type_name}}
                                .left(:style="event.style_left")
                                .right(:style="event.style_right")

                    tr(v-show="!office.optometrists.length")
                        td.schedule-td-opacity.text-center(style="cursor:default" colspan="100")
                            i.fa.fa-info-circle(style="padding-right:3px") <!-- TODO: później do usunięcia ten padding -->
                            span {{'patient.schedule.notify.EMPTY'|translate}}

                    tr.tr-patients
                        td.schedule-td-opacity {{'patient.schedule.patients.HEADING'|translate}}

                        td(v-for="visit in office.visits" :colspan="visit.colspan" :class="visit.class" @click="editCell(visit)")
                            div(v-if="isCurrentCell(visit)")
                                .current-cell-backdrop
                                .current-cell-content
                                    .circle(style="margin-top:10px" v-show="visit.patient" :class="{'circle-contact-lens': visit.type, 'flash animated infinite': !visit.confirmed}")

                            .circle(v-show="visit.patient" :class="{'circle-contact-lens': visit.type, 'flash animated infinite': !visit.confirmed}")

                    tr
                        td(style="border:none")

                        td(NOWRAP v-for="visit in office.visits" :colspan="visit.colspan" style="position:relative;border:none;transform:translateX(-50%);font-size:.8em")
                            div(style="position:absolute;top:0;right:0;transform-origin:top right;transform:rotate(-35deg)")
                                span.mr3 {{visit.patient_first_name}} {{visit.patient_last_name}}
                                span(v-if="visit.patient && visit.patient.id") ({{visit.patient.id}})

                    tr(v-if="current")
                        td.editor-cell(colspan="100")
                            OptiPatientsScheduleDayDetails(
                                v-if="!edit"
                                :office-type="office.type"
                                :optometrists="office.optometrists"
                                :visit="current"
                                @update-visits="updateVisits"
                                @change-to-edit="changeToEdit"

                                @navi-patient="naviPatient"
                                @navi-time="naviTime"

                                @closed="editorClosed")

                            OptiPatientsScheduleDayEditor(
                                v-if="edit"
                                :office-id="office.id"
                                :office-type="office.type"
                                :optometrists="office.optometrists"
                                :visit="current"
                                @update-visits="updateVisits"

                                @navi-time="naviTime"

                                @closed="editorClosed")

</template>

<script>
    import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'
    import OptiPatientsScheduleDayDetails from '@/js/app/vue/components/Patients/Schedule/Day/OptiPatientsScheduleDayDetails'
    import OptiPatientsScheduleDayEditor from '@/js/app/vue/components/Patients/Schedule/Day/OptiPatientsScheduleDayEditor'

    export default {
        name: 'OptiPatientsScheduleDay',
        components: {
            OptiReportOffersRate,
            OptiPatientsScheduleDayDetails,
            OptiPatientsScheduleDayEditor
        },
        props: {
            office: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                colspan: 2,
                hours: [],

                edit: false,
                current: null
            }
        },
        mounted() {
            if(this.office.type) {
                this.colspan = 4
            }

            for(let i = 6; i < 24; ++i) {
                this.hours.push(i)
            }

            if(this.$state.params.visit_id) {
                this.office.visits.forEach(visit => {
                    if(this.$state.params.visit_id === visit.id) {
                        this.current = visit
                    }
                })
            }
        },
        methods: {
            isCurrentCell(visit) {
                return this.current === visit
            },
            editCell(visit) {
                if(this.current === visit) {
                    this.current = null
                } else {
                    this.edit = visit.patient === null
                    this.current = visit
                }
            },

            changeToEdit() {
                this.edit = true
            },
            editorClosed() {
                this.current = null
            },

            updateVisits(data) {
                this.office.visits = data.visits

                if(data.visit_id) {
                    data.visits.forEach(visit => {
                        if(visit.id === data.visit_id) {
                            this.current = visit
                        }
                    })
                } else {
                    this.current = null
                }

                this.edit = false
            },

            goToUser(user_id) {
                this.$state.go('app.user.edit', { id: user_id })
            },

            naviPatient(navi) {
                this.current = this.office.visits[navi]

                this.edit = false
            },
            naviTime(navi) {
                for(let i = 0; i < this.office.visits.length; ++i) {
                    let visit = this.office.visits[i]

                    if(this.current === visit) {
                        this.current = this.office.visits[i + navi]

                        break
                    }
                }

                this.edit = this.current.patient === null
            }
        }
    }
</script>