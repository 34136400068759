<template lang="pug">
    .row
        .col-md-8.col-md-offset-2
            form.form-horizontal
                .form-group
                    label.col-sm-5.control-label.star-required(for="file") {{'product.lp.FILE'|translate}}
                    .col-sm-7
                        OptiUpload#file(
                            prefix="product_lp"

                            :file="file"
                            :disabled="status === 'processing'"

                            @update-loading="l => loading.file = l"
                            @update-file="f => file = f")
                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButton(type="success" :disabled="!file || loading.main || loading.file || status === 'processing'"
                            @click.native="goUpload") {{'product.lp.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-sort-numeric-asc

        .col-md-8.col-md-offset-2(style="margin-top:20px" v-show="!loading.main")
            .panel.widget.bg-info(v-show="!status")
                .row.row-table
                    .col-xs-3.text-center.bg-info-dark.pv-lg
                        i.fa.fa-ban.fa-3x
                    .col-xs-9.pv-lg
                        .h4 {{'product.lp.status.NONE'|translate}}

            .panel.widget.bg-success(v-show="status === 'current'")
                .row.row-table
                    .col-xs-3.text-center.bg-success-dark.pv-lg
                        i.fa.fa-check.fa-3x
                    .col-xs-9.pv-lg
                        .h4 {{'product.lp.status.CURRENT'|translate}}
                        span {{'product.lp.status.NIGHT'|translate}}

            .panel.widget.bg-warning(v-show="status === 'processing'")
                .row.row-table
                    .col-xs-3.text-center.bg-warning-dark.pv-lg
                        i.fa.fa-cog.fa-spin.fa-3x
                    .col-xs-9.pv-lg
                        .h4 {{'product.lp.status.PROCESSING'|translate}}

            .panel.widget.bg-danger(v-show="status === 'error'")
                .row.row-table
                    .col-xs-3.text-center.bg-danger-dark.pv-lg
                        i.fa.fa-exclamation-circle.fa-3x
                    .col-xs-9.pv-lg
                        .h4 {{'product.lp.status.ERROR'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductLp',
        components: {
            OptiUpload,
            OptiButton,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                file: null,
                status: '',

                loading: {
                    main: true,
                    file: false
                }
            }
        },
        mounted() {
            API.get('warehouse/products/lp/check-files').then(res => {
                this.status = res.data
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('product.lp.notify.error.LOAD')
            })
        },
        methods: {
            goUpload() {
                this.loading.main = true

                API.post('warehouse/products/lp/go-upload', {
                    file: this.file
                }).then(res => {
                    this.file = null
                    this.status = res.data

                    this.$notify.success('product.lp.notify.QUEUE')
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('product.lp.notify.error.QUEUE')
                })
            }
        }
     }
</script>