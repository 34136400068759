<template lang="pug">
    OptiDialog
        template(#header)
            span(v-show="mode === 'report'")
                i.fa.fa-exclamation.mr3
                span {{'catalog.product.report.BTN'|translate}}

            span(v-show="mode === 'correct'")
                i.fa.fa-edit.mr3
                span {{'catalog.product.correct.CORRECT'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            form.form-horizontal(name="formCorrect")
                .form-group
                    label.col-sm-5.control-label(for="stock")
                        span.mr3 {{'catalog.product.correct.STOCK'|translate}}
                        i.fa.fa-dropbox
                    .col-sm-7
                        OptiNumber#stock.form-control(:header="'catalog.product.correct.STOCK'|translate" :minus="true"
                            v-model="form.stock" @confirmed="v => form.stock = v") {{form.stock}}
                .form-group
                    label.col-sm-5.control-label(for="order")
                        span.mr3 {{'catalog.product.correct.ORDERED'|translate}}
                        i.fa.fa-envelope
                    .col-sm-7
                        OptiNumber#stock.form-control(:header="'catalog.product.correct.ORDERED'|translate"
                            v-model="form.order" @confirmed="v => form.order = v") {{form.order}}
                .form-group
                    label.col-sm-5.control-label(for="expect")
                        span.mr3 {{'catalog.product.correct.EXPECTED'|translate}}
                        i.fa.fa-car
                    .col-sm-7
                        OptiNumber#stock.form-control(:header="'catalog.product.correct.EXPECTED'|translate"
                            v-model="form.expect" @confirmed="v => form.expect = v") {{form.expect}}
                .form-group
                    label.col-sm-5.control-label.star-required(for="reason") {{'catalog.product.correct.REASON'|translate}}
                    .col-sm-7
                        textarea#reason.form-control(name="reason" rows="3" v-model="form.reason")
                        OptiValidate(f="reason" :data="$v.form.reason")

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButtonSave(:disabled="loading || $v.form.$invalid" @click.native="save")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogProductReport',
        components: {
            OptiDialog,
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            mode: {
                type: String,
                required: true
            },

            product: {
                type: Object,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                form: {
                    product_id: this.product.id,
                    department_id: this.departmentId,

                    stock: this.product.depository.stock,
                    order: this.product.depository.ordered,
                    expect: this.product.depository.expected,
                    reason: ''
                },

                loading: false
            }
        },
        validations: {
            form: {
                reason: {
                    required
                }
            }
        },

        mounted() {

        },

        methods: {
            save() {
                this.loading = true

                if(this.mode === 'report') {
                    API.post('catalog/product/depository/report', this.form).then(() => {
                        this.$notify.success('catalog.product.report.notify.SAVE')

                        this.$emit('closed')
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('catalog.product.report.notify.error.SAVE')
                    });
                } else {
                    API.post('catalog/product/depository/correct', this.form).then(() => {
                        this.$notify.success('catalog.product.notify.SAVECORRECT')

                        this.$emit('reported')
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('catalog.product.notify.error.SAVECORRECT')
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 400px;
    }
</style>