<template lang="pug">
    div
        .col-xs-12(v-for="(service, key) in services" :key="service.id" v-show="!service.hoya_option_id")
            .form-group
                label.col-sm-3.control-label(:for="'service_' + key")
                    span(v-show="services.findIndex(s => !s.hoya_option_id) === key") {{'dictionary.taskextra.breadcrumb.EXTRA'|translate}}

                .col-sm-5
                    .form-control(v-if="service.hoya_option_id" disabled) {{service.name}}

                    div(v-else)
                        .input-group
                            select.form-control(:id="'service_' + key" :name="'service_' + key"
                                :disabled="key !== services.length - 1 || block || !permission"
                                v-model="service.id" @change="$emit('payment-task-extras-was-updated')")
                                option(v-for="extra in extras.filter(e => {return !e.hoya_option_id && (e.id === 2 || e.id === service.id || !services.map(s => s.id).includes(e.id))})"
                                    :value="extra.id") {{extra.name}}
                            .input-group-btn
                                .btn.btn-success(:title="'dictionary.taskextra.task.ADDTITLE'|translate"
                                    :disabled="service.id === 1 || block || !permission"
                                    v-if="key === services.length - 1"  @click="addService")
                                    i.fa.fa-plus.mr3
                                    span {{'dictionary.taskextra.task.ADD'|translate}}
                                .btn.btn-danger(:title="'dictionary.taskextra.task.DELETETITLE'|translate"
                                    :disabled="block || !permission" v-else @click="deleteService(key)")
                                    i.fa.fa-minus.mr3
                                    span {{'dictionary.taskextra.task.DELETE'|translate}}

                        input.form-control(style="margin-top:5px" :class="{'border-red': !service.pivot.description}"
                            :placeholder="'dictionary.taskextra.task.DESCRIPTION'|translate"
                            :id="'service_description_' + key" :name="'service_description_' + key"
                            v-show="service.id === 2" :disabled="block || !permission"
                            v-model.lazy="service.pivot.description" @change="$emit('payment-task-extras-was-updated')")

                .col-sm-3
                    OptiNumber.star-info(:header="service.name" :class="{'border-red': !service.pivot.price}"
                        v-if="[2, 8, 9].includes(service.id)" :disabled="block || !permission" :pending="pending" :float="true"
                        :value="service.pivot.price" @confirmed="p => updatePrice(service, p)"
                        ) {{service.pivot.price|currency}}
                    span.form-control.text-right(:class="{'star-info': !service.us && service.id !== 1}"
                        disabled v-else) {{service.pivot.price|currency}}

                .col-sm-1.text-input
                    i.fa.fa-plus

        .col-xs-12(v-show="taskExtraUsInfo")
            .form-group
                .col-xs-9.col-xs-offset-3.color-blue
                    i.star-info.mr3
                    span - {{'tasks.registry.notify.TASKEXTRAUS'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiTaskDetailsPaymentTaskExtras',
        components: {
            OptiNumber
        },
        props: {
            services: {
                type: Array,
                required: true
            },

            taskExtraUsInfo: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                extras: [],

                permission: this.$uac.permission('tasks.task_extra')
            }
        },
        mounted() {
            API.get('dictionary/task-extra/action').then(res => {
                this.extras = res.data
            }).catch(() => {
                this.$notify.error('dictionary.taskextra.notify.error.LOAD')
            })
        },
        methods: {
            addService() {
                this.services.push({
                    id: 1,
                    name: 'Brak',
                    price: 0,
                    pivot: {
                        price: 0,
                        description: ''
                    }
                })

                this.$emit('payment-task-extras-was-updated')
            },
            deleteService(key) {
                this.services.splice(key, 1)

                this.$emit('payment-task-extras-was-updated')
            },
            updatePrice(service, p) {
                service.pivot.price = p

                this.$emit('payment-task-extras-was-updated')
            }
        }
    }
</script>