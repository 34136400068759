<template lang="pug">
    span
        i.fa.fa-gear.fa-spin(v-if="loading")
        OptiRate(v-else
            :header="header"

            :rate="data"

            :ref-type="refType"
            :ref-id="refId"

            @reload="$emit('reload')")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiRate from '@/js/app/vue/components/Rate/OptiRate'

    export default {
        name: 'OptiRateWrapper',
        components: {
            OptiRate
        },
        props: {
            header: {
                type: Boolean,
                required: false,
                default: false
            },

            load: {
                type: Boolean,
                required: false,
                default: true
            },
            rate: {
                type: Object | Array,
                required: false,
                default: function() {
                    return {}
                }
            },

            refType: {
                type: Number,
                required: true
            },
            refId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                data: _.cloneDeep(this.rate),

                endpoints: {
                    2: 'tasks/temporary/rate'
                },

                loading: true
            }
        },
        mounted() {
            if(this.load) {
                API.get(this.endpoints[this.refType] + '/' + this.refId, API.id(Math.random())).then(res => {
                    this.data = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('rate.notify.error.LOAD')
                })
            } else {
                this.loading = false
            }
        }
    }
</script>