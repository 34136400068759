<template lang="pug">
    .row
        .col-sm-6(style="margin-top:5px;margin-bottom:5px")
            h4.page-header(style="margin-top:0px")
                span.mr3 {{'sidebar.settings.offers.TRANSLATIONS'|translate}}
                small.text-muted {{tab[active_tab]|translate}}
        .col-sm-6(style="margin-bottom:15px")
            OptiButton(@click.native="$state.go('app.settings-offer.report-difference')") {{'sidebar.settings.offers.DIFFERENCE'|translate}}
                template(#icon)
                    i.fa.fa-exclamation

            .pull-right
                OptiButtonHistory(v-if="['gender', 'shapes'].includes(active_tab)"
                    :endpoint="'settings/offer/translations/attributes/history/' + (active_tab === 'gender' ? 0 : 1)")

                OptiButtonHistory(v-if="active_tab === 'frames_colors'"
                    endpoint="settings/offer/translations/colors/history/0")

                OptiButtonHistory(v-if="active_tab === 'language_versions'"
                    endpoint="settings/offer/translations/language-versions/history")

        .col-xs-12
            ul.nav.nav-tabs.nav-justified.icons-header-tab
                li(:class="{'active': active_tab === 'gender'}")
                    a(:title="tab.gender|translate" @click="setActive('gender')")
                        i.visible-xs-inline.fa-lg.fa.fa-venus-mars.mr3
                        i.hidden-xs.fa.fa-venus-mars.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.gender|translate}}
                        span.badge(v-show="count.gender") {{count.gender}}

                li(:class="{'active': active_tab === 'shapes'}")
                    a(:title="tab.shapes|translate" @click="setActive('shapes')")
                        i.visible-xs-inline.fa-lg.fa.fa-crop.mr3
                        i.hidden-xs.fa.fa-crop.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.shapes|translate}}
                        span.badge(v-show="count.shapes") {{count.shapes}}

                li(:class="{'active': active_tab === 'frames_colors'}")
                    a(:title="tab.frames_colors|translate" @click="setActive('frames_colors')")
                        i.visible-xs-inline.fa.fa-paint-brush.mr3
                        i.hidden-xs.fa.fa-paint-brush.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.frames_colors|translate}}
                        span.badge(v-show="count.frames_colors") {{count.frames_colors}}

                li(:class="{'active': active_tab === 'language_versions'}")
                    a(:title="tab.language_versions|translate" @click="setActive('language_versions')")
                        i.visible-xs-inline.fa-lg.fa.fa-language.mr3
                        i.hidden-xs.fa.fa-language.mr3
                        span.visible-xs-inline.tab-title.mr3 {{tab.language_versions|translate}}
                        span.badge(v-show="count.language_versions") {{count.language_versions}}

            .tab-content
                OptiSettingsOfferTranslationsDetailsGenderShape.tab-pane(:class="{'active': active_tab === 'gender'}"
                    :type="0"

                    @update-count="c => count.gender = c"
                    @attribute-was-updated="forceRerenderLanguageVersions")

                OptiSettingsOfferTranslationsDetailsGenderShape.tab-pane(:class="{'active': active_tab === 'shapes'}"
                    :type="1"

                    @update-count="c => count.shapes = c"
                    @attribute-was-updated="forceRerenderLanguageVersions")

                OptiSettingsOfferTranslationsDetailsFramesColors.tab-pane(:class="{'active': active_tab === 'frames_colors'}"
                    @update-count="c => count.frames_colors = c"
                    @color-was-updated="forceRerenderLanguageVersions")

                OptiSettingsOfferTranslationsDetailsLanguageVersions.tab-pane(v-if="render_language_versions"
                    :class="{'active': active_tab === 'language_versions'}"
                    @update-count="c => count.language_versions = c")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiSettingsOfferTranslationsDetailsGenderShape
        from '@/js/app/vue/components/Settings/Offer/Translations/Details/OptiSettingsOfferTranslationsDetailsGenderShape'
    import OptiSettingsOfferTranslationsDetailsFramesColors
        from '@/js/app/vue/components/Settings/Offer/Translations/Details/OptiSettingsOfferTranslationsDetailsFramesColors'
    import OptiSettingsOfferTranslationsDetailsLanguageVersions
        from '@/js/app/vue/components/Settings/Offer/Translations/Details/OptiSettingsOfferTranslationsDetailsLanguageVersions'

    export default {
        name: 'OptiSettingsOfferTranslations',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiSettingsOfferTranslationsDetailsGenderShape,
            OptiSettingsOfferTranslationsDetailsFramesColors,
            OptiSettingsOfferTranslationsDetailsLanguageVersions
        },
        props: {
            initHash: {
                type: String,
                required: true
            }
        },
        data() {
            let prefix = 'settings.offer.translations.card.'

            return {
                active_tab: 'gender',

                tab: {
                    gender: prefix + 'GENDER',
                    shapes: prefix + 'SHAPES',
                    frames_colors: prefix + 'FRAMES_COLORS',
                    language_versions: prefix + 'LANGUAGE_VERSIONS'
                },

                count: {
                    gender: 0,
                    shapes: 0,
                    frames_colors: 0,
                    language_versions: 0
                },

                render_language_versions: true
            }
        },
        mounted() {
            this.setActive(this.initHash ? this.initHash : this.active_tab)
        },
        methods: {
            setActive(hash) {
                this.active_tab = hash

                this.$emit('breadcrumb-change-last', hash, this.tab[hash])
            },
            async forceRerenderLanguageVersions() {
                this.render_language_versions = false

                await this.$nextTick()

                this.render_language_versions = true
            }
        }
    }
</script>