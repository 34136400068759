<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-show="!loading.main")
			form#form-marketing.form-horizontal(name="formMarketing" @submit.prevent="addMarketing")
				.form-group
					label.col-sm-4.control-label.star-required(for="name") {{'marketing.NAME'|translate}}
					.col-sm-8
						input#name.form-control(name="name" v-model="marketing.name")
						OptiValidate(f="name" :data="$v.marketing.name")
				.form-group
					label.col-sm-4.control-label.star-required(for="description") {{'marketing.DESCRIPTION'|translate}}
					.col-sm-8
						textarea#desciption.form-control(name="description" v-model="marketing.description" rows="7")
						OptiValidate(f="description" :data="$v.marketing.description")
				.form-group
					label.col-sm-4.control-label.star-required(for="type") {{'marketing.TYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#reclamationtype(enum="MarketingActionType"
							:value="marketing.type" @update-enum="v => marketing.type = v")
						OptiValidate(f="type" :data="$v.marketing.type")
				.form-group
					.col-sm-8.col-sm-offset-4
						label
							.checkbox.c-checkbox.checkbox-primary.inline
								input(type="checkbox" name="period" v-model="marketing.period")
								span.fa.fa-check(style="margin-left:0px")
							span {{'marketing.PERIOD'|translate}}
				.form-group
					label.col-sm-4.control-label(for="from") {{'marketing.period.FROM'|translate}}
					.col-sm-8
						OptiDate#from(name="from" :value="marketing.from" :disabled="!marketing.period"
							@update-date="d => marketing.from = d")
						OptiValidate(f="from" :data="$v.marketing.from")
				.form-group
					label.col-sm-4.control-label(for="to") {{'marketing.period.TO'|translate}}
					.col-sm-8
						OptiDate#to(name="to" :value="marketing.to" :disabled="!marketing.period"
							@update-date="d => marketing.to = d")
						OptiValidate(f="to" :data="$v.marketing.to")
				.form-group
					.col-sm-offset-4.col-sm-8
						label
							.checkbox.c-checkbox.checkbox-primary.inline
								input(type="checkbox" name="active" v-model="marketing.active")
								span.fa.fa-check(style="margin-left:0px")
							span {{'marketing.ACTIVE'|translate}}
				.form-group
					label.col-sm-4.control-label {{'marketing.ATTACHMENTS'|translate}}
					.col-sm-8
						OptiButton(type="info" @click.native="addAttachment"

						) {{'marketing.ADDATTACHMENT'|translate}}
							template(#icon)
								i.fa.fa-plus
				.form-group(style="padding-bottom:10px")
					.col-sm-8.col-sm-offset-4
						OptiUpload#upload(
							v-for="(file, index) in marketing.uploads" :key="index"
							:id="'marketing-action-' + index"
							prefix="marketing_action"

							:file="file"

							@update-file="u => updateFile(index, u)"
							@update-loading="u => updateFileLoading(index, u)")
				.form-group
					.col-xs-12
						OptiCheckboxDepartment(
							:ids="selectedDepartmentIds"
							:all="true"
							@department-ids="updateDepartmentIds")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.marketing.list')"
						) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.marketing.$invalid")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loadingDrimmer")
</template>
<script>
	import {API} from '../../api'
	import {required, requiredIf} from 'vuelidate/lib/validators'
	import {unique} from '../../../../vue.validators'

	import OptiValidate from '../OptiValidate'
	import OptiDimmer from '../Blocks/OptiDimmer'
	import OptiButtonSave from '../Button/OptiButtonSave'
	import OptiCheckboxDepartment from '../Checkbox/OptiCheckboxDepartment'
	import OptiUpload from '../OptiUpload'
	import OptiButton from '../Button/OptiButton'
	import OptiDate from '../OptiDate'
	import OptiSelectEnum from '../Select/OptiSelectEnum'

	export default {
		name: 'OptiMarketingAdd',
		components: {
			OptiValidate,
			OptiDimmer,
			OptiButtonSave,
			OptiCheckboxDepartment,
			OptiUpload,
			OptiButton,
			OptiDate,
			OptiSelectEnum
		},
		data() {
			return {
				marketing: {
					name: '',
					description: '',
					type: 0,
					period: false,
					from: '',
					to: '',
					active: false,
					uploads: []
				},
				loadingDrimmer: false,
				loading: {
					upload: false
				},
				selectedDepartmentIds: [],
			}
		},
		validations() {
			return {
				marketing: {
					name: {
						required,
						unique: unique('marketing_actions', 'LABEL')
					},
					description: {
						required
					},
					type: {
						required
					},
					from: {
						required: requiredIf(marketing => marketing.period === true)
					},
					to: {
						required: requiredIf(marketing => marketing.period === true)
					}
				}
			}
		},
		methods: {
			addMarketing() {
				this.loading = true

				API.post('marketing/action', {
					name: this.marketing.name,
					description: this.marketing.description,
					type: this.marketing.type,
					active: this.marketing.active,
					from: this.marketing.from,
					to: this.marketing.to,
					department_ids: this.selectedDepartmentIds
				}).then(() => {
					this.$notify.success('marketing.info.ADDED')

					this.$state.go('app.marketing.list')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('marketing.notify.ERRORADDED')
				})
			},
			addAttachment() {
				this.marketing.uploads.push('[object Object]')
			},
			updateDepartmentIds(ids) {
				this.selectedDepartmentIds = ids
			},
			updateFileLoading(index, loading) {
				this.$set(this.loading.upload, index, loading)
			},
			updateFile(index, file) {
				console.log(file,index)
				if(file) {
					this.$set(this.marketing.uploads, index, file)
				} else {
					this.marketing.uploads.splice(index, 1)
					this.loading.upload.splice(index, 1)
				}
			}
		}
	}
</script>
