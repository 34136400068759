<template lang="pug">
    span {{text}}
</template>

<script>
    export default {
        name: 'OptiEnum',
        components: {},
        props: {
            enum: {
                type: String,
                required: true
            },
            id: {
                type: Number | String,
                required: true
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                data: null
            }
        },
        mounted() {
            this.loadEnum()

            if(this.disabled) {
                this.$el.setAttribute('disabled', 'disabled')
            }
        },
        methods: {
            async loadEnum() {
                let enums = await import('../enums/Enums')

                this.data = enums[`Enum${this.enum}`]
            }
        },
        computed: {
            text() {
                try {
                    return this.data ? this.data.memberByValue(this.id).translate() : ''
                } catch(e) {
                    return ''
                }
            }
        }
    }
</script>