<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form#form-role.form-horizontal.form-validate(name="formRole" @submit.prevent="addException")
                .form-group
                    label.col-sm-4.control-label.star-required(for="user") {{'role.exceptions.table.USER'|translate}}
                    .col-sm-8
                        OptiSelectEmployeeGroupByDepartment#user(mode="central"
                            :user="exception.user" @selected="u => exception.user = u")
                        OptiValidate(f="user" :data="$v.exception.user.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="action") {{'role.exceptions.table.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#action(enum="UserPermissionOverrideType"
                            :value="exception.type" @update-enum="t => exception.type = t")
                .form-group
                    label.col-sm-4.control-label.star-required(for="permission") {{'role.exceptions.table.PERMISSION'|translate}}
                    .col-sm-8
                        OptiSelectPermissions#permission(
                            :permission="exception.permission" @selected="p => exception.permission = p")
                        OptiValidate(f="permission" :data="$v.exception.permission.id")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.exception.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectEmployeeGroupByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectPermissions from '@/js/app/vue/components/Select/OptiSelectPermissions'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRoleException',
        components: {
            OptiSelectEmployeeGroupByDepartment,
            OptiSelectEnum,
            OptiSelectPermissions,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                exception: {
                    user: {
                        id: 0
                    },
                    type: 0,
                    permission: {
                        id: 0
                    }
                },

                loading: false
            }
        },
        validations: {
            exception: {
                user: {
                    id: {
                        noZero
                    }
                },
                permission: {
                    id: {
                        noZero
                    }
                }
            }
        },
        methods: {
            addException() {
                this.loading = true

                API.post('roles/exceptions/add', this.exception).then(() => {
                    this.$state.go('app.roles.exceptions')

                    this.$notify.success('role.exceptions.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('role.exceptions.notify.error.SAVE')
                })
            }
        }
    }
</script>