<template lang="pug">
    DatePicker(
        mode="time"
        v-model="time"
        :model-config="modelConfig"
        :minute-increment="30"
        :is24hr = "true"
        :color="dark ? 'orange' : 'blue'"
        :is-dark="dark")
        template(v-slot="{inputValue, inputEvents, togglePopover}")
            span.input-group.input-group-date
                input.form-control(:id="id" :placeholder="'GG:mm'"
                    :disabled="disabled" :value="inputValue" v-on="inputEvents")
                span.input-group-btn
                    button.btn.btn-default(type="button" :disabled="disabled" @click="togglePopover")
                        i.fa.fa-clock-o
</template>

<script>
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'

    export default {
        name: 'OptiTimePicker',
        components: { DatePicker },
        props:{
            id: {
                type: String,
                required: false,
                default: 'date-picker'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dark: !!this.$uac.user.dark,
                validHours: {
                    min: 8,
                    max: 22
                },
                modelConfig: {
                    type: 'string',
                    mask: 'HH:mm'
                }
            }
        },
        computed: {
            time: {
                get() {
                    return this.value
                },
                set(time) {
                    this.$emit('update-time', time ? time : '')
                }
            }
        }
    }
</script>

<style scoped>

</style>