<template lang="pug">
    .panel.widget(:class="{'bg-danger': state.status.id === 1, 'bg-warning': state.status.id === 2}" v-show="state.status.id > 0")
        .row.row-table
            .col-xs-3.text-center.pv-lg(:class="{'bg-danger-dark': state.status.id === 1, 'bg-warning-dark': state.status.id === 2}")
                i.fa.fa-3x(:class="{'fa-exclamation-circle': state.status.id === 1, 'fa-refresh fa-spin': state.status.id === 2}")
            .col-xs-9.pv-lg
                .h4.mt0
                    .bold {{state.status.name|translate}}

                    ul
                        li(v-for="reason in state.reasons")
                            a(:href="$state.href(reason.state.state, reason.state.params)" target="_blank"
                                :title="'default.REFERENCETYPE'|translate")
                                div {{reason.name|translate}}
                                small {{reason.user.first_name}} {{reason.user.last_name}}, {{reason.created_at|noYearSek}}

                    .text-center(v-show="state.status.id === 1")
                        OptiButton(type="success" text="settings.offer.session.state.btn" @confirmed="goTranslate"
                            ) {{'settings.offer.session.state.btn.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-language

                #settings-offer-session-state-info
                    i.fa.fa-exclamation-circle.mr3
                    span(v-show="state.status.id === 1") {{'settings.offer.session.state.btn.info.BEFORE'|translate}}
                    span(v-show="state.status.id === 2") {{'settings.offer.session.state.btn.info.DURING'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Broadcast from '@/js/app/vue/Broadcast'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiSettingsOfferDifferenceSession',
        components: {
            OptiButton
        },
        props: {
            state: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                channel_name: 'settings-offer'
            }
        },
        mounted() {
            let broadcast = Broadcast.Echo.channel(this.channel_name)

            broadcast.subscribe()

            broadcast.listen('.offer-was-translated', () => {
                this.$notify.success('settings.offer.session.state.notify.TRANSLATED')

                this.$emit('refresh')
            })
        },
        destroyed() {
            Broadcast.Echo.channel(this.channel_name).unsubscribe()
        },
        methods: {
            goTranslate() {
                API.post('settings/offer/differences/translate').finally(() => {
                    this.$emit('refresh', 3500)
                }).catch(() => {
                    this.$notify.error('settings.offer.session.state.notify.error.TRANSLATED')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .panel.widget {
        margin-bottom: 50px;

        ul {
            list-style-type: square;

            > li {
                padding: 3px 0;

                > a {
                    color: inherit;
                }
            }
        }

        #settings-offer-session-state-info {
            opacity: .85;
        }
    }
</style>