<template lang="pug">
    OptiButton(type="primary" @click.native="dialog = true") {{'product.depository.STOCKS'|translate}}
        template(#icon)
            i.fa.fa-dropbox

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiDialogDepository(
                :product-id="productId"

                @closed="dialog = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogDepository from '@/js/app/vue/components/Button/Dialogs/OptiDialogDepository'

    export default {
        name: 'OptiButtonDepository',
        components: {
            OptiButton,
            OptiDialogDepository
        },
        props: {
            productId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>