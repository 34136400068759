<template lang="pug">
  div
    #btn-group-patient-detail.row(v-if="!loading")
      .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
        OptiButtonHistory(data="regeneration" :id="regeneration.id")
      .col-md-4.text-center
        OptiButton(type="primary" v-show="(!regeneration.status && (regeneration.state === 2 || regeneration.state === 3)) || permission"
          @click.native="$state.go('app.regeneration.edit', {id: regeneration.id})") {{'regeneration.breadcrumb.EDIT'|translate}}
          template(#icon)
            i.fa.fa-edit
      .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
        OptiButtonPrint(style="margin-right:5px" :title="'regeneration.print.title.FORCLIENT'|translate"
          url="regeneration/client/print" :id="regeneration.id") {{'regeneration.print.FORCLIENT'|translate}}
        OptiButtonPrint(v-show="regeneration.state > 0" :title="'regeneration.print.title.CONTROL'|translate"
          url="regeneration/control/print" :id="regeneration.id") {{'regeneration.print.CONTROL'|translate}}

    .row(v-show="!loading && permission")
      .col-xs-3
        a.btn.btn-success(:title="'regeneration.navi.PREV'|translate" :disabled="!navi.prev"
          :href="$state.href('app.regeneration.details', {id: navi.prev})")
          i.fa.fa-arrow-left
      .col-xs-6.text-center
        OptiButtonDepository(v-if="regeneration.product_id" :product-id="regeneration.product_id")
      .col-xs-3.text-right
        a.btn.btn-success(:title="'regeneration.navi.NEXT'|translate" :disabled="!navi.next"
          :href="$state.href('app.regeneration.details', {id: navi.next})")
          i.fa.fa-arrow-right

    .row(v-if="!loading")
      .col-sm-4
        .panel-grid
          .panel.panel-default
            .row(v-show="regeneration.regeneration_from")
              .col-xs-12.text-center.warn {{'regeneration.guarantee.DESC'|translate}}
            .row(v-show="regeneration.regeneration_from")
              .col-xs-12 {{'regeneration.table.GUARANTEE'|translate}}
              .col-xs-12
                a.link(:href="$state.href('app.regeneration.details', {id: regeneration.regeneration_from})"
                ) {{regeneration.regeneration_from}}
            .row
              .col-xs-12 {{'regeneration.table.PATIENT'|translate}}
              .col-xs-12
                OptiPatient(:data="regeneration.patient")
            .row(v-show="regeneration.source")
              .col-xs-12 {{'regeneration.table.SOURCE'|translate}}
              .col-xs-12
                OptiEnum.mr3(enum="RegenerationSource" :id="regeneration.source")
                OptiRefId(section="regeneration" :type-id="regeneration.source" :id="regeneration.source_id")
            .row(v-show="!regeneration.product.id")
              .col-xs-12 {{'regeneration.table.PRODUCT'|translate}}
              .col-xs-12 {{regeneration.product_name}}
            .row(v-if="regeneration.product.id")
              .col-xs-12 {{'regeneration.table.PRODUCT'|translate}}
              .col-xs-12
                OptiProduct(:product="regeneration.product") {{regeneration.product.name}}
            .row
              .col-xs-12 {{'regeneration.table.BRAND'|translate}}
              .col-xs-12 {{regeneration.product_brand}}
            .row
              .col-xs-12 {{'regeneration.table.PRODUCTTYPE'|translate}}
              .col-xs-12 {{regeneration.product_type_name|translate}}
            .row
              .col-xs-12 {{'regeneration.table.ACCOUNT'|translate}}
              .col-xs-12
                span {{regeneration.account_price|currency}}
                span(v-if="notice.account.all")
                  OptiReceiptNoticeValue(:data="notice.account.all")
                  OptiReceiptNotice(style="display:block" :data="notice.account.all")
            OptiPaymentShow(v-show="regeneration.account_payment_type"
              :type-id="regeneration.account_payment_type" :type-name="regeneration.account_payment_type_name"
              :payments="regeneration.account_payment" :differences="notice.account.differences")
            .row
              .col-xs-12 {{'regeneration.table.WHOLESALE'|translate}}
              .col-xs-12 {{regeneration.wholesale_price|currency}}
            .row
              .col-xs-12 {{'regeneration.table.RETAIL'|translate}}
              .col-xs-12
                span {{regeneration.retail_price|currency}}
                span(v-if="notice.rest_to_pay.all")
                  OptiReceiptNoticeValue(:data="notice.rest_to_pay.all")
                  OptiReceiptNotice(style="display:block" :data="notice.rest_to_pay.all")
            .row(v-show="regeneration.discount")
              .col-xs-12 {{'regeneration.table.DISCOUNT'|translate}}
              .col-xs-12 {{regeneration.discount|currency}}
            .row(v-show="regeneration.discount")
              .col-xs-12 {{'regeneration.table.VALUEAFTERDISCOUNT'|translate}}
              .col-xs-12(:class="{'warn': regeneration.retail_price - regeneration.discount < 0}"
              ) {{regeneration.retail_price - regeneration.discount|currency}}
            OptiPaymentShow(v-show="regeneration.payment_type && regeneration.retail_price && regeneration.status === 8"
              :type-id="regeneration.payment_type" :type-name="regeneration.payment_type_name"
              :payments="regeneration.payment" :differences="notice.rest_to_pay.differences")
            .row(v-show="regeneration.status === 8 && regeneration.receipt")
              .col-xs-12 {{'sale.VALUEBILL'|translate}}
              .col-xs-12 {{regeneration.receipt|receipt}}
            .row(v-show="regeneration.difficulty")
              .col-xs-12 {{'regeneration.table.DIFFICULTY'|translate}}
              .col-xs-12 {{regeneration.difficulty}}
            .row(v-show="regeneration.date_expected")
              .col-xs-12 {{'regeneration.table.EXPECTED'|translate}}
              .col-xs-12 {{regeneration.date_expected}}
            .row(v-show="regeneration.specialist.id")
              .col-xs-12 {{'regeneration.table.SPECIALIST'|translate}}
              .col-xs-12
                span.mr3 {{regeneration.specialist.first_name}} {{regeneration.specialist.last_name}}
                small(v-show="regeneration.specialist.type === 1") ({{'dictionary.specialist.CENTRAL'|translate}})
            .row
              .col-xs-12 {{'regeneration.table.USER'|translate}}
              .col-xs-12
                span.mr3 {{regeneration.user.firstName}} {{regeneration.user.lastName}}
                small ({{regeneration.department.name}})
            .row
              .col-xs-12 {{'regeneration.table.CREATEDAT'|translate}}
              .col-xs-12 {{regeneration.created_at}}
            .row(v-show="shipment_exists")
              .col-xs-12 {{'shipments.SHIPMENTS'|translate}}
              .col-xs-12
                OptiShipmentNotice(:type="15" :id="regeneration.id"
                  @shipment-exists="s => shipment_exists = s")
            .row
              .col-xs-12 {{'regeneration.table.STATE'|translate}}
              .col-xs-12 {{regeneration.state_name|translate}}
            .row(v-show="regeneration.shipment_cost && regeneration.status === 8")
              .col-xs-12 {{'regeneration.table.SHIPMENTCOST'|translate}}
              .col-xs-12 {{regeneration.shipment_cost|currency}}
            .row(v-show="regeneration.right_iLog")
              .col-xs-12 iLog {{'regeneration.iLog.eye.right.HEADING'|translate|lower}}
              .col-xs-12
                a.link(:title="'default.REFERENCETYPE'|translate"
                  @click="openHoyaPage(regeneration.right_iLog)") {{regeneration.right_iLog}}
            .row(v-show="regeneration.left_iLog")
              .col-xs-12 iLog {{'regeneration.iLog.eye.left.HEADING'|translate|lower}}
              .col-xs-12
                a.link(:title="'default.REFERENCETYPE'|translate"
                  @click="openHoyaPage(regeneration.left_iLog)") {{regeneration.left_iLog}}
            .row
              .col-xs-12 {{'regeneration.damages.HEADING'|translate}}
              .col-xs-12
                ul(style="list-style-type:square;margin-bottom:0")
                  li(v-for="damage in regeneration.damages")
                    span.mr3 {{damage.name}}
                    i(v-show="regeneration.specialist.type === 1") {{damage.price_from_central|currency}}
                    i(v-show="regeneration.specialist.type === 2") {{damage.price_from_specialist|currency}}
            .row(v-show="regeneration.date_end")
              .col-xs-12 {{'regeneration.table.END'|translate}}
              .col-xs-12 {{regeneration.date_end}}

        .text-center
          OptiButton(type="danger" :title="'reclamations.action.cancel.BTN'|translate|lower" v-show="!regeneration.status"
            text="regeneration.cancel.confirm" @confirmed="goCancel") {{'regeneration.cancel.BTN'|translate}}
            template(#icon)
              i.fa.fa-remove
          OptiButton(type="success" v-show="department_id === 49 && regeneration.status === 1"
            text="regeneration.receivedepartment.confirm" @confirmed="goReceiveWarehouse") {{'regeneration.receivedepartment.BTN'|translate}}
            template(#icon)
              i.fa.fa-check
          OptiButton(type="success" v-show="permission && regeneration.status === 2 && regeneration.state === 8"
            text="regeneration.regenerated.confirm" @confirmed="goRegenerated") {{'regeneration.regenerated.BTN'|translate}}
            template(#icon)
              i.fa.fa-check
          OptiButton(type="danger" style="display:block;margin:15px auto"
            v-show="permission && regeneration.status === 2 && regeneration.state === 8"
            text="regeneration.notregenerated.confirm" @confirmed="goNotRegenerated") {{'regeneration.notregenerated.BTN'|translate}}
            template(#icon)
              i.fa.fa-remove
          OptiButton(type="success" v-show="regeneration.status === 6"
            text="regeneration.receivedepartment.confirm" @confirmed="goReceiveDepartment") {{'regeneration.receivedepartment.BTN'|translate}}
            template(#icon)
              i.fa.fa-check
          OptiButton(type="success" v-show="regeneration.status === 7"
            :disabled="(invalid.payment || invalid.receipt) && regeneration.state !== 4"
            text="regeneration.done.confirm" @confirmed="goDone") {{'regeneration.done.BTN'|translate}}
            template(#icon)
              i.fa.fa-check
          //OptiButton(type="danger" v-show="regeneration.status === 8"
              text="regeneration.guarantee.confirm" @confirmed="goGuarantee") {{'regeneration.guarantee.BTN'|translate}}
              template(#icon)
                  i.fa.fa-medkit

      .col-sm-8
        .row
          .col-md-6
            .panel-grid
              .panel.panel-default
                .row
                  .col-xs-12 {{'regeneration.table.DESCRIPTION'|translate}}
                  .col-xs-12(v-html="$options.filters.nl2br(regeneration.descriptions)")
                .row(v-for="photo in regeneration.photo_descriptions")
                  .col-xs-12.text-center
                    a(style="padding-top:5px" :title="'regeneration.notes.PICTURETITLE'|translate"
                      :href="'regeneration/descriptions/' + photo.photo|upload" target="_blank")
                      img.img-rounded(style="width:80%" :src="'regeneration/descriptions/' + photo.photo|upload")

          .col-md-6
            .panel-grid(v-show="regeneration.photo_comments.length || regeneration.comments")
              .panel.panel-default
                .row
                  .col-xs-12 {{'regeneration.table.COMMENTS'|translate}}
                  .col-xs-12(v-show="regeneration.comments" v-html="$options.filters.nl2br(regeneration.comments)")
                .row(v-for="photo in regeneration.photo_comments")
                  .col-xs-12.text-center
                    a(style="padding-top:5px" :title="'regeneration.notes.PICTURETITLE'|translate"
                      :href="'regeneration/comments/' + photo.photo|upload" target="_blank")
                      img.img-rounded(style="width:80%" :src="'regeneration/comments/' + photo.photo|upload")

          .col-xs-12(v-if="regeneration.source === 1 && regeneration.status === 2 && !regeneration.right_iLog && !regeneration.left_iLog && permission")
            OptiRegenerationDetailsHoyaiLog(
              :regeneration-id="regeneration.id"
              :task-id="regeneration.source_id"

              @load="load")

          .col-xs-12
            OptiRegenerationNotes(:notes="regeneration.notes" @update-notes="n => regeneration.notes = n")

          .col-xs-12(v-if="regeneration.status === 7 && regeneration.state !== 4")
            OptiRegenerationDetailsPayment(
              :payment-done="paymentDone"
              :price="regeneration.retail_price - regeneration.account_price - regeneration.discount"

              @shipment_cost="v => shipment_cost = v"

              @invalid="(t, i) => invalid[t] = i")

    .row
      .col-xs-12
        OptiDimmer(:active="loading")
</template>

<script>
import {API} from '@/js/app/vue/api'
import {ReceiptNotice} from '@/js/app/vue/helpers/ReceiptNotice'
import {HoyaHelper} from '@/js/app/vue/helpers/HoyaHelper'

import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
import OptiButtonDepository from '@/js/app/vue/components/Button/OptiButtonDepository'
import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
import OptiEnum from '@/js/app/vue/components/OptiEnum'
import OptiRefId from '@/js/app/vue/components/OptiRefId'
import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
import OptiPaymentShow from '@/js/app/vue/components/Payments/OptiPaymentShow'
import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
import OptiRegenerationDetailsHoyaiLog
  from '@/js/app/vue/components/Regenerations/Details/iLog/OptiRegenerationDetailsHoyaiLog'
import OptiRegenerationNotes from '@/js/app/vue/components/Regenerations/OptiRegenerationNotes'
import OptiRegenerationDetailsPayment
  from '@/js/app/vue/components/Regenerations/Details/OptiRegenerationDetailsPayment'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

export default {
  name: 'OptiRegenerationDetails',
  components: {
    OptiButtonHistory,
    OptiButton,
    OptiButtonPrint,
    OptiButtonDepository,
    OptiPatient,
    OptiEnum,
    OptiRefId,
    OptiProduct,
    OptiReceiptNoticeValue,
    OptiReceiptNotice,
    OptiPaymentShow,
    OptiShipmentNotice,
    OptiRegenerationDetailsHoyaiLog,
    OptiRegenerationNotes,
    OptiRegenerationDetailsPayment,
    OptiDimmer
  },
  data() {
    return {
      regeneration: {},
      navi: {},
      department_id: this.$uac.user.activeDepartmentId,
      notice: {
        account: {},
        rest_to_pay: {}
      },
      shipment_exists: false,
      shipment_cost: null,

      paymentDone: {
        formAngular: {},
        payment: [],
        receipt: {
          cash_register_id: 0,
          receipt: ''
        }
      },

      permission: this.$uac.permission('regenerations.admin'),
      invalid: {
        payment: true,
        receipt: true
      },
      loading: true
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true

      API.get('regeneration/' + this.$state.params.id).then(res => {
        this.getData(res.data)
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.LOAD')
      })

      if (this.permission) {
        API.get('regeneration/navi/' + this.$state.params.id).then(res => {
          this.navi = res.data
        }).catch(() => {
          this.$notify.error('regeneration.navi.notify.error.LOAD')
        })
      }
    },
    getData(data) {
      this.regeneration = data

      ReceiptNotice.get('regeneration_account', this.regeneration.id).then(res => {
        this.notice.account = res
      }).catch(() => {
        // brak różnic
      })

      ReceiptNotice.get('regeneration_rest_to_pay', this.regeneration.id).then(res => {
        this.notice.rest_to_pay = res
      }).catch(() => {
        // brak różnic
      })

      this.$emit('update-breadcrumb', this.regeneration.status_name)
    },
    goCancel() {
      this.loading = true

      API.get('regenerations/gocancel/' + this.regeneration.id).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.CANCEL')
      }).finally(() => {
        this.loading = false
      }).catch(error => {
        if (error.response.status === 412) {
          this.$notify.error(error.response.data.message, false)
        }

        this.$notify.error('regeneration.notify.error.CANCEL')
      })
    },
    goReceiveWarehouse() {
      this.loading = true

      API.get('regenerations/goreceivewarehouse/' + this.regeneration.id).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.RECEIVEDEPARTMENT')
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.RECEIVEDEPARTMENT')
      })
    },
    goRegenerated() {
      this.loading = true

      API.get('regenerations/goregenerated/' + this.regeneration.id).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.REGENERATED')
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.REGENERATED')
      })
    },
    goNotRegenerated() {
      this.loading = true

      API.get('regenerations/gonotregenerated/' + this.regeneration.id).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.NOTREGENERATED')
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.NOTREGENERATED')
      })
    },
    goReceiveDepartment() {
      this.loading = true

      API.get('regenerations/goreceivedepartment/' + this.regeneration.id).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.RECEIVEDEPARTMENT')
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.RECEIVEDEPARTMENT')
      })
    },
    goDone() {
      this.loading = true

      API.post('regenerations/godone', {
        id: this.regeneration.id,
        payment: this.paymentDone.payment,
        receipt: this.paymentDone.receipt,
        shipment_cost: this.shipment_cost
      }).then(res => {
        this.getData(res.data)

        this.$notify.success('regeneration.notify.DONE')
      }).finally(() => {
        this.loading = false
      }).catch(() => {
        this.$notify.error('regeneration.notify.error.DONE')
      })
    },
    goGuarantee() {
      this.loading = true

      API.get('regenerations/guarentee/' + this.regeneration.id).then(res => {
        this.$state.go('app.regeneration.details', {
          id: res.data
        })

        this.$notify.success('regeneration.guarantee.notify.CREATED')
      }).finally(() => {
        this.loading = false
      }).catch(error => {
        if (error.response.status === 412) {
          this.$notify.error(error.response.data.message, false)
        }

        this.$notify.error('regeneration.guarantee.notify.error.CREATED')
      })
    },

    openHoyaPage(order_id) {
      HoyaHelper.openHoyaPage(order_id)
    }
  }
}
</script>