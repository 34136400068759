import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class ReceiptNoticeClass {
    constructor() {

    }

    async get(module, id) {
        return new Promise((resolve, reject) => {
            API.post('register/notice', {
                module: module,
                id: id
            }, API.id(Math.random())).then(res => {
                if(res.data.all) {
                    resolve(res.data)
                } else {
                    reject()
                }
            }).catch(() => {
                Notify.error('register.notify.error.NOTGETCHANGE')

                reject()
            })
        })
    }
}

export const ReceiptNotice = new ReceiptNoticeClass()