<template lang="pug">
    div
        OptiButton(type="info" v-show="permission.schedule_admin && department.id === 60"
            @click.native="goExcelGrindery") {{'schedule.grindery.BTN'|translate}}
            template(#icon)
                i.fa.fa-file-excel-o

        OptiButton.pull-right(type="info" v-show="permission.schedule_manager"
            @click.native="goExcelMonth") {{'schedule.EXCEL'|translate}}
            template(#icon)
                i.fa.fa-file-excel-o
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiScheduleMonthExcel',
        components: {
            OptiButton
        },
        props: {
            department: {
                type: Object,
                required: true
            },
            view: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                permission: {
                    schedule_admin: this.$uac.permission('schedule.admin'),
                    schedule_manager: this.$uac.permission('schedule.manager')
                }
            }
        },
        methods: {
            goExcelGrindery() {
                let filename = this.$filters.translate('schedule.grindery.month.' + _.cloneDeep(this.view.start).add(15, 'days').format('M')) +
                    ' ' + _.cloneDeep(this.view.start).add(15, 'days').format('YYYY') + ' ' + parseInt(Math.random() * 100000) + '.xls'
                // CalendarExcelGrinderyController.php:createExcel()

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'harmonogram/grindery/' + filename,
                        status: true
                    }
                })

                API.post('schedule/calendar/grindery-excel', {
                    filename: filename,
                    start: this.view.start.format('YYYY-MM-DD')
                }).then(res => {
                    if(!res.data.nodata) {
                        this.$notify.error('schedule.grindery.notify.error.NODATA')
                    }
                }).catch(() => {
                    this.$notify.error('schedule.grindery.notify.error.DOWNLOAD')
                })
            },

            goExcelMonth() {
                let filename = this.department.login + ' ' + this.$filters.translate('schedule.grindery.month.' + _.cloneDeep(this.view.start).add(15, 'days').format('M')) +
                    ' ' + _.cloneDeep(this.view.start).add(15, 'days').format('YYYY') + ' ' + parseInt(Math.random() * 100000) + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'harmonogram/month/' + filename,
                        status: true
                    }
                })

                API.post('schedule/calendar/excel/month', {
                    filename: filename,
                    department_id: this.department.id,
                    view: this.view.type,
                    start: this.view.start.format('YYYY-MM-DD'),
                    end: this.view.end.format('YYYY-MM-DD')
                }).catch(() => {
                    this.$notify.error('schedule.notify.error.EXCEL')
                })
            }
        }
    }
</script>