<template lang="pug">
    div
        .text-center(:class="{'text-muted': disabled}") {{minValue|number(0)}} - {{maxValue|number(0)}}

        .slider-between
            input.slider-between-from(
                type="range" :step="step" :min="min" :max="max" :disabled="disabled"
                :value="value.from" @input="e => updateSlider('from', e)")
            input.slider-between-to(ref="slider_between_to"
                type="range" :step="step" :min="min" :max="max" :disabled="disabled"
                :value="value.to" @input="e => updateSlider('to', e)")
</template>

<script>
    export default {
        name: 'OptiSliderBetween',
        props: {
            min: {
                type: Number,
                required: true
            },
            max: {
                type: Number,
                required: true
            },

            step: {
                type: Number,
                required: false,
                default: 1
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },

            minValue: {
                type: Number,
                required: true
            },
            maxValue: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                range_color: '#c6c6c6',

                value: {
                    from: this.minValue,
                    to: this.maxValue
                }
            }
        },
        mounted() {
            this.range_color = document.documentElement.getAttribute('data-theme') === 'dark' ? '#f90' : '#25daa5'

            this.fill_color()
            this.accessible('to')
        },
        methods: {
            updateSlider(type, e) {
                this.value[type] = parseInt(e.target.value)

                this.fill_color()
                this.accessible(type)

                if(this.value.from > this.value.to) {
                    if(type === 'from') {
                        this.value.from = this.value.to
                    } else if(type === 'to') {
                        this.value.to = this.value.from
                    }
                }

                this.$emit('updated-' + type, this.value[type])
            },

            fill_color() {
                let range_distance = this.max - this.min,
                    from_position = this.value.from - this.min,
                    to_position = this.value.to - this.min,
                    slider_color = '#c6c6c6'

                this.$refs['slider_between_to'].style.background = `linear-gradient(
                    to right,
                    ${slider_color} 0%,
                    ${slider_color} ${(from_position)/(range_distance)*100}%,
                    ${this.range_color} ${((from_position)/(range_distance))*100}%,
                    ${this.range_color} ${(to_position)/(range_distance)*100}%,
                    ${slider_color} ${(to_position)/(range_distance)*100}%,
                    ${slider_color} 100%)`
            },
            accessible(type) {
                this.$refs['slider_between_to'].style.zIndex = this.value[type] === this.minValue ? 2 : 0
            }
        }
    }
</script>

<style lang="less" scoped>
    .slider-between {
        position: relative;

        > input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            height: 2px;
            width: 100%;
            position: absolute;
            background-color: #c6c6c6;
            pointer-events: none;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                pointer-events: all;
                width: 24px;
                height: 24px;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 0 0 0 1px #c6c6c6;
                cursor: pointer;

                &:active {
                    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
                    -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
                }

                &:hover {
                    background: #f7f7f7;
                }
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                pointer-events: all;
                width: 24px;
                height: 24px;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 0 0 0 1px #C6C6C6;
                cursor: pointer;
            }

            &.slider-between-from {
                height: 0;
                z-index: 1;
            }
        }
    }
</style>