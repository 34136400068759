<template lang="pug">
    .row(v-if="regeneration")
        form.form-validate(name="formRegeneration" v-if="!loading && !regeneration.status1" @submit.prevent="editRegeneration")
            .col-sm-4
                .form-group.text-center.warn.bold(v-show="regeneration.regeneration_from") {{'regeneration.guarantee.DESC'|translate}}
                .form-group(v-show="regeneration.regeneration_from")
                    label {{'regeneration.table.GUARANTEE'|translate}}
                    div
                        a.link(:href="$state.href('app.regeneration.details', {id: regeneration.regeneration_from})") {{regeneration.regeneration_from}}
                .form-group(v-if="regeneration.source")
                    label {{'regeneration.table.SOURCE'|translate}}
                    div
                        OptiEnum.mr3(enum="RegenerationSource" :id="regeneration.source")
                        OptiRefId(section="regeneration" :type-id="regeneration.source" :id="regeneration.source_id")
                .form-group(v-if="regeneration.patient")
                    label.star-required(for="patient") {{'regeneration.table.PATIENT'|translate}}
                    OptiSelectPatient#patient(:id="'patient'" :required="true" :disabled="!!regeneration.source"
                        :init-result="regeneration.patient" @selected="updatePatient")
                    OptiValidate(f="patient" :data="$v.regeneration.patient")
                .form-group(v-show="!regeneration.product.id")
                    label.star-required(for="product_name") {{'regeneration.table.PRODUCT'|translate}}
                    input#product_name.form-control(:placeholder="'regeneration.table.PRODUCT'|translate"
                        v-model="regeneration.product_name"
                        name="product_name" :disabled="!!regeneration.source")
                    OptiValidate(f="product_name" :data="$v.regeneration.product_name")
                .form-group(v-if="regeneration.product.id")
                    label.star-required(for="product_name") {{'regeneration.table.PRODUCT'|translate}}
                    OptiProduct(style="display:block" :product="regeneration.product" :title="'default.title.PRODUCT'|translate") {{regeneration.product.name}}
                .form-group
                    label.star-required(for="product_brand") {{'regeneration.table.BRAND'|translate}}
                    input#product_brand.form-control(
                        :placeholder="'regeneration.table.BRAND'|translate"
                        v-model="regeneration.product_brand" name="product_brand" :disabled="!!regeneration.source")
                    OptiValidate(f="product_brand" :data="$v.regeneration.product_brand")
                .form-group
                    label.star-required(for="product_type") {{'regeneration.table.PRODUCTTYPE'|translate}}
                    OptiSelectEnum#product_type(enum="TaskFrameType" :value="regeneration.product_type"
                        name="product_type" :disabled="!!regeneration.source" @update-enum="v => regeneration.product_type = v")
                    OptiValidate(f="product_type" :data="$v.regeneration.product_type")
                .form-group
                    label(for="account_price") {{'regeneration.table.ACCOUNT'|translate}}
                    OptiNumber.form-control.text-right#account_price(
                        :header="'regeneration.table.ACCOUNT'|translate"
                        :value="regeneration.account_price" @confirmed="value => regeneration.account_price = value"
                        :float="true" :disabled="!!regeneration.regeneration_from") {{regeneration.account_price|currency}} zł
                div(v-if="$uac.permission('regenerations.admin')")
                    .form-group
                        label {{'regeneration.table.WHOLESALE'|translate}}
                        OptiNumber.form-control.text-right#wholesale_price(
                            :header="'regeneration.table.WHOLESALE'|translate"
                            :value="regeneration.wholesale_price" @confirmed="value => regeneration.wholesale_price = value"
                            :float="true") {{regeneration.wholesale_price|currency}} zł
                    .form-group
                        label(for="retail_price") {{'regeneration.table.RETAIL'|translate}}
                        OptiNumber.form-control.text-right#retail_price(
                            :header="'regeneration.table.RETAIL'|translate"
                            :value="regeneration.retail_price" @confirmed="value => regeneration.retail_price = value"
                            :float="true" :disabled="!!regeneration.regeneration_from") {{regeneration.retail_price|currency}} zł
                    .form-group
                        label(for="specialist") {{'regeneration.table.SPECIALIST'|translate}}
                        select.form-control#specialist(v-model="regeneration.specialist_id")
                            option(v-for="s in regeneration" v-if="regeneration.specialist_id === s.id"
                                :key="s.id"
                                :value="s.id"
                                :disabled="!!regeneration.regeneration_from") {{ s.first_name + ' ' + s.last_name + ' ' + (s.type === 1 ? ' (Centrala)' : '') }}
                    .form-group
                        label(for="difficulty") {{'regeneration.table.DIFFICULTY'|translate}}
                        select.form-control#difficulty(name="difficulty" v-model="regeneration.difficulty" :disabled="!!regeneration.regeneration_from")
                            option(v-for="number in 6" :key="number" :value="number - 1") {{ number - 1 }}
                    .form-group(v-show="allow_discount")
                        label(for="discount") {{'regeneration.table.DISCOUNT'|translate}}
                        OptiNumber.form-control.text-right#discount(
                            :header="'regeneration.table.DISCOUNT'|translate"
                            :value="regeneration.discount"
                            @confirmed="value => regeneration.discount = value"
                            :float="true"
                            :disabled="!!regeneration.regeneration_from") {{regeneration.discount|currency}} zł
                    .form-group(v-show="regeneration.discount !== 0")
                        label {{'regeneration.table.VALUEAFTERDISCOUNT'|translate}}
                        .form-control-static.text-right(style="padding-right:15px"
                            :class="{'warn': regeneration.retail_price - regeneration.discount < 0}") {{regeneration.retail_price - regeneration.discount|currency}} zł
                    .form-group
                        label.star-required(for="date_expected") {{'regeneration.table.EXPECTED'|translate}}
                        OptiDate#date_expected(name="date_expected"
                            :value="regeneration.date_expected"
                            @update-date="d => regeneration.date_expected = d")
                        OptiValidate(f="date_expected" :data="$v.regeneration.date_expected")
                    div(v-if="$uac.permission('regenerations.admin')")
                        .form-group
                            label(for="status") {{'regeneration.table.STATUS'|translate}}
                            OptiSelectEnum#status(enum="RegenerationStatus" name="status"
                                :value="regeneration.status"
                                @update-enum="v => regeneration.status = v")
                        .form-group
                            label(for="state") {{'regeneration.table.STATE'|translate}}
                            OptiSelectEnum#state(enum="RegenerationState" name="state"
                                :value="regeneration.state"
                                @update-enum="v => regeneration.state = v")
                    .form-group
                        OptiButtonSave(:disabled="$v.regeneration.$invalid || !regeneration.patient.id || !regeneration.damages[0].obj.id")

            .col-sm-8
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="descriptions") {{'regeneration.table.DESCRIPTION'|translate}}
                            textarea#descriptions.form-control(style="height:120px;resize:none"
                                :placeholder="'regeneration.table.DESCRIPTION'|translate"
                                v-model="regeneration.descriptions"
                                :class="{ 'updateOn': 'blur' }"
                                name="descriptions")
                            OptiValidate(f="descriptions" :data="$v.regeneration.descriptions")
                        .form-group
                            .row(v-for="(photo, key) in $v.regeneration.photo_descriptions.$each.$iter" :key="key")
                                .col-xs-6
                                    div
                                        OptiUpload(
                                            :id="'regenerations-descriptions-' + key"
                                            prefix="regenerations_descriptions"

                                            :file="photo.photo.$model"
                                            :picture="true"

                                            @update-loading="l => photo.loading = l"
                                            @update-file="p => photo.photo.$model = p")
                                        OptiValidate(:f="'regenerations-descriptions-' + key" :data="photo.photo")
                                .col-xs-6.text-center
                                    OptiButton(type="danger"
                                        style="display:block;margin-bottom:5px"
                                        v-show="regeneration.photo_descriptions.length !== 1"
                                        @click.native="deletePhoto('descriptions', key)") {{'regeneration.notes.DELETE'|translate}}
                                        template(#icon)
                                            i.fa.fa-trash

                                    OptiButton(type="success"
                                        style="display:block"
                                        v-show="key == regeneration.photo_descriptions.length - 1"
                                        :disabled="!photo.photo.$model"
                                        @click.native="addPhoto('descriptions')") {{'regeneration.notes.ADD'|translate}}
                                        template(#icon)
                                            i.fa.fa-plus
                    .col-md-6
                        .form-group
                            label(for="comments") {{'regeneration.table.COMMENTS'|translate}}
                            textarea#comments.form-control(
                                :placeholder="'regeneration.table.COMMENTS'|translate"
                                :class="{ 'updateOn': 'blur' }"
                                v-model="regeneration.comments"
                                name="comments")

                        .form-group
                            .row(v-for="(photo, key) in $v.regeneration.photo_comments.$each.$iter" :key="key")
                                .col-xs-6
                                    div
                                        OptiUpload(
                                            :id="'regenerations-comments-' + key"
                                            prefix="regenerations_comments"

                                            :file="photo.photo.$model"
                                            :picture="true"

                                            @update-loading="l => photo.loading = l"
                                            @update-file="p => photo.photo.$model = p")
                                        OptiValidate(:f="'regenerations-comments-' + key" :data="photo.photo")
                                .col-xs-6.text-center
                                    OptiButton(type="danger"
                                        style="display:block;margin-bottom:5px"
                                        v-show="(regeneration.photo_comments.length !== 1 && photo.photo.$model) || regeneration.photo_comments.length > 1"
                                        @click.native="deletePhoto('comments', key)") {{'regeneration.notes.DELETE'|translate}}
                                        template(#icon)
                                            i.fa.fa-trash
                                    OptiButton(type="success"
                                        style="display:block"
                                        v-show="key == regeneration.photo_comments.length - 1"
                                        :disabled="!photo.photo.$model"
                                        @click.native="addPhoto('comments')") {{'regeneration.notes.ADD'|translate}}
                                        template(#icon)
                                            i.fa.fa-plus

                    .col-xs-12
                        OptiRegenerationNotes(:notes="regeneration.notes" @update-notes="updateNotes")

                    .col-xs-12
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-navicon.mr3
                                span {{'regeneration.damages.HEADING'|translate}}

                            .panel-body
                                .row(v-for="(damage, key) in $v.regeneration.damages.$each.$iter" :key="key")
                                    .col-xs-6
                                        .form-group
                                            label.star-required(:for="'type' + key") {{'regeneration.damages.TYPE'|translate}}
                                            select.form-control(:id="'type' + key" :name="'type' + key" v-model="damage.obj.$model")
                                                option(v-for="d in damages" :key="d.id" :value="d") {{d.name}}
                                            OptiValidate(:f="'type' + key" :data="damage.obj.id")
                                            .text-info.bold(v-if="damage.obj.$model.id")
                                                div {{'regeneration.LIST'|translate}} {{getAssignedSpecialists(damage.obj.$model.specialists)}}.
                                                div {{'dictionary.regenerationtype.price.CENTRAL'|translate}}: {{damage.obj.$model.price_from_central|currency}}.
                                                div {{'dictionary.regenerationtype.price.SPECIALIST'|translate}}: {{damage.obj.$model.price_from_specialist|currency}}.
                                                div(v-if="$uac.permission('regenerations.manager')") {{'dictionary.regenerationtype.price.WHOLESALE'|translate}}: {{damage.obj.$model.price_wholesale|currency}}.

                                    .col-xs-6.text-center(style="padding-top:25px")
                                        OptiButton(type="danger" v-if="regeneration.damages.length !== 1 && key >= 0" :disabled="!!regeneration.regeneration_from"
                                            @click.native="deleteDamage(key)") {{'regeneration.damages.DELETE'|translate}}
                                            template(#icon)
                                                i.fa.fa-trash
                            .panel-footer.text-right(v-if="regeneration.damages")
                                OptiButton(type="success" :disabled="!regeneration.damages[regeneration.damages.length - 1].obj.id || !!regeneration.regeneration_from"
                                    @click.native="addDamage()") {{'regeneration.damages.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
        .col-xs-12
            OptiDimmer(:active="loading")
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiRefId from '@/js/app/vue/components/OptiRefId.vue'
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient.vue'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber.vue'
    import OptiDate from '@/js/app/vue/components/OptiDate.vue'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave.vue'
    import OptiUpload from '@/js/app/vue/components/OptiUpload.vue'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton.vue'
    import OptiRegenerationNotes from '@/js/app/vue/components/Regenerations/OptiRegenerationNotes.vue'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct.vue'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum.vue'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
    import OptiEnum from '@/js/app/vue/components/OptiEnum.vue'
    import OptiValidate from '@/js/app/vue/components/OptiValidate.vue'

    export default {
        name: 'OptiRegenerationEdit',
        components: {
            OptiValidate,
            OptiDimmer,
            OptiSelectEnum,
            OptiProduct,
            OptiRegenerationNotes,
            OptiButton,
            OptiUpload,
            OptiButtonSave,
            OptiDate,
            OptiNumber,
            OptiSelectPatient,
            OptiRefId,
            OptiEnum
        },
        data() {
            return {
                regeneration: {
                    date_expected: '',
                    notes: []
                },
                specialist: {},
                damages: [],
                loading: true,
                previous_regeneration_status: null,
                allow_discount: false
            }
        },
        validations: {
            regeneration: {
                patient: {
                    required
                },
                product_name: {
                    required
                },
                product_brand: {
                    required
                },
                product_type: {
                    noZero
                },
                date_expected: {
                    required
                },
                descriptions: {
                    required
                },
                photo_descriptions: {
                    required,

                    $each: {
                        photo: {
                            required
                        }
                    }
                },
                photo_comments: {
                    required,

                    $each: {
                        photo: {
                            required: requiredIf(function () {
                                if (this.regeneration.photo_comments && this.regeneration.photo_comments.length > 1) {
                                    return required
                                }
                                return false
                            })
                        }
                    }
                },
                damages: {
                    required,

                    $each: {
                        obj: {
                            id: {
                                required: requiredIf(function() {
                                    if(this.regeneration.damages && this.regeneration.damages.length > 1 && this.regeneration.damages[this.regeneration.damages.length - 1].obj) {
                                        return required
                                    }
                                    return false
                                })
                            }
                        }
                    }
                }
            },
        },
        mounted() {
            API.get('regeneration/' + this.$state.params.id).then(res => {
                this.regeneration = res.data
            }).then(() => {
                this.regenerationDetails()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('regeneration.notify.error.LOAD')
            })


            this.getDamages()
        },
        methods: {
            regenerationDetails() {
                this.previous_regeneration_status = this.regeneration.status

                this.regeneration.photo_descriptions.forEach(photo_description => {
                    photo_description.photo = 'regeneration/descriptions/' + photo_description.photo
                })

                this.regeneration.photo_comments.forEach(photo_comment => {
                    photo_comment.photo = 'regeneration/comments/' + photo_comment.photo
                })

                if (!this.regeneration.photo_descriptions.length) {
                    this.addPhoto('descriptions')
                }

                if (!this.regeneration.photo_comments.length) {
                    this.addPhoto('comments')
                }


                this.parseDamages()


                if (this.regeneration && this.regeneration.status_name) {
                    this.$emit('update-breadcrumb', this.regeneration.status_name)
                }

                if (this.$uac.user.departmentType === 3 || this.$uac.permission('regenerations.admin')) {
                    this.allow_discount = true
                }
            },
            editRegeneration() {
                if (this.regeneration.retail_price - this.regeneration.discount < 0) {
                    this.$notify.error('regeneration.notify.error.MINUSVALUE')
                } else if (this.previous_regeneration_status !== this.regeneration.status && this.regeneration.status === 9) {
                    if (this.regeneration.account_payment_type !== 2) {
                        if (confirm(this.$filters.translate('regeneration.cancel.deposit.MESSAGE'))) {
                            this.goSave(true)
                        } else {
                            this.goSave(false)
                        }
                    } else {
                        this.goSave(false)

                        this.$notify.warning('regeneration.cancel.NORETURNACCOUNT')
                    }
                } else {
                    this.goSave(false)
                }
            },
            parseDamages() {
                let _parsed = []

                this.regeneration.damages.forEach(damage => {
                    _parsed.push({
                        obj: damage
                    })
                })

                this.regeneration.damages = _parsed
            },
            updatePatient(patient) {
                this.regeneration.patient = patient
            },
            updateNotes(notes) {
                this.regeneration.notes = notes
            },
            getDamages() {
                API.get('regenerations/damages').then(res => {
                    this.damages = res.data
                }).catch(() => {
                    this.$notify.error('regeneration.damages.notify.error.LOAD')
                })
            },
            getSpecialist() {
                API.get('dictionary/specialist/action/' + this.regeneration.specialist_id).then(res => {
                    this.specialist = res
                }).catch(() => {
                    this.$notify.error('regeneration.notify.error.SPECIALISTLIST')
                })
            },
            goSave(return_deposit) {
                this.regeneration.return_deposit = return_deposit
                this.loading = true

                API.post('regenerations/edit', this.regeneration).then(() => {
                    this.$state.go('app.regeneration.details', {id: this.regeneration.id})

                    this.$notify.success('regeneration.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('regeneration.notify.error.EDIT')
                })
            },
            addDamage() {
                this.regeneration.damages.push({
                    obj: {
                        id: null,
                        price_wholesale: 0
                    }
                })
            },
            deleteDamage(key) {
                this.regeneration.damages.splice(key, 1)
            },
            getAssignedSpecialists(specialists) {
                let _tab = []

                specialists.forEach(specialist => {
                    _tab.push(specialist.first_name + ' ' + specialist.last_name)
                })

                return _tab.join(', ')
            },
            addPhoto(type) {
                this.regeneration['photo_' + type].push({
                    photo: '',
                    loading: false
                })
            },
            deletePhoto(type, key) {
                if (this.regeneration.photo_comments.length === 1 && type === 'comments' && !key) {
                    this.regeneration['photo_comments'][0].photo = ''
                } else {
                    this.regeneration['photo_' + type].splice(key, 1)
                }
            }
        }
    }
</script>