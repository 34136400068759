<template lang="pug">
    .panel.panel-default
        .panel-heading.bold Budżet osiągnięty/skorygowany pracownika
        .panel-body.relative
            .info(v-show="!userId") Wybierz użytkownika żeby wyświetlić dane
            .info(v-show="userId && !$asyncComputed.chartData.updating && !chartData") Brak danych

            LineChart(
                v-if="chartData"
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

            OptiChartTooltip(
                :model="tooltipModel"
                :chart-data="chartData"
                :chart="$refs.chart")
                template(#default="{body}")
                    table.table.table-striped.table-bordered.table-condensed
                        thead
                            tr
                                th
                                th
                                    .line-legend(:style="{backgroundColor: body.tooltip.labelColors[0].backgroundColor, borderColor: body.tooltip.labelColors[0].borderColor}"
                                        ) {{chartData.datasets[0].label}}
                                th
                                    .line-legend(:style="{backgroundColor: body.tooltip.labelColors[1].backgroundColor, borderColor: body.tooltip.labelColors[1].borderColor}"
                                        ) {{chartData.datasets[1].label}}
                                th
                                    .line-legend(:style="{backgroundColor: body.tooltip.labelColors[2].backgroundColor, borderColor: body.tooltip.labelColors[2].borderColor}"
                                        ) {{chartData.datasets[2].label}}

                        tbody
                            tr
                                th Razem (tys)
                                td.no-brake.text-right {{body.tooltip.dataPoints[0].value|currency({fractionCount:0})}}
                                td.no-brake.text-right {{body.tooltip.dataPoints[1].value|currency({fractionCount:0})}}
                                td.no-brake.text-right {{body.tooltip.dataPoints[2].value|currency({fractionCount:0})}}

            OptiDimmer(:active="$asyncComputed.chartData.updating")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {INIT} from '@/js/app/vue/store/opti-store'
    import {budgetForecastAnnotation} from './Base/OptiChartBudgetAnnotations'

    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiChartTooltip from './Base/OptiChartTooltip'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiChartEmployeeCorrectedReachedBudget',
        components: {
            LineChart,
            OptiChartTooltip,
            OptiDimmer
        },
        props: {
            departmentId: {
                type: Number,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            date: {
                type: Object,
                required: false,
                default: () => {
                    return moment()
                }
            }
        },
        data() {
            return {
                styles: {
                    position: 'relative',
                    height: '300px'
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    spanGaps: false,
                    tooltips: {
                        mode: 'index',
                        callbacks: {
                            label(tooltipItems, data) {
                                return {
                                    label: data.datasets[tooltipItems.datasetIndex].label,
                                    value: tooltipItems.yLabel
                                }
                            }
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.tooltipModel = tooltip
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.4,
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        },
                    },
                    annotation: {
                        drawTime: 'afterDatasetsDraw',
                        annotations: [
                            ...budgetForecastAnnotation(this.date)
                        ]
                    }
                },
                tooltipModel: null
            }
        },
        mounted() {
            this.$store.dispatch(`departments/${INIT}`)
        },
        computed: {},
        methods: {},
        asyncComputed: {
            async chartData() {
                try {
                    if(!this.userId) return

                    let { data } = await API.post('charts/employee-budget', {
                        departmentId: this.departmentId,
                        userId: this.userId,
                        date: this.date.format('YYYY-MM-DD')
                    })

                    return Object.keys(data).length ? data : null
                } catch (e) {
                    console.log(e)
                    this.$notify.error('Could not load chart data', false)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .fill-box {
        display: flex;
    }

    .fill-grow {
        flex: 1;
        padding-right: 20px;
    }

    .relative {
        position: relative;
    }

    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        display: inline-block;
        margin-right: 4px;
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }
    .info {
        text-align: center;
        font-size: 21px;
    }
</style>
