import {API} from '@/js/app/vue/api'
import Vue from 'vue'
import _ from 'lodash'
import {EventBus} from "@/js/app/_bridge/EventBus";

export default {
    namespaced: true,

    state: {
        data: {},
        status: false,
        previousAssemblies: [],
        messages: {
            alerts: 0,
            unread: {
                all: 0,
                left: 0,
                info: []
            }
        }
    },

    actions: {
        fetchAll({dispatch}) {
            return Promise.all([
                dispatch('fetch'),
                dispatch('fetchAlerts'),
                dispatch('fetchMessages')
            ])
        },
        fetch({commit}) {
            commit('setStatus', 'fetching')

            let request = API.get('dashboard/statuses')

            request
                .then(res => {
                    commit('setData', res.data)
                    commit('setStatus', 'fetched')
                })
                .catch(() => {
                    commit('setStatus', 'error')
                })

            return request
        },
        fetchPreviousAssemblies({commit}) {
            let request = API.get('dashboard/previousAssemblies')

            request
                .then(res => {
                    commit('setPreviousAssemblies', res.data)
                })
                .catch(() => {
                    commit('setPreviousAssemblies', [])
                })

            return request
        },
        fetchSingle({commit}, type) {
            commit('setStatus', 'fetching')

            let request = API.post('dashboard/statuses', {
                type: type
            })

            request
                .then(response => {
                    commit('setSingleData', {type: type, data: response.data})
                    commit('setStatus', 'fetched')
                })
                .catch(err => {
                    commit('setStatus', 'error')
                })

            return request
        },
        fetchAlerts({commit}) {
            let request = API.get('alert/read', API.id(Math.random()))

            request
                .then(response => {
                    commit('setAlerts', response.data.count)
                })
                .catch(err => {
                    commit('setAlerts', 0)
                })

            return request
        },
        fetchMessages({commit}) {
            let request = API.get('room/unread')

            request
                .then(response => {
                    let all = response.data.all
                    let left = 0
                    let i = 0
                    let info = []

                    _.each(response.data.info, (unread, key) => {
                        if (i < 5) {
                            info.push({
                                id: key,
                                title: unread.title,
                                unread: unread.unread
                            })
                        } else {
                            left += unread.unread
                        }
                    })

                    commit('setUnreadMessages', {
                        all: all,
                        left: left,
                        info: info
                    })
                })
                .catch(err => {
                    commit('setUnreadMessages', {
                        all: 0,
                        left: 0,
                        info: []
                    })
                })

            return request
        }
    },

    mutations: {
        setStatus(state, status) {
            state.status = status
        },

        setData(state, data) {
            _.each(data, (val, key) => {
                Vue.set(state.data, key, val)
            })

            EventBus.$emit('dashboard-updated-block', state.data['block'])

            // BARDZO brzydki hack
            window.root.$apply()
        },

        setSingleData(state, {type, data}) {
            Vue.set(state.data, type, data)

            if(type === 'block') {
                EventBus.$emit('dashboard-updated-block', data)
            }

            // BARDZO brzydki hack
            window.root.$apply()
        },

        setAlerts(state, count) {
            state.messages.alerts = count
        },

        setUnreadMessages(state, {all, left, info}) {
            state.messages.unread.all = all
            state.messages.unread.left = left
            Vue.set(state.messages.unread, 'info', info)
            window.root.$apply()
        },

        setPreviousAssemblies(state, data) {
            Vue.set(state, 'previousAssemblies', data)
            window.root.$apply()
        }
    },

    getters: {
        all: state => {
            return state.data
        },

        get: state => {
            return key => {
                return state.data[key]
            }
        },

        alerts: state => {
            return state.messages.alerts
        },

        unread: state => {
            return state.messages.unread
        },

        status: state => {
            return state.status
        },

        previousAssemblies: state => {
            return state.previousAssemblies
        }
    },

    plugins: [
        store => {
            const updateDashboard = () => {
                store.dispatch('dashboard/fetch')
            }
            const timeout = 1000 * 30 // every 30 seconds

            window.setInterval(updateDashboard, timeout)
        }
    ]
}
