<template lang="pug">
    #task-pack.same-color
        OptiCatalogPageByBenefits(v-if="page === 'by-benefits'"
            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"
            :offer-hoya-option="offerHoyaOption"

            @go-to-page="p => page = p"

            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesPageCatalog(
            v-if="page === 'catalog'"

            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"
            :offer-hoya-option="offerHoyaOption"

            @go-to-page="p => page = p"

            @lenses-was-updated="$emit('lenses-was-updated')")

        OptiTaskDetailsLensesPageSummary(
            v-if="page === 'summary'"

            :task="task"
            :is-offer="isOffer"
            :is-compare="isCompare"

            :shape="shape"
            :increment="increment"

            :block="block"
            :allow-edit-confirmed="allowEditConfirmed"
            :pending="pending"

            @go-to-page="p => page = p"

            @pending="p => $emit('pending', p)"
            @increment-up="$emit('increment-up')"
            @register-change="$emit('register-change')"
            @lenses-was-updated="$emit('lenses-was-updated')")
</template>

<script>
    import OptiCatalogPageByBenefits
        from '@/js/app/vue/components/Tasks/Details/Lenses/ByBenefits/OptiCatalogPageByBenefits'
    import OptiTaskDetailsLensesPageCatalog
        from '@/js/app/vue/components/Tasks/Details/Lenses/Pages/OptiTaskDetailsLensesPageCatalog'
    import OptiTaskDetailsLensesPageSummary
        from '@/js/app/vue/components/Tasks/Details/Lenses/Pages/OptiTaskDetailsLensesPageSummary'

    export default {
        name: 'OptiTaskDetailsLenses',
        components: {
            OptiCatalogPageByBenefits,
            OptiTaskDetailsLensesPageCatalog,
            OptiTaskDetailsLensesPageSummary
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: false,
                default: false
            },
            offerHoyaOption: {
                required: true
            },
            shape: {
                type: Object,
                required: true
            },
            increment: {
                type: Number,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                page: 'summary'
            }
        }
    }
</script>
