import _ from 'lodash'

export default class Employee {
    constructor(rawData) {
        this.raw = _.cloneDeep(rawData)
    }

    get active() {
        return !!this.raw.active
    }

    get activeDepartmentId() {
        return parseInt(this.raw.activeDepartmentId)
    }

    get color() {
        return parseInt(this.raw.color)
    }

    get created_at() {
        return _.cloneDeep(this.raw.created_at)
    }

    get date_employee() {
        return this.raw.date_employee
    }

    get email() {
        return this.raw.email
    }

    get firstName() {
        return this.raw.firstName
    }

    get id() {
        return this.raw.id
    }

    get ipad_serial_number() {
        return this.raw.ipad_serial_number
    }

    get lastName() {
        return this.raw.lastName
    }

    get login() {
        return this.raw.login
    }

    get pin() {
        return this.raw.pin
    }

    get position() {
        return this.raw.position
    }

    get position_name() {
        return this.raw.position_name
    }

    get updated_at() {
        return this.raw.updated_at
    }

    get week_h() {
        return this.raw.week_h
    }

    get name() {
        return this.lastName + ' ' + this.firstName
    }
}
