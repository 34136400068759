<template lang="pug">
    OptiDialog.fullscreen(:header="false" :footer="false")
        template(#body)
            div(style="margin:5px")
                .row(v-if="!loading")
                    .col-sm-4
                        form.form.form-horizontal
                            .form-group
                                label.col-xs-3.control-label(for="type") {{'catalog.graph.navi.TYPE'|translate}}
                                .col-xs-9
                                    select#type.form-control(:disabled="select_type.length === 1"
                                        v-model="navi.type" @change="force_render")
                                        option(v-for="t in select_type" :value="t") {{'catalog.graph.navi.type.' + t.toUpperCase()|translate}}

                    .col-sm-4.text-center
                        #direction.form-control-static
                            label.control-label.mr3(style="cursor:pointer" :class="{'text-normal': navi.direction}"
                                @click="change_navi_direction(false)") {{'catalog.graph.navi.direction.LR'|translate}}
                            label.switch.switch-lg.mr3
                                input(type="checkbox" v-model="navi.direction" @change="force_render")
                                span
                            label.control-label(style="cursor:pointer" :class="{'text-normal': !navi.direction}"
                                @click="change_navi_direction(true)") {{'catalog.graph.navi.direction.RL'|translate}}

                    .col-sm-4
                        OptiButton(v-show="!taskId && mermaidId && mermaidId !== active.main.id"
                            @click.native="select_mermaid_id(true)") {{'catalog.graph.SELECT_FROM_CATALOG'|translate}}
                            template(#icon)
                                i.fa.fa-hand-o-up

                        OptiButton(v-show="taskId && active.main.id && !active.main.extra.disabled.errors.length"
                            @click.native="selected_for_task") {{'tasks.benefits.graph.CONFIRM'|translate}}
                            template(#icon)
                                i.fa.fa-hand-o-up

                        .btn.btn-danger.pull-right(@click="closeFullScreen")
                            i.fa.fa-close

                    .col-xs-12(v-if="render")
                        PinchZoom
                            VueMermaid(
                                type="graph LR"
                                :nodes="data[direction_selected_name][navi.type]"

                                @click.native="mermaid_click"
                                @nodeClick="selected")

                        OptiCatalogGraphPanelInfo(
                            :active="active"

                            @go-to-category="go_to_category"
                            @close="() => {resetColors();reset()}")

                        #task-info(v-show="taskId")
                            span.mr3(v-if="type === 'offer'") {{'tasks.breadcrumb.OFFER_TASK'|translate}}
                            span.mr3(v-else) {{'tasks.breadcrumb.TASK'|translate}}

                            span \#{{taskId}}

                .row
                    .col-xs-12(style="margin-top:20px")
                        OptiDimmer(:active="loading")

                OptiCatalogGraphSearchLens(v-if="search_mode"
                    :data="data[direction_selected_name][navi.type]"
                    :navi-direction="navi.direction"

                    @selected="s => selected(s.id)"
                    @close="search_mode = false")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {FullScreenMode} from '@/js/app/vue/helpers/FullScreenMode'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import PinchZoom from 'vue-pinch-zoom'
    import VueMermaid from 'vue-mermaid/src/vue-mermaid'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiCatalogGraphPanelInfo from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraphPanelInfo'
    import OptiCatalogGraphSearchLens from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraphSearchLens'

    export default {
        name: 'OptiCatalogGraphDialog',
        components: {
            OptiDialog,
            OptiButton,
            PinchZoom,
            VueMermaid,
            OptiDimmer,
            OptiCatalogGraphPanelInfo,
            OptiCatalogGraphSearchLens
        },
        props: {
            taskId: {
                type: Number,
                required: false,
                default: 0
            },
            type: {
                type: String,
                required: false,
                default: ''
            },
            isCompare: {
                type: Boolean,
                required: false,
                default: false
            },
            mermaidId: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                navi: {
                    type: 'single',
                    direction: false,
                    direction_type: ['worst-best', 'best-worst']
                },

                data: [],
                task: {
                    types: [],
                    disabled: []
                },
                active: {
                    main: {
                        id: ''
                    },
                    compare: {
                        id: ''
                    }
                },

                iOS: ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
                    // iPad on iOS 13 detection
                    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
                render: true,
                search_mode: false,
                loading: true
            }
        },

        mounted() {
            if(!this.iOS) {
                FullScreenMode.open()

                window.addEventListener('fullscreenchange', this.changeFullscreen)
            }

            API.get('task/hoya/graph').then(res => {
                this.data = res.data

                this.disabled_by_task()
            }).catch(() => {
                this.$notify.error('catalog.graph.notify.error.LOAD')
            })
        },
        destroyed() {
            if(!this.iOS) {
                window.removeEventListener('fullscreenchange', this.changeFullscreen)
            }
        },

        methods: {
            disabled_by_task() {
                if(this.type && this.taskId) {
                    API.get('task/hoya/graph/validate/' + this.type + '/' + this.taskId + '/' + (this.isCompare ? '1' : '0')).then(res => {
                        this.task.types = res.data.types
                        this.task.disabled = res.data.disabled

                        if(!this.select_type.includes(this.navi.type)) {
                            this.navi.type = this.select_type[0]
                        }

                        this.select_mermaid_id(true)
                        this.set_disabled()
                        this.show_search()
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('tasks.benefits.graph.notify.error.LOAD')
                    })
                } else {
                    this.select_mermaid_id(true)
                    this.show_search()
                    this.loading = false
                }
            },
            select_mermaid_id(change_type) {
                if(this.mermaidId) {
                    _.forEach(this.data[this.direction_selected_name], (d, type) => {
                        if(d.map(data => data.id).includes(this.mermaidId)) {
                            if(change_type) {
                                this.navi.type = type

                                setTimeout(() => {
                                    this.selected(this.mermaidId)
                                }, 0)
                            } else if(this.navi.type === type) {
                                setTimeout(() => {
                                    this.selected(this.mermaidId)
                                }, 0)
                            }
                        }
                    })
                }
            },
            set_disabled() {
                setTimeout(() => {
                    _.forEach(this.task.disabled, (disabled, disabled_id) => {
                        let d = this.find_d(disabled_id)

                        if(d) {
                            d.extra.disabled = disabled

                            if(d.extra.disabled.errors.length) {
                                document.querySelector('[id^="flowchart-' + disabled_id + '-"]').classList.add('disabled')
                                document.querySelectorAll('[id^="L-' + disabled_id + '-"]').forEach(e => {
                                    e.classList.add('disabled')
                                })
                                document.getElementsByClassName('LE-' + disabled_id).forEach(e => {
                                    e.classList.add('disabled')
                                })
                                document.querySelectorAll('[id^="L-L-' + disabled_id + '-"]').forEach(e => {
                                    e.classList.add('disabled')
                                })
                                document.getElementsByClassName('L-LE-' + disabled_id).forEach(e => {
                                    e.classList.add('disabled')
                                })
                            }

                            if(!d.extra.disabled.errors.length && d.extra.disabled.warnings.length) {
                                document.querySelector('[id^="flowchart-' + disabled_id + '-"] i').classList.remove('hide')
                            }
                        }
                    })
                }, 0)
            },

            selected(id) {
                this.resetColors()

                if(this.active.main.id === id && !this.active.compare.id) {
                    this.reset()
                } else {
                    this.reset()

                    this.active.main = this.find_d(id)
                }

                if(this.active.main.id) {
                    document.querySelector('[id^="flowchart-' + id + '-"]').classList.add('node-active')

                    if(!this.active.main.extra.disabled.errors.length) {
                        this.active.main.next.forEach(next_id => {
                            if(this.active.main.id) {
                                document.querySelector('[id^="flowchart-' + next_id + '-"]').classList.add('node-active-sub')
                                document.querySelector('[id="L-' + id + '-' + next_id + '"]').classList.add('edge-path-active')
                                document.querySelector('[id="L-L-' + id + '-' + next_id + '"]').classList.add('edge-label-active')
                            }
                        })
                    }
                }

                this.search_mode = false
            },

            mermaid_click(e) {
                if(e.target.classList.contains('search')) {
                    this.search_lenses()

                    return null
                } else if(e.target.classList.contains('disabled')) {
                    return null
                }

                let selected = {
                    main: {
                        id: ''
                    },
                    compare: {
                        id: ''
                    }
                }

                e.target.classList.forEach(c => {
                    if(c.startsWith('L-LS-')) {
                        selected.main.id = c.replace('L-LS-', '').slice(0, -1)
                    }

                    if(c.startsWith('L-LE-')) {
                        selected.compare = this.find_d(c.replace('L-LE-', ''))
                    }
                })

                if(selected.main.id && selected.compare.id) {
                    if(selected.main.id === this.active.main.id && selected.compare.id === this.active.compare.id) {
                        this.resetColors()
                        this.reset()
                    } else {
                        this.reset()

                        this.selected(selected.main.id)

                        this.active.compare = selected.compare
                        this.active.compare.label = e.target.innerText
                        this.active.compare.diff = {
                            deleted: _.differenceBy(this.active.main.extra.pictograms, this.active.compare.extra.pictograms, 'id'),
                            added: _.differenceBy(this.active.compare.extra.pictograms, this.active.main.extra.pictograms, 'id')
                        }

                        document.querySelector('[id^="flowchart-' + this.active.main.id + '-"]').classList.add('double')
                        document.querySelector('[id^="flowchart-' + this.active.compare.id + '-"]').classList.add('double')
                        document.querySelector('[id="L-' + this.active.main.id + '-' + this.active.compare.id + '"]').classList.add('double')
                        document.querySelector('[id="L-L-' + this.active.main.id + '-' + this.active.compare.id + '"]').classList.add('double')
                    }
                }
            },

            find_d(id) {
                let _return = null

                this.data[this.direction_selected_name][this.navi.type].forEach(d => {
                    if(d.id === id) {
                        _return = d
                    }
                })

                return _return
            },

            change_navi_direction(bool) {
                if(this.navi.direction !== bool) {
                    this.navi.direction = bool

                    this.force_render()
                }
            },
            closeFullScreen() {
                if(!this.iOS) {
                    FullScreenMode.close()
                }

                this.$emit('close')
            },
            changeFullscreen() {
                if(!document.fullscreenElement) {
                    document.body.style.overflow = ''

                    this.$emit('close')
                }
            },

            go_to_category(category_id) {
                this.closeFullScreen()

                this.$state.go('app.catalog.category', {
                    id: category_id,
                    isOffer: this.type === 'offer' ? 1 : 0,
                    taskId: this.taskId
                })
            },

            resetColors() {
                let node_active = document.querySelectorAll('.node-active'),
                    node_active_sub = document.querySelectorAll('.node-active-sub'),
                    edge_path_active = document.querySelectorAll('.edge-path-active'),
                    edge_label_active = document.querySelectorAll('.edge-label-active')

                for(let i = 0; i < node_active.length; ++i) {
                    node_active[i].classList.remove('node-active')
                    node_active[i].classList.remove('double')
                }

                for(let i = 0; i < node_active_sub.length; ++i) {
                    node_active_sub[i].classList.remove('node-active-sub')
                    node_active_sub[i].classList.remove('double')
                }

                for(let i = 0; i < edge_path_active.length; ++i) {
                    edge_path_active[i].classList.remove('edge-path-active')
                    edge_path_active[i].classList.remove('double')
                }

                for(let i = 0; i < edge_label_active.length; ++i) {
                    edge_label_active[i].classList.remove('edge-label-active')
                    edge_label_active[i].classList.remove('double')
                }
            },
            reset() {
                this.active = {
                    main: {
                        id: ''
                    },
                    compare: {
                        id: ''
                    }
                }
            },

            selected_for_task() {
                this.closeFullScreen()

                this.$emit('selected', this.active.main)
            },

            async force_render() {
                this.render = false

                this.reset()

                await this.$nextTick()
                this.render = true

                this.set_disabled()
                this.show_search()
                this.select_mermaid_id(false)
            },

            search_lenses() {
                this.resetColors()
                this.reset()

                this.search_mode = true
            },
            show_search() {
                setTimeout(() => {
                    document.getElementsByClassName('search').forEach(e => {
                        e.textContent = '🔍'
                    })
                }, 0)
            }
        },
        computed: {
            direction_selected_name() {
                return this.navi.direction_type[this.navi.direction ? 1 : 0]
            },
            select_type() {
                let select_type = Object.keys(this.data[this.direction_selected_name])

                if(this.task.types.length) {
                    select_type = select_type.filter(s => this.task.types.includes(s))
                }

                return select_type
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-body {
        overflow: hidden !important;

        #mermaid {
            width: 100%;

            > svg[id^="mermaid-"] {
                width: 100%;
                height: calc(100dvh - 50px);

                .node.clickable {
                    &.node-active:not(.disabled) > rect {
                        fill: greenyellow;
                        stroke: royalblue;
                        stroke-width: 3px;
                    }

                    &.node-active-sub:not(.disabled) {
                        > rect {
                            fill: lightgreen;
                            stroke: aqua;
                            stroke-width: 1px;
                        }

                        &.double > rect {
                            fill: aqua;
                            stroke: royalblue;
                            stroke-width: 3px;
                        }
                    }

                    &.disabled {
                        opacity: .3 !important;
                    }
                }

                .edgePath {
                    &.edge-path-active:not(.disabled) {
                        > .path {
                            stroke: deeppink !important;
                            stroke-width: 3px;
                        }

                        > defs > marker {
                            stroke: deeppink;

                            > path {
                                fill: deeppink !important;
                            }
                        }

                        &.double {
                            > .path {
                                stroke: royalblue !important;
                            }

                            > defs > marker {
                                stroke: royalblue;

                                > path {
                                    fill: royalblue !important;
                                }
                            }
                        }
                    }

                    &.disabled {
                        opacity: .3 !important;
                    }
                }

                .edgeLabel {
                    background-color: inherit !important;

                    rect {
                        opacity: 0 !important;
                    }

                    .edgeLabel {
                        cursor: pointer;
                    }

                    &.edge-label-active:not(.disabled) {
                        background-color: #eee !important;
                        color: deeppink !important;

                        &.double {
                            color: royalblue !important;
                        }
                    }

                    &.disabled {
                        color: grey !important;
                        cursor: move;
                    }
                }

                g.label > g > foreignObject > div {
                    display: flex !important;
                    justify-content: space-around;

                    > span > span {
                        display: block;

                        > i.fa-exclamation-circle {
                            color: red;
                            display: inline;
                            margin-left: 5px;
                        }
                    }

                    > .search {
                        font-size: 20px;

                        &:hover {
                            color: royalblue;
                        }
                    }
                }
            }
        }

        .pinch-zoom-wrapper {
            background-color: inherit !important;

            > .pz-zoom-button.pz-zoom-control-position-bottom {
                display: none;
            }
        }

        #task-info {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 10px;
            padding: 5px;
            font-size: 16px;
            font-weight: 700;
        }
    }
</style>