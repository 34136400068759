import {i18n} from '@/js/app/vue/i18n-setup'

import helper from '@/js/app/vue/store/offer/helper'
import _ from 'lodash'

export default {
    getQuestionsFrames: state => state.questions.frames,
    getIndexQuestionFrames: state => state.index_question_frames,
    sthChecked: state => Object.values(state.questions.frames[state.index_question_frames].options).map(o => o.selected).includes(true),

    getCurrentQuestionHoya: state => {
        let current_path = _.cloneDeep(state.questions.hoya)

        state.selected_questions_hoya.forEach(selected_question_hoya => {
            current_path.options.forEach(question_hoya => {
                if(selected_question_hoya === question_hoya.name) {
                    current_path = _.cloneDeep(question_hoya)

                    if(current_path.options.filter(o => o.type_of_beauty).length) {
                        let selected_type_of_beauty = []

                        _.forEach(state.questions.frames[8].options, (option, key) => {
                            if(option.selected) {
                                selected_type_of_beauty.push(key)
                            }
                        })

                        if(!selected_type_of_beauty.includes('all')) {
                            current_path.options = current_path.options.filter(o => o.type_of_beauty.some(r => selected_type_of_beauty.includes(r)))
                        }
                    }
                }
            })
        })

        return current_path
    },
    getIndexQuestionHoya: state => state.selected_questions_hoya.length - 1,
    getSelectedCurrentHoyaOption: state => state.selected_current_hoya_option,

    getProducts: state => state.products,
    getProductsCount: state => state.products_count,

    getColors: state => state.colors,
    getColorsHeader: state => state.colors_header,

    getFilters: state => state.filters,
    isValidFilters: state => {
        let _return = true

        state.filters.selected.forEach(filter => {
            if(!helper.isValidFilter(filter)) {
                _return = false
            }
        })

        return _return
    },

    getPatient: state => state.patient,
    getChoseProducts: state => state.chose_products,
    getFavorites: state => state.favorites,

    getDepartmentId: state => state.department_id,

    getPaginator: state => state.paginator,

    getSelected: state => {
        let _return = {
            questions: {
                frames: [],
                hoya: []
            },
            filters: []
        }

        // ramki
        state.questions.frames.forEach(question => {
            _.forEach(question.options, (option, option_key) => {
                if(option.selected) {
                    let option_translated = i18n.t('catalog.offer.questions.' + question.name + '.options.' + option_key.toUpperCase())

                    if(!_return.questions.frames.map(q => q.name).includes(question.name)) {
                        _return.questions.frames.push({
                            name: question.name,
                            options: [{
                                key: option_key,
                                translated: option_translated
                            }],
                            count: question.count
                        })
                    } else {
                        _return.questions.frames.forEach(q => {
                            if(q.name === question.name) {
                                q.options.push({
                                    key: option_key,
                                    translated: option_translated
                                })
                            }
                        })
                    }
                }
            })
        })

        _return.questions.frames.forEach(q => {
            q.options_translated = q.options.map(o => o.translated).join(', ')
        })

        // hoya
        let current_path = _.cloneDeep(state.questions.hoya)

        state.selected_questions_hoya.forEach(selected_question_hoya => {
            let selected = selected_question_hoya ? selected_question_hoya : state.selected_current_hoya_option

            current_path.options.forEach(question_hoya => {
                if(selected === question_hoya.name) {
                    _return.questions.hoya.push({
                        name: current_path.name,
                        option: selected
                    })

                    current_path = _.cloneDeep(question_hoya)
                }
            })
        })

        // filtry
        state.filters.selected.forEach(filter => {
            let option = ''

            switch(filter.type) {
                case 'slider':
                    option = i18n.t('catalog.offer.filters.operators.types.from-to').toLowerCase() + ' ' +
                        helper.number(filter.from, filter.float ? 2 : 0) + ' - ' + helper.number(filter.to, filter.float ? 2 : 0)

                    break

                case 'operators':
                    if(filter.operator) {
                        option = i18n.t('catalog.offer.filters.operators.types.' + filter.operator).toLowerCase() + ' ' +
                            (filter.operator === 'from-to' ? helper.number(filter.from, filter.float ? 2 : 0) + ' - ' +
                                helper.number(filter.to, filter.float ? 2 : 0) : helper.number(filter.value, filter.float ? 2 : 0))
                    }

                    break

                case 'select':
                    filter.options.forEach(o => {
                        if(o.value == filter.option) { // tutaj musi być ==
                            option = filter.prefix ? i18n.t(filter.prefix + '.' + o.name) : o.name
                        }
                    })

                    break

                case 'multiselect':
                case 'multiselect-group':
                    if(filter.option) {
                        let or_and = i18n.t('catalog.offer.filters.multiselect.' +
                            (typeof filter.or_and === 'undefined' ? 'or' : filter.or_and).toUpperCase())

                        option = filter.option.map(o => {
                            let name = o.name

                            if(name.includes('lang:')) {
                                let  part_names = name.split('lang:')

                                name = part_names[0] + ' (' + i18n.t(part_names[1]) + ')'
                            }

                            return filter.prefix ? i18n.t(filter.prefix + '.' + name) : name
                        }).join(' ' + or_and + ' ')
                    }
            }

            _return.filters.push({
                name: filter.name ? i18n.t('catalog.offer.filters.names.' + filter.name) + ' |' : '',
                option: option,
                valid: helper.isValidFilter(filter)
            })
        })

        return _return
    },

    isSkipped: state => {
        return typeof state.questions.frames[4] !== 'undefined' && !state.questions.frames[4].options.frame.selected &&
            state.questions.frames[4].options.sunglasses.selected
    },
    toSkip: state => state.to_skip,

    isLastStep: (state, getters) => {
        if(state.index_question_frames === state.questions.frames.length - 1 && getters.isSkipped) {
            return true
        }

        let _return = false

        if(typeof getters.getCurrentQuestionHoya.options !== 'undefined') {
            if(!getters.getCurrentQuestionHoya.options.filter(option => typeof option.options !== 'undefined').length) {
                _return = true
            } else if(getters.getSelectedCurrentHoyaOption) {
                getters.getCurrentQuestionHoya.options.forEach(option => {
                    if(option.name === getters.getSelectedCurrentHoyaOption && typeof option.options === 'undefined') {
                        _return = true
                    }
                })
            }
        }

        return _return
    },

    getExamplesChunk: state => {
        let img = [],
            mode = {
                man: _.find(state.questions.frames[1].options, (o, key) => ['man', 'boy', 'child', 'all'].includes(key.toLowerCase()) && o.selected),
                woman: _.find(state.questions.frames[1].options, (o, key) => ['woman', 'girl', 'child', 'all'].includes(key.toLowerCase()) && o.selected)
            }

        _.forEach(state.help.img.examples.info, (examples, type) => {
            if(!_.has(mode, type) || (_.has(mode, type) && mode[type])) {
                examples.forEach(example => {
                    let caption_preg = example.match(/example_\d_(.*?)\.jpg/)

                    img.push({
                        path: 'img/offer/' + state.help.section + '/' + state.help.option_key +
                            (type !== 'general' ? '/' + type : '') + '/' + example,
                        caption: caption_preg && caption_preg.length === 2 ? caption_preg[1].replaceAll('_', ' ') : ''
                    })
                })
            }
        })

        return _.chunk(img, state.help.img.examples.style ? 12 / parseInt(state.help.img.examples.style.replace(/^\D+/g, '')) : 1)
    },

    isFindOrCreatePatient: state => state.mode === 'choice',

    getEnglishVersion: state => state.english_version,
    getMode: state => state.mode,
    getHelp: state => state.help,

    disabledFetch: (state, getters) => state.loading.products || !state.changed || !getters.isValidFilters,
    disabledConfirmChoice: state => !state.patient.id || !state.chose_products.length ||
        state.chose_products.filter(p => !p.choice_by_client).length > 3
        || !!(state.colors.length && state.chose_products.filter(p => p.color === null).length),

    getDialog: state => type => state.dialog[type],
    getLoading: state => type => state.loading[type],
    getLoadingPhoto: state => {
        let _return = false

        state.chose_products.forEach(product => {
            if(product.photo.loading) {
                _return = true
            }
        })

        return _return
    }
}