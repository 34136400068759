<template lang="pug">
    .feedback(@click="grab" v-show="!open")
        i.fa.fa-bug.mr3
        span {{'bugtrack.BUGTRACK'|translate}}

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogErrorsAdd(:canvas="canvas" @closed="open = false")

        OptiDimmer(:active="loading")
</template>

<script>
    import OptiDialogErrorsAdd from '@/js/app/vue/components/Errors/Dialogs/OptiDialogErrorsAdd'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiErrorsAdd',
        components: {
            OptiDialogErrorsAdd,
            OptiDimmer

        },
        data() {
            return {
                canvas: null,

                open: false,
                loading: false
            }
        },
        mounted() {

        },
        methods: {
            grab() {
                this.loading = true

                html2canvas($('#mainContent'), {
                    background: '#f5f7fa'
                }).then(canvas => {
                    this.canvas = canvas

                    this.open = true
                    this.loading = false
                }, () => {
                    this.loading = false
                })
            }
        },
        computed: {

        }
    }
</script>
