<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'user.tests.HEADING'|translate}}

            .label.label-danger.pull-right(v-show="tests.length") {{tests.length}}

        .row(v-show="!loading")
            .col-xs-12.test(v-for="(test, index) in tests" :key="test.id")
                .media-box(:class="{'bg-separator': index % 2}")
                    .media-box-body
                        p.m0
                            a.link(style="font-size:18px" :href="$state.href('app.test.results.details', {test_result_id: test.id})"
                                target="_blank") {{test.name}} \#{{test.id}}
                            small.pull-right.text-muted {{test.date}}

                        div(style="font-size:18px")
                            span {{test.points}} / {{test.max|points}} ({{test.percent|number(2)}} %)
                            .pull-right.fa-lg
                                i.fa.fa-check-circle.text-success(v-show="test.pass")
                                i.fa.fa-minus-circle.text-danger(v-show="!test.pass")

            .col-xs-12.text-center.text-muted(style="padding:15px" v-if="!tests.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'user.tests.NONE'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersEditTests',
        components: {
            OptiDimmer
        },
        props: {
            userId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                tests: [],

                loading: true
            }
        },
        mounted() {
            API.get('user/tests/' + this.userId).then(res => {
                this.tests = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('user.tests.notify.error.LOAD')
            })
        }
    }
</script>

<style scoped lang="less">
    .test {
        &:not(:last-child) > .media-box {
            border-bottom: 1px solid #000;
        }

        > .media-box {
            padding: 10px;
        }
    }
</style>