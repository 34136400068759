export default {
    getCurrentQuantity(items, product_id) {
        let quantity = 0

        items.forEach(item => {
            if(item.productId === product_id) {
                quantity = item.quantity
            }
        })

        return quantity
    },
    stock(carts, type, product) {
        switch(type) {
            case 'order':
                return product.quantity >= (this.getCurrentQuantity(carts.order.items, product.id) + 1)
            case 'sendback':
                return product.depository.stock >= (this.getCurrentQuantity(carts.sendback.items, product.id) + 1)
        }

        return true
    },
    duplicate(carts, type, product) {
        let compare = type === 'order' ? 'sendback' : 'order',
            duplicate = false

        if(type === 'order' || type === 'sendback') {
            carts[compare].items.forEach(item => {
                if(item.productId === product.id) {
                    duplicate = true
                }
            })
        }

        return duplicate
    }
}
