<template lang="pug">
    .table-responsive
        table.table.table-striped.middle(style="margin-bottom:0")
            tbody
                tr(v-show="heading && ((result.selected.length && result.selected[0] && result.selected[1]) || maddox.r_suppression || maddox.l_suppression)")
                    td(colspan="5")
                        i.fa.fa-navicon.mr3
                        span {{'optometrist.correction.MADDOX'|translate}}
                tr(v-if="(result.selected.length && result.selected[0] && result.selected[1]) || maddox.r_suppression || maddox.l_suppression")
                    td
                        img(style="width:70px" v-if="!maddox.r_suppression && !maddox.l_suppression"
                            :src="'img/optometrist/screening-test/maddox/' + result.selected[0] + '.jpg'")
                    td(style="white-space:nowrap")
                        div(v-show="result.selected.length && result.selected[0][2] !== 'h' && result.selected[0][2] !== 'v' && !maddox.r_suppression && !maddox.l_suppression")
                            div(v-show="!maddox[result.selected[0]]") {{'prescription.PRISM'|translate}} {{maddox[result.selected[0]]}}
                            div {{'prescription.PRISMANGLE'|translate}} {{angle_maddox(result.selected[0])}}
                        div(v-show="result.selected.length && result.selected[0][2] === 'h'") ORTO
                        div(v-show="result.selected.length && result.selected[0][2] === 'v'") ORTO
                    td(style="white-space:nowrap" rowspan="2")
                        div(v-show="result.r.prism")
                            div {{'prescription.RIGHT'|translate}}
                            div {{'prescription.PRISM'|translate}} {{result.r.prism|number(2)}}
                            div {{'prescription.PRISMANGLE'|translate}} {{result.r.angle|number(0)}}

                        div(v-show="result.l.prism")
                            div {{'prescription.LEFT'|translate}}
                            div {{'prescription.PRISM'|translate}} {{result.l.prism|number(2)}}
                            div {{'prescription.PRISMANGLE'|translate}} {{result.l.angle|number(0)}}
                    td
                        span(v-show="description_maddox(result.selected[0], 'r')") {{'optometrist.result.CORRECTION'|translate}} - {{description_maddox(result.selected[0], 'r')}}
                    td.text-right(rowspan="2" v-show="icon")
                        i.fa.fa-2x.fa-remove.warn.mr3(v-show="!summary()")
                        span.fa.fa-2x.fa-check.text-success(v-show="summary()")
                tr(v-if="(result.selected.length && result.selected[0] && result.selected[1]) || maddox.r_suppression || maddox.l_suppression")
                    td
                        img(style="width:70px" v-if="!maddox.r_suppression && !maddox.l_suppression"
                            :src="'img/optometrist/screening-test/maddox/' + result.selected[1] + '.jpg'")
                    td(style="white-space:nowrap")
                        div(v-show="result.selected.length && result.selected[1][2] !== 'h' && result.selected[1][2] !== 'v' && !maddox.r_suppression && !maddox.l_suppression")
                            div(v-show="!maddox[result.selected[1]]") {{'prescription.PRISM'|translate}} {{maddox[result.selected[1]]}}
                            div {{'prescription.PRISMANGLE'|translate}} {{angle_maddox(result.selected[1])}}
                        div(v-show="result.selected.length && result.selected[1][2] === 'h'") ORTO
                        div(v-show="result.selected.length && result.selected[1][2] === 'v'") ORTO
                    td
                        span(v-show="description_maddox(result.selected[1], 'l')") {{'optometrist.result.CORRECTION'|translate}} - {{description_maddox(result.selected[1], 'l')}}
                tr(v-show="noResult && (!(result.selected.length && result.selected[0] && result.selected[1]) && !maddox.r_suppression && !maddox.l_suppression)")
                    td.text-center.warn.bold(colspan="4") {{'optometrist.correction.NORESULT'|translate}}

</template>

<script>
    export default {
        name: 'OptiOptometristsDescriptionsMaddox',
        components: {

        },
        props: {
            heading: {
                type: Boolean,
                required: false,
                default: false
            },
            icon: {
                type: Boolean,
                required: false,
                default: false
            },
            noResult: {
                type: Boolean,
                required: false,
                default: false
            },

            maddox: {
                type: Object,
                required: true
            },
            result: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            angle_maddox(maddox) {
                switch(maddox) {
                    case 'r_l': return 180
                    case 'r_t': return 270
                    case 'r_b': return 90

                    case 'l_l': return 180
                    case 'l_t': return 270
                    case 'l_b': return 90
                }

                return 0
            },
            description_maddox(maddox, side) {
                switch(maddox) {
                    case 'r_l': return 'Nieprawidłowe ustawienie oczu w poziomie EXO'
                    case 'r_v': return 'Prawidłowe ustawienie oczu w poziomie - ORTO'
                    case 'r_r': return 'Nieprawidłowe ustawienie oczu w poziomie ESO'
                    case 'r_t': return 'Nieprawidłowe ustawienie oczu w pionie HIPO'
                    case 'r_h': return 'Prawidłowe ustawienie oczu w pionie - ORTO'
                    case 'r_b': return 'Nieprawidłowe ustawienie oczu w pionie HIPER'

                    case 'l_l': return 'Nieprawidłowe ustawienie oczu w poziomie ESO'
                    case 'l_v': return 'Prawidłowe ustawienie oczu w poziomie - ORTO'
                    case 'l_r': return 'Nieprawidłowe ustawienie oczu w poziomie EXO'
                    case 'l_t': return 'Nieprawidłowe ustawienie oczu w pionie HIPO'
                    case 'l_h': return 'Prawidłowe ustawienie oczu w pionie - ORTO'
                    case 'l_b': return 'Nieprawidłowe ustawienie oczu w pionie HIPER'
                }

                if(this.maddox.r_suppression && side === 'r') {
                    return 'Tłumienie prawego oka'
                } else if(this.maddox.l_suppression && side === 'l') {
                    return 'Tłumienie lewego oka'
                }

                return ''
            },
            summary() {
                return Object.keys(this.result).length > 0 && this.result.selected.length === 2 &&
                    ((this.result.selected[0] === 'r_v' && this.result.selected[1] === 'r_h') ||
                        (this.result.selected[0] === 'l_v' && this.result.selected[1] === 'l_h'))
            }
        }
    }
</script>