<template lang="pug">
    #grid-brand-products(:class="{'is_dragging': is_dragging}")
        .col-xs-4
            .row
                .grid-brand-container
                    div(v-for="previous in products.previous" :key="previous.id")
                        OptiLayoutProductsGridBrand(
                            :brand="previous"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")

                        OptiLayoutProductsGridBrand(v-show="previous.supply.products_parsed.length"
                            :brand="previous.supply"
                            :supply="true"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")

                .text-center.bold.text-muted(v-show="products.previous && !products.previous.length")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'layout.products.no.PREVIOUS'|translate}}
            OptiDimmer(:active="loading")

        .col-xs-4
            .row
                .grid-brand-container
                    div(v-for="brand in products.brands" :key="brand.id")
                        OptiLayoutProductsGridBrand(
                            :brand="brand"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")

                        OptiLayoutProductsGridBrand(v-show="brand.supply.products_parsed.length"
                            :brand="brand.supply"
                            :supply="true"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")
            OptiDimmer(:active="loading")

        .col-xs-4
            .row
                .grid-brand-container
                    div(v-for="next in products.next" :key="next.id")
                        OptiLayoutProductsGridBrand(
                            :brand="next"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")

                        OptiLayoutProductsGridBrand(v-show="next.supply.products_parsed.length"
                            :brand="next.supply"
                            :supply="true"

                            @set-products="p => products = parseBrandProducts(p)"
                            @set-dragging="d => is_dragging = d"
                            @set-loading="l => loading = l")

                .text-center.bold.text-muted(v-show="products.next && !products.next.length")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'layout.products.no.NEXT'|translate}}
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiLayoutProductsGridBrand from '@/js/app/vue/components/Layout/Products/OptiLayoutProductsGridBrand'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiLayoutProducts',
        components: {
            OptiLayoutProductsGridBrand,
            OptiDimmer
        },
        data() {
            return {
                products: {},

                translate_none: this.$filters.translate('layout.products.NONE'),

                is_dragging: false,
                loading: true
            }
        },
        mounted() {
            this.$emit('set-navi-loading', true)

            API.get('layout/room/products/' + this.$state.params.brandId).then(res => {
                this.products = this.parseBrandProducts(res.data)
            }).finally(() => {
                this.loading = false
                this.$emit('set-navi-loading', false)
            }).catch(() => {
                this.$notify.error('layout.products.notify.error.LOAD')
            })
        },
        methods: {
            parseBrandProducts(data) {
                _.forEach(data, brands => {
                    brands.forEach(brand => {
                        brand.products_parsed = this.parseProducts(brand)
                        brand.supply.products_parsed = this.parseProducts(brand.supply)
                    })
                })

                return data
            },
            parseProducts(brand) {
                let products = _.cloneDeep(brand.products)

                while(products.length < brand.size) {
                    products.push({
                        product_id: 0,
                        product: {
                            name: '< ' + this.translate_none + ' >'
                        }
                    })
                }

                return products
            }
        }
    }
</script>