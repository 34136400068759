<template lang="pug">
    .row(style="margin-top:20px")
        OptiDimmer(:active="active")

        .col-xs-12(v-if="message")
            a.alert.alert-warning(style="display:block;color:#000;text-decoration:none"
                :href="$state.href(message.sref.state, message.sref.params)" :title="'default.REFERENCETYPE'|translate")
                i.fa.fa-exclamation-circle.mr3
                span(v-html="message.text")

        .col-xs-4.col-xs-offset-4(style="margin-bottom:20px" v-if="show_range")
            LineChart(
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

        .col-xs-12(v-if="chartData")
            .row
                .col-sm-6.col-xs-12.text-center-xs(style="margin-bottom:5px;font-size:20px")
                    a.link(v-if="task_id" :href="$state.href(is_offer ? 'app.catalog.offer-temporary-task' : 'app.task.detail', {id: task_id})"
                        ) {{'tasks.breadcrumb.' + (is_offer ? 'OFFER_TASK' : 'TASK')|translate}} \#{{task_id}}

                .col-sm-6.col-xs-12.text-right.text-center-xs(style="margin-bottom:5px")
                    OptiSwitchToggleButton(:checked="show_range" @input="$emit('change')")
                        template(#left) {{'catalog.lenses.CATALOG_PAGE'|translate}}
                        template(#right) {{'catalog.lenses.RANGES'|translate}}

                .col-xs-12.text-center.warn(style="margin-bottom:5px" v-show="!chartData.datasets.length")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'catalog.lenses.ranges.notify.warning.NO_RANGES'|translate}}

        .col-xs-12(v-if="$uac.permission('catalog.lenses')")
            .panel.panel-primary
                .panel-heading(:style="{'cursor': $uac.permission('catalog.lenses') && 'pointer'}"
                    @click="edit_ranges = $uac.permission('catalog.lenses') ? !edit_ranges : false")
                    i.fa.fa-navicon.mr3
                    span {{'catalog.lenses.RANGES'|translate}}

                    .pull-right(v-show="$uac.permission('catalog.lenses')")
                        i.fa(:class="{'fa-arrow-up': !edit_ranges, 'fa-arrow-down': edit_ranges}")

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr.text-center
                                th {{'catalog.lenses.ranges.DIAMETER'|translate}}
                                th(v-show="edit_ranges") X
                                th {{'catalog.lenses.ranges.SPHEREFROM'|translate}}
                                th {{'catalog.lenses.ranges.SPHERETO'|translate}}
                                th {{'catalog.lenses.ranges.CYLINDERFROM'|translate}}
                                th {{'catalog.lenses.ranges.CYLINDERTO'|translate}}
                                th {{'catalog.lenses.ranges.TOP'|translate}}
                                th {{'catalog.lenses.ranges.BOTTOM'|translate}}
                                th(v-show="edit_ranges")
                        tbody
                            template(v-for="range in ranges")
                                tr
                                    td(style="width:14%")
                                        input#diameter.form-control.text-center(v-if="edit_ranges" v-model="range.diameter")
                                        .form-control.text-center(disabled v-else :class="{'star-info': range.x}"
                                            ) {{range.diameter + (range.x ? ' + X' : '')}}

                                    td.text-center(v-show="edit_ranges")
                                        .checkbox.c-checkbox.checkbox-info
                                            label
                                                input(type="checkbox" v-model="range.x")
                                                span.fa.fa-check

                                    td(style="width:14%")
                                        input#sphere_start.form-control.text-center(:disabled="!edit_ranges" v-model="range.sphere_start")

                                    td(style="width:14%")
                                        input#sphere_stop.form-control.text-center(:disabled="!edit_ranges" v-model="range.sphere_stop")

                                    td(style="width:14%")
                                        input#cylinder_start.form-control.text-center(:disabled="!edit_ranges" v-model="range.cylinder_start")

                                    td(style="width:14%")
                                        input#cylinder_stop.form-control.text-center(:disabled="!edit_ranges" v-model="range.cylinder_stop")

                                    td(style="width:15%")
                                        select#top.form-control.text-center(name="top" :disabled="!edit_ranges" v-model="range.top")
                                            option(v-for="option in topOptions" :value="option.value") {{option.name}}

                                    td(style="width:15%")
                                        select#bottom.form-control.text-center(name="bottom" :disabled="!edit_ranges" v-model="range.bottom")
                                            option(v-for="option in topOptions" :value="option.value") {{option.name}}

                                    td(v-show="edit_ranges")
                                        .btn.btn-danger(@click="removeRow(range)")
                                            i.fa.fa-trash

                                tr.tr-task-range(v-if="range.task && !edit_ranges")
                                    td(colspan="100")
                                        div(v-for="side in ['r', 'l']")
                                            span(:class="{'text-success': range.task[side].sphere.pass, 'text-danger': !range.task[side].sphere.pass}"
                                                ) {{'prescription.' + (side === 'r' ? 'RIGHT' : 'LEFT')|translate}}
                                            span(:class="{'text-success': range.task[side].cylinder.pass, 'text-danger': !range.task[side].cylinder.pass}"
                                                ) {{'prescription.CYLINDER'|translate}} {{range.task[side].cylinder.value|plus}} {{range.task[side].cylinder.range}}
                                            span(:class="{'text-success': range.task[side].sphere.pass, 'text-danger': !range.task[side].sphere.pass}"
                                                ) {{'prescription.SPHERE'|translate}} {{range.task[side].sphere.value|plus}} {{range.task[side].sphere.range}}

                            tr.text-muted.text-center(v-show="!ranges.length")
                                td(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                            tr.warn.text-center(v-show="invalid")
                                td(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'catalog.lenses.ranges.notify.error.INVALID'|translate}}

                        tfoot(v-show="!edit_ranges && ranges.filter(r => r.x).length")
                            tr.text-right
                                td(colspan="100")
                                    span.star-info.text-info.mr3
                                    span.text-info - {{'catalog.lenses.ranges.X_TOOLTIP'|translate}}

                .panel-footer(v-show="edit_ranges")
                    .row
                        .col-sm-4.text-left.text-center-xs.text-left-lg
                            OptiButton(type="default" @click.native="refreshTable") {{'catalog.lenses.REFRESH'|translate}}
                                template(#icon)
                                    i.fa.fa-refresh

                        .col-sm-4.text-center
                            OptiButton(type="success" @click.native="addRow") {{'catalog.lenses.ranges.ADD_ROW'|translate}}
                                template(#icon)
                                    i.fa.fa-plus

                        .col-sm-4.text-right.text-center-xs.text-right-lg
                            OptiButton(type="success" :disabled="invalid"
                                text="catalog.lenses.save.confirm" @confirmed="updateChanges") {{'default.SAVE'|translate}}
                                template(#icon)
                                    i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogRanges',
        components: {
            OptiDimmer,
            LineChart,
            OptiSwitchToggleButton,
            OptiButton
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            },
            show_range: {
                type: Boolean
            }
        },
        data() {
            let leftAxis = null,
                filters = this.$filters

            return {
                chartData: null,
                active: false,
                edit_ranges: false,
                ranges: [],
                delete: [],

                options: {
                    tooltips: {
                        titleAlign: 'center',
                        intersect: false,
                        callbacks: {
                            title(tooltipItems, data) {
                                if(data.datasets[tooltipItems[0].datasetIndex].data_type !== 'power') {
                                    return filters.translate('prescription.CYLINDER').toLowerCase() + ' ' +
                                        filters.plus(data.labels[tooltipItems[0].index])
                                }

                                return ''
                            },
                            label(tooltipItems, data) {
                                if(data.datasets[tooltipItems.datasetIndex].data_type === 'power') {
                                    return data.datasets[tooltipItems.datasetIndex].data_text
                                } else {
                                    let result = {
                                        diameter: data.datasets[tooltipItems.datasetIndex].label.replace('_', ''),
                                        ext: data.datasets[tooltipItems.datasetIndex].ext,
                                        spheres: []
                                    }

                                    data.datasets.forEach((d, key) => {
                                        if(result.diameter === d.label && result.ext === d.ext) {
                                            let from = data.datasets[key].data[tooltipItems.index],
                                                to = data.datasets[key + 1].data[tooltipItems.index]

                                            if(typeof from !== 'undefined' && from !== null && typeof to !== 'undefined' && to !== null &&
                                                !result.spheres.filter(s => s.from === from && s.to === to).length) {
                                                result.spheres.push({
                                                    from: from,
                                                    to: to
                                                })
                                            }
                                        }
                                    })

                                    return 'φ ' + result.diameter + ' | ' + filters.translate('prescription.SPHERE').toLowerCase() + ' ' +
                                        result.spheres.map(s => '<' + filters.plus(s.from) + ', ' + filters.plus(s.to) + '>').join(' ')
                                }
                            }
                        },
                    },
                    layout: {
                        padding: {
                            right: 30
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: true,
                        labels: {
                            filter: item => parseInt(item.text) && !item.text.toString().endsWith('_')
                        },
                        onClick(event, legendItem) {
                            let ci = this.chart,
                                meta = ci.getDatasetMeta(legendItem.datasetIndex)

                            meta.hidden = meta.hidden === null ? !ci.data.datasets[legendItem.datasetIndex].hidden : null

                            if(legendItem.datasetIndex <= 1) {
                                // pierwszy lub drugi item to togglujemy razem
                                [
                                    ci.getDatasetMeta(0),
                                    ci.getDatasetMeta(1)
                                ].forEach(m => {
                                    m.hidden = meta.hidden
                                })
                            }

                            if(legendItem.datasetIndex > 1 && legendItem.datasetIndex % 2 === 0) {
                                [
                                    ci.getDatasetMeta(legendItem.datasetIndex),
                                    ci.getDatasetMeta(legendItem.datasetIndex + 1)
                                ].forEach(m => {
                                    m.hidden = meta.hidden
                                })
                            }

                            if(legendItem.datasetIndex >= 3 && legendItem.datasetIndex % 2 !== 0) {
                                [
                                    ci.getDatasetMeta(legendItem.datasetIndex),
                                    ci.getDatasetMeta(legendItem.datasetIndex - 1)
                                ].forEach(m => {
                                    m.hidden = meta.hidden
                                })
                            }
                            ci.update()
                        }
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                callback: (value, index) => index % 4 === 0 ? value : '',
                                stepSize: 0.25,
                            },
                            gridLines: {
                                zeroLineWidth: 3,
                                zeroLineColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                callback: (value, index) => index % 4 === 0 ? value : '',
                                stepSize: 0.25
                            },
                            gridLines: {
                                zeroLineWidth: 3,
                                zeroLineColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            afterDataLimits(axis) {
                                leftAxis = axis
                            }
                        }]
                    }
                },
                styles: {
                    height: '700px',
                    width: '350px',
                    backgroundColor: 'rgba(255,255,255)'
                },
                topOptions: [{
                    value: 0,
                    name: 'Równo'
                }, {
                    value: 1,
                    name: '0.25'
                }, {
                    value: 2,
                    name: '0.5'
                }],

                message: null,

                is_offer: this.$state.params.isOffer ? !!parseInt(this.$state.params.isOffer) : false,
                task_id: this.$state.params.taskId ? parseInt(this.$state.params.taskId) : 0
            }
        },
        mounted() {
            this.startUp()
        },
        methods: {
            startUp() {
                this.active = true

                API.get('catalog/hoya/getRanges/' + this.categoryId + '/' + (this.is_offer ? '1' : '0') + '/' + this.task_id).then(res => {
                    this.ranges = res.data
                }).then(() => {
                    this.drawRange()
                }).finally(() => {
                    this.active = false
                }).catch(() => {
                    this.$notify.error('catalog.lenses.ranges.notify.error.LOAD')
                })
            },
            addRow() {
                this.ranges.push({
                    id: 0,
                    diameter: 0,
                    x: false,
                    sphere_start: 0,
                    sphere_stop: 0,
                    cylinder_start: 0,
                    cylinder_stop: 0,
                    top: 0,
                    bottom: 0
                })
            },
            refreshTable() {
                this.edit_ranges = false
                this.startUp()
            },
            drawRange() {
                API.post('catalog/hoya/getRangePoints', {
                    categoryId: this.categoryId,
                    ranges: this.ranges
                }).then(res => {
                    this.message = res.data.message
                    this.chartData = res.data.chartData

                    this.setPowerPoints()
                })
            },
            setPowerPoints() {
                if(this.ranges.length && this.ranges[0].task) {
                    ['r', 'l'].forEach(side => {
                        let sph = this.ranges[0].task[side].sphere.value,
                            cyl = this.ranges[0].task[side].cylinder.value,
                            side_text = this.$filters.translate('prescription.' + (side === 'r' ? 'RIGHT' : 'LEFT')),
                            data = _.range(0, cyl / 0.25)

                        _.fill(data, null)

                        data.push(sph)

                        this.chartData.datasets.push({
                            label: side_text,
                            data_type: 'power',
                            data_text: side_text + ' ' + this.$filters.translate('prescription.SPHERE').toLowerCase() + ' ' + this.$filters.plus(sph) + ' ' +
                                this.$filters.translate('prescription.CYLINDER').toLowerCase() + ' ' + this.$filters.plus(cyl),
                            data: data,
                            fill: false,
                            backgroundColor: !!this.ranges.filter(r => r.task[side].sphere.pass).length ? 'green' : 'red',
                            pointRadius: 4,
                            pointHoverRadius: 6
                        })
                    })
                }
            },
            removeRow(range) {
                this.delete.push(this.ranges[this.ranges.indexOf(range)].id)
                this.ranges.splice(this.ranges.indexOf(range), 1)
            },
            updateChanges() {
                API.post('catalog/hoya/updateRanges', {
                    ranges: this.ranges,
                    category_id: this.categoryId,
                    delete: this.delete
                }).then(() => {
                    this.refreshTable()
                }).finally(() => {
                    this.$notify.success('catalog.lenses.properties.notify.SAVE')
                }).catch(() => {
                    this.$notify.error('catalog.lenses.properties.notify.error.SAVE')
                })
            }
        },
        computed: {
            invalid() {
                return !!this.ranges.filter(r => parseFloat(r.sphere_start) >= parseFloat(r.sphere_stop) ||
                    parseFloat(r.cylinder_start) >= parseFloat(r.cylinder_stop)).length
            }
        }
    }
</script>

<style scoped lang="less">
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    table > tbody > tr.tr-task-range > td {
        border-top: 0;

         > div {
            margin-bottom: 5px;

            > span {
                display: inline-block;

                &:nth-child(1) {
                    width: 40px;
                }

                &:nth-child(2) {
                    width: 150px;
                }
            }
        }
    }

    .panel > .panel-footer {
        padding-top: 0;
        padding-bottom: 0;

        > .row > div[class^='col-'] {
            @padding: 10px;

            padding-top: @padding;
            padding-bottom: @padding;
        }
    }

    .star-info:after {
        color: #23b7e5 !important;
    }
</style>