<template lang="pug">
    .row
        div(style="position:relative")
            p.header
                .text-expressive {{'report.entries.HEADING'|translate}}

        div(style="position:relative")
            OptiDimmer(:active="$asyncComputed.chartData.updating")

            LineChart(
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

            OptiChartTooltip(
                :model="check"
                :chart-data="chartData"
                :chart="$refs.chart")
                template(#header="{header}")
                    div {{header.tooltip.title[0]}}
                template(#default="{body}")
                    table.table.table-striped.table-bordered.table-condensed
                        thead
                            tr
                                th(colspan="2") &nbsp;
                                th.text-right {{period.pastYear}}
                                th.text-right {{period.chooseYear}}
                                th.text-right %
                        tbody
                            tr(v-for="row in body.tooltip.body")
                                th
                                    div.line-legend(:style="{ backgroundColor: row.lines[0].background, borderColor: row.lines[0].border }")
                                th {{ row.lines[0].label }}
                                td.text-right.no-brake {{ row.lines[0].valueB | currency({fractionCount: 0}) }}
                                td.text-right.no-brake {{ row.lines[0].valueA | currency({fractionCount: 0}) }}
                                td.text-right.no-brake.percent(:class="row.lines[0].percentClass")
                                    span {{ row.lines[0].valuePercent }}%
                            tr
                                th(colspan="2") {{'report.entries.DATES'|translate}}
                                td(v-if="!period.cumulative") {{chooseRange(body.tooltip.body[0].lines[0].weekPast)}}
                                td(v-if="!period.cumulative") {{chooseRange(body.tooltip.body[0].lines[0].weekCurrent, true)}}
                                td(v-if="period.cumulative") {{chooseRangePast(body.tooltip.body[0].lines[0].weekPast)}}
                                td(v-if="period.cumulative") {{chooseRangeCurrent(body.tooltip.body[0].lines[0].weekCurrent, true)}}
                                td &nbsp;
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {budgetQuartersAnnotation} from '@/js/app/vue/components/Chart/Base/OptiChartBudgetAnnotations'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiChartTooltip from '@/js/app/vue/components/Chart/Base/OptiChartTooltip'

    export default {
        name: 'OptiSummaryEntriesReport',
        components: {
            OptiDimmer,
            LineChart,
            OptiChartTooltip
        },
        props: {
            period: Object
        },
        data() {
            let leftAxis = null

            return {
                check: null,
                chartData: null,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: label => label + '%'
                            },
                            afterDataLimits(axis) {
                                leftAxis = axis
                            }
                        }, {
                            position: 'right',
                            ticks: {
                                callback: label => label + '%'
                            },
                            afterDataLimits(axis) {
                                axis.min = leftAxis.min
                                axis.max = leftAxis.max
                            }
                        }]
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {
                            label(tooltipItems, data) {
                                let obj = data.datasets[tooltipItems.datasetIndex]['data_ints'][tooltipItems.index],
                                    weekPast = data.datasets[tooltipItems.datasetIndex]['weekPast'][tooltipItems.index],
                                    weekCurrent = data.datasets[tooltipItems.datasetIndex]['weekCurrent'][tooltipItems.index]

                                return {
                                    label: data.datasets[tooltipItems.datasetIndex].label,
                                    valuePercent: tooltipItems.yLabel,
                                    valueA: obj.a,
                                    valueB: obj.b,
                                    percentClass: tooltipItems.yLabel < 0 ? 'percent-negative' : 'percent-positive',
                                    border: data.datasets[tooltipItems.datasetIndex].borderColor,
                                    background: data.datasets[tooltipItems.datasetIndex].backgroundColor,
                                    weekPast: weekPast,
                                    weekCurrent: weekCurrent
                                }
                            },
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    },
                    annotation: {
                        annotations: [
                            ...budgetQuartersAnnotation()
                        ]
                    }
                },
                styles: {
                    position: 'relative',
                    height: '300px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                }
            }
        },
        async mounted() {
            window.chart = this
        },
        methods: {
            chooseRangePast(week, end = false) {
                let begin = moment(this.chartData.datasets[0].weekPast[0]),
                    date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = begin.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${begin.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }
                return `${begin.format('D')} - ${endDate.format('D MMMM')}`
            },
            chooseRangeCurrent(week, end = false) {
                let begin = moment(this.chartData.datasets[0].weekCurrent[0]),
                    date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = begin.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${begin.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }

                return `${begin.format('D')} - ${endDate.format('D MMMM')}`
            },
            chooseRange(week, end = false) {
                let date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = date.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${date.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }

                return `${date.format('D')} - ${endDate.format('D MMMM')}`
            }
        },
        asyncComputed: {
            async chartData() {
                try {
                    let {data} = await API.post('charts/entriesSummary', {
                        chooseYear: this.period.chooseYear,
                        departmentId: this.period.departmentId,
                        pastYear: this.period.pastYear,
                        period: this.period.period,
                        pastWeekFrom: this.period.pastWeekFrom,
                        pastWeekTo: this.period.pastWeekTo,
                        currentWeekFrom: this.period.currentWeekFrom,
                        currentWeekTo: this.period.currentWeekTo,
                        pastQuarter: this.period.pastQuarter,
                        currentQuarter: this.period.currentQuarter,
                        cumulative: this.period.cumulative,
                        checkbox_w53: this.period.checkbox_w53
                    })

                    this.chartData = data
                    this.chartData.datasets[0].borderColor = 'red'
                    this.chartData.datasets[0].pointHoverRadius = 10
                    this.chartData.datasets[0].pointHitRadius = 10
                    this.chartData.datasets[0].backgroundColor = 'rgba(255,0,0,0.5)'
                    this.chartData.datasets[1].borderColor = 'black'
                    this.chartData.datasets[1].pointHoverRadius = 10
                    this.chartData.datasets[1].pointHitRadius = 10
                    this.chartData.datasets[1].backgroundColor = 'rgba(0,0,0,0.5)'
                } catch(e) {
                    this.$notify.error('report.entries.notify.error.ALL')

                    console.log(e)
                }

                return this.chartData
            }
        }
    }
</script>

<style lang="less" scoped>
    p.header {
        color: black;
        font-weight: bold;
        padding: 2px 10px;
    }
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }

    .percent-positive {
        & > span:before {
            content: '▲'
        }
        color: @btn-success-bg;
    }
    .percent-negative {
        & > span:before {
            content: '▼'
        }
        color: @btn-danger-bg;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }
</style>
