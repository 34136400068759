<template lang="pug">
    .row(v-if="$asyncComputed.printout.success")
        OptiDimmer(:active="active")
        .col-xs-offset-3.col-xs-6(style="margin-top:10px")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'tasks.printouts.breadcrumb.PRINTOUTS'|translate}} \#{{printout.id}}
                    .pull-right \#{{printout.created_at}}

                .table-responsive
                    table.table.table-responsive.table-striped(style="table-layout:fixed")
                        tbody
                            tr
                                th(style="width:50%").text-right Wydrukował
                                td.text-left {{printout.user.firstName}} {{printout.user.lastName}}
                            tr
                                th(style="width:50%").text-right Liczba prac
                                td.text-left {{printout.tasks_qty}}
                            tr
                                th(style="width:50%").text-right Numery prac
                                td
                                    tr.text-left(v-for="detail in printout.details" style="word-wrap:break-word")
                                        a.link(:href="$state.href('app.task.detail', {id: detail.task_id})" target="_blank"
                                            ) {{detail.task_id}}
                .panel-footer
                    OptiButton(style="align:right" @click.native="goBack"
                        ) {{'tasks.printouts.details.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left

                    OptiButtonPrint.pull-right(:id="printout.id" url="task/printouts/reprint-group"
                        ) {{'tasks.printouts.details.REPRINT'|translate}}
</template>

<script>
import {API} from "@/js/app/vue/api";
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
import OptiButtonPrint from "@/js/app/vue/components/Button/OptiButtonPrint";
import OptiButton from "@/js/app/vue/components/Button/OptiButton";

export default {
    name: "OptiTasksPrintoutDetails",
    components: {OptiButton, OptiButtonPrint, OptiDimmer},

    data() {
        return {
            active: false
        }
    },
    asyncComputed: {
        async printout() {
            try {
                let{data} = await API.get('task/printouts/details/' + this.$state.params.id)
                return data
            } catch(e) {
                console.log(e)
            }
        }
    },
    methods: {
        goBack() {
            this.$state.go('app.tasks.printouts.list')
        }
    }
}
</script>