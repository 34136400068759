<template lang="pug">
    div
        .row(v-show="!loading")

            .col-xs-12(v-show="reclamation.tasks.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'reclamations.RECLAMATIONTASK'|translate}}
                        .label.label-danger.pull-right(v-show="reclamation.tasks.length") {{reclamation.tasks.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'reclamations.PRICE'|translate}}
                                    th {{'reclamations.CREATED'|translate}}
                                    th {{'reclamations.RESOLVING'|translate}}
                                    th {{'reclamations.details.TASKID'|translate}}
                                    th {{'reclamations.DEPARTMENT'|translate}}
                                    th {{'reclamations.STATUS'|translate}}
                                    th {{'reclamations.OPINION'|translate}}
                                    th
                            tbody
                                tr(v-for="reclamation in reclamation.tasks")
                                    td
                                        a.link.mr3(:href="$state.href('app.tasks.reclamation.details', {id: reclamation.id})") {{reclamation.id}}
                                        OptiTaskIcon(v-show="reclamation.notes.length" name="notes")
                                    td {{reclamation.productPrice|currency}}
                                    td {{reclamation.created}}
                                    td {{reclamation.expected}}
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: reclamation.productId})"
                                            ) {{reclamation.productId}}
                                    td {{reclamation.department.name}}
                                    td {{reclamation.statusName|translate}}
                                    td {{reclamation.reviewName|translate}}
                                    td.text-center
                                        a(:href="$state.href('app.tasks.reclamation.details', {id: reclamation.id})")
                                            i.fa.fa-info-circle.fa-2x.fa-fw

            .col-xs-12(v-show="reclamation.sales.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'reclamations.RECLAMATIONSALE'|translate}}
                        .label.label-danger.pull-right(v-show="reclamation.sales.length") {{reclamation.sales.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'sale.reclamation.details.PRODUCT'|translate}}
                                    th {{'sale.reclamation.details.SALEID'|translate}}
                                    th {{'sale.reclamation.details.STATUS'|translate}}
                                    th {{'sale.reclamation.details.DECISION'|translate}}
                            tbody
                                tr(v-for="reclamation in reclamation.sales")
                                    td
                                        a.link.mr3(:href="$state.href('app.sale.reclamation.details', {id: reclamation.id})") {{reclamation.id}}
                                        OptiTaskIcon(v-show="reclamation.notes.length" name="notes")
                                    td
                                        OptiProduct(:product="reclamation.productRelation") {{reclamation.productRelation.name}}
                                    td
                                        a.link(:href="$state.href('app.sale.details', {id: reclamation.sale.id})"
                                            ) {{reclamation.sale.id}}
                                    td {{reclamation.status_name|translate}}
                                    td {{reclamation.accepted_name|translate}}
                                    td.text-center
                                        a(:href="$state.href('app.sale.reclamation.details', {id: reclamation.id})")
                                            i.fa.fa-info-circle.fa-2x.fa-fw

            .col-xs-12(v-show="regenerations.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'regeneration.breadcrumb.REGENERATIONS'|translate}}
                        .label.label-danger.pull-right(v-show="regenerations.length") {{regenerations.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'regeneration.table.PRODUCT'|translate}}
                                    th {{'regeneration.table.PRODUCTTYPE'|translate}}
                                    th {{'regeneration.table.EXPECTED'|translate}}
                                    th {{'regeneration.table.SOURCE'|translate}}
                                    th {{'regeneration.table.STATUS'|translate}}
                                    th {{'regeneration.table.STATE'|translate}}
                                    th
                            tbody
                                tr(v-for="regeneration in regenerations")
                                    td
                                        a.link.mr3(:href="$state.href('app.regeneration.details', {id: regeneration.id})") {{regeneration.id}}
                                        OptiTaskIcon(v-show="regeneration.notes.length" name="notes")
                                    td
                                        OptiProduct(v-if="regeneration.product_id"
                                            :product="regeneration.product") {{regeneration.product.name}}
                                        span(v-show="!regeneration.product_id") {{regeneration.product_name}}
                                    td
                                        OptiEnum(enum="TaskFrameType" :id="regeneration.product_type")
                                    td {{regeneration.date_expected}}
                                    td
                                        span.mr3 {{regeneration.source_name|translate}}
                                        OptiRefId(section="regeneration"
                                            :type-id="regeneration.source" :id="regeneration.source_id")
                                    td {{regeneration.status_name|translate}}
                                    td {{regeneration.state_name|translate}}
                                    td.text-center
                                        a(:href="$state.href('app.regeneration.details', {id: regeneration.id})")
                                            i.fa.fa-info-circle.fa-2x.fa-fw

            .col-xs-12(v-show="!count")
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'reclamations.notify.NORECLAMATION'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiTaskIcon from '../../Tasks/OptiTaskIcon'
    import OptiEnum from '../../OptiEnum'
    import OptiRefId from '../../OptiRefId'
    import OptiDimmer from '../../Blocks/OptiDimmer'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'

    export default {
        name: 'OptiPatientsDetailsReclamations',
        components: {
            OptiTaskIcon,
            OptiEnum,
            OptiRefId,
            OptiDimmer,
            OptiProduct
        },
        data() {
            return {
                reclamation: {
                    tasks: [],
                    sales: []
                },
                regenerations: [],

                count: 0,
                loading: true
            }
        },
        mounted() {
            API.get('tasks/reclamations/fromPatientId/' + this.$state.params.id).then(res => {
                this.reclamation.tasks = res.data.reclamation_task
                this.reclamation.sales = res.data.reclamation_sale
                this.regenerations = res.data.regenerations

                this.count = this.reclamation.tasks.length + this.reclamation.sales.length + this.regenerations.length
                this.$emit('update-count', 'reclamations', this.count)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('reclamations.notify.ERRORRECLAMATION')
            })
        }
    }
</script>
