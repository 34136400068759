<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.breadcrumb.CREATE'|translate}}

                .panel-body
                    .form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label(for="date_from") {{'specification.create.FROM'|translate}}
                            .col-sm-8
                                OptiDate#date_from(:value="form.date_from" @update-date="d => form.date_from = d")
                                OptiValidate(f="date_from" :data="$v.form.date_from")
                        .form-group
                            label.col-sm-4.control-label(for="date_to") {{'specification.create.TO'|translate}}
                            .col-sm-8
                                OptiDate#date_to(:value="form.date_to" @update-date="d => form.date_to = d")
                                OptiValidate(f="date_to" :data="$v.form.date_to")
                        .form-group
                            .col-xs-12
                                button.btn.btn-default(:disabled="$v.form.$invalid" @click="getAllRegenerations"
                                    ) {{'specification.table.regenerations.NO_SPECIFICATION'|translate}}

                .panel-footer.text-right
                    OptiButton(type="success" :disabled="$v.form.$invalid" @click.native="create"
                        ) {{'specification.table.regenerations.CREATE'|translate}}
                        template(#icon)
                            i.fa.fa-save

                OptiDimmer(:active="loading")

        .col-xs-12
            .table-responsive
                table.table.table-striped.middle
                    thead
                        tr
                            th.text-right #
                            th.text-right {{'specification.table.regenerations.REGENERATION_ID'|translate}}
                            th.text-right {{'regeneration.table.CREATEDAT'|translate}}
                            th.text-right {{'regeneration.table.STATUS'|translate}}
                            th.text-right {{'regeneration.table.DEPARTMENT'|translate}}

                    tbody
                        tr(v-for="(regeneration, index) in regenerations")
                            td.text-right {{index + 1}}
                            td.text-right
                                a.link(:href="$state.href('app.regeneration.details', {id: regeneration.id})"
                                    target="_blank") {{regeneration.id}}
                            td.text-right {{regeneration.created_at}}
                            td.text-right {{regeneration.status_name|translate}}
                            td.text-right {{regeneration.department.name}}

                    tfoot(v-show="!regenerations.length")
                        tr
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {requiredIf} from 'vuelidate/lib/validators'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiRegenerationSpecificationCreate',
        components: {
            OptiDate,
            OptiButton,
            OptiDimmer,
            OptiValidate
        },
        data() {
            return {
                form: {
                    date_from: '',
                    date_to: ''
                },

                regenerations: [],

                loading: false
            }
        },
        validations: {
            form: {
                date_from: {
                    required: requiredIf(form => form.date_to)
                },
                date_to: {
                    required: requiredIf(form => form.date_from)
                }
            }
        },

        methods: {
            create() {
                this.loading = true

                API.post('specifications/create', this.form).then(res => {
                    if(res.data) {
                        this.$state.go('app.specifications.regenerationDetails', {
                            id: res.data
                        })

                        this.$notify.success('specification.table.regenerations.notify.ADD')
                    } else {
                        this.$notify.warning('specification.table.regenerations.notify.EMPTY')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('specification.table.regenerations.notify.error.ADD')
                })
            },
            getAllRegenerations() {
                this.loading = true

                API.post('specifications/regenerations-without-specification', this.form).then(res => {
                    this.regenerations = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('specification.table.regenerations.notify.error.LOAD')
                })
            }
        }
    }
</script>