<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-files-o.mr3
            span {{'optometrist.contactlens.efron.COMPARE'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .dialog-contents
                table.table(v-if="ef.length")
                    tr
                        td.text-center(style="padding:0")
                            div(style="padding:10px;font-size:18px")
                                i.fa.fa-eye.mr3
                                span.mr3(v-show="ef[index].side === 'r'") ({{'prescription.RIGHT'|translate}}
                                span.mr3(v-show="ef[index].side === 'l'") ({{'prescription.LEFT'|translate}}
                                span.mr3 {{ef[index].position}}/{{position[ef[index].side]}})
                                span {{ef[index].name}}
                            .row
                                .col-md-6(style="margin-top:40px")
                                    div(style="width:500px;margin:0 auto")
                                        img(style="width:500px;height:282px"
                                            :src="'img/optometrist/screening-test/contact-lens/efron/efron' + ef[index].id + '_' + ef[index].scale + '.jpg'")
                                        table(style="width:100%")
                                            tr
                                                td.scale-efron(v-for="scale in [0, 1, 2, 3, 4]" :class="getClass(scale)")
                                                    label {{scale}}

                                .col-md-6(style="margin-top:40px")
                                    div(style="width:500px;margin:0 auto")
                                        img(style="width:500px;height:282px" :src="ef[index].photo|upload")
                                        table(style="width:100%")
                                            tr
                                                td.scale-efron.scale-efron-disabled
                                                    label {{'optometrist.contactlens.efron.UPLOADED'|translate}}

        template.text-right(#footer)
            .row
                .col-xs-4.text-left
                    OptiButton(type="success" :disabled="!index" @click.native="--index") {{'optometrist.video.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left
                .col-xs-4.text-center(style="white-space:nowrap")
                    OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check
                .col-sm-4.text-right(style="white-space:nowrap")
                    OptiButton(type="success" :right="true" :disabled="ef.length === index + 1"
                        @click.native="++index") {{'optometrist.video.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDialogEfronCompare',
        components: {
            OptiDialog,
            OptiButton
        },
        props: {
            efron: {
                type: Array,
                required: true
            },
            side: {
                type: String,
                required: true
            },
            efronId: {
                type: Number,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                ef: {r: [], l: []},
                position: {r: 0, l: 0},

                index: 0
            }
        },
        mounted() {
            this.efron.forEach(efron => {
                if(efron.pivot.r_photo) {
                    this.ef.r.push({
                        side: 'r',
                        id: efron.id,
                        name: efron.name,
                        photo: efron.pivot.r_photo,
                        scale: efron.pivot.r,
                        position: ++this.position.r
                    })
                }

                if(efron.pivot.l_photo) {
                    this.ef.l.push({
                        side: 'l',
                        id: efron.id,
                        name: efron.name,
                        photo: efron.pivot.l_photo,
                        scale: efron.pivot.l,
                        position: ++this.position.l
                    })
                }
            })

            this.ef = this.ef.r.concat(this.ef.l)

            this.ef.forEach((efron, index) => {
                if(efron.side === this.side && efron.id === this.efronId) {
                    this.index = index
                }
            })
        },
        methods: {
            getClass(scale) {
                let _class = 'scale-efron-' + scale,
                    _return = {
                        'scale-efron-active': this.ef[this.index].scale === scale,
                        'scale-efron-disabled': this.ef[this.index].scale !== scale
                    }

                _return[_class] = true

                return _return
            }
        }
    }
</script>

<style lang="less" scoped>
    label {
        cursor: default !important;
    }
</style>