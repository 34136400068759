<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'prices.pricetochange.KINDOFCHANGE'|translate}}

                .panel-body
                    form.form.form-horizontal
                        .form-group
                            .col-xs-11.col-xs-offset-1
                                .radio.c-radio.c-radio-nofont(@click="setType(2)")
                                    input(type="radio" v-model="form.type" name="type" :value="2")
                                    span
                                    | {{'prices.promotion.type.PRICE_CHANGE'|translate}}
                        .form-group
                            .col-xs-11.col-xs-offset-1
                                .radio.c-radio.c-radio-nofont(@click="setType(4)")
                                    input(type="radio" v-model="form.type" name="type" :value="4")
                                    span
                                    | {{'prices.promotion.type.PRICE_CHANGE_WITH_STATUS_CHANGE'|translate}}
                        .form-group
                            label.col-xs-12.star-required(for="date_from") {{'prices.pricetochange.FROMWHEN'|translate}}
                            .col-md-6
                                OptiDate#date_from(:value="form.start_date" @update-date="d => form.start_date = d")
                                OptiValidate(f="date_from" :data="$v.form.start_date")
                        .form-group(v-show="form.type === 1")
                            label.col-xs-12.star-required(for="date_to") {{'prices.pricetochange.TOWHEN'|translate}}
                            .col-md-6
                                OptiDate#date_to(:value="form.end_date" @update-date="d => form.end_date = d")
                                OptiValidate(f="date_to" :data="$v.form.end_date")

                .panel-footer
                    OptiButton(type="danger" @click.native="$state.go('app.prices.list')"
                        ) {{'prices.pricetochange.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left

                    OptiButton.pull-right(type="success" @click.native="confirm" :disabled="$v.form.$invalid"
                        ) {{'prices.pricetochange.CONFIRM'|translate}}
                        template(#icon)
                            i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiProductPriceChangeAdd',
        components: {
            OptiDate,
            OptiButton,
            OptiValidate
        },
        data() {
            return {
                form: {
                    type: null,
                    start_date: '',
                    end_date: ''
                }
            }
        },
        validations() {
            return {
                form: {
                    type: {
                        required
                    },
                    start_date: {
                        required
                    },
                    end_date: {
                        required: requiredIf(model => model.type === 1)
                    }
                }
            }
        },
        methods: {
            setType(x) {
                return this.form.type = x
            },
            confirm() {
                API.post('prices/add', {
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    type: this.form.type
                }).then(res => {
                    this.$state.go('app.prices.edit', {id: res.data})
                })
            }
        }
    }
</script>