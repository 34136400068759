<template lang="pug">
    div
        template(v-for="(carton, carton_index) in cartons")
            .row
                .col-xs-6
                    h4
                        span {{'shipments.item.CARTON'|translate}} \#{{carton_index + 1}}
                        small.text-muted(style="padding-left:5px") {{'shipments.new.done.WEIGHT'|translate|lower}} {{carton.weight|kg}}
                //.col-xs-6.text-right(v-show="step === 2 && cartons.length === carton_index + 1")
                    OptiButton(type="success" :disabled="!carton.items.length || !carton.weight" @click.native="addCarton"
                        ) {{'shipments.new.content.title.item.ADDCARTON'|translate}}
                        template(#icon)
                            i.fa.fa-dropbox.mr3

            .table-responsive(style="padding-bottom:15px")
                table.table.table-striped.middle.no-padding
                    thead
                        tr
                            th #
                            th.text-right {{'default.REFERENCEID'|translate}}
                            th {{'shipments.new.content.NAME'|translate}}
                            th {{'shipments.new.content.DESCRIPTION'|translate}}
                            th.text-right {{'shipments.new.content.QUANTITY'|translate}}
                            th.text-right {{'shipments.new.content.VALUE'|translate}}
                            th.text-right(v-show="step === 2 && cartons.length === carton_index + 1") {{'shipments.new.content.ACTION'|translate}}
                    tbody
                        template(v-for="(item, key) in carton.items")
                            tr(:class="{'tr-stripped': key % 2}")
                                td {{carton.items.length - key}}
                                td.text-right
                                    OptiRefId(section="shipment-item" :type-id="item.type" :id="item.id")
                                td {{item.name}}
                                td {{item.description}}
                                td.text-right {{item.quantity}}
                                td.text-right {{item.value|currency}}
                                td.text-right(v-show="step === 2 && cartons.length === carton_index + 1")
                                    OptiButton.hide(:id="'item-' + key" text="shipments.new.content.dialog.removeitem"
                                        @confirmed="$emit('delete-item', item, key, carton_index)")
                                    label.btn.btn-danger(:for="'item-' + key" :title="'shipments.new.content.title.item.REMOVE'|translate")
                                        i.fa.fa-trash
                            tr.borderless(:class="{'tr-stripped': key % 2}" v-show="getWarning(item)")
                                td.text-warning(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'shipments.new.content.warning.RECEIVER'|translate}} {{getWarning(item)}}.
                        tr(v-show="!carton.items.length")
                            td.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}
                    tfoot
                        tr
                            td(colspan="5")
                                .form-group(v-show="step === 2 && cartons.length === carton_index + 1")
                                    label.control-label.col-xs-2(style="padding-top:7px" :for="'weight-' +carton_index"
                                        ) {{'shipments.new.done.WEIGHT'|translate}}
                                    .col-xs-4
                                        OptiNumber#value(:header="'shipments.new.done.WEIGHT'|translate"
                                            :value="carton.weight" @confirmed="w => updateWeight(carton_index, w)"
                                            :float="true" :required="true") {{carton.weight|kg}}
                            td.text-right
                                span(v-show="carton.value") {{carton.value|currency}}
                            td(v-show="step === 2 && cartons.length === carton_index + 1")
</template>

<script>
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiShipmentCreateStepItems',
        components: {
            OptiRefId,
            OptiButton,
            OptiNumber
        },
        props: {
            model: {
                type: Object,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },

            cartons: {
                type: Array,
                required: true
            },
            step: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            addCarton() {
                this.cartons.push({
                    weight: 0,
                    value: 0,
                    items: []
                })
            },
            getWarning(item) {
                switch(item.type) {
                    case 1:
                    case 4:
                    case 12:
                    case 14:
                    case 16:
                    case 17:
                        if(this.receiver.type || this.model.department.id !== item.department.id) {
                            return item.department.name
                        }

                        break

                    case 9:
                        if(this.receiver.type !== 3 || item.address.firstName !== this.model.custom.firstName ||
                            item.address.lastName !== this.model.custom.lastName || item.address.street !== this.model.custom.street ||
                            item.address.zipCode !== this.model.custom.zipCode || item.address.city !== this.model.custom.city) {
                            return item.address.firstName + ' ' + item.address.lastName + ', ' + item.address.street + ' ' +
                                item.address.zipCode + ' ' + item.address.city
                        }

                        break

                    case 15:
                        if(!((this.receiver.type === 4 && item.address.type === 'specialist' && this.model.specialist.id === item.address.id) ||
                            (this.receiver.type === 0 && item.address.type === 'department' && this.model.department.id === item.address.id))) {
                            return item.address.name
                        }
                }

                return false
            },

            updateWeight(carton_index, w) {
                this.cartons[carton_index].weight = w

                this.$emit('update-weight')
            }
        }
    }
</script>