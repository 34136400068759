import json from './modules.json'
import angular from 'angular'

let buildDepends = function (prefix, list, modules) {
    let dependencies = []

    for (let i = 0; i < list.length; i++) {
        let item       = list[i]
        let external   = !!item.external
        let moduleName = (external ? '' : prefix + '.') + item.name
        dependencies.push(moduleName)
        if (!external) {
            if (typeof item.depends != 'undefined') {
                let moduleDepends = buildDepends(moduleName, item.depends, modules)
                modules.push({module: moduleName, depends: moduleDepends})
            } else {
                modules.push({module: moduleName, depends: []})
            }
        }
    }

    return dependencies
}

let modules = []
let deps = buildDepends('eOpti', json, modules)

for (let i = 0; i < modules.length; i++) {
    let module = modules[i]
    angular.module(module.module, module.depends)
}

// most pomiędzy angularjs a vuejs
import './app/_dev/dev.index'
import ngVueComponentsModule from './app/_bridge/ngVueComponents'

deps.push(ngVueComponentsModule.name)

let eOpti = angular.module('eOpti', deps)

export default eOpti
