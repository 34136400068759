<template lang="pug">
    .row(v-show="diseaseExists")
        .col-xs-12
            .table-responsive
                table.table(style="margin-bottom:0")
                    tbody
                        tr
                            td
                                i.fa.fa-navicon.mr3
                                span {{'optometrist.interview.disease.DISEASES'|translate}}

        .col-sm-6(v-for="disease in diseases" v-show="disease.pivot.checked")
            .table-responsive
                table.table.table-striped.table-center
                    tbody
                        tr
                            td.bold(colspan="2") {{disease.name}}
                        tr(v-show="disease.pivot.from_id")
                            td {{'optometrist.disease.form.FROM'|translate}}
                            td
                                OptiEnum(enum="InterviewDiseaseTime" :id="disease.pivot.from_id")
                        tr(v-show="disease.pivot.eye_id")
                            td {{'optometrist.disease.form.EYE'|translate}}
                            td
                                OptiEnum(enum="InterviewDiseaseEye" :id="disease.pivot.eye_id")
                        tr(v-show="[1, 2, 3, 5, 6].includes(disease.id) && disease.pivot.type_id")
                            td {{'optometrist.disease.form.TYPE'|translate}}
                            td
                                OptiEnum(v-show="disease.id === 1" enum="InterviewDiseaseAMD" :id="disease.pivot.type_id")
                                OptiEnum(v-show="disease.id === 2" enum="InterviewDiseaseCataract" :id="disease.pivot.type_id")
                                OptiEnum(v-show="disease.id === 3" enum="InterviewDiseaseGlaucoma" :id="disease.pivot.type_id")
                                OptiEnum(v-show="disease.id === 5" enum="InterviewDiseaseHyperthyroidism" :id="disease.pivot.type_id")
                                OptiEnum(v-show="disease.id === 6" enum="InterviewDiseaseDiabetes" :id="disease.pivot.type_id")
                        tr(v-show="disease.id === 2 && disease.pivot.method_id")
                            td {{'optometrist.disease.form.TREATMENTREMOVE'|translate}}
                            td
                                OptiEnum(enum="InterviewDiseaseCataractMethod1" :id="disease.pivot.method_id")
                        tr(v-show="disease.id === 2 && disease.pivot.method2_id")
                            td {{'optometrist.disease.form.REMOVE'|translate}}
                            td
                                OptiEnum(v-show="disease.id === 2" enum="InterviewDiseaseCataractMethod2" :id="disease.pivot.method2_id")
                        tr(v-show="disease.pivot.doctor_control")
                            td
                            td
                                i.fa.fa-check.mr3
                                span {{'optometrist.disease.form.DOCTORCONTROL'|translate}}
                        tr(v-show="disease.pivot.last_visit_id")
                            td {{'optometrist.disease.form.LASTVISIT'|translate}}
                            td
                                OptiEnum(enum="InterviewDiseaseTime" :id="disease.pivot.last_visit_id")
                        tr(v-show="disease.id === 6 && disease.pivot.treatment_id")
                            td {{'optometrist.disease.form.TREATMENT'|translate}}
                            td
                                OptiEnum(v-show="disease.id === 6" enum="InterviewDiseaseDiabetesTreatment" :id="disease.pivot.treatment_id")
                        tr(v-show="(disease.pivot.images.length === 1 && disease.pivot.images[0].image) || disease.pivot.images.length > 1")
                            td {{'optometrist.disease.photo.LABEL'|translate}}
                            td
                                div(style="margin-top:5px" v-for="image in disease.pivot.images")
                                    a(v-if="image.image" :href="image.image|upload" target="_blank"
                                        :title="'optometrist.disease.photo.TITLE'|translate")
                                        img.img-rounded(style="width:100%" :src="image.image|upload")
</template>

<script>
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'OptiOptometristsDetailsSummaryDiseases',
        components: {
            OptiEnum
        },
        props: {
            diseases: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            diseaseExists() {
                let _return = false

                this.diseases.forEach(disease => {
                    if(disease.pivot.checked) {
                        _return = true
                    }
                })

                return _return
            }
        }
    }
</script>