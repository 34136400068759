<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>
<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryTaskExtraDelete',
        components: {
            OptiDimmer
        },
        mounted() {
            API.delete('dictionary/task-extra/action/' + this.$state.params.id).then(() => {
                this.$notify.success('dictionary.taskextra.notify.DELETE')
            }).finally(() => {
                this.$state.go('app.dictionary.taskextras.table')
            }).catch(() => {
                this.$notify.error('dictionary.taskextra.notify.error.DELETE')
            })
        }
    }
</script>