import { render, staticRenderFns } from "./OptiCatalogCategoryEdit.vue?vue&type=template&id=633c7441&scoped=true&lang=pug&"
import script from "./OptiCatalogCategoryEdit.vue?vue&type=script&lang=js&"
export * from "./OptiCatalogCategoryEdit.vue?vue&type=script&lang=js&"
import style0 from "./OptiCatalogCategoryEdit.vue?vue&type=style&index=0&id=633c7441&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "633c7441",
  null
  
)

export default component.exports