<template lang="pug">
    .payment-directive
        .form-group
            label.control-label(for="type0" :class="{'star-required': required, 'col-sm-3': mode === 'small', 'col-sm-4': mode === 'normal'}") {{header|translate}}
            div(:style="{'padding-right': mode === 'small' && 0}" :class="class_name[1]")
                div(:class="{'input-group': payments[0].type.id === 3}")
                    select#type0.form-control(name="type0" :disabled="disabled" :required="required"
                        v-model="payments[0].type" @change="changeType0")
                        option(v-for="p in payment_type" :key="p.id" :value="p") {{p.name|translate}}
                    .input-group-btn(v-show="payments[0].type.id === 3")
                        .btn.btn-success(:disabled="disabled" :title="'payment.directive.NEXTTITLE'|translate" @click="next")
                            i.fa.fa-plus.mr3
                            span {{'payment.directive.NEXT'|translate}}
                OptiValidate(f="type0" :data="$v.payments.$each[0].type" :payment-diff="diff")
            div(v-show="payments[0].type.id !== 3")
                slot

        .form-group.separate-top(v-show="payments[0].type.id === 2")
            label.control-label(for="ccType0" v-show="payments[0].ccType.id !== 7 && payments[0].ccType.id !== 8" :class="class_name[4]") {{'payment.directive.CCTYPECARDNUMBER'|translate}}
            label.control-label(for="ccType0" v-show="payments[0].ccType.id === 7" :class="class_name[4]") {{'payment.directive.CCTYPECARDNUMBERBLIK'|translate}}
            label.control-label(for="ccType0" v-show="payments[0].ccType.id === 8" :class="class_name[4]") {{'payment.directive.CCTYPECARDNUMBERCOUPON'|translate}}
            div(style="padding-right:0" :class="class_name[2]")
                select#ccType0.form-control(name="ccType0" :disabled="disabled" required
                    v-model="payments[0].ccType" @change="typeWasUpdated(0)")
                    option(v-for="c in cc_type" :key="c.id" :value="c") {{c.label}}
            div(style="padding-left:0" :class="class_name[3]" :style="{'padding-right': mode === 'small' && 0}" pending)
                OptiNumber#cardNumber0.text-number(v-if="payments[0].ccType.id !== 8"
                    :header="'payment.directive.CARDNUMBER' + (payments[0].ccType.id === 7 ? 'BLIK' : '')|translate"
                    :disabled="disabled" :pending="pending" required :is-number="false"
                    :value="payments[0].cardNumber" @confirmed="c => updatePaymentField(0, 'cardNumber', c)"
                    ) {{payments[0].cardNumber}}
                input#cardNumber0.form-control(v-if="payments[0].ccType.id === 8 && taskId" maxlength="12" :disabled="disabled" required
                    name="cardNumber0" v-model.lazy="payments[0].cardNumber" @change="$emit('payment-was-updated')")
                input#cardNumber0.form-control(v-if="payments[0].ccType.id === 8 && !taskId" maxlength="12"
                    name="cardNumber0" v-model="payments[0].cardNumber" :disabled="disabled" required)
            OptiValidate(f="ccType0" :data="$v.payments.$each[0].ccType" :class="class_name[7]")
            OptiValidate(f="ccType0" :data="$v.payments.$each[0].cardNumber" v-show="!$v.payments.$each[0].ccType.$invalid" :class="class_name[7]")
            OptiPaymentCouponValidate(:class="class_name[7]" v-if="coupon_data[0] && !$v.payments.$each[0].cardNumber.$pending"
                :data="coupon_data[0]")
        .form-group.separate-bottom(v-show="payments[0].type.id === 2 && payments[0].ccType.id !== 8")
            label.control-label(for="accountNo0" :class="class_name[4]") {{'payment.directive.ACCOUNTNO'|translate}}
            div(:style="{'padding-right': mode === 'small' && 0}" :class="class_name[8]")
                OptiNumber.text-number(:header="'payment.directive.ACCOUNTNO'|translate" :disabled="disabled" :pending="pending" required
                :is-number="false" :value="payments[0].accountNo" @confirmed="a => updatePaymentField(0, 'accountNo', a)"
                ) {{payments[0].accountNo}}
                OptiValidate(f="accountNo0" :data="$v.payments.$each[0].accountNo")
        .form-group(v-show="payments[0].type.id === 2 && payments[0].ccType.id === 8 && mode === 'normal'")
            label.control-label(for="accountNo0" :class="class_name[4]") {{'payment.directive.ACCOUNTNOAMOUNTCOUPON'|translate}}
            .text-right(:style="{'padding-right': mode === 'small' && 0}" :class="class_name[8]")
                .form-control.text-right(disabled) {{payments[0].amount|currency}}

        .separate-top.separate-bottom(v-for="(payment, key) in payments" :key="key" v-if="payments[0].type.id === 3 && key")
            .form-group
                label.control-label.star-required(:for="'type' + key" :class="class_name[0]") {{'payment.directive.TYPE'|translate}}
                div(:style="{'padding-right': mode === 'small' && 0}" :class="class_name[1]")
                    .input-group
                        select.form-control.star-required(:id="'type' + key" name="'type' + key"
                            :disabled="disabled || paymentTypeFiltr(payment).length === 1" required
                            v-model="payment.type" @change="$emit('payment-was-updated')")
                            option(v-for="p in paymentTypeFiltr(payment)" :key="p.id" :value="p") {{p.name|translate}}
                        .input-group-btn
                            .btn.btn-danger(:title="'payment.directive.DELETETITLE'|translate"
                                :disabled="disabled" @click="deletePayment(key)")
                                i.fa.fa-minus.mr3
                                span {{'payment.directive.DELETE'|translate}}
                    OptiValidate(f="type0" :data="$v.payments.$each[key].type")
                .col-sm-3.text-right(v-show="mode === 'small'")
                    OptiNumber(:header="'payment.directive.AMOUNT'|translate"
                        :disabled="disabled || (payment.type.id === 2 && payment.ccType.id === 8)"
                        :pending="pending" required :float="true"
                        :value="payment.amount" @confirmed="a => updatePaymentField(key, 'amount', a)"
                        ) {{payment.amount|currency}}
                    OptiValidate(:f="'amount' + key" :data="$v.payments.$each[key].amount")
                    OptiReceiptNoticeValue(style="padding-right:17px" :data="checkNotice(payment.id)")
                .col-xs-1.text-input(v-show="mode === 'small'")
                    i.fa.fa-minus
            .form-group(v-show="payment.type.id === 2")
                label.control-label(:for="'ccType' + key" v-show="payment.ccType.id !== 7 && payment.ccType.id !== 8" :class="class_name[6]") {{'payment.directive.CCTYPECARDNUMBER'|translate}}
                label.control-label(:for="'ccType' + key" v-show="payment.ccType.id === 7" :class="class_name[6]") {{'payment.directive.CCTYPECARDNUMBERBLIK'|translate}}
                label.control-label(:for="'ccType' + key" v-show="payment.ccType.id === 8" :class="class_name[6]") {{'payment.directive.CCTYPECARDNUMBERCOUPON'|translate}}
                div(style="padding-right:0" :class="class_name[2]")
                    select.form-control(:id="'ccType' + key" :name="'ccType' + key" :disabled="disabled" required
                        v-model="payment.ccType" @change="typeWasUpdated(key)")
                        option(v-for="c in cc_type" :key="c.id" :value="c") {{c.label}}
                div(style="padding-left:0" :style="{'padding-right': mode === 'small' && 0}" :class="class_name[3]" pending)
                    OptiNumber.text-number(v-if="payment.ccType.id !== 8"
                        :header="'payment.directive.CARDNUMBER' + (payment.ccType.id === 7 ? 'BLIK' : '')|translate"
                        :disabled="disabled" :pending="pending" required :is-number="false"
                        :value="payment.cardNumber" @confirmed="c => updatePaymentField(key, 'cardNumber', c)"
                        ) {{payment.cardNumber}}
                    input.form-control(:id="'cardNumber' + key" :name="'cardNumber' + key"
                        v-if="payment.ccType.id === 8 && taskId" maxlength="12" :disabled="disabled" required
                        v-model.lazy="payment.cardNumber" @change="$emit('payment-was-updated')")
                    input.form-control(:id="'cardNumber' + key" :name="'cardNumber' + key"
                        v-if="payment.ccType.id === 8 && !taskId" maxlength="12" :disabled="disabled" required
                        v-model="payment.cardNumber" @change="$emit('payment-was-updated')")
                OptiValidate(:f="'ccType' + key" :data="$v.payments.$each[key].ccType" :class="class_name[7]")
                OptiValidate(:f="'ccType' + key" :data="$v.payments.$each[key].cardNumber" v-show="!$v.payments.$each[key].ccType.$invalid" :class="class_name[7]")
                OptiPaymentCouponValidate(:class="class_name[7]" v-if="coupon_data[key] && !$v.payments.$each[key].cardNumber.$pending"
                    :data="coupon_data[key]")
            .form-group(v-show="payment.type.id === 2 && payment.ccType.id !== 8")
                label.control-label(:for="'accountNo' + key" :class="class_name[6]"
                    ) {{(mode === 'normal' ? 'payment.directive.ACCOUNTNOAMOUNT' : 'payment.directive.ACCOUNTNO')|translate}}
                .text-right(style="padding-right:0" :class="class_name[5]")
                    OptiNumber.text-number(:header="'payment.directive.ACCOUNTNO'|translate" :disabled="disabled" :pending="pending" required
                        :is-number="false" :value="payment.accountNo" @confirmed="a => updatePaymentField(key, 'accountNo', a)"
                        ) {{payment.accountNo}}
                div(style="padding-left:0;text-align:right" :style="{'padding-right': mode === 'small' && 0}" :class="class_name[3]")
                    OptiNumber(v-show="mode === 'normal'" :header="'payment.directive.AMOUNT'|translate"
                        :disabled="disabled" :pending="pending" required :float="true"
                        :value="payment.amount" @confirmed="a => updatePaymentField(key, 'amount', a)"
                        ) {{payment.amount|currency}}
                OptiValidate(:f="'accountNo' + key" :data="$v.payments.$each[key].accountNo" :class="class_name[7]")
                OptiValidate(:f="'amount' + key" :data="$v.payments.$each[key].amount"
                    v-show="!$v.payments.$each[key].accountNo.$invalid && mode === 'normal'" :class="class_name[7]")
            .form-group(v-show="payment.type.id === 2 && payment.ccType.id === 8 && mode === 'normal'")
                label.control-label(:for="'accountNo' + key" :class="class_name[4]") {{'payment.directive.ACCOUNTNOAMOUNTCOUPON'|translate}}
                .text-right(:style="{'padding-right': mode === 'small' && 0}" :class="class_name[8]")
                    .form-control.text-right(disabled) {{payment.amount|currency}}
                OptiValidate(:f="'amount' + key" :data="$v.payments.$each[key].amount" :class="class_name[7]")
            .form-group(v-show="payment.type.id === 1 && mode === 'normal'")
                label.control-label(:for="'amount' + key" :class="class_name[4]") {{'payment.directive.AMOUNT'|translate}}
                .text-right(:style="{'padding-right': mode === 'small' && 0, 'text-align': 'right'}" :class="class_name[8]")
                    OptiNumber(:header="'payment.directive.AMOUNT'|translate"
                        :disabled="disabled" :pending="pending" required :float="true"
                        :value="payment.amount" @confirmed="a => updatePaymentField(key, 'amount', a)"
                        ) {{payment.amount|currency}}
                OptiValidate(:f="'amount' + key" :data="$v.payments.$each[key].amount" :class="class_name[7]")
                OptiReceiptNoticeValue(style="padding-right:17px" :data="checkNotice(payment.id)")
</template>

<script>
    import {EnumPaymentType} from '@/js/app/vue/enums/Enums'
    import {Storage} from '@/js/app/vue/helpers/Storage'
    import {requiredIf} from 'vuelidate/lib/validators'
    import {noZeroIf, minLengthIf, equalLengthIf} from '@/js/vue.validators.js'
    import {paymentCouponIf, paymentMixRequiredMinTwoTypePayments, paymentValueDifference} from './CouponValidator'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiPaymentCouponValidate from '@/js/app/vue/components/Payments/OptiPaymentCouponValidate'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'

    export default {
        name: 'OptiPayment',
        components: {
            OptiNumber,
            OptiValidate,
            OptiPaymentCouponValidate,
            OptiReceiptNoticeValue
        },
        props: {
            payments: {
                type: Array,
                required: true
            },
            value: {
                type: Number | String,
                required: true
            },
            taskId: {
                type: Number,
                required: false,
                default: 0
            },

            header: {
                type: String,
                required: false,
                default: 'payment.directive.PAYMENTTYPE'
            },
            type: {
                type: String,
                required: false,
                default: 'none'
            },

            notice: {
                required: false,
                default() {
                    return null
                }
            },

            edit: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            pending: {
                type: Boolean,
                required: false,
                default: false
            },

            amountToPay: {
                type: String,
                required: false,
                default: ''
            },
            restToPay: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            let mode = ['account', 'rest'].includes(this.type) ? 'small' : 'normal',
                classes = {
                    small: ['col-sm-3', 'col-sm-5', 'col-sm-3', 'col-sm-2', 'col-sm-3', 'col-sm-5', 'col-sm-3', 'col-sm-5 col-sm-offset-3', 'col-sm-5'],
                    normal: ['col-sm-4', 'col-sm-8', 'col-sm-3', 'col-sm-4', 'col-sm-5', 'col-sm-3', 'col-sm-5', 'col-sm-7 col-sm-offset-5', 'col-sm-7']
                }

            return {
                mode: mode,
                class_name: classes[mode],

                payment_type: EnumPaymentType.simpleItems(),
                cc_type: [],
                diff: 0,

                coupon_data: []
            }
        },
        watch: {
            '$v.payments.$invalid'(invalid) {
                this.$emit('invalid', invalid)
            },
            '$v.payments.$pending'(pending) {
                this.$emit('pending', pending)
            }
        },
        validations() {
            return {
                payments: {
                    $each: {
                        type: {
                            paymentMixRequiredMinTwoTypePayments: paymentMixRequiredMinTwoTypePayments(),
                            paymentValueDifference: paymentValueDifference(),
                            required: requiredIf(payment => {
                                let index = this.payments.map(p => p.__ob__.dep.id).findIndex(p => p === payment.__ob__.dep.id)

                                return (!index && this.required) || index > 0
                            })
                        },
                        ccType: {
                            required: requiredIf(payment => payment.type.id === 2)
                        },
                        cardNumber: {
                            noZeroIf: noZeroIf(payment => payment.type.id === 2),
                            minLengthIf: minLengthIf(payment => payment.cardNumber && payment.type.id === 2 && [7, 8].includes(payment.ccType.id), 8),
                            paymentCouponIf: paymentCouponIf(payment => payment.cardNumber && payment.type.id === 2 && payment.ccType.id === 8, this.taskId)
                        },
                        accountNo: {
                            noZeroIf: noZeroIf(payment => payment.type.id === 2 && payment.ccType.id !== 8),
                            minLengthIf: minLengthIf(payment => payment.accountNo && payment.type.id === 2 && payment.ccType.id === 7, 6)
                        },
                        amount: {
                            noZeroIf: noZeroIf(payment => {
                                let index = this.payments.map(p => p.__ob__.dep.id).findIndex(p => p === payment.__ob__.dep.id)

                                return this.payments.length && this.payments[0].type.id === 3 && index > 0
                            })
                        }
                    }
                }
            }
        },
        beforeMount() {
            this.payment_type.shift()

            Storage.get('ccTypes').then(cc_type => {
                this.cc_type = cc_type

                if(this.$uac.user.departmentType === 1) {
                    this.cc_type.splice(8, 1)
                }
            })

            if(!this.edit) {
                this.addPayment()
            }

            this.$emit('invalid', this.$v.payments.$invalid)
        },
        methods: {
            addPayment() {
                this.payments.push({
                    type: this.mode === 'small' && this.payments.length > 2 ? {
                        id: 2
                    } : '',
                    ccType: '',
                    cardNumber: '',
                    accountNo: '',
                    amount: 0
                })
            },
            deletePayment(key) {
                this.payments.splice(key, 1)

                this.$emit('payment-was-updated')
            },

            changeType0() {
                if(this.payments.length === 1 && this.payments[0].type && this.payments[0].type.id === 3) {
                    this.addPayment()
                    this.addPayment()
                } else if(this.payments[0].type && this.payments[0].type.id !== 3) {
                    this.payments.splice(1)
                }

                this.$emit('payment-was-updated')
            },

            next() {
                if(this.payments.length === 1) {
                    this.addPayment()
                    this.addPayment()
                } else {
                    this.addPayment()
                }

                this.$emit('payment-was-updated')
            },

            paymentTypeFiltr(payment) {
                let cash_exists = this.payments.filter(p => p.type && p.type.id === 1).length,
                    ids = [1, 2]

                if(cash_exists && payment.type.id !== 1) {
                    ids = [2]

                    if(payment.type.id !== 2) {
                        payment.type = this.payment_type.filter(p => p.id === 2)[0]
                    }
                }

                return this.payment_type.filter(p => ids.includes(p.id))
            },

            checkNotice(payment_id) {
                let result = false

                if(this.notice) {
                    _.forEach(this.notice.differences, difference => {
                        if(difference.credit_card_payment_id === payment_id) {
                            result = difference
                        }
                    })
                }

                return result
            },

            updatePaymentField(key, field, value) {
                this.$set(this.payments[key], field, value)

                this.$emit('payment-was-updated')
            },
            typeWasUpdated(key) {
                if(this.payments[key].ccType.id === 8) {
                    this.payments[key].cardNumber = ''
                    this.payments[key].amount = 0
                }

                this.$emit('payment-was-updated')
            }
        }
    }
</script>

<style lang="less" scoped>
    .text-number {
        text-align: left;
    }
</style>