<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{heading|translate}}

        .panel-body
            .row
                form.col-md-4.col-xs-12(name="form" @submit.prevent="formSubmit()")
                    .form-group
                        .radio.c-radio.c-radio-nofont
                            label.text-bold(:class="{'star-required': checkType(1)}")
                                input(type="radio" v-model="search.type" name="type" value="1" @change="setType(1)")
                                span
                                | {{'register.return.sale.SALEID'|translate}}
                        input#saleId.form-control(v-model="search.saleId" name="saleId" @input="setType(1)"
                            :placeholder="'register.return.sale.ENTERSALEID'|translate")
                        label.warn(for="saleId" v-show="checkType(1) && !search.saleId") {{'validation.formValidate.required'|translate}}
                    .form-group
                        .radio.c-radio.c-radio-nofont
                            label.text-bold(:class="{'star-required': checkType(2)}")
                                input(type="radio" v-model="search.type" name="type" value="2" @change="setType(2)")
                                span
                                | {{'register.return.sale.search.NAMEPRODUCT'|translate}}
                        OptiSelectProduct(
                            :product="search.product"
                            @selected="updateProduct")
                        label.warn(v-show="checkType(2) && !Object.keys(search.product).length") {{'validation.formValidate.required'|translate}}
                    .form-group
                        label.control-label {{'register.return.sale.search.DATE'|translate}}
                        .form-group
                            label.control-label.col-xs-3.text-right(for="date_from" style="padding-top:7px;margin-bottom:10px"
                                :class="{'star-required': checkType(2)}") {{'register.return.sale.search.date.FROM'|translate}}
                            .col-xs-9(style="padding-left:0;padding-right:0;margin-bottom:10px")
                                OptiDate#date_from(:value="search.date.from" @update-date="d => updateDate('from', d)")
                                label.warn(v-show="checkType(2) && !search.date.from") {{'validation.formValidate.required'|translate}}
                        .form-group
                            label.control-label.col-xs-3.text-right(for="date_to" style="padding-top:7px;margin-bottom:10px"
                                :class="{'star-required': checkType(2)}") {{'register.return.sale.search.date.TO'|translate}}
                            .col-xs-9(style="padding-left:0;padding-right:0;margin-bottom:10px")
                                OptiDate#date_to(:value="search.date.to" @update-date="d => updateDate('to', d)")
                                label.warn(v-show="checkType(2) && !search.date.to") {{'validation.formValidate.required'|translate}}
                            label Jeśli szukasz sprzedaży z innego salonu wybierz konkretną datę a nie zakres.
                    .form-group
                        label.control-label(for="receipt") {{'register.return.sale.search.RECEIPT'|translate}}
                        input#receipt.form-control(v-model="search.receipt" name="receipt" @input="setType(2)"
                            :placeholder="'register.return.sale.search.placeholder.RECEIPT'|translate")
                    .form-group.text-right
                        OptiButton(type="primary" :submit="true"
                            :disabled="(checkType(1) && !search.saleId) || (checkType(2) && !(Object.keys(search.product).length && search.date.from && search.date.to))"
                            ) {{'register.return.sale.search.SEARCH'|translate}}
                            template(#icon)
                                span.fa.fa-search

                OptiDimmer(:active="loading")

                .col-md-8.col-xs-12(v-if="sale.id")
                    .row(style="padding-bottom:10px" v-show="sales.length > 1")
                        .col-xs-12
                            OptiButton(type="primary" @click.native="modelResetSale()") {{'default.BACK'|translate}}
                                template(#icon)
                                    span.fa.fa-arrow-left

                    OptiSaleSearchDetails(
                        :reclamation-mode="reclamationMode"
                        :sale="sale"
                        :more="more"
                        @get-sale="updateSale"
                        @toggle="toggle")

                .col-md-8.col-xs-12(v-if="sales.length && !sale.id")
                    OptiSaleSearchList(
                        :sales="sales",
                        @get-sale="parseSale")
</template>

<script>
    import {API} from '../../../api'
    import {SMSHelper} from '@/js/app/vue/helpers/SMSHelper'

    import Vue from 'vue'

    import OptiSelectProduct from '../../Select/OptiSelectProduct'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '../../Button/OptiButton'
    import OptiDimmer from '../../Blocks/OptiDimmer'
    import OptiSaleSearchDetails from './OptiSaleSearchDetails'
    import OptiSaleSearchList from './OptiSaleSearchList'

    export default {
        name: 'OptiSaleSearch',
        components: {
            OptiSelectProduct,
            OptiDate,
            OptiButton,
            OptiDimmer,
            OptiSaleSearchDetails,
            OptiSaleSearchList
        },
        props: {
            sale: {
                type: Object | Array,
                required: true
            },
            heading: {
                type: String,
                required: true
            },
            reclamationMode: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                search: {
                    type: 2,
                    saleId: null,
                    product: {},
                    date: {
                        from: moment().format('YYYY-MM-DD'),
                        to: moment().format('YYYY-MM-DD')
                    },
                    receipt: ''
                },

                sales: [],

                loading: false,
                more: false
            }
        },
        mounted() {
            if(this.$state.params.saleId) {
                this.search.type = 1
                this.search.saleId = this.$state.params.saleId

                this.loadSale()
            }
        },

        methods: {
            toggle(bool) {
                this.more = bool
            },

            lock(sale) {
                sale.lock = (typeof sale.patient !== 'undefined' && Object.keys(sale.patient).length && !sale.patient.cellPhoneNumber && !SMSHelper.unlockToken(sale.patient.unlock)) ||
                    (typeof sale.search_patient !== 'undefined' && Object.keys(sale.search_patient).length && !sale.search_patient.cellPhoneNumber && !SMSHelper.unlockToken(sale.search_patient.unlock))
            },
            updateSale(sale) {
                this.loading = true
                Vue.nextTick(() => {
                    this.loading = false
                })

                this.lock(sale)

                this.$emit('update-sale', sale)
            },
            setData(sale) {
                sale.proof = {type: 0}

                if(sale.bill) {
                    sale.proof = {type: 1, id: sale.bill}
                }

                sale.date_of_notice = moment().format('YYYY-MM-DD')

                sale.search_patient = {}

                sale.expired = false

                if(sale.created && !this.$uac.permission('sale.return.allow_after_14_days') &&
                    this.diffInDays(sale.created) > 14) {
                    sale.expired = true
                }

                this.updateSale(sale)
            },
            modelReset() {
                this.more = false

                this.sales = []
                let sale = {
                    items: {},
                    search_patient: {}
                }

                this.setData(sale)
            },
            modelResetSale() {
                this.more = false

                let sale = {
                    items: {},
                    search_patient: {}
                }

                this.setData(sale)
            },
            modelSales(sales) {
                this.more = false

                this.sales = sales
            },
            modelSale(sale) {
                this.more = false

                this.setData(sale)
            },

            checkType(int) {
                return this.search.type === int
            },
            setType(int) {
                this.search.type = int
            },
            formSubmit() {
                if(this.search.type === 1) {
                    this.loadSale()
                } else if(this.search.type === 2) {
                    this.loadSales()
                }
            },
            loadSales() {
                this.loading = true
                this.modelReset()

                API.post('register/return/sales', {
                    productId: this.search.product.id,
                    date: this.search.date,
                    receipt: this.search.receipt
                }).then(res => {
                    this.modelSales(res.data)

                    if(this.sales.length === 1) {
                        this.parseSale(this.sales[0])
                    } else if(!this.sales.length) {
                        this.$notify.error('register.return.sale.notify.NOSALE')
                    }

                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            addMinusToItem(sale, old) {
                sale.items.forEach(item => {
                    if(item.productId === old.productId && item.quantity > 0) {
                        item.quantity += old.quantity
                        item.value += item.price * old.quantity
                        item.valueAfterDiscount += old.valueAfterDiscount
                    }
                })
            },
            linkSale(sale) {
                if(Object.keys(sale).length) {
                    sale.items.forEach(item => {
                        if(item.quantity < 0) {
                            this.addMinusToItem(sale, item)
                        }
                    })
                }
            },
            diffInDays(date) {
                let days = 1000 * 60 * 60 * 24,
                    c = date.split('-'),
                    created = new Date(parseInt(c[0]), parseInt(c[1]) - 1, parseInt(c[2])).getTime(),
                    now = new Date().getTime()

                return parseInt((now - created) / days)
            },
            parseSale(sale) {
                this.linkSale(sale)

                let vatValues = {},
                    wholeVat = 0

                if(Object.keys(sale).length) {
                    sale.items.forEach(item => {
                        item.checked = false
                        item.return = 0

                        if(typeof vatValues[item.vat] === 'undefined') {
                            vatValues[item.vat] = []
                        }

                        vatValues[item.vat].push(item.vatValue * item.quantity)
                        wholeVat = wholeVat + (item.vatValue * item.quantity)
                    })
                } else {
                    this.$notify.error('register.return.sale.notify.NOSALE')
                }

                sale.vat = vatValues
                sale.netto = sale.valueAfterDiscount - wholeVat

                this.modelSale(sale)
            },
            loadSale() {
                this.loading = true
                this.modelReset()

                API.get('register/return/sale/' + this.search.saleId).then(res => {
                    this.parseSale(res.data)

                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },

            updateProduct(product) {
                this.search.product = product

                this.setType(2)
            },
            updateDate(type, date) {
                this.search.date[type] = date

                this.setType(2)
            }
        }
    }
</script>