<template lang="pug">
    .feedback.feedback-left(style="font-size:1.1em;cursor:default" v-show="show")
        i.fa.fa-download.mr3
        span {{'default.downloading.GENERATING'|translate}}
        div(v-for="file in files")
            span(v-show="file.status || file.error")
                span.mr3 {{file.name}}
                i.fa.fa-spin.fa-spinner(v-show="!file.error")
                span(style="color:#f90" v-show="file.error") {{'default.downloading.ERROR'|translate}}
            a(style="color:#fff;cursor:pointer" v-show="!file.status && !file.error"
                :title="'default.downloading.DOWNLOAD'|translate" @click="download(file)")
                span.mr3 {{file.name}}
                span.bold ({{'default.downloading.DOWNLOAD'|translate}})
</template>

<script>
    import {EventBus} from '../../_bridge/EventBus'

    export default {
        name: 'OptiDownloading',
        data() {
            return {
                files: [],

                show: false
            }
        },
        mounted() {
            EventBus.$on('ng:emit', this.watchEmit)
        },
        beforeDestroy() {
            EventBus.$off('ng:emit', this.watchEmit)
        },

        methods: {
            download(file) {
                this.files.forEach((current, key) => {
                    if(current.name === file.name) {
                        this.files.splice(key, 1)
                    }
                })

                if(!this.files.length) {
                    this.show = false
                }

                window.open((!file.name.startsWith('/upload/') ? '/reports/' : '') + file.name)
            },

            downloadable(file) {
                if(file.status) {
                    this.files.push(file)
                } else {
                    setTimeout(() => {
                        let find = false

                        angular.forEach(this.files, current => {
                            if(current.name === file.name) {
                                current.status = false
                                current.error = file.error

                                find = true
                            }
                        })

                        if(!find) {
                            this.files.push(file)
                        }
                    }, 1000)
                }

                this.show = true
            },
            watchEmit(event) {
                if (event.name === 'downloadable') {
                    this.downloadable(event.data)
                }
            }
        }
    }
</script>