<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.correction.WORTH'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="cancelled()")

        template(#body)
            OptiOptometristsDisplayControls

            .row
                .col-xs-12.text-center
                    div(style="padding:10px 0;font-size:18px")
                        i.fa.fa-eye.mr3
                        span {{'optometrist.correction.WORTH'|translate}}
                        button.btn.btn-info(type="button" style="margin-left:3px" :class="{'btn-warning active': help}"
                            @click="help = !help") {{'default.HELP'|translate}}

                    OptiOptometristsTips(style="padding-left:15px" v-if="help" :data="tips")

                .col-xs-12(v-show="!help")
                    .container-worth-item(v-for="e in worth")
                        label.screening-hover(:for="e.id" :class="{'screening-opacity': e.id !== w}")
                            img(style="display:block;width:100%" :src="'img/optometrist/screening-test/worth/' + e.id + '.png'")
                            .text-center(style="background:#000;color:#fff;white-space:nowrap;font-weight:normal;font-size:12px") {{e.name|translate}}
                        input.hide(type="radio" :id="e.id" v-model="w" :value="e.id" @click="uncheck(e.id)")

        template.text-right(#footer)
            OptiButton(type="success" @click.native="confirmed()") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
            OptiButton(type="danger" @click.native="cancelled()") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'
    import {EnumInterviewWorth} from '@/js/app/vue/enums/Enums'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'

    export default {
        name: 'OptiOptometristsDetailsRefractionDialogScreeningTestWorth',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips,
            OptiOptometristsDisplayControls
        },
        props: {
            worthId: {
                type: Number,
                required: true
            }
        },
        data() {
            let prefix = 'optometrist.tips.worth.'

            return {
                unique: Math.random().toString(36).substr(2, 9),

                worth: EnumInterviewWorth.simpleItems(),
                w: 0,

                help: false,
                tips:  {
                    info: _.map(_.range(1, 3), i => prefix + 'info.' + i),
                    instruction: _.map(_.range(1, 6), i => prefix + 'instruction.' + i),
                    procedure: _.map(_.range(1, 5), i => prefix + 'procedure.' + i)
                }
            }
        },
        mounted() {
            setTimeout(() => {
                OptometristsDisplay.message.open('/optometrist/screen/worth')
            }, 1000)

            this.worth.shift()

            SurveyLogs.save(this.$state.params.id, this.unique, 'worth', '', 'start', '50%',
                'Rozpoczęto test', [], {data: this.worthId})
        },
        methods: {
            uncheck(worth_id) {
                if(worth_id === this.w) {
                    this.w = 0
                }
            },

            displayClose() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')
            },
            confirmed() {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'worth', '', 'end', '50%',
                    'Zakończono test', [], {data: this.w})

                this.$emit('confirmed', this.w)
            },
            cancelled() {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'worth', '', 'cancel', '50%',
                    'Anulowano test', [], {data: 0})

                this.$emit('cancelled')
            }
        }
    }
</script>

<style lang="less">
    .container-worth-item {
        display:inline-block;
        padding: 0 1% 1% 5px;
        width: 12%;
    }
</style>