<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading.hoya_options && !loading.main")
            form.form-horizontal.form-validate(name="formTaskExtra" @submit.prevent="editTaskExtra")
                .form-group(v-show="task_extra.hoya_option_id")
                    .col-sm-8.col-sm-offset-4
                        i.fa.fa-exclamation-circle.mr3
                        span {{'dictionary.taskextra.notify.info.INCREASE'|translate}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'dictionary.taskextra.table.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(:placeholder="'dictionary.taskextra.table.NAME'|translate"
                            name="name" v-model="task_extra.name" @input="updateHoyaLenses")
                        OptiValidate(f="name" :data="$v.task_extra.name")
                .form-group
                    label.col-sm-4.control-label(for="hoya_option_id") {{'dictionary.taskextra.table.LINK_HOYA'|translate}}
                    .col-sm-8
                        select#hoya_option_id.form-control(:placeholder="'dictionary.taskextra.table.LINK_HOYA'|translate"
                            name="hoya_option_id" v-model="task_extra.hoya_option_id" @change="updateHoyaLenses")
                            option(v-for="hoya_option in hoya_options" :value="hoya_option.id"
                                ) {{hoya_option.name_long}}

                .form-group
                    label.col-sm-4.control-label(for="price") {{'dictionary.taskextra.table.PRICE'|translate}}
                    .col-sm-8
                        OptiNumber#price_wholesale(:header="'dictionary.taskextra.table.PRICE'|translate" :float="true"
                            :disabled="!!task_extra.hoya_option_id"
                            :value="task_extra.price" @confirmed="value => task_extra.price = value"
                            ) {{task_extra.price}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="price_wholesale") {{'dictionary.taskextra.table.PRICEWHOLESALE'|translate}}
                    .col-sm-8
                        OptiNumber(:header="'dictionary.taskextra.table.PRICEWHOLESALE'|translate" :float="true"
                            :disabled="!!task_extra.hoya_option_id"
                            :value="task_extra.price_wholesale" @confirmed="value => task_extra.price_wholesale = value"
                            ) {{task_extra.price_wholesale}}
                        OptiValidate(f="price_wholesale" :data="$v.task_extra.price_wholesale")
                .form-group
                    label.col-sm-4.control-label.star-required(for="price_catalog") {{'dictionary.taskextra.table.PRICECATALOG'|translate}}
                    .col-sm-8
                        OptiNumber(:header="'dictionary.taskextra.table.PRICECATALOG'|translate" :float="true"
                            :disabled="!!task_extra.hoya_option_id"
                            :value="task_extra.price_catalog" @confirmed="value => task_extra.price_catalog = value"
                            ) {{task_extra.price_catalog}}
                        OptiValidate(f="price_catalog" :data="$v.task_extra.price_catalog")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" name="us" v-model="task_extra.us")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{'dictionary.taskextra.table.US'|translate}}

                .form-group
                    .col-xs-12
                        OptiButton(type="danger" @click.native="$state.go('app.dictionary.taskextras.table')"
                            ) {{'default.BACK'|translate}}
                            template(#icon)
                                i.fa.fa-backward
                        OptiButtonSave.pull-right(:disabled="$v.task_extra.$invalid")

        .col-md-offset-2.col-md-8
            OptiDimmer(:active="loading.hoya_options || loading.main")
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiNumber from "@/js/app/vue/components/Inputs/OptiNumber"
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryTaskExtraEdit',
        components: {
            OptiValidate,
            OptiNumber,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                task_extra: {},

                hoya_options: [],

                loading: {
                    hoya_options: true,
                    main: true
                }
            }
        },
        validations() {
            return {
                task_extra: {
                    name: {
                        required,
                        unique: unique('task_extras', 'name', this.$state.params.id)
                    },
                    price_wholesale: {
                        noZero
                    },
                    price_catalog: {
                        noZero
                    }
                }
            }
        },
        mounted() {
            API.get('dictionary/task-extra/hoya-options-others/list').then(res => {
                this.hoya_options = res.data
            }).finally(() => {
                this.loading.hoya_options = false
            }).catch(() => {
                this.$notify.error('dictionary.taskextra.notify.error.OPTION_LIST')
            })

            API.get('dictionary/task-extra/action/' + this.$state.params.id).then(res => {
                this.task_extra = res.data
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('payment.card.notify.error.LOAD')
            })
        },
        methods: {
            updateHoyaLenses() {
                let match = this.task_extra.name.match(/\d+/),
                    number = match ? parseInt(match[0]) : 1,
                    hoya_option = this.hoya_options.find(o => {
                        return o.id === this.task_extra.hoya_option_id
                    })

                if(hoya_option.id) {
                    this.task_extra.price = hoya_option.zhc * number
                    this.task_extra.price_wholesale = hoya_option.netto_price * number
                    this.task_extra.price_catalog = hoya_option.brutto_price * number
                }
            },

            editTaskExtra() {
                this.loading.main = true

                API.put('dictionary/task-extra/action', this.task_extra).then(() => {
                    this.$notify.success('dictionary.taskextra.notify.EDIT')

                    this.$state.go('app.dictionary.taskextras.table')
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('dictionary.taskextra.notify.error.EDIT')
                })
            }
        }
    }
</script>