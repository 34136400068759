<template lang="pug">
    .opti-select-brand
        VueMultiSelect(
            id="category"
            :options="categories"
            :value="category"
            :clearable="false"
            label="name"
            :placeholder="'search.brand.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"
            group-label="type"
            group-values="categories"
            :group-select="false"
            :disabled="disabled"

            @input="updateSelected")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
                i.fa.fa-sun-o(v-show="props.option.type === 'słońce'")
                small.pull-right {{props.option.type}}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{props.option.$groupLabel}}
                span(v-else)
                    span {{props.option.name}}
                    i.fa.fa-sun-o(v-show="props.option.type === 'słońce'")
                    small.pull-right {{props.option.type}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectBrand',
        components: {
            VueMultiSelect
        },
        props: {
            extended: {
                type: Boolean,
                required: false,
                default: false
            },
            category: {
                type: Object,
                required: true,
                default: {}
            },

            disabled: {
                type: Boolean,
                required: false,
                default : false
            }
        },
        data() {
            return {
                types: {
                    ramki: 0,
                    słońce: 0,
                    akcesoria: 1
                },

                categories: [{
                    type: 'Ramki / słońce',
                    categories: []
                }, {
                    type: 'Akcesoria',
                    categories: []
                }]
            }
        },
        mounted() {
            API.get('search/categories').then(res => {
                res.data.forEach(category => {
                    this.categories[this.types[category.type]].categories.push(category)
                })

                if(!this.extended) {
                    this.categories.splice(1, 1)
                }
            }).catch(() => {
                this.$notify.error('search.brand.notify.error.LOAD')
            })
        },
        methods: {
            updateSelected(category) {
                this.$emit('selected', category)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }

    .fa-sun-o {
        margin-left: 3px;
        color: orange;
    }
</style>
