<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right(rowspan="2") #
                    th(style="border-right:1px solid #aaa" rowspan="2") {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th.text-center(style="border-right:1px solid #aaa") {{'prescription.RIGHT'|translate}}
                    th.text-center {{'prescription.LEFT'|translate}}
                    th(style="border-left:1px solid #aaa" v-show="edit")
                tr
                    th.text-center(style="border-right:1px solid #aaa") Visus
                    th.text-center Visus
                    th(style="border-left:1px solid #aaa" v-show="edit") {{'optometrist.history.table.head.TIME'|translate}}
            tbody
                tr(v-for="(log, index) in survey.data")
                    td.text-right {{index + 1}}
                    td(style="border-right:1px solid #aaa") {{log.description}}
                    td.text-center(style="border-right:1px solid #aaa" v-show="log.side === 'l' || log.side === 'r'"
                        :class="{'bold-black': log.changed.length && log.changed.indexOf('visus') > -1}")
                        span(v-show="!log.side || log.side === 'r'") {{log.parsed.visus}}
                    td.text-center(v-show="log.side === 'l' || log.side === 'r'"
                        :class="{'bold-black': log.changed.length && log.changed.indexOf('visus') > -1}")
                        span(v-show="!log.side || log.side === 'l'") {{log.parsed.visus}}
                    td.text-center(colspan="2" v-show="!log.side"
                        :class="{'bold-black': log.changed.length && log.changed.indexOf('visus') > -1}") {{log.parsed.visus}}
                    td.text-right(style="border-left:1px solid #aaa" v-show="edit")
                        OptiOptometristsDetailsSurveyHistoryTime(:action="log.action"
                            :scope="log.time_scope" :diff="log.time_diff")
</template>

<script>
    import OptiOptometristsDetailsSurveyHistoryTime
        from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSurveyHistoryTime'

    export default {
        name: 'OptiOptometristsDetailsSurveyHistoryAcuityNoCorrection',
        components: {
            OptiOptometristsDetailsSurveyHistoryTime
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('optometrist.survey_history_time')
            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }
</script>