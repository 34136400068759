<template lang="pug">
    OptiDialog.fullscreen(:header="false")
        template(#body)
            OptiOptometristsDisplayControls
            .correction-dialog
                .dialog-contents(v-if="!loading.permutations && !loading.path && !loading.settings")
                    table.table
                        tr
                            OptiOptometristsSurveysPath(
                                :view="view"
                                :path="path"
                                :route="route.computed"
                                :visited="visited"

                                @update-view="updateView")
                            td.text-center(style="padding:10px" v-show="view")
                                .text-expressive(style="font-size:18px")
                                    i.fa.fa-eye.mr3
                                    span.mr3 {{view.desc}}
                                    button.btn.btn-info(type="button" :class="{'btn-warning active': help && !with_correction}"
                                        @click="help = !help; with_correction = false") {{'default.HELP'|translate}}
                                    button.btn.btn-info(type="button" style="margin-left:5px" :class="{'btn-warning active': !help && with_correction}"
                                        @click="with_correction = !with_correction; help = false") {{'optometrist.WITHCORRECTION'|translate}}

                                div(v-show="!help && !with_correction")
                                    keep-alive
                                        OptiOptometristsSurveysOptotypesVisus(
                                            v-if="isVisus"
                                            :permutation="permutation.selected"
                                            :dirty="dirty"
                                            :type="type"
                                            :visus="visus"
                                            :read-only="['rSaltVisus', 'lSaltVisus', 'balance2'].includes(view.id)"

                                            @update-visus="updateVisus")

                                        OptiOptometristsSurveysOptotypesRedGreenMirror(
                                            v-if="isEE"
                                            :permutation="permutation.selected"
                                            :cz="cz"
                                            :read-only="view.id === 'balance1'"

                                            @update-cz="updateCz")

                                        OptiOptometristsSurveysOptotypesRedGreen(
                                            v-if="isCz"
                                            :permutation="permutation.selected"
                                            :type="type"
                                            :cz="cz"
                                            :read-only="view.id === 'balance3'"

                                            @update-cz="updateCz")

                                        OptiOptometristsSurveysOptotypesClock(
                                            v-if="isClock"
                                            :axis="axis"

                                            @update-axis="updateAxis")

                                        OptiOptometristsSurveysOptotypesSalt(v-if="isSalt")

                                OptiOptometristsTips(v-show="help && !with_correction" :data="view.tips")

                                OptiOptometristsDetailsRefractionFarDialogCorrectionWith(
                                    v-show="with_correction"

                                    :interview="interview"

                                    :previouslens="previouslens"
                                    :correction="correction")

                                .text-center(style="padding-top:50px" v-show="!help && !returned_to_department && view.id === 'binVisus3'")
                                    OptiButton(type="success" text="optometrist.video.confirm.returned"
                                        @confirmed="goBack()") {{'optometrist.video.confirm.returned.MESSAGE'|translate}}
                                        template(#icon)
                                            i.fa.fa-sign-in

                            td.correction-controls
                                .form-group
                                    label.control-label.col-xs-12.text-center(style="color:#fff") {{'optometrist.video.RANDOM'|translate}}
                                    .col-xs-12(style="white-space:nowrap;display:flex;justify-content:space-between;padding-bottom:10px")
                                        template(v-for="shuffle in permutation.shuffle")
                                            label.btn.btn-default(:for="'random' + shuffle"
                                                :class="{'btn-warning active': permutation.selected.active === shuffle}") {{shuffle + 1}}
                                            input.hide(type="radio" :id="'random' + shuffle" v-model="permutation.selected.active"
                                                name="random" :value="shuffle" @change="updatePermutation()")
                                OptiOptometristsSurveysAutorefraktometr(:autorefraktometr="autorefraktometr")

                                table(style="border-spacing:3px;border-collapse:separate;width:100%;margin-top:10px")
                                    tr.text-center
                                        th(style="color:#fff;font-weight:bolder;width:50%")
                                            span.mr3 {{'prescription.RIGHT'|translate}}
                                            small(v-show="interview.binocular_vision === 1"
                                                :title="'optometrist.correction.BINOCULAR'|translate|lower") (d)
                                        th(style="color:#fff;font-weight:bolder;width:50%")
                                            span.mr3 {{'prescription.LEFT'|translate}}
                                            small(v-show="interview.binocular_vision === 2"
                                                :title="'optometrist.correction.BINOCULAR'|translate|lower") (d)
                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="sphere"
                                                :value="viewModel[view.id].rSphere"
                                                :editable="isEditable('rSphere')"
                                                @update-power="v => viewModel[view.id].rSphere = v")
                                        td
                                            OptiOptometristsSurveysPower(type="sphere"
                                                :value="viewModel[view.id].lSphere"
                                                :editable="isEditable('lSphere')"
                                                @update-power="v => viewModel[view.id].lSphere = v")

                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="cylinder"
                                                :value="viewModel[view.id].rCylinder"
                                                :editable="isEditable('rCylinder')"
                                                @update-power="v => viewModel[view.id].rCylinder = v")
                                        td
                                            OptiOptometristsSurveysPower(type="cylinder"
                                                :value="viewModel[view.id].lCylinder"
                                                :editable="isEditable('lCylinder')"
                                                @update-power="v => viewModel[view.id].lCylinder = v")

                                    tr
                                        td
                                            OptiOptometristsSurveysPower(v-show="viewModel[view.id].rCylinder"
                                                type="axis" :value="viewModel[view.id].rAxis"
                                                :editable="isEditable('rAxis')"
                                                @update-power="v => viewModel[view.id].rAxis = v")
                                        td
                                            OptiOptometristsSurveysPower(v-show="viewModel[view.id].lCylinder"
                                                type="axis" :value="viewModel[view.id].lAxis"
                                                :editable="isEditable('lAxis')"
                                                @update-power="v => viewModel[view.id].lAxis = v")
                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="visus" :value="viewModel[view.id].rVisus")
                                        td
                                            OptiOptometristsSurveysPower(type="visus" :value="viewModel[view.id].lVisus")

                                    tr
                                        td(colspan="2")
                                            OptiOptometristsSurveysPower(style="margin:0 auto;width:50%"
                                                type="binvisus" :value="viewModel[view.id].binVisus")

                                OptiOptometristsSurveysOptotypesType(
                                    v-show="(side === 'right' && view.id === 'rVisus1') || (side === 'left' && view.id === 'lVisus1')"
                                    :type="type" @update-type="t => type = t")

                OptiDimmer(:active="loading.permutations || loading.path || loading.settings")
        template.text-right(#footer)
            .row
                .col-xs-4.text-left
                    OptiButton(type="success" text="optometrist.video.confirm.previous" @confirmed="goPreviousView()"
                        :disabled="(route.computed[0] === view.id) || disabled_btn_step") {{'optometrist.video.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left
                .col-xs-4.text-center
                    OptiButton(type="success" text="optometrist.video.confirm.success" @confirmed="confirmed()"
                        :disabled="!(dirty && ((side === 'right' && view.id === 'rVisus3') || (side === 'left' && view.id === 'lVisus3') || (returned_to_department && view.id === 'binVisus3')))") {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check
                    OptiButton#cancelled(type="danger" text="optometrist.video.confirm.cancel" @confirmed="cancelled()"
                        ) {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove
                .col-sm-4.text-right(style="white-space:nowrap")
                    OptiButton(type="success" :disabled="disabledNextView()" @click.native="goNextView()"
                        :right="true") {{'optometrist.video.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'
    import {PermutationsService} from '@/js/app/vue/components/Optometrists/Helpers/PermutationsService'
    import {PathService} from '@/js/app/vue/components/Optometrists/Helpers/PathService'
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'
    import OptiOptometristsSurveysPower from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysPower'
    import OptiOptometristsSurveysPath from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysPath'
    import OptiOptometristsSurveysOptotypesType
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysOptotypesType'
    import OptiOptometristsSurveysAutorefraktometr
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysAutorefraktometr'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiOptometristsSurveysOptotypesVisus
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesVisus'
    import OptiOptometristsSurveysOptotypesRedGreenMirror
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreenMirror'
    import OptiOptometristsSurveysOptotypesRedGreen
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreen'
    import OptiOptometristsSurveysOptotypesClock
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesClock'
    import OptiOptometristsSurveysOptotypesSalt
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesSalt'
    import OptiOptometristsDetailsRefractionFarDialogCorrectionWith
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogCorrectionWith'

    export default {
        name: 'OptiOptometristsDetailsRefractionFarDialogCorrection',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips,
            OptiOptometristsDisplayControls,
            OptiOptometristsSurveysPower,
            OptiOptometristsSurveysPath,
            OptiOptometristsSurveysOptotypesType,
            OptiOptometristsSurveysAutorefraktometr,
            OptiDimmer,
            OptiOptometristsSurveysOptotypesVisus,
            OptiOptometristsSurveysOptotypesRedGreenMirror,
            OptiOptometristsSurveysOptotypesRedGreen,
            OptiOptometristsSurveysOptotypesClock,
            OptiOptometristsSurveysOptotypesSalt,
            OptiOptometristsDetailsRefractionFarDialogCorrectionWith
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            previous: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },

            side: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unique: Math.random().toString(36).substr(2, 9),

                help: false,
                with_correction: false,

                interview_id: this.interview.id,
                binocular: this.interview.binocular_vision,
                info: {
                    r: {
                        sph: this.interview.dist_c_r_sphere,
                        cyl: this.interview.dist_c_r_cylinder,
                        axs: this.interview.dist_c_r_axis
                    },
                    l: {
                        sph: this.interview.dist_c_l_sphere,
                        cyl: this.interview.dist_c_l_cylinder,
                        axs: this.interview.dist_c_l_axis
                    }
                },
                autorefraktometr: {
                    r: {
                        sph: this.interview.autorefraktometr.r_r_sph,
                        cyl: this.interview.autorefraktometr.r_r_cyl,
                        axs: this.interview.autorefraktometr.r_r_axs,
                        es: this.interview.autorefraktometr.r_r_es
                    },
                    l: {
                        sph: this.interview.autorefraktometr.r_l_sph,
                        cyl: this.interview.autorefraktometr.r_l_cyl,
                        axs: this.interview.autorefraktometr.r_l_axs,
                        es: this.interview.autorefraktometr.r_l_es
                    }
                },

                permutation: {
                    unique: null,
                    shuffle: [],
                    selected: {}
                },

                view: {},
                viewModel: {},

                route: {
                    right: [],
                    left: [],
                    bin: [],
                    computed: []
                },
                visited: [this.side === 'right' ? 'rVisus1' : 'lVisus1'],

                path: [],
                type: 'letter',

                dirty: false,
                disabled_btn_step: false,
                returned_to_department: false,

                loading: {
                    permutations: true,
                    path: true,
                    settings: true
                }
            }
        },
        mounted() {
            PermutationsService.load().then(res => {
                [this.permutation.unique, this.permutation.shuffle, this.permutation.selected] = res
                this.loading.permutations = false
            })

            this.loadPath()
            this.loadSettings()
        },
        methods: {
            loadPath() {
                PathService.load('correction').then(res => {
                    this.path = res

                    PathService.setupFor(this.path, this.side)

                    this.view = PathService.findPoint(this.path, this.side === 'right' ? 'rVisus1' : 'lVisus1')

                    this.setupViewModel(this.side === 'right' ? 'rVisus1' : 'lVisus1', {
                        rSphere: this.autorefraktometr.r.es,
                        rCylinder: this.autorefraktometr.r.cyl,
                        rAxis: this.autorefraktometr.r.axs,
                        rVisus: this.interview.dist_nc_r_visus,
                        rCz: '0',

                        lSphere: this.autorefraktometr.l.es,
                        lCylinder: this.autorefraktometr.l.cyl,
                        lAxis: this.autorefraktometr.l.axs,
                        lVisus: this.interview.dist_nc_l_visus,
                        lCz: '0',

                        binVisus: this.interview.dist_nc_binvisus
                    }, true)

                    let begin_model = _.clone(this.viewModel[this.view.id])
                    begin_model.rSphere = this.autorefraktometr.r.es
                    begin_model.lSphere = this.autorefraktometr.l.es

                    SurveyLogs.save(this.interview.id, this.unique, 'correction',
                        '', 'start', '50%', 'Rozpoczęto badanie (autoref.)',
                        ['rSphere', 'rCylinder', 'rAxis', 'rVisus', 'rCz',
                            'lSphere', 'lCylinder', 'lAxis', 'lVisus', 'lCz', 'binVisus'], begin_model)

                    this.loading.path = false
                })
            },
            loadSettings() {
                API.get('settings').then(res => {
                    let parts = res.data.interview_path.split(','),
                        r = [],
                        l = [],
                        bin = []

                    parts.forEach(part => {
                        if(part.charAt(0) === 'r') {
                            r.push(part)
                        } else if(part.charAt(0) === 'l') {
                            l.push(part)
                        } else {
                            bin.push(part)
                        }
                    })

                    this.route.right = r
                    this.route.left = l
                    this.route.bin = bin

                    if(this.side === 'right') {
                        this.route.computed = [].concat(this.route.right).concat(this.route.left)
                    } else if(this.side === 'left') {
                        this.route.computed = [].concat(this.route.left).concat(this.route.right)
                    }

                    this.route.computed = this.route.computed.concat(this.route.bin)
                }).finally(() => {
                    this.loading.settings = false
                }).catch(() => {
                    this.$notify.error('optometrist.notify.error.SETTINGS')
                })
            },

            setupViewModel(id, basedOn, override) {
                if(!this.viewModel[id] || override) {
                    let previous = (typeof basedOn === 'object') ? basedOn : this.viewModel[basedOn]

                    if(previous) {
                        this.$set(this.viewModel, id, _.cloneDeep(previous))

                        if(!this.viewModel[id].rCylinder) {
                            this.viewModel[id].rAxis = 'brak'
                        }

                        if(!this.viewModel[id].lCylinder) {
                            this.viewModel[id].lAxis = 'brak'
                        }

                        let altId = PathService.alternative(id)
                        if(id !== altId) {
                            this.$set(this.viewModel, altId, this.viewModel[id])
                        }

                        let altId2 = PathService.alternative(altId)
                        if (id !== altId2) {
                            this.$set(this.viewModel, altId2, this.viewModel[id])
                        }

                        if(id === 'rVisus1') {
                            this.viewModel[id].rVisus = this.interview.dist_nc_r_visus
                        } else if(id === 'lVisus1') {
                            this.viewModel[id].lVisus = this.interview.dist_nc_l_visus
                        } else if(this.view.type === 'redgreen' || this.view.type === 'redgreen2') {
                            if(parseInt(this.view.x) < 50) {
                                this.viewModel[id].rCz = '0'
                            } else if(parseInt(this.view.x) > 50) {
                                this.viewModel[id].lCz = '0'
                            }
                        } else if(id === 'binVisus1') {
                            let rVisus = parseFloat(this.viewModel.rVisus3.rVisus.replace(',', '.')),
                                lVisus = parseFloat(this.viewModel.lVisus3.lVisus.replace(',', '.'))

                            this.viewModel[id].binVisus = rVisus > lVisus ?
                                lVisus.toString().replace('.', ',') : rVisus.toString().replace('.', ',')

                            if(this.viewModel[id].binVisus === '1') {
                                this.viewModel[id].binVisus += ',0'
                            }
                        }
                    }
                }
            },

            disabledNextView() {
                if(this.disabled_btn_step) {
                    return true
                } else if(this.view && this.view.type === 'visus' && !['rSaltVisus', 'lSaltVisus', 'balance2'].includes(this.view.id) && !this.dirty) {
                    return true
                } else if(this.view) {
                    if(this.route.computed[this.route.computed.length - 1] === this.view.id) {
                        return true
                    } else if(['balance1', 'balance2', 'balance3'].includes(this.view.id)) {
                        return false
                    } else if(this.view.type === 'visus') {
                        return this.visus === '-'
                    } else if(this.view.type === 'redgreen' || this.view.type === 'redgreen2') {
                        return this.cz === '0'
                    }
                }

                return false
            },
            setDescription(view) {
                let type = ''

                if(view.id === 'visus1') {
                    switch(this.type) {
                        case 'circle':
                            type = 'C'
                            break

                        case 'hs':
                            type = 'E'
                            break

                        case 'letter':
                            type = 'A'
                            break

                        case 'number':
                            type = '8'
                    }

                    return view.desc + ' (typ ' + type + ')'
                }

                return view.desc
            },

            checkOptionalSteps(view) {
                if(['rCZ2', 'rEE2', 'lCZ2', 'lEE2'].includes(view.id)) {
                    let side = view.id.replace('CZ2', '').replace('EE2', ''),
                        vmAxis = this.viewModel[view.id][side + 'Axis']

                    if(vmAxis === 'brak') {
                        this.goNextView(true)
                        this.goNextView(true)
                        this.goNextView(true)
                    }
                } else if(['rOptVisus', 'lOptVisus'].includes(view.id)) {
                    let side = view.id.replace('OptVisus', ''),
                        vmAxis = this.viewModel[view.id][side + 'Axis']

                    if(vmAxis === 'brak') {
                        return false
                    }

                    if(typeof vmAxis === 'string') {
                        vmAxis = parseInt(vmAxis)
                    }

                    let startingAxis = this.autorefraktometr[side].axs

                    if(startingAxis === 'brak') {
                        this.goNextView(true)

                        return false
                    }

                    if(typeof startingAxis === 'string') {
                        startingAxis = parseInt(startingAxis)
                    }

                    if(Math.abs(startingAxis - vmAxis) <= 30) {
                        this.goNextView(true)
                    }
                }
            },
            changedView(previous) {
                this.disabled_btn_step = true

                setTimeout(() => {
                    this.disabled_btn_step = false
                }, 1000)

                this.dirty = false
                this.help = false
                this.with_correction = false

                SurveyLogs.save(this.interview_id, this.unique, 'correction', previous.type2, 'step',
                    ['balance1', 'balance2', 'balance3'].includes(previous.id) ? '50%' : previous.x,
                    this.setDescription(previous), previous.editable, this.viewModel[previous.id])
            },
            goPreviousView() {
                let previous = _.cloneDeep(this.view)

                this.visited.pop()

                let id = this.visited.pop()

                this.view = PathService.findPoint(this.path, id)

                this.visited.push(this.view.id)

                this.changedView(previous)
            },
            goNextView(is_skipped_step) {
                let key = this.route.computed.indexOf(this.view.id),
                    previous = _.cloneDeep(this.view)

                if(key !== -1) {
                    key += 1

                    if(key !== this.route.computed.length) {
                        this.view = PathService.findPoint(this.path, this.route.computed[key])
                    }
                } else {
                    this.view.links.forEach(link => {
                        if(this.route.computed.includes(link.id)) {
                            this.view = link
                        }
                    })
                }

                this.setupViewModel(this.view.id, previous.id, true)
                this.visited.push(this.view.id)
                this.checkOptionalSteps(this.view)

                if(!is_skipped_step) {
                    this.changedView(previous)
                }
            },
            goBack() {
                this.returned_to_department = true

                SurveyLogs.save(this.interview_id, this.unique, 'correction', 'returned', 'returned', this.view.x,
                    'Powrót z salonu', [], this.viewModel[this.view.id])
            },
            isEditable(field) {
                if(this.view.id === 'binVisus3' && !this.returned_to_department) {
                    return false
                }

                return this.view.editable.includes(field)
            },

            displayClose() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')
            },

            saveRight() {
                this.interview.dist_r_sphere = this.viewModel[this.view.id].rSphere
                this.interview.dist_r_cylinder = this.viewModel[this.view.id].rCylinder
                this.interview.dist_r_axis =
                    this.viewModel[this.view.id].rCylinder ? this.viewModel[this.view.id].rAxis : 'brak'

                this.interview.dist_r_visus = this.viewModel.rVisus3.rVisus
                this.interview.dist_r_cz = this.viewModel.rVisus3.rCz

                this.interview.near_r_cylinder = this.interview.dist_r_cylinder
                this.interview.near_r_axis = this.interview.dist_r_axis

                this.interview.near_r_sphere = this.interview.dist_r_sphere + this.interview.dist_r_add

                if(this.interview.dist_r_add) {
                    this.interview.amplitude_uncorrected = 0
                }
            },
            saveLeft() {
                this.interview.dist_l_sphere = this.viewModel[this.view.id].lSphere
                this.interview.dist_l_cylinder = this.viewModel[this.view.id].lCylinder
                this.interview.dist_l_axis = this.viewModel[this.view.id].lCylinder ?
                    this.viewModel[this.view.id].lAxis : 'brak'

                this.interview.dist_l_visus = this.viewModel.lVisus3.lVisus
                this.interview.dist_l_cz = this.viewModel.lVisus3.lCz

                this.interview.near_l_cylinder = this.interview.dist_l_cylinder
                this.interview.near_l_axis = this.interview.dist_l_axis

                this.interview.near_l_sphere = this.interview.dist_l_sphere + this.interview.dist_l_add
            },
            confirmed() {
                if(this.view.id === 'rVisus3') {
                    this.saveRight()
                } else if(this.view.id === 'lVisus3') {
                    this.saveLeft()
                } else if(this.view.id === 'binVisus3') {
                    this.saveRight()
                    this.saveLeft()

                    this.interview.dist_binvisus = this.viewModel.binVisus3.binVisus
                }

                this.displayClose()

                SurveyLogs.save(this.interview_id, this.unique, 'correction',
                    this.view.type2, 'end', this.view.x, this.view.short + ' - zakończono badanie',
                    this.view.editable, this.viewModel[this.view.id])

                this.$emit('confirmed', this.interview)
            },
            cancelled() {
                this.displayClose()

                SurveyLogs.save(this.interview_id, this.unique, 'correction',
                    this.view.type2, 'cancel', this.view.x, 'Anulowano badanie',
                    this.view.editable, this.viewModel[this.view.id])

                this.$emit('cancelled')
            },

            field(type) {
                switch(type) {
                    case 'visus':
                        if(['rVisus1', 'rSaltVisus', 'rVisus3', 'rOptVisus'].includes(this.view.id)) {
                            return 'rVisus'
                        } else if(['lVisus1', 'lSaltVisus', 'lVisus3', 'lOptVisus'].includes(this.view.id)) {
                            return 'lVisus'
                        } else if(['binVisus1', 'binVisus2', 'binVisus3'].includes(this.view.id)) {
                            return 'binVisus'
                        }

                        return 'rVisus'

                    case 'cz':
                        if(['rCZ1', 'rCZ2', 'rCZ3', 'rEE1', 'rEE2', 'rEE3'].includes(this.view.id)) {
                            return 'rCz'
                        } else if(['lCZ1', 'lCZ2', 'lCZ3', 'lEE1', 'lEE2', 'lEE3'].includes(this.view.id)) {
                            return 'lCz'
                        }

                        return 'rCz'

                    case 'axis':
                        if(this.view.id === 'rClock')  {
                            return 'rAxis'
                        }

                        return 'lAxis'
                }
            },

            updateView(l) {
                let previous = _.cloneDeep(this.view)

                this.visited = l[0]
                this.view = l[1]

                this.changedView(previous)
            },
            updatePermutation() {
                PermutationsService.updatePermutation(this.permutation)
            },
            updateVisus(visus) {
                this.visus = visus

                this.dirty = true
            },
            updateCz(cz) {
                this.cz = cz

                this.dirty = true
            },
            updateAxis(axis) {
                this.axis = axis

                this.dirty = true
            }
        },
        computed: {
            isVisus() {
                return ['rVisus1', 'rSaltVisus', 'rVisus3', 'rOptVisus', 'lVisus1', 'lSaltVisus', 'lVisus3', 'lOptVisus',
                    'binVisus1', 'binVisus2', 'balance2'].includes(this.view.id) ||
                    this.view.id === 'binVisus3' && this.returned_to_department
            },
            visus: {
                get() {
                    return this.viewModel[this.view.id][this.field('visus')]
                },
                set(visus) {
                    this.viewModel[this.view.id][this.field('visus')] = visus
                }
            },

            isEE() {
                return ['rEE1', 'rEE2', 'rEE3', 'lEE1', 'lEE2', 'lEE3', 'balance1'].includes(this.view.id)
            },
            isCz() {
                return ['rCZ1', 'rCZ2', 'rCZ3', 'lCZ1', 'lCZ2', 'lCZ3', 'balance3'].includes(this.view.id)
            },
            cz: {
                get() {
                    return this.viewModel[this.view.id][this.field('cz')]
                },
                set(cz) {
                    this.viewModel[this.view.id][this.field('cz')] = cz
                }
            },

            isClock() {
                return ['lClock', 'rClock'].includes(this.view.id)
            },
            axis: {
                get() {
                    return this.viewModel[this.view.id][this.field('axis')]
                },
                set(axis) {
                    this.viewModel[this.view.id][this.field('axis')] = axis
                }
            },

            isSalt() {
                return ['rSalt', 'rSalt0', 'lSalt', 'lSalt0'].includes(this.view.id)
            }
        }
    }
</script>