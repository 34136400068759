<template lang="pug">
    div
        OptiTaskDetailsIpadVisuReal(v-if="[0, 2].includes(task.measurement_app) || task.measurement.id"
            :task="task"

            :is-offer="isOffer"

            :task-duplicate="taskDuplicate"
            :task-service="taskService"

            :block="block"
            :pending="pending"

            @ipad-visu-real-was-updated="$emit('ipad-was-updated')")

        OptiTaskDetailsIpadJawroVisu(v-if="[1, 2].includes(task.measurement_app) || task.measurementJawroVisu.id"
            :task="task"

            :is-offer="isOffer"

            :block="block"
            :pending="pending"

            @ipad-jawro-visu-was-updated="$emit('ipad-was-updated')")

        <!-- TODO: v-if występuje w OptiTaskDetailsIpadJawroVisu -->
        OptiTaskDetailsIpadCompare(v-if="task.measurement_app === 2 || (task.measurement.id && task.measurementJawroVisu.id)"
            :ref-type="isOffer ? 'task_offer' : 'task'"
            :ref-id="task.id"

            :measurement="task.measurement"
            :measurement-jawro-visu="task.measurementJawroVisu")
</template>

<script>
    import OptiTaskDetailsIpadVisuReal
        from '@/js/app/vue/components/Tasks/Details/Ipad/OptiTaskDetailsIpadVisuReal'
    import OptiTaskDetailsIpadJawroVisu
        from '@/js/app/vue/components/Tasks/Details/Ipad/OptiTaskDetailsIpadJawroVisu'
    import OptiTaskDetailsIpadCompare from '@/js/app/vue/components/Tasks/Details/Ipad/OptiTaskDetailsIpadCompare'

    export default {
        name: 'OptiTaskDetailsIpad',
        components: {
            OptiTaskDetailsIpadVisuReal,
            OptiTaskDetailsIpadJawroVisu,
            OptiTaskDetailsIpadCompare
        },
        props: {
            task: {
                type: Object,
                required: true
            },

            isOffer: {
                type: Boolean,
                required: true
            },

            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
