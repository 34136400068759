<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-image.mr3
            span {{'tasks.measurement.individual.compare.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            img(:src="'upload/jawro_visu/' + (isOffer ? 'offer_' : '') + 'task/' + image_toggle + '.jpg'")

        template(#footer)
            .pull-left
                i.fa.fa-clock-o.mr3
                span.mr3 {{'tasks.measurement.individual.compare.PLACEHOLDER'|translate}} {{sec}}s.
                span / {{'tasks.measurement.individual.compare.IMAGE'|translate}} {{pointer}}
            OptiButton.pull-right(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
            .clearfix
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiTaskDetailsIpadJawroVisuDialogCompareImages',
        components: {
            OptiDialog,
            OptiButton: () => import('../../../../../Button/OptiButton')
        },
        props: {
            image1: {
                type: Number,
                required: true
            },
            image2: {
                type: Number,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                interval: null,
                pointer: 1,
                sec: 0.2,
                image_toggle: this.image1
            }
        },
        mounted() {
            this.interval = setInterval(() => {
                this.pointer = this.pointer === 1 ? 2 : 1
                this.image_toggle = this['image' + this.pointer]
            }, this.sec * 1000)
        },
        destroyed() {
            clearInterval(this.interval)
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 98%;

        > .modal-body {
            text-align: center;

            > img {
                max-width: 100%;
            }
        }
    }
</style>