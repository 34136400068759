<template lang="pug">
    div(v-show="!loading")
        OptiButtonToggle(v-show="visit_exists" enum="PatientWaitingConfirmed" :model="confirmed" @change="updateConfirm")
        .warn(v-show="!visit_exists")
            i.fa.fa-exclamation-circle(style="padding-right:3px")
            span {{'patient.schedule.alert.CANCELLED'|translate}}
</template>

<script>
    import {API} from '../../../api'

    import OptiButtonToggle from '@/js/app/vue/components/Button/OptiButtonToggle'

    export default {
        name: 'OptiPatientsScheduleAlert',
        components: {
            OptiButtonToggle
        },
        props: {
            smsId: {
                type: Number
            }
        },
        data() {
            return {
                loading: true,
                visit_exists: false,

                visit: {},
                confirmed: '0'
            }
        },
        mounted() {
            API.get('patients/schedule/alert-remember/' + this.smsId).then(res => {
                if(res.data) {
                    this.visit = res.data
                    this.confirmed = this.visit.confirmed.toString()

                    this.visit_exists = true
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('patient.schedule.notify.error.ALERT')
            })
        },
        methods: {
            updateConfirm(confirmed) {
                if(confirmed === '2') {
                    this.$state.go('app.patients.schedule', {
                        department_id: this.visit.department_id.toString(),
                        view: 'day',
                        date: this.visit.date_visit,
                        visit_id: this.visit.id
                    })

                    this.$notify.warning('patient.schedule.notify.DELETE')
                } else {
                    API.post('patients/schedule/alert-remember/save', {
                        visit_id: this.visit.id,
                        confirmed: confirmed
                    }).catch(() => {
                        this.$notify.error('patient.schedule.notify.error.ALERTSAVE')
                    })
                }
            }
        }
    }
</script>
