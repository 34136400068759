<template lang="pug">
    div(:class="{'table-responsive': previouslens.dist}")
        table.table.table-striped.text-center.middle
            thead
                tr
                    th(style="border-top:1px solid #aaa" colspan="3") {{'optometrist.acuity.CONTACTLENS'|translate}}
            tbody
                tr(v-show="previouslens.contactlens")
                    th
                        button.btn.btn-info(type="button" :disabled="disabled" @click="dialog = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionNearDialogSurvey(
                                    :interview="interview"

                                    r-visus="near_r_visus"
                                    l-visus="near_l_visus"
                                    bin-visus="near_binvisus"

                                    mode="contact_lens_correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog = false")
                    td Visus
                    td BinVisus

                tr(v-show="!previouslens.contactlens")
                    td.text-center.warn.bold(colspan="3") {{'optometrist.correction.NORESULT'|translate}}
                tr(v-show="previouslens.contactlens")
                    th {{'prescription.RIGHT'|translate}}
                    td
                        span.form-control(disabled) {{interview.contactLensCorrection.near_r_visus}}
                    td(rowspan="2")
                        span.form-control(disabled) {{interview.contactLensCorrection.near_binvisus}}
                tr(v-show="previouslens.contactlens")
                    th {{'prescription.LEFT'|translate}}
                    td
                        span.form-control(disabled) {{interview.contactLensCorrection.near_l_visus}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiOptometristsDetailsRefractionNearDialogSurvey
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/Dialogs/OptiOptometristsDetailsRefractionNearDialogSurvey'

    export default {
        name: 'OptiOptometristsDetailsRefractionNearContactLens',
        components: {
            OptiButton,
            OptiEnum,

            OptiOptometristsDetailsRefractionNearDialogSurvey,
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: false
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                this.dialog = false

                this.$emit('update-interview', interview)
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>