<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-loyality-order.form-horizontal.form-validate(name="formOrder" @submit.prevent="addLoyalityOrder()")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'cards.order.form.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="LoyalityCardType" :value="form.type" @update-enum="updateType")
                        OptiValidate(f="type" :data="$v.form.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="comments") {{'cards.order.form.COMMENTS'|translate}}
                    .col-sm-8
                        textarea#comments.form-control(v-model="form.comments" name="comments" required)
                        OptiValidate(f="comments" :data="$v.form.comments")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.form.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsLoyalityCardsOrder',
        components: {
            OptiSelectEnum,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    type: parseInt(this.$state.params.cardType),
                    comments: ''
                },

                loading: false
            }
        },
        validations: {
            form: {
                type: {
                    noZero
                },
                comments: {
                    required
                }
            }
        },

        mounted() {

        },
        methods: {
            addLoyalityOrder() {
                this.loading = true

                API.post('loyality-card/order/add', {
                    cardId: this.$state.params.cardId,
                    type: this.form.type,
                    comments: this.form.comments
                }).then(res => {
                    this.$notify.success('cards.order.notify.SAVE')

                    this.$state.go('app.patient.detail', {
                        id: res.data.patientId,
                        '#': 'loyality'
                    })
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('cards.notify.event.ERRORNOADDED')
                })
            },
            updateType(type) {
                this.form.type = type
            }
        }
    }
</script>
