<template lang="pug">
    .panel.panel-default.relative
        .panel-heading.bold Budżet salonu
        .panel-body.relative
            .info(v-show="!departmentId") Wybierz salon żeby wyświetlić dane

            line-chart(
                v-if="chartData"
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

            OptiChartTooltip(
                :model="tooltipModel"
                :chart-data="chartData"
                :chart="$refs.chart")
                template(#default="{body}")
                    OptiChartDepartmentBudgetTooltip(
                        :tooltip="body.tooltip"
                        :chart-data="chartData")

        OptiDimmer(:active="$asyncComputed.chartData.updating")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {INIT} from '@/js/app/vue/store/opti-store'
    import {budgetForecastAnnotation} from './Base/OptiChartBudgetAnnotations'

    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'

    import OptiChartTooltip from './Base/OptiChartTooltip'
    import OptiChartDepartmentBudgetTooltip from './OptiChartDepartmentBudgetTooltip'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiChartDepartmentBudget',
        components: {
            LineChart,
            OptiChartTooltip,
            OptiChartDepartmentBudgetTooltip,
            OptiDimmer
        },
        props: {
            data: {
                type: Object,
                required: false
            },
            departmentId: {
                type: Number,
                required: true
            },
            year: {
                type: [Number, String],
                required: true
            },
            quarter: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                chartData: null,
                tooltipModel: null,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    spanGaps: false,
                    tooltips: {
                        mode: 'index',
                        enabled: false,
                        custom: tooltip => {
                            this.tooltipModel = tooltip
                        }
                    },
                    legend: {
                        position: 'right',
                        align: 'end'
                    },
                    elements: {
                        line: {
                            tension: 0.4,
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                    annotation: {
                        annotations: [
                            ...budgetForecastAnnotation(moment(this.year + '-10-10'))
                        ]
                    }
                },
                styles: {
                    position: 'relative',
                    height: '400px'
                }
            }
        },
        mounted() {
            this.$store.dispatch(`departments/${INIT}`)
        },
        asyncComputed: {
            async chartData() {
                if(this.departmentId) {
                    try {
                        let {data} = await API.post('charts/budget', {
                            departmentId: this.departmentId,
                            year: this.year,
                            quarter: this.quarter
                        })

                        _.forEach(data.datasets, dataset => {
                            dataset.pointHoverRadius = 10
                            dataset.pointHitRadius = 10
                            dataset.radius = 6
                        })

                        // os/skor
                        data.datasets[2].pointStyle = 'star'
                        data.datasets[3].pointStyle = 'star'

                        // amb
                        data.datasets[4].pointStyle = 'triangle'
                        data.datasets[5].pointStyle = 'triangle'

                        // centrl
                        data.datasets[6].pointStyle = 'crossRot'
                        data.datasets[7].pointStyle = 'crossRot'

                        return data
                    } catch(error) {
                        if(!API.isCancel(error)) {
                            console.log(error)
                        }
                    }
                }
            }
        },
    }
</script>

<style scoped lang="less">
    .fill-box {
        display: flex;
    }

    .fill-grow {
        flex: 1;
        padding-right: 20px;
    }

    .relative {
        position: relative;
    }

    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        display: inline-block;
        margin-right: 4px;
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }

    .info {
        text-align: center;
        font-size: 21px;
    }
</style>
