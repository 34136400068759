<template lang="pug">
    .row
        .col-xs-12(v-if="selected")
            .panel.panel-default
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span(v-show="!printed") {{'cards.NEW'|translate}}
                    span(v-show="printed") {{'cards.GENERATED'|translate}}
                table.table.table-striped.table-hover.middle
                    thead
                        tr
                            th #
                            th {{'cards.table.REQUEST_ID'|translate}}
                            th {{'cards.table.SALON'|translate}}
                            th {{'cards.table.CREATED'|translate}}
                            th {{'cards.table.COMMENTS'|translate}}
                            th {{'cards.table.CARD_TYPE'|translate}}
                            th {{'cards.table.CARD_NUMBER'|translate}}
                            th {{'cards.table.PATIENT'|translate}}
                    tbody
                        tr(v-for="(select, index) in selected")
                            td {{index + 1}}
                            td {{select.id}}
                            td {{select.salonName}}
                            td {{select.created}}
                            td {{select.comments}}
                            td {{select.cardType}}
                            td(v-show="!printed")
                                input.form-control(type="text" name="card_number" v-model="select.cardId")
                            td(v-show="printed") {{select.cardId}}
                            td
                                a.link(:title="'register.return.sale.table.PATIENTDETAILS'|translate"
                                    :href="$state.href('app.patient.detail', {id: select.patientId})"
                                )
                                    span.block {{select.patientId}}
                                    span.block {{select.patientName}}
                                    span.block {{select.patientPostal}} {{select.patientCity}}
                                    span.block {{select.patientStreet}}
                                    span.block tel. {{select.patientPhone}}
                .panel-footer.clearfix(v-show="!printed")
                    OptiButton.pull-left(
                        :disabled="!selected"
                        @click.native="printDocument"
                        ) {{'cards.buttons.DOWNLOAD_PRINTOUT'|translate}}
                            template(#icon)
                                i.fa.fa-print

                    OptiButtonSave.pull-right(@click.native="$emit('save-changes', selected)")

                .panel-footer.clearfix(v-show="printed")
                    OptiButton.pull-left(
                        @click.native="$emit('go-back')"
                    ) {{'cards.buttons.GO_BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left


</template>

<script>

import {API} from '@/js/app/vue/api'

import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

export default {
    name: 'OptiCardsGeneratedView',
    components: {OptiButtonSave, OptiButton},
    props: {
        selected: null,
        printed: null
    },
    mounted() {
        if(this.printed === false) {
            this.selected.forEach(select => {
                select.cardId = null
            })
        }
    },
    methods: {
        printDocument() {
            API.post('loyality-card/printCsv', {
                selected: this.selected
            }).then((res) => {
                window.open(res.data)
            }).catch(() => {
                this.$notify.error('cards.notify.ERROR')
            })
        }
    }
}
</script>

<style scoped>
    .block {
        display:block;
    }
</style>