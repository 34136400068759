<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'report.tasks.HEADING'|translate}}

        .panel-body
            .row
                .col-xs-12
                    .form-inline
                        .form-group
                            label.label-padding {{'report.tasks.period.HEADING'|translate}}
                            .btn-group
                                label.btn.btn-default(:class="{'active': period === 'own'}" @click="updatePeriod('own')")
                                    i.fa.fa-calendar.mr3
                                    span {{'report.tasks.period.btn.OWN'|translate}}
                                label.btn.btn-default(:class="{'active': period === 'year'}" @click="updatePeriod('year')"
                                    ) {{'report.tasks.period.btn.YEAR'|translate}}
                                label.btn.btn-default(:class="{'active': period === 'month'}" @click="updatePeriod('month')"
                                    ) {{'report.tasks.period.btn.MONTH'|translate}}
                                label.btn.btn-default(:class="{'active': period === 'week'}" @click="updatePeriod('week')"
                                    ) {{'report.tasks.period.btn.WEEK'|translate}}

                        .form-group.pull-right
                            label.label-padding(for="date_to") {{'report.tasks.date.TO'|translate}}
                            OptiDate#date_to(:disabled="period !== 'own'" :value="date.to" @update-date="updateDateTo")
                            OptiValidate(f="date_to" :data="$v.date.to")
                        .form-group.pull-right
                            label.label-padding(for="date_from") {{'report.tasks.date.FROM'|translate}}
                            OptiDate#date_from(:disabled="period !== 'own'" :value="date.from" @update-date="updateDateFrom")
                            OptiValidate(f="date_from" :data="$v.date.from")

                .col-xs-12(style="padding-top:15px")
                    .form-inline
                        .form-group
                            label.label-padding {{'report.tasks.group.HEADING'|translate}}
                            .btn-group
                                label.btn.btn-default(:class="{'active': group === 'year'}" @click="updateGroup('year')"
                                    ) {{'report.tasks.group.btn.YEAR'|translate}}
                                label.btn.btn-default(:class="{'active': group === 'month'}" @click="updateGroup('month')"
                                    ) {{'report.tasks.group.btn.MONTH'|translate}}
                                label.btn.btn-default(:class="{'active': group === 'week'}" @click="updateGroup('week')"
                                    ) {{'report.tasks.group.btn.WEEK'|translate}}
                                label.btn.btn-default(:class="{'active': group === 'day'}" @click="updateGroup('day')"
                                ) {{'report.tasks.group.btn.DAY'|translate}}

                .col-xs-12(v-if="!loading")
                    LineChart(:chart-data="data")

                .col-xs-12
                    OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {requiredIf} from 'vuelidate/lib/validators'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportTasks',
        components: {
            OptiDate,
            OptiValidate,
            LineChart,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                period: 'own',
                group: 'week',
                date: {
                    from: moment().isoWeekYear(moment().isoWeekYear()).isoWeek(1).startOf('week').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                },

                data: null,

                loading: true
            }
        },
        validations: {
            date: {
                from: {
                    required: requiredIf(function() { return this.period === 'own' })
                },
                to: {
                    required: requiredIf(function() { return this.period === 'own' })
                }
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                if(this.$v.date.$invalid) return false

                this.loading = true

                API.post('reports/chart', {
                    date: this.period === 'own' ? this.date.from + ';' + this.date.to : this.period,
                    groupBy: this.group
                }).then(res => {
                    this.data = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.tasks.notify.error.LOAD')
                })
            },

            updatePeriod(period) {
                this.period = period

                this.load()
            },
            updateDateFrom(date_from) {
                this.date.from = date_from

                this.load()
            },
            updateDateTo(date_to) {
                this.date.to = date_to

                this.load()
            },
            updateGroup(group) {
                this.group = group

                this.load()
            }
        }
    }
</script>

<style scoped lang="less">
    .form-inline > .form-group {
        padding-right: 15px;

        > label.label-padding {
            padding-right: 5px;
        }
    }
</style>