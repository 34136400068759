<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary.reclamation-service
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'sale.reclamation.DEPARTMENTATTENSION'|translate}}
                .panel-body(v-for="reclamationServiceType in reclamationServiceTypes"
                    v-if="reclamationServiceType.id && services[reclamationServiceType.id].length")
                    .row
                        .col-xs-12.bold(style="padding-top:10px;padding-bottom:5px;font-size:20px")
                            i.fa.fa-wrench.mr3
                            span {{reclamationServiceType.name|translate}}
                    .row(v-for="(service, key) in services[reclamationServiceType.id]")
                        .col-sm-6
                            b #
                            |{{services[reclamationServiceType.id].length - key}}
                            div(style="padding-bottom:10px") {{service.name}}
                            b {{'sale.reclamation.service.DESCRIPTION'|translate}}
                            div(v-html="service.description")
                        .col-sm-6
                            .text-center(style="font-style:italic;padding-top:10px" v-show="!service.picture"
                            ) {{'sale.reclamation.service.NOPHOTO'|translate}}
                            a(:href="path + '/' + service.picture|upload" target="_blank" v-if="service.picture")
                                img.img-rounded(style="width:100%" :src="path + '/' + service.picture|upload")
                        .col-xs-12(style="padding-bottom:35px" v-show="key !== services[reclamationServiceType.id].length - 1")
</template>

<script>
    import {EnumReclamationServiceType} from '../../enums/Enums'

    export default {
        name: 'OptiReclamationServicesView',
        components: {

        },
        props: {
            data: {
                type: Array,
                required: true
            },
            path: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                reclamationServiceTypes: {},
                services: {}
            }
        },
        mounted() {
            this.services = {}

            this.reclamationServiceTypes = EnumReclamationServiceType.simpleItems().map(member => {
                this.services[member.id] = []

                return member
            })

            this.data.forEach((service) => {
                this.services[service.type].push({
                    name: service.name,
                    description: service.pivot.description,
                    picture: service.pivot.picture
                })
            })
        }
    }
</script>
