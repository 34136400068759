<template lang="pug">
    div
        .row
            .col-xs-12
                OptiDimmer(:active="loading")
            #btn-group-patient-detail.col-xs-12(v-if="!loading")
                    .col-sm-4.col-xs-12.text-center-xs(style="padding:5px 0")
                        OptiButtonHistory(data="training" :id="training.id")
                        OptiButton(style="margin-left:5px" type="success" v-if="!training.status && $uac.permission('schedule.trainings')"
                            :disabled="disabledSaveUsers()" text="user.training.actions.accept.confirm" @confirmed="saveUsers(true)"
                            ) {{'user.training.actions.accept.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check
                    .col-sm-4.col-xs-12.text-center(style="padding:5px 0")
                        OptiButton(type="danger" v-if="[0, 1].includes(training.status) && $uac.permission('schedule.trainings')"
                            text="user.training.actions.cancel.confirm" @confirmed="goCancel"
                            ) {{'user.training.actions.cancel.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check
                    .col-sm-4.col-xs-12.text-center-xs.text-right(style="padding-right:0px")
                        OptiButton(type="primary" v-if="[0, 1].includes(training.status) && $uac.permission('schedule.trainings')"
                            @click.native="$state.go('app.user.training.edit', {id: $state.params.id})"
                            ) {{'user.training.breadcrumb.EDIT'|translate}}
                            template(#icon)
                                i.fa.fa-edit

                        OptiButton(style="margin-left:10px" type="primary" v-if="training.status === 1 || training.status === 2"
                            @click.native="goToTimesheet()") {{'user.training.PRINTTIMESHEET'|translate}}
                            template(#icon)
                                i.fa.fa-print

        .row(v-if="!loading")
            .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0
                .panel-grid
                    .panel.panel-primary
                        .panel-heading
                            .pull-left
                                i.fa.fa-navicon.mr3
                                span {{'user.training.breadcrumb.TRAINING'|translate}}
                            .pull-right \#{{training.id}}
                            .clearfix
                        .row
                            .col-sm-6 {{'user.training.table.STATUS'|translate}}
                            .col-sm-6 {{training.status_name|translate}}
                        .row
                            .col-sm-6 {{'user.training.table.TYPE'|translate}}
                            .col-sm-6 {{training.trainingType.name}}
                        .row
                            .col-sm-6 {{'user.training.table.NAME'|translate}}
                            .col-sm-6 {{training.name}}

                        .row(v-show="training.trainingType.downloading.length")
                            .col-sm-6 {{'user.training.DOWNLOADING'|translate}}
                            .col-sm-6
                                ul(style="list-style-type:none;padding:0;margin:0;float:left")
                                    li(v-for="downloading in training.trainingType.downloading")
                                        a.link(@click="openDownloadLink(downloading)")
                                            i.fa.fa-paperclip.mr3
                                            span {{downloading.name}}

                        .row
                            .col-sm-6 {{'user.training.table.date.FROM'|translate}}
                            .col-sm-6 {{training.date_from}}
                        .row
                            .col-sm-6 {{'user.training.table.date.TO'|translate}}
                            .col-sm-6 {{training.date_to}}

                        .row
                            .col-sm-6 {{'user.training.table.date.hours.FROM'|translate}}
                            .col-sm-6 {{training.date_from_start}}:{{training.date_from_end}}
                        .row
                            .col-sm-6 {{'user.training.table.date.hours.TO'|translate}}
                            .col-sm-6 {{training.date_to_start}}:{{training.date_to_end}}

                        .row
                            .col-sm-6 {{'user.training.table.MAX'|translate}}
                            .col-sm-6 {{training.max}}
                        .row
                            .col-sm-6 {{'user.training.table.COMMENTS'|translate}}
                            .col-sm-6(style="word-break:break-word" v-html="$options.filters.nl2br(training.comments)")

            .col-xs-12
                .panel-grid
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-users.mr3
                            span {{'user.training.USERS'|translate}}
                            .pull-right
                                span.label.label-inverse {{assignedUsers()}}

                        .panel-body(style="padding:0")
                            .alert.alert-warning(style="margin:10px" v-show="assignedUsers() > training.max")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'user.training.notify.error.MAX'|translate}}
                            table.table.table-responsive.teable-striped
                                thead
                                    tr
                                        th {{'user.training.users.NAME'|translate}}
                                        th {{'user.training.users.STATUS'|translate}}
                                        th {{'user.training.users.COMMENTS'|translate}}
                                        th {{'user.training.users.REASONOFCANCELLED'|translate}}
                                tbody
                                    tr(v-for="(employee, key) in training.employees")
                                        td
                                            a.link(v-show="!employee.new" @click="goToUser(employee.id)")
                                                div {{employee.firstName}} {{employee.lastName}}
                                                small ({{employee.pivot.department_name}})
                                                small(style="display:block")
                                                    span(style="padding-right:5px") {{'user.training.users.phone.HEADING'|translate}}
                                                    span(v-show="employee.cellPhoneNumber") {{employee.cellPhoneNumber|number(0)}}
                                                    span.warn(v-show="!employee.cellPhoneNumber") {{'user.training.users.phone.NONE'|translate}}
                                            div(v-if="employee.new")
                                                OptiSelectEmployeeGroupByDepartment(mode="central" :user="employee.data" :index="key"
                                                    :disabled-users="disabled_users" @selected="updateEmployee")
                                                a.link.warn(@click="deleteEmployee(key)")
                                                    i.fa.fa-trash.mr3
                                                    span {{'user.training.users.DELETE'|translate}}
                                        td
                                            OptiSelectEnum(enum="TrainingUserStatus" :value="training.employees[key].pivot.status" :index="key" @update-enum="updateUserStatus"
                                                :disabled="training.status === 3 || training.status === 4 || !$uac.permission('schedule.trainings')")
                                            div(style="padding-top:5px" v-show="employee.opinions && employee.opinions.length")
                                                div {{'user.training.users.OPINION'|translate}}
                                                a.link(style="display:block" v-for="opinion in employee.opinions"
                                                    :href="'user_documents/' + opinion.upload|upload" target="_blank")
                                                    i.fa.fa-paperclip.mr3
                                                    span {{opinion.note}}
                                        td
                                            textarea.form-control(v-model="employee.pivot.comments" rows="4"
                                                :disabled="training.status === 3 || training.status === 4 || !$uac.permission('schedule.trainings')")
                                        td
                                            textarea.form-control(v-model="employee.pivot.reason_of_cancelled" rows="4"
                                                :class="{'its-error': employee.pivot.status === 2 && !employee.pivot.reason_of_cancelled}"
                                                :disabled="employee.pivot.status !== 2 || training.status === 3 || training.status === 4 || !$uac.permission('schedule.trainings')")

                                    tr(v-show="!training.employees.length")
                                        td.text-center.text-muted(colspan="4")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'user.training.users.NONE'|translate}}

                        .panel-footer.text-right(v-show="$uac.permission('schedule.trainings') && training.status !== 3 && training.status !== 4")
                            OptiButton(style="margin-right:10px" type="success" @click.native="addUser()"
                                ) {{'user.training.users.ADD'|translate}}
                                template(#icon)
                                    i.fa.fa-plus
                            OptiButtonSave(:disabled="disabledSaveUsers()" @click.native="saveUsers(false)")

            .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0
                OptiNotes(table="training_notes" table-key="training_id" :upload="true"
                    :id="training.id" :notes="training.notes" @update-notes="updateNotes")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {DownloadingHelper} from '@/js/app/vue/components/Downloading/DownloadingHelper'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectEmployeeGroupByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiButtonHistory from "@/js/app/vue/components/Button/OptiButtonHistory";

    export default {
        name: 'OptiUsersTrainingDetails',
        components: {
            OptiButtonHistory,
            OptiDimmer,
            OptiSelectEnum,
            OptiSelectEmployeeGroupByDepartment,
            OptiButton,
            OptiButtonSave,
            OptiNotes
        },
        data() {
            return {
                training: {
                    id: null,
                    status: 0,

                    type: 0,
                    links: [],
                    name: '',

                    date_from: '',
                    date_to: '',

                    max: 0,
                    comments: '',

                    employees: [],
                    notes: []
                },

                disabled_users: [],
                loading: true
            }
        },
        mounted() {
            API.get('training/' + this.$state.params.id).then(res => {
                this.training = res.data

                this.setSelectDisabledUsers()
                this.getOpinions()

                this.loading = false
            }).catch(() => {
                this.$notify.error('user.training.notify.error.LOAD')
            })
        },
        methods: {
            setSelectDisabledUsers() {
                this.training.employees.forEach(employee => {
                    this.disabled_users.push(employee.id)
                })
            },
            getOpinions() {
                API.get('training/users/opinions/' + this.training.id).then(res => {
                    this.training.employees.forEach(employee => {
                        let opinions = []

                        res.data.forEach(document => {
                            if(employee.id === document.employee_id) {
                                opinions.push(document)
                            }
                        })

                        this.$set(employee, 'opinions', opinions)
                    })
                }).catch(() => {
                    this.$notify.error('user.training.users.notify.error.OPINIONS')
                })
            },
            goToUser(user_id) {
                this.$state.go('app.user.edit', {
                    id: user_id
                })
            },
            goToTimesheet() {
                window.open('api/training/print/timesheet/' + this.training.id)
            },

            addUser() {
                this.training.employees.push({
                    new: true,
                    id: 0,
                    data: {},
                    pivot: {
                        status: 0,
                        comments: '',
                        reason_of_cancelled: ''
                    }
                })
            },
            saveUsers(accept) {
                this.loading = true

                API.post('training/users', this.training).then(res => {
                    this.training = res.data

                    this.$notify.success('user.training.users.notify.SAVE')

                    if(accept) {
                        this.goAccept()
                    } else {
                        this.loading = false
                    }
                }).catch(() => {
                    this.$notify.error('user.training.users.notify.error.SAVE')

                    this.loading = false
                })
            },

            goAccept() {
                this.loading = true

                API.get('training/accept/' + this.training.id).then(res => {
                    this.training = res.data

                    this.$notify.success('user.training.actions.accept.notify.ACCEPTED')
                }).catch(() => {
                    this.$notify.error('user.training.actions.accept.notify.error.ACCEPTED')
                }).finally(() => {
                    this.loading = false
                })
            },
            goCancel() {
                this.loading = true

                API.get('training/cancel/' + this.training.id).then(res => {
                    this.training = res.data

                    this.$notify.success('user.training.actions.cancel.notify.CANCELLED')
                }).catch(() => {
                    this.$notify.error('user.training.actions.cancel.notify.error.CANCELLED')
                }).finally(() => {
                    this.loading = false
                })
            },

            assignedUsers() {
                let count = 0

                this.training.employees.forEach(employee => {
                    if(!employee.pivot.status) {
                        ++count
                    }
                })

                return count
            },

            updateEmployee(employee) {
                this.training.employees[employee.index].id = employee.id
                this.training.employees[employee.index].data = employee

                this.disabled_users.push(employee.id)
            },
            deleteEmployee(key) {
                this.$delete(this.training.employees, key)
            },

            updateUserStatus(data) {
                this.training.employees[data[0]].pivot.status = data[1]
            },
            updateNotes(notes) {
                this.training.notes = notes
            },

            disabledSaveUsers() {
                let disabled = false

                this.training.employees.forEach(employee => {
                    if((employee.pivot.status === 2 && !employee.pivot.reason_of_cancelled) || !employee.id) {
                        disabled = true
                    }
                })

                return this.training.status === 4 || !this.training.employees.length ||
                    this.assignedUsers() > this.training.max || disabled
            },

            openDownloadLink(download) {
                DownloadingHelper.openUri(download)
            }
        }
    }
</script>