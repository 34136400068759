<template lang="pug">

        tr
            td(:class="'schedule-td-color-' + user.color")
                span {{user.name}}
                span.fa.fa-sun-o(v-if="user.sun")
            td.event-empty.event-week(v-for="user_events in user.data")
                OptiScheduleEditor(:events="user_events" :workingHours="user.workingHours")
            td.bg-separator.bold {{user.hours}}
            td.bg-separator.bold
                TextCumulative(:right="true") {{user.week_hours}}
            td.bg-separator.bold
                span {{user.quarter_hours}}
                span /
                span {{user.quarterWorkingHours}}
</template>

<script>
    import OptiScheduleEditor from '@/js/app/vue/components/Schedule/Week/OptiScheduleEditor'
    import TextCumulative from '@/js/app/vue/components/Text/TextCumulative'

    export default {
        name: 'OptiScheduleWeekRow',
        components: {OptiScheduleEditor, TextCumulative},
        props: {
            user: {
                type: undefined,
                required: true
            },
        },
    }
</script>

<style scoped>

</style>
