<template lang="pug">
    .input-group
        input.form-control(:id="id" v-model="patient_id" :name="id" :disabled="disabled"
            :required="required" :placeholder="'search.patient.PLACEHOLDER'|translate"
            @keyup.enter="download" @blur="download")
        span.input-group-addon(@click="download" :disabled="disabled")
            span(v-if="loading === 'ok'") {{result.firstName}} {{result.lastName}}
            i.fa.fa-remove.warn(v-if="loading === 'error'")
            i.fa.fa-spin.fa-cog(v-if="loading === 'fetching'")
            i.fa.fa-search(v-if="!loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectPatient',
        props: {
            id: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            initResult: {
                type: Array | Object,
                required: false,
                default: {}
            }
        },
        watch: {
            disabled(disabled) {
                if(!disabled) {
                    this.$emit('selected', this.result)
                }
            }
        },
        data() {
            return {
                patient_id: null,
                loading: false,
                result: {}
            }
        },
        mounted() {
            this.result = this.initResult

            if(Object.keys(this.result).length) {
                this.patient_id = this.result.id
                this.loading = 'ok'
            }
        },
        methods: {
            download() {
                if(!this.disabled) {
                    if(this.loading === 'fetching') return

                    if(parseInt(this.patient_id)) {
                        this.loading = 'fetching'
                        this.result = {}
                        this.$emit('selected', this.result)

                        API.get('patient/' + this.patient_id).then(res => {
                            this.result = res.data
                            this.$emit('selected', this.result)

                            this.loading = Object.keys(this.result).length ? 'ok' : 'error'
                        })
                    } else {
                        this.loading = false
                        this.result = {}
                        this.$emit('selected', this.result)
                    }
                }
            }
        }
    }
</script>
