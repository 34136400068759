<template lang="pug">
    .row
        .col-xs-12
            legend {{'shipments.new.RECEIVER'|translate}}

        .col-md-4
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="0" @change="changeAddress")
                    span
                    | {{'shipments.new.receiver.HEADQUARTERS'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="1" :disabled="blockPatient"
                        @change="changeAddress")
                    span
                    | {{'shipments.new.receiver.PATIENT'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="2" :disabled="blockPatient"
                        @change="changeAddress")
                    span
                    | {{'shipments.new.receiver.TASK'|translate}}
            .radio.c-radio.c-radio-nofont
                label.bold
                    input(type="radio" v-model="receiver.type" name="receiver_type" :value="3" :disabled="blockPatient"
                        @change="changeAddress")
                    span
                    | {{'shipments.new.receiver.OTHER'|translate}}

        .col-md-8
            div(v-show="receiver.type === 0")
                .form-group
                    label(for="headquarters") {{'shipments.new.receiver.headquarters.SELECT'|translate}}
                    .controls
                        select#headquarters.form-control(v-model="model.department" :disabled="selectDepartments.length === 1"
                            @change="changeAddress")
                            option(v-for="department in selectDepartments" :value="department") {{department.firstName}}
            div(v-show="receiver.type === 1")
                .form-group
                    label(for="patient") {{'shipments.new.receiver.patient.PATIENTID'|translate}}
                    .controls
                        OptiSelectPatient#patient(:init-result="{}" @selected="updatePatient")
            div(v-show="receiver.type === 2")
                .form-group
                    label(for="task") {{'shipments.new.receiver.task.TASKID'|translate}}
                    .controls
                        OptiSelectTask#task(:init-result="{}" @selected="updateTask")
            form(v-show="receiver.type === 3")
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="name") {{'shipments.new.receiver.other.NAME'|translate}}
                            .controls
                                input#name.form-control(v-model="model.custom.firstName" name="firstName" required)
                                OptiValidate(f="name" :data="vModelCustom.firstName")
                    .col-md-6
                        .form-group
                            label(for="surname") {{'shipments.new.receiver.other.SURNAME'|translate}}
                            .controls
                                input#surname.form-control(v-model="model.custom.lastName" name="lastName")
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="zipCode") {{'shipments.new.receiver.other.POSTALCODE'|translate}}
                            .controls
                                input#zipCode.form-control(v-model="model.custom.zipCode" name="zipCode" required)
                                OptiValidate(f="zipCode" :data="vModelCustom.zipCode")
                    .col-md-6
                        .form-group
                            label.star-required(for="city") {{'shipments.new.receiver.other.CITY'|translate}}
                            .controls
                                input#city.form-control(v-model="model.custom.city" name="city" required)
                                OptiValidate(f="city" :data="vModelCustom.city")
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="street") {{'shipments.new.receiver.other.STREET'|translate}}
                            .controls
                                input#street.form-control(v-model="model.custom.street" name="street" required)
                                OptiValidate(f="street" :data="vModelCustom.street")
                    .col-md-6
                        .form-group
                            label(for="telephone") {{'shipments.new.receiver.other.TELEPHONE'|translate}}
                            .controls
                                input#telephone.form-control(v-model="model.custom.cellPhoneNumber" name="telephone")

</template>

<script>
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiSelectTask from '@/js/app/vue/components/Select/OptiSelectTask'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiShipmentCreateStepReceivers',
        components: {
            OptiSelectPatient,
            OptiSelectTask,
            OptiValidate
        },
        props: {
            model: {
                type: Object,
                required: true
            },
            filter: {
                type: Object,
                required: true
            },
            departments: {
                type: Array,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },
            items: {
                type: Array,
                required: true
            },

            vModelCustom: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            changeAddress() {
                switch(parseInt(this.receiver.type)) {
                    case 0:
                        this.receiver.address = this.model.department
                        break

                    case 1:
                        this.receiver.address = this.model.patient
                        break

                    case 2:
                        this.receiver.address = Object.keys(this.model.task).includes('patient') ?
                            this.model.task.patient : {}
                        break

                    case 3:
                        this.receiver.address = this.model.custom
                }
            },
            updatePatient(patient) {
                this.model.patient = patient
                this.changeAddress()
            },
            updateTask(task) {
                this.model.task = task
                this.changeAddress()
            },


            filterDepartments(departments, filter) {
                let filtered = []

                if(parseInt(filter)) {
                    departments.forEach(department => {
                        if(filter === 1 && department.id === 49) {
                            filtered.push(department)
                        } else if(filter === 2 && department.id !== 49) {
                            filtered.push(department)
                        }
                    })
                } else {
                    filtered = departments
                }

                return filtered
            },
            filterDestinations(departments, filter) {
                let filtered = []

                if(parseInt(filter)) {
                    departments.forEach(department => {
                        if(filter === department.id) {
                            filtered.push(department)
                        }
                    })
                } else {
                    filtered = departments
                }

                return filtered
            }
        },
        computed: {
            selectDepartments() {
                let _return = this.filterDestinations(this.filterDepartments(this.departments, this.filter.mode), this.filter.destination)

                if(_return.length === 1) {
                    this.model.department = _return[0]

                    this.changeAddress()
                }

                return _return
            },
            blockPatient() {
                let result = false

                this.items.forEach(item => {
                    if(!((item.type === 4 && item.status === 9) || item.type === -1)) {
                        result = true
                    }
                })

                return result
            }
        }
    }
</script>