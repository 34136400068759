<template lang="pug">
    .form-group
        label.control-label.col-xs-12.text-center(style="color:#fff") {{'optometrist.video.TYPE'|translate}}
        .col-xs-12(style="display:flex;justify-content:space-between;white-space:nowrap")
            label.btn.btn-default(for="type1" :class="{'btn-warning active': t === 'circle'}") C
            input#type1.hide(type="radio" v-model="t" name="type" value="circle" @change="updateType()")
            label.btn.btn-default(for="type2" :class="{'btn-warning active': t === 'hs'}") E
            input#type2.hide(type="radio" v-model="t" name="type" value="hs" @change="updateType()")
            label.btn.btn-default(for="type3" :class="{'btn-warning active': t === 'letter'}") A
            input#type3.hide(type="radio" v-model="t" name="type" value="letter" @change="updateType()")
            label.btn.btn-default(for="type4" :class="{'btn-warning active': t === 'number'}") 8
            input#type4.hide(type="radio" v-model="t" name="type" value="number" @change="updateType()")
            label.btn.btn-default(v-show="withKids" for="type5" :class="{'btn-warning active': t === 'kids'}")
                i.fa.fa-child
            input#type5.hide(v-show="withKids" type="radio" v-model="t" name="type" value="kids" @change="updateType()")
</template>

<script>
    export default {
        name: 'OptiOptometristsSurveysOptotypesType',
        props: {
            type: {
                type: String,
                required: true
            },
            withKids: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                t: this.type
            }
        },
        mounted() {

        },
        methods: {
            updateType() {
                this.$emit('update-type', this.t)
            }
        }
    }
</script>