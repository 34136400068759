import { render, staticRenderFns } from "./OptiProductDetailedStatistic.vue?vue&type=template&id=2073eb70&scoped=true&lang=pug&"
import script from "./OptiProductDetailedStatistic.vue?vue&type=script&lang=js&"
export * from "./OptiProductDetailedStatistic.vue?vue&type=script&lang=js&"
import style0 from "./OptiProductDetailedStatistic.vue?vue&type=style&index=0&id=2073eb70&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2073eb70",
  null
  
)

export default component.exports