<template lang="pug">
    .row
        .col-xs-12(v-show="!loading.main")
            OptiButton(@click.native="$emit('go-to-page', 'summary')") {{'tasks.pack.suggest.action.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButton.pull-right(@click.native="open = true") {{'tasks.benefits.graph.BTN'|translate}}
                template(#icon)
                    i.fa.fa-share-alt

            MountingPortal(v-if="open" mountTo="#vue-modal" append)
                OptiCatalogGraphDialog(
                    :task-id="task.id"
                    :type="isOffer ? 'offer' : 'task'"
                    :is-compare="isCompare"
                    :mermaid-id="selected ? selected.id : ''"

                    @selected="d => { selected_from_graph(d); select_coloration_from_offer(); get_ranges() }"
                    @close="open = false")

        .col-xs-12(v-if="!loading.main && selected")
            h4.page-header {{'tasks.benefits.selected.CONSTRUCTION_INDEX_COATING'|translate}}

            .text-center
                span
                    a.link(:title="'default.title.LENSES'|translate"
                        :href="$state.href('app.catalog.category', {id: selected.extra.category_id, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                        target="_blank") {{selected.extra.name}}
                    small.text-muted(style="margin-left:10px") {{selected.extra.price|number(0)}}

                OptiPictograms(:pack-details="pictograms")

            .row
                .col-md-4.option-section(v-if="types && Object.keys(types).length > 1")
                    .form-group
                        label.col-xs-12.control-label(for="type_lenses") {{'catalog.lenses.partition.SORT'|translate}}
                        .col-xs-12
                            select#type_lenses.form-control(:class="{'placeholder': type_id === null}" v-model="type_id"
                                @change="get_ranges")
                                option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                option(v-for="(type, type_name) in types" :value="type_name") {{type_name}}

                .col-md-4.option-section(v-if="progressions.length")
                    .form-group
                        label.col-xs-12.control-label(for="progression") {{'catalog.lenses.properties.heading.progression_length'|translate}}
                        .col-xs-12
                            select#progression.form-control(:class="{'placeholder': progression_id === null}"
                                v-model="progression_id")
                                option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                option(v-for="progression in progressions" :value="progression.id"
                                    :selected="progression.id === progression_id") {{progression.name_long}}

                .col-md-4.option-section(v-if="Object.keys(colorations).length")
                    .form-group
                        label.col-xs-12.control-label(for="coloration")
                            span {{'catalog.lenses.properties.heading.coloration'|translate}}
                            small.text-muted(style="font-weight:400;margin-left:10px" v-show="coloration_price"
                                ) {{coloration_price|number(0)}}
                        .col-xs-12
                            select#coloration.form-control(:class="{'placeholder': coloration_id === null}"
                                :disabled="false" v-model="coloration_id" @change="update_coloration")
                                option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                option(v-if="Object.keys(colorations)[0] === 'coloration' && colorations.coloration.length"
                                    v-for="option in colorations.coloration" :value="option.id") {{option.name_long}}
                                optgroup(v-for="(sub_options, sub_section_name) in colorations"
                                    v-if="'coloration' !== sub_section_name && sub_options.length"
                                    :key="sub_section_name" :label="'catalog.lenses.properties.heading.' + sub_section_name|translate")
                                    option(v-for="sub_option in sub_options" :value="sub_option.id"
                                        :disabled="disabled_color(sub_section_name, sub_option)") {{sub_option.name_long}}

                    .form-group(v-if="coloration_options.length")
                        label.col-xs-12.control-label(for="saturation") {{'catalog.lenses.properties.heading.saturation'|translate}}
                        .col-xs-12
                            select#saturation.form-control(:class="{'placeholder': coloration_id < 0}"
                                :disabled="coloration_options.length === 2" v-model="coloration_id"
                                @change="update_coloration_option")
                                option(v-for="option in coloration_options" :class="{'hide': option.id < 0}"
                                    :key="option.id" :value="option.id") {{option.name}}

                    .form-group(v-if="coloration_id >= 12 && coloration_id <= 30 && gradient_types.length")
                        label.col-xs-12.control-label(for="gradient_type") {{'catalog.lenses.properties.heading.gradient_type'|translate}}
                        .col-xs-12
                            select#gradient_type.form-control(:class="{'placeholder': gradient_type_id === null}"
                                v-model="gradient_type_id")
                                option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                option(v-for="gradient_type in  gradient_types" :disabled="disabled_saturation(gradient_type)"
                                    :value="gradient_type.id") {{gradient_type.name_long}}

            .row
                .col-sm-8(style="margin-top:20px")
                    .alert.alert-danger(v-show="alerts.length")
                        div
                            i.fa.fa-exclamation-circle.mr3
                            span {{'tasks.lenses.notify.warnings.HEADING'|translate}}

                        ul
                            li(v-for="alert in alerts" v-html="alert")

                    .alert.alert-info(v-if="offerHoyaOption && offerHoyaOption.id !== coloration_id")
                        div
                            i.fa.fa-question-circle.mr3
                            span {{'tasks.offer.lenses.warning.HEADER'|translate}}

                        ul
                            li
                                span.mr3 {{'tasks.offer.lenses.warning.COLORATION'|translate}}
                                i.mr3 {{'catalog.lenses.properties.heading.' + offerHoyaOption.section|translate}}
                                b {{offerHoyaOption.name}}
                                span .

                    OptiTaskDetailsLensesRanges(v-if="!loading.range"
                        :ranges="ranges")

                    div(style="position:relative;margin-top:25px" v-else)
                        OptiDimmer(:active="true")

                .col-sm-4.text-right(style="margin-top:20px")
                    .page-header(style="margin: 0 0 21px")
                        span.mr3 {{'tasks.lenses.SUM'|translate}}:
                        b {{selected.extra.price + coloration_price|number(0)}}

                    OptiButtonSave(:disabled="!!alerts.length || loading.range || !!ranges.errors.length" @click.native="save")

        .col-xs-12.text-center(style="margin:10px 0" v-if="!loading.main && !selected")
            div(v-if="exists")
                a.link(:title="'default.title.LENSES'|translate"
                    :href="$state.href('app.catalog.category', {id: task['hoya_lens_category_id' + (isCompare ? '_2' : '')], isOffer: isOffer ? 1 : 0, taskId: task.id})"
                    target="_blank") {{task['rightLens' + (isCompare ? '2' : '')].partName}}
                OptiPictograms(:pack-details="task['pictograms' + (this.isCompare ? '2' : '')]")

                .text-muted.fa-lg(style="margin:10px 0")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.benefits.graph.notify.NO_FROM_GRAPH'|translate}}

            .text-muted.fa-lg(v-else)
                i.fa.fa-exclamation-circle.mr3
                span {{'tasks.benefits.graph.notify.NO_EXISTS'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {TaskHoyaHelper} from '@/js/app/vue/helpers/TaskHoyaHelper'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogGraphDialog from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraphDialog'
    import OptiTaskDetailsLensesRanges
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesRanges'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'

    export default {
        name: 'OptiCatalogPageByBenefits',
        components: {
            OptiButton,
            OptiCatalogGraphDialog,
            OptiTaskDetailsLensesRanges,
            OptiDimmer,
            OptiButtonSave,
            OptiPictograms
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },
            offerHoyaOption: {
                required: true
            }
        },
        data() {
            return {
                hoya_data: null,

                selected: null,

                types: [],
                type_id: null,
                options: [],

                progressions: [],
                progression_id: null,
                colorations: [],
                coloration_id: 0,
                coloration_options: [],
                gradient_types: [],
                gradient_type_id: null,

                coloration_validate: {
                    required: false,
                    without_cf_and_cg: false,
                    only_contrast_and_cf: false,
                    only_cf_and_cg: false
                },

                ranges: {
                    errors: [],
                    warnings: []
                },

                loading: {
                    main: true,
                    range: false
                },
                open: false
            }
        },

        mounted() {
            API.get('task/hoya/graph/' + (this.isOffer ? 'offer' : 'task') + '/' + (this.isCompare ? '1' : '0') + '/' +
                this.task.id).then(res => {
                this.hoya_data = res.data.hoya_data

                if(res.data.selected) {
                    this.selected_from_graph(res.data.selected)
                    this.select_exists_options()
                }

                this.get_ranges()
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('tasks.pack.catalog.notify.error.LOAD')
            })
        },

        methods: {
            selected_from_graph(d) {
                this.selected = d

                this.hoya_data.SunLoox.SunLoox.forEach(design => {
                    if(this.selected.extra.components.hoya_design_id === design.id) {
                        this.$set(this, 'types', TaskHoyaHelper.sort(design.data.RX.clear[this.selected.extra.components.index].types))
                        this.$set(this, 'type_id', Object.keys(this.types).length === 1 ? Object.keys(this.types)[0] : null)

                        this.$set(this, 'options', design.data.RX.clear[this.selected.extra.components.index].options)

                        this.$set(this, 'progressions', [])
                        this.$set(this, 'progression_id', null)
                        this.$set(this, 'colorations', [])
                        this.$set(this, 'coloration_id', 0)
                        this.$set(this, 'coloration_options', [])
                        this.$set(this, 'gradient_types', [])
                        this.$set(this, 'gradient_type_id', null)

                        this.$set(this, 'coloration_validate', {
                            required: false,
                            without_cf_and_cg: false,
                            only_contrast_and_cf: false,
                            only_cf_and_cg: false
                        })

                        this.options.forEach(option => {
                            _.forEach(option, (o, option_name) => {
                                switch(option_name) {
                                    case 'coatings':
                                        o.coatings.forEach(coating => {
                                            if(coating.id === this.selected.extra.components.coating_id) {
                                                this.$set(this, 'coloration_validate', coating.coloration)
                                            }
                                        })

                                        break

                                    case 'progression_length':
                                        this.$set(this, 'progressions', o)

                                        break

                                    case 'coloration':
                                        this.$set(this, 'colorations', o)

                                        break

                                    case 'gradient_type':
                                        this.$set(this, 'gradient_types', o)
                                }
                            })
                        })
                    }
                })
            },
            select_exists_options() {
                _.forEach(this.types, (type, type_name) => {
                    if(type.id === this.task['hoya_lens_id' + (this.isCompare ? '_2' : '')]) {
                        this.$set(this, 'type_id', type_name)
                    }
                })

                let progression_id = null,
                    coloration_id_minus = null,
                    coloration_id = null,
                    gradient_type_id = null

                this.task['hoyaOptions' + (this.isCompare ? '2' : '')].forEach(option => {
                    if(option.class === 'ProgressionLength') {
                        progression_id = option.id
                    } else if(option.class === 'GradientType') {
                        gradient_type_id = option.id
                    } else {
                        let coloration = TaskHoyaHelper.findColor(this.colorations, option.id)

                        if(coloration.coloration_id_minus) {
                            coloration_id_minus = coloration.coloration_id_minus
                        }

                        if(coloration.coloration_id) {
                            coloration_id = coloration.coloration_id
                        }
                    }
                })

                if(progression_id) {
                    this.$set(this, 'progression_id', progression_id)
                }

                if(coloration_id_minus) {
                    this.$set(this, 'coloration_id', coloration_id_minus)
                    this.update_coloration()

                    this.$set(this, 'coloration_id', coloration_id)
                    this.update_coloration_option()
                } else if(coloration_id) {
                    this.$set(this, 'coloration_id', coloration_id)
                    this.update_coloration()
                }

                if(gradient_type_id) {
                    this.$set(this, 'gradient_type_id', gradient_type_id)
                }
            },
            select_coloration_from_offer() {
                if(this.offerHoyaOption) {
                    if(this.disabled_color(this.offerHoyaOption.section, TaskHoyaHelper.getOption(this.options, this.offerHoyaOption.id))) {
                        this.$notify.warning('tasks.offer.lenses.notify.warning.NO_AVAILABLE_COLORATION', true, true)
                    } else {
                        let {coloration_id_minus, coloration_id} = TaskHoyaHelper.findColor(this.colorations, this.offerHoyaOption.id)

                        if(coloration_id_minus) {
                            this.$set(this, 'coloration_id', coloration_id_minus)
                            this.update_coloration()

                            this.$set(this, 'coloration_id', coloration_id)
                            this.update_coloration_option()
                        } else if(coloration_id) {
                            this.$set(this, 'coloration_id', coloration_id)
                            this.update_coloration()
                        }

                        if(!this.coloration_id) {
                            this.$notify.warning('tasks.offer.lenses.notify.warning.NO_AVAILABLE_COLORATION', true, true)
                        }
                    }
                }
            },

            update_coloration() {
                this.coloration_options = []

                if(this.coloration_id < 12 || this.coloration_id > 30) {
                    this.$set(this, 'gradient_type_id', null)
                }

                _.forEach(this.colorations, colorations => {
                    colorations.forEach(coloration => {
                        if(coloration.options && this.coloration_id === coloration.id) {
                            this.$set(this, 'coloration_options', coloration.options)

                            if(this.coloration_options.length === 2) {
                                this.$set(this, 'coloration_id', this.coloration_options[1].id)

                                this.update_coloration_option()
                            }
                        }
                    })
                })

                this.color_selected()
            },
            update_coloration_option() {
                _.forEach(this.colorations, colorations => {
                    colorations.forEach(coloration => {
                        if(typeof coloration.options !== 'undefined') {
                            coloration.options.forEach(option => {
                                if(option.id === this.coloration_id) {
                                    coloration.id = this.coloration_id
                                }
                            })
                        }
                    })
                })

                this.color_selected()
            },
            color_selected() {
                if(this.coloration_id > 0 && this.selected.extra.components.coating_id !== 281) {
                    let coating_color = TaskHoyaHelper.getOption(this.options, 281)

                    if(coating_color.id) {
                        let coating_selected = TaskHoyaHelper.getOption(this.options, this.selected.extra.components.coating_id)

                        this.selected.extra.components.coating_id = coating_color.id
                        this.selected.extra.name = this.selected.extra.name.replace(coating_selected.name_long, coating_color.name_long)
                        this.selected.extra.price = this.selected.extra.price - coating_selected.brutto_price + coating_color.brutto_price
                        this.$set(this, 'coloration_validate', coating_color.coloration)

                        this.selected.extra.pictograms = this.selected.extra.pictograms
                            .filter(p => !coating_selected.pictograms.map(csp => csp.id).includes(p.id))
                        this.selected.extra.pictograms = this.selected.extra.pictograms.concat(coating_color.pictograms)
                        this.selected.extra.pictograms = _.sortBy(this.selected.extra.pictograms, ['groupId', 'subgroup', 'order'])

                        this.$notify.warning(this.$filters.translate('tasks.offer.lenses.notify.warning.CHANGE_TO_COLOR_COATING') +
                            ' ' + coating_color.name_long, false, true)
                    }
                }
            },

            save() {
                let full_name = [this.types[this.type_id].name_long, 'RX'],
                    coating = TaskHoyaHelper.getOption(this.options, this.selected.extra.components.coating_id),
                    options = {}

                full_name.push(coating.name_long)
                options[coating.id] = {
                    price: coating.brutto_price
                }

                if(this.coloration_id) {
                    let coloration = TaskHoyaHelper.getOption(this.options, this.coloration_id),
                        name_long = coloration.name_long

                    options[this.coloration_id] = {
                        price: this.coloration_price
                    }

                    if(coloration.options) {
                        coloration.options.forEach(opt => {
                            if(opt.id === this.coloration_id) {
                                name_long += ' ' + opt.name
                            }
                        })
                    }

                    full_name.push(name_long)
                }

                if(this.gradient_type_id) {
                    full_name.push(TaskHoyaHelper.getOption(this.options, this.gradient_type_id).name_long)
                    options[this.gradient_type_id] = {
                        price: 0
                    }
                }

                if(this.progression_id) {
                    full_name.push(TaskHoyaHelper.getOption(this.options, this.progression_id).name_long)
                    options[this.progression_id] = {
                        price: 0
                    }
                }

                ['right', 'left'].forEach(side => {
                    let field = side + 'Lens' + (this.isCompare ? '2' : '')

                    this.task[field].productId = 0
                    this.task[field].partName = full_name.join('|')
                    this.task[field].manufacturer = 'Hoya'
                    this.task[field].type = this.types[this.type_id].type
                    this.task[field].price = (this.selected.extra.price + this.coloration_price) / 2
                })

                this.task['hoya_lens_id' + (this.isCompare ? '_2' : '')] = this.types[this.type_id].id
                this.task['hoya_lens_category_id' + (this.isCompare ? '_2' : '')] = this.types[this.type_id].category_id
                this.task['hoyaOptions' + (this.isCompare ? '2' : '')] = options

                this.task.was_graph = true

                TaskHoyaHelper.setTaskExtras(this.isCompare, this.ranges, this.task)

                this.$emit('lenses-was-updated')
                this.$emit('go-to-page', 'summary')
            },

            get_ranges() {
                if(this.type_id) {
                    this.loading.range = true

                    API.post('task/hoya/catalog/validate', {
                        task_id: this.task.id,
                        is_offer: this.isOffer,
                        is_compare: this.isCompare,
                        category_id: this.types[this.type_id].category_id,
                        hoya_lens_id: this.types[this.type_id].id
                    },  API.id(Math.random())).then(res => {
                        this.ranges = res.data
                    }).finally(() => {
                        this.loading.range = false
                    }).catch(() => {
                        this.$notify.error('tasks.lenses.notify.error.LOAD_RANGES')
                    })
                } else {
                    this.ranges = {
                        errors: [],
                        warnings: []
                    }
                }
            },

            disabled_color(sub_section_name, option) {
                return TaskHoyaHelper.disabledColor(this.coloration_validate, sub_section_name, option)
            },
            disabled_saturation(opt) {
                return TaskHoyaHelper.disabledSaturation(this.coloration_validate, opt)
            }
        },
        computed: {
            pictograms() {
                return this.selected.extra.pictograms.filter(pictogram => {
                    return this.coloration_id || (!this.coloration_id && pictogram.id !== 17)
                })
            },
            coloration_price() {
                return TaskHoyaHelper.getOption(this.options, this.coloration_id).brutto_price
            },
            exists() {
                return this.task['hoya_lens_id' + (this.isCompare ? '_2' : '')] &&
                    this.task['hoya_lens_category_id' + (this.isCompare ? '_2' : '')]
            },
            alerts() {
                let _return = []

                if(!this.type_id) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOTSELECTED') + ' <b>' +
                        this.$filters.translate('catalog.lenses.partition.SORT') + '</b>.')
                }

                if(this.progressions.length && !this.progression_id) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOTSELECTED') + ' <b>' +
                        this.$filters.translate('catalog.lenses.properties.heading.progression_length') + '</b>.')
                }

                if(this.coloration_validate.required && !this.coloration_id) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.COLOR_REQUIRED'))
                }

                if(this.coloration_id < 0) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOTSELECTED') + ' <b>' +
                        this.$filters.translate('catalog.lenses.properties.heading.coloration') + '</b>.')
                }

                if(this.coloration_id >= 12 && this.coloration_id <= 30 && !this.gradient_type_id) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOTSELECTED') + ' <b>' +
                        this.$filters.translate('catalog.lenses.properties.heading.gradient_type') + '</b>.')
                }

                return _return
            }
        }
    }
</script>

<style scoped lang="less">
    h4.page-header {
        margin: 30px 0 10px;
    }

    .option-section label {
        margin-top: 10px;
    }
</style>