export default class ApiManager {
    constructor() {
        this.requests = {}
    }

    addRequest(requestId, cancelFunction) {
        if (this.has(requestId)) {
            this.cancelRequest(requestId)
        }

        this.requests[requestId] = cancelFunction
    }

    cancelRequest(requestId, reason = '') {
        if (this.has(requestId) && typeof this.requests[requestId] === 'function') {
            this.requests[requestId](reason)
            this.removeRequest(requestId)
        }
    }

    removeRequest(requestId) {
        delete this.requests[requestId]
    }

    has(requestId) {
        return !!this.requests[requestId]
    }

    cancelAllRequest(reason) {
        for (let requestId in this.requests) {
            this.cancelRequest(requestId, reason)
        }
    }

    static generateHashFromConfig(config) {
        // jeśli mamy podany requestId w configu to go używamy
        if (config.headers['X-Request-Id']) {
            return config.headers['X-Request-Id']
        }

        let data = config.data
        if (!data) {
            data = ''
        } else if (typeof data === 'object') {
            data = JSON.stringify(data)
        } else {
            data = JSON.stringify(JSON.parse(data))
        }

        let url = config.url.replace(config.baseURL, '')

        // jeśli nie to generujemy na podstawie metody/url/danych
        return config.method + '@' + url + '[' + data + ']'
    }
}
