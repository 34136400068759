<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            OptiPreviousLenses(mode="patient" :data="previousLenses" :contact-lenses="previousLensesContact"
                :patient-id="patient.id" :department-id="patient.departmentId" :measurement-app="patient.department.measurement_app"
                :checked="checked" :checked-contact="checkedContact" :glasses="patient.glasses"
                @update-previous-lenses="++count_saved" @update-previous-lenses-contact="++count_saved"
                @update-glasses-checkbox="++count_saved")

        .col-xs-12.text-center(v-if="!loading")
            OptiButtonSave(:disabled="block" @click.native="goSavePreviousLenses")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiPreviousLenses from '@/js/app/vue/components/PreviousLenses/OptiPreviousLenses'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsPreviousLenses',
        components: {
            OptiPreviousLenses,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            patient: {
                type: Object,
                required: true
            },
            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                previousLenses: null,
                previousLensesContact: null,

                checked: [],
                checkedContact: [],

                previous_lenses_updated_at: null,

                count_saved: 0,
                loading: true
            }
        },
        mounted() {
            API.get('patient/previouslenses/all/fromPatientId/' + this.patient.id).then(res => {
                this.previous_lenses_updated_at = res.data.previous_lenses_updated_at
                this.previousLenses = res.data.previouslenses
                this.previousLensesContact = res.data.contactlenses

                this.previousLenses.forEach((previousLens, key) => {
                    this.checked[key] = this.patient.previous_glasses.includes(previousLens.id.toString())
                })

                this.previousLensesContact.forEach((contactLens, key) => {
                    this.checkedContact[key] = this.patient.previous_contacts.includes(contactLens.id.toString())
                })

                this.$emit('update-count', 'previousLenses', this.previousLenses.length + this.previousLensesContact.length)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('previouslens.notify.error.LOAD')
            })
        },
        beforeDestroy() {
            if(this.count_saved > 1) {
                this.goSavePreviousLenses()

                this.$notify.warning('previouslens.notify.error.FORGOTSAVE')
            }
        },
        methods: {
            goSavePreviousLenses() {
                this.loading = true

                API.post('patient/previouslenses/create', {
                    previousLenses: this.previousLenses,
                    contactLenses: this.previousLensesContact,
                    patient_id: this.patient.id,
                    previous_lenses_updated_at: this.previous_lenses_updated_at,
                    checked: this.checked,
                    checkedContacts: this.checkedContact,
                    glasses: this.patient.glasses
                }).then(res => {
                    this.previous_lenses_updated_at = res.data.previous_lenses_updated_at
                    this.previousLenses = res.data.previousLenses
                    this.previousLensesContact = res.data.previousContacts

                    this.$emit('reload-tasks')
                    this.$emit('update-count', 'previousLenses', this.previousLenses.length + this.previousLensesContact.length)

                    this.count_saved = 0
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.response.status === 460) {
                        this.$notify.warning(error.response.data.message, false)

                        this.$emit('reload-all')
                    } else {
                        this.$notify.error('previouslens.notify.error.SAVE')
                    }
                })
            }
        }
    }
</script>
