<template lang="pug">
  .row(v-if="$uac.permission('products.statistic_chart')" style="margin-left:0;margin-right:0")
    .col-xs-12
      .col-xs-12.col-sm-4.col-md-2
        .panel.panel-default
          .panel-body.text-center.hover-me(style="cursor:pointer" @click="$emit('scroll', 'task_sale')")
            h2.mt0 {{sum_task_sale}}
            .mb0.text-muted {{'catalog.product.report.SUMTASKSALE'|translate}}

      .col-xs-12.col-sm-4.col-md-2(style="align-items:stretch")
        .panel.panel-default
          .panel-body.text-center.hover-me(style="cursor:pointer" @click="$emit('scroll', 'reclamation')")
            h2.mt0 {{sum_reclamations}}
            .mb0.text-muted {{'catalog.product.properties.RECLAMATIONS'|translate}}

      .col-xs-12.col-sm-4.col-md-2
        .panel.panel-default
          .panel-body.text-center(style="align-items: stretch")
            h2.mt0 {{selection.selection }}
            .mb0.text-muted {{'selection.table.SELECTION'|translate}}
            OptiButton.text-center(
              style="cursor:pointer"
              v-if="$uac.permission('products.selection_status_change')"
              type="info"
              :size="'xs'"
              @click.native="optiNum = !optiNum")
              span {{'selection.button.CHANGE'|translate}}

            OptiDialogNumber(
              :header="'Wprowadź numer selekcji'"
              :minus="false"
              :float="false"
              :required="false"
              :pending="false"
              :is-number="true"
              :value="selection.selection"
              v-if="optiNum"
              @closed ="optiNum = false"
              @confirmed = "changeSelection")

      .col-xs-12.col-sm-4.col-md-2
        .panel.panel-default
          .panel-body.text-center
            h2.mt0 {{selection.old_status }}
            .mb0.text-muted {{'selection.table.OLD_STATUS'|translate}}

            OptiButtonDropdown.text-center(
              style="padding:0; margin:0; cursor:pointer"
              v-if="$uac.permission('products.selection_status_change') && !['MH', 'PI1', 'PI'].includes(selection.old_status)"
              type="info"
              :simple="true"
              :size="'xs'")
              span {{'selection.button.CHANGE'|translate}}
              template(#menu)
                li
                  a(@click="changeRate('MH')")
                    span MH
                    span.text-sm.text-muted - Must Have
                li
                  a(@click="changeRate('PI1')")
                    span PI1
                    span.text-sm.text-muted - Phase in One
                li
                  a(@click="changeRate('PI')")
                    span PI
                    span.text-sm.text-muted - Phase in

      .col-xs-12.col-sm-4.col-md-2
        .panel.panel-default
          .panel-body.text-center
            h2.mt0.mr3 {{selection.new_status}}
              span.h4.text-muted(v-if="$uac.permission('products.selection_status_change') && product.outOfProduction")  (XX)
            .mb0.text-muted {{'selection.table.STATUS'|translate}}

            OptiButtonDropdown.text-center(
              style="padding:0; margin:0; cursor:pointer"
              v-if="($uac.permission('products.selection_status_change') && !product.new_product && !product.temp_product && !product.outOfProduction) || $uac.user.id === 616 || $uac.user.id === 861"
              type="info"
              :simple="true"
              :size="'xs'")
              span(v-show="selection.new_status !== '--'") {{'selection.button.CHANGE'|translate}}
              span(v-show="selection.new_status === '--'") {{'selection.button.NEW_STATUS'|translate}}

              template(#menu)
                template(v-if="selection.new_status !== 'PO' || $uac.user.id === 616 || $uac.user.id === 861")
                  li
                    a(@click="changeStatus('MH')")
                      span MH
                      span.text-sm.text-muted - {{'selection.status.MH'|translate}}
                  li
                    a(@click="changeStatus('PI1')")
                      span PI1
                      span.text-sm.text-muted - {{'selection.status.PI1'|translate}}
                  li
                    a(@click="changeStatus('PI')")
                      span PI
                      span.text-sm.text-muted - {{'selection.status.PI'|translate}}
                  li
                    a(@click="changeStatus('AC')")
                      span AC
                      span.text-sm.text-muted - {{'selection.status.AC'|translate}}
                  li
                    a(@click="changeStatus('PO')")
                      span PO
                      span.text-sm.text-muted - {{'selection.status.PO'|translate}}
                template
                  li(style="border-top: 1px solid" v-if="!product.outOfProduction")
                    a(@click="changeStatus('XX')")
                      span XX
                      span.text-sm.text-muted - Zakończono produkcję


      .col-xs-12.col-sm-4.col-md-2
        OptiProductDetailedStatistic(
          :product-id="product.id"
          :category-id="product.customerProduct.categoryId"

          @scroll="$emit('scroll', 'chart')")
</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
import OptiButtonDropdown from '@/js/app/vue/components/Button/OptiButtonDropdown'
import OptiProductDetailedStatistic
  from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetailedStatistic'
import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiDialogNumber from '@/js/app/vue/components/Inputs/Dialogs/OptiDialogNumber'

export default {
  name: 'OptiProductStatistic',
  components: {
    OptiDialogNumber,
    OptiButton,
    OptiProductDetailedStatistic,
    OptiButtonDropdown,
    OptiDimmer
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    sum_reclamations: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      optiNum: false,
      active: true,
      sum_task_sale: 0,
      selection: {
        selection: '',
        new_status: '',
        old_status: ''
      }
    }
  },
  mounted() {
    this.startUp()
  },
  methods: {
    startUp() {
      if (this.product) {
        API.get('warehouse/product/getStatistics/' + this.product.id).then(res => {
          this.sum_task_sale = res.data.task_sale
          this.selection = res.data.selection
        }).then(() => {
          this.$emit('selection_id', this.selection.id)
        }).catch(() => {
          this.$notify.error('catalog.notify.error.STATS')
        }).finally(() => {
          this.active = false
        })
      }
    },
    changeRate(rate) {
      this.selection.old_status = rate

      API.post('selection/changeProductStatus', {
        product_id: this.product.id,
        sku_status: rate
      }).then(() => {
        this.$notify.success('catalog.notify.RATE')
      }).catch(() => {
        this.$notify.error('catalog.notify.error.RATE')
      })
    },
    changeSelection(selection) {
      this.selection.selection = selection

      API.post('selection/changeProductStatus', {
        product_id: this.product.id,
        selection: selection
      }).then(() => {
        this.$notify.success('catalog.notify.SELECTION')
      }).then(() => {
        this.optiNum = false
      }).catch(() => {
        this.$notify.error('catalog.notify.error.SELECTION')
      })
    },
    changeStatus(status) {
      if (status !== 'XX') {
        this.selection.new_status = status
      } else {
        this.$emit('out_of_production')
      }

      API.post('selection/changeProductStatus', {
        product_id: this.product.id,
        new_status: status
      }).then(() => {
        this.$notify.success('catalog.notify.STATUS')
      }).catch(() => {
        this.$notify.error('catalog.notify.error.STATUS')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.col-md-2 {
  padding: 3px
}

.hover-me:hover {
  background-color: rgba(189, 184, 173, 0.5)
}
</style>