<template lang="pug">
    div
        .grid-brand
            .content
                .bg-separator
                    span(style="margin-left:3px") {{brand.catalog.name}}
                    .pull-right.mr3
                        span.mr3 {{brand.products.length}}
                        span(v-show="!supply") / {{brand.size}}

                .content-body(style="min-height:auto" v-show="!supply")
                    img.img-responsive(style="height:50px" :src="'img/categories/' + brand.category_id + '.jpg'")
                    div(style="color:yellow;position:absolute;top:4px;left:4px" v-show="brand.catalog.parentId === 463")
                        i.fa.fa-sun-o.fa-2x
                    a(style="position:absolute;top:12px;right:10px"
                        :href="$state.href('app.catalog.category', {id: brand.category_id, departmentId: $state.params.departmentId})" target="_blank")
                        i.fa.fa-2x.fa-external-link(style="color:#fff")

        draggable.products(:list="brand.products_parsed" group="products" v-bind="dragOptions" draggable=".is_draggable"
            :data-brand-id="brand.id" :data-supply="supply ? 'true' : 'false'"
            @start="$emit('set-dragging', true)" @end="update")
            .grid-brand-product(v-for="(product, index) in brand.products_parsed.slice(0, all ? brand.products_parsed.length : less)" :key="index"
                :class="{'is_draggable': product.id}" :data-item-id="product.id")
                OptiLayoutProductsGridProduct(
                    :model="product"
                    :category-id="brand.category_id"
                    :supply="supply")
                .idx.text-muted {{index + 1}}.

        .text-center.bold(style="cursor:pointer" v-show="supply" :class="{'toggle-disabled': toggleDisabled}"
            @click="all = toggleDisabled ? all : !all")
            span(v-show="all") {{'layout.products.toggle.ONLY'|translate}}
            span(v-show="!all") {{'layout.products.toggle.MORE'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import draggable from 'vuedraggable'

    import OptiLayoutProductsGridProduct from '@/js/app/vue/components/Layout/Products/OptiLayoutProductsGridProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiLayoutProductsGridBrand',
        components: {
            draggable,
            OptiLayoutProductsGridProduct,
            OptiDimmer
        },
        props: {
            brand: {
                type: Object,
                required: true
            },
            supply: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                products: [],

                dragOptions: {
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                    scrollSensitivity: 200,
                    forceFallback: true
                },

                less: 1,
                all: !this.supply
            }
        },
        methods: {
            update(e) {
                this.$emit('set-dragging', false)
                this.$emit('set-loading', true)

                let item_id = parseInt(e.clone.getAttribute('data-item-id')),
                    source = {
                        brand: parseInt(e.from.getAttribute('data-brand-id')),
                        supply: e.from.getAttribute('data-supply') === 'true',
                        index: e.oldIndex
                    }, target = {
                        brand: parseInt(e.to.getAttribute('data-brand-id')),
                        supply: e.to.getAttribute('data-supply') === 'true',
                        index: e.newIndex
                    }

                if(source.brand === target.brand && source.index < target.index) {
                    ++target.index
                }

                let current = {
                    id: item_id,
                    position: target.index,
                    brand: target.brand,
                    sourceSupply: source.supply,
                    targetSupply: target.supply
                }

                API.patch('layout/room/products/' + this.$state.params.brandId, current).then(res => {
                    this.$emit('set-products', res.data)
                }).finally(() => {
                    this.$emit('set-loading', false)
                }).catch(() => {
                    this.$notify.error('layout.products.notify.error.MOVE')
                })
            }
        },
        computed: {
            toggleDisabled() {
                return this.brand.products_parsed.length <= this.less
            }
        }
    }
</script>

<style lang="less" scoped>
    .grid-brand-product {
        cursor: not-allowed;

        &.is_draggable {
            cursor: move;
        }

        &:active {
            /deep/ .product-tooltip,
            /deep/ .product-link:not(:hover) {
                display: none !important;
            }
        }

        &.ghost {
            background: #ff9900ff;
            color: #000;
        }
    }

    .toggle-disabled {
        color: #909fa7;
        opacity: .3;
        cursor: default !important;
    }
</style>