<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            form.panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'prices.import.HEADING'|translate}}

                .panel-body
                    .row
                        label.col-xs-12(for="excel") {{'prices.import.LABEL'|translate}}
                        .col-xs-12
                            OptiUpload#excel(
                                prefix="price_changes"

                                :file="file"

                                @update-loading="l => loading.xls = l"
                                @update-file="f => file = f")

                        .col-xs-12.tada.animated.text-muted(style="margin-top:10px")
                            i.fa.fa-exclamation-circle.mr3
                            span(v-html="$filters.translate('prices.import.INFO_FORMULA')")
                .panel-footer
                    OptiButton(@click.native="$state.go('app.prices.edit', {id: $state.params.id})") {{'prices.pricetochange.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left
                    OptiButton.pull-right(type="success" :disabled="!file || loading.main || loading.xls"
                        text="prices.import.confirm" @confirmed="goImport") {{'prices.import.HEADING'|translate}}
                        template(#icon)
                            i.fa.fa-file-excel-o

                OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductPriceChangeImport',
        components: {
            OptiUpload,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                file: null,

                loading: {
                    main: false,
                    xls: false
                }
            }
        },
        methods: {
            goImport() {
                this.loading.main = true

                API.post('prices/edit/' + this.$state.params.id + '/import', {
                    'import_file': this.file
                }).then(() => {
                    this.$notify.success('prices.import.notify.IMPORTED')

                    this.$state.go('app.prices.edit', {
                        id: this.$state.params.id
                    })
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('prices.import.notify.error.IMPORTED')
                })
            }
        }
    }
</script>