import { render, staticRenderFns } from "./OptiCatalogOfferPreviewDialog.vue?vue&type=template&id=8b113826&scoped=true&lang=pug&"
import script from "./OptiCatalogOfferPreviewDialog.vue?vue&type=script&lang=js&"
export * from "./OptiCatalogOfferPreviewDialog.vue?vue&type=script&lang=js&"
import style0 from "./OptiCatalogOfferPreviewDialog.vue?vue&type=style&index=0&id=8b113826&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b113826",
  null
  
)

export default component.exports