<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'user.documents.HEADING'|translate}}

            .label.label-danger.pull-right(v-show="documents.length") {{documents.length}}

        .row
            .col-xs-12.document(v-for="(document, index) in documents" :key="document.id" v-if="document.upload")
                .media-box(:class="{'bg-separator': index % 2}")
                    .media-box-body
                        p.m0.text-muted
                            small {{document.user.firstName}} {{document.user.lastName}}
                            small.pull-right {{document.created_at}}
                        div(style="font-size:20px")
                            span {{document.type_name|translate}}
                            .pull-right(v-show="document.ref_id")
                                a.link(v-show="document.type === 9"
                                    :href="$state.href('app.user.training.details', {id: document.ref_id})"
                                    ) {{'user.documents.ref.TRAINING'|translate}} \#{{document.ref_id}}
                                a.link(v-show="document.type === 14"
                                    :href="$state.href('app.user.exchange.details', {id: document.ref_id})"
                                    ) {{'user.documents.ref.EXCHANGE'|translate}} \#{{document.ref_id}}

                        .m0(v-html="$options.filters.nl2br(document.note)")

                        p.m0(style="padding-top:10px")
                            a.link(:href="'user_documents/' + document.upload|upload"
                                :title="'notes.upload.TITLE'|translate" target="_blank")
                                i.fa.fa-paperclip.mr3
                                span {{'user.documents.ATTACHMENT'|translate}}

            .col-xs-12.text-center.text-muted(style="padding:15px" v-if="!documents.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'user.documents.NONE'|translate}}

            .col-xs-12(style="padding:15px 30px" v-if="$uac.permission('user.documents')")
                form.form.form-horizontal
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiSelectEnum#type(enum="UserDocumentType" :value="document.type" @update-enum="updateType")
                            OptiValidate(f="type" :data="$v.document.type")

                    .form-group(v-if="document.type === 9")
                        .col-sm-8.col-sm-offset-4
                            OptiSelectTrainingByUser#training(:user-id="userId" @selected-training="t => document.ref = t")
                            OptiValidate(f="training" :data="$v.document.ref")

                    .form-group(v-if="document.type === 14")
                        .col-sm-8.col-sm-offset-4
                            OptiSelectExchangeByUser#exchange(:user-id="userId" @selected-exchange="e => document.ref = e")
                            OptiValidate(f="exchange" :data="$v.document.ref")

                    .form-group
                        .col-sm-8.col-sm-offset-4
                            textarea#note.form-control(:placeholder="'notes.PLACEHOLDER'|translate" rows="5" v-model="document.note")
                            OptiValidate(f="note" :data="$v.document.note")

                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiUpload#attachment(
                                prefix="user_documents"

                                :file="document.attachment"

                                @update-loading="l => loading.angular_attachment = l"
                                @update-file="f => document.attachment = f")
                            OptiValidate(f="attachment" :data="$v.document.attachment")

        .panel-footer.text-right(v-show="$uac.permission('user.documents')")
            OptiButton(type="success" :disabled="$v.document.$invalid || loading.angular_attachment"
                    @click.native="addDocument") {{'user.documents.BTN'|translate}}
                template(#icon)
                    i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectTrainingByUser from '@/js/app/vue/components/Select/OptiSelectTrainingByUser'
    import OptiSelectExchangeByUser from '@/js/app/vue/components/Select/OptiSelectExchangeByUser'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiUsersEditDocuments',
        components: {
            OptiSelectEnum,
            OptiSelectTrainingByUser,
            OptiSelectExchangeByUser,
            OptiValidate,
            OptiUpload,
            OptiButton
        },
        props: {
            userId: {
                type: Number,
                required: true
            },
            documents: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                document: {
                    user_id: this.userId,
                    type: 0,
                    ref: null,
                    note: '',
                    attachment: null
                },

                loading: {
                    document: false,
                    angular_attachment: false
                }
            }
        },
        validations: {
            document: {
                type: {
                    noZero
                },
                ref: {
                    required: requiredIf(model => [9, 14].includes(model.type))
                },
                note: {
                    required
                },
                attachment: {
                    required
                }
            }
        },
        mounted() {

        },
        methods: {
            updateType(type) {
                this.document.type = type

                this.document.ref = null
            },

            addDocument() {
                this.loading.document = true

                API.post('user/documents/add', this.document).then(res => {
                    this.$emit('update-documents', res.data)

                    this.document.type = 0
                    this.document.ref = null
                    this.document.note = ''
                    this.document.attachment = null

                    this.$notify.success('user.documents.notify.SAVE')
                }).finally(() => {
                    this.loading.document = false
                }).catch(() => {
                    this.$notify.error('user.documents.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .document {
        &:not(:last-child) > .media-box {
            border-bottom: 1px solid #000;
        }

        > .media-box {
            padding: 10px;
        }
    }
</style>