import {API} from '@/js/app/vue/api'

import Notify from '@/js/app/vue/helpers/Notify'

class HoyaHelperClass {
    openHoyaPage(order_id) {
        let link = 'https://pl.hoyailog.com/order/{id}#orderSummary'

        if(order_id.toString()[0] === '7') {
            API.get('hoya/link-to-iLog/' + order_id).then(res => {
                window.open(link.replace('{id}', res.data))
            }).catch(() => {
                Notify.error('default.hoya.link.notify.error.LOAD7')
            })
        } else if(order_id.toString()[0] === '0' || _.range(10, 15 + 1).includes(parseInt(order_id.toString().slice(0, 2)))) {
            Notify.error('default.hoya.link.notify.error.LOAD0')
        } else {
            window.open(link.replace('{id}', order_id))
        }
    }
}

export const HoyaHelper = new HoyaHelperClass()