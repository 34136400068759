<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.warehouse.manually-changes.table.WHCHANGES'|translate}}

                .panel-body
                    form.form.form-horizontal
                        .form-group
                            label.control-label.col-sm-4.star-required(for="date_from") {{'report.warehouse.manually-changes.table.DATEFROM'|translate}}
                            .col-sm-8
                                OptiDate#date_from(:value="form.start_date" @update-date="d => form.start_date = d")
                                OptiValidate(f="date_from" :data="$v.form.start_date")
                        .form-group
                            label.control-label.col-sm-4.star-required(for="date_to") {{'report.warehouse.manually-changes.table.DATETO'|translate}}
                            .col-sm-8
                                OptiDate#date_to(:value="form.end_date" @update-date="d => form.end_date = d")
                                OptiValidate(f="date_to" :data="$v.form.end_date")

                .panel-footer
                    OptiButton(@click.native="$state.go('app.dashboard')") {{'report.warehouse.manually-changes.table.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left

                    OptiButton.pull-right(type="success" :disabled="$v.form.$invalid" @click.native="download") {{'report.warehouse.manually-changes.table.DOWNLOAD'|translate}}
                        template(#icon)
                            i.fa.fa-download

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportWarehouseManuallyChanges',
        components: {
            OptiDate,
            OptiButton,
            OptiValidate,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    start_date: '',
                    end_date: ''
                },

                loading: false
            }
        },
        validations: {
            form: {
                start_date: {
                    required
                },
                end_date: {
                    required
                }
            }
        },
        methods: {
            download() {
                this.loading = true

                let filename = 'zmiany_magazyniera_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'warehouse/manually_changes/' + filename,
                        status: true
                    }
                })

                API.post('reports/warehouse/manually-changes/xls', {
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    filename: filename
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.warehouse.manually-changes.notify.error.XLS')
                })
            }
        }
    }
</script>