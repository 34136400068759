export default {
    notInCentralStock(items) {
        let _return = false

        items.forEach(item => {
            if(item.quantity > item.product.quantity) {
                _return = true
            }
        })

        return _return
    },
    hasProblems(problems, is_admin) {
        if(is_admin) {
            return false
        }

        return typeof problems !== 'undefined' ? problems.length : 0
    },
    disabled(state, is_admin) {
        return state.pending || !state.carts.order.items.length || this.notInCentralStock(state.carts.order.items) ||
            this.hasProblems(state.carts.order.problems, is_admin) || !state.carts.order.totals.value
    },

    setTotal(order) {
        let totals = {
            quantity: 0,
            value: 0
        }

        order.items.forEach(item => {
            totals.quantity += parseFloat(item.quantity)
            totals.value += item.product.price * item.quantity
        })

        if(totals.quantity > 0 && !totals.value) {
            totals.value = 0.01
        }

        return totals
    }
}
