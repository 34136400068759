import moment from 'moment'


/**
 * Pad given number to given length
 *
 * @param number
 * @param length How many characters it should have? Default: 2
 * @param type How to pad? left/right/both, Default: 'left'
 * @param char With what character it should pad? Default: '0'
 * @returns {string}
 */
export function numberPad(number, length, type, char) {
    type = type || 'left'
    char = char || '0'
    length = length || 2
    number = number.toString()

    while (number.length < length) {
        switch (type) {
            case 'left':
                number = char + number
                break
            case 'right':
                number = number + char
                break
            case 'both':
                number = char + number
                if (number.length === length) return number
                number = number + char
                break
        }
    }

    return number
}

/**
 * Returns string with easter date in given year (or current if false)
 *
 * @param year
 * @returns {string}
 */
export function easterDateRaw(year) {
    year = year || moment().format('GGGG')

    let C = Math.floor(year / 100);
    let N = year - 19 * Math.floor(year / 19);
    let K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor((I / 30));
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    let L = I - J;
    let M = 3 + Math.floor((L + 40) / 44);
    let D = L + 28 - 31 * Math.floor(M / 4);

    return year + '-' + numberPad(M) + '-' + numberPad(D);
}

/**
 * Returns moment instance of given year easter date (or current year)
 *
 * @param year
 * @returns {moment.Moment}
 */
export function easterDate(year) {
    year = year || moment().format('GGGG')

    return moment(easterDateRaw(year), 'YYYY-MM-DD').startOf('day')
}


export function randomString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
