<template lang="pug">
    div
        table.optometrist-table
            tbody
                tr
                    th.scale
                        label.btn.btn-default(for="visus1" :class="{'btn-warning active': mode === '0.05'}") 0,05
                        input#visus1.hide(type="radio" v-model="mode" name="visus" value="0.05" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="visus2" :class="{'btn-warning active': mode === '0.3'}") 0,1 - 0,3
                        input#visus2.hide(type="radio" v-model="mode" name="visus" value="0.3" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="visus3" :class="{'btn-warning active': mode === '0.6'}") 0,4 - 0,6
                        input#visus3.hide(type="radio" v-model="mode" name="visus" value="0.6" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="visus4" :class="{'btn-warning active': mode === '0.9'}") 0,7 - 0,9
                        input#visus4.hide(type="radio" v-model="mode" name="visus" value="0.9" @change="sendDisplay()")
                    th.scale
                        label.btn.btn-default(for="visus5" :class="{'btn-warning active': mode === '1.5'}") 1,0 - 1,5
                        input#visus5.hide(type="radio" v-model="mode" name="visus" value="1.5" @change="sendDisplay()")

        table.optometrist-table(style="z-index:30" :class="{'top-border': list[mode].length === 1}")
            tbody
                tr(v-if="list[mode].length > 1")
                    td &nbsp;
                    td(v-for="(o, index) in permutation[type][list[mode][0].replace(',', '.')]"
                        :class="{'not-active': selected.column !== false && selected.column !== index}")
                        a.btn.btn-default(style="height:auto;font-size:18px;border-left:0" @click="selectColumn(index)")
                            i.fa.fa-angle-double-down
                    td &nbsp;
                tr(v-if="list[mode].length > 1" v-for="(scale, index) in list[mode]" :class="{'selected-visus': !readOnly && dirty && scale === visus}")
                    td(width="1px" :class="{'not-active': selected.row !== false && selected.row !== index}")
                        a.btn.btn-default.btn-xl(style="border:0" @click="selectRow(index)")
                            i.fa.fa-angle-double-right
                    td(style="width:20%" v-for="(o, index_inner) in permutation[type][scale.replace(',', '.')]"
                        :class="{'not-active': (selected.column !== false && selected.column !== index_inner) || (selected.row !== false && selected.row !== index)}")
                        OptiOptometristsSurveysLetter(:type="type" :model="o" @click.native="selectRowAndColumn(index, index_inner)")

                    td(width="1px")
                        a.btn.btn-inverse.btn-xl(@click="$emit('update-visus', scale)" :disabled="readOnly") {{scale}}
                tr(v-if="list[mode].length === 1" :class="{'selected-visus': !readOnly && dirty && list[mode][0] === visus}")
                    td(v-for="o in permutation[type][list[mode][0].replace(',', '.')]")
                        OptiOptometristsSurveysLetter(:type="type" :model="o")

                    td(width="1px")
                        a.btn.btn-inverse.btn-xl(@click="$emit('update-visus', '0,05')" :disabled="readOnly") {{list[mode][0]}}
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    import OptiOptometristsSurveysLetter
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysLetter'

    export default {
        name: 'OptiOptometristsSurveysOptotypesVisus',
        components: {OptiOptometristsSurveysLetter},
        props: {
            permutation: {
                type: Object,
                required: true
            },
            dirty: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                required: true
            },

            visus: {
                type: String,
                required: true
            },

            readOnly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                watch: {
                    optotypes: null,
                    visus: null
                },

                mode: '0.05',
                selected: {
                    row: false,
                    column: false
                },

                list: {
                    '0.05': ['0,05'],
                    '0.3': ['0,1', '0,2', '0,3'],
                    '0.6': ['0,4', '0,5', '0,6'],
                    '0.9': ['0,7', '0,8', '0,9'],
                    '1.5': ['1,0', '1,2', '1,5']
                }
            }
        },

        activated() {
            this.setMode()

            this.sendDisplay()

            this.watch.optotypes = this.$watch('optotypes', () => {
                this.sendDisplay()
            })

            this.watch.visus = this.$watch('visus', () => {
                this.setMode(true)
            })
        },
        deactivated() {
            this.watch.optotypes()
            this.watch.visus()
        },

        methods: {
            setMode(display) {
                if(this.visus === '-' && this.mode !== '0.05') {
                    this.mode = '0.05'

                    if(display) {
                        this.sendDisplay()
                    }
                } else {
                    _.forEach(this.list, (list, mode) => {
                        list.forEach(l => {
                            if(this.visus === l && this.mode !== mode) {
                                this.mode = mode

                                if(display) {
                                    this.sendDisplay()
                                }
                            }
                        })
                    })
                }
            },

            sendDisplay() {
                let mod = []

                if(this.selected.row !== false) {
                    mod.push('row=' + this.selected.row)
                }

                if(this.selected.column !== false) {
                    mod.push('column=' + this.selected.column)
                }

                mod = mod.length > 0 ? '?' + mod.join('&') : ''
                OptometristsDisplay.message.open('/optometrist/screen/' + this.type + '/' + this.mode + '/' +
                    this.permutation.active + mod)
            },

            selectRow(index) {
                if(this.selected.row === index) {
                    this.selected.column = false
                    this.selected.row = false
                } else {
                    this.selected.column = false
                    this.selected.row = index
                }

                this.sendDisplay()
            },
            selectColumn(index) {
                if(this.selected.column === index) {
                    this.selected.column = false
                    this.selected.row = false
                } else {
                    this.selected.column = index
                    this.selected.row = false
                }

                this.sendDisplay()
            },
            selectRowAndColumn(row, column) {
                if(this.selected.column === column && this.selected.row === row) {
                    this.selected.column = false
                    this.selected.row = false
                } else {
                    this.selected.column = column
                    this.selected.row = row
                }

                this.sendDisplay()
            }
        },
        computed: {
            optotypes() {
                return [this.permutation.active, this.type]
            }
        }
    }
</script>