<template lang="pug">
    .row
        .col-xs-12(v-if="$asyncComputed.report.success && loading.main === false")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'catalog.hoya.heading.REPORT'|translate}} \#{{report.id}}

                .panel-body
                    .row
                        .col-sm-6(style="margin-bottom:10px")
                            .row(style="margin-bottom:5px")
                                .col-xs-6.bold.text-right {{'catalog.hoya.table.ID'|translate}}
                                .col-xs-6 {{report.id}}
                            .row(style="margin-bottom:5px")
                                .col-xs-6.bold.text-right {{'catalog.hoya.table.FV'|translate}}
                                .col-xs-6 {{report.invoice_id}}
                            .row(style="margin-bottom:5px")
                                .col-xs-6.bold.text-right {{'catalog.hoya.table.SHIPPINGDATE'|translate}}
                                .col-xs-6 {{report.shipping_date}}
                            .row
                                .col-xs-6.bold.text-right {{'catalog.hoya.table.SUM'|translate}}
                                .col-xs-6 {{report.summary|currency}}

                        .col-sm-6.text-center-xs.text-right-not-xs
                            OptiButtonDropdown(type="info") EDI
                                template(#icon)
                                    i.fa.fa-file-excel-o
                                template(#menu)
                                    li
                                        a(:href="'upload/hoya/edi/hoya/edi_hoya_' + report.created_at.split(' ')[0] + '.xlsx'"
                                            target="_blank") Hoya
                                    li
                                        a(:href="'upload/hoya/edi/eOpti/edi_eOpti_' + report.created_at.split(' ')[0] + '.xls'"
                                            target="_blank") eOpti
                                    li
                                        a(:href="'upload/hoya/edi/compare/edi_compare_' + report.created_at.split(' ')[0] + '.xls'"
                                            target="_blank") {{'catalog.hoya.buttons.COMPARE'|translate}}
                .row
                    .col-xs-12.table-responsive
                        table.table.table-striped.middle(style="position:relative")
                            thead
                                tr
                                    td.text-center(colspan="100") EDI
                                tr
                                    th.text-center #
                                    th.text-center {{'catalog.hoya.table.ILOG'|translate}}
                                    th.text-left {{'catalog.hoya.table.REF'|translate}}
                                    th.text-left {{'catalog.hoya.table.DATE'|translate}}
                                    th.text-center {{'catalog.hoya.table.EYE'|translate}}
                                    th.text-left {{'catalog.hoya.table.FV_CODE'|translate}}
                                    th.text-left SPH
                                    th.text-left Cyl
                                    th.text-left Ax
                                    th.text-left Add
                                    th.text-left Parametry dodatkowe
                                    th.text-right ZHC
                            tbody
                                tr(v-show="edi_errors && !edi_errors.length")
                                    td.text-center.text-success(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'catalog.hoya.table.NOERRORSEDI'|translate}}

                                tr(v-show="edi_errors === 'no_file'")
                                    td.text-center.text-muted(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span Plik EDI nie jest dostępny.

                            template(v-if="edi_errors && edi_errors !== 'no_file'" v-for="(error, index) in edi_errors")
                                tbody
                                    tr(:class="{'warn': index % 2 !== 0}" v-if="error.length  > 1 && error[1] === null")
                                        td.text-center(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span Brak wiersza w eOpti!

                                    tr(:class="{'warn': index % 2 !== 0}" v-else-if="error.length  > 13")
                                        td.text-center {{index + 1}}
                                        td.text-center(:class="{'difference': difference(index, 5)}")
                                            a.link(:title="'default.REFERENCETYPE'|translate"
                                                @click="openHoyaPage(error[5])") {{error[5]}}
                                        td.text-left(:class="{'difference': difference(index, 6)}")
                                            a.link(:title="'default.REFERENCETYPE'|translate"
                                                @click="openRefPage(error[6])") {{error[6]}}
                                        td.text-left(:class="{'difference': difference(index, 1)}") {{error[1]}}
                                        td.text-center(:class="{'difference': difference(index, 8)}") {{error[8]}}
                                        td.text-left(:class="{'difference': difference(index, 7)}") {{error[7]}}
                                        td.text-left(:class="{'difference': difference(index, 9)}") {{error[9]}}
                                        td.text-left(:class="{'difference': difference(index, 10)}") {{error[10]}}
                                        td.text-left(:class="{'difference': difference(index, 11)}") {{error[11]}}
                                        td.text-left(:class="{'difference': difference(index, 12)}") {{error[12]}}
                                        td.text-left(:class="{'difference': difference(index, 13)}") {{error[13]}}
                                        td.text-right(:class="{'difference': difference(index, 17)}") {{error[17]|currency}}
                                    tr(:class="{'warn': index % 2 !== 0}" v-else-if="error.length < 13")
                                        td.text-center {{error[0]}}

                            tfoot
                                tr
                                    td.text-right.warn(colspan="100")
                                        i.fa.fa-info-circle.mr3
                                        span {{'catalog.hoya.info.EOPTI_ROW'|translate}}

                            OptiDimmer(:active="loading.edi")

                .row.hide
                    <!-- w przyszłości do usunięcia jak hoya zrobi nowe kody -->
                    .col-xs-12.table-responsive
                        table.table.table-striped.middle
                            thead
                                tr
                                    td.text-center(colspan="100")
                                        span.text-center PORÓWNANIE CEN
                                tr
                                    th.text-center #
                                    th.text-center {{'catalog.hoya.table.ILOG'|translate}}
                                    th.text-left {{'catalog.hoya.table.REF'|translate}}
                                    th.text-left {{'catalog.hoya.table.DATE'|translate}}
                                    th.text-center {{'catalog.hoya.table.EYE'|translate}}
                                    th.text-left {{'catalog.hoya.table.FV_CODE'|translate}}
                                    th.text-right {{'catalog.hoya.table.ZHCOPTI'|translate}}
                                    th.text-center {{'catalog.hoya.table.ZHC'|translate}}
                                    th.text-center {{'catalog.hoya.table.COMMENT'|translate}}
                                    th.text-center {{'catalog.hoya.table.DIFFERENCE'|translate}}
                                    th.text-center {{'catalog.hoya.table.TYPE'|translate}}

                                tr(v-show="!errors")
                                    td.text-center.text-success(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'catalog.hoya.table.NOERRORS'|translate}}

                                template(v-for="(item, index) in sortedItems")
                                    tr.finger(:class="{'warn': item.error_type !== 0}" v-show="item.error_type > 0 || show.all || item.showTask"
                                        @click="showThisTask(item.task_id, index)")
                                        td.text-center
                                            div {{index + 1}}
                                            div {{item.id}}
                                        td.tex-center
                                            a.link(:title="'default.REFERENCETYPE'|translate"
                                                :href="'https://pl.hoyailog.com/order/'+item.ilog_id+'#orderSummary'" target="_blank") {{item.ilog_id}}
                                        td.text-left
                                            a.link(:title="'default.REFERENCETYPE'|translate"
                                                :href="$state.href('app.task.detail', {id: item.task_id})") {{item.task_id}}
                                        td.text-left {{item.order_date}}
                                        td.text-center {{item.eye}}
                                        td.text-left {{item.fv_code}}
                                        td.text-right {{item.opti_zhc|currency}}
                                        td.text-center
                                            span {{item.zhc|currency}}
                                                i.fa.fa-history.fa-2x.fa-fw(style="cursor:pointer"
                                                    @click.stop.prevent="showHist(index)")
                                        td.text-center {{item.comment}}
                                        td.text-center {{item.difference|currency}}
                                        td.text-center
                                            OptiEnum(enum="HoyaErrorType" :id="item.error_type")

                                    tr(v-if="item.checkPrice")
                                        td(colspan="100")
                                            OptiHoyaPriceHistory(:item="item")

                .panel-footer.hide
                    <!-- w przyszłości do usunięcia jak hoya zrobi nowe kody -->

                    OptiButton(@click.native="$state.go('app.catalog.hoya')") {{'catalog.hoya.buttons.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left

                    OptiButton.pull-right(v-show="!show.all" @click.native="show.all = !show.all") {{'catalog.hoya.buttons.SHOWALL'|translate}}
                        template(#icon)
                            i.fa.fa-angle-down

                    OptiButton.pull-right(v-show="show.all" @click.native="show.all = !show.all") {{'catalog.hoya.buttons.HIDEGOOD'|translate}}
                        template(#icon)
                            i.fa.fa-angle-up

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {HoyaHelper} from '@/js/app/vue/helpers/HoyaHelper'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonDropdown from '@/js/app/vue/components/Button/OptiButtonDropdown'
    import OptiHoyaPriceHistory from '@/js/app/vue/components/Catalog/Hoya/OptiHoyaPriceHistory'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'HoyaDailyReportDetails',
        components: {
            OptiDimmer,
            OptiButton,
            OptiButtonDropdown,
            OptiEnum,
            OptiHoyaPriceHistory
        },
        data() {
            return {
                allCombinations: false,

                errors: false,
                edi_errors: null,

                show: {
                    select_all_combi: false,
                    all: false
                },
                loading: {
                    main: true,
                    edi: false
                }
            }
        },
        methods: {
            getEdiErrors(date) {
                this.loading.edi = true

                let file = 'upload/hoya/edi/compare/edi_compare_' + date + '.xls'

                API.post('specifications/hoyaDifference/checkEdiDifference', {
                    file: file
                }).then((res) => {
                    this.edi_errors = res.data
                }).finally(() => {
                    this.loading.edi = false
                }).catch(() => {
                    this.$notify.error('catalog.hoya.notify.error.PREVIEW_EDI')
                })
            },
            showThisTask(task, index) {
                this.report.items.forEach(item => {
                    if(item.task_id === task) {
                        return item.showTask = item.showTask !== true
                    }

                    let updated = this.report.items[index]
                    updated.showTask = updated.showTask !== true

                    this.report.items.splice(index, 1, updated)
                })
            },
            showHist(idx) {
                let item = this.report.items[idx]
                item.checkPrice = item.checkPrice !== true;

                this.report.items.splice(idx, 1, item)
            },
            selectAll() {
                this.getEdiErrors()

                if(this.allCombinations) {
                    this.report.items.forEach(item => {
                        if(item.error_type === 4) {
                            item.change = true
                        }
                    })
                }
                if(!this.allCombinations) {
                    this.report.items.forEach(item => {
                        if(item.error_type === 4) {
                            item.change = false
                        }
                    })
                }
            },
            difference(row_index, column_index) {
                let row_index_compare = row_index % 2 ? row_index - 1 : row_index + 1

                if(this.edi_errors[row_index][1] !== null && this.edi_errors[row_index_compare][1] !== null) {
                    return this.edi_errors[row_index][column_index] !== this.edi_errors[row_index_compare][column_index]
                }

                return false
            },

            openHoyaPage(order_id) {
                HoyaHelper.openHoyaPage(order_id)
            },
            openRefPage(ref_id) {
                if(ref_id.startsWith('REG')) {
                    window.open(this.$state.href('app.regeneration.details', {id: ref_id.slice(3)}), '_blank')
                } else if(parseInt(ref_id) > 0) {
                    window.open(this.$state.href('app.task.detail', {id: parseInt(ref_id)}), '_blank')
                } else {
                    this.$notify.error('catalog.hoya.notify.error.LINK')
                }
            }
        },
        computed:{
            sortedItems() {
                this.report.items.sort((a, b) => {
                    let sorted = b.task_id - a.task_id

                    if(sorted !== 0) {
                        return sorted
                    }

                    return b.error_type - a.error_type
                })

                return this.report.items
            }
        },
        asyncComputed: {
            async report() {
                try {
                    this.loading.main = true

                    let {data} = await API.get('specifications/hoyaDifference/dailyReports/' + this.$state.params.id)
                    data.items.filter(el => {
                        if(el.error_type === 4) {
                            this.show.select_all_combi = true
                        } else if(el.error_type === 1) {
                            el.checkPrice = false
                        }

                        if(el.error_type !== 0) {
                            this.errors = true
                        }
                    })

                    this.loading.main = false

                    this.getEdiErrors(data.created_at.split(' ')[0])

                    return data
                } catch (e) {
                    this.loading.main = false
                    console.log(e)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    tr {
        &.finger {
            cursor: pointer;
        }

        > td.difference {
            background: #000;
            color: #fff;

            > a.link {
                color: #fff;
            }
        }
    }
</style>