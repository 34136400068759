<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form.form-horizontal
                .form-group
                    label.col-sm-4.control-label.star-required(for="date") {{'register.expense.current.form.DATEOFDOCUMENT'|translate}}
                    .col-sm-8
                        OptiDate#date(:value="form.date" @update-date="d => form.date = d")
                        OptiValidate(f="date" :data="$v.form.date")
                .form-group
                    label.col-sm-4.control-label.star-required(for="description") {{'bookkeeping.expenses.table.DESCRIPTION'|translate}}
                    .col-sm-8
                        textarea#description.form-control(name="description" v-model="form.description" rows="7")
                        OptiValidate(f="description" :data="$v.form.description")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'bookkeeping.expenses.table.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="ExpensesAccountType" :value="form.type" @update-enum="v => form.type = v")
                        OptiValidate(f="type" :data="$v.form.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="costs") {{'bookkeeping.expenses.table.COSTS'|translate}}
                    .col-sm-8
                        OptiSelectEnum#costs(enum="ExpensesAccountCosts" :value="form.costs" @update-enum="v => form.costs = v")
                        OptiValidate(f="costs" :data="$v.form.costs")
                .form-group
                    label.col-sm-4.control-label.star-required(for="nodocument") {{'bookkeeping.expenses.table.DOCID'|translate}}
                    .col-sm-8
                        input#nodocument.form-control(name="nodocument" v-model="form.nodocument")
                        OptiValidate(f="nodocument" :data="$v.form.nodocument")

            OptiBookkeepingExpensesAddDepartments(:form="form" :v="$v.form")

            form.form-horizontal(@submit.prevent="addExpense")
                .form-group
                    .bold.warn(v-show="!validateDepartments") {{'bookkeeping.expenses.notify.error.FILLDEPARTMENTS'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="disabled")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiBookkeepingExpensesAddDepartments
        from '@/js/app/vue/components/Bookkeeping/Expenses/Add/OptiBookkeepingExpensesAddDepartments'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiBookkeepingExpensesAdd',
        components: {
            OptiDate,
            OptiSelectEnum,
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiBookkeepingExpensesAddDepartments,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    date: moment().format('YYYY-MM-DD'),
                    type: '',
                    costs: null,
                    nodocument: '',
                    value: 0,
                    cyclical: false,
                    cyclical_date: '',
                    departments: [],

                    equal: false,
                },

                loading: true
            }
        },
        validations: {
            form: {
                date: {
                    required
                },
                description: {
                    required
                },
                type: {
                    required
                },
                costs: {
                    required
                },
                nodocument: {
                    required
                },
                value: {
                    noZero
                },
                cyclical_date: {
                    required: requiredIf(form => form.cyclical)
                }
            }
        },
        mounted() {
            API.get('bookkeeping/expenses/departments').then(res => {
                this.form.departments = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('bookkeeping.expenses.notify.error.DEPARTMENTS')
            })
        },
        methods: {
            addExpense() {
                this.loading = true

                API.post('bookkeeping/expenses/add', this.form).then(() => {
                    this.$notify.error('bookkeeping.expenses.notify.CREATE')

                    this.$state.go('app.bookkeeping.expenses')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('bookkeeping.expenses.notify.error.CREATE')
                })
            }
        },
        computed: {
            validateDepartments() {
                let _return = false,
                    is_selected = false,
                    selected_with_no_value = false

                this.form.departments.forEach(department => {
                    if(department.checked) {
                        is_selected = true

                        if(!department.value) {
                            selected_with_no_value = true
                        }
                    }
                })

                if(is_selected && !selected_with_no_value) {
                    _return = true
                }

                return _return
            },
            disabled() {
                return this.$v.form.$invalid || !this.validateDepartments
            }
        }
    }
</script>
