<template lang="pug">
    .row
        .col-xs-12
            .row(style="margin-bottom:10px")
                .col-xs-9(v-if="current_question_hoya.name")
                    h4.filter-header.page-header
                        span.text-muted(style="margin-right:5px") {{index_question_hoya + 1}}
                        span {{'catalog.offer.questions.hoya.' + current_question_hoya.name|translate}}

                .col-xs-3.text-right
                    OptiButton(@click.native="$store.commit('offer/setMode', 'filters')") {{'catalog.offer.switch.FILTERS'|translate}}
                        template(#icon)
                            i.fa.fa-filter

            .row
                .col-md-4.col-sm-6(v-for="option in current_question_hoya.options" :key="option.name")
                    .question-option(:class="{'question-option-disabled': loading_products}")
                        label.question-name(:for="option.name"
                            :class="{'question-option-true': option.name === selected_current_hoya_option, 'question-option-false': option.name !== selected_current_hoya_option}"
                            :title="'catalog.offer.questions.hoya.' + option.name|translate"
                            @click="$store.dispatch('offer/clickOption')")
                            i.fa.fa-lg.mr3(:class="{'fa-check': option.name === selected_current_hoya_option, 'fa-remove': option.name !== selected_current_hoya_option}")
                            span {{'catalog.offer.questions.hoya.' + option.name|translate}}

                        input.hide(:id="option.name" type="checkbox"
                            :disabled="loading_products" :checked="option.name === selected_current_hoya_option"
                            @change="e => $store.commit('offer/setQuestionHoyaOption', {name: option.name, bool: e.target.checked})")

            .row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg(style="margin-top:10px")
                    OptiButton(v-if="index_question_hoya" @click.native="$store.commit('offer/setSelectedQuestionHoyaBack')"
                        ) {{'catalog.offer.questions.navi.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton(v-else="index_question_hoya" @click.native="$store.commit('offer/setMode', 'questions_frames')"
                        ) {{'catalog.offer.questions.navi.BACK_TO_FRAMES'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                .col-md-4.text-center(style="margin-top:10px")
                    OptiButton(type="success" v-show="is_last_step" :disabled="!selected_current_hoya_option || loading_hoya"
                        @click.native="$store.dispatch('offer/loadHoya')") {{'catalog.offer.questions.FINISH'|translate}}
                        template(#icon)
                            i.fa.fa-check

                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg(style="margin-top:10px")
                    OptiButton(type="success" :right="true" v-show="!is_last_step"
                        :disabled="!selected_current_hoya_option" @click.native="$store.commit('offer/setSelectedQuestionHoya')"
                        ) {{'catalog.offer.questions.navi.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogOfferQuestionsHoya',
        components: {
            OptiButton
        },
        computed: {
            current_question_hoya() {
                return this.$store.getters['offer/getCurrentQuestionHoya']
            },
            index_question_hoya() {
                return this.$store.getters['offer/getIndexQuestionHoya']
            },
            selected_current_hoya_option() {
                return this.$store.getters['offer/getSelectedCurrentHoyaOption']
            },

            is_last_step() {
                return this.$store.getters['offer/isLastStep']
            },
            loading_products() {
                return this.$store.getters['offer/getLoading']('products')
            },
            loading_hoya() {
                return this.$store.getters['offer/getLoading']('hoya')
            }
        }
    }
</script>