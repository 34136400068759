import Vue from 'vue'

import sale from '@/js/app/vue/store/carts/helper/sale'
import order from '@/js/app/vue/store/carts/helper/order'
import sendback from '@/js/app/vue/store/carts/helper/sendback'

let helper = {
    sale: sale,
    order: order,
    sendback: sendback
}

export default {
    setActive(state, index) {
        Vue.set(state.carts, 'active', [false, false, false])

        Vue.set(state.carts.active, index, true)
    },

    setCart(state, {type, data}) {
        data.productIdBarcode = {}
        data.totals = {}
        data.disabled_btn_delete = true

        if(type === 'sale') {
            data.patient_search = Object.keys(data.patient).length ? _.clone(data.patient) : {}
        }

        Vue.set(state.carts, type, data)
    },
    setCartOrderProblems(state, data) {
        Vue.set(state.carts.order, 'problems', data)
    },
    setItems(state, {type, items}) {
        Vue.set(state.carts[type], 'items', items)
    },
    setTotals(state, type) {
        Vue.set(state.carts[type], 'totals', helper[type].setTotal(state.carts[type]))
    },

    setPatientExists(state, patient_exists) {
        Vue.set(state.carts.sale, 'patient_exists', patient_exists)

        if(patient_exists === 2) {
            state.carts.sale.items.forEach(item => {
                Vue.set(item, 'mode', 1)
                Vue.set(item, 'percentDiscount', 0)
                Vue.set(item, 'discount', 0)
            })
        }
    },
    setPatient(state, patient) {
        Vue.set(state.carts.sale, 'patient_search', patient)
    },
    changedPatient(state, patient) {
        if(Object.keys(patient).includes('id')) {
            Vue.set(state.carts.sale, 'patient', patient)
            Vue.set(state.carts.sale, 'patient_id', patient.id)

            helper.sale.setDiscount(state)
        } else {
            Vue.set(state.carts.sale, 'patient', {})
            Vue.set(state.carts.sale, 'patient_id', null)
        }
    },

    setQuantity(state, {type, key, quantity}) {
        Vue.set(state.carts[type].items[key], 'quantity', quantity)
    },
    setDiscountPercent(state, {type, key, discount}) {
        Vue.set(state.carts[type].items[key], 'percentDiscount', discount)
        Vue.set(state.carts[type].items[key], 'mode', 0)
    },
    setDiscount(state, {type, key, discount}) {
        Vue.set(state.carts[type].items[key], 'discount', discount)
        Vue.set(state.carts[type].items[key], 'mode', 1)
    },

    setDiscountForProduct(state, product_id) {
        helper.sale.setDiscount(state, product_id)
    },

    setCheckbox(state, {type, key, bool}) {
        Vue.set(state.carts[type].items[key], 'checkbox', bool)
    },
    setCheckboxAll(state, {type, bool}) {
        Vue.set(state.carts[type], 'checkbox_all', bool)
    },
    setDisabledBtnDelete(state, type) {
        let disabled_btn_delete = true
        state.carts[type].items.forEach(item => {
            if(item.checkbox) {
                disabled_btn_delete = false
            }
        })

        Vue.set(state.carts[type], 'disabled_btn_delete', disabled_btn_delete)
    },

    setHighlight(state, {type, product_id}) {
        Vue.set(state.carts[type], 'highlight', true)

        state.carts[type].items.forEach(item => {
            Vue.set(item, 'highlight', item.productId === product_id)
        })
    },
    refreshHighlight(state, type) {
        Vue.set(state.carts[type], 'highlight', false)
    },

    setComments(state, {key, comments}) {
        Vue.set(state.carts.sale.items[key], 'comments', comments)
    },
    setCodes(state, {key, codes}) {
        Vue.set(state.carts.sale.items[key], 'codes', codes)
    },

    setWarningError(state, {id, warning, error}) {
        state.carts.order.items.forEach(item => {
            if(item.id === id) {
                Vue.set(item, 'warning', warning)
                Vue.set(item, 'error', error)
            }
        })
    },

    setLoading(state, bool) {
        state.loading = bool
    },
    setPending(state, bool) {
        state.pending = bool
    }
}
