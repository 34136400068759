<template lang="pug">
    div
        h4.page-header(style="margin-top:0" v-show="patients.length")
            .row
                .col-xs-12
                    a.btn.btn-default(style="margin-right:5px;margin-bottom:5px" v-for="patient in patients.slice(0, show ? patients.length : 1)"
                        :class="{'btn-purple active': parseInt($state.params.id) === patient.id, main: patient.cellPhoneNumber}"
                        :href="$state.href('app.patient.detail', {id: patient.id})" :title="'default.REFERENCETYPE'|translate")
                        i.fa.fa-user.mr3
                        span {{patient.firstName}} {{patient.lastName}} \#{{patient.id}}
                        div(style="display:block;font-size:.8em") ul. {{patient.street}}, {{patient.zipCode}} {{patient.city}}

                        div(v-show="patient.cellPhoneNumber")
                            i.fa.fa-phone.mr3
                            span {{patient.cellPhoneNumber|number(0)}}

                        .text-muted(v-show="!patient.cellPhoneNumber")
                            i.fa.fa-remove.mr3
                            span {{'patient.notify.NOPHONE'|translate}}

                        div(:class="{'text-muted': !patient.loyality.sum}")
                            span.mr3 {{'patient.CARD'|translate}}
                            span(v-show="patient.loyality.typeTranslate") {{patient.loyality.typeTranslate|translate}}
                            span(v-show="!patient.loyality.typeTranslate") {{'cards.type.NONE'|translate}}
                            span(style="margin-left:3px") - {{patient.loyality.sum|currency}}

                    .btn.btn-default(style="margin-right:5px;margin-bottom:5px" @click="show = !show")
                        i.fa.fa-lg(:class="{'fa-chevron-left': show, 'fa-chevron-right': !show}")
                        div
                            span.mr3(v-if="show") {{'default.toggle.HIDE'|translate}}
                            span.mr3(v-else) {{'default.toggle.SHOW'|translate}}
                            span {{patients.length - 1}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsRelationships',
        components: {
            OptiDimmer
        },
        data() {
            return {
                patients: [],

                show: false,
                loading: true
            }
        },
        mounted() {
            API.get('patient/get-relationships/' + this.$state.params.id).then(res => {
                this.patients = res.data

                this.parseLoyality()

                this.$emit('relationships', this.patients)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('patient.notify.error.LOADRELATIONSHIPS')
            })
        },
        methods: {
            parseLoyality() {
                this.patients.forEach(patient => {
                    let sum = 0

                    if(typeof patient.loyality.events !== 'undefined') {
                        patient.loyality.events.forEach(event => {
                            sum += event.price
                        })
                    }

                    this.$set(patient.loyality, 'sum', sum)
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .main {
        border: 3px solid limegreen;
    }
</style>