<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'notes.HEADING'|translate}}

        .row
            .col-xs-12.note(v-for="(note, index) in notes" :key="note.id")
                .media-box(:class="{'bg-separator': index % 2}")
                    .media-box-body
                        p.m0.text-muted
                            small {{note.user.firstName}} {{note.user.lastName}}
                            small.pull-right {{note.created_at}}
                        p.m0(v-html="$options.filters.nl2br(note.note)")
                        p.m0(style="padding-top:5px" v-if="note.upload")
                            a(:href="'notes/' + note.upload|upload" :title="'notes.upload.TITLE'|translate" target="_blank")
                                img.img-rounded(style="width:50%" :src="'notes/' + note.upload|upload")

            .col-xs-12.text-center.text-muted(style="padding:15px" v-show="!notes.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'notes.NONE'|translate}}

            .col-xs-12(style="padding:15px 30px")
                form.form.form-horizontal
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            textarea.form-control(:placeholder="'notes.PLACEHOLDER'|translate" rows="5" v-model="note")

                    .form-group(style="padding-top:10px" v-if="upload")
                        .col-sm-8.col-sm-offset-4
                            OptiUpload#notePhoto(
                                prefix="notes"
                                :picture="true"

                                :file="note_photo"

                                @update-loading="l => loading_photo = l"
                                @update-file="f => note_photo = f")

        .panel-footer.text-right
            OptiButton(type="success" :disabled="!note || disabled || loading_photo"
                @click.native="addNote") {{'notes.BTN'|translate}}
                template(#icon)
                    i.fa.fa-save
</template>

<script>
    import {API} from '../api'

    import OptiButton from './Button/OptiButton'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'

    export default {
        name: 'OptiNotes',
        components: {
            OptiButton,
            OptiUpload
        },
        props: {
            table: {
                type: String,
                required: true
            },
            tableKey: {
                type: String,
                required: true
            },
            id: {
                type: Number,
                required: true
            },
            notes: {
                type: Array,
                required: true
            },
            upload: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                note: '',
                note_photo: '',

                loading_photo: false,
                disabled: false
            }
        },
        methods: {
            addNote() {
                this.disabled = true

                if(this.note) {
                    API.post('note', {
                        table: this.table,
                        key: this.tableKey,
                        id: this.id,
                        note: this.note,
                        photo: this.note_photo
                    }).then(res => {
                        this.$emit('update-notes', res.data)

                        this.note = ''
                        this.note_photo = ''

                        this.disabled = false
                    }).catch(() => {
                        this.$notify.error('notes.notify.error.SAVE')

                        this.note_photo = ''

                        this.disabled = false
                    })
                }
            },

            deleteNotePhoto() {
                this.note_photo = ''
            }
        }
    }
</script>

<style scoped lang="less">
    .note {
        &:not(:last-child) > .media-box {
            border-bottom: 1px solid #000;
        }

        > .media-box {
            padding: 10px;
        }
    }
</style>