<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'catalog.product.stockhistory.STOCKHISTORY'|translate}}

        .panel-body(v-if="$uac.permission('products.history')")
            .row
                .col-xs-12
                    form.form-horizontal
                        .form-group
                            label.col-sm-5.control-label(for="department_salon") {{'catalog.product.SALON'|translate}}
                            .col-sm-7
                                OptiSelectDepartment#department_salon(
                                    :department-id="departmentId"
                                    :simple="true"

                                    @selected="d => $emit('update-department', d)")

                .col-xs-12.warn.text-center(v-if="archive")
                    i.fa.fa-exclamation-circle.mr3
                    span.mr3 {{'catalog.product.stockhistory.ARCHIVE'|translate}}
                    span {{product.depository.department.name}}.

        .table-responsive
            table.table.table-striped
                thead
                    tr
                        th #
                        th {{'catalog.product.stockhistory.AFTERCHANGESTATE'|translate}}
                        th {{'catalog.product.stockhistory.CHANGESTATE'|translate}}
                        th {{'catalog.product.stockhistory.TYPECHANGE'|translate}}
                        th {{'catalog.product.stockhistory.IDDOC'|translate}}
                        th {{'catalog.product.stockhistory.DETAIL'|translate}}
                        th {{'catalog.product.stockhistory.DATECHANGE'|translate}}
                tbody
                    tr(v-for="(log, key) in product.depository.logs")
                        td.text-right {{product.depository.logs.length - key}}
                        td.text-right {{log.stockAfterChange}}
                        td.text-right {{log.change}}
                        td {{log.referenceTypeName|translate}}
                        td.text-right
                            OptiRefId(v-if="!hide" section="product-history" :type-id="log.referenceType" :id="log.referenceId")
                            span(v-else) {{log.referenceId}}
                        td {{log.description}}
                        td {{log.created|noYearSek}}

                    tr(v-show="!product.depository.logs.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

        .panel-footer.text-right(v-show="$uac.permission('products.correct')")
            OptiButton(type="info" :disabled="archive" @click.native="dialog = true"
                ) {{'catalog.product.correct.CORRECT'|translate}}
                template(#icon)
                    i.fa.fa-edit

                MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                    OptiDialogProductReport(
                        mode="correct"

                        :product="product"
                        :department-id="departmentId"

                        @reported="reported"
                        @closed="dialog = false")

        OptiDimmer(:active="loading")
</template>

<script>
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogProductReport
        from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductReport'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductDetailsDepositoryHistory',
        components: {
            OptiSelectDepartment,
            OptiRefId,
            OptiButton,
            OptiDialogProductReport,
            OptiDimmer
        },
        props: {
            product: {
                type: Object,
                required: true
            },

            departmentId: {
                type: Number,
                required: true
            },
            archive: {
                type: Boolean,
                required: true
            },

            loading: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
                dialog: false
            }
        },
        methods: {
            reported() {
                this.dialog = false

                this.$emit('reported')
            }
        }
    }
</script>