<template lang="pug">
	.row
		.col-xs-12
			h2.text-center
				i.fa.fa-gears.mr3
				span W BUDOWIE

		.col-sm-6.col-xs-12(v-if="product")
			.panel.panel-default
				.panel-body.text-center
					div
						a(:href="'https://salony.eopti.pl/thumb/original/ids/' + product.product_id + '.jpg'" target="_blank")
							img(:src="'https://salony.eopti.pl/thumb/product/ids/' + product.product_id + '.jpg'")
			.col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
				OptiButton(:title="'catalog.product.buttons.back.CATALOG'|translate"
					@click.native="$state.go('app.catalog.product', {id: product.product_id, departmentId: $state.params.departmentId})"
				) {{'catalog.product.buttons.back.CATALOG'|translate}}
					template(#icon)
						i.fa.fa-arrow-left

		.col-sm-6.col-xs-12
			.panel.panel-primary
				.panel-heading
					i.fa.fa-navicon.mr3
					span {{'eShop.product.breadcrumb.HEADER'|translate}}

				.panel-body(v-if="product")
					table.table.table-responsive.text-center.middle
						tbody
							tr
								td  {{'catalog.product.eshop.SHOPWARE_NAME'|translate}}
								td
									input.form-control(v-model="product.shopware_name")
							tr
								td  {{'catalog.product.eshop.CREATED_AT'|translate}}
								td  {{product.created_at}}
							tr
								td {{'catalog.product.eshop.MEDICAL_PRODUCT'|translate}}
								td {{product.medical_product}}
							tr
								td {{'catalog.product.eshop.AVAILABLE_QTY'|translate}}
								td {{product.available_qty}}
							tr
								td {{'catalog.product.eshop.START_DATE'|translate}}
								td {{product.start_date}}
							tr
								td {{'catalog.product.eshop.EXPIRE_DATE'|translate}}
								td {{product.expire_date}}
							tr
								td {{'catalog.product.eshop.UPDATED_AT'|translate}}
								td {{product.updated_at}}

		.col-sm-6.col-xs-12
			.panel.panel-primary
				.panel-heading
					i.fa.fa-navicon.mr3
					span {{'eShop.product.table.OPTI'|translate}}

				.panel-body(v-if="product")
					table.table.table-responsive.text-center.middle
						tbody
							tr
								td  ID produktu
								td  {{product.product_id}}
							tr
								td  {{'catalog.product.producent.BARCODE'|translate}}
								td  {{product.barcode}}
							tr
								td  {{'catalog.product.eshop.EXPIRED'|translate}}
								td  {{product.expired}}
							tr
								td  {{'catalog.product.eshop.MANUFACTURER'|translate}}
								td  {{product.manufacturer}}
							tr
								td  {{'catalog.product.eshop.SUPPLIER'|translate}}
								td  {{product.supplier}}
							tr
								td  {{'catalog.product.eshop.PRODUCT_TYPE'|translate}}
								td  {{product.product_type}}
							tr
								td {{'catalog.product.eshop.PRODUCT_NAME'|translate}}
								td {{product.name}}
							tr
								td {{'catalog.product.properties.FULLNAME'|translate}}
								td {{product.fullName}}
							tr
								td {{'catalog.product.eshop.PRICE_NETTO'|translate}}
								td {{product.price.price_netto}}
							tr
								td {{'catalog.product.eshop.PRICE_BRUTTO'|translate}}
								td {{product.price.price_brutto}}
							tr
								td {{'catalog.product.eshop.VAT'|translate}}
								td {{product.price.vat}}
							tr
								td {{'catalog.product.eshop.SPECIAL_PRICE'|translate}}
								td {{product.specialPrice}}
							tr
								td {{'catalog.product.eshop.SPECIAL_PRICE_VALUE'|translate}}
								td {{product.specialPriceValue}}
							tr
								td {{'catalog.product.eshop.SPECIAL_PRICE_BEFORE_VALUE'|translate}}
								td {{product.specialPriceBeforeValue}}
		.col-sm-6.col-xs-12
			.panel.panel-primary
				.panel-heading
					i.fa.fa-navicon.mr3
					span {{'eShop.product.table.ATTRIBUTES_SIMPLE'|translate}}

				.panel-body(v-if="product")
					table.table.table-responsive.text-center.middle
						tbody
							tr
								td {{'eShop.product.table.STYLE_NAME'|translate}}
								td {{product.attributes_simple.style_name}}
							tr
								td {{'eShop.product.table.COLOR_CODE'|translate}}
								td {{product.attributes_simple.color_code}}
							tr
								td {{'eShop.product.table.COLOR_DESCRIPTION'|translate}}
								td {{product.attributes_simple.color_description}}
							tr
								td {{'eShop.product.table.LENS_CODE'|translate}}
								td {{product.attributes_simple.lens_code}}
							tr
								td {{'eShop.product.table.LENS_NOTE'|translate}}
								td {{product.attributes_simple.lens_note}}
							tr
								td {{'eShop.product.table.LENS_DESCRIPTION'|translate}}
								td {{product.attributes_simple.lens_description}}
							tr
								td Typ barwienia
								td
							tr
								td Własciwości soczewki
								td
							tr
								td Rodzaj montażu
								td
							tr
								td {{'eShop.product.table.LENS_BASE'|translate}}
								td {{product.attributes_simple.lens_base}}
							tr
								td {{'eShop.product.table.LENS_BASE_DESCRIPTION'|translate}}
								td {{product.attributes_simple.lens_base_description}}
							tr
								td {{'eShop.product.table.LENS_POLARIZED'|translate}}
								td {{product.attributes_simple.lens_polarized}}
							tr
								td {{'eShop.product.table.LENS_MATERIAL_CODE_DESCRIPTION'|translate}}
								td {{product.attributes_simple.lens_material_code_description}}
							tr
								td {{'eShop.product.table.LENS_MATERIAL_CODE'|translate}}
								td {{product.attributes_simple.lens_material_code}}
							tr
								td {{'eShop.product.table.FILTER_CODE'|translate}}
								td {{product.attributes_simple.filter_code}}
							tr
								td {{'eShop.product.table.UV_FILTER'|translate}}
								td {{product.attributes_simple.uv_filter}}
							tr
								td {{'eShop.product.table.EYES_SIZE'|translate}}
								td {{product.attributes_simple.eyes_size}}
							tr
								td {{'eShop.product.table.BRIDGE_SIZE'|translate}}
								td {{product.attributes_simple.bridge_size}}
							tr
								td {{'eShop.product.table.TEMPLE_LENGTH'|translate}}
								td {{product.attributes_simple.temple_length}}
							tr
								td {{'eShop.product.table.GENDER'|translate}}
								td {{product.attributes_simple.gender}}
							tr
								td {{'eShop.product.table.MAIN_MATERIAL'|translate}}
								td {{product.attributes_simple.main_material}}
							tr
								td {{'eShop.product.table.MAIN_MATERIAL_DESCRIPTION'|translate}}
								td {{product.attributes_simple.main_material_description}}
							tr
								td {{'eShop.product.table.INDICATOR_OF_MASK'|translate}}
								td {{product.attributes_simple.indicator_of_mask}}
							tr
								td {{'eShop.product.table.HINGE_TYPE_DESCRIPTION'|translate}}
								td {{product.attributes_simple.hinge_type_description}}
							tr
								td {{'eShop.product.table.EYE_SHARP_DESCRIPTION'|translate}}
								td {{product.attributes_simple.eye_shape_description}}
							tr
								td {{'eShop.product.table.BRIDGE_OR_PAD_DESCRIPTION'|translate}}
								td {{product.attributes_simple.bridge_or_pad_description}}
							tr
								td {{'eShop.product.table.CURRENT_CASE_CODE'|translate}}
								td {{product.attributes_simple.current_case_code}}
							tr
								td {{'eShop.product.table.CURRENT_CASE_CODE_DESCRIPTION'|translate}}
								td {{product.attributes_simple.current_case_description}}
							tr
								td {{'catalog.product.eshop.START_DATE'|translate}}
								td {{product.attributes_simple.start_date}}
</template>

<script>
import {API} from '@/js/app/vue/api'
import OptiButton from '../Button/OptiButton'

export default {
	name: 'OptiEshopProductDetail', components: {
		OptiButton
	}, data() {
		return {
			prefix: {
				gender: 'eShop.attributes.gender.',
				material: 'eShop.attributes.material.',
				shape: 'eShop.attributes.shape.'
			},

			product: null, previous: {}, active: true,

			department_id: this.$state.params.departmentId && this.$state.params.departmentId !== '49' ? parseInt(this.$state.params.departmentId) : this.$uac.user.activeDepartmentId,
		}
	}, mounted() {
		this.startUp()
	}, methods: {
		startUp() {

			API.get('shopware/product/' + this.$state.params.id).then(res => {
				this.product = res.data
			}).catch(() => {
				this.$notify.error('something went wrong')
			})
		}
	}
}
</script>

<style scoped>

</style>