<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-show="!loading")
			form.form-horizontal.form-validate(name="formReclamationService" @submit.prevent="addReclamationService")
				.form-group
					label.col-sm-4.control-label.star-required(for="name") {{'dictionary.reclamationservice.table.NAME'|translate}}
					.col-sm-8
						input#name.form-control(:placeholder="'dictionary.reclamationservice.table.NAME'|translate"
							v-model="reclamationservice.name" name="name")
						OptiValidate(f="name" :data="$v.reclamationservice.name")
				.form-group
					label.col-sm-4.control-label(for="type") {{'dictionary.reclamationservice.table.TYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#type(enum="ReclamationServiceType"
							:value="reclamationservice.type" @update-enum="v => reclamationservice.type = v")
				.form-group
					label.col-sm-4.control-label(for="reclamation_type") {{'dictionary.reclamationservice.table.RECLAMATIONTYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#reclamationtype(enum="ReclamationServiceReclamationType"
							:value="reclamationservice.reclamation_type" @update-enum="v => reclamationservice.reclamation_type = v")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.dictionary.reclamationservices.table')"
						) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.reclamationservice.$invalid")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'
	import {required} from 'vuelidate/lib/validators'

	import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
	import OptiValidate from '../../OptiValidate'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiButton from '../../Button/OptiButton'
	import OptiDimmer from '../../Blocks/OptiDimmer'

	export default {
		name: 'OptiDictionaryReclamationServicesAdd',
		components: {
			OptiSelectEnum,
			OptiValidate,
			OptiButtonSave,
			OptiButton,
			OptiDimmer
		},
		data() {
			return {
				reclamationservice: {
					name: '',
					type: 0,
					reclamation_type: 0
				},
				loading: false
			}
		},
		validations: {
			reclamationservice: {
				name: {
					required
				}
			}
		},
		methods: {
			addReclamationService() {
				this.loading = true

				API.post('dictionary/reclamation-service/action', this.reclamationservice).then(() => {
					this.$notify.success('dictionary.reclamationservice.notify.SAVE')

					this.$state.go('app.dictionary.reclamationservices.table')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('dictionary.reclamationservice.notify.error.SAVE')
				})
			}
		}
	}
</script>
