<template lang="pug">
    .input-group(style="width:100%")
        OptiSelectCountry(
            :country="country"

            @selected="c => $emit('country', c)")
        .input-group-addon
            span(v-if="country.phoneCode") (+{{country.phoneCode}})
        input.form-control.phoneNumber(:placeholder="'patient.CELLPHONENUMBER'|translate" name="phone"
            :value="phone"

            @input="$emit('phone', $event.target.value)")
</template>

<script>
    import OptiSelectCountry from '@/js/app/vue/components/Select/OptiSelectCountry'

    export default {
        name: "OptiCellPhoneInput",
        components: {
            OptiSelectCountry
        },
        props: {
            country: {
                type: Object
            },
            phone: ''
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .multiselect {
        float: left;

        .multiselect__tags {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .phoneNumber {
        height: 40px;
    }
</style>