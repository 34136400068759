<template lang="pug">
    span
        a(style="text-decoration:none" v-if="main === 'task-icon' && sub === 'sc'"
            :href="$state.href('app.task.detail', {id: scId})")
            span(:class="[main, sub]" :title="title|translate|lower")
            span(v-if="desc") {{title|translate}}
        span(v-else)
            span(:class="[main, sub]" :title="title|translate|lower")
            span(v-show="desc") {{title|translate}}
</template>

<script>
    export default {
        name: 'OptiTaskIcon',
        props: {
            scId: {
                type: Number,
                required: false,
                default: 0
            },
            sun: {
                type: Boolean,
                required: false,
                default: false
            },
            name: {
                type: String,
                required: true
            },
            desc: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                main: '',
                sub: '',
                title: ''
            }
        },
        mounted() {
            switch(this.name) {
                case 'putBack':
                    this.main  = 'task-icon'
                    this.sub   = 'put-back'
                    this.title = 'tasks.attributes.PUTBACK'
                    break
                case 'deadline':
                    this.main  = 'task-icon'
                    this.sub   = 'deadline'
                    this.title = 'tasks.attributes.DEADLINE'
                    break
                case 'printed':
                    this.main  = 'task-icon'
                    this.sub   = 'printed'
                    this.title = 'tasks.attributes.PRINTED'
                    break
                case 'orderByAdmin':
                    this.main  = 'fa'
                    this.sub   = 'fa-user fa-lg'
                    this.title = 'tasks.attributes.ORDER_BY_ADMIN'
                    break
                case 'greenPutBack':
                    this.main  = 'task-icon'
                    this.sub   = 'green-put-back'
                    this.title = 'tasks.attributes.GREEN_PUT_BACK'
                    break
                case 'measurementsOk':
                    this.main  = 'task-icon'
                    this.sub   = 'ok'
                    this.title = 'tasks.attributes.MEASURMENTOK'
                    break
                case 'measurementsNotOk':
                    this.main  = 'task-icon'
                    this.sub   = 'not-ok'
                    this.title = 'tasks.attributes.MEASURMENTNOTOK'
                    break
                case 'taskDuplicate':
                    this.main  = 'task-icon'
                    this.sub   = 'duplicate'
                    this.title = 'tasks.attributes.DUPLICATE'
                    break
                case 'forSc':
                    this.main  = 'task-icon'
                    this.sub   = 'sc'
                    this.title = 'tasks.attributes.SERVICECONTRACT'
                    break
                case 'service':
                    this.main  = 'fa'
                    this.sub   = 'fa-recycle fa-lg text-success'
                    this.title = 'tasks.attributes.SERVICE'
                    break
                //case 'specification':
                //    this.main = 'task-icon';
                //    this.sub = 'specification';
                //    this.title = 'tasks.attributes.SPECIFICATION';
                //    break;


                case 'sc':
                    this.main  = 'task-icon-letter'
                    this.sub   = 'service-contract'
                    this.title = 'tasks.attributes.SERVICECONTRACT'
                    break
                case 'pakiet':
                    this.main  = 'task-icon-letter'
                    this.sub   = 'pakiet'
                    this.title = 'tasks.attributes.PAKIET'
                    break

                case 'notes':
                    this.main  = 'fa'
                    this.sub   = 'fa-file text-warning'
                    this.title = 'notes.LEFT'
                    break

                case 'simple':
                    this.main  = 'task-icon-letter'
                    this.sub   = 'simple'
                    this.title = 'tasks.attributes.SIMPLE'
                    break

                case 'satisfaction':
                    this.main = 'task-icon'
                    this.sub = 'guarantee'
                    this.title = 'tasks.SATISFACTION'
                    break

                case 'sun':
                    this.main = 'fa'
                    this.sub = 'fa-sun-o text-warning'
                    this.title = 'tasks.SUN'
            }
        }
    }
</script>
