<template lang="pug">
    #graph-search-lenses
        i.fa.fa-3x.fa-close(:title="'default.CLOSE'|translate" @click="$emit('close')")

        .form-inline
            .form-group
                label.col-xs-12.control-label(for="search-lenses-input") {{'catalog.graph.FIND'|translate}}
                .col-xs-12
                    OptiSelectGraphLenses#search-lenses-input(
                        :data="data"
                        :navi-direction="naviDirection"

                        @selected="s => $emit('selected', s)")
</template>

<script>
    import OptiSelectGraphLenses from '@/js/app/vue/components/Select/OptiSelectGraphLenses'

    export default {
        name: 'OptiCatalogGraphSearchLens',
        components: {
            OptiSelectGraphLenses
        },
        props: {
            data: {
                type: Array,
                required: true
            },
            naviDirection: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    #graph-search-lenses {
        @padding-top: 20px;

        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        background-color: rgba(0, 0, 0, .8);

        > i.fa.fa-close {
            position: absolute;
            top: 5px;
            right: 0;
            margin-right: 20px;
            cursor: pointer;
            color: #fff;
        }

        > .form-inline {
            width: 1000px;
            margin-bottom: 200px;

            > .form-group > label {
                color: #fff;
            }
        }
    }
</style>