<template lang="pug">
    .editor
        .panel.panel-default
            .panel-body
                OptiPatientVisitsRefuse(v-if="!loading && visit.patient.id"
                    :patient-id="visit.patient.id" :visit-id="visit.id")
                .schedule-day-painter
                    OptiDimmer(:active="loading")
                    .overflower.text-left(v-if="!loading")
                        .col-sm-6
                            .row.bg-hover(style="padding:10px 0")
                                .col-xs-12 {{'patient.schedule.patients.PATIENT'|translate}}
                                .col-xs-12(style="display:flex;justify-content:space-between" v-show="visit.patient.id")
                                    .btn.btn-default(:disabled="!visit.patient_prev" @click="goPatientPrev()")
                                        i.fa.fa-arrow-left
                                    OptiPatient(style="padding-top:6px" :data="visit.patient")
                                    .btn.btn-default(:disabled="!visit.patient_next" @click="goPatientNext()")
                                        i.fa.fa-arrow-right
                                .col-xs-12(style="display:flex;justify-content:space-between" v-show="!visit.patient.id")
                                    .btn.btn-default(:disabled="!visit.patient_prev" @click="goPatientPrev()")
                                        i.fa.fa-arrow-left
                                    div(style="padding-top:6px") {{visit.patient_first_name}} {{visit.patient_last_name}}
                                    .btn.btn-default(:disabled="!visit.patient_next" @click="goPatientNext()")
                                        i.fa.fa-arrow-right
                            .row(style="padding:10px 0")
                                .col-xs-12 {{'patient.schedule.patients.PHONE'|translate}}
                                .col-xs-12(v-show="visit.patient.id && visit.patient.cellPhoneNumber") {{visit.patient.cellPhoneNumber|number(0)}}
                                .col-xs-12(v-show="!visit.patient.id && visit.patient_phone") {{visit.patient_phone|number(0)}}
                                .col-xs-12.warn(v-show="!visit.patient.cellPhoneNumber && !visit.patient_phone") {{'patient.schedule.patients.NONE'|translate}}
                            .row.bg-hover(style="padding:19px 0" v-show="visit.patient.id")
                                .col-xs-12 {{'patient.schedule.patients.ADDRESS'|translate}}
                                .col-xs-12 {{visit.patient.street}}, {{visit.patient.zipCode}} {{visit.patient.city}}
                        .col-sm-6
                            .row.bg-hover(style="padding:16px 0")
                                .col-xs-12 {{'patient.schedule.patients.OPTOMETRIST'|translate}}
                                .col-xs-12
                                    a.link(:href="$state.href('app.user.edit', { id: visit.user.id })" target="_blank") {{visit.user.name}}
                            .row(style="padding:10px 0")
                                .col-xs-12 {{'patient.schedule.patients.TYPE'|translate}}
                                .col-xs-12 {{visit.type_name|translate}}
                            .row.bg-hover(style="padding:13px 0")
                                .col-xs-12 {{'patient.schedule.patients.DATE'|translate}}
                                .col-xs-12(style="display:flex;justify-content:space-between")
                                    .btn.btn-default(:disabled="disabled.time.prev" v-show="isFuture" @click="goTimePrev()")
                                        i.fa.fa-arrow-left
                                    div(style="padding-top:6px") {{date}}
                                    .btn.btn-default(:disabled="disabled.time.next" v-show="isFuture" @click="goTimeNext()")
                                        i.fa.fa-arrow-right

                        .col-xs-12(v-show="visit.comments")
                            .row
                                .col-xs-12 {{'patient.schedule.patients.COMMENTS'|translate}}
                                .col-xs-12(v-html="$options.filters.nl2br(visit.comments)")

                        .col-xs-12.text-center.text-success(v-show="isFuture && !visit.interview_id && visit.confirmed")
                            i.fa.fa-thumbs-up(style="padding-right:3px")
                            span {{'patient.schedule.notify.status.CONFIRMED'|translate}}

                        .col-xs-12.text-center.warn(v-show="isFuture && !visit.interview_id && !visit.confirmed")
                            i.fa.fa-thumbs-down(style="padding-right:3px")
                            span {{'patient.schedule.notify.status.UNCONFIRMED'|translate}}

                        .col-xs-12.text-center.text-success(v-show="visit.interview_id")
                            i.fa.fa-check-circle(style="padding-right:3px")
                            span {{'patient.schedule.notify.status.INTERVIEW'|translate}}

                        .col-xs-12.text-center.warn(v-show="!isFuture && !visit.interview_id && !officeType")
                            i.fa.fa-exclamation-circle(style="padding-right:3px")
                            span {{'patient.schedule.notify.status.NOINTERVIEW'|translate}}
                            span(style="padding-left:3px" v-show="visit.confirmed") ({{'patient.schedule.notify.status.nointerview.CONFIRMED'|translate}})
                            span(style="padding-left:3px" v-show="!visit.confirmed") ({{'patient.schedule.notify.status.nointerview.UNCONFIRMED'|translate}})

            .panel-footer.text-right
                OptiButton.pull-left(v-show="visit.interview_id" @click.native="goToInterview()") {{'patient.schedule.patients.actions.GOTOINTERVIEW'|translate}} ({{visit.interview_id}})
                    template(#icon)
                        i.fa.fa-eye

                OptiButton.pull-left(type="success" v-show="isFuture && visit.patient.id && visit.confirmed && !visit.interview_id && !officeType"
                    :disabled="!visit.patient.cellPhoneNumber" text="patient.schedule.patients.actions.interview" @confirmed="createInterview"
                    ) {{'patient.schedule.patients.actions.interview.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-comments-o

                OptiButton.pull-left(style="margin-right:30px" v-show="!visit.patient.id" @click.native="createPatient()") {{'patient.schedule.patients.actions.CREATE'|translate}}
                    template(#icon)
                        i.fa.fa-user

                OptiButton.pull-left(type="success" v-show="isFuture && !visit.confirmed && !visit.interview_id"
                    text="patient.schedule.patients.actions.confirm" @confirmed="goConfirm") {{'patient.schedule.patients.actions.confirm.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-thumbs-up

                OptiButton(style="margin-right:30px" v-show="isFuture && !visit.interview_id"
                    @click.native="changeToEdit()") {{'patient.schedule.patients.actions.EDIT'|translate}}
                    template(#icon)
                        i.fa.fa-edit

                OptiButton(@click.native="close()") {{'patient.schedule.patients.actions.CANCEL'|translate}}
                    template(#icon)
                        i.fa.fa-remove
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiPatientVisitsRefuse from '@/js/app/vue/components/Patients/OptiPatientVisitsRefuse'

    const CLIPBOARD = 'schedule-patients-clipboard'

    export default {
        name: 'OptiPatientsScheduleDayDetails',
        components: {
            OptiPatientVisitsRefuse,
            OptiDimmer,
            OptiPatient,
            OptiButton
        },
        props: {
            officeType: {
                type: Number,
                required: true
            },
            visit: {
                type: Object,
                required: true
            },
            optometrists: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                date: '',
                isFuture: false,

                disabled: {
                    time: {
                        prev: this.disabledTimePrev(),
                        next: this.disabledTimeNext()
                    }
                },

                loading: false
            }
        },
        watch: {
            visit() {
                this.init()
            }
        },
        mounted() {
            this.init()

            window.addEventListener('keydown', this.keyPress)
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyPress)
        },
        methods: {
            init() {
                this.parseDate()

                this.disabled.time.prev = this.disabledTimePrev()
                this.disabled.time.next = this.disabledTimeNext()

                this.markLoading()
            },
            markLoading() {
                this.loading = true

                setTimeout(() => {
                    this.loading = false
                }, 300)
            },
            getTime(minutes) {
                switch(minutes) {
                    case 15:
                        return '15min'
                    case 30:
                        return '30min'
                    case 45:
                        return '45min'
                    case 60:
                        return '1h'
                    case 90:
                        return '1,5h'
                    case 120:
                        return '2h'
                }

                return ''
            },
            parseDate() {
                let start = moment(this.visit.start),
                    end = moment(this.visit.end)

                this.isFuture = start.isSameOrAfter(moment(), 'd')

                this.date = start.format('YYYY-MM-DD HH:mm') + ' - ' + end.format('HH:mm') +
                    ' (' + this.getTime(moment.duration(end.diff(start)).asMinutes()) + ')'
            },

            createInterview() {
                this.$state.go('app.optometrist.create', {
                    id: this.visit.id
                })
            },
            createPatient() {
                this.$state.go('app.patient.find', {
                    visit: {
                        id: this.visit.id,
                        first_name: this.visit.patient_first_name,
                        last_name: this.visit.patient_last_name,
                        phone: this.visit.patient_phone
                    }
                })
            },

            goConfirm() {
                this.loading = true

                API.post('patients/schedule/calendar/confirm', {
                    id : this.visit.id
                }).then(res => {
                    this.$emit('update-visits', res.data)

                    this.$notify.success('patient.schedule.notify.CONFIRM')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.CONFIRM')
                })
            },

            goToInterview() {
                this.$state.go('app.optometrist.details', {
                    id: this.visit.interview_id,
                    '#': this.visit.type ? 'lens' : 'summary'
                })
            },
            changeToEdit() {
                this.$emit('change-to-edit')
            },
            close() {
                this.$emit('closed')
            },

            goPatientPrev() {
                this.$emit('navi-patient', this.visit.patient_prev)
            },
            goPatientNext() {
                this.$emit('navi-patient', this.visit.patient_next)
            },

            checkPrevNextOptometrists(date) {
                let exists = false

                this.optometrists.forEach(optometrist => {
                    for(let i = 0; i < optometrist.data.length; ++i) {
                        let part = optometrist.data[i]

                        if(typeof part.type !== 'undefined' && date.isBetween(part.start, part.end, null, '[)')) {
                            exists = true

                            break
                        }
                    }
                })

                return exists
            },
            disabledTimePrev() {
                let date = moment(this.visit.start)

                if(date.hours() === 6 && !date.minutes()) {
                    return true
                }

                return !this.checkPrevNextOptometrists(date.add((this.officeType ? 15 : 30) * -1, 'm'))
            },
            disabledTimeNext() {
                let date = moment(this.visit.end)

                if(!date.hours() && !date.minutes()) {
                    return true
                }

                return !this.checkPrevNextOptometrists(date)
            },
            goTimePrev() {
                this.$emit('navi-time', -1)
            },
            goTimeNext() {
                this.$emit('navi-time', 1)
            },
            keyPress(event) {
                if(event.key === 'ArrowLeft') {
                    if(this.isFuture && !this.disabledTimePrev()) {
                        this.goTimePrev()
                    } else if(this.visit.patient_prev) {
                        this.goPatientPrev()
                    }
                } else if(event.key === 'ArrowRight') {
                    if(this.isFuture && !this.disabledTimeNext()) {
                        this.goTimeNext()
                    } else if(this.visit.patient_next) {
                        this.goPatientNext()
                    }
                }
            }
        }
    }
</script>