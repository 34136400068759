<template lang="pug">
    .optometrist-clock
        .optometrist-clock-image
            img(src="img/optometrist/screening-test/optotype/clock.png")
            button.btn.btn-xl.btn-default(type="button"
                v-for="btn in buttons"
                :class="getBtnClass(btn)"
                @click="toggleBtn(btn)"
                :disabled="!isAllowedForSelectedGroups(btn)") {{btn.name}}
            span.clock-value {{axis}}
            .clock-arm(v-show="axis !== 'brak'" :style="armStyle")
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    export default {
        name: 'OptiOptometristsSurveysOptotypesClock',
        components: {},
        props: {
            axis: {
                type: Number | String,
                required: true
            }
        },
        data() {
            return {
                selected: [],
                buttons: [{
                        name: '1',
                        group: 1,
                        value: 30,
                        angle: 300
                    }, {
                        name: '2',
                        group: 2,
                        value: 60,
                        angle: 330
                    }, {
                        name: '3',
                        group: 3,
                        value: 90,
                        angle: 360
                    }, {
                        name: '4',
                        group: 4,
                        value: 120,
                        angle: 30
                    }, {
                        name: '5',
                        group: 5,
                        value: 150,
                        angle: 60
                    }, {
                        name: '6',
                        group: 6,
                        value: 180,
                        angle: 90
                    }, {
                        name: '7',
                        group: 1,
                        value: 210,
                        angle: 120
                    }, {
                        name: '8',
                        group: 2,
                        value: 240,
                        angle: 150
                    }, {
                        name: '9',
                        group: 3,
                        value: 270,
                        angle: 180
                    }, {
                        name: '10',
                        group: 4,
                        value: 300,
                        angle: 210
                    }, {
                        name: '11',
                        group: 5,
                        value: 330,
                        angle: 240
                    }, {
                        name: '12',
                        group: 6,
                        value: 360,
                        angle: 270
                    }],
                allowedForGroup: {
                    1: [6, 1, 2],
                    2: [1, 2, 3],
                    3: [2, 3, 4],
                    4: [3, 4, 5],
                    5: [4, 5, 6],
                    6: [5, 6, 1]
                }
            }
        },

        activated() {
            this.selected = []

            OptometristsDisplay.message.open('/optometrist/screen/clock')
        },
        methods: {
            isSelected(button) {
                return this.selected.includes(button.group)
            },
            getBtnClass(button) {
                return 'clock-' + button.angle + 'deg' + (this.isSelected(button) ? ' btn-success active' : '')
            },
            isAllowedForSelectedGroups(button) {
                let allowed = false

                if(this.selected.length === 3) {
                    let inGroup = this.selected.includes(button.group)
                    allowed = true

                    this.selected.forEach(g => {
                        if(!this.allowedForGroup[g].includes(button.group)) {
                            allowed = false
                        }
                    })

                    return inGroup && !allowed
                }

                this.selected.forEach(g => {
                    if(this.allowedForGroup[g].includes(button.group)) {
                        allowed = true
                    }
                })

                return allowed || !this.selected.length
            },
            computeAxis() {
                switch(this.selected.length) {
                    case 1: return this.selected[0] * 30
                    case 2:
                        // musza byc kolo siebie wiec dodajemy 15deg - czyli tyle ile powinno byc do nastepnej godziny
                        let first = this.selected[0] * 30,
                            second = this.selected[1] * 30

                        return first === 30 && second === 180 ? first - 15 : first + 15
                    case 3:
                        // przy trzech bierzemy ten ktory jest domyślnie między nimi wyłączony
                        for(let i = 0; i < this.selected.length; i++) {
                            if(!this.isAllowedForSelectedGroups({
                                group: this.selected[i]
                            })) {
                                return this.selected[i] * 30
                            }
                        }
                }

                return 'brak'
            },
            toggleBtn(btn) {
                let idx = this.selected.indexOf(btn.group)

                if(idx > -1) {
                    this.selected.splice(idx, 1)
                } else {
                    this.selected.push(btn.group)
                }
                this.selected.sort()

                this.$emit('update-axis', this.computeAxis())
            }
        },
        computed: {
            armStyle() {
                return {'transform': 'rotate(90deg) rotate(' + this.axis + 'deg)'}
            }
        }
    }
</script>