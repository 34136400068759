<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:15px" v-if="!loading")
            OptiButtonHistory(data="role" :id="role.id")

        .col-md-10.col-md-offset-1.col-xs-12(v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-key.mr3
                    span {{role.name}}

                .row
                    .col-xs-12
                        ul.permissions.main(v-for="(items, key) in role.permissions.items"
                            :class="{'bg-separator': key % 2}")
                            OptiRoleAssignList(:items="items")

        .col-md-10.col-md-offset-1.col-xs-12.text-right(style="margin-bottom:15px" v-if="!loading && role.id !== 1")
            OptiButtonSave(@click.native="save")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiRoleAssignList from '@/js/app/vue/components/Users/Roles/Assign/OptiRoleAssignList'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRoleAssign',
        components: {
            OptiButtonHistory,
            OptiRoleAssignList,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                role: {},

                loading: true
            }
        },
        mounted() {
            API.get('role/' + this.$state.params.id).then(res => {
                this.role = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('role.notify.error.LOADPERMISSION')
            });
        },
        methods: {
            save() {
                this.loading = true

                API.put('role/permissions', this.role).then(() => {
                    this.$notify.success('role.notify.ASSIGNED')

                    if(this.role.id === this.$uac.user.activeRole.id) {
                        this.$uac.relogin(this.$uac.user.id)
                    } else {
                        this.$state.go('app.roles.list')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('role.notify.error.ASSIGNED')
                })
            }
        }
    }
</script>