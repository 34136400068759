<template lang="pug">
    div(:class="{'table-responsive': previouslens.dist}")
        table.table.table-striped.text-center.middle
            thead
                tr
                    th(style="border-top:1px solid #aaa" colspan="5") {{'optometrist.acuity.CONTACTLENS'|translate}}
                    th(style="border-top:1px solid #aaa;text-align:right" colspan="4")
                        div(v-show="acuityAxisContactLensExists")
                            OptiButton(type="success" v-show="activeCylContactLens === 'plus'"
                                @click.native="$emit('update-cyl-contact-lens', 'minus')") {{'optometrist.cylinder.MINUS'|translate}}
                                template(#icon)
                                    i.fa.fa-minus
                            OptiButton(type="success" v-show="activeCylContactLens === 'minus'"
                                @click.native="$emit('update-cyl-contact-lens', 'plus')") {{'optometrist.cylinder.PLUS'|translate}}
                                template(#icon)
                                    i.fa.fa-plus
            tbody
                tr(v-show="previouslens.contactlens")
                    th
                    td {{'previouslens.contactlens.NAME'|translate}}
                    td SPH
                    td CYL
                    td(v-show="acuityAxisContactLensExists") AXS
                    td(v-show="acuityAddContactLensExists") Add
                    td Visus
                    td C/Z
                    td BinVisus

                tr(v-show="!previouslens.contactlens")
                    td.text-center.warn.bold(colspan="8") {{'optometrist.correction.NORESULT'|translate}}
                tr(v-show="previouslens.contactlens")
                    th
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.r" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                    :interview="interview"
                                    :data="survey"
                                    side="right"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.r = false")
                    td(style="text-align:left") {{interview.contactLensCorrection.r_name}}
                    td
                        span.form-control(disabled) {{correction.contactlens[activeCylContactLens].r.sph|plus}}
                    td
                        span.form-control(disabled) {{correction.contactlens[activeCylContactLens].r.cyl|plus}}
                    td(v-show="acuityAxisContactLensExists")
                        span.form-control(disabled v-show="correction.contactlens[activeCylContactLens].r.cyl") {{correction.contactlens[activeCylContactLens].r.axs}}
                    td(v-show="acuityAddContactLensExists")
                        span.form-control(disabled) {{interview.contactLensCorrection.r_add|number(2)}}
                    td
                        span.form-control(disabled) {{interview.contactLensCorrection.dist_r_visus}}
                    td
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.contactLensCorrection.dist_r_cz"
                            :disabled="true")
                    td(rowspan="2")
                        span.form-control(disabled) {{interview.contactLensCorrection.dist_binvisus}}
                tr(v-show="previouslens.contactlens")
                    th
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.l = true") {{'prescription.LEFT'|translate}}

                            MountingPortal(v-if="dialog.l" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                    :interview="interview"
                                    :data="survey"
                                    side="left"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.l = false")
                    td(style="text-align:left") {{interview.contactLensCorrection.l_name}}
                    td
                        span.form-control(disabled) {{correction.contactlens[activeCylContactLens].l.sph|plus}}
                    td
                        span.form-control(disabled) {{correction.contactlens[activeCylContactLens].l.cyl|plus}}
                    td(v-show="acuityAxisContactLensExists")
                        span.form-control(disabled v-show="correction.contactlens[activeCylContactLens].l.cyl") {{correction.contactlens[activeCylContactLens].l.axs}}
                    td(v-show="acuityAddContactLensExists")
                        span.form-control(disabled) {{interview.contactLensCorrection.l_add|number(2)}}
                    td
                        span.form-control(disabled) {{interview.contactLensCorrection.dist_l_visus}}
                    td
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.contactLensCorrection.dist_l_cz"
                            :disabled="true")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection'

    export default {
        name: 'OptiOptometristsDetailsRefractionFarContactLens',
        components: {
            OptiNumber,
            OptiNumberPrescription,
            OptiButton,
            OptiEnum,
            OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection,
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },

            correction: {
                type: Object,
                required: true
            },
            activeCylContactLens: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    r: false,
                    l: false
                }
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                  this.dialog.r = false
                  this.dialog.l = false

                this.$emit('update-interview', interview)
            }
        },
        computed: {
            survey() {
                return {
                    type: 'acuity_with_correction_contact_lens',
                    info: {
                        r: {
                            sph: this.interview.contactLensCorrection.r_sph,
                            cyl: this.interview.contactLensCorrection.r_cyl,
                            axs: this.interview.contactLensCorrection.r_axs,
                            visus: this.interview.dist_nc_r_visus,
                        },
                        l: {
                            sph: this.interview.contactLensCorrection.l_sph,
                            cyl: this.interview.contactLensCorrection.l_cyl,
                            axs: this.interview.contactLensCorrection.l_axs,
                            visus: this.interview.dist_nc_l_visus,
                        },
                        binVisus: this.interview.dist_nc_binvisus
                    },
                    save: {
                        r: {
                            cz: 'contactLensCorrection.dist_r_cz',
                            visus: 'contactLensCorrection.dist_r_visus'
                        },
                        l: {
                            cz: 'contactLensCorrection.dist_l_cz',
                            visus: 'contactLensCorrection.dist_l_visus'
                        },
                        binVisus: 'contactLensCorrection.dist_binvisus'
                    }
                }
            },

            acuityAxisContactLensExists() {
                return this.interview.contactLensCorrection.r_cyl || this.interview.contactLensCorrection.l_cyl
            },
            acuityAddContactLensExists() {
                return this.interview.contactLensCorrection.r_add || this.interview.contactLensCorrection.l_add
            },

            disabledSurvey() {
                return [0, 2, 3].includes(this.interview.status) || !this.$uac.permission('optometrist.edit')
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>