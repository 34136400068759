<template lang="pug">
    .row
        .col-xs-12
            form.form.form-horizontal(name="formNotes")
                .form-group
                    label.col-sm-5.control-label(for="note_type") {{'tasks.notes.form.TYPE'|translate}}
                    .col-sm-7
                        OptiSelectEnum#note_type(enum="TaskNoteStatus" :disabled="!permission" required
                            :value="form.type" @update-enum="t => form.type = t")
                        OptiValidate(f="note_type" :data="$v.form.type")

                .form-group(v-show="form.type === 'clens'")
                    label.col-sm-5.control-label {{'tasks.notes.form.POWERS'|translate}}
                    .col-sm-7.form-control-static.bold.warn(v-show="cLensPowers.text") {{cLensPowers.text|translate}}
                    .col-sm-7.form-control-static(v-show="cLensPowers.result") {{cLensPowers.result}}

                .form-group(v-show="form.type === 'bc'")
                    .col-sm-7.col-sm-offset-5
                        div(style="display:flex;align-items:center")
                            .label-bc
                                span BC
                                sub {{'prescription.RIGHT'|translate}}
                            div(style="width:100%")
                                OptiNumberPrescription(:header="'BC (' + $filters.translate('prescription.RIGHT') + ')'" :minus="false"
                                    :value="form.bc.r" @confirmed="bc => updateBC('r', bc)") {{form.bc.r|number(2)}}
                                OptiValidate(f="bc_r" :data="$v.form.bc.r")
                            .label-bc(style="margin-left:50px")
                                span BC
                                sub {{'prescription.LEFT'|translate}}
                            div(style="width:100%")
                                OptiNumberPrescription(:header="'BC (' + $filters.translate('prescription.LEFT') + ')'" :minus="false"
                                    :value="form.bc.l" @confirmed="bc => updateBC('l', bc)") {{form.bc.l|number(2)}}
                                OptiValidate(f="bc_l" :data="$v.form.bc.l")

                .form-group
                    label.col-xs-12(for="note") {{'tasks.notes.form.COMMENTS'|translate}}
                    .col-xs-12
                        textarea#note.form-control(rows="5" name="note" v-model="form.note"
                            :placeholder="'tasks.notes.form.PLACEHOLDER'|translate" required)
                        OptiValidate(f="note" :data="$v.form.note")

                .form-group
                    label.col-sm-5 {{'tasks.notes.form.PHOTOS'|translate}}
                    .col-sm-7
                        OptiButton(type="success" :disabled="!!form.images.filter(i => !i.image).length"
                            @click.native="form.images.push({image: ''})") {{'tasks.notes.form.ADDPHOTO'|translate}}
                            template(#icon)
                                i.fa.fa-plus
                        .text-center.text-muted(style="margin-top:5px" v-show="!form.images.length")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'tasks.notes.notify.NO_PHOTOS'|translate}}
                .form-group(v-for="(image, index) in $v.form.images.$each.$iter" :key="index")
                    .col-sm-7.col-sm-offset-5
                        OptiUpload(
                            :id="'task-note-image-' + index"

                            prefix="task_note_image"

                            :file="image.image.$model"

                            @update-loading="l => loading.images = l"
                            @update-file="i => image.image.$model = i")
                        OptiValidate(:f="'task-note-image-' + index" :data="image.image")
                        .text-center(style="margin-top:5px")
                            .btn.btn-danger(@click="form.images.splice(index, 1)")
                                i.fa.fa-trash

                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButtonSave(:disabled="!!($v.form.$invalid || (form.type === 'clens' && cLensPowers.text) || loading.images || loading.main)"
                            @click.native="addNote")

        .col-xs-12
            .list-group(v-if="notes.length")
                a.list-group-item(v-for="note in notes")
                    .media-box
                        p.m0.text-muted
                            small {{note.type_name|translate}}
                            small.pull-right
                                span.mr3 {{note.user.firstName}} {{note.user.lastName}}
                                span {{note.created_at}}
                                span.ml3(v-show="!isOffer")
                                    span.mr3 -
                                    span {{note.task_status_name|translate}}
                        p(style="white-space:normal" v-html="$options.filters.nl2br(note.text)")
                        p.m0.text-muted(v-show="note.uploads.length")
                            small {{'tasks.notes.form.PHOTOS'|translate}}
                        p(style="white-space:normal" v-if="note.uploads.length")
                            a(v-for="upload in note.uploads" :href="uploadHost + '/task_note/' + upload.upload" target="_blank")
                                img.img-rounded(style="width:30%;padding:5px" :src="uploadHost + '/task_note/' + upload.upload")

            .text-center.text-muted(style="margin-top:5px" v-show="!notes.length && !loading.main")
                i.fa.fa-exclamation-circle.mr3
                span {{'tasks.notes.notify.error.EMPTY'|translate}}

            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZeroIf} from '@/js/vue.validators.js'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsNotes',
        components: {
            OptiSelectEnum,
            OptiNumberPrescription,
            OptiUpload,
            OptiButton,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            }
        },
        validations() {
            return {
                form: {
                    type: {
                        required
                    },
                    bc: {
                        r: {
                            noZero: noZeroIf(this.form.type === 'bc')
                        },
                        l: {
                            noZero: noZeroIf(this.form.type === 'bc')
                        }
                    },
                    note: {
                        required: requiredIf(form => form.type !== 'bc')
                    },
                    images: {
                        $each: {
                            image: {
                                required
                            }
                        }
                    }
                }
            }
        },
        data() {
            return {
                form: {
                    type: !this.$uac.permission('tasks.notes') ? 'all' : null,
                    bc: {
                        r: 0,
                        l: 0
                    },
                    note: '',
                    images: []
                },
                cLensPowers: {},

                notes: [],

                uploadHost: window.__env.uploadHost,
                permission: this.$uac.permission('tasks.notes'),
                loading: {
                    images: false,
                    main: true
                }
            }
        },
        mounted() {
            this.cLensPowers = this.getCLensPowers()

            API.get('tasks/note/' + (this.isOffer ? 'offer' : 'task') + '/' + this.task.id).then(res => {
                this.notes = res.data

                this.$emit('update-notes-length', this.notes.length)
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('tasks.notes.notify.error.LOAD')
            })
        },
        methods: {
            getCLensPowers() {
                if(this.task.type !== 2) {
                    return {
                        text: 'tasks.notes.notify.lens.NOFAR'
                    }
                } else if(this.task.rightEye.cylinder < 0 || this.task.leftEye.cylinder < 0) {
                    return {
                        text: 'tasks.notes.notify.lens.MINUSCYL'
                    }
                }

                return {
                    result: 'PS: ' + this.getPower(this.task.rightEye.sphere, this.task.rightEye.cylinder) + '; ' +
                        'LS: ' + this.getPower(this.task.leftEye.sphere, this.task.leftEye.cylinder)
                }
            },
            getPower(sphere, cylinder) {
                if((cylinder * 10) % 5 !== 0) {
                    cylinder -= 0.25;
                }

                let val = (sphere + (cylinder / 2)).toFixed(2)

                return (val >= 0 ? '+' : '') + val.replace('.', ',')
            },
            addNote() {
                this.loading.main = true
                this.notes = []

                if(this.form.type === 'clens' && this.cLensPowers.result) {
                    this.form.note = '(Moce: ' + this.cLensPowers.result + ') ' + this.form.note
                }

                API.post('tasks/note/add', {
                    task_id: this.task.id,
                    is_offer: !!this.isOffer,
                    status: this.task.status,
                    type: this.form.type,
                    note: this.form.note,
                    images: this.form.images,
                    bc: this.form.bc
                }).then(res => {
                    this.notes = res.data

                    this.form = {
                        type: !this.$uac.permission('tasks.notes') ? 'all' : null,
                        bc: {
                            r: 0,
                            l: 0
                        },
                        note: '',
                        images: []
                    }

                    this.$emit('update-notes-length', this.notes.length)
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('tasks.notes.notify.error.SAVE')
                })
            },
            updateBC(side, bc) {
                this.form.bc[side] = bc

                let opposite = side === 'r' ? 'l' : 'r'

                if(!this.form.bc[opposite]) {
                    this.form.bc[opposite] = bc
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .label-bc {
        display: inline-block;
        font-weight: 700;
        width: 30px;
        padding-right: 10px;
    }
</style>