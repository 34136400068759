<template lang="pug">
    .row
        .col-md-6.col-md-offset-3(v-show="!loading && missing_days.length")
            .alert.alert-danger
                i.fa.fa-exclamation-circle.mr3
                span {{'entries.missing.HEADING'|translate}}

                ul
                    li(v-for="day in missing_days") {{day}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEntriesMissingDays',
        components: {
            OptiDimmer
        },
        data() {
            return {
                missing_days: [],

                loading: true
            }
        },
        mounted() {
            API.get('entries/missing-days', API.id(Math.random())).then(res => {
                this.missing_days = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('entries.missing.notify.error.LOAD')
            })
        }
     }
</script>