<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-show="!loading")
			form.form-horizontal.form-validate(name="formRegenerationType" @submit.prevent="addRegenerationType")
				.form-group
					label.col-sm-4.control-label.star-required(for="name") {{'dictionary.regenerationtype.NAME'|translate}}
					.col-sm-8
						input#name.form-control(:placeholder="'dictionary.regenerationtype.NAME'|translate"
							v-model="regenerationtype.name" name="name")
						OptiValidate(f="name" :data="$v.regenerationtype.name")
				.form-group
					label.col-sm-4.control-label(for="price_from_central") {{'dictionary.regenerationtype.price.CENTRAL'|translate}}
					.col-sm-8
						OptiNumber#price_from_central.form-control.text-right(
							:header="'dictionary.regenerationtype.price.CENTRAL'|translate"
							:value="regenerationtype.price_from_central"
							@confirmed="value => regenerationtype.price_from_central = value"
						) {{regenerationtype.price_from_central}}
				.form-group
					label.col-sm-4.control-label(for="price_from_specialist") {{'dictionary.regenerationtype.price.SPECIALIST'|translate}}
					.col-sm-8
						OptiNumber#price_from_specialist.form-control.text-right(
							:header="'dictionary.regenerationtype.price.SPECIALIST'|translate"
							:value="regenerationtype.price_from_specialist"
							@confirmed="value => regenerationtype.price_from_specialist = value"
						) {{regenerationtype.price_from_specialist}}
				.form-group
					label.col-sm-4.control-label(for="price_wholesale") {{'dictionary.regenerationtype.price.WHOLESALE'|translate}}
					.col-sm-8
						OptiNumber#price_wholesale.form-control.text-right(
							:header="'dictionary.regenerationtype.price.WHOLESALE'|translate"
							:value="regenerationtype.price_wholesale"
							@confirmed="value => regenerationtype.price_wholesale = value"
						) {{regenerationtype.price_wholesale}}
				.form-group
					label.col-sm-4.control-label.star-required {{'dictionary.specialist.breadcrumb.SPECIALISTS'|translate}}
					.col-sm-8
						div(v-for="specialist in specialists")
							.checkbox.c-checkbox.checkbox-primary.inline
								label
									input(type="checkbox" :id="'specialist' + specialist.id" v-model="specialist.selected")
									span.fa.fa-check
							label(:for="'specialist' + specialist.id") {{specialist.first_name}} {{specialist.last_name}}
								small(v-show="specialist.type === 1") ({{'dictionary.specialist.CENTRAL'|translate}})
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.dictionary.regenerationtypes.table')"
							) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.regenerationtype.$invalid || disableSave === true")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'
	import {required} from 'vuelidate/lib/validators'
	import {unique} from '../../../../../vue.validators'

	import OptiValidate from '../../OptiValidate'
	import OptiDimmer from '../../Blocks/OptiDimmer'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiButton from '../../Button/OptiButton'
	import OptiNumber from '../../Inputs/OptiNumber'

	export default {
		name: 'OptiDictionaryRegenerationTypeAdd',
		components: {
			OptiValidate,
			OptiDimmer,
			OptiButtonSave,
			OptiButton,
			OptiNumber
		},
		data() {
			return {
				regenerationtype: {
					name: '',
					price_from_central: 0,
					price_from_specialist: 0,
					price_wholesale: 0,
					specialists: [],
				},
				specialists: [],
				loading: false
			}
		},
		validations: {
			regenerationtype: {
				name: {
					required,
					unique: unique('regenerations_type', 'name')
				}
			}
		},
		mounted() {
			API.get('dictionary/specialist/action').then(res => {
				this.specialists = res.data

			}).finally(() => {
				this.loading = false
			}).catch(() => {
				this.$notify.error('regeneration.notify.error.SPECIALISTLIST')
			})
		},
		methods: {
			addRegenerationType() {
				this.loading = true

				this.specialists.forEach(specialist => {
					if(specialist.selected) {
						this.regenerationtype.specialists.push(specialist.id)
					}
				})

				API.post('dictionary/regeneration-type/action', this.regenerationtype).then(() => {
					this.$notify.success('dictionary.regenerationtype.notify.ADD')

					this.$state.go('app.dictionary.regenerationtypes.table')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('dictionary.regenerationtype.notify.error.ADD')
				})
			}
		},
		computed: {
			disableSave() {
				let value = true;

				this.specialists.forEach(specialist => {
					if (specialist.selected) {
						value = false;
					}
				})
				return value;
			}
		}
	}
</script>