<template lang="pug">
    div(:class="{'bg-separator': background}")
        .col-sm-6
            .line-padding
                .bold {{'eShop.address.SHIPMENT'|translate}}
                .form-control-static
                    div(v-show="data.first_name !== data.address_shipment_first_name || data.last_name !== data.address_shipment_last_name"
                        ) {{data.address_shipment_first_name}} {{data.address_shipment_last_name}}
                    div {{data.address_shipment_street}}
                    div {{data.address_shipment_zip_code}} {{data.address_shipment_city}}

        .col-sm-6
            .line-padding
                .bold {{'eShop.address.BILLING'|translate}}
                .form-control-static(v-if="difference")
                    div(v-show="data.first_name !== data.address_billing_first_name || data.last_name !== data.address_billing_last_name"
                        ) {{data.address_billing_first_name}} {{data.address_billing_last_name}}
                    div {{data.address_billing_street}}
                    div {{data.address_billing_zip_code}} {{data.address_billing_city}}
                    div(v-show="data.address_shipment_phone_number !== data.address_billing_phone_number") {{'eShop.address.PHONE'|translate}} {{data.address_billing_phone_number}}
                .form-control-static.text-muted(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span {{'eShop.address.EQUAL'|translate}}

        .clearfix
</template>

<script>
    export default {
        name: 'OptiEshopAddressPanel',
        props: {
            data: {
                type: Object,
                required: true
            },

            background: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            difference() {
                let _return = false,
                    address = {
                    types: ['shipment', 'billing'],
                    fields: ['first_name', 'last_name', 'sex', 'street', 'zip_code', 'city', 'phone_number']
                }

                address.fields.forEach(field => {
                    if(this.data['address_' + address.types[0] + '_' + field] !== this.data['address_' + address.types[1] + '_' + field]) {
                        _return = true
                    }
                })

                return _return
            }
        }
    }
</script>