<template lang="pug">
    span(v-show="action !== 'start'" :class="{'warn': scope === 0 || scope === 2}")
        span.mr3 {{diff|secondsToHuman}}
        i.fa.fa-sort-desc(v-show="scope === 0")
        i.fa.fa-sort-asc(v-show="scope === 2")
        i.fa.fa-sort(style="visibility:hidden" v-show="scope !== 0 && scope !== 2")
</template>

<script>
    export default {
        name: 'OptiOptometristsDetailsSurveyHistoryTime',
        components: {

        },
        props: {
            action: {
                type: String,
                required: true
            },
            scope: {
                type: Number,
                required: true
            },
            diff: {
                type: Number,
                required: true
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>