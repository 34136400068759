<template lang="pug">
    .row
        .col-md-8.col-md-offset-2
            form.form-horizontal
                .form-group
                    label.col-sm-5.control-label.star-required(for="type") {{'product.export.TYPE'|translate}}
                    .col-sm-7
                        OptiSelectEnum#type(enum="ProductType" :value="type" @update-enum="t => type = t")
                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButton(type="success" @click.native="goExport") {{'product.export.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-upload
</template>

<script>
    import {EnumProductType} from '@/js/app/vue/enums/Enums'
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiProductExport',
        components: {
            OptiSelectEnum,
            OptiButton
        },
        data() {
            return {
                type: 0
            }
        },
        methods: {
            goExport() {
                let filename = 'products/' + this.$filters.translate(EnumProductType.memberByValue(this.type).translation) + ' ' +
                    moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx'

                DownloadFileHelper.post('warehouse/products/export', filename, {
                    type: this.type
                })
            }
        }
     }
</script>