<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-book.mr3
            span {{'default.HISTORY'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .table-responsive
                table.table.table-presenter.table-striped
                    thead
                        tr
                            th #
                            th {{'default.STATUS'|translate}}
                            th {{'default.DESCRIPTION'|translate}}
                            th {{'default.DETAILS'|translate}}
                            th {{'default.USER'|translate}}
                            th {{'default.date.LABEL'|translate}}
                    tbody
                        tr(v-for="(log, key) in logs")
                            td.text-right {{logs.length - key}}
                            td {{log.status}}
                            td(v-html="$options.filters.nl2br(log.description)")
                            td(v-html="$options.filters.nl2br(log.details)")
                            td {{log.user}}
                            td {{log.date|noYearSek}}

                        tr(v-show="!logs.length")
                            td.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        template(#footer)
            OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiReclamationTaskDetailsDialogHistory',
        components: {
            OptiDialog,
            OptiButton: () => import('../../../Button/OptiButton')
        },
        props: {
            logs: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 100%;

        .modal-body {
            padding: 0;
        }
    }
</style>