<template lang="pug">
    form.form-horizontal(@submit.prevent="add")
        .form-group
            label.col-md-4.control-label.star-required(for="name") {{'catalog.edit.form.NAME'|translate}}
            .col-md-8
                input#name.form-control(v-model="selected.name")
                OptiValidate(f="name" :data="$v.selected.name")
        .form-group(v-show="type")
                label.col-md-4.control-label.star-required(for="short_name") {{'catalog.edit.form.SHORT'|translate}}
                .col-md-8
                    input#short_name.form-control(v-model="selected.short_name")
                    OptiValidate(f="short_name" :data="$v.selected.short_name")
        .form-group
            label.col-md-4.control-label.star-required(for="sort") {{'catalog.edit.form.SORT'|translate}}
            .col-md-8
                select#sort.form-control(v-model="selected.sort_id" @change="changedSort")
                    option(v-for="s in select_sort" :value="s.id") {{s.name|translate}}
                OptiValidate(f="sort" :data="$v.selected.sort_id")
        .form-group
            label.col-md-4.control-label.star-required(for="type") {{'catalog.edit.form.TYPE'|translate}}
            .col-md-8
                OptiSelectEnum#type.form-control(enum="CategoryType" :disabled="selected.sort_id === 1"
                    :value="selected.type_id" @update-enum="updateType")
                OptiValidate(f="type" :data="$v.selected.type_id")
        .form-group
            label.col-md-4.control-label(for="delivery_type") {{'catalog.edit.form.DELIVERY'|translate}}
            .col-md-8
                OptiSelectEnum#delivery_type.form-control(enum="CategoryDeliveryType"
                    :disabled="selected.sort_id === 1 || selected.type_id === 1"
                    :value="selected.delivery_type_id" @update-enum="e => selected.delivery_type_id = e")

        .form-group(v-if="!type")
            label.col-md-4.control-label(for="upload") {{'catalog.edit.form.LOGO'|translate}}
            .col-md-8
                OptiUpload#upload(
                    prefix="catalog"
                    :picture="true"

                    :file="logo"

                    @update-loading="l => loading.logo = l"
                    @update-file="f => logo = f")
        .form-group
            .col-md-8.col-md-offset-4
                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" name="lock" v-model="selected.isLocked" @change="$emit('lock-was-updated')")
                            span.fa.fa-check
                    span {{'catalog.edit.form.LOCK'|translate}}
        .form-group
            .col-md-8.col-sm-offset-4
                OptiButtonSave(:disabled="$v.selected.$invalid || loading.logo")

        OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogCategoryFormAdd',
        components: {
            OptiSelectEnum,
            OptiValidate,
            OptiUpload,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            type: {
                type: Boolean,
                required: true
            },
            selected: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                select_sort: [{
                    id: 0,
                    name: 'catalog.sort.FILE'
                }, {
                    id: 1,
                    name: 'catalog.sort.FOLDER'
                }],

                logo: null,

                loading: {
                    main: false,
                    logo: false
                }
            }
        },
        validations: {
            selected: {
                name: {
                    required
                },
                short_name: {
                    required: requiredIf(function() { return this.type }) // nie działa jako: () => this.type
                },
                sort_id: {
                    required
                },
                type_id: {
                    required
                }
            }
        },
        methods: {
            add() {
                this.loading.main = true

                this.selected.type = this.type
                this.selected.logo = this.logo

                API.post('catalog/add', this.selected).then(res => {
                    this.$notify.success('catalog.notify.ADD')

                    this.$emit('position-was-added', res.data)
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('catalog.notify.error.ADD')

                    this.$emit('fail')
                })
            },

            updateType(e) {
                this.selected.type_id = e

                if(this.selected.type_id === 1) {
                    this.selected.delivery_type_id = 0
                }
            },
            changedSort() {
                if(this.selected.sort_id === 1) {
                    this.selected.type_id = 0
                    this.selected.delivery_type_id = 0
                }
            }
        }
    }
</script>