import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class DownloadingHelperClass {
    saveInLogs(download_id) {
        API.get('download/logs/' + download_id).catch(() => {
            Notify.error('download.logs.notify.error.DOWNLOADED')
        })
    }

    openUri(download) {
        if(download.uri.includes('/')) {
            window.open(download.uri)
        } else {
            window.open(window.__env.uploadHost + '/download/' + download.uri)
        }

        this.saveInLogs(download.id)
    }
}

export const DownloadingHelper = new DownloadingHelperClass()