import actions from '@/js/app/vue/store/carts/actions'
import mutations from '@/js/app/vue/store/carts/mutations'
import getters from '@/js/app/vue/store/carts/getters'

export default {
    namespaced: true,

    state: {
        carts: {
            sale: {},
            order: {},
            sendback: {},
            active: [false, false, false]
        },

        loading: true,
        pending: false
    },

    actions: actions,
    mutations: mutations,
    getters: getters
}