<template lang="pug">
    .row
        .col-xs-12(v-for="(category, type) in categories")
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'dictionary.hoya.panel.header.LENS'|translate}} {{type}}

                .panel-body(style="padding:0")
                    table.table.table-responsive.middle
                        tbody
                            template(v-for="(lens, index) in category")
                                tr.main(:class="{'bg-separator': lens.selected, 'bold': lens.selected}" @click="lens.selected = !lens.selected")
                                    td
                                        span {{lens.name}}
                                        i.fa.pull-right(:class="{'fa-arrow-up': lens.selected, 'fa-arrow-down': !lens.selected}")
                                tr(v-if="lens.selected")
                                    td(style="padding:0")
                                        OptiHoyaPriceGroupEdit(
                                            :edit="edit"
                                            :is-sun-loox="type === 'SunLoox'"
                                            :category="lens")
</template>

<script>
    import OptiHoyaPriceGroupEdit from '@/js/app/vue/components/Dictionary/Hoya/OptiHoyaPriceGroupEdit'

    export default {
        name: 'HoyaLensesPriceEdit',
        components: {
            OptiHoyaPriceGroupEdit
        },
        props: {
            edit: {
                type: Boolean,
                required: true
            },
            categories: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    tr.main {
        &:hover {
            cursor: pointer;
            font-weight: 700;
        }

        > td > i {
            margin-top: 3px;
        }
    }
</style>