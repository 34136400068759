<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:10px")
            OptiButton(type="info" :class="{'active': view === 'attributes'}"
                @click.native="change_view('attributes')") {{'eShop.product.breadcrumb.PRODATTRIBUTES'|translate}}

            OptiButton(type="info" :class="{'active': view === 'color_lens'}"
                @click.native="change_view('color_lens')") {{'eShop.product.breadcrumb.PRODCOLOR_LENS'|translate}}

            .pull-right
                OptiButtonHistory(v-if="view === 'attributes'" endpoint="shopware/attributes/getShopwareAttributeHistory")

                OptiButtonHistory(v-else-if="view === 'color_lens'" endpoint="settings/offer/translations/colors/history/1")

        OptiEshopColors(:type="1" v-if="view === 'color_lens'")

        .col-xs-12(v-if="view === 'attributes'")
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'eShop.product.breadcrumb.PRODATTRIBUTES'|translate}}

                .text-right(style="margin:10px")
                    OptiButton(@click.native="add") {{'eShop.attributes.table.ADD_NEW_ATTRIBUTE'|translate}}
                        template(#icon)
                            i.fa.fa-plus

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th.text-left Id
                                th.text-left {{'eShop.attributes.table.NAME'|translate}}
                                th {{'eShop.attributes.table.TYPE'|translate}}
                                th.text-center {{'eShop.attributes.table.FILTER'|translate}}
                                th.text-center {{'eShop.attributes.table.PRODUCT'|translate}}
                                th.text-center {{'eShop.attributes.table.SHOW_TYPE'|translate}}
                                th.text-center {{'eShop.attributes.table.SORT'|translate}}
                                th.text-center {{'eShop.attributes.table.POSITION'|translate}}
                                th

                        tbody
                            tr(v-if="new_attributes" v-for="(new_attribute, key) in new_attributes")
                                td
                                td
                                    input.form-control.text-center(v-model="new_attribute.translation")
                                td
                                    OptiSelectEnum(enum="ProductType" :value="new_attribute.product_type"
                                        @update-enum="v => new_attribute.product_type = v")
                                td
                                    select.form-control.text-center#filter_flag(v-model="new_attribute.filter_flag")
                                        option(:value="0") NIE
                                        option(:value="1") TAK
                                td
                                    select.text-center.form-control#product_detail_flag(v-model="new_attribute.product_detail_flag")
                                        option(:value="0") NIE
                                        option(:value="1") TAK
                                td
                                    OptiSelectEnum.text-center(enum="PropertyType" :value="new_attribute.property_type"
                                        @update-enum="v => new_attribute.property_type = v")
                                td
                                    OptiSelectEnum.text-center(enum="DisplayType" :value="new_attribute.sorting"
                                        @update-enum="v => new_attribute.sorting = v")
                                td(style="width:80px")
                                    input.form-control.text-center(v-model="new_attribute.position")
                                td
                                    button.btn.btn-danger(type="button" @click="new_attributes.splice(key, 1)")
                                        i.fa.fa-trash

                            tr(v-for="attribute in attributes" :key="attribute.id")
                                td.text-left {{attribute.id}}
                                td.text-left {{attribute.translation}}
                                td
                                    OptiSelectEnum.text-center(enum="ProductType" :value="attribute.product_type"
                                        @update-enum="v => attribute.product_type = v")
                                td
                                    select.form-control.text-center#filter_flag(v-model="attribute.filter_flag")
                                        option(:value="0") NIE
                                        option(:value="1") TAK
                                td
                                    select.text-center.form-control#product_detail_flag(v-model="attribute.product_detail_flag")
                                        option(:value="0") NIE
                                        option(:value="1") TAK
                                td
                                    OptiSelectEnum.text-center(enum="PropertyType" :value="attribute.property_type"
                                        @update-enum="v => attribute.property_type = v")
                                td
                                    OptiSelectEnum.text-center(enum="DisplayType" :value="attribute.sorting"
                                        @update-enum="v => attribute.sorting = v")
                                td(style="width:80px")
                                    input.form-control.text-center(v-model="attribute.position")
                                td.text-center(style="width:25px")
                                    label.btn.btn-danger(:for="'delete_' + attribute.id")
                                        i.fa.fa-trash

                                    OptiButton.hide(:id="'delete_' + attribute.id"
                                        text="eShop.attributes.confirm.delete" @confirmed="del(attribute.id)")
                                        template(#icon)
                                            i.fa.fa-trash

                .panel-footer.text-right
                    OptiButton(type="success" text="eShop.attributes.confirm.save" @confirmed="save"
                        ) {{'default.SAVE'|translate}}
                        template(#icon)
                            i.fa.fa-save

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiEshopColors from '@/js/app/vue/components/eShop/Attributes/OptiEshopColors'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEshopAttributes',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiEshopColors,
            OptiSelectEnum,
            OptiDimmer
        },
        data() {
            return {
                attributes: [],
                new_attributes: [],

                card_name: {
                    attributes: 'PRODATTRIBUTES',
                    color_lens: 'PRODCOLOR_LENS'
                },

                view: 'attributes',
                loading: true
            }
        },
        mounted() {
            API.get('shopware/attributes').then(res => {
                this.attributes = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('eShop.attributes.notify.error.LOAD')
            })
        },
        methods: {
            add() {
                this.new_attributes.push({
                    id: 0,
                    translation: '',
                    product_type: '',
                    filter_flag: 0,
                    product_detail_flag: 0,
                    property_type: 0,
                    sorting: 0,
                    position: 0
                })
            },
            del(id) {
                this.loading = true

                API.delete('shopware/attributes/delete/' + id).then(res => {
                    this.attributes = res.data

                    this.$notify.success('eShop.attributes.notify.DELETE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('eShop.attributes.notify.error.DELETE')
                })
            },
            save() {
                this.loading = true

                API.post('shopware/attributes/save', {
                    attributes: this.attributes,
                    new_attributes: this.new_attributes
                }).then(res => {
                    this.attributes = res.data

                    this.$notify.success('eShop.attributes.notify.SAVE')
                }).finally(() => {
                    this.new_attributes = []

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('eShop.attributes.notify.error.SAVE')
                })
            },

            async change_view(view) {
                this.view = ''

                await this.$nextTick()

                this.view = view

                this.$emit('card-was-changed', 'eShop.product.breadcrumb.' + this.card_name[view])
            }
        }
    }
</script>