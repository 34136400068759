import Vue from 'vue'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        windowWidth: 0,
        windowHeight: 0,
        env: {},
        title: 'eOpti'
    },

    actions: {

    },

    mutations: {
        setSize(state, {width, height}) {
            state.windowWidth = width
            state.windowHeight = height
        },
        setEnv(state, env) {
            Vue.set(state, 'env', env)
        },
        setTitle(state, title) {
            state.title = title
            document.title = title
        },
    },

    getters: {
        windowWidth: state => state.windowWidth,
        windowHeight: state => state.windowHeight,
        env: state => {
            return key => {
                if (!key) return state.env
                return state.env[key]
            }
        },
        title: state => state.title,
        isMobile: state => state.windowWidth < 768
    },

    plugins: [
        store => {
            const updateSize = () => {
                store.commit('globals/setSize', {
                    width: window.innerWidth,
                    height: window.innerHeight
                })
            }
            updateSize()

            window.addEventListener('resize', _.debounce(updateSize, 250))
        },
        store => {
            store.commit('globals/setEnv', window.__env)
        },
        store => {
            store.commit('globals/setTitle', document.title)
        }
    ]
}
