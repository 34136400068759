<template lang="pug">
    div
        span(v-if="!autoOpen" style="cursor:pointer" :title="'default.SHOWDETAILS'|translate" @click="dialog = true")
            a.link(v-if="type === 'id'") {{id}}
            i.icon.icon-envelope-open.fa-2x.fa-fw(v-else)

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiPatientEmailPreviewDialog(
                :id="id"

                @close="closed")
</template>

<script>
    import OptiPatientEmailPreviewDialog from '@/js/app/vue/components/Patients/Emails/OptiPatientEmailPreviewDialog'

    export default {
        name: 'OptiPatientEmailPreview',
        components: {
            OptiPatientEmailPreviewDialog
        },
        props: {
            autoOpen: {
                type: Boolean,
                required: false,
                default: false
            },

            type: {
                type: String,
                required: true
            },
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                dialog: this.autoOpen
            }
        },
        methods: {
            closed(changed) {
                if(changed) {
                    this.$emit('changed')
                }

                this.dialog = false
            }
        }
    }
</script>