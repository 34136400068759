import general from '@/js/app/vue/store/carts/helper/general'

import sale from '@/js/app/vue/store/carts/helper/sale'
import order from '@/js/app/vue/store/carts/helper/order'
import sendback from '@/js/app/vue/store/carts/helper/sendback'

let helper = {
    sale: sale,
    order: order,
    sendback: sendback
}

export default {
    getCarts: state => state.carts,

    getPatientExists: state => state.carts.sale.patient_exists,

    getCheckboxAll: state => type => !!state.carts[type].checkbox_all,

    getDisabled: state => (type, is_admin) => helper[type].disabled(state, is_admin),

    getLoading: state => state.loading,
    getPending: state => state.pending,

    getQuantityToDepository: state => product_id => {
        let _return = {
            sale: 0,
            order: 0,
            sendback: 0
        }

        if(Object.keys(state.carts.sale).length) {
            Object.keys(_return).forEach(type => {
                state.carts[type].items.forEach(item => {
                    if (item.product.id === product_id) {
                        _return[type] += item.quantity
                    }
                })
            })
        }

        return _return
    },

    getActiveCartName: state => {
        let index = state.carts.active.findIndex(bool => {
                return bool
            }),
            tab = ['sale', 'order', 'sendback']

        return tab[index]
    },
    getDataToButton: (state, getters) => {
        let tab = {
            'sale': {
                color: 'success',
                icon: 'fa-shopping-cart',
                text: 'product.addtocart.SALE',
                text_pill: 'carts.type.SALE'
            },
            'order': {
                color: 'info',
                icon: 'fa-truck',
                text: 'product.addtocart.ORDER',
                text_pill: 'carts.type.ORDER'
            },
            'sendback': {
                color: 'warning',
                icon: 'fa-archive',
                text: 'product.addtocart.RETURN',
                text_pill: 'carts.type.SENDBACK'
            }}

        return tab[getters.getActiveCartName]
    },
    allowButton: (state, getters) => product => {
        if(!Object.keys(state.carts.sale).length) {
            return false
        }

        let cart_name = getters.getActiveCartName

        return general.stock(state.carts, cart_name, product) && !general.duplicate(state.carts, cart_name, product)
    },

    getOrderAlert: state => type => {
        let _return = false

        state.carts.order.items.forEach(item => {
            if(item[type]) {
                _return = true
            }
        })

        return _return
    }
}
