<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:15px" v-if="!loading")
            OptiButtonHistory(data="role" :id="role.id")

        .col-md-8.col-md-offset-2(v-if="!loading")
            form#form-role.form-horizontal.form-validate(name="formRole" @submit.prevent="editRole")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'role.ROLENAME'|translate}}
                    .col-sm-8
                        input#name.form-control(:placeholder="'role.ROLENAME'|translate"
                            name="name" v-model="role.name" required)
                        OptiValidate(f="name" :data="$v.role.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="short") {{'role.SHORT'|translate}}
                    .col-sm-8
                        input#short.form-control(:placeholder="'role.SHORT'|translate"
                            name="short" v-model="role.short" required)
                        OptiValidate(f="short" :data="$v.role.short")
                .form-group
                    label.col-sm-4.control-label(for="hierarchy") {{'role.HIERARCHY'|translate}}
                    .col-sm-8
                        OptiNumber#hierarchy(style="text-align:left" :header="'role.HIERARCHY'|translate"
                            :value="role.hierarchy" @confirmed="h => role.hierarchy = h"
                            ) {{role.hierarchy}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="optometrist" v-model="role.optometrist")
                                    span.fa.fa-check
                            span {{'role.OPTOMETRIST'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="doctor" v-model="role.doctor")
                                    span.fa.fa-check
                            span {{'role.DOCTOR'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="admin" v-model="role.admin")
                                    span.fa.fa-check
                            span {{'role.ADMIN'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="manager" v-model="role.manager")
                                    span.fa.fa-check
                            span {{'role.MANAGER'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="default" v-model="role.default")
                                    span.fa.fa-check
                            span {{'role.DEFAULT'|translate}}
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.role.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRoleEdit',
        components: {
            OptiButtonHistory,
            OptiValidate,
            OptiNumber,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                role: {},

                loading: true
            }
        },
        validations() {
            return {
                role: {
                    name: {
                        required,
                        unique: unique('users_roles', 'name', this.$state.params.id, 'id')
                    },
                    short: {
                        required,
                        unique: unique('users_roles', 'short', this.$state.params.id, 'id')
                    }
                }
            }
        },
        mounted() {
            API.get('role/get/' + this.$state.params.id).then(res => {
                this.role = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('role.notify.error.LOAD')
            })
        },
        methods: {
            editRole() {
                if(this.role.admin && this.role.manager) {
                    this.$notify.error('role.notify.error.ADMINMANAGER')

                    return false
                }

                this.loading = true

                API.put('role', this.role).then(() => {
                    this.$notify.success('role.notify.EDITED')

                    if(this.role.id === this.$uac.user.activeRole.id) {
                        this.$uac.relogin(this.$uac.user.id)
                    } else {
                        this.$state.go('app.roles.list')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('role.notify.error.EDITED')
                })
            }
        }
    }
</script>