<template lang="pug">
    .panel.panel-primary
        OptiOptometristsDisplayControls(:pilot="true")

        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.optometrist.calibrate.HEADING'|translate}}
        .panel-body
            .text-right
                button.btn.btn-info(type="button" :class="{'btn-warning active': help}"
                    @click="help = !help") {{'default.HELP'|translate}}
            ul(style="list-style-type:decimal" v-show="help")
                li {{'optometrist.tips.calibrate.1'|translate}}
                li {{'optometrist.tips.calibrate.2'|translate}}
                li {{'optometrist.tips.calibrate.3'|translate}}
                li {{'optometrist.tips.calibrate.4'|translate}}
                li {{'optometrist.tips.calibrate.5'|translate}}

            div(v-show="!display.connected || display.reconnecting") {{'settings.optometrist.calibrate.TIPTOSTART'|translate}}
            div(v-show="display.connected && !display.reconnecting")
                .text-center(v-show="!calibrating")
                    OptiButton(type="default" @click.native="calibrate()") {{'settings.optometrist.calibrate.START'|translate}}
                        template(#icon)
                            i.fa.fa-arrows-alt
                .form-horizontal(v-show="calibrating")
                    div
                        p {{'settings.optometrist.calibrate.TIP1'|translate}}
                        p {{'settings.optometrist.calibrate.TIP2'|translate}}
                    .form-group
                        label.col-lg-2.control-label {{'settings.optometrist.calibrate.HEIGHT'|translate}}
                        .col-lg-10.btn-group
                            OptiButton(type="default" @click.native="calibrateSize('h', -10)") {{'settings.optometrist.calibrate.scale.-10'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-double-down
                            OptiButton(type="default" @click.native="calibrateSize('h', -1)") {{'settings.optometrist.calibrate.scale.-1'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-down
                            span.pull-left &nbsp;
                            OptiButton(type="default" :right="true" @click.native="calibrateSize('h', 1)") {{'settings.optometrist.calibrate.scale.+1'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-up
                            OptiButton(type="default" :right="true" @click.native="calibrateSize('h', 10)") {{'settings.optometrist.calibrate.scale.+10'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-double-up
                    .form-group
                        label.col-lg-2.control-label {{'settings.optometrist.calibrate.WIDTH'|translate}}
                        .col-lg-10.btn-group
                            OptiButton(type="default" @click.native="calibrateSize('w', -10)") {{'settings.optometrist.calibrate.scale.-10'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-double-down
                            OptiButton(type="default" @click.native="calibrateSize('w', -1)") {{'settings.optometrist.calibrate.scale.-1'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-down
                            span.pull-left &nbsp;
                            OptiButton(type="default" :right="true" @click.native="calibrateSize('w', 1)") {{'settings.optometrist.calibrate.scale.+1'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-up
                            OptiButton(type="default" :right="true" @click.native="calibrateSize('w', 10)") {{'settings.optometrist.calibrate.scale.+10'|translate}}
                                template(#icon)
                                    i.fa.fa-angle-double-up
        .panel-footer.text-right(v-show="calibrating")
            OptiButtonSave(@click.native="calibrateFinish(true)")
            OptiButton.pull-left(type="danger" @click.native="calibrateFinish(false)") {{'settings.optometrist.calibrate.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiSettingsOptometristsCalibrate',
        components: {
            OptiOptometristsDisplayControls,
            OptiButton,
            OptiButtonSave
        },
        props: {

        },
        data() {
            return {
                help: false,

                display: OptometristsDisplay,
                calibrating: false
            }
        },
        mounted() {

        },
        methods: {
            calibrateSize(dim, offset) {
                this.display.message.calibrateSize(dim, offset)
            },
            calibrate() {
                this.display.message.calibrate()
                this.calibrating = true
            },
            calibrateFinish(apply) {
                this.display.message.calibrateFinish(apply)
                this.calibrating = false
            }
        }
    }
</script>