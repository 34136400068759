<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-calculator.mr3
            span {{header}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-4.pr-sm(v-show="minus")
                    button.btn.btn-default.btn-block.btn-lg.text-bold(@click='addMinus()' :class="{active: inner.minus}")
                        i.fa.fa-minus
                div(:class="{'col-xs-12': !minus, 'col-xs-8': minus}")
                    .form-control.form-control-calculator.text-right
                        span(:class="{'bg-selected': first_add}" v-show="!inner.value && inner.minus") -{{inner.value}}
                        span(:class="{'bg-selected': first_add}" v-show="inner.value || !inner.minus") {{inner.value}}

            .row.mt(v-show="max2dot")
                .col-xs-12.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.validate.MAX2DOT'|translate}}
            .row.mt(v-show="minValue")
                .col-xs-12.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.validate.MINVALUE'|translate}} {{min}}.
            .row.mt(v-show="maxValue")
                .col-xs-12.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.validate.MAXVALUE'|translate}} {{max}}.
            .row.mt(v-show="required && !parseFloat(inner.value)")
                .col-xs-12.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'validation.formValidate.required'|translate}}

            .row.mt
                .col-xs-6.pr-sm
                    button.btn.btn-warning.btn-block(@click='clear()') {{'default.CLEAR'|translate}}
                .col-xs-6.pl-sm
                    button.btn.btn-info.btn-block(@click='backspace()') {{'default.BACKSPACE'|translate}}

            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("7")') 7
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("8")') 8
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("9")') 9
            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("4")') 4
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("5")') 5
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("6")') 6
            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("1")') 1
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("2")') 2
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("3")') 3

            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("0")') 0
                .col-xs-4.pr-sm.pl-sm(v-show='float')
                    button.btn.btn-info.btn-block.btn-lg(@click='add(".00")' :disabled='hasDot || !inner.value') ,00
                .col-xs-4.pl-sm(v-show='float')
                    button.btn.btn-info.btn-block.btn-lg(@click='add(".")' :disabled='hasDot') ,

        template(#footer)
            OptiButton(type="success" @click.native="confirmed" :disabled="disabled || pending") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(@click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDialogNumber',
        components: {
            OptiButton,
            OptiDialog

        },
        props: {
            header: {
                type: String,
                default: ''
            },

            minus: {
                type: Boolean,
                required: true
            },
            float: {
                type: Boolean,
                required: true
            },
            required: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            },
            none: {
                type: Number | Boolean,
                required: false,
                default: false
            },
            min: {
                type: Number | Boolean,
                required: false,
                default: false
            },
            max: {
                type: Number | Boolean,
                required: false,
                default: false
            },

            isNumber: {
                type: Boolean,
                required: true
            },
            value: {
                type: Number | String,
                required: true
            }
        },
        data() {
            return {
                first_add: true,
                inner: {
                    value: this.none !== false && this.none === this.value ? 'brak' : this.value,
                    minus: this.none === false && this.value < 0
                }
            }
        },

        mounted() {
            window.addEventListener('keydown', this.keyPress)
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyPress)
        },

        methods: {
            firstTime() {
                if(this.first_add) {
                    this.inner.value = this.isNumber ? 0 : ''

                    this.first_add = false
                }
            },
            addMinus() {
                this.firstTime()

                this.inner.minus = !this.inner.minus

                this.inner.value *= -1
            },
            add(num) {
                this.firstTime()

                if(!parseFloat(this.inner.value) && this.inner.value !== '0.' && this.isNumber) {
                    this.inner.value = num
                } else {
                    this.inner.value += num
                }

                if(this.inner.value === '.00') {
                    this.inner.value = '0'
                } else if(this.inner.value === '.') {
                    this.inner.value = '0.'
                }

                if(this.inner.minus && this.inner.value > 0) {
                    this.inner.value *= -1
                }
            },
            clear() {
                this.firstTime()

                this.inner.value = this.isNumber ? 0 : ''
                this.inner.minus = false
            },
            backspace() {
                this.firstTime()

                let str = this.inner.value.toString()
                this.inner.value = str.substring(0, str.length - 1)

                if(typeof this.inner.value === 'undefined' || this.inner.value === '' || this.inner.value === '-') {
                    this.inner.value = this.isNumber ? 0 : ''
                }

                if(!this.inner.value) {
                    this.inner.minus = false
                }
            },

            keyPress(e) {
                let key = e.which

                if(key >= 96 && key <= 106) {
                    key -= 48
                }

                switch(key) {
                    case 48: // 0
                    case 49: // 1
                    case 50: // 2
                    case 51: // 3
                    case 52: // 4
                    case 53: // 5
                    case 54: // 6
                    case 55: // 7
                    case 56: // 8
                    case 57: // 9
                        this.add((key - 48).toString())

                        break

                    case 109:
                    case 189:
                        this.addMinus()

                        break

                    case 8:
                        this.backspace()

                        break

                    case 46:
                        this.clear()

                        break

                    case 13:  // enter
                        if(!this.disabled) {
                            this.confirmed()
                        }

                        break

                    case 188:  // comma
                    case 190:  // dot
                    case 108:  // dot (numpad)
                    case 110:  // dot (windows)
                        if(this.float && !this.hasDot) {
                            this.add('.')
                        }
                }
            },

            confirmed() {
                this.$emit('confirmed', this.float ? parseFloat(this.inner.value) :
                    (this.isNumber ? parseInt(this.inner.value) : this.inner.value))
            }
        },
        computed: {
            hasDot() {
                return this.inner.value.toString().includes('.')
            },
            max2dot() {
                if(this.float) {
                    let split = this.inner.value.toString().split('.')

                    if(split.length === 2 && split[1].length > 2) {
                        return true
                    }
                }

                return false
            },
            minValue() {
                if(this.min !== false && parseFloat(this.inner.value) < this.min) {
                    return true
                }

                return false
            },
            maxValue() {
                if(this.max !== false && parseFloat(this.inner.value) > this.max) {
                    return true
                }

                return false
            },
            disabled() {
                return this.max2dot || this.minValue || this.maxValue || (this.required && !parseFloat(this.inner.value)) ||
                    this.inner.value.toString().substr(-1) === '.' || this.inner.value === 'brak'
            }
         }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 350px;
    }
</style>