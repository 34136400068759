<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:10px" v-show="loading === false")
            OptiButtonHistory(data="layout_range" :id="form.id")
        .col-md-offset-2.col-md-8(v-show="loading === false")
            form#form-range.form-horizontal(name="formLayoutRange" @submit="editRange")
                .form-group
                    label.col-sm-4.control-label.star-required(for="refers_to") {{'layout.ranges.refers_to.HEADING'|translate}}
                    .col-sm-8
                        OptiSelectEnum#refers_to(enum="LayoutCategoryRangeRefersTo"
                            :value="form.refers_to" @update-enum="r => form.refers_to = r")
                .form-group(v-show="form.refers_to === 1")
                    label.col-sm-4.control-label.star-required(for="department") {{'layout.ranges.DEPARTMENT_ID'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department(:simple="true"
                            :department-id="form.department" @selected="d => form.department = d")
                        OptiValidate(f="department" :data="$v.form.department")
                .form-group
                    label.col-sm-4.control-label.star-required(for="category") {{'layout.ranges.CATEGORY_ID'|translate}}
                    .col-sm-8
                        OptiSelectBrand#category(:category="form.category" @selected="c => form.category = c")
                        OptiValidate(f="category" :data="$v.form.category")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'layout.ranges.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="LayoutCategoryRangeType" :value="form.type" @update-enum="updateType")
                        OptiValidate(f="type" :data="$v.form.type")

                .form-group(v-show="form.type === 3")
                    label.col-sm-4.control-label(for="facing") {{'layout.ranges.FACING'|translate}}
                    .col-sm-8
                        OptiNumber#facing.form-control(:header="'layout.ranges.FACING'|translate"
                            :value="form.facing" @confirmed="f => form.facing = f") {{form.facing}}
                .form-group
                    label.col-sm-4.control-label(for="min") {{'layout.ranges.MIN'|translate}}
                    .col-sm-8
                        OptiNumber#min.form-control(:header="'layout.ranges.MIN'|translate"
                            :value="form.min" @confirmed="f => form.min = f") {{form.min}}
                .form-group
                    label.col-sm-4.control-label(for="max") {{'layout.ranges.MAX'|translate}}
                    .col-sm-8
                        OptiNumber#max.form-control(:header="'layout.ranges.MAX'|translate"
                            :value="form.max" @confirmed="f => form.max = f") {{form.max}}

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.form.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiSelectBrand from '@/js/app/vue/components/Select/OptiSelectBrand'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiLayoutRangeEdit',
        components: {
            OptiButtonHistory,
            OptiSelectEnum,
            OptiSelectDepartment,
            OptiSelectBrand,
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    refers_to: 0,
                    department: null,
                    category: {},
                    facing: 0,
                    type: 0,
                    min: 0,
                    max: 0
                },

                loading: true
            }
        },
        validations: {
            form: {
                department: {
                    required: requiredIf(form => form.refers_to === 1)
                },
                category: {
                    required
                },
                type: {
                    noZero
                }
            }
        },
        mounted() {
            API.get('layout/ranges/action/' + this.$state.params.id).then(res => {
                this.form = res.data

                this.form.category.type = this.form.category.parentId === 509 ? 'ramki' : 'słońce'
                this.$set(this.form, 'department', this.form.department_id ? this.form.department_id : null)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('layout.ranges.notify.error.EDIT')
            })
        },
        methods: {
            editRange() {
                this.loading = true

                API.put('layout/ranges/action', this.form).then(() => {
                    this.$state.go('app.layout.ranges')

                    this.$notify.success('layout.ranges.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('layout.ranges.notify.error.EDIT')
                })
            },

            updateType(type) {
                if(type !== 3) {
                    this.form.facing = 0
                }

                this.form.type = type
            }
        }
    }
</script>