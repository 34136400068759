<template lang="pug">
    .row(style="margin-top:10px" v-if="$asyncComputed.ticket.success")
        OptiDimmer(:active="active")
        .col-xs-12
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'ticket.breadcrumb.TICKETS'|translate}}
                    .pull-right(v-show="ticket.date") \#{{ticket.date}}
                .table-responsive
                    table.table.table-condensed.table-striped
                        thead
                            tr
                                th #
                                th {{'ticket.details.head.PRODUCTID'|translate}}
                                th {{'ticket.details.head.PRODUCTNAME'|translate}}
                                th {{'ticket.details.head.BARCODE'|translate}}
                                th {{'ticket.details.head.PRICE'|translate}}
                                th
                        tbody(table-empty)
                            tr(v-for="(detail, index) in ticket.details")
                                td.text-right {{index + 1}}
                                td.text-right(style="vertical-align:middle")
                                    OptiProduct(:product="detail.product") {{detail.product_id}}
                                td
                                    OptiProduct(:product="detail.product") {{detail.product_name}}
                                    small(style="display:block") {{detail.product.fullName}}
                                td {{detail.barcode}}
                                td.text-right {{detail.price|currency}}
                                td.text-center(style="vertical-align:middle")
                                    a(:href="$state.href('app.catalog.product', {id: detail.product_id})"
                                        :title="'returns.items.title.PRODUCT'|translate")
                                        span.fa.fa-2x.fa-fw.fa-info-circle
        .col-md-offset-7.col-md-5
            OptiButton(type="success" text="ticket.confirm"
                :disabled="!ticket.details" @confirmed="orderTickets") {{'ticket.confirm.GOEND'|translate}}
                template(#icon)
                    i.fa.fa-ticket
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'

    export default {
        name: "OptiTicketsAdd",
        components: {OptiDimmer, OptiButton, OptiProduct},
        data() {
            return {
                active: false
            }
        },
        asyncComputed: {
            async ticket() {
                try {
                    this.active = true
                    let {data} = await API.get('warehouse/ticket/storage/added')
                    this.active = false
                    return this.ticket = data
                } catch(e) {
                    console.log(e)
                }
            },
        },
        methods: {
            orderTickets() {
                this.active=true

                API.get('warehouse/ticket/storage/confirm/' + this.ticket.id).then(res => {
                    if(res.data.status) {
                        this.$state.go('app.warehouse.tickets.table')

                        this.$notify.success('ticket.confirm.notify.END')
                    } else {
                        this.active = false

                        this.$notify.error('ticket.confirm.notify.error.END')
                    }
                })
            }
        }
    }
</script>