<template lang="pug">
    Vue2Dropzone(
        :class="{'disabled': disable}"
        ref="myVueDropzone"

        :id="id"
        :useCustomSlot="true"
        :options="options"

        @vdropzone-mounted="init"

        @vdropzone-sending="sending"
        @vdropzone-success="success"
        @vdropzone-removed-file="removed"
        @vdropzone-error="error")
            i.fa.fa-cloud-upload.mr3
            span {{'default.upload.DROP'|translate}}
</template>

<script>
    import Vue from 'vue'

    import Vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'OptiUpload',
        components: {
            Vue2Dropzone
        },
        props: {
            id: {
                type: String,
                required: true
            },
            prefix: {
                type: String,
                required: true
            },
            picture: {
                type: Boolean,
                required: false,
                default: false
            },
            disabledDelete: {
                type: Boolean,
                required: false,
                default: false
            },

            file: {
                type: String
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        watch: {
            file(f) {
                if(!f) {
                    this.$refs.myVueDropzone.removeAllFiles()
                } else if(f !== this.uploaded) {
                    this.setUploaded(f)
                }
            }
        },
        data() {
            return {
                options: {
                    url: 'api/upload' + (this.picture ? '/image' : ''),
                    timeout: 300000,
                    maxFiles: 1,
                    acceptedFiles: this.picture ? '.jpg,.jpeg,.png,.gif' : null,
                    thumbnailWidth: 400,

                    addRemoveLinks: true,
                    dictRemoveFile: `<button type="button" class="btn btn-danger btn-labeled ` +
                        (this.disabledDelete ? `invisible` : ``) + `">
                        <span class="btn-label"><i class="fa fa-trash"></i></span>
                        <span>${this.$filters.translate('default.upload.REMOVE')}</span>
                    </button>
                    <button type="button" class="btn btn-purple btn-upload-preview"
                        onclick="window.open(window.__env.uploadHost + '/' + document.getElementById('${this.id}').dataset.url, '_blank'); event.stopPropagation()">
                        <i class="fa fa-search"></i>
                    </button>`,

                    dictMaxFilesExceeded: this.$filters.translate('default.upload.notify.error.MAXFILES'),
                    dictInvalidFileType: this.$filters.translate('default.upload.notify.error.NOTYPEPIC')
                },
                is_destroying: false,
                uploaded: null
            }
        },

        beforeDestroy() { // rozwiązuje to problem gdy upload jest w pętli
            this.is_destroying = true
        },
        destroyed() {
            this.is_destroying = false
        },

        methods: {
            init() {
                if(this.file) {
                    this.setUploaded(this.file)
                }
            },

            updateFile(file) {
                this.uploaded = file
                this.$emit('update-file', file)

                let el = document.getElementById(this.id)

                if(el) {
                    el.dataset.url = file
                }
            },
            setUploaded(file) {
                this.$refs.myVueDropzone.removeAllFiles()

                Vue.nextTick(() => {
                    if(typeof this.$refs.myVueDropzone !== 'undefined') {
                        this.$refs.myVueDropzone.manuallyAddFile({
                            name: file.substring(file.lastIndexOf('/') + 1),
                            size: 0
                        }, window.__env.uploadHost + '/' + file)

                        this.updateFile(file)
                    }
                })
            },

            sending(file, xhr, formData) {
                formData.append('prefix', this.prefix)

                this.$emit('update-loading', true)
            },
            success(file, res) {
                if(!file.type.startsWith('image')) {
                    $('.dz-image').css({height: '150px'})
                }

                this.$emit('update-file-original-name', file.name)
                this.$emit('update-loading', false)
                this.updateFile(res.info)
            },
            error(file, error) {
                $(file.previewElement).find('.dz-error-message').text(error.message)

                this.$emit('update-loading', false)

                this.$notify.error('default.upload.notify.error.SAVE')
            },
            removed() {
                if(!this.is_destroying) {
                    this.updateFile('')
                }
            }
        },
        computed: {
            disable() {
                $('.dz-hidden-input').prop('disabled', this.disabled)

                if(this.disabled) {
                    this.$refs.myVueDropzone.removeAllFiles()
                }

                return this.disabled
            }
        }
    }
</script>

<style lang="less" scoped>
    .dropzone {
        background: inherit;
        border: 1px solid #cfdbe2;
        border-radius: 5px;
        min-height: initial;
        padding: 0;

        &.disabled {
            opacity: .3;
        }

        /deep/ .dz-preview {
            margin: 0;
            border-radius: 5px;
            width: 100%;

            > .dz-image {
                background: rgba(33, 150, 243, 0.8);
                border-radius: 5px;

                > img {
                    width: 100% !important;
                    object-fit: inherit !important;
                }
            }

            &:hover {
                > .dz-details {
                    background-color: transparent;
                    font-size: 20px;
                }

                .dz-image > img {
                    transform: scale(1.2, 1.2);
                    filter: blur(5px);
                }

                > .dz-image > img {
                    border-radius: 5px;
                }
            }

            > .dz-details {
                padding-top: 15px;

                > .dz-size, .dz-filename {
                    text-align: center;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    > span {
                        background-color: rgba(255, 255, 255, 0.4);
                        padding: 0 0.4em;
                        border-radius: 3px;
                    }
                }

                > .dz-size {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
        }

        /deep/ .dz-message {
            margin: 1em 0;
        }

        /deep/ .dz-error-message {
            top: 10px;
            width: 90%;
            margin: 0 5%;
        }

        /deep/ .dz-remove {
            padding: 0;
            left: 50%;
            margin-left: -65px;
            border: none;
            cursor: default;

            &:hover {
                text-decoration: none;
            }

            > button {
                cursor: pointer;

                > span {
                    cursor: pointer;

                    > i {
                        cursor: pointer;
                    }
                }

                > i {
                    cursor: pointer;
                }

                &.btn-upload-preview {
                    margin-left: 10px;
                }
            }
        }
    }
</style>