<template lang="pug">
    .row
        .col-md-offset-2.col-md-8
            form#form-inventory.form-horizontal(name="formInventory" @submit.prevent="addInventory")
                .form-group
                    label.col-sm-4.control-label.star-required(for="quantitySunglasses") {{'inventory.form.SUNGLASSES'|translate}}
                    .col-sm-8
                        OptiNumber#quantitySunglasses(:header="'inventory.form.SUNGLASSES'|translate"
                            :value="inventory.quantitySunglasses" @confirmed="v => inventory.quantitySunglasses = v"
                            :required="true") {{inventory.quantitySunglasses}}
                        OptiValidate(f="quantitySunglasses" :data="$v.inventory.quantitySunglasses")
                .form-group
                    label.col-sm-4.control-label.star-required(for="quantityFrames") {{'inventory.form.FRAMES'|translate}}
                    .col-sm-8
                        OptiNumber#quantityFrames(:header="'inventory.form.FRAMES'|translate"
                            :value="inventory.quantityFrames" @confirmed="v => inventory.quantityFrames = v"
                            :required="true") {{inventory.quantityFrames}}
                        OptiValidate(f="quantityFrames" :data="$v.inventory.quantityFrames")
                .form-group
                    label.col-sm-4.control-label(for="when") {{'inventory.form.WHEN'|translate}}
                    .col-sm-8
                        OptiSelectEnum#when(enum="InventoryAction" :value="inventory.action"
                            @update-enum="a => inventory.action = a")
                .form-group
                    label.col-sm-4.control-label(for="comments") {{'inventory.form.ATTENTIONS'|translate}}
                    .col-sm-8
                        textarea#comments.form-control(rows="4" v-model="inventory.comments" name="comments")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.inventory.$invalid")

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryQuickAdd',
        components: {
            OptiNumber,
            OptiSelectEnum,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                inventory: {
                    quantitySunglasses: 0,
                    quantityFrames: 0,
                    action: 'start',
                    comments: ''
                },

                loading: false
            }
        },
        validations: {
            inventory: {
                quantitySunglasses: {
                    noZero
                },
                quantityFrames: {
                    noZero
                }
            }
        },

        mounted() {

        },
        methods: {
            addInventory() {
                this.loading = true

                API.post('warehouse/inventory/quick/add', this.inventory).then(() => {
                    this.$notify.success('inventory.notify.SAVE')

                    this.$state.go('app.warehouse.inventories.quick.table')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('inventory.notify.error.SAVE')
                })
            }
        }
    }
</script>
