<template lang="pug">
    .row
        .col-md-offset-2.col-md-8
            form.form-horizontal.form-validate(name="formArchive" @submit.prevent="add")
                .form-group
                    .col-xs-12
                        input#id.form-control(:placeholder="'bookkeeping.archive.PLACEHOLDER'|translate"
                            ref="archive_input" name="id" :disabled="loading" v-model="id")
                        OptiValidate(f="id" :data="$v.id")
                .form-group
                    .col-xs-12.text-right
                        OptiButtonSave(:disabled="loading || $v.id.$invalid")

        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'bookkeeping.archive.today.HEADER'|translate}}
                    small
                        span(v-if="mode === '10'") {{'bookkeeping.archive.today.10'|translate}}
                        span(v-else)
                            span.mr3 {{'bookkeeping.archive.today.all.SUB_1'|translate}}
                            span.mr3 {{patientDocuments.length}}
                            span {{'bookkeeping.archive.today.all.SUB_2'|translate}}

                table.table.table-striped.middle(v-if="patientDocuments.length")
                    thead
                        tr
                            th #
                            th {{'bookkeeping.archive.DATEODRECEIPT'|translate}}
                            th {{'bookkeeping.archive.DEPARTMENT'|translate}}
                            th {{'bookkeeping.archive.STATUS'|translate}}
                            th {{'bookkeeping.archive.IDTASK'|translate}}
                            th {{'bookkeeping.archive.US'|translate}}
                            th {{'bookkeeping.archive.patient.FIRSTNAME'|translate}}
                            th {{'bookkeeping.archive.patient.LASTNAME'|translate}}
                            th
                    tbody
                        tr(v-for="(patientDocument, index) in patientDocuments" :key="index")
                            th {{patientDocuments.length - index}}
                            td {{patientDocument.created_at}}
                            td {{patientDocument.task.departmentName}}
                            td {{patientDocument.task.statusName|translate}}
                            td
                                a.link(:href="$state.href('app.task.detail', {id: patientDocument.ref_id})" target="_blank"
                                ) {{patientDocument.ref_id}}
                            td {{patientDocument.task.hasServiceContract ? 'tak' : 'nie'}}
                            td {{patientDocument.patient.firstName}}
                            td {{patientDocument.patient.lastName}}
                            td.text-center
                                OptiButton(type="danger" @click.native="remove(patientDocument.id)") {{'default.REMOVE'|translate}}
                                    template(#icon)
                                        i.fa.fa-trash

                .panel-body(v-else)
                    .col-xs-12.text-center.text-muted
                        i.fa.fa-exclamation-circle.mr3
                        span {{'bookkeeping.archive.notify.warning.EMPTY'|translate}}

                OptiDimmer(:active="loading")

        .col-sm-6.text-center-xs(style="margin-bottom:15px")
            OptiButton(v-show="mode === '10'" @click.native="load('all')"
                ) {{'bookkeeping.archive.today.all.SHOW_ALL_BUTTON'|translate}}
                template(#icon)
                    i.fa.fa-list

        .col-sm-6.text-center-xs.text-right(style="margin-bottom:15px")
            OptiButton(style="margin-right:10px" @click.native="$state.go('app.bookkeeping.archive.archived')"
                ) {{'sidebar.register.bookkeeping.archive.ARCHIVED'|translate}}
                template(#icon)
                    i.fa.fa-table

            OptiButton(@click.native="$state.go('app.bookkeeping.archive.not_archived')"
                ) {{'sidebar.register.bookkeeping.archive.NOT_ARCHIVED'|translate}}
                template(#icon)
                    i.fa.fa-table
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators'
    import {numeric} from 'vuelidate/lib/validators'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiBookkeepingArchiveAdd',
        components: {
            OptiValidate,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                id: '',
                patientDocuments: [],

                mode: '10',
                loading: true
            }
        },
        validations: {
            id: {
                noZero,
                numeric
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load(mode) {
                if(typeof mode === 'undefined') {
                    mode = '10'
                }

                this.loading = true
                this.mode = mode

                API.get('bookkeeping/archive/added/' + this.mode).then(res => {
                    this.patientDocuments = res.data
                }).finally(() => {
                    this.id = ''

                    this.loading = false

                    this.$nextTick(() => {
                        this.$refs.archive_input.focus()
                    })
                }).catch(() => {
                    this.$notify.error('bookkeeping.archive.notify.error.TODAY_LOAD')
                })
            },
            add() {
                this.loading = true

                API.post('bookkeeping/archive/save', {
                    id: this.id
                }).then(res => {
                    if(res.data === 'exists') {
                        this.$notify.warning('bookkeeping.notify.warning.EXISTS')
                    } else if(res.data === 'not ok') {
                        this.$notify.error('bookkeeping.notify.error.NOTFOUND')
                    } else {
                        this.$notify.success('bookkeeping.notify.success.ADD')
                    }
                }).finally(() => {
                    this.load()
                }).catch(() => {
                    this.$notify.error('bookkeeping.notify.error.ADD')
                })
            },
            remove(id) {
                this.loading = true

                API.delete('bookkeeping/archive/delete/' + id).then(() => {
                    this.$notify.success('bookkeeping.notify.success.DELETE')
                }).finally(() => {
                    this.load()
                }).catch(() => {
                    this.$notify.error('bookkeeping.notify.error.DELETE')
                })
            }
        }
    }
</script>