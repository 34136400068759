<template lang="pug">
    .row
        .col-xs-12
            legend {{'shipments.new.DONE'|translate}}
        .col-sm-6
            form.form-horizontal
                .form-group
                    label.control-label.col-sm-6.star-required(for="delivery_type") {{'shipments.new.done.SPEDYTOR'|translate}}
                    .col-sm-6
                        OptiSelectEnum#delivery_type(enum="ShipmentDeliveryType" :value="shipment.delivery_type"
                            @update-enum="d => shipment.delivery_type = d")
                        OptiValidate(f="delivery_type" :data="vShipment.delivery_type")
                .form-group(v-show="shipment.delivery_type === 30")
                    label.control-label.col-sm-6.star-required(for="shipment_type") {{'shipments.action.ready.SERVICETYPE'|translate}}
                    .col-sm-6
                        OptiSelectEnum#shipment_type(enum="ShipmentDeliveryUPSType" :value="shipment.shipment_type"
                            :disabled="shipment.saturday" @update-enum="s => shipment.shipment_type = s")
                        OptiValidate(f="shipment_type" :data="vShipment.shipment_type")
                .form-group
                    label.control-label.col-sm-6(style="padding-top:0" :title="'shipments.action.ready.CODINPUT'|translate"
                        :class="{'star-required': shipment.cod_checkbox}")
                        .checkbox.c-checkbox.checkbox-primary.inline
                            input(type="checkbox" v-model="shipment.cod_checkbox" name="cod_checkbox"
                                @change="shipment.cod = 0")
                            span.fa.fa-check(style="margin-left:0px")
                        span {{'shipments.action.ready.CODINPUT'|translate}}
                    .col-sm-6
                        OptiNumber#cod(:header="'shipments.action.ready.CODINPUT'|translate"
                            :disabled="!shipment.cod_checkbox"
                            :value="shipment.cod" @confirmed="c => shipment.cod = c"
                            :float="true" :required="shipment.cod_checkbox") {{shipment.cod|currency}}
                        OptiValidate(f="cod" :data="vShipment.cod")
                .form-group
                    .col-xs-12.text-right
                        label(:title="'shipments.action.ready.SATURDAY'|translate")
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="saturday" v-model="shipment.saturday"
                                        @change="shipment.shipment_type = '07'")
                                    span.fa.fa-check
                            span {{'shipments.action.ready.SATURDAY'|translate}}
                .form-group(v-show="shipment.delivery_type === 30")
                    .col-xs-12.text-right
                        label(:title="'shipments.GENERATED'|translate")
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" v-model="shipment.generate_tour_id" name="generate_tour_id")
                                    span.fa.fa-check
                            span {{'shipments.GENERATED'|translate}}

        .col-sm-6
            form.form-horizontal
                .form-group
                    label.control-label.col-sm-6(for="suggested_value") {{'shipments.new.done.SUGGESTEDVALUE'|translate}}
                    .col-sm-6
                        #suggested_value.form-control.text-right(disabled) {{shipment.suggested_value|currency}}
                .form-group
                    label.control-label.col-sm-6.star-required(for="value") {{'shipments.new.done.VALUE'|translate}}
                    .col-sm-6
                        OptiNumber#value(:header="'shipments.new.done.VALUE'|translate"
                            :value="shipment.value" @confirmed="v => shipment.value = v"
                            :float="true" :required="true") {{shipment.value|currency}}
                        OptiValidate(f="value" :data="vShipment.value")
                .form-group
                    label.control-label.col-sm-6.star-required(for="weight") {{'shipments.new.done.WEIGHT'|translate}}
                    .col-sm-6
                        OptiNumber#weight(v-show="cartons.length === 1"
                            :header="'shipments.new.done.WEIGHT'|translate"
                            :value="cartons[0].weight" @confirmed="updateWeight"
                            :float="true" :required="true") {{cartons[0].weight|kg}}
                        .form-control.text-right(v-show="cartons.length > 1" disabled) {{shipment.weight|kg}}
                        OptiValidate(f="weight" :data="vShipment.weight")

        .col-xs-12(v-if="![40, 45].includes(shipment.delivery_type)")
            h4 {{'shipments.new.done.ADRRESS'|translate}}
            .receiver-address(v-show="receiver.address.firstName")
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.NAME'|translate}}
                        div {{receiver.address.firstName}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.SURNAME'|translate}}
                        div {{receiver.address.lastName}}
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.POSTALCODE'|translate}}
                        div {{receiver.address.zipCode}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.CITY'|translate}}
                        div {{receiver.address.city}}
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.STREET'|translate}}
                        div {{receiver.address.street}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.TELEPHONE'|translate}}
                        div {{receiver.address.cellPhoneNumber}}
                .row
                    .col-xs-12
                        label(for="comments") {{'shipments.COMMENTS'|translate}}
                        .controls
                            textarea#comments.form-control(v-model="receiver.comments")
            .row(v-show="!receiver.address.firstName")
                .col-xs-12.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'shipments.new.done.notify.NOADDRESS'|translate}}

        .col-xs-12(v-if="[40, 45].includes(shipment.delivery_type)")
            h4 {{'shipments.new.done.ADRRESS'|translate}}
            .row(v-show="receiver.address.firstName")
                .col-sm-5.receiver-address(v-show="receiver.address.firstName" :class="{ 'text-muted': receiver_send_to_locker }")
                    .row
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.NAME'|translate}}
                            div {{receiver.address.firstName}}
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.SURNAME'|translate}}
                            div {{receiver.address.lastName}}
                    .row
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.POSTALCODE'|translate}}
                            div {{receiver.address.zipCode}}
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.CITY'|translate}}
                            div {{receiver.address.city}}
                    .row
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.STREET'|translate}}
                            div {{receiver.address.street}}
                        .col-sm-6
                            .bold {{'shipments.new.receiver.other.TELEPHONE'|translate}}
                            div {{receiver.address.cellPhoneNumber}}
                .col-sm-2
                    OptiSwitchToggleButton(:checked="receiver_send_to_locker" @input="toggleReceiverSendLocker")
                .col-sm-5(:class="{ 'text-muted': !receiver_send_to_locker }")
                    label(for="receiver_locker") Paczkomat odbiorcy
                    .controls
                        input#receiver_locker.form-control(
                            :value="receiver.locker"
                            @input="updateReceiverLocker"
                            :disabled="!receiver_send_to_locker"
                            :placeholder="receiver_locker_default"
                        )

                .col-xs-12
                    label(for="comments") {{'shipments.COMMENTS'|translate}}
                    .controls
                        textarea#comments.form-control(v-model="receiver.comments")

            .row(v-show="!receiver.address.firstName")
                .col-xs-12.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'shipments.new.done.notify.NOADDRESS'|translate}}

        .col-xs-12(v-show="[40, 45].includes(shipment.delivery_type)")
            h4
                label(for="size") {{'shipments.size.HEADING'|translate}}
            #package-size
                .row
                    .col-sm-6
                        form.form-horizontal
                            .form-group
                                .col-xs-12
                                    OptiSelectEnum#size(enum="ShipmentSize" :value="shipment.size"
                                        @update-enum="updateSize")
                                    OptiValidate(f="size" :data="vShipment.size")
                    .col-sm-6
                        form.form-horizontal
                            .form-group
                                label.control-label.col-sm-6.star-required(for="width") {{'shipments.size.WIDTH'|translate}}
                                .col-sm-6
                                    OptiNumber#width(:header="'shipments.size.WIDTH'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.width" @confirmed="w => shipment.width = w"
                                        :float="true" :required="true") {{shipment.width|currency}} cm
                                    OptiValidate(f="width" :data="vShipment.width")
                            .form-group
                                label.control-label.col-sm-6.star-required(for="height") {{'shipments.size.HEIGHT'|translate}}
                                .col-sm-6
                                    OptiNumber#height(:header="'shipments.size.HEIGHT'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.height" @confirmed="h => shipment.height = h"
                                        :float="true" :required="true") {{shipment.height|currency}} cm
                                    OptiValidate(f="height" :data="vShipment.height")
                            .form-group
                                label.control-label.col-sm-6.star-required(for="len") {{'shipments.size.LEN'|translate}}
                                .col-sm-6
                                    OptiNumber#len(:header="'shipments.size.LEN'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.len" @confirmed="l => shipment.len = l"
                                        :float="true" :required="true") {{shipment.len|currency}} cm
                                    OptiValidate(f="len" :data="vShipment.len")

        .col-xs-12
            OptiShippingCreateStepItems(
                :model="model"
                :receiver="receiver"

                :cartons="cartons")
</template>

<script>
    import {ShipmentSize} from '@/js/app/vue/helpers/ShipmentSize'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiShippingCreateStepItems
        from '@/js/app/vue/components/Shipping/Create/Steps/OptiShippingCreateStepItems'
    import OptiSwitchToggleButton from "@/js/app/vue/components/Button/OptiSwitchToggleButton";

    export default {
        name: 'OptiShipmentCreateStepDone',
        components: {
            OptiSwitchToggleButton,
            OptiSelectEnum,
            OptiNumber,
            OptiValidate,
            OptiShippingCreateStepItems
        },
        props: {
            model: {
                type: Object,
                required: true
            },
            shipment: {
                type: Object,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },

            cartons: {
                type: Array,
                required: true
            },

            vShipment: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                default_size: ShipmentSize.get(),
                receiver_send_to_locker: true,
                empty_locker: '< uzupełnij >'
            }
        },
        mounted() {

        },
        computed: {
            receiver_locker_default() {
                if (!this.receiver.locker || !this.receiver.address.id) return this.empty_locker
                if (this.receiver.type !== 0) return this.empty_locker

                let department = this.$store.getters['departments/get'](this.receiver.address.id)

                console.log(department)

                return department.inpost_dropoff
            }
        },
        methods: {
            updateWeight(w) {
                this.cartons[0].weight = w

                this.$emit('update-weight')
            },
            updateSize(s) {
                this.shipment.size = s

                if(Object.keys(this.default_size).includes(this.shipment.size.toString())) {
                    let selected = this.default_size[this.shipment.size]

                    _.forEach(selected, (value, key) => {
                        this.shipment[key] = value
                    })
                }
            },
            toggleReceiverSendLocker(send) {
                this.receiver_send_to_locker = send

                if (!send) {
                    this.updateReceiverLocker(false)
                } else {
                    if (!this.receiver.locker.length && this.receiver_locker_default !== this.empty_locker) {
                        this.updateReceiverLocker({ target: { value: this.receiver_locker_default }})
                    }
                }
            },
            updateReceiverLocker(event) {
                if (!event) {
                    this.$emit('update-receiver-locker', '')
                } else {
                    this.$emit('update-receiver-locker', event.target.value)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .receiver-address [class^='col-'] {
        margin-bottom: 10px;
    }
</style>