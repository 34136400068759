<template lang="pug">
    .row
        OptiDimmer(:active="active")

        .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg(style="padding-bottom:15px")
            OptiButton(@click.native="$state.go('app.test.edit', {id: $state.params.id})") {{'test.table.EDITTITLE'|translate}}
                template(#icon)
                    i.fa.fa-edit
        .col-md-4.text-center(style="padding-bottom:15px")
            OptiButton(@click.native="$state.go('app.test.assign.edit', {id: $state.params.id})") {{'test.table.ASSIGN'|translate}}
                template(#icon)
                    i.fa.fa-user-plus
        .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg(style="padding-bottom:15px")
            OptiButton(@click.native="$state.go('app.test.do', {id: $state.params.id})") {{'test.table.DOTITLE'|translate}}
                template(#icon)
                    i.fa.fa-puzzle-piece

        .col-xs-12(v-if="test")
            .row
                .col-xs-offset-8.col-xs-2
                    span.mr3 Początek:
                    OptiTimePicker(
                        :disabled="!test.change"
                        :value="test.start_from"

                        @update-time="t => test.start_from = t")
                .col-xs-2
                    span.mr3 Koniec:
                    OptiTimePicker(
                        :disabled="!test.change"
                        :value="test.start_to"

                        @update-time="t => test.start_to = t")
                .col-xs-12.text-right
                    label(v-show="!test.change && $uac.permission('tests.edit')")
                        .checkbox.c-checkbox.checkbox-info.inline
                            label
                                input(type="checkbox" name="change" v-model="test.change")
                                span.fa.fa-check
                        span Zmień godziny
                .col-xs-12.text-right(style="margin-top:5px")
                    OptiButtonSave(v-show="test.change" :disabled="!test.change" @click.native="saveChanges")

        .col-xs-12(v-if="test")
            .panel.panel-primary(style="margin-top:5px")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'test.question.table.CAPTION'|translate}}
                    span.mr3 {{test.name}}
                    span \#{{test.id}}
                    .pull-right \#{{test.created_at|noYearSek}}
                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th(colspan="3" style="padding:0")
                        tbody(table-empty)
                            template(v-for="(q, index) in test.questions")
                                tr
                                    td(colspan="2")
                                        .bold.text-expressive
                                            div {{test.questions.length - index}}. {{q.question}}
                                            OptiTimer(:data="q.time")
                                    td(NOWRAP style="text-align:right")
                                        a(:href="uploadHost + '/test/question/' + q.picture" target="_blank"
                                            :title="'test.question.title.PICTURE'|translate" v-if="q.picture")
                                            img.img-rounded(style="width:150px"
                                                :src="uploadHost + '/test/question/' + q.picture")
                                        span \#{{q.id}}
                                tr
                                    td.text-center
                                        span.fa.fa-check.text-success(v-show="q.answer === 1")
                                    td {{q.replyA}}
                                    td {{q.pointsA}}
                                tr
                                    td.text-center
                                        span.fa.fa-check.text-success(v-show="q.answer === 2")
                                    td {{q.replyB}}
                                    td {{q.pointsB}}
                                tr
                                    td.text-center
                                        span.fa.fa-check.text-success(v-show="q.answer === 3")
                                    td {{q.replyC}}
                                    td {{q.pointsC}}
                                tr
                                    td.text-center
                                        span.fa.fa-check.text-success(v-show="q.answer === 4")
                                    td {{q.replyD}}
                                    td {{q.pointsD}}

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiTimer from '@/js/app/vue/components/Timer/OptiTimer'
    import OptiTimePicker from '@/js/app/vue/components/Test/OptiTimePicker'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiTestDetails',
        components: {OptiButtonSave, OptiTimePicker, OptiTimer, OptiDimmer, OptiButton},
        data() {
            return {
                uploadHost: window.__env.uploadHost,
                active: false,
                test: null,
                responsibility: {}
            }
        },
        mounted() {
            try {
                this.active = true
                API.get('tests/' + this.$state.params.id).then( res => {
                    this.test = res.data
                }).then(() => {
                    this.active = false
                })
            } catch(e) {

            }
        },
        methods: {
            saveChanges() {
                try {
                    this.active = true
                    API.post('tests/changeTestTime', {
                        test_id: this.$state.params.id,
                        start_from: this.test.start_from,
                        start_to: this.test.start_to
                    }).then(() => {
                        this.test.change = false
                        this.active = false
                    }).finally(() => {
                        this.$notify.success('test.notify.success.CHANGED')
                    })
                } catch (e) {
                    this.$notify.error('test.notify.error.CHANGEFAILED')
                }
            }
        }
    }
</script>