import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'
import {i18n} from './app/vue/i18n-setup'


export default function installFilters(VueInstance) {

    VueInstance.use(VueCurrencyFilter, {
        symbol: '',
        thousandsSeparator: ' ',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'back',
        symbolSpacing: true
    })

    VueInstance.filter('translate', function (value) {
        if (!value) return ''
        value = value.toString()
        return i18n.t(value)
    })

    VueInstance.filter('number', function (value, digits = 2, defaultValue = 0) {
        return VueInstance.options.filters['currency'](value || defaultValue, '', ' ', digits, ',', 'front', false)
    })

    VueInstance.filter('plus', function (value, none) {
        if (typeof none !== 'undefined' && parseInt(value) === parseInt(none)) {
            return 'brak'
        }

        return (value >= 0 ? '+' : '') + VueInstance.options.filters['number'](value, 2)
    })

    VueInstance.filter('none', function (value, none, mask) {
        if(parseInt(value) === parseInt(none)) {
            return typeof mask === 'undefined' ? 'brak' : mask
        }

        return value
    })

    VueInstance.filter('nl2br', function(value) {
        if(value != null) {
            return value.replace(/\n/g, '<br>');
        }
    })

    VueInstance.filter('mm', value => {
        return value + ' mm'
    })

    VueInstance.filter('kg', value => {
        return VueInstance.options.filters['currency'](value) + ' kg'
    })

    VueInstance.filter('day', value => {
        return value + ' ' + (parseInt(value) === 1 ? i18n.t('default.day.ONE') : i18n.t('default.day.MORE'))
    })

    VueInstance.filter('dateWithoutPastYear', value => {
        if(value && (value.length === 10 || value.length === 19)) {
            let result = '',
                tab    = value.split(' '),
                time   = tab[0].split('-'),
                date   = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]))
            // na chromie wystarczy new Date(value) niestety safari jest głupie i potrzebuje to co jest wyżej ...

            if(date.getFullYear() !== new Date().getFullYear()) {
                result = date.getFullYear().toString() + '-'
            }

            return result + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }

        return value
    })

    VueInstance.filter('noYearSek', value => {
        if(value !== null && typeof value !== 'undefined' && value.length === 19) {
            let result = '',
                tab = value.split(' '),
                time = tab[0].split('-'),
                MH = tab[1].substr(0, 5),
                date = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]))
            // na chromie wystarczy new Date(value) niestety safari jest głupie i potrzebuje to co jest wyżej ...

            if(date.getFullYear() !== new Date().getFullYear()) {
                result = date.getFullYear().toString() + '-'
            }

            return result + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + MH
        }

        return value
    })

    VueInstance.filter('noYear', value => {
        if(typeof value !== 'undefined' && value.length === 19) {
            let result = '',
                tab = value.split(' '),
                time = tab[0].split('-'),
                MHS = tab[1],
                date = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]))
            // na chromie wystarczy new Date(value) niestety safari jest głupie i potrzebuje to co jest wyżej ...

            if(date.getFullYear() !== new Date().getFullYear()) {
                result = date.getFullYear().toString() + '-'
            }

            return result + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + MHS
        }

        return value
    })

    VueInstance.filter('stringTimeWithoutYear', value => {
        if(value && (value.length === 10 || value.length === 19)) {
            let result = '',
                tab    = value.split(' '),
                time   = tab[0].split('-'),
                date   = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]))
            // na chromie wystarczy new Date(value) niestety safari jest głupie i potrzebuje to co jest wyżej ...

            if (date.getFullYear() !== new Date().getFullYear()) {
                result = date.getFullYear().toString() + '-'
            }

            return result + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }

        return value
    })

    VueInstance.filter('stringTime', value => {
        if(!value) {
            return value
        }

        let split = value.split(' '),
            objDate = {
                y: parseInt(split[0].substring(0, 4)),
                m: parseInt(split[0].substring(5, 7)) - 1,
                d: parseInt(split[0].substring(8, 10))
            },
            date = new Date(objDate.y, objDate.m, objDate.d),
            current = new Date(),
            back = new Date()

        back.setDate(current.getDate() - 1)

        let year = date.getFullYear(),
            month = date.getMonth(),
            day = date.getDate(),

            c = {
                year: current.getFullYear(),
                month: current.getMonth(),
                day: current.getDate()
            },

            y = {
                year: back.getFullYear(),
                month: back.getMonth(),
                day: back.getDate()
            }

        if(year === c.year && month === c.month && day === c.day) {
            return i18n.t('default.TODAY') + (split.length === 2 ? (' ' + split[1]) : '')
        } else if(year === y.year && month === y.month && day === y.day) {
            return i18n.t('default.YESTERDAY') + (split.length === 2 ? (' ' + split[1]) : '')
        }

        return value
    })

    VueInstance.filter('secondsToHuman', value => {
        let datetime = new Date(1970, 0, 1).setSeconds(value),
            human = moment(datetime).format('HH:mm:ss')

        if(human.substring(0, 2) === '00') {
            human = human.substring(3)

            if(human.substring(0, 2) === '00') {
                human = parseInt(human.substring(3)) + 's'
            }
        }

        return human
    })

    VueInstance.filter('receipt', value => {
        if(value && value.includes('/')) {
            return value.substr(value.indexOf('/') + 1)
        }

        return value
    })

    VueInstance.filter('phone', value => {
        if(typeof value === 'string' && value.length === 9) {
            return value.substr(0, 3) + ' ' + value.substr(3, 3) + ' ' + value.substr(6, 3)
        }

        return value
    })

    VueInstance.filter('lower', value => {
        if(typeof value === 'string') {
            return value.toLowerCase()
        }

        return value
    })

    VueInstance.filter('upload', value => {
        return __env.uploadHost + '/' + value
    })

    VueInstance.filter('points', value => {
        return value + ' ' + VueInstance.options.filters['translate']('default.filter.POINTS')
    })

    VueInstance.filter('attributeSelect', (value, options) => {
        let parse = {}

        options.split('&&').forEach(option => {
            let value = option.split('/')

            if(value[0] && value[1]) {
                parse[value[0].trim()] = value[1].trim()
            }
        })

        return Object.keys(parse).length ? parse[value] : false
    })
}
