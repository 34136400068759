<template lang="pug">
    div
        .row
            .col-xs-5
                h4.page-header(style="margin-top:0px") {{'cards.breadcrumb.LOYALITYCARDS'|translate}}

            .col-xs-12(v-if="!active")
                ul.nav.nav-tabs.nav-justified.icons-header-tab
                    li(:class="{'active': active_tab === 'list'}")
                        a(:title="'cards.LIST'|translate" @click="setActive('list')")
                            i.visible-xs-inline.fa-lg.fa.fa-list.mr3
                            i.hidden-xs.fa.fa-list
                            span.visible-xs-inline.tab-title

                    li(:class="{'active': active_tab === 'new', 'disabled': !$uac.permission('patients.print_loyalty_card')}")
                        a(:title="'cards.NEW'|translate" @click="setActive('new')")
                            i.visible-xs-inline.fa-lg.fa.fa-star.mr3
                            i.hidden-xs.fa.fa-star
                            span.visible-xs-inline.tab-title

                    li(:class="{'active': active_tab === 'printed', 'disabled': !$uac.permission('patients.print_loyalty_card')}")
                        a(:title="'cards.GENERATED'|translate" @click="setActive('printed')")
                            i.visible-xs-inline.fa-lg.fa.fa-file-text.mr3
                            i.hidden-xs.fa.fa-file-text
                            span.visible-xs-inline.tab-title

                .tab-content
                    .row(v-show="active_tab === 'list'")
                        .col-xs-12(style="margin-top:10px")
                            AngularComponent(:component="list")

                    OptiGeneratedList.tab-pane(:class="{'active': active_tab === 'printed'}"
                        :reload_printed="reload_printed"
                        @reloaded="reload_printed = false")

                    OptiNewRequests.tab-pane(:class="{'active': active_tab === 'new'}"
                        @card-saved="reloadPrinted()")
</template>

<script>
import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
import OptiNewRequests from '@/js/app/vue/components/Patients/LoyalityCard/OptiNewRequests'
import OptiGeneratedList from '@/js/app/vue/components/Patients/LoyalityCard/OptiGeneratedList'

export default {
    name: 'OptiPatientLoyalityCard',
    components: {OptiGeneratedList, OptiNewRequests, AngularComponent},
    data() {
        return {
            active: false,
            active_tab: 'list',
            reload_printed: false
        }
    },
    mounted() {
        if(!this.$state.params.page) {
            this.$state.go('app.patients.cards.list', {
                page: 1
            })
        }
    },
    computed: {
        list() {
            return {
                template: `<base-table end-point="/api/patients/table/loyality" ref="app.patients.cards.list" header="Lista"></base-table>`,
                $ctrl: {}
            }
        }
    },
    methods: {
        reloadPrinted() {
            this.reload_printed = true
            this.active_tab = 'printed'
        },
        setActive(hash) {
            this.active_tab = hash
        }
    }
}
</script>

<style scoped>
    .disabled {
        pointer-events:none;
        opacity:0.6;
    }
</style>