<template lang="pug">
    .col-xs-12(style="margin-top:15px")
        .row(v-show="task['hoyaLens' + (isCompare ? '2' : '')].identifier || task.rLensPriceGroup.identifier")
            .col-xs-12
                form.form-horizontal(style="margin:10px 7px")
                    .form-group
                        label.col-xs-2.control-label(for="iDentifier") iDentifier
                        .col-xs-10
                            input#iDentifier.form-control.text-center(disabled v-model="task['manufacturerParam1' + field]")

            .col-xs-12.text-center(v-show="allowEditConfirmed")
                OptiButton(type="info" style="margin-bottom:10px" :disabled="loading.iDentifier"
                    @click.native="goiDentifier") {{'tasks.lenses.identifier.confirm.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-send-o

            .col-xs-12(v-show="task['manufacturerParam1Lens' + field]")
                form.form-horizontal
                    .form-group(style="margin-bottom:0")
                        label.col-xs-2.control-label {{'tasks.lenses.identifier.suggestion.HEADING'|translate}}
                        .col-xs-10
                            .form-control-static.text-center {{task['manufacturerParam1Lens' + field].split(' id: ')[0]}}

            .col-xs-12.text-center(style="margin-bottom:20px" v-show="task['manufacturerParam1Lens' + field]")
                OptiButton(type="success" v-show="allowEditConfirmed && ((!task['hoya_lens_id' + field] && !task['manufacturerParam1Lens' + field].endsWith(task.rLensPriceGroup.code)) || (task['hoya_lens_id' + field] && task['manufacturerParam1Lens' + field].includes(' id: ') && !task['manufacturerParam1Lens' + field].startsWith(task['rightLens' + (isCompare ? '2' : '')].partName)))"
                    text="tasks.lenses.identifier.suggestion.confirm" @confirmed="setiDentifierSuggestion"
                    ) {{'tasks.lenses.identifier.suggestion.confirm.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-exchange

                .text-success(v-show="task['manufacturerParam1Lens' + field].endsWith(task.rLensPriceGroup.code) || task['manufacturerParam1Lens' + field].startsWith(task['rightLens' + (isCompare ? '2' : '')].partName)")
                    i.fa.fa-check.mr3
                    span {{'tasks.lenses.identifier.suggestion.checked.YES'|translate}}
                .warn(v-show="!allowEditConfirmed && ((!task['hoya_lens_id' + field] && !task['manufacturerParam1Lens' + field].endsWith(task.rLensPriceGroup.code)) || (task['hoya_lens_id' + field] && !task['manufacturerParam1Lens' + field].startsWith(task['rightLens' + (isCompare ? '2' : '')].partName)))")
                    i.fa.fa-remove.mr3
                    span {{'tasks.lenses.identifier.suggestion.checked.NO'|translate}}

        OptiTaskDetailsLensesRanges(:ranges="task['ranges' + field]")

        .row(v-if="!isOffer")
            .col-md-6.col-xs-12
                form.form-horizontal(style="margin:10px 7px")
                    .form-group
                        label.col-xs-4.control-label(for="hoyaId") HoyaID
                        .col-xs-8
                            input#hoyaId.form-control.text-center(
                                :disabled="!!task['ranges' + field].errors.length || disabledInputHoyaID"
                                v-model.lazy="task['manufacturerCustomId' + field]" @change="$emit('lenses-was-updated')")
            .col-md-6.col-xs-12.text-center
                OptiButton(type="info" style="margin-top:10px;margin-bottom:10px" :disabled="!!task['ranges' + field].errors.length || loading.hoya"
                    v-show="(allowEditConfirmed || allowAfterPutBackAndSupply) && !(task['hoyaLens' + (isCompare ? '2' : '')].identifier && !task['manufacturerParam1' + field]) && !task['manufacturerCustomId' + field]"
                    @click.native="goHoyaPage") {{'tasks.lenses.ilog.SENDORDER'|translate}}
                    template(#icon)
                        i.fa.fa-send-o

                OptiButton(type="info" style="margin-top:10px;margin-bottom:10px"
                    v-show="task['manufacturerCustomId' + field]" @click.native="openHoyaPage") {{'tasks.lenses.ilog.OPENORDER'|translate}}
                    template(#icon)
                        i.fa.fa-folder-open-o
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {HoyaHelper} from '@/js/app/vue/helpers/HoyaHelper'
    import validateTask from '../../TaskValidator'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsLensesRanges
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesRanges'

    export default {
        name: 'OptiTaskDetailsLensesHoya',
        components: {
            OptiButton,
            OptiTaskDetailsLensesRanges
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },

            allowEditConfirmed: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                debug_hoya: false,
                field: this.isCompare ? '_2' : '',

                loading: {
                    iDentifier: false,
                    hoya: false
                }
            }
        },
        watch: {
            'task.ranges'(nVal) {
                this.ranges(nVal)
            },
            'task.ranges_2'(nVal) {
                this.ranges(nVal)
            }
        },
        methods: {
            ranges(nVal) {
                if(nVal.errors.length && this.task['manufacturerCustomId' + this.field] && !this.disabledInputHoyaID) {
                    this.task['manufacturerCustomId' + this.field] = ''

                    this.$emit('lenses-was-updated')
                }
            },
            goiDentifier() {
                let warnings = validateTask(this.task, 'hoya')

                if(!warnings.length) {
                    if(confirm(this.$filters.translate('tasks.lenses.identifier.confirm.MESSAGE'))) {
                        this.loading.iDentifier = true

                        API.get('task/identifier/generate-link/' + (this.isOffer ? 'offer' + (this.isCompare ? '_compare' : '') : 'task') + '/' + this.task.id).then(res => {
                            window.location = res.data
                        }).finally(() => {
                            this.loading.iDentifier = false
                        }).catch(() => {
                            this.$notify.error('tasks.lenses.identifier.notify.error.LOAD')
                        })
                    }
                } else {
                    warnings.forEach(warning => {
                        this.$notify.error(warning, true, true)
                    })
                }
            },
            setiDentifierSuggestion() {
                let task_lens_type = {
                    1: 'Single',
                    2: 'Single',
                    3: 'MULTI',
                    4: 'PROG',
                }

                if(this.task['manufacturerParam1Lens' + this.field].includes(' id: ')) {
                    let ids = this.task['manufacturerParam1Lens' + this.field].split(' id: ')[1].split(' cena: ')[0].split(',')

                    if(ids.length > 1) {
                        ['right', 'left'].forEach(side => {
                            let field = side + 'Lens' + (this.isCompare ? '2' : '')

                            this.task[field].productId = 0
                            this.task[field].partName = this.task['manufacturerParam1Lens' + this.field].split(' id: ')[0]
                            this.task[field].manufacturer = 'Hoya'
                            this.task[field].type = task_lens_type[this.task.type]
                            this.task[field].price = parseFloat(this.task['manufacturerParam1Lens' + this.field].split(' cena: ')[1])
                        })

                        this.task['hoya_lens_id' + this.field] = ids[0]
                        this.task['hoya_lens_category_id' + this.field] = ids[1]

                        let options = []
                        ids.forEach(id => {
                            if(id.includes('-')) {
                                let part = id.split('-')

                                let option_id = parseInt(part[0]),
                                    price = parseFloat(part[1])
                                options[option_id] = {
                                    price: price
                                }
                            }
                        })
                        this.task['hoyaOptions' + (this.isCompare ? '2' : '')] = options
                    }
                }

                this.$emit('lenses-was-updated')
            },

            goHoyaPage() {
                let warnings = validateTask(this.task, 'hoya')

                if(!warnings.length) {
                    this.loading.hoya = true

                    API.get('task/hoya/' + (this.isOffer ? 'offer' + (this.isCompare ? '_compare' : '') : 'task') +
                        '/' + this.task.id + (this.debug_hoya ? 'xml' : '')).then(res => {
                        if(res.data.link) {
                            window.open(res.data.link)
                        }

                        if(res.data.orderId) {
                            this.task['manufacturerCustomId' + this.field] = res.data.orderId

                            this.$emit('lenses-was-updated')

                            this.$notify.success('Wysłano zamówienie do Hoya iLog. Uaktualniono HoyaID na ' + res.data.orderId + '.', false, true)
                        }

                        if(res.data.error) {
                            this.$notify.error(res.data.error, false, true)
                        }

                        this.changeiLog()

                    }).catch(() => {
                        this.$notify.error('tasks.logs.hoyailog.notify.error.NOLINK')
                    })
                } else {
                    warnings.forEach(warning => {
                        this.$notify.error(warning, true, true)
                    })
                }
            },
            changeiLog() {
                API.get('task/change/ilog/' + (this.isOffer ? 'offer' + (this.isCompare ? '_compare' : '') : 'task') + '/' + this.task.id + '/' + this.task.status).then(res => {
                    if(res.data.status === 'OK') {
                        this.$emit('register-change')
                    } else {
                        this.$notify.error('tasks.logs.hoyailog.notify.error.NOSAVECHANGE')
                    }
                }).finally(() => {
                    this.loading.hoya = false
                }).catch(() => {
                    this.$notify.error('tasks.logs.hoyailog.notify.error.NOSAVECHANGE')
                })
            },
            openHoyaPage() {
                HoyaHelper.openHoyaPage(this.task['manufacturerCustomId' + this.field])
            }
        },
        computed: {
            allowAfterPutBackAndSupply() { //to samo TasksSaveController.php:161, 177
                return Object.keys(this.task.attributes).includes('putBack') && [2, 3].includes(this.task.status) && !this.task.has_supply
            },
            disabledInputHoyaID() {
                return (!this.allowEditConfirmed && !this.allowAfterPutBackAndSupply) ||
                    (this.task['hoyaLens' + (this.isCompare ? '2' : '')].identifier && !this.task['manufacturerParam1' + this.field])
            }
        }
    }
</script>
