<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.breadcrumb.SPECIFICATION'|translate}} \#{{specification.id}}

                .panel-body
                    .row
                        .col-xs-8
                            .row
                                .col-sm-4.bold {{'specification.table.tasks.ID'|translate}}
                                .col-sm-8 {{specification.id}}
                            .row
                                .col-sm-4.bold {{'specification.table.tasks.SALON_NAME'|translate}}
                                .col-sm-8 {{specification.departmentName}}
                            .row
                                .col-sm-4.bold {{'specification.table.tasks.TASKS_QTY'|translate}}
                                .col-sm-8 {{specification.tasks_qty}}
                            .row
                                .col-sm-4.bold {{'specification.table.tasks.TASKS_VALUE'|translate}}
                                .col-sm-8 {{specification.tasks_value|currency}}
                            .row
                                .col-sm-4.bold {{'specification.table.tasks.CREATED_AT'|translate}}
                                .col-sm-8 {{specification.created_at}}
                        .col-xs-4
                            OptiButton(type="default" @click.native="excelExport") {{'specification.table.tasks.EXPORTEXCEL'|translate}}
                              template(#icon)
                                i.fa.fa-file-excel-o
                .row
                    .col-xs-12
                        .table-responsive
                            table.table.table-striped
                                thead
                                    tr
                                        th #
                                        th {{'tasks.TASKID'|translate}}
                                        th {{'tasks.FRAMENAME'|translate}}
                                        th {{'tasks.RL'|translate}}
                                        th {{'tasks.LL'|translate}}
                                        th {{'tasks.SERVICE'|translate}}
                                        th {{'specification.create.EXTRAS'|translate}}
                                        th {{'specification.table.tasks.NETTO'|translate}}
                                tbody
                                    tr(v-for="(detail, index) in specification.details")
                                        td.text-right  {{index + 1}}
                                        td.text-right
                                            a.link(:href="$state.href('app.task.detail', {id: detail.taskId})" target="_blank"
                                                ) {{detail.taskId}}
                                        td {{detail.frame_name}}
                                        td {{detail.rl_lens_name}}
                                        td {{detail.ll_lens_name}}
                                        td {{detail.assembly_name}}
                                        td
                                            div(v-for="extra in detail.extras") {{extra}}
                                        td.text-right {{detail.total_netto_price|currency}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import {EventBus} from "@/js/app/_bridge/EventBus";

    export default {
        name: 'OptiTaskSpecification',
        components: {
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                specification: {},

                loading: true
            }
        },

        mounted() {
            API.get('specifications/tasks/details/' + this.$state.params.id).then(res => {
                this.specification = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('specification.table.tasks.notify.error.LOAD')
            })
        },
        methods: {
            excelExport() {
                let filename = 'specifications/tasks/prace_specyfikacja_nr_' + this.specification.id + '_' + this.specification.departmentName + '.xlsx'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: filename,
                        status: true
                    }
                })

                API.post('specifications/tasks/details/export', {
                    id: this.$state.params.id,
                    filename: filename
                }).catch(() => {
                    this.$notify.error('specification.table.tasks.notify.error.LOAD')
                })
            }
        }
    }
</script>