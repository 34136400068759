<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-if="!loading")
			form#form-marketing.form-horizontal(name="formPayments" @submit.prevent="editPayments")
				.form-group
					label.col-xs-4.control-label.star-required(for="label") {{'payment.card.NAME'|translate}}
					.col-xs-8
						input#label.form-control(name="label" v-model="payment.label")
						OptiValidate(f="label" :data="$v.payment.label")
				.form-group
					label.col-xs-4.control-label.star-required(for="description") {{'payment.card.DESCRIPTION'|translate}}
					.col-xs-8
						textarea#description.form-control(name="description"
							v-model="payment.description" rows="7")
						OptiValidate(f="description" :data="$v.payment.description")
				.form-group
					label.col-xs-4.control-label.star-required(for="ccFee") {{'payment.card.PROVISION'|translate}}
					.col-xs-8
						OptiNumber(:header="'payment.card.PROVISION'|translate" :value="payment.ccFee" :float="true"
							@confirmed="value => payment.ccFee = value") {{payment.ccFee}}
						OptiValidate(f="ccFee" :data="$v.payment.ccFee")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.payments.list')"
							) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.payment.$invalid")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>

<script>
	import {API} from '../../../api'
	import {required} from 'vuelidate/lib/validators'
	import {noZero} from '@/js/vue.validators.js'
	import {unique} from '../../../../../vue.validators'

	import OptiButton from '../../Button/OptiButton'
	import OptiNumber from '../../Inputs/OptiNumber'
	import OptiValidate from '../../OptiValidate'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiDimmer from '../../Blocks/OptiDimmer'

	export default {
		name: 'OptiDictionaryPaymentsEdit',
		components: {
			OptiButton,
			OptiNumber,
			OptiValidate,
			OptiButtonSave,
			OptiDimmer
		},
		data() {
			return {
				payment: {},
				loading: true
			}
		},
		validations() {
			return {
				payment: {
					label: {
						required,
						unique: unique('cc_types', 'LABEL', this.payment.id, 'PK_UID')
					},
					description: {
						required
					},
					ccFee: {
						noZero
					}
				}
			}
		},
		mounted() {
			API.get('payments/' + this.$state.params.id).then(res => {
				this.payment = res.data
			}).finally(() => {
				this.loading = false
			}).catch(() => {
				this.$notify.error('payment.card.notify.error.LOAD')
			})
		},
		methods: {
			editPayments() {
				this.loading = true

				API.put('payments', this.payment).then(() => {
					this.$notify.success('payment.card.notify.UPDATED')

					this.$state.go('app.payments.list')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('payment.card.notify.error.UPDATED')
				})
			}
		}
	}
</script>