<template lang="pug">
    div
        #btn-group-patient-detail.row(v-if="!loading")
            .col-md-3.text-left.text-center-sm.text-center-xs.text-left-lg
                OptiButton(type="info" @click.native="goPrintCSVDelivery") {{'smile.details.csv.DELIVERY'|translate}}
                    template(#icon)
                        i.fa.fa-file-excel-o
            .col-md-3.text-center
                OptiButton(type="info" :disabled="!smile.quantityprod.news" @click.native="goPrintCSVNew") {{'smile.details.csv.NEW'|translate}}
                    template(#icon)
                        i.fa.fa-file-excel-o
            .col-md-3.text-center
                OptiButton(type="info" :disabled="!smile.quantityprod.temp_product" @click.native="goPrintCSVTemp") Tymczasowe
                    template(#icon)
                        i.fa.fa-file-excel-o
            .col-md-3.text-center-sm.text-center-xs.text-right-md.text-right-lg
                OptiButton(type="info" @click.native="goPrint") {{'smile.details.PRINT'|translate}}
                    template(#icon)
                        i.fa.fa-print

        .row(v-if="!loading")
            .col-md-8.col-xs-12
                .panel-grid
                    .panel.panel-primary
                        .row
                            .col-sm-6 {{'smile.table.TYPE'|translate}}
                            .col-sm-6 {{smile.type}}
                        .row
                            .col-sm-6 Id
                            .col-sm-6 {{smile.id}}
                        .row
                            .col-sm-6 {{'smile.table.ORDER'|translate}}
                            .col-sm-6 {{smile.number}}
                        .row
                            .col-sm-6 {{'smile.table.DATE'|translate}}
                            .col-sm-6 {{smile.date}}
                        .row(v-show="smile.delivery")
                            .col-sm-6 {{'smile.table.DELIVERY'|translate}}
                            .col-sm-6 {{smile.delivery}}
                        .row
                            .col-sm-6 {{'smile.table.INVOICE'|translate}}
                            .col-sm-6 {{smile.invoice}}
                        .row
                            .col-sm-6 {{'smile.details.quantityprod.HEADING'|translate}}
                            .col-sm-6
                                span.bold.mr3 {{'smile.details.quantityprod.DELIVERY'|translate}}
                                span {{smile.quantityprod.delivery}}
                                br
                                span.bold.mr3 {{'smile.details.quantityprod.NEW'|translate}}
                                span {{smile.quantityprod.news}}
                                br
                                span.bold.mr3 Tymczasowe
                                span {{smile.quantityprod.temp_product}}
                                br
                                span.bold.mr3 {{'smile.details.quantityprod.SUM'|translate}}
                                span {{smile.quantityprod.delivery + smile.quantityprod.news + smile.quantityprod.temp_product}}

            .col-md-4.col-xs-12
                OptiUpload#temporary_products(
                    style="margin-top: 10px"
                    prefix="temporary_products"
                    :file="file"
                    @update-file="f => file = f"
                )

                OptiButton.pull-right(
                    style="margin-top: 5px"
                    @click.native="updateTempProductFromFile"
                    :disabled="!file"
                ) Wczytaj plik.

            .col-xs-12.text-right.text-expressive
                a.pointer.mr3(@click="toggle(true)") {{'smile.details.toggle.SHOW'|translate}}
                span.mr3 /
                a.pointer.mr3(@click="toggle(false)") {{'smile.details.toggle.HIDE'|translate}}
                span {{'smile.details.toggle.ALL'|translate}}

            .col-xs-12
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'smile.details.HEADING'|translate}}

                    .table-responsive
                        table.table.table-striped.middle.table-condensed
                            thead
                                tr
                                    th
                                    th.text-right #
                                    th.text-right Id
                                    th {{'smile.details.ORDER'|translate}}
                                    th SKU Smile
                                    th {{'smile.details.TYPE'|translate}}
                                    th Brand
                                    th {{'smile.details.QUANTITY'|translate}}
                                    th Net
                                    th {{'smile.details.TRACKING'|translate}}
                                    th.text-right {{'smile.details.PRODUCTID'|translate}}
                                    th {{'smile.details.PRODUCTNAME'|translate}}
                                    th Kl.
                                    th M.
                                    th {{'smile.details.CODECOLOR'|translate}}
                                    th {{'smile.details.CODE'|translate}}
                                    th Temp
                                    th {{'smile.details.RECEIVED'|translate}}
                            tbody
                                template(v-for="item in smile.items")
                                    tr.pointer(@click="item.show = !item.show")
                                        td.text-center.bg-info-dark(colspan="100")
                                            span.mr3 {{item.departments[0].department.name}}
                                            small ({{'smile.details.DEPARTMENTID'|translate}} {{item.departments[0].departmentSmileId}})

                                    tr(v-show="item.show && (item.allow_order || item.orders.length)")
                                        td
                                            .checkbox.c-checkbox.checkbox-primary.inline(v-if="item.allow_order")
                                                label
                                                    input(type="checkbox" :name="item.id"
                                                        v-model="item.selected_all" @change="selectAll(item)")
                                                    span.fa.fa-check
                                        td(colspan="100")
                                            OptiButton(type="info" v-show="item.allow_order" :disabled="checkIfDisableOrder(item.departments)"
                                                text="smile.details.order.btn" @confirmed="createOrder(item.departments[0].department.id)"
                                            ) {{'smile.details.order.btn.HEADING'|translate}}
                                                template(#icon)
                                                    i.fa.fa-truck

                                            span(style="padding-left:15px" v-show="item.orders.length")
                                                span.mr3 {{'smile.details.order.MANY'|translate}}:
                                                a.link.mr3(v-for="order in item.orders" :href="$state.href('app.warehouse.order.details', {id: order})"
                                                    target="_blank" :title="'smile.details.order.TITLE'|translate") {{order}}

                                            .clearfix

                                    tr(v-for="(item_department, key) in item.departments" v-show="item.show")
                                        td
                                            .checkbox.c-checkbox.checkbox-primary.inline(v-if="(!item_department.ordered && item_department.productId) && (item_department.product && !item_department.product.temp_product)")
                                                label
                                                    input(type="checkbox" :name="item_department.id"
                                                        v-model="item_department.checkbox" @change="select(item)")
                                                    span.fa.fa-check
                                            span.fa.fa-clock-o(v-if="(item_department.product && item_department.product.temp_product)")
                                        td.text-right {{key + 1}}
                                        td.text-right {{item_department.id}}
                                        td {{item_department.order}}
                                        td {{item_department.sku}}
                                        td {{item_department.category}}
                                        td {{item_department.brand}}
                                        td {{item_department.quantity}}
                                        td {{item_department.netValue}}
                                        td {{item_department.barcode}}
                                        td.text-right
                                            OptiProduct(v-if="item_department.productId" :product="item_department.product"
                                            ) {{item_department.product.id}}
                                        td
                                            OptiProduct(v-if="item_department.productId" :product="item_department.product"
                                            ) {{item_department.product.name}}
                                            span(v-show="!item_department.productId") {{item_department.description}}
                                        td {{item_department.eyesSize}}
                                        td {{item_department.bridgeSize}}
                                        td {{item_department.colorCode}}
                                        td {{item_department.lensCode}}
                                        td {{item_department.templeSize}}
                                        td {{item_department.delivered}}

        OptiDimmer(:active="loading")

</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
import OptiUpload from "@/js/app/vue/components/OptiUpload.vue";

export default {
    name: 'OptiSmileMoreDetails',
    components: {
        OptiUpload,
        OptiButton,
        OptiProduct,
        OptiDimmer
    },
    props: {


    },
    data() {
        return {
            smile: {},

            file: null,
            loading: true
        }
    },
    mounted() {
        API.get('warehouse/smile/details/' + this.$state.params.id).then(res => {
            this.smile = res.data

            this.smile.type = this.smile.delivery ? 'More' : 'Smile'
            this.$emit('update-breadcrumb', this.smile.type)

            this.smile.quantityprod = {
                delivery: 0,
                news: 0,
                temp_product: 0
            }

            let items = {}

            this.smile.items.forEach(item => {
                if(!Object.keys(items).includes(item.departmentId.toString())) {
                    items[item.departmentId] = {
                        departments: [],
                        count: 0,
                        show: true,
                        selected_all: false,
                        orders: [],
                        allow_order: false
                    }
                }

                items[item.departmentId].departments.push(item)
                ++items[item.departmentId].count

                if(item.ordered && !items[item.departmentId].orders.includes(item.ordered)) {
                    items[item.departmentId].orders.push(item.ordered)
                }

                if(item.productId && !item.product.temp_product) {
                    this.smile.quantityprod.delivery += item.quantity

                    if(!item.ordered) {
                        items[item.departmentId].allow_order = true
                    }
                }
                if(item.product && item.product.temp_product){
                    this.smile.quantityprod.temp_product += item.quantity
                }
                if(!item.productId && !item.product.temp_product) {
                    this.smile.quantityprod.news += item.quantity
                }
                items[item.departmentId].departments.sort((a,b) => {
                    return a.product.name < b.product.name ? -1 : 1
                })
            })

            this.smile.items = items
            console.log(items)
        }).finally(() => {
            this.loading = false
        }).catch(() => {
            this.$notify.error('smile.details.notify.error.LOADING')
        })
    },
    methods: {
        updateTempProductFromFile() {
            this.loading = true

            API.post('warehouse/smile/updateTemporaryProducts', {
                upload: this.file
            }).then(() => {
                this.file = null
            }).then(() => {
                this.$notify.success('Produkt został zaktualizowany.')
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('Nie udało się wgrać pliku!')
            })
        },
        goPrintCSVDelivery() {
            this.loading = true

            API.get('warehouse/smile/csv/delivery/' + this.$state.params.id).then(res => {
                window.open(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('smile.details.notify.error.CSV')
            })
        },
        goPrintCSVTemp() {
            this.loading = true

            API.get('warehouse/smile/csv/temp/' + this.$state.params.id).then(res => {
                window.open(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('smile.details.notify.error.CSV')
            })
        },
        goPrintCSVNew() {
            this.loading = true

            API.get('warehouse/smile/csv/new/' + this.$state.params.id).then(res => {
                window.open(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('smile.details.notify.error.CSV')
            })
        },
        goPrint() {
            window.open('api/warehouse/smile/print/' + this.$state.params.id)
        },

        checkIfDisableOrder(departments) {
            let _return = true

            departments.forEach(department => {
                if(department.checkbox) {
                    _return = false
                }
            })

            return _return
        },

        toggle(bool) {
            _.forEach(this.smile.items, item => {
                item.show = bool
            })
        },

        selectAll(item) {
            item.departments.forEach(department => {
                if(!department.ordered && department.productId) {
                    department.checkbox = item.selected_all
                }
            })
        },
        select(item) {
            item.selected_all = true

            item.departments.forEach(department => {
                if(!department.ordered && department.productId && !department.checkbox) {
                    item.selected_all = false
                }
            })
        },

        createOrder(department_id) {
            this.loading = true

            let item_ids = []
            this.smile.items[department_id].departments.forEach(department => {
                if(department.checkbox) {
                    item_ids.push(department.id)
                }
            })

            API.post('warehouse/smile/order', {
                smile_id: this.smile.id,
                department_id: department_id,
                type: this.smile.type,
                items: item_ids
            }).then(res => {
                this.$state.go('app.warehouse.order.details', {id: res.data})

                this.$notify.success('smile.details.notify.ORDER')
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('smile.details.notify.error.ORDER')
            })
        }
    }
}
</script>