<template lang="pug">
    table.table.table-month.text-center.table-schedule.table-mark-past
        thead
            tr
                th.text-center {{'schedule.directive.view.header.WEEK'|translate}}
                th(v-for="day in days") {{day.label.short}}
        tbody
            tr(style="cursor:pointer" v-for="(week, key) in events" :class="key % 2 ? 'mark-even' : 'mark-odd'")
                td.bg-separator.middle(@click="openViewWeek(week.week)") {{week.week}}
                td.event-empty(v-for="(data, date) in week.data" @click="openViewDay(date)"
                    :class="{'is-past': data.past, 'is-today': data.today}" :style="getMonthStyle(week, data)")
                    .label-month-day(:class="{'noactive': !data.active}")
                        span.pull-left.warn(style="max-width:50%;font-size:.8em;text-align:left" v-if="holidays[date]") {{holidays[date]|translate}}
                        span.pull-left(style="color:navy;font-size:.8em" v-if="specialEvents[date]") {{specialEvents[date]}}
                        span.pull-right
                            span.text-muted.mr3(v-show="mode === 'quarter'") {{monthName(date)}}
                            span {{data.day}}
                        .clearfix
                    .month-event-user(v-for="user in data.users" :class="user.class" :title="user.user_name")
                        span.month-hours.mr3 {{user.hours.start}}-{{user.hours.end}}
                        .label.label-inverse.mr3(style="display:inline-block" v-for="type in user.types") {{type}}
                        span(:class="{'no-active': !user.active}") {{user.user_abbr}}
</template>

<script>
    export default {
        name: 'OptiScheduleMonthQuarter',
        components: {

        },
        props: {
            view: {
                type: Object,
                required: true
            },

            holidays: {
                type: Object,
                required: true
            },
            specialEvents: {
                type: Object,
                required: true
            },

            days: {
                type: Array,
                required: true
            },
            events: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                mode: this.events.length > 5 ? 'quarter' : 'month'
            }
        },
        methods: {
            monthName(date) {
                return moment(date).format('MMMM')
            },

            openViewDay(day) {
                this.view.start = moment(day).startOf('day')

                this.view.type = 'day'

                this.$emit('render-navigation')
            },

            openViewWeek(weekNumber) {
                let year = this.view.start.format('GGGG')

                if(weekNumber < 10) {
                    weekNumber = '0' + weekNumber
                }

                this.view.start = moment(year + 'W' + weekNumber).startOf('day')

                this.view.type = 'week'

                this.$emit('render-navigation')
            },

            getMonthStyle(week, item) {
                if(this.mode === 'month') {
                    return {}
                }

                if(!week.bordering) return {}

                let style = {}

                if(item.day > 20) {
                    style['border-bottom'] = '3px solid black'
                } else {
                    style['border-top'] = '3px solid black'
                }

                if(item.day === 1) {
                    style['border-left'] = '3px solid black'
                }

                return style
            }
        }
    }
</script>