import {UAC} from '@/js/app/vue/UserAccessControl'
import Notify from '@/js/app/vue/helpers/Notify'

class OptometristsDisplayClass {
    constructor() {
        this.message = {
            open: function (uri) {}
        }

        this.close = () => {}

        this.link = display => {}

        this.connected = false
        this.reconnecting = false

        this.displays = []
    }

    init() {
        let _obj = this,
            user = UAC.user,
            host = window.__env.sockets.proto + '://' + window.__env.sockets.host + ':' + window.__env.sockets.port + '/ows-remote',
            socket = new OptiSocket(host, {
                department: user.activeDepartmentId,
                logger: o => {},
                reconnecting(trying) {
                    setTimeout(() => {
                        _obj.reconnecting = trying
                    }, 0)
                },
                userId: user.id,
                lsKey: 'websocket-remote-uuid',
                uuidSetter() {
                    return UAC.fingerprint.hash + '_' + (user ? user.id + '-' + user.activeDepartmentId + '-' + user.username : 'not-logged-in')
                }
            }, (message, listen) => {
                let currentLogo = false,
                    send = (type, payload) => {
                    if(type === 'uri/open') {
                        if(payload.uri !== currentLogo) {
                            window.localStorage.setItem('lastOpen', payload.uri)
                        }
                    } else {
                        window.localStorage.setItem('lastOpen', false)
                    }

                    if(socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING) {
                        let onOpen = () => {
                            socket.removeEventListener('open', onOpen)
                            message.send(type, payload)
                        }
                        socket.addEventListener('open', onOpen)
                        socket.open()
                    } else {
                        message.send(type, payload)
                    }
                }

                this.message.open = uri => {
                    send('uri/open', {
                        userId: user.id,
                        uri: uri
                    })
                }

                this.message.calibrate = () => {
                    send('calibrate', user.id)
                }

                this.message.calibrateSize = (dim, offset) => {
                    send('calibrate-size', {
                        userId: user.id,
                        size: {
                            side: dim,
                            inc: offset
                        }
                    })
                }

                this.message.calibrateFinish = apply => {
                    send('calibrate-finish', {
                        userId: user.id,
                        apply: apply
                    })
                }

                this.message.displays = () => {
                    send('displays', true)
                }

                this.link = display => {
                    send('link', {
                        uuid: display.uuid,
                        userId: user.id,
                        userName: user.username
                    })
                }

                this.disconnect = d => {
                    send('disconnect', {
                        uuid: d.uuid,
                        userId: user.id,
                        userName: user.username
                    })
                }

                this.refresh = d => {
                    this.disconnect(d)

                    setTimeout(() => {
                        this.link(d)
                    }, 1000)
                }

                this.close = () => {
                    socket.close(1000, 'Pausing service')
                }

                let lastLinked = false

                listen('linked', connected => {
                    setTimeout(() => {

                        this.connected = connected

                        if(connected) {
                            if(window.localStorage.getItem('lastOpen')) {
                                this.message.open(window.localStorage.getItem('lastOpen'))
                            }
                        }

                        if(lastLinked !== connected) {
                            lastLinked = connected

                            if(lastLinked) {
                                // polaczono
                                currentLogo = '/img/logo/small/' + (user.departmentLogo === 5 ? 'sun' : '') + 'loox.png'
                                this.message.open(currentLogo)

                                Notify.success('optometrist.connection.CONNECTED')
                            } else {
                                // rozlaczono
                                Notify.error('optometrist.connection.DISCONNECTED')
                            }
                        }
                    }, 0)
                })

                listen('displays', displays => {
                    setTimeout(() => {
                        this.displays = displays
                    }, 0)
                })

                window.m = message
                window.l = listen
            })
    }
}

export const OptometristsDisplay = new OptometristsDisplayClass()