<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-refresh.mr3
            span {{'tasks.admin.change_status.HEADER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            table.table.table-responsive.text-center.middle
                tbody
                    tr
                        th {{'tasks.admin.change_status.TASK_STATUS'|translate}}
                        td(v-if="status_change") {{'tasks.status.UNCOMPLETED'|translate}}
                        td(v-else) {{task.statusName|translate}}
                    tr
                        th {{'tasks.FRAME'|translate}}
                        td
                            OptiProduct(v-if="task.product.id" :product="task.product") {{task.frame.partName}}
                            span(v-else) {{task.frame.partName}}
                    tr
                        th(rowspan="2") {{'tasks.STATUS'|translate}}
                        td
                            select.form-control(v-model="statuses.frame.status")
                                option(v-for="status in options.frame_statuses" :value="status.id") {{status.name|translate}}
                    tr
                        td
                            select.form-control(v-model="statuses.frame.state")
                                option(v-for="state in options.frame_states" :value="state.id") {{state.name|translate}}

                    tr(style="border-top:2px")
                        th {{'tasks.card.LENSES'|translate}}
                        td
                            a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.rLensPriceGroup.categoryId"
                                :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.rLensPriceGroup.categoryId, isOffer: 0, taskId: task.id})"
                                target="_blank") {{task.rightLens.partName}}
                            span(v-else) {{task.rightLens.partName}}

            table.table.table-responsive.text-center.middle
                tbody
                    tr
                        th {{'tasks.RL'|translate}}
                        th {{'tasks.LL'|translate}}
                    tr
                        td
                            select.form-control(v-model="statuses.rightLens.status")
                                option(v-for="status in options.lens_statuses" :value="status.id") {{status.name|translate}}
                        td
                            select.form-control(v-model="statuses.leftLens.status")
                                option(v-for="status in options.lens_statuses" :value="status.id") {{status.name|translate}}
                    tr
                        td
                            select.form-control(v-model="statuses.rightLens.state")
                                option(v-for="state in options.lens_states" :value="state.id") {{state.name|translate}}
                        td
                            select.form-control(v-model="statuses.leftLens.state")
                                option(v-for="state in options.lens_states" :value="state.id") {{state.name|translate}}

        template(#footer)
            OptiButton.pull-left(v-show="!status_change" @click.native="status_change = true"
                ) {{'tasks.admin.change_status.TO_COMPLETE'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButtonSave(@click.native="saveChanges")

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumTaskFrameState, EnumTaskFrameStatus, EnumTaskLensState, EnumTaskLensStatus}  from '@/js/app/vue/enums/Enums'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsSummaryFramePaymentFrameChange',
        components: {
            OptiDialog,
            OptiProduct,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                statuses: {
                    frame: {
                        state: this.task.frame.state,
                        status: this.task.frame.status
                    },
                    leftLens: {
                        state: this.task.leftLens.state,
                        status: this.task.leftLens.status
                    },
                    rightLens: {
                        state: this.task.rightLens.state,
                        status: this.task.rightLens.status
                    }
                },

                options: {
                    lens_statuses: EnumTaskLensStatus.simpleItems(),
                    lens_states: EnumTaskLensState.simpleItems(),
                    frame_statuses: EnumTaskFrameStatus.simpleItems(),
                    frame_states: EnumTaskFrameState.simpleItems()
                },

                status_change: false,
                loading: false
            }
        },
        methods: {
            async saveChanges() {
                this.loading = true

                let checks = ['state', 'status'],
                    sides = ['leftLens', 'rightLens', 'frame'],
                    changes = []

                checks.forEach(check => {
                    sides.forEach(side => {
                        if(this.statuses[side][check] !== this.task[side][check]) {
                            changes.push({
                                side: side,
                                check: check,
                                value: this.statuses[side][check]
                            })
                        }
                    })
                })

                if(changes.length > 0 || this.status_change) {
                    changes.forEach(item => {
                        this.task[item.side][item.check] = item.value
                    })

                    if(this.status_change) {
                        this.task.status = 4
                    }

                    await API.post('task/updateLesnFrameStatus', {
                        task_id: this.task.id,
                        updates: changes,
                        status_change: this.status_change
                    }).then(() => {
                        this.$emit('statuses-were-updated')

                        this.$notify.success('tasks.notify.STATUSES_CHANGED')
                    }).finally(() => {
                        this.loading = false

                        this.$emit('closed')
                    }).catch(() => {
                        this.$notify.error('tasks.notify.error.STATUS_CHANGE')
                    })
                } else {
                    this.$notify.warning('tasks.notify.error.NO_CHANGES')

                    this.loading = false

                    this.$emit('closed')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;
        position: relative;

        > .modal-body {
            padding: 0;
        }
    }

    table {
        margin-bottom: 0 !important;

        &:first-child > tbody > tr:first-child {
            th:first-child {
                width: 35%;
            }

            th, td {
                border-top: 0;
            }
        }
    }
</style>