<template lang="pug">
    .optometrist-clock
        .optometrist-clock-image
            img(style="margin:20px auto 10px" src="img/optometrist/screening-test/optotype/salt.png")
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    export default {
        name: 'OptiOptometristsSurveysOptotypesSalt',
        components: {},
        props: {

        },

        activated() {
            OptometristsDisplay.message.open('/optometrist/screen/salt')
        }
    }
</script>