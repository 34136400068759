class OCRClass {
    constructor() {

    }

    checkRightAxis() {
        if(!parseFloat(this.result[2])) {
            this.result[0] = this.result[1]
            this.result[1] = '0'
            this.result[2] = '0'
        }
    }
    checkLeftAxis() {
        if(!parseFloat(this.result[5])) {
            this.result[0] = this.result[1]
            this.result[1] = this.result[2]
            this.result[2] = this.result[3]
            this.result[3] = this.result[4]
            this.result[4] = '0'
            this.result[5] = '0'
        }
    }

    sph(val) {
        val = val.replace('8', '0').replace('6', '0').replace('-.', '-0.').replace('D', '0')

        if(val % 25 === 0) {
            val /= 100
        }

        if(val % 0.25 !== 0) {
            val = val - val % 0.25
        }

        val = parseFloat(val)
        val = !isNaN(val) ? val : 0

        if(val >= 9 && val < 10) {
            val -= 9
        } else if(val > -10 && val <= -9) {
            val += 9
        }

        return Math.abs(val) <= 25 ? val : 0
    }
    cyl(val) {
        val = this.sph(val)

        return val > 0 ? val * -1 : val
    }
    axs(val) {
        val = parseInt(val)
        val = !isNaN(val) ? val : 0

        if(val >= 190 && val <= 199) {
            val -= 90
        }

        return val >= 0 && val <= 180 ? val : 0
    }

    calculate(text) {
        let matches = text.replace('\\n', '\n'),
            tab = matches.split('\n'),
            pd = 0

        this.result = []

        console.log(tab)

        tab.forEach(val => {
            if(val.includes('PD') && val.length > 4 && val[3] === ' ') {
                val = val.slice(0, 3) + val.slice(4)
            }

            let values = val.replace('- ', '-').replace('=', '-').split(' ')

            values.forEach(v => {
                if(!pd && (v.includes('PD') || v.includes('D:'))) {
                    let number_pd = v.match(/\d+/g)

                    pd = parseInt(number_pd !== null ? number_pd[0].substr(-2) : 0)
                }

                if(v.match(/\d+/g) !== null) {
                    this.result.push(v)
                }
            })
        })

        console.log(this.result)

        if(this.result.length >= 6) {
            this.result = this.result.slice(-6)

            this.checkLeftAxis()
            this.checkRightAxis()

            this.result[0] = this.sph(this.result[0])
            this.result[1] = this.cyl(this.result[1])
            this.result[2] = this.result[1] ? this.axs(this.result[2]) : 'brak'

            this.result[3] = this.sph(this.result[3])
            this.result[4] = this.cyl(this.result[4])
            this.result[5] = this.result[4] ? this.axs(this.result[5]) : 'brak'

            this.result[6] = pd >= 45 ? pd : 0
        }

        console.log(this.result)

        return this.result
    }
}

export const OCR = new OCRClass()