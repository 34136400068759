<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-group-department.form-horizontal(name="formDepartmentGroup" @submit.prevent="add")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'departments.groups.form.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="departmentGroup.name" required)
                        OptiValidate(f="name" :data="$v.departmentGroup.name")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.departmentGroup.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDepartmentsGroupAdd',
        components: {
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                departmentGroup: {
                    name: ''
                },

                loading: false
            }
        },
        validations: {
            departmentGroup: {
                name: {
                    required,
                    unique: unique('salon_groups', 'GROUP_NAME')
                }
            }
        },
        mounted() {

        },
        methods: {
            add() {
                this.loading = true

                API.post('departments/group', this.departmentGroup).then(() => {
                    this.$state.go('app.departments.groups.list')

                    this.$notify.success('departments.groups.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('departments.groups.notify.error.SAVE')
                })
            }
        }
    }
</script>