<template lang="pug">
    .optometrist-powers
        .group(v-show="type === 'sphere'")
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="increment(0.25)") +0,25
            span.value.text-expressive
                span.text-muted.text-sm SPH
                span {{print|none(-999)}}
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="decrement(0.25)") -0,25

        .group(v-show="type === 'cylinder'")
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="increment(0.25)"
                :disabled="!value") +0,25
            span.value.text-expressive
                span.text-muted.text-sm CYL
                span {{print|none(-999)}}
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="decrement(0.25)") -0,25

        .group(v-show="type === 'axis'")
            .btn-group(v-show="editable")
                a.btn.btn-default.btn-lg(style="padding:5px 20px;width:40px" @click="increment(1)") +1
                a.btn.btn-default.btn-lg(style="padding:5px 20px;width:40px" @click="increment(10)") +10
            span.value.text-expressive
                span.text-muted.text-sm AXS
                span {{print}}
            .btn-group(v-show="editable")
                a.btn.btn-default.btn-lg(style="padding:5px 20px;width:40px" @click="decrement(1)") -1
                a.btn.btn-default.btn-lg(style="padding:5px 20px;width:40px" @click="decrement(10)") -10

        .group(v-show="type === 'addition'")
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="increment(0.25)") +0,25
            span.value.text-expressive
                span.text-muted.text-sm ADD
                span {{print}}
            a.btn.btn-default.btn-lg(style="padding:5px 20px" v-show="editable" @click="decrement(0.25)") -0,25

        .group(v-show="type === 'visus'")
            span.value.text-expressive
                span.text-muted.text-sm Visus
                span {{print}}

        .group(v-show="type === 'binvisus'")
            span.value.text-expressive
                span.text-muted.text-sm BinVisus
                span {{print}}
</template>

<script>
    export default {
        name: 'OptiOptometristsPower',
        props: {
            type: {
                type: String,
                required: true
            },
            value: {
                type: Number | String,
                required: true
            },
            editable: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        mounted() {

        },
        methods: {
            increment(delta) {
                let v = this.value

                if(v === 'brak') {
                    v = 0
                }

                if(typeof v === 'string') {
                    v = parseFloat(v)
                }

                if(this.type === 'cylinder' && v === 0) {
                    return
                }

                v = parseFloat(v + delta)

                if(this.type === 'axis' && v >= 180) {
                    v = v - 180
                }

                this.$emit('update-power', v)
            },
            decrement(delta) {
                let v = this.value

                if(v === 'brak') {
                    v = 0
                }

                if(typeof v === 'string') {
                    v = parseFloat(v)
                }

                v = parseFloat(v - delta)

                if(this.type === 'axis' && v < 0) {
                    v = v + 180
                }

                this.$emit('update-power', v)
            }
        },
        computed: {
            print() {
                let val = this.value

                if(this.type === 'visus' || this.type === 'binvisus') {
                    return val ? val : '-'
                }

                if(val === 'brak') {
                    return val
                }

                val = parseFloat(val)

                if(val >= 0) {
                    val = (this.type !== 'axis') ? '+' + val.toFixed(2) : val.toFixed(0)
                } else {
                    val = val.toFixed(2)
                }

                return val.replace('.', ',')
            }
        }
    }
</script>