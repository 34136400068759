import {EventBus} from '../../_bridge/EventBus'

export default class Notify {
    static sendRaw(data) {
        EventBus.$emitAndApply('ng:emit', {
            name: 'notify',
            data: data
        })
    }

    static send(message, status, translate = true, sticky = false) {
        let data = {
            status: status,
            message: message
        }

        if (translate) data.translate = true
        if (sticky) data.timeout = 0

        Notify.sendRaw(data)
    }

    static success(message, translate = true, sticky = false) {
        Notify.send(
            message,
            'success',
            translate,
            sticky
        )
    }

    static error(message, translate = true, sticky = false) {
        Notify.send(
            message,
            'danger',
            translate,
            sticky
        )
    }

    static warning(message, translate = true, sticky = false) {
        Notify.send(
            message,
            'warning',
            translate,
            sticky
        )
    }
}
