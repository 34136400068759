class ShipmentSizeClass {
    constructor() {

    }

    get() { // defined in ShipmentSize.php
        return {
            1: { // small
                width: 8,
                height: 38,
                len: 64
            },
            2: { // medium
                width: 19,
                height: 38,
                len: 64
            },
            3: { // large
                width: 41,
                height: 38,
                len: 64
            }
        }
    }
}

export const ShipmentSize = new ShipmentSizeClass()