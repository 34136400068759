<template lang="pug">
    .row
        .col-xs-12.text-right(style="padding-bottom:10px")
            OptiButton(type="success" @click.native="$store.dispatch('offer/createNewOffer', patient)") {{'catalog.offer.ADD'|translate}}
                template(#icon)
                    i.fa.fa-plus

        .col-xs-12
            .panel.panel-primary(v-for="offer in offers" :key="offer.id")
                .panel-heading(style="cursor:pointer" :title="'default.toggle.' + (offer.toggle ? 'HIDE' : 'SHOW')|translate"
                    @click="offer.toggle = !offer.toggle")
                    i.fa.fa-briefcase.mr3
                    span.mr3 {{'catalog.offer.breadcrumb.OFFER'|translate}} \#{{offer.id}}

                    i.pull-right.fa(style="margin-top:5px" :class="{'fa-arrow-down': !offer.toggle, 'fa-arrow-up': offer.toggle}")

                div(v-show="offer.toggle")
                    .panel-body.text-center(style="padding:10px")
                        .row(style="margin-bottom:5px")
                            .col-sm-6
                                b.mr3 {{'catalog.offer.created.BY'|translate}}
                                span.mr3 {{offer.user.firstName}} {{offer.user.lastName}}
                                small.text-muted ({{offer.department.login}})

                            .col-sm-6
                                b.mr3 {{'catalog.offer.created.AT'|translate}}
                                span {{offer.created_at}}

                        .row
                            .col-sm-6
                                b.mr3 {{'patient.offer.table.COUNT_PRODUCTS'|translate}}
                                span {{offer.count_products}}

                            .col-sm-6
                                .space-around
                                    OptiCatalogOfferFavorites(
                                        :favorites="offer.favorites"
                                        :selected="offer.products.map(p => p.id)"
                                        :department-id="offer.department_id")

                                    OptiRate(
                                        :header="true"
                                        :rate="offer.rate"
                                        :ref-type="1"
                                        :ref-id="offer.id"

                                        @reload="$emit('reload', offer.id)")

                    OptiCatalogOfferHeader#opti-catalog-offer-header(
                        :selected="offer.filters"
                        :is-skipped="!!(offer.filters.questions.frames[4] && JSON.stringify(offer.filters.questions.frames[4].options.map(o => o.key)) === JSON.stringify(['sunglasses']))")

                    OptiCatalogOfferHeaderProducts(
                        :products="offer.products"
                        :department-id="departmentId"

                        @reload="$emit('reload', offer.id)")

                    .panel-footer
                        .footer-buttons
                            OptiCatalogOfferBtnMail(
                                :offer="offer"
                                :patient="patient"

                                @reload="$emit('reload', offer.id)")

                            OptiCatalogOfferBtnPortraitsEdit(
                                :offer="offer"

                                @reload="$emit('reload', offer.id)")

                            OptiButtonHistory(data="offer" :id="offer.id")

                            OptiButton(type="success"
                                v-show="!offer.products.filter(p => p.pivot.offer_temporary_task_id).length"
                                @click.native="$emit('go-to-prescriptions', offer.id)"
                                ) {{'catalog.offer.GO_TO_PRESCRIPTION'|translate}}
                                template(#icon)
                                    i.fa.fa-arrow-right

            .alert.alert-warning.text-center(v-show="!offers.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'catalog.offer.notify.none.PATIENT'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferFavorites
        from '@/js/app/vue/components/Catalog/Offer/Favorites/OptiCatalogOfferFavorites'
    import OptiRate from '@/js/app/vue/components/Rate/OptiRate'
    import OptiCatalogOfferHeader from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeader'
    import OptiCatalogOfferHeaderProducts
        from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeaderProducts'
    import OptiCatalogOfferBtnMail from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnMail'
    import OptiCatalogOfferBtnPortraitsEdit
        from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnPortraitsEdit'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsOffers',
        components: {
            OptiButton,
            OptiCatalogOfferFavorites,
            OptiRate,
            OptiCatalogOfferHeader,
            OptiCatalogOfferHeaderProducts,
            OptiCatalogOfferBtnMail,
            OptiCatalogOfferBtnPortraitsEdit,
            OptiButtonHistory,
            OptiDimmer
        },
        props: {
            departmentId: {
                type: Number,
                required: true
            },
            patient: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                offers: [],

                loading: true
            }
        },
        mounted() {
            API.get('catalog/offers/patient/' + this.$state.params.id).then(res => {
                this.offers = res.data

                this.offers.forEach((offer, key) => {
                    this.$set(offer, 'toggle', this.$state.params.offerId ? offer.id === this.$state.params.offerId : !key)
                })

                this.$emit('set-offers', this.offers)
                this.$emit('update-count', 'offers', this.offers.length)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.offer.notify.error.LOAD')
            })
        }
    }
</script>

<style lang="less" scoped>
    .space-around {
        display: flex;
        justify-content: space-around;
    }

    .footer-buttons {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;

            > :not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
</style>