<template lang="pug">
    .row
        .col-xs-12
            legend {{'shipments.new.DONE'|translate}}
        .col-xs-6
            form.form-horizontal
                .form-group
                    label.control-label.col-sm-6.star-required(for="delivery_type") {{'shipments.new.done.SPEDYTOR'|translate}}
                    .col-sm-6
                        OptiSelectEnum#delivery_type(enum="ShipmentDeliveryType" :value="shipment.delivery_type"
                            @update-enum="d => shipment.delivery_type = d")
                        OptiValidate(f="delivery_type" :data="vShipment.delivery_type")
                .form-group
                    label.control-label.col-sm-6(for="suggested_value") {{'shipments.new.done.SUGGESTEDVALUE'|translate}}
                    .col-sm-6
                        #suggested_value.form-control.text-right(disabled) {{shipment.suggested_value|currency}}
                .form-group
                    label.control-label.col-sm-6.star-required(for="value") {{'shipments.new.done.VALUE'|translate}}
                    .col-sm-6
                        OptiNumber#value(:header="'shipments.new.done.VALUE'|translate"
                            :value="shipment.value" @confirmed="v => shipment.value = v"
                            :float="true" :required="true") {{shipment.value|currency}}
                        OptiValidate(f="value" :data="vShipment.value")
                .form-group
                    label.control-label.col-sm-6.star-required(for="weight") {{'shipments.new.done.WEIGHT'|translate}}
                    .col-sm-6
                        OptiNumber#weight(:header="'shipments.new.done.WEIGHT'|translate"
                            :value="shipment.weight" @confirmed="w => shipment.weight = w"
                            :float="true" :required="true") {{shipment.weight|kg}}
                        OptiValidate(f="weight" :data="vShipment.weight")

        .col-xs-12
            h4 {{'shipments.new.done.ADRRESS'|translate}}
            #receiver-address(v-show="receiver.address.firstName")
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.NAME'|translate}}
                        div {{receiver.address.firstName}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.SURNAME'|translate}}
                        div {{receiver.address.lastName}}
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.POSTALCODE'|translate}}
                        div {{receiver.address.zipCode}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.CITY'|translate}}
                        div {{receiver.address.city}}
                .row
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.STREET'|translate}}
                        div {{receiver.address.street}}
                    .col-sm-6
                        .bold {{'shipments.new.receiver.other.TELEPHONE'|translate}}
                        div {{receiver.address.cellPhoneNumber}}
                .row
                    .col-xs-12
                        label(for="comments") {{'shipments.COMMENTS'|translate}}
                        .controls
                            textarea#comments.form-control(v-model="receiver.comments")
            .row(v-show="!receiver.address.firstName")
                .col-xs-12.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'shipments.new.done.notify.NOADDRESS'|translate}}

        .col-xs-12(v-show="[40, 45].includes(shipment.delivery_type)")
            h4
                label(for="size") {{'shipments.size.HEADING'|translate}}
            #package-size
                .row
                    .col-sm-6
                        form.form-horizontal
                            .form-group
                                .col-xs-12
                                    OptiSelectEnum#size(enum="ShipmentSize" :value="shipment.size"
                                        @update-enum="updateSize")
                                    OptiValidate(f="size" :data="vShipment.size")
                    .col-sm-6
                        form.form-horizontal
                            .form-group
                                label.control-label.col-sm-6.star-required(for="width") {{'shipments.size.WIDTH'|translate}}
                                .col-sm-6
                                    OptiNumber#width(:header="'shipments.size.WIDTH'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.width" @confirmed="w => shipment.width = w"
                                        :float="true" :required="true") {{shipment.width|currency}} cm
                                    OptiValidate(f="width" :data="vShipment.width")
                            .form-group
                                label.control-label.col-sm-6.star-required(for="height") {{'shipments.size.HEIGHT'|translate}}
                                .col-sm-6
                                    OptiNumber#height(:header="'shipments.size.HEIGHT'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.height" @confirmed="h => shipment.height = h"
                                        :float="true" :required="true") {{shipment.height|currency}} cm
                                    OptiValidate(f="height" :data="vShipment.height")
                            .form-group
                                label.control-label.col-sm-6.star-required(for="len") {{'shipments.size.LEN'|translate}}
                                .col-sm-6
                                    OptiNumber#len(:header="'shipments.size.LEN'|translate"
                                        :disabled="shipment.size !== 4"
                                        :value="shipment.len" @confirmed="l => shipment.len = l"
                                        :float="true" :required="true") {{shipment.len|currency}} cm
                                    OptiValidate(f="len" :data="vShipment.len")

        .col-xs-12
            OptiShipmentCreateStepItems(:items="items")
</template>

<script>
    import {ShipmentSize} from '@/js/app/vue/helpers/ShipmentSize'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiShipmentCreateStepItems
        from '@/js/app/vue/components/Warehouse/Shipments/Create/Steps/OptiShipmentCreateStepItems'

    export default {
        name: 'OptiShipmentCreateStepDone',
        components: {
            OptiSelectEnum,
            OptiNumber,
            OptiValidate,
            OptiShipmentCreateStepItems
        },
        props: {
            shipment: {
                type: Object,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },
            items: {
                type: Array,
                required: true
            },

            vShipment: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                default_size: ShipmentSize.get()
            }
        },
        mounted() {

        },
        methods: {
            updateSize(s) {
                this.shipment.size = s

                if(Object.keys(this.default_size).includes(this.shipment.size.toString())) {
                    let selected = this.default_size[this.shipment.size]

                    _.forEach(selected, (value, key) => {
                        this.shipment[key] = value
                    })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    #receiver-address [class^='col-'] {
        margin-bottom: 10px;
    }
</style>