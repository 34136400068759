<template lang="pug">
    form#formPrescription.row.form-horizontal.form-validate(name="formPrescription" @submit.prevent="editPrescription")
        .col-md-6.col-xs-12(v-if="!loading.main")
            OptiPatientsDetailsPrescriptionDoctor(
                :prescription="prescription"
                :doctor-type="doctor_type"
                :loading="loading"

                @update-doctor-type="t => doctor_type = t")

            OptiPatientsDetailsPrescriptionSource(
                :prescription="prescription"
                :loading="loading"
                :disabled="true")

            OptiPatientsDetailsPrescriptionCheckVisus(
                :prescription="prescription"

                @update-prescription-check="c => prescription.check = c")

        .col-md-6.col-xs-12(v-if="!loading.main")
            OptiPatientsDetailsPrescriptionPowers(
                :prescription="prescription"
                :loading="loading")

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import validatePrescription from './PrescriptionValidator'

    import OptiPatientsDetailsPrescriptionDoctor
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionDoctor'
    import OptiPatientsDetailsPrescriptionSource
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionSource'
    import OptiPatientsDetailsPrescriptionCheckVisus
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionCheckVisus'
    import OptiPatientsDetailsPrescriptionPowers
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionPowers'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsPrescriptionEdit',
        components: {
            OptiPatientsDetailsPrescriptionDoctor,
            OptiPatientsDetailsPrescriptionSource,
            OptiPatientsDetailsPrescriptionCheckVisus,
            OptiPatientsDetailsPrescriptionPowers,
            OptiDimmer
        },
        data() {
            return {
                prescription: {},

                doctor_type: 1,

                loading: {
                    main: true,
                    doctors: true,
                    files: []
                }
            }
        },
        mounted() {
            API.get('prescriptions/' + this.$state.params.prescriptionId).then(res => {
                this.prescription = res.data

                this.$set(this.prescription, 'files', [])
                this.prescription.uploads.forEach(upload => {
                    this.prescription.files.push('prescription/' + upload.name)
                    this.loading.files.push(false)
                })
                this.prescription.files.push(null)
                this.loading.files.push(false)

                this.doctor_type = this.prescription.doctorId > 0 ? 1 : 0
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('prescription.notify.ERRORDATA')
            })
        },
        methods: {
            editPrescription() {
                let validate = validatePrescription(this.prescription, this.doctor_type)

                if(!validate.length) {
                    this.loading.main = true

                    let data = _.cloneDeep(this.prescription)
                    data.files = data.files.filter(f => f && !f.startsWith('prescription/'))

                    API.put('prescriptions', data).then(res => {
                        this.$notify.success('prescription.UPDATE')

                        this.$state.go('app.patient.detail', {
                            id: res.data.patientId,
                            '#': 'prescriptions',
                            prescriptionId: res.data.id
                        });
                    }).finally(() => {
                        this.loading.main = false
                    }).catch(() => {
                        this.$notify.error('prescription.notify.NOUPDATE')
                    })
                } else {
                    validate.forEach(error => {
                        this.$notify.error(error)
                    })
                }
            }
        }
    }
</script>
