<template lang="pug">
    .task-measurement-field(:class="{'warn': hasDiff}" :title="hasDiff ? 'tasks.ipad.notify.MANUAL' : ''|translate")
        i.fa.fa-exclamation-circle.mr3(v-show="hasDiff")
        span(:class="{'mr3': !enter}") {{main|number(2)}}
        span(:class="{'text-muted': !compare, 'enter': enter}") ({{compare|number(2)}})
</template>

<script>
    export default {
        name: 'OptiTaskDetailsMeasurementField',
        props: {
            task: {
                type: Object,
                required: true
            },
            field: {
                type: String,
                required: true
            },
            enter: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                minusBt: this.task.type === 1 ? 2 : 0
            }
        },
        computed: {
            main() {
                switch(this.field) {
                    case 'pdDist':
                        return this.task.pdDist
                    case 'pdDistR':
                        return this.task.rightEye.pdDist
                    case 'pdDistL':
                        return this.task.leftEye.pdDist

                    case 'pdNear':
                        return this.task.pdNear
                    case 'pdNearR':
                        return this.task.rightEye.pdNear
                    case 'pdNearL':
                        return this.task.leftEye.pdNear

                    case 'btR':
                        return this.task.rightEye.bt
                    case 'btL':
                        return this.task.leftEye.bt
                }

                return 0
            },
            compare() {
                if(this.task.measurementId) {
                    switch(this.field) {
                        case 'pdDist':
                            return this.task.measurement.pd
                        case 'pdDistR':
                            return this.task.measurement.pdR
                        case 'pdDistL':
                            return this.task.measurement.pdL

                        case 'pdNear':
                            return this.task.measurement.pdNear
                        case 'pdNearR':
                            return this.task.measurement.pdNearR
                        case 'pdNearL':
                            return this.task.measurement.pdNearL

                        case 'btR':
                            return _.round(this.task.measurement.btR - this.minusBt, 2)
                        case 'btL':
                            return _.round(this.task.measurement.btL - this.minusBt, 2)
                    }
                } else if(this.task.measurement_jawro_visu_id) {
                    switch(this.field) {
                        case 'pdDist':
                            return _.round(task.measurementJawroVisu.pd_r + task.measurementJawroVisu.pd_l, 2)
                        case 'pdDistR':
                            return _.round(task.measurementJawroVisu.pd_r, 2)
                        case 'pdDistL':
                            return _.round(task.measurementJawroVisu.pd_l, 2)

                        case 'pdNear':
                            return _.round(task.measurementJawroVisu.pd_r + task.measurementJawroVisu.pd_l - 4, 2)
                        case 'pdNearR':
                            return _.round(task.measurementJawroVisu.pd_r - 2, 2)
                        case 'pdNearL':
                            return _.round(task.measurementJawroVisu.pd_l - 2, 2)

                        case 'btR':
                            return _.round(task.measurementJawroVisu.bt_r - this.minusBt, 2)
                        case 'btL':
                            return _.round(task.measurementJawroVisu.bt_l - this.minusBt, 2)
                    }
                }

                return 0
            },
            hasDiff() {
                return this.compare && this.main !== this.compare
            }
        }
    }
</script>

<style lang="less" scoped>
    .enter {
        display: block;
    }
</style>