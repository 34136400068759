<template lang="pug">
    OptiDialog
        template(#header)
            .text-right
                i.fa.fa-remove(style="cursor:pointer" @click="closed")

        template(#body)
            h3
                i.fa.fa-exclamation-circle.text-info.mr3
                span {{text + '.MESSAGE'|translate}}

        template(#footer)
            OptiButton(type="success" @click.native="confirmed") {{text + '.YES'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(type="danger" @click.native="closed") {{text + '.NO'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiDialogConfirm',
        components: {
            OptiDialog,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            text: {
                type: String,
                default: '',
                required: false
            }
        },
        data() {
            return {

            }
        },
        methods: {
            confirmed() {
                this.$emit('confirmed')
            },
            closed() {
                this.$emit('closed')
            }
        }
    }
</script>

<style lang="less" scoped>
    h3 {
        margin: 0 !important;
    }

    /deep/ .modal-container {
        max-width: 750px;
    }
</style>