<template lang="pug">
    .opti-select-period.row(style="padding-bottom:10px")
        .col-sm-8
            .row
                .col-sm-8(style="margin-bottom:15px")
                    .btn-group.btn-group-m
                        label.btn.btn-default(for="period-year" :class="{'btn-purple active': localPeriod.period === 'year'}") Y/Y
                        input#period-year.hide(type="radio" name="period" value="year"
                            :checked="localPeriod.period === 'year'"

                            @change="loadPeriod($event.target.value)")

                        label.btn.btn-default(for="period-quarter" :class="{'btn-purple active': localPeriod.period === 'quarter'}") Q/Q
                        input#period-quarter.hide(type="radio" name="period" value="quarter"
                            :checked="localPeriod.period === 'quarter'"

                            @change="loadPeriod($event.target.value)")

                        label.btn.btn-default(for="period-week" :class="{'btn-purple active': localPeriod.period === 'week'}") W/W
                        input#period-week.hide(type="radio" name="period" value="week"
                            :checked="localPeriod.period === 'week'"

                            @change="loadPeriod($event.target.value)")
                .col-sm-4
                    .form-group
                        button.btn.btn-primary(@click="emitChanges") {{'report.entries.CALCULATE'|translate}}
            div
                table
                    tr
                        th Y1:
                        td
                            select.form-control#pastYear(style="display:block" name="pastYear" v-model="localPeriod.pastYear" @change="setW53")
                                option(v-for="year in select.years" :value="year" :disabled="localPeriod.chooseYear < year || localPeriod.chooseYear <= year && localPeriod.period === 'year'") {{year}}

                        td(v-if="localPeriod.period === 'year'")
                            .btn-group.btn-group-m
                                label.btn.btn-default(for="past-year-1" :class="{'btn-purple active': localPeriod.pastYear === select.years[2] && localPeriod.chooseYear === select.years[1] || localPeriod.pastYear === select.years[2] && localPeriod.chooseYear === select.years[1]}"
                                    ) {{select.years[2]}}->{{select.years[1]}}
                                input#past-year-1.hide(type="radio" name="past-year" :value="1" @change="loadLastCompare(select.years[2], select.years[1])")

                                label.btn.btn-default(for="past-year-2" :class="{'btn-purple active': localPeriod.pastYear === select.years[1] && localPeriod.chooseYear === select.years[0] || localPeriod.pastYear === select.years[1] && localPeriod.chooseYear === select.years[0]}"
                                    ) {{select.years[1]}}->{{select.years[0]}}
                                input#past-year-2.hide(type="radio" name="past-year" :value="2" @change="loadLastCompare(select.years[1], select.years[0])")

                                label.btn.btn-default(for="past-year-3" :class="{'btn-purple active': localPeriod.pastYear === select.years[0] && localPeriod.chooseYear === (select.years[0] + 1) || localPeriod.pastYear === select.years[0] && localPeriod.chooseYear === (select.years[0] + 1)}"
                                    ) {{select.years[0]}}->{{select.years[0] + 1}}
                                input#past-year-3.hide(type="radio" name="past-year" :value="3" @change="loadLastCompare(select.years[0], select.years[0] + 1)")

                        td(v-if="localPeriod.period === 'quarter'")
                            .btn-group.btn-group-m
                                label.btn.btn-default(for="past-quarter-1" :class="{'btn-purple active': localPeriod.pastQuarter === 1}") Q1
                                input#past-quarter-1.hide(type="radio" name="pastQuarter" :value="1"
                                    @change="loadPastQuarter($event.target.value)")

                                label.btn.btn-default(for="past-quarter-2" :class="{'btn-purple active': localPeriod.pastQuarter === 2}") Q2
                                input#past-quarter-2.hide(type="radio" name="pastQuarter" :value="2"
                                    @change="loadPastQuarter($event.target.value)")

                                label.btn.btn-default(for="past-quarter-3" :class="{'btn-purple active': localPeriod.pastQuarter === 3}") Q3
                                input#past-quarter-3.hide(type="radio" name="pastQuarter" :value="3"
                                    @change="loadPastQuarter($event.target.value)")

                                label.btn.btn-default(for="past-quarter-4" :class="{'btn-purple active': localPeriod.pastQuarter === 4}") Q4
                                input#past-quarter-4.hide(type="radio" name="pastQuarter" :value="4"
                                    @change="loadPastQuarter($event.target.value)")

                        td(v-if="localPeriod.period === 'quarter'")
                            .btn-group.btn-group-m
                                label.btn.btn-default(for="compare-week-1" :class="{'btn-purple active': localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2020 && localPeriod.pastQuarter === 3 && localPeriod.currentQuarter === 4 || localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2020 && localPeriod.pastQuarter === 3 && localPeriod.currentQuarter === 4}") 3Q20Y do 4Q20Y
                                input#compare-week-1.hide(type="radio" name="compareWeek" :value="1"
                                    @change="loadLastQuarterCompare(2020, 2020, 3, 4)")

                                label.btn.btn-default(for="compare-week-2" :class="{'btn-purple active': localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 4 && localPeriod.currentQuarter === 4 || localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 4 && localPeriod.currentQuarter === 4}") 4Q20Y do 4Q21Y
                                input#compare-week-2.hide(type="radio" name="compareWeek" :value="2"
                                    @change="loadLastQuarterCompare(2020, 2021, 4, 4)")

                        th(v-if="localPeriod.period === 'week'") W od
                        td(v-if="localPeriod.period === 'week'")
                            select.form-control#pastWeekFrom(:value="localPeriod.pastWeekFrom" name="pastWeekFrom" @change="loadPastWeekFrom($event.target.value)" )
                                option(v-for="x in (lastWeek(localPeriod.pastYear))" :value="x") W{{x}}
                        th(v-if="localPeriod.period === 'week'") do
                        td(v-if="localPeriod.period === 'week'")
                            select.form-control#pastWeekTo(:value="localPeriod.pastWeekTo" name="pastWeekTo" @change="loadPastWeekTo($event.target.value)")
                                option(v-for="x in (lastWeek(localPeriod.pastYear))" :value="x" :disabled="disableRange(x)") W{{x}}
                    tr
                        th Y2:
                        td
                            select.form-control#choosenYear(style="display:block" name="chooseYear" v-model="localPeriod.chooseYear" @change="setW53")
                                option(:value="select.years[0] + 1") {{select.years[0] + 1}}
                                option(v-for="year in select.years" :value="year" :disabled="localPeriod.pastYear >= year && localPeriod.period === 'year' || localPeriod.pastYear > year") {{year}}

                        th(v-if="localPeriod.period === 'week'") W od
                        td(v-if="localPeriod.period === 'week'")
                            select.form-control#currentWeekFrom(name="currentWeekFrom" :value="localPeriod.currentWeekFrom" @change="loadCurrentWeekFrom($event.target.value)")
                                option(v-for="x in (lastWeek(localPeriod.chooseYear))" :value="x") W{{x}}
                        th(v-if="localPeriod.period === 'week'") do
                        td(v-if="localPeriod.period === 'week'")
                            select.form-control#currentWeekTo(name="currentWeekTo" :value="localPeriod.currentWeekTo" disabled)
                                option(v-for="x in (lastWeek(localPeriod.chooseYear))" :value="x" disabled) W{{x}}
                        td(v-if="localPeriod.period === 'quarter'")
                            .btn-group.btn-group-m
                                label.btn.btn-default(for="current-quarter-1" :class="{'btn-purple active': localPeriod.currentQuarter === 1 || localPeriod.currentQuarter === 1}") Q1
                                input#current-quarter-1.hide(type="radio" name="currentQuarter" :value="1"
                                    @change="loadCurrentQuarter($event.target.value)")

                                label.btn.btn-default(for="current-quarter-2" :class="{'btn-purple active': localPeriod.currentQuarter === 2 || localPeriod.currentQuarter === 2}") Q2
                                input#current-quarter-2.hide(type="radio" name="currentQuarter" :value="2"
                                    @change="loadCurrentQuarter($event.target.value)")

                                label.btn.btn-default(for="current-quarter-3" :class="{'btn-purple active': localPeriod.currentQuarter === 3 || localPeriod.currentQuarter === 3}") Q3
                                input#current-quarter-3.hide(type="radio" name="currentQuarter" :value="3"
                                    @change="loadCurrentQuarter($event.target.value)")

                                label.btn.btn-default(for="current-quarter-4" :class="{'btn-purple active': localPeriod.currentQuarter === 4 || localPeriod.currentQuarter === 4}") Q4
                                input#current-quarter-4.hide(type="radio" name="currentQuarter" :value="4"
                                    @change="loadCurrentQuarter($event.target.value)")
                        td(v-if="localPeriod.period === 'quarter'")
                            .btn-group.btn-group-m
                                label.btn.btn-default(for="compare-week-3" :class="{'btn-purple active': localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 4 && localPeriod.currentQuarter === 1 || localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 4 && localPeriod.currentQuarter === 1}") 4Q20Y do 1Q21Y
                                input#compare-week-3.hide(type="radio" name="compareWeek" :value="3"
                                    @change="loadLastQuarterCompare(2020, 2021, 4, 1)")

                                label.btn.btn-default(for="compare-week-4" :class="{'btn-purple active': localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 1 && localPeriod.currentQuarter === 1 || localPeriod.pastYear === 2020 && localPeriod.chooseYear === 2021 && localPeriod.pastQuarter === 1 && localPeriod.currentQuarter === 1}") 1Q20Y do 1Q21Y
                                input#compare-week-4.hide(type="radio" name="compareWeek" :value="4"
                                    @change="loadLastQuarterCompare(2020, 2021, 1, 1)")

        .col-sm-4
            OptiSelectDepartment(
                :department-id="departmentId"
                :reduce="department => department.id"
                :filter="filterOutHeadquarters"
                :recently-closed="true"

                @selected="d => $emit('load-department', d)")

            .text-right(style="margin-top:15px")
                OptiSwitchToggleButton(:checked="localPeriod.cumulative" @input="b => localPeriod.cumulative = b")
                    template(#left) {{'report.entries.switch.WEEKLY'|translate}}
                    template(#right) {{'report.entries.switch.CUMULATIVE'|translate}}

            label.pull-right(style="margin-bottom:0" v-show="localPeriod.w53")
                .checkbox.c-checkbox.checkbox-primary.inline
                    label
                        input(type="checkbox" name="w53" v-model="localPeriod.checkbox_w53")
                        span.fa.fa-check
                span {{'report.entries.W53'|translate}}
</template>

<script>
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'
    import {weekNumbersFromQuarter} from '@/js/app/vue/helpers/Dates'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'

    export default {
        name: 'OptiReportEntriesNavigation',
        components: {
            OptiSelectDepartment,
            OptiSwitchToggleButton
        },
        props: {
            departmentId: Number,

            period: {
                type: Object,
                default: () => {
                    let today = moment(),
                        quarter = today.quarter(),
                        [weekFrom, weekTo] = weekNumbersFromQuarter(quarter)

                    return {
                        period: 'year',
                        pastYear: today.year() - 1, //przy zmianie roku zmień checkbox_w53 i w53
                        chooseYear: today.year(),
                        pastQuarter: quarter,
                        currentQuarter: quarter,
                        pastWeekFrom: weekFrom,
                        pastWeekTo: weekTo,
                        currentWeekFrom: weekFrom,
                        currentWeekTo: weekTo,
                        cumulative: false,
                        checkbox_w53: moment((today.year() - 1).toString() + 'W53').isoWeek() === 53, //rok taki jak pastYear
                        w53: moment((today.year() - 1).toString() + 'W53').isoWeek() === 53
                    }
                }
            }
        },
        data() {
            let year = moment().isoWeekYear(),
                week = moment().isoWeek(),
                quarter = BudgetHelper.getQuarterByWeek(moment())

            if(week === 1) {
                --year
            }

            _.forEach(BudgetHelper.weeks_by_quarter, weeks => {
                if(week === weeks[0]) {
                    quarter = quarter === 1 ? 4 : quarter - 1
                }
            })

            let weekFrom = BudgetHelper.weeks_by_quarter[quarter][0],
                weekTo = _.last(BudgetHelper.weeks_by_quarter[quarter]),
                checkbox = moment(year + 'W53').isoWeek() === 53

            if(weekTo === 53 && !checkbox) {
                weekTo = 52
            }

            return {
                localPeriod: {
                    type: Object,
                    chooseYear: year,
                    pastYear: year - 1,
                    period: 'year',
                    pastWeekFrom: weekFrom,
                    pastWeekTo: weekTo,
                    currentWeekFrom: weekFrom,
                    currentWeekTo: weekTo,
                    pastQuarter: quarter,
                    currentQuarter: quarter,
                    cumulative: false,
                    checkbox_w53: checkbox,
                    w53: checkbox,
                },

                select: {
                    years: _.reverse(_.range(year - (year - 2015), year))
                }
            }
        },
        methods: {
            filterOutHeadquarters(items) {
                let headquarters = [91, 49, 149, 60, 86, 392, 402]

                return items.filter(department => !headquarters.includes(department.id))
            },

            weekNumbersFromQuarter(quarter) {
                switch(quarter) {
                    case 1:
                        return [1, 13]
                    case 2:
                        return [14, 26]
                    case 3:
                        return [27, 39]
                    case 4:
                        return [40, 52]
                    default:
                        return [1,1]
                }
            },

            emitChanges() {
                this.period.period = this.localPeriod.period
                this.period.pastYear = this.localPeriod.pastYear
                this.period.chooseYear = this.localPeriod.chooseYear
                this.period.pastQuarter = this.localPeriod.pastQuarter
                this.period.currentQuarter = this.localPeriod.currentQuarter
                this.period.pastWeekFrom = this.localPeriod.pastWeekFrom
                this.period.pastWeekTo = this.localPeriod.pastWeekTo
                this.period.currentWeekFrom = this.localPeriod.currentWeekFrom
                this.period.currentWeekTo = this.localPeriod.currentWeekTo
                this.period.cumulative = this.localPeriod.cumulative
                this.period.checkbox_w53 = this.localPeriod.checkbox_w53
                this.period.w53 = this.localPeriod.w53

                this.$emit('period-changed', this.period)
            },

            disableRange(x) {
                return  x < this.localPeriod.pastWeekFrom
            },
            setW53() {
                this.localPeriod.w53 = (this.localPeriod.pastYear !== this.localPeriod.chooseYear) ?
                    moment(this.localPeriod.pastYear + 'W53').isoWeek() === 53 : false

                this.localPeriod.checkbox_w53 = this.localPeriod.w53
            },
            loadLastCompare(pastYear, currentYear) {
                this.localPeriod.pastYear = pastYear
                this.localPeriod.chooseYear = currentYear

                this.setW53()
            },
            loadLastQuarterCompare(pastYear, currentYear, pastQuarter, currentQuarter) {
                let [pastFrom, pastTo] = this.weekNumbersFromQuarter(pastQuarter),
                    [currFrom, currTo] = this.weekNumbersFromQuarter(currentQuarter)

                this.localPeriod.pastYear = pastYear
                this.localPeriod.chooseYear = currentYear
                this.localPeriod.pastQuarter = pastQuarter
                this.localPeriod.currentQuarter = currentQuarter
                this.localPeriod.pastWeekFrom = pastFrom
                this.localPeriod.pastWeekTo = pastTo
                this.localPeriod.currentWeekFrom = currFrom
                this.localPeriod.currentWeekTo = currTo

                this.setW53()
            },
            loadPeriod(period) {
                this.localPeriod.period = period
            },
            loadPastWeekFrom(week) {
                let difference = this.localPeriod.pastWeekTo - week,
                    current = (+week +difference),
                    intWeek = parseInt(week)

                this.localPeriod.pastWeekFrom = intWeek
                this.localPeriod.currentWeekFrom = intWeek
                this.localPeriod.currentWeekTo = current
            },
            loadPastWeekTo(week) {
                let intWeek = parseInt(week)

                this.localPeriod.pastWeekTo = intWeek
                this.localPeriod.currentWeekTo = intWeek
                this.localPeriod.currentWeekFrom = this.localPeriod.pastWeekFrom
            },
            loadCurrentWeekFrom(week) {
                let difference = this.localPeriod.pastWeekTo - this.localPeriod.pastWeekFrom
                let intWeek = parseInt(week)
                let current = (+week + difference)

                this.localPeriod.currentWeekFrom = intWeek
                this.localPeriod.currentWeekTo = current
            },

            loadPastQuarter(quarter) {
                quarter = parseInt(quarter)
                let [from, to] = this.weekNumbersFromQuarter(quarter)

                this.localPeriod.pastQuarter = quarter
                this.localPeriod.currentQuarter = quarter
                this.localPeriod.pastWeekFrom = from
                this.localPeriod.pastWeekTo = to
                this.localPeriod.currentWeekFrom = from
                this.localPeriod.currentWeekTo = to

            },
            loadCurrentQuarter(quarter) {
                quarter = parseInt(quarter)
                let [from, to] = this.weekNumbersFromQuarter(quarter)

                this.localPeriod.currentQuarter = quarter
                this.localPeriod.currentWeekFrom = from
                this.localPeriod.currentWeekTo = to
            },

            lastWeek(year) {
                return moment(year + 'W53').isoWeek() === 53 ? 53 : 52
            }
        }
    }
</script>

<style lang="less" scoped>
    table {
        border-collapse:separate;
        border-spacing: 0 .3em;

        > tr > th,
        > tr > td {
            padding-right: 3px;
        }
    }
</style>