<template lang="pug">
    OptiDialog
        template(#header)
            i.icon-handbag.mr3
            span {{product.name}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            OptiProductInfo(:product="product")

        template.text-right(#footer)
            OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'

    export default {
        name: 'OptiDialogProduct',
        components: {
            OptiDialog,
            OptiButton,
            OptiProductInfo
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;
    }
</style>