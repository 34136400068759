<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-film.mr3
            span {{packDetail.name}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            video(loop controls preload="auto" autoplay width="400" :src="'video/' + packDetail.id + '.mp4'")

        template(#footer)
            OptiButton.pull-right(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import {Activity} from '@/js/app/vue/helpers/Activity'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiPictogramsDialog',
        components: {
            OptiDialog,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            packDetail: {
                type: Object,
                required: true
            },

            departmentId: {
                type: Number,
                required: true
            }
        },
        mounted() {
            Activity.increaseForDepartment(this.departmentId)
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;
    }

    video {
        display: block;
        margin: 0 auto;
    }
</style>