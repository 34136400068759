<template lang="pug">
    OptiDialog.fullscreen(:header="false")
        template(#body)
            OptiOptometristsDisplayControls
            .correction-dialog
                .dialog-contents(v-if="!loading.permutations && !loading.path")
                    table.table
                        tr
                            OptiOptometristsSurveysPath(
                                :view="view"
                                :path="path"
                                :route="route"
                                :visited="visited"
                                @update-view="updateView")
                            td.text-center(style="padding:10px" v-show="view")
                                .text-expressive(style="font-size:18px")
                                    i.fa.fa-eye.mr3
                                    span.mr3 {{view.desc}}
                                    button.btn.btn-info(type="button" :class="{'btn-warning active': help}"
                                        @click="help = !help") {{'default.HELP'|translate}}

                                div(v-show="!help")
                                    keep-alive
                                        OptiOptometristsSurveysOptotypesVisus(
                                            v-if="['visus1', 'visus2', 'binVisus'].includes(view.id)"
                                            :permutation="permutation.selected"
                                            :dirty="dirty"
                                            :type="type"
                                            :visus="viewModel[view.id].visus"

                                            @update-visus="updateVisus")

                                        OptiOptometristsSurveysOptotypesRedGreenMirror(
                                            v-if="['EE1', 'EE2'].includes(view.id)"
                                            :permutation="permutation.selected"
                                            :cz="viewModel[view.id].cz"

                                            @update-cz="updateCz")

                                        OptiOptometristsSurveysOptotypesRedGreen(
                                            v-if="['CZ1', 'CZ2'].includes(view.id)"
                                            :permutation="permutation.selected"
                                            :type="type"
                                            :cz="viewModel[view.id].cz"

                                            @update-cz="updateCz")

                                OptiOptometristsTips(v-show="help" :data="view.tips")
                            td.correction-controls
                                .form-group
                                    label.control-label.col-xs-12.text-center(style="color:#fff") {{'optometrist.video.RANDOM'|translate}}
                                    .col-xs-12(style="white-space:nowrap;display:flex;justify-content:space-between;padding-bottom:10px")
                                        template(v-for="shuffle in permutation.shuffle")
                                            label.btn.btn-default(:for="'random' + shuffle"
                                                :class="{'btn-warning active': permutation.selected.active === shuffle}") {{shuffle + 1}}
                                            input.hide(type="radio" :id="'random' + shuffle" v-model="permutation.selected.active"
                                                name="random" :value="shuffle" @change="updatePermutation()")

                                table(style="border-spacing:3px;border-collapse:separate;width:100%;margin-top:10px")
                                    tr.text-center
                                        th(style="color:#fff;font-weight:bolder;width:50%") {{'prescription.RIGHT'|translate}}
                                        th(style="color:#fff;font-weight:bolder;width:50%") {{'prescription.LEFT'|translate}}
                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="sphere" :value="prescription.reSphere")
                                        td
                                            OptiOptometristsSurveysPower(type="sphere" :value="prescription.leSphere")

                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="cylinder" :value="prescription.reCylinder")
                                        td
                                            OptiOptometristsSurveysPower(type="cylinder" :value="prescription.leCylinder")

                                    tr
                                        td
                                            OptiOptometristsSurveysPower(v-show="prescription.reCylinder"
                                                type="axis" :value="prescription.reAxis")
                                        td
                                            OptiOptometristsSurveysPower(v-show="prescription.leCylinder"
                                                type="axis" :value="prescription.leAxis")
                                    tr
                                        td
                                            OptiOptometristsSurveysPower(type="visus" :value="viewModel.visus1.visus")
                                        td
                                            OptiOptometristsSurveysPower(type="visus" :value="viewModel.visus2.visus")

                                    tr
                                        td(colspan="2")
                                            OptiOptometristsSurveysPower(style="margin:0 auto;width:50%"
                                                type="binvisus" :value="viewModel.binVisus.visus")

                                OptiOptometristsSurveysOptotypesType(
                                    v-show="view.id === 'visus1'"
                                    :type="type" @update-type="t => type = t")

                OptiDimmer(:active="loading.permutations || loading.path")
        template.text-right(#footer)
            .row
                .col-xs-4.text-left
                    OptiButton(type="success" text="optometrist.video.confirm.previous" @confirmed="goPreviousView()"
                        :disabled="(route[0] === view.id) || disabled_btn_step") {{'optometrist.video.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left
                .col-xs-4.text-center
                    OptiButton(type="success" text="optometrist.video.confirm.success" @confirmed="confirmed()"
                        :disabled="!dirty && view.id !== 'visus2'") {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check
                    OptiButton#cancelled(type="danger" text="optometrist.video.confirm.cancel" @confirmed="cancelled()"
                        ) {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove
                .col-sm-4.text-right(style="white-space:nowrap")
                    OptiButton(type="success" :disabled="disabledNextView()" @click.native="goNextView()"
                        :right="true") {{'optometrist.video.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {PermutationsService} from '@/js/app/vue/components/Optometrists/Helpers/PermutationsService'
    import {PathService} from '@/js/app/vue/components/Optometrists/Helpers/PathService'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'
    import OptiOptometristsSurveysPower from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysPower'
    import OptiOptometristsSurveysPath from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysPath'
    import OptiOptometristsSurveysOptotypesType
        from '@/js/app/vue/components/Optometrists/Surveys/OptiOptometristsSurveysOptotypesType'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiOptometristsSurveysOptotypesVisus
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesVisus'
    import OptiOptometristsSurveysOptotypesRedGreen
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreen'
    import OptiOptometristsSurveysOptotypesRedGreenMirror
        from '@/js/app/vue/components/Optometrists/Surveys/Optotypes/OptiOptometristsSurveysOptotypesRedGreenMirror'

    export default {
        name: 'OptiPatientsPrescriptionsSurveysDist',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips,
            OptiOptometristsDisplayControls,
            OptiOptometristsSurveysPower,
            OptiOptometristsSurveysPath,
            OptiOptometristsSurveysOptotypesType,
            OptiDimmer,
            OptiOptometristsSurveysOptotypesVisus,
            OptiOptometristsSurveysOptotypesRedGreen,
            OptiOptometristsSurveysOptotypesRedGreenMirror
        },
        props: {
            prescription: {
                type: Object,
                required: true
            },

            side: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unique: Math.random().toString(36).substr(2, 9),

                help: false,

                permutation: {
                    unique: null,
                    shuffle: [],
                    selected: {}
                },

                view: {},
                viewModel: {},

                route: ['visus1', 'EE1', 'visus2', 'EE2', 'binVisus'],
                visited: ['visus1'],

                path: [],
                type: 'letter',

                dirty: false,
                disabled_btn_step: false,

                loading: {
                    permutations: true,
                    path: true
                }
            }
        },
        mounted() {
            PermutationsService.load().then(res => {
                [this.permutation.unique, this.permutation.shuffle, this.permutation.selected] = res
                this.loading.permutations = false
            })

            this.loadPath()
        },
        methods: {
            loadPath() {
                PathService.load('prescription-check-far').then(res => {
                    this.path = res

                    PathService.setupForAcuityWithCorrection(this.path, this.side)

                    this.view = PathService.findPoint(this.path, 'visus1')

                    this.setupViewModel('visus1', {
                        rSphere: 0,
                        rCylinder: 0,
                        rAxis: 0,

                        lSphere: 0,
                        lCylinder: 0,
                        lAxis: 0,

                        cz: '0',
                        visus: '-'
                    }, true)

                    this.setupViewModel('visus2', {
                        rSphere: 0,
                        rCylinder: 0,
                        rAxis: 0,

                        lSphere: 0,
                        lCylinder: 0,
                        lAxis: 0,

                        cz: '0',
                        visus: '-'
                    }, true)

                    this.$set(this.viewModel, 'binVisus', {
                        rSphere: 0,
                        rCylinder: 0,
                        rAxis: 0,

                        lSphere: 0,
                        lCylinder: 0,
                        lAxis: 0,

                        cz: '0',
                        visus: '-'
                    })

                    this.prescription.check.logs.push({survey_id: this.unique, type: 'far', action: 'start',
                        side: this.view.x, description: 'Rozpoczęto badanie', editable: ['cz', 'visus'],
                        values: this.viewModel[this.view.id], survey_at: moment().valueOf()})

                    this.loading.path = false
                })
            },

            setupViewModel(id, basedOn, override) {
                if(!this.viewModel[id] || override) {
                    let previous = typeof basedOn === 'object' ? basedOn : this.viewModel[basedOn]

                    if(previous) {
                        this.$set(this.viewModel, id, _.cloneDeep(previous))

                        let altId = PathService.alternative(id)
                        if(id !== altId) {
                            this.viewModel[altId] = this.viewModel[id]
                        }
                        let altId2 = PathService.alternative(altId)
                        if(id !== altId2) {
                            this.viewModel[altId2] = this.viewModel[id]
                        }

                        if(id === 'visus2') {
                            this.viewModel[id].visus = '-'
                        } else if(id === 'binVisus') {
                            let visus1 = parseFloat(this.viewModel.visus1.visus.replace(',', '.')),
                                visus2 = parseFloat(this.viewModel.visus2.visus.replace(',', '.'))

                            this.viewModel[id].visus = visus1 > visus2 ?
                                visus2.toString().replace('.', ',') : visus1.toString().replace('.', ',')

                            if(this.viewModel[id].visus === '1') {
                                this.viewModel[id].visus += ',0'
                            }
                        } else if(this.view.type === 'redgreen' || this.view.type === 'redgreen2') {
                            this.viewModel[id].cz = '0'
                        }
                    }
                }
            },

            disabledNextView() {
                if(this.disabled_btn_step) {
                    return true
                } else if(!this.dirty && this.view.type === 'visus') {
                    return true
                } else if(this.view) {
                    if(this.route[this.route.length - 1] === this.view.id) {
                        return true
                    } else if(this.view.type === 'visus') {
                        return this.viewModel[this.view.id].visus === '-'
                    } else if(this.view.type === 'redgreen' || this.view.type === 'redgreen2') {
                        return this.viewModel[this.view.id].cz === '0'
                    }
                }

                return false
            },
            setDescription(view) {
                let type = ''

                if(view.id === 'visus1') {
                    switch(this.type) {
                        case 'circle':
                            type = 'C'
                            break

                        case 'hs':
                            type = 'E'
                            break

                        case 'letter':
                            type = 'A'
                            break

                        case 'number':
                            type = '8'
                    }

                    return view.desc + ' (typ ' + type + ')'
                }

                return view.desc
            },

            changedView(previous) {
                this.disabled_btn_step = true

                setTimeout(() => {
                    this.disabled_btn_step = false
                }, 1000)

                this.dirty = false
                this.help = false

                this.prescription.check.logs.push({survey_id: this.unique, type: 'far', side: previous.x, action: 'step',
                    description: this.setDescription(previous), editable: previous.editable, values: this.viewModel[previous.id],
                    survey_at: moment().valueOf()})
            },
            goPreviousView() {
                let previous = _.cloneDeep(this.view)

                this.visited.pop()

                let id = this.visited.pop()

                this.view = PathService.findPoint(this.path, id)

                this.visited.push(this.view.id)

                this.changedView(previous)
            },
            goNextView() {
                let key = this.route.indexOf(this.view.id),
                    previous = _.cloneDeep(this.view)

                if(key !== -1) {
                    key += 1

                    if(key !== this.route.length) {
                        this.view = PathService.findPoint(this.path, this.route[key])
                    }
                } else {
                    this.view.links.forEach(link => {
                        if(this.route.includes(link.id)) {
                            this.view = link
                        }
                    })
                }

                this.setupViewModel(this.view.id, previous.id, true)
                this.visited.push(this.view.id)

                this.changedView(previous)
            },

            displayClose() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')
            },
            confirmed() {
                if(this.view.id === 'binVisus') {
                    if(this.side === 'right') {
                        this.prescription.check.dist_r_visus = this.viewModel.visus1.visus
                        this.prescription.check.dist_l_visus = this.viewModel.visus2.visus
                        this.prescription.check.dist_r_cz = this.viewModel.EE1.cz
                        this.prescription.check.dist_l_cz = this.viewModel.EE2.cz
                    } else {
                        this.prescription.check.dist_r_visus = this.viewModel.visus2.visus
                        this.prescription.check.dist_l_visus = this.viewModel.visus1.visus
                        this.prescription.check.dist_r_cz = this.viewModel.EE2.cz
                        this.prescription.check.dist_l_cz = this.viewModel.EE1.cz
                    }

                    this.prescription.check.dist_binvisus = this.viewModel.binVisus.visus
                } else if(this.side === 'right') {
                    this.prescription.check.dist_r_visus = this.viewModel.visus1.visus
                    this.prescription.check.dist_r_cz = this.viewModel.EE1.cz

                    if(this.view.id === 'visus2') {
                        this.prescription.check['dist_l_visus'] = this.viewModel.visus2.visus
                        this.prescription.check['dist_l_cz'] = this.viewModel.EE2.cz
                    }
                } else if(this.side === 'left') {
                    this.prescription.check.dist_l_visus = this.viewModel.visus1.visus
                    this.prescription.check.dist_l_cz = this.viewModel.EE1.cz

                    if(this.view.id === 'visus2') {
                        this.prescription.check.dist_r_visus = this.viewModel.visus2.visus
                        this.prescription.check.dist_r_cz = this.viewModel.EE2.cz
                    }
                }

                this.displayClose()

                this.prescription.check.logs.push({survey_id: this.unique, type: 'far', side: this.view.x, action: 'end',
                    description: this.view.desc + ' - zakończono badanie', editable: this.view.editable,
                    values: this.viewModel[this.view.id], survey_at: moment().valueOf()})

                this.$emit('confirmed', this.prescription.check)
            },
            cancelled() {
                this.displayClose()

                this.prescription.check.logs.push({survey_id: this.unique, type: 'far', side: this.view.x, action: 'cancel',
                    description: this.view.desc + ' - anulowano badanie', editable: this.view.editable,
                    values: this.viewModel[this.view.id], survey_at: moment().valueOf()})

                this.$emit('cancelled')
            },

            updateView(l) {
                let previous = _.cloneDeep(this.view)

                this.visited = l[0]
                this.view = l[1]

                this.changedView(previous)
            },
            updatePermutation() {
                PermutationsService.updatePermutation(this.permutation)
            },
            updateVisus(visus) {
                this.viewModel[this.view.id].visus = visus

                this.dirty = true
            },
            updateCz(cz) {
                this.viewModel[this.view.id].cz = cz

                this.dirty = true
            }
        }
    }
</script>