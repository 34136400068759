<template lang="pug">
    div
        .row(v-if="!active")
            .col-xs-5
                h4.page-header(style="margin-top:0px") {{'catalog.hoya.heading.' + active_tab.toUpperCase()|translate}}

            .col-xs-12(v-if="!active")
                ul.nav.nav-tabs.nav-justified.icons-header-tab
                    li(:class="{'active': active_tab === 'report'}")
                        a(:title="'catalog.hoya.heading.REPORT'|translate" @click="setActive('report')")
                            i.visible-xs-inline.fa-lg.fa.fa-edit.mr3
                            i.hidden-xs.fa.fa-edit
                            span.visible-xs-inline.tab-title
                    li(:class="{'active': active_tab === 'update_prices'}")
                        a(:title="'catalog.hoya.heading.UPDATE_PRICES'|translate" @click="setActive('update_prices')")
                            i.visible-xs-inline.fa-lg.fa.fa-gears.mr3
                            i.hidden-xs.fa.fa-gears
                            span.visible-xs-inline.tab-title

                .tab-content
                    .row(v-show="active_tab === 'report'")
                        .col-xs-12(style="margin-top:10px")
                            AngularComponent(:component="dailyReport")

                    OptiHoyaCatalogDataImporter.tab-pane(:class="{'active': active_tab === 'update_prices'}")

            OptiDimmer(:active="active")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiHoyaCatalogDataImporter from '@/js/app/vue/components/Catalog/Hoya/OptiHoyaCatalogDataImporter'
    import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogHoya',
        components: {
            OptiHoyaCatalogDataImporter,
            AngularComponent,
            OptiDimmer
        },
        data() {
            return {
                active: false,
                active_tab: 'report',
                lenses: {},
                options: {}
            }
        },
        mounted() {
            API.get('catalog/hoya/getLenses').then(res => {
                this.lenses = res.data.lenses
                this.options = res.data.options
            })

            if(!this.$state.params.page) {
                this.$state.go('app.catalog.hoya.daily', {
                    page: 1
                })
            }
        },
        methods: {
            setActive(hash) {
                this.active_tab = hash

                this.$emit('update-breadcrumb', this.active_tab)
            }
        },
        computed: {
            dailyReport() {
                return {
                    template: `<base-table end-point="/api/specifications/hoyaDifference/dailyReports" ref="app.catalog.hoya.daily"   header="Raporty dzienne Hoya"></base-table>`,
                    $ctrl: {}
                }
            }
        }
    }
</script>