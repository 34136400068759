<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right(rowspan="2") #
                    th(style="border-right:1px solid #aaa" rowspan="2") {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th.text-center(style="border-right:1px solid #aaa" colspan="2") {{'prescription.RIGHT'|translate}}
                    th.text-center(colspan="2") {{'prescription.LEFT'|translate}}
                    th(style="border-left:1px solid #aaa")
                tr
                    th.text-center CZ
                    th.text-center(style="border-right:1px solid #aaa") Visus
                    th.text-center CZ
                    th.text-center Visus
                    th(style="border-left:1px solid #aaa")
            tbody
                tr(v-for="(log, key) in survey.data")
                    td.text-right {{key + 1}}
                    td(style="border-right:1px solid #aaa") {{log.description}}
                    td.text-center(:class="{'bold-black': log.changed.includes('cz'), 'text-aaa': !log.editable.includes('cz')}")
                        OptiEnum(v-show="!log.side || log.side === 'r'" enum="InterviewRedGreen" :id="log.parsed.cz")
                    td.text-center(style="border-right:1px solid #aaa"
                        :class="{'bold-black': log.changed.includes('visus'), 'text-aaa': !log.editable.includes('visus')}")
                        span(v-show="!log.side || log.side === 'r'") {{log.parsed.visus}}
                    td.text-center(:class="{'bold-black': log.changed.includes('cz'), 'text-aaa': !log.editable.includes('cz')}")
                        OptiEnum(v-show="!log.side || log.side === 'l'" enum="InterviewRedGreen" :id="log.parsed.cz")
                    td.text-center(:class="{'bold-black': log.changed.includes('visus'), 'text-aaa': !log.editable.includes('visus')}")
                        span(v-show="!log.side || log.side === 'l'") {{log.parsed.visus}}
                    td.text-right(style="border-left:1px solid #aaa")
                        span(v-show="log.action !== 'start'") {{log.time_diff|secondsToHuman}}
</template>

<script>
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'OptiPatientsPrescriptionDialogsSurveyFar',
        components: {
            OptiEnum
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>