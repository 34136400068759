<template lang="pug">
    .opti-select-array
        VueMultiSelect(
            id="opti-select-array"
            :options="options"
            label="name"
            :value="s"
            :clearable="false"
            :placeholder="'default.SELECT'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            :group-select="false"
            :internal-search="true"
            :searchable="true"

            @input="update")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option.name }}
            template(slot="option" slot-scope="props")
                span {{ props.option.name }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectArray',
        components: {
            VueMultiSelect
        },
        props: {
            data: {
                type: Array,
                required: true
            },
            disabled: {
                type: Array,
                required: false,
                default: []
            },
            selected: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                options: this.data.map(d => { return {name: d, $isDisabled: this.disabled.includes(d)} }),
                s: this.selected
            }
        },
        methods: {
            update(d) {
                this.s = d

                this.$emit('selected', this.s.name)
            }
        }
    }
</script>

<style lang="less" scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
