<template lang="pug">
    .opti-select-report-offers-periods
        VueMultiSelect(
            :options="periods"
            :value="period"
            :clearable="false"
            :placeholder="'search.report_offers_periods.PLACEHOLDER'|translate"
            :group-select="false"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"

            @input="p => $emit('selected', p)")
            template(slot="singleLabel" slot-scope="props")
                .pull-left
                    span.text-muted.mr3 \#{{props.option.id}}
                    span {{label(props.option)}}
                .pull-right.text-muted(v-show="isNewest(props.option)") {{'report.offer.NEWEST'|translate}}

            template(slot="option" slot-scope="props")
                .pull-left
                    span.text-muted.mr3 \#{{props.option.id}}
                    span {{label(props.option)}}
                .pull-right.text-muted(v-show="isNewest(props.option)") {{'report.offer.NEWEST'|translate}}

            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectReportOffersPeriods',
        components: {
            VueMultiSelect
        },
        props: {
            period: {
                type: Object | null,
                required: true
            }
        },
        data() {
            return {
                periods: []
            }
        },
        mounted() {
            API.get('report/offers/periods').then(res => {
                this.periods = res.data

                if(this.periods.length) {
                    this.$emit('selected', this.periods[0])
                }
            }).catch(() => {
                this.$notify.error('search.report_offers_periods.notify.error.LOAD')
            })
        },
        methods: {
            label(period) {
                let start = moment(period.start_date),
                    end = moment(period.end_date)

                return start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')
            },
            isNewest(period) {
                let selected_end = moment(period.end_date),
                    newest_end_date = moment.max(this.periods.map(p => moment(p.end_date)))

                return selected_end.isSame(newest_end_date)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
