export default {
    getDefaultState() {
        return {
            questions: {
                frames: [],
                hoya: []
            },
            index_question_frames: 0,
            selected_questions_hoya: [],
            selected_current_hoya_option: null,

            products: [],
            products_count: 0,

            colors: [],
            colors_header: '',

            filters: {
                types: [],
                selected: []
            },

            patient: {},
            favorites: [],
            chose_products: [],

            department_id: 0,

            paginator: {
                page: 1,
                pages: 1
            },

            english_version: false,
            mode: 'questions_frames',
            help: false,
            changed: false,
            dialog: {
                by_client: false,
                favorites: false
            },
            loading: {
                main: false,
                questions: true,
                products: true,
                hoya: false
            },

            to_skip: [5, 6, 7]
        }
    },
    cache(state) {
        state.updated_at = moment()

        localStorage.setItem('offer', JSON.stringify(state))
    },

    isValidFilter(filter) {
        if(filter.name) {
            switch(filter.type) {
                case 'operators':
                    if(filter.operator === 'from-to') {
                        let from = typeof filter.from !== 'undefined' ? filter.from : 0,
                            to = typeof filter.to !== 'undefined' ? filter.to : 0

                        return from <= to
                    }

                    return !!filter.operator

                case 'slider':
                    return true

                case 'select':
                    return filter.option

                case 'multiselect':
                case 'multiselect-group':
                    return filter.option.length
            }
        }

        return false
    },

    number(float, decimals) {
        float = float ? float : 0

        return float.toFixed(decimals).replace('.', ',')
    }
}
