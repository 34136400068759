<template lang="pug">
    .row
        .col-md-10.col-md-offset-1
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'sale.reclamation.admin.HEADING'|translate}}

                .panel-body
                    form.form.form-horizontal(name="formReclamationAdmin")
                        .form-group
                            label.col-sm-4.control-label.star-required(for="accepted") {{'sale.reclamation.admin.ACCEPTED'|translate}}
                            .col-sm-8
                                select.form-control#accepted(v-model="form.accepted" name="accepted")
                                    option(v-for="option in options" :value="option.id") {{option.name|translate}}
                                label.warn(for="accepted" v-show="!$v.form.accepted.minValue") {{'validation.formValidate.required'|translate}}
                        .form-group
                            label.col-sm-4.control-label.star-required(for="decision") {{'sale.reclamation.admin.DECISION'|translate}}
                            .col-sm-8
                                textarea.form-control#decision(name="decision" rows="5" v-model="form.decision" required)
                                OptiValidate(f="decision" :data="$v.form.decision")
                        .form-group
                            .col-sm-8.col-sm-offset-4
                                div(v-for="option in form.options")
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" :id="'option-' + option.id" v-model="option.selected")
                                            span.fa.fa-check
                                    label(:for="'option-' + option.id") {{option.name}}
                        .form-group(style="margin-bottom:0")
                            .col-sm-8.col-sm-offset-4
                                OptiButton(type="success" :disabled="$v.form.$invalid"
                                    :title="'sale.reclamation.action.considered.BTN'|translate"
                                    text="sale.reclamation.action.considered.confirm" @confirmed="goConsidered"
                                    ) {{'sale.reclamation.action.considered.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, minValue} from 'vuelidate/lib/validators'
    import {EnumReclamationDecision} from '@/js/app/vue/enums/Enums'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReclamationSaleDetailsDecision',
        components: {
            OptiValidate,
            OptiButton
        },
        data() {
            return {
                options: [],
                form: {
                    id: this.$state.params.id,
                    accepted: -1,
                    decision: '',
                    options: []
                }
            }
        },
        validations: {
            form: {
                accepted: {
                    minValue: minValue(0)
                },
                decision: {
                    required
                }
            }
        },
        mounted() {
            API.get('sale/reclamation/admin/options').then(res => {
                this.form.options = res.data
            }).catch(() => {
                this.$notify.error('sale.reclamation.notify.error.LOADOPTIONFORADMIN')
            })

            EnumReclamationDecision.simpleItems().forEach(decision => {
                if(![2, 4].includes(decision.id)) {
                    this.options.push(decision)
                }
            })
        },
        methods: {
            parseOptions(options) {
                let _return = {}

                options.forEach(option => {
                    if(option.selected) {
                        _return[option.id] = {
                            type: 2
                        }
                    }
                })

                return _return
            },
            goConsidered() {
                let _form = _.cloneDeep(this.form)

                _form.options = this.parseOptions(_form.options)

                this.$emit('go-considered', _form)
            }
        }
    }
</script>
