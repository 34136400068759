<template lang="pug">
    .row
        OptiDimmer(:active="active")
        .col-xs-12
            form.panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span Edycja pakietu \#{{packproduct.id}} {{packproduct.pack_name1}}
                .panel-body
                    .row
                        .col-xs-12
                            .form-group
                                label
                                    div(style="color:green" v-if="packproduct.active") {{'pack.edit.ISACTIVE'|translate}}
                                    .warn(v-if="!packproduct.active") {{'pack.edit.ISNOTACTIVE'|translate}}
                        .col-xs-12
                            .form-group
                                OptiSwitchToggleButton(
                                        :checked="checked"
                                        @input="activate"
                                        v-model="packproduct.active"
                                    )
                                    template(#left) {{'pack.edit.ISNOTACTIVE'|translate}}
                                    template(#right)  {{'pack.edit.ISACTIVE'|translate}}
                        .col-sm-4
                            .form-group
                                label {{'pack.table.PACK_NAME'|translate}}
                                input.form-control(v-model="packproduct.pack_name1" name="pack_name1")
                            .form-group
                                label {{'pack.table.DELIVERY_TIME'|translate}}
                                input.form-control(v-model="packproduct.delivery_time" name="delivery_time")
                        .col-sm-4
                            .form-group
                                label {{'pack.table.PRICE'|translate}}
                                input.form-control(v-model="packproduct.price" name="price")
                            .form-group
                                label {{'pack.table.TYPE'|translate}}
                                select.form-control(v-model="packproduct.type" name="type")
                                    option(value="0") Jednoogniskowa
                                    option(value="2") Progresywna
                                    option(value="3") Biurowa
                        .col-xs-12
                            label {{'pack.table.DETAIL_ID'|translate}}
                        .col-xs-12
                            .form-group
                                label(v-for="det in details")
                                    img.mr3(style="width:90px;margin-bottom:15px; cursor:pointer"
                                        :src="'img/task/pack/' + det.id + '.png'" @click="det.enabled = !det.enabled"
                                        v-bind:style="[det.enabled ? {opacity:1} : {opacity:0.15}]" v-model="det.id")
                        .col-sm-12
                            label {{'pack.edit.LINKS'|translate}}
                        .col-xs-12(v-if="!packproduct.productsTo")
                            label Pakiety przypisane do pakietu {{packproduct.id}}
                            .panel.panel-primary
                                table.table.table-striped.table-responsive
                                    thead
                                        tr
                                            th.text-left ID pakietów przypisanych do {{packproduct.id}}
                                            th.text-left ID pakietu
                                            th.text-left Nazwa pakietów przypisanych do {{packproduct.id}}
                                            th
                                    tbody
                                        tr(v-for="pT in packproduct.productsTo" v-model="productsTo")
                                            td
                                                input.form-control(v-model="pT.id" name="prod_from" disabled)
                                            td
                                                input.form-control(v-model="packproduct.id" name="prod_to" disabled)
                                            td
                                                input.form-control(v-model="pT.pack_name1" name="name1" disabled)
                                            td
                                                OptiButton(
                                                    type="info"
                                                    @click.native="$state.go('app.packs.edit', {id: pT.id})"
                                                ) {{'pack.table.EDIT'|translate}}
                                                    template(#icon)
                                                        i.fa.fa-edit
                        .col-xs-12
                            label Pakiety przypisane od pakietu {{packproduct.id}}
                            .panel.panel-primary
                                table.table.table-responsive.table-striped
                                    thead
                                        tr
                                            th.text-left ID pakietu
                                            th.text-left ID pakietów przypisanych od {{packproduct.id}}
                                                th.text-left Priorytet
                                            th.text-left Nazwa
                                    tbody
                                        tr(v-for="(pF, index) in packproduct.packFrom")
                                            td
                                                input.form-control(v-model="pF.pack_from" name="pack_from" disabled)
                                            td
                                                input.form-control(v-model="pF.pack_to" name="pack_to" v-if="pF.pack_from" disabled)
                                                input.form-control(v-model="pF.pack_to" name="pack_to" v-if="pF.pack_from === 0")
                                            td
                                                input.form-control(v-model="pF.priority" name="priority")
                                            td
                                                OptiSelectPackageAll(
                                                    :package="pF.pack_to"
                                                    :index="index"
                                                    @selected="updatePackFrom"
                                                )
                                            td
                                                OptiButton(
                                                    type="danger"
                                                    @click.native="removeLink(index)"
                                                ) {{'pack.edit.DELETE'|translate}}
                                                    template(#icon)
                                                        i.fa.fa-trash
                        .col-xs-12
                            .form-group
                                OptiButton(
                                    style="width:100%"
                                    type="primary"
                                    @click.native="addLink"
                                ) {{'pack.edit.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
                        .col-xs-12
                            label {{'pack.new.SPHERES'|translate}}
                            .panel.panel-primary
                                table.table.table-striped.table-responsive
                                    thead
                                        tr
                                            th.text-left #
                                            th.text-left {{'pack.table.ID'|translate}}
                                            th.text-left {{'pack.edit.FI'|translate}}
                                            th.text-left {{'pack.edit.SPHERE_FROM'|translate}}
                                            th.text-left {{'pack.edit.SPHERE_TO'|translate}}
                                            th.text-left {{'pack.edit.CYLINDER_FROM'|translate}}
                                            th.text-left {{'pack.edit.CYLINDER_TO'|translate}}
                                            th.text-left {{'pack.edit.TOP'|translate}}
                                            th.text-left {{'pack.edit.DOWN'|translate}}
                                            th.text-left {{'pack.edit.ADD_FROM'|translate}}
                                            th.text-left {{'pack.edit.ADD_TO'|translate}}
                                            th.text-left {{'pack.edit.ADD_EXCEPT'|translate}}
                                            th
                                    tbody
                                        tr(v-for="(sphere, index) in packproduct.packSphere")
                                            td.text-center {{index + 1}}
                                            td.text-left
                                            td
                                                input.form-control(v-model="sphere.fi" name="fi")
                                            td
                                                input.form-control(v-model="sphere.sphere_from" name="sphere_from")
                                            td
                                                input.form-control(v-model="sphere.sphere_to" name="sphere_to")
                                            td
                                                input.form-control(v-model="sphere.cylinder_from" name="cylinder_from")
                                            td
                                                input.form-control(v-model="sphere.cylinder_to" name="cylinder_to")
                                            td
                                                select.form-control(v-model="sphere.top" name="top" style="width:90px")
                                                    option(value="0") {{'pack.edit.STRAIGHT'|translate}}
                                                    option(value="1") {{'pack.edit.INCLINE'|translate}}
                                            td
                                                select.form-control(v-model="sphere.down" name="down" style="width:90px")
                                                    option(value="0") {{'pack.edit.STRAIGHT'|translate}}
                                                    option(value="1") {{'pack.edit.INCLINE'|translate}}
                                            td
                                                input.form-control(v-model="sphere.add_from" name="add_from")
                                            td
                                                input.form-control(v-model="sphere.add_to" name="add_to")
                                            td
                                                input.form-control(v-model="sphere.add_except" name="add_except")
                                            td
                                                OptiButton(
                                                    type="danger"
                                                    @click.native="removeRow(index)"
                                                ) {{'pack.edit.DELETE'|translate}}
                                                    template(#icon)
                                                        i.fa.fa-trash
                        .col-xs-12
                            .form-group
                                OptiButton(
                                    type="default"
                                    style="width:100%"
                                    @click.native="addRow()"
                                ) {{'pack.edit.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
                        .col-xs-12
                            .form-gourp.text-right
                                OptiButton(
                                    type="success"
                                    :disabled="!packproduct.packSphere || !details"
                                    @click.native="updatePack"
                                ) {{'pack.edit.SAVE'|translate}}
                                    template(#icon)
                                        i.fa.fa-save
                                OptiButton(
                                    style="margin-left:3px"
                                    type="danger"
                                    @click.native="goBack()"
                                ) {{'pack.edit.CANCEL'|translate}}
                                    template(#icon)
                                        i.fa.fa-remove
</template>

<script>
import {API} from '@/js/app/vue/api'
import _ from 'lodash'
import OptiButton from "@/js/app/vue/components/Button/OptiButton";
import OptiSwitchToggleButton from "@/js/app/vue/components/Button/OptiSwitchToggleButton";
import OptiSelectPackageAll from "@/js/app/vue/components/Select/OptiSelectPackageAll";
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";

export default {
    name: "OptiPackEdit",
    components: {
        OptiButton,
        OptiSwitchToggleButton,
        OptiSelectPackageAll,
        OptiDimmer
    },
    data() {
        return {
            packproduct: [{
                productsTo: []
            }],
            details: [],
            checked: false,
            active: true
        }
    },
    mounted() {
        API.get('packs/edit/' + this.$state.params.id).then(res => {
            this.packproduct = res.data.product

            let enabledDetails = res.data.product.product.map(item => item.detail_id)

            this.$set(this, 'details', _.map(res.data.details, item => {
                item.enabled = enabledDetails.includes(item.id)
                return item
            }))

            this.productsTo = res.data.productsTo
            if(this.packproduct.active === 1) {
                this.checked = true
            }else if(this.packproduct.active === 0) {
                this.checked = false
            }
            this.active = false
        })
    },
    methods: {
        activate(checked) {
            this.checked = checked
            if(this.checked) {
                this.packproduct.active = 1
            } else if(!this.checked) {
                this.packproduct.active = 0
            }
        },
        updatePackFrom(_package) {
              this.packproduct.packFrom[_package.index].pack_to = _package.id
        },
        removeLink(index) {
            this.packproduct.packFrom.splice(index, 1)
        },
        addLink() {
            this.packproduct.packFrom.push({
                pack_from: 0,
                pack_to: 0,
                priority: 0
            })
        },
        removeRow(index){
            this.packproduct.packSphere.splice(index, 1)
        },
        addRow() {
            this.packproduct.packSphere.push({
                id: 0,
                fi: 0,
                sphere_from: 0,
                sphere_to: 0,
                cylinder_from: 0,
                cylinder_to: 0,
                top: 0,
                down: 0,
                color: '',
                add_from: 0,
                add_to: 0,
                add_except: ''
            })
        },
        updatePack() {
            this.active = true
            API.post('packs/update/' + this.$state.params.id, {
                active: this.packproduct.active,
                pack_name1: this.packproduct.pack_name1,
                type: this.packproduct.type,
                price: this.packproduct.price,
                delivery_time: this.packproduct.delivery_time,
                packSphere: this.packproduct.packSphere,
                details: this.details.filter(item => item.enabled),
                packFrom: this.packproduct.packFrom
            }).then(() => {
                this.$notify.success('Pakiet został zaktualizowany')
                this.active = false
            }).finally(() => {
                this.$state.go('app.packs.list')
            }).catch(() => {
                this.$notify.error('Wystąpił błąd. Nie można zaktualizować pakietu.')
            })
        },
        goBack() {
            return this.$state.go('app.packs.list')
        },
    },
}
</script>

<style scoped>

</style>