<template lang="pug">
    div
        div(style="margin-top:10px;text-align:right"
            v-show="(correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl) && !previouslens.dist_cant_read_powers")
            OptiButton(type="success" v-show="sign.with_correction === 'plus'"
                @click.native="sign.with_correction = 'minus'") {{'optometrist.cylinder.MINUS'|translate}}
                template(#icon)
                    i.fa.fa-minus
            OptiButton(type="success" v-show="sign.with_correction === 'minus'"
                @click.native="sign.with_correction = 'plus'") {{'optometrist.cylinder.PLUS'|translate}}
                template(#icon)
                    i.fa.fa-plus
        .table-responsive(style="margin-top:10px")
            table.table.table-striped.text-center.middle
                tbody
                    tr(v-show="previouslens.dist")
                        th {{'optometrist.acuity.FAR'|translate}}
                        th.text-center.warn.bold(rowspan="6" colspan="2"
                            v-show="previouslens.dist_cant_read_powers") {{'previouslens.READPOWER'|translate}}
                        th SPH
                        th(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") CYL
                        th(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") AXS
                        th(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add") Add
                        th(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") Prism
                        th(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") Angle
                        th Visus
                        th C/Z
                        th BinVisus
                    tr(v-show="previouslens.dist")
                        th {{'prescription.RIGHT'|translate}}
                        td {{correction[sign.with_correction].dist.r.sph|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") {{correction[sign.with_correction].dist.r.cyl|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl")
                            span(v-show="correction[sign.with_correction].dist.r.cyl") {{correction[sign.with_correction].dist.r.axs}}
                        td(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add") {{withcorrection.dist.r.add|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") {{withcorrection.dist.r.prd|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd")
                            span(v-show="withcorrection.dist.r.prd") {{withcorrection.dist.r.base}}
                        td {{withcorrection.dist.r.visus}}
                        td
                            OptiEnum(enum="InterviewRedGreen" :id="withcorrection.dist.r.cz")
                        td(rowspan="2") {{withcorrection.dist.binvisus}}
                    tr(v-show="previouslens.dist")
                        th {{'prescription.LEFT'|translate}}
                        td {{correction[sign.with_correction].dist.l.sph|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") {{correction[sign.with_correction].dist.l.cyl|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl")
                            span(v-show="correction[sign.with_correction].dist.l.cyl") {{correction[sign.with_correction].dist.l.axs}}
                        td(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add") {{withcorrection.dist.l.add|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") {{withcorrection.dist.l.prd|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd")
                            span(v-show="withcorrection.dist.l.prd") {{withcorrection.dist.l.base}}
                        td {{withcorrection.dist.l.visus}}
                        td
                            OptiEnum(enum="InterviewRedGreen" :id="withcorrection.dist.l.cz")
                    tr(v-show="previouslens.dist || previouslens.near")
                        th {{'optometrist.acuity.NEAR'|translate}}
                        th SPH
                        th(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") CYL
                        th(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") AXS
                        th(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add")
                        th(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") Prism
                        th(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") Angle
                        th Visus
                        th
                        th BinVisus
                    tr(v-show="previouslens.dist || previouslens.near")
                        th {{'prescription.RIGHT'|translate}}
                        td {{correction[sign.with_correction].near.r.sph|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") {{correction[sign.with_correction].near.r.cyl|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl")
                            span(v-show="correction[sign.with_correction].near.r.cyl") {{correction[sign.with_correction].near.r.axs}}
                        td(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add")
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") {{withcorrection.near.r.prd|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd")
                            span(v-show="withcorrection.near.r.prd") {{withcorrection.near.r.base}}
                        td {{withcorrection.near.r.visus}}
                        td
                        td(rowspan="2") {{withcorrection.near.binvisus}}
                    tr(v-show="previouslens.dist || previouslens.near")
                        th {{'prescription.LEFT'|translate}}
                        td {{correction[sign.with_correction].near.l.sph|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl") {{correction[sign.with_correction].near.l.cyl|plus}}
                        td(v-show="correction[sign.with_correction].dist.r.cyl || correction[sign.with_correction].dist.l.cyl || correction[sign.with_correction].near.r.cyl || correction[sign.with_correction].near.l.cyl")
                            span(v-show="correction[sign.with_correction].near.l.cyl") {{correction[sign.with_correction].near.l.axs}}
                        td(v-show="withcorrection.dist.r.add || withcorrection.dist.l.add || withcorrection.near.r.add || withcorrection.near.l.add")
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd") {{withcorrection.near.l.prd|number(2)}}
                        td(v-show="withcorrection.dist.r.prd || withcorrection.dist.l.prd || withcorrection.near.r.prd || withcorrection.near.l.prd")
                            span(v-show="withcorrection.near.l.prd") {{withcorrection.near.l.base}}
                        td {{withcorrection.near.l.visus}}
                        td

        div(v-show="correction.contactlens[sign.contact_lens].r.sph || correction.contactlens[sign.contact_lens].l.sph")
            div(style="margin-top:10px")
                .bold.pull-left(style="padding-top:10px") {{'optometrist.acuity.CONTACTLENS'|translate}}
                .pull-right(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl")
                    OptiButton(type="success" v-show="sign.contact_lens === 'plus'"
                        @click.native="sign.contact_lens = 'minus'") {{'optometrist.cylinder.MINUS'|translate}}
                        template(#icon)
                            i.fa.fa-minus
                    OptiButton(type="success" v-show="sign.contact_lens === 'minus'"
                        @click.native="sign.contact_lens = 'plus'") {{'optometrist.cylinder.PLUS'|translate}}
                        template(#icon)
                            i.fa.fa-plus
            .clearfix
            .table-responsive(style="margin-top:10px")
                table.table.table-striped.text-center.middle
                    tbody
                        tr
                            th
                            th SPH
                            th(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl") CYL
                            th(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl") AXS
                            th(v-show="withcorrection.contactlens.r.add || withcorrection.contactlens.l.add") Add
                            th Visus
                            th C/Z
                            th BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction.contactlens[sign.contact_lens].r.sph|plus}}
                            td(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl") {{correction.contactlens[sign.contact_lens].r.cyl|plus}}
                            td(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl")
                                span(v-show="correction.contactlens[sign.contact_lens].r.cyl") {{correction.contactlens[sign.contact_lens].r.axs}}
                            td(v-show="withcorrection.contactlens.r.add || withcorrection.contactlens.l.add")
                                span(v-show="withcorrection.contactlens.r.add") {{withcorrection.contactlens.r.add|number(2)}}
                            td {{withcorrection.contactlens.r.visus}}
                            td
                                OptiEnum(enum="InterviewRedGreen" :id="withcorrection.contactlens.r.cz")
                            td(rowspan="2") {{withcorrection.contactlens.binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{correction.contactlens[sign.contact_lens].l.sph|plus}}
                            td(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl") {{correction.contactlens[sign.contact_lens].l.cyl|plus}}
                            td(v-show="correction.contactlens[sign.contact_lens].r.cyl || correction.contactlens[sign.contact_lens].l.cyl")
                                span(v-show="correction.contactlens[sign.contact_lens].l.cyl") {{correction.contactlens[sign.contact_lens].l.axs}}
                            td(v-show="withcorrection.contactlens.r.add || withcorrection.contactlens.l.add")
                                span(v-show="withcorrection.contactlens.l.add") {{withcorrection.contactlens.l.add|number(2)}}
                            td {{withcorrection.contactlens.l.visus}}
                            td
                                OptiEnum(enum="InterviewRedGreen" :id="withcorrection.contactlens.l.cz")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'OptiOptometristsDetailsRefractionFarDialogCorrectionWith',
        components: {
            OptiButton,
            OptiEnum
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                sign: { // jak będzie Vuex to można stąd brać
                    with_correction: 'minus',
                    contact_lens: 'minus'
                },

                withcorrection: {
                    dist: {
                        r: {
                            prd: this.interview.dist_c_r_prism,
                            base: this.interview.dist_c_r_base,
                            add: this.interview.dist_c_r_add,
                            visus: this.interview.dist_c_r_visus,
                            cz: this.interview.dist_c_r_cz
                        },
                        l: {
                            prd: this.interview.dist_c_l_prism,
                            base: this.interview.dist_c_l_base,
                            add: this.interview.dist_c_l_add,
                            visus: this.interview.dist_c_l_visus,
                            cz: this.interview.dist_c_l_cz
                        },
                        binvisus: this.interview.dist_c_binvisus
                    },
                    near: {
                        r: {
                            prd: this.interview.near_c_r_prism,
                            base: this.interview.near_c_r_base,
                            visus: this.interview.near_c_r_visus,
                            cz: this.interview.near_c_r_cz
                        },
                        l: {
                            prd: this.interview.near_c_l_prism,
                            base: this.interview.near_c_l_base,
                            visus: this.interview.near_c_l_visus,
                            cz: this.interview.near_c_l_cz
                        },
                        binvisus: this.interview.near_c_binvisus
                    },
                    contactlens: {
                        r: {
                            add: this.interview.contactLensCorrection.r_add,
                            visus: this.interview.contactLensCorrection.dist_r_visus,
                            cz: this.interview.contactLensCorrection.dist_r_cz
                        },
                        l: {
                            add: this.interview.contactLensCorrection.l_add,
                            visus: this.interview.contactLensCorrection.dist_l_visus,
                            cz: this.interview.contactLensCorrection.dist_l_cz
                        },
                        binvisus: this.interview.contactLensCorrection.dist_binvisus
                    }
                }
            }
        }
    }
</script>