<template lang="pug">
    .opti-select-country(v-if="countries.length")
        VueMultiSelect(
            :value="country"
            label="pl"
            track-by="id"
            :options="countries"
            :placeholder="'search.country.PLACEHOLDER'|translate"
            :showLabels="false"
            :loading="loading"

            @input="c => $emit('selected', c)")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.pl}}
</template>

<script>
    import {Storage} from '@/js/app/vue/helpers/Storage'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: "OptiSelectCountry",
        components: {
            VueMultiSelect
        },
        props: {
            country: {
                type: Object
            }
        },
        data() {
            return {
                loading: false,
                countries: []
            }
        },
        mounted() {
            Storage.get('countryCode').then(countries => {
                this.countries = countries
            })
        }

    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single,
    .multiselect /deep/ .multiselect__tags
    {
        font-size: 14px;
    }
    .multiselect /deep/ .multiselect__select {
        border-top-right-radius: 0;
    }
</style>