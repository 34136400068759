<template lang="pug">
    .input-group
        input.form-control(:id="id" v-model="task_id" name="id" :disabled="disabled"
            :required="required" placeholder="id lub kod kreskowy pracy"
            @keyup.enter="download" @blur="download")

        span.input-group-addon(@click="download" :disabled="disabled")
            span(v-if="loading === 'ok'") {{result.patient.firstName}} {{result.patient.lastName}}
            span.fa.fa-remove.warn(v-if="loading === 'error'")
            span.fa.fa-spin.fa-cog(v-if="loading === 'fetching'")
            span.fa.fa-search(v-if="!loading")
</template>

<script>
    import {API} from '@/js/app/vue/api';

    export default {
        name: 'OptiSelectTask',
        props: {
            id: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            initResult: {
                type: Object,
                required: false,
                default: {}
            }
        },
        watch: {
            disabled(disabled) {
                if(!disabled) {
                    this.$emit('selected', this.result)
                }
            }
        },
        data() {
            return {
                task_id: null,
                loading: false,
                result: {}
            }
        },
        mounted() {
            this.result = this.initResult

            if(Object.keys(this.result).length) {
                this.task_id = this.result.id
                this.loading = 'ok'
            }
        },
        methods: {
            download() {
                if(!this.disabled) {
                    if (this.loading === 'fetching') return;

                    if(parseInt(this.task_id)) {
                        this.loading = 'fetching'
                        this.result = {}
                        this.$emit('selected', this.result)

                        API.post('search/task', {
                            id: this.task_id
                        }).then(res => {
                            this.result = res.data
                            this.$emit('selected', this.result)

                            this.loading = Object.keys(this.result).length ? 'ok' : 'error'
                        })
                    } else {
                        this.loading = false
                        this.result = {}
                        this.$emit('selected', this.result)
                    }
                }
            }
        }
    }
</script>
