<template lang="pug">
    div
        div
            img(style="display:block;width:100%" :class="{'screening-opacity': !data}"
                :src="'img/optometrist/screening-test/maddox/' + side + '_' + type + '.jpg'")
            img(style="position:absolute;top:0;right:15px;width:40px"
                :src="'img/optometrist/screening-test/maddox/legend/' + legend + '.png'")

        .text-center(style="background:#000;color:#fff" :class="{'screening-opacity': !data}")
            div(v-show="type !== 'v' && type !== 'h' && data")
                span {{'prescription.PRISM'|translate}} {{data|number(2)}}
                small {{'prescription.PRISMANGLE'|translate}} {{angle|number(0)}}
            .text-expressive(v-show="(type === 'v' || type === 'h') || !data") &nbsp;

        .text-center(v-show="type !== 'v' && type !== 'h'")
            button.btn.btn-lg.btn-success.pull-left(type="button" style="width:40%"
                :disabled="data >= 20 || block" @click="$emit('update-maddox', data + 1)")
                i.fa.fa-plus
            button.btn.btn-lg.btn-danger.pull-right(type="button" style="width:40%"
                :disabled="data <= 0 || block" @click="$emit('update-maddox', data - 1)")
                i.fa.fa-minus

        .text-center(v-show="type === 'v' || type === 'h'")
            button.btn.btn-lg.btn-success(type="button" v-show="!data" style="width:40%"
                :disabled="block" @click="$emit('update-maddox', true)")
                i.fa.fa-plus
            button.btn.btn-lg.btn-danger(type="button" v-show="data" style="width:40%"
                :disabled="block" @click="$emit('update-maddox', false)")
                i.fa.fa-minus
</template>

<script>
    export default {
        name: 'OptiOptometristsDetailsRefractionSurveysDialogScreeningTestMaddoxField',
        components: {

        },
        props: {
            type: {
                type: String,
                required: true
            },

            maddox: {
                type: Object,
                required: true
            },
            data: {
                type: Number | Boolean,
                required: true
            },

            validate: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                vars: ['r', 'v', 'l', 't', 'h', 'b'],
                side: this.validate[0][0],
                angles: {
                    'r': 0,
                    'v': 0,
                    'l': 180,
                    't': 270,
                    'h': 0,
                    'b': 90
                },
                angle: null
            }
        },
        mounted() {
            this.angle = this.angles[this.type]
        },
        methods: {

        },
        computed: {
            selected() {
                return this.maddox[this.validate[0]] || this.maddox[this.validate[1]]
            },
            legend() {
                let vertical = ['r', 'v', 'l'].includes(this.type)

                if(this.side === 'r' && vertical) {
                    return 'r_vertical'
                } else if(this.side === 'r' && !vertical) {
                    return 'r_horizontal'
                } else if(this.side === 'l' && vertical) {
                    return 'l_vertical'
                }

                return 'l_horizontal'
            },
            allEmpty() {
                let sum = 0,
                    parsed = 0

                _.forEach(['r', 'l'], s => {
                    this.vars.forEach(v => {
                        if(v === 'v' || v === 'h') {
                            parsed = this.maddox[s + '_' + v] ? 1 : 0
                        } else {
                            parsed = parseInt(this.maddox[s + '_' + v])
                        }

                        sum += parsed
                    })
                })

                return sum <= 0
            },
            disabledSide() {
                let sum = 0,
                    parsed = 0

                this.vars.forEach(v => {
                    if(v === 'v' || v === 'h') {
                        parsed = this.maddox[this.side + '_' + v] ? 1 : 0
                    } else {
                        parsed = parseInt(this.maddox[this.side + '_' + v])
                    }

                    sum += parsed
                })

                return sum <= 0
            },
            block() {
                return this.selected || (!this.allEmpty && this.disabledSide)
            }
        }
    }
</script>