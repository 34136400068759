import {API} from '@/js/app/vue/api'
import {helpers} from 'vuelidate/lib/validators'

function noCashRegister() {
    return helpers.withParams({ }, function() {
        return this.cash_registers.length
    })
}

function bill() {
    let timeout = null,
        old_model = {
            cash_register_id: 0,
            receipt: '',
            _return: true
        }

    return helpers.withParams({ }, async function(model) {
        if(timeout) {
            clearTimeout(timeout)
        }

        let diff = old_model.cash_register_id !== model.cash_register_id || old_model.receipt !== model.receipt

        old_model.cash_register_id = model.cash_register_id
        old_model.receipt = model.receipt

        if(this.disabled || !model.cash_register_id || !model.receipt || (!this.taskId && !this.cash_registers.length)) {
            old_model._return = true

            this.validate = {
                type: ''
            }

            return true
        } else if(!diff) {
            return old_model._return
        }

        return new Promise((resolve, reject) => {
            this.validate = {
                type: ''
            }

            timeout = setTimeout(() => {
                let params = _.clone(model)
                params.department_id = this.department_id
                if(this.taskId) {
                    params.task_id = this.taskId
                }

                API.post('validate/receipt', params).then(res => {
                    if(this.model.cash_register_id && this.model.receipt && Object.keys(res.data).length) {
                        this.validate = res.data

                        old_model._return = false

                        resolve(false)
                    } else {
                        old_model._return = true

                        resolve(true)
                    }
                }).catch(() => {
                    reject('Wystąpił błąd przy sprawdzaniu paragonu!')
                })
            }, this.taskId ? 0 : 350 + Math.random() * 300)
        })
    })
}

export {noCashRegister, bill}