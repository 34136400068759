<template lang="pug">
    #historyOptometrist
        .row(v-for="survey in history")
            .col-xs-12
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span.mr3 {{survey.data[0].type_name|translate}}
                        span.warn {{survey.data[0].created_at}}
                        .pull-right(v-show="edit" :class="{'warn': survey.time_scope === 0 || survey.time_scope === 2}")
                            span.mr3 {{survey.time_diff|secondsToHuman}}
                            i.fa.fa-sort-desc(v-show="survey.time_scope === 0")
                            i.fa.fa-sort-asc(v-show="survey.time_scope === 2")
                            i.fa.fa-sort(style="visibility:hidden"
                                v-show="survey.time_scope !== 0 && survey.time_scope !== 2")
                    component(:is="OptiSurveyHistoryComponent(survey.data[0].type_key)" :survey="survey")

        OptiDimmer(:active="loading")

        .row(v-show="!Object.keys(history).length")
            .col-xs-12.text-center.text-muted
                i.fa.fa-exclamation-circle.mr3
                span(v-show="status !== 403") {{'optometrist.history.notify.EMPTY'|translate}}
                span(v-show="status === 403") {{'optometrist.history.notify.PERMISSION'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiOptometristsDetailsSurveyHistoryAcuityContactLens
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryAcuityContactLens'
    import OptiOptometristsDetailsSurveyHistoryAcuityNoCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryAcuityNoCorrection'
    import OptiOptometristsDetailsSurveyHistoryAcuityWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryAcuityWithCorrection'
    import OptiOptometristsDetailsSurveyHistoryAcuityWithCorrectionContactLens
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryAcuityWithCorrectionContactLens'
    import OptiOptometristsDetailsSurveyHistoryBinOcular
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryBinOcular'
    import OptiOptometristsDetailsSurveyHistoryCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryCorrection'
    import OptiOptometristsDetailsSurveyHistoryMaddox
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryMaddox'
    import OptiOptometristsDetailsSurveyHistoryStereo
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryStereo'
    import OptiOptometristsDetailsSurveyHistoryContactLensCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryContactLensCorrection'
    import OptiOptometristsDetailsSurveyHistoryVisusNearContactLens
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryVisusNearContactLens'
    import OptiOptometristsDetailsSurveyHistoryVisusNearContactLensCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryVisusNearContactLensCorrection'
    import OptiOptometristsDetailsSurveyHistoryVisusNearCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryVisusNearCorrection'
    import OptiOptometristsDetailsSurveyHistoryVisusNearNoCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryVisusNearNoCorrection'
    import OptiOptometristsDetailsSurveyHistoryVisusNearWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryVisusNearWithCorrection'
    import OptiOptometristsDetailsSurveyHistoryWorth
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/OptiOptometristsDetailsSurveyHistoryWorth'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiOptometristsDetailsSurveyHistory',
        components: {
            OptiOptometristsDetailsSurveyHistoryAcuityContactLens,
            OptiOptometristsDetailsSurveyHistoryAcuityNoCorrection,
            OptiOptometristsDetailsSurveyHistoryAcuityWithCorrection,
            OptiOptometristsDetailsSurveyHistoryAcuityWithCorrectionContactLens,
            OptiOptometristsDetailsSurveyHistoryBinOcular,
            OptiOptometristsDetailsSurveyHistoryCorrection,
            OptiOptometristsDetailsSurveyHistoryMaddox,
            OptiOptometristsDetailsSurveyHistoryStereo,
            OptiOptometristsDetailsSurveyHistoryContactLensCorrection,
            OptiOptometristsDetailsSurveyHistoryVisusNearContactLens,
            OptiOptometristsDetailsSurveyHistoryVisusNearContactLensCorrection,
            OptiOptometristsDetailsSurveyHistoryVisusNearCorrection,
            OptiOptometristsDetailsSurveyHistoryVisusNearNoCorrection,
            OptiOptometristsDetailsSurveyHistoryVisusNearWithCorrection,
            OptiOptometristsDetailsSurveyHistoryWorth,

            OptiDimmer
        },
        props: {
            isActive: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                history: [],

                edit: this.$uac.permission('optometrist.survey_history_time'),

                interval: null,

                status: 200,
                loading: false
            }
        },
        watch: {
            isActive(active) {
                if(active) {
                    this.loop()
                }
            }
        },
        mounted() {
            this.loop()
        },
        beforeDestroy() {
            clearInterval(this.interval)
        },
        methods: {
            download() {
                if(!this.isActive) {
                    clearInterval(this.interval)

                    return
                }

                this.loading = true

                API.get('optometrists/survey/log/' + this.$state.params.id).then(res => {
                    this.history = res.data

                    this.status = 200
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.status === 403) {
                        this.status = 403
                    } else {
                        this.$notify.error('optometrist.history.notify.error.LOAD')
                    }
                })
            },
            loop() {
                clearInterval(this.interval)
                this.download()

                this.interval = setInterval(() => {
                    this.download()
                }, 10000)
            },

            OptiSurveyHistoryComponent(type_key) {
                return 'OptiOptometristsDetailsSurveyHistory' + type_key
            }
        }
    }
</script>