<template lang="pug">
    .row
        .col-xs-12(v-if="!loading.main")
            form.form-horizontal(name="formSaleReclamation" @submit.prevent="editSaleReclamation")
                .col-md-offset-2.col-md-8
                    .form-group
                        label.col-sm-4.control-label {{'sale.reclamation.details.PRODUCT'|translate}}
                        .col-sm-8
                            .form-control-static(v-if="reclamation.productRelation.id")
                                OptiProduct(:product="reclamation.productRelation") {{reclamation.productRelation.name}}
                            .form-control-static(v-if="!reclamation.productRelation.id") {{reclamation.product_name}}
                    .form-group(v-if="reclamation.sale.patient.id")
                        label.col-sm-4.control-label {{'sale.offstock.table.head.PATIENT'|translate}}
                        .col-sm-8
                            .form-control-static
                                OptiPatient(:data="reclamation.sale.patient")
                    .form-group(v-if="!reclamation.sale.patient.id")
                        label.col-sm-4.control-label.star-required(for="client") {{'register.return.sale.PATIENT'|translate}}
                        .col-sm-8
                            input#client.form-control(:placeholder="'register.return.sale.PATIENT'|translate"
                                name="client" v-model="reclamation.client" required)
                            OptiValidate(f="client" :data="$v.reclamation.client")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="street") {{'register.return.sale.PATIENTSTREET'|translate}}
                        .col-sm-8
                            input#street.form-control(:placeholder="'register.return.sale.PATIENTSTREET'|translate"
                                name="street" v-model="reclamation.street" :disabled="reclamation.sale.patient.id" required)
                            OptiValidate(f="street" :data="$v.reclamation.street")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="zip_code") {{'register.return.sale.ZIPCODE'|translate}}
                        .col-sm-8
                            input#zip_code.form-control(:placeholder="'register.return.sale.ZIPCODE'|translate"
                                name="zip_code" v-model="reclamation.zip_code" :disabled="reclamation.sale.patient.id" required)
                            OptiValidate(f="zip_code" :data="$v.reclamation.zip_code")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="city") {{'register.return.sale.CITY'|translate}}
                        .col-sm-8
                            input#city.form-control(:placeholder="'register.return.sale.CITY'|translate"
                                name="city" v-model="reclamation.city" :disabled="reclamation.sale.patient.id" required)
                            OptiValidate(f="city" :data="$v.reclamation.city")
                    .form-group
                        label.col-sm-4.control-label(for="cellphonenumber") {{'register.return.sale.CELLPHONENUMBER'|translate}}
                        .col-sm-8
                            input#cellphonenumber.form-control(:placeholder="'register.return.sale.CELLPHONENUMBER'|translate"
                                name="cellphonenumber" v-model="reclamation.cellphonenumber" :disabled="reclamation.sale.patient.id")
                    .form-group
                        label.col-sm-4.control-label(for="proof_type") {{'sale.reclamation.PROOF'|translate}}
                        .col-sm-8
                            OptiSelectEnum#proof_type(enum="ReclamationProof"
                                :value="reclamation.proof_type" @update-enum="p => reclamation.proof_type = p")
                    .form-group
                        label.col-sm-4.control-label(for="proof_id") {{'sale.reclamation.PROOFID'|translate}}
                        .col-sm-8
                            input#proof_id.form-control(v-model="reclamation.proof_id" :disabled="!reclamation.proof_type")
                    .form-group
                        label.col-sm-4.control-label(for="proof_photo") {{'sale.reclamation.PROOFPHOTO'|translate}}
                        .col-sm-8
                            OptiUpload#proof_photo(
                                prefix="sale_reclamation_proof"
                                :picture="true"

                                :file="reclamation.proof_photo"
                                :disabled="!reclamation.proof_type || !reclamation.proof_id"

                                @update-loading="l => loading.picture = l"
                                @update-file="f => reclamation.proof_photo = f")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="date_of_notice") {{'sale.reclamation.DATEOFNOTICE'|translate}}
                        .col-sm-8
                            OptiDate#date_of_notice(:value="reclamation.date_of_notice" @update-date="d => reclamation.date_of_notice = d")
                            OptiValidate(f="date_of_notice" :data="$v.reclamation.date_of_notice")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="description") {{'sale.reclamation.COMMENTS'|translate}}
                        .col-sm-8
                            textarea#description.form-control(name="description" v-model="reclamation.description" required)
                            OptiValidate(f="description" :data="$v.reclamation.description")
                    .form-group
                        label.col-sm-4.control-label(for="department_description") {{'sale.reclamation.DEPARTMENTDESCRIPTION'|translate}}
                        .col-sm-8
                            textarea#department_description.form-control(v-model="reclamation.department_description")

                OptiReclamationServices(:edit="true" type="sale" :product-type="reclamation.productRelation.type"
                    :services="services" @update-services-invalid="b => services_invalid = b")
                .clearfix

                .col-md-offset-2.col-md-8
                    .form-group
                        .col-sm-offset-4.col-sm-8
                            OptiButtonSave(:disabled="$v.reclamation.$invalid || services_invalid")

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumReclamationServiceType} from '@/js/app/vue/enums/Enums'
    import {required} from 'vuelidate/lib/validators'
    import Vue from 'vue'

    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiReclamationServices from '@/js/app/vue/components/Reclamations/OptiReclamationServices'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationSaleEdit',
        components: {
            OptiDate,
            OptiProduct,
            OptiPatient,
            OptiSelectEnum,
            OptiUpload,
            OptiReclamationServices,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                reclamation: {},
                services: {},
                services_invalid: true,

                loading: {
                    main: true,
                    picture: false
                }
            }
        },
        validations: {
            reclamation: {
                client: {
                    required
                },
                street: {
                    required
                },
                zip_code: {
                    required
                },
                city: {
                    required
                },
                date_of_notice: {
                    required
                },
                description: {
                    required
                }
            }
        },
        mounted() {
            API.get('sale/reclamation/' + this.$state.params.id).then(res => {
                this.reclamation = res.data

                if(this.reclamation.proof_photo) {
                    this.reclamation.proof_photo = 'sale/reclamation/proof/' + this.reclamation.proof_photo
                }

                if(Object.keys(this.reclamation.sale).includes('id') && Object.keys(this.reclamation.sale.patient).includes('id')) {
                    this.reclamation.client = this.reclamation.sale.patient.firstName + ' ' + this.reclamation.sale.patient.lastName
                    this.reclamation.street = this.reclamation.sale.patient.street
                    this.reclamation.zip_code = this.reclamation.sale.patient.zipCode
                    this.reclamation.city = this.reclamation.sale.patient.city
                    this.reclamation.cellphonenumber = this.reclamation.sale.patient.cellPhoneNumber
                }

                this.parse()

                this.$emit('update-breadcrumb', this.reclamation.status_name)
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('sale.reclamation.notify.error.LOAD')
            })
        },
        methods: {
            parse() {
                this.reclamation.services.forEach(service => {
                    if(!Object.keys(this.services).includes(service.type.toString())) {
                        Vue.set(this.services, service.type, [])
                    }

                    this.services[service.type].push({
                        service: {
                            id: service.id,
                            name: service.name
                        },
                        type: service.type,
                        description: service.pivot.description,
                        picture: service.pivot.picture ? 'sale/reclamation/' + service.pivot.picture : null,
                        loading: false
                    })
                })

                EnumReclamationServiceType.simpleItems().forEach(reclamationServiceType => {
                    if(reclamationServiceType.id && ((!this.reclamation.productRelation.type || this.reclamation.productRelation.type === 1) ||
                        (this.reclamation.productRelation.type === 2 && (reclamationServiceType.id === 4 || reclamationServiceType.id === 5))) &&
                        !Object.keys(this.services).includes(reclamationServiceType.id.toString())) {
                        Vue.set(this.services, reclamationServiceType.id, [{
                            service: null,
                            type: reclamationServiceType.id,
                            description: null,
                            picture: null,
                            loading: false
                        }])
                    }
                })
            },
            editSaleReclamation() {
                this.loading.main = true
                this.reclamation.services = this.services

                API.post('sale/reclamation/edit', this.reclamation).then(res => {
                    if(res.data.status === 'OK') {
                        this.$state.go('app.sale.reclamation.details', {id: this.reclamation.id})

                        this.$notify.success('sale.reclamation.notify.EDIT')
                    } else {
                        this.$notify.error('sale.reclamation.notify.error.EDIT')
                    }
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.EDIT')
                })
            }
        }
    }
</script>