<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.mr3(:class="'fa-' + icon")
            span {{header|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .row
                .col-xs-12.text-center(v-if="img")
                    img(style="width:100%" :src="img" :alt="header|translate")

                .col-xs-12(v-show="description")
                    p(v-html="$filters.translate(description)")

        template(#footer)
            .text-center
                OptiButton(type="success" @click.native="open") {{'default.GO'|translate}}
                    template(#icon)
                        i.fa.fa-arrow-right
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiDialogHelp',
        components: {
            OptiDialog,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            icon: {
                type: String,
                required: true
            },
            header: {
                type: String,
                required: true
            },
            img: {
                type: String,
                required: false,
                default: ''
            },
            description: {
                type: String,
                required: false,
                default: ''
            },
            link: {
                type: String,
                required: false,
                default: ''
            }
        },
        methods: {
            open() {
                window.open(this.link, '_blank')

                this.$emit('close')
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;
    }

    p {
        margin: 10px 3px 0;
    }
</style>