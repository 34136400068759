<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="loading")
        .col-md-offset-1.col-md-10(v-if="!loading")
            form.form-horizontal.form-validate(name="formUsersExchange" @submit.prevent="editUsersExchange")
                fieldset
                    .form-group
                        label.col-sm-4.control-label.star-required {{'user.exchange.add.week.HEADER'|translate}}
                        .col-sm-8
                            OptiSelectWeek(:date="week" @selected="updateWeek")

                fieldset(v-if="week")
                    .form-group(style="margin-bottom:10px")
                        label.col-sm-4.control-label.star-required {{'user.exchange.add.USER1'|translate}}
                        .col-sm-8
                            OptiSelectEmployeeGroupByDepartment(
                                :user="employee_1" mode="exchanges"
                                :disabled-group-department="employee_2 && employee_2.group_id"
                                @selected="updateEmployee1")
                    .form-group(style="margin-bottom:10px")
                        label.col-sm-4.control-label.star-required(for="comments_1")
                            div {{'user.exchange.add.comments.HEADING'|translate}}
                            small {{'user.exchange.add.comments.FOR1'|translate}}
                        .col-sm-8
                            textarea#comments_1.form-control(v-model="comments_1" name="comments1" rows="6" required)
                    .form-group.schedule(v-if="employee_2 && employee_2.departments && employee_2.departments.length")
                        table.table.table-striped.middle.text-center.table-schedule.table-schedule-week
                            thead
                                tr
                                    th(style="width:20%") {{'user.exchange.add.DEPARTMENT'|translate}}
                                    th.day-cell(nowrap v-for="day in week.days")
                                        span {{day.label.long}}
                                        .warn {{day.holiday|translate}}

                            tbody(v-show="!exchange.status" v-for="department in employee_1.departments")
                                tr(v-if="department.preview && department.preview.length")
                                    th(nowrap)
                                        div {{department.name}}
                                        small ({{'user.exchange.add.desc.ASSIGNED'|translate}})
                                    td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                        @click="editCell(department, department.preview[key], schedule.working_hours, week.days[key].label.day, true)")
                                        div(v-if="isCurrentCell(department, department.preview[key], true)")
                                            .current-cell-backdrop
                                            .current-cell-content
                                                OptiScheduleEditor(
                                                    :events="department.preview[key]"
                                                    :workingHours="schedule.working_hours")
                                        OptiScheduleEditor(v-else
                                        :events="department.preview[key]"
                                            :workingHours="schedule.working_hours")

                                tr(v-if="current.data === department && preview === true")
                                    td.editor-cell(colspan="8")
                                        .editor
                                            .panel.panel-default
                                                .panel-body
                                                    OptiScheduleDayPainter(
                                                        mode="exchange"
                                                        :readonly="true"
                                                        :user="current.data"
                                                        :events="current.cell"
                                                        :workingHours="current.working_hours"
                                                        :departmentId="department.id"
                                                        :date="current.date"
                                                        @cancelled="cancelEdit"
                                                        @saved="saveEdit")

                            tbody(v-for="(department, key) in employee_2.departments")
                                tr
                                    th(nowrap :class="'schedule-td-color-' + key")
                                        div {{department.name}}
                                        small ({{'user.exchange.add.desc.MOVE'|translate}})
                                    td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                        @click="editCell(department, schedule.events, schedule.working_hours, week.days[key].label.day, false)")
                                        div(v-if="isCurrentCell(department, schedule.events, false)")
                                            .current-cell-backdrop
                                            .current-cell-content
                                                OptiScheduleEditor(
                                                    :events="schedule.events"
                                                    :workingHours="schedule.working_hours")
                                        OptiScheduleEditor(v-else
                                            :events="schedule.events"
                                            :workingHours="schedule.working_hours")

                                tr(v-if="current.data === department && preview === false")
                                    td.editor-cell(colspan="8")
                                        .editor
                                            .panel.panel-default
                                                .panel-body
                                                    OptiScheduleDayPainter(
                                                        mode="exchange"
                                                        :user="current.data"
                                                        :events="current.cell"
                                                        :workingHours="current.working_hours"
                                                        :departmentId="department.id"
                                                        :date="current.date"
                                                        @cancelled="cancelEdit"
                                                        @saved="saveEdit")

                .form-group(style="margin-bottom:10px")
                    label.col-sm-4.control-label.star-required {{'user.exchange.add.USER2'|translate}}
                    .col-sm-8
                        OptiSelectEmployeeGroupByDepartment(
                            :user="employee_2" mode="exchanges"
                            :disabled-group-department="employee_1 && employee_1.group_id"
                            @selected="updateEmployee2")
                .form-group(style="margin-bottom:10px")
                    label.col-sm-4.control-label.star-required(for="comments_2")
                        div {{'user.exchange.add.comments.HEADING'|translate}}
                        small {{'user.exchange.add.comments.FOR2'|translate}}
                    .col-sm-8
                        textarea#comments_2.form-control(v-model="comments_2" name="comments2" rows="6" required)
                .form-group.schedule(v-if="employee_1 && employee_1.departments && employee_1.departments.length")
                    table.table.table-striped.middle.text-center.table-schedule.table-schedule-week
                        thead
                            tr
                                th(style="width:20%") {{'user.exchange.add.DEPARTMENT'|translate}}
                                th.day-cell(NOWRAP v-for="day in week.days")
                                    span {{day.label.long}}
                                    .warn {{day.holiday|translate}}

                        tbody(v-show="!exchange.status" v-for="department in employee_2.departments")
                            tr(v-if="department.preview && department.preview.length")
                                th(nowrap)
                                    div {{department.name}}
                                    small ({{'user.exchange.add.desc.ASSIGNED'|translate}})
                                td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                    @click="editCell(department, department.preview[key], schedule.working_hours, week.days[key].label.day, true)")
                                    div(v-if="isCurrentCell(department, department.preview[key], true)")
                                        .current-cell-backdrop
                                        .current-cell-content
                                            OptiScheduleEditor(
                                                :events="department.preview[key]"
                                                :workingHours="schedule.working_hours")
                                    OptiScheduleEditor(v-else
                                        :events="department.preview[key]"
                                        :workingHours="schedule.working_hours")
                            tr(v-if="current.data === department && preview === true")
                                td.editor-cell(colspan="8")
                                    .editor
                                        .panel.panel-default
                                            .panel-body
                                                OptiScheduleDayPainter(
                                                    mode="exchange"
                                                    :readonly="true"
                                                    :user="current.data"
                                                    :events="current.cell"
                                                    :workingHours="current.working_hours"
                                                    :departmentId="department.id"
                                                    :date="current.date"
                                                    @cancelled="cancelEdit"
                                                    @saved="saveEdit")

                        tbody(v-for="(department, key) in employee_1.departments")
                            tr
                                th(nowrap :class="'schedule-td-color-' + key")
                                    div {{department.name}}
                                    small ({{'user.exchange.add.desc.MOVE'|translate}})
                                td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                    @click="editCell(department, schedule.events, schedule.working_hours, week.days[key].label.day, false)")
                                    div(v-if="isCurrentCell(department, schedule.events, false)")
                                        .current-cell-backdrop
                                        .current-cell-content
                                            OptiScheduleEditor(
                                                :events="schedule.events"
                                                :workingHours="schedule.working_hours")
                                    OptiScheduleEditor(v-else
                                    :events="schedule.events"
                                        :workingHours="schedule.working_hours")

                            tr(v-if="current.data === department && preview === false")
                                td.editor-cell(colspan="8")
                                    .editor
                                        .panel.panel-default
                                            .panel-body
                                                OptiScheduleDayPainter(
                                                    mode="exchange"
                                                    :user="current.data"
                                                    :events="current.cell"
                                                    :workingHours="current.working_hours"
                                                    :departmentId="department.id"
                                                    :date="current.date"
                                                    @cancelled="cancelEdit"
                                                    @saved="saveEdit")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(v-if="!exchange.status" :disabled="!(employee_1 && comments_1 && employee_2 && comments_2) || loading")

                        .alert.alert-warning(v-if="exchange.status")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'user.exchange.notify.LOCKEDIT'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiSelectWeek from '@/js/app/vue/components/Select/OptiSelectWeek'
    import OptiSelectEmployeeGroupByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiScheduleEditor from '@/js/app/vue/components/Schedule/Week/OptiScheduleEditor'
    import OptiScheduleDayPainter from'@/js/app/vue/components/Schedule/Week/OptiScheduleDayPainter'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from "@/js/app/vue/components/Button/OptiButton"

    export default {
        name: 'OptiUsersExchangeEdit',
        components: {
            OptiButton,
            OptiSelectWeek, OptiSelectEmployeeGroupByDepartment, OptiScheduleEditor, OptiScheduleDayPainter, OptiButtonSave, OptiDimmer
        },
        data() {
            return {
                exchange: null,

                current: {
                    data: false,
                    cell: false,
                    working_hours: false,
                    date: false
                },

                week: {},
                employee_1: {},
                comments_1: '',
                employee_2: {},
                comments_2: '',

                loading: true,
                preview: false
            }
        },
        mounted() {
            API.get('exchange/' + this.$state.params.id).then(res => {
                this.exchange = res.data

                let date = moment(this.exchange.start_date)
                this.week = {
                    label: date.format('W/GGGG'),
                    start: date.format('YYYY-MM-DD'),
                    end: date.endOf('w').format('YYYY-MM-DD'),
                    days: OptiSelectWeek.methods.fillDays(date.startOf('w'))
                }

                this.employee_1 = this.exchange.employee_1
                this.comments_1 = this.exchange.comments_1

                this.employee_2 = this.exchange.employee_2
                this.comments_2 = this.exchange.comments_2

                this.getPreview(this.employee_1)
                this.getPreview(this.employee_2)

                this.loading = false
            }).catch(() => {
                this.$notify.error('user.exchange.notify.error.LOAD')

                this.loading = false
            })
        },
        methods: {
            updateSchedule(employee) {
                if(Object.keys(employee).length) {
                    employee.departments.forEach((department) => {
                        department.schedule.forEach((schedule, key) => {
                            let date = this.week.days[key].label.day

                            schedule.events.forEach((event) => {
                                event.start = date + ' ' + event.start.split(' ')[1]
                                event.end = date + ' ' + event.end.split(' ')[1]
                            })
                        })
                    })
                }
            },
            updateWeek(week) {
                this.week = week

                this.updateSchedule(this.employee_1)
                this.updateSchedule(this.employee_2)

                this.getPreview(this.employee_1)
                this.getPreview(this.employee_2)
            },
            setEmptySchedule(employee) {
                if(!employee) {
                    return
                }

                employee.departments.forEach((department) => {
                    department.schedule = []

                    for(let i = 0; i < 7; ++i) {
                        let working_hours = department.working_hours[i === 5 ? 'saturday' : (i === 6 ? 'sunday' : 'week')]

                        department.schedule[i] = {
                            events: [],
                            working_hours: {
                                start: {
                                    hour: working_hours.from.hour,
                                    minute: working_hours.from.minute
                                },
                                end: {
                                    hour: working_hours.to.hour,
                                    minute: working_hours.to.minute
                                }
                            }
                        }
                    }
                })
            },

            isCurrentCell(department, cell, preview) {
                return this.current.data === department && this.current.cell === cell && this.preview === preview
            },
            editCell(department, cell, working_hours, day, preview) {
                this.preview = preview

                if(this.isCurrentCell(department, cell, preview)) {
                    this.cancelEdit()
                    return
                }

                this.cancelEdit()

                Vue.nextTick(() => {
                    this.current.data = department
                    this.current.cell = cell
                    this.current.working_hours = working_hours
                    this.current.date = day
                })
            },
            cancelEdit() {
                this.current.data = false
                this.current.cell = false
                this.current.working_hours = false
                this.current.date = false
            },
            saveEdit(events) {
                this.current.cell.length = 0
                _.forEach(events, event => {
                    this.current.cell.push(event)
                })
            },

            updateEmployee1(employee1) {
                this.employee_1 = employee1

                this.setEmptySchedule(this.employee_1)
                this.getPreview(this.employee_1)
            },
            updateEmployee2(employee2) {
                this.employee_2 = employee2

                this.setEmptySchedule(this.employee_2)
                this.getPreview(this.employee_2)
            },

            editUsersExchange() {
                this.loading = true

                API.post('exchanges/edit', {
                    id: this.exchange.id,
                    date: this.week.start,

                    employee_id_1: this.employee_1.id,
                    group_id_1: this.employee_1.group_id,
                    comments_1: this.comments_1,
                    departments_1: this.employee_1.departments,

                    employee_id_2: this.employee_2.id,
                    group_id_2: this.employee_2.group_id,
                    comments_2: this.comments_2,
                    departments_2: this.employee_2.departments
                }).then(() => {
                    this.$state.go('app.users.exchanges.list')

                    this.$notify.success('user.exchange.notify.EDIT')
                }).catch(() => {
                    this.$notify.error('user.exchange.notify.error.EDIT')

                    this.loading = false
                })
            },

            getPreview(employee) {
                if(typeof employee.departments !== 'undefined') {
                    employee.departments.forEach(department => {
                        API.post('exchange/preview/events', {
                            date: this.week.start,
                            employee_id: employee.id,
                            department_id: department.id
                        }).then((res) => {
                            Vue.set(department, 'preview', res.data)
                        }).catch(() => {
                            this.$notify.error('user.exchange.add.notify.error.PREVIEW')
                        })
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .current-cell-backdrop {
        border: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: 0;
        z-index: 80;
    }

    .current-cell-content {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        background-color: lightblue;
        padding: 8px 4px;
        z-index: 120;
    }

    .editor-cell {
        border: 0px solid black !important;
        padding: 0;
    }

    .editor {
        border-radius: 2px;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        position: relative;
        /*top: 0;*/
        /*left: 20px;*/
        /*right: 20px;*/
        padding: 4px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        margin-top: 4px;
        z-index: 100;
    }

    .editor .panel {
        margin: 0;
    }

    .event-week {
        cursor: pointer;
    }

    .day-cell {
        display: table-cell;
        white-space: normal;
    }
</style>