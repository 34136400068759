<template lang="pug">
    #ipad-individual.panel.panel-primary(style="position:relative")
        .panel-heading(:class="{'cursor-pointer': measurementId}" @click="setToggle")
            i.fa.fa-navicon.mr3
            span {{'tasks.measurement.individual.table.CAPTION'|translate}}
            i.pull-right.fa(style="margin-top:5px"
                :class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}" v-show="measurementId")

        div(style="padding:5px 10px" v-show="isOffer")
            span.mr3 {{'tasks.ipad.PT'|translate}}
            b pt{{taskId}}(A|B|C|D…).

        .table-responsive(style="position:relative")
            table.table.table-striped.middle
                thead
                    tr(v-if="deltaCVD && deltaCVD < 3")
                        th.text-center.text-info(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span(v-html="$filters.translate('tasks.measurement.individual.notify.DELTA_CVD_IS_SMALLER')")
                    tr(v-if="from_task")
                        th.text-center.text-info(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span.mr3 {{'tasks.measurement.individual.test.notify.FROM_TASK'|translate}}
                            a.link(:href="$state.href('app.task.detail', {id: from_task})" target="_blank") {{from_task}}
                            span .

                    tr
                        th #
                        th
                            div
                                span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                                sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                            small(style="font-weight:normal") &Delta;0,5
                        th
                            div
                                span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                                sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                            small(style="font-weight:normal") &Delta;0,5
                        th
                            div
                                span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                                sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                            small(style="font-weight:normal") &Delta;1,5
                        th
                            div
                                span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                                sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                            small(style="font-weight:normal") &Delta;1,5
                        th
                            i.fa.fa-arrows-v(:title="'tasks.frame.HEIGHT'|translate")
                        th
                            i.fa.fa-arrows-h(:title="'tasks.frame.WIDTH'|translate")
                        th
                            div(:title="'tasks.INCLINATION'|translate")
                                div PA
                                small(style="font-weight:normal") &Delta;3,0
                        th
                            div CVD
                            small(style="font-weight:normal") &Delta;{{deltaCVD|number(1)}}
                        th
                            div(:title="'tasks.FFFA'|translate")
                                div FFFA
                                small(style="font-weight:normal") &Delta;1,0
                        th {{'tasks.measurement.individual.table.thead.CREATED'|translate}}
                        th {{'tasks.measurement.individual.table.thead.SIDE'|translate}}
                        th
                            span(:title="'tasks.measurement.individual.table.thead.gm.TITLE'|translate") {{'tasks.measurement.individual.table.thead.gm.GM'|translate}}
                        th
                            span(:title="'tasks.measurement.individual.table.thead.im.TITLE'|translate") {{'tasks.measurement.individual.table.thead.im.IM'|translate}}
                        th
                            i.fa.fa-edit(:title="'tasks.measurement.individual.table.title.MANUALY'|translate")
                        th(v-show="measurements.filter(m => m.measurementId).length")

                tbody
                    template(v-for="(m, key) in measurements")
                        tr(style="cursor:pointer" :class="{'tr-stripped': key % 2, 'opacity': m.disabled}" @click="clickError(m)"
                            :title="'tasks.measurement.individual.table.title.CHECK'|translate"
                            v-show="!measurementId || toggle || m.pass")
                            td {{m.order}}
                            td {{m.pdR|number(1)}}
                            td {{m.pdL|number(1)}}
                            td
                                .bt-row
                                    div(:title="btTitle('main', m, 'r')") {{m.btR|number(1)}}
                                    .text-muted(v-show="m.btR !== m.rawBtR" :title="btTitle('raw', m, 'r')") {{m.rawBtR|number(1)}}
                            td
                                .bt-row
                                    div(:title="btTitle('main', m, 'l')") {{m.btL|number(1)}}
                                    .text-muted(v-show="m.btL !== m.rawBtL" :title="btTitle('raw', m, 'l')") {{m.rawBtL|number(1)}}
                            td(:class="{'warn': checkAlerts(m.error, 'frameB')}") {{m.frameB|number(1)}}
                            td(:class="{'warn': checkAlerts(m.error, 'frameA')}") {{m.frameA|number(1)}}
                            td(:class="{'warn': checkAlerts(m.error, 'wpa')}") {{m.wpa|number(1)}}
                            td
                                span(:class="{'warn': checkAlerts(m.error, 'cvd')}")
                                    span.mr3(v-show="m.cvd !== m.cvdLeft") {{'prescription.RIGHT'|translate}}
                                    span {{m.cvd|number(1)}}
                                span(style="margin-left:3px" v-show="m.cvd !== m.cvdLeft" :class="{'warn': checkAlerts(m.error, 'cvdLeft')}")
                                    span.mr3 {{'prescription.LEFT'|translate}}
                                    span {{m.cvdLeft|number(1)}}
                            td(:class="{'warn': checkAlerts(m.error, 'fffa')}") {{m.fffa|number(1)}}
                            td {{m.created_at|noYear}}
                            td
                                span(v-if="m.side") {{'prescription.' + m.side.toUpperCase()|translate}}
                            td.bold
                                i.fa.fa-check.text-success(v-show="!m.error.length"
                                    :title="'tasks.measurement.individual.table.title.PAIR'|translate")
                                i.fa.fa-remove.warn(v-show="m.error.length"
                                    :title="'tasks.measurement.individual.table.title.NOPAIR'|translate")
                            td.bold
                                i.fa.fa-check.text-success(v-show="m.pass"
                                    :title="'tasks.measurement.individual.table.title.PAIR'|translate")
                            td.bold
                                i.fa.fa-exclamation.warn(v-show="m.manualy"
                                    :title="'tasks.measurement.individual.table.title.MANUALY'|translate")
                            td.text-center(style="cursor:pointer" :title="'tasks.measurement.IMAGE_SHOW'|translate"
                                v-show="m.measurementId" @click.stop="toggleImage(m)")
                                i.fa.fa-camera

                        tr(v-show="(!measurementId || toggle || m.pass) && !isEmpty(m.show)"
                            :class="{'tr-stripped': key % 2}")
                            td(colspan="100" style="border-top:0")
                                small.warn(style="display:block" v-for="e in m.show.error")
                                    i.fa.fa-exclamation-circle.mr3
                                    span.mr3 {{'tasks.measurement.notify.info.' + e[0]|translate}}
                                    span {{e[1]}}

                        tr(v-if="m.image")
                            td.text-center(colspan="100" style="border-top:0")
                                a(:href="'upload/task/visureal/' + m.measurementId + '.jpg'" target="_blank"
                                    :title="'tasks.ipad.printscreen.TITLE'|translate")
                                    img(style="width:100%" :src="'upload/task/visureal/' + m.measurementId + '.jpg?refresh=' + timestamp")

                    tr(v-show="!measurements.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

            OptiDimmer(:active="loading.test || loading.measurements")

        .panel-footer
            OptiButton.hide(style="margin-right:10px" v-show="permission.test"
                :disabled="loading.test || loading.measurements || block"
                @click.native="loadTest") {{'tasks.measurement.individual.test.BTN'|translate}}
                template(#icon)
                    i.fa.fa-download

            span(v-show="loading.measurements")
                i.fa.fa-cog.fa-spin.mr3
                span {{'tasks.ipad.DOWNLOADING'|translate}} {{download.key}}/{{download.length}}

            OptiButton.pull-right(type="success" :disabled="loading.test || loading.measurements || block"
                @click.native="load_new") {{'tasks.ipad.BTNREFRESH'|translate}}
                template(#icon)
                    i.fa.fa-refresh

            .clearfix
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {TaskMeasurementTitle} from '@/js/app/vue/components/Tasks/TaskMeasurementTitle'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsIpadVisuRealMeasurements',
        components: {
            OptiButton,
            OptiDimmer
        },
        props: {
            alertsRange: {
                type: Object,
                required: true
            },

            taskId: {
                type: Number,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            status: {
                type: Number,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            measurementId: {
                type: Number,
                required: true
            },
            frame: {
                type: Object,
                required: true
            },
            reloadMeasurements: {
                type: String,
                required: true
            },
            timestamp: {
                type: String,
                required: true
            },

            rSph: {
                type: Number,
                required: true
            },
            lSph: {
                type: Number,
                required: true
            },
            rCyl: {
                type: Number,
                required: true
            },
            lCyl: {
                type: Number,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                measurements: [],

                deltaCVD: 0,

                toggle: false,
                download: {
                    key: 0,
                    length: 0
                },
                permission: {
                    test: this.$uac.permission('tasks.measurements.test')
                },
                loading: {
                    test: false,
                    measurements: false
                }
            }
        },
        watch: {
            reloadMeasurements(assembly_method) {
                if(assembly_method) {
                    this.load(true)

                    this.$notify.warning(this.$filters.translate('tasks.measurement.notify.CHANGED_ASSEMBLY_METHOD') + ' ' +
                        assembly_method.toLowerCase(), false)
                }
            }
        },
        mounted() {
            this.load(true)
        },
        methods: {
            load_new() {
                this.loading.measurements = true
                this.download.key = 0

                API.get('task/visu-real/' + this.taskId + '/' + this.departmentId).then(res => {
                    this.download.length = res.data.length

                    if(this.download.length) {
                        res.data.forEach(measurement => {
                            API.post('task/visu-real/measurement', {
                                task_id: this.taskId,
                                is_offer: this.isOffer,
                                department_id: this.departmentId,
                                measurement_id: measurement.measurement_id,
                                updated: measurement.updated
                            }, API.id(Math.random())).then(() => {
                                ++this.download.key

                                if(this.download.key === this.download.length) {
                                    this.load(false)
                                }
                            }).catch(() => {
                                this.loading.measurements = false

                                this.$notify.error('tasks.measurement.notify.error.checkMeasurement')
                            })
                        })
                    } else {
                        this.loading.measurements = false
                    }
                }).catch(() => {
                    this.loading.measurements = false

                    this.$notify.error('tasks.measurement.notify.error.checkMeasurement')
                })
            },

            load(mounted) {
                if(!this.frame.type) {
                    if(!mounted) {
                        this.$notify.error('tasks.measurement.notify.error.NO_TYPE_FRAME')
                    }
                } else {
                    this.loading.measurements = true

                    API.post('task/visu-real/measurements', {
                        task_id: this.taskId,
                        is_offer: this.isOffer,
                        frame: this.frame,
                        sph_r: this.rSph,
                        sph_l: this.lSph,
                        cyl_r: this.rCyl,
                        cyl_l: this.lCyl
                    }).then(res => {
                        this.measurements = res.data.measurements
                        this.deltaCVD = res.data.deltaCVD

                        let pair = []

                        this.measurements.forEach(measurement => {
                            if(measurement.pass > 0) {
                                pair.push(measurement.measurementId)
                            }
                        })

                        this.$emit('update-measurement-visu-real', {
                            measurement_id: res.data.measurementId ? res.data.measurementId : 0,
                            pair: pair
                        })
                    }).finally(() => {
                        this.loading.measurements = false
                    }).catch(() => {
                        this.$notify.error('tasks.measurement.individual.notify.error.LOADMEASUREMENTS')
                    })
                }
            },

            loadTest() {
                this.loading.test = true

                API.post('task/measurements/test', {
                    task_id: this.taskId,
                    is_offer: this.isOffer,
                    type: 'VisuReal'
                }).then(res => {
                    if('error' in res.data) {
                        this.$notify.error(res.data.error, false)
                    } else if('task_id' in res.data) {
                        this.load()
                    }
                }).finally(() => {
                    this.loading.test = false
                }).catch(() => {
                    this.$notify.error('tasks.measurement.individual.test.notify.error.LOAD')
                })
            },

            checkAlerts(errors, key) {
                let data = this.alertsRange[key],
                    result = []

                errors.forEach(a => {
                    if(data.includes(parseInt(a[0]))) {
                        result.push(a)
                    }
                })

                return result.length ? result : false
            },
            clickError(m) {
                if(!m.error.length) {
                    return false
                }

                if(Object.keys(m.show).length && m.show.id === m.id) {
                    m.show = {}
                } else {
                    m.show = {
                        id: m.id,
                        error: m.error
                    }
                }
            },

            setToggle() {
                if(this.measurementId) {
                    this.toggle = !this.toggle
                }
            },
            toggleImage(m) {
                this.$set(m, 'image', !m.image)
            },

            btTitle(type, measurement, side) {
                return TaskMeasurementTitle.title('VisuReal', type, measurement, side)
            },

            isEmpty(obj) {
                return _.isEmpty(obj)
            }
        },
        computed: {
            from_task() {
                let filtered = this.measurements.filter(m => m.from_task)

                return filtered.length ? filtered[0].from_task : 0
            }
        }
    }
</script>

<style scoped lang="less">
    .cursor-pointer {
        cursor: pointer;
    }

    table > tbody > tr.opacity {
        opacity: .2;
    }
</style>