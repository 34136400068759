<template lang="pug">
    .opti-smile-more-files
        div.alert.alert-warning.text-center Wyświetla pliki nie starsze niż #[strong 3 miesiące]!
        //pre {{files}}
        table.table.table-condensed.table-striped.table-bordered
            thead
                tr
                    th.text-right #
                    th Typ
                    th.text-right Rozmiar
                    th Data
                    th Nazwa
                    th
            tbody
                tr(v-for="(file, idx) in files" :key="idx")
                    td.text-right {{idx+1}}
                    td {{file.type}}
                    td.text-right {{file.size}}
                    td {{file.time}} ({{file.relativeTime}})
                    td {{file.filename}}
                    td.text-center
                        a(:href="'smile_more_files/download/' + file.type + '/' + file.filename" download="download")
                            span.fa.fa-2x.fa-download
</template>

<script>
import {API} from "@/js/app/vue/api";
import moment from 'moment'

export default {
    name: "OptiSmileMoreFiles",
    data() {
        return {
            files: []
        }
    },
    mounted() {
        this.fetchFiles()
    },
    methods: {
        async fetchFiles() {
            try {
                let {data} = await API.get('reports/smile_more_files')
                this.files = data.map(item => {
                    item.time = moment.unix(item.ctime).format('YYYY-MM-DD hh:mm:ss')
                    item.relativeTime = moment.unix(item.ctime).fromNow()
                    return item
                })
            } catch (e) {
                console.error(e)
            }
        }
    },
}
</script>

<style scoped lang="less">

</style>