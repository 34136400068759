<template lang="pug">
    .opti-select-department(v-if="items.length")
        VueMultiSelect(
            :options="items"
            :value="current"
            :clearable="false"
            label="name"
            @input="updateSelected"
            :placeholder="'search.department.PLACEHOLDER'|translate"
            :group-select="false"
            group-label="group"
            group-values="deps"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            :custom-label="searchModifier"
            ref="select"
            :disabled="!multipleDepartments")
            template(slot="singleLabel" slot-scope="props")
                .pull-left
                    span {{ simple ? props.option.login : props.option.name}}
                .pull-right(v-if="!simple")
                    small {{props.option.id}}:{{props.option.login}}
            template(slot="option" slot-scope="props")
                div(v-if="!props.option.$isLabel")
                    div(v-if="isHQ(props.option.id)")
                        .pull-left {{props.option.name}}
                        small.text-muted.pull-right {{props.option.id}}
                        .clearfix
                    div(v-else)
                        .muted
                            .pull-left
                                small {{props.option.city}} &mdash; {{departmentType(props.option)}}
                            .pull-right
                                small {{props.option.id}}:{{props.option.login}}
                            .clearfix
                        div
                            span {{props.option.name}}
                div(v-if="props.option.$isLabel")
                    span {{props.option.$groupLabel}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import {INIT} from '@/js/app/vue/store/opti-store'

    export default {
        name: 'OptiSelectDepartment',
        components: {VueMultiSelect},
        props: {
            departmentId: {
                required: true
            },
            index: {
                type: Number,
                required: false
            },
            reduce: {
                type: Function,
                required: false,
                default: department => department
            },
            simple: {
                type: Boolean,
                required: false,
                default: false
            },
            filter: {
                type: Function,
                required: false,
                default: items => items
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            showAll: {
                type: Boolean,
                required: false,
                default: false
            },
            disabledDepartments: {
                type: Array,
                required: false,
                default: () => {
                    return []
                }
            },
            recentlyClosed: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                type: this.recentlyClosed ? 'departments_recently_closed' : 'departments',

                headquarters: [49, 60, 91, 149, 392],

                selected: null
            }
        },
        watch: {
            disabledDepartments() {
                this.selectDisabledDepartments()
            }
        },
        mounted() {
            this.$store.dispatch(`${this.type}/${INIT}`)

            if(this.simple) {
                this.$refs.select.$refs.list.classList.add('simple')
            }

            this.selectDisabledDepartments()
        },
        computed: {
            multipleDepartments() {
                let _count = 0

                this.items.forEach(item => {
                    _count += item.deps.length
                })

                return _count > 1
            },
            itemsFlat() {
                let items = _.clone(this.$store.state[this.type].items)

                // filtrujemy tylko salony dostępne dla użytkownika
                let departments = this.getDepartments()
                items = items.filter(department => departments.indexOf(department.id) !== -1)

                items.sort((a, b) => {
                    // sort departments by city
                    if (a.city.toUpperCase().replace('Ł', 'L') === b.city.toUpperCase().replace('Ł', 'L')) {
                        return a.name.toUpperCase().replace('Ł', 'L') > b.name.toUpperCase().replace('Ł', 'L') ? -1 : 1
                    }

                    return a.city.toUpperCase().replace('Ł', 'L') < b.city.toUpperCase().replace('Ł', 'L') ? -1 : 1
                })

                return this.filter(items)
            },
            items() {
                let items = this.itemsFlat,
                    groups = [{
                        group: 'Centrala',
                        deps: []
                    }, {
                        group: 'Inne',
                        deps: []
                    }, {
                        group: 'Niedawno zamknięte',
                        deps: []
                    }]

                items.map(department => {
                    if(department.status === 3) {
                        groups[2].deps.push(department)
                    } else if(this.headquarters.includes(department.id)) {
                        groups[0].deps.push(department)
                    } else {
                        groups[1].deps.push(department)
                    }
                })

                return groups
            },
            current() {
                if(!this.selected) {
                    let item = this.itemsFlat.find(dep => dep.id === parseInt(this.departmentId))
                    if(item) {
                        this.updateSelected(item)
                    }
                }

                return this.selected
            }
        },
        methods: {
            selectDisabledDepartments() {
                this.items.forEach(item => {
                    item.deps.forEach(dep => {
                        dep.$isDisabled = this.disabledDepartments.includes(dep.id)
                    })
                })
            },
            getDepartments() {
                if(this.showAll) {
                    return this.$store.state[this.type].items.map(department => department.id)
                }

                return this.$uac.user.department.map(department => department.departmentId)
            },
            isHQ(id) {
                return this.headquarters.includes(id)
            },
            updateSelected(department) {
                if(typeof this.index !== 'undefined') {
                    department.index = this.index
                }

                this.selected = department
                this.$emit('selected', this.reduce(department))
            },
            searchModifier(option) {
                return [
                    option.login,
                    option.name,
                    option.city,
                    option.id
                ].join('|')
            },
            departmentType(department) {
                switch (department.groupId) {
                    case 4:
                        return 'Loox'
                    case 5:
                        return 'Sunloox'
                    default:
                        return ''
                }
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    @media only screen and (max-width: 767px) {
        .multiselect /deep/ .simple {
            width: unset;
            right: unset;
        }
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: 0.5;
    }
</style>
