<template lang="pug">
    span.opti-report-offers-rate
        .opti-report-offers-rate-params
            span
                a.fa-stack(:title="'report.offer.rate.offer.RATE'|translate|lower"
                    :class="getColor('offers_rate_avg')" :href="getHref('offers')" target="_blank")
                    i.fa.fa-square.fa-stack-2x
                    i.fa.fa-stack-1x(v-if="!loading") {{r.offers_rate_avg|number(1)}}

                a.fa-stack(:title="loading ? '' : $filters.translate('report.offer.rate.offer.ratio.PART_1').toLowerCase() + r.offers_count + $filters.translate('report.offer.rate.offer.ratio.PART_2').toLowerCase() + r.tasks_count + $filters.translate('report.offer.rate.offer.ratio.PART_3').toLowerCase()"
                    :class="getColor('ratio_offers_count_tasks_count')" :href="getHref('tasks')" target="_blank")
                    i.fa.fa-circle.fa-stack-2x
                    i.fa.fa-stack-1x(v-if="!loading") {{r.ratio_offers_count_tasks_count|number(1)}}

            br

            span
                a.fa-stack(:title="'report.offer.rate.temporary_task.RATE'|translate|lower"
                    :class="getColor('temporary_tasks_rate_avg')" :href="getHref('temporary_tasks')" target="_blank")
                    i.fa.fa-square.fa-stack-2x
                    i.fa.fa-stack-1x(v-if="!loading") {{r.temporary_tasks_rate_avg|number(1)}}

                a.fa-stack(:title="loading ? '' : $filters.translate('report.offer.rate.temporary_task.ratio.PART_1').toLowerCase() + r.temporary_tasks_count + $filters.translate('report.offer.rate.temporary_task.ratio.PART_2').toLowerCase() + r.tasks_count + $filters.translate('report.offer.rate.temporary_task.ratio.PART_3').toLowerCase()"
                    :class="getColor('ratio_temporary_tasks_count_tasks_count')" :href="getHref('tasks')" target="_blank")
                    i.fa.fa-circle.fa-stack-2x
                    i.fa.fa-stack-1x(v-if="!loading") {{r.ratio_temporary_tasks_count_tasks_count|number(1)}}

        .opti-report-offers-rate-sum-params
            .fa-stack(:title="'report.offer.rate.SUM'|translate|lower" :class="getColor('sum')")
                i.fa.fa-square.fa-stack-2x
                i.fa.fa-stack-1x(v-if="!loading") {{r.sum|number(1)}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import {ReportOffersRatesHelper} from '@/js/app/vue/components/Reports/Offers/ReportOffersRatesHelper'

    export default {
        name: 'OptiReportOffersRate',
        props: {
            rate: {
                type: Object,
                required: false,
                default: null
            },
            userId: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                r: this.rate,

                loading: !!this.userId
            }
        },
        mounted() {
            if(this.userId) {
                API.get('reports/offers/rates/user/' + this.userId).then(res => {
                    this.r = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.offer.notify.error.LOAD')
                })
            }
        },
        methods: {
            getColor(field) {
                if(this.loading) {
                    return ['loading']
                }

                let _return = [this.r[field] >= this.r.period.offerDepartmentRate[field] ? 'up' :
                    (this.r[field] >= 0.7 * this.r.period.offerDepartmentRate[field] ? 'warning' : 'down')]

                if(!this.r[field]) {
                    _return.push('no_rate')
                }

                return _return
            },
            getHref(type) {
                if(this.loading) {
                    return ''
                }

                let {state, params} = ReportOffersRatesHelper.getHref(this.r, type)

                return this.$state.href(state, params)
            }
        }
    }
</script>

<style lang="less" scoped>
    .margin {
        margin-bottom: 15px;
    }

    .opti-report-offers-rate {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        color: #fff;

        > .opti-report-offers-rate-params > span > .fa-stack,
        > .opti-report-offers-rate-sum-params > .fa-stack {
            color: #fff;

            &:hover, &:focus {
                color: inherit;
            }

            &.no_rate {
                opacity: .6;
            }

            &.loading > .fa-stack-2x {
                color: royalblue;

                -webkit-text-stroke: 1px powderblue;
                text-stroke: 1px powderblue;
            }

            &.up > .fa-stack-2x {
                color: forestgreen;

                -webkit-text-stroke: 1px greenyellow;
                text-stroke: 1px greenyellow;
            }

            &.warning {
                > .fa-stack-2x {
                    color: darkorange;

                    -webkit-text-stroke: 1px #000;
                    text-stroke: 1px #000;
                }

                > .fa-stack-1x {
                    color: #000;
                }
            }

            &.down > .fa-stack-2x {
                color: indianred;

                -webkit-text-stroke: 1px darkred;
                text-stroke: 1px darkred;
            }
        }

        > .opti-report-offers-rate-sum-params > .fa-stack > .fa-stack-2x {
            transform: rotate(45deg);
        }
    }
</style>