<template lang="pug">
    a.link(:title="'default.title.PRESCRIPTION'|translate" @click="open = true")
        i.fa.fa-file-text-o.mr3(v-show="!autoOpen")
        span(v-show="!autoOpen") {{id}}

        MountingPortal(v-if="open && id > 0" mountTo="#vue-modal" append)
            OptiDialogPrescription(:id="id" @closed="open = false")
</template>

<script>
    import OptiDialogPrescription from '@/js/app/vue/components/Button/Dialogs/OptiDialogPrescription'

    export default {
        name: 'OptiButtonPrescription',
        components: {
            OptiDialogPrescription
        },
        props: {
            autoOpen: {
                type: Boolean,
                required: false,
                default: false
            },

            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                open: this.autoOpen
            }
        }
    }
</script>