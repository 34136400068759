<template lang="pug">
    div(:class="{'table-responsive': previouslens.dist || previouslens.near}")
        table.table.table-striped.text-center.middle
            thead
                tr
                    th.text-center(style="border-top:1px solid #aaa;border-right:1px solid #aaa" colspan="3") {{'optometrist.acuity.WITHOUTCORRECTION'|translate}}
                    th(style="padding-top:10px;padding-bottom:10px;border-top:1px solid #aaa" colspan="4") {{'optometrist.acuity.WITHCORRECTION'|translate}}
                    th(style="border-top:1px solid #aaa" colspan="6")
            tbody
                tr
                    th
                        button.btn.btn-info(type="button" :disabled="disabled"
                            @click="dialog.near_no_correction = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog.near_no_correction" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionNearDialogSurvey(
                                    :interview="interview"

                                    r-visus="near_nc_r_visus"
                                    l-visus="near_nc_l_visus"
                                    bin-visus="near_nc_binvisus"

                                    mode="no_correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog.near_no_correction = false")
                    td Visus
                    td(style="border-right:1px solid #aaa") BinVisus
                    th(v-show="previouslens.dist || previouslens.near")
                        button.btn.btn-info(type="button" :disabled="disabled"
                            @click="dialog.near_with_correction = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog.near_with_correction" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionNearDialogSurvey(
                                    :interview="interview"

                                    r-visus="near_c_r_visus"
                                    l-visus="near_c_l_visus"
                                    bin-visus="near_c_binvisus"

                                    mode="with_correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog.near_with_correction = false")
                    td.text-center.warn.bold(rowspan="3" colspan="2"
                        v-show="previouslens.dist && previouslens.dist_cant_read_powers") {{'previouslens.READPOWER'|translate}}
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers") SPH
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers") CYL
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAxisNearExists") AXS
                    td(v-show="(previouslens.dist || previouslens.near) && (interview.near_c_r_prism || interview.near_c_l_prism)") Prism
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAngleNearExists") Angle
                    td(v-show="previouslens.dist || previouslens.near") Visus
                    td(v-show="previouslens.dist || previouslens.near") BinVisus
                    td.text-center.bold.warn(rowspan="3" colspan="8"
                        v-show="!previouslens.dist && !previouslens.near") {{'optometrist.correction.NORESULT'|translate}}
                tr
                    th {{'prescription.RIGHT'|translate}}
                    td
                        span.form-control(disabled) {{interview.near_nc_r_visus}}
                    td(style="border-right:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.near_nc_binvisus}}
                    th(v-show="previouslens.dist || previouslens.near") {{'prescription.RIGHT'|translate}}
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].near.r.sph|plus}}
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].near.r.cyl|plus}}
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAxisNearExists")
                        span.form-control(disabled v-show="correction[activeCyl].near.r.cyl") {{correction[activeCyl].near.r.axs}}
                    td(v-show="(previouslens.dist || previouslens.near) && (interview.near_c_r_prism || interview.near_c_l_prism)")
                        span.form-control(disabled) {{interview.near_c_r_prism|number(2)}}
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAngleNearExists")
                        span.form-control(disabled v-show="interview.near_c_r_prism") {{interview.near_c_r_base}}
                    td(v-show="previouslens.dist || previouslens.near")
                        span.form-control(disabled) {{interview.near_c_r_visus}}
                    td(rowspan="2" v-show="previouslens.dist || previouslens.near")
                        span.form-control(disabled) {{interview.near_c_binvisus}}
                tr
                    th {{'prescription.LEFT'|translate}}
                    td
                        span.form-control(disabled) {{interview.near_nc_l_visus}}
                    th(v-show="previouslens.dist || previouslens.near") {{'prescription.LEFT'|translate}}
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].near.l.sph|plus}}
                    td(v-show="(previouslens.dist || previouslens.near) && !previouslens.dist_cant_read_powers")
                        span.form-control(disabled) {{correction[activeCyl].near.l.cyl|plus}}
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAxisNearExists")
                        span.form-control(disabled v-show="correction[activeCyl].near.l.cyl") {{correction[activeCyl].near.l.axs}}
                    td(v-show="(previouslens.dist || previouslens.near) && (interview.near_c_r_prism || interview.near_c_l_prism)")
                        span.form-control(disabled) {{interview.near_c_l_prism|number(2)}}
                    td(v-show="(previouslens.dist || previouslens.near) && acuityAngleNearExists")
                        span.form-control(disabled v-show="interview.near_c_l_prism") {{interview.near_c_l_base}}
                    td(v-show="previouslens.dist || previouslens.near")
                        span.form-control(disabled) {{interview.near_c_l_visus}}
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiOptometristsDetailsRefractionNearDialogSurvey
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/Dialogs/OptiOptometristsDetailsRefractionNearDialogSurvey'

    export default {
        name: 'OptiOptometristsDetailsRefractionNear',
        components: {
            OptiNumberPrescription,
            OptiNumber,
            OptiButton,
            OptiEnum,

            OptiOptometristsDetailsRefractionNearDialogSurvey
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },
            activeCyl: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    near_no_correction: false,
                    near_with_correction: false
                }
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                ['near_no_correction', 'near_with_correction'].forEach(type => {
                    this.dialog[type] = false
                    this.dialog[type] = false
                })

                this.$emit('update-interview', interview)
            }
        },
        computed: {
            acuityAxisNearExists() {
                return this.interview.near_c_r_cylinder || this.interview.near_c_l_cylinder
            },
            acuityAngleNearExists() {
                return this.interview.near_c_r_prism || this.interview.near_c_l_prism
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>