<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-show="!loading")
			form.form-horizontal.form-validate(name="formSpecialist" @submit.prevent="addSpecialist")
				.form-group
					label.col-sm-4.control-label.star-required(for="login") {{'dictionary.specialist.table.LOGIN'|translate}}
					.col-sm-8
						input#login.form-control(:placeholder="'dictionary.specialist.table.LOGIN'|translate"
						v-model="specialist.login" name="login")
						OptiValidate(f="login" :data="$v.specialist.login")
				.form-group(style="padding-top:30px")
					label.col-sm-4.control-label.star-required(for="priority") {{'dictionary.specialist.table.PRIORITY'|translate}}
					.col-sm-8
						OptiNumber#priority.form-control.text-right(
							:header="'dictionary.specialist.table.PRIORITY'|translate"
							:value="specialist.priority"
							@confirmed="value => specialist.priority = value"
						) {{specialist.priority}}
						OptiValidate(f="priority" :data="$v.specialist.priority")
				.form-group
					label.col-sm-4.control-label.star-required(for="parity") {{'dictionary.specialist.table.PARITY'|translate}}
					.col-sm-8
						OptiNumber#parity.form-control.text-right(
							:header="'dictionary.specialist.table.PARITY'|translate"
							:value="specialist.parity"
							@confirmed="value => specialist.parity = value"
						) {{specialist.parity}}
						OptiValidate(f="parity" :data="$v.specialist.parity")
				.form-group(style="padding-top:30px")
					label.col-sm-4.control-label.star-required(for="password") {{'dictionary.specialist.table.PASSWORD'|translate}}
					.col-sm-8
						input#password.form-control(:placeholder="'dictionary.specialist.table.PASSWORD'|translate"
							v-model="specialist.password" type="password")
						OptiValidate(f="password" :data="$v.specialist.password")
				.form-group
					label.col-sm-4.control-label.star-required(for="confirm") {{'dictionary.specialist.table.CONFIRM'|translate}}
					.col-sm-8
						input#confirm.form-control(:placeholder="'dictionary.specialist.table.CONFIRM'|translate"
							v-model="specialist.confirm" type="password" name="confirm")
						OptiValidate(f="confirm" :data="$v.specialist.confirm")
				.form-group(style="padding-top:30px")
					label.col-sm-4.control-label.star-required(for="first_name") {{'dictionary.specialist.table.FIRSTNAME'|translate}}
					.col-sm-8
						input#first_name.form-control(:placeholder="'dictionary.specialist.table.FIRSTNAME'|translate"
							v-model="specialist.first_name" name="first_name")
						OptiValidate(f="first_name" :data="$v.specialist.first_name")
				.form-group
					label.col-sm-4.control-label.star-required(for="last_name") {{'dictionary.specialist.table.LASTNAME'|translate}}
					.col-sm-8
						input#last_name.form-control(:placeholder="'dictionary.specialist.table.LASTNAME'|translate"
							v-model="specialist.last_name" name="last_name")
						OptiValidate(f="last_name" :data="$v.specialist.last_name")
				.form-group
					label.col-sm-4.control-label.star-required(for="type") {{'dictionary.specialist.table.TYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#type(enum="SpecialistType"
							:value="specialist.type" @update-enum="v => specialist.type = v")
						OptiValidate(f="type" :data="$v.specialist.type")
				.form-group
					label.col-sm-4.control-label(for="cellphonenumber") {{'dictionary.specialist.table.PHONE'|translate}}
					.col-sm-8
						OptiCellPhoneInput#phone(:placeholder="'dictionary.specialist.table.PHONE'|translate"
							:country="specialist.cellPhoneCountry"
							:phone="specialist.cellphonenumber"

							@country="c => specialist.cellPhoneCountry = c"
							@phone="p => specialist.cellphonenumber = p")
						OptiValidate(f="cellphonenumber" :data="$v.specialist.cellphonenumber")
				.form-group(style="padding-top:30px")
					.col-sm-8.col-sm-offset-4
						span.fa.fa-home
						span {{'dictionary.specialist.table.ADDRESS'|translate}}
				.form-group
					label.col-sm-4.control-label(for="name") {{'dictionary.specialist.table.NAME'|translate}}
					.col-sm-8
						input#name.form-control(:placeholder="'dictionary.specialist.table.NAME'|translate"
							v-model="specialist.name" name="name")
				.form-group
					label.col-sm-4.control-label(for="street") {{'dictionary.specialist.table.STREET'|translate}}
					.col-sm-8
						input#street.form-control(:placeholder="'dictionary.specialist.table.STREET'|translate"
							v-model="specialist.street" name="street")
				.form-group
					label.col-sm-4.control-label(for="zip-code") {{'dictionary.specialist.table.ZIPCODE'|translate}}
					.col-sm-8
						input#zip-code.form-control(:placeholder="'dictionary.specialist.table.ZIPCODE'|translate"
							v-model="specialist.zip_code" name="zip_code")
				.form-group
					label.col-sm-4.control-label(for="city") {{'dictionary.specialist.table.CITY'|translate}}
					.col-sm-8
						OptiSelectCity(
							:city="specialist.city"
							@selected="c => specialist.city = c"
							)
				.form-group
					label.col-sm-4.control-label(for="nip") {{'dictionary.specialist.table.NIP'|translate}}
					.col-sm-8
						input#nip.form-control(:placeholder="'dictionary.specialist.table.NIP'|translate"
							v-model="specialist.nip" name="nip")
				.form-group
					label.col-sm-4.control-label(for="bill") {{'dictionary.specialist.table.BILL'|translate}}
					.col-sm-8
						input#bill.form-control(:placeholder="'dictionary.specialist.table.BILL'|translate"
							v-model="specialist.bill" name="bill")
				.form-group(style="padding-top:30px")
					label.col-sm-4.control-label(for="ups_id") {{'dictionary.specialist.table.UPS'|translate}}
					.col-sm-8
						input#ups_id.form-control(:placeholder="'dictionary.specialist.table.UPS'|translate"
							v-model="specialist.ups_id" name="ups_id")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.dictionary.specialists.table')"
						) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(
							:disabled="$v.specialist.$invalid")
		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'
	import {minLength, required, sameAs} from 'vuelidate/lib/validators'
	import {unique} from '../../../../../vue.validators'
	import {noZero} from '@/js/vue.validators.js'

	import OptiSelectEnum from '../../Select/OptiSelectEnum'
	import OptiValidate from '../../OptiValidate'
	import OptiDimmer from '../../Blocks/OptiDimmer'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiButton from '../../Button/OptiButton'
	import OptiSelectCity from '../../Select/OptiSelectCity'
	import OptiSelectCountry from '../../Select/OptiSelectCountry'
	import OptiCellPhoneInput from '../../Inputs/OptiCellPhoneInput'
	import OptiNumber from '../../Inputs/OptiNumber'

	export default {
		name: 'OptiDictionarySpecialistAdd',
		components: {
			OptiSelectEnum,
			OptiValidate,
			OptiDimmer,
			OptiButtonSave,
			OptiButton,
			OptiSelectCity,
			OptiSelectCountry,
			OptiCellPhoneInput,
			OptiNumber
		},
		data() {
			return {
				specialist: {
					login: '',
					priority: 0,
					parity: 0,
					password: '',
					type: 0,
					confirm: '',
					first_name: '',
					last_name: '',
					name: '',
					street: '',
					zip_code: '',
					city: '',
					nip: '',
					bill: '',
					ups_id: '',
					cellPhoneCountry: {
						id: 171,
						pl: 'Polska',
						phoneCode: 48
					},
					cellphonenumber: ''
				},
				loading: false
			}
		},
		validations: {
			specialist: {
				login: {
					required,
					unique: unique('specialists', 'login'),
					minLength: minLength(5),
				},
				priority: {
					noZero
				},
				parity: {
					noZero
				},
				password: {
					required,
					minLength: minLength(5)
				},
				confirm: {
					required,
					minLength: minLength(5),
					sameAsPassword: sameAs('password')
				},
				first_name: {
					required
				},
				last_name: {
					required
				},
				type: {
					noZero
				},
				cellphonenumber: {
					minLength: minLength(8),
				}
			}
		},
		methods: {
			addSpecialist() {
				this.loading = true

				API.post('dictionary/specialist/action', this.specialist).then(() => {
					this.$notify.success('dictionary.specialist.notify.ADD')

					this.$state.go('app.dictionary.specialists.table')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('dictionary.specialist.notify.error.ADD')
				})
			}
		}
	}
</script>
