<template lang="pug">
    div
        table#task-summary-sidebar.table.table-condensed
            tbody
                tr
                    th
                    th(:title="'tasks.sidebar.RIGHT'|translate") {{'prescription.RIGHT'|translate}}
                    th(:title="'tasks.sidebar.LEFT'|translate") {{'prescription.LEFT'|translate}}

                tr(v-show="task.type !== 1")
                    th(rowspan="2" :title="'tasks.sidebar.pd.title.DIST'|translate")
                        div PD
                        div {{'tasks.sidebar.pd.DIST'|translate}}
                    td(colspan="2")
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdDist")
                tr(v-show="task.type !== 1")
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdDistR")
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdDistL")

                tr(v-show="task.type !== 2")
                    th(rowspan="2" :title="'tasks.sidebar.pd.title.NEAR'|translate")
                        div PD
                        div {{'tasks.sidebar.pd.NEAR'|translate}}
                    td(colspan="2")
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdNear")
                tr(v-show="task.type !== 2")
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdNearR")
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="pdNearL")

                tr
                    th(:title="'tasks.sidebar.BT'|translate") {{'tasks.BT'|translate}}
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="btR")
                    td
                        OptiTaskDetailsMeasurementField(
                            :enter="true"

                            :task="task"
                            field="btL")

                tr
                    th(:title="'tasks.sidebar.SPHERE'|translate") {{'prescription.SPHERE'|translate}}
                    td {{task.rightEye.sphere|plus}}
                    td {{task.leftEye.sphere|plus}}
                tr(v-show="task.rightEye.cylinder || task.leftEye.cylinder")
                    th(:title="'tasks.sidebar.CYLINDER'|translate") {{'prescription.CYLINDER'|translate}}
                    td {{task.rightEye.cylinder|plus}}
                    td {{task.leftEye.cylinder|plus}}
                tr(v-show="task.rightEye.axis !== 'brak' || task.leftEye.axis !== 'brak'")
                    th(:title="'tasks.sidebar.AXIS'|translate") {{'prescription.AXIS'|translate}}
                    td {{task.rightEye.axis}}
                    td {{task.leftEye.axis}}
                tr(v-show="task.rightEye.addition || task.leftEye.addition")
                    th(:title="'tasks.sidebar.ADDITION'|translate") {{'prescription.ADDICTION'|translate}}
                    td {{task.rightEye.addition|number}}
                    td {{task.leftEye.addition|number}}
                tr(v-show="task.rightEye.support || task.leftEye.support")
                    th(:title="'tasks.sidebar.SUPPORT'|translate") {{'prescription.SUPPORT'|translate}}
                    td {{task.rightEye.support|number}}
                    td {{task.leftEye.support|number}}
                tr(v-show="task.rightEye.prism || task.leftEye.prism")
                    th(:title="'tasks.sidebar.PRISM'|translate") {{'prescription.PRISM'|translate}}
                    td {{task.rightEye.prism|number}}
                    td {{task.leftEye.prism|number}}
                tr(v-show="task.rightEye.prism || task.leftEye.prism")
                    th(:title="'tasks.sidebar.PRISMANGLE'|translate") {{'prescription.PRISMANGLE'|translate}}
                    td {{task.rightEye.prismAngle}}
                    td {{task.leftEye.prismAngle}}

                tr.separate-top
                    th(:title="'tasks.sidebar.FRAME'|translate")
                        i.icon-eyeglasses
                    td(colspan="2")
                        OptiProduct(v-if="task.product.id" :product="task.product") {{task.frame.partName}}
                        span(v-else) {{task.frame.partName}}

                tr(v-show="task.packId")
                    th(:title="'tasks.sidebar.PACK'|translate") PAK
                    td(colspan="2")
                        a.link(:title="'default.title.LENSES'|translate"
                            :href="$state.href('app.packs.list', {search: ['id:=:' + task.packId]})" target="_blank") {{task.pack.pack_name1}}
                tr(v-show="!task.packId && task.rightLens.partName && (task.rightLens.partName === task.leftLens.partName)")
                    th(:title="'tasks.sidebar.lens.HEADER'|translate") {{'prescription.LENS'|translate}}
                    td(colspan="2")
                        a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.rLensPriceGroup.categoryId"
                            :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                            target="_blank") {{task.rightLens.partName}}
                        span(v-else) {{task.rightLens.partName}}
                tr(v-show="!task.packId && task.rightLens.partName && (task.rightLens.partName !== task.leftLens.partName)")
                    th(:title="'tasks.sidebar.lens.RIGHT'|translate") {{'prescription.RIGHT'|translate}} {{'prescription.LENS'|translate}}
                    td(colspan="2")
                        a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.rLensPriceGroup.categoryId"
                            :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                            target="_blank") {{task.rightLens.partName}}
                        span(v-else) {{task.rightLens.partName}}
                tr(style="border-top:1px solid white" v-show="!task.packId && task.rightLens.partName && (task.rightLens.partName !== task.leftLens.partName)")
                    th(:title="'tasks.sidebar.lens.LEFT'|translate") {{'prescription.LEFT'|translate}} {{'prescription.LENS'|translate}}
                    td(colspan="2")
                        a.link(:title="'default.title.LENSES'|translate" v-if="task.hoya_lens_category_id || task.lLensPriceGroup.categoryId"
                            :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.lLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                            target="_blank") {{task.leftLens.partName}}
                        span(v-else) {{task.leftLens.partName}}

                tr.separate-top
                    th(:title="'tasks.sidebar.FRAMEPRICE'|translate")
                        i.icon-eyeglasses.mr3
                        i.fa.fa-dollar
                    td.price(colspan="2") {{task.frame.price|currency}}
                tr(v-show="task.packId")
                    th(:title="'tasks.sidebar.PACKPRICE'|translate")
                        span.mr3 PAK
                        i.fa.fa-dollar
                    td.price(colspan="2") {{task.rightLens.price|currency}}
                tr(v-show="!task.packId")
                    th(:title="'tasks.sidebar.lens.price.RIGHT'|translate")
                        span.mr3 {{'prescription.RIGHT'|translate}} {{'prescription.LENS'|translate}}
                        i.fa.fa-dollar
                    td.price(colspan="2") {{task.rightLens.price|currency}}
                tr(v-show="!task.packId")
                    th(:title="'tasks.sidebar.lens.price.LEFT'|translate")
                        span.mr3 {{'prescription.LEFT'|translate}} {{'prescription.LENS'|translate}}
                        i.fa.fa-dollar
                    td.price(colspan="2") {{task.leftLens.price|currency}}

                tr(v-show="sumTaskExtra(true)")
                    th
                        i.fa.fa-puzzle-piece.mr3
                        span.mr3 {{'prescription.LENS'|translate}}
                        i.fa.fa-dollar
                    td.price(colspan="2") {{sumTaskExtra(true)|currency}}
                tr(v-show="task.taskAssembly.price")
                    th(:title="'tasks.sidebar.SERVICEPRICE'|translate")
                        i.fa.fa-cogs
                    td.price(colspan="2") {{task.taskAssembly.price|currency}}
                tr(v-show="task.coveringPrice")
                    th(:title="'tasks.sidebar.ADDCOLOR'|translate")
                        i.fa.fa-paint-brush
                    td.price(colspan="2") {{task.coveringPrice|currency}}
                tr(v-show="sumTaskExtra(false)")
                    th(:title="'tasks.sidebar.ADDPRICE'|translate")
                        i.fa.fa-puzzle-piece
                    td.price(colspan="2") {{sumTaskExtra(false)|currency}}
                tr(v-show="task.registry.serviceContractPrice && task.registry.serviceContractPrice !== '0'")
                    th(:title="'tasks.sidebar.SC'|translate") US
                    td.price(colspan="2") {{task.registry.serviceContractPrice|currency}}
                tr.separate-bottom
                    th(:title="'tasks.sidebar.DISCOUNT'|translate")
                        i.fa.fa-minus-square
                    td.price(colspan="2") ({{task.registry.discountPercent|number}}%) {{task.registry.discountAmount|currency}}
                tr
                    th(:title="'tasks.sidebar.TOPAY'|translate") &Sigma;
                    td.price(colspan="2") {{task.registry.amountToPay|currency}}
                tr.separate-top
                    th(:title="'tasks.sidebar.ACCOUNT'|translate") K
                    td.price(colspan="2") {{task.registry.account|currency}}
                tr
                    th(:title="'tasks.sidebar.RESTTOPAY'|translate") R
                    td.price(colspan="2") {{task.registry.restToPay|currency}}

        .text-center(style="padding-bottom:10px" v-show="!showSummary && !isOffer && task.status === 1")
            OptiButton(type="danger" text="tasks.action.cancelled.confirm"
                @confirmed="goCancel") {{'tasks.action.cancelled.GOCANCELLEDFULLNAME'|translate}}
                template(#icon)
                    i.fa.fa-remove

        .text-center(v-show="showSummary && !isOffer && [1, 9].includes(task.status)")
            label.btn.btn-purple.btn-task-print.mr3(for="printSidebarMain" :title="'tasks.AGREEMENT'|translate"
                :disabled="!task.expected")
                i.fa.fa-print
                div {{'tasks.AGREEMENT'|translate}}
            OptiButtonPrint.hide(element-id="printSidebarMain" url="task/print" :id="task.id")

            label.btn.btn-purple.btn-task-print.mr3(for="printSidebarServiceContract" :title="'tasks.US'|translate"
                :disabled="!task.expected" v-show="task.hasServiceContract")
                i.fa.fa-print
                div {{'tasks.US'|translate}}
            OptiButtonPrint.hide(element-id="printSidebarServiceContract" url="task/print/service-contract" :id="task.id")

            label.btn.btn-purple.btn-task-print.mr3(for="printSidebarAcceptanceOfRisk" :title="'tasks.RISK'|translate"
                v-show="task.frame.state === 2")
                i.fa.fa-print.mr3
                div {{'tasks.RISK'|translate}}
            OptiButtonPrint.hide(element-id="printSidebarAcceptanceOfRisk" url="task/print/acceptance-of-risk" :id="task.id")
</template>

<script>
    import OptiTaskDetailsMeasurementField from '@/js/app/vue/components/Tasks/OptiTaskDetailsMeasurementField'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'

    export default {
        name: 'OptiTaskDetailsSidebar',
        components: {
            OptiTaskDetailsMeasurementField,
            OptiProduct,
            OptiButton,
            OptiButtonPrint
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            showSummary: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            sumTaskExtra(lenses) {
                return _.sumBy(this.task.services,
                    s => (lenses && s.hoya_option_id) || (!lenses && !s.hoya_option_id) ? s.pivot.price : 0)
            },
            goCancel() {
                this.task.status = 0

                this.$emit('sidebar-was-updated')
            }
        }
    }
</script>

<style lang="less" scoped>
    label.btn {
       width: 75px;
        margin-bottom: 5px;
    }
</style>