<template lang="pug">
    .search-result-item(@click="changeState")
        .search-result-item__info(:style="{'background-color': itemBackgroundColor}")
            .search-result-item__info__type {{item.type|translate}}
            .search-result-item__info__id {{item.id}}
            .search-result-item__info__type(v-if="item.more") {{item.more.status|translate}}
        .search-result-item__body(:style="itemBodyStyle")
            .search-result-item__body__name {{item.name}}
            .search-result-item__body__line(v-if="shortType !== 'PRODUCTS'" v-html="itemInfo")
            .search-result-item__body__line(v-else)
                OptiDepositoryInfo(:product="item.more.product")
            .search-result-item__body__icon(v-if="shortType !== 'PRODUCTS'")
                i.fa-3x(:class="item.icon")
            .search-result-item__body__date(v-if="item.date") {{item.date}}
</template>

<script>
import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'

export default {
    name: 'OptiSearchResult',
    components: {OptiDepositoryInfo},
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true,
            validator: p => [
                'topbar.search.RECLAMATIONS',
                'topbar.search.RECLAMATIONSALE',
                'topbar.search.COUPONS',
                'topbar.search.ERROR',
                'topbar.TRANSFERS',
                'topbar.SHIPMENTS',
                'topbar.SALES',
                'topbar.SALEOFFSTOCK',
                'topbar.PRODUCTS',
                'topbar.PATIENTS',
                'topbar.LOYALITIES',
                'topbar.INTERVIEW',
                'topbar.TASKS',
                'topbar.TEMPORARYTASKS',
                'topbar.ORDERS',
                'topbar.RETURNS',
                'topbar.REGENERATIONS',
                'topbar.visits.HEADING',
	              'eShop.order.SEARCH',
                'catalog.offer.breadcrumb.OFFER',
                'HIDDEN'
            ].includes(p)
        }
    },
    computed: {
        itemBackgroundColor() {
            let types = {
                'topbar.search.RECLAMATIONS': '#d9534f',
                'topbar.search.RECLAMATIONSALE': '#ff69B4',
                'topbar.search.COUPONS': false,
                'topbar.TRANSFERS': false,
                'topbar.SHIPMENTS': false,
                'topbar.SALES': '#37bc9b',
                'topbar.SALEOFFSTOCK': false,
                'topbar.PRODUCTS': false,
                'topbar.PATIENTS': false,
                'topbar.LOYALITIES': false,
                'topbar.INTERVIEW': false,
                'topbar.TASKS': false,
                'topbar.TEMPORARYTASKS': false,
                'topbar.ORDERS': '#23b7e5',
                'topbar.RETURNS': '#ff902b',
                'topbar.REGENERATIONS': '#9CFF0D',
                'topbar.visits.HEADING': false,
                'catalog.offer.breadcrumb.OFFER': false
            }

            return types[this.item.type] || '#e0e0e0'
        },
        shortType() {
            return this.type.replace('topbar.', '').replace('search.', '').replace('visits.', '')
        },
        itemInfo() {
            switch(this.shortType) {
                case 'PATIENTS':
                case 'LOYALITIES':
                case 'INTERVIEW':
                case 'catalog.offer.breadcrumb.OFFER':
                    return `
                        <div>${this.item.more.address}</div>
                        <div>${this.item.more.phone}</div>`

                case 'TASKS':
                case 'TEMPORARYTASKS':
                    return `
                        <div>${this.item.more.patient}</div>
                        <div>${this.item.more.department}</div>
                    `

                case 'SHIPMENTS':
                case 'TRANSFERS':
                    return `
                        <div>${this.item.more.from} <i class="fa fa-arrow-right"></i> ${this.item.more.to}</div>`

                case 'RECLAMATIONS':
                case 'RECLAMATIONSALE':
                    return ''

                case 'SALES':
                case 'SALEOFFSTOCK':
                    return `
                        <div>${this.item.more.department}</div>
                        <div>${this.item.more.value}</div>
                    `

                case 'RETURNS':
                case 'ORDERS':
                    return `<div>${this.item.more.department}</div>`

                case 'REGENERATIONS':
                    return `<div>${this.item.more.address}</div><div>${this.item.more.product}</div>`

                case 'HEADING': // visits
                    return `<div>${this.item.more.date}</div>`

                case 'COUPONS':
                    return `<div>${this.item.status_name}</div>`

                case 'ERROR':
                    return `<div>${this.item.more.status_name}</div>`

	            case 'eShop.order.SEARCH':
					return `<div>${this.item.more.price}</div><div>${this.item.more.date}</div>`

                case 'PRODUCTS':
                    return ''

                default:
                    return this.item
            }
        },
        itemBodyStyle() {
            if(this.shortType !== 'PRODUCTS') return {}

            return {
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-position': 'right center',
                'background-image': 'url(https://salony.eopti.pl/thumb/product/ids/' + this.item.id + '.jpg)'
            }
        },
    },
    methods: {
        changeState() {
            if (this.type === 'HIDDEN') return;

            this.$router.push({
                name: this.item.link.state,
                params: this.item.link.params
            })
        },
        goToRef(state, params) {
            this.$state.go(state, params)
        }
    }
}
</script>

<style scoped lang="less">
    .search-result-item {
        display: flex;
        min-width: 400px;
        height: 100px;
        margin: 4px;
        flex-basis: 0;
        flex-grow: 1;

        background-color: @panel-bg;
        border: 1px solid silver;
        cursor: pointer;
        padding: 1px;
        border-radius: 3px;

        @media only screen
        and (max-width: 767px) {
            min-width: unset;
        }


        &__info {
            width: 120px;
            color: #2b2b2b;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__type {
                text-align: center;
            }

            &__id {
                font-size: 1.4em;
                font-weight: bold;
            }
        }

        &__body {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex-grow: 1;
            padding: 4px;

            text-shadow: white -1px 0px 1px, white 0px 1px 1px, white 1px 0px 1px, white 0px -1px 1px;

            &__name {
                font-size: 1.4em;
                font-weight: bold;
            }

            &__icon {
                position: absolute;
                top: 0;
                right: 4px;
                bottom: 0;

                display: flex;
                justify-content: center;
                align-items: center;
                color: @text-muted;
                opacity: 0.2;
            }

            &__date {
                position: absolute;
                right: 4px;
                bottom: 4px;

                font-size: 0.8em;
                color: @text-muted;
            }
        }
    }
</style>
