import Vue from 'vue'
import {loadLanguageAsync} from '@/js/app/vue/i18n-setup'
import Vue2Storage from 'vue2-storage'
import AsyncComputed from 'vue-async-computed'
import Vue2TouchEvents from 'vue2-touch-events'
import VuePlugins from './vue.plugins'
import VueDirectives from './vue.directives'
import PortalVue from 'portal-vue'

import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import 'chartjs-plugin-annotation'
import Vuelidate from 'vuelidate'

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
    display: false
})

import installFilters from './vue.filters'
//import {datadogRum} from '@datadog/browser-rum'

installFilters(Vue)

Vue.use(Vue2Storage, {
    prefix: 'eo_',
    driver: 'local',
    ttl: 60 * 60 * 1000 // 1h
})
Vue.use(AsyncComputed)
Vue.use(Vue2TouchEvents)
Vue.use(PortalVue)
Vue.use(Vuelidate)

// if (__env.dd.rum) {
//     datadogRum.init({
//         applicationId: __env.dd.appKey,
//         clientToken: __env.dd.appTok,
//         datacenter: 'eu',
//         sampleRate: 100,
//         trackInteractions: true
//     })
// }

// Pluginy eOpti
Vue.use(VuePlugins)
Vue.use(VueDirectives)

loadLanguageAsync('pl_PL')
