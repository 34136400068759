<template lang="pug">
    .optometrist-letter.text-center
        a.btn.btn-xl.btn-default
            .cover.cover-full(v-show="background === 'r' || background === 'g'"
                :class="{'red': background === 'r', 'green': background === 'g'}")
            .cover.cover-left.red(v-show="background === 'rg'")
            .cover.cover-right.green(v-show="background === 'rg'")
            span(style="display:inline-block" :style="style" v-if="type !== 'kids'") {{text}}
            img.optometrist-letter-kids(style="width:50px" v-if="type === 'kids'" :src="'img/optometrist/screening-test/optotype/kids/' + model + '.png'")
</template>

<script>
    export default {
        name: 'OptiOptometristsSurveysLetter',
        props: {
            type: {
                type: String,
                required: true
            },
            model: {
                type: String | Number,
                required: true
            },
            background: {
                type: String,
                required: false,
                default: ''
            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            style() {
                if(['letter', 'number'].includes(this.type)) {
                    return {}
                }

                let rotate = 'rotate(-' + this.model + 'deg)'

                 return {
                    '-webkit-transform': rotate,
                    '-moz-transform': rotate,
                    '-ms-transform': rotate,
                    '-o-transform': rotate,
                    'transform': rotate
                }
            },
            text() {
                if(['letter', 'number'].includes(this.type)) {
                    return this.model
                }

                return this.type === 'hs' ? 'E' : 'C'
            }
        }
    }
</script>