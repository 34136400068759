export default function validateTask(task, type) {
    let result = [],
        prefix = 'tasks.action.accepted.notify.'

    if(task.type !== 2 && (parseFloat(task.rightEye.pdNear) < 20 || parseFloat(task.rightEye.pdNear) > 40 ||
        task.leftEye.pdNear < 20 || parseFloat(task.leftEye.pdNear) > 40)) {
        result.push(prefix + 'PDNEAR')
    }

    if(task.type !== 1 && (parseFloat(task.rightEye.pdDist) < 20 || parseFloat(task.rightEye.pdDist) > 40 ||
        parseFloat(task.leftEye.pdDist) < 20 || parseFloat(task.leftEye.pdDist) > 40)) {
        result.push(prefix + 'PDDIST')
    }

    if(parseFloat(task.rightEye.bt) < 0 || parseFloat(task.leftEye.bt) > 40) {
        result.push(prefix + 'BT')
    }

    if((task.assemblyId === 27 && !task.leftLens.price) || (task.assemblyId === 29 && !task.rightLens.price) ||
        (task.assemblyId === 41 && (!task.leftLens.price || !task.rightLens.price)) ||
        (task.packId && !task.rightLens.price)) {
        result.push(prefix + 'lenses.PRICE')
    }

    if((parseFloat(task.rightEye.cylinder) !== 0 && task.rightEye.axis === 'brak') ||
        (parseFloat(task.leftEye.cylinder) !== 0 && task.leftEye.axis === 'brak')) {
        result.push(prefix + 'cyl.AXIS')
    }

    if((parseFloat(task.rightEye.cylinder) > 0 && parseFloat(task.leftEye.cylinder) < 0) ||
        (parseFloat(task.rightEye.cylinder) < 0 && parseFloat(task.leftEye.cylinder) > 0)) {
        result.push(prefix + 'cyl.SIGN')
    }

    if(parseFloat(task.rightEye.cylinder) < 0 || parseFloat(task.leftEye.cylinder) < 0) {
        result.push(prefix + 'cyl.MINUS')
    }

    if((task.rightLens.minFi <= 0 || task.leftLens.minFi <= 0) && task.assemblyId !== 31 && task.status === 1) {
        result.push(prefix + 'FI')
    }

    if(!task.frame.type) {
        result.push(prefix + 'FRAMETYPE')
    }

    if(task.status === 1) {
        if(Object.keys(task.frame.allow).length && task.frame.allow.allow === false) {
            result.push(prefix + 'FRAMEALLOW')
        }

        if(task.frame.state === 1 && task.frame.status === 0
            && Object.keys(task.product).length
            && Object.keys(task.product.depository).length
            && !task.product.depository.stock && ![392, 86].includes(task.departmentId)) {
            result.push(prefix + 'FRAMENOSTOCK')
        }

        if(task.marketingErrors.length) {
            result.push(prefix + 'MARKETINGACTIONUNAVAILABLE')
        }

        _.forEach(task.services, service => {
            if(service.id === 2 && (!service.pivot.description || !service.pivot.price)) {
                result.push(prefix + 'SERVICEOTHER')
            }
        })

        if((task.packId === 183 || task.packId === 182) && parseInt(task.covering) === 0) {
            result.push(prefix + 'COLORREQUIRED')
        }

        if(task.expected && moment(task.expected).isBefore(moment().add(1, 'd'))) {
            result.push(prefix + 'EXPECTEDEARLY')
        }

        if(type !== 'hoya' && (('from_task' in task.measurement && task.measurement.from_task) ||
            ('from_task' in task.measurementJawroVisu && task.measurementJawroVisu.from_task))) {
            result.push(prefix + 'TEST_MEASUREMENT')
        }
    }

    if(type === 'hoya') {
        if((task.rightLens.manufacturer && !task.rightLens.manufacturer.toLowerCase().includes('hoya') &&
            !task.rightLens.manufacturer.toLowerCase().includes('klienta')) ||
            (task.leftLens.manufacturer && !task.leftLens.manufacturer.toLowerCase().includes('hoya') &&
                !task.leftLens.manufacturer.toLowerCase().includes('klienta'))) {
            result.push(prefix + 'NOTHOYA')
        }

        let oryginalBt = Math.round(task.frame.height * 50) / 100

        if(oryginalBt === task.rightEye.bt && oryginalBt === task.leftEye.bt) {
            result.push(prefix + 'MANUALBT')
        }
    }

    if(type === 'all') {
        if(!task.expected) {
            result.push(prefix + 'EXPECTED')
        }

        if(parseFloat(task.registry.pricing) < 0) {
            result.push(prefix + 'PRICING')
        }

        if(parseFloat(task.registry.amountToPay) < 0) {
            result.push(prefix + 'AMOUNTTOPAY')
        }

        if(parseFloat(task.registry.restToPay) < 0) {
            result.push(prefix + 'RESTTOPAY')
        }

        if(parseInt(task.packId) === 86 && !parseInt(task.covering)) {
            result.push(prefix + 'SATURATIONPACK')
        }

        if(task.dbl && task.rLensPriceGroup.identifier && !task.manufacturerParam1) {
            result.push(prefix + 'IDENTIFIER')
        }
    }

    return result
}
