<template lang="pug">
    .row
        .col-xs-12(style="padding-top:5px")
            img(style="width:100%" :src="'optometrist/screen/cz/mirror/' + permutation.active")

        .col-xs-12(style="margin-top:30px;padding-right:5px" v-show="!readOnly")
            .btn-group(style="width:100%")
                .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'C'}"
                    @click="$emit('update-cz', 'C')") {{'optometrist.video.cz.RED'|translate}}

                .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'C/Z'}"
                    @click="$emit('update-cz', 'C/Z')") {{'optometrist.video.cz.CZ'|translate}}

                .btn.btn-default.btn-xl(style="width:33%" :class="{'btn-warning active': cz === 'Z'}"
                    @click="$emit('update-cz', 'Z')") {{'optometrist.video.cz.GREEN'|translate}}
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    export default {
        name: 'OptiOptometristsSurveysOptotypesRedGreenMirror',
        props: {
            permutation: {
                type: Object,
                required: true
            },

            cz: {
                type: String,
                required: true
            },

            readOnly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
               watch: {
                   optotypes: null
               }
            }
        },

        activated() {
            this.sendDisplay()

            this.watch.optotypes = this.$watch('optotypes', () => {
                this.sendDisplay()
            })
        },
        deactivated() {
            this.watch.optotypes()
        },

        methods: {
            sendDisplay() {
                OptometristsDisplay.message.open('/optometrist/screen/cz/mirror/' + this.permutation.active)
            }
        },
        computed: {
            optotypes() {
                return this.permutation.active
            }
        }
    }
</script>