<template lang="pug">
    div
        .text-center
            label.control-label.mr3(:class="{'text-normal': mode_quarter}"
                @click="mode_quarter = false") {{'report.forecast.range.mode.WEEK'|translate}}
            label.switch.switch-lg.mr3
                input(type="checkbox" v-model="mode_quarter" @change="changeDate")
                span
            label.control-label(:class="{'text-normal': !mode_quarter}"
                @click="mode_quarter = true") {{'report.forecast.range.mode.QUARTER'|translate}}

        label {{'report.forecast.range.FROM'|translate}}
        OptiSelectSnapshot(
            v-show="!mode_quarter"
            type="from"
            :date="week.from"
            :opposite="week.to"

            @selected="updateDateFrom"
            @weeks="updateSelectWeeks")

        .input-group(v-show="mode_quarter")
            select.form-control(v-model="quarter.from.q" @change="parseDate")
                option(v-for="q in select.quarters.from" :value="q") {{q}}
            span.input-group-addon Q
            select.select-year.form-control(v-model="quarter.from.y" @change="updateSelectQuarters('from')")
                option(v-for="y in select.years" :value="y") {{y}}

        label(style="margin-top:10px") {{'report.forecast.range.TO'|translate}}
        OptiSelectSnapshot(
            v-show="!mode_quarter"
            type="to"
            :date="week.to"
            :opposite="week.from"

            @selected="updateDateTo"
            @weeks="updateSelectWeeks")

        .input-group(v-show="mode_quarter")
            select.form-control(v-model="quarter.to.q" @change="parseDate")
                option(v-for="q in select.quarters.to" :value="q") {{q}}
            span.input-group-addon Q
            select.select-year.form-control(v-model="quarter.to.y" @change="updateSelectQuarters('to')")
                option(v-for="y in select.years" :value="y") {{y}}
</template>

<script>
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiSelectSnapshot from '@/js/app/vue/components/Select/OptiSelectSnapshot'

    export default {
        name: 'OptiSelectQuarterOrSnapshot',
        components: {
            OptiSelectSnapshot
        },
        props: {
            quarter: {
                type: Object,
                required: true
            },
            week: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                mode_quarter: false,

                select: {
                    weeks: null,
                    quarters: {
                        from: _.range(1, BudgetHelper.getQuarterByWeek(moment()) + 1),
                        to: _.range(1, BudgetHelper.getQuarterByWeek(moment()) + 1)
                    },
                    years: null
                },

                loading_count: 0
            }
        },
        beforeMount() {
            this.quarter.from.q = BudgetHelper.getQuarterByWeek(moment())
            this.quarter.from.y = moment().isoWeekYear()

            this.quarter.to.q = BudgetHelper.getQuarterByWeek(moment())
            this.quarter.to.y = moment().isoWeekYear()
        },
        methods: {
            updateSelectQuarters(type, parse_date) {
                this.select.quarters[type] = _.range(1, (this.quarter[type].y === moment().isoWeekYear() ?
                    BudgetHelper.getQuarterByWeek(moment()) : 4) + 1)

                if(!this.select.quarters[type].includes(this.quarter[type].q)) {
                    this.quarter[type].q = this.select.quarters[type][0]
                }

                if(typeof parse_date === 'undefined' || parse_date) {
                    this.parseDate()
                }
            },
            updateIsCurrentDate(date) {
                let _moment = moment(date.start),
                    year = _moment.isoWeekYear(),
                    quarter = BudgetHelper.getQuarterByWeek(_moment),

                    current = {
                        year: moment().isoWeekYear(),
                        quarter: BudgetHelper.getQuarterByWeek(moment())
                    }

                this.week.is_current = year === current.year && quarter === current.quarter
            },
            updateDateFrom(date) {
                this.updateIsCurrentDate(date)

                this.week.from = date
            },
            updateDateTo(date) {
                this.week.to = date
            },
            parseDate() {
                let weeks = {
                    from: {
                        date: this.quarter.from.y + '/' + _.first(BudgetHelper.weeks_by_quarter[this.quarter.from.q]),
                        find: false
                    },
                    to: {
                        date: this.quarter.to.y + '/' + _.last(BudgetHelper.weeks_by_quarter[this.quarter.to.q]),
                        find: false
                    }
                }

                if(weeks.from.date.endsWith('53') && !BudgetHelper.isW53(this.quarter.from.y)) {
                    weeks.from.date = weeks.from.date.slice(0, -2) + '52'
                }

                if(weeks.to.date.endsWith('53') && !BudgetHelper.isW53(this.quarter.to.y)) {
                    weeks.to.date = weeks.to.date.slice(0, -2) + '52'
                }

                this.select.weeks.forEach(years => {
                    years.dates.forEach(week => {
                        if(week.week === weeks.from.date) {
                            this.updateDateFrom(_.cloneDeep(week))
                            weeks.from.find = true
                        }

                        if(week.week === weeks.to.date) {
                            this.updateDateTo(_.cloneDeep(week))
                            weeks.to.find = true
                        }
                    })
                })

                if(!weeks.from.find) {
                    this.updateDateFrom(_.cloneDeep(_.first(_.first(this.select.weeks).dates)))
                }

                if(!weeks.to.find) {
                    this.updateDateTo(_.cloneDeep(_.first(_.first(this.select.weeks).dates)))
                }
            },
            changeDate() {
                if(this.mode_quarter) {
                    ['from', 'to'].forEach(range => {
                        this.quarter[range].y = moment(this.week[range].start).isoWeekYear()
                        this.quarter[range].q = BudgetHelper.getQuarterByWeek(moment(this.week[range].start))

                        this.updateSelectQuarters(range, false)
                    })
                }

                this.parseDate()
            },

            updateSelectWeeks(w) {
                ++this.loading_count

                if(this.loading_count === 1) {
                    this.select.weeks = w
                } else if(this.loading_count === 2) {
                    if(this.mode_quarter) {
                        this.parseDate()
                    }

                    this.select.years = _.range(this.select.weeks[this.select.weeks.length - 1].year, moment().isoWeekYear() + 1)

                    this.$emit('select-weeks-was-loaded')
                }
            }
        }
    }
</script>