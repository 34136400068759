class ValidateInterviewClass {
    checkToPrescription(interview, previouslens, points_covd) {
        let result = [],
            prefix = 'optometrist.validate.prescription.'

        if(!previouslens.allowSave) {
            result.push(prefix + 'PREVIOUSLENSES')
        }

        //autorefraktometr
        if((interview.autorefraktometr.k_r_cyl && interview.autorefraktometr.k_r_cyl !== -999 && interview.autorefraktometr.k_r_axs === 'brak') ||
            (interview.autorefraktometr.k_l_cyl && interview.autorefraktometr.k_l_cyl !== -999 && interview.autorefraktometr.k_l_axs === 'brak')) {
            result.push(prefix + 'KERATOMETRYCYL')
        }

        if((interview.autorefraktometr.r_r_cyl && interview.autorefraktometr.r_r_cyl !== -999 && interview.autorefraktometr.r_r_axs === 'brak') ||
            (interview.autorefraktometr.r_l_cyl && interview.autorefraktometr.r_l_cyl !== -999 && interview.autorefraktometr.r_l_axs === 'brak')) {
            result.push(prefix + 'AUTOREFRAKTOMETRCYL')
        }

        if(interview.autorefraktometr.r_r_es === -999 || interview.autorefraktometr.r_l_es === -999) {
            result.push(prefix + 'ES')
        }

        if(interview.autorefraktometr.r_pd === -999 || interview.autorefraktometr.r_pd < 45) {
            result.push(prefix + 'AUTOREFRAKTOMETRPD')
        }

        //korekcja dal
        if((interview.dist_r_cylinder && interview.dist_r_axis === 'brak') || (interview.dist_l_cylinder && interview.dist_l_axis === 'brak')) {
            result.push(prefix + 'correction.dist.AXS')
        }

        if(interview.dist_r_pd + interview.dist_l_pd < 45) {
            result.push(prefix + 'correction.dist.PD')
        }

        //korekcja bliż
        if((interview.near_r_cylinder && interview.near_r_axis === 'brak') || (interview.near_l_cylinder && interview.near_l_axis === 'brak')) {
            result.push(prefix + 'correction.near.AXS')
        }

        if(interview.near_r_pd + interview.near_l_pd < 45) {
            result.push(prefix + 'correction.near.PD')
        }

        if(interview.near_r_visus === '-' || interview.near_l_visus === '-' || interview.near_binvisus === '-') {
            result.push(prefix + 'correction.near.VISUS')
        }

        if(!interview.rodo) {
            result.push(prefix + 'RODO')
        }

        if(!interview.refused_covd && points_covd === -1) {
            result.push(prefix + 'COVD')
        }

        if(interview._autosave._state !== 'init') {
            result.push(prefix + 'SAVE')
        }

        return result
    }
}

export const ValidateInterview = new ValidateInterviewClass()