<template lang="pug">
    .task-ipad
        h4.page-header(style="margin-top:10px") VisuReal

        .row(v-if="!mode_manual")
            .col-xs-12(style="margin-bottom:10px")
                OptiButton(type="info" :disabled="block || (!!task.measurementId && task.measurement.pass === -1)"
                    @click.native="mode_manual = true") {{'tasks.measurement.individual.form.WRITEMANUALY'|translate}}
                    template(#icon)
                        i.fa.fa-edit

                OptiButton.pull-right(type="info" v-show="pair.measurement_id_1 && pair.measurement_id_2"
                    @click.native="dialog_compare = true") {{'tasks.measurement.individual.compare.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-image

                MountingPortal(v-if="dialog_compare" mountTo="#vue-modal" append)
                    OptiTaskDetailsIpadVisuRealDialogCompareImages(
                        :image1="pair.measurement_id_1"
                        :image2="pair.measurement_id_2"

                        @closed="dialog_compare = false")

            .col-xs-12
                OptiTaskDetailsIpadVisuRealMeasurements(
                    :alerts-range="alerts_range"

                    :task-id="task.id"
                    :is-offer="isOffer"
                    :status="task.status"
                    :department-id="task.departmentId"
                    :measurement-id="task.measurementId"
                    :frame="task.frame"
                    :reload-measurements="task.reload_measurements.visu_real"
                    :timestamp="task.timestamp"

                    :r-sph="task.rightEye.sphere"
                    :l-sph="task.leftEye.sphere"
                    :r-cyl="task.rightEye.cylinder"
                    :l-cyl="task.leftEye.cylinder"

                    :block="block"

                    @update-measurement-visu-real="updateMeasurementVisuReal")

            #alert.col-xs-12(v-show="loading.alerts || (!loading.alerts && alerts.length)")
                .panel.widget.bg-danger
                    .row.row-table
                        .col-xs-3.text-center.bg-danger-dark.pv-lg
                            i.fa.fa-eye-slash.fa-3x
                        .col-xs-9.pv-lg
                            .h4.mt0
                                div(style="padding-bottom:10px") {{'tasks.measurement.notify.CHECK'|translate}}
                                .info(v-for="alert in alerts" :class="{'opacity': hover && alerts_range[hover].indexOf(parseInt(alert[0])) === -1}")
                                    i.fa.fa-exclamation-circle.mr3
                                    span.mr3 {{'tasks.measurement.notify.info.' + alert[0]|translate}}
                                    span(v-html="alert[1]")
                                OptiDimmer(:active="loading.alerts")

            .col-xs-12
                a(v-if="pair.measurement_id_1" :href="'upload/task/visureal/' + pair.measurement_id_1 + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/task/visureal/' + pair.measurement_id_1 + '.jpg?refresh=' + task.timestamp")
                a(style="display:block;margin-top:15px" v-if="pair.measurement_id_2" :href="'upload/task/visureal/' + pair.measurement_id_2 + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/task/visureal/' + pair.measurement_id_2 + '.jpg?refresh=' + task.timestamp")

            .col-xs-12
                .row
                    .col-xs-12.text-right.bold.text-info(v-show="task.measurement.manualy")
                        i.fa.fa-info-circle.mr3
                        span {{'tasks.measurement.MANUALLY'|translate}}

                    .col-lg-8.text-center
                        #measurement1(:class="{'pupil-centre': !task.measurementId || task.measurement.lensType === 'Progresywne' || task.measurement.lensDesign === 'Środek źrenicy'}")
                            #measurement1-img
                            #measurement1-points
                                #right {{'prescription.RIGHT'|translate}}
                                #left {{'prescription.LEFT'|translate}}

                                #rePdDist(:class="{'warn': checkAlerts('pdR')}"
                                    @mouseover="measurementIn('pdR')" @mouseleave="measurementOut") {{task.measurement.pdR|number(1)}}
                                #lePdDist(:class="{'warn': checkAlerts('pdL')}"
                                    @mouseover="measurementIn('pdL')" @mouseleave="measurementOut") {{task.measurement.pdL|number(1)}}

                                #reBt.bt-row(:class="{'warn': checkAlerts('btR')}"
                                    @mouseover="measurementIn('btR')" @mouseleave="measurementOut")
                                    div(:title="btTitle('main', task.measurement, 'r')") {{task.measurement.btR|number(1)}}
                                    .text-muted(v-show="task.measurement.btR !== task.measurement.rawBtR" :title="btTitle('raw', task.measurement, 'r')"
                                        ) {{task.measurement.rawBtR|number(1)}}
                                #leBt.bt-row(:class="{'warn': checkAlerts('btL')}"
                                    @mouseover="measurementIn('btL')" @mouseleave="measurementOut")
                                    div(:title="btTitle('main', task.measurement, 'l')") {{task.measurement.btL|number(1)}}
                                    .text-muted(v-show="task.measurement.btL !== task.measurement.rawBtL" :title="btTitle('raw', task.measurement, 'l')"
                                    ) {{task.measurement.rawBtL|number(1)}}

                                #heightR(:class="{'warn': checkAlerts('frameB')}"
                                    @mouseover="measurementIn('frameB')" @mouseleave="measurementOut") {{task.measurement.frameB|number(2)}}
                                #heightL(:class="{'warn': checkAlerts('frameB')}"
                                    @mouseover="measurementIn('frameB')" @mouseleave="measurementOut") {{task.measurement.frameB|number(2)}}

                                #widthR(:class="{'warn': checkAlerts('frameA')}"
                                    @mouseover="measurementIn('frameA')" @mouseleave="measurementOut") {{task.measurement.frameA|number(2)}}
                                #widthL(:class="{'warn': checkAlerts('frameA')}"
                                    @mouseover="measurementIn('frameA')" @mouseleave="measurementOut") {{task.measurement.frameA|number(2)}}

                                #vboxR.oma-measurement(v-show="task.frame.height !== 0") {{task.frame.height|number(2)}}
                                #vboxL.oma-measurement(v-show="task.frame.height !== 0") {{task.frame.height|number(2)}}

                                #hboxR.oma-measurement(v-show="task.frame.width !== 0") {{task.frame.width|number(2)}}
                                #hboxL.oma-measurement(v-show="task.frame.width !== 0") {{task.frame.width|number(2)}}

                                #dbl {{task.measurement.dbl|number(2)}}
                    .col-lg-4.text-center
                        #measurement2(:class="task.measurement.side")
                            #measurement2-img
                            #measurement2-points
                                #wpa(:class="{'warn': checkAlerts('wpa')}") {{task.measurement.wpa|number(2)}}
                                #cvd
                                    div(:class="{'warn': checkAlerts('cvd')}"
                                        @mouseover="measurementIn('cvd')" @mouseleave="measurementOut")
                                        span.mr3(v-show="task.measurement.cvd !== task.measurement.cvdLeft") {{'prescription.RIGHT'|translate}}
                                        span {{task.measurement.cvd|number(1)}}

                                    div(v-show="task.measurement.cvd !== task.measurement.cvdLeft"
                                        :class="{'warn': checkAlerts('cvdLeft')}"
                                        @mouseover="measurementIn('cvdLeft')" @mouseleave="measurementOut")
                                        span.mr3 {{'prescription.LEFT'|translate}}
                                        span {{task.measurement.cvdLeft|number(1)}}

            .col-xs-12
                form#formiPad.form-validate(name="formiPad")
                    .row.text-center.border-top-separate.border-bottom-separate(
                        style="padding:10px 0;border-top-width:1px;border-bottom-width:1px"
                        v-show="task.measurement.measurementId")
                        .col-md-4
                            b.mr3 {{'tasks.ipad.TYPE'|translate}}
                            span {{task.measurement.lensType}}
                        .col-md-4(v-show="task.measurement.lensType === 'Jednoogniskowe'")
                            b.mr3 {{'tasks.ipad.CONSTRUCT'|translate}}
                            span {{task.measurement.lensDesign}}
                        .col-md-4(v-show="task.measurement.lensType === 'Progresywne'")
                            b.mr3 {{'tasks.ipad.CORRIDOR'|translate}}
                            span {{task.measurement.corridor}}mm
                        .col-md-4
                            b.mr3 {{'tasks.ipad.FRAMETYPE'|translate}}
                            span {{task.measurement.frameType}}
                    .row
                        .col-md-6(style="padding-left:0;padding-right:0")
                            .table-responsive
                                table.table.table-presenter.table-striped
                                    thead
                                        tr.separate-bottom
                                            th
                                            th {{'prescription.RIGHT'|translate}}
                                            th {{'prescription.LEFT'|translate}}
                                    tbody
                                        tr
                                            td(colspan="3" style="padding:0;border-top:0")
                                                OptiPdCalculate(
                                                    :measurement="task.measurement"
                                                    :task-type="task.type"

                                                    :disabled="block"
                                                    :pending="pending"

                                                    :show-pd-dist="task.type !== 1"
                                                    :pd-dist="task.pdDist"
                                                    :re-pd-dist="task.rightEye.pdDist"
                                                    :le-pd-dist="task.leftEye.pdDist"

                                                    :show-pd-near="task.type !== 2"
                                                    :pd-near="task.pdNear"
                                                    :re-pd-near="task.rightEye.pdNear"
                                                    :le-pd-near="task.leftEye.pdNear"

                                                    :show-bt="true"
                                                    :re-bt="task.rightEye.bt"
                                                    :le-bt="task.leftEye.bt"

                                                    @updated="updatePd")

                        .col-md-6(style="padding-left:0;padding-right:0" v-show="task.measurementId")
                            .table-responsive
                                table.table.table-presenter.table-striped
                                    tr
                                        td(colspan="3" style="padding:0;border-top:0")
                                            table.table.table-presenter.table-striped
                                                thead.hidden-xs.hidden-sm
                                                    tr
                                                        td(colspan="4") &nbsp;
                                                tbody
                                                    tr.separate-top
                                                        th {{'tasks.frame.HEIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.frame.height|number(1)}}
                                                        th(style="text-align:right !important") {{'tasks.frame.WIDTH'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.frame.width|number(1)}}
                                                    tr
                                                        th {{'tasks.INCLINATION'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.inclination|number(1)}}
                                                        th(style="text-align:right !important")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub(v-show="task.cvd !== task.cvdLeft") {{'prescription.RIGHT'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.cvd|number(1)}}
                                                    tr
                                                        th {{'tasks.FFFA'|translate}}
                                                        td
                                                            .form-control.text-center(disabled) {{task.fffa|number(1)}}
                                                        th(style="text-align:right !important" v-show="task.cvd !== task.cvdLeft")
                                                            span {{'tasks.CVD'|translate}}
                                                            sub {{'prescription.LEFT'|translate}}
                                                        td(v-show="task.cvd !== task.cvdLeft")
                                                            .form-control.text-center(disabled) {{task.cvdLeft|number(1)}}
                                                        td(colspan="2" v-show="task.cvd === task.cvdLeft")

        OptiTaskDetailsIpadVisuRealForm(v-else
            :task-id="task.id"
            :type="task.type"
            :frame-type="task.frame.type"
            :is-offer="isOffer"

            :block="block"
            :pending="pending"

            @back="mode_manual = false")

        OptiTaskDetailsIpadVisuRealPrtScr(
            :task-id="task.id"
            :department-task-id="task.departmentTaskId"
            :status="task.status"

            :task-duplicate="taskDuplicate"
            :task-service="taskService")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {TaskMeasurementTitle} from '@/js/app/vue/components/Tasks/TaskMeasurementTitle'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsIpadVisuRealDialogCompareImages
        from '@/js/app/vue/components/Tasks/Details/Ipad/VisuReal/Dialogs/OptiTaskDetailsIpadVisuRealDialogCompareImages'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPdCalculate from '@/js/app/vue/components/Inputs/OptiPdCalculate'
    import OptiTaskDetailsIpadVisuRealMeasurements
        from '@/js/app/vue/components/Tasks/Details/Ipad/VisuReal/OptiTaskDetailsIpadVisuRealMeasurements'
    import OptiTaskDetailsIpadVisuRealForm from '@/js/app/vue/components/Tasks/Details/Ipad/VisuReal/OptiTaskDetailsIpadVisuRealForm'
    import OptiTaskDetailsIpadVisuRealPrtScr from '@/js/app/vue/components/Tasks/Details/Ipad/VisuReal/OptiTaskDetailsIpadVisuRealPrtScr'

    export default {
        name: 'OptiTaskDetailsIpadVisuReal',
        components: {
            OptiButton,
            OptiTaskDetailsIpadVisuRealDialogCompareImages,
            OptiDimmer,
            OptiPdCalculate,
            OptiTaskDetailsIpadVisuRealMeasurements,
            OptiTaskDetailsIpadVisuRealForm,
            OptiTaskDetailsIpadVisuRealPrtScr
        },
        props: {
            task: {
                type: Object,
                required: true
            },

            isOffer: {
                type: Boolean,
                required: true
            },

            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                mode_manual: false,

                hover: null,
                alerts_range: {
                    pdR: [-21, -23],
                    pdL: [-22, -24],
                    btR: [-14, -16],
                    btL: [-15, -17],
                    frameB: [-31, -34],
                    frameA: [-32, -35],
                    wpa: [-3, -13],
                    cvd: [-1, -11],
                    cvdLeft: [-6, -11],
                    fffa: [-2, -12]
                },
                alerts: [],

                pair: {
                    measurement_id_1: 0,
                    measurement_id_2: 0
                },

                dialog_compare: false,
                loading: {
                    alerts: true
                }
            }
        },
        watch: {
            pending(val) {
                if(!val) {
                    this.loadAlerts()
                }
            }
        },
        mounted() {
            this.loadAlerts()
        },
        methods: {
            loadAlerts() {
                this.loading.alerts = true

                this.alerts = []

                API.post('task/check', {
                    task: this.task,
                    is_offer: this.isOffer
                }).then(res => {
                    this.alerts = res.data
                }).finally(() => {
                    this.loading.alerts = false
                }).catch(() => {
                    this.$notify.error('tasks.measurement.notify.error.checkMeasurement')
                })
            },

            checkAlerts(key) {
                let data = this.alerts_range[key],
                    result = []

                this.alerts.forEach(a => {
                    if(data.includes(parseInt(a[0]))) {
                        result.push(a)
                    }
                })

                return result.length ? result : false
            },

            measurementIn(key) {
                if(this.hover === key) {
                    this.hover = null
                } else if(this.checkAlerts(key)) {
                    this.hover = key
                }
            },
            measurementOut() {
                this.hover = null
            },

            updateMeasurementVisuReal({measurement_id, pair}) {
                if(!this.block && this.task.measurementId !== measurement_id) {
                    this.task.measurementId = measurement_id

                    this.$emit('ipad-visu-real-was-updated')
                }

                this.pair.measurement_id_1 = pair[0]
                this.pair.measurement_id_2 = pair[1]
            },
            updatePd(changed) {
                _.forEach(changed, (value, key) => {
                    if(key.startsWith('re')) {
                        this.task.rightEye[key[2].toLowerCase() + key.substring(3)] = value
                    } else if(key.startsWith('le')) {
                        this.task.leftEye[key[2].toLowerCase() + key.substring(3)] = value
                    } else {
                        this.task[key] = value
                    }
                })

                this.$emit('ipad-visu-real-was-updated')
            },

            btTitle(type, measurement, side) {
                return TaskMeasurementTitle.title('VisuReal', type, measurement, side)
            }
        }
    }
</script>
