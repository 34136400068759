import 'core-js'
import 'regenerator-runtime/runtime'

import 'jquery'
import 'angular'
import '../../../public/js/websocket'

import { App, run } from './app.init'
import eOpti from './eopti.init'
import 'pusher-js'
import Fingerprint2 from 'fingerprintjs2'
window.Fingerprint2 = Fingerprint2

window.App = App
window.eOpti = eOpti

import './__init.old'
import './vue.init'

run(eOpti)
