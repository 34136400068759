import angular from 'angular'
import 'ngVue'
import 'ngVue/build/plugins'

import registerVueDirectives from './ngVueDirectives'
import registerAngularDEV from '../_dev/dev.index'
import {Utilities} from './services/Utilities'
import {i18n} from './../vue/i18n-setup'
import store from './../vue/store'
import {EventBus} from '@/js/app/_bridge/EventBus'

import Vue from 'vue'
import 'ng-vue-uirouter'
import {UAC} from '@/js/app/vue/UserAccessControl'

const ngVueComponentsModule = angular.module('ngVueComponents', ['ngVue', 'ngVue.plugins', 'ngVueUIRouter'])

ngVueComponentsModule.config(['$ngVueProvider', ($ngVueProvider) => {
    // zarejestrowanie globalnych pluginów vue tak żeby były dostępne dla komponentów vue w ngVue
    $ngVueProvider.setRootVueInstanceProps({
        i18n: i18n,
        store: store
    })
}])
ngVueComponentsModule.run(['$ngVueUIRouter', ($ngVueUIRouter) => {
    $ngVueUIRouter.install(Vue)
}])

// rejestracja vuex tak żeby był dostępny jako service w angularze
ngVueComponentsModule.factory('VuexStore', [() => store])

ngVueComponentsModule.factory('EventBus', [() => EventBus])

ngVueComponentsModule.factory('uac', [() => UAC])

registerVueDirectives(ngVueComponentsModule)
registerAngularDEV(ngVueComponentsModule)

ngVueComponentsModule.service('Utilities', Utilities)

export default ngVueComponentsModule
