'<template lang="pug">
    form#cart-order(name="cartOrder")

        #cart-order-product.row(v-show="edit")
            .col-xs-6
                OptiSelectProduct(:product="order.productIdBarcode" :auto-select="true" @selected="updateProduct")

                MountingPortal(v-if="product" mountTo="#vue-modal" append)
                    OptiCartDialogProductConfirm(type="order" :product="product"
                        @confirmed="addProduct" @closed="product = null")
            .col-xs-6.text-right
                OptiButton(type="info" :disabled="disabled" :text="warning ? 'carts.confirm.goorderwarning' : 'carts.confirm.goorder'"
                    @confirmed="goOrder") {{'carts.sale.GOORDER'|translate}}
                    template(#icon)
                        i.fa.fa-truck

        OptiCartOrderProblems(v-if="order.problems && order.problems.length" :problems="order.problems")

        #cart-order-table.row
            .col-xs-12
                .panel.panel-info
                    .panel-heading
                        i.fa.fa-truck.mr3
                        span {{'carts.type.fullname.ORDER'|translate}}

                    .table-responsive
                        table.table
                            thead
                                tr
                                    th(v-show="edit")
                                        .checkbox.c-checkbox.checkbox-info
                                            label
                                                input(type="checkbox" v-model="checkbox_all")
                                                span.fa.fa-check
                                    th #
                                    th {{'carts.PRODUCTID'|translate}}
                                    th {{'carts.NAME'|translate}}
                                    th {{'carts.PRICE'|translate}}
                                    th {{'carts.QUANTITY'|translate}}
                                    th {{'carts.VAT'|translate}}
                                    th(v-show="edit")

                            tbody
                                template(v-for="(item, key) in order.items")
                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td(v-show="edit")
                                            .checkbox.c-checkbox.checkbox-info
                                                label
                                                    input(type="checkbox" :checked="item.checkbox"
                                                        @change="e => updateCheckbox(key, e)")
                                                    span.fa.fa-check
                                        td {{order.items.length - key}}
                                        td.text-right
                                            OptiProduct(:product="item.product") {{item.product.id}}
                                        td
                                            OptiProduct(:product="item.product") {{item.product.name}}
                                            .label.label-success.pull-right(v-show="item.highlight") {{'carts.NEW'|translate}}
                                            small(style="display:block") {{item.product.fullName}}
                                        td.text-right {{item.product.price|currency}}
                                        td.col-xs-1.text-right
                                            OptiNumber(v-show="edit" :header="'carts.QUANTITY'|translate" :required="true"
                                                :value="item.quantity" @confirmed="quantity => updateQuantity(key, quantity)"
                                                ) {{item.quantity}}
                                            span(v-show="!edit") {{item.quantity}}
                                        td.text-right {{item.product.price * item.quantity|currency}}
                                        td.text-center(v-show="edit")
                                            label.btn.btn-danger(:for="'cart-order-remove-' + key")
                                                i.fa.fa-fw.fa-remove
                                            OptiButton.hide(:id="'cart-order-remove-' + key" text="carts.confirm.remove"
                                                @confirmed="deleteItems([item.id])")

                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td.b0(:colspan="edit ? 2 : 1" v-show="edit")
                                        td.b0(colspan="3")
                                            .warn(v-show="item.quantity > item.product.quantity")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.nostock.CENTRAL'|translate}}
                                            .warn(v-show="item.warning")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.order.WARNING'|translate}}
                                            .warn(v-show="item.error")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.order.ERROR'|translate}}
                                        td.b0(colspan="2" v-show="edit")
                                        td.b0.text-center
                                            OptiAutoSave(endpoint="carts/update" fields="quantity,discount,comments"
                                                :model="item" :key="item.id" @saved="getWarningError")

                                tr(v-show="!order.items.length")
                                    td.text-center.text-muted(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'default.table.EMPTY'|translate}}

                            tfoot
                                tr.align-middle
                                    td(colspan="4" v-show="edit")
                                        OptiButton(type="danger" :disabled="order.disabled_btn_delete"
                                            text="carts.confirm.remove.all" @confirmed="deleteItemsFromCheckbox"
                                            ) {{'carts.DELETEITEMS'|translate}}
                                            template(#icon)
                                                i.fa.fa-remove
                                    td(colspan="3" v-show="!edit")

                                    th.text-right {{'carts.TOTALS'|translate}}
                                    th.text-right {{order.totals.quantity}}
                                    th.text-right {{order.totals.value|currency}}
                                    td(v-show="edit")

</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiCartDialogProductConfirm from '@/js/app/vue/components/Carts/Dialogs/OptiCartDialogProductConfirm'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiCartOrderProblems from '@/js/app/vue/components/Carts/OptiCartOrderProblems'

    export default {
        name: 'OptiCartOrder',
        components: {
            OptiButton,
            OptiSelectProduct,
            OptiCartDialogProductConfirm,
            OptiNumber,
            OptiProduct,
            OptiAutoSave,
            OptiCartOrderProblems
        },
        props: {
            order: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('carts.order.edit'),

                product: null
            }
        },
        mounted() {

        },
        methods: {
            updateProduct(product) {
                this.product = product
            },
            addProduct() {
                this.$store.dispatch('carts/addProduct', {
                    type: 'order',
                    product: this.product
                })

                this.product = null
            },

            updateQuantity(key, quantity) {
                this.$store.dispatch('carts/updateQuantity', {
                    type: 'order',
                    key: key,
                    quantity: quantity
                })
            },

            updateCheckbox(key, event) {
                this.$store.dispatch('carts/updateCheckbox', {
                    type: 'order',
                    key: key,
                    bool: event.target.checked
                })
            },

            deleteItems(item_ids) {
                this.$store.dispatch('carts/deleteItems', {
                    type: 'order',
                    item_ids: item_ids
                })
            },
            deleteItemsFromCheckbox() {
                this.$store.dispatch('carts/deleteItemsFromCheckbox', 'order')
            },

            getWarningError(item) {
                this.$store.dispatch('carts/updateWarningError', item)
            },

            goOrder() {
                this.$emit('toggled')
            }
        },
        computed: {
            disabled() {
                return !!(this.$store.getters['carts/getDisabled']('order',
                    typeof this.$uac.user.activeRole !== 'undefined' ? this.$uac.user.activeRole.admin : false) ||
                    this.$store.getters['carts/getOrderAlert']('error'))
            },
            warning() {
                return this.$store.getters['carts/getOrderAlert']('warning')
            },
            checkbox_all: {
                get() {
                    return this.$store.getters['carts/getCheckboxAll']('order')
                },
                set(value) {
                    this.$store.dispatch('carts/updateCheckboxAll', {
                        type: 'order',
                        bool: value
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    #cart-order-product,
    #cart-order-problem {
        margin-bottom: 10px;
    }
</style>