<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:10px")
            OptiButton(type="info" :disabled="block" @click.native="$emit('back')"
                ) {{'tasks.measurement.individual.form.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButtonSave.pull-right(:disabled="block" @click.native="save")

        .col-xs-12
            form#formManualyMeasurement.form-validate.row(name="formMeasurement")
                .col-md-6(style="padding-left:0;padding-right:0")
                    .table-responsive
                        table.table.table-presenter.table-striped
                            thead
                                tr.separate-bottom
                                    th
                                    th {{'prescription.RIGHT'|translate}}
                                    th {{'prescription.LEFT'|translate}}
                            tbody
                                tr
                                    td(colspan="3" style="padding:0;border-top:0")
                                        OptiPdCalculate(
                                            :disabled="block"
                                            :pending="pending"

                                            :show-pd-dist="true"
                                            :pd-dist="form.pdDist"
                                            :re-pd-dist="form.rePdDist"
                                            :le-pd-dist="form.lePdDist"

                                            :show-pd-near="false"
                                            :pd-near="form.pdNear"
                                            :re-pd-near="form.rePdNear"
                                            :le-pd-near="form.lePdNear"

                                            :show-bt="true"
                                            :re-bt="form.reBt"
                                            :le-bt="form.leBt"

                                            @updated="updatePd")
                .col-md-6(style="padding-left:0;padding-right:0")
                    .table-responsive
                        table.table.table-presenter.table-striped
                            tr
                                td(colspan="3" style="padding:0;border-top:0")
                                    table.table.table-presenter.table-striped
                                        thead.hidden-xs.hidden-sm
                                            tr
                                                td(colspan="4") &nbsp;
                                        tbody
                                            tr.separate-top
                                                th {{'tasks.frame.HEIGHT'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.frame.HEIGHT'|translate"
                                                        :value="form.frameB" @confirmed="v => form.frameB = v"
                                                        :float="true" :pending="pending") {{form.frameB|number(1)}}
                                                th(style="text-align:right !important") {{'tasks.frame.WIDTH'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.frame.WIDTH'|translate"
                                                        :value="form.frameA" @confirmed="v => form.frameA = v"
                                                        :float="true" :pending="pending") {{form.frameA|number(1)}}
                                            tr
                                                th {{'tasks.INCLINATION'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.INCLINATION'|translate"
                                                        :value="form.wpa" @confirmed="v => form.wpa = v"
                                                        :float="true" :pending="pending") {{form.wpa|number(1)}}
                                                th(style="text-align:right !important")
                                                    span {{'tasks.CVD'|translate}}
                                                    sub {{'prescription.RIGHT'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.CVD_R'|translate"
                                                        :value="form.cvd" @confirmed="v => form.cvd = v"
                                                        :float="true" :pending="pending") {{form.cvd|number(1)}}
                                            tr
                                                th {{'tasks.FFFA'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.FFFA'|translate"
                                                        :value="form.fffa" @confirmed="v => form.fffa = v"
                                                        :float="true" :pending="pending") {{form.fffa|number(1)}}
                                                th(style="text-align:right !important")
                                                    span {{'tasks.CVD'|translate}}
                                                    sub {{'prescription.LEFT'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.CVD_L'|translate"
                                                        :value="form.cvdLeft" @confirmed="v => form.cvdLeft = v"
                                                        :float="true" :pending="pending") {{form.cvdLeft|number(1)}}
                                            tr
                                                th(style="text-align:right !important") {{'tasks.measurement.individual.form.DBL'|translate}}
                                                td
                                                    OptiNumber.text-center(:header="'tasks.measurement.individual.form.DBL'|translate"
                                                        :value="form.dbl" @confirmed="v => form.dbl = v"
                                                        :float="true" :pending="pending") {{form.dbl|number(1)}}
                                                th(style="text-align:right !important") {{'tasks.measurement.individual.table.thead.SIDE'|translate}}
                                                td
                                                    select.form-control(v-model="form.side")
                                                        option(v-for="s in side" :value="s.key") {{s.name|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiPdCalculate from '@/js/app/vue/components/Inputs/OptiPdCalculate'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsIpadVisuRealForm',
        components: {
            OptiButton,
            OptiButtonSave,
            OptiPdCalculate,
            OptiNumber,
            OptiDimmer
        },
        props: {
            taskId: {
                type: Number,
                required: true
            },
            type: {
                type: Number,
                required: true
            },
            frameType: {
                type: String,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                form: {
                    isOffer: this.isOffer,

                    pdDist: 0,
                    rePdDist: 0,
                    lePdDist: 0,

                    pdNear: 0,
                    lePdNear: 0,
                    rePdNear: 0,

                    reBt: 0,
                    leBt: 0,

                    frameA: 0,
                    frameB: 0,

                    wpa: 0,
                    cvd: 0,
                    cvdLeft: 0,
                    fffa: 0,
                    dbl: 0,

                    side: 'right'
                },

                side: [{
                    key: 'right',
                    name: 'prescription.RIGHT'
                }, {
                    key: 'left',
                    name: 'prescription.LEFT'
                }],

                loading: false
            }
        },
        methods: {
            updatePd(changed) {
                _.forEach(changed, (value, key) => {
                    this.form[key] = value
                })
            },

            save() {
                this.loading = true

                this.form.task_id = this.taskId
                this.form.type = this.type
                this.form.frame_type = 'Nylon'

                API.post('task/visu-real/measurement/save', this.form).then(() => {
                    this.$notify.success('tasks.measurement.individual.notify.SAVE')

                    this.$emit('back')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.measurement.individual.notify.error.SAVEMEASUREMENT')
                })
            }
        }
    }
</script>
