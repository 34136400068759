<template lang="pug">
    .row
        .col-xs-12
            .row(style="margin-bottom:10px")
                .col-xs-9
                    h4.filter-header.page-header {{'catalog.offer.choice.heading.PATIENT'|translate}}

                .col-xs-3.text-right
                    OptiButton(@click.native="$store.commit('offer/setMode', 'filters')") {{'catalog.offer.switch.FILTERS'|translate}}
                        template(#icon)
                            i.fa.fa-filter

            .row
                .col-sm-6(style="margin-bottom:15px")
                    OptiSelectPatient(style="margin-bottom:10px" id="patient" :init-result="patient"
                        @selected="p => $store.commit('offer/setPatient', p)")

                    .text-center
                        OptiButton(@click.native="$state.go('app.patient.find')") {{'catalog.offer.choice.FIND_OR_CREATE_PATIENT'|translate}}
                            template(#icon)
                                i.fa.fa-search

                .col-sm-6.text-center(style="margin-bottom:15px")
                    OptiButton(type="success" :disabled="disabled_confirm_choice"
                        @click.native="$store.commit('offer/setMode', 'photos')") {{'catalog.offer.choice.CONFIRM'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

            OptiCatalogOfferChoiceProducts

            .row
                .col-xs-12(style="margin-top:10px")
                    OptiButton(@click.native="$store.commit('offer/setMode', 'filters')"
                        ) {{'catalog.offer.choice.navi.BACK_TO_FILTERS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton.pull-right(type="success" :disabled="!!chose_products.filter(p => p.choice_by_client).length"
                        @click.native="$store.commit('offer/setDialog', {type: 'by_client', bool: true})"
                        ) {{'catalog.offer.choice.byClient.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-plus
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiCatalogOfferChoiceProducts
        from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferChoiceProducts'

    export default {
        name: 'OptiCatalogOfferChoice',
        components: {
            OptiSelectPatient,
            OptiButton,
            OptiCatalogOfferChoiceProducts
        },
        computed: {
            patient() {
                return this.$store.getters['offer/getPatient']
            },

            chose_products() {
                return this.$store.getters['offer/getChoseProducts']
            },
            disabled_confirm_choice() {
                return this.$store.getters['offer/disabledConfirmChoice']
            }
        }
    }
</script>

<style lang="less" scoped>
    .product > .text-center > .btn {
        position: relative;
        z-index: 99;

        margin-top: -12px;

        &.btn-with-color {
            margin-top: 15px;
        }
    }
</style>