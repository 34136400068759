<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.breadcrumb.CREATE'|translate}}

                .panel-body
                    .form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label(for="department-id") {{'specification.create.DEPARTMENT'|translate}}
                            .col-sm-8
                                OptiSelectDepartment#department-id(
                                    :department-id="form.departmentId"
                                    :reduce="department => department.id"

                                    @selected="d => form.departmentId = d")
                        .form-group
                            label.col-sm-4.control-label(for="date_from") {{'specification.create.FROM'|translate}}
                            .col-sm-8
                                OptiDate#date_from(:value="form.date_from" @update-date="d => form.date_from = d")
                        .form-group
                            label.col-sm-4.control-label(for="date_to") {{'specification.create.TO'|translate}}
                            .col-sm-8
                                OptiDate#date_to(:value="form.date_to" @update-date="d => form.date_to = d")
                        .form-group
                            .col-sm-8.col-sm-offset-4
                                .row
                                    .col-xs-6
                                        label(for="year") {{'specification.create.YEAR'|translate}}
                                        select#year.form-control(name="year" v-model="year" @change="clearDate")
                                            option(v-for="year in select.years" :value="year") {{year}}
                                    .col-xs-6
                                        label(for="month") {{'specification.create.MONTH'|translate}}
                                        select#month.form-control(name="month" v-model="month" :disabled="!year" @change="updateDate")
                                            option(v-for="month in select.months" :value="month") {{month}}
                        .form-group
                            label.col-sm-4.control-label(for="task_status") Status
                            .col-sm-8
                                select#task_status.form-control(name="taskStatus" v-model="form.taskStatus")
                                    option(v-for="status in select.task_statuses" :value="status.id") {{status.name|translate}}

                        .form-group
                            .col-xs-12
                                button.btn.btn-default.pull-left(:disabled="!form.departmentId"
                                    @click="getAllTasks") {{'specification.table.tasks.NOSPECIFICATION'|translate}}

                                button.btn.btn-default.pull-right(
                                    :disabled="!form.date_from || !form.date_to || !form.departmentId"
                                    @click="getTasks") {{'specification.table.tasks.PREVIEW'|translate}}

                .panel-footer.text-right
                    OptiButton(type="success" :disabled="!tasks.length" text="specification.table.tasks.create.confirm"
                        @confirmed="create") {{'specification.table.tasks.create.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-save

                OptiDimmer(:active="loading")

        .col-xs-12
            .table-responsive
                table.table.table-striped.middle
                    thead
                        tr
                            th.text-right #
                            th.text-right {{'tasks.TASKID'|translate}}
                            th {{'tasks.date.STARTED'|translate}}
                            th {{'tasks.date.FINISHED'|translate}}
                            th {{'tasks.FRAMENAME'|translate}}
                            th {{'tasks.RL'|translate}}
                            th {{'tasks.LL'|translate}}
                            th {{'tasks.SERVICE'|translate}}
                            th {{'specification.create.EXTRAS'|translate}}

                    tbody
                        tr(v-for="(task, index) in tasks")
                            td.text-right {{index + 1}}
                            td.text-right
                                a.link(:href="$state.href('app.task.detail', {id: task.TASK_ID})" target="_blank") {{task.TASK_ID}}
                            td {{task.START_DATE}}
                            td {{task.END_DATE}}
                            td {{task.FRAME_PART_NAME}}
                            td {{task.RL_PART_NAME}}
                            td {{task.LL_PART_NAME}}
                            td {{task.ASSEMBLY_NAME}}
                            td
                                div(v-for="extra in task.extras") {{extra.name}}

                    tfoot(v-show="!tasks.length")
                        tr
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: "OptiTaskSpecificationCreate",
        components: {
            OptiSelectDepartment,
            OptiDate,
            OptiButton,
            OptiDimmer
        },
        data() {
            let prefix_status = 'tasks.status.'

            return {
                select: {
                    years: _.range(moment().isoWeekYear() - 4, moment().isoWeekYear() + 1),
                    months: _.range(0, 11 + 1).map((_,i) => moment().month(i).format('MMMM')),
                    task_statuses: [{
                        id: 2,
                        name: prefix_status + 'ACCEPTED'
                    }, {
                        id: 8,
                        name: prefix_status + 'SENT'
                    }, {
                        id: 10,
                        name: prefix_status + 'DONE'
                    }, {
                        id: 0,
                        name: prefix_status + 'CANCELLED'
                    }]
                },

                loading: false,
                tasks: [],

                form: {
                    departmentId: null,
                    date_from: '',
                    date_to: '',
                    taskStatus: 2
                },

                month: null,
                year: null
            }
        },
        methods: {
            getAllTasks() {
                this.loading = true
                this.tasks = []

                API.post('specifications/tasks/getAllTasks', {
                    departmentId: this.form.departmentId,
                    taskStatus: this.form.taskStatus
                }).then(res => {
                    this.tasks = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('specification.table.tasks.notify.error.LIST')
                })
            },
            getTasks() {
                this.loading = true
                this.tasks = []

                API.post('specifications/tasks/getTasks', this.form).then(res => {
                    this.tasks = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('specification.table.tasks.notify.error.LIST')
                })
            },

            create() {
                this.loading = true

                API.post('specifications/tasks/saveSpecification', {
                    departmentId: this.form.departmentId,
                    tasks: this.tasks
                }).then(res => {
                    this.$state.go('app.specifications.tasksDetails', {
                        id: res.data
                    })

                    this.$notify.success('specification.table.tasks.notify.ADD')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('specification.table.tasks.notify.error.ADD')
                })
            },

            updateDate() {
                let month = this.month,
                    year = this.year,
                    date = moment().year(year).month(month).format('YYYY-MM-DD')

                this.form.date_from = moment(date).startOf('month').format('YYYY-MM-DD')
                this.form.date_to = moment(date).endOf('month').format('YYYY-MM-DD')
            },
            clearDate() {
                this.form.date_from = ''
                this.form.date_to = ''
                this.month = null
            }
        }
    }
</script>