import {UAC} from './app/vue/UserAccessControl'
import Broadcast from './app/vue/Broadcast'
import Notify from './app/vue/helpers/Notify'
import Logger from './app/vue/helpers/Logger'

export default {
    install(vueInstance, options) {
        vueInstance.prototype.$uac = UAC
        vueInstance.prototype.$echo = Broadcast
        vueInstance.prototype.$notify = Notify
        vueInstance.prototype.$logger = Logger

        vueInstance.prototype.$filters = vueInstance.options.filters
    }
}
