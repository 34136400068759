<template lang="pug">
    #category-tree(style="position:relative")
        .row(v-show="!loading")
            .row
                .col-xs-12.text-center(style="padding-bottom:15px")
                    OptiSwitchToggleButton(:checked="type" @input="toggleType")
                        template(#left) {{'catalog.edit.type.DEPARTMENT'|translate}}
                        template(#right) {{'catalog.edit.type.WAREHOUSE'|translate}}
            .col-sm-6
                #search.input-group
                    input.form-control(:placeholder="'catalog.edit.search.PLACEHOLDER'|translate"
                        v-model.lazy="filter.search" @change="changedSearch")
                    .input-group-addon
                        i.fa.fa-search

                label
                    .checkbox.c-checkbox.checkbox-primary.inline
                        label
                            input(type="checkbox" name="lock" v-model="filter.lock" @change="changedLock")
                            span.fa.fa-check
                    span {{'catalog.edit.search.LOCK'|translate}}

                ul#ul-main
                    OptiCatalogCategoryTreeList(
                        v-for="category in filteredCategories"
                        :key="category.id"

                        :category="category"
                        :selected="selected"
                        :count="count"

                        :lock="filter.lock"

                        @selected="selectedWasUpdated")

            .col-sm-6
                OptiCatalogCategoryFormEdit(
                    v-if="selected.id"

                    :type="type"
                    :selected="selected"

                    @lock-was-updated="lockWasUpdated"
                    @fail="load")

                OptiCatalogCategoryFormAdd(
                    v-if="selected.id === 0"

                    :type="type"
                    :selected="selected"

                    @position-was-added="positionWasAdded"
                    @lock-was-updated="lockWasUpdated"
                    @fail="load")

        OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiCatalogCategoryTreeList from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryTreeList'
    import OptiCatalogCategoryFormEdit from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryFormEdit'
    import OptiCatalogCategoryFormAdd from '@/js/app/vue/components/Catalog/Edit/OptiCatalogCategoryFormAdd'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogCategoryEdit',
        components: {
            OptiSwitchToggleButton,
            OptiCatalogCategoryTreeList,
            OptiCatalogCategoryFormEdit,
            OptiCatalogCategoryFormAdd,
            OptiDimmer
        },
        data() {
            return {
                type: false,

                categories: [],
                selected: {},
                count: {},

                filter: {
                    search: '',
                    lock: false
                },
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            toggleType(bool) {
                this.type = bool

                this.filter.search = ''
                this.filter.lock = false

                this.load()
            },
            loadProductsCount() {
                API.get('catalog/tree/products/count/' + (this.type ? 'warehouse' : 'department')).then(res => {
                    this.count = res.data
                }).finally(() => {

                }).catch(() => {
                    this.$notify.error('catalog.edit.notify.error.LOADCOUNT')
                })
            },
            load() {
                this.loading = true
                this.selected = {}

                API.get('catalog/tree/'  + (this.type ? 'warehouse' : 'department')).then(res => {
                    this.categories = res.data

                    this.loadProductsCount()
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.edit.notify.error.LOAD')
                })
            },

            forSearch(collection, searchFunc) {
                return _.filter(_.map(collection, item => {
                    Vue.set(item, 'show', !!this.filter.search)

                    if(searchFunc(item)) return item

                    if(!_.isEmpty(item.childrenWithLocked)) {
                        let subItems = this.forSearch(item.childrenWithLocked, searchFunc)

                        if(_.isEmpty(subItems)) return false

                        let newItem = _.cloneDeep(item)

                        newItem.childrenWithLocked = subItems

                        return newItem
                    }

                    return false
                }))
            },

            changedSearch() {
                this.selected = {}
            },
            changedLock() {
                if(!this.filter.lock && !_.isEmpty(this.selected) && this.selected.isLocked) {
                    this.selected = {}
                }
            },

            penetrate(category, data) {
                if(category.id === data.part_of_tree.id) {
                    Object.assign(category, data.part_of_tree)

                    category.childrenWithLocked.forEach(c => {
                        if(c.id === data.category_new_id) {
                            this.selected = c
                        }
                    })
                } else if(category.childrenWithLocked.length) {
                    category.childrenWithLocked.forEach(c => {
                        this.penetrate(c, data)
                    })
                }
            },
            positionWasAdded(data) {
                this.categories.forEach(category => {
                    this.penetrate(category, data)
                })
            },

            selectedWasUpdated(selected) {
                this.selected = selected

                if(!this.selected.id) {
                    this.lockWasUpdated()
                }
            },
            lockWasUpdated() {
                this.selected.path_locks = {}

                if(!this.selected.isLocked) {
                    this.goUnlockUp(this.categories, this.selected.parentId)
                }

                this.goLockDown(this.selected, this.selected.isLocked)
            },
            goUnlockUp(obj, id) {
                if(!Array.isArray(obj) && obj.id === id) {
                    this.setUnLockLockData(obj, false)

                    this.goUnlockUp(this.categories, obj.parentId)
                }

                if(Array.isArray(obj)) {
                    obj.forEach(o => {
                        o.childrenWithLocked.forEach(child => {
                            this.goUnlockUp(child, id)
                        })
                    })
                } else {
                    obj.childrenWithLocked.forEach(child => {
                        this.goUnlockUp(child, id)
                    })
                }
            },
            goLockDown(obj, lock) {
                obj.childrenWithLocked.forEach(child => {
                    this.setUnLockLockData(child, lock)

                    this.goLockDown(child, lock)
                })
            },
            setUnLockLockData(obj, bool) {
                if(obj.isLocked !== bool) {
                    obj.isLocked = bool

                    this.selected.path_locks[obj.id] = bool
                }
            }
        },
        computed: {
            filteredCategories() {
                return this.forSearch(this.categories, item => {
                    return item.name.toLowerCase().includes(this.filter.search.toLowerCase()) ||
                        item.short_name.toLowerCase().includes(this.filter.search.toLowerCase())
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    #search > .input-group-addon {
        cursor: pointer;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        &#ul-main {
            height: 70vh;
            overflow: auto;
        }
    }
</style>