<template lang="pug">
    .btn-group(:class="{ open: isOpen }" ref="dropdownButton")
        button.btn(type="button" :class="[typeClass]" @click="$emit('click')" v-if="!simple")
            span.btn-label(v-if="hasIcon")
                slot(name="icon")
            span
                slot
        button.btn.dropdown-toggle(
            :class="[simple ? typeClass : typeClassWithoutIcon]"
            @click="toggleState"
            )
            template(v-if="simple")
                span.btn-label(v-if="hasIcon")
                    slot(name="icon")
                span
                    slot
            template(v-if="!simple")
                span.caret
        ul.dropdown-menu(@click="toggleState")
            slot(name="menu")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiButtonDropdown',
        components: {
            OptiButton
        },
        props: {
            type: {
                type: String,
                default: 'default',
                validator(value) {
                    return ['default', 'primary', 'info', 'warning', 'danger', 'success', 'green', 'inverse'].includes(value)
                }
            },
            size: {
                type: String,
                default: '',
                validator(value) {
                    return ['', 'sm', 'xs', 'lg'].includes(value)
                }
            },
            simple: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            isOpen() {
                return this.open
            },
            typeClass() {
                let typeString = this.typeClassWithoutIcon
                let icon = this.hasIcon ? 'btn-labeled' : ''
                return `${typeString} ${icon}`
            },
            typeClassWithoutIcon() {
                let type = `btn-${this.type}`
                let size = this.size ? `btn-${this.size}` : ''
                return `${type} ${size}`
            },
            hasIcon() {
                return !!this.$slots.icon
            }
        },
        methods: {
            toggleState() {
                this.open = !this.open
                this.$emit('opened', this.open)
            },
            documentClick(e) {
                if (this.isOpen) {
                    let el = this.$refs.dropdownButton
                    let target = e.target

                    if (el !== target && !el.contains(target)) {
                        this.toggleState()
                    }
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.documentClick)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.documentClick)
        }
    }
</script>