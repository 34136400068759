<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-arrow-up.mr3
            span {{'layout.unit.MOVETO'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .form-horizontal
                .form-group(style="margin-bottom:0")
                    label.col-lg-2.control-label(for="object") {{'layout.unit.object.HEADING'|translate}}
                    .col-lg-10
                        VueMultiSelect#object(
                            :options="objects"
                            :value="selected"
                            :clearable="false"
                            label="name"
                            :placeholder="'layout.unit.object.PLACEHOLDER'|translate"
                            :allow-empty="false"
                            :showLabels="false"
                            :showNoResults="false"
                            ref="select"
                            track-by="id"

                            @input="o => selected = o")
                            template(slot="singleLabel" slot-scope="props")
                                span {{props.option.name}}
                            template(slot="option" slot-scope="props")
                                span {{props.option.name}}
                            template(slot="noOptions") {{'search.EMPTY'|translate}}

        template(#footer)
            OptiButton(type="success" :disabled="!selected" @click.native="$emit('confirmed', selected)") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(type="danger" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiLayoutUnitMoveToDialog',
        components: {
            VueMultiSelect,
            OptiDialog,
            OptiButton
        },
        props: {
            object: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                objects: [],
                selected: null,

                loading: true
            }
        },
        mounted() {
            API.get('layout/grid/' + this.object.gridId + '/objects/summary').then(res => {
                this.objects = res.data.filter(obj => {
                    return obj.id !== this.object.id && obj.id !== 0 && obj.type !== 'glasscase' && obj.type !== 'advertisement'
                }).map(item => {
                    let brands  = item.brands || [],
                        reduced = brands.reduce((prev, brand) => {
                            return (prev ? prev + ', ' : '') + brand.name + (brand.type === 'sun' ? '☼' : '')
                        }, false)

                    reduced = !reduced ? '' : ' (' + reduced + ')'
                    item.name = item.name + reduced

                    return item
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('layout.unit.notify.error.CATEGORIES')
            })
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 800px;

        > .modal-body {
            overflow: unset !important;
        }
    }
</style>