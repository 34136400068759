<template lang="pug">
    .row
        .col-xs-12(v-if="$state.params.patient && !$state.params.contact_lens")
            .alert.alert-info
                i.fa.fa-user(style="padding-right:3px")
                span(style="padding-right:3px") {{'patient.schedule.patients.assign.OPTOMETRIST'|translate}}
                a.link(:href="$state.href('app.patient.detail', {id: $state.params.patient.id})"
                    ) {{$state.params.patient.firstName}} {{$state.params.patient.lastName}} ({{$state.params.patient.id}})

        .col-xs-12(v-if="$state.params.contact_lens")
            .alert.alert-info
                i.fa.fa-user(style="padding-right:3px")
                span(style="padding-right:3px") {{'patient.schedule.patients.assign.CONTROL'|translate}}
                a.link(style="padding-right:3px" :href="$state.href('app.patient.detail', {id: $state.params.patient.id})"
                    ) {{$state.params.patient.firstName}} {{$state.params.patient.lastName}} ({{$state.params.patient.id}})
                span(style="padding-right:3px") {{'patient.schedule.patients.assign.INTERVIEW'|translate}}
                a.link(style="padding-right:3px" :href="$state.href('app.optometrist.details', {id: $state.params.contact_lens, '#': 'lens'})"
                    ) {{$state.params.contact_lens}}

        .col-xs-12(v-if="$state.params.doctor")
            .alert.alert-info
                i.fa.fa-user(style="padding-right:3px")
                span(style="padding-right:3px") {{'patient.schedule.patients.assign.DOCTOR'|translate}}
                a.link(style="padding-right:3px" :href="$state.href('app.patient.detail', {id: $state.params.doctor.patient.id})"
                    ) {{$state.params.doctor.patient.firstName}} {{$state.params.doctor.patient.lastName}} ({{$state.params.doctor.patient.id}})
                span(style="padding-right:3px") {{'patient.schedule.patients.assign.INTERVIEW'|translate}}
                a.link(style="padding-right:3px" :href="$state.href('app.optometrist.details', {id: $state.params.doctor.interview_id})"
                    ) {{$state.params.doctor.interview_id}}

        .col-md-7(v-if="admin")
            form.form.form-horizontal
                .form-group
                    label.control-label.col-xs-2 {{'schedule.DEPARTMENT'|translate}}
                    .col-xs-10
                        OptiSelectDepartment(
                            :departmentId="department_id"
                            :disabled="disabled"
                            :filter="filterOutHeadquarters"
                            @selected="updateDepartment")
        .col-xs-12
            .row
                .col-md-4
                    OptiButtonToggle(
                        enum="PatientWaitingViewType"
                        :model="view.type"
                        :disabled="disabled"
                        @change="viewWasChanged")

                #navigation-label.col-md-4.text-center(style="padding-bottom:20px" v-show="!edit")
                    .btn.btn-default(:disabled="disabled" @click="prevTime")
                        i.fa.fa-chevron-left

                    div(style="cursor:pointer" @click="goToEditMode")
                        .bold(style="font-size:22px")
                            span.mr3 {{view.label.main}}
                            i.fa.fa-edit
                        div(style="font-size:14px;margin-top:-5px;color:grey") {{view.label.sub}}
                        div(style="font-size:14px;margin-top:-5px;color:red")

                    .btn.btn-default(:disabled="disabled" @click="nextTime")
                        i.fa.fa-chevron-right

                OptiScheduleNavigationEdit(style="padding-bottom:20px" v-if="edit" :view="view" @accepted="dateWasEdited")
                .col-md-4(style="display:flex;justify-content:space-around")
                    button.btn.btn-default(@click="setToday()" :disabled="disabled") {{'schedule.directive.view.today'|translate}}
                    button.btn.btn-default(@click="goToSchedule()" :disabled="disabled") {{'patient.schedule.HARMONOGRAM'|translate}}
        .col-xs-12
            OptiDimmer(:active="loading")
        .col-xs-12(v-if="!loading")
            OptiPatientsScheduleDay(
                v-if="view.type === 'day'"
                v-for="office in offices"
                :key="office.id"
                :office="office")

            OptiPatientsScheduleAgenda(
                v-if="view.type === 'agenda' || view.type === 'week'"
                :type="view.type"
                :offices="offices"
                @reload-visits="loadData"
                @change-to-view-day="changeToViewDay")

        .col-xs-12.text-muted.text-center(v-show="!loading && !offices.length")
            i.fa.fa-exclamation-circle.mr3
            span {{'patient.schedule.notify.NOOFFICES'|translate}}

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment.vue'
    import OptiButtonToggle from '@/js/app/vue/components/Button/OptiButtonToggle'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPatientsScheduleDay from '@/js/app/vue/components/Patients/Schedule/Day/OptiPatientsScheduleDay'
    import OptiPatientsScheduleAgenda from '@/js/app/vue/components/Patients/Schedule/OptiPatientsScheduleAgenda'
    import OptiScheduleNavigationEdit from '@/js/app/vue/components/Schedule/Navigation/OptiScheduleNavigationEdit'

    export default {
        name: 'OptiPatientsSchedule',
        components: {
            OptiButtonToggle,
            OptiSelectDepartment,
            OptiDimmer,
            OptiPatientsScheduleDay,
            OptiPatientsScheduleAgenda,
            OptiScheduleNavigationEdit
        },
        data() {
            return {
                disabled_department: [86, 91, 149, 392], // to samo w directives/schedule.js:11
                department_id: null,
                view: {
                    type: this.$state.params.view,
                    start: (['day', 'agenda']).includes(this.$state.params.view) ? moment(this.$state.params.date).set({hour: 0, minute: 0, second: 0}) :
                        moment(this.$state.params.date).startOf('w').set({hour: 0, minute: 0, second: 0}),
                    end: (['day', 'agenda']).includes(this.$state.params.view) ? moment(this.$state.params.date).set({hour: 23, minute: 59, second: 59}) :
                        moment(this.$state.params.date).endOf('w').set({hour: 23, minute: 59, second: 59}),
                    label: {
                        main: null,
                        sub: null
                    }
                },

                offices: [],

                admin: this.$uac.user.activeRole.admin,
                edit: false,
                loading: true
            }
        },
        mounted() {
            this.$emit('dialog-close') // nie działa

            this.department_id = this.$state.params.department_id === '0' ?
                this.disabled_department.includes(this.$uac.user.activeDepartmentId) ? 60 : this.$uac.user.activeDepartmentId
                : this.disabled_department.includes(parseInt(this.$state.params.department_id)) ? 60 : parseInt(this.$state.params.department_id)

            if(!this.changeLink()) {
                this.setLabels()
                this.loadData()
            }
        },
        methods: {
            filterOutHeadquarters(items) {
                let allows = [49, 60, 402] // to samo w _directives/schedule.js:56

                this.$uac.user.department.forEach(department => {
                    if(!this.disabled_department.includes(department.departmentId)) {
                        allows.push(department.departmentId)
                    }
                })

                return items.filter(department => allows.includes(department.id))
            },
            changeLink() {
                if(this.$state.params.department_id !== this.department_id.toString() || this.$state.params.view !== this.view.type ||
                    this.$state.params.date !== this.view.start.format('YYYY-MM-DD')) {
                    this.loading = true

                    this.$state.go('app.patients.schedule', {
                        department_id: this.department_id.toString(),
                        view: this.view.type,
                        date: this.view.start.format('YYYY-MM-DD')
                    })

                    return true
                }

                return false
            },
            setLabels() {
                let year = this.view.start.format('GG'),
                    week = this.view.start.format('W'),
                    day = this.view.start.format('DD-MM-YYYY'),
                    quarter = BudgetHelper.getQuarterByWeek(this.view.start),

                    quarter_string = `${quarter}Q${year}`,
                    week_string = `${week}W${year}`,
                    day_string = `${day}`

                switch(this.view.type) {
                    case 'day':
                    case 'agenda':
                        this.view.label.main = `${week_string} ${quarter_string}`
                        this.view.label.sub = `${day_string}, ${this.view.start.format('dddd')}`

                        break

                    case 'week':
                        this.view.label.main = `${week_string} ${quarter_string}`
                        this.view.label.sub = this.view.start.format('DD-MM-YYYY') + ' - ' + this.view.end.format('DD-MM-YYYY')
                }
            },
            loadData() {
                this.loading = true

                API.post('patients/schedule/calendar', {
                    start: this.view.start.format('YYYY-MM-DD HH:mm:ss'),
                    end: this.view.end.format('YYYY-MM-DD HH:mm:ss'),

                    department_id: this.department_id,
                    view: this.view.type
                }).then(res => {
                    this.offices = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.LOAD')
                })
            },
            updateDepartment(department) {
                this.department_id = department.id

                this.changeLink()
            },
            viewWasChanged(view_type) {
                if(view_type === 'week') {
                    this.view.start.startOf('w')
                    this.view.end.endOf('w')
                } else if(this.view.type === 'week') {
                    this.view.start.startOf('w')
                    this.view.end.startOf('w').set({hour: 23, minute: 59, second: 59})
                }

                this.view.type = view_type

                this.setLabels()
                this.changeLink()
            },

            prevTime() {
                switch(this.view.type) {
                    case 'day':
                    case 'agenda':
                        this.view.start.subtract(1, 'd')
                        this.view.end.subtract(1, 'd')

                        break

                    case 'week':
                        this.view.start.subtract(1, 'w').startOf('w')
                        this.view.end.subtract(1, 'w').endOf('w')
                }

                this.setLabels()
                this.changeLink()
            },
            nextTime() {
                switch(this.view.type) {
                    case 'day':
                    case 'agenda':
                        this.view.start.add(1, 'd')
                        this.view.end.add(1, 'd')

                        break

                    case 'week':
                        this.view.start.add(1, 'w').startOf('w')
                        this.view.end.add(1, 'w').endOf('w')
                }

                this.setLabels()
                this.changeLink()
            },
            setToday() {
                switch(this.view.type) {
                    case 'day':
                    case 'agenda':
                        this.view.start = moment()
                        this.view.end = moment()

                        break;

                    case 'week':
                        this.view.start = moment().startOf('w')
                        this.view.end = moment().endOf('w')
                }

                this.setLabels()
                this.changeLink()
            },
            goToEditMode() {
                if(this.loading) {
                    this.$notify.warning('schedule.notify.LOAD')
                } else {
                    this.edit = true
                }
            },
            changeToViewDay(date) {
                this.view.start = date.clone().set({hour: 0, minute: 0, second: 0})
                this.view.end = date.clone().set({hour: 23, minute: 59, second: 59})

                this.view.type = 'day'

                this.setLabels()
                this.changeLink()
            },
            goToSchedule() {
                this.$state.go('app.schedule.calendar', {
                    department_id: this.department_id.toString(),
                    view: this.view.type === 'week' ? 'week' : 'day',
                    date: this.view.start.format('YYYY-MM-DD'),
                    budget: '0'
                })
            },
            dateWasEdited() {
                this.edit = false

                this.setLabels()
                this.changeLink()
            }
        },
        computed: {
            disabled() {
                return this.loading || this.edit
            }
        }
    }
</script>

<style lang="less" scoped>
    #navigation-label {
        display: flex;
        justify-content: space-around;

        > div:nth-child(2) {
            cursor: pointer;
        }

        > .btn {
            display: flex;
            align-items: center;
        }
    }
</style>