<template lang="pug">
    .product.vue-product
        .product-thumbnail(:class="border")

            a(:href="$state.href('app.catalog.product', {id: product.id, departmentId: currentDepartmentId})")

                img.product-icon(:src="'img/temp_product.png'"
                    style="max-width: 35px"
                    v-if="product.temp_product")

                img.product-icon(:src="'img/new_product.png'"
                    style="max-width: 50px"
                    v-if="product.new_product && [0, 1].includes(product.type) && !product.temp_product")

                img(:src="'thumb/product/ids/' + product.id + '.jpg'" @load="loading = false" @error="loading = false")
                OptiDepositoryInfo(
                    v-if="!loading"

                    :dot-color="availability"
                    :product="product")
                span.bold.warn(style="position:absolute;bottom:35px;right:0;padding:6px 16px;font-size:2em"
                    v-if="specialPrice.enabled" v-text="specialPrice.text")

                OptiCurveInfo(
                    v-if="[0, 1].includes(product.type) && !loading"

                    :product-id="product.id"
                    :attributes="product.attributes"
                    :selection="typeof product.productSelection !== 'undefined' ? product.productSelection.new_status : ''"
                    :xx="!!product.outOfProduction")

                .product-mark(v-if="selected || isHeart")
                    i.fa.fa-2x.fa-check(v-show="selected")
                    i.fa.fa-2x.fa-heart(v-show="isHeart")

            OptiProductLayoutInfo(v-if="!loading"
                :id="product.id"
                :department="departmentId")
            button.btn.btn-success.btn-ticket.btn-pill-right(type="button" :title="'catalog.product.buttons.ADDTICKET'|translate"
                v-if="ticketButtons && !ticket_exists"
                @click="addTicket")
                i.fa.fa-fw.fa-plus
            button.btn.btn-danger.btn-ticket.btn-pill-right(type="button" :title="'catalog.product.buttons.DELETETICKET'|translate"
                v-if="ticketButtons && ticket_exists"
                @click="deleteTicket")
                i.fa.fa-fw.fa-minus
            OptiCartButton(v-if="btnShow" type="pill" :product="product")
            OptiDimmer(:active="loading")

        .product-description
            .tpl-id
                i.fa.fa-link.mr3
                span {{product.id}}
            .tpl-barcode
                i.fa.fa-barcode.mr3
                span {{product.barcode}}
            .clearfix

        .product-name-price
          div {{ product.name }}
          .bold.warn(style="display:flex;justify-content:flex-end;align-content:center"
            :title="'catalog.TITLESTOCKWIKI'|translate" @click="goToWikiCatalogPrice")
            .text-right.mr3(style="font-size:.9em;line-height:1.1em")
              div(v-show="permission_wholesale && product.priceWholesale > 0") {{ product.priceWholesale | currency }}
              div(style="text-decoration:line-through" v-if="specialPrice.enabled && specialPrice.before > 0") {{ specialPrice.before | currency }}
            div(style="font-size:1.3em" :class="{'invisible': !(specialPrice.enabled && specialPrice.value > 0 ? specialPrice.value > 0 : product.price > 0)}"
                ) {{ specialPrice.enabled && specialPrice.value > 0 ? specialPrice.value : product.price | currency }}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
    import OptiCurveInfo from '@/js/app/vue/components/Warehouse/Products/OptiCurveInfo'
    import OptiProductLayoutInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductLayoutInfo.vue'
    import OptiCartButton from '@/js/app/vue/components/Carts/OptiCartButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductInfo',
        components: {
            OptiDepositoryInfo,
            OptiCurveInfo,
            OptiProductLayoutInfo,
            OptiCartButton,
            OptiDimmer
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            selected: {
                type: Boolean,
                required: false,
                default: false
            },
            departmentId: {
                type: Number
            },
            tickets: {
                type: Boolean,
                required: false,
                default: false
            },
            active: {
                type: Boolean,
                required: false,
                default: false
            },
            btnShow: {
                type: Boolean,
                default: false,
                required: false
            },
            isOffer: {
                type: Boolean,
                default: false,
                required: false
            },
            isHeart: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                ticket_exists: false,
                availability: {},

                permission_wholesale: !this.isOffer && (this.$uac.user.activeRole.admin || this.$uac.user.activeDepartmentId === 49 ||
                    ([16, 17].includes(this.$uac.user.activeRole.id) && [3, 4].includes(this.$uac.user.departmentType))),
                //ten sam warunek jest w OptiProductDetails

                loading: true
            }
        },
        watch: {
            'product.id'() {
                this.loading = true
            }
        },
        mounted() {
            if(this.product && this.product.productSelection && this.product.productSelection.id) {
                API.get('catalog/category/products/getAvailabilityColor/' + this.product.productSelection.id, API.id(Math.random())).then(res => {
                    this.$set(this, 'availability', res.data)
                })
            }
        },
        methods: {
            addTicket() {
                this.loading = true

                API.post('warehouse/ticket/storage/add', {
                    product_id: this.product.id,
                    product_name: this.product.name,
                    barcode: this.product.barcode,
                    price: this.product.price
                }).then(() => {
                    this.ticket_exists = true
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('ticket.service.notify.error.ADD')
                })
            },
            deleteTicket() {
                this.loading = true

                API.post('warehouse/ticket/storage/delete', {
                    product_id: this.product.id
                }).then(() => {
                    this.ticket_exists = false
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('ticket.service.notify.error.DELETE')
                })
            },

            goToWikiCatalogPrice() {
                window.open('https://wiki.eopti.pl/oprogramowanie:system_eopti:ceny_katalog', '_blank')
            }
        },
        computed: {
            border() {
                if(this.selected) {
                    return 'active-is-selected'
                } else if(this.isHeart) {
                    return 'active-is-heart'
                } else if(this.active) {
                    return 'active-' + this.$store.getters['carts/getActiveCartName']
                }

                return ''
            },
            currentDepartmentId() {
                // Kolejność: parametr komponentu -> parametr w url'u -> salon użytkownika -> error
                return this.departmentId || this.$route.params.departmentId || this.$uac.user.activeDepartmentId || 0
            },

            specialPrice() {
                let enabled = this.product.customerProduct && this.product.customerProduct.specialPrice,
                    text = '',
                    value = 0,
                    before = 0

                if(enabled
                    && Number(this.product.customerProduct.specialPrice.before) > 0
                    && Number(this.product.customerProduct.specialPrice.before) !== Number(this.product.customerProduct.specialPrice.value)) {
                    let price = this.product.customerProduct.specialPrice
                    let percent = (price.value * 100) / price.before
                    percent = 100 - (Math.round(percent / 10) * 10)

                    text = '-' + percent + '%'
                    value = price.value
                    before = price.before
                }

                return {
                    enabled: enabled,
                    text: text,
                    value: value,
                    before: before
                }
            },
            ticketButtons() {
                if(this.tickets) { //TODO: dobry pomysł by zmienić to na vuex
                    API.get('warehouse/ticket/storage/exists/' + this.product.id, API.id(Math.random())).then(res => {
                        this.ticket_exists = res.data.exists
                    }).catch(() => {
                        this.$notify.error('ticket.service.notify.error.LOAD')
                    })
                }

                return this.tickets
            }
        }
    }
</script>

<style lang="less" scoped>
    .product-icon {
        position: absolute !important;
        z-index: 1 !important;
        opacity: 0.7 !important;
        top: 0 !important;
        margin-top: 30px !important;
    }

    .product-name-price {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        cursor: pointer;
    }

    .opti-product-info-small .product-name-price {
        font-size: .75em;
    }

    .product-mark {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -18px;

        > i {
            &.fa-check {
                color: #27c24c;

                text-shadow: 0 0 2px #000;
            }

            &.fa-heart {
                color: red;

                text-shadow: 0 0 2px #fff;
            }
        }
    }
</style>