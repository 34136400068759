<template lang="pug">
    .opti-select-product
        VueMultiSelect(
            id="product"
            :options="products"
            :value="product"
            :clearable="false"
            label="name"
            placeholder="id lub nazwa ramki"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"
            :loading="loading"
            group-label="type"
            group-values="products"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            :disabled="!!disabled"

            @search-change="loadData"
            @input="p => $emit('selected', p)")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option.name }}
                small.pull-right {{ props.option.id }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{ props.option.name }}
                    small.pull-right {{ props.option.id }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectFrame',
        components: {VueMultiSelect},
        props: {
            product: {
                type: Object,
                required: true,
                default() {
                    return {}
                }
            },
            foreign: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: [Boolean, Number],
                required: false,
                default: false
            },
            additionalBody: {
                type: Object,
                required: false,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                products: [],

                queue: null,
                loading: false
            }
        },
        created() {
            this.loadData = _.debounce(this.asyncLoadData, 2000)
        },
        methods: {
            reset() {
                this.products = [{
                    type: 'Nowy wpis (klienta załączona)',
                    products: []
                }, {
                    type: 'Ramki z nazwy',
                    products: []
                }, {
                    type: 'Ramki z id',
                    products: []
                }]
            },
            asyncLoadData(name) {
                if(name.length < 3) {
                    return false
                }

                let body = _.defaults({}, this.additionalBody, {name: name})

                this.loading = true
                API.post('search/frame', body).then(res => {
                    this.reset()

                    let exists = false
                    res.data.forEach(product => {
                        if(name == product.id) {
                            this.products[2].products.push(product)
                        } else if(name == product.name) {
                            exists = true
                        } else {
                            this.products[1].products.push(product)
                        }

                        if(this.foreign && !exists) {
                            this.products[0].products.push({
                                id: 0,
                                name: name
                            })
                        }
                    })
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
