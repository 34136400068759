<template lang="pug">
    div
        .col-xs-12.text-center.warn(v-show="!customer.active")
            .line-padding
                i.fa.fa-exclamation-circle.mr3
                span {{'eShop.customer.NO_ACTIVE'|translate}}

        .col-sm-4
            .line-padding
                .bold {{'eShop.customer.FIRST_LAST_NAME'|translate}}
                .form-control-static
                    a.link(v-if="link" :href="$state.href('app.eShop.customer', {id: customer.id})")
                        span.mr3 {{customer.first_name}} {{customer.last_name}}
                        span(:title="customer.sex_name|translate") ({{$filters.translate(customer.sex_name)[0]}})
                    div(v-else)
                        span.mr3 {{customer.first_name}} {{customer.last_name}}
                        span(:title="customer.sex_name|translate") ({{$filters.translate(customer.sex_name)[0]}})

        .col-sm-4
            .line-padding
                .bold {{'eShop.customer.PHONE'|translate}}
                .form-control-static
                    span(v-if="customer.address_billing_phone_number") {{customer.address_billing_phone_number}}
                    span.warn(v-else)
                        i.fa.fa-exclamation-circle.mr3
                        span {{'default.NA'|translate}}

        .col-sm-4
            .line-padding
                .bold {{'eShop.customer.EMAIL'|translate}}
                .form-control-static
                    a.link(:href="'mailto:' + customer.email") {{customer.email}}

        .col-sm-4.bg-separator
            .line-padding
                .bold {{'eShop.customer.LAST_LOGIN_AT'|translate}}
                .form-control-static
                    span(v-if="customer.last_login_at") {{customer.last_login_at}}
                    span.warn(v-else)
                        i.fa.fa-exclamation-circle.mr3
                        span {{'default.NA'|translate}}

        .col-sm-4.bg-separator
            .line-padding
                .bold {{'eShop.customer.ORDER_TOTAL_AMOUNT'|translate}}
                .form-control-static {{customer.order_total_amount|currency}}

        .col-sm-4.bg-separator
            .line-padding
                .bold {{'eShop.customer.ORDER_COUNT'|translate}}
                .form-control-static {{customer.order_count}}
</template>

<script>
    export default {
        name: 'OptiEshopCustomerPanel',
        props: {
            customer: {
                type: Object,
                required: true
            },

            link: {
                type: Boolean,
                required: false,
                default: true
            }
        }
    }
</script>