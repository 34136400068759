<template lang="pug">
    .schedule-day-painter
        OptiDimmer(:active="saving")
        .overflower
            .col-xs-8
                .flex(v-if="eventsByHour")
                    .cell(v-for="hour in hours" @click="updateCell(eventsByHour[hour], hour, eventsByHour[hour].type)" :data-hour="hour")
                        .department-closed(v-if="hour < working.start || hour >= working.end")
                        .cell-content(:class="eventsByHour[hour].class")
                            .hours
                                span(v-html="timeString(eventsByHour[hour].start)")
                                span -
                                span(v-html="timeString(eventsByHour[hour].end)")
                            div {{ shortName(eventsByHour[hour]) }}

            .col-xs-4
                .flex(v-show="!readonly")
                    .cell.cell-auto(:class="{ active: brush === false }")
                        div(@click="selectBrush(false)")
                            i.fa.fa-eraser.mr3
                            span WYMAŻ
                    .cell.cell-auto(v-for="type in types" v-if="checkIfHoliday(type.id)" :class="{ active: brush.id === type.id }")
                        div(:class="type.class" @click="selectBrush(type)") {{ type.name | translate }}
        .col-xs-12(style="padding-top: 10px" v-show="!readonly")
            .pull-left
                OptiButton(type='info' @click.native="onCut") Wytnij
                    template(#icon)
                        i.fa.fa-cut
                span &nbsp;
                OptiButton(type='info' @click.native="onCopy") Kopiuj
                    template(#icon)
                        i.fa.fa-copy
                span &nbsp;
                OptiButton(type='info' @click.native="onPaste" :disabled="clipboardEmpty") Wklej
                    template(#icon)
                        i.fa.fa-paste
            .pull-right
                OptiButton(type="success" @click.native="onSave") Zapisz
                    template(#icon)
                        i.fa.fa-save
                span &nbsp;
                OptiButton(type="danger" @click.native="onCancel") Anuluj
                    template(#icon)
                        i.fa.fa-remove
            .clearfix
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import HolidayHelper from '@/js/app/vue/helpers/HolidayHelper'
    import {EnumJobTypeExchange, EnumJobType, EnumJobTypeFiltered} from '../../../enums/Enums'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    const CLIPBOARD = 'schedule-clipboard'

    export default {
        name: 'OptiScheduleDayPainter',
        components: {
            OptiButton,
            OptiDimmer
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            events: {
                type: Array,
                required: true
            },
            workingHours: {
                type: Object,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            date: {
                type: String,
                required: true
            },
            mode: {
                type: String,
                required: false,
                default: 'normal'
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                eventsByHour: false,
                brush: false,
                saving: false,
                types_allow_id: [],
                nonWorkingSundays: HolidayHelper.getNonWorkingSundays(parseInt(moment(this.date).format('YYYY')))
            }
        },
        mounted() {
            this.eventsByHour = this.splitEventsByHour(this.events)

            window.p = this
        },
        computed: {
            hours() {
                let hours = []

                for (let i = 6; i <= 23; i++) {
                    hours.push(i)
                    hours.push(i + 0.5)
                }

                return hours
            },
            eventNames() {
                return {
                    2: 'L4',
                    4: 'U ż'
                }
            },
            clipboardEmpty() {
                return !this.$storage.has(CLIPBOARD)
            },
            working() {
                return {
                    start: parseFloat(parseInt(this.workingHours.start.hour) + '.' + (parseInt(this.workingHours.start.minute) === 0 ? '0' : '5')),
                    end: parseFloat(parseInt(this.workingHours.end.hour) + '.' + (parseInt(this.workingHours.end.minute) === 0 ? '0' : '5'))
                }
            },
            allTypes() {
                return this.$uac.hasRole('schedule.all_job_type')
            },
            types() {
                this.types_allow_id = [];

                let types = this.mode === 'exchange' ? EnumJobTypeExchange : (this.allTypes ? EnumJobType : EnumJobTypeFiltered)
                return types.simpleItems().map(member => {
                    member.class = 'schedule-color-' + member.id
                    this.types_allow_id.push(member.id)

                    return member
                })
            }
        },
        methods: {
            async saveEvents(events) {
                if(this.mode === 'exchange') {
                    this.eventsByHour = this.splitEventsByHour(events)

                    this.$emit('saved', events)

                    return null
                }

                try {
                    this.saving = true
                    let {data} = await API.post('schedule/calendar/update-events', {
                        events: events,
                        departmentId: this.departmentId,
                        userId: this.user.id,
                        date: this.date
                    })

                    this.eventsByHour = this.splitEventsByHour(data)

                    this.$emit('saved', data)
                } catch (e) {
                    if(!API.isCancel(e)) {
                        this.$notify.error(typeof e.response.data === 'string' ? e.response.data : e.response.data.message, false)
                    } else {
                        console.log(e)
                    }
                } finally {
                    this.saving = false
                }
            },
            onSave() {
                let draft   = []
                let indexed = []

                _.forEach(this.eventsByHour, (event, key) => {
                    let skey = key
                    if (parseInt(key) < 10) {
                        skey = '0' + skey
                    }
                    if (this.isInt(key)) {
                        skey = skey + '.0'
                    }
                    indexed.push({key: skey, event: _.cloneDeep(event)})
                })
                indexed.sort((a, b) => {
                    return parseFloat(a.key) < parseFloat(b.key) ? -1 : 1
                })

                _.forEach(indexed, ({key, event}) => {
                    let lastEvent = draft.length ? draft[draft.length - 1] : false
                    if (lastEvent) {
                        if (lastEvent.type === event.type) {
                            draft[draft.length - 1].end = event.end
                            if (lastEvent.id !== event.id) {
                            }
                        } else {
                            draft.push(event)
                        }
                    } else {
                        draft.push(event)
                    }
                })

                draft = draft.filter(event => event.type > -1)

                draft.forEach(item => console.log(item.start.format('H:m'), item.end.format('H:m'), item.type_name))
                draft = draft.map(item => {
                    return {
                        id: item.id,
                        editable: item.editable,
                        class: item.class,
                        end: item.end.format('YYYY-MM-DD HH:mm:ss'),
                        start: item.start.format('YYYY-MM-DD HH:mm:ss'),
                        type: item.type,
                        type_name: item.type_name
                    }
                })

                this.saveEvents(draft)
            },
            onCancel() {
                this.$emit('cancelled')
            },
            selectBrush(brush) {
                this.brush = brush
            },
            updateCell(event, hour, event_cell_type) {
                if(this.readonly) {
                    return null
                }

                if(event_cell_type === -1 || this.types_allow_id.indexOf(event_cell_type) > -1) {
                    let cls = 'schedule-color-' + (this.brush ? this.brush.id : 'off')

                    if (hour <= this.working.start || hour >= this.working.end) {
                        cls += ' no-working-hour'
                    }

                    event.type      = this.brush ? this.brush.id : -1
                    event.class     = cls
                    event.type_name = this.brush ? this.brush.name : ' '
                }
            },
            shortName(event) {
                if(event.type === -1) return ''

                if(this.eventNames[event.type]) {
                    return this.eventNames[event.type]
                }

                return event.type_name.indexOf('schedule.') > -1 ? this.$i18n.t(event.type_name)[0] : event.type_name[0]
            },
            isInt(number) {
                return number % 1 === 0
            },
            splitEventsByHour(events) {
                let hours = {}

                _.forEach(events, (event) => {
                    let start = moment(event.start).add(-30, 'minutes'),
                        end   = moment(event.end)

                    let newEvent = _.cloneDeep(event)
                    newEvent.end = end

                    let endCheck = end.clone().subtract(30, 'minutes')

                    while(start.isBefore(endCheck)) {
                        start   = start.add(30, 'minutes')
                        let key = start.format('H') + (start.minutes() === 30 ? '.5' : '')

                        hours[key]       = _.cloneDeep(newEvent)
                        hours[key].start = start.clone()
                        hours[key].end   = start.clone().add(30, 'minutes')

                        if (key <= this.working.start || key >= this.working.end) {
                            hours[key].class += ' no-working-hours'
                        }
                    }
                })

                this.hours.map(hour => {
                    if(!hours[hour]) {
                        let start = moment(this.date, 'YYYY-MM-DD')
                            .set({'hour': parseInt(hour), 'minute': (hour - parseInt(hour)) * 60, 'second': 0})

                        hours[hour] = {
                            id: 0,
                            editable: true,
                            type: -1,
                            type_name: ' ',
                            class: 'schedule-color-off',
                            start: start.clone(),
                            end: start.clone().add(30, 'minutes')
                        }

                        if(hour <= this.working.start || hour >= this.working.end) {
                            hours[hour].class += ' no-working-hours'
                        }
                    }
                })

                return hours
            },
            timeString(time) {
                let hours = time.format('H'),
                    minutes = parseInt(time.format('m')),
                    sup = !!minutes ? '³⁰' : ''

                return `${hours}${sup}`
            },
            onCut() {
                this.$storage.set(CLIPBOARD, _.cloneDeep(this.eventsByHour))
                this.eventsByHour = this.splitEventsByHour([])
            },
            onCopy() {
                this.$storage.set(CLIPBOARD, _.cloneDeep(this.eventsByHour))
            },
            onPaste() {
                if(this.$storage.has(CLIPBOARD)) {
                    try {
                        let events = this.$storage.get(CLIPBOARD),
                            currentDate = moment(this.date, 'YYYY-MM-DD'),
                            currentDateObj = {
                                year: parseInt(currentDate.format('YYYY')),
                                month: parseInt(currentDate.format('MM'))-1,
                                date: parseInt(currentDate.format('DD')),
                            }

                        _.map(events, event => {
                            event.id = 0

                            event.start = moment(event.start)
                            event.end = moment(event.end)

                            event.start.set(currentDateObj)
                            event.end.set(currentDateObj)

                            return event
                        })

                        this.eventsByHour = events
                    } catch (e) {
                        this.eventsByHour = this.splitEventsByHour([])
                    }
                }
            },
            checkIfHoliday(type_id) {
                if(type_id === 1 && this.nonWorkingSundays.indexOf(this.date) > -1) {
                    return false
                }

                return true
            }
        }
    }
</script>
