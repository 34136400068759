<template lang="pug">
    OptiDialog.fullscreen(:header="lastPage" :footer="lastPage")
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.SURVEYNEAR'|translate}}

            label.fa.fa-remove.pull-right(style="font-weight:normal;cursor:pointer" for="btn-cancel")

        template(#body)
            div(v-if="!lastPage")
                img(:src="'img/optometrist/screening-test/visus-near/' + view + '.png'" width="100%")

                div
                    button#btn-left.btn.btn-lg.btn-success(:disabled="pages[0] === view" @click="--view")
                        i.fa.fa-3x.fa-arrow-left

                    button#btn-right.btn.btn-lg.btn-success(:disabled="lastPage" @click="++view")
                        i.fa.fa-3x.fa-arrow-right

                    label#btn-remove.btn.btn-lg.btn-danger(for="btn-cancel")
                        i.fa.fa-remove

            .table-responsive(v-show="lastPage")
                table.table.table-borderless
                    thead
                        tr
                            th.text-center {{'prescription.RIGHT'|translate}}
                            th.text-center {{'prescription.LEFT'|translate}}
                            th.text-center BinVisus
                            th.text-center
                    tbody.table-visus-near
                        tr(v-for="(row, index) in rows")
                            th.center
                                label.btn.btn-default(:for="'r_visus' + index" :class="{'btn-warning active': r_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'r_visus' + index" v-model="r_visus" name="r_visus" :value="row.scale")
                            th.center
                                label.btn.btn-default(:for="'l_visus' + index" :class="{'btn-warning active': l_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'l_visus' + index" v-model="l_visus" name="l_visus" :value="row.scale")
                            th.center
                                label.btn.btn-default(:for="'bin_visus' + index" :class="{'btn-warning active': bin_visus === row.scale}") {{row.scale}}
                                input.hide(type="radio" :id="'bin_visus' + index" v-model="bin_visus" name="bin_visus" :value="row.scale")
                            td.middle
                                label
                                    div {{row.lines[0]}}
                                    div {{row.lines[1]}}

        template.text-right(#footer)
            .row
                .col-xs-4.text-left
                    OptiButton(type="success" @click.native="--view") {{'optometrist.video.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left
                .col-xs-4.text-center
                    OptiButton(type="success" text="optometrist.video.confirm.success"
                        :disabled="r_visus === '-' || l_visus === '-' || bin_visus === '-'" @confirmed="confirmed()"
                        ) {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton#btn-cancel(type="danger" text="optometrist.video.confirm.cancel" @confirmed="cancelled()"
                        ) {{'default.CANCEL'|translate}}
                        template(#icon)
                            i.fa.fa-remove
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiPatientsPrescriptionsSurveysNear',
        components: {
            OptiDialog,
            OptiButton
        },
        props: {
            prescription: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                unique: Math.random().toString(36).substr(2, 9),

                pages: [1, 2, 3, 4],
                view: 1,

                r_visus: '-',
                l_visus: '-',
                bin_visus: '-',

                rows: [{
                    scale: '0,2',
                    lines: ['W bogatej ofercie LOOX Galerii Optyki', 'znajdują się wyłącznie na markowe produkty,']
                }, {
                    scale: '0,3',
                    lines: ['które poza zapewnieniem komfortu widzenia, mają', 'nadać naszym klientom wyjątkowy charakter.']
                }, {
                    scale: '0,4',
                    lines: ['Wieloletnie doświadczenie, szeroki wybór markowych okularów', 'oraz dostępność salonów LOOX Galerii Optyki w kilkunastu']
                }, {
                    scale: '0,5',
                    lines: ['miastach Polski gwarantuje najwyższą jakość naszych produktów i usług.', 'W naszych salonach optycznych oferujemy Państwu: profesjonalne badanie']
                }, {
                    scale: '0,6',
                    lines: ['ostrości wzroku, ogromny wybór markowych modeli oprawek do okularów korekcyjnych', 'i przeciwsłonecznych najnowszych, światowych kolekcji, a także indywidualne dobrane']
                }, {
                    scale: '0,8',
                    lines: ['do każdego pacjenta soczewki okularowe, zbudowane z najlepszych materiałów i z wykorzystaniem nowoczesnych', 'technologii optymalizacyjnych. Loox Galeria Optyki oferuje w swoich salonach optycznych na terenie Polski']
                }, {
                    scale: '1,0',
                    lines: ['usługę Umowy Serwisowej. Każdy klient, który kupi okulary korecyjne (oprawę okularową i dwie soczewki okularowe)', 'w salonie optycznym Loox Galeria Optyki, ma prawo do zakupu Umowy Serwisowej za równowartość 10% wartości okularów.']
                }]
            }
        },
        mounted() {
            let object = {
                rVisus: '-',
                lVisus: '-',
                binVisus: '-'
            }

            this.prescription.check.logs.push({survey_id: this.unique, type: 'near', action: 'start', side: '50%',
                description: 'Rozpoczęto badanie', editable: [], values: object, survey_at: moment().valueOf()})
        },
        methods: {
            confirmed() {
                this.prescription.check.near_r_visus = this.r_visus
                this.prescription.check.near_l_visus = this.l_visus
                this.prescription.check.near_binvisus = this.bin_visus

                let result = {
                    rVisus: this.r_visus,
                    lVisus: this.l_visus,
                    binVisus: this.bin_visus
                }

                this.prescription.check.logs.push({survey_id: this.unique, type: 'near', action: 'end', side: '50%',
                    description: 'Zakończono badanie', editable: [], values: result, survey_at: moment().valueOf()})

                this.$emit('confirmed', this.prescription.check)
            },
            cancelled() {
                this.prescription.check.logs.push({survey_id: this.unique, type: 'near', action: 'cancel', side: '50%',
                    description: 'Anulowano badanie', editable: [], values: {data: null}, survey_at: moment().valueOf()})

                this.$emit('cancelled')
            }
        },
        computed: {
            lastPage() {
                return this.pages[this.pages.length - 1] === this.view
            }
        }
    }
</script>

<style lang="less" scoped>
    #btn-left {
        position:fixed;
        padding:20px 30px;
        left:5px;
        top:45%
    }

    #btn-right {
        position:fixed;
        padding:20px 30px;
        right:5px;
        top:45%
    }

    #btn-remove {
        position:fixed;
        right:35px;
        top:20px;
    }

    /deep/ .modal-container {
        padding: 0 !important;

        .modal-body {
            overflow: hidden !important;
        }
    }
</style>