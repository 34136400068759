<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="loading")
        .col-xs-12.text-right(v-show="!loading && $uac.permission('salons.edit')")
            OptiButton(type='primary' @click.native="goToEdit") {{'departments.office.breadcrumb.EDIT'|translate}}
                template(#icon)
                    span.fa.fa-edit
        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-show="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'departments.office.breadcrumb.OFFICE'|translate}}

                        .pull-right \#{{id}}
                        .clearfix
                    .row
                        .col-sm-6 {{'departments.office.table.NAME'|translate}}
                        .col-sm-6 {{name}}
                    .row
                        .col-sm-6 {{'departments.office.table.TYPE'|translate}}
                        .col-sm-6 {{type_name|translate}}
                    .row
                        .col-sm-6 {{'departments.office.table.DEPARTMENT'|translate}}
                        .col-sm-6 {{department_name}}
                    .row(v-show="departments.length")
                        .col-sm-6 {{'departments.office.departments.HEADING'|translate}}
                        .col-sm-6
                            ul
                                li(v-for="department in departments") {{department.name}}
                        .clearfix
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDepartmentsOfficeDetails',
        components: {
            OptiDimmer, OptiButton
        },
        data() {
            return {
                id: 0,
                name: '',
                type: 0,
                type_name: '',
                department_id: 0,
                department_name: '',
                departments: [],

                loading: true
            }
        },
        mounted() {
            API.get('departments/office/' + this.$state.params.id).then((res) => {
                this.id = res.data.id
                this.name = res.data.name
                this.type = res.data.type
                this.type_name = res.data.type_name
                this.department_id = res.data.department_id
                this.department_name = res.data.department.name
                this.departments = res.data.departments

                this.loading = false
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('departments.office.notify.error.LOAD')
            })
        },
        methods: {
            goToEdit() {
                this.$state.go('app.departments.office.edit', {
                    id: this.$state.params.id
                })
            }
        }
    }
</script>