<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            form#form-reclamations.form-horizontal.form-validate(name="formReclamation" @submit.prevent="editReclamation")
                .col-md-offset-2.col-md-8
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.CREATEDBY'|translate}}
                        .col-sm-8.form-control-static {{reclamation.employee}} ({{reclamation.departmentName}})
                    .form-group
                        label.col-sm-4.control-label.star-required(for="expected") {{'reclamations.details.CONSIDERDATE'|translate}}
                        .col-sm-8(v-if="permission")
                            OptiDate#expected(:value="reclamation.expected" @update-date="d => reclamation.expected = d")
                            OptiValidate(f="expected" :data="$v.reclamation.expected")
                        .col-sm-8.form-control-static(v-if="!permission") {{reclamation.expected}}

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label {{'reclamations.details.declarant.PATIENT'|translate}}
                        .col-sm-8.form-control-static
                            a.link(:title="'reclamations.details.title.PATIENT'|translate"
                                :href="$state.href('app.patient.detail', {id: reclamation.clientId})" target="_blank"
                                ) {{reclamation.clientName}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.declarant.ADDRESS'|translate}}
                        .col-sm-8.form-control-static {{reclamation.clientStreet}}, {{reclamation.clientPostal}} {{reclamation.clientCity}}
                    .form-group
                        label.col-sm-4.control-label(for="phone") {{'reclamations.details.declarant.PHONE'|translate}}
                        .col-sm-8
                            input#phone.form-control(name="phone" v-model="reclamation.clientPhone")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="clientCellphone") {{'reclamations.details.declarant.CELLPHONE'|translate}}
                        .col-sm-8
                            input#clientCellphone.form-control(name="clientCellphone" v-model="reclamation.clientCellphone" required)
                            OptiValidate(f="clientCellphone" :data="$v.reclamation.clientCellphone")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label {{'reclamations.details.TASKID'|translate}}
                        .col-sm-8.form-control-static
                            a.link(:title="'reclamations.details.title.TASK'|translate"
                                :href="$state.href('app.task.detail', {id: reclamation.productId})" target="_blank"
                                ) {{reclamation.productId}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.FINISHEDTASK'|translate}}
                        .col-sm-8.form-control-static {{reclamation.sellDate}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.PRICING'|translate}}
                        .col-sm-8.form-control-static {{reclamation.productPrice|currency}}
                    .form-group
                        label.col-sm-4.control-label(for="reclamation_evidence") {{'reclamations.details.EVIDENCE'|translate}}
                        .col-sm-8
                            OptiSelectEnum#reclamation_evidence(enum="ReclamationEvidence"
                                :value="reclamation.refType" @update-enum="t => reclamation.refType = t")
                    .form-group
                        label.col-sm-4.control-label(for="refId") {{'reclamations.details.EVIDENCEID'|translate}}
                        .col-sm-8(:class="{'form-control-static': !reclamation.refType}")
                            span(v-show="!reclamation.refType") {{reclamation.refId|receipt}}
                            input#refId.form-control(name="refId" v-show="reclamation.refType" v-model="reclamation.refId")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label.star-required(for="description") {{'reclamations.details.DESCRIPTION'|translate}}
                        .col-sm-8
                            textarea#description.form-control(name="description" v-model="reclamation.description" required)
                            OptiValidate(f="description" :data="$v.reclamation.description")
                    .form-group
                        label.col-sm-4.control-label(for="salon_comments") {{'reclamations.details.SALONCOMMENTS'|translate}}
                        .col-sm-8
                            textarea#salon_comments.form-control(name="salon_comments" v-model="reclamation.salon_comments")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label.star-required(for="noticed") {{'reclamations.details.DEFECTDATE'|translate}}
                        .col-sm-8
                            OptiDate#noticed(:value="reclamation.noticed" @update-date="d => reclamation.noticed = d")
                            OptiValidate(f="noticed" :data="$v.reclamation.noticed")

                    .form-group(style="padding-top:15px")
                        label.col-xs-12(for="demand1") {{'reclamations.details.DEMAND'|translate}}
                    .form-group
                        .col-xs-10
                            OptiSelectEnum#demand1(enum="ReclamationDemand1"
                                :value="reclamation.demand1" @update-enum="d => reclamation.demand1 = d")
                        .col-xs-2.text-center
                            //.btn.btn-default(:title="'reclamations.details.title.TIP'|translate"
                            //    :class="{'active': demand.option1}" @click="demand.option1 = !demand.option1")
                            //    i.fa.fa-question
                    .form-group(v-show="demand.option1")
                        .col-xs-12.text-expressive {{'reclamations.details.tips.TIPS1'|translate}}.
                    .form-group
                        .col-sm-10
                            OptiSelectEnum(enum="ReclamationDemand2"
                                :value="reclamation.demand2" @update-enum="d => reclamation.demand2 = d")
                        .col-xs-2.text-center
                            //.btn.btn-default(:title="'reclamations.details.title.TIP'|translate"
                            //    :class="{'active': demand.option2}" @click="demand.option2 = !demand.option2")
                            //    i.fa.fa-question
                    .form-group(v-show="demand.option2")
                        .col-xs-12.text-expressive
                            div {{'reclamations.details.tips.TIPS2'|translate}}
                            ul
                                li {{'reclamations.details.tips.TIPS3'|translate}},
                                li {{'reclamations.details.tips.TIPS4'|translate}},
                                li {{'reclamations.details.tips.TIPS5'|translate}}.

                OptiReclamationServices(:edit="true" type="task" :product-type="1"
                    :services="services" @update-services-invalid="b => services_invalid = b")
                .clearfix

                .col-md-offset-2.col-md-8
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiButtonSave(:disabled="$v.reclamation.$invalid || services_invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumReclamationServiceType} from '@/js/app/vue/enums/Enums'
    import {required} from 'vuelidate/lib/validators'
    import Vue from 'vue'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiReclamationServices from '@/js/app/vue/components/Reclamations/OptiReclamationServices'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationTaskEdit',
        components: {
            OptiDate,
            OptiSelectEnum,
            OptiValidate,
            OptiReclamationServices,
            OptiButtonSave,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                reclamation: {},
                services: {},
                services_invalid: true,

                demand: {
                    option1: true,
                    option2: true
                },
                permission: this.$uac.permission('tasks.reclamation.admin'),
                loading: true
            }
        },
        validations: {
            reclamation: {
                expected: {
                    required
                },
                clientCellphone: {
                    required
                },
                description: {
                    required
                },
                noticed: {
                    required
                }
            }
        },
        mounted() {
            API.get('tasks/reclamation/' + this.$state.params.id).then(res => {
                this.reclamation = res.data

                this.parse()

                this.$emit('update-breadcrumb', this.reclamation.statusName)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('reclamations.notify.error.LOAD')
            })
        },
        methods: {
            parse() {
                this.reclamation.services.forEach(service => {
                    if(!Object.keys(this.services).includes(service.type.toString())) {
                        Vue.set(this.services, service.type, [])
                    }

                    this.services[service.type].push({
                        service: {
                            id: service.id,
                            name: service.name
                        },
                        type: service.type,
                        description: service.pivot.description,
                        picture: service.pivot.picture ? 'task/reclamation/' + service.pivot.picture : null,
                        loading: false
                    })
                })

                EnumReclamationServiceType.simpleItems().forEach(reclamationServiceType => {
                    if(reclamationServiceType.id && !Object.keys(this.services).includes(reclamationServiceType.id.toString())) {
                        Vue.set(this.services, reclamationServiceType.id, [{
                            service: null,
                            type: reclamationServiceType.id,
                            description: null,
                            picture: null,
                            loading: false
                        }])
                    }
                })
            },

            editReclamation() {
                if(moment(this.reclamation.expected).diff(moment(), 'days') < 0) {
                    this.$notify.error('reclamations.notify.error.BACKDATE')
                } else if(moment(this.reclamation.expected).diff(moment(), 'days') >= 14) {
                    this.$notify.error('reclamations.notify.error.AFTER14')
                } else {
                    this.loading = true
                    this.reclamation.services = this.services

                    API.post('tasks/reclamation/edit', this.reclamation).then(res => {
                        if(res.data.status === 'OK') {
                            this.$state.go('app.tasks.reclamation.details', {id: this.reclamation.id})

                            this.$notify.success('reclamations.notify.EDIT')
                        } else {
                            this.$notify.error('reclamations.notify.error.EDIT')
                        }
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('reclamations.notify.error.EDIT')
                    })
                }
            }
        }
    }
</script>
