class UserHelperClass {
    constructor() {

    }

    getHighRoles(group) {
        let _return = {}

        group.forEach(group => {
            if(group.active) {
                group.departments.forEach(department => {
                    if(department.role_hierarchy > 8) {
                        _return[department.id] = department.roleId
                    }
                })
            }
        })

        return _return
    }
}

export const UserHelper = new UserHelperClass()