<template lang="pug">
    .opti-select-exchanges
        VueMultiSelect(
            :options="exchanges"
            :value="exchange"
            label="id"
            :clearable="false"
            :placeholder="'search.exchange.PLACEHOLDER'|translate"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            @input="updateSelected"
        )
            template(slot="singleLabel" slot-scope="props")
                small
                    span {{props.option.week}}
                    .pull-right \#{{props.option.id}}
                .text-center
                    span {{props.option.group1.name}}
                    span.fa.fa-arrows-h(style="padding: 0 7px")
                    span {{props.option.group2.name}}
            template(slot="option" slot-scope="props")
                small
                    span {{props.option.week}}
                    .pull-right \#{{props.option.id}}
                .text-center
                    span {{props.option.group1.name}}
                    span.fa.fa-arrows-h(style="padding: 0 7px")
                    span {{props.option.group2.name}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectExchangeByUser',
        components: {VueMultiSelect},
        props: {
            userId: {
                required: true
            }
        },
        data() {
            return {
                exchanges: [],
                exchange: null
            }
        },
        mounted() {
            API.post('search/user/exchange', {
                user_id: this.userId
            }).then(res => {
                this.exchanges = res.data

                this.exchanges.forEach((exchange => {
                    exchange.week = moment(exchange.start_date).format('w\\WGG')
                }))
            }).catch(() => {
                this.$notify.error('search.exchange.notify.error.LOAD')
            })
        },
        methods: {
            updateSelected(exchange) {
                this.exchange = exchange
                this.$emit('selected-exchange', exchange)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: .5;
    }
</style>
