<template lang="pug">
    .col-xs-12.text-center
        div(style="position:absolute;width:100%;padding-top:48px;padding-right:30px")
            OptiButton(type="info" :disabled="block" v-show="!task.packId" @click.native="moveFi") {{'tasks.frame.MOVEFI'|translate}}
                template(#icon)
                    i.fa.fa-arrow-up
        div(style="position:absolute;width:100%;padding-top:20px;padding-right:30px;bottom:20px")
            // to samo w CatalogOfferTaskCompareController.php:compare
            OptiButton(type="info" :disabled="!!(block || task['heightAdditionTop' + field] || task['heightAdditionBottom' + field] || task['widthTemporal' + field] || task['widthNasal' + field])"
                @click.native="checkData") {{'tasks.frame.CHECKDATA'|translate}}
                template(#icon)
                    i.fa.fa-refresh
        div
            img(style="width:100%" :src="'api/oma/' + (isOffer ? 'offer' + (isCompare ? '_compare' : '') : 'task') + '/image/' + task.id + '.jpg?refresh=' + increment + '&' + task.timestamp")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskDetailsLensesOma',
        components: {
            OptiButton
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },
            increment: {
                type: Number,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                field: this.isCompare ? '_2' : ''
            }
        },
        methods: {
            moveFi() {
                this.$emit('pending', true)

                API.get('oma/' + (this.isOffer ? 'offer' + (this.isCompare ? '_compare' : '') : 'task') + '/image/' + this.task.id + '.fi').then(res => {
                    this.task['rightLens' + (this.isCompare ? '2' : '')].minFi = res.data.right
                    this.task['leftLens' + (this.isCompare ? '2' : '')].minFi = res.data.left

                    this.$emit('lenses-was-updated')
                }).finally(() => {
                    this.$emit('pending', false)
                }).catch(() => {
                    this.$notify.error('tasks.frame.oma.notify.error.OMA', true, true)
                })
            },

            checkData() {
                this.$emit('pending', true)

                API.get('task/data/' + (this.isOffer ? 'offer' + (this.isCompare ? '_compare' : '') : 'task') + '/' + this.task.id + '/0').then(res => {
                    if(res.data.ok) {
                        this.$emit('register-change')
                        this.$emit('increment-up')

                        this.$notify.success('tasks.frame.oma.notify.THICKNESS', true, true)
                    } else if('error' in res.data) {
                        this.$notify.error(res.data.error, false)
                    } else {
                        this.$notify.error('tasks.frame.oma.notify.error.OMA')
                    }
                }).finally(() => {
                    this.$emit('pending', false)
                }).catch(() => {
                    this.$notify.error('tasks.frame.oma.notify.error.OMA')
                })
            }
        }
    }
</script>