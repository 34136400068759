<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="active")
            .panel.panel-primary(v-if="$asyncComputed.specification.success")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span Specyfikacja \#{{specification.id}}

                .panel-body
                    .col-sm-8
                        .row
                            .col-sm-4
                                label ID specyfikacji
                            .col-sm-8
                                span {{specification.id}}
                        .row
                            .col-sm-4
                                label Salon
                            .col-sm-8
                                span {{specification.departmentName}}
                        .row
                            .col-sm-4
                                label Ilość sprzedaży
                            .col-sm-8
                                span {{specification.sales_qty}}
                        .row
                            .col-sm-4
                                label Wartość sprzedaży
                            .col-sm-8
                                span {{specification.sales_value|currency}}
                        .row
                            .col-sm-4
                                label Skorygowana wartość sprzedaży
                            .col-sm-8
                                span {{specification.corrected_sales_value|currency}}
                        .row
                            .col-sm-4
                                label Data utworzenia
                            .col-sm-8
                                span {{specification.created_at}}
                    .col-sm-4
                        button.btn.btn-default(@click="optimaExport()" style="margin-right:5px") Eksport do OPTIMA
                        button.btn.btn-default(@click="excelExport()") Eksport do Excela
                    .row
                        .col-sm-12.table-responsive
                            table.table.table-striped
                                thead
                                    tr
                                        th.text-center #
                                        th.text-center ID sprzedaży
                                        th.text-left Netto
                                        th.text-left Brutto
                                        th.text-left Po rabacie
                                        th.text-left Sztuk w sprzedaży
                                        th.text-left Komentarz
                                tbody
                                    tr(v-for="(detail, index) in specification.details")
                                        td.text-right  {{index+1}}
                                        td.text-center {{detail.saleId}}
                                        td.text-left {{detail.netto_price|currency}}
                                        td.text-left {{detail.brutto_price|currency}}
                                        td.text-left {{detail.price_after_discount|currency}}
                                        td.text-left {{detail.items_qty}}
                                        td.text-left {{detail.comments}}
</template>

<script>
    import {API} from "@/js/app/vue/api";
    import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";

    export default {
        name: "OptiSaleSpecification",
        components: {OptiDimmer},
        data() {
            return {
                active: false
            }
        },
        asyncComputed: {
            async specification () {
                try {
                    this.active = true
                    let {data} = await API.get('specifications/sales/details/' + this.$state.params.id)
                    this.active = false
                    return data
                } catch(e) {
                    console.log(e)
                }
            }
        },
        methods: {
            async excelExport() {
                this.active = true
                await API.get('specifications/sales/details/export/' + this.$state.params.id)
                this.active = false
            },
            async optimaExport() {
                this.active = true
                await API.get('specifications/sales/details/optima/' + this.$state.params.id)
                this.active = false
            }
        }
    }
</script>

<style scoped>
    button {
        margin: 5px
    }
</style>