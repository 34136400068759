<template lang="pug">
    .opti-select-change-active-department(v-if="visible")
        .flex
            OptiSelectDepartment(
                :simple="true"
                :departmentId="departmentId"
                :reduce="department => department.id"
                @selected="updateDepartment"
            )
            OptiButton(
                v-if="backToHQPossible"
                @click.native="backToHQ()"
            )
                span.fa.fa-rotate-left
</template>

<script>
    import OptiSelectDepartment from './OptiSelectDepartment'
    import OptiButton from '../Button/OptiButton'
    export default {
        name: 'OptiSelectChangeActiveDepartment',
        components: {OptiButton, OptiSelectDepartment},
        computed: {
            departmentId() {
                if (!this.$uac.isAuthenticated()) return 0
                return this.$uac.user.activeDepartmentId
            },
            backToHQPossible() {
                if (!this.$uac.isAuthenticated()) return false
                return this.$uac.user.activeRole.admin && this.$uac.user.activeDepartmentId !== 91
            },
            visible() {
                if (!this.$uac.isAuthenticated()) return false
                return this.$uac.user.department.length > 1
            }
        },
        methods: {
            updateDepartment(departmentId) {
                if (this.$uac.user.activeDepartmentId !== departmentId) {
                    this.$uac.changeDepartment(departmentId)
                }
            },
            backToHQ() {
                this.updateDepartment(91)
            }
        },
    }
</script>

<style scoped lang="less">
    .opti-select-change-active-department {
        display: inline-block;

        .flex {
            display: flex;

            & div {
                flex: 1;
            }

            & .opti-select-department {
                display: inline-block;
            }
        }
    }
</style>
