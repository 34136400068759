<template lang="pug">
    .col-xs-12
        .table-responsive
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tbody
                    tr.separate
                        td(style="padding:0;width:35%" colspan="2")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-presenter-bold.table-striped.table-condensed
                                    tbody
                                        tr(v-if="isOffer && !rateSave")
                                            td.text-center(colspan="2")
                                                OptiRateWrapper(
                                                    :header="true"
                                                    :load="false"
                                                    :rate="task.rate"
                                                    :ref-type="2"
                                                    :ref-id="task.id"

                                                    @reload="$emit('rate-save')")
                                        tr(v-show="!isOffer")
                                            th {{'tasks.STATUS'|translate}}
                                            td {{task.statusName|translate}}
                                        tr(v-show="task.created_at !== '-0001-11-30'")
                                            th {{'tasks.date.summary.C'|translate}}
                                            td {{task.created_at}}
                                        tr(v-show="!isOffer && task.started")
                                            th {{'tasks.date.summary.S'|translate}}
                                            td {{task.started}}
                                        tr(v-show="task.confirmed_at")
                                            th {{'tasks.date.summary.D'|translate}}
                                            td {{task.confirmed_at}}
                                        tr
                                            th
                                                label(style="font-weight:400;margin-bottom:0" for="date-expected") {{'tasks.date.summary.E'|translate}}
                                            td
                                                OptiDate#date-expected(v-show="allowEditConfirmed"
                                                    :value="task.expected" @update-date="updateDate")
                                                span(v-show="!allowEditConfirmed") {{task.expected}}
                                        tr(v-show="task.finished")
                                            th {{'tasks.date.summary.F'|translate}}
                                            td {{task.finished}}
                                        tr(v-show="shipment_exists")
                                            th {{'shipments.SHIPMENTS'|translate}}
                                            th(NOWRAP)
                                                OptiShipmentNotice(:type="4" :id="task.id"
                                                    @shipment-exists="s => shipment_exists = s")

                        td(style="padding:0")
                            .table-responsive
                                table.table.table-half.table-presenter-left.table-presenter-bold.table-striped.table-condensed
                                    tbody
                                        tr
                                            th {{'tasks.TASKTYPE'|translate}}
                                            td {{task.typeName|translate}}
                                        tr(v-show="task.prescriptionId > 0")
                                            th {{'tasks.PRESCRIPTIONID'|translate}}
                                            td
                                                OptiButtonPrescription(:id="task.prescriptionId")
                                        tr
                                            th {{'tasks.PATIENT'|translate}}
                                            td
                                                OptiPatient(:data="task.patient")
                                        tr
                                            th {{'tasks.DEPARTMENT'|translate}}
                                            td {{task.departmentName}}
                                        tr
                                            th {{'tasks.EMPLOYEE'|translate}}
                                            td {{task.employee.firstName}} {{task.employee.lastName}}
                                        tr(v-show="task.departmentTaskId")
                                            th
                                                span(v-show="taskDuplicate") {{'tasks.duplicate.DUPLICATE'|translate}}
                                                span(v-show="taskService") {{'tasks.duplicate.SERVICE'|translate}}
                                            td
                                                a.link(:href="$state.href('app.task.detail', {id: task.departmentTaskId})")
                                                    span.mr3 {{task.departmentTaskId}}
                                                    i.fa.fa-gear
                                        tr(v-show="task.serviceContract.utilizedTaskId")
                                            th {{'tasks.US'|translate}}
                                            td
                                                a.link(:href="$state.href('app.task.detail', {id: task.serviceContract.utilizedTaskId})")
                                                    span.mr3 {{task.serviceContract.utilizedTaskId}}
                                                    i.fa.fa-gears
</template>

<script>
    import OptiRateWrapper from '@/js/app/vue/components/Rate/OptiRateWrapper'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiButtonPrescription from '@/js/app/vue/components/Button/OptiButtonPrescription'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'

    export default {
        name: 'OptiTaskDetailsSummaryBasicData',
        components: {
            OptiRateWrapper,
            OptiDate,
            OptiShipmentNotice,
            OptiButtonPrescription,
            OptiPatient
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            rateSave: { //wydaje się to bez sensu, ale daje to reaktywność
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                shipment_exists: false
            }
        },
        methods: {
            updateDate(d) {
                this.task.expected = d

                this.$emit('summary-was-updated')
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .input-group-date {
        width: 140px;

        > input#date-expected {
            height: 25px;
            padding: 0 7px;
        }

        > .input-group-btn > .btn {
            height: 25px;
            padding-top: 2px;

            > .fa-calendar {
                font-size: 14px;
            }
        }
    }
</style>