<template lang="pug">
    .grid-product(:class="{'product-invalid': !supply && wrongCategory}")
        .placeholder(v-show="!model.id")
            span.text-animated-alert < {{'layout.products.NONE'|translate}} >

        .product(v-show="model.id")
            .product-tooltip
                .product-tooltip-relative
                    img.img-responsive(:src="'https://salony.eopti.pl/thumb/product/ids/' + model.product_id + '.jpg'")
                    .special-price(v-show="specialPrice") {{specialPrice}}
                .text-center.bold
                    span.text-muted.mr3 {{model.product.id}}
                    span.mr3 {{model.product.name}}
                    span.text-muted {{model.status}}

            .row
                .col-xs-4(style="position:relative;padding-right:7px")
                    img.img-responsive(:src="'https://salony.eopti.pl/thumb/product/ids/' + model.product_id + '.jpg'")
                    .bold.warn(style="position:absolute;right:7px;bottom:0;font-size:.7em" v-show="specialPrice") {{specialPrice}}
                .col-xs-8
                    div
                        span.mr3 {{model.product.id}}
                        span.text-muted.bold {{model.status}}
                a.product-link(:href="$state.href('app.catalog.product', {id: model.product.id, departmentId: $state.params.departmentId})" target="_blank")
                    i.fa.fa-2x.fa-external-link.text-expressive

        .original-category.text-sm.text-muted(v-if="!supply && wrongCategory") {{model.product.customer_product.category.name}}
</template>

<script>
    export default {
        name: 'OptiLayoutProductsGridProduct',
        props: {
            model: {
                type: Object,
                required: true
            },
            categoryId: {
                type: Number,
                required: true
            },
            supply: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            wrongCategory() {
                if(this.supply || !this.model || !this.model.product_id) return false

                return this.categoryId !== this.model.product.customer_product.categoryId
            },
            specialPrice() {
                let customerProduct = this.model.product.customer_product
                if(!customerProduct || !customerProduct.specialPrice) {
                  return false
                }

                if(Number(customerProduct.specialPriceBeforeValue) > 0
                    && Number(customerProduct.specialPriceBeforeValue) !== Number(customerProduct.specialPriceValue)) {

                  let percent = (parseFloat(customerProduct.specialPriceValue) * 100) / customerProduct.specialPriceBeforeValue
                  percent = 100 - (Math.round(percent / 10) * 10)

                  return '-' + percent + '%'
                }

                return ''
            }
        }
    }
</script>