<template lang="pug">
    OptiDialog
        template(#header)
            i.icon.icon-book-open.mr3
            span {{'prescription.foreign.survey.logs.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .panel.panel-primary(v-for="survey in surveys")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{survey.data[0].type_name|translate}}
                    span.warn.mr3 {{survey.data[0].survey_at}}
                    small {{survey.data[0].user.firstName}} {{survey.data[0].user.lastName}}

                    .pull-right(v-show="permission")
                        span.warn(v-show="survey.time_scope === 0")
                            span.mr3 {{survey.time_diff|secondsToHuman}}
                            i.fa.fa-sort-desc
                        span.warn(v-show="survey.time_scope === 2")
                            span.mr3 {{survey.time_diff|secondsToHuman}}
                            i.fa.fa-sort-asc
                        span(v-show="![0, 2].includes(survey.time_scope)")
                            span.mr3 {{survey.time_diff|secondsToHuman}}
                            i.fa.fa-sort(style="visibility:hidden")

                component(:is="OptiPatientsPrescriptionDialogsSurvey(survey.data[0].type)" :survey="survey")

        template(#footer)
            OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    import OptiPatientsPrescriptionDialogsSurveyFar
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/Surveys/OptiPatientsPrescriptionDialogsSurveyFar'
    import OptiPatientsPrescriptionDialogsSurveyNear
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/Surveys/OptiPatientsPrescriptionDialogsSurveyNear'

    export default {
        name: 'OptiPatientsPrescriptionDialogSurveyHistory',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer,

            OptiPatientsPrescriptionDialogsSurveyFar,
            OptiPatientsPrescriptionDialogsSurveyNear
        },
        props: {
            checkId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                surveys: [],

                permission: this.$uac.permission('optometrist.survey_history_time'),

                loading: true
            }
        },
        mounted() {
            API.get('patients/prescriptions/survey/' + this.checkId).then(res => {
                this.surveys = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('prescription.foreign.survey.logs.notify.error.LOAD')
            })
        },
        methods: {
            OptiPatientsPrescriptionDialogsSurvey(type) {
                return 'OptiPatientsPrescriptionDialogsSurvey' + _.startCase(type)
            }
        }
    }
</script>
