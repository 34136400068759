<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-meh-o.mr3
            span {{'tasks.action.cancelled.received.GOCANCELLED'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-12(style="padding-bottom:15px") {{'tasks.action.cancelled.received.confirm.MESSAGE'|translate}}
                .col-xs-12
                    label.bold(style="display:block" for="comments-cancel") {{'tasks.action.cancelled.received.REASON'|translate}}
                    textarea#comments-cancel.form-control(name="comments" rows="5" v-model="comments"
                        :placeholder="'tasks.action.cancelled.received.PLACEHOLDER'|translate")
                    label.bold.warn(style="display:block" for="comments-cancel" v-show="!comments"
                        ) {{'validation.formValidate.required'|translate}}

        template(#footer)
            OptiButton(type="success" :disabled="!comments" @click.native="$emit('confirmed', comments)"
                ) {{'tasks.action.cancelled.received.confirm.YES'|translate}}
                template(#icon)
                    i.fa.fa-check
            OptiButton(@click.native="$emit('closed')") {{'tasks.action.cancelled.received.confirm.NO'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiTaskDetailsSummaryDialogCancelReceived',
        components: {
            OptiDialog,
            OptiButton: () => import('../../../../Button/OptiButton')
        },
        props: {

        },
        data() {
            return {
                comments: ''
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;
    }
</style>