<template lang="pug">
    .opti-select-source-patient
        VueMultiSelect(
            id="soruce-patient"
            :options="sources"
            :value="source"
            :clearable="false"
            label="name"
            placeholder="Jak do nas trafił?"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            @input="updateSelected"
            group-label="type"
            group-values="sources"
            :group-select="false"
            :searchable="true"
            @search-change="typeData"
            :disabled="disabled")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel") {{ props.option.name }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import {EnumPatientSource} from '../../enums/Enums'
    import {i18n} from '../../i18n-setup'

    export default {
        name: 'OptiSelectSourcePatient',
        components: {VueMultiSelect},
        props: {
            source: {
                type: String,
                required: true,
                default: ''
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                sources: [{
                    type: 'Nowy wpis',
                    sources: []
                }, {
                    type: 'Pozycje domyślne',
                    sources: EnumPatientSource.simpleItems().map(member => {
                        member.name = i18n.t(member.name)
                        return member
                    })
                }]
            }
        },
        mounted() {
            this.typeData(this.source)
        },
        methods: {
            typeData(data) {
                if(data.length) {
                    let exists = false

                    this.sources[1].sources.forEach((source) => {
                        if(data === source.name) { // translate ma być po stronie js poprawić
                            exists = true
                        }
                    })

                    if(!exists) {
                        this.sources[0].sources = [{
                            id: data,
                            name: data
                        }]
                    }
                } else {
                    this.sources[0].sources = []
                }
            },
            updateSelected(source) {
                this.$emit('selected', source)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
