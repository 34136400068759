import {API} from '@/js/app/vue/api'

//klucz do shopware AIzaSyBVpy8a_NFtJgfLtxWUGLLZI6d3iU-rkS8

class GoogleVisionAPIClass {
    constructor() {
        this.apiKey = 'AIzaSyADe6lya-Yw91tl_w-FnW8O8jlVCPZWfxs'
        this.apiUrl = 'https://vision.googleapis.com/v1/images:annotate?key=' + this.apiKey
        this.base64 = true

        this.feature = {
            TYPE_UNSPECIFIED: 'TYPE_UNSPECIFIED',
            FACE_DETECTION: 'FACE_DETECTION',
            LANDMARK_DETECTION: 'LANDMARK_DETECTION',
            LOGO_DETECTION: 'LOGO_DETECTION',
            LABEL_DETECTION: 'LABEL_DETECTION',
            TEXT_DETECTION: 'TEXT_DETECTION',
            DOCUMENT_TEXT_DETECTION: 'DOCUMENT_TEXT_DETECTION',
            SAFE_SEARCH_DETECTION: 'SAFE_SEARCH_DETECTION',
            IMAGE_PROPERTIES: 'IMAGE_PROPERTIES',
            CROP_HINTS: 'CROP_HINTS',
            WEB_DETECTION: 'WEB_DETECTION'
        }
    }

    annotate(image, features) {
        if(!Array.isArray(features)) {
            features = [{
                type: features,
                maxResults: 1
            }]
        }

        if(this.base64) {
            image = {
                content: image
            }
        } else {
            image = {
                source: {
                    imageUri: image
                }
            }
        }

        let request = {
            requests: [
                {
                    image: image,
                    features: features
                }
            ]
        }

        return API.post(this.apiUrl, request)
    }

    text(image) {
        return this.annotate(image, this.feature.TEXT_DETECTION)
    }

    documentText(image) {
        return this.annotate(image, this.feature.DOCUMENT_TEXT_DETECTION)
    }
}

export const GoogleVisionAPI = new GoogleVisionAPIClass()