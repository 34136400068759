<template lang="pug">
    table.table.table-striped.table-bordered.table-condensed
        thead
            tr
                th
                th
                    div.line-legend(:style="{ backgroundColor: colors.real, borderColor: colors.real}")
                    span Realny
                th
                    div.line-legend(:style="{ backgroundColor: colors.reached, borderColor: colors.reached}")
                    span Os/Skor
                th
                    div.line-legend(:style="{ backgroundColor: colors.ambitious, borderColor: colors.ambitious}")
                    span Ambitny
                th(v-if="table[0].central")
                    div.line-legend(:style="{ backgroundColor: colors.central, borderColor: colors.central}")
                    span Centralny

        tbody
            tr(v-for="row in table")
                th {{ row.name }}
                td.no-brake.text-right {{ row.real | currency({fractionCount:0}) }}
                td.no-brake.text-right {{ row.reached | currency({fractionCount:0}) }}
                td.no-brake.text-right {{ row.ambitious | currency({fractionCount:0}) }}
                td.no-brake.text-right(v-if="row.central") {{ row.central | currency({fractionCount:0}) }}
</template>

<script>
    export default {
        name: 'OptiChartDepartmentBudgetTooltip',
        props: {
            tooltip: {
                type: Object,
                required: true
            },
            chartData: {
                type: Object,
                required: true
            }
        },
        data() {
            return  {
                colors: {
                    real: 'rgb(238,66,47)',
                    reached: '#0f2080',
                    ambitious: 'rgba(99,172,190, 0.5)',
                    central: '#a95aa1'
                }
            }
        },
        computed: {
            table() {
                let ds = this.chartData
                let idx = this.tooltip.dataPoints[0].index || 0

                let table = [
                    {
                        name: 'Optyka',
                        real: ds.datasets[0].data[idx],
                        reached: ds.datasets[2].data[idx],
                        ambitious: ds.datasets[4].data[idx],
                        central: ds.datasets[6].data[idx],
                    },
                    {
                        name: 'Sprzedaż',
                        real: ds.datasets[1].data[idx],
                        reached: ds.datasets[3].data[idx],
                        ambitious: ds.datasets[5].data[idx],
                        central: ds.datasets[7].data[idx],
                    },
                    {
                        name: 'Razem',
                        real: parseInt(ds.datasets[0].data[idx]) + parseInt(ds.datasets[1].data[idx]),
                        reached: parseInt(ds.datasets[2].data[idx]) + parseInt(ds.datasets[3].data[idx]),
                        ambitious: parseInt(ds.datasets[4].data[idx]) + parseInt(ds.datasets[5].data[idx]),
                        central: parseInt(ds.datasets[6].data[idx]) + parseInt(ds.datasets[7].data[idx]),
                    }
                ]

                if (!this.$uac.hasRole('schedule.manager')) {
                    delete table[0].columns.central
                    delete table[1].columns.central
                    delete table[2].columns.central
                }

                return table
            }
        }
    }
</script>
