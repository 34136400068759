<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary(style="position:relative;margin-bottom:0" v-if="category.data.length > 1")
                table.table.table-striped.middle
                    thead
                        tr.align-middle
                            th {{'dictionary.hoya.table.LONGNAME'|translate}}
                            th {{'dictionary.hoya.table.ILOGCODE'|translate}}
                            th(v-show="isSunLoox") {{'dictionary.hoya.table.SUBSTITUTE'|translate}}
                            th.text-right {{'dictionary.hoya.table.HOYABRUTTO'|translate}}
                            th.text-right {{'dictionary.hoya.table.HOYANETTO'|translate}}
                            th.text-right {{'dictionary.hoya.table.CATALOG'|translate}}
                            th.text-right
                                .hoya-price-edit
                                    span.mr3 {{'dictionary.hoya.table.NETTO'|translate}}
                                    .input-group(v-if="edit")
                                        input.form-control(v-model="netto_percent" @change="changedNettoPercentAll")
                                        span.input-group-addon %
                                    span.text-muted(v-if="!edit && netto_percent") {{netto_percent}}%
                            th.text-right {{'dictionary.hoya.table.ZHC'|translate}}

                    tbody
                        tr.align-top(v-for="(item, index) in category.data" :key="index")
                            td {{item.name_long}}
                            td {{item.ilog_code}}
                            td(v-if="isSunLoox")
                                i.fa.fa-exclamation-circle.mr3(v-show="item.changes.substitute")
                                span {{item.substitute.name}}

                            td.text-right
                                i.fa.fa-exclamation-circle.mr3(v-show="item.changes.hoya_brutto")
                                span {{item.hoya_lens_price_group[0].hoya_brutto|currency}}
                            td.text-right
                                i.fa.fa-exclamation-circle.mr3(v-show="item.changes.hoya_netto")
                                span {{item.hoya_lens_price_group[0].hoya_netto|currency}}
                            td.text-right(style="border-left:solid;border-width:1px")
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.brutto_price")
                                    input.form-control(v-if="edit" v-model="item.hoya_lens_price_group[0].brutto_price"
                                        @input="item.changes.brutto_price = true")
                                    span(v-else) {{item.hoya_lens_price_group[0].brutto_price|currency}}
                            td
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.netto_percent || item.changes.netto_price")
                                    div(v-if="edit")
                                        .input-group
                                            input.form-control(v-model="item.hoya_lens_price_group[0].netto_percent"
                                                @input="changedNettoPercent(index)")
                                            span.input-group-addon %
                                        .text-muted {{item.hoya_lens_price_group[0].netto_price|currency}}
                                    span(v-else)
                                        span.mr3 {{item.hoya_lens_price_group[0].netto_price|currency}}
                                        small.text-muted ({{item.hoya_lens_price_group[0].netto_percent}}%)

                            td
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.zhc")
                                    input.form-control(v-if="edit" v-model="item.hoya_lens_price_group[0].zhc"
                                        @input="changedZhc(index)")
                                    span(v-else) {{item.hoya_lens_price_group[0].zhc|currency}}

                OptiDimmer(:active="loading")
</template>

<script>
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiHoyaPriceGroupEdit',
        components: {
            OptiDimmer
        },
        props: {
            edit: {
                type: Boolean,
                required: true
            },
            isSunLoox: {
                type: Boolean,
                required: true
            },

            category: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                netto_percent: null,

                loading: false
            }
        },
        mounted() {
            this.setNettoPercent()
        },
        methods: {
            changedNettoPercentAll() {
                this.category.data.forEach((row, index) => {
                    if(this.netto_percent && parseInt(row.hoya_lens_price_group[0].netto_percent) !== parseInt(this.netto_percent)) {
                        row.hoya_lens_price_group[0].netto_percent = this.netto_percent

                        this.changedNettoPercent(index, false)
                    }
                })
            },
            changedNettoPercent(index, setNettoPercent) {
                setNettoPercent = typeof setNettoPercent === 'undefined' ? true : setNettoPercent

                this.category.data[index].changes.netto_percent = true
                this.category.data[index].changes.netto_price = true

                this.nettoPrice(index)

                if(setNettoPercent) {
                    this.setNettoPercent()
                }
            },
            changedZhc(index) {
                this.category.data[index].changes.zhc = true
                this.category.data[index].changes.netto_price = true

                this.nettoPrice(index)
            },
            nettoPrice(index) {
                this.category.data[index].hoya_lens_price_group[0].netto_price =
                    _.round(this.category.data[index].hoya_lens_price_group[0].zhc *
                    (1 + this.category.data[index].hoya_lens_price_group[0].netto_percent / 100), 2)
            },
            setNettoPercent() {
                this.netto_percent = this.category.data[0].hoya_lens_price_group[0].netto_percent

                this.category.data.forEach(row => {
                    if(parseInt(row.hoya_lens_price_group[0].netto_percent) !== parseInt(this.netto_percent)) {
                        this.netto_percent = null
                    }
                })
            }
        }
    }
</script>