<template lang="pug">
    .row
        .col-xs-12.well.well-sm.form-horizontal(style="display:flex;flex-wrap:wrap")
            div(style="flex:2")
                OptiSelectSnapshot(type="from" :date="week.from" :opposite="week.to" @selected="w => week.from = w")
                OptiValidate(f="week_from" :data="$v.week.from")

            div(style="flex:2")
                OptiSelectSnapshot(type="to" :date="week.to" :opposite="week.from" @selected="w => week.to = w")
                OptiValidate(f="week_to" :data="$v.week.to")

            OptiButton(style="flex:1" type="success" :disabled="$v.week.$invalid || loading" @click.native="downloadXLS"
                ) {{'report.snapshot.buttons.DOWNLOAD'|translate}}
                template(#icon)
                    i.fa.fa-download

            OptiButton(style="flex:1" type="primary" :disabled="$v.week.$invalid || loading" @click.native="loadData"
                ) {{'report.snapshot.buttons.PREVIEW'|translate}}
                template(#icon)
                    i.fa.fa-search

            OptiButton(style="flex:1" type="warning" :disabled="$v.week.$invalid || loading" @click.native="chart = true"
                ) {{'report.snapshot.buttons.SWITCH'|translate}}
                template(#icon)
                    i.fa.fa-pie-chart

        div(v-if="chart")
            .clearfix
            OptiChartSnapshotTreemap(:week-from="week.from.id" :week-to="week.to.id")
            OptiChartSnapshotTreemap(:week-from="week.from.id" :week-to="week.to.id")

        OptiFlexBox(v-if="!chart && !loading && data")
            table#table-snapshot.table.table-striped.table-hover.table-condensed.table-bordered
                thead
                    tr
                        th.bg-separator.text-right #
                        th.bg-separator
                        th.bg-separator.text-left(width="50%") {{'report.snapshot.table.BRAND'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.FACING'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.WAREHOUSE'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.SOLD'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.RETAIL'|translate}}
                        th.bg-separator.text-right(v-show="is_admin") {{'report.snapshot.table.ZHC'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.DISCOUNT'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.MARGIN'|translate}}
                        th.bg-separator.text-right(v-show="is_admin") {{'report.snapshot.table.MARGINZHC'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.MARGINM'|translate}}
                        th.bg-separator.text-right {{'report.snapshot.table.STORE'|translate}}

                tbody
                    tr(v-for="(item, key) in data.items")
                        td.text-right {{key + 1}}
                        td
                            span(v-show="item.type === 'sun'") ☀
                        td.text-left
                            a.link(:href="$state.href('app.catalog.category', {id: item.key})" target="_blank") {{item.name}}
                        td.text-right.monospace(:class="textColor(item.sum.layout_to)") {{item.sum.layout_to|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.stock)") {{item.sum.stock|number(0)}}
                        td.text-right.monospace {{item.sum.sold_quantity|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.wholesale_value)") {{item.sum.wholesale_value|number(0)}}
                        td.text-right.monospace(v-show="is_admin" :class="textColor(item.sum.purchase_value)") {{item.sum.purchase_value|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.selling)") {{item.sum.selling|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.turnout_department)") {{item.sum.turnout_department|number(0)}}
                        td.text-right.monospace(v-show="is_admin" :class="textColor(item.sum.turnout_central)") {{item.sum.turnout_central|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.turnout_per_place)") {{item.sum.turnout_per_place|number(0)}}
                        td.text-right.monospace(:class="textColor(item.sum.stock_rotation)") {{item.sum.stock_rotation|number(2)}}

                tfoot
                    tr
                        th.text-right(colspan=3) ∑
                        th.text-right.monospace {{data.sum.layout_to|number(0)}}
                        th.text-right.monospace {{data.sum.stock|number(0)}}
                        th.text-right.monospace {{data.sum.sold_quantity|number(0)}}
                        th.text-right.monospace(:class="textColor(data.sum.wholesale_value)") {{data.sum.wholesale_value|number(0)}}
                        th.text-right.monospace(v-show="is_admin" :class="textColor(data.sum.purchase_value)") {{data.sum.purchase_value|number(0)}}
                        th.text-right.monospace(:class="textColor(data.sum.selling)") {{data.sum.selling|number(0)}}
                        th.text-right.monospace(:class="textColor(data.sum.turnout_department)") {{data.sum.turnout_department|number(0)}}
                        th.text-right.monospace(v-show="is_admin" :class="textColor(data.sum.turnout_central)") {{data.sum.turnout_central|number(0)}}
                        th.text-right.monospace {{(data.sum.turnout_department + data.sum.turnout_central) / data.sum.layout|number(0)}}
                        th.text-right.monospace {{data.sum.layout_to / (52 * data.sum.sold_quantity / weeks)|number(2)}}

        .col-xs-12
            OptiDimmer(:active="!chart && loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiSelectSnapshot from '@/js/app/vue/components/Select/OptiSelectSnapshot'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiChartSnapshotTreemap from '@/js/app/vue/components/Chart/OptiChartSnapshotTreemap'
    import OptiFlexBox from '@/js/app/vue/components/OptiFlexBox'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportSnapshots',
        components: {
            OptiSelectSnapshot,
            OptiValidate,
            OptiButton,
            OptiChartSnapshotTreemap,
            OptiFlexBox,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                department: {
                    id: null
                },
                week: {
                    from: {},
                    to: {}
                },

                data: false,

                is_admin: this.$uac.user.activeRole.admin,
                units: false,
                chart: false,
                loading: true
            }
        },
        validations: {
            week: {
                from: {
                    required
                },
                to: {
                    required
                }
            }
        },
        mounted() {
            API.get('reports/snapshots/weeks').then(res => {
                res.data.forEach(item => {
                    item.departments = item.departments.split(',')
                })

                this.week.from = res.data[0]
                this.week.to = res.data[0]
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('report.snapshot.notify.error.SEARCH')
            })
        },
        methods: {
            downloadXLS() {
                if(this.$v.week.$invalid) return false

                let from = moment(this.week.from.start),
                    to = moment(this.week.to.start),
                    filename = this.$uac.user.activeDepartmentId + '_' + moment().format('YYYYMMDDHHmmss') + '_snapshot_' +
                        from.format('YYYY') + 'wk' + from.format('W') + '_' + to.format('YYYY') + 'wk' + to.format('W')

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: filename + '.xls',
                        status: true
                    }
                })

                API.post('reports/snapshots/export', {
                    weekFrom: this.week.from.id,
                    weekTo: this.week.to.id,
                    department: this.is_admin ? -1 : this.department.id,
                    includeUnits: this.units,
                    filename: filename
                }).catch(() => {
                    this.$notify.error('default.basetable.notify.error.EXCEL')
                })
            },
            loadData() {
                if(this.$v.week.$invalid) return false

                this.loading = true
                this.chart = false

                API.post('reports/snapshots', {
                    weekFrom: this.week.from.id,
                    weekTo: this.week.to.id,
                }).then(res => {
                    this.data = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.snapshot.notify.error.LOAD')
                })
            },

            textColor(val) {
                if (val < 0) {
                    return 'text-danger'
                }

                return ''
            }
        },
        computed: {
            weeks() {
                let from = moment(this.week.from.start),
                    to = moment(this.week.to.start)

                return to.diff(from, 'week') + 1
            }
        }
    }
</script>

<style lang="less" scoped>
    #table-snapshot {
        > thead > tr > th {
            position:sticky;
            position:-webkit-sticky;
            z-index:5;
            top:0;
        }

        > tbody > tr > td.monospace,
        > tfoot > tr > th.monospace {
            white-space: nowrap;
        }
    }
</style>