<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form.form-horizontal(name="formStockCorrection" @submit.prevent="editStockCorrection")
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.DATE'|translate}}
                    .col-sm-8.form-control-static {{stock.date}}
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.USER'|translate}}
                    .col-sm-8.form-control-static {{stock.user.firstName}} {{stock.user.lastName}} ({{stock.department.name}})
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.PRODUCT'|translate}}
                    .col-sm-8.form-control-static
                        OptiProduct(:product="stock.product") {{stock.product.name}} ({{stock.product.id}})
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.STATUS'|translate}}
                    .col-sm-8.form-control-static {{stock.status_name|translate}}
                .form-group(v-show="stock.change_date !== '0000-00-00 00:00:00'")
                    label.col-sm-4.control-label {{'catalog.stock.table.CHANGEDATE'|translate}}
                    .col-sm-8.form-control-static {{stock.change_date}}
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.STOCK'|translate}}
                    .col-sm-8.col-sm-offset-4
                .form-group
                    label.col-sm-4.control-label(for="stock")
                        i.fa.fa-dropbox
                    .col-sm-8
                        OptiNumber#stock(style="text-align:left" :header="'catalog.product.correct.STOCK'|translate"
                            :disabled="disabled || !permission.correct" :minus="true"
                            :value="stock.stock" @confirmed="s => stock.stock = s") {{stock.stock}}
                .form-group
                    label.col-sm-4.control-label(for="ordered")
                        i.fa.fa-envelope
                    .col-sm-8
                        OptiNumber#ordered(style="text-align:left" :header="'catalog.product.correct.ORDERED'|translate"
                            :disabled="disabled || !permission.correct" :minus="true"
                            :value="stock.ordered" @confirmed="o => stock.ordered = o") {{stock.ordered}}
                .form-group
                    label.col-sm-4.control-label(for="expected")
                        i.fa.fa-car
                    .col-sm-8
                        OptiNumber#expected(style="text-align:left" :header="'catalog.product.correct.EXPECTED'|translate"
                            :disabled="disabled || !permission.correct" :minus="true"
                            :value="stock.expected" @confirmed="e => stock.expected = e") {{stock.expected}}
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.CURRENT'|translate}}
                    .col-sm-8.form-control-static
                        i.fa.fa-dropbox.mr3
                        span {{stock.current_stock}}
                        i.fa.fa-envelope.mr3(style="padding-left:10px")
                        span(style="padding-right:10px") {{stock.current_ordered}}
                        i.fa.fa-car.mr3
                        span {{stock.current_expected}}
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.CORRECT'|translate}}
                    .col-sm-8.form-control-static
                        i.fa.fa-dropbox.mr3
                        span {{stock.correct_stock}}
                        i.fa.fa-envelope.mr3(style="padding-left:10px")
                        span(style="padding-right:10px") {{stock.correct_ordered}}
                        i.fa.fa-car.mr3
                        span {{stock.correct_expected}}
                .form-group
                    label.col-sm-4.control-label {{'catalog.stock.table.COMMENTS'|translate}}
                    .col-sm-8.form-control-static {{stock.comments}}
                .form-group
                    label.col-sm-4.control-label(for="comments_admin") {{'catalog.stock.table.COMMENTSADMIN'|translate}}
                    .col-sm-8
                        textarea.form-control#comments_admin(rows="4" name="comments_admin" v-model="stock.comments_admin"
                            :disabled="disabled || !permission.correct")
                .form-group
                    label.col-sm-4.control-label(for="fee") {{'catalog.stock.table.FEE'|translate}}
                    .col-sm-8
                        OptiNumber#fee(style="text-align:left" :header="'catalog.stock.table.FEE'|translate"
                            :disabled="disabled || !permission.correct" :float="true"
                            :value="stock.fee" @confirmed="f => stock.fee = f") {{stock.fee|currency}}
                .form-group(v-show="stock.changeUser.id")
                    label.col-sm-4.control-label {{'catalog.stock.table.CHANGEUSER'|translate}}
                    .col-sm-8.form-control-static {{stock.changeUser.firstName}} {{stock.changeUser.lastName}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="disabled" v-if="permission.correct" )

                        OptiButton.pull-right(type="danger" :disabled="disabled" v-if="permission.correct"
                            text="catalog.stock.rejected.confirm" @confirmed="goRejected") {{'catalog.stock.rejected.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-remove

                        OptiButton.pull-right.mr3(type="danger" :disabled="disabled"
                                        v-if="permission.cancel"
                                        text="catalog.stock.canceled.confirm" @confirmed="goCanceled") {{'catalog.stock.canceled.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-remove

        .col-xs-12(v-show="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'catalog.product.stockhistory.STOCKHISTORY'|translate}}

                .table-responsive
                    table.table.table-striped
                        thead
                            tr
                                th #
                                th {{'catalog.product.stockhistory.AFTERCHANGESTATE'|translate}}
                                th {{'catalog.product.stockhistory.CHANGESTATE'|translate}}
                                th {{'catalog.product.stockhistory.TYPECHANGE'|translate}}
                                th {{'catalog.product.stockhistory.IDDOC'|translate}}
                                th {{'catalog.product.stockhistory.DETAIL'|translate}}
                                th {{'catalog.product.stockhistory.DATECHANGE'|translate}}
                        tbody
                            tr(v-for="(log, key) in logs")
                                td.text-right {{logs.length - key}}
                                td.text-right {{log.stockAfterChange}}
                                td.text-right {{log.change}}
                                td {{log.referenceTypeName|translate}}
                                td.text-right
                                    OptiRefId(section="product-history" :type-id="log.referenceType" :id="log.referenceId")
                                td {{log.description}}
                                td {{log.created|noYearSek}}

                            tr(v-show="!logs.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductStockChangeEdit',
        components: {
            OptiProduct,
            OptiNumber,
            OptiButtonSave,
            OptiButton,
            OptiRefId,
            OptiDimmer
        },
        data() {
            return {
                stock: {},
                logs: [],

                loading: true,

                permission: {
                    correct: this.$uac.permission('products.correct'),
                    cancel: this.$uac.permission('products.correct_cancel')
                }
            }
        },
        mounted() {
            API.get('catalog/stock-correction/' + this.$state.params.id).then(res => {
                this.stock = res.data.stock_correction

                Vue.set(this.stock, 'stock', res.data.depository.stock)
                Vue.set(this.stock, 'ordered', res.data.depository.ordered)
                Vue.set(this.stock, 'expected', res.data.depository.expected)

                this.logs = res.data.depository.logs
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.stock.notify.error.LOAD')
            });
        },
        methods: {
            editStockCorrection() {
                this.loading = true

                API.post('catalog/stock-correction/edit', this.stock).then(() => {
                    this.$state.go('app.catalog.stock-corrections.table')

                    this.$notify.success('catalog.stock.notify.UPDATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.stock.notify.error.UPDATE')
                })
            },
            goRejected() {
                this.loading = true

                API.post('catalog/stock-correction/reject', {
                    id: this.stock.id,
                    comments_admin: this.stock.comments_admin
                }).then(() => {
                    this.$state.go('app.catalog.stock-corrections.table')

                    this.$notify.success('catalog.stock.notify.REJECT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.stock.notify.error.REJECT')
                })
            },
            goCanceled() {
                this.loading = true

                API.post('catalog/stock-correction/cancel', {
                    id: this.stock.id
                }).then(() => {
                    this.$state.go('app.catalog.stock-corrections.table')

                    this.$notify.success('catalog.stock.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.stock.notify.error.CANCEL')
                })
            }
        },
        computed: {
            disabled() {
                return [2, 3, 4].includes(this.stock.status)
            }
        }
    }
</script>