<template lang="pug">
    div
        OptiButton(v-if="mail_id" style="position:relative" @click.native="dialog.preview = true"
            ) {{'catalog.offer.mail.BTN_PREVIEW'|translate}}
            template(#icon)
                i.icon.icon-envelope-open

            OptiDimmer(:active="loading")

        OptiButton(v-else style="position:relative" type="success" @click.native="dialog.send = true"
            ) {{'catalog.offer.mail.HEADER'|translate}}
            template(#icon)
                i.fa.fa-envelope

            OptiDimmer(:active="loading")

        MountingPortal(v-if="dialog.preview" mountTo="#vue-modal" append)
            OptiPatientEmailPreviewDialog(
                :id="mail_id"

                @close="changed => close('preview', changed)")

        MountingPortal(v-if="dialog.send" mountTo="#vue-modal" append)
            OptiCatalogOfferDialogEMail(
                :offer="offer"
                :patient="patient"

                @close="close('send')")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiCatalogOfferDialogEMail
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogEMail'

    export default {
        name: 'OptiCatalogOfferBtnMail',
        components: {
            OptiButton,
            OptiDimmer,
            OptiPatientEmailPreviewDialog: () => import('@/js/app/vue/components/Patients/Emails/OptiPatientEmailPreviewDialog'),
            OptiCatalogOfferDialogEMail
        },
        props: {
            offer: {
                type: Object,
                required: true
            },
            patient: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                mail_id: 0,

                dialog: {
                    preview: false,
                    send: false
                },
                loading: true
            }
        },
        mounted() {
            this.fetch()
        },
        methods: {
            fetch() {
                this.loading = true

                API.get('patients/email/from-offer-id/' + this.offer.id).then(res => {
                    this.mail_id = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.emails.notify.error.CHECK_MAIL')
                })
            },

            close(type, changed) {
                this.dialog[type] = false

                if(type === 'send' || (type === 'preview' && changed)) {
                    this.fetch()

                    this.$emit('reload')
                }
            }
        }
    }
</script>