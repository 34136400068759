<template lang="pug">
    span(v-show="data.firstValue != data.lastValue")
        a.link.old-value(v-if="data.receiptId && data.firstValue != data.lastValue" :href="receiptLink()"
            :title="'default.notify.receipt.TITLECHANGERECEIPT'|translate") {{data.firstValue|currency}}
        a.old-value.bold(v-if="!data.receiptId && data.firstValue != data.lastValue") {{data.firstValue|currency}}
</template>

<script>
    export default {
        name: 'optiReceiptNoticeValue',
        components: {

        },
        props: {
            data: {
                type: Object | Boolean,
                required: true
            }
        },
        data() {
            return {
                text: 'default.notify.receipt.CHANGERECEIPT'
            }
        },
        mounted() {
            if(this.deposit) {
                this.text = 'default.notify.receipt.CHANGEACCOUNT'
            } else if(this.rest) {
                this.text = 'default.notify.receipt.CHANGEREST'
            }
        },
        methods: {
            receiptLink() {
                return this.$state.href('app.register.detail', {
                    id: this.data.receiptId
                })
            }
        }
    }
</script>
