import axios from 'axios'
import ApiManager from '@/js/app/vue/api-manager'

export const API = axios.create({
    baseURL: '/api/'
})

API.interceptors.request.use(
    config => config,

    error => {
        return Promise.reject(error)
    }
)

const apiManager = new ApiManager()

API.interceptors.request.use(
    (config) => {
        // odpalamy tylko jeśli nie ma już zdefiniowanego cancelTokena
        if (!config.cancelToken) {
            let hash = ApiManager.generateHashFromConfig(config)

            const source       = axios.CancelToken.source()
            config.cancelToken = source.token

            apiManager.addRequest(hash, source.cancel)
        }

        return config
    }
)

API.interceptors.response.use(
    response => {
        let hash = ApiManager.generateHashFromConfig(response.config)
        apiManager.removeRequest(hash)

        return response
    }
)

API.cancel = (requestId, reason) => {
    apiManager.cancelRequest(requestId, reason)
}

API.cancelAll = (reason) => {
    apiManager.cancelAllRequest(reason)
}

API.id = (id, additionalHeaders = {}) => {
    return {
        headers: {
            'X-Request-Id': id
        },
        ...additionalHeaders
    }
}

API.isCancel = axios.isCancel

export const GET_ITEMS   = 'getItems'
export const ADD_ITEM    = 'addItem'
export const DELETE_ITEM = 'deleteItem'
export const SET_ITEMS   = 'setItems'
