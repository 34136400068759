<template lang="pug">
    div
        .row(style="margin-bottom:10px")
            .col-xs-9
                h4.filter-header.page-header {{'catalog.offer.choice.heading.FRAMES'|translate}}

        .row(style="position:relative")
            .col-sm-6.product(style="margin-bottom:15px" :class="{'col-md-4': chose_products.length < 4, 'col-md-3': chose_products.length === 4}"
                v-for="(chose_product, product_key) in chose_products" :key="chose_product.product.id")
                OptiProductInfo(:class="{'opti-product-info-small': chose_products.length === 4}"
                    :product="chose_product.product"
                    :department-id="department_id"

                    :is-offer="true")

                div(v-if="colors.length")
                    label.control-label(style="font-weight:400" :for="'product_color_' + chose_product.product.id"
                        ) {{'catalog.lenses.properties.heading.' + colors_header|translate}}
                    select.form-control(:id="'product_color_' + chose_product.product.id"
                        :name="'product_color_' + chose_product.product.id"
                        :class="{'placeholder': chose_product.color === null}"
                        @change="e => $store.commit('offer/setColor', {chose_product: chose_product, e: e})")
                        option.hide(disabled :value="null" :selected="chose_product.color === null"
                            ) {{'catalog.offer.notify.none.choose.HOYA'|translate}}
                        option(:value="0" :selected="chose_products.color === 0"
                            ) {{'catalog.offer.choice.NONE_COLOR'|translate}}
                        option(v-for="color in colors" :value="color.id" :selected="chose_product.color && chose_product.color.id === color.id"
                            ) {{color.name_long}}

                .text-center(style="display:flex;justify-content:space-evenly;align-items:center")
                    .btn.btn-danger(:title="'catalog.offer.choice.title.DELETE'|translate"
                        :class="{'btn-with-color': colors.length}"
                        @click="$store.commit('offer/deleteChoseProduct', product_key)"
                        ) {{'catalog.offer.choice.button.DELETE'|translate}}

                    .warn(v-show="chose_product.choice_by_client")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'catalog.offer.choice.byClient.HEADER'|translate}}

            .col-xs-12.text-center.text-muted(v-show="!chose_products.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'catalog.offer.notify.none.choose.FRAMES'|translate}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'

    export default {
        name: 'OptiCatalogOfferChoiceProducts',
        components: {
            OptiSelectPatient,
            OptiButton,
            OptiProductInfo
        },
        computed: {
            chose_products() {
                return this.$store.getters['offer/getChoseProducts']
            },

            colors() {
                return this.$store.getters['offer/getColors']
            },
            colors_header() {
                return this.$store.getters['offer/getColorsHeader']
            },

            department_id() {
                return this.$store.getters['offer/getDepartmentId']
            },

            patient() {
                return this.$store.getters['offer/getPatient']
            }
        }
    }
</script>

<style lang="less" scoped>
    .product > .text-center > .btn {
        position: relative;
        z-index: 99;

        margin-top: -12px;

        &.btn-with-color {
            margin-top: 15px;
        }
    }
</style>