<template lang="pug">
    .row
        .col-xs-12.text-center
            OptiButton(type="success" @click.native="open = true") {{'sidebar.catalog.GRAPH'|translate}}
                template(#icon)
                    i.fa.fa-share-alt

            MountingPortal(v-if="open" mountTo="#vue-modal" append)
                OptiCatalogGraphDialog(@close="close")
</template>

<script>
    import OptiCatalogGraphDialog from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraphDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogGraph',
        components: {
            OptiButton,
            OptiCatalogGraphDialog
        },
        data() {
            return {
                open: true
            }
        },
        methods: {
            close() {
                this.open = false

                this.$state.go('app.dashboard')
            }
        }
    }
</script>