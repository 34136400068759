import registerAngularDirectives from './_directives/_directives.index'


// bundle wszystkich *.pug dla starego angulara 1.*
import './ngTemplates'


export default function registerAngularDEV(module) {
    registerAngularDirectives(module)
}
