<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-navicon.mr3
            span Powód zmiany stanu
        template(#body)
            .col-xs-12
                table.table.table-responsive.middle
                    tbody
                        tr
                            td
                                .radio.c-radio.c-radio-nofont
                                    label.text-bold
                                        input(type="radio" name="regeneration" v-model="data.type" :value="'regeneration'" @click="data.info = null")
                                        span
                                        | Regeneracja:
                            td
                                input.form-control(type="text" v-model="data.info" v-show="data.type === 'regeneration'")
                                input.form-control(type="text" v-show="data.type !== 'regeneration'" disabled)
                        tr
                            td
                                .radio.c-radio.c-radio-nofont
                                    label.text-bold
                                        input(type="radio" name="reclamation" v-model="data.type" :value="'reclamation'" @click="data.info = null")
                                        span
                                        | Reklamacja:
                            td
                                input.form-control(type="text" v-model="data.info" v-show="data.type === 'reclamation'")
                                input.form-control(type="text" v-show="data.type !== 'reclamation'" disabled)
                        tr
                            td
                                .radio.c-radio.c-radio-nofont
                                    label.text-bold
                                        input(type="radio" name="other" v-model="data.type" :value="'other'" @click="data.info = null")
                                        span
                                        | Inny powód:
                            td
                                input.form-control(type="text" v-model="data.info" v-show="data.type === 'other'")
                                input.form-control(type="text" v-show="data.type !== 'other'" disabled)
                    tfoot
                        tr
                            td(colspan="100")
                                OptiButtonSave.pull-right(
                                :disabled="!data.type || !data.info"
                                    @click.native="updatePart"
                                )
                                OptiButton.pull-left(
                                    type="danger"
                                    @click.native="$emit('closed')"
                                ) Anuluj
                                    template(#icon)
                                        i.fa.fa-angle-left

</template>

<script>
import {API} from "@/js/app/vue/api";

import OptiDialog from "@/js/app/vue/components/OptiDialog";
import OptiButtonSave from "@/js/app/vue/components/Button/OptiButtonSave";
import OptiButton from "@/js/app/vue/components/Button/OptiButton";
export default {
    name: 'OptiSparePartsDialog',
    components: {OptiButtonSave, OptiDialog, OptiButton},
    props: {
        product_id: {
            type: Number,
            required: true
        },
        change: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            data: {
                type: null,
                info: null,
                change: this.change,
                product_id: this.product_id
            }
        }
    },
    methods: {
        updatePart() {
            API.post('warehouse/products/updateSpareParts/submission', {
                data: this.data
            }).then(() => {
                this.$emit('closed')
            }).finally(() => {
                this.$notify.success('Zmiany zostały wprowadzone')
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .modal-container {
    max-width: 600px;
}
</style>