<template lang="pug">
    .row(v-if="$asyncComputed.wz.success")
        OptiDimmer(:active="active")
        // MountingPortal(v-if="isDialogOpen" mountTo="#vue-modal" append)
          //OptiDateDialog(@close="isDialogOpen = false" @update:date="handleDateFromPicker")

        .col-xs-6
            OptiButtonPrint(url="warehouse/order/wz/print" :id="wz.id") {{'orders.wz.details.PRINT'|translate}}
            OptiButtonPrint(style="margin-left:2px" url="warehouse/order/wz/printList" :id="wz.id"
                ) {{'orders.wz.details.PRINTLIST'|translate}}
        .col-xs-6.text-right(v-show="!wz.closed")
            OptiButton(type="primary" style="margin-right:2px" v-show="wz.order_id < 1"
                @click.native="$state.go('app.warehouse.order.wz.edit', {id: $state.params.id})"
                ) {{'orders.wz.breadcrumb.EDIT'|translate}}
                template(#icon)
                    i.fa.fa-edit
            OptiButton(type="info" style="margin-right:2px"
                v-if="wz.closed === 0 && wz.department_id !== 49 && wz.order_id === -1"
                text="orders.wz.action.confirm" @confirmed="createOrder") Utwórz zamówienie
                template(#icon)
                    i.fa.fa-truck
            OptiButton(type="success" text="orders.wz.action.close.confirm" @confirmed="goClose"
                ) {{'orders.wz.action.close.confirm.BTN'|translate}}
                template(#icon)
                    i.fa.fa-unlock-alt

        .col-xs-6.text-right(v-show="wz.closed")
            OptiButton(@click.native="exportExcel") {{'orders.wz.details.EXPORT'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
            OptiButton(type="primary" style="margin-left:2px" :disabled="!wz.closed"
                @click.native="printTickets") Drukuj metki
                template(#icon)
                    i.fa.fa-ticket

        .col-xs-12(style="margin-top:10px")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'orders.wz.WZ'|translate}} \#{{wz.id}}
                    .pull-right \#{{wz.created}}

                .table-responsive
                    table.table.table-condensed.table-striped.middle
                        thead
                            tr
                                th.text-right #
                                th.text-right {{'orders.wz.head.PRODUCTID'|translate}}
                                th {{'orders.wz.head.PRODUCT'|translate}}
                                th {{'orders.wz.head.PRICE'|translate}}
                                th {{'orders.wz.head.QUANTITY'|translate}}
                                th {{'orders.wz.head.CORRECTION'|translate}}
                                th {{'orders.wz.head.COMMENTS'|translate}}
                        tbody
                            template(v-for="(item, index) in sortItems")
                                tr
                                    td.text-right {{index + 1}}
                                    td.text-right
                                        OptiProduct(:product="item.product") {{item.product_id}}
                                    td
                                        OptiProduct.mr3(:product="item.product") {{item.product.name}}
                                        OptiProductSpecialPercent(:customerProduct="item.product.customerProduct")
                                        small(style="display:block") {{item.product.fullName}}
                                    td {{item.product.priceWholesale|currency}}
                                    td {{item.quantity}}
                                    td
                                        span(v-show="wz.closed") {{item.corrected}}
                                        OptiNumber(
                                            v-show="!wz.closed"
                                            :value= "checkStock(wz, item)"
                                            :header="'Wprowadź korektę'"
                                            :disabled="item.product.stock === 0"

                                            @confirmed="v => item.corrected = v") {{item.corrected}}
                                    td(v-html="item.comments" v-if="!checkProduct(item) || item.corrected === 0 || wz.closed") {{item.comments}}
                                    td(v-else)
                                        OptiWzCouponValidator(
                                            :productId="item.product_id"
                                            :items= "wz.items"
                                            @code="x => updateCode(x, item)")
                                tr.align-middle(v-if="item.product.stock < 1 && !wz.closed")
                                    td(colspan="4")
                                    td.warn.bold(colspan="3") {{'orders.wz.add.validate.product.NOSTOCK'|translate}}
                        tfoot.text-expressive(v-show="wz.items.length > 1")
                            tr
                                th.text-right(colspan="3") {{'orders.items.SUM'|translate}}
                                th {{wz.value|currency}}
                                th {{wz.quantity}}
                                th {{wz.corrected}}
                                th

        .col-md-8.col-md-offset-4.col-xs-12.col-xs-offset-0
            .panel-grid
                .panel.panel-default
                    .row
                        .col-sm-6 {{'orders.wz.details.CREATEDBY'|translate}}
                        .col-sm-6 {{wz.user_name}}
                    .row(v-show="wz.comments")
                        .col-sm-6 {{'orders.wz.details.COMMENTS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(wz.comments)")
                    .row
                        .col-sm-6 {{'orders.wz.details.RECEIVED'|translate}}
                        .col-sm-6 {{wz.department_name}}
                    .row
                        .col-sm-6 {{'orders.wz.details.CREATEDAT'|translate}}
                        .col-sm-6 {{wz.created}}
                    .row(v-show="wz.order_id > 0")
                        .col-sm-6 {{'orders.wz.details.ORDER'|translate}}
                        .col-sm-6
                            a.link(:href="$state.href('app.warehouse.order.details', {id: wz.order_id})") {{wz.order_id}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiWzCouponValidator from '@/js/app/vue/components/Warehouse/Orders/WZ/OptiWzCouponValidator'
    import OptiProductSpecialPercent from '@/js/app/vue/components/Warehouse/Products/OptiProductSpecialPercent'
    import OptiDialog from "@/js/app/vue/components/OptiDialog.vue";
    import OptiDateDialog from "@/js/app/vue/components/Warehouse/Orders/WZ/OptiDateDialog.vue";

    export default {
        name: 'OptiOrdersWzDetails',
        components: {
          OptiDateDialog,
          OptiDialog,
          OptiProduct,
          OptiButtonPrint,
          OptiDimmer,
          OptiButton,
          OptiNumber,
          OptiWzCouponValidator,
          OptiProductSpecialPercent
        },
        data() {
            return {
              selectedDate: new Date(),
              isDialogOpen: false,
              attrs: [
                {
                  key: 'today',
                  highlight: {
                    backgroundColor: '#ffecb3',
                  },
                  dates: new Date(),
                },
              ],
              active: false
            }
        },
        asyncComputed: {
            async wz() {
                try {
                    this.active = true
                    let {data} = await API.get('warehouse/order/wz/details/' + this.$state.params.id)
                    this.active = false
                    return data
                } catch(e){
                    console.log(e)
                }
            }
        },
        methods: {
            goClose() {
                this.active = true
                API.post('warehouse/order/wz/close', this.wz).then(res => {
                    this.wz = res.data
                    this.$notify.success( 'orders.wz.notify.CLOSE')
                }).finally(() => {
                    this.active = false
                }).catch(() => {
                    this.$notify.error('orders.wz.notify.error.CLOSE')
                })
            },
            showModal() {
                this.isDialogOpen = true
            },
            handleDateFromPicker(date) {
              console.log(this.selectedDate)
              this.selectedDate = date
              this.isDialogOpen = false
              this.printTickets();
            },
            printTickets() {
                this.active = true
                const labelPrintDate = this.selectedDate.toISOString()
                API.get('warehouse/order/wz/printTickets/' + this.wz.id + '/' + labelPrintDate).then(res => {
                    window.open('reports/'+res.data + '.csv')
                })
                this.$notify.success('orders.wz.notify.TICKETS')
                this.active = false
            },
            createOrder() {
                this.active = true
                API.post('warehouse/order/wz/createOrder/' + this.$state.params.id).then( res => {
                    this.wz.order_id = res.data
                })
                this.$notify.success('orders.wz.notify.CREATED')
                this.active = false
            },
            checkStock(wz, item) {
                if (item.product.stock < 1 && !wz.closed){
                    return item.corrected = 0
                } else {
                    return item.corrected
                }
            },
            checkProduct(item) {
                return item.product_id === 32585 || item.product_id === 32586 || item.product_id === 32587
            },
            exportExcel() {
                this.active = true
                API.get('warehouse/order/wz/exportToExcel/' + this.$state.params.id).then(res => {
                    window.open('reports/' + res.data +'.xls')
                }).finally(() => {
                    this.active = false
                    this.$notify.success('orders.wz.notify.EXPORT')
                }).catch(() => {
                    this.active = false
                    this.$notify.error('order.wz.notify.error.EXPORT')
                })
            },
            updateCode(e, item) {
                return item.comments = e
            }
        },
        computed: {
            sortItems() {
                this.wz.items.sort(function (a, b) {
                    if(a.product.lp < b.product.lp) {
                        return -1
                    } else if(a.product.lp > b.product.lp) {
                        return 1
                    } else {
                        return 0
                    }
                })

                return this.wz.items
            }
        }
    }
</script>