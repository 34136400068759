<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-file-excel-o.mr3
            span {{'default.REPORT'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-12
                    form.form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label.star-required(for="date_from") {{'rate.report.date.FROM'|translate}}
                            .col-sm-8
                                OptiDate#date_from(:value="date.from" @update-date="d => date.from = d")
                                OptiValidate(f="date_from" :data="$v.date.from")

                        .form-group
                            label.col-sm-4.control-label.star-required(for="date_to") {{'rate.report.date.TO'|translate}}
                            .col-sm-8
                                OptiDate#date_to(:value="date.to" @update-date="d => date.to = d")
                                OptiValidate(f="date_to" :data="$v.date.to")

        template(#footer)
            OptiButton.pull-right(type="success" :disabled="$v.date.$invalid" @click.native="save") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogReportRate',
        components: {
            OptiDialog,
            OptiDate,
            OptiValidate,
            OptiDimmer,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            type: {
                type: String,
                required: true,
                validator: v => ['offers', 'temporary_tasks'].includes(v)
            }
        },
        validations: {
            date: {
                from: {
                    required
                },
                to: {
                    required
                }
            }
        },
        data() {
            return {
                filename: {
                    offers: 'rates/offers/report_rate_offer_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xls',
                    temporary_tasks: 'rates/temporary_tasks/report_rate_temporary_tasks_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xls'
                },
                date: {
                    from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                },

                loading: false
            }
        },
        methods: {
            save() {
                DownloadFileHelper.post('report/rate', this.filename[this.type], {
                    type: this.type,
                    date: this.date
                })

                this.$emit('closed')
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 350px;

        > .modal-body {
            overflow: unset !important;
        }
    }
</style>