<template lang="pug">
    OptiDialog(:footer="false")
        template(#header)
            i.fa.fa-exclamation-circle.warn.mr3
            span.warn {{'optometrist.prescription.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('cancelled')")
        template(#body)
            .dialog-contents
                .row
                    .col-sm-6
                        table.table.table-striped.text-center.middle
                            thead
                                tr
                                    th(colspan="9") {{'optometrist.correction.CORRECTION'|translate}}
                            tbody
                                tr
                                    th {{'optometrist.acuity.FAR'|translate}}
                                    th SPH
                                    th CYL
                                    th AXS
                                    th(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") Prism
                                    th(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") Angle
                                    th(v-show="interview.dist_r_add || interview.dist_l_add") Add
                                    th Visus
                                    th BinVisus
                                tr
                                    th {{'prescription.RIGHT'|translate}}
                                    td {{interview.dist_r_sphere|plus}}
                                    td(:class="{'warn': interview.dist_r_cylinder !== interview.near_r_cylinder}") {{interview.dist_r_cylinder|plus}}
                                    td(:class="{'warn': interview.dist_r_axis !== interview.near_r_axis}")
                                        span(v-show="interview.dist_r_cylinder") {{interview.dist_r_axis}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.dist_r_prd|number(2)}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.dist_r_base}}
                                    td(v-show="interview.dist_r_add || interview.dist_l_add") {{interview.dist_r_add|number(2)}}
                                    td {{interview.dist_r_visus}}
                                    td(rowspan="2") {{interview.dist_binvisus}}
                                tr
                                    th {{'prescription.LEFT'|translate}}
                                    td {{interview.dist_l_sphere|plus}}
                                    td(:class="{'warn': interview.dist_l_cylinder !== interview.near_l_cylinder}") {{interview.dist_l_cylinder|plus}}
                                    td(:class="{'warn': interview.dist_l_axis !== interview.near_l_axis}")
                                        span(v-show="interview.dist_l_cylinder") {{interview.dist_l_axis}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.dist_l_prd|number(2)}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.dist_l_base}}
                                    td(v-show="interview.dist_r_add || interview.dist_l_add") {{interview.dist_l_add|number(2)}}
                                    td {{interview.dist_l_visus}}
                                tr
                                    th {{'optometrist.acuity.NEAR'|translate}}
                                    th SPH
                                    th CYL
                                    th AXS
                                    th(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") Prism
                                    th(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") Angle
                                    th(v-show="interview.dist_r_add || interview.dist_l_add")
                                    th Visus
                                    th BinVisus
                                tr
                                    th {{'prescription.RIGHT'|translate}}
                                    td {{interview.near_r_sphere|plus}}
                                    td(:class="{'warn': interview.dist_r_cylinder !== interview.near_r_cylinder}") {{interview.near_r_cylinder|plus}}
                                    td(:class="{'warn': interview.dist_r_axis !== interview.near_r_axis}")
                                        span(v-show="interview.near_r_cylinder") {{interview.near_r_axis}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.near_r_prd|number(2)}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.near_r_base}}
                                    td(v-show="interview.dist_r_add || interview.dist_l_add")
                                    td {{interview.near_r_visus}}
                                    td(rowspan="2") {{interview.near_binvisus}}
                                tr
                                    th {{'prescription.LEFT'|translate}}
                                    td {{interview.near_l_sphere|plus}}
                                    td(:class="{'warn': interview.dist_l_cylinder !== interview.near_l_cylinder}") {{interview.near_l_cylinder|plus}}
                                    td(:class="{'warn': interview.dist_l_axis !== interview.near_l_axis}")
                                        span(v-show="interview.near_l_cylinder") {{interview.near_l_axis}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.near_l_prd|number(2)}}
                                    td(v-show="interview.dist_r_prd || interview.dist_l_prd || interview.near_r_prd || interview.near_l_prd") {{interview.near_l_base}}
                                    td(v-show="interview.dist_r_add || interview.dist_l_add")
                                    td {{interview.near_l_visus}}

                    .col-sm-6
                        .row
                            .col-xs-12.bold {{'optometrist.prescription.TITLE'|translate}}
                            .col-xs-12
                                .btn-glasses
                                    .row
                                        .col-xs-6.text-center
                                            .btn.btn-lg.btn-info(@click="$emit('create-prescription', 1)")
                                                .icon.icon-settings
                                                div {{'optometrist.prescription.choise.FAR'|translate}}
                                        .col-xs-6.text-center
                                            .btn.btn-lg.btn-info(@click="$emit('create-prescription', 2)")
                                                .icon.icon-settings
                                                div {{'optometrist.prescription.choise.NEAR'|translate}}
                                    .row
                                        .col-xs-6.text-center
                                            .btn.btn-lg.btn-info(@click="$emit('create-prescription', 3)")
                                                .icon.icon-settings
                                                div {{'optometrist.prescription.choise.BOTH'|translate}}
                                        .col-xs-6.text-center
                                            .btn.btn-lg.btn-danger(@click="$emit('cancelled')")
                                                .icon.icon-ban
                                                div {{'optometrist.prescription.choise.CANCEL'|translate}}
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiOptometristsDetailsSummaryDialogPrescriptionDifference',
        components: {
            OptiDialog
        },
        props: {
            interview: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
/deep/ .modal-container {
    max-width: 100%;
}
</style>