export default {
    notInLocalStock(items) {
        let _return = false

        items.forEach(item => {
            if(item.quantity > item.product.depository.stock) {
                _return = true
            }
        })

        return _return
    },
    disabled(state) {
        return state.pending || !state.carts.sendback.items.length || this.notInLocalStock(state.carts.sendback.items) ||
            !state.carts.sendback.totals.value
    },

    setTotal(sendback) {
        let totals = {
            quantity: 0,
            value: 0,
            wholesaleValue: 0
        }

        sendback.items.forEach(item => {
            totals.quantity += parseFloat(item.quantity)
            totals.value += item.product.price * item.quantity
            totals.wholesaleValue += item.product.priceWholesale * item.quantity
        })

        if(totals.quantity > 0 && !totals.value) {
            totals.value = 0.01
        }

        return totals
    }
}
