import {generateChart} from 'vue-chartjs'

import Chart from 'chart.js'
window.Chart = Chart

import 'chartjs-chart-treemap'
import {reactiveProp} from 'vue-chartjs/es/mixins'

const Treemap = generateChart('treemap', 'treemap')

export default {
    extends: Treemap,
    name: 'TreeMapChart',
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        this.renderChart(this.chartData, this.options)
    },
    methods: {
        update() {
            this.$data._chart.update()
        }
    }
}
