<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formPreviousLensesContactLenses" @submit.prevent="add")
                .form-group
                    label.col-sm-4.control-label.star-required(for="producent"
                        ) {{'dictionary.previousLensesContactLenses.table.PRODUCENT'|translate}}
                    .col-sm-8
                        input#producent.form-control(:placeholder="'dictionary.previousLensesContactLenses.table.PRODUCENT'|translate"
                            name="producent" v-model="lenses.producent")
                        OptiValidate(f="producent" :data="$v.lenses.producent")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name"
                        ) {{'dictionary.previousLensesContactLenses.table.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(:placeholder="'dictionary.previousLensesContactLenses.table.NAME'|translate"
                            name="name" v-model="lenses.name")
                        OptiValidate(f="name" :data="$v.lenses.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type"
                        ) {{'dictionary.previousLensesContactLenses.table.TYPE_ID'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="PreviousLensContactLensType" :value="lenses.type_id"
                            @update-enum="t => lenses.type_id = t")
                        OptiValidate(f="type" :data="$v.lenses.type_id")

                .form-group
                    .col-md-8.col-md-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="sph" v-model="lenses.sph")
                                    span.fa.fa-check
                            span {{'dictionary.previousLensesContactLenses.table.SPH'|translate}}
                .form-group
                    .col-md-8.col-md-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="cyl" v-model="lenses.cyl")
                                    span.fa.fa-check
                            span {{'dictionary.previousLensesContactLenses.table.CYL'|translate}}
                .form-group
                    .col-md-8.col-md-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="add" v-model="lenses.add")
                                    span.fa.fa-check
                            span {{'dictionary.previousLensesContactLenses.table.ADD'|translate}}

                .form-group
                    .col-xs-12.text-right
                        OptiButtonSave(:disabled="$v.lenses.$invalid")

        .col-md-offset-2.col-md-8
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryPreviousLensesContactLensesAdd',
        components: {
            OptiValidate,
            OptiSelectEnum,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                lenses: {
                    producent: '',
                    name: '',
                    type_id: null,
                    sph: false,
                    cyl: false,
                    add: false
                },

                loading: false
            }
        },
        validations: {
            lenses: {
                producent: {
                    required
                },
                name: {
                    required,
                    unique: unique('previous_lenses_products', 'name')
                },
                type_id: {
                    required
                }
            }
        },
        methods: {
            add() {
                this.loading = true

                API.post('dictionary/previous-lenses-contact-lenses', this.lenses).then(() => {
                    this.$notify.success('dictionary.previousLensesContactLenses.notify.ADD')

                    this.$state.go('app.dictionary.previous-lenses-contact-lenses.table')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dictionary.previousLensesContactLenses.notify.error.ADD')
                })
            }
        }
    }
</script>