<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-star.mr3
            span {{'rate.HEADER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="closed")

        template(#body)
            .row
                .col-sm-6
                    form.form.form-horizontal
                        .form-group
                            label.control-label.col-sm-4 {{'rate.form.REFERENCE'|translate}}
                            .col-sm-8.form-control-static
                                OptiEnum.mr3(enum="RateConditionType" :id="refType")
                                OptiRefId(section="rate" :type-id="refType" :id="refId")

                        .form-group
                            label.control-label.col-sm-4.star-required(for="rate") {{'rate.form.RATE'|translate}}
                            .col-sm-8
                                select#rate.form-control(:disabled="!allow_edit"
                                    v-model="form.rate" @change="getChoseConditions")
                                    option(v-for="r in rates" :value="r.id") {{r.name}}
                                OptiValidate(f="rate" :data="$v.form.rate")

                        .form-group
                            label.control-label.col-sm-4(for="description") {{'rate.form.DESCRIPTION'|translate}}
                            .col-sm-8
                                textarea#description.form-control(rows="5" :placeholder="'rate.none.DESCRIPTION'|translate"
                                    :disabled="!allow_edit" v-model="form.description")

                        .form-group(v-show="form.user.lastName")
                            label.control-label.col-sm-4 {{'rate.form.CREATED_BY'|translate}}
                            .col-sm-8.form-control-static {{form.user.firstName}} {{form.user.lastName}}

                        .form-group(v-show="form.created_at")
                            label.control-label.col-sm-4 {{'rate.form.CREATED_AT'|translate}}
                            .col-sm-8.form-control-static {{form.created_at}}

                        .form-group(v-show="form.updated_at")
                            label.control-label.col-sm-4 {{'rate.form.UPDATED_AT'|translate}}
                            .col-sm-8.form-control-static {{form.updated_at}}

                .col-sm-6
                    .form.form-horizontal
                        .form-group
                            label.col-xs-12 {{'rate.form.CONDITIONS'|translate}}
                            .col-xs-12(v-if="chose_conditions.length")
                                div(v-for="chose_condition in chose_conditions" v-show="allow_edit || chose_condition.checkbox")
                                    label
                                        .checkbox.c-checkbox.checkbox-primary.inline
                                            label
                                                input(type="checkbox" name="chose_condition"
                                                    :disabled="!allow_edit" v-model="chose_condition.checkbox")
                                                span.fa.fa-check
                                        span {{chose_condition.name}}

                            .col-xs-12.text-muted(v-if="!(chose_conditions.length && (allow_edit || chose_conditions.filter(c => c.checkbox).length))")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'rate.none.CONDITIONS'|translate}}

                OptiDimmer(:active="loading")

        template(#footer)
            .row
                .col-sm-4.text-left.text-center-xs
                    OptiButton(@click.native="closed") {{'default.CLOSE'|translate}}
                        template(#icon)
                            i.fa.fa-remove

                .col-sm-4.text-center
                    OptiButton(type="success" :disabled="loading || !allow_edit"
                        text="rate.confirm.auto-rate" @confirmed="autoSave") {{'rate.confirm.auto-rate.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-cog

                .col-sm-4.text-right.text-center-xs
                    OptiButton(type="success" :disabled="loading || !allow_edit || $v.form.$invalid"
                        text="rate.confirm.save" @confirmed="save") {{'default.SAVE'|translate}}
                        template(#icon)
                            i.fa.fa-save
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiRateDialog',
        components: {
            OptiDialog,
            OptiDimmer,
            OptiEnum,
            OptiRefId,
            OptiValidate,
            OptiButton
        },
        props: {
            rate: {
                type: Object,
                required: true
            },

            refType: {
                type: Number,
                required: true
            },
            refId: {
                type: Number,
                required: true
            },

            rateMax: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                form: _.cloneDeep(this.rate),
                conditions: [],
                chose_conditions: [],

                rates: [{
                    id: null,
                    name: this.$filters.translate('rate.none.RATE')
                }],

                allow_edit: this.$uac.permission('rate.edit'),
                reload: false,
                loading: true
            }
        },
        validations: {
            form: {
                rate: {
                    required
                }
            }
        },
        mounted() {
            API.get('rate-conditions/' + this.refType).then(res => {
                this.conditions = res.data

                this.getChoseConditions()

                this.setSelected()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('rate.notify.error.CONDITION_LOAD')
            })

            this.rates = this.rates.concat(
                _.range(0, this.rateMax[this.refType] + 1).map(function(v) {
                    return {
                        id: v,
                        name: v
                    }
                }))
        },
        methods: {
            getChoseConditions() {
                this.chose_conditions = _.cloneDeep(this.conditions).filter(c => c.active && c.rate === this.form.rate)

                this.chose_conditions.forEach((chose_condition, key) => {
                    this.$set(this.chose_conditions[key], 'checkbox', false)
                })
            },
            setSelected() {
                let selected = this.form.conditions.map(c => c.id)

                this.chose_conditions.forEach(chose_condition => {
                    if(selected.includes(chose_condition.id)) {
                        chose_condition.checkbox = true
                    }
                })

                //TODO: jeśli są jakieś pozycje na active false to zaimplementować to
            },
            autoSave() {
                this.loading = true

                API.post('rate/auto', {
                    refType: this.refType,
                    refId: this.refId
                }).then(res => {
                    this.form = res.data

                    this.getChoseConditions()

                    this.setSelected()

                    this.$notify.success('rate.notify.AUTO')

                    this.reload = true
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('rate.notify.error.AUTO')
                })
            },
            save() {
                this.loading = true

                this.form.conditions = this.chose_conditions.filter(c => c.checkbox).map(c => c.id)

                API.post('rate/save', {
                    refType: this.refType,
                    refId: this.refId,
                    rate: this.form
                }).then(() => {
                    this.$notify.success('rate.notify.SAVE')

                    this.reload = true
                    this.closed()
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('rate.notify.error.SAVE')
                })
            },
            closed() {
                this.$emit(this.reload ? 'reload' : 'closed')
            }
        }
    }
</script>