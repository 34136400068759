<template lang="pug">
    OptiButton(@click.native="dialog = true") {{header|translate}}
        template(#icon)
            i.fa(:class="'fa-' + icon")

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiDialogHelp(
                :icon="icon"
                :header="header"
                :img="img"
                :description="description"
                :link="link"

                @close="dialog = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogHelp from '@/js/app/vue/components/Button/Dialogs/OptiDialogHelp'

    export default {
        name: 'OptiButtonHelp',
        components: {
            OptiButton,
            OptiDialogHelp
        },
        props: {
            icon: {
                type: String,
                required: true
            },
            header: {
                type: String,
                required: true
            },
            img: {
                type: String,
                required: false,
                default: ''
            },
            description: {
                type: String,
                required: false,
                default: ''
            },
            link: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>