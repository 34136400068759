<template lang="pug">
    .panel.panel-default
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'report.entries.HEADING'|translate}}

        .panel-body
            OptiReportEntriesNavigation(
                :period="selectedPeriod"
                :department-id="departmentId"

                @period-changed="updatePeriod"
                @load-department="d => departmentId = d")
            .clearfix

            div(style="position:relative")
                OptiDimmer(:active="active")

                LineChart(
                    :chart-data="chartData"
                    :options="options"
                    :styles="styles"
                    ref="chart")

                OptiChartTooltip(
                    :model="check"
                    :chart-data="chartData"
                    :chart="$refs.chart")
                    template(#header="{header}")
                        div {{header.tooltip.title[0]}}
                    template(#default="{body}")
                        table.table.table-striped.table-bordered.table-condensed
                            thead
                                tr
                                    th(colspan="2") &nbsp;
                                    th.text-right {{selectedPeriod.pastYear}}
                                    th.text-right {{selectedPeriod.chooseYear}}
                                    th.text-right %
                            tbody
                                tr(v-for="row in body.tooltip.body")
                                    th
                                        div.line-legend(:style="{ backgroundColor: row.lines[0].background, borderColor: row.lines[0].border }")
                                    th {{ row.lines[0].label }}
                                    td.text-center(colspan="2" v-if="row.lines[0].valueA == 0") ---
                                    td.text-right.no-brake(v-if="row.lines[0].valueB != 0") {{ row.lines[0].valueB | currency({fractionCount: 0}) }}
                                    td.text-right.no-brake(v-if="row.lines[0].valueA != 0") {{ row.lines[0].valueA | currency({fractionCount: 0}) }}
                                    td.text-right.no-brake.percent(:class="row.lines[0].percentClass")
                                        span {{ row.lines[0].valuePercent }}%
                                tr
                                    th(colspan="2") {{'report.entries.DATES'|translate}}
                                    td(v-if="!selectedPeriod.cumulative") {{chooseRange(body.tooltip.body[0].lines[0].weekPast)}}
                                    td(v-if="!selectedPeriod.cumulative") {{chooseRange(body.tooltip.body[0].lines[0].weekCurrent, true)}}
                                    td(v-if="selectedPeriod.cumulative") {{chooseRangePast(body.tooltip.body[0].lines[0].weekPast)}}
                                    td(v-if="selectedPeriod.cumulative") {{chooseRangeCurrent(body.tooltip.body[0].lines[0].weekCurrent, true)}}
                                    td &nbsp;

            .panel-body(style="padding-bottom:0" v-if="visible")
                OptiDepartmentsReport(:period="selectedPeriod")

            .panel-body(v-if="visible")
                OptiSummaryEntriesReport(:period="selectedPeriod")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {budgetQuartersAnnotation} from '@/js/app/vue/components/Chart/Base/OptiChartBudgetAnnotations'

    import OptiReportEntriesNavigation from './OptiReportEntriesNavigation'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiChartTooltip from '@/js/app/vue/components/Chart/Base/OptiChartTooltip'
    import OptiDepartmentsReport from '@/js/app/vue/components/Chart/OptiDepartmentsReport'
    import OptiSummaryEntriesReport from '@/js/app/vue/components/Chart/OptiSummaryEntriesReport'

    export default {
        name: 'OptiReportEntries',
        components: {
            OptiReportEntriesNavigation,
            OptiDimmer,
            LineChart,
            OptiChartTooltip,
            OptiDepartmentsReport,
            OptiSummaryEntriesReport
        },
        props: {
            data: {
                type: Object,
                required: false
            }
        },
        data() {
            let leftAxis = null

            return {
                active: false,
                check: null,
                chartData: null,
                selectedPeriod: {
                    chooseYear: 2021,
                    pastYear: 2020, //przy zmianie roku zmień checkbox_w53 i w53
                    period: 'year',
                    pastWeekFrom: 1,
                    pastWeekTo: 13,
                    currentWeekFrom: 1,
                    currentWeekTo: 13,
                    pastQuarter: 1,
                    currentQuarter: 1,
                    cumulative: false,
                    checkbox_w53: moment('2020W53').isoWeek() === 53, //rok taki jak pastYear
                    w53: moment('2020W53').isoWeek() === 53
                },

                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    fill: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: label => label + '%'
                            },
                            afterDataLimits(axis) {
                                leftAxis = axis
                            }
                            }, {
                            position: 'right',
                            ticks: {
                                callback: label => label + '%'
                            },
                            afterDataLimits(axis) {
                                axis.min = leftAxis.min
                                axis.max = leftAxis.max
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {
                            label(tooltipItems, data) {
                                let obj = data.datasets[tooltipItems.datasetIndex]['data_ints'][tooltipItems.index],
                                    weekPast = data.datasets[tooltipItems.datasetIndex]['weekPast'][tooltipItems.index],
                                    weekCurrent = data.datasets[tooltipItems.datasetIndex]['weekCurrent'][tooltipItems.index]

                                return {
                                    label: data.datasets[tooltipItems.datasetIndex].label,
                                    valuePercent: tooltipItems.yLabel,
                                    valueA: obj.a,
                                    valueB: obj.b,
                                    percentClass: tooltipItems.yLabel < 0 ? 'percent-negative' : 'percent-positive',
                                    border: data.datasets[tooltipItems.datasetIndex].borderColor,
                                    background: data.datasets[tooltipItems.datasetIndex].backgroundColor,
                                    weekPast: weekPast,
                                    weekCurrent: weekCurrent
                                }
                            },
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    },
                    annotation: {
                        annotations: [
                            ...budgetQuartersAnnotation()
                        ]
                    }
                },
                styles: {
                    position: 'relative',
                    height: '300px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                },
                departmentId: this.$uac.user.activeDepartmentId,
                visible: this.$uac.hasPermission('reports.entries_summary')
            }
        },
        mounted() {
            window.chart = this
        },
        methods: {
            updatePeriod(period) {
                this.selectedPeriod = period

                this.loadData()
            },

            async loadData() {
                try {
                    this.active = true

                    const {data} = await API.post('charts/entries', {
                        chooseYear: this.selectedPeriod.chooseYear,
                        departmentId: this.departmentId,
                        cumulative: this.selectedPeriod.cumulative,
                        pastYear: this.selectedPeriod.pastYear,
                        period: this.selectedPeriod.period,
                        pastWeekFrom: this.selectedPeriod.pastWeekFrom,
                        pastWeekTo: this.selectedPeriod.pastWeekTo,
                        currentWeekFrom: this.selectedPeriod.currentWeekFrom,
                        currentWeekTo: this.selectedPeriod.currentWeekTo,
                        currentQuarter: this.selectedPeriod.currentQuarter,
                        checkbox_w53: this.selectedPeriod.checkbox_w53
                    })

                    if(data) {
                        this.chartData = data
                        this.chartData.datasets[0].borderColor = 'red'
                        this.chartData.datasets[0].yAxisPosition = 'left'
                        this.chartData.datasets[0].pointHoverRadius = 10
                        this.chartData.datasets[0].pointHitRadius = 10
                        this.chartData.datasets[0].backgroundColor = 'rgba(255,0,0,0.5)'

                        this.chartData.datasets[1].borderColor = 'black'
                        this.chartData.datasets[1].pointHoverRadius = 10
                        this.chartData.datasets[1].pointHitRadius = 10
                        this.chartData.datasets[1].backgroundColor = 'rgba(0,0,0,0.5)'
                        this.chartData.datasets[2].borderColor = 'rgba(255,0,0,0.3)'
                        this.chartData.datasets[2].fill = false
                        this.chartData.datasets[2].pointRadius = 0
                        this.chartData.datasets[2].pointHoverRadius = 0
                        this.chartData.datasets[2].borderDash = [10, 1]
                        this.chartData.datasets[3].borderDash = [10, 1]
                        this.chartData.datasets[3].borderColor = 'rgba(0,0,0,0.3)'
                        this.chartData.datasets[3].fill = false
                        this.chartData.datasets[3].pointRadius = 0
                        this.chartData.datasets[3].pointHoverRadius = 0

                        this.active = false
                    } else {
                        this.active = false
                    }

                    window.ch = this.chartData
                } catch(e) {
                    this.$notify.error('report.entries.notify.error.DEPARTMENT')

                    console.error(e)
                }
            },

            filterOutHeadquarters(items) {
                let headquarters = [91, 49, 149, 60, 86, 392]

                return items.filter(department => headquarters.indexOf(department.id) === -1)
            },

            chooseRangePast(week, end = false) {
                let begin = moment(this.chartData.datasets[0].weekPast[0]),
                    date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = begin.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${begin.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }

                return `${begin.format('D')} - ${endDate.format('D MMMM')}`
            },
            chooseRangeCurrent(week, end = false) {
                let begin = moment(this.chartData.datasets[0].weekCurrent[0]),
                    date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = begin.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${begin.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }

                return `${begin.format('D')} - ${endDate.format('D MMMM')}`
            },
            chooseRange(week, end = false) {
                let date = moment(week),
                    endDate = date.clone().endOf('week'),
                    month = date.format('MMMM')

                if(endDate.format('MMMM') !== month) {
                    return `${date.format('D MMMM')} - ${endDate.format('D MMMM')}`
                }

                return `${date.format('D')} - ${endDate.format('D MMMM')}`
            }
        }
    }
</script>

<style lang="less" scoped>
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }

    .percent-positive {
        > span:before {
            content: '▲'
        }

        color: @btn-success-bg;
    }

    .percent-negative {
        > span:before {
            content: '▼'
        }

        color: @btn-danger-bg;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }

    p.arrow {
        font-size: 20px;
    }
</style>
