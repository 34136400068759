<template lang="pug">
    div
        label(:for="id" v-if="data.type")
            a.link.warn(v-if="data.type === 'saleOffstock'" :title="'default.REFERENCETYPE'|translate"
                :href="$state.href('app.sale-offstock.details', {id: data.id})" target="_blank"
                ) {{'default.validate.SALEOFFSTOCK'|translate}} \#{{data.id}}.
            a.link.warn(v-else-if="data.type === 'sale'" :title="'default.REFERENCETYPE'|translate"
                :href="$state.href('app.sale.details', {id: data.id})" target="_blank"
                ) {{'default.validate.SALE'|translate}} \#{{data.id}}.
            a.link.warn(v-else-if="data.type === 'task'" :title="'default.REFERENCETYPE'|translate"
                :href="$state.href('app.task.detail', {id: data.id})" target="_blank"
                ) {{'default.validate.TASK'|translate}} \#{{data.id}}.
            span(v-else) {{'default.validate.DEFAULT'|translate}}.

            a.link.text-expressive(style="display:block" :title="'default.validate.TITLERECEIPTPREFIX'|translate"
                @click="$emit('add-prefix', data.suffix)") {{'default.validate.RECEIPTPREFIX'|translate}} {{data.suffix}}.
            a.link.text-expressive(style="display:block" :title="'default.validate.TITLENEWCASHREGISTER'|translate"
                :href="$state.href('app.settings', {'#': 'cashRegister'})" target="_blank"
                ) {{'default.validate.NEWCASHREGISTER'|translate}}

        label(:for="id" v-if="!noCashRegister")
            a.link.warn(:title="'default.REFERENCETYPE'|translate"
                :href="$state.href('app.settings', {'#': 'cashRegister'})" target="_blank"
                ) {{'default.validate.NOCASHREGISTER'|translate}}
</template>

<script>
    export default {
        name: 'OptiReceiptValidate',
        props: {
            id: {
                type: String,
                required: true
            },

            data: {
                type: Object,
                required: true
            },
            noCashRegister: {
                type: Boolean,
                required: true
            }
        }
    }
</script>