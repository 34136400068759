<template lang="pug">
    .panel.panel-primary
        .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
            i.fa.fa-navicon.mr3
            span {{'optometrist.correction.SCREENINGTEST'|translate}}
            .pull-right
                i.fa(:class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")
        .panel-body(v-show="toggle")
            .row
                .col-xs-12(style="line-height:2em")
                    i.fa.fa-file-text.mr3
                    span {{'optometrist.correction.ACOMODATIONAMPLITUDE'|translate}}
                    button.btn.btn-info(style="padding: 2px 10px;margin: 0 0 2px 7px" type="button"
                        :class="{'btn-warning active': help}" @click="help = !help") {{'default.HELP'|translate}}
                .col-xs-12(v-show="help")
                    ul.optometrist-tips(style="list-style-type:none")
                        li {{'optometrist.tips.amplitude.1'|translate}}
            .row(style="padding-top:10px")
                .col-sm-6
                    form.form-horizontal
                        .form-group(v-show="age <= 30")
                            label.col-sm-4.control-label
                                span A
                                sub max
                            .col-xs-8.form-control-static {{min}}
                        .form-group
                            label.col-xs-4.control-label
                                span A
                                sub {{'optometrist.AVG'|translate}}
                            .col-xs-8.form-control-static {{avg}}
                        .form-group(v-show="age <= 30")
                            label.col-sm-4.control-label
                                span A
                                sub min
                            .col-xs-8.form-control-static {{max}}
                .col-sm-6.text-center
                    form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label(for="amplitude_distance") {{'optometrist.correction.DISTANCE'|translate}}
                            .col-sm-8
                                OptiNumber#amplitude_distance(:header="'optometrist.correction.DISTANCE'|translate"
                                    :disabled="disabled" :value="interview.amplitude_distance"
                                    @confirmed="v => updateAmplitude('amplitude_distance', v)") {{interview.amplitude_distance}} cm
                        .form-group
                            label.col-sm-4.control-label(for="amplitude_uncorrected") {{'optometrist.correction.UNCORRECTED'|translate}}
                            .col-sm-8
                                OptiNumber#amplitude_uncorrected(:header="'optometrist.correction.UNCORRECTED'|translate"
                                    :disabled="!!interview.dist_r_add || disabled" :value="interview.amplitude_uncorrected"
                                    @confirmed="v => updateAmplitude('amplitude_uncorrected', v)") {{interview.amplitude_uncorrected}} dptr
                        .form-group
                            label.col-sm-4.control-label {{'optometrist.correction.ACOMODATIONAMPLITUDE'|translate}}
                            .col-sm-8.form-control-static.bold.text-expressive {{amplitude}}

</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiOptometristsDetailsRefractionAmplitude',
        components: {
            OptiNumber
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            age: {
                type: Number,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                toggle: false,
                help: false
            }
        },
        mounted() {

        },
        methods: {
            updateAmplitude(field, v) {
                this.interview[field] = v

                this.$emit('update-interview', this.interview)
            }
        },
        computed: {
            min() {
                return this.$filters.number(25 - 0.5 * this.age) + ' dptr'
            },
            avg() {
                return this.$filters.number(18.5 - 0.3 * this.age) + ' dptr'
            },
            max() {
                return this.$filters.number(15 - 0.25 * this.age) + ' dptr'
            },

            amplitude() {
                if(this.interview.amplitude_distance) {
                    return this.$filters.number(100 / this.interview.amplitude_distance +
                        this.interview.dist_r_add + this.interview.amplitude_uncorrected) + ' dptr'
                }

                return '0,00 dptr'
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>