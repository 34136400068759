<template lang="pug">
    div
        .panel.panel-primary(style="position:relative;margin-bottom:0" v-show="permission")
            .panel-heading
                i.fa.fa-building.mr3
                span {{'user.DEPARTMENTS'|translate}}

            .panel-body(style="padding-top:0;padding-bottom:0")
                .row
                    .col-xs-12.bg-separator(style="padding-top:15px" v-show="!disabledAll")
                        .form-group
                            label.control-label.col-sm-7(for="all") {{'user.SELECTASALL'|translate}}
                            .col-sm-5
                                select.form-control#all(name="all" v-model="select_all" @change="changeSelectAll")
                                    option(v-for="role in roles" :value="role.id") {{role.name}}

                    .col-xs-12(v-for="(group, index) in groups" :class="{'bg-separator': index % 2}")
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" :disabled="disabledGroup(group.departments)"
                                    v-model="group.active" @change="groupCheckbox")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{group.name}}
                        .form-group(v-for="department in group.departments" v-show="group.active")
                            label.control-label.col-sm-7(:for="'department-' + department.id") {{department.name}}
                            .col-sm-5
                                select.form-control(:id="'department-' + department.id" :name="'department-' + department.id"
                                    v-show="!department.disabled" v-model="department.roleId" @change="validateDepartments")
                                    option(v-for="role in roles" :value="role.id") {{role.name}}
                                span.form-control(v-show="department.disabled" disabled) {{department.disabled}}
                        .bold.warn(v-show="!group.validate") {{'user.notify.SELECTATLEASTONEDEPARTMENT'|translate}}

        label.warn(v-show="permission && !validate.groups") {{'user.notify.REQUIREDONEGROUPDEPARTMENTS'|translate}}

        .form-group(v-show="common.panel && !permission")
            label.control-label.col-sm-4.star-required(for="common-role") {{'user.ROLEDEFAULT'|translate}}
            .col-sm-8
                select#common-role.form-control(v-model="common.role" name="default_role"
                    @change="$emit('change-common-role', common.role)")
                    option(v-for="role in common.roles" :value="role") {{role.name}}
                .bold.warn(v-show="!common.role") {{'validation.formValidate.required'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiUserAssignDepartments',
        components: {

        },
        props: {
            groups: {
                type: Array,
                required: true
            },

            commonPanel: {
                type: Boolean,
                required: false,
                default: false
            },
            commonRole: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                roles: [],
                select_all: null,

                common: {
                    panel: this.commonPanel,
                    roles: [],
                    role: this.commonRole
                },

                permission: this.$uac.permission('user.assigment_to_salon'),
                validate: {
                    departments: false,
                    groups: false
                }
            }
        },
        mounted() {
            if(this.permission) {
                API.get('roles/hierarchy').then(roles => {
                    this.roles = roles.data
                }).catch(() => {
                    this.$notify.error('role.notify.error.LOAD')
                })

                this.groupCheckbox()
                this.setSelectAll()
            } else {
                API.get('roles/permission/default').then(res => {
                    this.common.roles = res.data

                    this.common.role = this.common.roles[0]
                    this.$emit('change-common-role', this.common.role)
                }).catch(() => {
                    this.$notify.error('role.notify.error.LOADDEFAULT')
                })

                this.setValidate('departments', true)
                this.setValidate('groups', true)
            }
        },
        methods: {
            groupCheckbox() {
                let groups = false

                this.groups.forEach(group => {
                    if(group.active) {
                        groups = true
                    }
                })

                this.setValidate('groups', groups)

                this.validateDepartments()
            },
            disabledGroup(departments) {
                let _return = false

                departments.forEach(department => {
                    if(department.disabled) {
                        _return = true
                    }
                })

                return _return
            },

            setSelectAll() {
                let _return = false

                this.groups.forEach((group, groupId) => {
                    if (!group.active) {
                        _return = false

                        return false
                    } else {
                        group.departments.forEach((department, departmentId) => {
                            if(!groupId && !departmentId) {
                                _return = department.roleId
                            } else if(_return !== department.roleId) {
                                _return = false

                                return false
                            }
                        })

                        if(!_return) {
                            return false
                        }
                    }
                })

                if(_return) {
                    this.select_all = _return
                }
            },
            changeSelectAll() {
                this.groups.forEach(group => {
                    group.active = 1

                    group.departments.forEach(department => {
                        department.roleId = this.select_all
                    })
                })

                this.validateDepartments()

                this.setValidate('groups', true)
                this.setValidate('departments', true)
            },

            validateDepartments() {
                let validate_departments = true

                this.groups.forEach(group => {
                    let _return = false

                    if(group.active) {
                        group.departments.forEach(department => {
                            if(department.roleId) {
                                if(this.roles.length) {
                                    department.role_hierarchy = this.getHierarchy(department.roleId)
                                }

                                _return = true
                            }
                        })
                    } else {
                        _return = true
                    }

                    group.validate = _return

                    if(!group.validate) {
                        validate_departments = false
                    }
                })

                this.setValidate('departments', validate_departments)
            },

            setValidate(type, bool) {
                this.validate[type] = bool

                this.$emit('validate-was-changed', this.validate.departments && this.validate.groups)
            },

            getHierarchy(role_id) {
                let _return = 0

                this.roles.forEach(role => {
                    if(role.id === role_id) {
                        _return = role.hierarchy
                    }
                })

                return _return
            }
        },
        computed: {
            disabledAll() {
                let _return = false

                this.groups.forEach(group => {
                    group.departments.forEach(department => {
                        if(department.disabled) {
                            _return = true
                        }
                    })
                })

                return _return
            }
        }
    }
</script>