<template lang="pug">
    .optometrist-tips
        h4(v-show="data.info.length") {{'optometrist.tips.header.INFO'|translate}}
        ul
            li(v-for="info in data.info" v-html="$options.filters.translate(info)")

        h4(v-show="data.instruction") {{'optometrist.tips.header.INSTRUCTION'|translate}}
        ul
            li(v-for="instruction in data.instruction" v-html="$options.filters.translate(instruction)")

        h4(v-show="data.procedure.length") {{'optometrist.tips.header.PROCEDURE'|translate}}
        ul
            li(v-for="procedure in data.procedure" v-html="$options.filters.translate(procedure)")
</template>

<script>
    export default {
        name: 'OptiOptometristsTips',
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>