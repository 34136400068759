class FullScreenModeClass {
    open() {
        let elem = document.documentElement

        if(elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if(elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen()
        } else if(elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen()
        }

        document.body.style.overflow = 'hidden'
    }

    close() {
        if(document.exitFullscreen) {
            document.exitFullscreen()
        } else if(document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen()
        } else if(document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen()
        }

        document.body.style.overflow = ''
    }
}

export const FullScreenMode = new FullScreenModeClass()