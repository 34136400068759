import _ from 'lodash'
import Vue from 'vue'

import Notify from '@/js/app/vue/helpers/Notify'

export default {
    filterModuleByDepartment(state) {
        let department_id = state.department_id,
            name = 'module',
            filter = {
                selected: state.filters.selected.filter(s => s.name === name),
                type: state.filters.types.filter(t => t.name === name)[0]
            }

        Vue.set(filter.type, 'disabled', !filter.type.options.filter(o => o.department_id === department_id).length)

        if(filter.selected.length === 1) {
            filter.selected = filter.selected[0]

            filter.selected.options = _.cloneDeep(filter.type).options.filter(t => t.department_id === department_id)

            if(filter.selected.option.length || !filter.selected.options.length) {
                let filter_to_delete_key = _.findKey(state.filters.selected, s => s.name === name)

                state.filters.selected.splice(filter_to_delete_key, 1)

                if(filter_to_delete_key) {
                    Notify.warning('catalog.offer.layout.MODULE_DELETE', true, true)
                }
            }
        }
    }
}