<template lang="pug">
    form#cart-sendback(name="cartSendback")

        #cart-sendback-product.row(v-show="edit")
            .col-xs-6
                OptiSelectProduct(:product="sendback.productIdBarcode" :auto-select="true" @selected="updateProduct")

                MountingPortal(v-if="product" mountTo="#vue-modal" append)
                    OptiCartDialogProductConfirm(type="sendback" :product="product"
                        @confirmed="addProduct" @closed="product = null")
            .col-xs-6.text-right
                OptiButton(type="warning" text="carts.confirm.gosendback" :disabled="disabled" @confirmed="goSendback") {{'carts.sale.GOSENDBACK'|translate}}
                    template(#icon)
                        i.fa.fa-archive

        #cart-sendback-warning-free.row(v-show="sendback.items.length && !sendback.totals.value")
            .col-xs-12.text-center.warn
                i.fa.fa-exclamation-circle.mr3
                span {{'carts.sendback.notify.error.free.RETURN'|translate}}

        #cart-sendback-table.row
            .col-xs-12
                .panel.panel-warning
                    .panel-heading
                        i.fa.fa-archive.mr3
                        span {{'carts.type.fullname.SENDBACK'|translate}}

                    .table-responsive
                        table.table
                            thead
                                tr
                                    th(v-show="edit")
                                        .checkbox.c-checkbox.checkbox-warning
                                            label
                                                input(type="checkbox" v-model="checkbox_all")
                                                span.fa.fa-check
                                    th #
                                    th {{'carts.PRODUCTID'|translate}}
                                    th {{'carts.NAME'|translate}}
                                    th {{'carts.PRICE'|translate}}
                                    th {{'carts.QUANTITY'|translate}}
                                    th {{'carts.VALUE'|translate}}
                                    th(v-show="edit")

                            tbody
                                template(v-for="(item, key) in sendback.items")
                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td(v-show="edit")
                                            .checkbox.c-checkbox.checkbox-warning
                                                label
                                                    input(type="checkbox" :checked="item.checkbox"
                                                        @change="e => updateCheckbox(key, e)")
                                                    span.fa.fa-check
                                        td {{sendback.items.length - key}}
                                        td.text-right
                                            OptiProduct(:product="item.product") {{item.product.id}}
                                        td
                                            OptiProduct(:product="item.product") {{item.product.name}}
                                            .label.label-success.pull-right(v-show="item.highlight") {{'carts.NEW'|translate}}
                                            small(style="display:block") {{item.product.fullName}}
                                        td.text-right {{item.product.price|currency}}
                                        td.col-xs-1.text-right
                                            OptiNumber(v-show="edit" :header="'carts.QUANTITY'|translate" :required="true"
                                                :value="item.quantity" @confirmed="quantity => updateQuantity(key, quantity)") {{item.quantity}}
                                            span(v-show="!edit") {{item.quantity}}
                                        td.text-right {{item.product.price * item.quantity|currency}}
                                        td.text-center(v-show="edit")
                                            label.btn.btn-danger(:for="'cart-sendback-remove-' + key")
                                                i.fa.fa-fw.fa-remove
                                            OptiButton.hide(:id="'cart-sendback-remove-' + key" text="carts.confirm.remove"
                                                @confirmed="deleteItems([item.id])")
                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td.b0
                                        td.b0(:colspan="3")
                                            .warn(v-show="item.product.depository.stock - item.quantity < 0")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.nostock.LOCAL'|translate}}
                                        td.b0(colspan="3" v-show="edit")
                                        td.b0.text-center
                                            OptiAutoSave(endpoint="carts/update" fields="quantity,discount,comments"
                                                :model="item" :key="item.id")

                                tr(v-show="!sendback.items.length")
                                    td.text-center.text-muted(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'default.table.EMPTY'|translate}}

                            tfoot
                                tr.align-middle
                                    td(colspan="4" v-show="edit")
                                        OptiButton(type="danger" :disabled="sendback.disabled_btn_delete"
                                            text="carts.confirm.remove.all" @confirmed="deleteItemsFromCheckbox"
                                            ) {{'carts.DELETEITEMS'|translate}}
                                            template(#icon)
                                                i.fa.fa-remove
                                    td(colspan="3" v-show="!edit")
                                    th.text-right {{'carts.TOTALS'|translate}}
                                    th.text-right {{sendback.totals.quantity}}
                                    th.text-right {{sendback.totals.value|currency}}
                                    td(v-show="edit")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiCartDialogProductConfirm from '@/js/app/vue/components/Carts/Dialogs/OptiCartDialogProductConfirm'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'

    export default {
        name: 'OptiCartSendback',
        components: {
            OptiButton,
            OptiSelectProduct,
            OptiCartDialogProductConfirm,
            OptiNumber,
            OptiProduct,
            OptiAutoSave
        },
        props: {
            sendback: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('carts.sendback.edit'),

                product: null
            }
        },
        mounted() {

        },
        methods: {
            updateProduct(product) {
                this.product = product
            },
            addProduct() {
                this.$store.dispatch('carts/addProduct', {
                    type: 'sendback',
                    product: this.product
                })

                this.product = null
            },

            updateQuantity(key, quantity) {
                this.$store.dispatch('carts/updateQuantity', {
                    type: 'sendback',
                    key: key,
                    quantity: quantity
                })
            },

            updateCheckbox(key, event) {
                this.$store.dispatch('carts/updateCheckbox', {
                    type: 'sendback',
                    key: key,
                    bool: event.target.checked
                })
            },

            deleteItems(item_ids) {
                this.$store.dispatch('carts/deleteItems', {
                    type: 'sendback',
                    item_ids: item_ids
                })
            },
            deleteItemsFromCheckbox() {
                this.$store.dispatch('carts/deleteItemsFromCheckbox', 'sendback')
            },


            goSendback() {
                this.$emit('toggled')
            }
        },
        computed: {
            disabled() {
                return this.$store.getters['carts/getDisabled']('sendback')
            },
            checkbox_all: {
                get() {
                    return this.$store.getters['carts/getCheckboxAll']('sendback')
                },
                set(value) {
                    this.$store.dispatch('carts/updateCheckboxAll', {
                        type: 'sendback',
                        bool: value
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    #cart-sendback-product,
    #cart-sendback-warning-free {
        margin-bottom: 10px;
    }
</style>