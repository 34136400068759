<template lang="pug">
    div
        .row(v-show="!loading")
            OptiPatientsDetailsPrescriptionsRecommendedVisits

            .col-xs-12.text-right(style="margin-bottom:10px" v-show="!is_sun_saler")
                OptiButton(type="success" :disabled="block"
                    @click.native="$state.go('app.patient.prescription-add', {patientId: $state.params.id})"
                    ) {{'prescription.ADDPRESCRIPTION'|translate}}
                    template(#icon)
                        i.fa.fa-plus

            .col-xs-12(v-for="d in data")
                OptiPatientsPrescription(
                    v-if="d.type === 'prescription'"

                    :data="d.data"
                    :offers="offers"
                    :offer-id="offerId"

                    :own="departmentId === $uac.user.activeDepartmentId"
                    :block="block"

                    @reload-covd="$emit('reload-covd')")

                OptiPatientsDetailsPrescriptionsMatching(
                    v-if="d.type === 'matching'"
                    :match="d.data")

            .col-xs-12(v-show="!data.length")
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'prescription.notify.NOHAVE'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiPatientsDetailsPrescriptionsRecommendedVisits
        from '@/js/app/vue/components/Patients/Details/Prescriptions/OptiPatientsDetailsPrescriptionsRecommendedVisits'
    import OptiPatientsPrescription from '@/js/app/vue/components/Patients/Prescriptions/OptiPatientsPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '../../../Blocks/OptiDimmer'
    import OptiPatientsDetailsPrescriptionsMatching
        from '@/js/app/vue/components/Patients/Details/Prescriptions/OptiPatientsDetailsPrescriptionsMatching'

    export default {
        name: 'OptiPatientsDetailsPrescriptions',
        components: {
            OptiPatientsDetailsPrescriptionsMatching,
            OptiPatientsDetailsPrescriptionsRecommendedVisits,
            OptiPatientsPrescription,
            OptiButton,
            OptiDimmer
        },
        props: {
            departmentId: {
                type: Number,
                required: true
            },
            block: {
                type: Boolean,
                required: true
            },

            offers: {
                type: Array,
                required: true
            },
            offerId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                data: [],

                is_sun_saler: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
                loading: true
            }
        },
        mounted() {
            API.get('patient/prescriptions/fromPatientId/' + this.$state.params.id).then(res => {
                this.data = res.data

                let count = 0,
                    show = false

                this.data.forEach(d => {
                    if(d.type === 'prescription') {
                        if(d.data.id === this.$state.params.prescriptionId) {
                            d.data.show = true

                            if(this.$state.params.historySurvey) {
                                d.data.historySurvey = true
                            }
                        }

                        ++count

                        if(!this.$state.params.prescriptionId && !show) {
                            d.data.show = true

                            show = true
                        }
                    }
                })

                if(count) {
                    this.$emit('update-count', 'prescriptions', count)
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('prescription.notify.ERRORLOAD')
            })
        }
    }
</script>
