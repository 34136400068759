<template lang="pug">
    .row
        .col-xs-12.text-right(style="margin-bottom:21px" v-show="!loading")
            OptiButton(type="success" @click.native="addCategory") {{'events.categories.ADD'|translate}}
                template(#icon)
                    i.fa.fa-plus

        .col-xs-12(v-show="!loading")
            .panel.panel-primary(v-for="(category, category_index) in categories" :key="category_index")
                .panel-heading
                    i.fa.fa-folder-o.mr3
                    span(style="margin-right:10px") {{category.name}}
                    i.fa.fa-pencil(v-show="category.old_name !== category.name")

                .panel-body
                    .row
                        .col-sm-6
                            OptiButton(type="info" v-show="!category.mode_change_name"
                                @click.native="category.mode_change_name = true") {{'events.categories.CHANGENAME'|translate}}
                                template(#icon)
                                    i.fa.fa-edit

                            .input-group(style="cursor:pointer" v-show="category.mode_change_name")
                                input.form-control(v-model="category.name" @keyup.enter="category.mode_change_name = false")
                                span.input-group-addon(@click="category.mode_change_name = false")
                                    i.fa.fa-save

                        .col-sm-6.text-right
                            OptiButton(type="danger" text="events.categories.delete.text" @confirmed="categories.splice(category_index, 1)"
                                ) {{'events.categories.delete.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-remove

                    draggable#upload-container(:list="category.uploads" :group="'uploads-' + category_index" v-bind="dragOptions" draggable=".is_draggable")
                        .upload(v-for="(upload, upload_index) in category.uploads" :key="upload_index" :class="{'is_draggable': upload.file}")
                            OptiUpload#event-upload(
                                prefix="events"

                                :file="upload.file"

                                @update-loading="l => upload.loading = l"
                                @update-file="f => upload.file = f")

                            label.btn.btn-danger(style="margin-top:5px" :for="'upload-delete-' + category_index + '-' + upload_index"
                                :disabled="category.uploads.length === 1")
                                i.fa.fa-trash

                            OptiButton.hide(:id="'upload-delete-' + category_index + '-' + upload_index"
                                text="events.categories.upload.delete" @confirmed="category.uploads.splice(upload_index, 1)")

                    .row
                        .col-xs-12.text-right
                            OptiButton(type="success" :disabled="!!category.uploads.filter(u => !u.file).length"
                                @click.native="addUpload(category)") {{'events.categories.upload.ADD'|translate}}
                                template(#icon)
                                    i.fa.fa-plus

            .text-center.text-muted(style="margin-bottom:21px" v-show="!categories.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'default.table.EMPTY'|translate}}

        .col-xs-12.text-center(v-show="!loading")
            OptiButtonSave(:disabled="disabled" @click.native="goSave")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import draggable from 'vuedraggable'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEventsUpload',
        components: {
            draggable,
            OptiButton,
            OptiUpload,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                categories: [],

                dragOptions: {
                    animation: 200,
                    group: 'uploads',
                    disabled: false,
                    ghostClass: 'ghost',
                    scrollSensitivity: 200,
                    forceFallback: true
                },

                loading: true
            }
        },
        mounted() {
            API.get('events').then(res => {
                this.categories = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('events.notify.error.LOAD')
            })
        },
        methods: {
            addCategory() {
                this.categories.push({
                    new: true,
                    old_name: this.$filters.translate('events.categories.TITLE'),
                    name: this.$filters.translate('events.categories.TITLE'),
                    mode_change_name: false,
                    uploads: []
                })

                this.addUpload(this.categories[this.categories.length - 1])
            },
            addUpload(category) {
                category.uploads.push({
                    new: true,
                    file: null,
                    old_position: category.uploads.length,
                    loading: false
                })
            },
            goSave() {
                this.loading = true

                API.post('events/save', {
                    categories: this.categories
                }).then(res => {
                    this.categories = res.data

                    this.$notify.success('events.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('events.notify.error.SAVE')
                })
            }
        },
        computed: {
            disabled() {
                let category_names = this.categories.map(c => c.name),
                    duplicate_category_names = category_names.length !== [...new Set(category_names)].length,
                    category_names_empty = !!this.categories.filter(c => !c.name).length,
                    category_without_uploads = false,
                    not_uploaded = false

                this.categories.forEach(category => {
                    if(!category.uploads.length) {
                        category_without_uploads = true
                    }

                    if(category.uploads.filter(u => !u.file).length) {
                        not_uploaded = true
                    }
                })

                return duplicate_category_names || category_names_empty || category_without_uploads || not_uploaded
            }
        }
     }
</script>

<style lang="less" scoped>
    #upload-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        text-align: center;
        margin: 20px 0;

        > .upload {
            width: 190px;
            margin: 11px;

            cursor: not-allowed;

            &.is_draggable {
                cursor: move;
            }

            &.ghost {
                background: yellow;
            }
        }
    }
</style>