<template lang="pug">
    .opti-left-menu(@mouseleave="delayDeselect" @mouseenter="resetDeselect" :class="menuClass" v-if="$uac.user.id")
        .opti-left-menu__header(v-if="isMobile")
            OptiSelectChangeActiveDepartment
        .opti-left-menu__content
            ul(v-if="$asyncComputed.items.success")
                //li(v-for="item in items.menu") {{item}}
                OptiMenuItem(
                    v-for="item in topMenu"
                    :key="item.idx"
                    :item="item"
                    @click="updateSelected"
                    :class="{ 'active': isActive(item) }"
                    :show-dot="true"
                    :use-hardcoded-labels="true"
                    :show-names="shouldShowNames"
                )

            ul(v-if="$asyncComputed.items.success")
                OptiMenuItem(
                    v-for="item in bottomMenu"
                    :key="item.idx"
                    :item="item"
                    @click="updateSelected"
                    :class="{ 'active': isActive(item) }"
                    :show-dot="true"
                    :use-hardcoded-labels="true"
                    :show-names="shouldShowNames"
                )

        .opti-left-menu__level(
            v-if="breadcrumb.length"
            v-for="(bread, idx) in breadcrumb"
            :class="breadClass(bread, idx)"
            :key="idx"
        )
            ul()
                OptiMenuItem.opti-left-menu__nav-back(
                    v-if="windowWidth < 768"
                    :item="navBackItem"
                    @click="navBack"
                )
                OptiMenuItem(
                    v-for="item in bread.submenu"
                    :key="item.idx"
                    :item="item"
                    :class="{ 'active': isActive(item) }"
                    @click="updateSelected"
                    :show-dot="false"
                )
                OptiMenuItem.opti-left-menu__nav-back(
                    v-if="isMobile"
                    :item="navBackItem"
                    @click="navBack"
                )

        //.opti-left-menu__footer
            p futer
</template>

<script>
import {API} from '../../api'
import {EventBus} from '../../../_bridge/EventBus'

import OptiMenuItem from './OptiMenuItem'
import OptiSelectChangeActiveDepartment from '../Select/OptiSelectChangeActiveDepartment'

export default {
    name: 'OptiLeftMenu',
    components: {OptiSelectChangeActiveDepartment, OptiMenuItem},
    data() {
        return {
            currentItem: false,
            breadcrumb: [],
            userMenuItem: {
                translate: 'sidebar.SETTINGS'
            },
            deselect: false,
            opened: false,
            navBackItem: {
                translate: 'sidebar.NAVIGATION',
                sref: '',
                icon: 'fa fa-arrow-left'
            }
        }
    },
    mounted() {
        //this.$store.dispatch('dashboard/fetch')
        EventBus.$on('MENU_OPEN', () => {
            this.opened = true
        })
        EventBus.$on('MENU_CLOSE', () => {
            this.opened = false
        })
        EventBus.$on('MENU_TOGGLE', () => {
            this.opened = !this.opened
        })
    },
    asyncComputed: {
        async items() {
            const menuJson = '../server/sidebar-menu.json'
            const menuUrl  = menuJson + '?v=' + (new Date().getTime())

            let {data} = await API.get(menuUrl)

            let idx = 1
            let addParentNodes = (items, parent) => {
                _.each(items, item => {
                    item.parent = parent
                    item.idx = 'idx' + idx
                    idx++
                    if (item.submenu) {
                        addParentNodes(item.submenu, item)
                    }
                })

                return items
            }
            let menuWithSettings = data.menu
            let settings = data.settings[0]

            menuWithSettings.unshift({
                separator: true
            })

            if(menuWithSettings.length !== 2) { // this.$uac.user.activeRole.short !== 'Katalog'
                menuWithSettings.unshift({
                    translate: 'alert.ALERTS',
                    sref: 'app.alerts',
                    params: {
                        search: 'read:=:no'
                    },
                    icon: 'fa fa-bell',
                    // TODO: alertUnreadMessages
                    //forceFullWidth: true
                })
                menuWithSettings.unshift({
                    translate: 'timeline.MESSAGES',
                    icon: 'fa fa-comments',
                    // TODO: counter wiadomości, angular robi unreads.all
                    submenu: [
                        {
                            translate: 'sidebar.messages.NEWMESSAGE',
                            sref: 'app.messages.view.create',
                            icon: 'fa fa-envelope'
                        },
                        {
                            translate: 'sidebar.messages.BOX',
                            sref: 'app.messages.view.inbox',
                            label: 'unreadMessage',
                            // TODO: counter
                            icon: 'fa fa-inbox'
                        }
                    ]
                })
            }

            menuWithSettings.unshift({
                translate: 'sidebar.DASHBOARD',
                icon: 'fa fa-home',
                sref: 'app.dashboard'
            })

            settings.submenu.push({
                translate: 'default.LOGOUT',
                sref: 'app.logout',
                icon: 'fa fa-sign-out'
            })

            settings.bottom = true
            settings.icon = 'fa fa-power-off'

            menuWithSettings.push(settings)

            let menu = addParentNodes(menuWithSettings, false)

            return {
                menu: menu,
                settings: data.settings
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.getters['globals/windowWidth']
        },
        isMobile() {
            return this.$store.getters['globals/isMobile']
        },
        menuClass() {
            let cls = []

            cls.push('user-color-' + this.$uac.user.color)

            if (!this.currentItem && !this.opened) {
                cls.push('opti-left-menu__small')
            }

            if (this.opened) {
                cls.push('opti-left-menu__opened')
            }

            return cls.join(' ')
        },
        shouldShowNames() {
            return (!!this.currentItem || this.windowWidth > 1025) || this.opened
        },
        topMenu() {
            let items = this.items.menu

            _.each(items, item => {
                if (item.translate === 'timeline.MESSAGES') {
                    let submenu = item.submenu.splice(0, 2)

                    let messages = this.$store.getters['dashboard/unread'].info

                    _.each(messages, message => {
                        submenu.push({
                            idx: message.id,
                            text: message.title,
                            sref: 'app.messages.view.details',
                            params: {
                                id: message.id
                            },
                            icon: 'icon-speech'
                        })
                    })

                    item.submenu = submenu
                }
            })

            if (this.isMobile) {
                return items
            }

            return items.filter(item => !item.bottom)
        },
        bottomMenu() {
            if (this.isMobile) {
                return []
            }

            return this.items.menu.filter(item => item.bottom)
        }
    },
    methods: {
        delayDeselect() {
            this.resetDeselect()
            if (this.windowWidth > 767) {
                this.deselect = setTimeout(() => {
                    this.updateSelected(false)
                }, 500)
            } else {
                this.updateSelected(false)
            }
        },
        resetDeselect() {
            if (this.deselect) {
                clearTimeout(this.deselect)
                this.deselect = false
            }
        },
        label(name) {
            return this.$store.getters['dashboard/get'](name)
        },
        updateSelected(item) {
            if (item && !item.submenu) {
                this.opened = false
                // Nie ma submenu wiec to link docelowy
                if (item.href) {
                    window.location = item.href
                } else {
                    this.$router.push({
                        name: item.sref,
                        params: item.params || {}
                    })

                    this.$nextTick(fn => {
                        this.updateSelected(false)
                    })
                }
            }

            this.currentItem = item
            this.updateBreadcrumb(item)
        },
        updateBreadcrumb(item) {
            if (!item) {
                this.breadcrumb = []
                return
            }
            //if (!item.submenu) {
            //    return
            //}

            let crumbs = []
            let current = item

            if (!current.submenu) {
                current = current.parent
            }

            while (current) {
                crumbs.unshift(current)
                current = current.parent
            }

            this.breadcrumb = crumbs

            if (this.breadcrumb.length > 0) {
                if (this.breadcrumb.length === 1) {
                    this.navBackItem.translate = 'sidebar.NAVIGATION'
                } else {
                    let previousCrumb = this.breadcrumb[this.breadcrumb.length - 2]
                    this.navBackItem.translate = previousCrumb.translate
                }
            }

            return crumbs
        },
        navBack() {
            if (!this.breadcrumb.length) {
                return;
            }

            if (this.breadcrumb.length === 1) {
                this.updateSelected(false)
                return;
            }

            let [previousCrumb] = this.breadcrumb.slice(-2, 1)
            this.updateSelected(previousCrumb)
        },
        breadClass(bread, idx) {
            return 'opti-left-menu__level' + idx + ' ' //+ (bread.bottom ? 'opti-left-menu__level_bottom' : '')
        },
        isChildActive(items) {
            for (let i = 0; i < items.length; i++) {
                if (items[i] === this.currentItem) return true
                if (items[i].submenu) {
                    let result = this.isChildActive(items[i].submenu)
                    if (result) {
                        return true
                    }
                }
            }

            return false
        },
        isActive(item) {
            if (!this.currentItem) return false
            if (!item) return false
            if (item === this.currentItem) return true
            if (item.submenu) {
                return this.isChildActive(item.submenu)
            }
            return false
        }
    },
}
</script>

<style scoped lang="less">
ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.opti-left-menu {
    position: fixed;

    top: 0;
    left: -15px;
    bottom: 0;
    z-index: 1000;

    width: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    margin: 0;

    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);

    @media only screen
    and (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }

    &__small {
        @media only screen
        // and (min-width: 768px)
        and (max-width: 1024px) {
            left: -15px;
            width: 65px;
        }
    }

    &__content {
        width: 240px;
        //display: inline-flex;
        display: flex;

        flex-direction: column;
        flex-wrap: nowrap;
        //justify-content: flex-start;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;

        & > ul {
            display: flex;
            justify-content: center;
            flex-direction: column;

            & > .opti-menu-item {

            }
        }
    }

    &__level {
        padding-top: 8px;
        width: 240px;

        z-index: 1020;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        box-shadow: 5px 0px 20px -5px rgba(0,0,0,0.5);

        & > ul {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-evenly;

            & > li {
                flex-grow: 1;
                border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
            }

            & > li:last-child {
                border-bottom: 0;
            }

            & > li:nth-child(2n) {
                //background-color: rgba(0, 0, 0, 0.05);
                //border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
                //border-left: 3px dotted rgba(255, 255, 255, 0.3);
            }
        }
    }
    &__level_bottom {
        justify-content: flex-end;
    }

    &__level0 {
        left: 240px;
    }
    &__level1 {
        left: 480px;
    }
    &__level2 {
        left: 720px;
    }


    &__nav-back {
        margin-top: 48px;
        margin-bottom: 48px;
        background-color: rgba(0, 0, 0, 0.2);
    }
}

@media only screen and (max-width: 767px) {
    .opti-left-menu {
        width: 0;
        padding-top: 80px;
        padding-bottom: 148px;

        &__opened {
            left: 0;
            width: 100%;
        }

        &__content {
            width: 100%;
        }

        &__header {
            position: relative;
            text-align: center;
            padding-bottom: 48px;
            & > .opti-select-change-active-department {
                width: 80%;
            }
        }

        &__level {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //padding-top: 56px;
            //padding-bottom: 56px;
            width: unset;

            & > ul {
                display: block;
                height: unset;
                padding-top: 100px;
                padding-bottom: 100px;

                & > .opti-menu-item {
                    height: unset;
                }
            }
        }

        &__level0, &__level1, &__level2 {
            left: 0;
        }
    }
}
</style>
