<template lang="pug">
    .alert.alert-danger.text-left(v-show="visits.length > 1")
        i.fa.fa-exclamation-circle.mr3
        span {{'patient.notify.refuse.HEADING'|translate}}

        ul
            li(v-for="visit in visits" :class="{'disabled': visit.id === visitId}")
                span.mr3 {{'patient.notify.refuse.DAY'|translate}}
                a.link(@click="goToVisit(visit)") {{visit.date}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiPatientVisitsRefuse',
        props: {
            patientId: {
                type: Number,
                required: true
            },
            visitId: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                visits: []
            }
        },
        mounted() {
            API.get('patient/refuse/' + this.patientId, API.id(Math.random())).then(res => {
                this.visits = res.data
            }).catch(() => {
                this.$notify.error('patient.notify.error.REFUSE')
            })
        },
        methods: {
            goToVisit(visit) {
                if(visit.id !== this.visitId) {
                    this.$state.go('app.patients.schedule', {
                        visit_id: visit.id,
                        department_id: visit.department_id,
                        date: visit.link_date
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    ul > li.disabled {
        opacity: .5;

        > a.link {
            cursor: default;
            text-decoration: none;
        }
    }
</style>
