<template lang="pug">
    #lens-container
        .lens-info(v-if="active.main.id")
            .lens-info-label
                span.lens-info-label-name(:title="active.main.extra.name"
                    @click="$emit('go-to-category', active.main.extra.category_id)") {{active.main.extra.name}}
                span.lens-info-label-price {{active.main.extra.price}} zł
                i.lens-info-label-close.fa.fa-3x.fa-close(:title="'default.CLOSE'|translate" @click="$emit('close')")
            OptiPictograms(:pack-details="active.main.extra.pictograms")

            ul.lens-info-errors(v-show="active.main.extra.disabled.errors.length")
                li(v-for="error in active.main.extra.disabled.errors")
                    i.fa.fa-exclamation-circle.mr3
                    span {{error.text}}

            ul.lens-info-errors.lens-info-errors-warnings(v-show="active.main.extra.disabled.warnings.length")
                li(v-for="warning in active.main.extra.disabled.warnings")
                    i.fa.fa-exclamation-circle.mr3
                    span {{warning.text}}

        .lens-info(v-if="active.main.id && active.compare.id")
            .lens-info-compare-label
                i.fa.fa-arrow-down
                span {{active.compare.label}}
                i.fa.fa-arrow-down

        .lens-info(v-if="active.compare.id")
            .lens-info-label
                span.lens-info-label-name(:title="active.compare.extra.name"
                    @click="$emit('go-to-category', active.compare.extra.category_id)") {{active.compare.extra.name}}
                span.lens-info-label-price {{active.compare.extra.price}} zł
            OptiPictograms(:pack-details="active.compare.extra.pictograms")

            ul.lens-info-errors(v-show="active.compare.extra.disabled.errors.length")
                li(v-for="error in active.compare.extra.disabled.errors")
                    i.fa.fa-exclamation-circle.mr3
                    span {{error.text}}

            ul.lens-info-errors.lens-info-errors-warnings(v-show="active.compare.extra.disabled.warnings.length")
                li(v-for="warning in active.compare.extra.disabled.warnings")
                    i.fa.fa-exclamation-circle.mr3
                    span {{warning.text}}

        .lens-info(v-if="active.main.id && active.compare.id")
            .lens-info-label
                span.lens-info-label-name(style="cursor:default" :title="'catalog.graph.DIFFERENCE'|translate"
                    ) {{'catalog.graph.DIFFERENCE'|translate}}

            .lens-info-compare
                OptiPictograms(v-if="active.compare.diff.deleted.length" :pack-details="active.compare.diff.deleted")
                i.fa.fa-ban.fa-lg(v-else)

                i.fa.fa-arrow-right

                OptiPictograms(v-if="active.compare.diff.added.length" :pack-details="active.compare.diff.added")
                i.fa.fa-ban.fa-lg(v-else)
</template>

<script>
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'

    export default {
        name: 'OptiCatalogGraphPanelInfo',
        components: {
            OptiPictograms
        },
        props: {
            active: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    #lens-container {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 10px;

        > .lens-info {
            margin: 5px;
            padding: 5px;
            background-color: rgba(0, 0, 0, .8);
            border-radius: 5px;

            > .lens-info-label {
                color: #fff;
                padding: 0 15px 0 8px;

                > .lens-info-label-name {
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                > .lens-info-label-price {
                    float: right;
                    padding-top: 4px;
                }

                > .lens-info-label-close {
                    position: absolute;
                    top: 5px;
                    right: -60px;
                    padding: 5px 10px;
                    background-color: rgba(0, 0, 0, .8);
                    border-radius: 5px;
                    cursor: pointer;
                }
            }

            > .lens-info-compare-label {
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                font-weight: 700;
                color: #fff;

                > i {
                    margin-top: 3px;
                }
            }

            > .lens-info-compare {
                > div {
                    display: inline-block;
                }

                > i.fa-lg {
                    padding: 8px 8px 3px;
                    opacity: .5;
                }
            }
        }
    }
</style>