<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-phone.mr3
            span {{'sms.dialog.SENDSMS'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="closed")

        template(#body)
            form#form-send-sms.form-horizontal(name="formSendSms")
                .panel-body
                    .form-group(v-show="!patient.cellPhoneNumber")
                        .col-sm-8.col-sm-offset-4
                            .alert.alert-danger(style="margin-bottom:0")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'sms.dialog.notify.error.PATIENTNOPHONE'|translate}}
                    .form-group
                        label.col-sm-4.control-label {{'sms.dialog.TO'|translate}}
                        .col-sm-8.form-control-static
                            a.link(:href="$state.href('app.patient.detail', {id: patient.id})" target="_blank" v-show="patient.id"
                                :title="'sms.dialog.title.PATIENT'|translate") {{patient.firstName}} {{patient.lastName}}
                            span(v-show="!patient.id") {{patient.firstName}} {{patient.lastName}}
                            span(style="margin-left:3px") {{patient.cellPhoneNumber|number(0)}}
                    .form-group
                        label.col-sm-4.control-label(for="title") {{'sms.dialog.TITLE'|translate}}
                        .col-sm-8
                            select#title.form-control(v-model="template_id" name="template" @change="updateBody()")
                                option(v-for="template in templates" :value="template.id") {{template.title}}
                    .form-group
                        label.col-sm-4.control-label.star-required(for="body") {{'sms.dialog.MESSAGE'|translate}}
                        .col-sm-8
                            textarea#body.form-control(v-model="body" name="body" rows="10" required)
                            OptiValidate(f="body" :data="$v.body")
                    .form-group
                        label.col-sm-4.control-label {{'sms.dialog.COUNT'|translate}}
                        .col-sm-8.form-control-static {{count}}

        template(#footer)
            OptiButton(type="success" :disabled="!patient.cellPhoneNumber || $v.body.$invalid"
                @click.native="sendSMS()") {{'sms.dialog.action.SENDSMS'|translate}}
                template(#icon)
                    i.fa.fa-envelope

            OptiButton.pull-left(@click.native="closed") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {SMSHelper} from '@/js/app/vue/helpers/SMSHelper'
    import {required} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogSms',
        components: {
            OptiDialog,
            OptiValidate,
            OptiDimmer,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            patient: {
                type: Object,
                required: true
            },
            tags: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                templates: [],
                template_id: 0,

                body: '',

                loading: true
            }
        },
        validations: {
            body: {
                required
            }
        },

        mounted() {
            if(this.patient.cellPhoneNumber) {
                API.post('sms/templates/generate', this.tags).then(res => {
                    this.templates = res.data

                    if(this.templates.length === 1) {
                        this.template_id = this.templates[0].id
                        this.updateBody()
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sms.dialog.notify.error.TEMPLATES')
                })
            } else {
                this.loading = false
            }
        },
        methods: {
            updateBody() {
                this.templates.forEach(template => {
                    if(template.id === this.template_id) {
                        this.body = template.body
                    }
                })
            },
            sendSMS() {
                this.loading = true

                API.post('task/sms/send', {
                    patientId: this.patient.id,
                    patientName: this.patient.firstName + ' ' + this.patient.lastName,
                    patientCity: this.patient.city,
                    phoneNumber: this.patient.cellPhoneNumber,
                    salonId: this.patient.departmentId,
                    body: this.body,
                    type: Object.keys(this.tags).includes('type') ? this.tags.type : 0,
                    ref_id: Object.keys(this.tags).includes('ref_id') ? this.tags.ref_id : 0
                }).then(res => {
                    if(res.data.status === 'OK') {
                        this.$notify.success('sms.dialog.notify.SENDED')

                        this.$emit('sent')
                        this.closed()
                    } else {
                        this.$notify.error(res.data.status, false)
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sms.dialog.notify.error.NOSEND')
                })
            },
            closed() {
                this.$emit('closed')
            }
        },
        computed: {
            count() {
                return SMSHelper.count(this.body)
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 100%;

        .modal-body {
            padding: 0;
        }
    }
</style>