<template lang="pug">
    OptiDialog.fullscreen(:header="false" :footer="false")
        template(#body)
            .dialog-contents.questionnaire-dialog(style="overflow-y:auto")
                table.table.table-stripped(style="position:fixed;top:0;height:auto;z-index:200;margin-bottom:0")
                    thead
                        tr
                            th.sticky.text-muted(style="width:50px;text-align:right") #
                            th.sticky(style="text-align:left")
                                span(v-show="!english") Pytanie
                                span(v-show="english") Question

                                button.btn.btn-info(style="margin-left:35px" type="button"
                                    :class="{'btn-warning active': help}" @click="help = !help")
                                    span(v-show="!english") {{'default.HELP'|translate}}
                                    span(v-show="english") Help

                                button.btn.btn-primary(style="margin-left:35px" type="button" @click="english = !english")
                                    span(v-show="!english") {{'optometrist.questionnaire.POLISH'|translate}}
                                    span(v-show="english") {{'optometrist.questionnaire.ENGLISH'|translate}}
                            th.sticky(style="width: 85px" v-for="option in options")
                                span {{label[english ? 'eng' : 'pl']['label'][option.id]}}
                                div(style="font-size:10px;white-space:nowrap") {{label[english ? 'eng' : 'pl']['description'][option.id]}}
                        tr(v-show="help")
                            th.sticky.help(colspan="100")
                                ul
                                    li {{'optometrist.tips.covd.info.1'|translate}}
                                    li {{'optometrist.tips.covd.info.2'|translate}}
                                    li {{'optometrist.tips.covd.info.3'|translate}}
                                    li {{'optometrist.tips.covd.info.4'|translate}}
                                    li {{'optometrist.tips.covd.info.5'|translate}}
                                    li {{'optometrist.tips.covd.info.6'|translate}}

                table.table.table-striped.questions(style="margin-top:47px")
                    tbody
                        tr(v-for="(question, key) in questions")
                            td.text-right.text-muted(style="width:50px") {{key + 1}}.
                            td.text-left.question {{question[english ? 'eng' : 'pl']}}
                            td.text-center(style="width:85px" v-for="option in options")
                                .radio.c-radio.c-radio-nofont.radio-3x
                                    label(:title="question.name + ' - ' + $filters.translate(option.name)")
                                        input(type="radio" v-model="question.pivot.option" :value="option.id"
                                            :disabled="disabled" @change="calculatePoints()")
                                        span
                    tfoot
                        tr
                            td(colspan="7" style="text-align:center;padding-top:20px")
                                OptiButton.pull-left(type="danger"
                                    :text="english ? 'optometrist.questionnaire.eng.confirmCancel' : 'optometrist.questionnaire.pl.confirmCancel'"
                                    @confirmed="$emit('cancelled')")
                                    span(v-show="!english") {{'optometrist.questionnaire.pl.CANCEL'|translate}}
                                    span(v-show="english") {{'optometrist.questionnaire.eng.CANCEL'|translate}}
                                    template(#icon)
                                        i.fa.fa-remove

                                OptiButton.pull-right(type="success" :disabled="points === -1 || disabled"
                                    @click.native="$emit('confirmed', {questions: questions, points: points})")
                                    span(v-show="!english") {{'optometrist.questionnaire.pl.SAVE'|translate}}
                                    span(v-show="english") {{'optometrist.questionnaire.eng.SAVE'|translate}}
                                    template(#icon)
                                        i.fa.fa-save
</template>

<script>
    import {EnumInterviewQuestionnaireOption} from '@/js/app/vue/enums/Enums'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiOptometristsDetailsSummaryDialogCOVD',
        components: {
            OptiDialog,
            OptiButton
        },
        props: {
            questionnaire: {
                type: Array,
                required: true
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                help: false,
                english: false,

                options: EnumInterviewQuestionnaireOption.simpleItems(),
                label: {
                    pl: {
                        label: ['Nigdy', 'Rzadko', 'Czasami', 'Często', 'Zawsze'],
                        description: ['', 'raz w tygodniu', '2 - 3 / tydz.', '4 -6 / tydz.', '']
                    },
                    eng: {
                        label: ['Never', 'Seldom', 'Occasionally', 'Frequently', 'Always'],
                        description: ['', 'once a week', '2 - 3 / week', '4 -6 / week', '']
                    }
                },
                questions: _.cloneDeep(this.questionnaire),
                points: -1
            }
        },
        mounted() {
            this.options.shift()

            this.calculatePoints()
        },
        methods: {
            calculatePoints() {
                let points  = 0,
                    checked = true

                this.questions.forEach(q => {
                    if(q.pivot.option !== -1) {
                        points += q.pivot.option
                    } else {
                        checked = false
                    }
                })

                this.points = checked ? points : -1
            }
        }
    }
</script>