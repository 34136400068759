<template lang="pug">
    .col-xs-12
        .panel.panel-primary(style="position:relative")
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'eShop.product.breadcrumb.PRODCOLOR_' + (type ? 'LENS' : 'FRAME')|translate}}

            .panel-body
                table
                    tr
                        th {{'eShop.attributes.table_colors.stats.FILLED'|translate}}
                        td {{stats.filled}}
                    tr
                        th(style="padding-right:10px") {{'eShop.attributes.table_colors.stats.NOT_FILLED'|translate}}
                        td(:class="{'warn': stats.not_filled > 0}") {{stats.not_filled}}

            .table-responsive
                table.table.table-striped.middle
                    thead
                        tr
                            th Id
                            th {{'settings.offer.translations.colors.table.NAME'|translate}}
                            th {{'settings.offer.translations.colors.table.CODE'|translate}}
                            th {{'settings.offer.translations.colors.table.TRANSLATION'|translate}}
                            th(v-if="type === 1") {{'settings.offer.translations.colors.table.GRADIENT_TYPE'|translate}}
                            th
                            th

                    tbody
                        tr(v-for="color in colors" :key="color.id")
                            td
                                a.link(@click="dialog = color") {{color.id}}
                            td
                                a.link(@click="dialog = color") {{color.name}}
                            td
                                a.link(@click="dialog = color") {{color.code}}
                            td(style="width:300px")
                                input.form-control.text-center(v-model.lazy="color.description")
                            td(style="width:300px" v-if="type === 1")
                                select.form-control(v-model="color.gradient_type" v-show="color.gradient_type !== 1")
                                    option(v-for="gradient_type in gradient_types" :value="gradient_type.id"
                                        ) {{gradient_type.name|translate}}
                            td.text-center(style="width:70px")
                                OptiAutoSave(endpoint="settings/offer/translations/colors/save" fields="description,gradient_type"
                                    :model="color" :key="color.id")
                            td.text-center(style="width:50px")
                                label.btn.btn-danger(:for="'delete_' + color.id")
                                    i.fa.fa-trash

                                OptiButton.hide(:id="'delete_' + color.id"
                                    text="settings.offer.translations.colors.delete" @confirmed="del(color.id)")
                                    template(#icon)
                                        i.fa.fa-trash

            OptiProductsListDialog(v-if="dialog"
                endpoint="settings/offer/translations/colors/get-products"
                :params="dialog"

                @close="dialog = null")
                template(#header)
                    i.fa.fa-paint-brush.mr3

                    span.mr30 {{'eShop.product.breadcrumb.PRODCOLOR_' + (dialog.type ? 'LENS' : 'FRAME')|translate}}
                    span.mr30 Id - {{dialog.id}}
                    span.mr30 {{'eShop.attributes.table_colors.NAME'|translate}} - {{dialog.name}}
                    span.mr30
                        span.mr3 {{'eShop.attributes.table_colors.CODE'|translate}} -
                        span(v-if="dialog.code") {{dialog.code}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}
                    span.mr30
                        span.mr3 {{'eShop.attributes.table_colors.DESCRIPTION'|translate}} -
                        span(v-if="dialog.description") {{dialog.description}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumProductColorTranslatedGradientType} from '@/js/app/vue/enums/Enums'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEshopColors',
        components: {
            OptiButton,
            OptiAutoSave,
            OptiProductsListDialog,
            OptiDimmer
        },
        props: {
            type: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                gradient_types: EnumProductColorTranslatedGradientType.simpleItems().filter(c => c.id > 1),

                colors: [],

                dialog: null,
                loading: true
            }
        },
        mounted() {
            API.get('settings/offer/translations/colors/' + this.type, API.id(Math.random())).then(res => {
                this.colors = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.offer.translations.colors.notify.error.LOAD')
            })
        },
        methods: {
            del(id) {
                this.loading = true

                API.delete('settings/offer/translations/colors/delete/' + id).then(res => {
                    this.colors = res.data

                    this.$notify.success('settings.offer.translations.colors.notify.DELETE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.offer.translations.colors.notify.error.DELETE')
                })
            }
        },
        computed: {
            stats() {
                let filled = this.colors.filter(c => (!c.type && c.description) || (c.type && c.description && c.gradient_type)).length

                return {
                    filled: filled,
                    not_filled: this.colors.length - filled
                }
            }
        }
    }
</script>