<template lang="pug">
    label.switch.switch-lg
        .inline(:class="{ active: !checked, 'btn btn-default': buttons && checked, 'btn btn-primary': buttons && !checked, 'disabled': this.disabled }")
            slot(name="left")
        input(
            type="checkbox"
            :checked="checked"
            @input="$emit('input', $event.target.checked)"
            :disabled="this.disabled")
        span.margins
        .inline(:class="{ active: checked, 'btn btn-default': buttons && !checked, 'btn btn-primary': buttons && checked, 'disabled': this.disabled }")
            slot(name="right")
</template>

<script>
    export default {
        name: 'OptiSwitchToggleButton',
        props: {
            checked: {
                type: Boolean,
                required: true
            },
            buttons: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>
    .inline {
        display: inline;
    }

    .active {
        font-weight: bold;
    }

    .margins {
        margin-left: 4px;
        margin-right: 4px;
    }

    label {
        font-weight: normal;
    }
</style>
