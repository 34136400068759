<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'optometrist.contactlens.calculator.HEADING'|translate}}

        .row
            .col-sm-6
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(style="height:51px" colspan="9") {{'optometrist.correction.CORRECTION'|translate}}
                    tbody
                        tr
                            th {{'optometrist.acuity.FAR'|translate}}
                            td SPH
                            td(v-show="correctionCylExists") CYL
                            td(v-show="correctionAxisExists") AXS
                            td(v-show="correctionPrismExists") Prism
                            td(v-show="correctionBaseExists") Angle
                            td(v-show="correctionAddExists") Add
                            td Visus
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{interview.dist_r_sphere|plus}}
                            td(v-show="correctionCylExists") {{interview.dist_r_cylinder|plus}}
                            td(v-show="correctionAxisExists")
                                span(v-show="interview.dist_r_cylinder") {{interview.dist_r_axis}}
                            td(v-show="correctionPrismExists") {{interview.dist_r_prd|number(2)}}
                            td(v-show="correctionBaseExists")
                                span(v-show="interview.dist_r_prd") {{interview.dist_r_base}}
                            td(v-show="correctionAddExists") {{interview.dist_r_add|number(2)}}
                            td {{interview.dist_r_visus}}
                            td(rowspan="2") {{interview.dist_binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{interview.dist_l_sphere|plus}}
                            td(v-show="correctionCylExists") {{interview.dist_l_cylinder|plus}}
                            td(v-show="correctionAxisExists")
                                span(v-show="interview.dist_l_cylinder") {{interview.dist_l_axis}}
                            td(v-show="correctionPrismExists") {{interview.dist_l_prd|number(2)}}
                            td(v-show="correctionBaseExists")
                                span(v-show="interview.dist_l_prd") {{interview.dist_l_base}}
                            td(v-show="correctionAddExists") {{interview.dist_l_add|number(2)}}
                            td {{interview.dist_l_visus}}
                        tr
                            th {{'optometrist.acuity.NEAR'|translate}}
                            td SPH
                            td(v-show="correctionCylExists") CYL
                            td(v-show="correctionAxisExists") AXS
                            td(v-show="correctionPrismExists") Prism
                            td(v-show="correctionBaseExists") Angle
                            td(v-show="correctionAddExists")
                            td Visus
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{interview.near_r_sphere|plus}}
                            td(v-show="correctionCylExists") {{interview.near_r_cylinder|plus}}
                            td(v-show="correctionAxisExists")
                                span(v-show="interview.near_r_cylinder") {{interview.near_r_axis}}
                            td(v-show="correctionPrismExists") {{interview.near_r_prd|number(2)}}
                            td(v-show="correctionBaseExists")
                                span(v-show="interview.near_r_prd") {{interview.near_r_base}}
                            td(v-show="correctionAddExists")
                            td {{interview.near_r_visus}}
                            td(rowspan="2") {{interview.near_binvisus}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{interview.near_l_sphere|plus}}
                            td(v-show="correctionCylExists") {{interview.near_l_cylinder|plus}}
                            td(v-show="correctionAxisExists")
                                span(v-show="interview.near_l_cylinder") {{interview.near_l_axis}}
                            td(v-show="correctionPrismExists") {{interview.near_l_prd|number(2)}}
                            td(v-show="correctionBaseExists")
                                span(v-show="interview.near_l_prd") {{interview.near_l_base}}
                            td(v-show="correctionAddExists")
                            td {{interview.near_l_visus}}
                    tbody(v-show="!correctionAllExists")
                        tr
                            td.text-center.warn(colspan="9") {{'optometrist.notify.NOCORRECTION'|translate}}

            .col-sm-6
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(style="text-align:right" colspan="2") {{'optometrist.contactlens.calculator.MATCHES'|translate}}
                            th.text-center(colspan="2")
                                OptiButton(type="success" v-show="!interview.contactLens.toric"
                                    :disabled="interview.contactLens.r_product_order || interview.contactLens.l_product_order || !interview.contactLens.order_contact_lens || !correctionCylExists || interview.contactLens.r_provided_contact_lens || interview.contactLens.l_provided_contact_lens"
                                    @click.native="changeContactLensesType()") {{'optometrist.contactlens.calculator.type.SPHERICAL'|translate}}
                                    template(#icon)
                                        i.fa.fa-circle

                                OptiButton(type="success" v-show="interview.contactLens.toric"
                                    :disabled="interview.contactLens.r_product_order || interview.contactLens.l_product_order || !interview.contactLens.order_contact_lens || interview.contactLens.r_provided_contact_lens || interview.contactLens.l_provided_contact_lens"
                                    @click.native="changeContactLensesType()") {{'optometrist.contactlens.calculator.type.TORIC'|translate}}
                                    template(#icon)
                                        i.fa.fa-circle
                    tbody
                        tr
                            th {{'optometrist.acuity.FAR'|translate}}
                            td SPH
                            td(v-show="interview.contactLens.toric && correctionCylExists") CYL
                            td(v-show="interview.contactLens.toric && correctionAxisExists") AXS
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction.matches.dist.r.sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists") {{correction.matches.dist.r.cyl|plus}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                span(v-show="correction.matches.dist.r.cyl") {{interview.dist_r_axis}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{correction.matches.dist.l.sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists") {{correction.matches.dist.l.cyl|plus}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                span(v-show="correction.matches.dist.l.cyl") {{interview.dist_l_axis}}
                        tr
                            th {{'optometrist.acuity.NEAR'|translate}}
                            td SPH
                            td(v-show="interview.contactLens.toric && correctionCylExists") CYL
                            td(v-show="interview.contactLens.toric && correctionAxisExists") AXS
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td {{correction.matches.near.r.sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists") {{correction.matches.near.r.cyl|plus}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                span(v-show="correction.matches.near.r.cyl") {{interview.near_r_axis}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td {{correction.matches.near.l.sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists") {{correction.matches.near.l.cyl|plus}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                span(v-show="correction.matches.near.l.cyl") {{interview.near_l_axis}}

            .clearfix
            .col-sm-6
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th.text-center(style="height:51px" colspan="4") Visus
                    tbody
                        tr
                            th {{'optometrist.acuity.FAR'|translate}}
                            td Visus
                            td C/Z
                            td BinVisus
                        tr
                            td
                                button.btn.btn-info(type="button" :disabled="disabledVisus"
                                    @click="dialog.dist.r = true") {{'prescription.RIGHT'|translate}}

                                    MountingPortal(v-if="dialog.dist.r" mountTo="#vue-modal" append)
                                        OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                            :interview="interview"
                                            :data="acuity_contact_lens"
                                            side="right"
                                            @confirmed="updateInterview"
                                            @cancelled="dialog.dist.r = false")
                            td
                                span.form-control(disabled) {{interview.contactLens.powers.dist_r_visus || '-'}}
                            td
                                OptiEnum(enum="InterviewRedGreen" :id="interview.contactLens.powers.dist_r_cz" :disabled="true")
                            td(rowspan="2")
                                span.form-control(disabled) {{interview.contactLens.powers.dist_binvisus || '-'}}
                        tr
                            td
                                button.btn.btn-info(type="button" :disabled="disabledVisus"
                                    @click="dialog.dist.l = true") {{'prescription.LEFT'|translate}}

                                    MountingPortal(v-if="dialog.dist.l" mountTo="#vue-modal" append)
                                        OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                            :interview="interview"
                                            :data="acuity_contact_lens"
                                            side="left"
                                            @confirmed="updateInterview"
                                            @cancelled="dialog.dist.l = false")
                            td
                                span.form-control(disabled) {{interview.contactLens.powers.dist_l_visus || '-'}}
                            td
                                OptiEnum(enum="InterviewRedGreen" :id="interview.contactLens.powers.dist_l_cz" :disabled="true")
                        tr
                            th
                                button.btn.btn-info(type="button" :disabled="disabledVisus"
                                    @click="dialog.near = true") {{'optometrist.acuity.NEAR'|translate}}

                                    MountingPortal(v-if="dialog.near" mountTo="#vue-modal" append)
                                        OptiOptometristsDetailsRefractionNearDialogSurvey(
                                            :interview="interview"

                                            r-visus="near_r_visus"
                                            l-visus="near_l_visus"
                                            bin-visus="near_binvisus"

                                            mode="contactLens"

                                            @confirmed="updateInterview"
                                            @cancelled="dialog.near = false")
                            td Visus
                            td
                            td BinVisus
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td
                                span.form-control(disabled) {{interview.contactLens.powers.near_r_visus || '-'}}
                            td
                            td(rowspan="2")
                                span.form-control(disabled) {{interview.contactLens.powers.near_binvisus || '-'}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td
                                span.form-control(disabled) {{interview.contactLens.powers.near_l_visus || '-'}}

            .col-sm-6
                table.table.table-striped.text-center.middle
                    thead
                        tr
                            th(style="text-align:right" colspan="2") {{'optometrist.contactlens.calculator.TYPE'|translate}}
                            th.text-center(colspan="2")
                                OptiButton(type="success" :disabled="(interview.contactLens.r_product_order && interview.contactLens.l_product_order) || !interview.contactLens.order_contact_lens || interview.contactLens.r_provided_contact_lens || interview.contactLens.l_provided_contact_lens"
                                    @click.native="rewritePowers()") {{'optometrist.contactlens.calculator.REWRITE'|translate}}
                                    template(#icon)
                                        i.fa.fa-angle-double-down
                    tbody
                        tr
                            th {{'optometrist.acuity.FAR'|translate}}
                            td SPH
                            td(v-show="interview.contactLens.toric && correctionCylExists") CYL
                            td(v-show="interview.contactLens.toric && correctionAxisExists") AXS
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.FAR') + ' SPH (' + $filters.translate('prescription.RIGHT') + ')'"
                                    :value="interview.contactLens.powers.dist_r_sph" @confirmed="v => updatePowers('dist_r_sph', v)"
                                    :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.dist_r_sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists")
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.FAR') + ' CYL (' + $filters.translate('prescription.RIGHT') + ')'"
                                    v-show="interview.contactLens.r_product_id !== 25360 && interview.contactLens.r_product_id !== 32439"
                                    :value="interview.contactLens.powers.dist_r_cyl" @confirmed="v => updatePowers('dist_r_cyl', v)"
                                    :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.dist_r_cyl|plus}}
                                select.form-control(v-show="interview.contactLens.r_product_id === 25360 || interview.contactLens.r_product_id === 32439"
                                    v-model="interview.contactLens.powers.dist_r_cyl" @change="updatePowers('dist_r_cyl', interview.contactLens.powers.dist_r_cyl)"
                                    :disabled="disabledFieldRewrite('r')")
                                    option(v-for="s in scope_toric_cyl" :value="s.power") {{s.name}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                OptiNumber(:header="$filters.translate('optometrist.acuity.FAR') + ' AXS (' + $filters.translate('prescription.RIGHT') + ')'"
                                    :class="{'its-error': interview.contactLens.powers.dist_r_axs === 'brak'}"
                                    v-show="interview.contactLens.powers.dist_r_cyl && interview.contactLens.r_product_id !== 25360 && interview.contactLens.r_product_id !== 32439"
                                    :value="interview.contactLens.powers.dist_r_axs" @confirmed="v => updatePowers('dist_r_axs', v)"
                                    :max="180" :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.dist_r_axs}}
                                select.form-control(v-show="interview.contactLens.powers.dist_r_cyl && (interview.contactLens.r_product_id === 25360 || interview.contactLens.r_product_id === 32439)"
                                    :class="{'its-error': interview.contactLens.powers.dist_r_axs === 'brak'}"
                                    v-model="interview.contactLens.powers.dist_r_axs" @change="updatePowers('dist_r_axs', interview.contactLens.powers.dist_r_axs)"
                                    :disabled="disabledFieldRewrite('r')")
                                    option(v-for="s in scope_axs(interview.contactLens.r_product_id, interview.contactLens.powers.dist_r_cyl)" :value="s") {{s}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.FAR') + ' SPH (' + $filters.translate('prescription.LEFT') + ')'"
                                    :value="interview.contactLens.powers.dist_l_sph" @confirmed="v => updatePowers('dist_l_sph', v)"
                                    :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.dist_l_sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists")
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.FAR') + ' CYL (' + $filters.translate('prescription.LEFT') + ')'"
                                    v-show="interview.contactLens.l_product_id !== 25360 && interview.contactLens.l_product_id !== 32439"
                                    :value="interview.contactLens.powers.dist_l_cyl" @confirmed="v => updatePowers('dist_l_cyl', v)"
                                    :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.dist_l_cyl|plus}}
                                select.form-control(v-show="interview.contactLens.l_product_id === 25360 || interview.contactLens.l_product_id === 32439"
                                    v-model="interview.contactLens.powers.dist_l_cyl" @change="updatePowers('dist_l_cyl', interview.contactLens.powers.dist_l_cyl)"
                                    :disabled="disabledFieldRewrite('l')")
                                    option(v-for="s in scope_toric_cyl" :value="s.power") {{s.name}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                OptiNumber(:header="$filters.translate('optometrist.acuity.FAR') + ' AXS (' + $filters.translate('prescription.LEFT') + ')'"
                                    :class="{'its-error': interview.contactLens.powers.dist_l_axs === 'brak'}"
                                    v-show="interview.contactLens.powers.dist_l_cyl && interview.contactLens.l_product_id !== 25360 && interview.contactLens.l_product_id !== 32439"
                                    :value="interview.contactLens.powers.dist_l_axs" @confirmed="v => updatePowers('dist_l_axs', v)"
                                    :max="180" :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.dist_l_axs}}
                                select.form-control(v-show="interview.contactLens.powers.dist_l_cyl && (interview.contactLens.l_product_id === 25360 || interview.contactLens.l_product_id === 32439)"
                                    :class="{'its-error': interview.contactLens.powers.dist_l_axs === 'brak'}"
                                    v-model="interview.contactLens.powers.dist_l_axs" @change="updatePowers('dist_l_axs', interview.contactLens.powers.dist_l_axs)"
                                    :disabled="disabledFieldRewrite('l')")
                                    option(v-for="s in scope_axs(interview.contactLens.l_product_id, interview.contactLens.powers.dist_l_cyl)" :value="s") {{s}}
                        tr
                            th(style="height:51px") {{'optometrist.acuity.NEAR'|translate}}
                            td SPH
                            td(v-show="interview.contactLens.toric && correctionCylExists") CYL
                            td(v-show="interview.contactLens.toric && correctionAxisExists") AXS
                        tr
                            th {{'prescription.RIGHT'|translate}}
                            td
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.NEAR') + ' SPH (' + $filters.translate('prescription.RIGHT') + ')'"
                                    :value="interview.contactLens.powers.near_r_sph" @confirmed="v => updatePowers('near_r_sph', v)"
                                    :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.near_r_sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists")
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.NEAR') + ' CYL (' + $filters.translate('prescription.RIGHT') + ')'"
                                    v-show="interview.contactLens.r_product_id !== 25360 && interview.contactLens.r_product_id !== 32439"
                                    :value="interview.contactLens.powers.near_r_cyl" @confirmed="v => updatePowers('near_r_cyl', v)"
                                    :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.near_r_cyl|plus}}
                                select.form-control(v-show="interview.contactLens.r_product_id === 25360 || interview.contactLens.r_product_id === 32439"
                                    v-model="interview.contactLens.powers.near_r_cyl" @change="updatePowers('near_r_cyl', interview.contactLens.powers.near_r_cyl)"
                                    :disabled="disabledFieldRewrite('r')")
                                    option(v-for="s in scope_toric_cyl" :value="s.power") {{s.name}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                OptiNumber(:header="$filters.translate('optometrist.acuity.NEAR') + ' AXS (' + $filters.translate('prescription.RIGHT') + ')'"
                                    :class="{'its-error': interview.contactLens.powers.near_r_axs === 'brak'}"
                                    v-show="interview.contactLens.powers.near_r_cyl && interview.contactLens.r_product_id !== 25360 && interview.contactLens.r_product_id !== 32439"
                                    :value="interview.contactLens.powers.near_r_axs" @confirmed="v => updatePowers('near_r_axs', v)"
                                    :max="180" :disabled="disabledFieldRewrite('r')") {{interview.contactLens.powers.near_r_axs}}
                                select.form-control(v-show="interview.contactLens.powers.near_r_cyl && (interview.contactLens.r_product_id === 25360 || interview.contactLens.r_product_id === 32439)"
                                    :class="{'its-error': interview.contactLens.powers.near_r_axs === 'brak'}"
                                    v-model="interview.contactLens.powers.near_r_axs" @change="updatePowers('near_r_axs', interview.contactLens.powers.near_r_axs)"
                                    :disabled="disabledFieldRewrite('r')")
                                    option(v-for="s in scope_axs(interview.contactLens.r_product_id, interview.contactLens.powers.near_r_cyl)" :value="s") {{s}}
                        tr
                            th {{'prescription.LEFT'|translate}}
                            td
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.NEAR') + ' SPH (' + $filters.translate('prescription.LEFT') + ')'"
                                    :value="interview.contactLens.powers.near_l_sph" @confirmed="v => updatePowers('near_l_sph', v)"
                                    :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.near_l_sph|plus}}
                            td(v-show="interview.contactLens.toric && correctionCylExists")
                                OptiNumberPrescription(:header="$filters.translate('optometrist.acuity.NEAR') + ' CYL (' + $filters.translate('prescription.LEFT') + ')'"
                                    v-show="interview.contactLens.l_product_id !== 25360 && interview.contactLens.l_product_id !== 32439"
                                    :value="interview.contactLens.powers.near_l_cyl" @confirmed="v => updatePowers('near_l_cyl', v)"
                                    :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.near_l_cyl|plus}}
                                select.form-control(v-show="interview.contactLens.l_product_id === 25360 || interview.contactLens.l_product_id === 32439"
                                    v-model="interview.contactLens.powers.near_l_cyl" @change="updatePowers('near_l_cyl', interview.contactLens.powers.near_l_cyl)"
                                    :disabled="disabledFieldRewrite('l')")
                                    option(v-for="s in scope_toric_cyl" :value="s.power") {{s.name}}
                            td(v-show="interview.contactLens.toric && correctionAxisExists")
                                OptiNumber(:header="$filters.translate('optometrist.acuity.NEAR') + ' AXS (' + $filters.translate('prescription.LEFT') + ')'"
                                    :class="{'its-error': interview.contactLens.powers.near_l_axs === 'brak'}"
                                    v-show="interview.contactLens.powers.near_l_cyl && interview.contactLens.l_product_id !== 25360 && interview.contactLens.l_product_id !== 32439"
                                    :value="interview.contactLens.powers.near_l_axs" @confirmed="v => updatePowers('near_l_axs', v)"
                                    :max="180" :disabled="disabledFieldRewrite('l')") {{interview.contactLens.powers.near_l_axs}}
                                select.form-control(v-show="interview.contactLens.powers.near_l_cyl && (interview.contactLens.l_product_id === 25360 || interview.contactLens.l_product_id === 32439)"
                                    :class="{'its-error': interview.contactLens.powers.near_l_axs === 'brak'}"
                                    v-model="interview.contactLens.powers.near_l_axs" @change="updatePowers('near_l_axs', interview.contactLens.powers.near_l_axs)"
                                    :disabled="disabledFieldRewrite('l')")
                                    option(v-for="s in scope_axs(interview.contactLens.l_product_id, interview.contactLens.powers.near_l_cyl)" :value="s") {{s}}
</template>

<script>
    import {ContactLensCalculate} from '@/js/app/vue/components/Optometrists/Helpers/ContactLensCalculate'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'

    import OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection'
    import OptiOptometristsDetailsRefractionNearDialogSurvey
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/Dialogs/OptiOptometristsDetailsRefractionNearDialogSurvey'

    export default {
        name: 'OptiOptometristsDetailsLensCalculator',
        components: {
            OptiButton,
            OptiEnum,

            OptiNumber,
            OptiNumberPrescription,

            OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection,
            OptiOptometristsDetailsRefractionNearDialogSurvey
        },
        props: {
            interview: {
                type: Object,
                required: true
            },

            correction: {
                type: Object,
                required: true
            },

            contactLens: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    dist: {
                        r: false,
                        l: false
                    },
                    near: false
                },

                acuity_contact_lens: {
                    type: 'acuity_contact_lens',
                    info: {
                        r: {
                            sph: this.interview.contactLens.powers.dist_r_sph,
                            cyl: this.interview.contactLens.powers.dist_r_cyl,
                            axs: this.interview.contactLens.powers.dist_r_axs,
                            visus: '-',
                        },
                        l: {
                            sph: this.interview.contactLens.powers.dist_l_sph,
                            cyl: this.interview.contactLens.powers.dist_l_cyl,
                            axs: this.interview.contactLens.powers.dist_l_axs,
                            visus: '-'
                        },
                        binVisus: '-'
                    },
                    save: {
                        r: {
                            cz: 'contactLens.powers.dist_r_cz',
                            visus: 'contactLens.powers.dist_r_visus'
                        },
                        l: {
                            cz: 'contactLens.powers.dist_l_cz',
                            visus: 'contactLens.powers.dist_l_visus'
                        },
                        binVisus: 'contactLens.powers.dist_binvisus'
                    }
                },

                scope_toric_cyl: [{
                    power: 0,
                    name: 'brak'
                }, {
                    power: -0.75,
                    name: '-0,75'
                }, {
                    power: -1.25,
                    name: '-1,25'
                }, {
                    power: -1.75,
                    name: '-1,75'
                }, {
                    power: -2.25,
                    name: '-2,25'
                }]
            }
        },
        mounted() {
            ContactLensCalculate.init(this.interview, this.correction, this.contactLens)

            this.calculate()
        },
        methods: {
            scope_axs(product_id, cyl) { // to samo w lensOptometristController.js
                if([25360, 32439].includes(product_id) && [-0.75, -1.25, -1.75, -2.25].includes(cyl)) {
                    return ['brak'].concat(_.range(0, 180 + 1, 10))
                }

                return []
            },
            calculate() {
                ContactLensCalculate.set().then(({interview, correction, contactLens}) => {
                    this.updateInterview(interview)
                    this.$emit('update-correction', correction)
                    this.$emit('update-contact-lens', contactLens)
                })
            },
            changeContactLensesType() {
                this.interview.contactLens.toric = !this.interview.contactLens.toric

                this.calculate()
            },
            rewritePowers() {
                if(!this.interview.contactLens.r_product_order) {
                    this.interview.contactLens.powers.dist_r_sph = this.correction.matches.dist.r.sph
                    this.interview.contactLens.powers.dist_r_cyl = this.correction.matches.dist.r.cyl

                    this.interview.contactLens.powers.near_r_sph = this.correction.matches.near.r.sph
                    this.interview.contactLens.powers.near_r_cyl = this.correction.matches.near.r.cyl

                    this.$emit('change-product', 'r')
                }

                if(!this.interview.contactLens.l_product_order) {
                    this.interview.contactLens.powers.dist_l_sph = this.correction.matches.dist.l.sph
                    this.interview.contactLens.powers.dist_l_cyl = this.correction.matches.dist.l.cyl

                    this.interview.contactLens.powers.near_l_sph = this.correction.matches.near.l.sph
                    this.interview.contactLens.powers.near_l_cyl = this.correction.matches.near.l.cyl

                    this.$emit('change-product', 'l')
                }

                this.updateInterview(this.interview)
            },
            updateInterview(interview) {
                this.dialog.dist.r = false
                this.dialog.dist.l = false
                this.dialog.near = false

                this.$emit('update-interview', interview)
            },
            updatePowers(field, value) {
                this.interview.contactLens.powers[field] = value

                this.calculate()
            },
            disabledFieldRewrite(side) {
                return this.interview.contactLens[side + '_product_order'] || !this.interview.contactLens.order_contact_lens ||
                    this.interview.contactLens.r_provided_contact_lens || this.interview.contactLens.l_provided_contact_lens ||
                    !this.interview.contactLens.order_contact_lens
            }
        },
        computed: {
            correctionCylExists() {
                return this.interview.dist_r_cylinder || this.interview.dist_l_cylinder ||
                    this.interview.near_r_cylinder || this.interview.near_l_cylinder
            },
            correctionAxisExists() {
                return this.interview.dist_r_axis !== 'brak' || this.interview.dist_l_axis !== 'brak' ||
                    this.interview.near_r_axis !== 'brak' || this.interview.near_l_axis !== 'brak'
            },
            correctionPrismExists() {
                return this.interview.dist_r_prd || this.interview.dist_l_prd ||
                    this.interview.near_r_prd || this.interview.near_l_prd
            },
            correctionBaseExists() {
                return parseInt(this.interview.dist_r_base) || parseInt(this.interview.dist_l_base) ||
                    parseInt(this.interview.near_r_base) || parseInt(this.interview.near_l_base)
            },
            correctionAddExists() {
                return this.interview.dist_r_add || this.interview.dist_l_add
            },
            correctionAllExists() {
                let sphExists = this.interview.dist_r_sphere || this.interview.dist_l_sphere ||
                    this.interview.near_r_sphere || this.interview.near_l_sphere

                return sphExists || this.correctionCylExists || this.correctionAxisExists ||
                    this.correctionPrismExists || this.correctionBaseExists || this.correctionAddExists
            },

            disabledVisus() {
                return (!(this.interview.contactLens.r_provided_contact_lens || this.interview.contactLens.l_provided_contact_lens) &&
                    !this.interview.contactLens.control_visit) || this.interview.contactLens.matching > 0 || !this.interview.contactLens.order_contact_lens
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>