<template lang="pug">
    .input-group(style="width:145px")
        .input-group-btn
            button.btn.btn-primary(:disabled="disabled || (!allowMinus && !value)"  @click="$emit('minus')")
                i.fa.fa-minus

        .input-group
            input.form-control.text-center(:value="value" disabled)

        .input-group-btn
            button.btn.btn-primary(:disabled="disabled" @click="$emit('plus')")
                i.fa.fa-plus
</template>

<script>
    export default {
        name: 'OptiInputNumber',
        props: {
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },
	        allowMinus: {
				type: Boolean,
		        default: true,
		        required: false
	        },
            value: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    button, input {
        height: 33px !important;
    }
</style>