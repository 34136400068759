<template lang="pug">
    span
        span.fa.fa-sort-amount-asc.text-muted(v-if="!right")
        span &nbsp;
            slot
        span(v-if="right") &nbsp;
        span.fa.fa-sort-amount-asc.text-muted(v-if="right")
</template>

<script>
    export default {
        name: 'TextCumulative',
        props: {
            right: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>
