<template lang="pug">
    .btn-group(v-if="show")
        label.btn(v-for="d in data" :for="'b' + d.id + '_' + rand" :class="color(d.id)") {{d.translate()}}
            input(type="radio" style="display:none" :id="'b' + d.id + '_' + rand" :name="'btn_toggle_' + rand"
                @input="change($event.target.value)" :value="d.id" :checked="dataModel == d.id")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiButtonToggle',
        props: {
            enum: {
                type: String,
                required: true
            },
            endpoint: {
                type: String,
                required: false
            },
            model: {
                type: undefined,
                required: true,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                dataModel: null,
                show: false,
                rand: Math.random(),
                allowColors: ['primary', 'info', 'warning', 'danger', 'success', 'green', 'inverse'],
                colorNull: 'btn-default',
                data: []
            }
        },
        methods: {
            async loadEnum() {
                let enums = await import('../../enums/Enums')
                this.data = enums[`Enum${this.enum}`]
            },
            color(id) {
                let tab = []

                if (!this.dataModel || this.dataModel != id) {
                    tab.push(this.colorNull)
                } else if(this.dataModel == id) {
                    tab.push('btn-purple', 'active')
                }

                if(this.disabled) {
                    tab.push('disabled')
                }

                return tab
            },
            change(value) {
                this.dataModel = value
                this.$emit('change', value)

                if(this.endpoint) {
                    API.post(this.endpoint, {
                        value: parseInt(value)
                    }).then(res => {
                        this.dataModel = parseInt(res.data)
                    });
                }
            }
        },
        watch: {
            model(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.dataModel = newValue
                }
            }
        },
        mounted() {
            this.dataModel = this.model

            var allowColors = this.allowColors,
                colorNull = this.colorNull

            if(!this.endpoint) {
                this.show = true
            }

            if(this.endpoint && !this.disabled) {
                API.get(this.endpoint).then(res => {
                    this.dataModel = parseInt(res.data);

                    if(this.endpoint.indexOf('sms/rating') > -1 && this.dataModel === -2) {
                        this.show = false
                    } else {
                        this.show = true
                    }
                });
            }

            Object.keys(this.$attrs).forEach(function(attr) {
                if(allowColors.indexOf(attr) > -1) {
                    colorNull = 'btn-' + attr;
                }
            });

            this.colorNull = colorNull

            this.loadEnum()
        }
    }
</script>
