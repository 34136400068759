<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportExcelHistoryDownloading',
        components: {
            OptiDimmer
        },
        mounted() {
            API.get('excel-history/details/' + this.$state.params.id).then(res => {
                window.open('/reports/' + res.data)
            }).finally(() => {
                this.$state.go('app.excel.history-list')
            }).catch(() => {
                this.$notify.error('download.notify.error.LOAD')
            })
        }
    }
</script>