<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form#form-attribute-product.form-horizontal(name="formAttributeProduct" @submit.prevent="edit")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'attributes.product.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="attributeProduct.name" required)
                        OptiValidate(f="name" :data="$v.attributeProduct.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'attributes.product.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="ProductType" :value="attributeProduct.type"
                            @update-enum="t => attributeProduct.type = t")
                        OptiValidate(f="type" :data="$v.attributeProduct.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="valueType") {{'attributes.product.VALUE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#valueType(enum="ProductFormat" :value="attributeProduct.valueType"
                            @update-enum="vT => attributeProduct.valueType = vT")
                        OptiValidate(f="valueType" :data="$v.attributeProduct.valueType")
                .form-group
                    label.col-sm-4.control-label(for="options") {{'attributes.product.ALLOW'|translate}}
                    .col-sm-8
                        textarea#options.form-control(name="options" rows="7" v-model="attributeProduct.options")
                .form-group
                    label.col-sm-4.control-label(for="default") {{'attributes.product.DEFAULT'|translate}}
                    .col-sm-8
                        input#default.form-control(name="default" v-model="attributeProduct.default")
                .form-group
                    label.col-sm-4.control-label.star-required(for="key") {{'attributes.product.KEY'|translate}}
                    .col-sm-8
                        input#key.form-control(name="key" v-model="attributeProduct.key" required)
                        OptiValidate(f="key" :data="$v.attributeProduct.key")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="required" v-model="attributeProduct.required")
                                    span.fa.fa-check
                            span {{'attributes.product.REQUIRED'|translate}}
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.attributeProduct.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryProductAttributesEdit',
        components: {
            OptiSelectEnum,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                attributeProduct: {
                    name: '',
                    type: null,
                    valueType: null,
                    options: '',
                    default: '',
                    key: '',
                    required: false
                },

                loading: true
            }
        },
        validations() {
            return {
                attributeProduct: {
                    name: {
                        required,
                        unique: unique('products_attributes', 'ATTRIBUTE_NAME', this.attributeProduct.id, 'PK_UID')
                    },
                    type: {
                        required
                    },
                    valueType: {
                        required
                    },
                    key: {
                        required,
                        unique: unique('products_attributes', 'ATTRIBUTE_KEY', this.attributeProduct.id, 'PK_UID')
                    }
                }
            }
        },
        mounted() {
            API.get('warehouse/products/attribute/' + this.$state.params.id).then(res => {
                this.attributeProduct = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('attributes.product.error.LOAD')
            })
        },
        methods: {
            edit() {
                this.loading = true

                API.put('warehouse/products/attribute', this.attributeProduct).then(() => {
                    this.$state.go('app.warehouse.products.attributes.list')

                    this.$notify.success('attributes.product.action.EDITED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('attributes.product.error.EDITED')
                })
            }
        }
    }
</script>