<template lang="pug">
    .row(v-if="$asyncComputed.ticket.success")
        OptiDimmer(:active="active")

        .col-xs-12.text-right
            OptiButton(type="info" @click.native="exportTickets") {{'prices.prices.PRINT_TICKETS'|translate}}
                template(#icon)
                    i.fa.fa-print

        .col-xs-12(style="margin-top:10px")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'ticket.breadcrumb.TICKETS'|translate}} \#{{ticket.id}}
                    small ({{ticket.status_name|translate}})
                    .pull-right \#{{ticket.date}}
                .table-responsive
                    table.table.table-condensed.table-striped
                        thead
                            tr
                                th #
                                th {{'ticket.details.head.PRODUCTID'|translate}}
                                th {{'ticket.details.head.PRODUCTNAME'|translate}}
                                th {{'ticket.details.head.BARCODE'|translate}}
                                th {{'ticket.details.head.PRICE'|translate}}
                                th
                        tbody(table-empty)
                            tr(v-for="(detail, index) in ticket.details")
                                td.text-right {{index + 1}}
                                td.text-right(style="vertical-align:middle")
                                    OptiProduct(:product="detail.product") {{detail.product_id}}
                                td
                                    OptiProduct(:product="detail.product") {{detail.product_name}}
                                    small(style="display:block") {{detail.product.fullName}}
                                td {{detail.barcode}}
                                td.text-right {{detail.price|currency}}
                                td.text-center(style="vertical-align:middle")
                                    a(:href="$state.href('app.catalog.product',{id: detail.product_id, departmentId: ticket.department_id})"
                                        :title="'returns.items.title.PRODUCT'|translate")
                                        i.fa.fa-2x.fa-fw.fa-info-circle

        .col-md-8.col-md-offset-4.col-xs-12
            .panel-grid
                .panel.panel-primary
                    .row
                        .col-sm-6 {{'ticket.table.STATUS'|translate}}
                        .col-sm-6 {{ticket.status_name|translate}}
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="16" :id="ticket.id" @shipment-exists="shipmentExists")
                    .row
                        .col-sm-6 {{'ticket.table.CREATEDBY'|translate}}
                        .col-sm-6 {{ticket.user.firstName}} {{ticket.user.lastName}} ({{ticket.department.name}})
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'

    export default {
        name: "OptiTicketsDetail",
        components: {
            OptiButton,
            OptiDimmer,
            OptiProduct,
            OptiShipmentNotice
        },
        data() {
            return {
                active: false,
                shipment_exists: false
            }
        },
        asyncComputed: {
            async ticket() {
                try {
                    this.active = true
                    let {data} = await API.get('warehouse/ticket/' + this.$state.params.id)
                    this.active = false
                    return data
                } catch(e) {
                    console.log(e)
                }
            }
        },
        methods: {
            async exportTickets() {
                this.active = true
                await API.post('warehouse/ticket/' + this.$state.params.id + '/exportTickets').then(res => {
                    window.open('reports/' + res.data + '.csv')
                })
                this.active = false
            },
            shipmentExists(shipment_exists) {
                this.shipment_exists = shipment_exists
            }
        }

    }
</script>