export default function validatePrescription(prescription, doctorType) {
    let result = [],
        prefix = 'tasks.action.accepted.notify.'

    if(prescription.reSphere < -20 || prescription.reSphere > 20) {
        result.push(prefix + 'SPHERE')
    }

    if(prescription.leSphere < -20 || prescription.leSphere > 20) {
        result.push(prefix + 'SPHERE')
    }

    if((prescription.reCylinder && prescription.reAxis === 'brak') || (prescription.leCylinder && prescription.leAxis === 'brak')) {
        result.push(prefix + 'cyl.AXIS')
    }

    if((!prescription.reCylinder && prescription.reAxis !== 'brak') || (!prescription.leCylinder && prescription.leAxis !== 'brak')) {
        result.push(prefix + 'cyl.EXISTAXIS')
    }

    if((prescription.reCylinder && prescription.reAxis < 0 || prescription.reAxis > 180) ||
        (prescription.leCylinder && prescription.leAxis < 0 || prescription.leAxis > 180)) {
        result.push(prefix + 'RANGEAXIS')
    }

    if(prescription.rePrism < 0 || prescription.rePrism > 15 || prescription.lePrism < 0 || prescription.lePrism > 15) {
        result.push(prefix + 'PRISM')
    }

    if(prescription.rePrismAngle < 0 || prescription.rePrismAngle > 360 || prescription.lePrismAngle < 0 || prescription.lePrismAngle > 360) {
        result.push(prefix + 'ANGLE')
    }

    if(prescription.source !== 1 && (prescription.rePdNear < 20 || prescription.rePdNear > 45 ||
        prescription.lePdNear < 20 || prescription.lePdNear > 45)) {
        result.push(prefix + 'PDNEAR')
    }

    if(prescription.source !== 1 && (prescription.rePdDist < 20 || prescription.rePdDist > 45 ||
        prescription.lePdDist < 20 || prescription.lePdDist > 45)) {
        result.push(prefix + 'PDDIST')
    }

    if(((doctorType === 0 && !prescription.doctorLastName) || (doctorType === 1 && !prescription.doctorId)) &&
        prescription.source !== 2) {
        result.push(prefix + 'DOCTOR')
    }

    if(prescription.source === 1 && !prescription.files.filter(f => f).length) {
        result.push(prefix + 'FOREIGNPHOTO')
    }

    if(prescription.source === 1 && !prescription.check.status) {
        result.push(prefix + 'FOREIGNSTATUS')
    }

    if([1, 2].includes(prescription.source) && prescription.check.status === 1 &&
        prescription.check.dist_r_visus === '-' && prescription.check.dist_l_visus === '-' &&
        prescription.check.dist_r_cz === '0' && prescription.check.dist_l_cz === '0' &&
        prescription.check.dist_binvisus === '-' && prescription.check.near_binvisus === '-' &&
        prescription.check.near_r_visus === '-' && prescription.check.near_l_visus === '-') {
        result.push(prefix + 'FOREIGNCHECK')
    }

    return result
}
