<template lang="pug">
    span.text-range-week {{rangeText}}
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'TextRangeWeek',
        props: {
            ranges: {
                type: Array,
                required: true
            }
        },
        computed: {
            rangeText() {
                if (!this.ranges || this.ranges.length !== 2) {
                    return ''
                }

                let firstDate = moment(this.ranges[0])
                let secondDate = moment(this.ranges[1])

                if (firstDate.isoWeekYear() === secondDate.isoWeekYear()) {
                    return `${firstDate.format('W')}-${secondDate.format('W')}\W ${secondDate.format('GG')}`
                } else {
                    return `${firstDate.format('W\\WGG')} - ${secondDate.format('W\\WGG')}`
                }
            }
        }
    }
</script>

<style scoped>

</style>
