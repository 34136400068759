<template lang="pug">
    .row
        div(style="position:relative")
            OptiDimmer(:active="$asyncComputed.chartData.updating")

            BubbleChart(
                :chart-data="chartData"
                :options="options"
                :styles="styles"
                ref="chart")

            OptiChartTooltip(
                :model="check"
                :chart-data="chartData"
                :chart="$refs.chart")

                template(#header="{header}")
                    div {{header.tooltip.body[0].lines[0].raw.meta.name}}

                template(#default="{body}")
                    .text-center.bold(style="margin-bottom:10px") {{title(body.tooltip.body[0].lines[0].raw.week)}}

                    table.table.table-striped.table-bordered.table-condensed
                        thead
                            tr
                                th &nbsp;
                                th.text-right {{period.pastYear}}
                                th.text-right {{period.chooseYear}}
                                th.text-right %
                        tbody
                            tr
                                th {{'report.entries.bubble.ENTRIES'|translate}}
                                td.text-right.no-brake {{body.tooltip.body[0].lines[0].raw.entries.past | currency({fractionCount: 0})}}
                                td.text-right.no-brake {{body.tooltip.body[0].lines[0].raw.entries.current | currency({fractionCount: 0})}}
                                td.text-right.no-brake.percent(:class="body.tooltip.body[0].lines[0].raw.entries.percent > 0 ? 'percent-positive' : 'percent-negative'"
                                    ) {{body.tooltip.body[0].lines[0].raw.entries.percent}}%
                            tr
                                th {{'report.entries.bubble.TURNOVER'|translate}}
                                td.text-right.no-brake {{body.tooltip.body[0].lines[0].raw.money.past | currency({fractionCount: 0})}}
                                td.text-right.no-brake {{body.tooltip.body[0].lines[0].raw.money.current | currency({fractionCount: 0})}}
                                td.text-right.no-brake.percent(:class="body.tooltip.body[0].lines[0].raw.money.percent > 0 ? 'percent-positive' : 'percent-negative'"
                                    ) {{body.tooltip.body[0].lines[0].raw.money.percent}}%
                            tr
                                th {{'report.entries.bubble.DATES'|translate}}
                                td.no-brake {{date(period.pastYear, body.tooltip.body[0].lines[0].raw.week.past.from, body.tooltip.body[0].lines[0].raw.week.past.to)}}
                                td.no-brake {{date(period.chooseYear, body.tooltip.body[0].lines[0].raw.week.current.from, body.tooltip.body[0].lines[0].raw.week.current.to)}}
                                td
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '../Blocks/OptiDimmer'
    import BubbleChart from './Base/BubbleChart'
    import OptiChartTooltip from './Base/OptiChartTooltip'

    export default {
        name: 'OptiDepartmentsReport',
        components: {
            OptiDimmer,
            BubbleChart,
            OptiChartTooltip
        },
        props: {
            period: Object
        },
        data() {
            let leftAxis = null

            return {
                check: null,
                chartData: null,
                axisMin: 0,
                axisMax: 0,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: label => label + '%'
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Obrót %'
                            },
                            gridLines: {
                                zeroLineWidth: 3,
                                zeroLineColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            afterDataLimits(axis) {
                                leftAxis = axis
                            }
                        }, {
                            position: 'right',
                            ticks: {
                                callback: label => label + '%'
                            },
                            afterDataLimits(axis) {
                                axis.min = leftAxis.min
                                axis.max = leftAxis.max
                            },
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                callback: label =>  label + '%'
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Wejścia %'
                            },
                            gridLines: {
                                zeroLineWidth: 3,
                                zeroLineColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label(tooltipItems, data) {
                                let obj = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]

                                return obj
                            },
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    },
                    annotation: {
                        annotations: [{
                            drawTime: 'beforeDatasetsDraw',
                            type: 'box',
                            id: 'quarter1',
                            xScaleID: 'x-axis-0',
                            yScaleID: 'y-axis-0',

                            xMax: 0,
                            yMin: 0,
                            backgroundColor: 'rgba(0, 127, 0, 0.25)'
                        }, {
                            drawTime: 'beforeDatasetsDraw',
                            type: 'box',
                            id: 'quarter3',
                            xScaleID: 'x-axis-0',
                            yScaleID: 'y-axis-0',

                            yMax: 0,
                            xMin: 0,
                            backgroundColor: 'rgba(127, 0, 0, 0.25)'
                        }]
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            anchor: 'center',
                            align: 'right',
                            clamp: true,
                            color: '#000',
                            labels: {
                                department: {
                                    offset: context => Math.ceil(context.dataset.data[context.dataIndex].r),
                                    formatter: (value, context) => context.dataset.data[context.dataIndex].raw.meta.login,
                                    textShadowBlur: 2,
                                    textShadowColor: 'white'
                                },
                                dot: {
                                    align: 'center',
                                    formatter: () => '●',
                                    offset: 0,
                                    padding: 0,
                                    font: {
                                        family: 'monospace',
                                        size: 8
                                    }
                                }
                            }
                        }
                    }
                },
                styles: {
                    position: 'relative',
                    height: '600px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                }
            }
        },
        methods: {
            title(week) {
                let week_past_from = week.past.from < 10 ? '0' + week.past.from : week.past.from,
                    week_past_to = week.past.to < 10 ? '0' + week.past.to : week.past.to,
                    week_current_from = week.current.from < 10 ? '0' + week.current.from : week.current.from,
                    week_current_to = week.current.to < 10 ? '0' + week.current.to : week.current.to,
                    past = week_past_from !== week_past_to ? week_past_from + ' - ' + week_past_to : week_past_from,
                    current = week_current_from !== week_current_to ? week_current_from + ' - ' + week_current_to : week_current_from

                return past !== current ? past + 'W / ' + current + 'W' : past + 'W'
            },

            date(year, past_week, current_week) {
                let begin = moment(year + 'W' + (past_week < 10 ? '0' + past_week : past_week)),
                    end = moment(year + 'W' + (current_week < 10 ? '0' + current_week : current_week)).endOf('week'),
                    month = begin.format('MMMM')

                if(end.format('MMMM') !== month) {
                    return `${begin.format('D MMMM')} - ${end.format('D MMMM')}`
                }

                return `${begin.format('D')} - ${end.format('D MMMM')}`
            }
        },
        asyncComputed: {
            async chartData() {
                try {
                    return (await API.post('charts/bubbleSummary', {
                        chooseYear: this.period.chooseYear,
                        departmentId: this.period.departmentId,
                        pastYear: this.period.pastYear,
                        period: this.period.period,
                        pastWeekFrom: this.period.pastWeekFrom,
                        pastWeekTo: this.period.pastWeekTo,
                        currentWeekFrom: this.period.currentWeekFrom,
                        currentWeekTo: this.period.currentWeekTo,
                        checkbox_w53: this.period.checkbox_w53
                    })).data
                } catch(e) {
                    this.$notify.error('report.entries.notify.error.BUBBLE')

                    console.log(e)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .percent-positive {
        & > span:before {
            content: '▲'
        }

        color: @btn-success-bg;
    }

    .percent-negative {
        & > span:before {
            content: '▼'
        }

        color: @btn-danger-bg;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }

    p.arrow {
        font-size: 20px;
    }
</style>
