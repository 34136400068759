import moment from 'moment'
import _ from 'lodash'
import {randomString} from '../../../helpers/Utils'

export function budgetForecastAnnotation(date) {
    let box = {
        drawTime: 'beforeDatasetsDraw',
        type: 'box',
        id: 'a-forecast-box-' + randomString(),
        xScaleID: 'x-axis-0',
        yScaleID: 'y-axis-0',

        xMin: 0,

        borderColor: 'rgba(198,212,225, 0.5)',
        backgroundColor: 'rgba(198,212,225, 0.5)',
    }

    if (typeof date === 'string') {
        date = moment(date)
    }
    let today = moment()

    if (date.isoWeekYear() < today.isoWeekYear()) {
        return []
    } else if (date.isoWeekYear() === today.isoWeekYear()) {
        let week = today.isoWeek()

        box.xMin = week
    }

    return [box]
}

export function budgetQuartersAnnotation() {
    let quarter1 = {
        drawTime: 'beforeDatasetsDraw',
        type: 'box',
        id: 'a-quarter-1-',
        xScaleID: 'x-axis-0',
        yScaleID: 'y-axis-0',

        xMin: 1,
        xMax: 13,

        borderColor: 'rgba(189,184,173, 0.6)',
        backgroundColor: 'rgba(189,184,173, 0.6)',
    }
    let quarter2 = _.clone(quarter1)
    quarter2.xMin = 27
    quarter2.xMax = 39

    quarter1.id += randomString()
    quarter2.id += randomString()

    return [quarter1, quarter2]
}
