<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            OptiSaleSearch(heading="sale.reclamation.HEADING" :reclamation-mode="true"
                :sale="sale" @update-sale="updateSale")

        .col-xs-12(v-show="sale.id && !loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'register.return.sale.SELECTEDITEM'|translate}}

                .table-responsive
                    table.table
                        thead
                            tr
                                th
                                th.text-right #
                                th.text-right Id
                                th {{'register.return.sale.PRODUCT'|translate}}
                                th {{'register.return.sale.PRICE'|translate}}
                                th.text-right {{'register.return.sale.QUANTITY'|translate}}
                                th.text-right {{'register.return.sale.VAT'|translate}}
                                th.text-right {{'register.return.sale.VALUE'|translate}}
                                th.text-right {{'register.return.sale.DISCOUNT'|translate}}
                                th.text-right {{'register.return.sale.AFTERDISCOUNT'|translate}}
                        tbody
                            template(v-for="(item, key) in sale.items" v-show="item.quantity > 0")
                                tr.align-middle(:class="{'tr-stripped': key % 2}")
                                    td.text-right
                                        .radio.c-radio.c-radio-nofont
                                            label.text-bold
                                                input(type="radio" name="product" v-model="product" :value="item.product"
                                                    :disabled="!allow(item.reclamations)")
                                                span
                                    td.text-right {{key + 1}}
                                    td.text-right
                                        OptiProduct(:product="item.product") {{item.productId}}
                                    td
                                        OptiProduct(:product="item.product") {{item.productSymbol}}
                                    td.text-right {{item.price|currency}}
                                    td.text-right {{item.quantity}}
                                    td.text-right(NOWRAP) {{item.vat}}%
                                    td.text-right {{item.value|currency}}
                                    td.text-right ({{item.discountPercent|number(2)}}%) {{item.discount|currency}}
                                    td.text-right {{item.valueAfterDiscount|currency}}
                                tr.align-middle(:class="{'tr-stripped': key % 2}")
                                    td(style="border-top:0;padding-left:15px")
                                    td(colspan="9" style="border-top:0;padding-left:15px")
                                        div(v-for="reclamation in item.reclamations")
                                            a.bold.text-primary(style="display:block" :title="'default.REFERENCETYPE'|translate"
                                                :href="$state.href('app.sale.reclamation.details', {id: reclamation.id})")
                                                i.fa.fa-warning.mr3
                                                span {{'sale.RECLAMATION'|translate}} {{reclamation.id}}
                                        div(v-for="regeneration in item.regenerations")
                                            a.bold.text-info(style="display:block" :title="'default.REFERENCETYPE'|translate"
                                                :href="$state.href('app.regeneration.details', {id: regeneration.id})")
                                                i.fa.fa-warning.mr3
                                                span {{'regeneration.breadcrumb.REGENERATION'|translate}} {{regeneration.id}}
                            tr(v-show="sale.items && !sale.items.length")
                                td.text-center(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        form.form-horizontal(v-show="!loading")
            OptiReclamationServices(v-if="sale.id && product.id" type="sale" :product-type="product.type"
                :services="services" @update-services-invalid="b => services_invalid = b")
            .clearfix

            .col-md-6
                .form-group
                    label.col-xs-12.star-required(for="comments") {{'sale.reclamation.COMMENTS'|translate}}
                    .col-xs-12
                        textarea#comments.form-control(v-model="comments" name="comments" required)
                        OptiValidate(f="comments" :data="$v.comments")
            .col-md-6
                .form-group
                    label.col-xs-12(for="department_description") {{'sale.reclamation.DEPARTMENTDESCRIPTION'|translate}}
                    .col-xs-12
                        textarea#department_description.form-control(style="height:120px;resize:none"
                            v-model="sale.department_description" name="department_description")
                .form-group
                    .col-xs-12
                        OptiButton(type="success" text="sale.reclamation.confirm" @confirmed="goReclamationSale"
                            :disabled="$v.comments.$invalid || services_invalid || !sale.date_of_notice || !product.id || (!sale.patient.id && !sale.search_patient.id) || !!sale.lock"
                            ) {{'sale.reclamation.confirm.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-medkit

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiSaleSearch from '@/js/app/vue/components/Sales/SaleSearch/OptiSaleSearch'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiReclamationServices from '@/js/app/vue/components/Reclamations/OptiReclamationServices'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationSaleAdd',
        components: {
            OptiSaleSearch,
            OptiProduct,
            OptiReclamationServices,
            OptiValidate,
            OptiButton,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                sale: {
                    items: {},
                    search_patient: {}
                },

                services: {},
                services_invalid: true,

                product: {type: 0},
                comments: '',

                loading: false
            }
        },
        validations: {
            comments: {
                required
            }
        },
        mounted() {

        },
        methods: {
            allow(reclamations) {
                let _return = true

                if(reclamations) {
                    reclamations.forEach(reclamation => {
                        if(reclamation.status !== 0 && reclamation.status !== 8) {
                            _return = false

                            return false
                        }
                    })
                }

                return _return
            },

            updateSale(sale) {
                if(this.sale.id !== sale.id) {
                    this.product = {type: 0}
                }

                this.sale = sale
            },

            goReclamationSale() {
                this.loading = true

                _.forEach(this.services, types => {
                    types.forEach(service => {
                        service.upload = null
                        service.file = null
                    })
                })

                API.post('sale/reclamation/add', {
                    saleId: this.sale.id,
                    productId: this.product.id,
                    comments: this.comments,
                    patientId: Object.keys(this.sale.search_patient).length ? this.sale.search_patient.id :
                        (Object.keys(this.sale.patient).length ? this.sale.patient.id : 0),
                    services: this.services,
                    proof: this.sale.proof,
                    date_of_notice: this.sale.date_of_notice,
                    department_description: this.sale.department_description
                }).then(() => {
                    this.$state.go('app.sale.reclamations.accepted')

                    this.$notify.success('sale.reclamation.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.SAVE')
                })
            }
        }
    }
</script>