<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            form.form.form-horizontal(name="formRegister")
                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="0")
                                span
                                | {{'specification.create.RANGE'|translate}}
                    .col-xs-8
                        .form-group
                            label.col-xs-2.control-label(for="date_from") {{'specification.create.FROM'|translate}}
                            .col-xs-10
                                OptiDate#date_from(:value="search.date_from" @update-date="updateDateFrom")
                        .form-group
                            label.col-xs-2.control-label(for="date_to") {{'specification.create.TO'|translate}}
                            .col-xs-10
                                OptiDate#date_to(:value="search.date_to" @update-date="updateDateTo")
                .form-group
                    .col-xs-4(style="padding-top:23px")
                        .radio.c-radio.c-radio-nofont
                            label.text-bold
                                input(type="radio" name="type" v-model="search.type" :value="1")
                                span
                                | {{'specification.create.MONTH'|translate}}
                    .col-xs-8
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#month_years.form-control(v-model="search.month.year" @change="updateMonthYear")
                                    option(v-for="y in select.years" :value="y") {{y}}
                            label.col-xs-3.bold(style="padding-top:7px" for="month_years") {{'specification.create.YEAR'|translate}}
                        .form-group
                            .col-xs-7.col-xs-offset-2
                                select#month_months.form-control(v-model="search.month.month" @change="search.type = 1")
                                    option(v-for="month in select.months" :value="month.id") {{month.name|translate}}
                            label.col-xs-3.bold(style="padding-top:7px" for="month_months") {{'specification.create.MONTH'|translate}}
                        .form-group
                            .col-xs-12.text-right
                                OptiButton(type="success" :disabled="loading.main" @click.native="goSearch"
                                    ) {{'specification.turn-back.FIND'|translate}}
                                    template(#icon)
                                        i.fa.fa-search

        .col-xs-12.text-right(style="padding-bottom:10px" v-show="!loading.main && turnBacks.length")
            OptiButton(:disabled="loading.excel" @click.native="exportToExcel"
                ) {{'specification.turn-back.EXCEL'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o

        .col-xs-12(v-show="!loading.main")
            .panel.panel-primary(style="margin-bottom:0")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.turn-back.table.HEADING'|translate}}

                .table-responsive
                    table.table.table-condensed.table-bordered.table-striped.middle
                        thead
                            tr
                                th.text-right {{'specification.turn-back.table.TASKID'|translate}}
                                th.text-right {{'specification.turn-back.table.DEPARTMENT'|translate}}
                                th.text-right {{'specification.turn-back.table.LENSES'|translate}}
                                th.text-right {{'specification.turn-back.table.ZHCLENSES'|translate}}
                                th.text-right {{'specification.turn-back.table.FRAME'|translate}}
                                th.text-right {{'specification.turn-back.table.ZHCFRAME'|translate}}
                                th.text-right {{'specification.turn-back.table.TURNBACK'|translate}}
                                th.text-right {{'specification.turn-back.table.CONFIRMED'|translate}}
                                th.text-right {{'specification.turn-back.table.ORDERED'|translate}}
                                th.text-right {{'specification.turn-back.table.GRINDER'|translate}}
                                th.text-right {{'specification.turn-back.table.USER'|translate}}
                                th.text-right {{'specification.turn-back.table.DATE'|translate}}

                        tbody
                            tr(v-for="tback in turnBacks")
                                td.text-right
                                    a.link(:href="$state.href('app.task.detail', {id: tback.task_id})"
                                        target="_blank") {{tback.task_id}}
                                td.text-right {{tback.department_name}}
                                td.text-right {{tback.lenses}}
                                td.text-right {{tback.lenses_zhc|currency}}
                                td.text-right {{tback.frame_name}}
                                td.text-right {{tback.frame_zhc|currency}}
                                td.text-right(v-html="tback.turn_back") {{tback.turn_back}}
                                td.text-right {{tback.confirmed_by_name}}
                                td.text-right {{tback.ordered_by_name}}
                                td.text-right {{tback.grinder_name}}
                                td.text-right {{tback.turned_back_by_name}}
                                td.text-right {{tback.created_at}}

                        tfoot(v-show="!turnBacks.length")
                            tr
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {Months} from '@/js/app/vue/helpers/Months'
    import {API} from '@/js/app/vue/api'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskTurnBackSpecification',
        components: {
            OptiDate,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                search: {
                    type: 0,
                    date_from: moment().format('YYYY-MM-DD'),
                    date_to: moment().format('YYYY-MM-DD'),
                    month: {
                        year: parseInt(moment().format('YYYY')),
                        month: parseInt(moment().format('M'))
                    },
                },
                select: {
                    years: [],
                    months: []
                },

                turnBacks: [],
                turned_back_by: '',

                loading: {
                    main: false,
                    excel: false
                }
            }
        },
        mounted() {
            this.generateYears()
        },
        methods: {
            generateSelectYears() {
                for(let y = parseInt(moment().format('YYYY')); y >= parseInt(this.firstYear); --y) {
                    this.select.years.push(y)
                }

                this.generateSelectMonths()
            },
            generateYears() {
                API.get('register/getFirstYear').then(res => {
                    this.firstYear = res.data.firstYear
                    this.startMonth = res.data.startMonth

                    this.generateSelectYears()
                }).catch(() => {
                    console.error('Nie można pobrać roku - weźmie 2006')

                    this.generateSelectYears()
                })
            },
            generateSelectMonths() {
                let m = Months.get(),
                    tmp = []

                if(this.search.month.year === parseInt(moment().format('YYYY'))) {
                    let currentMonths = Months.getToMonth(moment().format('M'))

                    m.forEach(month => {
                        if(currentMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                    tmp = []
                }

                if(this.search.month.year === this.firstYear) {
                    let firstYearMonths = Months.getFromMonth(this.startMonth)

                    m.forEach(month => {
                        if(firstYearMonths.indexOf(month) !== -1) {
                            tmp.push(month)
                        }
                    })

                    m = tmp
                }

                if(m.indexOf(this.search.month.month) === -1) {
                    this.search.month.month = m[0]
                }

                this.select.months = Months.parse(m)
            },
            updateDateFrom(date_from) {
                this.search.date_from = date_from

                this.search.type = 0
            },
            updateDateTo(date_to) {
                this.search.date_to = date_to

                this.search.type = 0
            },
            updateMonthYear() {
                this.generateSelectMonths()

                this.search.type = 1
            },
            goSearch() {
                this.loading.main = true

                API.post('specifications/getTurnBacks', this.search).then(res => {
                    this.turnBacks = res.data
                }).finally( () => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('specification.turn-back.notify.error.LOAD')
                })
            },
            exportToExcel() {
                this.loading.excel = true

                API.post('specifications/getTurnBacks/exportToExcel', {
                    turnBacks: this.turnBacks
                }).then(res=> {
                    window.open('reports/' + res.data + '.xlsx')
                }).finally( () => {
                    this.loading.excel = false
                }).catch(() => {
                    this.$notify.error('specification.turn-back.notify.error.EXCEL')
                })
            }
        }
    }
</script>