<template lang="pug">
    OptiScheduleWeekEvents(:events="events" :working-hours="workingHours")
</template>

<script>
    import OptiScheduleWeekEvents from '@/js/app/vue/components/Schedule/Week/OptiScheduleWeekEvents'
    export default {
        name: 'OptiScheduleEditor',
        components: {OptiScheduleWeekEvents},
        props: {
            events: {
                type: undefined,
                required: true
            },
            workingHours: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
