<template lang="pug">
    .row
        .col-xs-12
            .table-responsive
                table.table.table-striped.table-offer-header
                    tbody
                        tr(v-if="mode === 'questions_frames' || selected.questions.frames.length")
                            th
                                span {{'catalog.offer.switch.QUESTIONS'|translate}}
                                small {{'catalog.offer.questions.type.FRAMES'|translate}}
                            td
                                .label-offer(v-for="(question, key_question) in selected.questions.frames"
                                    :class="{'disabled': question.name !== 'available' && question.options.map(o => o.key).includes('all'), 'label-offer-group': typeof question.count !== 'undefined' && question.count !== null}"
                                    :title="$filters.translate('catalog.offer.questions.' + question.name + '.HEADING') + ' | ' + (isSkipped && to_skip.includes(key_question) ? $filters.translate('catalog.offer.questions.SKIPPED') : question.options_translated) + (typeof question.count !== 'undefined' && question.count !== null ? ' | ' + question.count : '')")
                                    .label.label-primary
                                        span.mr3 {{'catalog.offer.questions.' + question.name + '.HEADING'|translate}} |
                                        span(style="color:red" v-if="isSkipped && to_skip.includes(key_question)"
                                            ) {{'catalog.offer.questions.SKIPPED'|translate}}
                                        span(v-else) {{question.options_translated}}
                                    .label.label-info(v-if="typeof question.count !== 'undefined' && question.count !== null")
                                        span.label-offer-count {{question.count}}

                                .text-muted(v-show="!selected.questions.frames.length")
                                    i.fa.fa-exclamation-circle.mr3
                                    span.mr3 {{'catalog.offer.notify.header.QUESTIONS'|translate}}
                                    span {{'catalog.offer.questions.type.FRAMES'|translate}}

                        tr(v-if="mode === 'questions_hoya' || selected.questions.hoya.length")
                            th
                                span {{'catalog.offer.switch.QUESTIONS'|translate}}
                                small {{'catalog.offer.questions.type.HOYA'|translate}}
                            td
                                .label-offer(v-for="question in selected.questions.hoya"
                                    :title="$filters.translate('catalog.offer.questions.hoya.' + question.name) + ' | ' + $filters.translate('catalog.offer.questions.hoya.' + question.option)")
                                    .label.label-primary
                                        span.mr3 {{'catalog.offer.questions.hoya.' + question.name|translate}} |
                                        span {{'catalog.offer.questions.hoya.' + question.option|translate}}

                                .text-muted(v-show="!selected.questions.hoya.length && !isSkipped")
                                    i.fa.fa-exclamation-circle.mr3
                                    span.mr3 {{'catalog.offer.notify.header.QUESTIONS'|translate}}
                                    span {{'catalog.offer.questions.type.HOYA'|translate}}

                                .warn(v-show="!selected.questions.hoya.length && isSkipped")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'catalog.offer.questions.SKIPPED'|translate}}

                        tr(v-if="mode === 'filters' || selected.filters.length")
                            th {{'catalog.offer.switch.FILTERS'|translate}}
                            td
                                .label-offer(v-for="filter in selected.filters")
                                    .label.label-primary
                                        span.mr3(v-show="filter.name") {{filter.name}}
                                        span(v-show="filter.option") {{filter.option}}
                                        span(style="color:red" v-show="!filter.valid") {{'catalog.offer.filters.NO_FILLED'|translate}}

                                .text-muted(v-show="!selected.filters.length")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'catalog.offer.notify.header.FILTERS'|translate}}

                        tr(v-show="!['questions_frames', 'questions_hoya', 'filters'].includes(mode) && !selected.questions.frames.length && !selected.questions.hoya.length && !selected.filters.length")
                            td.warn.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'catalog.offer.filters.NONE_ALL'|translate}}
</template>

<script>
    export default {
        name: 'OptiCatalogOfferHeader',
        props: {
            selected: {
                type: Object,
                required: true
            },
            isSkipped: {
                type: Boolean,
                required: true
            },
            mode: {
                type: String,
                required: false,
                default: ''
            }
        },
        computed: {
            to_skip() {
                return this.$store.getters['offer/toSkip']
            }
        }
    }
</script>

<style lang="less" scoped>
    table {
        margin-bottom: 15px !important;

        > tbody > tr {
            > th, > td {
                border: none !important;
            }

            > th {
                width: 70px;
                height: 50px;
                vertical-align: middle !important;
                padding: 0 0 0 8px !important;

                > small {
                    display: block;
                }
            }

            > td > .text-muted, > td > .warn {
                margin-top: 5px;
            }
        }
    }

    .label-offer {
        margin: 5px 10px 5px 0 !important;

        &.disabled {
            opacity: .6;
        }
    }

    @media (max-width: 767px) {
        .col-xs-12 img {
            width: 100%;
        }
    }
</style>