import Empty from '@/js/app/vue/directives/Empty'

const directives = [
    Empty
]

export default {
    install(VueInstance) {
        directives.forEach(directive => {
            VueInstance.directive('opti-' + directive.name.toLowerCase(), directive.directive)
        })
    }
}