<template lang="pug">
    .row
        .col-md-6.col-md-offset-3
            form.form.form-horizontal(style="padding-bottom:15px" name="formReport")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_from") {{'report.warehouse.tasks-turn-back.date.FROM'|translate}}
                    .col-sm-8
                        OptiDate#date_from(:disabled="loading.main" :value="date.from" @update-date="d => date.from = d")
                        OptiValidate(f="date_from" :data="$v.date.from")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_to") {{'report.warehouse.tasks-turn-back.date.TO'|translate}}
                    .col-sm-8
                        OptiDate#date_to(:disabled="loading.main" :value="date.to" @update-date="d => date.to = d")
                        OptiValidate(f="date_to" :data="$v.date.to")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButton(type="success" :disabled="loading.main || $v.date.$invalid"
                            @click.native="generate") {{'report.warehouse.tasks-turn-back.GENERATE'|translate}}
                            template(#icon)
                                i.fa.fa-check

        .col-xs-12(v-show="!loading.main")
            .table-responsive
                table.table.middle
                    thead
                        tr.bg-separator
                            th(rowspan="2") #
                            th(rowspan="2") {{'report.warehouse.tasks-turn-back.header.TASK'|translate}}
                            th(rowspan="2")
                                div {{'report.warehouse.tasks-turn-back.header.date.ACCEPTED'|translate}}
                                div {{'report.warehouse.tasks-turn-back.header.date.RECEIVED'|translate}}
                            th.text-center(colspan="5") {{'report.warehouse.tasks-turn-back.header.wz.HEADER'|translate}}
                            th(rowspan="2") {{'report.warehouse.tasks-turn-back.header.COMMENTS'|translate}}
                        tr.bg-separator.tr-separator
                            th #
                            th {{'report.warehouse.tasks-turn-back.header.wz.ID'|translate}}
                            th {{'report.warehouse.tasks-turn-back.header.wz.PRODUCT'|translate}}
                            th {{'report.warehouse.tasks-turn-back.header.wz.QUANTITY'|translate}}
                            th {{'report.warehouse.tasks-turn-back.header.wz.CORRECTED'|translate}}
                    tbody
                        template(v-for="(task, key) in tasks")
                            tr(:class="{'tr-stripped': key % 2}")
                                td(:rowspan="task.wz.length") {{key + 1}}
                                td(:rowspan="task.wz.length")
                                    a.link(:href="$state.href('app.task.detail', {id: task.id})" target="_blank") {{task.id}}
                                td(:rowspan="task.wz.length")
                                    div {{task.date.accepted}}
                                    div {{task.date.finished}}
                                td 1
                                td
                                    a.link(:href="$state.href('app.tasks.wz.details', {id: task.wz[0].id})" target="_blank") {{task.wz[0].id}}
                                td
                                    OptiProduct.mr3(:product="task.wz[0].product") {{task.wz[0].product.id}}
                                    span {{task.wz[0].product.name}}
                                td {{task.wz[0].quantity}}
                                td {{task.wz[0].corrected}}
                                td.warn(:rowspan="task.wz.length")
                                    div(v-for="comments in task.comments") {{comments}}
                            tr(v-for="(wz, wz_key) in task.wz.slice(1, 5000)"
                                :class="{'tr-stripped': key % 2, 'tr-separator': wz_key === task.wz.length - 2 && key !== tasks.length - 1}")
                                td {{wz_key + 2}}
                                td
                                    a.link(:href="$state.href('app.tasks.wz.details', {id: wz.id})" target="_blank") {{wz.id}}
                                td
                                    OptiProduct.mr3(:product="wz.product") {{wz.product.id}}
                                    span {{wz.product.name}}
                                td {{wz.quantity}}
                                td {{wz.corrected}}

                        tr(v-show="!tasks.length")
                            td.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        .col-xs-12.text-right(style="padding-bottom:20px" v-show="!loading.main && tasks.length")
            OptiButton(:disabled="loading.xls" @click.native="xls") {{'report.warehouse.tasks-turn-back.XLS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportWarehouseTasksTurnBack',
        components: {
            OptiDate,
            OptiProduct,
            OptiValidate,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                date: {
                    from: '',
                    to: ''
                },

                tasks: [],

                loading: {
                    main: false,
                    xls: false
                }
            }
        },
        validations: {
            date: {
                from: {
                    required
                },
                to: {
                    required
                }
            }
        },
        methods: {
            generate() {
                this.loading.main = true

                API.post('reports/warehouse/tasks-turn-back', this.date).then(res => {
                    this.tasks = res.data
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('report.warehouse.tasks-turn-back.notify.error.GENERATE')
                })
            },

            xls() {
                this.loading.xls = true

                let filename = 'prace_cofki_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'warehouse/tasks_turn_back/' + filename,
                        status: true
                    }
                })

                API.post('reports/warehouse/tasks-turn-back/xls', {
                    from: this.date.from,
                    to: this.date.to,
                    filename: filename
                }).finally(() => {
                    this.loading.xls = false
                }).catch(() => {
                    this.$notify.error('report.warehouse.tasks-turn-back.notify.error.XLS')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    table tr.tr-separator {
        border-bottom: 2px solid #aaa;
    }
</style>