<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-default
                .panel-heading
                    i.fa.fa-info-circle.mr3
                    span Informacje
                .panel-body
                    ul
                        li  Pliki należy wgrywać w odpowiedniej kolejności,
                        li  Wersja pliku referencyjnego jest sprawdzana z wersją na serwerze,
                        li  Powodem wgrywania jest to, że plik Hoya jest bardzo duży, dlatego nasz system wyciąga część wskazaną przez użytkownika i wgrywa do naszego systemu,
                        li Wersja pliku referencyjnego jest automatycznie sprawdzana i jeśli jest nieaktualna użytkownik dostanie instrukcję co robić dalej.
                        li.text-bold UWAGA! Jeśli były jakiekolwiek zmiany ręczne na produktach, system nadpisze zmiany i zarchiwizuje stare dane.

                    ol Kolejność wgrywania plików:
                        li Plik Hoya z opcją "Soczewki",
                        li Plik Hoya z opcją "Powłoki i dodatki",
                        li Plik Hoya z opcją "Kombinacje".
            .panel.panel-default
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span Wczytaj plik
                .panel-body
                    .col-sm-6
                        .form-group
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'lenses'"
                                                @change="changeType('lenses')")
                                            span.fa.fa-check
                                    span Soczewki
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'extras'"
                                                @change="changeType('extras')")
                                            span.fa.fa-check
                                    span Powłoki i dodatki
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'combi'"
                                                @change="changeType('combi')")
                                            span.fa.fa-check
                                    span Kombinacje
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'reference'"
                                                @change="changeType('reference')" )
                                            span.fa.fa-check
                                    span Plik referencyjny
                            .col-xs-12
                                span.text-bold.text-warning.mr3 UWAGA!
                                |
                                span.text-bold.text-warning Przed wprowadzeniem zmian Sunloox lub kodów z faktury skontaktuj się z adminem!
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'sunloox'"
                                                @change="changeType('sunloox')" )
                                            span.fa.fa-check
                                    span Soczewki Sunloox
                            .col-xs-12
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="radio" v-model="file_ref" :value="'fv_code'"
                                                @change="changeType('fv_code')" )
                                            span.fa.fa-check
                                    span Kody na fakturze

                    .col-sm-6
                        .row
                            OptiUpload#xml(
                                prefix="hoya"
                                :file="file"

                                @update-file="f => file = f"
                                @update-loading ="l => loading.file = l")
                        .row.pull-right
                            OptiButton(
                                type="success"
                                :disabled="!file || ref_ok === false"
                                style="margin-top:5px"

                                @click.native="updatePrice()") Wczytaj plik
                                template(#icon)
                                    i.fa.fa-upload
                        .row(v-show="ref_ok===false")
                            span.text-bold.text-center.text-warning Typ danych, które wybrałeś jest aktualny.

        OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api';
    import OptiButton from '@/js/app/vue/components/Button/OptiButton';
    import OptiUpload from '@/js/app/vue/components/OptiUpload';
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer';

    export default {
        name: 'OptiHoyaCatalogDataImporter',
        components: {OptiUpload, OptiButton, OptiDimmer},
        data() {
            return {
                file_ref: null,
                ref_ok: true,
                file: null,
                loading: {
                    file: false,
                    main: false
                }
            }
        },
        methods: {
            changeType(x) {
                this.file_ref = x
                API.post('catalog/hoya/refCheck', {
                    ref: x
                }).then(res => {
                    console.log(res.data)
                    this.ref_ok = res.data !== 'exists';
                })
            },
            updatePrice() {
                this.loading.main = true
                if (this.file) {
                    try {
                        API.post('catalog/hoya/updatePrices', {
                            file: this.file,
                            file_ref: this.file_ref
                        }).then(() => {
                            this.$notify.success('Produkty zostały wprowadzone do systemu.')
                        }).then(() => {
                            this.file_ref = null
                            this.file = null
                        }).finally(() => {
                            this.loading.main = false
                        })
                    } catch(e) {
                        this.$notify.error('Something went wrong!')
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .pane-header {
        font-size: 16px;
        border-bottom: 1px solid;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom-color: #bcc2c4;
    }
</style>