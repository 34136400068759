<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .panel.panel-primary
                .panel-heading(v-show="!$state.params.shipment_id")
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.CREATENEW'|translate}}
                .panel-heading(v-show="$state.params.shipment_id")
                    i.fa.fa-edit.mr3
                    span {{'shipments.new.EDIT'|translate}} \#{{$state.params.shipment_id}}

                .form-wizard.wizard-horizontal
                    ol.row
                        li.col-md-5(:class="{'active': step === 1}" @click="step = 1")
                            h4 {{'shipments.new.RECEIVER'|translate}}
                            small.text-muted
                                span.mr3(style="color:red" v-show="!receiver.address.firstName && !receiver.address.lastName") {{'shipments.new.receiver.LABEL'|translate}}
                                span {{receiver.address.firstName}} {{receiver.address.lastName}}
                        li.col-md-4(:class="{'active': step === 2}" @click="goStep2")
                            h4 {{'shipments.new.CONTENT'|translate}}
                            small.text-muted {{'shipments.new.content.LABEL'|translate}}
                        li.col-md-3(:class="{'active': step === 3}" @click="step = 3")
                            h4 {{'shipments.new.DONE'|translate}}
                            small.text-muted {{'shipments.new.done.LABEL'|translate}}

                .panel-body(style="padding-top:0")
                    OptiShippingCreateStepReceivers(v-show="step === 1"
                        :model="model"
                        :departments="ingredients.departments"
                        :specialists="ingredients.specialists"
                        :receiver="receiver"

                        :v-model-custom="$v.model.custom"

                        @change-address="changeAddress")

                    OptiShippingCreateStepItems(v-show="step === 2"
                        :model="model"
                        :receiver="receiver"

                        :step="2"
                        :cartons="cartons"

                        @delete-item="deleteItem"
                        @update-weight="sumWeight")

                    OptiShippingCreateStepDone(v-show="step === 3"
                        :model="model"
                        :shipment="shipment"
                        :receiver="receiver"

                        :cartons="cartons"

                        :v-shipment="$v.shipment"

                        @update-weight="sumWeight"
                        @update-receiver-locker="updateReceiverLocker"
                    )

                .panel-footer.text-right(v-show="step === 3")
                    OptiButtonSave(:disabled="disabled" @click.native="processShipping")

            OptiShippingCreateStepContent(v-show="step === 2"
                :cartons="cartons"

                @update-item="updateItem")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero, noZeroIf} from '@/js/vue.validators.js'
    import Vue from 'vue'

    import OptiShippingCreateStepReceivers
        from '@/js/app/vue/components/Shipping/Create/Steps/OptiShippingCreateStepReceivers'
    import OptiShippingCreateStepItems
        from '@/js/app/vue/components/Shipping/Create/Steps/OptiShippingCreateStepItems'
    import OptiShippingCreateStepContent
        from '@/js/app/vue/components/Shipping/Create/Steps/OptiShippingCreateStepContent'
    import OptiShippingCreateStepDone
        from '@/js/app/vue/components/Shipping/Create/Steps/OptiShippingCreateStepDone'

    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiShippingCreate',
        components: {
            OptiShippingCreateStepReceivers,
            OptiShippingCreateStepItems,
            OptiShippingCreateStepContent,
            OptiShippingCreateStepDone,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                shipment: {
                    delivery_type: 30,
                    shipment_type: '11',
                    suggested_value: 0,
                    value: 0,
                    weight: 0,
                    saturday: false,
                    cod_checkbox: false,
                    cod: 0,
                    generate_tour_id: true,
                    size: 0,
                    width: 0,
                    height: 0,
                    len: 0
                },

                model: {
                    department: {},
                    patient: {},
                    specialist: {},
                    custom: {}
                },

                receiver: {
                    type: 0,
                    address: {},
                    comments: '',
                    locker: ''
                },

                ingredients: {},
                cartons: [{
                    items: [],
                    weight: 0,
                    value: 0
                }],

                step: 1,
                loading: true
            }
        },
        validations: {
            shipment: {
                delivery_type: {
                    noZero
                },
                shipment_type: {
                    required: requiredIf(shipment => shipment.delivery_type === 30)
                },
                cod: {
                    noZeroIf: noZeroIf(shipment => shipment.cod_checkbox)
                },
                value: {
                    noZero
                },
                weight: {
                    noZero
                },
                size: {
                    noZeroIf: noZeroIf(shipment => [40, 45].includes(shipment.delivery_type))
                },
                width: {
                    noZeroIf: noZeroIf(shipment => [40, 45].includes(shipment.delivery_type))
                },
                height: {
                    noZeroIf: noZeroIf(shipment => [40, 45].includes(shipment.delivery_type))
                },
                len: {
                    noZeroIf: noZeroIf(shipment => [40, 45].includes(shipment.delivery_type))
                }
            },
            model: {
                custom: {
                    firstName: {
                        required
                    },
                    street: {
                        required
                    },
                    zipCode: {
                        required
                    },
                    city: {
                        required
                    }
                }
            }
        },
        mounted() {
            API.get('shipment/ingredients').then(res => {
                this.ingredients = res.data

                if(this.$state.params.shipment_id) {
                    this.getShipment()
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('shipments.new.notify.error.LOAD')
            })

            this.updateBreadcrumb()
        },
        methods: {
            goStep2() {
                this.step = 2

                Vue.nextTick(() => {
                    document.getElementById('barcode').focus()
                })
            },
            updateBreadcrumb() {
                let breadcrumb = []

                if(this.$state.params.shipment_id) {
                    let active_department_id = this.$uac.user.activeDepartmentId === 149 ? 49 : this.$uac.user.activeDepartmentId,
                        outcoming = this.shipment.department_id === active_department_id

                    breadcrumb = [{
                        name: 'shipments.breadcrumb.' + (outcoming ? 'OUTCOMING' : 'INCOMING')
                    }, {
                        functions: outcoming ? 'outcomingshipments' : 'incomingshipments',
                        args: 'shipments.status.READY'
                    }, {
                        functions: 'shipment',
                        args: [this.$state.params.shipment_id, 'state']
                    }, {
                        name: 'shipments.breadcrumb.EDIT'
                    }]
                } else {
                    breadcrumb = [{
                        name: 'shipments.breadcrumb.OUTCOMING'
                    }, {
                        name: 'shipments.breadcrumb.NEW'
                    }]
                }

                this.$emit('update-breadcrumb', breadcrumb)
            },
            changeAddress() {
                switch(parseInt(this.receiver.type)) {
                    case 0:
                        this.receiver.address = this.model.department
                        break

                    case 1:
                        this.receiver.address = this.model.patient
                        break

                    case 4:
                        this.receiver.address = this.model.specialist
                        break

                    case 3:
                        this.receiver.address = this.model.custom
                }
            },
            getDepartment(department_id) {
                let _return = false

                this.ingredients.departments.forEach(department => {
                    if(department.id === department_id) {
                        _return = department
                    }
                })

                return _return
            },
            getSpecialist(specialist_id) {
                let _return = false

                this.ingredients.specialists.forEach(specialist => {
                    if(specialist.id === specialist_id) {
                        _return = specialist
                    }
                })

                return _return
            },
            getShipmentReceiver(data) {
                this.receiver.type = data.receiver_type
                this.receiver.comments = data.comment

                switch(this.receiver.type) {
                    case 0:
                        this.model.department = this.getDepartment(parseInt(data.receiver_id))
                        break

                    case 1:
                        API.get('patient/' + data.receiver_id).then(res => {
                            this.model.patient = res.data
                            this.changeAddress()
                        }).catch(() => {
                            this.$notify.error('patient.notify.NOTLOAD')
                        })
                        break

                    case 4:
                        this.model.specialist = this.getSpecialist(parseInt(data.receiver_id))
                        break

                    case 3:
                        let split = data.receiver_name.split(' ')

                        this.model.custom = {
                            firstName: split.length === 2 ? split[0] : data.receiver_name,
                            lastName: split.length === 2 ? split[1] : '',
                            street: data.receiver_street,
                            zipCode: data.receiver_zip_code,
                            city: data.receiver_city,
                            cellPhoneNumber: data.receiver_telephone
                        }
                }

                if([0, 4, 3].includes(this.receiver.type)) {
                    this.changeAddress()
                }
            },
            getShipment() {
                this.loading = true

                API.get('shipment/parse-to-edit/' + this.$state.params.shipment_id).then(res => {
                    this.shipment.id = this.$state.params.shipment_id
                    this.shipment.delivery_type = res.data.delivery_type
                    this.shipment.shipment_type = res.data.shipment_type
                    this.shipment.suggested_value = res.data.suggested_value
                    this.shipment.value = res.data.value
                    this.shipment.saturday = res.data.saturday
                    this.shipment.cod_checkbox = res.data.cod_checkbox
                    this.shipment.cod = res.data.cod
                    this.generate_tour_id = res.data.generate_tour_id

                    this.getShipmentReceiver(res.data)

                    this.cartons = res.data.details
                    this.sumWeight()
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('shipments.notify.error.LOAD')
                })
            },

            updateItem(cartons) {
                this.cartons = cartons
                this.shipment.suggested_value = 0
                this.shipment.value = 0

                this.cartons.forEach(carton => {
                    carton.value = 0

                    carton.items.forEach(item => {
                        if(!this.model.department.id && [1, 4, 12, 14, 16, 17].includes(item.type)) {
                            this.model.department = this.getDepartment(item.department.id)
                        } else if(!this.model.custom.firstName && item.type === 9) {
                            Vue.set(this.model, 'custom', item.address)

                            if(!this.receiver.type && !this.model.department.id) {
                                this.receiver.type = 3
                            }
                        } else if(item.type === 15) {
                            if(!this.model.department.id && item.address.type === 'department') {
                                this.model.department = this.getDepartment(item.address.id)
                            } else if(!this.model.specialist.id && item.address.type === 'specialist') {
                                this.model.specialist = this.getSpecialist(item.address.id)

                                if(!this.model.department.id && !this.receiver.type) {
                                    this.receiver.type = 4
                                }
                            }
                        }

                        carton.value += parseFloat(item.value)
                        this.shipment.suggested_value += parseFloat(item.value)
                    })
                })

                this.shipment.value = Math.round(this.shipment.suggested_value * 100) / 100
                this.changeAddress()
            },
            deleteItem(item, key, carton_index) {
                this.cartons[carton_index].items.splice(key, 1)

                this.updateItem(this.cartons)
            },

            sumWeight() {
                this.shipment.weight = 0

                this.cartons.forEach(carton => {
                    this.shipment.weight += parseFloat(carton.weight)
                })
            },

            updateReceiverLocker(lockerName) {
                this.receiver.locker = lockerName
            },

            downloadURI(uri, name) {
                let link = document.createElement('a')

                link.download = name
                link.href = uri
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            },
            printMets(id) {
                if(this.shipment.generate_tour_id && this.shipment.delivery_type === 30) {
                    this.cartons.forEach((carton, key) => {
                        let link = 'upload/shipment/mets/' + id + (key ? '-' + (key + 1) : '') + '.jpg'

                        this.downloadURI(link, id + '.jpg')
                    })
                }
            },

            add() {
                API.post('shipment/create', {
                    shipment: this.shipment,
                    receiver: this.receiver,
                    cartons: this.cartons
                }).then(res => {
                    if(Object.keys(res.data).includes('error')) {
                        this.$notify.error(res.data.error, false, true)
                    } else {
                        this.printMets(res.data.id)

                        this.$notify.success('shipments.new.done.notify.SAVE')

                        this.$state.go('app.shipment.create', null, {reload: true})
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('shipments.new.done.notify.error.SAVE')
                })
            },
            edit() {
                API.put('shipment/edit', {
                    shipment: this.shipment,
                    receiver: this.receiver,
                    cartons: this.cartons
                }).then(res => {
                    if(Object.keys(res.data).includes('error')) {
                        this.$notify.error(res.data.error, false, true)
                    } else {
                        this.printMets(res.data.id)

                        this.$notify.success('shipments.new.done.notify.EDIT')

                        this.$state.go('app.warehouse.shipment.detail', {id: res.data.id})
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('shipments.new.done.notify.error.EDIT')
                })
            },
            processShipping() {
                this.loading = true

                if(!this.$state.params.shipment_id) {
                    this.add()
                } else {
                    this.edit()
                }
            }
        },
        computed: {
            disabled() {
                let emptyItems = false

                this.cartons.forEach(carton => {
                    if(!carton.items.length) {
                        emptyItems = true
                    }
                })

                return emptyItems || !this.receiver.address.firstName ||
                    (this.receiver.type === 3 && this.$v.model.custom.$invalid) || this.$v.shipment.$invalid
            }
        }
    }
</script>