<template lang="pug">
  .container
    .row
      .col-md-12
        .new-import-header {{'product.product_selection_imports.new_import.HEADING'|translate}}
      .col-md-8.col-md-offset-2
        form.form-horizontal
          .form-group
            label.col-sm-5.control-label.star-required(for="file") {{ 'product.import.FILE' | translate }}
            .col-sm-7
              OptiUpload#file(
                prefix="product_selection"
                :file="file"
                @update-loading="l => loading.file = l"
                @update-file-original-name="f => originalFileName = f"
                @update-file="f => file = f"
                ref="optiUploadRef"
              )
          .form-group
            .col-sm-5.col-sm-offset-5
              .opti-select-supplier(v-if="suppliers && suppliers.length > 0")
                VueMultiSelect(
                  :value="selectedSupplier"
                  :options="suppliers"
                  :show-labels="false"
                  @input="updateSelectedSupplier"
                  label="name"
                  track-by="id"
                  :placeholder="$t('product.product_selection_imports.select_supplier.label')"
                )
                template(slot="option", slot-scope="{ option }")
                  span(:class="{ 'disabled-option': option.disabled }") {{ option.name }}
                template(slot="singleLabel", slot-scope="props")
                  span {{ props.option.translation | translate }}
          .form-group
            .col-sm-8.col-sm-offset-5
              .btn-group
                OptiButton.button-spacing(
                  type="success"
                  :disabled="!file || loading.file || loading.main || !selectedSupplier"
                  @click.native="runImport"
                )
                  | {{ 'product.product_selection_imports.IMPORT_BTN' | translate }}
                  template(#icon)
                    i.fa.fa-upload

                OptiButton.button-spacing(
                  type="info"
                  :disabled="!file || loading.file || loading.main || !selectedSupplier"
                  @click.native="runValidation"
                )
                  | {{ 'product.product_selection_imports.VALIDATE_BTN' | translate }}
                  template(#icon)
                    i.fa.fa-check-square-o
    .row
      .col-xs-12
        .panel.panel-primary(style="position:relative")
          .panel-heading
            i.fa.fa-navicon.mr3
            span {{'product.product_selection_imports.table.HEADING'|translate}}

          .table-responsive
            table.table.table-striped.middle
              thead
                tr
                  th {{'product.product_selection_imports.table.ID'|translate}}
                  th {{'product.product_selection_imports.table.CREATED_AT'|translate}}
                  th {{'product.product_selection_imports.table.FILE_NAME'|translate}}
                  th {{'product.product_selection_imports.table.USER_NAME'|translate}}
                  th {{'product.product_selection_imports.table.NUMBER_OF_PRODUCTS'|translate}}
                  th {{''}}
              tbody
                tr(v-for="(history_entry, key) in history")
                  td {{history_entry.id}}
                  td {{history_entry.created_at}}
                  td {{history_entry.original_file_name}}
                  td {{history_entry.user_name}}
                  td {{history_entry.number_of_products}}
                  td
                    a(@click="downloadFile(history_entry.id)" title="Download")
                      i.fa.fa-download.fa-2x
                tr(v-show="!history.length")
                  td.text-muted.text-center(colspan="100")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.table.EMPTY'|translate}}
          .panel-footer.clearfix
            .col-xs-12.text-center
              OptiPaginator(
                style="width: 100%"
                :paginator="paginator"

                @changed-page="p => refreshGrid(p)")
        OptiDimmer(:active="loading.main")
</template>

<style lang="less" scoped>
.opti-select-supplier {
  margin-bottom: 15px;
}
.button-spacing {
  margin-right: 10px;
}
.button-spacing.last-button {
  margin-left: 20px;
}
.new-import-header {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}
</style>

<script>
import {API} from '@/js/app/vue/api'
import OptiUpload from "@/js/app/vue/components/OptiUpload.vue";
import OptiButton from "@/js/app/vue/components/Button/OptiButton.vue";
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer.vue";
import { EnumCategoryDeliveryType } from "@/js/app/vue/enums/Enums.js";
import VueMultiSelect from "vue-multiselect";
import OptiPaginator from "@/js/app/vue/components/OptiPaginator.vue";

export default {
  name: 'OptiProductsSelectionImport',
  components: {
    OptiPaginator,
    OptiUpload,
    OptiButton,
    OptiDimmer,
    VueMultiSelect
  },
  props: {

  },
  data() {
    return {
      file: null,
      selectedSupplier: null,
      originalFileName: null,
      suppliers: [],
      history: [],
      loading: {
        main: true,
        upload: false
      },
      paginator: {
        pages: 1,
        page: 1
      },
    }
  },
  created() {
    this.initializeSuppliers();
    this.refreshGrid(1);
  },
  methods: {
    initializeSuppliers() {
      const members = EnumCategoryDeliveryType.members();
      this.suppliers = Object.keys(members).map(key => ({
        id: members[key].id,
        name: members[key].name,
        disabled: this.shouldDisableSupplier(members[key]),
      }))
          .filter(supplier => !supplier.disabled);
    },
    updateSelectedSupplier(value) {
      this.selectedSupplier = value;
      this.$emit('selected', value);
    },
    shouldDisableSupplier(supplier) {
      return supplier.name !== "MORE" && supplier.name !== "THELIOS" && supplier.name !== "SMILE" && supplier.name !== "KERING";
    },
    callRemovedOnChild() {
      if (this.$refs.optiUploadRef) {
        this.$refs.optiUploadRef.removed();
      }
    },
    downloadFile(id) {
      this.loading.main = true
      API.post(`warehouse/products/selection-import/download`, {
        id: id,
        responseType: 'blob'
      }).then(res => {
        window.open(res.data);
    }).finally(() => {
      this.loading.main = false
    }).catch(() => {
      this.$notify.error('product.product_selection_imports.notify.error.DOWNLOAD');
    })},
    runValidation() {
      this.loading.main = true

      API.post('warehouse/products/selection-import/validate', {
        file: this.file,
        supplier_id: this.selectedSupplier.id,
      }).then(res => {
        if(res.data.success === true) {
          this.$notify.success('product.product_selection_imports.notify.FILE_VALID')
        } else {
          if (res.data.errors && res.data.errors.length > 0) {
            const errorMessages = res.data.errors.map(error => {
              return `Wiersz: ${error.row}, Kolumna: ${error.column}, Problem: ${error.message}`
            });

            const errorMessageString = errorMessages.join('<br>')

            this.$notify.error(res.data.message + errorMessageString, false, true);
          }
        }
      }).finally(() => {
        this.loading.main = false
      }).catch(() => {
        this.$notify.error('product.product_selection_imports.notify.error.VALIDATE')
      })
    },
    refreshGrid(pageNumber) {
      this.loading.main = true
      API.post('warehouse/products/selection-import-table', {
        page: pageNumber,
        pageSize: 10,
        sortBy: "id",
        sortOrder: "desc",
      }).then(res => {
        this.paginator.page = pageNumber
        this.paginator.pages = Math.ceil(res.data.headers.count / res.data.headers.pageSize)
        this.history = res.data.rows
      }).finally(() => {
        this.loading.main = false
      }).catch(() => {
        this.$notify.error('product.product_selection_imports.notify.error.REFRESH')
      })
    },
    runImport() {
      this.loading.main = true

      API.post('warehouse/products/selection-import', {
        file: this.file,
        original_file_name: this.originalFileName,
        supplier_id: this.selectedSupplier.id,
      }).then(res => {

        if(res.data.success) {
          this.$notify.success(res.data.message,false, true)
          this.refreshGrid()
          this.$emit('refreshParent')
          this.callRemovedOnChild()
        } else {
          this.$notify.error(res.data.message, false, true)
        }
      }).finally(() => {
        this.loading.main = false
      }).catch(() => {
        this.$notify.error('product.product_selection_imports.notify.error.IMPORT')
      })
    }
  }
}
</script>