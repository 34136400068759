<template lang="pug">
    MountingPortal(mountTo="#vue-modal" append)
        OptiDialog
            template(#header)
                slot(name="header")

                i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                    @click="$emit('close')")

            template(#body)
                .row(v-if="products.length")
                    .col-md-4.col-sm-6.single-product(v-for="product in products" :key="product.id")
                        OptiProductInfo(
                            :product="product"
                            :department-id="department_id")

                .row(v-else)
                    .col-xs-12.text-center.text-muted
                        i.fa.fa-exclamation-circle.mr3
                        span {{'product.dialog_list_products.NONE'|translate}}

            template(#footer)
                .pull-left
                    OptiPaginator(
                        :paginator="paginator"

                        @changed-page="load")

                    .text-left(style="display:inline-block;font-size:12px")
                        div
                            span.mr3 {{'default.pagination.PER_PAGE'|translate}}
                            b {{paginator.size|number(0)}}
                        div
                            span.mr3 {{'default.pagination.COUNT'|translate}}
                            b {{count|number(0)}}

                OptiButton(type="success" @click.native="$emit('close')") {{'default.OK'|translate}}
                    template(#icon)
                        i.fa.fa-check

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductsListDialog',
        components: {
            OptiDialog,
            OptiProductInfo,
            OptiPaginator,
            OptiButton,
            OptiDimmer
        },
        props: {
            endpoint: {
                type: String,
                required: true
            },
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                products: [],
                count: 0,
                department_id: this.$uac.user.activeDepartmentId,

                paginator: {
                    page: 1,
                    pages: 1,
                    size: 50
                },
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load(page) {
                if(typeof page !== 'undefined') {
                    this.loading = true
                    this.paginator.page = page
                }

                this.params.paginator = this.paginator

                API.post(this.endpoint, this.params).then(res => {
                    this.products = res.data.products
                    this.count = res.data.count
                    this.paginator.pages = res.data.pages
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('product.dialog_list_products.notify.error.LOAD')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 100%;
    }

    .single-product {
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>