<template lang="pug">
    .row
        .col-sm-4.col-sm-offset-8(v-if="office")
            select#select-office.form-control(v-model="office" :disabled="offices.length === 1")
                option(v-for="office in offices" :key="office.id" :value="office") {{office.name}}

        .col-xs-12(v-if="office")
            table#table-agenda.table.table-responsive.middle(v-for="visit in office.visits" :key="visit.id")
                caption(v-show="type === 'week'") {{visit.date.day}} {{visit.date.label}}
                thead
                    tr
                        th.text-right(colspan="2") {{'patient.schedule.agenda.thead.HOURS'|translate}}
                        th {{'patient.schedule.agenda.thead.PATIENT'|translate}}
                        th {{'patient.schedule.agenda.thead.COMMENTS'|translate}}
                        th {{office.type ? 'patient.schedule.agenda.thead.DOCTOR' : 'patient.schedule.agenda.thead.OPTOMETRIST'|translate}}
                        th {{'patient.schedule.agenda.thead.INTERVIEW'|translate}}
                tbody
                    tr(v-for="data in visit.data" :key="data.index" :class="{'free-date': !data.id, 'past': data.past}"
                        @click="openViewDay(data)")
                        td.text-right(style="width:100px") {{data.date}}
                        td.text-right(style="width:30px") {{data.h}}
                        td(v-show="data.id")
                            OptiPatient(v-show="data.patient.object" :data="data.patient.object")
                            span(v-show="!data.patient.object") {{data.patient.first_name}} {{data.patient.last_name}}
                        td(v-show="data.id")
                            .text-success(v-show="!data.past && !data.interview_id && data.confirmed")
                                i.fa.fa-thumbs-up(style="padding-right:3px")
                                span {{'patient.schedule.notify.status.CONFIRMED'|translate}}

                            .warn.flash.animated.infinite(v-show="!data.past && !data.interview_id && !data.confirmed")
                                i.fa.fa-thumbs-down(style="padding-right:3px")
                                span {{'patient.schedule.notify.status.UNCONFIRMED'|translate}}

                            .text-success(v-show="data.interview_id")
                                i.fa.fa-check-circle(style="padding-right:3px")
                                span {{'patient.schedule.notify.status.INTERVIEW'|translate}}

                            .warn(v-show="data.past && !data.interview_id && !data.type")
                                i.fa.fa-exclamation-circle(style="padding-right:3px")
                                span {{'patient.schedule.notify.status.NOINTERVIEW'|translate}}
                                span(style="padding-left:3px" v-show="data.confirmed") ({{'patient.schedule.notify.status.nointerview.CONFIRMED'|translate}})
                                span.flash.animated.infinite(style="padding-left:3px" v-show="!data.confirmed") ({{'patient.schedule.notify.status.nointerview.UNCONFIRMED'|translate}})

                            div(v-html="$options.filters.nl2br(data.comments)")
                        td(v-show="data.id")
                            a.link(:href="$state.href('app.user.edit', {id: data.user.id})")
                                span {{data.user.name}}
                                span(style="padding-left:3px" v-show="!office.type") ({{office.turnovers[data.user.id] ? office.turnovers[data.user.id] : 0}})
                        td(NOWRAP v-show="data.id")
                            a.link(:href="$state.href('app.optometrist.details', {id: data.interview_id, '#': data.type ? 'lens' : 'summary'})"
                                v-show="data.interview_id")
                                span {{data.interview_id}}
                                span(style="padding-left:2px" v-show="data.type") (SK)

                            label.btn.btn-success(:for="'btn-interview-' + data.id"
                                v-show="!data.past && data.patient.object && data.confirmed && !data.interview_id")
                                i.fa.fa-comments-o

                            OptiButton.hide(:id="'btn-interview-' + data.id" text="patient.schedule.patients.actions.interview"
                                @confirmed="createInterview(data.id)")

                            .btn.btn-primary(style="margin-right:5px" v-show="!data.past && !data.patient.object" :title="'patient.schedule.patients.actions.CREATE'|translate"
                                @click.prevent="createPatient(data.id, data.patient)")
                                i.fa.fa-user

                            label.btn.btn-success(:for="'btn-confirm-' + data.id" v-show="!data.past && !data.confirmed && !data.interview_id"
                                :title="'patient.schedule.patients.actions.confirm.BTN'|translate")
                                i.fa.fa-thumbs-up

                            OptiButton.hide(:id="'btn-confirm-' + data.id" text="patient.schedule.patients.actions.confirm"
                                @confirmed="goConfirm(data.id)")

                            i.fa.fa-2x.fa-remove.warn(v-show="data.past && !data.interview_id" :title="'patient.schedule.notify.NOINTERVIEW'|translate")
                        td(colspan="4" v-show="!data.id")
                            i.fa.fa-info-circle(style="padding-right:3px")
                            span {{'patient.schedule.agenda.FREE'|translate}}
                    tr(v-show="!visit.data.length")
                        td.text-center(colspan="6")
                            i.fa.fa-info-circle(style="padding-right:3px")
                            span {{'patient.schedule.notify.EMPTY'|translate}}

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiPatientsScheduleAgenda',
        components: {
            OptiPatient,
            OptiButton
        },
        props: {
            type: {
                type: String,
                required: true
            },
            offices: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                office: this.offices[0]
            }
        },
        mounted() {
            this.parseData()
        },
        methods: {
            parseData() {
                this.offices.forEach(office => {
                    office.visits.forEach(visit => {
                        let date = moment(visit.date)

                        visit.date = {
                            day: date.format('D'),
                            label: date.format('ddd, MMM')
                        }
                    })
                })
            },
            openViewDay(data) {
                if(data.id) {
                    return false
                }

                if(data.past) {
                    this.$notify.error('patient.schedule.notify.error.PAST')
                } else {
                    this.$emit('change-to-view-day', moment(data.end.date))
                }
            },
            createPatient(visit_id, patient) {
                this.$state.go('app.patient.find', {
                    visit: {
                        id: visit_id,
                        first_name: patient.first_name,
                        last_name: patient.last_name,
                        phone: patient.phone
                    }
                })
            },
            createInterview(visit_id) {
                this.$state.go('app.optometrist.create', {
                    id: visit_id
                })
            },
            goConfirm(visit_id) {
                this.loading = true

                API.post('patients/schedule/calendar/confirm', {
                    id : visit_id
                }).then(() => {
                    this.$emit('reload-visits')

                    this.$notify.success('patient.schedule.notify.CONFIRM')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.CONFIRM')
                })
            }
        }
    }
</script>