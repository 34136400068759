<template lang="pug">
    .opti-select-employee
        VueMultiSelect(
            :options="users"
            :value="current"
            :clearable="false"
            label="name"
            :placeholder="'search.user.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            :disabled="disabled"
            ref="select"
            @input="updateSelected")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
            template(slot="option" slot-scope="props")
                .pull-left {{props.option.name}}
                .pull-right.small.muted {{props.option.id}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Employee from '@/js/app/vue/models/Employee'

    import VueMultiSelect from 'vue-multiselect'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSelectEmployeeByDepartment',
        components: {
            VueMultiSelect,
            OptiDimmer
        },
        props: {
            departmentId: {
                type: Number,
                required: true
            },
            reduce: {
                type: Function,
                required: false,
                default: user => user
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                users: [],

                selected: null,

                loading: false
            }
        },
        watch: {
            departmentId() {
                this.loadData()
            }
        },
        async mounted() {
            this.loadData()
        },
        computed: {
            current() {
                if(!this.loading && !this.selected) {
                    let item = this.users.find(user => user.id === this.$uac.user.id)

                    if(item) {
                        this.updateSelected(item)
                    }
                }

                return this.selected
            }
        },
        methods: {
            loadData() {
                if(this.departmentId) {
                    this.loading = true

                    API.get('search/user/by-department/' + this.departmentId).then(res => {
                        this.users = res.data.map(user => new Employee(user))
                        this.users.sort((a, b) => {
                            return a.name > b.name ? 1 : -1
                        })
                    }).finally(() => {
                        this.loading = false

                        this.updateSelected({id: 0})
                    }).catch(e => {
                        console.error(e)
                    })
                }
            },
            updateSelected(user) {
                this.selected = user.id ? user : null

                this.$emit('selected', this.reduce(user))
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
