/*!
 * 
 * Angle - Bootstrap Admin App + AngularJS
 * 
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: http://support.wrapbootstrap.com/knowledge_base/topics/usage-licenses
 * 
 */

import $ from 'jquery'
import angular from 'angular'
import angularDragula from 'angularjs-dragula'

import ngRoute from 'angular-route'
import ngMessages from 'angular-messages'
import uiRouter from 'angular-ui-router'
import ngAnimate from 'angular-animate'
import ngCookies from 'angular-cookies'
import ngFilter from 'angular-filter'
import ngDialog from 'ng-dialog'

import ngSanitize from 'angular-sanitize'
import ngResource from 'angular-resource'
import angularChart from 'angular-chart.js'
import ngInfiniteScroll from 'ng-infinite-scroll'

import './statics'
import {UAC} from '@/js/app/vue/UserAccessControl'
import Logger from './app/vue/helpers/Logger'

if (typeof $ === 'undefined') {
    throw new Error('This application\'s JavaScript requires jQuery')
}

// APP START
// ----------------------------------- 

export let App = angular.module('angle', [
    ngRoute,
    ngMessages,
    ngAnimate,
    'ngStorage',
    ngCookies,
    'ngLocale',
    'pascalprecht.translate',
    'ui.bootstrap',
    uiRouter,
    'cfp.loadingBar',
    ngSanitize,
    ngResource,
    'ui.utils',
    ngFilter,
    'pusher-angular',
    ngDialog,
    'ui.bootstrap-slider',
    angularChart,
    angularDragula(angular),
    //'modernizr',
    'ngWig',
    'angularFileUpload',
    'ui.select',
    'dragularModule',
    ngInfiniteScroll,
])

export function run(Module) {

    Module.run([
        '$http',
        '$rootScope',
        '$state',
        '$stateParams',
        '$window',
        '$templateCache',
        'uac',
        '$cookies',
        '$translate',
        '$interval',
        '$localStorage',
        '$location',
        'EventBus',
        'dashboard',
        'dialogs',
        'VuexStore',
        function ($http, $rootScope, $state, $stateParams, $window, $templateCache, uac, $cookies, $translate, $interval, $localStorage, $location, EventBus, dashboard, dialogs, VuexStore) {

            EventBus.$on('ng:broadcast', ({name, data}) => {
                $rootScope.$broadcast(name, data)
            });
            EventBus.$on('ng:emit', ({name, data}) => {
                $rootScope.$emit(name, data)
            })
            //EventBus.$on('ng:state.go')

            EventBus.$on('opti-table-update-route', ({name, criteria}) => {
                // let name = $state.current.name
                if (!!name) {
                    $state.transitionTo(name, criteria, {notify: false})

                    $localStorage[name + '-search'] = criteria.search
                    $localStorage[name + '-pageSize'] = criteria.pageSize
                    $localStorage[name + '-sortBy'] = criteria.sortBy
                    $localStorage[name + '-sortOrder'] = criteria.sortOrder

                    let search = JSON.stringify(criteria.search)
                    $localStorage[name + '-columns-' + search] = criteria.columns
                }
            })

            $rootScope.uac = UAC

            // brzydki hack
            let tplGet         = $templateCache.get
            $templateCache.get = function (...args) {
                args[0] = args[0].replace(/^\//, '')
                return tplGet(...args)
            }
            window.tpl         = $templateCache

            let checkIfShouldBeBlocked = (block, suppressNotify) => {
                if (block) {
                    if (block.active) {
                        let goodState =
                                $state.includes('app.layout') ||
                                $state.includes('app.optometrists') ||
                                $state.is('page.login')

                        if (!goodState) {
                            $state.go('page.block')
                        }
                    } else {
                        if (!suppressNotify && $state.current.name !== 'app.optometrist.details') {
                            let percent = Math.max(100, 100 + block.timePercent)
                            let text    = `<div style="font-size:${percent}%; text-align:center;line-height:normal"><b>${block.translated}</b><br> do blokady systemu.<br>Uzupełnij puste miejsca w layoutach.</div>`
                            $.notify.closeAll('block', true)
                            $.notify(text, {
                                status: 'warning',
                                pos: 'bottom-left',
                                group: 'block'
                            })
                        }
                    }
                } else {
                    $.notify.closeAll('block', true)
                }
            }
            checkIfShouldBeBlocked(VuexStore.getters['dashboard/get']('block'))

            // Set reference to access them from any scope
            $rootScope.$state       = $state
            $rootScope.$stateParams = $stateParams
            $rootScope.$storage     = $window.localStorage

            $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
                $rootScope.toState       = toState
                $rootScope.toStateParams = toStateParams

                Logger.setGlobal('rout', {
                    toState: toState,
                    toStateParams: toStateParams
                })

                $rootScope.$broadcast('mobile-menu-close')
            })
            //
            //$rootScope.previousState;
            //$rootScope.previousParams;

            $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
                $rootScope.previousState  = from.name
                $rootScope.previousParams = fromParams

                checkIfShouldBeBlocked(VuexStore.getters['dashboard/get']('block'), true)
            })

            // Scope Globals
            // -----------------------------------
            $rootScope.app = {
                name: 'eOpti',
                description: 'System eOpti',
                year: ((new Date()).getFullYear()),
                layout: {
                    isFixed: true,
                    isCollapsed: false,
                    isBoxed: true,
                    isRTL: false,
                    horizontal: false,
                    isFloat: false,
                    asideHover: false,
                    theme: null
                },
                useFullLayout: false,
                hiddenFooter: false,
                viewAnimation: 'ng-fadeInUp'
            }

            $rootScope.user = {}
            //Object.defineProperty($rootScope, 'user', {
            //    get: function () {
            //        return uac.user
            //    }
            //})

            $rootScope.$on('notify', function (event, args) {
                var obj = {
                    status: args.status
                }

                if (angular.isDefined(args.timeout)) {
                    obj['timeout'] = args.timeout
                }

                if (angular.isDefined(args.translate) && !args.translate) {
                    $.notify(args.message, obj)
                } else {
                    $translate(args.message).then(function (res) {
                        $.notify(res, obj)
                    })
                }
            })

            $rootScope.$on('success', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'success'
                    })
                })
            })

            $rootScope.$on('successSticky', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'success',
                        timeout: 0
                    })
                })
            })

            $rootScope.$on('error', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'danger'
                    })
                })
            })

            $rootScope.$on('errorSticky', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'danger',
                        timeout: 0
                    })
                })
            })

            $rootScope.$on('warning', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'warning'
                    })
                })
            })

            $rootScope.$on('warningSticky', function (event, message) {
                $translate(message).then(function (res) {
                    $.notify(res, {
                        status: 'warning',
                        timeout: 0
                    })
                })
            })


            let counter = 0
            let notifyEveryNUpdates = 2
            EventBus.$on('dashboard-updated-block', block => {
                counter++

                if (counter >= notifyEveryNUpdates) {
                    checkIfShouldBeBlocked(block)
                    counter = 0
                }
            })

            window.root = $rootScope
        }

    ])

}

