<template lang="pug">
    .table-responsive
        table.table.table-striped.text-center.middle
            thead
                tr
                    th.text-center(style="padding-top:10px;padding-bottom:10px;border-top:1px solid #aaa" colspan="12"
                        ) {{'optometrist.correction.CORRECTION'|translate|lower}}
                tr
                    th
                        button.btn.btn-info(type="button" :disabled="disabled" @click="dialog = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionNearDialogSurvey(
                                    :interview="interview"

                                    r-visus="near_r_visus"
                                    l-visus="near_l_visus"
                                    bin-visus="near_binvisus"

                                    mode="correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog = false")
                    td SPH
                    td CYL
                    td(v-show="correctionCylNearExists") AXS
                    td Prism
                    td(v-show="correctionPrismNearExists") Angle
                    td Visus
                    td BinVisus
                    td PD/2
                    td PD
            tbody
                tr
                    th {{'prescription.RIGHT'|translate}}
                    td
                        .form-control(disabled) {{interview.near_r_sphere|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_sphere|plus}}
                    td
                        OptiNumberPrescription(:header="$filters.translate('prescription.CYLINDER') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.near_r_cylinder" @confirmed="v => updatePowers('near_r_cylinder', v)"
                            :disabled="disabled") {{interview.near_r_cylinder|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_cylinder|plus}}
                    td(v-show="correctionCylNearExists")
                        OptiNumber(:class="{'its-error': interview.near_r_axis === 'brak'}" v-show="interview.near_r_cylinder"
                            :header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.near_r_axis" @confirmed="v => updatePowers('near_r_axis', v)"
                            :max="180" :disabled="disabled") {{interview.near_r_axis}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_axis}}
                    td
                        OptiNumberPrescription(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.near_r_prd" @confirmed="v => updatePowers('near_r_prd', v)"
                            :minus="false" :disabled="disabled") {{interview.near_r_prd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_prd|number(2)}}
                    td(v-show="correctionPrismNearExists")
                        OptiNumber(v-show="interview.near_r_prd"
                            :header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.near_r_base" @confirmed="v => updatePowers('near_r_base', v)"
                            :max="360" :disabled="disabled") {{interview.near_r_base}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_base}}
                    td
                        span.form-control(disabled) {{interview.near_r_visus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_visus}}
                    td(style="border-bottom:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.near_binvisus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_binvisus}}
                    td
                        OptiNumber(:header="'PD - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.near_r_pd" @confirmed="v => updatePowers('near_r_pd', v)"
                            :float="true" :max="99" :disabled="disabled") {{interview.near_r_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_pd|number(2)}}
                    td(style="border-bottom:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.near_r_pd + interview.near_l_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_r_pd + previous.near_l_pd|number(2)}}
                tr
                    th(style="border-bottom:1px solid #aaa") {{'prescription.LEFT'|translate}}
                    td(style="border-bottom:1px solid #aaa")
                        .form-control(disabled) {{interview.near_l_sphere|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_sphere|plus}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescription(:header="$filters.translate('prescription.CYLINDER') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.near_l_cylinder" @confirmed="v => updatePowers('near_l_cylinder', v)"
                            :disabled="disabled") {{interview.near_l_cylinder|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_cylinder|plus}}
                    td(style="border-bottom:1px solid #aaa" v-show="correctionCylNearExists")
                        OptiNumber(:class="{'its-error': interview.near_l_axis === 'brak'}" v-show="interview.near_l_cylinder"
                            :header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.near_l_axis" @confirmed="v => updatePowers('near_l_axis', v)"
                            :max="180" :disabled="disabled") {{interview.near_l_axis}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_axis}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescription(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.near_l_prd" @confirmed="v => updatePowers('near_l_prd', v)"
                            :minus="false" :disabled="disabled") {{interview.near_l_prd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_prd|number(2)}}
                    td(style="border-bottom:1px solid #aaa" v-show="correctionPrismNearExists")
                        OptiNumber(v-show="interview.near_l_prd"
                            :header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.near_l_base" @confirmed="v => updatePowers('near_l_base', v)"
                            :max="360" :disabled="disabled") {{interview.near_l_base}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_base}}
                    td(style="border-bottom:1px solid #aaa")
                        span.form-control(disabled) {{interview.near_l_visus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_visus}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumber(:header="'PD - ' + $filters.translate('optometrist.acuity.NEAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.near_l_pd" @confirmed="v => updatePowers('near_l_pd', v)"
                            :float="true" :max="99" :disabled="disabled") {{interview.near_l_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.near_l_pd|number(2)}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'

    import OptiOptometristsDetailsRefractionNearDialogSurvey
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/Dialogs/OptiOptometristsDetailsRefractionNearDialogSurvey'

    export default {
        name: 'OptiOptometristsDetailsRefractionNearCorrection',
        components: {
            OptiButton,
            OptiEnum,

            OptiNumber,
            OptiNumberPrescription,

            OptiOptometristsDetailsRefractionNearDialogSurvey
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            previous: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: false
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                this.dialog = false

                this.$emit('update-interview', interview)
            },
            updatePowers(field, value) {
                this.interview[field] = value

                switch(field) {
                    case 'near_r_cylinder':
                        if(!this.interview.near_r_cylinder) {
                            this.interview.near_r_axis = 'brak'
                        }

                        break

                    case 'near_l_cylinder':
                        if(!this.interview.near_l_cylinder) {
                            this.interview.near_l_axis = 'brak'
                        }

                        break

                    case 'near_r_prd':
                        if(!this.interview.near_r_prd) {
                            this.interview.near_r_base = 0
                        }

                        break

                    case 'near_l_prd':
                        if(!this.interview.near_l_prd) {
                            this.interview.near_l_base = 0
                        }
                }

                this.$emit('update-interview', this.interview)
            }
        },
        computed: {
            correctionCylNearExists() {
                return this.interview.near_r_cylinder || this.interview.near_l_cylinder
            },
            correctionPrismNearExists() {
                return this.interview.near_r_prd || this.interview.near_l_prd
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>