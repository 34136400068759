<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-indent.mr3
            span {{'optometrist.autorefraktometr.ocr.TITLE'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-12
                    label(for="autorefraktometr-upload" style="text-align:left") {{'optometrist.autorefraktometr.ocr.MONITOR'|translate}}
                    OptiUpload#autorefraktometr-upload(style="margin-bottom:15px"
                        prefix="autorefraktometr"
                        :picture="true"

                        :file="photo"

                        @update-loading="l => loading.photo = l"
                        @update-file="ocr")

                .col-xs-12.text-center
                    .table-responsive(v-show="result.length === 7")
                        table.table.table-striped.middle
                            thead
                                tr
                                    th.text-center
                                    th.text-center SPH
                                    th.text-center CYL
                                    th.text-center AXS
                                    th.text-center PD
                            tbody
                                tr
                                    th.text-center {{'prescription.RIGHT'|translate}}
                                    td {{result[0]|plus}}
                                    td {{result[1]|plus}}
                                    td {{result[2]}}
                                    td(rowspan="2") {{result[6]}}
                                tr
                                    th.text-center {{'prescription.LEFT'|translate}}
                                    td {{result[3]|plus}}
                                    td {{result[4]|plus}}
                                    td {{result[5]}}

                    .text-muted(style="margin-top:10px" v-show="result.length !== 7")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'optometrist.autorefraktometr.ocr.NONE'|translate}}

                    OptiDimmer(:active="loading.data")

        template.text-right(#footer)
            OptiButton(type="success" :disabled="result.length !== 7"
                @click.native="$emit('data', result, photo)") {{'optometrist.autorefraktometr.ocr.ACCEPT'|translate}}
                template(#icon)
                    i.fa.fa-check
            OptiButton(v-show="photo" @click.native="$emit('photo', photo)"
                ) {{'optometrist.autorefraktometr.ocr.IMAGE'|translate}}
                template(#icon)
                    i.fa.fa-camera
            OptiButton(type="danger" v-show="!photo" @click.native="$emit('closed')"
                ) {{'optometrist.autorefraktometr.ocr.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-camera
</template>

<script>
    import {GoogleVisionAPI} from '@/js/app/vue/components/Optometrists/Details/Refraction/Helpers/GoogleVisionAPI'
    import {OCR} from '@/js/app/vue/components/Optometrists/Details/Refraction/Helpers/OCR'
    import Vue from 'vue'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiOptometristsDetailsRefractionDialogOCR',
        components: {
            OptiDialog,
            OptiButton,
            OptiUpload,
            OptiDimmer
        },
        props: { //TODO: uwzględnić dla OptiUpload
            image: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                photo: this.image,

                result: [],

                loading: {
                    photo: false,
                    data: false
                }
            }
        },
        mounted() {

        },
        methods: {
            getDataUri(targetUrl, callback) {
                let xhr = new XMLHttpRequest()

                xhr.onload = () => {
                    let reader = new FileReader()
                    reader.onloadend = () => {
                        callback(reader.result)
                    }

                    reader.readAsDataURL(xhr.response)
                }

                xhr.open('GET', targetUrl)
                xhr.responseType = 'blob'
                xhr.send()
            },
            ocr(photo) {
                this.photo = photo

                Vue.nextTick(() => {
                    if(this.photo) {
                        this.result = []
                        this.loading.data = true

                        let uri = __env.uploadHost + '/' + this.photo

                        this.getDataUri(uri, base64Img => {
                            GoogleVisionAPI.documentText(base64Img.split(',')[1]).then(ocr => {
                                if(ocr.status === 200) {
                                    if(Object.keys(ocr.data.responses[0]).includes('fullTextAnnotation')) {
                                        this.result = OCR.calculate(ocr.data.responses[0].fullTextAnnotation.text)
                                    } else if(Object.keys(ocr.data.responses[0]).includes('error')) {
                                        console.log(this.$filters.translate('optometrist.autorefraktometr.ocr.notify.error.GOOGLE') +
                                            '\n\r' + ocr.data.responses[0].error.message)

                                        this.$notify.warning('optometrist.autorefraktometr.ocr.notify.error.READ')
                                    }
                                }

                                if(!this.result.length) {
                                    this.$notify.error('optometrist.autorefraktometr.ocr.notify.error.GOOGLE')
                                }

                                this.loading.data = false
                            })
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;

        .form-control[type="file"] { //TODO: usunąć gdy będzie vue upload
            width: inherit;
        }
    }
</style>