<template lang="pug">
    .panel.panel-default(style="position:relative")
        OptiDimmer(:active="loading")
        .panel-heading
            span.bold Orientacyjny kalkulator premii pracownika
            .pull-right(style="max-width:50%;font-weight:normal" v-if="userId")
                OptiSwitchToggleButton(style="padding-right:10px" :checked="currentQuarter" @input="updateQuarter")
                    template(#left) Poprzedni Kwartał
                    template(#right) Aktualny Kwartał
            .pull-right(v-if="visible")
                button.btn.btn-primary(type="button" style="margin-right:5px" @click="exportBonus") Eksport do Excela
            .clearfix
        .info(v-show="!userId") Wybierz użytkownika żeby wyświetlić dane

        div(v-if="rangesData" style="overflow-x:auto")
            table.table.table-condensed.table-striped.table-bordered.table-custom-border.middle
                thead
                    tr
                        th(rowspan="3") Tygodnie
                        th.text-center(colspan="7") Narastająco od {{ startWeek }}
                        th.text-center.b-left.b-top.b-right(colspan="3") Należne kwoty za dany okres - kwoty brutto
                    tr
                        th.text-right(rowspan="2") Obrót/h
                        th.text-center(colspan="2") Osiągnięto/Skor.
                        th.text-center(colspan="2" @click="toggleBonusTable") Stawka %
                        th.text-right(rowspan="2") Premia od osiąg/skor.
                        th.text-right(rowspan="2") Premia z soczewek
                        th.text-right.b-left(rowspan="2") Premia osiąg/skor.
                        th.text-right(rowspan="2") Premia soczewki
                        th.text-right.b-right.bg-separator(rowspan="2") Premia razem
                    tr
                        th.text-center(@click="toggleBonusTable") prace
                        th.text-center(@click="toggleBonusTable") sprzedaż
                        th.text-center(@click="toggleBonusTable") prace
                        th.text-center(@click="toggleBonusTable") sprzedaż
                tbody(style="white-space:nowrap" v-if="rangesData")
                    tr(v-for="range in ranges")
                        td
                            TextRangeWeek(:ranges="[ranges[4].ranges[0], range.ranges[1]]")
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{ range.all|currency }}
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{ range.budget.tasks|currency }}
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{ range.budget.sales|currency }}
                        td.text-right.monospace(:class="{ forecast: range.forecast }" @click="toggleBonusTable") {{range.bonus.percent_task|number(2)}}%
                        td.text-right.monospace(:class="{ forecast: range.forecast }" @click="toggleBonusTable") {{range.bonus.percent_sale|number(2)}}%
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{range.bonus.value|currency }}
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{ sumLenses(range.lenses)|currency }}
                        td.text-right.monospace.b-left(:class="{ forecast: range.forecast }") {{ range.bonus.payout|currency }}
                        td.text-right.monospace(:class="{ forecast: range.forecast }") {{ range.bonus.lenses.payout|currency }}
                        td.text-right.monospace.b-right.bg-separator(:class="{ forecast: range.forecast }") {{ range.bonus.payout + range.bonus.lenses.payout|currency }}
                tfoot(v-if="rangesData")
                    tr
                        td.text-right(colspan="8") ∑
                        td.text-right.monospace.text-muted.b-left.b-bottom *{{ totals.payout|currency }}
                        td.text-right.monospace.text-muted.b-bottom *{{ totals.lenses|currency }}
                        td.text-right.monospace.text-muted.b-right.b-bottom.text-bold.bg-separator *{{ totals.payout + totals.lenses|currency }}
            .panel-body
                .text-right
                    .text-muted * - prognoza zawiera budżet skorygowany/prognozowany który może ulec zmianie.
            .panel-body
                .pull-left(v-if="totals.lenses")
                    button(type="button" class="btn btn-primary" :class="{'btn-warning active': lensesVisible}"
                        @click="toggleLenses") Premie soczewek
                .pull-right
                    button(type="button" class="btn btn-primary"  :class="{'btn-warning active': bonusTableVisible}"
                        @click="toggleBonusTable") Progi premiowe
                table.table.table-condensed.table-striped.table-bordered.table-xs(v-if="lensesVisible")
                        caption.text-center
                            TextRangeWeek(:ranges="[ranges[4].ranges[0], ranges[13].ranges[1]]")
                        thead
                            tr
                                th.text-right Id pracy
                                th.text-right Id recepty
                                th.text-right Id wywiadu
                                th.text-right Daty (przyjęcie/wywiad)
                                th.text-right Wartość pracy
                                th.text-right Wartość pracy po rabacie
                                th.text-right Rabat pracy
                                th.text-right Wartość soczewek
                                th.text-right Proporcjonalny rabat soczewek
                                th.text-right Wartość soczewek po rabacie
                                th.text-right Premia %
                                th.text-right Premia brutto
                        tbody
                            tr(v-for="task in ranges[13].lenses")
                                td.text-right {{ task.taskId }}
                                td.text-right {{ task.prescriptionId }}
                                td.text-right {{ task.interviewId }}
                                td.text-right
                                    div {{ task.taskDate }}
                                    div {{ task.interviewDate }}
                                td.text-right {{ task.taskPriceWithoutDiscount|currency }}
                                td.text-right {{ task.taskPrice|currency }}
                                td.text-right {{ task.taskDiscount|currency }}
                                td.text-right {{ task.lensesPrice|currency }}
                                td.text-right {{ (task.lensesPercent * task.taskDiscount)|currency }}
                                td.text-right {{ task.lensesPriceWithDiscount|currency }}
                                td.text-right {{ task.bonusPercent|currency }}
                                td.text-right {{ task.bonusLenses|currency }}
                            tr
                                td.text-right(colspan="7")
                                    div ∑
                                    div Śr
                                td.text-right
                                    div {{ ranges[13].bonus.lenses.lensesPrice.sum|currency }}
                                    div {{ ranges[13].bonus.lenses.lensesPrice.avg|currency }}
                                td.text-right(colspan="2") {{ ranges[13].bonus.lenses.lensesPriceWithDiscount|currency }}
                                td.text-right(colspan="2") {{sumLenses(ranges[13].lenses)|currency}}

        BonusShow2(
            :departmentId="departmentId"

            :bonusTableVisible="bonusTableVisible"
            :currentQuarter="currentQuarter")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import TextRangeWeek from '@/js/app/vue/components/Text/TextRangeWeek'
    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import BonusShow2 from '@/js/app/vue/components/Bonus/BonusShow2'

    export default {
        name: 'OptiTableEmployeeBonus',
        components: {OptiDimmer, OptiSwitchToggleButton, TextRangeWeek, BonusShow2},
        props: {
            departmentId: {
                type: Number,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            currentQuarter: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                rangesData: null,
                lensesVisible: false,
                bonusTableVisible: false,
                loading: false
            }
        },
        computed: {
            ranges() {
                if (!this.rangesData) {
                    return {}
                }

                let ranges = {}

                ranges[4]  = this.rangesData[4]
                ranges[8]  = this.rangesData[8]
                ranges[13] = this.rangesData[13]

                return ranges
            },
            totals() {
                if(this.rangesData) {
                    return this.rangesData.total
                }

            },
            startWeek() {
                if(!this.rangesData) {
                    return ''
                }

                let date = moment(this.ranges[4].ranges[0]),
                    week = parseInt(date.format('W')),
                    quarter = Math.min(parseInt(week / 13) + Math.ceil(week % 13), 4)

                return `${date.format('W')} \W ${date.format('GG')} / Q${quarter}`
            },
            visible() {
                return this.$uac.user.activeRole.admin || this.$uac.user.activeRole.id === 16
            }
        },
        watch: {
            userId() {
                this.loadData()
            }
        },
        methods: {
            updateUser(userId) {
                this.userId = userId

                this.loadData()
            },
            updateQuarter(current) {
                this.$emit('update-current-quarter', current)

                this.$nextTick(() => {
                    this.loadData()
                })
            },
            async loadData() {
                if(this.userId) {
                    try {
                        this.loading = true

                        let quarter = BudgetHelper.getQuarterByWeek(moment()),
                            year = moment().isoWeekYear()

                        if(!this.currentQuarter) {
                            quarter--
                            if(quarter <= 0) {
                                quarter = 4
                                year--
                            }
                        }

                        let request = await API.post('schedule/employee/bonus', {
                            quarter: quarter,
                            year: year,
                            departmentId: this.departmentId,
                            userId: this.userId
                        }, API.id('employee-bonus'))

                        this.rangesData = request.data
                    } catch (e) {
                        this.rangesData = false
                        console.log(e)
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.rangesData = false
                }
            },
            sumLenses(lenses) {
                return lenses.reduce((prev, curr) => {
                    return prev + curr.bonusLenses
                }, 0)
            },
            toggleLenses() {
                this.lensesVisible = !this.lensesVisible
            },
            toggleBonusTable() {
                this.bonusTableVisible = !this.bonusTableVisible
            },
            async exportBonus() {
                let quarter = BudgetHelper.getQuarterByWeek(moment()),
                    year = moment().isoWeekYear()

                if (!this.currentQuarter) {
                    quarter--
                    if (quarter <= 0) {
                        quarter = 4
                        year--
                    }
                }

                try {
                    let {data} = await API.post('schedule/employee/bonusExport', {
                        departmentId: this.departmentId,
                        quarter: quarter,
                        year: year
                    })

                    EventBus.$emitAndApply('ng:emit', {
                        name: 'downloadable',
                        data: {
                            name: data.filename,
                            status: true
                        }
                    })

                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>

<style scoped>
    .forecast::before {
        content: '*';
    }

    .forecast, .forecast-no-star {
        color: #909FA7;
    }

    .table-xs {
        font-size: 12px;
    }

    .table-custom-border /deep/ .b-left {
        border-left: 2px solid black !important;
    }
    .table-custom-border /deep/ .b-top {
        border-top: 2px solid black !important;
    }
    .table-custom-border /deep/ .b-right {
        border-right: 2px solid black !important;
    }
    .table-custom-border /deep/ .b-bottom {
        border-bottom: 2px solid black !important;
    }
    .info {
        text-align: center;
        font-size: 21px;
        padding: 15px;
    }
</style>
