<template lang="pug">
    li.permission(v-if="!items.id") {{items.name}}
        small(style="display:block" v-show="items.desc") {{items.desc}}
        div(v-show="items.items")
            ul.permissions(v-for="items in items.items")
                OptiRoleAssignList(:items="items")

    li.permission(v-else)
        .checkbox.c-checkbox.checkbox-primary.inline
            label
                input(type="checkbox" :id="'per-' + items.id" v-model="items.active" :disabled="disabled")
                span.fa.fa-check
                label(:for="'per-' + items.id") {{items.name}}
        label.description(style="display:block" :for="'per-' + items.id")
            small {{items.lang|translate}}
</template>

<script>
    export default {
        name: 'OptiRoleAssignList',
        props: {
            items: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                disabled: parseInt(this.$state.params.id) === 1
            }
        }
    }
</script>