import Vue from 'vue'

export const EventBus = new Vue({
    methods: {
        async $emitAndApply(name, data) {
            await this.$emit(name, data)

            if (window.root) {
                window.root.$apply()
            }
        }
    }
})
