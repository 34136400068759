import actions from '@/js/app/vue/store/offer/actions'
import mutations from '@/js/app/vue/store/offer/mutations'
import getters from '@/js/app/vue/store/offer/getters'
import helper from '@/js/app/vue/store/offer/helper'

export default {
    namespaced: true,

    state: helper.getDefaultState(),

    actions: actions,
    mutations: mutations,
    getters: getters
}