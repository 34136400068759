<template lang="pug">
    .row
        .col-xs-12
            div
                .text-center(style="cursor:pointer; padding-bottom: 10px" @click="show = !show" :class="{ 'show-animation': chartData && chartData.datasets }")
                    span(style="margin-right: 10px" v-if="chartData && chartData.datasets")
                        i.fa(:class="{'fa-arrow-up': !show, 'fa-arrow-down': show}")
                    span {{ 'catalog.product.ava_chart.HEADING' | translate }}
                    span(style="margin-left: 10px" v-if="chartData && chartData.datasets")
                        i.fa(:class="{'fa-arrow-up': !show, 'fa-arrow-down': show}")


            .panel-body(v-if="chartData && chartData.datasets && show" style="padding: 0")
                BarChart(
                    :chart-data="chartData"
                    :options="options"
                    :styles="styles"
                    ref="chart"
                )
                OptiChartTooltip(
                    :model="check",
                    :chart-data="chartData",
                    :chart="$refs.chart"
                )
                    template(#default="{body}")
                        .panel.panel-default
                            .panel-heading
                                span.text-center(style="font-size:18px; display:block;") {{body.tooltip.title[0]}}
                                span.text-center.text-muted(style="display:block;") {{ translateDate(body.tooltip.body[0].lines[0].start, body.tooltip.title[0]) }}

                            .panel-body
                                table.table-condensed
                                    tbody
                                        template
                                            tr
                                                td.text-center {{ body.tooltip.body[0].lines[0].label }}:
                                                th.text-center {{ body.tooltip.body[0].lines[0].availability }}
                                            tr
                                                td.text-center Status SMILE:
                                                th.text-center {{ body.tooltip.body[0].lines[0].status }}

            .row(v-if="!(chartData && chartData.datasets)")
                .col-xs-12.text-center.text-muted(style="padding-bottom: 5px")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.table.EMPTY'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import BarChart from '@/js/app/vue/components/Chart/Base/BarChart'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
    import OptiChartTooltip from '@/js/app/vue/components/Chart/Base/OptiChartTooltip.vue'

    export default {
        name: 'OptiProductAvailabilityChart',
        components: {OptiChartTooltip, OptiDimmer, BarChart},
        props: {
            selectionId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                chartData: null,
                loading: false,
                check: null,
                show: false,
                styles: {
                    height: '350px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: -10,
                                suggestedMax: 150
                            }
                        }]
                    },
                    tooltips: {
                        backgroundColor: 'rgba(189,184,173)',
                        mode: 'index',
                        intersect: true,
                        callbacks: {
                            label(tooltipItems, data) {
                                return {
                                    label: data.datasets[tooltipItems.datasetIndex].label,
                                    availability: data.datasets[tooltipItems.datasetIndex]['data'][tooltipItems.index],
                                    status: data.datasets[tooltipItems.datasetIndex]['status'][tooltipItems.index],
                                    heading: data.labels[tooltipItems.index],
                                    start: data.datasets[tooltipItems.datasetIndex]['start'][tooltipItems.index],
                                }
                            },
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: 'black',
                            rotation: -45,
                            labels: {
                                status: {
                                    font: {
                                        size: 20
                                    },
                                    offset: 15,
                                    formatter: (value, context) => context.dataset.status[context.dataIndex]
                                },
                                quantity: {
                                    offset: 1,
                                    formatter: (value, context) => context.dataset.data[context.dataIndex]
                                }
                            }
                        }
                    }
                }
            };
        },
        mounted() {
            this.startUp()
        },
        methods: {
            startUp() {
                this.loading = true

                API.get('warehouse/product/availabilityData/' + this.selectionId).then(res => {
                    this.chartData = res.data.chartData
                }).catch(() => {

                }).finally(() => {
                    this.loading = false
                })
            },
            translateDate(date, check) {
                let start = moment(date),
                    endDate = start.clone().endOf('week'),
                    month = start.format('MMMM')
                if(endDate.format('MMMM') !== month) {
                    return `${start.format('D MMMM')} - ${endDate.format('D MMMM Y')}`
                }

                return `${start.format('D')} - ${endDate.format('D MMMM Y')}`

            },
        }
    }
</script>

<style scoped lang="less">
.show-animation {
    animation-name: show-animation;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 7;
}

@keyframes show-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
</style>