<template lang="pug">
    .row(style="font-size:15px")
        OptiTaskDetailsSummaryTurnBackInfo(
            :task="task"

            @summary-was-updated="$emit('summary-was-updated')")

        OptiTaskDetailsSummaryBasicData(
            :task="task"
            :rate-save="rateSave"
            :is-offer="!!offer"

            :task-duplicate="taskDuplicate"
            :task-service="taskService"

            :block="block"
            :allow-edit-confirmed="allowEditConfirmed"

            @summary-was-updated="$emit('summary-was-updated')"
            @rate-save="$emit('rate-save')")

        OptiTaskDetailsSummaryPowers(
            :task="task")

        OptiTaskDetailsSummaryMeasurement(
            :task="task"
            :is-offer="!!offer"

            :increment="increment")

        OptiTaskDetailsSummaryFrameLensPayment(
            :task="task"
            :is-offer="!!offer"

            :saturation-translate="saturationTranslate"
            :task-extra-us-info="taskExtraUsInfo"
            :discount-percent-with-shipping="discountPercentWithShipping"
            :promotions="promotions"
            :notice="notice"

            :block="block"

            @summary-was-updated="$emit('summary-was-updated')")

        OptiTaskDetailsSummaryCommentsReferences(
            :task="task"
            :is-offer="!!offer"

            :block="block"
            :allow-edit-confirmed="allowEditConfirmed"

            @summary-was-updated="$emit('summary-was-updated')")

        OptiTaskDetailsSummaryActions(
            :task="task"
            :is-offer="!!offer"

            :valid="valid"

            :block="block"

            :offer="offer"

            @set-active="card => $emit('set-active', card)"
            @go-done="$emit('go-done')"
            @summary-was-updated="$emit('summary-was-updated')")
</template>

<script>
    import OptiTaskDetailsSummaryTurnBackInfo
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryTurnBackInfo'
    import OptiTaskDetailsSummaryBasicData
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryBasicData'
    import OptiTaskDetailsSummaryPowers
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryPowers'
    import OptiTaskDetailsSummaryMeasurement
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryMeasurement'
    import OptiTaskDetailsSummaryFrameLensPayment
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryFrameLensPayment'
    import OptiTaskDetailsSummaryCommentsReferences
        from '@/js/app/vue/components/Tasks/Details/Summary/OptiTaskDetailsSummaryCommentsReferences'
    import OptiTaskDetailsSummaryActions
        from '@/js/app/vue/components/Tasks/Details/Summary/Actions/OptiTaskDetailsSummaryActions'

    export default {
        name: 'OptiTaskDetailsSummary',
        components: {
            OptiTaskDetailsSummaryTurnBackInfo,
            OptiTaskDetailsSummaryBasicData,
            OptiTaskDetailsSummaryPowers,
            OptiTaskDetailsSummaryMeasurement,
            OptiTaskDetailsSummaryFrameLensPayment,
            OptiTaskDetailsSummaryCommentsReferences,
            OptiTaskDetailsSummaryActions
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            rateSave: {
                required: true
            },
            offer: {
                required: true
            },

            taskDuplicate: {
                type: Boolean,
                required: true
            },
            taskService: {
                type: Boolean,
                required: true
            },
            increment: {
                type: Number,
                required: true
            },
            saturationTranslate: {
                type: Object,
                required: true
            },
            taskExtraUsInfo: {
                type: Boolean,
                required: true
            },
            discountPercentWithShipping: {
                type: Boolean,
                required: true
            },
            promotions: {
                type: Array,
                required: true
            },
            notice: {
                type: Object,
                required: true
            },
            valid: {
                type: Object,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            allowEditConfirmed: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
