import { render, staticRenderFns } from "./OptiTaskDetailsSummaryFrameLensPayment.vue?vue&type=template&id=272534fb&scoped=true&lang=pug&"
import script from "./OptiTaskDetailsSummaryFrameLensPayment.vue?vue&type=script&lang=js&"
export * from "./OptiTaskDetailsSummaryFrameLensPayment.vue?vue&type=script&lang=js&"
import style0 from "./OptiTaskDetailsSummaryFrameLensPayment.vue?vue&type=style&index=0&id=272534fb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272534fb",
  null
  
)

export default component.exports