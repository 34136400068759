import { render, staticRenderFns } from "./OptiTaskDetailsLensesPageCatalog.vue?vue&type=template&id=384a93af&scoped=true&lang=pug&"
import script from "./OptiTaskDetailsLensesPageCatalog.vue?vue&type=script&lang=js&"
export * from "./OptiTaskDetailsLensesPageCatalog.vue?vue&type=script&lang=js&"
import style0 from "./OptiTaskDetailsLensesPageCatalog.vue?vue&type=style&index=0&id=384a93af&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384a93af",
  null
  
)

export default component.exports