<template lang="pug">
    .row
        .col-xs-12
            OptiOptometristsDescriptionsMaddox(:maddox="interview.maddox" :result="maddox" :heading="true" :icon="true")
            OptiOptometristsDescriptionsWorth(:id="interview.worth_id" :heading="true" :icon="true")
            OptiOptometristsDescriptionsStereo(:id="interview.stereo" :heading="true" :icon="true")
            .table-responsive
                table.table.table-striped.middle(style="margin-bottom:0")
                    tbody
                        tr(v-show="interview.amplitude_distance && interview.amplitude_distance !== 0")
                            td(colspan="3")
                                i.fa.fa-navicon.mr3
                                span {{'optometrist.correction.ACOMODATIONAMPLITUDE'|translate}}
                            td {{'optometrist.result.CORRECTION'|translate}} - {{amplitude|number(2)}} dptr
                            td.text-right
                                i.fa.fa-2x.fa-check.text-success(v-show="amplitude_ok")
                                i.fa.fa-2x.fa-remove.warn(v-show="!amplitude_ok")
        .col-xs-12
            .table-responsive
                table.table.table-striped(style="margin-bottom:0")
                    tbody
                        tr
                            td
                                i.fa.fa-navicon.mr3
                                span.mr3 {{'optometrist.correction.BINOCULAR'|translate}} -
                                OptiEnum(:class="{'warn': !interview.binocular_vision}"
                                    enum="InterviewBinocularVision" :id="interview.binocular_vision")
</template>

<script>


    import OptiOptometristsDescriptionsMaddox
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsMaddox'
    import OptiOptometristsDescriptionsWorth
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsWorth'
    import OptiOptometristsDescriptionsStereo
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsStereo'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'OptiOptometristsDetailsSummaryScreeningTests',
        components: {
            OptiOptometristsDescriptionsMaddox,
            OptiOptometristsDescriptionsWorth,
            OptiOptometristsDescriptionsStereo,

            OptiEnum
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            maddox: {
                type: Object,
                required: true
            },
            age: {
                type: Number,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            amplitude() {
                if(this.interview.amplitude_distance) {
                    return 100 / this.interview.amplitude_distance + this.interview.dist_r_add + this.interview.amplitude_uncorrected
                }

                return 0
            },
            amplitude_ok() {
                return 15 - 0.25 * this.age <= this.amplitude && this.amplitude <= 25 - 0.5 * this.age
            }
        }
    }
</script>