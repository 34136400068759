<template lang="pug">
    .row
        div(:class="{'col-xs-12': !match.control_visit, 'col-xs-11 col-xs-offset-1': match.control_visit}")
            .panel(:class="{'panel-success': mark !== match.interview.id, 'panel-warning': mark === match.interview.id}")
                .panel-heading(style="cursor:pointer" @click="toggle.main = !toggle.main")
                    .pull-left
                        i.fa.fa-navicon.mr3
                        span.mr3(v-show="match.control_visit") {{'optometrist.contactlens.controlvisit.HEADING'|translate}}
                        span.mr3(v-show="!match.control_visit") {{'optometrist.contactlens.controlvisit.MATCHING'|translate}} \#{{match.interview.id}}
                        span - {{match.interview.date|noYearSek}}

                    .pull-right
                        span.text-shadow.text-success(v-show="match.matching === 1")
                            i.fa.fa-check.mr3
                            span {{'optometrist.contactlens.finish.type.MATCHING'|translate}}
                        span.text-shadow.warn(v-show="match.matching === 2")
                            i.fa.fa-remove.mr3
                            span {{'optometrist.contactlens.finish.type.UNMATCHING'|translate}}
                        i.fa(:class="{'fa-arrow-down': !toggle.main, 'fa-arrow-up': toggle.main}")

                    .clearfix

                .panel-body(style="padding:0" v-show="match.r_product_order || match.l_product_order")
                    .row
                        .col-xs-12
                            table.table.table-striped.text-center.middle
                                thead
                                    tr
                                        th(colspan="2")
                                        th SPH
                                        th(v-show="match.toric") CYL
                                        th(v-show="match.toric") AXS
                                tbody
                                    tr(v-show="match.r_product_order")
                                        th {{'prescription.RIGHT'|translate}}
                                        td
                                            span(v-show="match.r_product_order") {{match.r_product_name}}
                                        td {{powers.r_sph|plus}}
                                        td(v-show="match.toric") {{powers.r_cyl|plus}}
                                        td(v-show="match.toric") {{powers.r_axs}}
                                    tr(v-show="match.l_product_order")
                                        th {{'prescription.LEFT'|translate}}
                                        td
                                            span(v-show="match.l_product_order") {{match.l_product_name}}
                                        td {{powers.l_sph|plus}}
                                        td(v-show="match.toric") {{powers.l_cyl|plus}}
                                        td(v-show="match.toric") {{powers.l_axs}}

                .panel-body(style="padding:0" v-show="toggle.main")
                    .table-responsive
                        table.table.table-striped.middle
                            tbody
                                tr
                                    td.bold(style="white-space:nowrap")
                                        i.fa.fa-navicon.mr3
                                        span {{'optometrist.contactlens.questionnaire.HEADING'|translate}}
                                    td
                                        button.btn.btn-info(type="button" style="margin-left:10px" v-show="osdi !== -1"
                                            :class="{'btn-warning active': toggle.answers}" @click="toggle.answers = !toggle.answers"
                                            ) {{'optometrist.contactlens.questionnaire.ANSWERS'|translate}} {{osdi|number(1)|points}}
                                    td(v-show="osdi !== -1")
                                        span(v-show="osdi > 40") {{'optometrist.contactlens.questionnaire.result.FAIL'|translate}}
                                        span(v-show="osdi <= 40") {{'optometrist.contactlens.questionnaire.result.OK'|translate}}
                                    td.text-right(v-show="osdi !== -1")
                                        i.fa.fa-2x.fa-remove.warn(v-show="osdi > 40")
                                        i.fa.fa-2x.fa-check.text-success(v-show="osdi <= 40")
                                tr(v-show="toggle.answers")
                                    td(colspan="3")
                                        ul(style="list-style-type:none;padding-left:0px")
                                            li(style="padding-top:5px" v-for="questionnaire in match.questionnaire"
                                                v-show="questionnaire.pivot.option")
                                                span.mr3 {{questionnaire.pl}} -
                                                OptiEnum(style="font-style:italic;text-transform:lowercase"
                                                    enum="InterviewContactLensQuestionnaireOption"
                                                    :id="questionnaire.pivot.option")

                    .table-responsive
                        table.table.table-striped.middle
                            thead
                                tr
                                    th(colspan="2")
                                        i.fa.fa-navicon.mr3
                                        span {{'optometrist.contactlens.efron.HEADING'|translate}}
                            tbody
                                tr
                                    td.text-center.bold(style="width:100px") {{'prescription.RIGHT'|translate}}
                                    td(style="padding:0")
                                        table.table.table-striped.middle
                                            tbody
                                                tr(v-for="efron in match.interview.efron" v-show="efron.pivot.r > 0")
                                                    td(style="width:280px") {{efron.name}}
                                                    td {{efron.pivot.r}}
                                                    td.text-center
                                                        OptiButtonEfronCompare(
                                                            :efron="match.interview.efron" side="r" :efron-id="efron.id"
                                                            :disabled="!efron.pivot.r_photo")
                                        .warn.bold(v-show="match.interview.efron[0].pivot.r === -1") {{'optometrist.contactlens.efron.NOSURVEY'|translate}}
                                        div(style="display:flex;align-items:center" v-show="!efron('r')")
                                            .form-control-static.text-success.bold(style="float:left;display:inline-block;width:30%") {{'optometrist.contactlens.efron.NOPROBLEM'|translate}}
                                            a(style="margin-left:5px" v-if="match.interview.contactLens.efron_r_image"
                                                :href="match.interview.contactLens.efron_r_image|upload" target="_blank")
                                                img.img-rounded(style="width:100px" :src="match.interview.contactLens.efron_r_image|upload")
                                tr
                                    td.text-center.bold(style="width:100px") {{'prescription.LEFT'|translate}}
                                    td(style="padding:0")
                                        table.table.table-striped.middle
                                            tbody
                                                tr(v-for="efron in match.interview.efron" v-show="efron.pivot.l > 0")
                                                    td(style="width:280px") {{efron.name}}
                                                    td {{efron.pivot.l}}
                                                    td.text-center
                                                        OptiButtonEfronCompare(
                                                            :efron="match.interview.efron" side="l" :efron-id="efron.id"
                                                            :disabled="!efron.pivot.l_photo")
                                        .warn.bold(v-show="match.interview.efron[0].pivot.l === -1") {{'optometrist.contactlens.efron.NOSURVEY'|translate}}
                                        div(style="display:flex;align-items:center" v-show="!efron('l')")
                                            .form-control-static.text-success.bold(style="float:left;display:inline-block;width:30%") {{'optometrist.contactlens.efron.NOPROBLEM'|translate}}
                                            a(style="margin-left:5px" v-if="match.interview.contactLens.efron_l_image"
                                                :href="match.interview.contactLens.efron_l_image|upload" target="_blank")
                                                img.img-rounded(style="width:100px" :src="match.interview.contactLens.efron_l_image|upload")

                    .table-responsive
                        table.table.table-striped.middle
                            thead
                                tr
                                    th(colspan="5")
                                        i.fa.fa-navicon.mr3
                                        span {{'optometrist.contactlens.film.HEADING'|translate}}
                                tr.text-center
                                    th
                                    th {{'optometrist.contactlens.film.QUALITY'|translate}}
                                    th {{'optometrist.contactlens.film.HEIGHT'|translate}}
                                    th {{'optometrist.contactlens.film.TIME'|translate}}
                                    th {{'optometrist.contactlens.film.VOLUME'|translate}}
                            tbody.text-center
                                tr
                                    th.text-center {{'prescription.RIGHT'|translate}}
                                    td
                                        OptiEnum(enum="InterviewContactLensQuality" :id="match.r_quality")
                                    td
                                        OptiEnum(enum="InterviewContactLensHeight" :id="match.r_height")
                                    td
                                        OptiEnum(enum="InterviewContactLensTime" :id="match.r_time")
                                    td
                                        OptiEnum(enum="InterviewContactLensVolume" :id="match.r_volume")
                                tr
                                    th.text-center {{'prescription.LEFT'|translate}}
                                    td
                                        OptiEnum(enum="InterviewContactLensQuality" :id="match.l_quality")
                                    td
                                        OptiEnum(enum="InterviewContactLensHeight" :id="match.l_height")
                                    td
                                        OptiEnum(enum="InterviewContactLensTime" :id="match.l_time")
                                    td
                                        OptiEnum(enum="InterviewContactLensVolume" :id="match.l_volume")

                    .table-responsive
                        table.table.table-striped.middle
                            thead
                                tr
                                    th(colspan="5")
                                        i.fa.fa-navicon.mr3
                                        span {{'optometrist.contactlens.disease.HEADING'|translate}}
                            tbody.text-center
                                tr
                                    td(style="padding:0" colspan="5")
                                        .row(style="margin-left:0;margin-right:0")
                                            .col-sm-6
                                                .table-responsive
                                                    table.table.table-striped.middle
                                                        tbody
                                                            tr
                                                                td.text-center.bold(style="border-top:0;width:100px")  {{'prescription.RIGHT'|translate}}
                                                                td(style="border-top:0")
                                                                    ul(style="list-style-type:none;padding-left:0")
                                                                        li(v-for="disease in diseases('r')") {{disease}}

                                                                    .text-success.bold(v-show="!diseases('r').length") {{'optometrist.contactlens.efron.NOPROBLEM'|translate}}
                                                                td(style="border-top:0")
                                                                    a(style="margin-left:5px" v-if="match.disease_r_image"
                                                                        :href="match.disease_r_image|upload" target="_blank")
                                                                        img.img-rounded(style="width:100px" :src="match.disease_r_image|upload")
                                                            tr
                                                                td.text-center.bold(style="width:100px") {{'prescription.LEFT'|translate}}
                                                                td
                                                                    ul(style="list-style-type:none;padding-left:0")
                                                                        li(v-for="disease in diseases('l')") {{disease}}

                                                                    .text-success.bold(v-show="!diseases('l').length") {{'optometrist.contactlens.efron.NOPROBLEM'|translate}}
                                                                td
                                                                    a(style="margin-left:5px" v-if="match.disease_l_image"
                                                                        :href="match.disease_l_image|upload" target="_blank")
                                                                        img.img-rounded(style="width:100px" :src="match.disease_l_image|upload")
                                            .col-sm-6.text-center
                                                img(style="width:300px" src="img/optometrist/screening-test/contact-lens/eye-map.jpg")
                                                .form-group(style="text-align:left" v-show="match.disease_comments")
                                                    label.control-label.col-xs-12 {{'optometrist.contactlens.disease.COMMENTS'|translate}}
                                                    .col-xs-12(style="margin-bottom:10px") {{match.disease_comments}}

                    .table-responsive
                        table.table.table-striped.middle
                            thead
                                tr
                                    th(colspan="5")
                                        i.fa.fa-navicon.mr3
                                        span {{'optometrist.contactlens.lens.HEADING'|translate}}
                                tr.text-center
                                    th
                                    th {{'optometrist.contactlens.matching.CENTRATION'|translate}}
                                    th {{'optometrist.contactlens.matching.MOVABLE'|translate}}
                                    th(v-show="match.toric") {{'optometrist.contactlens.matching.ROTATION'|translate}}
                                    th {{'optometrist.contactlens.matching.COVER'|translate}}
                            tbody.text-center
                                tr
                                    th.text-center {{'prescription.RIGHT'|translate}}
                                    td
                                        OptiEnum(enum="InterviewContactLensCentration" :id="match.r_centration")
                                    td
                                        OptiEnum(enum="InterviewContactLensMovable" :id="match.r_movable")
                                    td(v-show="match.toric")
                                        OptiEnum(enum="InterviewContactLensRotation" :id="match.r_rotation")
                                    td
                                        OptiEnum(enum="InterviewContactLensCover" :id="match.r_cover")
                                tr
                                    th.text-center {{'prescription.LEFT'|translate}}
                                    td
                                        OptiEnum(enum="InterviewContactLensCentration" :id="match.l_centration")
                                    td
                                        OptiEnum(enum="InterviewContactLensMovable" :id="match.l_movable")
                                    td(v-show="match.toric")
                                        OptiEnum(enum="InterviewContactLensRotation" :id="match.l_rotation")
                                    td
                                        OptiEnum(enum="InterviewContactLensCover" :id="match.l_cover")

                    .table-responsive(v-show="match.comfort_rating > -1")
                        table.table.table-striped.middle
                            thead
                                th(style="padding-left:5px")
                                    i.fa.fa-navicon.mr3
                                    span {{'optometrist.contactlens.comfort.HEADING'|translate}} - {{match.comfort_rating}}

                    .table-responsive
                        table.table.table-striped.middle
                            tbody
                                td.text-right(style="padding:5px 10px")
                                    OptiButton(type="info" @click.native="$state.go('app.optometrist.details', {id: match.interview.id})"
                                        ) {{'optometrist.contactlens.matching.LINK'|translate}}
                                        template(#icon)
                                            i.fa.fa-eye
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonEfronCompare from '@/js/app/vue/components/Optometrists/Details/Lens/Efron/OptiButtonEfronCompare'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    export default {
        name: 'OptiPatientsDetailsPrescriptionsMatching',
        components: {
            OptiButton,
            OptiButtonEfronCompare,
            OptiEnum
        },
        props: {
            match: {
                type: Object,
                required: true
            },
            
            mark: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                toggle: {
                    main: false,
                    answers: false
                },

                osdi: -1
            }
        },
        mounted() {
            this.points()
        },
        methods: {
            points() {
                let points = 0,
                    checked = true

                this.match.questionnaire.forEach(questionnaire => {
                    if(questionnaire.pivot.option !== -1) {
                        points += questionnaire.pivot.option
                    } else {
                        checked = false
                    }
                })

                this.osdi = checked ? points * 2.083333 : -1
            },

            efron(side) {
                let points = 0

                this.match.interview.efron.forEach(efron => {
                    points += efron.pivot[side]
                })

                return points
            },

            diseases(side) {
                let _return = []

                this.match.interview.contactLensDiseases.forEach(disease => {
                    let zone = []

                    _.range(1, 9 + 1).forEach(i => {
                        if(disease.pivot[side + '_zone_' + i]) {
                            zone.push(i)
                        }
                    })

                    if(zone.length) {
                        _return.push(disease.name + ' - ' + zone.join(', '))
                    }
                })

                return _return
            }
        },
        computed: {
            powers() {
                let r_type = null,
                    l_type = null

                if(this.match.r_type === 2) {
                    r_type = 'near'
                } else if(this.match.r_type === 3) {
                    r_type = 'dist'
                }

                if(this.match.l_type === 2) {
                    l_type = 'near'
                } else if(this.match.l_type === 3) {
                    l_type = 'dist'
                }

                return {
                    toric: this.match.toric,

                    r_sph: r_type ? this.match.powers[r_type + '_r_sph'] : 0,
                    r_cyl: r_type ? this.match.powers[r_type + '_r_cyl'] : 0,
                    r_axs: r_type ? this.match.powers[r_type + '_r_axs'] : 0,

                    l_sph: l_type ? this.match.powers[l_type + '_l_sph'] : 0,
                    l_cyl: l_type ? this.match.powers[l_type + '_l_cyl'] : 0,
                    l_axs: l_type ? this.match.powers[l_type + '_l_axs'] : 0
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .text-shadow {
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        margin-right: 3px;
    }
</style>