class MaddoxClass {
    constructor() {

    }

    check(maddox, side) {
        let i = 0

        _.forEach(['v', 't', 'b', 'h', 'l', 'r'], position => {
            if (maddox[side + '_' + position]) {
                ++i
            }
        })

        return i === 2
    }

    getX(maddox, side) {
        if(maddox[side + '_r']) {
            return maddox[side + '_r']
        } else if (maddox[side + '_l']) {
            return maddox[side + '_l'] * -1
        }

        return 0
    }
    getY(maddox, side) {
        if(maddox[side + '_b']) {
            return maddox[side + '_b']
        } else if (maddox[side + '_t']) {
            return maddox[side + '_t'] * -1
        }

        return 0
    }

    prism(x, y) {
        return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    }
    angle(x, y) {
        let radian = Math.atan2(y, x),
            angle = (radian > 0 ? radian : (2 * Math.PI + radian)) * 360 / (2 * Math.PI)

        return angle === 360 ? 0 : angle
    }

    reset(maddox) {
        _.forEach(['r', 'l'], side => {
            maddox[side + '_b'] = 0
            maddox[side + '_h'] = false
            maddox[side + '_l'] = 0
            maddox[side + '_r'] = 0
            maddox[side + '_suppression'] = false
            maddox[side + '_t'] = 0
            maddox[side + '_v'] = false
        })
    }

    compute(m, r) {
        if(m.l_b || m.l_h || m.l_l || m.l_l || m.l_r || m.l_t || m.l_v ||
            m.r_b || m.r_h || m.r_l || m.r_l || m.r_r || m.r_t || m.r_v) {
            m.r_suppression = false
            m.l_suppression = false
        }

        _.forEach(['r', 'l'], side => {
            if(this.check(m, side)) {
                let x = this.getX(m, side),
                    y = this.getY(m, side)

                r[side].prism = this.prism(x, y)
                r[side].angle = this.angle(x, y)
            } else {
                r[side].prism = 0
                r[side].angle = 0
            }
        })

        r.selected = []
        _.forEach(m, (m, column) => {
            if(column.length === 3 && m > 0) {
                r.selected.push(column)
            }
        })
    }
}

export const Maddox = new MaddoxClass()