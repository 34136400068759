<template lang="pug">
    .opti-select-brand
        VueMultiSelect(
            id="category_price_group"
            :options="category_price_groups"
            :value="categoryPriceGroup"
            :clearable="false"
            label="name"
            :placeholder="'search.category_price_group.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            :loading="loading"
            :disabled="disabled"

            @input="updateSelected"
            @search-change="loadData")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option }}
            template(slot="option" slot-scope="props")
                span {{ props.option }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectCategoryPriceGroup',
        components: {
            VueMultiSelect
        },
        props: {
            objectKey: {
                type: Number,
                required: true
            },
            categoryPriceGroup: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                required: false,
                default : false
            }
        },
        data() {
            return {
                category_price_groups: [],

                queue: null,
                loading: false
            }
        },
        methods: {
            async loadData(data) {
                clearTimeout(this.queue)

                if (data.length >= 3) {
                    this.loading = true

                    this.queue = setTimeout(() => {
                        API.post('search/category-price-groups', {
                            search: data
                        }).then(res => {
                            this.category_price_groups = res.data
                        }).finally(() => {
                            this.loading = false
                        }).catch(() => {
                            this.$notify.error('search.category_price_group.notify.error.LOAD')
                        })
                    }, 2000)
                }
            },
            updateSelected(category_price_group) {
                this.$emit('selected', this.objectKey, category_price_group)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
