<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right #
                    th {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th {{'optometrist.history.table.head.SUMMARY'|translate}}
                    th(v-show="edit") {{'optometrist.history.table.head.TIME'|translate}}
            tbody
                tr(v-for="(log, index) in survey.data")
                    td.text-right {{index + 1}}
                    td {{log.description}}
                    td(style="padding:0")
                        OptiOptometristsDescriptionsMaddox(v-if="log.parsed.maddox"
                            :maddox="log.parsed.maddox" :result="log.parsed.result" :no-result="true")
                    td.text-right(style="border-left:1px solid #aaa" v-show="edit")
                        OptiOptometristsDetailsSurveyHistoryTime(:action="log.action"
                            :scope="log.time_scope" :diff="log.time_diff")
</template>

<script>
    import OptiOptometristsDetailsSurveyHistoryTime
        from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSurveyHistoryTime'
    import OptiOptometristsDescriptionsMaddox
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsMaddox'

    export default {
        name: 'OptiOptometristsDetailsSurveyHistoryMaddox',
        components: {
            OptiOptometristsDetailsSurveyHistoryTime,
            OptiOptometristsDescriptionsMaddox
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('optometrist.survey_history_time')
            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }
</script>