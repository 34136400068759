<template lang="pug">
    #settings-offer-difference
        .row(v-if="!loading")
            .col-xs-12
                OptiSettingsOfferDifferenceSession(
                    :state="difference.session.state"

                    @refresh="load")

            .col-xs-6
                h4.page-header
                    i.fa.fa-language.mr3
                    span {{'settings.offer.difference.translations.HEADER'|translate}}
            .col-xs-6.text-right
                OptiButton(@click.native="$state.go('app.settings-offer.translations')") {{'settings.offer.difference.translations.BTN_EDIT'|translate}}
                    template(#icon)
                        i.fa.fa-edit
            .col-xs-12.panel-offer-difference
                .row
                    .col-xs-12(v-if="difference.translations.length")
                        .panel-offer-difference-row(v-for="translation in difference.translations")
                            .panel-offer-difference-row-header {{'settings.offer.translations.card.' + translation.name.toUpperCase()|translate}}
                            .panel-offer-difference-row-description.bg-separator
                                .warnings.warn(v-show="translation.difference.no_translate.length")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'settings.offer.difference.translations.difference.NO_TRANSLATE'|translate}}
                                    span
                                        .btn-offer-attribute(v-for="no_translate in translation.difference.no_translate"
                                            @click="openDialog('translations', translation.name, no_translate)")
                                            span.text-animated-alert(:title="no_translate.name") {{no_translate.name}}
                                    span .

                                .warnings.warn(v-show="translation.difference.no_active.length")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'settings.offer.difference.translations.difference.NO_ACTIVE'|translate}}
                                    span
                                        .btn-offer-attribute(v-for="no_active in translation.difference.no_active"
                                            @click="openDialog('translations', translation.name, no_active)")
                                            span.text-animated-alert(:title="no_active.name") {{no_active.name}}
                                    span .

                    .col-xs-12.text-center.text-success(v-else)
                        i.fa.fa-exclamation-circle.mr3
                        span {{'settings.offer.difference.NO_DIFFERENCE'|translate}}

            .col-xs-6
                h4.page-header
                    i.fa.fa-female.mr3
                    span {{'settings.offer.difference.styles.HEADER'|translate}}
            .col-xs-12.panel-offer-difference
                .row
                    .col-xs-12(v-if="difference.styles.length")
                        .panel-offer-difference-row
                            .panel-offer-difference-row-description.bg-separator
                                .warnings.warn
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'settings.offer.difference.styles.DIFFERENCE'|translate}}
                                    span
                                        .btn-offer-attribute(v-for="missing_style_product_id in difference.styles")
                                            a.text-animated-alert(:title="missing_style_product_id"
                                                :href="$state.href('app.catalog.product', {id: missing_style_product_id})"
                                                target="_blank") {{missing_style_product_id}}
                                    span .

                    .col-xs-12.text-center.text-success(v-else)
                        i.fa.fa-check-circle.mr3
                        span {{'settings.offer.difference.styles.NO_DIFFERENCE'|translate}}

            .col-xs-6
                h4.page-header
                    i.fa.fa-pencil.mr3
                    span {{'settings.offer.difference.questions.HEADER'|translate}}
            .col-xs-6.text-right
                OptiButton(@click.native="$state.go('app.settings-offer.questions-edit')") {{'settings.offer.difference.questions.BTN_EDIT'|translate}}
                    template(#icon)
                        i.fa.fa-edit
            .col-xs-12.panel-offer-difference
                .row
                    .col-xs-12(v-if="difference.questions.length")
                        .panel-offer-difference-row(v-for="question in difference.questions")
                            .panel-offer-difference-row-header {{'catalog.offer.questions.' + question.name + '.HEADING'|translate}}
                            .panel-offer-difference-row-description.bg-separator
                                OptiSettingsOfferDifferenceQuestion(:question="question"
                                    @open-dialog="d => openDialog('questions', question.name, d)")

                    .col-xs-12.text-center.text-success(v-else)
                        i.fa.fa-check-circle.mr3
                        span {{'settings.offer.difference.NO_DIFFERENCE'|translate}}

            OptiProductsListDialog(v-if="dialog.data"
                :endpoint="dialog.meta.endpoint"
                :params="dialog.data"

                @close="Object.keys(dialog.meta).forEach(k => dialog.meta[k] = null); dialog.data = null")

                template(#header)
                    i.fa.mr3(:class="'fa-' + dialog.meta.icon")
                    span.mr30 {{dialog.meta.header}}

                    span(v-if="dialog.section === 'translations' && ['gender', 'shapes'].includes(dialog.type)")
                        span.mr30 Id - {{dialog.data.id}}
                        span.mr30 {{'settings.offer.translations.attributes.table.NAME'|translate}} - {{dialog.data.name}}
                        span.mr30
                            span.mr3 {{'settings.offer.translations.attributes.table.TRANSLATION'|translate}} -
                            span(v-if="dialog.data.description") {{dialog.data.description}}
                            span.warn(v-else) {{'default.NA'|translate|lower}}

                    span(v-else-if="dialog.section === 'translations' && dialog.type === 'frames_colors'")
                        span.mr30 Id - {{dialog.data.id}}
                        span.mr30 {{'settings.offer.translations.colors.table.NAME'|translate}} - {{dialog.data.name}}
                        span.mr30
                            span.mr3 {{'settings.offer.translations.colors.table.CODE'|translate}} -
                            span(v-if="dialog.data.code") {{dialog.data.code}}
                            span.warn(v-else) {{'default.NA'|translate|lower}}
                        span.mr30
                            span.mr3 {{'settings.offer.translations.colors.table.TRANSLATION'|translate}} -
                            span(v-if="dialog.data.description") {{dialog.data.description}}
                            span.warn(v-else) {{'default.NA'|translate|lower}}

                    span(v-else-if="dialog.section === 'translations' && dialog.type === 'language_versions'")
                        span.mr30 {{'settings.offer.translations.language_versions.SECTION'|translate}} - {{dialog.data.type_name|translate}}
                        span.mr30 Id - {{dialog.data.id}}
                        span.mr30 {{'settings.offer.translations.language_versions.table.PL'|translate}} - {{dialog.data.pl}}
                        span.mr30
                            span.mr3 {{'settings.offer.translations.language_versions.table.ENG'|translate}} -
                            span(v-if="dialog.data.eng") {{dialog.data.eng}}
                            span.warn(v-else) {{'default.NA'|translate|lower}}

                        span.text-muted {{'settings.offer.translations.language_versions.DIALOG_INFO'|translate}}

                    span(v-else-if="dialog.section === 'styles'")
                        span.mr3 {{'catalog.breadcrumb.CATALOG'|translate}} -
                        span {{dialog.data.name}}

                    span(v-else-if="dialog.section === 'questions'")
                        span
                            span.mr3 {{'settings.offer.questions.products.ATTRIBUTE_TYPE'|translate}}
                            span.bold {{'catalog.offer.questions.attributes.' + dialog.data.attribute_ids[0]|translate}}

                        span.ml30
                            span.mr3 {{'settings.offer.questions.products.NAME'|translate}}
                            span.bold(:class="{'warn': !dialog.data.value}") {{dialog.data.value ? dialog.data.value : $filters.translate('settings.offer.questions.difference.NO_TRANSLATE')}}
        .row(v-else)
            .col-xs-12
                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSettingsOfferDifferenceSession
        from '@/js/app/vue/components/Settings/Offer/OptiSettingsOfferDifferenceSession'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSettingsOfferDifferenceQuestion
        from '@/js/app/vue/components/Settings/Offer/OptiSettingsOfferDifferenceQuestion'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsOfferDifference',
        components: {
            OptiSettingsOfferDifferenceSession,
            OptiButton,
            OptiSettingsOfferDifferenceQuestion,
            OptiProductsListDialog,
            OptiDimmer
        },
        data() {
            return {
                difference: [],

                dialog: {
                    meta: {
                        section: null,
                        type: null,
                        endpoint: null,
                        icon: null,
                        header: null
                    },
                    data: null
                },
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load(timeout) {
                this.loading = true

                setTimeout(() => {
                    API.get('settings/offer/differences').then(res => {
                        this.difference = res.data
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('settings.offer.difference.notify.error.LOAD')
                    })
                }, typeof timeout === 'undefined' ? 0 : timeout)
            },
            openDialog(section, type, data) {
                let endpoint = {
                    translations: {
                        gender: 'settings/offer/translations/attributes/get-products',
                        shapes: 'settings/offer/translations/attributes/get-products',
                        frames_colors: 'settings/offer/translations/colors/get-products',
                        language_versions: 'settings/offer/translations/language-versions/get-products'
                    },
                    styles: 'offer/settings/differences/products',
                    questions: 'settings/offer/questions/products'
                }, icons = {
                    translations: 'language',
                    styles: 'female',
                    questions: 'pencil'
                }

                this.dialog.section = section
                this.dialog.type = type

                if(typeof endpoint[section] === 'object') {
                    this.dialog.meta.endpoint = endpoint[section][type]
                } else {
                    this.dialog.meta.endpoint = endpoint[section]
                }

                this.dialog.meta.icon = icons[section]
                this.dialog.meta.header = this.$filters.translate('settings.offer.difference.' + section + '.HEADER')

                if(section === 'translations') {
                    this.dialog.meta.header += ' - ' + this.$filters.translate('settings.offer.translations.card.' + type.toUpperCase())
                } else if(section === 'questions') {
                    this.dialog.meta.header += ' - ' + this.$filters.translate('catalog.offer.questions.' + type + '.HEADING')
                }

                this.dialog.data = data
            }
        }
    }
</script>

<style lang="less" scoped>
    h4.page-header {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .panel-offer-difference {
        &:not(:last-child) {
            margin-bottom: 50px;
        }

        .panel-offer-difference-row {
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            > .panel-offer-difference-row-header {
                padding: 2px 5px;
                text-align: center;
                font-size: 16px;
                font-weight: 700;
            }

            > .panel-offer-difference-row-description {
                padding: 10px 5px;
            }
        }
    }
</style>