<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span Hoya iLog

        .panel-body
            .row.text-center
                .col-sm-6(style="padding:10px 0")
                    OptiButton(type="primary" text="regeneration.iLog.eye.right.confirm"
                        @confirmed="dialog = 'right'") {{'regeneration.iLog.eye.right.HEADING'|translate}}
                        template(#icon)
                            i.fa.fa-eye

                .col-sm-6(style="padding:10px 0")
                    OptiButton(type="primary" text="regeneration.iLog.eye.left.confirm"
                        @confirmed="dialog = 'left'") {{'regeneration.iLog.eye.left.HEADING'|translate}}
                        template(#icon)
                            i.fa.fa-eye

                MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                    OptiRegenerationDetailsHoyaiLogDialog(
                        :regeneration-id="regenerationId"
                        :task-id="taskId"
                        :side="dialog"

                        @load="$emit('load')"
                        @close="dialog = ''")
</template>

<script>
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiReceipt from '@/js/app/vue/components/Register/Receipt/OptiReceipt'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiRegenerationDetailsHoyaiLogDialog
        from '@/js/app/vue/components/Regenerations/Details/iLog/OptiRegenerationDetailsHoyaiLogDialog'

    export default {
        name: 'OptiRegenerationDetailsHoyaiLog',
        components: {
            OptiPayment,
            OptiReceipt,
            OptiButton,
            OptiRegenerationDetailsHoyaiLogDialog
        },
        props: {
            regenerationId: {
                type: Number,
                required: true
            },
            taskId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                dialog: ''
            }
        }
    }
</script>
