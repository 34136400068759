<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-user-md.mr3
            span {{'prescription.DOCTOR'|translate}}

        .panel-body
            .form-group
                label.col-md-4.col-sm-3.control-label
                .col-md-8.col-sm-9
                    OptiSelectEnum(enum="PrescriptionDoctorType" :disabled="prescription.source === 2"
                        :value="doctorType" @update-enum="updateDoctorType")

            .form-group
                label.col-md-4.col-sm-3.control-label(for="doctorName") {{'prescription.DOCTOR'|translate}}
                .col-md-8.col-sm-9
                    select#doctorName.form-control(:disabled="doctorType === 0 || prescription.source === 2"
                        v-model="prescription.doctorId" @change="updateDoctor")
                        option(v-for="doctor in doctors" :value="doctor.id") {{doctor.firstName}} {{doctor.lastName}}
            .form-group
                label.col-md-4.col-sm-3.control-label(for="doctorFirstName") {{'prescription.DOCTORFIRSTNAME'|translate}}
                .col-md-8.col-sm-9
                    input#doctorFirstName.form-control(:disabled="doctorType === 1 || prescription.source === 2"
                        v-model="prescription.doctorFirstName")
            .form-group
                label.col-md-4.col-sm-3.control-label(for="doctorLastName") {{'prescription.DOCTORLASTNAME'|translate}}
                .col-md-8.col-sm-9
                    input#doctorLastName.form-control(:disabled="doctorType === 1 || prescription.source === 2"
                        v-model="prescription.doctorLastName")
            .form-group
                label.col-md-4.col-sm-3.control-label(for="doctorNpwz") {{'prescription.DOCTORNPWZ'|translate}}
                .col-md-8.col-sm-9
                    input#doctorNpwz.form-control(:disabled="doctorType === 1 || prescription.source === 2"
                        v-model="prescription.doctorNpwz")
            .form-group
                label.col-md-4.col-sm-3.control-label(for="doctorContact") {{'prescription.DOCTORCONTACT'|translate}}
                .col-md-8.col-sm-9
                    input#doctorContact.form-control(:disabled="doctorType === 1 || prescription.source === 2"
                        v-model="prescription.doctorContact")

        OptiDimmer(:active="loading.doctors")
</template>

<script>
    import {Storage} from '@/js/app/vue/helpers/Storage'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsPrescriptionDoctor',
        components: {
            OptiSelectEnum,
            OptiDimmer

        },
        props: {
            prescription: {
                type: Object,
                required: true
            },
            doctorType: {
                type: Number,
                required: true
            },
            loading: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                doctors: []
            }
        },
        mounted() {
            Storage.get('doctors').then(doctors => {
                this.doctors = doctors

                this.loading.doctors = false
            }).catch(() => {
                this.$notify.error('prescription.notify.ERRORDOCTORS')

                this.loading.doctors = false
            })
        },
        methods: {
            updateDoctorType(doctor_type) {
                this.$emit('update-doctor-type', doctor_type)

                this.prescription.doctorId = 0

                this.prescription.doctorFirstName = ''
                this.prescription.doctorLastName = ''
                this.prescription.doctorNpwz = ''
                this.prescription.doctorContact = ''
            },
            updateDoctor() {
                let selected = {}

                this.doctors.forEach(doctor => {
                    if(this.prescription.doctorId === doctor.id) {
                        selected = doctor
                    }
                })

                this.prescription.doctorFirstName = selected.firstName
                this.prescription.doctorLastName = selected.lastName
                this.prescription.doctorNpwz = ''
                this.prescription.doctorContact = selected.cellphone ? selected.cellphone : ''
            }
        }
    }
</script>
