<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'catalog.product.report.tasksale.HEADING'|translate}}

        .row
            .col-md-6
                .table-responsive
                    table.table.table-striped.middle
                        caption.bg-separator.text-expressive.text-center
                            i.icon.icon-eyeglasses.mr3
                            span.mr3 {{'catalog.product.report.tasksale.TASKS'|translate}}
                            span.badge(v-show="tasks.length") {{tasks.length}}
                            small(style="display:block") {{'catalog.product.report.tasksale.TASKSLEGEND'|translate}}
                        thead
                            tr
                                th.text-right {{'catalog.product.report.ID'|translate}}
                                th {{'catalog.product.report.DATE'|translate}}
                                th {{'catalog.product.report.VALUE'|translate}}
                                th {{'catalog.product.report.CREATEDBY'|translate}}
                        tbody
                            tr(v-for="task in tasks.slice(0, all.tasks ? tasks.length : limit)")
                                td.text-right
                                    a.link(v-if="!hide" :href="$state.href('app.task.detail', {id: task.id})" target="_blank") {{task.id}}
                                    span(v-else) {{task.id}}
                                td {{task.date}}
                                td {{task.value|currency}}
                                td {{task.created_by}}

                            tr(v-show="!tasks.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot(v-show="tasks.length > limit")
                            tr
                                td.text-center(colspan="7")
                                    a.link(v-show="!all.tasks" @click="all.tasks = true") {{'default.table.show.ALL'|translate}}
                                    a.link(v-show="all.tasks" @click="all.tasks = false") {{'default.table.show.ONLY'|translate}} {{limit}} {{'default.table.show.ROWS'|translate}}

            .col-md-6
                .table-responsive
                    table.table.table-striped.middle
                        caption.bg-separator.text-expressive.text-center
                            i.fa.fa-shopping-cart.mr3
                            span.mr3 {{'catalog.product.report.tasksale.SALES'|translate}}
                            span.badge(v-show="sales.length") {{salesCount}}
                        thead
                            tr
                                th.text-right {{'catalog.product.report.ID'|translate}}
                                th {{'catalog.product.report.DATE'|translate}}
                                th {{'catalog.product.report.VALUE'|translate}}
                                th {{'catalog.product.report.CREATEDBY'|translate}}
                        tbody
                            tr(v-for="sale in sales.slice(0, all.sales ? sales.length : limit)")
                                td.text-right
                                    a.link(v-if="!hide" :href="$state.href('app.sale.details', {id: sale.id})" target="_blank") {{sale.id}}
                                    span(v-else) {{sale.id}}
                                td {{sale.date}}
                                td {{sale.value|currency}}
                                td {{sale.created_by}}

                            tr(v-show="!sales.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot(v-show="sales.length > limit")
                            tr
                                td.text-center(colspan="7")
                                    a.link(v-show="!all.sales" @click="all.sales = true") {{'default.table.show.ALL'|translate}}
                                    a.link(v-show="all.sales" @click="all.sales = false") {{'default.table.show.ONLY'|translate}} {{limit}} {{'default.table.show.ROWS'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductDetailsReportTaskSale',
        components: {
            OptiDimmer
        },
        data() {
            return {
                tasks: [],
                sales: [],
                salesCount: 0,

                limit: 5,
                all: {
                    tasks: false,
                    sales: false
                },

                hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/product/' + this.$state.params.id + '/report-tasks-sales', API.id(Math.random())).then(res => {
                this.tasks = res.data.tasks
                this.sales = res.data.sales
                this.sales.forEach(sale => {
                    if(sale.value > 0) {
                      ++this.salesCount
                    }
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.product.report.tasksale.notify.error.LOAD')
            })
        }
    }
</script>