export function prescriptionUpdatePrism(prescription, prism, side, type, field, value) {
    // sposób liczenia taki sam jak w HoyaEDIHelper.php

    if(typeof value !== 'undefined') {
        prism[side][type][field] = value
    }

    if(prescription.prism_mode) {
        prism.r.horizontal.side = prescription.rePrismAngle > 90 && prescription.rePrismAngle < 270 ? 1 : 0
        prism.r.vertical.side = prescription.rePrismAngle > 180 ? 3 : 2

        prism.l.horizontal.side = prescription.lePrismAngle < 90 || prescription.lePrismAngle > 270 ? 1 : 0
        prism.l.vertical.side = prescription.lePrismAngle > 180 ? 3 : 2

        prism.r.horizontal.power = Math.round(Math.abs(Math.cos(prescription.rePrismAngle * Math.PI / 180) * prescription.rePrism) * 100) / 100
        prism.r.vertical.power = Math.round(Math.abs(Math.sin(prescription.rePrismAngle * Math.PI / 180) * prescription.rePrism) * 100) / 100

        prism.l.horizontal.power = Math.round(Math.abs(Math.cos(prescription.lePrismAngle * Math.PI / 180) * prescription.lePrism) * 100) / 100
        prism.l.vertical.power = Math.round(Math.abs(Math.sin(prescription.lePrismAngle * Math.PI / 180) * prescription.lePrism) * 100) / 100
    } else {
        prescription.rePrism = Math.round(Math.sqrt(Math.pow(prism.r.horizontal.power, 2) + Math.pow(prism.r.vertical.power, 2)) * 100) / 100
        prescription.lePrism = Math.round(Math.sqrt(Math.pow(prism.l.horizontal.power, 2) + Math.pow(prism.l.vertical.power, 2)) * 100) / 100

        prescription.rePrismAngle = Math.round(Math.atan2(prism.r.vertical.power * (prism.r.vertical.side === 2 ? 1 : -1),
            prism.r.horizontal.power * (prism.r.horizontal.side === 0 ? 1 : -1)) * 180 / Math.PI)
        if(prescription.rePrismAngle < 0) {
            prescription.rePrismAngle += 360
        }

        prescription.lePrismAngle = Math.round(Math.atan2(prism.l.vertical.power * (prism.l.vertical.side === 2 ? 1 : -1),
            prism.l.horizontal.power * (prism.l.horizontal.side === 0 ? -1 : 1)) * 180 / Math.PI)
        if(prescription.lePrismAngle < 0) {
            prescription.lePrismAngle += 360
        }
    }
}

export function initPrescriptionPrism(prescription, prism) {
    let prism_mode = prescription.prism_mode
    prescription.prism_mode = true

    prescriptionUpdatePrism(prescription, prism)

    prescription.prism_mode = prism_mode
}