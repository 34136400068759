import { render, staticRenderFns } from "./OptiDialogPrescription.vue?vue&type=template&id=6f18b197&scoped=true&lang=pug&"
import script from "./OptiDialogPrescription.vue?vue&type=script&lang=js&"
export * from "./OptiDialogPrescription.vue?vue&type=script&lang=js&"
import style0 from "./OptiDialogPrescription.vue?vue&type=style&index=0&id=6f18b197&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f18b197",
  null
  
)

export default component.exports