<template lang="pug">
    OptiButton(:type="measurementApp === 2 ? 'pink' : 'info'"
        :disabled="disabled" @click.native="open = true")
        span.mr3 {{'default.MEASUREMENT'|translate}}
        span(v-show="measurementApp === 2") JawroVisu
        template(#icon)
            i.fa.fa-download

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogMeasurementJawroVisu(
                :patient-id="patientId"
                :measurement-app="measurementApp"

                :measurement-id="measurementId"

                @update-measurement="updateMeasurement"
                @closed="open = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogMeasurementJawroVisu
        from '@/js/app/vue/components/PreviousLenses/Measurement/Dialogs/OptiDialogMeasurementJawroVisu'

    export default {
        name: 'OptiButtonMeasurementJawroVisu',
        components: {
            OptiButton,
            OptiDialogMeasurementJawroVisu
        },
        props: {
            patientId: {
                type: Number,
                required: true
            },
            measurementApp: {
                type: Number,
                required: true
            },

            measurementId: {
                type: Number,
                required: false
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            updateMeasurement(measurement) {
                this.$emit('update-measurement', measurement)

                this.open = false
            }
        }
    }
</script>
