<template lang="pug">
    .shipment-notice.inline
        div(v-for="r in result")
            a.link.mr3(:href="'#/app/warehouse/shipment/' + r.id" :title="'shipments.SHIPMENTTITLE'|translate") {{r.id}}
            small(v-show="r.waybill")
                a.link(:title="'shipments.COURIERIDTITLE'|translate" :href="r.waybill_link + r.waybill" target="_blank"
                    ) {{r.waybill}}
            small(style="display:block;color:#656565")
                span.mr3 {{r.sender}}
                i.fa.fa-arrow-right.mr3
                span {{r.receiver}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiShipmentNotice',
        components: {

        },
        props: {
            type: {
                type: Number,
                required: true
            },
            id: {
                required: true
            }
        },
        asyncComputed: {
            result: {
                async get() {
                    try {
                        let {data} = await API.post('warehouse/shipment/directive', {
                            type: this.type,
                            id: this.id
                        })

                        this.$emit('shipment-exists', !!data.length)

                        return data
                    } catch(e) {
                        this.$notify.error('shipments.notify.error.DIRECTIVE')
                    }
                },
                lazy: true,
                shouldUpdate() {
                    return typeof this.id === 'number'
                }
            }
        }
    }
</script>
