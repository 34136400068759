<template lang="pug">
    OptiDialog
        template(#header)
            i.icon.icon-eyeglasses.mr3
            span {{'catalog.offer.choice.byClient.HEADER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$store.commit('offer/setDialog', {type: 'by_client', bool: false})")

        template(#body)
            OptiSelectProduct(
                :product="product"
                :department-id="departmentId"

                @selected="p => product = p")

            OptiProductInfo(style="margin-top:30px" v-if="product.id"
                :product="product"
                :department-id="departmentId")

        template(#footer)
            OptiButton.pull-left(@click.native="$store.commit('offer/setDialog', {type: 'by_client', bool: false})"
                ) {{'default.CLOSE'|translate}}
                template(#icon)
                    i.fa.fa-close

            OptiButton(type="success" :disabled="!product.id"
                @click.native="$store.dispatch('offer/saveProductByClient', product)") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogOfferDialogChoiceByClient',
        components: {
            OptiDialog,
            OptiSelectProduct,
            OptiProductInfo,
            OptiButton
        },
        data() {
            return {
                product: {}
            }
        },
        computed: {
            departmentId() {
                return this.$store.getters['offer/getDepartmentId']
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;

        > .modal-body {
            overflow: unset !important;
        }
    }
</style>