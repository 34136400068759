<template lang="pug">
    .row
        .col-xs-12
            .table-responsive(v-if="!loading")
                table.table.table-striped.middle
                    thead
                        tr.bg-separator
                            th.text-right {{'transfers.lock.table.ID'|translate}}
                            th {{'transfers.lock.table.NAME'|translate}}
                            th {{'transfers.lock.table.LOCK'|translate}}
                            th {{'transfers.lock.table.TEXT'|translate}}

                    tbody
                        tr(v-for="department in departments")
                            td.text-right {{department.id}}
                            td {{department.name}}
                            td
                                .text-danger(v-if="department.lock")
                                    i.fa.fa-remove.mr3
                                    span {{'default.bool.YES'|translate}}
                                    span.text-warning.star(v-if="Object.keys(department.available_product_ids).length")
                                .text-success(v-else)
                                    i.fa.fa-check.mr3
                                    span {{'default.bool.NO'|translate}}
                            td
                                .text-lock-alert(v-for="alert in department.alerts")
                                    .header(v-html="alert.header")
                                    .text(v-html="alert.text")

                        tr(v-show="!departments.length")
                            td.text-muted.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

                    tfoot(v-show="is_possible")
                        tr
                            td(colspan="100")
                                span.text-warning.star.mr3
                                span.text-muted {{'transfers.lock.rules.WARNING'|translate}}

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTransfersDepartmentsLock',
        components: {
            OptiDimmer
        },
        data() {
            return {
                departments: [],

                loading: true
            }
        },
        mounted() {
            API.get('transfers/departments/lock').then(res => {
                this.departments = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('transfers.lock.notify.error.DEPARTMENTS_LOAD')
            })
        },
        computed: {
            is_possible() {
                return !!this.departments.filter(d => d.lock && Object.keys(d.available_product_ids).length).length
            }
        }
    }
</script>

<style lang="less" scoped>
    .star:after {
        content: '*';
        font-weight: 700;
        font-size: 20px;
    }
</style>