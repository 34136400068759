<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'optometrist.contactlens.controlvisit.CHECKVISUS'|translate}}

        table.table.table-striped.text-center.middle
            tbody
                tr
                    th {{'optometrist.acuity.FAR'|translate}}
                    td Visus
                    td C/Z
                    td BinVisus
                    th
                        button.btn.btn-info(type="button" :disabled="disabledVisus"
                            @click="dialog.near = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog.near" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionNearDialogSurvey(
                                    :interview="interview"

                                    r-visus="near_r_visus"
                                    l-visus="near_l_visus"
                                    bin-visus="near_binvisus"

                                    mode="contactLens"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog.near = false")
                    td Visus
                    td
                    td BinVisus
                tr
                    td
                        button.btn.btn-info(type="button" :disabled="disabledVisus"
                            @click="dialog.dist.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.dist.r" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                    :interview="interview"
                                    :data="acuity_contact_lens"
                                    side="right"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.dist.r = false")
                    td
                        span.form-control(disabled) {{interview.contactLens.powers.dist_r_visus}}
                    td
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.contactLens.powers.dist_r_cz" :disabled="true")
                    td(rowspan="2")
                        span.form-control(disabled) {{interview.contactLens.powers.dist_binvisus}}
                    th {{'prescription.RIGHT'|translate}}
                    td
                        span.form-control(disabled) {{interview.contactLens.powers.near_r_visus}}
                    td
                    td(rowspan="2")
                        span.form-control(disabled) {{interview.contactLens.powers.near_binvisus}}
                tr
                    td
                        button.btn.btn-info(type="button" :disabled="disabledVisus"
                            @click="dialog.dist.l = true") {{'prescription.LEFT'|translate}}

                            MountingPortal(v-if="dialog.dist.l" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection(
                                    :interview="interview"
                                    :data="acuity_contact_lens"
                                    side="left"
                                    @confirmed="updateInterview"
                                    @cancelled="dialog.dist.l = false")
                    td
                        span.form-control(disabled) {{interview.contactLens.powers.dist_l_visus}}
                    td
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.contactLens.powers.dist_l_cz" :disabled="true")
                    th {{'prescription.LEFT'|translate}}
                    td
                        span.form-control(disabled) {{interview.contactLens.powers.near_l_visus}}
</template>

<script>
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiOptometristsDetailsRefractionNearDialogSurvey
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/Dialogs/OptiOptometristsDetailsRefractionNearDialogSurvey'
    import OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection'

    export default {
        name: 'OptiOptometristsDetailsLensControlVisitCheckVisus',
        components: {
            OptiEnum,

            OptiOptometristsDetailsRefractionNearDialogSurvey,
            OptiOptometristsDetailsRefractionFarDialogAcuityWithCorrection
        },
        props: {
            interview: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    dist: {
                        r: false,
                        l: false
                    },
                    near: false
                },

                acuity_contact_lens: {
                    type: 'acuity_contact_lens',
                    info: {
                        r: {
                            sph: this.interview.contactLens.powers.dist_r_sph,
                            cyl: this.interview.contactLens.powers.dist_r_cyl,
                            axs: this.interview.contactLens.powers.dist_r_axs,
                            visus: '-',
                        },
                        l: {
                            sph: this.interview.contactLens.powers.dist_l_sph,
                            cyl: this.interview.contactLens.powers.dist_l_cyl,
                            axs: this.interview.contactLens.powers.dist_l_axs,
                            visus: '-'
                        },
                        binVisus: '-'
                    },
                    save: {
                        r: {
                            cz: 'contactLens.powers.dist_r_cz',
                            visus: 'contactLens.powers.dist_r_visus'
                        },
                        l: {
                            cz: 'contactLens.powers.dist_l_cz',
                            visus: 'contactLens.powers.dist_l_visus'
                        },
                        binVisus: 'contactLens.powers.dist_binvisus'
                    }
                }
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                this.dialog.dist.r = false
                this.dialog.dist.l = false
                this.dialog.near = false

                this.$emit('update-interview', interview)
            }
        },
        computed: {
            disabledVisus() {
                return (!(this.interview.contactLens.r_provided_contact_lens || this.interview.contactLens.l_provided_contact_lens) &&
                    !this.interview.contactLens.control_visit) || this.interview.contactLens.matching > 0 || !this.interview.contactLens.order_contact_lens
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>