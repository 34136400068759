<template lang="pug">
    div(style="position:relative")
        .row
            .col-xs-12(v-for="language_version in language_versions")
                .language-version-header
                    i.fa.mr3(:class="'fa-' + type_icons[language_version.type]")
                    span {{language_version.type_name|translate}}

                table.table.table-striped.middle
                    thead
                        tr
                            th Id
                            th {{'settings.offer.translations.language_versions.table.PL'|translate}}
                            th {{'settings.offer.translations.language_versions.table.ENG'|translate}}
                            th
                            th

                    tbody
                        tr(v-for="(translation, key) in language_version.translations" :key="translation.id"
                            :class="{'bg-separator': key % 2}")
                            td
                                a.link(:class="{'text-animated-alert': !translation.active || !translation.eng}"
                                    @click="dialog = translation") {{translation.id}}
                            td
                                a.link(:class="{'text-animated-alert': !translation.active || !translation.eng}"
                                    @click="dialog = translation") {{translation.pl}}
                            td(style="width:300px")
                                input.form-control.text-center(v-model.lazy="translation.eng")
                            td.text-center(style="width:70px")
                                OptiAutoSave(endpoint="settings/offer/translations/language-versions/save" fields="eng"
                                    :model="translation" :key="translation.id" @saved="countLanguageVersions")
                            td.text-center(style="width:50px")
                                label.btn.btn-danger(v-show="!translation.active" :for="'delete_translation_' + translation.id")
                                    i.fa.fa-trash

                                OptiButton.hide(:id="'delete_translation_' + translation.id"
                                    text="settings.offer.translations.language_versions.delete" @confirmed="del(translation.id)")
                                    template(#icon)
                                        i.fa.fa-trash

                        tr(v-show="!language_version.translations")
                            td.text-muted.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

            OptiProductsListDialog(v-if="dialog"
                endpoint="settings/offer/translations/language-versions/get-products"
                :params="dialog"

                @close="dialog = null")
                template(#header)
                    i.fa.fa-language.mr3
                    span.mr30 {{'settings.offer.translations.card.LANGUAGE_VERSIONS'|translate}}

                    span.mr30 {{'settings.offer.translations.language_versions.SECTION'|translate}} - {{dialog.type_name|translate}}
                    span.mr30 Id - {{dialog.id}}
                    span.mr30 {{'settings.offer.translations.language_versions.table.PL'|translate}} - {{dialog.pl}}
                    span.mr30
                        span.mr3 {{'settings.offer.translations.language_versions.table.ENG'|translate}} -
                        span(v-if="dialog.eng") {{dialog.eng}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}

                    span.text-muted {{'settings.offer.translations.language_versions.DIALOG_INFO'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsOfferTranslationsDetailsGenderShape',
        components: {
            OptiAutoSave,
            OptiButton,
            OptiProductsListDialog,
            OptiDimmer
        },
        data() {
            return {
                language_versions: [],

                type_icons: ['venus-mars', 'crop', 'paint-brush'],

                dialog: null,
                loading: true
            }
        },
        mounted() {
            API.get('settings/offer/translations/language-versions', API.id(Math.random())).then(res => {
                this.language_versions = res.data

                this.countLanguageVersions()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.offer.translations.language_versions.notify.error.LOAD')
            })
        },
        methods: {
            del(id) {
                this.loading = true

                API.delete('settings/offer/translations/language-versions/delete/' + id).then(res => {
                    this.language_versions = res.data

                    this.$notify.success('settings.offer.translations.language_versions.notify.DELETE')
                }).finally(() => {
                    this.countLanguageVersions()

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.offer.translations.language_versions.notify.error.DELETE')
                })
            },
            countLanguageVersions() {
                let translations = this.language_versions.map(lv => lv.translations).flat(),

                    filled = translations.filter(c => c.eng).length,
                    inactive = translations.filter(c => !c.active).length,
                    not_filled = translations.length - filled

                this.$emit('update-count', inactive + not_filled)
            }
        }
    }
</script>

<style lang="less" scoped>
    .language-version-header {
        background: darkgray;
        color: #000;
        text-align: center;
        padding: 5px;
        font-size: 16px;
    }
</style>