<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:10px" v-show="!loading")
            OptiButtonHistory(data="error" :id="error.id")

        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-show="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span.mr3 {{'bugtrack.details.CAPTION'|translate}} \#{{error.id}}
                        .pull-right \#{{error.created|noYearSek}}

                    .row(v-if="$uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status)")
                        .col-sm-6
                            label(style="padding-top:12px" for="topic") {{'bugtrack.details.TOPIC'|translate}}
                        .col-sm-6(style="height:50px;padding-right:20px")
                            input#topic.form-control(v-model="error.topic")
                    .row(v-if="!($uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status))")
                        .col-sm-6 {{'bugtrack.details.TOPIC'|translate}}
                        .col-sm-6 {{error.topic}}

                    .row(v-if="!$uac.permission('errors.edit')")
                        .col-sm-6 {{'bugtrack.details.STATUS'|translate}}
                        .col-sm-6
                            OptiEnum(enum="ErrorStatus" :id="error.status")
                    .row(v-if="$uac.permission('errors.edit')")
                        .col-sm-6
                            label(style="padding-top:12px" for="status") {{'bugtrack.details.STATUS'|translate}}
                        .col-sm-6(style="height:50px;padding-right:20px")
                            OptiSelectEnum#status(enum="ErrorStatus" :value="error.status"
                                @update-enum="s => error.status = s")

                    .row
                        .col-sm-6 {{'bugtrack.details.USER'|translate}}
                        .col-sm-6
                            span(v-if="error.user_id")
                                span.mr3 {{error.user.firstName}} {{error.user.lastName}}
                                span ({{error.department.name}})
                            span(v-if="!error.user_id") {{error.department_name}}

                    .row(v-if="!($uac.permission('errors.edit') || ($uac.user.id === error.user_id))")
                        .col-sm-6 {{'bugtrack.details.WEIGHT'|translate}}
                        .col-sm-6
                            OptiEnum(enum="ErrorPriority" :id="error.priority")
                    .row(v-if="$uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status)")
                        .col-sm-6
                            label(style="padding-top:12px" for="weight") {{'bugtrack.details.WEIGHT'|translate}}
                        .col-sm-6(style="height:50px;padding-right:20px")
                            OptiSelectEnum#weight(enum="ErrorPriority" :value="error.priority"
                                @update-enum="p => error.priority = p")

                    .row(v-if="!($uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status))")
                        .col-sm-6 {{'bugtrack.details.TYPE'|translate}}
                        .col-sm-6
                            OptiEnum(enum="ErrorType" :id="error.type")
                    .row(v-if="$uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status)")
                        .col-sm-6
                            label(style="padding-top:12px" for="type") {{'bugtrack.details.TYPE'|translate}}
                        .col-sm-6(style="height:50px;padding-right:20px")
                            OptiSelectEnum#type(enum="ErrorType" :value="error.type"
                                @update-enum="t => error.type = t")

                    .row
                        .col-sm-6 {{'bugtrack.details.URL'|translate}}
                        .col-sm-6(style="word-break:break-all")
                            a.link(:href="error.url" target="_blank"
                                :title="'bugtrack.details.title.URL'|translate") {{error.url}}

                    .row(v-if="error.system")
                        .col-sm-6 {{'bugtrack.details.SYSTEM'|translate}}
                        .col-sm-6  {{error.system}}

                    .row(v-if="error.browser")
                        .col-sm-6 {{'bugtrack.details.BROWSER'|translate}}
                        .col-sm-6  {{error.browser}}

                    div(style="border-left:50px solid transparent" v-show="$uac.permission('errors.edit')")
                        .row
                            .col-sm-6
                                label(style="padding-top:12px" for="expectedTime") {{'bugtrack.details.EXPECTED_TIME'|translate}}
                            .col-sm-6(style="height:50px;padding-right:20px")
                                input#expectedTime.form-control(placeholder="30min?, 2h? 1d? 1m?"
                                    name="expectedTime" v-model="error.expectedTime")
                        .row
                            .col-sm-6
                                label(style="padding-top:12px" for="difficulty") {{'bugtrack.details.EXPECTED_DIFFICULTY'|translate}}
                            .col-sm-6(style="height:50px;padding-right:20px")
                                OptiSelectEnum#difficulty(enum="ErrorDifficulty" :value="error.expectedDifficulty"
                                    @update-enum="eD => error.expectedDifficulty = eD")
                        .row
                            .col-sm-6
                                label(style="padding-top:12px" for="date") {{'bugtrack.details.EXPECTED_DATE'|translate}}
                            .col-sm-6(style="height:50px;padding-right:20px")
                                OptiDate#date(:value="error.expectedDate" @update-date="d => error.expectedDate = d")

                    .row(v-if="!$uac.permission('errors.edit') && error.error_id_ref > 0")
                        .col-xs-12(style="padding-right:20px;padding-bottom:0")
                            a(style="text-decoration:none" :href="$state.href('app.error.details', {id: error.error_id_ref})")
                                .alert.alert-info.bold(style="margin-bottom:0;text-decoration:underline")
                                    i.fa.fa-info-circle.mr3
                                    span {{'bugtrack.details.info.REFERENCE'|translate}} {{error.error_id_ref}}.
                    .row(v-if="$uac.permission('errors.edit')")
                        .col-sm-6
                            label.form-control-static(for="error_id_ref") {{'bugtrack.details.REFERENCE'|translate}}
                        .col-sm-6(style="padding-right:20px;padding-bottom:0")
                            input#error_id_ref.form-control(name="error_id_ref" v-model="error.error_id_ref"
                                :placeholder="'bugtrack.details.title.REFERENCE'|translate")
                        .col-xs-12(style="padding-right:20px;padding-bottom:0" v-show="error.error_id_ref")
                            a(style="text-decoration:none" :href="$state.href('app.error.details', {id: error.error_id_ref})")
                                .alert.alert-info.bold(style="margin-bottom:0;text-decoration:underline")
                                    i.fa.fa-info-circle.mr3
                                    span {{'bugtrack.details.info.REFERENCE'|translate}} {{error.error_id_ref}}.

                    .row(v-if="error.finished")
                        .col-sm-6 {{'bugtrack.details.FINISHED'|translate}}
                        .col-sm-6 {{error.finished}}

                    .row(v-if="error.picture")
                        .col-sm-12(style="padding-right:20px")
                            a.link(:href="'error/' + error.picture|upload" target="_blank" :title="'bugtrack.details.title.PICTURE'|translate")
                                img(style="width:100%" :src="'error/' + error.picture|upload")

                    .panel-footer.text-right(v-show="$uac.permission('errors.edit') || ($uac.user.id === error.user_id && [0, 3].includes(error.status))")
                        OptiButtonSave(v-show="$uac.permission('errors.edit') || ($uac.user.id === error.user_id && !error.status)"
                            @click.native="save")
                        OptiButton(type="success" v-show="!$uac.permission('errors.edit') && error.status === 3"
                            text="bugtrack.details.confirm.checked" @confirmed="goCheck") {{'bugtrack.details.confirm.checked.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check

        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            OptiNotes(table="error_comments" table-key="error_id" :upload="true"
                :id="error.id" :notes="error.notes" @update-notes="n => error.notes = n")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiErrorsDetails',
        components: {
            OptiButtonHistory,
            OptiEnum,
            OptiSelectEnum,
            OptiDate,
            OptiButtonSave,
            OptiButton,
            OptiNotes,
            OptiDimmer
        },
        data() {
            return {
                error: {
                    expectedDate: ''
                },

                loading: true
            }
        },
        mounted() {
            API.get('error/details/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('bugtrack.notify.error.LOAD')
            })
        },
        methods: {
            getData(error) {
                this.error = error

                this.$emit('status-name-was-changed', this.error.status_name)
            },

            save() {
                this.loading = true

                API.post('error/update', this.error).then(res => {
                    this.getData(res.data)

                    this.$notify.success('bugtrack.notify.UPDATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('bugtrack.notify.error.UPDATE')
                })
            },
            goCheck() {
                this.loading = true

                API.post('error/gocheck', {
                    id: this.error.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('bugtrack.notify.GOCHECK')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('bugtrack.notify.error.GOCHECK')
                })
            }
        }
    }
</script>
