<template lang="pug">
    a.list-group-item(:href="href")
        span.label.label-info.pull-right {{notification.timeDiff}}
        div
            i.mr(:class="iconType")
            i.mr(v-show="iconSubType" :class="iconSubType")
            span {{notification.description}}
        .text-muted.text-sm(v-show="$uac.user.activeDepartmentId === 91") {{notification.departmentName}}
</template>

<script>
    export default {
        name: 'OptiNotificationsItem',
        props: {
            notification: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                icons: {
                    types: {
                        task: 'icon icon-eyeglasses',
                        transfer: 'fa fa-retweet',
                        sale: 'fa fa-shopping-cart',
                        regeneration: 'icon icon-magic-wand',
                        reclamation: 'icon icon-wrench',
                        stock_correction: 'fa fa-dropbox',
                        patient: 'icon icon-users',
                        eShop: 'fa fa-globe'
                    },
                    subtypes: {
                        putBack: 'fa fa-reply',
                        greenPutBack: 'fa fa-reply',
                        note: 'fa fa-file',
                        layout: 'fa fa-map-marker',
                        positive: 'fa fa-smile-o text-success',
                        negative: 'fa fa-frown-o text-danger',
                        accepted: '',
                        report: 'fa fa-exclamation'
                    },
                    severities: {
                        1: 'text-normal',
                        2: 'text-warning',
                        3: 'text-danger',

                        4: 'text-primary', //dla cofek
                        5: 'text-success'
                    }
                },

                iconType: null,
                iconSubType: null,

                href: ''
            }
        },
        mounted() {
            let severity = this.icon_severity(this.notification.severity)

            this.iconType = this.icon_type(this.notification.type)
            this.iconSubType = this.icon_subtype(this.notification.subtype) ?
                severity + ' ' + this.icon_subtype(this.notification.subtype) : ''

            this.href = this.getHref()
        },
        methods: {
            icon_type(type) {
                return this.icons.types[type] ? this.icons.types[type] : '!invalid!'
            },
            icon_subtype(subtype) {
                return this.icons.subtypes[subtype] ? this.icons.subtypes[subtype] : ''
            },
            icon_severity(s) {
                return this.icons.severities[s] ? this.icons.severities[s] : 'text-danger'
            },

            getHref() {
                switch(this.notification.type) {
                    case 'task':
                        if(this.notification.subtype === 'note') {
                            return this.$state.href('app.task.detail', {
                                id: this.notification.reference,
                                '#': 'notes'
                            })
                        }

                        return this.$state.href('app.task.detail', {
                            id: this.notification.reference
                        })

                    case 'patient':
                        return this.$state.href('app.patient.detail', {
                            id: this.notification.reference,
                            '#': 'notes'
                        })

                    case 'sale':
                        return this.$state.href('app.sale.details', {
                            id: this.notification.reference
                        })

                    case 'transfer':
                        return this.$state.href('app.warehouse.transfer.detail', {
                            id: this.notification.reference
                        })

                    case 'reclamation':
                        if(this.notification.referenceType === 'taskReclamation') {
                            return this.$state.href('app.tasks.reclamation.details', {
                                id: this.notification.reference
                            })
                        }

                        if(this.notification.referenceType === 'saleReclamation')  {
                            return this.$state.href('app.sale.reclamation.details', {
                                id: this.notification.reference
                            })
                        }

                        break

                    case 'stock_correction':
                        return this.$state.href('app.catalog.stock-correction.edit', {
                            id: this.notification.reference
                        })

                    case 'regeneration':
                        return this.$state.href('app.regeneration.details', {
                            id: this.notification.reference
                        })

                    case 'eShop':
                        return this.$state.href('app.eShop.order', {
                            id: this.notification.reference
                        })
                }

                return ''
            }
        }
    }
</script>