import { render, staticRenderFns } from "./OptiDialogProductProducentOverwrite.vue?vue&type=template&id=c0d7ee6e&scoped=true&lang=pug&"
import script from "./OptiDialogProductProducentOverwrite.vue?vue&type=script&lang=js&"
export * from "./OptiDialogProductProducentOverwrite.vue?vue&type=script&lang=js&"
import style0 from "./OptiDialogProductProducentOverwrite.vue?vue&type=style&index=0&id=c0d7ee6e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0d7ee6e",
  null
  
)

export default component.exports