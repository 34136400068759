<template lang="pug">
    .row
        .col-lg-6.col-lg-offset-3.col-md-8.col-md-offset-2
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'transfers.create.CREATE'|translate}}

                form(style="padding:10px 15px" name="formTransfer")
                    .form-wizard
                        #step1(v-show="step === 1")
                            .form-group
                                label {{'transfers.create.PRODUCTID'|translate}}
                                .controls
                                    OptiSelectProduct(:product="model.product" @selected="p => model.product = p")
                                    .alert.alert-danger(style="margin-top:10px" v-show="error === 404")
                                        i.fa.fa-frown-o.mr3
                                        span {{'transfers.create.notify.error.PRODUCTINSTOCK'|translate}}

                        #step2(v-show="step === 2")
                            .form-group
                                label(for="department") {{'transfers.create.select.DEPARTMENT'|translate}}
                                .controls
                                    select#department.form-control(v-model="model.sourceDepartment" name="product"
                                        :disabled="model.products.length === 1" required)
                                        option(v-for="product in model.products" :value="product"
                                            ) {{product.department.name}} ({{product.stock}})
                                    .help-block {{'transfers.create.select.DEPARTMENTDESCRIPTION'|translate}}

                        #step3(v-show="step === 3")
                            .form-group
                                label(for="reason") {{'transfers.create.select.REASON'|translate}}
                                .control(style="padding-bottom:10px")
                                    OptiSelectEnum#reason(enum="TransferDocumentType" :disabled="true"
                                        :value="model.reason" @update-enum="updateModelReason")
                                    label.warn(for="reason" v-show="model.reason === null") {{'validation.formValidate.required'|translate}}

                            .form-group(v-show="model.reason === 6")
                                div
                                    label
                                        span.mr3 {{'reclamations.RECLAMATIONS'|translate}}
                                        small.mr3 {{'reclamations.status.WAITINGFORPARTS'|translate|lower}},
                                        small {{'reclamations.status.BEINGREPAIRED'|translate|lower}}
                                    .control.table-responsive
                                        table.table.table-striped.middle
                                            thead
                                                tr
                                                    th
                                                    th {{'transfers.create.reason.table.ID'|translate}}
                                                    th {{'transfers.create.reason.table.CLIENT'|translate}}
                                            tbody
                                                tr(v-for="reason in reasons")
                                                    td.text-center
                                                        .radio.c-radio.c-radio-nofont
                                                            label.text-bold(style="margin-top:0;margin-bottom:0"
                                                                :title="reason.id + ' - ' + reason.clientName")
                                                                input(type="radio" name="reasonId"
                                                                    v-model="model.reasonId" :value="reason.id")
                                                                span
                                                    td
                                                        a.link(:href="$state.href('app.tasks.reclamation.details', {id: reason.id})" target="_blank") {{reason.id}}
                                                    td {{reason.clientName}}

                                                tr(v-show="!reasons.length")
                                                    td.text-center(colspan="100")
                                                        i.fa.fa-exclamation-circle.mr3
                                                        span {{'default.table.EMPTY'|translate}}

                                        .bold.warn(v-show="!model.reasonId") {{'validation.formValidate.required'|translate}}

                            .form-group
                                label(for="comment") {{'transfers.create.COMMENT'|translate}}
                                .control
                                    textarea#comment.form-control(v-model="model.comments" name="comment" required)
                                    label.warn(for="comments" v-show="!model.comments") {{'validation.formValidate.required'|translate}}

                        #step4(v-if="step === 4")
                            .row
                                .col-xs-6
                                    .form-group
                                        label {{'transfers.create.details.PRODUCTID'|translate}}
                                        .controls
                                            OptiProduct(:product="model.product") {{model.product.id}}
                                    .form-group
                                        label {{'transfers.create.details.PRODUCTNAME'|translate}}
                                        .controls
                                            OptiProduct(:product="model.product") {{model.product.name}}
                                    .form-group
                                        label {{'transfers.create.details.DEPARTMENT'|translate}}
                                        .controls {{model.sourceDepartment.department.name}}
                                    .form-group
                                        label {{'transfers.create.details.QUANTITY'|translate}}
                                        .controls 1
                                .col-xs-6
                                    a.link(:href="$state.href('app.catalog.product', {id: model.product.id, departmentId: model.sourceDepartment.department.id})"
                                        target="_blank" :title="'transfers.create.details.title.PRODUCTDETAILS'|translate")
                                        img.img-responsive(:src="'thumb/product/ids/' + model.product.id + '.jpg'")
                            .row
                                .col-xs-12
                                    .form-group
                                        label {{'transfers.create.details.REASON'|translate}}
                                        .controls
                                            OptiEnum(enum="TransferDocumentType" :id="model.reason")
                                            span(v-show="model.reasonId") ({{model.reasonId}})
                                    .form-group
                                        label {{'transfers.create.details.COMMENT'|translate}}
                                        .controls(v-html="$options.filters.nl2br(model.comments)")

                .panel-footer.text-right(v-show="step === 1")
                    OptiButton(type="info" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="!model.product" @click.native="selectProduct") {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                .panel-footer(v-show="step === 2")
                    OptiButton(type="info" :title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 1") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton.pull-right(type="info" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="!model.sourceDepartment" @click.native="selectDepartment") {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                .panel-footer(v-show="step === 3")
                    OptiButton(type="info" :title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 2") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton.pull-right(type="info" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="disabledStep3" @click.native="step = 4") {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                .panel-footer(v-show="step === 4")
                    OptiButton(type="info" :title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 3") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButtonSave.pull-right(:title="'transfers.create.action.CREATETITLE'|translate"
                        @click.native="create")

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTransferCreate',
        components: {
            OptiSelectProduct,
            OptiButton,
            OptiSelectEnum,
            OptiProduct,
            OptiEnum,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                model: {
                    product: null,
                    products: {},
                    sourceDepartment: null,
                    reason: 0,
                    reasonId: 0,
                    comments: ''
                },

                reasons: [],

                step: 1,
                error: 0,
                loading: false
            }
        },
        mounted() {

        },
        methods: {
            selectProduct() {
                this.error = 0
                this.loading = true

                API.post('warehouse/transfers/create/findProduct', {
                    id: this.model.product.id,
                    lock: true
                }).then(res => {
                    this.model.products = res.data
                    this.model.sourceDepartment = res.data[0]

                    this.step = 2
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    this.error = error.response.status
                })
            },
            selectDepartment() {
                if(this.model.sourceDepartment) {
                    this.step = 3
                }
            },

            updateModelReason(r) {
                this.model.reason = r
                this.loading = true

                API.get('warehouse/transfers/reason/' + this.model.reason).then(res => {
                    this.reasons = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.create.notify.error.REASONNOTLOAD')
                })
            },

            create() {
                this.loading = true

                API.post('warehouse/transfers/create', this.model).then(() => {
                    this.$state.go('app.warehouse.transfers.incoming.toconfirm')

                    this.$notify.success('transfers.create.notify.CREATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.create.notify.error.CREATE')
                })
            }
        },
        computed: {
            disabledStep3() {
                return !((this.model.reason !== 6 || (this.model.reason === 6 && this.model.reasonId > 0)) && this.model.comments)
            }
        }
    }
</script>