<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="active")
            .panel.panel-primary(v-if="$asyncComputed.specification.success")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.breadcrumb.SPECIFICATION'|translate}} \#{{specification.id}}
                .panel-body
                    .row
                        .col-sm-2
                            label ID
                        .col-sm-6
                            p {{specification.id}}
                        .col-sm-4
                            button.btn.btn-primary(@click="excelExport()") {{'specification.table.regenerations.EXPORT'|translate}}
                    .row
                        .col-sm-2
                            label Autor
                        .col-sm-10
                            p {{specification.user.firstName}} {{specification.user.lastName}}
                    .row
                        .col-sm-2
                            label Suma
                        .col-sm-10
                            p {{specification.sum|currency}}
                    .row
                        .col-sm-2
                            label Data
                        .col-sm-10
                            p {{specification.created_at}}
                    .row
                        .col-sm-12.table-responsive
                            table.table.table-striped

                                thead
                                    tr
                                        th.text-center #
                                        th.text-center {{'regeneration.table.ID'|translate}}
                                        th.text-left {{'regeneration.table.PATIENT'|translate}}
                                        th.text-center {{'regeneration.table.SOURCE'|translate}}
                                        th.text-left {{'regeneration.table.SPECIALIST'|translate}}
                                        th.text-left {{'regeneration.table.DEPARTMENT'|translate}}
                                        th.text-right {{'regeneration.table.RETAIL'|translate}}
                                        th.text-center {{'regeneration.table.END'|translate}}
                                tbody
                                    tr(v-for="(regeneration, index) in specification.regenerations")
                                        td.text-right  {{index + 1}}
                                        td
                                            a.link(:href="$state.href('app.regeneration.details',{id:regeneration.id})") {{regeneration.id}}
                                        td
                                            a.link(:href="$state.href('app.patient.detail',{id:regeneration.patient.id})") {{regeneration.patient.firstName}} {{regeneration.patient.lastName}}
                                        td.text-center
                                            OptiEnum.mr3(enum="RegenerationSource" :id="regeneration.source")
                                            OptiRefId(section="regeneration" :type-id="regeneration.source" :id="regeneration.source_id")
                                        td.text-left {{regeneration.specialist.first_name}} {{regeneration.specialist.last_name}}
                                        td.text-left {{regeneration.department.name}}
                                        td.text-right {{regeneration.wholesale_price|currency}}
                                        td.text-center {{regeneration.date_end}}

</template>

<script>
    import {API} from "@/js/app/vue/api";
    import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
    import OptiEnum from "@/js/app/vue/components/OptiEnum";
    import OptiRefId from "@/js/app/vue/components/OptiRefId";

    export default {
        name: "OptiRegenerationSpecification",
        components: {OptiDimmer, OptiEnum, OptiRefId},

        data() {
            return {
                active: false
            }
        },
        asyncComputed: {
            async specification () {
                try {
                    this.active = true
                    let {data} = await API.get('specifications/details/' +  this.$state.params.id)
                    this.active = false
                    return data
                } catch(e) {
                    console.log(e)
                }
            }
        },
        methods: {
            async excelExport() {
                this.active = true
                await API.get('specifications/export/' + this.$state.params.id)
                this.active = false
            }
        }
    }
</script>

<style scoped>

</style>