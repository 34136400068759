<template lang="pug">
    OptiDialog.fullscreen(:header="false" :footer="false")
        template(#body)
            i.fa.fa-close.fa-2x(:title="'default.CLOSE'|translate" @click="closeFullScreen")

            #product-name {{product.name}}

            #product-image(v-if="images.length")
                img(:src="'https://cdn.eopti.pl/images/' + product.id + '/' + product.id + '_' + images[key] + '.jpg'")

            #products-thumbnails
                img.product-thumbnail(v-for="(image, key_image) in images" :class="{'selected': key_image === key}"
                    :src="'https://cdn.eopti.pl/images/' + product.id + '/' + product.id + '_' + image + '.jpg'"
                    :title="'default.REFERENCETYPE'|translate" @click="changeImage(key_image)")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {FullScreenMode} from '@/js/app/vue/helpers/FullScreenMode'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiDialogProductPhotoPreview',
        components: {
            OptiDialog
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                images: [],
                key: 0,
                sec: 3,
                interval: null
            }
        },

        mounted() {
            FullScreenMode.open()

            window.addEventListener('fullscreenchange', this.changeFullscreen)

            API.post('https://cdn.eopti.pl/count_images.php', {
                product_id: this.product.id
            }).then(res => {
                if(!res.data) {
                    this.closeFullScreen()

                    this.$notify.error('product.notify.error.NO_IMAGE')
                } else if(res.data > 1) {
                    this.images = _.range(1, res.data + 1)

                    this.images.push(this.images.shift())

                    this.switch()
                } else {
                    this.images = [1]
                }
            }).catch(() => {
                this.closeFullScreen()

                this.$notify.error('product.notify.error.IMAGE_ERROR')
            })
        },
        destroyed() {
            window.removeEventListener('fullscreenchange', this.changeFullscreen)

            clearInterval(this.interval)
        },

        methods: {
            closeFullScreen() {
                FullScreenMode.close()

                this.$emit('closed')
            },
            changeFullscreen() {
                if(!document.fullscreenElement) {
                    document.body.style.overflow = ''

                    this.$emit('closed')
                }
            },

            changeImage(i) {
                clearInterval(this.interval)

                this.key = i
            },
            switch() {
                this.interval = setInterval(() => {
                    if(this.key === this.images.length - 1) {
                        this.key = 0
                    } else {
                        ++this.key
                    }
                }, this.sec * 1000)
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-body {
        background: #fff;
    }

    i.fa-close {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        color: grey;
        z-index: 9999;
    }

    #product-name {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 26px;
        font-weight: 700;
        margin-top: 10px;
        color: #000;
        text-shadow: 0 0 5px #fff;
        width: 100%;
        text-align: center;
    }

    #product-image {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        > img {
            width: 100%;
        }
    }

    #products-thumbnails {
        position: absolute;
        bottom: 0;

        width: 100%;

        display: flex;
        justify-content: space-around;

        > img.product-thumbnail {
            display: inline-block;
            width: 75px;
            margin: 10px;
            outline: 2px solid lightgrey;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                outline: 2px solid orange;
            }

            &.selected {
                outline: 2px solid darkslategrey;
            }
        }
    }
</style>