<template lang="pug">
	OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'

	import OptiDimmer from '../../Blocks/OptiDimmer'

	export default {
		name: 'OptiDictionaryDoctorDelete',
		components: {OptiDimmer},
		data(){
			return {
				loading: true
			}
		},
		mounted() {
			API.delete('dictionary/doctor/action/' + this.$state.params.id).then(() => {
				this.$notify.success('dictionary.doctor.notify.DELETE')
			}).then(() => {
				this.loading = false
			}).finally(() => {
				this.$state.go('app.dictionary.doctors.table')
			}).catch(() => {
				this.$notify.error('dictionary.doctor.notify.error.DELETE')
			})
		}
	}
</script>