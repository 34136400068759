<template lang="pug">
    #optometrist-controls(:class="{'connected': display.connected, 'disconnected': !display.connected, 'reconnecting': display.reconnecting}")
        .pin-container
            span.pin(v-show="!display.reconnecting" @click="link()")
                span(v-show="!display.connected") {{'optometrist.display.CONNECT'|translate}}
                span(v-show="display.connected") {{'optometrist.display.CONNECTED'|translate}} ({{ display.connected }})
            span.pin(v-show="display.reconnecting") {{'optometrist.display.notify.error.CONNECT'|translate}}
        .pin-container(v-show="display.connected && pilot")
            span.pin(style="left:45%" @click="dialog_pilot = true") {{'optometrist.display.REMOTE'|translate}}
                MountingPortal(v-if="dialog_pilot" mountTo="#vue-modal" append)
                    OptiOptometristsSurveysDialogsPilot(
                        @cancelled="dialog_pilot = false")

        #optometrist-controls-list.text-center(style="position:fixed;left:0;right:0;bottom:0;background-color:black;pointer-events:all"
            v-show="connecting")
            div(style="display:inline-block;width:300px")
                div(style="width:100%;margin-top:10px;position:relative" v-for="d in display.displays")
                    .btn.btn-default(style="width:100%;height:55px" @click="connect(d)")
                        div {{'optometrist.display.DEVICE'|translate}}: {{d.uuid}}
                        .warn(v-show="d.userId") {{'optometrist.display.CONNECTWITH'|translate}}: {{d.userName}}
                        .text-success(v-show="!d.userId") {{'optometrist.display.NOCONNECT'|translate}}

                    .btn.btn-default(style="position:absolute;top:0;left:310px;width:55px;height;55px"
                        v-show="checkUser(d)" @click="disconnect(d)")
                        i.fa.fa-2x.fa-remove(style="line-height:1.5em")

                    .btn.btn-default(style="position:absolute;top:0;left:-65px;width:55px;height:55px"
                        v-show="checkUser(d)" @click="refresh(d)")
                        i.fa.fa-2x.fa-refresh(style="line-height:1.5em")

                .btn.btn-danger(style="width:100%;margin-top:30px;margin-bottom:30px"
                    @click="connecting = false") {{'default.CLOSE'|translate}}
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

    import OptiOptometristsSurveysDialogsPilot
        from '@/js/app/vue/components/Optometrists/Surveys/Dialogs/OptiOptometristsSurveysDialogsPilot'

    export default {
        name: 'OptiOptometristsDisplayControls',
        components: {
            OptiOptometristsSurveysDialogsPilot
        },
        props: {
            destroy: {
                type: Boolean,
                required: false,
                default: false
            },
            pilot: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                display: OptometristsDisplay,

                connecting: false,

                dialog_pilot: false
            }
        },
        mounted() {
            this.connection = true
        },
        destroyed() {
            if(this.destroy) {
                this.display.close()
            }
        },
        methods: {
            link() {
                this.display.message.displays()
                this.connecting = true
            },
            checkUser(display) {
                return display.userId === this.$uac.user.id
            },
            disconnect(d) {
                this.display.disconnect(d)
            },
            refresh(d) {
                this.display.refresh(d)
            },
            connect(d) {
                if(d.userId) {
                    this.$notify.warning('optometrist.display.notify.DISCONNECTUSER')
                }

                this.display.link(d)
                this.connecting = false
            }
        }
    }
</script>

<style lang="less" scoped>
    //TODO: przenieś to z lessa
</style>