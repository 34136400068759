<template lang="pug">
    .row(style="margin-left:0;margin-right:0")
        .col-xs-12.col-sm-2.p-sm
            .panel.widget.action-panel
                a.panel-body.text-center(:href="$state.href('app.tasks.all', {search: ['status:!=:cancelled', 'status:!=:notaccepted', 'status:!=:completed', 'status:!=:abraded', 'status:!=:checked', 'status:!=:sent', 'status:!=:received', 'status:!=:done']})")
                    h2.mt0 {{dashboard.simpleData.expected || 0}}
                    .mb0.text-muted {{'dashboard.simple.EXPECTED'|translate}}
        .col-xs-12.col-sm-3.p-sm
            .panel.widget.action-panel
                a.panel-body.text-center(:href="$state.href('app.tasks.completed', {resetSearch: true})")
                    h2.mt0 {{dashboard.simpleData.completed || 0}}
                    .mb0.text-muted {{'dashboard.simple.COMPLETED'|translate}}
        .col-xs-12.col-sm-3.p-sm
            .panel.widget.action-panel
                a.panel-body.text-center(:href="$state.href('app.tasks.completed', {search: ['deadline:=:yes']})")
                    h2.mt0
                        i.task-icon.deadline.mr3(style="margin-left:-26px")
                        span {{dashboard.simpleData.deadline || 0}}
                        span(v-show="dashboard.simpleData.deadline_today" :title="'dashboard.DEADLINETODAY'|translate"
                            ) ({{ dashboard.simpleData.deadline_today}})
                    .mb0.text-muted {{'dashboard.simple.COMPLETEDTERM'|translate}}
        .col-xs-12.col-sm-2.p-sm
            .panel.widget.action-panel
                a.panel-body.text-center(:href="$state.href('app.tasks.abraded', {resetSearch: true})")
                    h2.mt0 {{dashboard.simpleData.abraded || 0}}
                    .mb0.text-muted {{'dashboard.simple.ABRADED'|translate}}
        .col-xs-12.col-sm-2.p-sm
            .panel.widget.action-panel
                a.panel-body.text-center(:href="$state.href('app.tasks.checked', {resetSearch: true})")
                    h2.mt0 {{ dashboard.simpleData.checked || 0 }}
                    .mb0.text-muted {{'dashboard.simple.CHECKED'|translate}}

        .col-md-6.col-xs-12
            OptiDashboardForecast(v-if="dashboard.task_assembly_forecast.length"
                :previousAssemblies="previousAssemblies"
                :taskAssemblyForecast="dashboard.task_assembly_forecast")

        .col-md-6.col-xs-12(v-show="dashboard.points.length")
            .panel.panel-primary
                .panel-heading
                    i.icon.icon-pie-chart.mr3
                    span {{'dashboard.scores.HEADING'|translate}}

                .table-responsive
                    table.table.table-striped.table-hover.table-dashboard.text-expressive
                        thead
                            tr
                                th
                                th.text-center {{'dashboard.scores.POINTS'|translate}}
                                th.text-center {{'dashboard.scores.VALUE'|translate}}
                        tbody
                            tr(v-for="point in dashboard.points")
                                td.text-left.middle
                                    a.link(:href="$state.href('app.tasks-raport.list', {search: ['user_id:=:' + point.user_name, 'date:=:' + point.date]})"
                                        ) {{point.user_name}}
                                td.text-right.middle {{point.points|points}}
                                td.text-right.middle {{point.values|currency}}
</template>

<script>
    import OptiDashboardForecast from '@/js/app/vue/components/Dashboard/OptDashboardForecast'

    export default {
        name: 'OptiDashboardSimpleGrindery',
        components: {
            OptiDashboardForecast
        },
        props: {
            dashboard: {
                type: Object,
                required: true
            },
            previousAssemblies: {
                type: Array,
                required: true
            }
        }
    }
</script>