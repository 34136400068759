<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            OptiButtonPrint(url="tasks/wz/print" :id="wz.id") {{'tasks.wz.details.PRINT'|translate}}

            OptiButton.pull-right(v-show="!wz.closed" type="success" text="tasks.wz.action.close.confirm"
                @confirmed="goClose") {{'tasks.wz.action.close.confirm.BTN'|translate}}
                template(#icon)
                    i.fa.fa-unlock-alt

        .col-xs-12(style="margin-top:10px" v-show="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'tasks.wz.WZ'|translate}} \#{{wz.id}}
                    .pull-right \#{{wz.created}}

                .table-responsive
                    table.table.table-responsive.table-striped.middle
                        thead
                            tr
                                th.text-right #
                                th.text-right {{'tasks.wz.items.PRODUCT_ID'|translate}}
                                th {{'tasks.wz.items.PRODUCT'|translate}}
                                th {{'tasks.wz.items.PRICE'|translate}}
                                th {{'tasks.wz.items.QUANTITY'|translate}}
                                th {{'tasks.wz.items.CORRECTED'|translate}}
                                th {{'tasks.wz.items.TASK_ID'|translate}}
                        tbody
                            template(v-for="(item, index) in wz.items")
                                tr
                                    td.text-right {{index + 1}}
                                    td.text-right
                                        OptiProduct(:product="item.product") {{item.product_id}}
                                    td
                                        OptiProduct(:product="item.product") {{item.product_symbol}}
                                        small(style="display:block") {{item.product.fullName}}
                                    td {{item.product_price|currency}}
                                    td {{item.quantity}}
                                    td
                                        OptiNumber(
                                            v-show="!wz.closed"
                                            :header="'tasks.wz.items.CORRECTED'|translate"
                                            :value="checkStock(wz, item)"
                                            :disabled="item.product.stock <= 0"
                                            @confirmed="c => item.corrected = c"
                                            ) {{item.corrected}}
                                        span(v-show="wz.closed") {{item.corrected}}
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: item.task_id})" target="_blank") {{item.task_id}}
                                tr(v-if="wz.items[index].product.stock < 1 && !wz.closed")
                                    td(colspan="5")
                                    td.warn.bold(colspan="2") {{'orders.wz.add.validate.product.NOSTOCK'|translate}}

        .col-md-8.col-md-offset-4.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-default(style="border-top-width:1px")
                    .panel-container
                        .row
                            .col-sm-6 {{'tasks.wz.details.CREATEDBY'|translate}}
                            .col-sm-6 {{wz.user.name}}
                        .row(v-show="wz.comments")
                            .col-sm-6 {{'tasks.wz.details.COMMENTS'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(wz.comments)")
                        .row
                            .col-sm-6 {{'tasks.wz.details.QUANTITY'|translate}}
                            .col-sm-6 {{wz.quantity}}
                        .row
                            .col-sm-6 {{'tasks.wz.details.CORRECTED'|translate}}
                            .col-sm-6
                                span(:class="{'old-value': wz.corrected !== corrected}") {{wz.corrected}}
                                span(style="margin-left:5px" v-show="wz.corrected !== corrected") {{corrected}}
                        .row
                            .col-sm-6 {{'tasks.wz.details.VALUE'|translate}}
                            .col-sm-6 {{wz.value|currency}}
                        .row
                            .col-sm-6 {{'tasks.wz.details.CREATED'|translate}}
                            .col-sm-6 {{wz.created}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTasksWzDetails',
        components: {
            OptiButtonPrint,
            OptiButton,
            OptiProduct,
            OptiNumber,
            OptiDimmer
        },
        data() {
            return {
                wz: {},

                loading: true
            }
        },
        mounted() {
            API.get('tasks/wz/details/' + this.$state.params.id).then(res => {
                this.wz = res.data
            }).then(() => {
                window.localStorage.removeItem('ngStorage-create_task_wz_checkbox')
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('tasks.wz.notify.error.LOAD')
            })
        },
        methods: {
            goClose() {
                this.loading = true

                API.post('tasks/wz/close', this.wz).then(res => {
                    this.wz = res.data

                    this.$notify.success('tasks.wz.notify.CLOSE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.wz.notify.error.CLOSE')
                })
            },
            checkStock(wz, item) {
                if (item.product.stock < 1 && !wz.closed){
                    return item.corrected = 0
                } else {
                    return item.corrected
                }
            },
        },
        computed: {
            corrected() {
                let _return = 0

                this.wz.items.forEach(item => {
                    _return += item.corrected
                })

                return _return
            }
        }
    }
</script>