<template lang="pug">
    OptiButton(type="info" @click.native="open = true" :disabled="disabled") {{'optometrist.contactlens.efron.COMPARE'|translate}}
        template(#icon)
            i.fa.fa-files-o

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogEfronCompare(
                :efron="efron" :side="side" :efron-id="efronId"
                :disabled="disabled" @closed="open = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogEfronCompare
        from '@/js/app/vue/components/Optometrists/Details/Lens/Efron/Dialogs/OptiDialogEfronCompare'

    export default {
        name: 'OptiButtonEfronCompare',
        components: {
            OptiButton,
            OptiDialogEfronCompare
        },
        props: {
            efron: {
                type: Array,
                required: true
            },
            side: {
                type: String,
                required: true
            },
            efronId: {
                type: Number,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>
