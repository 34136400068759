<template lang="pug">
    .col-xs-12
        .panel.panel-primary(style="margin-bottom:10px")
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'services.SERVICE'|translate}}

            .panel-body(v-show="task.packId && !task.frame.type")
                .alert.alert-warning
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.notify.NOTYPEFRAMEASSEMBLY'|translate}}

            .panel-body(v-show="(task.packId && task.frame.type) || !task.packId")
                form.form-horizontal(name="formAssembly")
                    .form-group(style="margin-bottom:0")
                        label.col-sm-4.control-label(for="servicesName") {{'services.NAME'|translate}}
                        .col-sm-8
                            .form-control-static {{task.taskAssembly.name || 'Nie wybrano'}}
                            label.bold.warn(for="servicesName" v-show="!task.frame.type && !task.assemblyId"
                                ) {{'tasks.validate.service.FRAMETYPE'|translate}}
</template>

<script>
    export default {
        name: 'OptiTaskDetailsLensesAssembly',
        props: {
            task: {
                type: Object,
                required: true
            }
        }
    }
</script>
