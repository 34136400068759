<template lang="pug">
    #cart-order-problem.row
        .col-xs-12
            .panel.panel-warning
                .panel-heading
                    i.fa.fa-exclamation-circle.mr3
                    span {{'carts.order.problems.HEADING'|translate}}

                .panel-body.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'carts.order.problems.LIMIT'|translate}}

                table.table.table-condensed.table-striped
                    thead
                        tr
                            th.text-right #
                            th Brand
                            th.text-right Facing
                            th.text-right {{'carts.order.problems.heading.INVENTORY'|translate}}
                            th.text-right {{'carts.order.problems.heading.STOCK'|translate}}
                            th.text-right {{'carts.order.problems.heading.ORDERED'|translate}}
                            th.text-right {{'carts.order.problems.heading.AFTER'|translate}}
                            th.text-right(style="padding-right: 20px") {{'carts.order.problems.heading.SURPLUS'|translate}}
                    tbody
                        tr(v-for="(problem, key) in problems")
                            td.text-right {{key + 1}}.
                            td
                                a.link(:href="$state.href('app.catalog.category', {id: problem.categoryId, departmentId: problem.departmentId})") {{problem.category.name}}
                            td.text-right {{problem.facing.facing}}
                            td.text-right {{problem.facing.min}} - {{problem.facing.max}}
                            td.text-right {{problem.currentStock}}
                            td.text-right {{problem.orderedQuantity}}
                            td.text-right {{problem.stockAfterOrder}}
                            td.text-right.warn(style="padding-right:20px") {{problem.stockAfterOrder - problem.facing.facing - problem.facing.max}}

                .panel-body.text-muted {{'carts.order.problems.TIP'|translate}}

</template>

<script>
    export default {
        name: 'OptiCartOrderProblems',
        components: {

        },
        props: {
            problems: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

            }
        }
    }
</script>