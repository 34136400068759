<template lang="pug">
    .row(v-if="sorting")
        .col-xs-12
            .input-group
                .input-group-addon(style="cursor: pointer; height:45px" @click="changeSorting")
                    i.fa(:class="{'fa-sort-amount-asc': sorting.asc, 'fa-sort-amount-desc': !sorting.asc}")

                select.form-control(style="height: 45px" v-model="sorting.sortBy" @change="$emit('sorting', sorting)")
                    option(:value="'lp'") LP
                    option(:value="'id'") ID produktu
</template>
<script>
    export default {
        name: 'OptiSortFilter',

        props: {
            sorting: {
                type: Object,
                required: false
            }
        },
        methods: {
            changeSorting() {
                this.sorting.asc = !this.sorting.asc
                this.$emit('sorting', this.sorting)
            }
        }
    }
</script>

<style scoped lang="less">

</style>