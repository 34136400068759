<template lang="pug">
    #cart-sendback-add

        #cart-sendback-table.row(v-if="Object.keys(sendback).length")
            .col-xs-12
                .panel.panel-warning
                    .panel-heading
                        i.fa.fa-archive.mr3
                        span {{'carts.type.fullname.SENDBACK'|translate}}

                    .table-responsive
                        table.table
                            thead
                                tr
                                    th #
                                    th {{'carts.PRODUCTID'|translate}}
                                    th {{'carts.NAME'|translate}}
                                    th {{'carts.PRICE'|translate}}
                                    th {{'carts.QUANTITY'|translate}}
                                    th {{'carts.VALUE'|translate}}
                                    th

                            tbody
                                template(v-for="(item, key) in sendback.items")
                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td {{sendback.items.length - key}}
                                        td.text-right
                                            OptiProduct(:product="item.product") {{item.product.id}}
                                        td
                                            OptiProduct(:product="item.product") {{item.product.name}}
                                            .label.label-success.pull-right(v-show="item.highlight") {{'carts.NEW'|translate}}
                                            small(style="display:block") {{item.product.fullName}}
                                        td.text-right {{item.product.price|currency}}
                                        td.col-xs-1.text-right {{item.quantity}}
                                        td.text-right {{item.product.price * item.quantity|currency}}
                                        td.text-center(rowspan="2")
                                            a(:href="$state.href('app.catalog.product', {id: item.product.id, departmentId: $uac.getUser().activeDepartmentId})"
                                                :title="'sale.items.table.action.DETAILS'|translate")
                                                i.fa.fa-2x.fa-fw.fa-info-circle
                                    tr.align-middle(:class="{'tr-stripped': key % 2}")
                                        td.b0(:colspan="3")
                                            .warn(v-show="item.product.depository.stock - item.quantity < 0")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.nostock.LOCAL'|translate}}
                                        td.b0(colspan="3")

                                tr(v-show="!sendback.items.length")
                                    td.text-center.text-muted(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'default.table.EMPTY'|translate}}

                            tfoot
                                tr.align-middle.text-expressive
                                    td(colspan="3")
                                    th.text-right {{'carts.TOTALS'|translate}}
                                    th.text-right {{sendback.totals.quantity}}
                                    th.text-right {{sendback.totals.value|currency}}
                                    td

            .col-md-offset-4.col-md-8
                form#form-sendback.form-horizontal
                    .form-group
                        label.col-sm-4.control-label(for="comments") {{'returns.COMMENTS'|translate}}
                        .col-sm-8
                            textarea#comments.form-control(v-model.lazy="comments" name="comments")
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiButton(type="warning" :disabled="disabled"
                                text="carts.confirm.goendsendback" @confirmed="goEndSendback") {{'carts.sendback.GOENDRETURN'|translate}}
                                template(#icon)
                                    i.fa.fa-archive

        OptiDimmer(:active="!Object.keys(sendback).length || loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCartSendbackAdd',
        components: {
            OptiButton,
            OptiProduct,
            OptiDimmer
        },
        data() {
            return {
                comments: '',

                loading: false
            }
        },
        mounted() {

        },
        methods: {
            goEndSendback() {
                this.loading = true

                API.post('warehouse/return/add', {
                    cart: this.sendback,
                    comments: this.comments
                }).then(res => {
                    if(res.data.status) {
                        this.$state.go('app.warehouse.return.details', {id: res.data.return_id})

                        this.$notify.success('returns.notify.SAVE')
                    } else {
                        this.$notify.error('carts.sendback.notify.error.GOENDRETURN')
                    }
                }).finally(() => {
                    this.loading = false

                    this.$store.dispatch('carts/fetch')
                }).catch(() => {
                    this.$notify.error('carts.sendback.notify.error.GOENDRETURN')
                })
            }
        },
        computed: {
            sendback() {
                return this.$store.getters['carts/getCarts'].sendback
            },
            disabled() {
                return this.$store.getters['carts/getDisabled']('sendback')
            }
        }
    }
</script>