<template lang="pug">
    form#formPrescription.row.form-horizontal.form-validate(name="formPrescription" @submit.prevent="addPrescription")
        .col-md-6.col-xs-12(v-show="!loading.main")
            OptiPatientsDetailsPrescriptionDoctor(
                :prescription="prescription"
                :doctor-type="doctor_type"
                :loading="loading"

                @update-doctor-type="t => doctor_type = t")

            OptiPatientsDetailsPrescriptionSource(
                :prescription="prescription"
                :loading="loading")

            OptiPatientsDetailsPrescriptionCheckVisus(
                :prescription="prescription"

                @update-prescription-check="c => prescription.check = c")

        .col-md-6.col-xs-12(v-show="!loading.main")
            OptiPatientsDetailsPrescriptionPowers(
                :prescription="prescription"
                :loading="loading")

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import validatePrescription from './PrescriptionValidator'

    import OptiPatientsDetailsPrescriptionDoctor
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionDoctor'
    import OptiPatientsDetailsPrescriptionSource
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionSource'
    import OptiPatientsDetailsPrescriptionCheckVisus
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionCheckVisus'
    import OptiPatientsDetailsPrescriptionPowers
        from '@/js/app/vue/components/Patients/Prescriptions/Details/OptiPatientsDetailsPrescriptionPowers'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsPrescriptionAdd',
        components: {
            OptiPatientsDetailsPrescriptionDoctor,
            OptiPatientsDetailsPrescriptionSource,
            OptiPatientsDetailsPrescriptionCheckVisus,
            OptiPatientsDetailsPrescriptionPowers,
            OptiDimmer
        },
        data() {
            return {
                prescription: {
                    type: 1,
                    leCode: 0,
                    reCode: 0,
                    reSphere: 0,
                    leSphere: 0,
                    reCylinder: 0,
                    leCylinder: 0,
                    reAxis: 'brak',
                    leAxis: 'brak',
                    reAdd: 0,
                    leAdd: 0,

                    prism_mode: true,
                    rePrism: 0,
                    lePrism: 0,
                    rePrismAngle: 0,
                    lePrismAngle: 0,

                    pdDist: 0,
                    rePdDist: 0,
                    lePdDist: 0,
                    pdNear: 0,
                    rePdNear: 0,
                    lePdNear: 0,
                    comments: '',
                    patientId: this.$state.params.patientId,
                    source: 1,
                    interviewId: 0,

                    doctorId: 0,

                    doctorFirstName: '',
                    doctorLastName: '',
                    doctorNpwz: '',
                    doctorContact: '',

                    files: [null],

                    check: {
                        status: 1,
                        dist_r_visus: '-',
                        dist_l_visus: '-',
                        dist_r_cz: '0',
                        dist_l_cz: '0',
                        dist_binvisus: '-',
                        near_r_visus: '-',
                        near_l_visus: '-',
                        near_binvisus: '-',
                        logs: []
                    }
                },

                doctor_type: 1,

                loading: {
                    main: false,
                    doctors: true,
                    files: [false]
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                if(location.hash.includes('interview-')) {
                    let tab = location.hash.split('-')

                    this.prescription.source = 0
                    this.prescription.interviewId = parseInt(tab[1])
                }
            })
        },
        methods: {
            addPrescription() {
                let validate = validatePrescription(this.prescription, this.doctor_type)

                if(!validate.length) {
                    this.loading.main = true

                    let data = _.cloneDeep(this.prescription)
                    data.files = data.files.filter(f => f)

                    API.post('prescriptions', data).then(res => {
                        this.$notify.success('prescription.ADDED')

                        this.$state.go('app.patient.detail', {
                            id: res.data.patientId,
                            '#': 'prescriptions',
                            prescriptionId: res.data.id
                        })
                    }).finally(() => {
                        this.loading.main = false
                    }).catch(() => {
                        this.$notify.error('prescription.notify.NOADDED')
                    })
                } else {
                    validate.forEach(error => {
                        this.$notify.error(error, false)
                    })
                }
            }
        }
    }
</script>
