<template lang="pug">
    div(v-show="!loading")
        .row
            .col-xs-12.text-center.warn(style="padding-bottom:5px" v-show="sale.lock")
                i.fa.fa-exclamation-circle.mr3
                span {{'patient.NOPHONE'|translate}}

        .row
            .col-xs-6
                label {{'register.return.sale.SALEID'|translate}}
                .form-control-static
                    a.link(:href="$state.href('app.sale.details', {id: sale.id})"
                        :title="'register.return.sale.title.SALEID'|translate") {{sale.id}}
            .col-xs-6
                .form-group
                    label {{'register.return.sale.SALEDATE'|translate}}
                    .form-control-static
                        div {{sale.created}}
                        .bold.warn(v-show="!reclamationMode && sale.expired")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'register.return.sale.notify.TWOWEEKSSHORT'|translate}}
        .row
            .col-xs-6
                .form-group
                    label {{'register.return.sale.DEPARTMENT'|translate}}
                    .form-control-static {{sale.customerName}}
            .col-xs-6
                .form-group
                    label {{'register.return.sale.QUANTITY'|translate}}
                    .form-control-static {{sale.quantity}}
        .row
            .col-xs-6
                .form-group
                    label {{'register.return.sale.EMPLOYEE'|translate}}
                    .form-control-static {{sale.employee.firstName}} {{sale.employee.lastName}}
            .col-xs-6
                .form-group
                    label {{'register.return.sale.VALUE'|translate}}
                    .form-control-static {{sale.value|currency}}
        .row
            .col-xs-6
                .form-group
                    label(for="patient") {{'register.return.sale.PATIENT'|translate}}
                    .form-control-static
                        OptiPatient(
                            v-if="Object.keys(sale.patient).length"
                            :data="sale.patient")
                        div(v-show="!Object.keys(sale.patient).length")
                            OptiSelectPatient(
                                id="patient"
                                :init-result="sale.search_patient"
                                @selected="updatePatient")
                        #patient.bold.warn(v-show="!Object.keys(sale.patient).length && !Object.keys(sale.search_patient).length") {{'validation.formValidate.required'|translate}}
            .col-xs-6
                .form-group
                    label {{'register.return.sale.VALUEAFTERDISCOUNT'|translate}}
                    .form-control-static {{sale.valueAfterDiscount|currency}}
        .row(v-show="reclamationMode")
            .col-xs-6
                .form-group
                    label(for="proof_type") {{'sale.reclamation.PROOF'|translate}}
                    div
                        OptiSelectEnum#proof_type(enum="ReclamationProof" :value="sale.proof.type" @update-enum="updateProofType")
            .col-xs-6
                .form-group
                    label(for="proof_id") {{'sale.reclamation.PROOFID'|translate}}
                    div
                        input#proof_id.form-control(v-model="sale.proof.id" :disabled="!sale.proof.type")
        .row(v-show="reclamationMode")
            .col-xs-6
                .form-group
                    label(for="proof_photo") {{'sale.reclamation.PROOFPHOTO'|translate}}
                    OptiUpload#proof_photo(
                        prefix="sale_reclamation_proof"

                        :file="sale.proof.picture"
                        :disabled="!sale.proof.type || !sale.proof.id"

                        @update-loading="l => loadingPicture = l"
                        @update-file="updateProofPhoto")
        .row(v-show="reclamationMode")
            .col-xs-6
                .form-group
                    label.star-required(for="date_of_notice") {{'sale.reclamation.DATEOFNOTICE'|translate}}
                    div
                        OptiDate#date_of_notice(:value="sale.date_of_notice" @update-date="updateDate")
                        OptiValidate(f="date_of_notice" :data="$v.sale.date_of_notice")
        div(v-if="more")
            .row
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.PAYMENTTYPE'|translate}}
                        .form-control-static {{sale.paymentType|translate}}
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.NETTO'|translate}}
                        .form-control-static {{sale.netto|currency}}
            .row
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.PAYMENTNOTE'|translate}}
                        .form-control-static {{sale.paymentNote}}
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.VATVALUES'|translate}}
                        .form-control-static
                            div(v-for="(vatValue, vatGroup) in sale.vat") {{vatGroup}}% - {{vatValue.join(', ')}}
            .row
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.RECEIPT'|translate}}
                        .form-control-static {{sale.bill|receipt}}
                .col-xs-6
                    .form-group
                        label {{'register.return.sale.VALUERETURNED'|translate}}
                        .form-control-static
                            span {{sale.value_returned|currency}}
                            span(v-show="sale.value_returned > 0") ({{sale.date_returned|noYearSek}})
        .row
            .col-xs-12
                .form-group
                    label {{'register.return.sale.COMMENTS'|translate}}
                    .form-control-static(v-html="$options.filters.nl2br(sale.comments)")
                .pull-right
                    OptiButton(type="primary" v-show="!more" @click.native="toggle(true)") {{'register.return.sale.info.MORE'|translate}}
                        template(#icon)
                            i.fa.fa-angle-double-down
                    OptiButton(type="primary" v-show="more" @click.native="toggle(false)") {{'register.return.sale.info.LESS'|translate}}
                        template(#icon)
                            i.fa.fa-angle-double-up
</template>

<script>
    import Vue from 'vue'
    import {required} from 'vuelidate/lib/validators'

    import OptiPatient from '../../Patients/OptiPatient'
    import OptiSelectPatient from '../../Select/OptiSelectPatient'
    import OptiDimmer from '../../Blocks/OptiDimmer'
    import OptiSelectEnum from '../../Select/OptiSelectEnum'
    import OptiButton from '../../Button/OptiButton'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiSaleSearchDetails',
        components: {
            OptiPatient,
            OptiSelectPatient,
            OptiDimmer,
            OptiSelectEnum,
            OptiButton,
            OptiUpload,
            OptiDate,
            OptiValidate
        },
        props: {
            reclamationMode: {
                type: Boolean,
                required: false,
                default: false
            },
            sale: {
                type: Object,
                required: true
            },
            more: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                loadingPicture: false
            }
        },
        validations: {
            sale: {
                date_of_notice: {
                    required
                }
            }
        },

        methods: {
            updateProofPhoto(file) {
                this.sale.proof.picture = file

                this.updateSale()
            },

            toggle(bool) {
                this.$emit('toggle', bool)
            },

            updateSale() {
                this.loading = true
                Vue.nextTick(() => {
                    this.loading = false
                })

                this.$emit('get-sale', this.sale)
            },
            updatePatient(patient) {
                this.sale.search_patient = patient

                this.updateSale()
            },
            updateProofType(type) {
                this.sale.proof.type = type

                this.updateSale()
            },
            updateDate(date) {
                this.sale.date_of_notice = date

                this.updateSale()
            }
        }
    }
</script>