<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'product.producent.HEADING'|translate}}
                    span(v-show="supplier_name") - {{supplier_name}}

                    .pull-right(v-show="rows.length")
                        i.fa(:class="{'fa-arrow-up': !toggle, 'fa-arrow-down': toggle}")

                .panel-body(style="padding:0" v-show="toggle")
                    .row
                        .col-md-4.col-sm-6(v-for="row in rows")
                            .table-responsive
                                table.table.table-striped.middle
                                    tbody
                                        tr(v-for="data in row")
                                            td {{data.key}}
                                            td
                                                .old-value(v-show="('old_value' in data)") {{data.old_value ? data.old_value : 'puste'}}
                                                div {{data.value}}

                .panel-body(v-show="!rows.length")
                    .row
                        .col-xs-12.text-center.text-muted
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

                .panel-footer(v-show="permission && toggle")
                    OptiButtonHistory(data="supplier_overwrite" :id="productId")

                    OptiButton.pull-right(type="info" @click.native="dialog = true"
                        ) {{'product.producent.overwrite.HEADING'|translate}}
                        template(#icon)
                            i.fa.fa-edit

                        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
                            OptiDialogProductProducentOverwrite(
                                :product-id="productId"

                                @saved="load"
                                @closed="dialog = false")

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogProductProducentOverwrite
        from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductProducentOverwrite'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductDetailsInfoFromProducent',
        components: {
            OptiButtonHistory,
            OptiButton,
            OptiDialogProductProducentOverwrite,
            OptiDimmer
        },
        props: {
            productId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                supplier_name: '',
                rows: [],

                permission: this.$uac.permission('products.producent_data_overwrite'),
                dialog: false,
                toggle: false,
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('warehouse/product/' + this.productId + '/producent').then(res => {
                    this.supplier_name = res.data.supplier_name
                    this.rows = res.data.rows
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('product.producent.notify.error.LOAD')
                })
            }
        }
    }
</script>