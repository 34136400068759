<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            .table-responsive
                table.table.table-striped
                    thead
                        tr
                            th #
                            th(v-show="!isOffer") {{'tasks.logs.STATUS'|translate}}
                            th {{'tasks.logs.DETAILS'|translate}}
                            th {{'tasks.logs.DATE'|translate}}
                            th {{'tasks.logs.USER'|translate}}
                    tbody
                        tr(v-for="(log, key) in parsed" v-show="log.afterPrinted || show" :class="{'color-aaa': !log.user_id}")
                            td {{parsed.length - key}}
                            td(v-show="!isOffer") {{log.status}}
                            td
                                .bold(style="color:tomato;font-size:1.2em" v-html="log.description"
                                    :class="{'color-aaa': !log.user_id}")
                                div(v-html="log.details")
                            td {{log.date}}
                            td {{log.user}}

                        tr(v-show="!parsed.length")
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

                        tr(v-show="printed && printed !== parsed.length")
                            td.text-center(colspan="100")
                                a.link(@click="show = !show"
                                    ) {{!show ? 'default.table.show.LEFT' : 'default.table.show.HIDELEFT'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsLogsTask',
        components: {
            OptiDimmer
        },
        props: {
            isOffer: {
                type: Boolean,
                required: true
            },
            logs: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                printed: 0,
                show: false
            }
        },
        computed: {
            parsed() {
                let filtered = [],
                    afterPrinted = false

                this.printed = 0

                for(let i = this.logs.length - 1; 0 <= i; --i) {
                    if(!afterPrinted && this.logs[i].details &&
                        this.logs[i].details.includes('<span class="fa fa-print"></span>')) {
                        afterPrinted = true
                        ++this.printed
                    }

                    this.logs[i].afterPrinted = afterPrinted

                    filtered.unshift(this.logs[i])
                }

                if(!afterPrinted) {
                    filtered.forEach(item => {
                        item.afterPrinted = true
                        ++this.printed
                    })
                }

                return filtered
            }
        }
    }
</script>
