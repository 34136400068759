<template lang="pug">
    .row
        .col-xs-12(v-show="!loading.main")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButtonHistory(data="shipment" :id="shipment.id")
                .col-md-4.text-center
                    OptiButton(type="primary" v-show="!shipment.status && !shipment.tour_id && activeDepartmentId === 49 && shipment.department_id === 49"
                        @click.native="$state.go('app.shipment.edit', {shipment_id: shipment.id})") {{'shipments.EDIT'|translate}}
                        template(#icon)
                            i.fa.fa-edit
                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
                    OptiButton(type="primary" v-show="shipment.delivery_type === 30 && shipment.tour_id"
                        :title="'shipments.SHIPMENTIDENTIFICATION'|translate|lower"
                        @click.native="goToPrint") {{'shipments.SHIPMENTIDENTIFICATION'|translate}}
                        template(#icon)
                            i.fa.fa-print

                    OptiButton(type="primary" v-show="shipment.delivery_type === 40 && shipment.tour_id"
                        :title="'shipments.SHIPMENTIDENTIFICATION'|translate|lower"
                        @click.native="goToPrintInPost") {{'shipments.SHIPMENTIDENTIFICATION'|translate}}
                        template(#icon)
                            i.fa.fa-print

        .col-xs-12(v-if="!loading.main")
            form.panel.panel-primary(style="position:relative" name="formShipmentReady")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.breadcrumb.SHIPMENT'|translate}} \#{{shipment.id}}
                    .pull-right \#{{shipment.sent_date}}

                .panel-body
                    .row
                        .col-sm-6
                            .form-group
                                label {{'shipments.STATUS'|translate}}
                                div {{shipment.status_name|translate}}
                            .form-group(v-show="shipment.user.id || shipment.specialist.id")
                                label {{'shipments.CREATEDBY'|translate}}
                                div(v-show="shipment.user.firstName || shipment.user.lastName")
                                    span.mr3 {{shipment.user.firstName}} {{shipment.user.lastName}}
                                    span(v-show="shipment.department") ({{shipment.department.name}})
                                div(v-show="shipment.specialist.id")
                                    span.mr3 {{shipment.specialist.first_name}} {{shipment.specialist.last_name}}
                                    span ({{'dictionary.specialist.breadcrumb.NO'|translate|lower}})
                            .form-group
                                label {{'shipments.new.done.SPEDYTOR'|translate}}
                                div
                                    span.mr3 {{shipment.delivery_type_name|translate}}
                                    small {{shipment.shipment_type_name|translate}}
                            .form-group(v-show="shipment.tour_id")
                                label {{'shipments.COURIERID'|translate}}
                                div(v-show="shipment.delivery_type != 40")
                                    a.link(:href="'https://wwwapps.ups.com/WebTracking/track?track=yes&loc=pl_PL&trackNums=' + shipment.tour_id"
                                        :title="'shipments.COURIERIDTITLE'|translate" target="_blank") {{shipment.tour_id}}
                                    OptiButton#removeIdentificationNumber.hide(text="shipments.action.removenumber.confirm"
                                        @confirmed="removeIdentificationNumber")
                                    label.link.warn(style="padding-left:5px" for="removeIdentificationNumber"
                                        v-show="shipment.status === 0 && shipment.department_id === activeDepartmentId"
                                        :title="'shipments.action.REMOVENUMBERSHIPMENT'|translate") {{'shipments.action.REMOVENUMBERSHIPMENT'|translate}}
                                div(v-show="shipment.delivery_type == 40")
                                    a.link(
                                        :href="'https://inpost.pl/sledzenie-przesylek?number=' + shipment.tour_id"
                                        :title="'shipments.COURIERIDTITLE'|translate"
                                        target="_blank"
                                    ) {{shipment.tour_id}}
                            .form-group(v-show="shipment.value")
                                label {{'shipments.new.done.VALUE'|translate}}
                                div {{shipment.value|currency}}
                            .form-group
                                label {{'shipments.new.done.WEIGHT'|translate}}
                                div {{shipment.weight|kg}}
                            .form-group(v-show="shipment.size")
                                label {{'shipments.size.HEADING'|translate}}
                                div
                                    span.mr3 {{shipment.size_name|translate}}
                                    span.mr3(:title="'shipments.size.WIDTH'|translate|lower") ({{shipment.width|currency}} cm
                                    span.mr3 x
                                    span.mr3(:title="'shipments.size.HEIGHT'|translate|lower") {{shipment.height|currency}} cm
                                    span.mr3 x
                                    span(:title="'shipments.size.LEN'|translate|lower") {{shipment.len|currency}} cm)
                            .form-group(v-show="shipment.cod")
                                label(:title="'shipments.action.ready.CODTITLE'|translate") {{'shipments.action.ready.COD'|translate}}
                                div {{shipment.cod|currency}}
                            .form-group(v-show="shipment.saturday")
                                i.fa.fa-check.mr3
                                span {{'shipments.action.ready.SATURDAY'|translate}}
                            .form-group(v-show="shipment.comment")
                                label {{'shipments.COMMENTS'|translate}}
                                div(v-html="$options.filters.nl2br(shipment.comment)")
                        .col-sm-6
                            .form-group(v-show="shipment.receiver_type !== 3")
                                label {{'shipments.RECEIVERID'|translate}}
                                div
                                    a.link(v-if="shipment.receiver_type === 1" :title="'default.REFERENCETYPE'|translate"
                                        :href="$state.href('app.patient.detail', {id: shipment.receiver_id})" target="_blank"
                                        ) {{shipment.receiver_id}} ({{shipment.receiver_type_name|translate|lower}})
                                    a.link(v-else-if="shipment.receiver_type === 2" :title="'default.REFERENCETYPE'|translate"
                                        :href="$state.href('app.patient.detail', {id: shipment.receiver_id})" target="_blank"
                                        ) {{shipment.receiver_id}} ({{'shipments.receiver.type.PATIENT'|translate|lower}})
                                    span(v-else-if="shipment.receiver_type === 4") {{shipment.receiver_id}} ({{'shipments.receiver.type.SPECIALIST'|translate|lower}})
                                    span(v-else) {{shipment.receiver_id}}
                            .form-group
                                label {{'shipments.RECEIVERNAME'|translate}}
                                div(v-show="shipment.receiver_type === 1 || shipment.receiver_type === 2")
                                    a.link(style="display:block" :title="'default.REFERENCETYPE'|translate"
                                        :href="$state.href('app.patient.detail', {id: shipment.receiver_id})" target="_blank"
                                        ) {{shipment.receiver_name}}
                                div(v-show="shipment.receiver_type !== 1 && shipment.receiver_type !== 2") {{shipment.receiver_name}}
                            .form-group
                                label {{'shipments.RECEIVERADDRESS'|translate}}
                                div
                                    span(v-show="shipment.receiver_street") {{'shipments.RECEIVERTITLESTREET'|translate}} {{shipment.receiver_street}},
                                    span {{shipment.receiver_zip_code}} {{shipment.receiver_city}}
                            .form-group(v-show="shipment.receiver_telephone")
                                label {{'shipments.RECEIVERPHONE'|translate}}
                                div {{shipment.receiver_telephone}}

                    .row
                        .col-xs-12.table-responsive(v-for="(details, carton_index) in shipment.details")
                            table.table.table-striped.middle
                                caption(v-show="shipment.details.length === 1") {{'shipments.item.table.ITEM'|translate}}
                                caption(v-show="shipment.details.length > 1")
                                    span {{'shipments.item.CARTON'|translate}} \#{{carton_index + 1}}
                                    small.text-muted(style="padding-left:5px") {{'shipments.new.done.WEIGHT'|translate|lower}} {{details.weight|kg}}
                                thead
                                    tr
                                        th.text-right #
                                        th.text-right {{'default.REFERENCEID'|translate}}
                                        th {{'shipments.item.table.NAME'|translate}}
                                        th {{'shipments.item.table.DESCRIPTION'|translate}}
                                        th.text-right {{'shipments.item.table.COUNT'|translate}}
                                        th.text-right {{'shipments.item.table.VALUE'|translate}}
                                        th.text-center {{'shipments.item.table.DELIVERED'|translate}}
                                tbody
                                    tr(v-for="(item, key) in details.items")
                                        td.text-right {{details.items.length - key}}
                                        td.text-right
                                            OptiRefId(section="shipment-item" :type-id="item.type" :id="item.ref_id")
                                        td {{item.name}}
                                        td {{item.description}}
                                        td.text-right {{item.quantity}}
                                        td.text-right(NOWRAP) {{item.value|currency}}
                                        td.text-center
                                            .checkbox.c-checkbox.checkbox-primary.inline(
                                                v-if="shipment.status === 1 && shipment.receiver_type === 0 && parseInt(shipment.receiver_id) === activeDepartmentId && ((!(item.task_status >= 8 && shipment.department_id === 49) && item.type !== 1 && item.type !== 2 && item.type !== 12 && item.type !== 13 && item.type !== 14 && item.type !== 15) || (activeDepartmentId === 49 && [4, 12, 16].includes(item.type)))")
                                                label
                                                    input(type="checkbox" v-model="item.delivered" @change="receiveItem(item)")
                                                    span.fa.fa-check
                                            i.fa(v-else :class="{'fa-check': item.delivered,'fa-remove': !item.delivered}")

                                    tr(v-show="!details.items.length")
                                        td.text-center(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}
                                tfoot(v-show="details.items.length > 1")
                                    tr
                                        td(colspan="5")
                                        td.text-right(NOWRAP) {{details.value|currency}}
                                        td

                    .row(style="padding-top:15px" v-show="shipment.status === 0 && !shipment.tour_id && shipment.department_id === activeDepartmentId && shipment.department_id !== 49")
                        .col-sm-7
                            table.table
                                caption {{'shipments.action.ready.ADDIONAL'|translate}}
                            .form-horizontal
                                .form-group
                                    .col-sm-7
                                        label(:title="'shipments.action.ready.CODINPUT'|translate")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" name="cod_checkbox" v-model="data.cod_checkbox"
                                                        @change="data.cod = 0")
                                                    span.fa.fa-check
                                            span {{'shipments.action.ready.CODINPUT'|translate}}
                                    .col-sm-5
                                        OptiNumber#cod(:header="'shipments.action.ready.CODINPUT'|translate" :float="true"
                                            :value="data.cod" @confirmed="c => data.cod = c"
                                            :disabled="!data.cod_checkbox" :required="true") {{data.cod|currency}}
                                        label.warn(for="cod" v-show="data.cod_checkbox && !data.cod") {{'validation.formValidate.required'|translate}}
                                .form-group
                                    .col-sm-7
                                        label(:title="'shipments.action.ready.VALUEINPUT'|translate")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" name="value_checkbox" v-model="data.value_checkbox"
                                                        @change="data.value = 0")
                                                    span.fa.fa-check
                                            span {{'shipments.action.ready.VALUEINPUT'|translate}}
                                    .col-sm-5
                                        OptiNumber#value(:header="'shipments.action.ready.VALUEINPUT'|translate" :float="true"
                                            :value="data.value" @confirmed="c => data.value = c"
                                            :disabled="!data.value_checkbox" :required="true") {{data.value|currency}}
                                        label.warn(for="value" v-show="data.value_checkbox && !data.value") {{'validation.formValidate.required'|translate}}
                                .form-group
                                    .col-sm-12
                                        label(:title="'shipments.action.ready.SATURDAY'|translate")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" name="saturday" v-model="data.saturday"
                                                        @change="data.shipment_type = '07'")
                                                    span.fa.fa-check
                                            span {{'shipments.action.ready.SATURDAY'|translate}}
                                .form-group(v-show="shipment.delivery_type === 30")
                                    label.col-sm-7(style="padding-left:49px" for="shipment_type") {{'shipments.action.ready.SERVICETYPE'|translate}}
                                    .col-sm-5
                                        OptiSelectEnum#shipment_type(enum="ShipmentDeliveryUPSType" :disabled="data.saturday"
                                            :value="data.shipment_type" @update-enum="s => data.shipment_type = s")
                                        label.warn(for="shipment_type" v-show="!data.shipment_type") {{'validation.formValidate.required'|translate}}

                .panel-footer.clearfix(v-opti-empty)
                    OptiButton(type="danger" :title="'shipments.action.CANCELSHIPMENT'|translate|lower"
                        v-if="shipment.status === 0 && shipment.department_id === activeDepartmentId"
                        text="shipments.action.cancel.confirm" @confirmed="cancelShipment") {{'shipments.action.CANCELSHIPMENT'|translate}}
                        template(#icon)
                            i.fa.fa-remove

                    OptiButton.pull-right(type="success" :title="'shipments.action.SETNUMBERSHIPMENT'|translate|lower"
                        v-if="shipment.status === 0 && shipment.department_id === activeDepartmentId && shipment.delivery_type === 30 && data.shipment_type && !shipment.tour_id"
                        @click.native="setIdentificationNumber")  {{'shipments.action.SETNUMBERSHIPMENT'|translate}}
                        template(#icon)
                            i.fa.fa-plane

                    OptiButton.pull-right(type="success" :title="'shipments.action.SENDSHIPMENT'|translate|lower"
                        v-if="shipment.status === 0 && shipment.department_id === activeDepartmentId && shipment.delivery_type !== 30"
                        :disabled="(data.cod_checkbox && !data.cod) || (data.value_checkbox && !data.value) || (shipment.delivery_type === 30 && !data.shipment_type)"
                        @click.native="sendShipment") {{'shipments.action.SENDSHIPMENT'|translate}}
                        template(#icon)
                            i.fa.fa-plane

                    OptiButton.pull-right(type="success" :title="'shipments.action.receive.confirm.BTN'|translate"
                        :disabled="!all_checked && (parseInt(shipment.receiver_id) === activeDepartmentId)"
                        v-if="shipment.status === 1 && ((parseInt(shipment.receiver_id) === activeDepartmentId) || (shipment.delivery_type !== 30 && shipment.receiver_type))"
                        text="shipments.action.receive.confirm" @confirmed="receiveShipment") {{'shipments.action.receive.confirm.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-plane

                OptiDimmer(:active="loading.part")

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiShipmentDetails',
        components: {
            OptiButtonHistory,
            OptiButton,
            OptiRefId,
            OptiNumber,
            OptiSelectEnum,
            OptiDimmer
        },
        data() {
            return {
                shipment: {},
                data: {
                    id: this.$state.params.id,
                    cod_checkbox: false,
                    cod: 0,
                    value_checkbox: false,
                    value: 0,
                    saturday: false,
                    shipment_type: '11'
                },
                all_checked: false,
                activeDepartmentId: this.$uac.user.activeDepartmentId === 149 ? 49 : this.$uac.user.activeDepartmentId,
                loading: {
                    main: true,
                    part: false
                }
            }
        },
        mounted() {
            API.get('warehouse/shipment/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('shipments.notify.loading.ERROR')
            })
        },
        methods: {
            checkAllChecked() {
                this.all_checked = true

                this.shipment.details[0].items.forEach(item => {
                    if(!item.delivered) {
                        this.all_checked = false
                    }
                })
            },
            getData(shipment) {
                this.shipment = shipment
                this.shipment.weight = 0

                console.log('shipment', shipment)
                this.shipment.details.forEach(detail => {
                    detail.value = 0
                    this.shipment.weight += parseFloat(detail.weight)

                    detail.items.forEach(item => {
                        detail.value += parseFloat(item.value)
                    })
                })

                this.checkAllChecked()

                let outcoming = this.shipment.department_id === this.activeDepartmentId

                this.$emit('update-breadcrumb', 'shipments.breadcrumb.' + (outcoming ? 'OUTCOMING' : 'INCOMING'),
                    outcoming ? 'outcomingshipments' : 'incomingshipments', this.shipment.status_name)
            },

            downloadURI(uri, name) {
                let link = document.createElement("a")

                link.download = name
                link.href = uri
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            },
            goToPrint() {
                this.shipment.details.forEach((details, key) => {
                    let link = ''

                  const WAREHOUSE_DEPARTMENT_ID = 49;
                  if(this.activeDepartmentId === WAREHOUSE_DEPARTMENT_ID) {
                        link = window.__env.uploadHost + '/shipment/mets/' + this.shipment.id + (key ? '-' + (key + 1) : '') + '.jpg'
                    } else {
                        link = 'upload/shipment/mets/' + this.shipment.id + (key ? '-' + (key + 1) : '') + '.pdf'
                    }

                    window.open(link)

                    if(this.activeDepartmentId === WAREHOUSE_DEPARTMENT_ID) {
                        this.downloadURI(link, this.shipment.id + '.jpg')
                    }
                })
            },
            goToPrintInPost() {
                window.open(`api/inpost/print/${this.shipment.id}/pdf`)
            },
            removeIdentificationNumber() {
                this.loading.part = true

                API.get('warehouse/shipment/cancel/' + this.shipment.id + '/0').then(res => {
                    this.getData(res.data)

                    if(!Object.keys(res.data).includes('error')) {
                        this.$notify.success('shipments.action.removenumber.OK')
                    } else {
                        this.$notify.error(res.data.error, false, true)
                    }
                }).finally(() => {
                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.removenumber.FAIL')
                })
            },

            receiveItem(item) {
                this.loading.part = true

                API.post('warehouse/shipment/receiveItem', {
                    id: item.id,
                    delivered: item.delivered
                }).finally(() => {
                    this.checkAllChecked()

                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.receive.item.FAIL')
                })
            },

            goRender() {
                this.loading.main = true

                setTimeout(() => {
                    this.loading.main = false
                }, 0)
            },
            cancelShipment() {
                this.loading.part = true

                API.get('warehouse/shipment/cancel/' + this.shipment.id + '/1').then(res => {
                    this.getData(res.data)

                    if(!Object.keys(res.data).includes('error')) {
                        this.$notify.success('shipments.action.cancel.OK')
                    } else {
                        this.$notify.error(res.data.error, false, true)
                    }
                }).finally(() => {
                    this.goRender()

                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.cancel.FAIL')
                })
            },
            setIdentificationNumber() {
                this.loading.part = true

                API.post('warehouse/shipment/identification-number', this.data).then(res => {
                    this.getData(res.data)

                    if(!Object.keys(res.data).includes('error')) {
                        this.$notify.success('shipments.action.setnumber.OK')
                    } else {
                        this.$notify.error(res.data.error, false, true)
                    }
                }).finally(() => {
                    this.goRender()

                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.setnumber.FAIL')
                })
            },
            sendShipment() {
                this.loading.part = true

                API.post('warehouse/shipment/send', this.data).then(res => {
                    this.getData(res.data)

                    this.$notify.success('shipments.action.send.OK')
                }).finally(() => {
                    this.goRender()

                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.send.FAIL')
                })
            },
            receiveShipment() {
                this.loading.part = true

                API.get('warehouse/shipment/receive/' + this.shipment.id).then(res => {
                    this.getData(res.data)

                    this.$notify.success('shipments.action.receive.OK')
                }).finally(() => {
                    this.goRender()

                    this.loading.part = false
                }).catch(() => {
                    this.$notify.error('shipments.action.receive.FAIL')
                })
            }
        }

    }
</script>