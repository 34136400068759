<template lang="pug">
    .text-center
        a(v-if="!autoOpen" style="cursor:pointer" :title="'default.SHOWDETAILS'|translate" @click="dialog = true")
            i.fa.fa-search.fa-2x.fa-fw

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiCatalogOfferPreviewDialog(
                :id="id"

                @reload="$emit('reload')"
                @closed="closed")
</template>

<script>
    import OptiCatalogOfferPreviewDialog from '@/js/app/vue/components/Catalog/Offer/Preview/OptiCatalogOfferPreviewDialog'

    export default {
        name: 'OptiCatalogOfferPreview',
        components: {
            OptiCatalogOfferPreviewDialog
        },
        props: {
            autoOpen: {
                type: Boolean,
                required: false,
                default: false
            },

            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                dialog: this.autoOpen
            }
        },
        methods: {
            closed(rated) {
                this.dialog = false

                if(rated) {
                    this.$emit('reload')
                }
            }
        }
    }
</script>