<template lang="pug">
    .opti-search-menu(v-if="$uac.user.id")
        .opti-search-menu__slope(:class="userColor")
        .opti-search-menu__topic(v-if="topic")
            .label.label-danger {{ topic }}
        .opti-search-menu__content(:class="userColor")

            .opti-search-menu__button(@click="toggleSearch")
                i.icon-magnifier.mr3
                span &nbsp;{{'default.SEARCH'|translate}}

            .opti-search-menu__button(v-show="cartsVisible" @click="toggleOffsidebar")
                i.fa.fa-shopping-cart.mr3
                span &nbsp;{{'carts.CARTS'|translate}}

            .opti-search-menu__department
                OptiSelectChangeActiveDepartment
            .opti-search-menu__user(@click="openMenu")
                .opti-search-menu__user__info {{userRole}}
                .opti-search-menu__user__user {{userName}}
                .opti-search-menu__user__info {{login}}

        OptiSearchPanel(v-if="searchVisible" @close="closePanel")

</template>

<script>
import OptiSelectChangeActiveDepartment from '../Select/OptiSelectChangeActiveDepartment'
import OptiButton from '../Button/OptiButton'
import {EventBus} from '../../../_bridge/EventBus'
import OptiSearchPanel from './OptiSearchPanel'

export default {
    name: 'OptiSearchMenu',
    components: {OptiSearchPanel, OptiButton, OptiSelectChangeActiveDepartment},
    data() {
        return {
            topic: this.$store.getters['globals/env']('topic'),

            searchVisible: false
        }
    },
    mounted() {

    },
    computed: {
        userRole() {
            return this.$uac.user.activeRole.short
        },
        userName() {
            let username = this.$uac.user.username
            let tab      = username.split(' ')

            return (tab[0].charAt(0) + '. ' + tab[1]).trim()
        },
        login() {
            return this.$uac.user.departmentLogin
        },
        userColor() {
            return 'global-user-color-' + this.$uac.user.color
        },
        cartsVisible() {
            return this.$uac.permission('carts.sale.read') || this.$uac.permission('carts.order.read') || this.$uac.permission('carts.sendback.read')
        },
        windowWidth() {
            return this.$store.getters['globals/windowWidth']
        }
    },
    methods: {
        toggleOffsidebar() {
            EventBus.$emitAndApply('ng:emit', {
                name: 'toggleOffsideState'
            })
        },
        toggleSearch() {
            this.searchVisible = !this.searchVisible
        },
        closePanel() {
            this.searchVisible = false
        },
        openMenu() {
            EventBus.$emit('MENU_TOGGLE')
        }
    },
}
</script>

<style scoped lang="less">
.opti-search-menu {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    min-height: 48px;
    z-index: 1050;

    box-shadow: -20px -5px 10px 5px rgba(0, 0, 0, 0.5);

    &__button {
        min-height: 48px;
        min-width: 80px;
        margin-right: 4px;
        cursor: pointer;

        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    &__topic {
        position: absolute;
        left: -60px;
        top: 0;
        height: 100%;

        display: flex;
        align-content: center;
        align-items: center;
        z-index: 15;
        font-size: 1.2em;
    }

    &__content {
        display: flex;
        flex-direction: row;
        padding: 2px;
        z-index: 120;
        position: relative;
        align-items: center;
        align-content: center;
    }

    &__slope {
        transform: skew(45deg);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -48px;
        z-index: 10;

        box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, 0.5);
    }

    &__department {

    }

    &__user {
        cursor: default;
        font-size: 0.9em;
        text-align: center;
        font-weight: bold;
        width: 80px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &__info {
            font-size: 0.8em;
        }
    }

    //& .opti-search-panel {
    //    z-index: 200;
    //}

    @media only screen
    and (max-width: 767px) {
        &__department {
            display: none;
        }
    }

}
</style>
