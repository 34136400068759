<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-phone.mr3
            span {{'patient.token.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            div(v-show="data.type === 'archive'")
                div {{'patient.token.ARCHIVE'|translate}}
                div {{'patient.token.ACTIVATE'|translate}} (+{{data.phone_country_number}}) {{data.phone|number(0)}}.

            div(v-show="data.type === 'patient'")
                div {{'patient.token.patients.HEADING'|translate}}
                .list-group
                    a.list-group-item(v-for="patient in data.patients" :title="'patient.token.patients.TITLE'|translate"
                        :href="$state.href('app.patient.detail', {id: patient.id})" target="_blank")
                        .pull-left.media-object.thumb32(style="padding-top:10px")
                            i.icon-users
                        .media-body
                            div {{patient.id}} {{patient.firstName}} {{patient.lastName}}
                            small.text-muted {{patient.street}}, {{patient.zipCode}} {{patient.city}}
                div {{'patient.token.patients.ASSIGN'|translate}} (+{{data.phone_country_number}}) {{data.phone|number(0)}}.

            div(v-show="data.type === 'activate'") {{'patient.token.ACTIVATE'|translate}} (+{{data.phone_country_number}}) {{data.phone|number(0)}}.

            div(v-show="data.type === 'unlock'") {{'patient.token.unlock.HEADING'|translate}} (+{{data.phone_country_number}}) {{data.phone|number(0)}}.

            .text-center(style="margin-bottom:20px")
                OptiButton(type="info" v-show="!loading.token" @click.native="getToken") {{'patient.token.token.SEND'|translate}}
                    template(#icon)
                        i.fa.fa-envelope-o

                div(v-show="loading.token")
                    i.fa.fa-cog.fa-spin.mr3
                    span {{'patient.token.token.SENDING'|translate}}

            .row.mt
                .col-xs-3.pr-sm
                    span.form-control.bg-separator.text-center.pointer(:class="{'outline': pointer === 0}"
                        @click="setPointer(0)") {{confirm[0]}}
                .col-xs-3.pr-sm.pl-sm
                    span.form-control.bg-separator.text-center.pointer(:class="{'outline': pointer === 1}"
                        @click="setPointer(1)") {{confirm[1]}}
                .col-xs-3.pr-sm.pl-sm
                    span.form-control.bg-separator.text-center.pointer(:class="{'outline': pointer === 2}"
                        @click="setPointer(2)") {{confirm[2]}}
                .col-xs-3.pl-sm
                    span.form-control.bg-separator.text-center.pointer(:class="{'outline': pointer === 3}"
                        @click="setPointer(3)") {{confirm[3]}}

            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(7)") 7
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(8)") 8
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(9)") 9
            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(4)") 4
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(5)") 5
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(6)") 6
            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(1)") 1
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(2)") 2
                .col-xs-4.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(3)") 3
            .row.mt
                .col-xs-4.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click="add(0)") 0
                .col-xs-4.pr-sm.pl-sm
                    button.btn.btn-warning.btn-block.btn-lg(@click="clear") C
                .col-xs-4.pl-sm
                    button.btn.btn-info.btn-block.btn-lg(@click="backspace")
                        i.fa.fa-arrow-left

            OptiDimmer(:active="loading.data")

        template(#footer)
            OptiButton.pull-left(type="danger" @click.native="$emit('closed')") {{'patient.token.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove

            OptiButton(type="success" @click.native="check") {{'patient.token.CONFIRM'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogNumber',
        components: {
            OptiButton,
            OptiDialog,
            OptiDimmer
        },
        props: {
            country: {
                type: Object,
                required: true
            },
            phone: {
                type: String,
                required: true
            },
            patientId: {
                type: Number,
                required: false,
                default: 0
            },
            mode: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                data: [],
                token: undefined,

                pointer: 0,
                confirm: [0, 0, 0, 0],

                loading: {
                    data: true,
                    token: false
                }
            }
        },

        mounted() {
            window.addEventListener('keydown', this.keyPress)

            if(this.mode === 'unlock') {
                this.data = {
                    type: 'unlock',
                    phone_country_number: this.country.phoneCode,
                    phone: this.phone
                }

                this.loading.data = false
            } else {
                API.post('patients/token', {
                    phone_country_id: this.country.id,
                    phone_country_number: this.country.phoneCode,
                    cell_phone_number: this.phone,
                    patient_id: this.patientId
                }).then(res => {
                    this.data = res.data

                    if(this.data.type === 'archive' && this.data.patient_id === this.patientId) {
                        this.$emit('token-success', this.data)

                        this.$notify.success('patient.token.notify.RETURNOLD')
                    }
                }).finally(() => {
                    this.loading.data = false
                }).catch(() => {
                    this.$emit('closed')

                    this.$notify.error('patient.token.notify.error.DATA')
                })
            }
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyPress)
        },

        methods: {
            getToken() {
                this.loading.token = true

                API.get('patient/get-token/' + (this.data.phone_country_number === 48 ? '' :
                    ('00' + this.data.phone_country_number)) + this.data.phone).then(res => {
                    this.token = parseInt(res.data)
                }).finally(() => {
                    this.loading.token = false
                }).catch(() => {
                    this.token = undefined

                    this.$notify.error('patient.token.notify.error.GENERATETOKEN')
                })
            },
            setPointer(pointer) {
                this.pointer = pointer
            },
            add(num) {
                this.$set(this.confirm, this.pointer, num)

                if(this.pointer !== 3) {
                    ++this.pointer
                }
            },
            backspace() {
                this.$set(this.confirm, this.pointer, 0)

                if(this.pointer) {
                    --this.pointer
                }
            },
            clear() {
                this.pointer = 0
                this.confirm = [0, 0, 0, 0]
            },
            check() {
                let confirm = parseInt(this.confirm.join(''))

                if(typeof this.token === 'undefined') {
                    this.$notify.error('patient.token.notify.error.NOTOKEN')
                } else if(confirm < 1000) {
                    this.$notify.error('patient.token.notify.error.CONFIRMTOKEN')
                } else if(this.token === confirm) {
                    this.$emit('token-success', this.data)
                } else {
                    this.$notify.error('patient.token.notify.error.INVALIDTOKEN')
                }
            },
            keyPress(e) {
                let key = e.which

                if(key >= 96 && key <= 106) {
                    key -= 48
                }

                switch(key) {
                    case 48: // 0
                    case 49: // 1
                    case 50: // 2
                    case 51: // 3
                    case 52: // 4
                    case 53: // 5
                    case 54: // 6
                    case 55: // 7
                    case 56: // 8
                    case 57: // 9
                        this.add(key - 48)

                        break
                    case 8: // backspace
                        this.backspace()

                        break
                    case 46: // delete
                        this.clear()

                        break
                    case 13:  // enter
                        this.check()
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 400px;

        .pointer {
            cursor: pointer;
        }

        .outline {
            outline: 3px solid;
        }
    }
</style>