<template lang="pug">
    .panel.panel-default.relative
        OptiDimmer(:active="$asyncComputed.chartData.updating")
        .panel-heading.bold
            span.mr3 Budżet całej sieci
            span(v-if="chartData") (raport wygenerowano {{chartData.date}})
        .panel-body.relative
            LineChart(
                :chart-data="chartData"
                :options="options"
                ref="chart"
                v-if="chartData")
            OptiChartTooltip(
                :model="tooltipModel"
                :chart-data="chartData"
                :chart="$refs.chart"
            )
                template(#default="{body}")
                    OptiChartDepartmentBudgetTooltip(
                        :tooltip="body.tooltip"
                        :chart-data="chartData"
                    )
            .pull-right
                button.btn.btn-default(type="button" @click="budgetSummaryRefresh") Pobierz świeże dane
</template>

<script>
    import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
    import LineChart from "@/js/app/vue/components/Chart/Base/LineChart";
    import {budgetForecastAnnotation} from './Base/OptiChartBudgetAnnotations'
    import OptiChartTooltip from './Base/OptiChartTooltip';
    import OptiChartDepartmentBudgetTooltip from './OptiChartDepartmentBudgetTooltip';
    import {API} from "@/js/app/vue/api";
    import {EventBus} from '../../../_bridge/EventBus'

    export default {
        name: "OptiChartSummaryBudget",
        components: {LineChart, OptiDimmer, OptiChartDepartmentBudgetTooltip, OptiChartTooltip},
        props: {
            data: {
                type: Object,
                required: false
            },
            year: {
                type: [Number, String],
                required: true,
                default: parseInt(moment().format('GGGG'))
            },
            quarter: {
                type: Number,
                required: true,
                default: parseInt(moment().format('Q'))
            }
        },
        data() {
            return {
                chartData: null,
                tooltipModel: null,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    spanGaps: false,
                    tooltips: {
                        mode: 'index',
                        enabled: false,
                        custom: tooltip => {
                            this.tooltipModel = tooltip
                        }
                    },
                    legend: {
                        position: 'right',
                        align: 'end'
                    },
                    elements: {
                        line: {
                            tension: 0.4,
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                    annotation: {
                        annotations: [
                            ...budgetForecastAnnotation(moment(this.year + '-10-10'))
                        ]
                    }
                },
                styles: {
                    position: 'relative',
                    height: '400px'
                }
            }
        },
        //visible trzeba dac na isadmin
        asyncComputed: {
            async chartData() {
                try {
                    let {data} = await API.post('charts/budgetChartSummary', {
                        year: this.year,
                        quarter: this.quarter
                    })
                    // if(!data) {
                    //     return false
                    // } else {
                        _.forEach(data.datasets, dataset => {
                            dataset.pointHoverRadius = 10
                            dataset.pointHitRadius = 10
                            dataset.radius = 6
                        })

                        //osiagniety/skorygowany
                        data.datasets[2].pointStyle = 'star'
                        data.datasets[3].pointStyle = 'star'

                        //ambitny
                        data.datasets[4].pointStyle = 'triangle'
                        data.datasets[5].pointStyle = 'triangle'

                        //centralny
                        data.datasets[6].pointStyle = 'crossRot'
                        data.datasets[7].pointStyle = 'crossRot'

                        return data
                    // }

                } catch (e) {
                        if (!API.isCancel(e)) {
                            console.log(e)
                        }
                }
            }
        },
        methods: {
            async budgetSummaryRefresh() {
                await API.post('charts/budgetChartSummary/update', {
                    year: this.year,
                    quarter: this.quarter
                })
            },
            watchEmit(event) {
                if (event.name === 'notify') {
                    if (event.data.custom && event.data.custom.year && event.data.custom.quarter) {
                        // mamy rok + kwartal
                        // spawdzamy czy this.year/quarter === data.custom.*
                        // jesli tak to this.$asyncComputed.chartData.update()
                        if(event.data.custom.year === this.year && event.data.custom.quarter === this.quarter) {
                            this.$asyncComputed.chartData.update()
                        }
                    }
                }
            }
        },
        mounted() {
            EventBus.$on('ng:emit', this.watchEmit)
        },
        beforeDestroy() {
            EventBus.$off('ng:emit', this.watchEmit)
        }

    }
</script>

<style scoped lang="less">
    .fill-box {
        display: flex;
    }

    .fill-grow {
        flex: 1;
        padding-right: 20px;
    }

    .relative {
        position: relative;
    }

    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

    & td {
          border: 1px solid @table-border-color;
          padding: @table-cell-padding / 2;

    &:first-child {
         border-left: 0;
     }

    &:last-child {
         border-right: 0;
     }
    }
    }

    .chart-tooltip /deep/ .chart-tooltip__body .line-legend {
        display: inline-block;
        margin-right: 4px;
        border: 2px solid transparent;
        width: 20px;
        height: 20px;
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }
</style>
