<template lang="pug">
    .panel.panel-primary
        .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
            i.fa.fa-navicon.mr3
            span {{'optometrist.breadcrumb.section.REFRACTION'|translate}}
            .pull-right
                i.fa(:class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")

        .row(v-show="toggle")
            .col-xs-12
                .row.bg-separator(style="padding-top:10px;padding-bottom:10px;margin-left:0;margin-right:0")
                    .col-xs-4
                        i.fa.fa-file-text.mr3
                        span {{'optometrist.correction.BINOCULAR'|translate}}
                    .col-xs-4.text-center
                        OptiButton(type="info" :disabled="disabled" @click.native="dialog.binocular = true")
                            OptiEnum(enum="InterviewBinocularVision" :id="interview.binocular_vision")
                            template(#icon)
                                i.fa.fa-eye

                        MountingPortal(v-if="dialog.binocular" mountTo="#vue-modal" append)
                            OptiOptometristsDetailsRefractionDialogBinocular(
                                :binocular="interview.binocular_vision"

                                @confirmed="updateBinocular"
                                @cancelled="dialog.binocular = false")

            .col-xs-12
                OptiOptometristsDetailsRefractionFar(
                    :interview="interview"

                    :previouslens="previouslens"
                    :correction="correction"
                    :active-cyl="activeCyl"

                    :disabled="disabled"
                    :disabled-survey="disabledSurvey"

                    @update-cyl="c => $emit('update-cyl', c)"
                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionFarContactLens(
                    :interview="interview"

                    :previouslens="previouslens"
                    :correction="correction"
                    :active-cyl-contact-lens="activeCylContactLens"

                    :disabled="disabled"
                    :disabled-survey="disabledSurvey"

                    @update-cyl-contact-lens="c => $emit('update-cyl-contact-lens', c)"
                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionFarCorrection(
                    :interview="interview"
                    :previous="previous"

                    :previouslens="previouslens"
                    :correction="correction"

                    :disabled="disabled"
                    :disabled-survey="disabledSurvey"

                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionScreeningTests(
                    :interview="interview"
                    :results="results"

                    :disabled="disabled"

                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionNear(
                    :interview="interview"

                    :previouslens="previouslens"
                    :correction="correction"
                    :active-cyl="activeCyl"

                    :disabled="disabled"

                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionNearContactLens(
                    :interview="interview"

                    :previouslens="previouslens"

                    :disabled="disabled"

                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12
                OptiOptometristsDetailsRefractionNearCorrection(
                    :interview="interview"
                    :previous="previous"

                    :previouslens="previouslens"

                    :disabled="disabled"

                    @update-interview="$emit('update-interview', interview)")

            .col-xs-12(style="padding:10px 30px")
                OptiButton(type="info" :disabled="disabled" @click.native="dialog.stereo = true"
                    ) {{'optometrist.correction.STEREO'|translate}}
                    template(#icon)
                        i.fa.fa-eye

                MountingPortal(v-if="dialog.stereo" mountTo="#vue-modal" append)
                    OptiOptometristsDetailsRefractionDialogStereo(
                        :stereo="interview.stereo"

                        @confirmed="updateStereo"
                        @cancelled="dialog.stereo = false")
            .col-xs-12
                OptiOptometristsDescriptionsStereo(:id="interview.stereo" :no-result="true")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiOptometristsDetailsRefractionDialogBinocular
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/OptiOptometristsDetailsRefractionDialogBinocular'

    import OptiOptometristsDetailsRefractionFar
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/OptiOptometristsDetailsRefractionFar'
    import OptiOptometristsDetailsRefractionFarContactLens
      from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/OptiOptometristsDetailsRefractionFarContactLens'
    import OptiOptometristsDetailsRefractionFarCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/OptiOptometristsDetailsRefractionFarCorrection'
    import OptiOptometristsDetailsRefractionScreeningTests
        from '@/js/app/vue/components/Optometrists/Details/Refraction/OptiOptometristsDetailsRefractionScreeningTests'

    import OptiOptometristsDetailsRefractionNear
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/OptiOptometristsDetailsRefractionNear'
    import OptiOptometristsDetailsRefractionNearContactLens
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/OptiOptometristsDetailsRefractionNearContactLens'
    import OptiOptometristsDetailsRefractionNearCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Near/OptiOptometristsDetailsRefractionNearCorrection'

    import OptiOptometristsDetailsRefractionDialogStereo
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/OptiOptometristsDetailsRefractionDialogStereo'
    import OptiOptometristsDescriptionsStereo
        from '@/js/app/vue/components/Optometrists/Descriptions/OptiOptometristsDescriptionsStereo'

    export default {
        name: 'OptiOptometristsDetailsRefractionSurveys',
        components: {
            OptiNumber,
            OptiNumberPrescription,
            OptiButton,
            OptiEnum,

            OptiOptometristsDetailsRefractionDialogBinocular,

            OptiOptometristsDetailsRefractionFar,
            OptiOptometristsDetailsRefractionFarContactLens,
            OptiOptometristsDetailsRefractionFarCorrection,
            OptiOptometristsDetailsRefractionScreeningTests,

            OptiOptometristsDetailsRefractionNear,
            OptiOptometristsDetailsRefractionNearContactLens,
            OptiOptometristsDetailsRefractionNearCorrection,

            OptiOptometristsDetailsRefractionDialogStereo,
            OptiOptometristsDescriptionsStereo,
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            previous: {
                type: Object,
                required: true
            },
            results: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },

            activeCyl: {
                type: String,
                required: true
            },
            activeCylContactLens: {
                type: String,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            },
            disabledSurvey: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                toggle: true,

                dialog: {
                    binocular: false,
                    stereo: false
                }
            }
        },
        mounted() {

        },
        methods: {
            updateBinocular(binocular) {
                this.interview.binocular_vision = binocular

                this.$emit('update-interview', this.interview)

                this.dialog.binocular = false
            },
            updateStereo(stereo) {
                this.interview.stereo = stereo

                this.$emit('update-interview', this.interview)

                this.dialog.stereo = false
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>