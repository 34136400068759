<template lang="pug">
    div
        h4 {{'shipments.ITEMS'|translate}}
        .table-responsive
            table.table.table-striped.middle
                thead
                    tr
                        th #
                        th.text-right {{'default.REFERENCEID'|translate}}
                        th {{'shipments.new.content.NAME'|translate}}
                        th {{'shipments.new.content.DESCRIPTION'|translate}}
                        th.text-right {{'shipments.new.content.QUANTITY'|translate}}
                        th.text-right {{'shipments.new.content.VALUE'|translate}}
                        th.text-right(v-show="step === 2") {{'shipments.new.content.ACTION'|translate}}
                tbody
                    tr(v-for="(item, key) in items" :key="key")
                        td {{items.length - key}}
                        td.text-right
                            OptiRefId(section="shipment-item" :type-id="item.type" :id="item.id")
                        td {{item.name}}
                        td {{item.description}}
                        td.text-right {{item.quantity}}
                        td.text-right {{item.value|currency}}
                        td.text-right(v-show="step === 2")
                            OptiButton.hide(:id="'item-' + key" text="shipments.new.content.dialog.removeitem"
                                @confirmed="$emit('delete-item', item, key)")
                            label.btn.btn-danger(:for="'item-' + key" :title="'shipments.new.content.title.item.REMOVE'|translate")
                                i.fa.fa-trash
                    tr(v-show="!items.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}
</template>

<script>
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiShipmentCreateStepItems',
        components: {
            OptiRefId,
            OptiButton
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            step: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>