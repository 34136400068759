<template lang="pug">
    OptiButton(type="info" :disabled="disabled" @click.native="open = true")
        span.mr3 {{'default.MEASUREMENT'|translate}}
        span(v-show="measurementApp") VisuReal
        template(#icon)
            i.fa.fa-download

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogMeasurement(
                :id="id"
                :department-id="departmentId"
                :measurement-app="measurementApp"

                :is-offer="isOffer"

                :measurement-id="measurementId"

                @update-measurement-id="updateMeasurementId"
                @closed="open = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogMeasurement from '@/js/app/vue/components/Button/Dialogs/OptiDialogMeasurement'

    export default {
        name: 'OptiButtonMeasurement',
        components: {
            OptiButton,
            OptiDialogMeasurement
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            measurementApp: {
                type: Number,
                required: true
            },

            isOffer: {
                type: Boolean,
                required: false,
                default: false
            },

            measurementId: {
                type: Number,
                required: false
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            updateMeasurementId(id) {
                this.$emit('update-measurement-id', id)

                this.open = false
            }
        }
    }
</script>
