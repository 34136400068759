<template lang="pug">
    span.form-control.text-right(:style="{cursor: disabled ? 'not-allowed' : 'pointer'}" :disabled="disabled"
        @click="open = true")
        slot

        MountingPortal(v-if="open && !disabled" mountTo="#vue-modal" append)
            OptiDialogNumberPrescriptionAddiction(:header="header" :none="none"
                :value="value" @confirmed="confirmed" @closed="open = false")
</template>

<script>
    import OptiDialogNumberPrescriptionAddiction from '@/js/app/vue/components/Inputs/Dialogs/OptiDialogNumberPrescriptionAddiction'

    export default {
        name: 'OptiNumberPrescriptionAddiction',
        components: {OptiDialogNumberPrescriptionAddiction},
        props: {
            header: {
                type: String,
                default: '',
                required: true
            },

            none: {
                type: Number | Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },

            value: {
                type: Number | String,
                default: 0,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {

        },
        methods: {
            confirmed(value) {
                this.$emit('confirmed', value)

                this.open = false
            }
        },
        computed: {

        }
    }
</script>
