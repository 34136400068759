import {UAC} from '@/js/app/vue/UserAccessControl'

class ReportOffersRatesHelperClass {
    isOwn(rate) {
        let is_admin = UAC.user.activeRole.admin,
            is_manager = UAC.user.activeRole.manager,
            rate_user_id = parseInt(typeof rate.user !== 'undefined' ? rate.user.id : 0),
            rate_department_id = parseInt(typeof rate.user !== 'undefined' ? rate.user.activeDepartmentId :
                (typeof rate.department !== 'undefined' ? rate.department.id : 0)),
            is_central = typeof rate.department !== 'undefined' ? rate.department.id === 91 : false

        return (is_admin || (is_manager && UAC.user.department.map(d => d.departmentId).includes(rate_department_id)) || UAC.user.id === rate_user_id) &&
            !is_central
    }

    getHref(rate, type) {
        if(this.isOwn(rate)) {
            switch(type) {
                case 'offers':
                    return {
                        state: 'app.patients.offers',
                        params: {
                            search: [
                                'rate:!=:null',
                                'created_at:>=:' + rate.period.start_date.split(' ')[0],
                                'created_at:<=:' + rate.period.end_date.split(' ')[0],
                                rate.user_id ? 'user_id:=:' + rate.user.lastName + ' ' + rate.user.firstName :
                                    'department_id:=:' + rate.department.name
                            ]
                        }
                    }

                case 'temporary_tasks':
                    return {
                        state: 'app.tasks.temporary',
                        params: {
                            search: [
                                'rate:!=:null',
                                'created_at:>=:' + rate.period.start_date.split(' ')[0],
                                'created_at:<=:' + rate.period.end_date.split(' ')[0],
                                rate.user_id ? 'employee:=:' + rate.user.lastName + ' ' + rate.user.firstName :
                                    'departmentId:=:' + rate.department.name
                            ]
                        }
                    }

                case 'tasks':
                    return {
                        state: 'app.tasks.all',
                        params: {
                            search: [
                                'status:>=:accepted',
                                'created:>=:' + rate.period.start_date.split(' ')[0],
                                'created:<=:' + rate.period.end_date.split(' ')[0],
                                rate.user_id ? 'employee:=:' + rate.user.lastName + ' ' + rate.user.firstName :
                                    'departmentId:=:' + rate.department.name
                            ]
                        }
                    }
            }
        }

        return {
            state: '',
            params: {}
        }
    }
}

export const ReportOffersRatesHelper = new ReportOffersRatesHelperClass()