<template lang="pug">
    .row
        .col-xs-12(v-if="!loading && visit")
            .alert.alert-info
                i.fa.fa-user.mr3
                span {{'patient.schedule.patients.CREATE'|translate}} {{visit.first_name}} {{visit.last_name}}

        .col-md-8.col-md-offset-2(v-show="!loading")
            form#form-patients.form-horizontal.form-validate(name="formPatient" @submit.prevent="save")
                .form-group
                    label.col-sm-4.control-label.star-required(for="firstName") {{'patient.FIRSTNAME'|translate}}
                    .col-sm-8
                        input#firstName.form-control(:placeholder="'patient.FIRSTNAME'|translate"
                            name="firstName" v-model="form.firstName" @change="setGender" required)
                        OptiValidate(f="firstName" :data="$v.form.firstName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="lastName") {{'patient.LASTNAME'|translate}}
                    .col-sm-8
                        input#lastName.form-control(:placeholder="'patient.LASTNAME'|translate"
                            name="lastName" v-model="form.lastName" required)
                        OptiValidate(f="lastName" :data="$v.form.lastName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="countryPatient") {{'patient.COUNTRY'|translate}}
                    .col-sm-8
                        select#countryPatient.form-control(name="countryPatient" v-model="form.countryPatient" required)
                            option(v-for="country in countries" :key="country.id" :value="country") {{country.pl}}
                        OptiValidate(f="countryPatient" :data="$v.form.countryPatient.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="city") {{'patient.CITY'|translate}}
                    .col-sm-8
                        OptiSelectCity#city(v-if="form.countryPatient.id === 171"
                            :city="form.city" @selected="c => form.city = c")
                        input#city2.form-control(:placeholder="'patient.CITY'|translate" v-show="form.countryPatient.id !== 171"
                            name="city2" v-model="form.city" required)
                        OptiValidate(f="city" :data="$v.form.city")
                .form-group
                    label.col-sm-4.control-label.star-required(for="zipCode") {{'patient.ZIPCODE'|translate}}
                    .col-sm-8
                        input#zipCode.form-control(:placeholder="'patient.ZIPCODE'|translate" maxlength="6"
                            name="zipCode" v-model="form.zipCode" required)
                        OptiValidate(f="zipCode" :data="$v.form.zipCode")
                .form-group
                    label.col-sm-4.control-label.star-required(for="street") {{'patient.STREET'|translate}}
                    .col-sm-8
                        input#street.form-control(:placeholder="'patient.STREET'|translate"
                            name="street" v-model="form.street" required)
                        OptiValidate(f="street" :data="$v.form.street")
                .form-group
                    label.col-sm-4.control-label(for="pesel") {{'patient.PESEL'|translate}}
                    .col-sm-8
                        input#pesel.form-control(:placeholder="'patient.PESEL'|translate" maxlength="11"
                            name="pesel" v-model="form.pesel" @input="updatePesel($event.target.value)")
                        OptiValidate(f="pesel" :data="$v.form.pesel")
                .form-group
                    label.col-sm-4.control-label.star-required(for="sex") {{'patient.SEX'|translate}}
                    .col-sm-8
                        OptiSelectEnum#sex(enum="PatientSex" :value="form.sex" @update-enum="s => form.sex = s")
                        OptiValidate(f="sex" :data="$v.form.sex")
                .form-group
                    label.col-sm-4.control-label.star-required(for="birthdate") {{'patient.BIRTHDATE'|translate}}
                    .col-sm-8
                        OptiDate#birthdate(:value="form.birthdate" @update-date="updateBirthdate")
                        OptiValidate(f="birthdate" :data="$v.form.birthdate")
                .form-group
                    label.col-sm-4.control-label(for="ageGroup") {{'patient.AGEGROUP'|translate}}
                    .col-sm-8
                        OptiSelectEnum#ageGroup(enum="PatientAgeGroup"
                            :value="form.ageGroup" @update-enum="a => form.ageGroup = a")
                .form-group
                    label.col-sm-4.control-label(for="cellPhoneNumber") {{'patient.CELLPHONENUMBER'|translate}}
                    .col-sm-8
                        OptiCellPhoneInput#cellPhoneNumber(v-if="form.cellPhoneNumberCountry"
                            :country="form.cellPhoneNumberCountry"
                            :phone="form.cellPhoneNumber"

                            @country="c => form.cellPhoneNumberCountry = c"
                            @phone="p => form.cellPhoneNumber = p")
                        OptiValidate(f="cellPhoneNumber" :data="$v.form.cellPhoneNumber")
                .form-group
                    label.col-sm-4.control-label(for="phoneNumber") {{'patient.PHONENUMBER'|translate}}
                    .col-sm-8
                        input#phoneNumber.form-control(:placeholder="'patient.PHONENUMBER'|translate"
                            name="phoneNumber" v-model="form.phoneNumber" minlength="8")
                        OptiValidate(f="phoneNumber" :data="$v.form.phoneNumber")
                .form-group
                    label.col-sm-4.control-label(for="email") {{'patient.EMAIL'|translate}}
                    .col-sm-8
                        input#email.form-control(type="email" :placeholder="'patient.EMAIL'|translate"
                            name="email" v-model="form.email")
                        OptiValidate(f="email" :data="$v.form.email")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="maAgreement" v-model="form.maAgreement")
                                    span.fa.fa-check
                            span {{'patient.MAAGREEMENT'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="occupation") {{'patient.OCCUPATION'|translate}}
                    .col-sm-8
                        input#occupation.form-control(:placeholder="'patient.OCCUPATION'|translate"
                            name="occupation" v-model="form.occupation")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="maMeasurment" v-model="form.maMeasurment")
                                    span.fa.fa-check
                            span {{'patient.MAMEASURMENT'|translate}}
                .form-group
                    .col-sm-offset-4.col-sm-8
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="maYear" v-model="form.maYear")
                                    span.fa.fa-check
                            span {{'patient.MAYEAR'|translate}}
                .form-group
                    .col-sm-offset-4.col-sm-8
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="maTask" v-model="form.maTask")
                                    span.fa.fa-check
                            span {{'patient.MATASK'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="status") {{'patient.STATUS'|translate}}
                    .col-sm-8
                        OptiSelectEnum#status(enum="PatientStatus" :value="form.status" @update-enum="s => form.status = s")
                .form-group
                    label.col-sm-4.control-label(for="source") {{'patient.SOURCE'|translate}}
                    .col-sm-8
                        OptiSelectSourcePatient#source(enum="PatientSource"
                            :source="form.source" @selected="s => form.source = s.name")
                .form-group
                    label.col-sm-4.control-label(for="comments") {{'patient.COMMENTS'|translate}}
                    .col-sm-8
                        textarea#comments.form-control(:placeholder="'patient.COMMENTS'|translate"
                            name="comments" v-model="form.comments")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.form.$invalid")

                        MountingPortal(v-if="dialog_sms" mountTo="#vue-modal" append)
                            OptiDialogSmsToken(
                                :country="form.cellPhoneNumberCountry"
                                :phone="form.cellPhoneNumber"

                                @token-success="addPatient"
                                @closed="dialog_sms = false")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, minLength, email} from 'vuelidate/lib/validators'
    import {noZero, pesel} from '@/js/vue.validators.js'
    import {Storage} from '@/js/app/vue/helpers/Storage'
    import {PESEL} from '@/js/app/vue/helpers/PESEL'
    import {AgeGroup} from '@/js/app/vue/helpers/AgeGroup'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectCity from '@/js/app/vue/components/Select/OptiSelectCity'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiCellPhoneInput from '@/js/app/vue/components/Inputs/OptiCellPhoneInput'
    import OptiSelectSourcePatient from '@/js/app/vue/components/Select/OptiSelectSourcePatient'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDialogSmsToken from '@/js/app/vue/components/Patients/Dialogs/OptiDialogSmsToken'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientAdd',
        components: {
            OptiValidate,
            OptiSelectCity,
            OptiSelectEnum,
            OptiDate,
            OptiCellPhoneInput,
            OptiSelectSourcePatient,
            OptiButtonSave,
            OptiDialogSmsToken,
            OptiDimmer
        },
        data() {
            return {
                form: this.$state.params.patient ? this.$state.params.patient : {
                    countryPatient: {
                        id: 0
                    },
                    city: '',
                    sex: 0,
                    cellPhoneNumber: '',
                    birthdate: '',
                    ageGroup: 0,
                    maAgreement: true,
                    maMeasurment: true,
                    maYear: true,
                    maTask: true,
                    status: 0,
                    source: '',
                    departmentId: this.$uac.user.activeDepartmentId
                },
                visit: this.$state.params.visit,

                countries: [],
                default_country_id: 171,

                dialog_sms: false,

                loading: false
            }
        },
        validations: {
            form: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                countryPatient: {
                    id: {
                        noZero
                    }
                },
                city: {
                    required
                },
                zipCode: {
                    required
                },
                street: {
                    required
                },
                pesel: {
                    pesel
                },
                sex: {
                    noZero
                },
                birthdate: {
                    required
                },
                cellPhoneNumber: {
                    minLength: minLength(8)
                },
                phoneNumber: {
                    minLength: minLength(8)
                },
                email: {
                    email
                }
            }
        },
        beforeMount() {
            this.$store.dispatch('offer/init')
        },
        mounted() {
            this.form.visit_id = this.visit ? this.visit.id : 0

            if(this.form.firstName) {
                this.setGender()
            }

            if(this.form.pesel) {
                this.updatePesel(this.form.pesel)
            }

            if(this.form.birthdate) {
                this.updateBirthdate(this.form.birthdate)
            }

            Storage.get('countryCode').then(countries => {
                this.countries = countries

                this.form.countryPatient = _.find(countries, {id: this.default_country_id})

                this.form.cellPhoneNumberCountry = this.form.cellPhoneNumberCountry ?
                    this.form.cellPhoneNumberCountry : _.find(countries, {id: this.default_country_id})
            })
        },
        methods: {
            setGender() {
                if(this.form.firstName) {
                    API.get('patient/set-gender/' + this.form.firstName).then(res => {
                        if(parseInt(res.data)) {
                            this.form.sex = parseInt(res.data)
                        }
                    }).catch(() => {
                        this.$notify.error('patient.notify.error.SETGENDER')
                    })
                }
            },
            updatePesel(pesel) {
                if(pesel && pesel !== '00000000000' && !this.$v.form.pesel.$invalid) {
                    let resultPesel = PESEL.calculate(pesel)

                    this.form.sex = resultPesel.sex
                    this.updateBirthdate(resultPesel.birthdate)
                }
            },
            updateBirthdate(birthdate) {
                this.form.birthdate = birthdate

                this.form.ageGroup = AgeGroup.calculate(birthdate).group
            },

            addPatient(token) {
                this.loading = true
                this.dialog_sms = false

                this.form.cellPhoneNumberCountryId = this.form.cellPhoneNumberCountry.id
                this.form.countryId = this.form.countryPatient.id

                this.form.token = token

                API.post('patients', this.form).then(res => {
                    if(Object.keys(res.data).includes('type')) {
                        return false
                    }

                    this.$notify.success('patient.ADDED')

                    if(this.is_offer) {
                        this.$store.commit('offer/setPatient', res.data)

                        this.$state.go('app.catalog.offer')
                    } else {
                        this.$state.go('app.patient.detail', {
                            id: res.data.id,
                            '#': 'detail'
                        })
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.notify.error.SAVE')
                })
            },
            save() {
                if(this.form.cellPhoneNumber) {
                    this.dialog_sms = true
                } else {
                    this.addPatient([])
                }
            }
        },
        computed: {
            is_offer() {
                return this.$store.getters['offer/isFindOrCreatePatient']
            }
        }
    }
</script>