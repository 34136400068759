<template lang="pug">
    .row
        .col-xs-12(v-show="!loading.main && data.is_locked")
            .alert.alert-danger.text-center
                i.fa.fa-exclamation-circle.mr3
                span {{'catalog.lenses.notify.LOCK'|translate}}

        .col-xs-12(style="margin-bottom:10px" v-if="!loading.main")
            .row
                .col-sm-5
                    span.mr3 {{'catalog.lenses.properties.navi.SELECTED'|translate}}
                    .btn.btn-purple.active(style="cursor:default") {{data.navi.current.name}}
                .col-sm-7.text-right(v-show="data.navi.others.length")
                    span.mr3 {{'catalog.lenses.properties.navi.SWITCH'|translate}}
                    .btn-group
                        a.btn.btn-default(v-for="other in data.navi.others"
                            :href="$state.href('app.catalog.category', {id: other.href_id})") {{other.name}}

        .col-xs-12.text-center(v-if="!loading.main")
            label(v-if="!data.checked")
                .checkbox.c-checkbox.checkbox-primary.inline
                    label
                        input(type="checkbox" v-model="data.checked" :disabled="!edit_mode" @change="save")
                        span.fa.fa-check
                span {{'catalog.lenses.properties.check.CHECKED'|translate}}

            div(v-else)
                i.fa.fa-check.text-success.mr3
                span.mr3 {{'catalog.lenses.properties.check.BY'|translate}} {{data.checked_by}}, {{data.checked_at}}.

        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading(:style="{'cursor': $uac.permission('catalog.lenses') && 'pointer'}"
                    @click="edit_mode = $uac.permission('catalog.lenses') ? !edit_mode : false")
                    i.fa.fa-navicon.mr3
                    span {{'catalog.lenses.properties.HEADING'|translate}}

                    .pull-right(v-show="$uac.permission('catalog.lenses')")
                        i.fa(:class="{'fa-arrow-up': !edit_mode, 'fa-arrow-down': edit_mode}")

                .panel-body(style="position:relative" v-if="!loading.main")
                    form
                        .row(style="margin-bottom:25px")
                            .col-sm-4
                                label.star-required(for="design") {{'catalog.lenses.properties.DESIGN'|translate}}
                                OptiSelectHoyaDesign#design(
                                    :design="data.design"

                                    :disabled="!edit_mode"

                                    @selected="d => data.design = d")
                                OptiValidate(f="design" :data="$v.data.design.id")
                            .col-sm-2
                                label.star-required(for="index") {{'catalog.lenses.properties.INDEX'|translate}}
                                OptiNumber#index.form-control(style="height:40px;padding-top:8px"
                                    header="Index" :float="true" :disabled="!edit_mode"
                                    :value="data.index" @confirmed="i => data.index = i") {{data.index.toFixed(2)}}
                                OptiValidate(f="index" :data="$v.data.index")
                            .col-sm-3
                                div(v-show="polarized_sensity")
                                    label.invisible {{'catalog.lenses.properties.POLSEN'|translate}}
                                    .form-control(style="height:40px;padding-top:8px" disabled) {{polarized_sensity}}
                            .col-sm-3
                                label.invisible(for="class") {{'catalog.lenses.properties.TYPE'|translate}}
                                OptiSelectEnum#class.form-control(enum="HoyaLensClass" :disabled="!edit_mode"
                                    :value="data.class" @update-enum="t => data.class = t")
                                OptiValidate(f="class" :data="$v.data.class")

                        .row(v-show="data.constructions.filter(c => c.type).length || edit_mode")
                            .col-sm-6(v-show="edit_mode")
                                label.star-required(for="construct0") {{'catalog.lenses.properties.CONSTRUCTION'|translate}}
                                a.btn-add(v-show="edit_mode" :class="{'disabled': data.constructions.filter(c => !c.id).length}"
                                    @click="addConstruct")
                                    i.fa.fa-plus.mr3
                                    span {{'catalog.lenses.properties.ADD'|translate}}
                            .col-sm-3
                                label(for="type0") {{'catalog.lenses.properties.TYPE'|translate}}
                            .col-sm-3(v-show="edit_mode")
                                label.star-required(for="code0") {{'catalog.lenses.properties.CODE'|translate}}

                        template(v-for="(construct, key) in data.constructions" v-if="data.constructions.filter(c => c.type).length || edit_mode")
                            .row.constructions(style="margin-bottom:0" :class="{'bg-separator': key % 2}")
                                .col-sm-6(v-show="edit_mode")
                                    OptiSelectHoyaConstruct(
                                        :id="'construct' + key"
                                        :key="key"
                                        :construct="construct"

                                        :disabled="!edit_mode"

                                        @selected="c => updateConstruct(key, c)")
                                    a.link.warn(:f="'construct' + key" :href="$state.href('app.catalog.category', {id: category_duplicate[key].id})" target="_blank"
                                        v-if="!$v.data.constructions.$each[key].id.$pending && !$v.data.constructions.$each[key].id.duplicateCatalogConstruct && category_duplicate[key]"
                                        ) {{'catalog.lenses.properties.validator.CATALOG'|translate}} {{category_duplicate[key].name}}.
                                    OptiValidate(:f="'construct' + key" :data="$v.data.constructions.$each[key].id")
                                    a.link.warn(v-show="edit_mode && data.constructions.length > 1" @click="deleteConstruct(key)")
                                        i.fa.fa-trash.mr3
                                        span {{'catalog.lenses.properties.DELETE'|translate}}

                                .col-sm-3
                                    input.form-control(:id="'type' + key" :placeholder="'catalog.lenses.properties.TYPE'|translate"
                                        :name="'type' + key" :disabled="!edit_mode" v-model="construct.type")

                                div(:class="{'col-sm-3': edit_mode, 'col-sm-9': !edit_mode}")
                                    div(v-show="edit_mode")
                                        input.form-control(:id="'code' + key" :placeholder="'catalog.lenses.properties.CODE'|translate"
                                            :name="'code' + key" v-model="construct.code")
                                        OptiValidate(:f="'code' + key" v-show="edit_mode" :data="$v.data.constructions.$each[key].code")
                                    div(v-show="!edit_mode")
                                        label(style="margin-right:20px")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" v-model="construct.individual" disabled)
                                                    span.fa.fa-check
                                            span {{'catalog.lenses.properties.INDIVIDUAL'|translate}}

                                        label
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" v-model="construct.construction_params" disabled)
                                                    span.fa.fa-check
                                            span {{'catalog.lenses.properties.CONSTRUCTION_PARAMS'|translate}}

                            .row.constructions(:class="{'bg-separator': key % 2}" v-show="edit_mode")
                                .col-sm-6
                                    label.star-required(:for="'iLog_design' + key") {{'catalog.lenses.properties.ilog.DESIGN'|translate}}
                                    input.form-control(:id="'iLog_design' + key" :placeholder="'catalog.lenses.properties.ilog.DESIGN'|translate"
                                        :name="'iLog_design' + key" v-model="construct.iLog_design")
                                    OptiValidate(:f="'iLog_design' + key" :data="$v.data.constructions.$each[key].iLog_design")

                                .col-sm-3
                                    label.star-required(:for="'iLog_material' + key") {{'catalog.lenses.properties.ilog.MATERIAL'|translate}}
                                    input.form-control(:id="'iLog_material' + key" :placeholder="'catalog.lenses.properties.ilog.MATERIAL'|translate"
                                        :name="'iLog_material' + key" v-model="construct.iLog_material")
                                    OptiValidate(:f="'iLog_material' + key" :data="$v.data.constructions.$each[key].iLog_material")

                                .col-sm-3
                                    label(:for="'fv_code' + key") {{'catalog.lenses.properties.FV_CODE'|translate}}
                                    input.form-control(:id="'fv_code' + key" :placeholder="'catalog.lenses.properties.FV_CODE'|translate"
                                        :name="'fv_code' + key" v-model="construct.fv_code")
                                    //OptiValidate(:f="'fv_code' + key" :data="$v.data.constructions.$each[key].fv_code")
                                    //TODO: jak zmienią wajchę niech będzie to pole wymagane

                                .col-sm-6.text-center(style="margin-top:15px")
                                    label
                                        .checkbox.c-checkbox.checkbox-primary.inline
                                            label
                                                input(type="checkbox" v-model="construct.individual")
                                                span.fa.fa-check
                                        span {{'catalog.lenses.properties.INDIVIDUAL'|translate}}

                                .col-sm-6.text-center(style="margin-top:15px")
                                    label
                                        .checkbox.c-checkbox.checkbox-primary.inline
                                            label
                                                input(type="checkbox" v-model="construct.construction_params")
                                                span.fa.fa-check
                                        span {{'catalog.lenses.properties.CONSTRUCTION_PARAMS'|translate}}

                        .row(style="margin-top:10px" v-show="!loading.sections")
                            .col-sm-4(v-for="section_names in section_names_chunk" v-show="edit_mode || section_names.map(sn => data.selects[sn].filter(s => s.active).length).reduce((a, b) => a + b)")
                                .section(v-for="section_name in section_names" v-show="edit_mode || data.selects[section_name].filter(s => s.active).length")
                                    .section-title.text-expressive(:style="{'cursor': !edit_mode && section_name.startsWith('coloration_') ? 'pointer' : 'default'}"
                                        @click="open_sections[section_name] = !edit_mode && section_name.startsWith('coloration_') ? !open_sections[section_name] : true")
                                        span {{'catalog.lenses.properties.heading.' + section_name|translate}}
                                        i.fa(style="margin-left:3px" :class="{'fa-arrow-up': !open_sections[section_name], 'fa-arrow-down': open_sections[section_name]}"
                                            v-show="!edit_mode && section_name.startsWith('coloration_')")

                                        label(style="margin-left:10px" v-show="edit_mode && section_name.startsWith('coloration_')")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" v-model="all[section_name]" @change="updateAll(section_name)")
                                                    span.fa.fa-check

                                    .label-line(v-for="(option, key) in data.selects[section_name]"
                                        v-show="edit_mode || (!edit_mode && open_sections[section_name] && option.active)" :class="{'bg-separator': key % 2}")
                                        label(:class="{'star-required': requiredColoration(option)}")
                                            .checkbox.c-checkbox.checkbox-primary.inline
                                                label
                                                    input(type="checkbox" :disabled="!edit_mode" v-model="option.active" @change="fillAll")
                                                    span.fa.fa-check
                                            span {{option.name}}

                                        .pull-right(v-if="section_name === 'coatings' && ((edit_mode && option.active) || (!edit_mode && option.coloration.without_cf_and_cg))")
                                            label(:title="'catalog.lenses.properties.coloration.without_cf_and_cg.TITLE'|translate")
                                                .checkbox.c-checkbox.checkbox-primary.inline
                                                    label
                                                        input(type="checkbox" :disabled="!edit_mode" v-model="option.coloration.without_cf_and_cg")
                                                        span.fa.fa-check
                                                span {{'catalog.lenses.properties.coloration.without_cf_and_cg.LABEL'|translate}}

                                        .clearfix

                                    .text-center(style="margin:10px" v-show="!edit_mode && !open_sections[section_name]")
                                        .label.label-primary.mr3 {{data.selects[section_name].filter(s => s.active).length}}
                                        span {{'catalog.lenses.properties.SELECTED'|translate}}

                                    div(style="margin:5px" v-show="section_name === 'coatings' && labelRequiredColoration")
                                        span.warn.mr3 * - {{'catalog.lenses.properties.coloration.REQUIRED'|translate}}
                                        i.fa.fa-lg.fa-info-circle(style="cursor:pointer"
                                            @click="$notify.success('catalog.lenses.properties.coloration.info.COATING', true, true)")

                                    div(style="margin:5px" v-for="mirror_type in ['mirror', 'mirror_light']" v-show="section_name === mirror_type")
                                        span.warn.mr3 {{'catalog.lenses.properties.coloration.REQUIRED'|translate}}
                                        i.fa.fa-lg.fa-info-circle(style="cursor:pointer"
                                            @click="$notify.success('catalog.lenses.properties.coloration.info.' + mirror_type.toUpperCase(), true, true)")

                        OptiDimmer(:active="loading.sections")

                        .row(style="margin-top:15px")
                            .col-sm-6
                                .row(style="margin-bottom:15px")
                                    .col-sm-4
                                        label.star-required(for="delivery_time") {{'catalog.lenses.properties.DELIVERY_TIME'|translate}}
                                        OptiNumber#delivery_time(:header="'catalog.lenses.properties.DELIVERY_TIME'|translate" :disabled="!edit_mode"
                                            :value="data.delivery_time" @confirmed="d => data.delivery_time = d") {{data.delivery_time|day}}
                                        OptiValidate(f="delivery_time" :data="$v.data.delivery_time")
                                    .col-sm-4
                                        label(for="shift_bt") {{'catalog.lenses.properties.SHIFT_BT'|translate}}
                                        select#shift_bt.form-control(name="shift_bt" v-model="data.shift_bt" :disabled="!edit_mode")
                                            option(v-for="shift in select_option_bt" :value="shift") {{shift > 0 ? '+' + shift : shift}}
                                    .col-sm-4
                                        label(for="minimal_thickness") {{'catalog.lenses.properties.MINIMAL_THICKNESS'|translate}}
                                        OptiNumber#minimal_thickness(:header="'catalog.lenses.properties.MINIMAL_THICKNESS'|translate" :disabled="!edit_mode"
                                            :value="data.minimal_thickness" @confirmed="d => data.minimal_thickness = d") {{data.minimal_thickness}}

                                .row(style="margin-bottom:15px")
                                    .col-sm-4
                                        label(for="pa") PA
                                        input.form-control.text-right#pa(v-model="data.pa" :disabled="!edit_mode")
                                    .col-sm-4
                                        label(for="fffa") FFFA
                                        input.form-control.text-right#fffa(v-model="data.fffa" :disabled="!edit_mode")
                                    .col-sm-4
                                        label(for="cvd") CVD
                                        input.form-control.text-right#cvd(v-model="data.cvd" :disabled="!edit_mode")

                                .row
                                    .col-sm-4
                                        label(for="pa_delta") PA &Delta;
                                        input.form-control.text-right#pa_delta(v-model="data.pa_delta" :disabled="!edit_mode")
                                    .col-sm-4
                                        label(for="fffa_delta") FFFA &Delta;
                                        input.form-control.text-right#fffa_delta(v-model="data.fffa_delta" :disabled="!edit_mode")
                                    .col-sm-4
                                        label(for="cvd_delta") CVD &Delta;
                                        input.form-control.text-right#cvd_delta(v-model="data.cvd_delta" :disabled="!edit_mode")

                            .col-sm-6.text-center
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" v-model="data.check_thickness" :disabled="!edit_mode")
                                            span.fa.fa-check
                                    span {{'catalog.lenses.properties.CHECK_THICKNESS'|translate}}

                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" v-model="data.identifier" :disabled="!edit_mode")
                                            span.fa.fa-check
                                    span {{'catalog.lenses.properties.IDENTIFIER'|translate}}

                                div(style="width:50%;margin:15px auto" v-if="edit_mode")
                                    OptiUpload#category_lens_images(
                                        prefix="category_lens_images"
                                        :picture="true"

                                        :file="data.image"

                                        @update-file="i => data.image = i")
                                    OptiValidate(f="category_lens_images" :data="$v.data.image")

                        .row
                            .col-sm-6(style="margin-top:30px" v-if="data.design.hoya_lens_design.length")
                                .bold(style="margin-bottom:5px") {{'catalog.lenses.properties.LINKS'|translate}}
                                .row.row-link-padding(v-for="(hoya_lens_design, key) in data.design.hoya_lens_design" :key="hoya_lens_design.id"
                                    :class="{'bg-separator': key % 2}")
                                    label.col-xs-1.form-control-static.text-right(:for="'design_' + hoya_lens_design.id") {{key + 1}}
                                    div(:class="{'col-xs-9': edit_mode, 'col-xs-11': !edit_mode}")
                                        select.form-control(:id="'design_' + hoya_lens_design.id" :disabled="!edit_mode"
                                            v-model="hoya_lens_design.id" @change="hoya_lens_design.name = data.designs.filter(d => d.id === hoya_lens_design.id)[0].name")
                                            option(v-for="design in data.designs.filter(d => d.id !== data.design.id && (d.id === hoya_lens_design.id || !data.design.hoya_lens_design.map(hd => hd.id).includes(d.id)))"
                                                :value="design.id") {{design.name}}
                                    .col-xs-2.text-center(v-show="edit_mode")
                                        .btn.btn-danger(@click="data.design.hoya_lens_design.splice(key, 1)")
                                            i.fa.fa-trash

                            .col-sm-6.text-center.text-muted(style="margin-top:30px" v-else)
                                i.fa.fa-exclamation-circle.mr3
                                span {{'dictionary.pictogram.link.notify.EMPTY'|translate}}

                            .col-sm-6.text-center(style="margin-top:30px")
                                .bold(style="margin-bottom:5px") {{'catalog.lenses.properties.assembly_method.HEADING'|translate}}
                                OptiSwitchToggleButton(v-if="edit_mode" :checked="data.assembly_method" @input="b => data.assembly_method = b")
                                    template(#left) {{'catalog.lenses.properties.assembly_method.CENTRE_OF_ROTATION'|translate}}
                                    template(#right) {{'catalog.lenses.properties.assembly_method.PUPIL_CENTRE'|translate}}
                                div(v-else)
                                    span(v-if="!data.assembly_method") {{'catalog.lenses.properties.assembly_method.CENTRE_OF_ROTATION'|translate}}
                                    span(v-else) {{'catalog.lenses.properties.assembly_method.PUPIL_CENTRE'|translate}}

                .panel-footer(v-show="edit_mode && !loading.main")
                    .row
                        .col-sm-4.text-left.text-center-sm.text-center-xs.text-left-lg(style="padding-top:5px;padding-bottom:5px")
                            OptiButton(type="success" :disabled="!!data.design.hoya_lens_design.filter(d => !d.id).length"
                                @click.native="data.design.hoya_lens_design.push({id: 0, name: ''})") {{'dictionary.pictogram.link.ADD'|translate}}
                                template(#icon)
                                    i.fa.fa-plus

                        .col-sm-4.text-center(style="padding-top:5px;padding-bottom:5px")
                            OptiButtonHistory(data="hoya_lenses" :id="categoryId")

                        .col-sm-4.text-center-xs.text-right-sm.text-right-md.text-right-lg(style="padding-top:5px;padding-bottom:5px")
                            OptiButton(type="success" :disabled="$v.data.$invalid || !!data.design.hoya_lens_design.filter(d => !d.id).length"
                                text="catalog.lenses.properties.save" @confirmed="save") {{'catalog.lenses.properties.save.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-save

                OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {duplicateCatalogConstruct, duplicateConstructions} from '@/js/app/vue/components/Catalog/Lenses/Properties/OptiCatalogLensesPropertiesValidator'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectHoyaDesign from '@/js/app/vue/components/Select/OptiSelectHoyaDesign'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectHoyaConstruct from '@/js/app/vue/components/Select/OptiSelectHoyaContruct'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiCatalogLensesProperties',
        components: {
            OptiSelectEnum,
            OptiSelectHoyaDesign,
            OptiNumber,
            OptiSelectHoyaConstruct,
            OptiUpload,
            OptiValidate,
            OptiSwitchToggleButton,
            OptiButtonHistory,
            OptiButton,
            OptiDimmer
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                data: {
                    category_id: this.categoryId,
                    design: {
                        id: 0,
                        name: '',
                        hoya_lens_design: []
                    },
                    index: 0
                },

                section_names_chunk: [],
                all: {},

                select_option_bt: _.range(-6, 6 + 1),

                category_duplicate: [],

                open_sections: {},
                edit_mode: false,
                loading: {
                    main: true,
                    sections: false,
                    image: false,
                    graph: false
                }
            }
        },
        validations: {
            data: {
                design: {
                    id: {
                        noZero
                    }
                },
                index: {
                    noZero
                },
                class: {
                    noZero
                },
                constructions: {
                    $each: {
                        id: {
                            duplicateCatalogConstruct: duplicateCatalogConstruct(),
                            duplicateConstructions: duplicateConstructions(),
                            noZero
                        },
                        code: {
                            required
                        },
                        iLog_design: {
                            required
                        },
                        iLog_material: {
                            required
                        }
                    }
                },
                delivery_time: {
                    noZero
                },
                image: {
                    required
                }
            }
        },
        mounted() {
            API.get('catalog/hoya/properties/' + this.categoryId).then(res => {
                this.setData(res.data)

                if(!this.data.constructions.length) {
                    this.addConstruct()
                }

                this.data.section_names.forEach(section_name => {
                    this.$set(this.open_sections, section_name, !section_name.startsWith('coloration_'))
                })

                this.fillAll()

                this.chunkSections()
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('catalog.lenses.properties.notify.error.LOAD')
            })
        },
        methods: {
            loadSections() {
                this.loading.sections = true

                API.post('catalog/hoya/properties/get-selects-from-constructions', {
                    construction_ids: this.data.constructions.filter(c => c.id).map(c => c.id),
                    category_id: this.data.category_id
                }).then(res => {
                    _.forEach(res.data, (section, section_name) => {
                        this.data.selects[section_name] = section
                    })

                    this.fillAll()
                }).finally(() => {
                    this.loading.sections = false
                }).catch(() => {
                    this.$notify.error('catalog.lenses.properties.notify.error.LOAD')
                })
            },

            updateConstruct(key, c) {
                c.type = c.type2

                this.$set(this.data.constructions, key, c)

                if(c.id) {
                    this.loadSections()
                }
            },
            deleteConstruct(key) {
                this.data.constructions.splice(key, 1)

                this.loadSections()
            },
            addConstruct() {
                this.data.constructions.push({
                    id: 0,
                    name: '',
                    type: '',
                    code: '',
                    fv_code: '',
                    individual: false,
                    construction_params: false,
                    iLog_design: '',
                    iLog_material: ''
                })
            },

            chunkSections() {
                this.section_names_chunk = [[], [], []]

                this.data.section_names.forEach(section_name => {
                    this.section_names_chunk[section_name.startsWith('coatings') || section_name.startsWith('mirror') ? 0 :
                        (section_name.startsWith('coloration') ? 1 : 2)].push(section_name)
                })
            },

            save() {
                this.loading.main = true

                API.post('catalog/hoya/properties/save', this.data).then(res => {
                    this.setData(res.data)

                    this.fillAll()

                    this.$emit('saved')

                    this.$notify.success('catalog.lenses.properties.notify.SAVE')
                }).finally(() => {
                    this.edit_mode = false

                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('catalog.lenses.properties.notify.error.SAVE')
                })
            },

            setData(data) {
                this.data = data

                this.graph()

                this.$emit('get-image', this.data.image)
            },

            requiredColoration(option) {
                return option.active && (option.coloration.required || option.coloration.without_cf_and_cg)
            },

            fillAll() {
                _.forEach(this.data.selects, (section, section_name) => {
                    this.all[section_name] = !section.filter(s => !s.active).length
                })
            },
            updateAll(section_name) {
                this.data.selects[section_name].forEach(option => {
                    option.active = this.all[section_name]
                })
            },

            graph() {
                this.loading.graph = true

                API.get('task/hoya/graph').then(res => {
                    let graph = res.data,
                        _return = []

                    this.data.selects.coatings.filter(c => c.active).forEach(c => {
                        let id = [this.data.design.name, this.data.index].concat(c.name)
                            .map(d => d.toString().replaceAll(' ', '_')).join('=')

                        _.forEach(graph['worst-best'], lenses => {
                            if(lenses.map(l => l.id).includes(id)) {
                                _return.push({
                                    coating_id: c.id,
                                    mermaid_id: id
                                })
                            }
                        })

                        if(_return.length) {
                            this.$emit('get-graph', _return)
                        }
                    })


                }).finally(() => {
                    this.loading.graph = false
                }).catch(() => {
                    this.$notify.error('catalog.graph.notify.error.LOAD')
                })
            }
        },
        computed: {
            polarized_sensity() {
                let _return = ''

                this.data.constructions.forEach(construct => {
                    if(construct.name.includes('Sensity')) {
                        _return = 'Sensity'
                    } else if(construct.name.includes('Polarized')) {
                        _return = 'Polaryzacja'
                    }
                })

                return _return
            },
            labelRequiredColoration() {
                let _return = false

                this.data.selects['coatings'].forEach(option => {
                    if(this.requiredColoration(option)) {
                        _return = true
                    }
                })

                return _return
            }
        }
    }

</script>

<style lang="less" scoped>
    /deep/ .multiselect--disabled {
        opacity: 1;
    }

    /deep/ .multiselect__select {
        background: inherit;
    }

    .form-control[disabled] {
        background: inherit !important;
    }

    a.btn-add {
        font-weight: 700;
        color: limegreen;
        margin-left: 15px;
        cursor: pointer;

        &.disabled {
            opacity: .5;
            color: grey;
            cursor: default;
        }
    }

    .constructions {
        padding-top: 10px;

        .form-control {
            height: 40px;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .row > .col-sm-4 > .section {
        border: 1px solid grey;
        border-radius: 3px;
        margin-bottom: 1em;

        > .section-title {
            text-align: center;
            font-weight: 700;
            padding-top: 5px;
        }

        > .label-line {
            display: block;
            padding: 0 10px;

            label {
                margin-bottom: 0px;
            }

            > .pull-right label:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .text-center > label {
        display: block;
    }

    .row.row-link-padding {
        padding: 10px 0;
    }
</style>