<template lang="pug">
    div
        .row(v-if="!loading")

            .col-xs-12(v-show="services.new.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'service.type.NEW'|translate}}
                        .label.label-danger.pull-right(v-show="services.new.length") {{services.new.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'service.CREATED'|translate}}
                                    th {{'service.VALIDFROM'|translate}}
                                    th {{'service.CREATEDBY'|translate}}}
                            tbody
                                tr(v-for="service in services.new")
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: service.id})") {{service.id}}
                                    td {{service.enteringDate}}
                                    td {{service.validFromDate}}
                                    td {{service.enteringSalon.name}} ({{service.enteringUser.firstName}} {{service.enteringUser.lastName}})

            .col-xs-12(v-show="services.utilized.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'service.type.UTILIZED'|translate}}
                        .label.label-danger.pull-right(v-show="services.utilized.length") {{services.utilized.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'service.CREATED'|translate}}
                                    th {{'service.VALIDFROM'|translate}}
                                    th {{'service.UTILIZED'|translate}}
                                    th {{'service.CREATEDBY'|translate}}
                                    th {{'service.UTILIZEDBY'|translate}}
                            tbody
                                tr(v-for="service in services.utilized")
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: service.id})") {{service.id}}
                                    td {{service.enteringDate}}
                                    td {{service.validFromDate}}
                                    td {{service.utilizeDate}}
                                    td {{service.enteringSalon.name}} ({{service.enteringUser.firstName}} {{service.enteringUser.lastName}})
                                    td {{service.utilizeSalon.name}} ({{service.utilizeUser.firstName}} {{service.utilizeUser.lastName}})

            .col-xs-12(v-show="services.valid.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'service.type.VALID'|translate}}
                        .label.label-danger.pull-right(v-show="services.valid.length") {{services.valid.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'service.CREATED'|translate}}
                                    th {{'service.VALIDFROM'|translate}}
                                    th {{'service.CREATEDBY'|translate}}
                            tbody
                                tr(v-for="service in services.valid")
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: service.id})") {{service.id}}
                                    td {{service.enteringDate}}
                                    td {{service.validFromDate}}
                                    td {{service.enteringSalon.name}} ({{service.enteringUser.firstName}} {{service.enteringUser.lastName}})

            .col-xs-12(v-show="services.invalid.length")
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'service.type.INVALID'|translate}}
                        .label.label-danger.pull-right(v-show="services.invalid.length") {{services.invalid.length}}

                    .table-responsive
                        table.table.table-striped
                            thead
                                tr
                                    th Id
                                    th {{'service.CREATED'|translate}}
                                    th {{'service.VALIDFROM'|translate}}
                                    th {{'service.CREATEDBY'|translate}}
                            tbody
                                tr(v-for="service in services.invalid")
                                    td
                                        a.link(:href="$state.href('app.task.detail', {id: service.id})") {{service.id}}
                                    td {{service.enteringDate}}
                                    td {{service.validFromDate}}
                                    td {{service.enteringSalon.name}} ({{service.enteringUser.firstName}} {{service.enteringUser.lastName}})

            .col-xs-12(v-show="!count")
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'service.notify.NOSERVICE'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '../../Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsServices',
        components: {
            OptiDimmer
        },
        data() {
            return {
                services: {},

                count: 0,
                loading: true
            }
        },
        mounted() {
            API.get('patient/serviceContract/fromPatientId/' + this.$state.params.id + '/groupByStatus').then(res => {
                this.services = res.data

                for(let name in this.services) {
                    this.count += this.services[name].length
                }

                this.$emit('update-count', 'services', this.count)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('service.notify.ERRORSERVICE')
            })
        }
    }
</script>
