<template lang="pug">
    .row
        .col-md-8.col-md-offset-2(v-show="!loading.main")
            form.form-horizontal
                .form-group
                    label.col-sm-5.control-label.star-required(for="file") {{'entries.add.FILE'|translate}}
                    .col-sm-7
                        OptiUpload#file(
                            prefix="entries"

                            :file="file"

                            @update-loading="l => loading.file = l"
                            @update-file="f => file = f")
                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButton(type="success" :disabled="!file || loading.file"
                            @click.native="goSave") {{'entries.breadcrumb.ADD'|translate}}
                            template(#icon)
                                i.fa.fa-road

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEntriesAdd',
        components: {
            OptiUpload,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                file: null,

                loading: {
                    file: false,
                    main: false
                }
            }
        },
        methods: {
            goSave() {
                this.loading.main = true

                API.post('entries/add', {
                    file: this.file
                }).then(res => {
                    this.$state.go('app.entries.list')

                    this.$notify.success(res.data, false, true)
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('entries.add.notify.error.ADDED')
                })
            }
        }
     }
</script>