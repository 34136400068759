<template lang="pug">
    aside.offsidebar
        nav
            ul.nav.nav-tabs.nav-justified
                li(:class="{active: carts.active[0]}")
                    a(@click.prevent="setActive(0)")
                        i.fa-lg.fa.fa-shopping-cart.text-success.mr3
                        span {{'carts.type.SALE'|translate}}
                        .label.label-success.pull-right(v-show="carts.sale.highlight") {{'carts.NEW'|translate}}
                li(:class="{active: carts.active[1]}")
                    a(@click.prevent="setActive(1)")
                        i.fa-lg.fa.fa-truck.text-info.mr3
                        span {{'carts.type.ORDER'|translate}}
                        .label.label-success.pull-right(v-show="carts.order.highlight") {{'carts.NEW'|translate}}
                li(:class="{active: carts.active[2]}")
                    a(@click.prevent="setActive(2)")
                        i.fa-lg.fa.fa-archive.text-warning.mr3
                        span {{'carts.type.SENDBACK'|translate}}
                        .label.label-success.pull-right(v-show="carts.sendback.highlight") {{'carts.NEW'|translate}}

            #carts(v-if="!loading")
                OptiCartSale(v-show="carts.active[0]" :sale="carts.sale"
                    @toggled="$emit('toggled', 'app.sale.add')")

                OptiCartOrder(v-show="carts.active[1]" :order="carts.order"
                    @toggled="$emit('toggled', 'app.warehouse.order.add')")

                OptiCartSendback(v-show="carts.active[2]" :sendback="carts.sendback"
                    @toggled="$emit('toggled', 'app.warehouse.return.add')")

            OptiDimmer(:active="loading || pending")
</template>

<script>
    import OptiCartSale from '@/js/app/vue/components/Carts/OptiCartSale'
    import OptiCartOrder from '@/js/app/vue/components/Carts/OptiCartOrder'
    import OptiCartSendback from '@/js/app/vue/components/Carts/OptiCartSendback'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCarts',
        components: {
            OptiCartSale,
            OptiCartOrder,
            OptiCartSendback,

            OptiDimmer
        },
        props: {

        },
        mounted() {
            this.$store.dispatch('carts/setActive', window.localStorage.getItem('cart-active') || 0)

            this.$store.dispatch('carts/fetch')
        },
        methods: {
            setActive(index) {
                this.$store.dispatch('carts/setActive', index)
            }
        },
        computed: {
            carts() {
                return this.$store.getters['carts/getCarts']
            },

            loading() {
                return this.$store.getters['carts/getLoading'] || !this.$uac.isAuthenticated()
            },
            pending() {
                return this.$store.getters['carts/getPending']
            }
        }
    }
</script>

<style scoped lang="less">
    #cart-sale,
    #cart-order,
    #cart-sendback {
        margin: 10px;
    }
</style>