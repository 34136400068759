<template lang="pug">
    .opti-select-source-patient
        VueMultiSelect(
            id="search-permissions"
            :options="permissions"
            :value="permission"
            :clearable="false"
            label="name"
            :placeholder="'search.permission.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            @input="updateSelected"
            :group-select="false"
            :searchable="true")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
            template(slot="option" slot-scope="props")
                span {{props.option.name}}
                small.text-muted(style="display:block") {{props.option.desc}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectPermissions',
        components: {
            VueMultiSelect
        },
        props: {
            permission: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                permissions: []
            }
        },
        mounted() {
            API.get('search/permissions').then(res => {
                this.permissions = res.data
            }).catch(() => {
                this.$notify.error('search.permission.notify.error.LOAD')
            })
        },
        methods: {
            updateSelected(permission) {
                this.$emit('selected', permission)
            }
        }
    }
</script>