const STATE       = {
    INITIAL: '__initial',
    LOADING: '__loading',
    LOADED: '__loaded'
}
const STATE_FIELD = '__state'
const GET_STATE   = '__getState'
const SET_STATE   = '__setState'
export const INIT = '__init'

export default class OptiStore {
    state() {
        return {
            [STATE_FIELD]: STATE.INITIAL
        }
    }

    getModule() {
        return {
            namespaced: true,
            state: this.state(),
            getters: this.getters(),
            actions: this.actions(),
            mutations: this.mutations(),
            modules: this.modules()
        }
    }

    getters() {
        return {
            [GET_STATE](state) {
                return state[STATE_FIELD]
            }
        }
    }

    actions() {
        return {
            async [INIT](store) {
                if (store.state[STATE_FIELD] === STATE.INITIAL) {
                    store.commit(SET_STATE, STATE.LOADING)
                    await store.dispatch('onInit')
                    store.commit(SET_STATE, STATE.LOADED)
                }
            }
        }
    }

    mutations() {
        return {
            [SET_STATE](state, newState) {
                state[STATE_FIELD] = newState
            }
        }
    }

    modules() {
        return {}
    }
}
