<template lang="pug">
    div
        .row(v-if="!loading && !exchange.status && $uac.permission('schedule.exchanges')")
            .col-xs-12.text-right(style="margin-bottom:15px")
                OptiButton(type="primary" @click.native="$state.go('app.user.exchange.edit', {id: $state.params.id})"
                    ) {{'user.exchange.breadcrumb.EDIT'|translate}}
                    template(#icon)
                        i.fa.fa-edit
        .row
            .col-xs-12
                OptiDimmer(:active="loading")
        .row(v-if="!loading")
            .col-md-offset-1.col-md-10
                .form-horizontal.form-validate
                    fieldset
                        .form-group
                            label.col-sm-4.control-label {{'user.exchange.table.STATUS'|translate}}
                            .col-sm-8
                                .form-control-static {{exchange.status_name|translate}}
                        .form-group
                            label.col-sm-4.control-label {{'user.exchange.add.week.HEADER'|translate}}
                            .col-sm-8
                                .form-control-static
                                    span {{week.label}}
                                    .pull-right.text-muted {{week.start}} - {{week.end}}

                    fieldset(v-if="week")
                        .form-group(style="margin-bottom:10px")
                            label.col-sm-4.control-label {{'user.exchange.add.USER1'|translate}}
                            .col-sm-8
                                .form-control-static
                                    a.link(@click="goToUser(employee_1.id)") {{employee_1.name}}
                                    .pull-right.text-muted {{employee_1.department_login}}
                        .form-group(style="margin-bottom:10px")
                            label.col-sm-4.control-label
                                div {{'user.exchange.add.comments.HEADING'|translate}}
                                small {{'user.exchange.add.comments.FOR1'|translate}}
                            .col-sm-8
                                .form-control-static(v-html="$options.filters.nl2br(comments_1)")
                        .form-group(style="margin-bottom:10px" v-show="opinions_1.length")
                            label.col-sm-4.control-label {{'user.exchange.table.OPINION'|translate}}
                            .col-sm-8
                                .form-control-static
                                    a.link(style="display:block" v-for="opinion in opinions_1"
                                        :href="'user_documents/' + opinion.upload|upload" target="_blank")
                                        i.fa.fa-paperclip.mr3
                                        span {{opinion.note}}
                        .form-group.schedule(v-if="employee_2 && employee_2.departments && employee_2.departments.length")
                            table.table.table-striped.middle.text-center.table-schedule.table-schedule-week
                                thead
                                    tr
                                        th(style="width:20%") {{'user.exchange.add.DEPARTMENT'|translate}}
                                        th.day-cell(NOWRAP v-for="day in week.days")
                                            span {{day.label.long}}
                                            .warn {{day.holiday|translate}}

                                tbody(v-show="!exchange.status" v-for="department in employee_1.departments")
                                    tr(v-if="department.preview && department.preview.length")
                                        th(nowrap)
                                            div {{department.name}}
                                            small ({{'user.exchange.add.desc.ASSIGNED'|translate}})
                                        td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                            @click="editCell(department, department.preview[key], schedule.working_hours, week.days[key].label.day, true)")
                                            div(v-if="isCurrentCell(department, department.preview[key], true)")
                                                .current-cell-backdrop
                                                .current-cell-content
                                                    OptiScheduleEditor(
                                                        :events="department.preview[key]"
                                                        :workingHours="schedule.working_hours")
                                            OptiScheduleEditor(v-else
                                            :events="department.preview[key]"
                                                :workingHours="schedule.working_hours")

                                    tr(v-if="current.data === department && preview === true")
                                        td.editor-cell(colspan="8")
                                            .editor
                                                .panel.panel-default
                                                    .panel-body
                                                        OptiScheduleDayPainter(
                                                            mode="exchange"
                                                            :readonly="true"
                                                            :user="current.data"
                                                            :events="current.cell"
                                                            :workingHours="current.working_hours"
                                                            :departmentId="department.id"
                                                            :date="current.date"
                                                            @cancelled="cancelEdit"
                                                            @saved="saveEdit")

                                tbody(v-for="(department, key) in employee_2.departments")
                                    tr
                                        th(nowrap :class="'schedule-td-color-' + key") {{department.name}}
                                        td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                            @click="editCell(department, schedule.events, schedule.working_hours, week.days[key].label.day, false)")
                                            div(v-if="isCurrentCell(department, schedule.events, false)")
                                                .current-cell-backdrop
                                                .current-cell-content
                                                    OptiScheduleEditor(
                                                        :events="schedule.events"
                                                        :workingHours="schedule.working_hours")
                                            OptiScheduleEditor(v-else
                                                :events="schedule.events"
                                                :workingHours="schedule.working_hours")

                                    tr(v-if="current.data === department && preview === false")
                                        td.editor-cell(colspan="8")
                                            .editor
                                                .panel.panel-default
                                                    .panel-body
                                                        OptiScheduleDayPainter(
                                                            mode="exchange"
                                                            :readonly="true"
                                                            :user="current.data"
                                                            :events="current.cell"
                                                            :workingHours="current.working_hours"
                                                            :departmentId="department.id"
                                                            :date="current.date"
                                                            @cancelled="cancelEdit"
                                                            @saved="saveEdit")

                    .form-group(style="margin-bottom:10px")
                        label.col-sm-4.control-label {{'user.exchange.add.USER2'|translate}}
                        .col-sm-8
                            .form-control-static
                                a.link(@click="goToUser(employee_2.id)") {{employee_2.name}}
                                .pull-right.text-muted {{employee_2.department_login}}
                    .form-group(style="margin-bottom:10px")
                        label.col-sm-4.control-label
                            div {{'user.exchange.add.comments.HEADING'|translate}}
                            small {{'user.exchange.add.comments.FOR2'|translate}}
                        .col-sm-8
                            .form-control-static(v-html="$options.filters.nl2br(comments_2)")
                    .form-group(style="margin-bottom:10px" v-show="opinions_2.length")
                        label.col-sm-4.control-label {{'user.exchange.table.OPINION'|translate}}
                        .col-sm-8
                            .form-control-static
                                a.link(style="display:block" v-for="opinion in opinions_2"
                                    :href="'user_documents/' + opinion.upload|upload" target="_blank")
                                    i.fa.fa-paperclip.mr3
                                    span {{opinion.note}}
                    .form-group.schedule(v-if="employee_1 && employee_1.departments && employee_1.departments.length")
                            table.table.table-striped.middle.text-center.table-schedule.table-schedule-week
                                thead
                                    tr
                                        th(style="width:20%") {{'user.exchange.add.DEPARTMENT'|translate}}
                                        th.day-cell(NOWRAP v-for="day in week.days")
                                            span {{day.label.long}}
                                            .warn {{day.holiday|translate}}

                                tbody(v-show="!exchange.status" v-for="department in employee_2.departments")
                                    tr(v-if="department.preview && department.preview.length")
                                        th(nowrap)
                                            div {{department.name}}
                                            small ({{'user.exchange.add.desc.ASSIGNED'|translate}})
                                        td.event-empty.event-week(v-for="(schedule, key) in department.schedule"
                                            @click="editCell(department, department.preview[key], schedule.working_hours, week.days[key].label.day, true)")
                                            div(v-if="isCurrentCell(department, department.preview[key], true)")
                                                .current-cell-backdrop
                                                .current-cell-content
                                                    OptiScheduleEditor(
                                                        :events="department.preview[key]"
                                                        :workingHours="schedule.working_hours")
                                            OptiScheduleEditor(v-else
                                            :events="department.preview[key]"
                                                :workingHours="schedule.working_hours")
                                    tr(v-if="current.data === department && preview === true")
                                        td.editor-cell(colspan="8")
                                            .editor
                                                .panel.panel-default
                                                    .panel-body
                                                        OptiScheduleDayPainter(
                                                            mode="exchange"
                                                            :readonly="true"
                                                            :user="current.data"
                                                            :events="current.cell"
                                                            :workingHours="current.working_hours"
                                                            :departmentId="department.id"
                                                            :date="current.date"
                                                            @cancelled="cancelEdit"
                                                            @saved="saveEdit")

                                tbody(v-for="(department, key) in employee_1.departments")
                                    tr
                                        th(nowrap :class="'schedule-td-color-' + key") {{department.name}}
                                        td.event-empty.event-week(v-for="schedule in department.schedule"
                                            @click="editCell(department, schedule.events, schedule.working_hours, week.days[key].label.day, false)")
                                            div(v-if="isCurrentCell(department, schedule.events, false)")
                                                .current-cell-backdrop
                                                .current-cell-content
                                                    OptiScheduleEditor(
                                                        :events="schedule.events"
                                                        :workingHours="schedule.working_hours")
                                            OptiScheduleEditor(v-else
                                                :events="schedule.events"
                                                :workingHours="schedule.working_hours")

                                    tr(v-if="current.data === department && preview === false")
                                        td.editor-cell(colspan="8")
                                            .editor
                                                .panel.panel-default
                                                    .panel-body
                                                        OptiScheduleDayPainter(
                                                            mode="exchange"
                                                            :readonly="true"
                                                            :user="current.data"
                                                            :events="current.cell"
                                                            :workingHours="current.working_hours"
                                                            :departmentId="department.id"
                                                            :date="current.date"
                                                            @cancelled="cancelEdit"
                                                            @saved="saveEdit")
                    .form-group(v-if="$uac.permission('schedule.exchanges')")
                        .col-sm-4.col-sm-offset-4
                            OptiButton(type="success" v-show="!exchange.status"
                                text="user.exchange.actions.accept.confirm" @confirmed="goAccept"
                                ) {{'user.exchange.actions.accept.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check

                        .col-sm-4
                            OptiButton(type="danger" v-show="!exchange.status || exchange.status === 1"
                                text="user.exchange.actions.cancel.confirm" @confirmed="goCancel"
                                ) {{'user.exchange.actions.cancel.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-remove

            .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(style="margin-top:15px")
                OptiNotes(table="users_exchanges_notes" table-key="exchange_id" :upload="true"
                    :id="exchange.id" :notes="exchange.notes" @update-notes="updateNotes")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiSelectWeek from '@/js/app/vue/components/Select/OptiSelectWeek'
    import OptiSelectEmployeeGroupByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiScheduleEditor from '@/js/app/vue/components/Schedule/Week/OptiScheduleEditor'
    import OptiScheduleDayPainter from'@/js/app/vue/components/Schedule/Week/OptiScheduleDayPainter'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiNotes from '../../OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersExchangeDetails',
        components: {
            OptiSelectWeek, OptiSelectEmployeeGroupByDepartment, OptiScheduleEditor, OptiScheduleDayPainter,
            OptiButton, OptiNotes, OptiDimmer
        },
        data() {
            return {
                exchange: null,
                current: {
                    data: false,
                    cell: false,
                    working_hours: false,
                    date: false
                },

                week: {},

                employee_1: {},
                comments_1: '',
                opinions_1: [],

                employee_2: {},
                comments_2: '',
                opinions_2: [],

                loading: true,
                preview: false
            }
        },
        mounted() {
            API.get('exchange/' + this.$state.params.id).then((res) => {
                this.exchange = res.data

                let date = moment(this.exchange.start_date)
                this.week = {
                    label: date.format('W/GGGG'),
                    start: date.format('YYYY-MM-DD'),
                    end: date.endOf('w').format('YYYY-MM-DD'),
                    days: OptiSelectWeek.methods.fillDays(date.startOf('w'))
                }

                this.employee_1 = this.exchange.employee_1
                this.comments_1 = this.exchange.comments_1

                this.employee_2 = this.exchange.employee_2
                this.comments_2 = this.exchange.comments_2

                this.getOpinions()

                this.getPreview(this.exchange.employee_1)
                this.getPreview(this.exchange.employee_2)

                this.loading = false
            }).catch(() => {
                this.$notify.error('user.exchange.notify.error.LOAD')

                this.loading = false
            })
        },
        methods: {
            isCurrentCell(department, cell, preview) {
                return this.current.data === department && this.current.cell === cell && this.preview === preview
            },
            editCell(department, cell, working_hours, day, preview) {
                this.preview = preview

                if(this.isCurrentCell(department, cell, preview)) {
                    this.cancelEdit()
                    return
                }

                this.cancelEdit()

                Vue.nextTick(() => {
                    this.current.data = department
                    this.current.cell = cell
                    this.current.working_hours = working_hours
                    this.current.date = day
                })
            },
            cancelEdit() {
                this.current.data = false
                this.current.cell = false
                this.current.working_hours = false
                this.current.date = false
            },
            saveEdit(events) {
                this.current.cell.length = 0
                _.forEach(events, event => {
                    this.current.cell.push(event)
                })
            },

            goAccept() {
                this.loading = true

                API.get('exchange/accept/' + this.exchange.id).then((res) => {
                    this.exchange = res.data

                    this.$notify.success('user.exchange.actions.accept.notify.ACCEPTED')
                }).catch(() => {
                    this.$notify.error('user.exchange.actions.accept.notify.error.ACCEPTED')
                }).finally(() => {
                    this.loading = false
                })
            },
            goCancel() {
                this.loading = true

                API.get('exchange/cancel/' + this.exchange.id).then((res) => {
                    this.exchange = res.data

                    this.$notify.success('user.exchange.actions.cancel.notify.CANCELLED')
                }).catch(() => {
                    this.$notify.error('user.exchange.actions.cancel.notify.error.CANCELLED')
                }).finally(() => {
                    this.loading = false
                })
            },

            getOpinions() {
                this.exchange.opinions.forEach(opinion => {
                    if(this.exchange.employee_1.id === opinion.employee_id) {
                        this.opinions_1.push(opinion)
                    } else if(this.exchange.employee_2.id === opinion.employee_id) {
                        this.opinions_2.push(opinion)
                    }
                })
            },

            getPreview(employee) {
                if(typeof employee.departments !== 'undefined') {
                    employee.departments.forEach(department => {
                        API.post('exchange/preview/events', {
                            date: this.week.start,
                            employee_id: employee.id,
                            department_id: department.id
                        }).then((res) => {
                            Vue.set(department, 'preview', res.data)
                        }).catch(() => {
                            this.$notify.error('user.exchange.add.notify.error.PREVIEW')
                        })
                    })
                }
            },

            goToUser(user_id) {
                this.$router.push({
                    name: 'app.user.edit',
                    params: {
                        id: user_id
                    }
                })
            },

            updateNotes(notes) {
                this.exchange.notes = notes
            }
        }
    }
</script>

<style scoped lang="less">
    .current-cell-backdrop {
        border: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: 0;
        z-index: 80;
    }

    .current-cell-content {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        background-color: lightblue;
        padding: 8px 4px;
        z-index: 120;
    }

    .editor-cell {
        border: 0px solid black !important;
        padding: 0;
    }

    .editor {
        border-radius: 2px;
        background-color: lightblue;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        position: relative;
        padding: 4px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        margin-top: 4px;
        z-index: 100;
    }

    .editor .panel {
        margin: 0;
    }

    .event-week {
        cursor: pointer;
    }

    .day-cell {
        display: table-cell;
        white-space: normal;
    }
</style>