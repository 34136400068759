<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:15px" v-show="!loading")
            OptiButtonHistory(data="expense" :id="form.id")

        .col-md-offset-2.col-md-8(v-if="!loading")
            form.form-horizontal(name="formExpenses" @submit.prevent="editExpense")
                .form-group
                    label.col-sm-4.control-label.star-required(for="department") {{'bookkeeping.expenses.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department(:department-id="form.department_id"
                            :simple="true" @selected="d => form.department_id = d.id")
                        OptiValidate(f="department" :data="$v.form.department_id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date") {{'register.expense.current.form.DATEOFDOCUMENT'|translate}}
                    .col-sm-8
                        OptiDate#date(:value="form.date" @update-date="d => form.date = d")
                        OptiValidate(f="date" :data="$v.form.date")
                .form-group
                    label.col-sm-4.control-label.star-required(for="description") {{'bookkeeping.expenses.table.DESCRIPTION'|translate}}
                    .col-sm-8
                        textarea#description.form-control(name="description" v-model="form.description" rows="7" required)
                        OptiValidate(f="description" :data="$v.form.description")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'bookkeeping.expenses.table.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="ExpensesAccountType" :value="form.type" @update-enum="v => form.type = v")
                        OptiValidate(f="type" :data="$v.form.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="costs") {{'bookkeeping.expenses.table.COSTS'|translate}}
                    .col-sm-8
                        OptiSelectEnum#costs(enum="ExpensesAccountCosts" :value="form.costs" @update-enum="v => form.costs = v")
                        OptiValidate(f="costs" :data="$v.form.costs")
                .form-group
                    label.col-sm-4.control-label.star-required(for="nodocument") {{'bookkeeping.expenses.table.DOCID'|translate}}
                    .col-sm-8
                        input#nodocument.form-control(name="nodocument" v-model="form.nodocument" required)
                        OptiValidate(f="nodocument" :data="$v.form.nodocument")
                .form-group
                    label.col-sm-4.control-label.star-required(for="value") {{'bookkeeping.expenses.table.AMOUNT'|translate}}
                    .col-sm-8
                        OptiNumber#value(:header="'bookkeeping.expenses.table.AMOUNT'|translate"
                            :value="form.value" @confirmed="v => form.value = v"
                            :minus="true" :float="true" :required="true") {{form.value|currency}}
                        OptiValidate(f="value" :data="$v.form.value")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        label(for="cyclical")
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" id="cyclical" name="cyclical" v-model="form.cyclical")
                                    span.fa.fa-check
                            span {{'bookkeeping.expenses.table.CYCLICAL'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="cyclical_date" :class="{'star-required': form.cyclical}"
                        ) {{'bookkeeping.expenses.table.CYCLICAL_DATE'|translate}}
                    .col-sm-8
                        OptiDate#cyclical_date(:value="form.cyclical_date" @update-date="d => form.cyclical_date = d")
                        OptiValidate(f="cyclical_date" :data="$v.form.cyclical_date")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="disabled")

                .form-group(v-show="!edit_close_expense")
                    .col-sm-8.col-sm-offset-4
                        i.fa.fa-exclamation-circle.mr3
                        span {{'bookkeeping.expenses.notify.closed.EDIT'|translate}}

                .form-group(v-show="edit_close_expense && form.close")
                    .col-sm-8.col-sm-offset-4
                        i.fa.fa-exclamation-circle.mr3
                        span {{'bookkeeping.expenses.notify.closed.INFO'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiBookkeepingExpensesEdit',
        components: {
            OptiButtonHistory,
            OptiSelectDepartment,
            OptiDate,
            OptiSelectEnum,
            OptiNumber,
            OptiValidate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                form: {},

                edit_close_expense: true,

                loading: true
            }
        },
        validations: {
            form: {
                department_id: {
                    required
                },
                date: {
                    required
                },
                description: {
                    required
                },
                type: {
                    required
                },
                costs: {
                    required
                },
                nodocument: {
                    required
                },
                value: {
                    noZero
                },
                cyclical_date: {
                    required: requiredIf(form => form.cyclical)
                }
            }
        },
        mounted() {
            API.get('bookkeeping/expense/' + this.$state.params.id).then(res => {
                this.form = res.data
                this.form.date = moment(this.form.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')

                this.edit_close_expense = !this.form.close || (this.form.close && this.$uac.permission('bookkeeping.edit_close_expense'))
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('bookkeeping.expenses.notify.error.LOAD')
            })
        },
        methods: {
            editExpense() {
                this.loading = true

                API.post('bookkeeping/expenses/edit', this.form).then(() => {
                    this.$notify.success('bookkeeping.expenses.notify.EDIT')

                    this.$state.go('app.bookkeeping.expenses')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('bookkeeping.expenses.notify.error.EDIT')
                })
            }
        },
        computed: {
            disabled() {
                return this.$v.form.$invalid || !this.edit_close_expense
            }
        }
    }
</script>
