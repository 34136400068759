<template lang="pug">
    .row(v-if="item")
        .col-xs-6.col-xs-offset-3
            .panel.panel-default(style="position:relative;margin-bottom:0")
                .panel-body(style="padding:0")
                    table.table.table-condensed.text-center.middle
                        thead
                            tr.bg-separator
                                th #
                                th {{'catalog.hoya.table.REPORT_ID'|translate}}
                                th {{'catalog.hoya.table.DATE'|translate}}
                                th {{'catalog.hoya.table.ZHC'|translate}}
                                td
                        tbody
                            tr(v-for="(item, index) in sortedItems" :class="{'activePrice': item.active, 'hover': edit}"
                                @click="changeActive(item)")
                                td {{index + 1}}
                                td(v-show="item.report_id > 0")
                                    a.link(:href="$state.href('app.catalog.dailycheck', {id: item.report_id})"
                                        target="_blank" style="color:black") {{item.report_id}}
                                td(v-show="item.report_id === 0") -
                                td {{item.created_at|dateYear}}
                                td {{item.zhc|currency}}
                                td
                                    .checkbox.c-checkbox.checkbox-green(v-show="edit")
                                        label
                                            input(type="radio" name="active" :value="item.active" @change="changeActive(item)" :checked="item.active")
                                            span.fa.fa-check

                            tr(v-if="!sortedItems || (sortedItems && !sortedItems.length)")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                .panel-footer.text-center.clearfix
                    OptiPaginator(style="width:100%"
                        :paginator="paginator"

                        @changed-page="changedPage")

                OptiDimmer(:active="loading")
</template>

<script>
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiHoyaPriceHistory',
        components: {
            OptiPaginator,
            OptiDimmer
        },
        props: {
            item: null
        },
        filters: {
            dateYear(val) {
                return moment(val).format('YYYY-MM-DD')
            }
        },
        data(){
            return {
                history: null,
                edit: false,
                count: 0,

                paginator: {
                    pages: 1,
                    page: 1
                },
                loading: false
            }
        },
        mounted() {
            this.loading = true

            if(this.item.product_type === 'options') {
                this.history = this.item.hoyaOptionsPriceHistory
            } else if(this.item.product_type === 'lenses') {
                this.history = this.item.hoyaLensesPriceHistory
            }

            if(this.history) {
                this.count = this.history.length
                this.paginator.pages = Math.ceil(this.count / 5)
            }

            this.loading = false
        },
        computed: {
            sortedItems() {
                if(this.history) {
                    this.history.sort((a, b) => {
                        let sorted = b.active - a.active
                        if(sorted !== 0) {
                            if (this.edit === false) {
                                return sorted
                            }
                        }

                        return new Date(b.created_at) - new Date(a.created_at)
                    })

                    return this.loadPageHistory(this.history)
                }
            }
        },
        methods: {
            loadPageHistory(history) {
                let page = this.paginator.page

                return history.slice((page - 1) * 5, page * 5)
            },
            changedPage(page) {
                this.paginator.page = page

                this.loadPageHistory(this.history)
            },
            changeActive(item){
                if(this.edit === true) {
                    this.history.forEach(it => {
                        it.active = 0
                    })
                    item.active = 1
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    tr.activePrice {
        background-color: #8dd593;
        color: #3e434c;
    }

    /deep/ tr.hover:hover {
        background-color: #8dd593;
        color: #3e434c;
        font-weight: bolder;
        cursor: pointer;
    }
</style>