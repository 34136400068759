<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-envelope.mr3
            span {{'catalog.offer.mail.HEADER'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .form.form-horizontal
                .form-group
                    label.col-sm-4.control-label {{'catalog.offer.breadcrumb.OFFER'|translate}}
                    .col-sm-8
                        .form-control-static
                            a.link(:title="'default.REFERENCETYPE'|translate"
                                @click="$state.go('app.patient.detail', {id: patient.id, '#': 'offers', offerId: offer.id})"
                                ) {{offer.id}}

                .form-group
                    label.col-sm-4.control-label {{'catalog.offer.created.AT'|translate}}
                    .col-sm-8
                        .form-control-static {{offer.created_at}}

                .form-group
                    label.col-sm-4.control-label {{'patient.breadcrumb.PATIENT'|translate}}
                    .col-sm-8
                        .form-control-static
                            OptiPatient(:data="patient")

                .form-group
                    label.col-sm-4.control-label.star-required(for="offer-e-mail") {{'patient.EMAIL'|translate}}
                    .col-sm-8
                        input#offer-e-mail.form-control(:placeholder="'patient.EMAIL'|translate"
                            name="email" v-model="patient.email")
                        OptiValidate(f="offer-e-mail" :data="$v.patient.email")

                OptiDimmer(:active="loading")

        template(#footer)
            OptiButton.pull-left(@click.native="$emit('close')") {{'default.CLOSE'|translate}}
                template(#icon)
                    i.fa.fa-close

            OptiButton(type="success" text="catalog.offer.mail.btn_send" @confirmed="send"
                :disabled="loading || $v.patient.$invalid") {{'catalog.offer.mail.btn_send.BTN'|translate}}
                template(#icon)
                    i.fa.fa-envelope
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, email} from 'vuelidate/lib/validators'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogOfferDialogEMail',
        components: {
            OptiDialog,
            OptiPatient,
            OptiValidate,
            OptiDimmer,
            OptiButton
        },
        props: {
            offer: {
                type: Object,
                required: true
            },
            patient: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        },
        validations: {
            patient: {
                email: {
                    required,
                    email
                }
            }
        },
        methods: {
            send() {
                this.loading = true

                API.post('catalog/offer/send-e-mail', {
                    patient_id: this.patient.id,
                    offer_id: this.offer.id,
                    e_mail: this.patient.email
                }).then(res => {
                    this.$notify.success('catalog.offer.mail.notify.SEND')

                    this.$emit('close', res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.offer.mail.notify.error.SEND')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
    }
</style>