<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-plus.mr3
            span {{'settings.offer.questions.btn.ADD'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .form-horizontal
                .form-group
                    label.col-lg-4.control-label {{'settings.offer.questions.add.QUESTION'|translate}}
                    .col-lg-8
                        .form-control-static {{'catalog.offer.questions.' + question.name + '.HEADING'|translate}}
                .form-group
                    label.col-lg-4.control-label {{'settings.offer.questions.add.OPTION'|translate}}
                    .col-lg-8
                        .form-control-static {{'catalog.offer.questions.' + question.name + '.options.' + option.name.toUpperCase()|translate}}
                .form-group
                    label.col-lg-4.control-label.star-required(for="attribute") {{'settings.offer.questions.add.ATTRIBUTE'|translate}}
                    .col-lg-8
                        OptiSelectArray#attribute(
                            :data="question.attributes"
                            :disabled="selected"
                            :selected="add"

                            @selected="s => add = s")
                        label.warn(for="attribute" v-show="!add") {{'validation.formValidate.required'|translate}}

        template(#footer)
            OptiButton(type="success" :disabled="!add" @click.native="$emit('add', add)"
                ) {{'settings.offer.questions.btn.ADD'|translate}}
                template(#icon)
                    i.fa.fa-plus

            OptiButton.pull-left(type="danger" @click.native="$emit('close')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectArray from '@/js/app/vue/components/Select/OptiSelectArray'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiSettingsOfferQuestionsAttributeAddDialog',
        components: {
            OptiSelectArray,
            OptiDialog,
            OptiButton
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            option: {
                type: Object,
                required: true
            },

            selected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                add: ''
            }
        },
        mounted() {

        },
        computed: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;

        > .modal-body {
            overflow: unset !important;
        }
    }
</style>