<template lang="pug">
    .table-responsive
        table.table.table-striped.text-center.middle
            thead
                tr
                    th.text-center(style="padding-top:10px;padding-bottom:10px;border-top: 1px solid #aaa" colspan="12"
                        ) {{'optometrist.correction.CORRECTION'|translate|lower}}
                tr
                    th {{'optometrist.acuity.FAR'|translate}}
                    td SPH
                    td CYL
                    td(v-show="correctionCylDistExists") AXS
                    td Prism
                    td(v-show="correctionPrismDistExists") Angle
                    td Add
                    td Visus
                    td C/Z
                    td BinVisus
                    td PD/2
                    td PD
            tbody
                tr
                    th
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.r" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogCorrection(
                                    :interview="interview"
                                    :previous="previous"

                                    side="right"

                                    :previouslens="previouslens"
                                    :correction="correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog.r = false")
                    td
                        OptiNumberPrescription(:header="$filters.translate('prescription.SPHERE') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_sphere" @confirmed="v => updatePowers('dist_r_sphere', v)"
                            :disabled="disabled") {{interview.dist_r_sphere|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_sphere|plus}}
                    td
                        OptiNumberPrescription(:header="$filters.translate('prescription.CYLINDER') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_cylinder" @confirmed="v => updatePowers('dist_r_cylinder', v)"
                            :disabled="disabled") {{interview.dist_r_cylinder|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_cylinder|plus}}
                    td(v-show="correctionCylDistExists")
                        OptiNumber(:class="{'its-error': interview.dist_r_axis === 'brak'}" v-show="interview.dist_r_cylinder"
                            :header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_axis" @confirmed="v => updatePowers('dist_r_axis', v)"
                            :max="180" :disabled="disabled") {{interview.dist_r_axis}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_axis}}
                    td
                        OptiNumberPrescription(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_prd" @confirmed="v => updatePowers('dist_r_prd', v)"
                            :minus="false" :disabled="disabled") {{interview.dist_r_prd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_prd|number(2)}}
                    td(v-show="correctionPrismDistExists")
                        OptiNumber(v-show="interview.dist_r_prd"
                            :header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_base" @confirmed="v => updatePowers('dist_r_base', v)"
                            :max="360" :disabled="disabled") {{interview.dist_r_base}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_base}}
                    td
                        OptiNumberPrescriptionAddiction(:header="$filters.translate('prescription.ADDICTION') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_add" @confirmed="v => updatePowers('dist_r_add', v)"
                            :disabled="disabled") {{interview.dist_r_add|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_add|number(2)}}
                    td
                        span.form-control(disabled) {{interview.dist_r_visus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_visus}}
                    td
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.dist_r_cz" :disabled="true")
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_cz_name|translate}}
                    td(style="border-bottom:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.dist_binvisus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_binvisus}}
                    td
                        OptiNumber(:header="'PD - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                            :value="interview.dist_r_pd" @confirmed="v => updatePowers('dist_r_pd', v)"
                            :float="true" :max="99" :disabled="disabled") {{interview.dist_r_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_pd|number(2)}}
                    td(style="border-bottom:1px solid #aaa" rowspan="2")
                        span.form-control(disabled) {{interview.dist_r_pd + interview.dist_l_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_pd + previous.dist_l_pd|number(2)}}
                tr
                    th(style="border-bottom:1px solid #aaa")
                        button.btn.btn-info(type="button" :disabled="disabledSurvey"
                            @click="dialog.l = true") {{'prescription.LEFT'|translate}}

                            MountingPortal(v-if="dialog.l" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionFarDialogCorrection(
                                    :interview="interview"
                                    :previous="previous"

                                    side="left"

                                    :previouslens="previouslens"
                                    :correction="correction"

                                    @confirmed="updateInterview"
                                    @cancelled="dialog.l = false")
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescription(:header="$filters.translate('prescription.SPHERE') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_sphere" @confirmed="v => updatePowers('dist_l_sphere', v)"
                            :disabled="disabled") {{interview.dist_l_sphere|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_sphere|plus}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescription(:header="$filters.translate('prescription.CYLINDER') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_cylinder" @confirmed="v => updatePowers('dist_l_cylinder', v)"
                            :disabled="disabled") {{interview.dist_l_cylinder|plus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_cylinder|plus}}
                    td(style="border-bottom:1px solid #aaa" v-show="correctionCylDistExists")
                        OptiNumber(:class="{'its-error': interview.dist_l_axis === 'brak'}" v-show="interview.dist_l_cylinder"
                            :header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_axis" @confirmed="v => updatePowers('dist_l_axis', v)"
                            :max="180" :disabled="disabled") {{interview.dist_l_axis}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_axis}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescription(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_prd" @confirmed="v => updatePowers('dist_l_prd', v)"
                            :minus="false" :disabled="disabled") {{interview.dist_l_prd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_prd|number(2)}}
                    td(style="border-bottom:1px solid #aaa" v-show="correctionPrismDistExists")
                        OptiNumber(v-show="interview.dist_l_prd"
                            :header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_base" @confirmed="v => updatePowers('dist_l_base', v)"
                            :max="360" :disabled="disabled") {{interview.dist_l_base}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_r_base}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumberPrescriptionAddiction(:header="$filters.translate('prescription.ADDICTION') + ' - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_add" @confirmed="v => updatePowers('dist_l_add', v)"
                            :disabled="disabled") {{interview.dist_l_add|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_add|number(2)}}
                    td(style="border-bottom:1px solid #aaa")
                        span.form-control(disabled) {{interview.dist_l_visus}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_visus}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiEnum.form-control(enum="InterviewRedGreen" :id="interview.dist_l_cz" :disabled="true")
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_cz_name|translate}}
                    td(style="border-bottom:1px solid #aaa")
                        OptiNumber(:header="'PD - ' + $filters.translate('optometrist.acuity.FAR') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                            :value="interview.dist_l_pd" @confirmed="v => updatePowers('dist_l_pd', v)"
                            :float="true" :max="99" :disabled="disabled") {{interview.dist_l_pd|number(2)}}
                        small.small-correction(v-show="interview.previous_interview_id > 0") {{previous.dist_l_pd|number(2)}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiNumberPrescriptionAddiction from '@/js/app/vue/components/Inputs/OptiNumberPrescriptionAddiction'

    import OptiOptometristsDetailsRefractionFarDialogCorrection
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Far/Dialogs/OptiOptometristsDetailsRefractionFarDialogCorrection'

    export default {
        name: 'OptiOptometristsDetailsRefractionFarCorrection',
        components: {
            OptiButton,
            OptiEnum,

            OptiNumber,
            OptiNumberPrescription,
            OptiNumberPrescriptionAddiction,

            OptiOptometristsDetailsRefractionFarDialogCorrection
        },
        props: {
            interview: {
                type: Object,
                required: true
            },
            previous: {
                type: Object,
                required: true
            },

            previouslens: {
                type: Object,
                required: true
            },
            correction: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            },
            disabledSurvey: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    r: false,
                    l: false
                }
            }
        },
        mounted() {

        },
        methods: {
            updateInterview(interview) {
                  this.dialog.r = false
                  this.dialog.l = false

                this.$emit('update-interview', interview)
            },
            updatePowers(field, value) {
                this.interview[field] = value

                switch(field) {
                    case 'dist_r_sphere':
                    case 'dist_r_add':
                        this.interview.near_r_sphere = this.interview.dist_r_sphere + this.interview.dist_r_add

                        if(this.interview.dist_r_add) {
                            this.interview.amplitude_uncorrected = 0
                        }

                        break

                    case 'dist_l_sphere':
                    case 'dist_l_add':
                        this.interview.near_l_sphere = this.interview.dist_l_sphere + this.interview.dist_l_add

                        break

                    case 'dist_r_cylinder':
                        if(!this.interview.dist_r_cylinder) {
                            this.interview.dist_r_axis = 'brak'
                        }

                        this.interview.near_r_cylinder = this.interview.dist_r_cylinder

                        break

                    case 'dist_l_cylinder':
                        if(!this.interview.dist_l_cylinder) {
                            this.interview.dist_l_axis = 'brak'
                        }

                        this.interview.near_l_cylinder = this.interview.dist_l_cylinder

                        break

                    case 'dist_r_axis':
                        this.interview.near_r_axis = this.interview.dist_r_axis

                        break

                    case 'dist_l_axis':
                        this.interview.near_l_axis = this.interview.dist_l_axis

                        break

                    case 'dist_r_prd':
                        if(!this.interview.dist_r_prd) {
                            this.interview.dist_r_base = 0
                        }

                        break

                    case 'dist_l_prd':
                        if(!this.interview.dist_l_prd) {
                            this.interview.dist_l_base = 0
                        }

                        break

                    case 'dist_r_pd':
                        if(this.interview.dist_r_pd >= 2) {
                            this.interview.near_r_pd = this.interview.dist_r_pd - 2
                        }

                        break

                    case 'dist_l_pd':
                        if(this.interview.dist_l_pd >= 2) {
                            this.interview.near_l_pd = this.interview.dist_r_pd - 2
                        }
                }

                this.$emit('update-interview', this.interview)
            }
        },
        computed: {
            correctionCylDistExists() {
                return this.interview.dist_r_cylinder || this.interview.dist_l_cylinder
            },
            correctionPrismDistExists() {
                return this.interview.dist_r_prd || this.interview.dist_l_prd
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>