<template lang="pug">
    .opti-search-panel
        .opti-search-panel__input
            input.form-control(ref="input" @blur="inputBlurred" :value="search" @input="sendSearch($event.target.value)" autofocus="autofocus")
        .opti-search-panel__loading(v-if="loading")
            i.fa.fa-spin.fa-spinner.fa-5x
        .opti-search-panel__results(v-if="searchResults.length")
            OptiSearchResult(v-for="(item, idx) in searchResults" :item="item" :type="item.type" :key="idx")
        .opti-search-panel__overlimit.alert.alert-danger(v-if="searchOverLimit.length")
            div(v-for="ol in searchOverLimit") {{ol.text}}
        .opti-search-panel__recent(v-if="searchResults.length === 0 && !loading")
            .opti-search-panel__recent_panel
                OptiSearchRecentItem(v-for="(item, idx) in recentlyUsed.patients" :item="item" type="patient" @close="closePanel" :key="idx")
            .opti-search-panel__recent_panel
                OptiSearchRecentItem(v-for="(item, idx) in recentlyUsed.tasks" :item="item" type="task" @close="closePanel" :key="idx")
            .opti-search-panel__recent_panel
                OptiSearchRecentItem(v-for="(item, idx) in recentlyUsed.products" :item="item" type="product" @close="closePanel" :key="idx")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiSearchResult from './OptiSearchResult'
    import OptiSearchRecentItem from './OptiSearchRecentItem'

    export default {
        name: 'OptiSearchPanel',
        components: {
            OptiSearchResult,
            OptiSearchRecentItem
        },
        data() {
            return {
                search: '',
                searchTimeout: false,
                searchResults: [],
                searchOverLimit: [],
                loading: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.input.focus()
                this.$refs.input.select()
            })

            let search = JSON.parse(window.localStorage.getItem('search-result'))
            if(search) {
                this.search = search.name
                this.searchResults = search.results
                this.searchOverLimit = search.overLimit
            }

            EventBus.$on('SEARCH_CLOSE', () => {
                this.closePanel()
            })
        },
        methods: {
            inputBlurred() {
                if (this.$store.getters['globals/windowWidth'] > 1024) {
                    this.closePanel()
                }
            },
            closePanel() {
                setTimeout(() => {
                    this.$emit('close')
                }, 250)
            },
            clearSearchTimeout() {
                if(this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                    this.searchTimeout = false
                }
            },
            sendSearch(search) {
                this.clearSearchTimeout()

                this.search = search
                this.searchResults = []
                this.searchOverLimit = []
                this.loading = true

                this.searchTimeout = setTimeout(() => {
                    if(this.search.trim().length > 0) {
                        console.log('sending... ', this.search)

                        API.post('search', {
                            search: this.search.trim()
                        }).then(res => {
                            if(res.data && res.data.items) {
                                this.searchResults = res.data.items
                                this.searchOverLimit = res.data.overLimit
                            } else {
                                this.searchResults = res.data
                                this.searchOverLimit = []
                            }

                            window.localStorage.setItem('search-result', JSON.stringify({
                                name: this.search.trim(),
                                results: this.searchResults,
                                overLimit: this.searchOverLimit
                            }))
                        }).finally(() => {
                            this.loading = false
                        }).catch(() => {
                            this.$notify.error('search.result.notify.error.LOAD')
                        })
                    } else {
                        window.localStorage.removeItem('search-result')
                        this.loading = false
                    }
                }, 500)
            }
        },
        computed: {
            recentlyUsed() {
                let memory = localStorage['ngStorage-memory']

                if(memory) {
                    return JSON.parse(memory)
                }

                return []
            }
        },
    }
</script>

<style scoped lang="less">
.opti-search-panel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    &__input {
        margin: 60px;
        margin-top: 80px;
        width: 60%;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);

        & /deep/ .form-control {
            font-size: 20px;
            line-height: 2;
            text-align: center;
            height: 48px;
        }
    }

    &__results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-x: auto;
        height: 100%;
        width: 90%;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__overlimit {
        text-align: center;
    }

    &__loading {
        margin: 60px;
    }

    &__recent {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        overflow-x: auto;

        &_panel {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background-color: rgba(100, 100, 100, 0.3);
            padding: 10px;
            margin: 10px;
        }
    }
}
</style>
