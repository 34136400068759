<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-trash.mr3
            span {{'transfers.details.action.cancel.BTN'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-12(style="padding-bottom:5px") {{'transfers.details.action.cancel.confirm.MESSAGE'|translate}}
                .col-xs-12
                    label.bold(for="comments" style="display:block") {{'transfers.details.action.cancel.confirm.COMMENTS'|translate}}
                    textarea#comments.form-control(rows="5" name="comments" v-model="comments"
                        :placeholder="'transfers.details.action.cancel.confirm.REASON'|translate")
                    label.bold.warn(style="display:block" for="comments" v-show="!comments") {{'validation.formValidate.required'|translate}}

        template(#footer)
            OptiButton(type="success" :disabled="!comments" @click.native="$emit('confirmed', comments)"
                ) {{'transfers.details.action.cancel.confirm.YES'|translate}}
                template(#icon)
                    i.fa.fa-check
            OptiButton(type="danger" @click.native="$emit('closed')") {{'transfers.details.action.cancel.confirm.NO'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTransferDetailsDialogReasonCancel',
        components: {
            OptiDialog,
            OptiButton
        },
        data() {
            return {
                comments: ''
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
    }
</style>