<template lang="pug">
    .row(style="position:relative")
        .col-xs-12(style="margin-bottom:10px")
            .row
                #padding-top-bottom.col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg.fa-lg
                    span(:title="'catalog.offer.title.count.PRODUCTS'|translate" v-show="products.length")
                        i.icon.icon-eyeglasses.mr3
                        span {{products_count|number(0)}}

                .col-md-4.padding-top-bottom <!-- taki v-if by odświeżyć department_id -->
                    OptiSelectDepartment(v-if="department_id !== -1 && (!loading || products.length)"
                        :department-id="department_id"
                        :simple="true"

                        @selected="d => $store.dispatch('offer/updateDepartmentId', d.id)")

                .col-md-4.text-center-xs.text-center-sm.text-right-md.text-right-lg.padding-top-bottom(v-show="favorites.length")
                    OptiButton(@click.native="$store.commit('offer/setDialog', {type: 'favorites', bool: true})")
                        span(style="margin-right:7px") {{'catalog.offer.favorite.HEADER'|translate}}
                        .label.label-primary {{favorites.length}}
                        template(#icon)
                            i.fa.fa-heart

                    MountingPortal(v-if="dialog_favorites" mountTo="#vue-modal" append)
                        OptiCatalogOfferDialogFavorites

        .col-xs-12(style="margin-bottom:20px" v-show="products.length")
            .row(style="position:relative")
                .col-md-4.col-sm-6.offer-product(v-for="product in products" :key="product.id")
                    OptiProductInfo(
                        :product="product"
                        :department-id="department_id"

                        :is-offer="true"
                        :selected="!!chose_products.filter(p => p.product.id === product.id).length"
                        :is-heart="!!favorites.filter(p => p.id === product.id).length")

                    .text-center(v-if="mode === 'choice'")
                        .btn.btn-success(:title="'catalog.offer.choice.title.SELECT'|translate"
                            :disabled="loading || chose_products.filter(p => !p.choice_by_client).length > 2 || chose_products.map(c => c.product.id).includes(product.id)"
                            @click="$store.commit('offer/setChoseProduct', {product: product, choice_by_client: false})"
                            ) {{'catalog.offer.choice.button.CHECK'|translate}}

                        .btn.btn-default(:title="'catalog.offer.favorite.title.ADD'|translate"
                            v-if="!favorites.filter(p => p.id === product.id).length"
                            :disabled="loading" @click="$store.commit('offer/setFavorite', product)")
                            i.fa.fa-heart

                        .btn.btn-default(:title="'catalog.offer.favorite.title.DELETE'|translate" v-else
                            @click="$store.commit('offer/deleteFavorite', product)")
                            i.fa.fa-ban

                OptiNavigator(
                    :paginator="paginator"
                    :loading="loading"

                    @load="p => $store.dispatch('offer/loadProducts', p)")

        .col-xs-12(v-show="products.length")
            .row
                .col-xs-12
                    OptiPaginator(
                        :paginator="paginator"

                        @changed-page="p => $store.dispatch('offer/loadProducts', p)")

        .col-xs-12.text-center.text-muted.fa-2x(v-show="!loading && !products.length")
            i.fa.fa-exclamation-circle.fa-2x
            div {{'catalog.offer.notify.none.PRODUCTS'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferDialogFavorites
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogFavorites'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiNavigator from '@/js/app/vue/components/OptiNavigator'
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogOfferProducts',
        components: {
            OptiSelectDepartment,
            OptiButton,
            OptiCatalogOfferDialogFavorites,
            OptiProductInfo,
            OptiNavigator,
            OptiPaginator,
            OptiDimmer
        },
        computed: {
            products() {
                return this.$store.getters['offer/getProducts']
            },
            products_count() {
                return this.$store.getters['offer/getProductsCount']
            },

            chose_products() {
                return this.$store.getters['offer/getChoseProducts']
            },
            favorites() {
                return this.$store.getters['offer/getFavorites']
            },

            department_id() {
                return this.$store.getters['offer/getDepartmentId']
            },

            paginator() {
                let paginator = this.$store.getters['offer/getPaginator']

                if(parseInt(this.$state.params.page) !== parseInt(paginator.page)) {
                    this.$state.go('app.catalog.offer', {
                        page: paginator.page
                    }, {
                        notify: false
                    })
                }

                return paginator
            },

            dialog_favorites() {
                return this.$store.getters['offer/getDialog']('favorites')
            },
            mode() {
                return this.$store.getters['offer/getMode']
            },
            loading() {
                return this.$store.getters['offer/getLoading']('products')
            }
        }
    }
</script>

<style lang="less" scoped>
    #padding-top-bottom {
        @padding: 20px;

        padding-top: @padding;
        padding-bottom: @padding;
    }

    .padding-top-bottom {
        @padding: 10px;

        padding-top: @padding;
        padding-bottom: @padding;
    }

    i.fa.fa-heart {
        color: red;
        text-shadow: 0 0 5px #fff;
    }
</style>