<template lang="pug">
    div
        input.form-control(
            v-model="input"
            @input="validateCode()" required
            maxlength="12"
        )
        div
            label.warn(v-show="check === 1") Nieprawidłowy kod karty.
            label.warn(v-show="check === 3") Karta ma zły status.
            label.warn(v-show="check === 4") Kod karty został już wprowadzony.
            label.warn(v-show="check === null") Wprowadź numer karty.
</template>

<script>
import {API} from '@/js/app/vue/api'
export default {
    name: "OptiWzCouponValidator",
    props: {
        productId: {
            type: Number,
            required: true
        },
        items: {
            type: Array
        }
    },
    data() {
        return {
            input: '',
            check: null
        }
    },
    methods: {
        validateCode() {
            setTimeout(() => {
                API.post('warehouse/validateWzCoupon', {
                    code: this.input,
                    productId: this.productId,
                    items: this.items
                }).then(res => {
                    this.check = res.data
                    if (this.check === 2) {
                        this.$emit('code', this.input)
                    }
                })
            }, 1000)
        }

    }
}
</script>

<style scoped>

</style>