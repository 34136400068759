import {i18n} from '@/js/app/vue/i18n-setup'

import _ from 'lodash'
import Vue from 'vue'

import helper from '@/js/app/vue/components/Select/Multi/helper'
import Notify from '@/js/app/vue/helpers/Notify'

/**
 * Powiązania są ustawione na rodzaj ramki i marki.
 *
 * Nie są ustawione na kształt i kolor ramki,
 * ze względu na to, że ramka może mieć wiele kształtów i kolorów
 * np. ramki mają kształt kocie / motyle, zapytania zawężają do tylko kocich,
 * a trzeba dać możliwość filtrowania także po motylach.
 */
export default {
    check(state) {
        this.conditions = []

        this.checkQuestionsFrames(state)
        this.checkFilters(state)

        this.filterByConditions(state.filters)
    },
    checkQuestionsFrames(state) {
        state.questions.frames.forEach(question => {
            if(question.connections) {
                let value = Object.values(question.options).filter(o => o.selected).map(o => o.keys).flat()

                this.setConnectedFilters(state, question.connections, value, {
                    type: i18n.t('catalog.offer.switch.QUESTIONS').toLowerCase(),
                    name: i18n.t('catalog.offer.questions.' + question.name + '.HEADING')
                })
            }
        })
    },
    checkFilters(state) {
        state.filters.types.forEach(type => {
            if(!type.disabled && type.connections) {
                let value = []

                switch(type.type) {
                    case 'select':
                        let selected_filter = state.filters.selected.filter(s => s.name === type.name)

                        if(selected_filter.length === 1) {
                            selected_filter = selected_filter[0]

                            if(selected_filter.option !== null) {
                                value = [selected_filter.option === parseInt(selected_filter.option).toString() ?
                                    parseInt(selected_filter.option) : selected_filter.option]
                            }
                        }
                }

                this.setConnectedFilters(state, type.connections, value, {
                    type: i18n.t('catalog.offer.connections.FILTER').toLowerCase(),
                    name: i18n.t(type.lang)
                })
            }
        })
    },
    setConnectedFilters(state, connections, value, current) {
        connections.forEach(connection => {
            switch(connection.action) {
                case 'disabled':
                    this.goDisabled(state.filters, connection.name, value, current)

                    break

                case 'edit':
                    this.goEdit(state.filters, connection, value, current)
            }
        })
    },
    goDisabled(filters, disabled_name, value, current) {
        if(value.length) {
            let filter_to_delete_key = _.findKey(filters.selected, s => s.name === disabled_name)

            if(typeof filter_to_delete_key !== 'undefined') {
                Notify.warning(i18n.t('catalog.offer.notify.warning.connections.DISABLED', {
                    deleted_filter: i18n.t(filters.selected[filter_to_delete_key].lang),
                    checked_type: current.type,
                    checked_filter: current.name
                }), false, true)

                filters.selected.splice(filter_to_delete_key, 1)
            }
        }

        Vue.set(filters.types.filter(t => t.name === disabled_name)[0], 'disabled', !!value.length)
    },
    goEdit(filters, connection, value, current) {
        let connected_filter = {
                selected: filters.selected.filter(t => t.name === connection.name),
                type: filters.types.filter(t => t.name === connection.name)
            }

        if(connected_filter.selected.length === 1 && connected_filter.type.length === 1) {
            connected_filter.selected = connected_filter.selected[0]
            connected_filter.type = connected_filter.type[0]

            switch(connected_filter.type.type) {
                case 'multiselect':
                    if(!_.find(this.conditions, {
                        name: connected_filter.selected.name,
                        field: 'options'
                    })) {
                        this.conditions.push({
                            name: connected_filter.selected.name,
                            field: 'options',
                            wheres: []
                        })
                    }

                    let key_condition = _.findKey(this.conditions, condition =>
                        condition.name === connected_filter.selected.name && condition.field === 'options')

                    this.conditions[key_condition].wheres.push({
                        key: connection.field,
                        value: value
                    })

                    if(value.length) {
                        let names_deleted = []

                        connected_filter.selected.option.slice().reverse().forEach((o, index, object) => {
                            if(!value.includes(o[connection.field])) {
                                connected_filter.selected.option.splice(object.length - 1 - index, 1)

                                names_deleted.push(helper.multiselectCustomLabel('', o.name))
                            }
                        })

                        if(names_deleted.length) {
                            if(!connected_filter.selected.option.length) {
                                let filter_to_delete_key = _.findKey(filters.selected, s => s.name === connected_filter.type.name)

                                if(typeof filter_to_delete_key !== 'undefined') {
                                    Notify.warning(i18n.t('catalog.offer.notify.warning.connections.DISABLED', {
                                        deleted_filter: i18n.t(connected_filter.type.lang),
                                        checked_type: current.type,
                                        checked_filter: current.name
                                    }), false, true)

                                    filters.selected.splice(filter_to_delete_key, 1)
                                }
                            } else {
                                Notify.warning(i18n.t('catalog.offer.notify.warning.connections.EDIT', {
                                    positions: names_deleted.map(n => '"' + n + '"').join(', '),
                                    filter: i18n.t(connected_filter.type.lang),
                                    checked_type: current.type,
                                    checked_filter: current.name
                                }), false, true)
                            }
                        }
                    }
            }
        }
    },
    filterByConditions(filters) {
        this.conditions.forEach(condition => {
            let f = {
                type: filters.types.filter(t => t.name === condition.name),
                selected: filters.selected.filter(t => t.name === condition.name)
            }

            if(f.type.length === 1 && f.selected.length === 1) {
                let list = _.cloneDeep(f.type[0][condition.field])

                condition.wheres.forEach(where => {
                    if(where.value.length) {
                        list = list.filter(l => where.value.includes(l[where.key]))
                    }
                })

                f.selected[0][condition.field] = list
            }
        })
    }
}