<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButtonHistory(data="e_shop_customer" :id="customer.id")
                .col-md-4.text-center

                .col-sm-4.col-xs-12.text-center-xs.text-right(style="padding:5px 15px")
                    OptiButton(type="success" :disabled="!customer.connected" v-show="customer.connected"
                      :title="'eorder.action.disconnect.TITLE'|translate" text="eorder.action.disconnect" @confirmed="goDisconnect"
                    ) {{'eorder.action.disconnect.BTN'|translate}}
                      template(#icon)
                        i.fa.fa-shopping-cart

        .col-xs-12(v-show="!loading")
          .col-md-offset-4.col-md-8
            form#form-order.form-horizontal
              .form-group
                .col-sm-8
                  label {{'eorder.action.join.label'|translate}}
                  input#patient_id.form-control(v-model="customer.patient_id" name="patient_id")
              .form-group
                .col-sm-8.col-sm-offset-4
                  OptiButton(type="info"
                    :title="'eorder.action.join.TITLE'|translate" text="eorder.action.join" @confirmed="goJoin"
                  ) {{'eorder.action.join.BTN'|translate}}
                    template(#icon)
                      i.fa.fa-save

        .col-xs-12(style="margin-bottom:10px" v-show="!loading")
            OptiEshopCustomerPanel(:customer="customer" :link="false")

            OptiEshopAddressPanel(:data="customer")

        .col-xs-12(v-show="!loading")
            OptiEShopCustomerDetailsOrdersTable(:customer="customer")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiEshopCustomerPanel from '@/js/app/vue/components/eShop/OptiEshopCustomerPanel'
    import OptiEshopAddressPanel from '@/js/app/vue/components/eShop/OptiEshopAddressPanel'
    import OptiEShopCustomerDetailsOrdersTable from '@/js/app/vue/components/eShop/Customers/Details/OptiEShopCustomerDetailsOrdersTable'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEShopCustomerDetails',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiEshopCustomerPanel,
            OptiEshopAddressPanel,
            OptiEShopCustomerDetailsOrdersTable,
            OptiDimmer
        },
        data() {
            return {
                customer: {
                    orders: []
                },

                loading: true
            }
        },
        mounted() {
            API.get('e_shop/customer/' + this.$state.params.id).then(res => {
                this.customer = res.data
            }).finally(() => {
                this.loading = false
            }).catch(e => {
                if([412, 404].includes(e.response.status)) {
                    this.$notify.error(e.response.data.message, false)
                } else {
                    this.$notify.error('eShop.customer.notify.error.LOAD')
                }

                this.$state.go('app.dashboard')
            })
        },
        methods: {
            goDisconnect() {
                this.loading = true

                API.post('e_shop/customer/' + this.$state.params.id + '/disconnect', this.customer).then(() => {
                  this.$notify.success('eorder.action.disconnect.notify.SAVE')
                }).finally(() => {
                  this.loading = false
                }).catch(() => {
                  this.$notify.error('eorder.action.disconnect.notify.error.SAVE')
                })
            },

            goJoin() {
                this.loading = true

                API.post('e_shop/customer/' + this.$state.params.id + '/join', {
                  patient_id: this.customer.patient_id,
                }).then(res => {
                  this.$notify.success('eorder.action.join.notify.SAVE')
                }).finally(() => {
                  this.loading = false
                }).catch(() => {
                  this.$notify.error('eorder.action.join.notify.error.SAVE')
                })
            }
        }
    }
</script>