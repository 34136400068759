<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right(rowspan="2") #
                    th(style="border-right: 1px solid #aaa" rowspan="2") {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th.text-center(style="border-right: 1px solid #aaa" colspan="2") {{'prescription.RIGHT'|translate}}
                    th.text-center(colspan="2") {{'prescription.LEFT'|translate}}
                    th(style="border-left:1px solid #aaa" v-show="edit")
                tr
                    th.text-center CZ
                    th.text-center(style="border-right: 1px solid #aaa") Visus
                    th.text-center CZ
                    th.text-center Visus
                    th(style="border-left:1px solid #aaa" v-show="edit") {{'optometrist.history.table.head.TIME'|translate}}
            tbody
                tr(v-for="(log, index) in survey.data")
                    td.text-right {{index + 1}}
                    td(style="border-right: 1px solid #aaa") {{log.description}}
                    td.text-center(:class="{'bold-black': log.changed.length && log.changed.indexOf('cz') > -1, 'text-aaa': log.editable.indexOf('cz') === -1}")
                        OptiEnum(v-show="!log.side || log.side === 'r'" enum="InterviewRedGreen" :id="log.parsed.cz")
                    td.text-center(style="border-right: 1px solid #aaa"
                        :class="{'bold-black': log.changed.length && log.changed.indexOf('visus') > -1, 'text-aaa': log.editable.indexOf('visus') === -1}")
                        span(v-show="!log.side || log.side === 'r'") {{log.parsed.visus}}
                    td.text-center(:class="{'bold-black': log.changed.length && log.changed.indexOf('cz') > -1, 'text-aaa': log.editable.indexOf('cz') === -1}")
                        OptiEnum(v-show="!log.side || log.side === 'l'" enum="InterviewRedGreen" :id="log.parsed.cz")
                    td.text-center(
                        :class="{'bold-black': log.changed.length && log.changed.indexOf('visus') > -1, 'text-aaa': log.editable.indexOf('visus') === -1}")
                        span(v-show="!log.side || log.side === 'l'") {{log.parsed.visus}}
                    td.text-right(style="border-left:1px solid #aaa" v-show="edit")
                        OptiOptometristsDetailsSurveyHistoryTime(:action="log.action"
                            :scope="log.time_scope" :diff="log.time_diff")
</template>

<script>
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiOptometristsDetailsSurveyHistoryTime
        from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSurveyHistoryTime'

    export default {
        name: 'OptiOptometristsDetailsSurveyHistoryAcuityWithCorrection',
        components: {
            OptiEnum,
            OptiOptometristsDetailsSurveyHistoryTime
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('optometrist.survey_history_time')
            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }
</script>