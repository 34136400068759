import {API} from '@/js/app/vue/api'

class ActivityClass {
    constructor() {

    }

    increase(data) {
        return API.post('department/activity/increase', data)
    }

    increaseForDepartment(departmentId) {
        this.increase({
            departmentId: departmentId
        })
    }

    increaseForPatient(patientId) {
        this.increase({
            patientId: patientId
        })
    }
}

export const Activity = new ActivityClass()