export default {
    name: 'angularOptiTable',
    definition: [
        'uac',
        function (uac) {
            return {
                restrict: 'E',
                replace: true,
                template: `
                    <opti-table 
                        v-props-end-point="endPoint"  
                        v-props-header="header"
                        v-props-subHeader="subHeader"
                        v-props-subHeader-no-translate="subHeaderNoTranslate"
                        v-props-href="ref"
                        v-props-renderers="renderers"
                        v-props-additional-row="additionalRow"
                        v-props-custom-data="customData"
                        v-props-before-fetch="beforeFetch"
                        v-props-events="events"
                    ></opti-table>`,
                scope: {
                    endPoint: '@',
                    header: '@',
                    subHeader: '@',
                    subHeaderNoTranslate: '@',
                    ref: '@',
                    renderers: '=',
                    additionalRow: '=',
                    customData: '&',
                    beforeFetch: '&',
                    events: '&'
                }
            }
        }
    ]
}
