<template lang="pug">
    .row(style="margin-top:20px")
        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading(:style="{'cursor': coatings.length && $uac.permission('catalog.lenses') && 'pointer'}"
                    @click="edit = coatings.length && $uac.permission('catalog.lenses') ? !edit: false")
                    i.fa.fa-navicon.mr3
                    span {{'dictionary.pictogram.PICTOGRAMS'|translate}}

                    .pull-right(v-show="coatings.length && $uac.permission('catalog.lenses')")
                        i.fa(:class="{'fa-arrow-up': !edit, 'fa-arrow-down': edit}")

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th {{'dictionary.pictogram.COATING'|translate}}
                                th {{'dictionary.pictogram.PICTOGRAMS'|translate}}
                                th
                        tbody
                            tr(v-for="coating in coatings")
                                td {{coating.name}}
                                td(style="padding:0")
                                    OptiPictograms(:pack-details="coating.pack_details")
                                td.text-center
                                    .btn.btn-default(:title="'catalog.graph.TITLE'|translate" v-show="graph.map(g => g.coating_id).includes(coating.id)"
                                        @click="mermaid_id = graph.filter(g => g.coating_id === coating.id)[0].mermaid_id")
                                        i.fa.fa-share-alt

                            tr(v-show="!coatings.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                .panel-body.bg-separator(style="padding-top:25px" v-show="coatings.length && edit")
                    img(v-for="construction in constructions"
                        :class="{'selected': construction.id === selectedConstruction.id}"
                        :title="construction.name" :src="'img/task/pack/' + construction.id + '.png'"
                        @click="select(construction)")

                .panel-footer(v-show="edit")
                    OptiButton(@click.native="openCoatingEdit") {{'dictionary.pictogram.LINK_COATING'|translate}}
                        template(#icon)
                            i.fa.fa-edit

                    OptiButtonSave.pull-right(:disabled="!selectedConstruction.id" @click.native="save")

                OptiDimmer(:active="loading")

            MountingPortal(v-if="mermaid_id" mountTo="#vue-modal" append)
                OptiCatalogGraphDialog(
                    :mermaid-id="mermaid_id"

                    @close="mermaid_id = null")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiCatalogGraphDialog from '@/js/app/vue/components/Catalog/Graph/OptiCatalogGraphDialog'

    export default {
        name: 'OptiCatalogLensesPictograms',
        components: {
            OptiPictograms,
            OptiButton,
            OptiButtonSave,
            OptiDimmer,
            OptiCatalogGraphDialog
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            },
            graph: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                coatings: [],
                constructions: [],

                mermaid_id: null,

                edit: false,
                loading: true
            }
        },
        mounted() {
            this.loading = true

            API.get('dictionary/pictograms/coatings/' + this.categoryId).then(res => {
                this.coatings = res.data.coatings
                this.constructions = res.data.constructions
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('dictionary.pictogram.notify.error.LOAD')
            })
        },
        methods: {
            openCoatingEdit() {
                window.open(this.$state.href('app.dictionary.pictograms.coatings-edit'), '_blank')
            },

            select(construction) {
                this.coatings.forEach(coating => {
                    if(coating.pack_details[0].groupId === 1 && coating.pack_details[0].subgroup !== 5) {
                        coating.pack_details.splice(0, 1)
                    }

                    coating.pack_details.unshift(_.cloneDeep(construction))
                })
            },
            save() {
                this.loading = true

                API.post('dictionary/pictograms/coatings/design-save', {
                    categoryId: this.categoryId,
                    constructionId: this.selectedConstruction.id
                }).then(res => {
                    this.coatings = res.data.coatings
                    this.constructions = res.data.constructions

                    this.$notify.success('dictionary.pictogram.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                    this.edit = false
                }).catch(() => {
                    this.$notify.error('dictionary.pictogram.notify.error.SAVE')
                })
            }
        },
        computed: {
            selectedConstruction() {
                let _return = {}

                this.constructions.forEach(construction => {
                    if(this.coatings.length && this.coatings[0].pack_details.map(d => d.id).includes(construction.id)) {
                        _return = construction
                    }
                })

                return _return
            }
        }
    }
</script>

<style lang="less" scoped>
    img {
        cursor: pointer;
        width: 80px;
        margin: 0 10px 10px 0;
        opacity: .2;

        &.selected {
            opacity: 1;
        }
    }
</style>