<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.CREATENEW'|translate}}

                .form-wizard.wizard-horizontal
                    ol.row
                        li.col-md-5(:class="{'active': step === 1}" @click="step = 1")
                            h4 {{'shipments.new.RECEIVER'|translate}}
                            small.text-muted
                                span.mr3(style="color:red" v-show="!receiver.address.firstName && !receiver.address.lastName") {{'shipments.new.receiver.LABEL'|translate}}
                                span {{receiver.address.firstName}} {{receiver.address.lastName}}
                        li.col-md-4(:class="{'active': step === 2}" @click="step = 2")
                            h4 {{'shipments.new.CONTENT'|translate}}
                            small.text-muted {{'shipments.new.content.LABEL'|translate}}
                        li.col-md-3(:class="{'active': step === 3}" @click="step = 3")
                            h4 {{'shipments.new.DONE'|translate}}
                            small.text-muted {{'shipments.new.done.LABEL'|translate}}

                .panel-body(style="padding-top:0")
                    OptiShipmentCreateStepReceivers(v-show="step === 1"
                        :model="model"
                        :filter="filter"
                        :departments="ingredients.departments"
                        :receiver="receiver"
                        :items="items"

                        :v-model-custom="$v.model.custom")

                    OptiShipmentCreateStepItems(v-show="step === 2"
                        :step="2"
                        :items="items"

                        @delete-item="deleteItem")

                    OptiShipmentCreateStepDone(v-show="step === 3"
                        :shipment="shipment"
                        :receiver="receiver"
                        :items="items"

                        :v-shipment="$v.shipment")

                .panel-footer.text-right(v-show="step === 3")
                    OptiButtonSave(:disabled="disabled" @click.native="save")

            OptiShipmentCreateStepContent(v-show="step === 2"
                :shipment="shipment"
                :ingredients="ingredients"
                :department="model.department"
                :receiver="receiver"
                :items="items"

                @update-item="updateItem")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero, noZeroIf} from '@/js/vue.validators.js'

    import OptiShipmentCreateStepReceivers
        from '@/js/app/vue/components/Warehouse/Shipments/Create/Steps/OptiShipmentCreateStepReceivers'
    import OptiShipmentCreateStepItems
        from '@/js/app/vue/components/Warehouse/Shipments/Create/Steps/OptiShipmentCreateStepItems'
    import OptiShipmentCreateStepContent
        from '@/js/app/vue/components/Warehouse/Shipments/Create/Steps/OptiShipmentCreateStepContent'
    import OptiShipmentCreateStepDone
        from '@/js/app/vue/components/Warehouse/Shipments/Create/Steps/OptiShipmentCreateStepDone'

    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiShipmentCreate',
        components: {
            OptiShipmentCreateStepReceivers,
            OptiShipmentCreateStepItems,
            OptiShipmentCreateStepContent,
            OptiShipmentCreateStepDone,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                shipment: {
                    delivery_type: parseInt(window.localStorage.getItem('shipment_delivery_type')) || 30,
                    suggested_value: 0,
                    value: 0,
                    weight: 0,
                    size: 0,
                    width: 0,
                    height: 0,
                    len: 0
                },

                model: {
                    department: {},
                    patient: {},
                    task: {},
                    custom: {}
                },
                filter: {
                    mode: 0,
                    destination: 0
                },

                receiver: {
                    type: 0,
                    address: {},
                    comments: ''
                },

                ingredients: {},
                items: [],

                step: 1,
                loading: true
            }
        },
        validations() {
            return {
                shipment: {
                    delivery_type: {
                        noZero
                    },
                    value: {
                        noZero
                    },
                    weight: {
                        noZero
                    },
                    size: {
                        noZeroIf: noZeroIf([40, 45].includes(this.shipment.delivery_type))
                    },
                    width: {
                        noZeroIf: noZeroIf([40, 45].includes(this.shipment.delivery_type))
                    },
                    height: {
                        noZeroIf: noZeroIf([40, 45].includes(this.shipment.delivery_type))
                    },
                    len: {
                        noZeroIf: noZeroIf([40, 45].includes(this.shipment.delivery_type))
                    }
                },
                model: {
                    custom: {
                        firstName: {
                            required
                        },
                        street: {
                            required
                        },
                        zipCode: {
                            required
                        },
                        city: {
                            required
                        }
                    }
                }
            }
        },
        mounted() {
            API.get('warehouse/shipment/ingredients').then(res => {
                this.ingredients = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('shipments.new.notify.error.LOAD')
            })
        },
        methods: {
            updateItem() {
                this.filter.mode = 0
                this.filter.destination = 0

                this.items.forEach(item => {
                    if((item.type === 4 && !parseInt(item.status)) || [12, 2, 14, 15].includes(item.type)) {
                        this.filter.mode = 1
                    }

                    if(item.type === 13) {
                        this.filter.destination = item.destinationSalonId
                    }
                })

                this.shipment.value = Math.round(this.shipment.suggested_value * 100) / 100
            },

            returnType(type) {
                switch(type) {
                    case 2:
                        return 'returns'
                    case 4:
                        return 'tasks'
                    case 12:
                        return 'reclamations_to_task'
                    case 13:
                        return 'transfers'
                    case 14:
                        return 'reclamations_to_sale'
                    case 15:
                        return 'regenerations'
                    default:
                        return 'customs'
                }
            },
            deleteItem(item, key) {
                this.items.splice(key, 1)

                let list = this.ingredients[this.returnType(item.type)] || []

                list.forEach(listItem => {
                    if(listItem.id === item.id) {
                        listItem.added = false
                    }
                })

                this.shipment.suggested_value -= parseFloat(item.value)

                this.updateItem()
            },

            save() {
                this.loading = true

                API.post('warehouse/shipment/create', {
                    shipment: this.shipment,
                    receiver: this.receiver,
                    items: this.items
                }).then(() => {
                    window.localStorage.setItem('shipment_delivery_type', this.shipment.delivery_type)

                    this.$notify.success('shipments.new.done.notify.SAVE')

                    this.$state.go('app.warehouse.shipments.outcoming.ready')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('shipments.new.done.notify.error.SAVE')
                })
            }
        },
        computed: {
            disabled() {
                return !this.items.length || !this.receiver.address.firstName ||
                    (this.receiver.type === 3 && this.$v.model.custom.$invalid) || this.$v.shipment.$invalid
            }
        }
    }
</script>