<template lang="pug">
    .row(style="margin-left:0;margin-right:0")
        .col-md-6.col-xs-12
            .row
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.notaccepted', {resetSearch: true})")
                            h2.mt0 {{dashboard.simpleData.notaccepted || 0}}
                            .mb0.text-muted {{'dashboard.simple.NOTACCEPTED'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.accepted', {resetSearch: true})")
                            h2.mt0 {{dashboard.simpleData.accepted || 0}}
                            .mb0.text-muted {{'dashboard.simple.ACCEPTED'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.notaccepted', {search: ['putBack:=:yes']})")
                            h2.mt0
                                i.task-icon.put-back.mr3(style="margin-left:-26px")
                                span {{dashboard.simpleData.putBack || 0}}
                            .mb0.text-muted {{'dashboard.simple.BACK'|translate}}
                .col-xs-12.col-sm-3.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.all', {search: ['status:!=:cancelled', 'status:!=:done', 'status:!=:notaccepted', 'status:!=:sent', 'status:!=:received']})")
                            h2.mt0 {{dashboard.simpleData.inProgress || 0}}
                            .mb0.text-muted {{'dashboard.simple.DURING'|translate}}

        .col-md-6.col-xs-12
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.sent')")
                        h2.mt0 {{dashboard.simpleData.sent || 0}}
                        .mb0.text-muted {{'dashboard.simple.SENT'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.received')")
                        h2.mt0 {{dashboard.simpleData.received || 0}}
                        .mb0.text-muted {{'dashboard.simple.RECEIVED'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.completed', {search: ['deadline:=:yes']})")
                        h2.mt0
                            i.task-icon.deadline.mr3(style="margin-left:-26px")
                            span {{dashboard.simpleData.deadline || 0}}
                            span(v-show="dashboard.simpleData.deadline_today" :title="'dashboard.DEADLINETODAY'|translate"
                                ) ({{dashboard.simpleData.deadline_today}})
                        .mb0.text-muted {{'dashboard.simple.DEADLINE'|translate}}
            .col-xs-12.col-sm-3.p-sm
                .panel.widget.action-panel
                    a.panel-body.text-center(:href="$state.href('app.tasks.all', {search: ['status:!=:cancelled', 'status:!=:done', 'status:!=:notaccepted', 'status:!=:received', 'status:!=:sent', 'deadline:=:yes']})")
                        h2.mt0
                            i.task-icon.deadline.mr3(style="margin-left:-26px")
                            span {{dashboard.simpleData.delay || 0}}
                        .mb0.text-muted {{'dashboard.simple.DELAY'|translate}}

        .col-md-6.col-xs-12
            OptiNotifications

        .col-md-3.col-sm-6.col-xs-12
            .panel.panel-transparent
                .panel-heading
                    i.fa.fa-map-marker.fa-fw.text-muted.mr3
                    span.text-muted {{'dashboard.simple.MAP'|translate}}
                .action-div.clearfix
                    AngularComponent(:component="map")

        .col-md-3.col-sm-6.col-xs-12
            .panel.panel-transparent
                .panel-heading
                    i.fa.fa-briefcase.fa-fw.text-muted.mr3
                    span.text-muted {{'dashboard.simple.offer.HEADING'|translate}}

                .action-div(style="font-size:26px")
                    OptiReportOffersRate(:user-id="$uac.user.id")

                    OptiButton(style="display:block;margin:20px auto 0" @click.native="$state.go('app.reports.offers')"
                        ) {{'dashboard.simple.offer.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-briefcase
</template>

<script>
    import OptiNotifications from '@/js/app/vue/components/Dashboard/Simple/Notification/OptiNotifications'
    import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
    import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDashboardSimple',
        components: {
            OptiNotifications,
            AngularComponent,
            OptiReportOffersRate,
            OptiButton
        },
        props: {
            dashboard: {
                type: Object,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        },
        computed: {
            map() {
                let vm = this

                return {
                    template: `<layout-map department-id="$ctrl.departmentId" width="300px" height="300px"></layout-map>`,
                    $ctrl: {
                        get departmentId() {return vm.departmentId}
                    }
                }
            }
        }
    }
</script>