<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:10px" v-show="is_offer")
            OptiButton(:title="'catalog.product.buttons.back.OFFER'|translate"
                @click.native="$state.go('app.catalog.offer')"
                ) {{'catalog.product.buttons.back.OFFER'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

        .col-xs-12(v-if="visit")
            .alert.alert-info
                i.fa.fa-user.mr3
                span {{'patient.schedule.patients.CREATE'|translate}} {{visit.first_name}} {{visit.last_name}}

        .col-md-offset-2.col-md-8
            form#form-patients.form-horizontal.form-validate(name="formPatients")
                .form-group
                    label.col-sm-4.control-label(for="id")
                        span {{'patient.ID'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.id}}
                    .col-sm-7
                        input#id.form-control(name="id" :placeholder="'patient.ID'|translate"
                            v-model="form.id")
                        OptiValidate(f="id" :data="$v.form.id")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="id") {{hierarchy.id}}
                .form-group
                    label.col-sm-4.control-label(for="firstName")
                        span {{'patient.FIRSTNAME'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.firstName}}
                    .col-sm-7
                        input#firstName.form-control(name="firstName" :placeholder="'patient.FIRSTNAME'|translate"
                            v-model="form.firstName")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="firstName") {{hierarchy.firstName}}
                .form-group
                    label.col-sm-4.control-label(for="lastName")
                        span {{'patient.LASTNAME'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.lastName}}
                    .col-sm-7
                        input#lastName.form-control(name="lastName" :placeholder="'patient.LASTNAME'|translate"
                            v-model="form.lastName")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="lastName") {{hierarchy.lastName}}
                .form-group
                    label.col-sm-4.control-label(for="street")
                        span {{'patient.STREET'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.street}}
                    .col-sm-7
                        input#street.form-control(name="city" :placeholder="'patient.STREET'|translate"
                            v-model="form.street")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="street") {{hierarchy.street}}
                .form-group
                    label.col-sm-4.control-label(for="zipCode")
                        span {{'patient.ZIPCODE'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.zipCode}}
                    .col-sm-7
                        input#zipCode.form-control(name="zipCode" :placeholder="'patient.ZIPCODE'|translate"
                            v-model="form.zipCode")
                        OptiValidate(f="zipCode" :data="$v.form.zipCode")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="zipCode") {{hierarchy.zipCode}}
                .form-group
                    label.col-sm-4.control-label(for="city")
                        span {{'patient.CITY'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.city}}
                    .col-sm-7
                        OptiSelectCity#city(:city="form.city" @selected="c => form.city = c")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="city") {{hierarchy.city}}
                .form-group
                    label.col-sm-4.control-label(for="pesel")
                        span {{'patient.PESEL'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.pesel}}
                    .col-sm-7
                        input#pesel.form-control(name="pesel" :placeholder="'patient.PESEL'|translate"
                            v-model="form.pesel" @input="updatePesel($event.target.value)")
                        OptiValidate(f="pesel" :data="$v.form.pesel")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="pesel") {{hierarchy.pesel}}
                .form-group
                    label.col-sm-4.control-label(for="birthdate")
                        span {{'patient.BIRTHDATE'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.birthdate}}
                    .col-sm-7
                        OptiDate#birthdate(:value="form.birthdate" @update-date="b => form.birthdate = b")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="birthdate") {{hierarchy.birthdate}}
                .form-group
                    label.col-sm-4.control-label(for="cellPhoneNumber")
                        span {{'patient.CELLPHONENUMBER'|translate}}
                        span.label-hierarchy-small.visible-xs-inline {{hierarchy.cellPhoneNumber}}
                    .col-sm-7
                        OptiCellPhoneInput#cellPhoneNumber(v-if="form.cellPhoneNumberCountry"
                            :country="form.cellPhoneNumberCountry"
                            :phone="form.cellPhoneNumber"

                            @country="c => form.cellPhoneNumberCountry = c"
                            @phone="p => form.cellPhoneNumber = p")
                        OptiValidate(f="cellPhoneNumber" :data="$v.form.cellPhoneNumber")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="cellPhoneNumber") {{hierarchy.cellPhoneNumber}}
                .form-group
                    label.col-sm-4.control-label(for="phoneNumber") {{'patient.PHONENUMBER'|translate}}
                    .col-sm-7
                        input#phoneNumber.form-control(name="phoneNumber" :placeholder="'patient.PHONENUMBER'|translate"
                            v-model="form.phoneNumber")
                        OptiValidate(f="phoneNumber" :data="$v.form.phoneNumber")
                    .col-sm-1.hidden-xs.label-hierarchy
                        label.font-weight-normal(for="phoneNumber") {{hierarchy.phoneNumber}}
                .form-group
                    label.col-sm-4.control-label(for="lastActivity")
                        span {{'patient.LASTACTIVITY'|translate}}
                    .col-sm-7
                        select#lastActivity.form-control(name="lastActivity" v-model="form.lastActivity")
                            option(v-for="obj in optionsLastActivity" :value="obj.days") {{obj.day}} {{obj.name|translate}}
                .form-group
                    .col-sm-offset-2.col-sm-10
                        OptiButton(type="info" :disabled="$v.form.$invalid || disabledBtnFind"
                            @click.native="find") {{'default.CHECK'|translate}}
                            template(#icon)
                                i.fa.fa-search
                        OptiButton.pull-right(type="success" :disabled="$v.form.$invalid || is_dirty"
                            @click.native="save") {{'patient.CREATENEW'|translate}}
                            template(#icon)
                                i.fa.fa-save

        .col-xs-12(style="margin-top:10px")
            div(v-show="patients.length")
                small.bold
                    i.fa.fa-info-circle.mr3
                    span {{info}}

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th {{'patient.ID'|translate}}
                                th {{'patient.FIRSTNAME'|translate}}
                                th {{'patient.LASTNAME'|translate}}
                                th {{'patient.CELLPHONENUMBER'|translate}}
                                th.hidden-sm {{'patient.ZIPCODE'|translate}}
                                th {{'patient.CITY'|translate}}
                                th {{'patient.STREET'|translate}}
                                th.hidden-sm {{'patient.BIRTHDATE'|translate}}
                                th {{'patient.WEIGHT'|translate}}
                                th
                        tbody
                            tr(v-for="patient in patients")
                                td
                                    a.link(:title="'patient.TITLE_DETAILS'|translate"
                                        :href="$state.href('app.patient.detail', {id: patient.id})") {{patient.id}}
                                td {{patient.firstName}}
                                td {{patient.lastName}}
                                td {{patient.cellPhoneNumber}}
                                td.hidden-sm {{patient.zipCode}}
                                td {{patient.city}}
                                td {{patient.street}}
                                td.hidden-sm {{patient.birthdate}}
                                td {{patient.weight}}
                                td(style="white-space:nowrap")
                                    a(:title="'patient.TITLE_DETAILS'|translate"
                                        :href="$state.href('app.patient.detail', {id: patient.id})")
                                        i.fa.fa-info-circle.fa-2x.fa-fw

                                    a(style="margin-left:7px" :title="'patient.offer.TITLE'|translate" v-show="is_offer")
                                        label(style="cursor:pointer" :for="'offer_' + patient.id")
                                            i.fa.fa-briefcase.fa-2x.fa-fw
                                        OptiButton.hide(:id="'offer_' + patient.id" @click.native="setPatientToOffer(patient)")

            .alert.alert-warning.text-center(v-show="is_empty")
                i.fa.fa-exclamation-circle.mr3
                span {{'patient.notify.NOTFOUND'|translate}}

            .col-xs-12
                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {maxLength, minLength} from 'vuelidate/lib/validators'
    import {pesel} from '@/js/vue.validators.js'
    import {Storage} from '@/js/app/vue/helpers/Storage'
    import {PESEL} from '@/js/app/vue/helpers/PESEL'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectCity from '@/js/app/vue/components/Select/OptiSelectCity'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiCellPhoneInput from '@/js/app/vue/components/Inputs/OptiCellPhoneInput'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientFind',
        components: {
            OptiValidate,
            OptiSelectCity,
            OptiDate,
            OptiCellPhoneInput,
            OptiButton,
            OptiDimmer
        },
        data() {
            let lang_prefix = 'patient.find.'

            return {
                form: {
                    city: '',
                    birthdate: '',
                    cellPhoneNumber: '',
                    lastActivity: 0
                },
                visit: this.$state.params.visit,
                patients: [],

                trans: {
                    result1: this.$filters.translate(lang_prefix + 'RESULT1'),
                    result2: this.$filters.translate(lang_prefix + 'RESULT2'),
                    result3: this.$filters.translate(lang_prefix + 'RESULT3')
                },
                info: '',

                hierarchy: {},
                optionsLastActivity: [],
                is_dirty: true,
                is_empty: false,
                loading: false
            }
        },
        validations: {
            form: {
                id: {
                    maxLength: maxLength(7)
                },
                zipCode: {
                    maxLength: maxLength(6)
                },
                pesel: {
                    pesel
                },
                cellPhoneNumber: {
                    minLength: minLength(8)
                },
                phoneNumber: {
                    minLength: minLength(8)
                }
            }
        },
        mounted() {
            if(this.visit) {
                this.$set(this.form, 'firstName', this.visit.first_name)
                this.$set(this.form, 'lastName', this.visit.last_name)
                this.$set(this.form, 'cellPhoneNumber', this.visit.phone)
            }

            Storage.get('hierarchy').then(hierarchy => {
                this.hierarchy = hierarchy
            })

            Storage.get('countryCode').then(countries => {
                this.form.cellPhoneNumberCountry = this.form.cellPhoneNumberCountry ?
                    this.form.cellPhoneNumberCountry : _.find(countries, {id: 171})
            })

            API.get('patients/lastActivity').then(res => {
                this.optionsLastActivity = res.data
            }).catch(() => {
                this.$notify.error('patient.notify.ERRORLASTACTIVITY')
            })
        },
        beforeMount() {
            this.$store.dispatch('offer/init')
        },
        methods: {
            find() {
                this.patients = []
                this.is_empty = false
                this.loading = true

                if(!this.disabledBtnFind) {
                    API.post('patients/find', this.form).then(res => {
                        this.patients = res.data

                        this.is_empty = !this.patients.length
                        this.info = ''

                        if(this.patients.length) {
                            API.post('patients/find/count', this.form).then(res => {
                                if(res.data[0].sum > 30) {
                                    this.info = this.trans.result1 + ' ' + res.data[0].sum + ' ' + this.trans.result3
                                } else {
                                    this.info = this.trans.result1 + ' ' + res.data[0].sum + ' ' + this.trans.result2
                                }
                            }).finally(() => {
                                this.is_dirty = false
                                this.loading = false
                            }).catch(() => {
                                this.$notify.error('patient.notify.NOTLOAD')
                            })
                        } else {
                            this.is_dirty = false
                            this.loading = false
                        }
                    }).catch(() => {
                        this.$notify.error('patient.notify.NOTLOAD')
                    })
                } else {
                    this.$notify.error('patient.find.STHFILL')
                }
            },
            save() {
                if(!this.disabledBtnFind) {
                    this.$state.go('app.patient.add', {
                        patient: {
                            firstName: this.form.firstName,
                            lastName: this.form.lastName,
                            pesel: this.form.pesel,
                            cellPhoneNumberCountry: this.form.cellPhoneNumberCountry,
                            cellPhoneNumber: this.form.cellPhoneNumber,
                            phoneNumber: this.form.phoneNumber,
                            zipCode: this.form.zipCode,
                            city: this.form.city,
                            street: this.form.street,
                            birthdate: typeof this.form.birthdate === 'undefined' ? '' : this.form.birthdate,
                            countryPatient: {
                                id: 0
                            },
                            sex: 0,
                            ageGroup: 0,
                            maAgreement: true,
                            maMeasurment: true,
                            maYear: true,
                            maTask: true,
                            status: 0,
                            departmentId: this.$uac.user.activeDepartmentId,
                            source: ''
                        },
                        visit: this.visit ? this.visit : null
                    })
                } else {
                    this.$notify.error('patient.find.STHFILL')
                }
            },

            updatePesel(pesel) {
                if(pesel && pesel !== '00000000000' && !this.$v.form.pesel.$invalid) {
                    let resultPesel = PESEL.calculate(pesel)

                    this.form.birthdate = resultPesel.birthdate
                }
            },

            setPatientToOffer(patient) {
                if(confirm([
                    this.$filters.translate('patient.offer.MESSAGE'),
                    this.$filters.translate('patient.offer.NAME') + ': ' + patient.firstName + ' ' + patient.lastName,
                    this.$filters.translate('patient.offer.ADDRESS') + ': ' + patient.street + ', ' + patient.zipCode + ' ' + patient.city,
                    this.$filters.translate('patient.offer.PHONE') + ': ' + (patient.cellPhoneNumber ? patient.cellPhoneNumber : this.$filters.translate('default.NA'))
                ].join('\n'))) {
                    this.$store.commit('offer/setPatient', patient)

                    this.$state.go('app.catalog.offer')
                }
            }
        },
        computed: {
            disabledBtnFind() {
                let _return = true

                _.forEach(this.form, (value, key) => {
                    if(!['cellPhoneNumberCountry', 'lastActivity'].includes(key) && value) {
                        _return = false
                    }
                })

                this.is_dirty = true

                return _return
            },
            is_offer() {
                return this.$store.getters['offer/isFindOrCreatePatient']
            }
        }
    }
</script>