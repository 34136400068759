import 'angular-file-upload'
import 'bootstrap-filestyle'

// stare importy
import 'pusher-angular'
import 'ng-storage'
import 'angular-translate'
import '../old_assets/angular-translate-storage-local/angular-translate-storage-local.min'
import 'angular-loading-bar'

// css'y
import '../../vendor/fontawesome/css/font-awesome.min.css'
import '../../vendor/simple-line-icons/css/simple-line-icons.css'

// bootstrap tour
import 'bootstrap-tour/build/css/bootstrap-tour.css'
import 'bootstrap-tour/build/js/bootstrap-tour-standalone'

// modernizr
import './app/_assets/modernizr'

// jquery inputmask potrzebne dla directives/masked.js (deprecated?)
//import 'jquery.inputmask'

// bootstrap filestyle
import 'bootstrap-filestyle'

// ngDialog css
import 'ng-dialog/css/ngDialog.min.css'
import 'ng-dialog/css/ngDialog-theme-default.min.css'
import 'ng-dialog/css/ngDialog-theme-plain.min.css'

// loaders.css
import 'loaders.css'

// ui-select
import 'ui-select'
import 'ui-select/dist/select.css'

import html2canvas from 'html2canvas'
window.html2canvas = html2canvas

// bootstrap ui slider
import Slider from 'bootstrap-slider/dist/bootstrap-slider'
window.Slider = Slider
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

// dragular
import 'dragular/dist/dragular'
import 'dragular/dist/dragular.css'

// parsley
import 'parsleyjs'

// html5-sortable
import 'html5-sortable-angularjs'

// momentjs
import moment from 'moment'
moment.locale('pl')
window.moment = moment

// ngVue potrzebne dla długiego okresu przejściowego
//import 'vue'
//import 'ngVue'
//import 'ngVue/build/plugins'

import '../less/app.less'
