<script>
    import Vue from 'vue'

    export default {
        name: 'OptiFlexBox',
        props: {
            breakPoint: {
                type: Number,
                default: 765
            },
            beforeElement: {
                default: false
            },
            afterElement: {
                default: false
            },
            enabled: {
                type: Boolean,
                default: true
            },
            paddingBottom: {
                type: Number,
                default: 20
            }
        },
        data() {
            return {
                bh: 0,
                shadow: {
                    top: true,
                    left: true,
                    right: true,
                    bottom: true
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.updateSize)
                this.updateSize()
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateSize)
        },
        computed: {
            boxHeight: {
                set(val) {
                    if (val !== this.bh) {
                        this.$emit('updated', { from: this.bh, to: val })
                        this.bh = val
                    }
                },
                get() {
                    return this.bh
                }
            },
            cssShadowBackground() {
                let gradients = []

                if (this.shadow.top) gradients.push(this.linearGradient('bottom'))
                if (this.shadow.left) gradients.push(this.linearGradient('right'))
                if (this.shadow.right) gradients.push(this.linearGradient('left'))
                if (this.shadow.bottom) gradients.push(this.linearGradient('top'))

                return gradients.join(', ')
            }
        },
        watch: {
            enabled(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.updateSize()
                }
            }
        },
        methods: {
            updateScroll(event) {
                this.shadow.top = event.target.scrollTop !== 0
                this.shadow.left = event.target.scrollLeft !== 0
                this.shadow.right = event.target.scrollWidth - event.target.scrollLeft !== event.target.clientWidth
                this.shadow.bottom = event.target.scrollHeight - event.target.scrollTop !== event.target.clientHeight
            },
            updateSize(event) {
                let clientWidth = document.documentElement.clientWidth
                if (clientWidth < this.breakPoint || !this.enabled) {
                    this.boxHeight = 'auto'
                    return
                }

                let clientHeight = document.documentElement.clientHeight
                let rect = this.$refs.box.getBoundingClientRect()
                let boxTop = rect.top + window.scrollY
                let contentWrapperPaddingBottom = this.paddingBottom

                let beforeElHeight = this.getElementSize('before')
                let afterElHeight = this.getElementSize('after')

                this.boxHeight = (clientHeight - boxTop - contentWrapperPaddingBottom - beforeElHeight - afterElHeight) + 'px'

                this.updateScroll({target: this.$refs.content})
            },
            getElementSize(type) {
                if (type === 'before') {
                    return 0
                }

                if (!this[`${type}Element`]) {
                    return 0
                }

                let el = this[`${type}Element`]
                if (typeof el === 'string') {
                    el = document.getElementById(this[`${type}Element`])
                } else if (el instanceof Vue) {
                    el = el.$el
                }
                if (!el) {
                    return 0
                }

                return el.getBoundingClientRect().height + 2
            },
            linearGradient(side) {
                return `linear-gradient(to ${side}, #999 0, #ffffff00 14px, #ffffff00 100%)`
            }
        },
        render(createElement, context) {
            return createElement(
                'div',
                {
                    ref: 'box',
                    class: 'opti-flex-box',
                    style: {
                        height: this.boxHeight
                    }
                },
                [
                    createElement(
                        'div',
                        {
                            class: 'shadow-box',
                            style: {
                                background: this.cssShadowBackground
                            }
                        }
                    ),
                    createElement(
                        'div',
                        {
                            class: 'flex-content',
                            on: {
                                scroll: this.updateScroll
                            },
                            ref: 'content'
                        },
                        [
                            createElement(
                                'div',
                                {
                                    style: {
                                        width: 'fit-content',
                                    }
                                },
                                [...this.$slots.default]
                            )
                        ]
                    )
                ]
            )
        },
    }
</script>

<style scoped lang="less">
    .opti-flex-box {
        position: relative;
        width:100%;
        overflow: hidden;
    }

    .flex-content {
        overflow: auto;
        height: 100%;
        z-index: 100;
        -webkit-overflow-scrolling: touch;
    }

    .shadow-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 200;
    }
</style>
