<template lang="pug">
    td(colspan="12")
        .row(style="margin:0")
            div(style="padding:0" :class="{'col-lg-6': mode === 'task', 'col-sm-6': mode !== 'task'}")
                .table-responsive
                    table.table.middle
                        thead
                            tr
                                td
                                td.text-center(colspan="100") VisuReal
                            tr(v-show="previousLens.measurement.ipad.id && !['Żyłka', 'Patentka', 'Patent'].includes(previousLens.measurement.ipad.frameType)")
                                td.text-center.warn(colspan="5")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'previouslens.notify.error.FRAMETYPE'|translate}}
                            tr(v-show="!previousLens.measurement.ipad_measurements_previous_lens_id")
                                td.text-center.warn(colspan="5")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'previouslens.NODATA'|translate}}
                                    span(style="margin-left:3px" v-show="!previousLens.task_id && (previousLens.r_sph > 2 || previousLens.r_sph < -2 || previousLens.l_sph > 2 || previousLens.l_sph < -2)") {{'previouslens.NODATAREQUIRED'|translate}}
                            tr
                                td.bold
                                td.bold.text-center(:colspan="!previousLens.task_id ? 2 : 1") {{'prescription.RIGHT'|translate}}
                                td.bold.text-center(style="border-left:1px solid #aaa"
                                    :colspan="!previousLens.task_id ? 2 : 1") {{'prescription.LEFT'|translate}}
                            tr
                                td.bold
                                td.bold.text-center
                                    span {{'previouslens.ipad.IPAD'|translate}}
                                    .warn(style="display:inline-block;padding-left:3px" :title="'previouslens.ipad.NEAR'|translate"
                                        v-show="previousLens.measurement.ipad.measurement_id && previousLens.distance_id === 2") *
                                td.bold.text-center(v-show="!previousLens.task_id") {{'previouslens.ipad.DIOPTROMIERZ'|translate}}
                                td.bold.text-center(style="border-left:1px solid #aaa")
                                    span {{'previouslens.ipad.IPAD'|translate}}
                                    .warn(style="display:inline-block;padding-left:3px" :title="'previouslens.ipad.NEAR'|translate"
                                        v-show="previousLens.measurement.ipad.measurement_id && previousLens.distance_id === 2") *
                                td.bold.text-center(v-show="!previousLens.task_id") {{'previouslens.ipad.DIOPTROMIERZ'|translate}}

                        tbody
                            tr
                                td.bold PD/2
                                td.text-center(:class="{'its-error': !measurementValidate.pdR}")
                                    span(v-show="previousLens.measurement.ipad.pdR") {{previousLens.measurement.ipad.pdR|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.pdR"
                                        ) (&Delta; {{measurementValidate.delta.pdR|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.pdR}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.RIGHT') + ') - PD/2'"
                                        :value="previousLens.measurement.pdR" @confirmed="v => $emit('update-dioprometer', 'pdR', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.pdR|number(2)}}
                                td.text-center(style="border-left:1px solid #aaa" :class="{'its-error': !measurementValidate.pdL}")
                                    span(v-show="previousLens.measurement.ipad.pdL") {{previousLens.measurement.ipad.pdL|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.pdL"
                                    ) (&Delta; {{measurementValidate.delta.pdL|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.pdL}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.LEFT') + ') - PD/2'"
                                        :value="previousLens.measurement.pdL" @confirmed="v => $emit('update-dioprometer', 'pdL', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.pdL|number(2)}}
                            tr
                                td.bold BT
                                td.text-center(:class="{'its-error': !measurementValidate.btR}")
                                    span(v-show="previousLens.measurement.ipad.btR") {{previousLens.measurement.ipad.btR|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.btR") (&Delta; {{measurementValidate.delta.btR|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.btR}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.RIGHT') + ') - BT'"
                                        :value="previousLens.measurement.btR" @confirmed="v => $emit('update-dioprometer', 'btR', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.btR|number(2)}}
                                td.text-center(style="border-left:1px solid #aaa" :class="{'its-error': !measurementValidate.btL}")
                                    span(v-show="previousLens.measurement.ipad.btL") {{previousLens.measurement.ipad.btL|number(2)}}
                                    small(style="display:block" v-show="measurementValidate.delta.btL") (&Delta; {{measurementValidate.delta.btL|number(2)|mm}})
                                td(v-show="!previousLens.task_id")
                                    OptiNumber.text-center(style="width:80px;margin:0 auto"
                                        :class="{'its-error': !measurementValidate.btL}"
                                        :header="$filters.translate('previouslens.ipad.DIOPTROMIERZ') + ' (' + $filters.translate('prescription.LEFT') + ') - BT'"
                                        :value="previousLens.measurement.btL" @confirmed="v => $emit('update-dioprometer', 'btL', v)"
                                        :disabled="disabled || !!previousLens.task_id" :float="true") {{previousLens.measurement.btL|number(2)}}

            div(style="margin-top:7px" :class="{'col-lg-6': mode === 'task', 'col-sm-6': mode !== 'task'}")
                #interview-measurement(:class="{'pupil-centre': previousLens.measurement.ipad.lensType === 'Progresywne' || previousLens.measurement.ipad.lensDesign === 'Środek źrenicy'}")
                    #pdR(v-if="previousLens.measurement.ipad.pdR || previousLens.task_pd_bt") {{previousLens.measurement.ipad.pdR ? previousLens.measurement.ipad.pdR : previousLens.task_pd_bt.pd_r|number(2)}}
                    #pdL(v-if="previousLens.measurement.ipad.pdL || previousLens.task_pd_bt") {{previousLens.measurement.ipad.pdL ? previousLens.measurement.ipad.pdL : previousLens.task_pd_bt.pd_l|number(2)}}

                    #btR(v-if="previousLens.measurement.ipad.btR || previousLens.task_pd_bt") {{previousLens.measurement.ipad.btR ? previousLens.measurement.ipad.btR : previousLens.task_pd_bt.bt_r|number(2)}}
                    #btL(v-if="previousLens.measurement.ipad.btL || previousLens.task_pd_bt") {{previousLens.measurement.ipad.btL ? previousLens.measurement.ipad.btL : previousLens.task_pd_bt.bt_l|number(2)}}

                    #width(v-show="previousLens.measurement.ipad.frameA") {{previousLens.measurement.ipad.frameA|number(2)}}
                    #height(v-show="previousLens.measurement.ipad.frameB") {{previousLens.measurement.ipad.frameB|number(2)}}


                    #data(v-show="previousLens.measurement.ipad.wpa || previousLens.measurement.ipad.fffa || previousLens.measurement.ipad.cvd")
                        span
                            span.mr3 {{'tasks.INCLINATION'|translate}}
                            span {{previousLens.measurement.ipad.wpa|number(1)}}

                        span
                            span.mr3 {{'tasks.FFFA'|translate}}
                            span {{previousLens.measurement.ipad.fffa|number(1)}}

                        span
                            span.mr3 {{'tasks.CVD'|translate}}

                            small.mr3(v-show="previousLens.measurement.ipad.cvd !== previousLens.measurement.ipad.cvdLeft"
                                ) {{'prescription.RIGHT'|translate}}
                            span.mr3 {{previousLens.measurement.ipad.cvd|number(1)}}

                            small.mr3(v-show="previousLens.measurement.ipad.cvd !== previousLens.measurement.ipad.cvdLeft"
                                ) {{'prescription.LEFT'|translate}}
                            span(v-show="previousLens.measurement.ipad.cvd !== previousLens.measurement.ipad.cvdLeft"
                                ) {{previousLens.measurement.ipad.cvdLeft|number(1)}}

                        span
                            span.mr3 {{'tasks.TYPE'|translate}}
                            span {{translate(previousLens.measurement.ipad.lensType, previousLens.measurement.ipad.frameType)}}
                            .text-right
                                span(v-if="previousLens.measurement.ipad.lensType === 'Progresywne' && previousLens.measurement.ipad.corridor"
                                    ) {{previousLens.measurement.ipad.corridor|mm}}
                                span(v-else) {{previousLens.measurement.ipad.lensDesign}}

            div(v-if="previousLens.measurement.ipad.measurement_id")
                a(:href="'upload/patient/visureal/' + previousLens.measurement.ipad.measurement_id + '.jpg'"
                    target="_blank" :title="'tasks.ipad.printscreen.TITLE'|translate")
                    img(style="width:100%" :src="'upload/patient/visureal/' + previousLens.measurement.ipad.measurement_id + '.jpg?refresh=' + previousLens.measurement.ipad.updated_at")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiPreviousLensesMeasurementVisuReal',
        components: {
            OptiNumber
        },
        props: {
            mode: {
                type: String,
                required: true
            },

            previousLens: {
                type: Object,
                required: true
            },
            measurementValidate: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            translate(lens, frame) {
                let trans = {
                    lens: lens,
                    frame: frame
                }

                switch(lens) {
                    case 'Progressive':
                    case 'Progresywne':
                        trans.lens = 'Prog'
                        break

                    case 'single':
                    case 'singlefocal':
                    case 'Jednoogniskowe':
                    case 'SV bliż':
                    case 'SV dal':
                        trans.lens = 'SV'
                }

                switch(frame) {
                    case 'Patent':
                    case 'Patentka':
                        trans.frame = 'Patentka'
                }

                return trans.lens + '/' + trans.frame
            }
        }
    }
</script>