<template lang="pug">
    .table-responsive
        table.table.table-striped.middle(style="margin-bottom:0")
            tbody
                tr(v-show="heading && id")
                    td(colspan="5")
                        i.fa.fa-navicon.mr3
                        span {{'optometrist.correction.WORTH'|translate}}
                tr(v-if="id")
                    td
                        img(style="width:70px" :src="'img/optometrist/screening-test/worth/' + id + '.png'")
                    td(colspan="2") {{name_worth(id)|translate}}
                    td {{'optometrist.result.CORRECTION'|translate}} - {{description_worth(id)}}
                    td.text-right(v-show="icon")
                        i.fa.fa-2x.fa-remove.warn(v-show="!summary()")
                        span.fa.fa-2x.fa-check.text-success(v-show="summary()")
                tr(v-show="noResult && !id")
                    td.text-center.warn.bold(colspan="5") {{'optometrist.correction.NORESULT'|translate}}
</template>

<script>
    import {EnumInterviewWorth} from '@/js/app/vue/enums/Enums'

    export default {
        name: 'OptiOptometristsDescriptionsWorth',
        components: {

        },
        props: {
            heading: {
                type: Boolean,
                required: false,
                default: false
            },
            icon: {
                type: Boolean,
                required: false,
                default: false
            },
            noResult: {
                type: Boolean,
                required: false,
                default: false
            },

            id: {
                type: Number,
                required: true
            }
        },
        mounted() {

        },
        methods: {
            name_worth(worth_id) {
                return EnumInterviewWorth.memberByValue(worth_id).translation
            },
            description_worth(worth_id) {
                switch(worth_id) {
                    case 1: return 'Udział oczu w widzeniu jednoczesny i wyrównany'
                    case 2: return 'Fuzja z naprzemienną dominacją lub dwuoczną rywalizacją'
                    case 3: return 'Fuzja z dominacją oka prawego'
                    case 4: return 'Fuzja z dominacją oka lewego'
                    case 5: return 'Esoforia lub esotropia z dominacją oka lewego'
                    case 6: return 'Esoforia lub esotropia z dominacją oka prawego'
                    case 7: return 'Exoforia lub exotropia z dominacją oka prawego'
                    case 8: return 'Exoforia lub exotropia z dominacją oka prawego'
                    case 9: return 'Dadatnia heteroforia wertykalna lub prawostronna hipertropia lub lewostronna hipotropia z dominajcą oka lewego'
                    case 10: return 'Dadatnia heteroforia wertykalna lub prawostronna hipertropia lub lewostronna hipotropia z dominajcą oka prawego'
                    case 11: return 'Ujemna heteroforia wertykalna lub lewostronna hipertropia lub prawostronna hipotropia z dominacją oka lewego'
                    case 12: return 'Ujemna heteroforia wertykalna lub lewostronna hipertropia lub prawostronna hipotropia z dominacją oka prawego'
                    case 13: return 'Dwojenie nieskrzyżowane'
                    case 14: return 'Dwojenie skrzyżowane'
                    case 15: return 'Dwojenie w kierunku pionowym. Zez ku górze oka prawego lub zez ku dołowi oka lewego'
                    case 16: return 'Dwojenie w kierunku pionowym. Zez ku dołowi oka prawego lub zez ku górze oka lewego'
                    case 17: return 'Dwojenie złożona. Jednoczesne odchylenie oczu w pionie i w poziomie'
                    case 18: return 'Tłumienie oka lewego'
                    case 19: return 'Tłumienie oka prawego'
                }

                return ''
            },

            summary() {
                return [1 ,2, 3, 4].includes(this.id)
            }
        }
    }
</script>