<template lang="pug">
    nav
        ul.pagination
            li(:class="{'disabled': paginator.page === 1}")
                a.fa.fa-angle-double-left(@click="goToPage(1)")
            li(:class="{'disabled': paginator.page === 1}")
                a.fa.fa-angle-left(@click="goToPage(paginator.page - 1)")

            li(v-for="p in pages" :class="{'active': p === paginator.page}")
                a(@click="goToPage(p)") {{p}}

            li(:class="{'disabled': paginator.page === paginator.pages}")
                a.fa.fa-angle-right(@click="goToPage(paginator.page + 1)")
            li(:class="{'disabled': paginator.page === paginator.pages}")
                a.fa.fa-angle-double-right(@click="goToPage(paginator.pages)")

        ul.pagination
            li
                OptiNumber(:header="'default.pagination.PAGE'|translate" :disabled="paginator.pages === 1"
                    :required="true" :max="paginator.pages" :value="paginator.page" @confirmed="goToPage"
                    ) {{'default.pagination.PAGE'|translate}}: {{paginator.page}} / {{paginator.pages}}
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiPaginator',
        components: {
            OptiNumber
        },
        props: {
            paginator: {
                type: Object,
                required: true
            }
        },
        methods: {
            goToPage(page) {
                if(this.paginator.page !== page && this.paginator.pages >= page && page > 0) {
                    this.$emit('changed-page', page)
                }
            }
        },
        computed: {
            pages() {
                let start = Math.max(Math.min(this.paginator.page - 2, this.paginator.pages - 4), 1),
                    end = Math.min(Math.max(this.paginator.page + 2, 5), this.paginator.pages)

                return _.range(start, end + 1)
            }
        }
    }
</script>

<style lang="less" scoped>
    nav {
        display: inline-block;
        float: left;

        > ul {
            margin: 0 5px 0 0 !important;
        }
    }
</style>