<template lang="pug">
    #payment-show
        .row
            .col-sm-6 {{'payment.directive.PAYMENTTYPE'|translate}}
            .col-sm-6 {{typeName|translate}}

        .row(v-if="typeId === 2")
            .col-sm-5.col-sm-offset-1
                span(v-show="payments[0].ctype.id !== 7 && payments[0].ctype.id !== 8") {{'payment.directive.CCTYPECARDNUMBER'|translate}}
                span(v-show="payments[0].ctype.id === 7") {{'payment.directive.CCTYPECARDNUMBERBLIK'|translate}}
                span(v-show="payments[0].ctype.id === 8") {{'payment.directive.CCTYPECARDNUMBERCOUPON'|translate}}
            .col-sm-6 {{payments[0].ctype.label}} / {{payments[0].cardNumber}}
        .row(v-if="typeId === 2")
            .col-sm-5.col-sm-offset-1
                span(v-show="payments[0].ctype.id !== 8") {{'payment.directive.ACCOUNTNOAMOUNT'|translate}}
                span(v-show="payments[0].ctype.id === 8") {{'payment.directive.ACCOUNTNOAMOUNTCOUPON'|translate}}
            .col-sm-6
                span(v-show="payments[0].ctype.id === 8") {{payments[0].amount|currency}}
                span(v-show="payments[0].ctype.id !== 8") {{payments[0].accountNo}}

        div(v-show="typeId === 3")
            template(v-for="payment in payments")
                .row
                    .col-xs-12
                        b.mr3 {{'payment.directive.TYPE'|translate}}
                        span(style="font-weight:normal") - {{payment.typeName|translate}}
                .row(v-show="payment.type === 1")
                    .col-sm-5.col-sm-offset-1 {{'payment.directive.AMOUNT'|translate}}
                    .col-sm-6
                        span {{payment.amount|currency}}
                        OptiReceiptNoticeValue(:data="notice(payment.id)")
                .row(v-show="payment.type === 2")
                    .col-sm-5.col-sm-offset-1
                        span(v-show="payment.ctype.id !== 7 && payment.ctype.id !== 8") {{'payment.directive.CCTYPECARDNUMBER'|translate}}
                        span(v-show="payment.ctype.id === 7") {{'payment.directive.CCTYPECARDNUMBERBLIK'|translate}}
                        span(v-show="payment.ctype.id === 8") {{'payment.directive.CCTYPECARDNUMBERCOUPON'|translate}}
                    .col-sm-6 {{payment.ctype.label}} / {{payment.cardNumber}}
                .row(v-show="payment.type === 2")
                    .col-sm-5.col-sm-offset-1
                        span.mr3(v-show="payment.ctype.id !== 8") {{'payment.directive.ACCOUNTNOAMOUNT'|translate}}
                        span(v-show="payment.ctype.id === 8") {{'payment.directive.ACCOUNTNOAMOUNTCOUPON'|translate}}
                    .col-sm-6
                        span.mr3(v-show="payment.ctype.id !== 8") {{payment.accountNo}} /
                        span {{payment.amount|currency}}
                        OptiReceiptNoticeValue(:data="notice(payment.id)")
</template>

<script>
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'

    export default {
        name: 'OptiPaymentShow',
        components: {
            OptiReceiptNoticeValue
        },
        props: {
            typeId: {
                type: Number,
                required: true
            },
            typeName: {
                type: String,
                required: true
            },
            payments: {
                type: Array,
                required: true
            },
            differences: {
                type: Array,
                required: false,
                default() {
                    return []
                }
            }
        },
        methods: {
            notice(payment_id) {
                let _return = false

                this.differences.forEach(difference => {
                    if(difference.credit_card_payment_id === payment_id) {
                        _return = difference
                    }
                })

                return _return
            }
        }
    }
</script>