<template lang="pug">
    .row(style="margin-left:0;margin-right:0")
        .col-sm-6(style="padding-left:7.5px;padding-right:7.5px")
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.TASKS'|translate}}
                .col-xs-12.col-sm-4.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.uncompleted', {resetSearch: true})")
                            h2.mt0 {{dashboard.whData.taskUncompleted || 0}}
                            .mb0.text-muted {{'dashboard.simple.tasks.UNCOMPLETED'|translate}}
                .col-xs-12.col-sm-4.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.completed', {resetSearch: true})")
                            h2.mt0 {{dashboard.whData.taskCompleted || 0}}
                            .mb0.text-muted {{'dashboard.simple.COMPLETED'|translate}}
                .col-xs-12.col-sm-4.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.tasks.confirmed', {resetSearch: true})")
                            h2.mt0 {{dashboard.whData.taskConfirmed || 0}}
                            .mb0.text-muted {{'dashboard.simple.tasks.CONFIRMED'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.TICKETS'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.tickets.table', {resetSearch: true})")
                            h2.mt0 {{dashboard.whData.tickets || 0}}
                            .mb0.text-muted {{'dashboard.simple.TOPRINT'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.RETURNS'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.returns.placed')")
                            h2.mt0 {{dashboard.whData.returnPlaced || 0}}
                            .mb0.text-muted {{'dashboard.simple.returns.PLACED'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.ORDERS'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.orders.confirmed')")
                            h2.mt0 {{dashboard.whData.orderConfirmed}}
                            .mbo.text-muted {{'dashboard.simple.CONFIRMED'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.TRANSFERSP'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.transfers.incoming.sent')")
                            h2.mt0 {{dashboard.whData.incomingTransfer}}
                            .mbo.text-muted {{'dashboard.simple.INCOMINGSENT'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.SHIPMENTSP'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.shipments.incoming.sent')")
                            h2.mt0 {{dashboard.whData.incomingShipping}}
                            .mbo.text-muted {{'dashboard.simple.INCOMINGSENT'|translate}}

        .col-sm-3
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'dashboard.simple.PROMOTIONS'|translate}}
                .col-xs-12.col-xs-12.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.prices.list', {search: ['status:!=:cancelled', 'status:!=:completed']})")
                            h2.mt0  {{dashboard.whData.promoPlanned}} / {{dashboard.whData.promoActive}}
                            .mbo.text-muted {{'dashboard.simple.promotions.PLANNED'|translate}} / {{'dashboard.simple.promotions.ACTIVE'|translate}}
</template>

<script>
    export default {
        name: 'OptiDashboardSimpleWarehouse',
        components: {

        },
        props: {
            dashboard: {
                type: Object,
                required: true
            }
        }
    }
</script>