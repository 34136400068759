<template lang="pug">
    .row
        .col-xs-12.aligned-row
            .col-xs-1.switch-col
                div.switch-index(v-if="previous_index" @click="changeIndex(previous_index)")
                    span.fa.fa-2x.fa-chevron-left.text-muted
                    h2(style="margin-top:-3px") {{previous_index}}

                div.switch-index(v-if="!previous_index && !first_index && previous_design" @click="changeDesign(previous_design)")
                    span.fa.fa-2x.fa-fast-backward.text-muted
                    span.text-bold {{previous_design.name}}

                div.switch-index(style="margin-top:10px" v-if="first_index" @click="changeIndex(first_index)")
                    span.fa.fa-backward.text-muted
                    h4.text-muted(style="margin-top:3px") {{first_index}}
            .col-xs-10
                img(style="max-width:100%" :src="image_url")
            .col-xs-1.switch-col
                div.switch-index(v-if="next_index" @click="changeIndex(next_index)")
                    span.fa.fa-2x.fa-chevron-right.text-muted
                    h2(style="margin-top:-3px") {{next_index}}

                div.switch-index(v-if="!next_index && !last_index && next_design" @click="changeDesign(next_design)")
                    span.fa.fa-2x.fa-fast-forward.text-muted
                    span.text-bold {{next_design.name}}

                div.switch-index(style="margin-top:10px" v-if="last_index" @click="changeIndex(last_index)")
                    span.fa.fa-forward.text-muted
                    h4.text-muted(style="margin-top:3px") {{last_index}}
</template>

<script>
    export default {
        name: 'OptiCatalogPageSwitcher',
        data() {
            return {
                next_index: null,
                previous_index: null,
                first_index: null,
                last_index: null,
                next_design: null,
                previous_design: null
            }
        },
        props: {
            design: null,
            current_index: null,
            image_url: null,
            group: null,
            design_id: null
        },
        mounted() {
            this.startUp()
        },
        watch: {
            current_index() {
                this.startUp()
            },
            design() {
                this.startUp()
            }
        },
        methods: {
            changeDesign(design) {

                this.$emit('change_design', design)
                this.previous_design = null
                this.next_design = null
            },
            changeIndex(idx) {
                this.$emit('index_change', idx)
                this.next_index = null
                this.previous_index = null
            },
            startUp() {
                if(this.design) {
                    let keys =  Object.keys(this.design)
                    let check = Object.keys(keys).find(key => keys[key] === this.current_index)

                    let design_keys = Object.keys(this.group)

                    let design_check = this.group.map(function (x) {
                        return x.id
                    }).indexOf(this.design_id)

                    if(parseFloat(design_check) > 0) {
                        this.$set(this, 'next_design', this.group[parseFloat(design_check) -1])
                    }
                    if(parseFloat(design_check) < design_keys.length -1) {
                        this.$set(this, 'previous_design', this.group[parseFloat(design_check)+1])
                    }

                    this.first_index = keys[0]
                    this.last_index = keys[keys.length -1]

                    if(parseFloat(check) > 0) {
                        this.$set(this, 'previous_index', keys[parseFloat(check)-1])
                        if(parseFloat(check) === 1) {
                            this.$set(this, 'first_index', null)
                        }
                    }
                    if(parseFloat(check) < keys.length) {
                        this.$set(this, 'next_index', keys[parseFloat(check)+1])
                        if(this.next_index === keys[keys.length-1]) {
                            this.$set(this, 'last_index', null)
                        }
                    }
                    if(parseFloat(check) === keys.length -1) {
                        this.$set(this, 'next_index', null)
                        this.$set(this, 'last_index', null)
                    }
                    if(parseFloat(check) === 0) {
                        this.$set(this, 'previous_index', null)
                        this.$set(this, 'first_index', null)
                    }
                }
            }
        },
    }
</script>

<style scoped lang="less">
    .switch-index {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .switch-col {
        margin-top: 25%;
    }
    .aligned-row {
        display: flex;
        position: relative;
    }
</style>