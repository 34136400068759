import moment from 'moment'
import {easterDate, numberPad} from './Utils'

const staticNonWorkingDays = {
    NEW_YEAR: '01-01',
    EPIPHANY: '01-06',
    MAY_FIRST: '05-01',
    MAY_THIRD: '05-03',
    ARMED_FORCES_DAY: '08-15',
    ALL_SAINTS_DAY: '11-01',
    INDEPENDENCE_DAY: '11-11',
    CHRISTMAS_1: '12-25',
    CHRISTMAS_2: '12-26'
}

/**
 * to samo w HolidayHelper.php:$excludeNonWorkingSundays
 */
const excludeNonWorkingSundays = [
    '2023-12-10'
]

class HolidayHelper {

    constructor() {
        this.cache = {
            holidays: {},
            sundays: {}
        }
        this.currentYear = parseInt(moment().format('GGGG'))
    }

    lastSundayOfMonth(year, month) {
        let date = moment(year + '-' + numberPad(month) + '-01').endOf('month')
        if (date.day() === 0) return date.format('YYYY-MM-DD')
        return date.add(-1, 'week').isoWeekday('Sunday').format('YYYY-MM-DD')
    }

    getHolidays(year, includeNonWorkingSundays) {
        year = year || this.currentYear

        if (!this.cache.holidays[year]) {
            let easter = easterDate(year)

            let holidays = {
                EASTER: easter,
                EASTER_MONDAY: easter.clone().add(1, 'day'),
                CORPUS_CHRISTI: easter.clone().add(60, 'day'),
                PENTECOST: easter.clone().add(49, 'day')
            }

            _.forEach(staticNonWorkingDays, (day, name) => {
                holidays[name] = moment(year + '-' + day).startOf('day')
            })

            this.cache.holidays[year] = holidays
        }

        let dates = this.cache.holidays[year]

        if (!!includeNonWorkingSundays) {
            let sundays = this.getNonWorkingSundays(year)

            _.forEach(sundays, (day, index) => {
                dates['NON_WORKING_SUNDAY|' + index] = moment(day)
            })
        }

        return dates
    }

    getHolidaysByDate(year, includeNonWorkingSundays) {
        let dates = this.getHolidays(year, includeNonWorkingSundays)
        let result = {}

        _.forEach(dates, (day, name) => {
            let dayString = day.format('YYYY-MM-DD')
            if (!result[dayString]) result[dayString] = []
            result[dayString].push('holiday.' + name.split('|')[0])
        })

        return result
    }

    getNonWorkingSundays(year) { //TODO: ściągnąć to z php!
        // ten sam kod jest w getNonWorkingSundays() HolidayHelper.php

        year = year || this.currentYear

        if (!this.cache.sundays[year]) {
            let sundays = []

            if (year === 2018) {
                sundays = [
                    '2018-03-11',
                    '2018-03-18',

                    '2018-04-15',
                    '2018-04-22',

                    '2018-05-13',
                    '2018-05-20',

                    '2018-06-10',
                    '2018-06-17',

                    '2018-07-08',
                    '2018-07-15',
                    '2018-07-22',

                    '2018-08-12',
                    '2018-08-19',

                    '2018-09-09',
                    '2018-09-16',
                    '2018-09-23',

                    '2018-10-14',
                    '2018-10-21',

                    '2018-11-18',

                    '2018-12-09'
                ]
            } else if(year === 2019) {
                let except = [
                    '2019-01-27',
                    '2019-02-24',
                    '2019-03-31',
                    '2019-04-14',
                    '2019-04-28',
                    '2019-05-26',
                    '2019-06-30',
                    '2019-07-28',
                    '2019-08-25',
                    '2019-09-29',
                    '2019-10-27',
                    '2019-11-24',
                    '2019-12-15',
                    '2019-12-22',
                    '2019-12-29'
                ]

                let sunday = moment('2019W01').endOf('isoweek')
                while(sunday.year() === year) {
                    let day = sunday.format('YYYY-MM-DD')
                    if (!except.includes(day)) {
                        sundays.push(day)
                    }
                    sunday.add(1, 'week')
                }
            } else if(year >= 2020) {
                let easter = easterDate(year)
                let christmas = moment(year + '-12-25')
                let format = 'YYYY-MM-DD'

                let except = [
                    // niedziela przed bożym narodzeniem
                    christmas.clone().add(-1, 'week').isoWeekday(7).format(format),
                    // druga niedziela przed bożym narodzeniem
                    christmas.clone().add(-2, 'week').isoWeekday(7).format(format),

                    // jedna niedziela przed wielkanocą
                    easter.clone().add(-1, 'week').isoWeekday(7).format(format),

                    // pierwsza niedziela stycznia/kwietnia/czerwca/sierpnia
                    this.lastSundayOfMonth(year, 1),
                    this.lastSundayOfMonth(year, 4),
                    this.lastSundayOfMonth(year, 6),
                    this.lastSundayOfMonth(year, 8)
                ]

                let sunday = moment(year + 'W01').endOf('isoweek')
                while (sunday.year() === year) {
                    let day = sunday.format('YYYY-MM-DD')
                    if (!except.includes(day)) {
                        sundays.push(day)
                    }
                    sunday.add(1, 'week')
                }
            }

            this.cache.sundays[year] = sundays.filter(d => !excludeNonWorkingSundays.includes(d))
        }

        return this.cache.sundays[year]
    }
}


const Instance = new HolidayHelper()

export default Instance
