import {API} from '@/js/app/vue/api'
import Vue from 'vue'
import Logger from '../../helpers/Logger'
import _ from 'lodash'

const LOCAL_STORAGE_KEY = 'eopti-user'

function loadFromLocalStorage() {
    let user = localStorage.getItem(LOCAL_STORAGE_KEY) || false
    if(user) {
        user = JSON.parse(user)
    }

    return user
}

function saveToLocalStorage(user) {
    if (user) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user))
    } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY)
    }
}


export default {
    namespaced: true,

    state: {
        user: false,
    },

    actions: {
        login({commit}, credentials) {
            let request = API.post('user/login', credentials)

            request
                .then(response => {
                    commit('setCurrentUser', response.data.user)
                })
                .catch(err => {
                    commit('setCurrentUser', false)
                })

            return request
        },
        logout({commit}) {
            let request = API.get('user/logout')

            request
                .finally(response => {
                    commit('setCurrentUser', false)
                })

            return request
        },
        loginAs({commit}, userId) {
            let request = API.get(`user/loginAs/${userId}`)

            request.then(res => {
                commit('setCurrentUser', res.data.user)
            }).catch(() => {
                commit('setCurrentUser', false)
            })

            return request
        },
        async changeDepartment({commit, state, dispatch}, departmentId) {
            try {
                let request = {
                    departmentId: parseInt(departmentId),
                    color: state.user.color,
                    lang: state.user.lang,
                    dark: state.user.dark
                }

                let {data} = await API.post('settings/save', request)

                if(data.user_id) {
                    return dispatch(`loginAs`, data.user_id)
                }

            } catch(e) {
                console.log(e)
            }

            throw new Error(`It's not possible to change department at this time.`)
        },
        check({commit}) {
            let request = API.get('user/check')

            request.then(res => {
                commit('setCurrentUser', res.data.user)
            }).catch(() => {
                commit('setCurrentUser', false)
            })

            return request
        }
    },

    mutations: {
        setCurrentUser(state, user) {
            //state.user = user
            Vue.set(state, 'user', user)

            if(user) {
                let clone = _.clone(user)
                delete clone.department
                delete clone.myPermissions

                Logger.setGlobal('usr', clone)
            } else {
                Logger.setGlobal('usr', false)
            }

            saveToLocalStorage(user)
        }
    },

    getters: {
        getUser: state => state.user,

        isAuthenticated: state => !!state.user,

        hasPermission: state => {
            return name => {
                return !!_.findKey(state.user.myPermissions, permission => permission === name)
            }
        },

        hasPermissionExtra: state => {
            return (name, department_id) => {
                if(Object.keys(state.user.extraPermissions).includes(name)) {
                    if(typeof department_id === 'undefined') {
                        department_id = state.user.activeDepartmentId
                    }

                    return state.user.extraPermissions[name].includes(department_id)
                }

                return false
            }
        },

        groupPermission: state => {
            return name => {
                name += '.'
                return !!_.findKey(state.user.myPermissions, permission => permission.substring(0, name.length) === name)
            }
        },

        hasRole: (state, getters) => {
            return name => {
                return getters.hasPermission(name)
            }
        },

        hasAnyRole: (state, getters) => {
            return names => {
                for (let i = 0; i < names.length; i++) {
                    if (getters.hasRole(names[i])) {
                        return true
                    }
                }
                return false
            }
        }
    }
}
