<template lang="pug">
    div
        .row(v-if="patient && !loading")
            .col-xs-12
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'sms.breadcrumb.SMSES'|translate}}

                        .label.label-danger.pull-right(v-show="smses.length") {{smses.length}}

                    .row
                        .col-xs-12.text-right(v-show="patient.departmentId === $uac.user.activeDepartmentId || $uac.user.activeDepartmentId === 91")
                            .panel-body
                                OptiButtonSms(:patient="patient" :tags="{type: 0, firstName: patient.firstName}"
                                    :disabled="block" @sent="load")

                        .col-xs-12
                            .table-responsive
                                table.table.table-striped
                                    thead(v-show="smses.length")
                                        tr
                                            th Id
                                            th {{'sms.DEPARTMENT'|translate}}
                                            th {{'sms.MESSAGE'|translate}}
                                            th {{'sms.STATUS'|translate}}
                                            th {{'sms.DELIVERYDATE'|translate}}
                                            th {{'sms.SENTDATE'|translate}}
                                            th

                                    tbody
                                        template(v-for="sms in smses")
                                            tr
                                                td {{sms.id}}
                                                td {{sms.department.name}}
                                                td
                                                    a.link(v-show="sms.ref_type === 1"
                                                        :href="$state.href('app.tasks.reclamation.details', {id: sms.ref_id})") {{sms.body}}
                                                    a.link(v-show="sms.ref_type === 2"
                                                        :href="$state.href('app.sale.reclamation.details', {id: sms.ref_id})") {{sms.body}}
                                                    a.link(v-show="sms.ref_type === 3 && sms.visit_params.department_id"
                                                        @click="$state.go('app.patients.schedule', {department_id: sms.visit_params.department_id, date: sms.visit_params.date, visit_id: sms.ref_id})") {{sms.body}}
                                                    span(v-show="sms.ref_type === 4 && !$uac.user.activeRole.admin") {{'sms.HIDECODE'|translate}}
                                                    a.link(v-show="sms.ref_type === 5"
                                                        :href="$state.href('app.optometrist.details', {id: sms.ref_id})") {{sms.body}}
                                                    span(v-show="!sms.ref_type || (sms.ref_type === 3 && !sms.visit_params.department_id) || (sms.ref_type === 4 && $uac.user.activeRole.admin)") {{sms.body}}
                                                td {{sms.status}}
                                                td {{sms.deliveryDate}}
                                                td {{sms.sentDate}}
                                                td.text-center(style="vertical-align:middle")
                                                    i.fa.fa-level-down.fa-2x(style="cursor:pointer" :title="'sms.REPLY'|translate"
                                                        v-show="sms.children.length" @click="sms.show = !sms.show")
                                                    label.fa.fa-remove.fa-2x.warn(:for="'btn-delete-' + sms.id" style="cursor:pointer"
                                                        v-show="sms.status_number === 409 && ($uac.user.activeDepartmentId === patient.departmentId || $uac.user.activeDepartmentId === 91)"
                                                        :title="'sms.queque.TITLE'|translate")
                                                    OptiButton.hide(:id="'btn-delete-' + sms.id" text="sms.queque.confirm"
                                                        @confirmed="deleteSMS(sms.id)")
                                            tr.bg-separator(v-for="children in sms.children" v-show="sms.children.length && sms.show")
                                                td {{children.id}}
                                                td(colspan="4")
                                                    span.bold.mr3 {{'sms.REPLY'|translate}}
                                                    span {{children.body}}
                                                td(colspan="2") {{children.sentDate}}

                                        tr.text-muted.text-center(v-show="!smses.length")
                                            td(colspan="100")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'sms.notify.NOSMS'|translate}}

                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'patient.emails.breadcrumb.EMAILS'|translate}}

                        .label.label-danger.pull-right(v-show="emails.length") {{emails.length}}

                    .row
                        .col-xs-12
                            .table-responsive
                                table.table.table-striped.middle
                                    thead(v-show="emails.length")
                                        tr
                                            th {{'patient.emails.table.ID'|translate}}
                                            th {{'patient.emails.table.SUBJECT'|translate}}
                                            th {{'patient.emails.table.REF_TYPE'|translate}}
                                            th {{'patient.emails.table.REF_ID'|translate}}
                                            th {{'patient.emails.table.DELIVERED'|translate}}
                                            th {{'patient.emails.table.COUNT_REPLIES'|translate}}
                                            th {{'patient.emails.table.created.AT'|translate}}
                                            th
                                    tbody
                                        tr(v-for="email in emails" :class="{'bg-separator': email.count_replies}")
                                            td
                                                a.link(:title="'default.SHOWDETAILS'|translate" @click="email_id = email.id") {{email.id}}
                                            td {{email.subject}}
                                            td {{email.ref_type_name|translate}}
                                            td
                                                OptiRefId(v-if="email.ref_type" section="mail" :type-id="email.ref_type" :id="email.ref_id")
                                                a.link(v-else :title="'default.REFERENCETYPE'|translate"
                                                    @click="$state.go('app.patient.detail', {'#': 'offers', id: patient.id, offerId: email.ref_id})"
                                                    ) {{email.ref_id}}
                                            td(:class="{'warn': !email.delivered}") {{email.delivered ? 'default.bool.YES' : 'default.bool.NO'|translate}}
                                            td {{email.count_replies}}
                                            td {{email.created_at}}
                                            td.text-center
                                                a
                                                    i.icon.icon-envelope-open.fa-2x(style="cursor:pointer" :title="'default.SHOWDETAILS'|translate"
                                                        @click="email_id = email.id")

                                        tr.text-muted.text-center(v-show="!emails.length")
                                            td(colspan="100")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'patient.emails.notify.EMPTY_FOR_PATIENT'|translate}}

        MountingPortal(v-if="email_id" mountTo="#vue-modal" append)
            OptiPatientEmailPreviewDialog(
                :id="email_id"

                @close="closeMail")

        OptiDimmer(:active="!patient || loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonSms from '@/js/app/vue/components/Button/OptiButtonSms'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiPatientEmailPreviewDialog
        from '@/js/app/vue/components/Patients/Emails/OptiPatientEmailPreviewDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsSmsesAndEmails',
        components: {
            OptiButton,
            OptiButtonSms,
            OptiRefId,
            OptiPatientEmailPreviewDialog,
            OptiDimmer
        },
        props: {
            patient: {
                type: Object | null,
                required: true
            },
            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                smses: [],
                emails: [],

                email_id: 0,

                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('patient/smses-and-emails/fromPatientId/' + this.$state.params.id).then(res => {
                    this.smses = res.data.smses
                    this.emails = res.data.emails

                    this.$emit('update-count', 'smses_and_emails', this.smses.length + this.emails.length)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sms.notify.ERRORSMS')
                })
            },

            deleteSMS(sms_id) {
                this.loading = true

                API.post('patient/sms/queque', {
                    sms_id: sms_id
                }).then(res => {
                    if(res.data.status === true) {
                        this.$notify.success('sms.queque.notify.DELETED')

                        this.load()
                    } else {
                        this.$notify.error(res.data.status.error, false)
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sms.queque.notify.error.DELETED')
                })
            },

            closeMail(changed) {
                if(changed) {
                    this.load()
                }

                this.email_id = 0
            }
        }
    }
</script>

<style lang="less" scoped>
    table > tbody > tr > td > a {
        white-space: normal !important;
    }
</style>