<template lang="pug">
    .row(:class="{hide: hide}")
        .col-xs-12
            .alert.alert-warning
                i.fa.fa-exclamation-circle.mr3
                span {{'test.notify.EDITWARNING'|translate}}
                .pull-right(style="cursor:pointer" @click="goToHide") &times;
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiTestWarning',
        components: {

        },
        props: {
            testId: {
                type: String
            },
            questionId: {
                type: String
            }
        },
        data() {
            return {
                hide: true
            }
        },
        mounted() {
            this.checkTest()
            this.checkQuestion()
        },
        methods: {
            checkTest() {
                if(this.testId) {
                    API.post('tests/warning', {
                        testId: this.testId
                    }).then((res) => {
                        this.hide = !res.data.status
                    }).catch(() => {
                        this.$notify.error('test.notify.error.EDITWARNING')
                    })
                }
            },
            checkQuestion() {
                if(this.questionId) {
                    API.post('tests/warning', {
                        questionId: this.questionId
                    }).then((res) => {
                        this.hide = !res.data.status
                    }).catch(() => {
                        this.$notify.error('test.notify.error.EDITWARNING')
                    })
                }
            },
            goToHide() {
                this.hide = true
            }
        }
    }
</script>