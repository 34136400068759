<template lang="pug">
    .opti-select-package
        VueMultiSelect(
            :options="packages"
            :value="selected"
            :clearable="false"
            label="pack_name1"
            @input="updateSelected"
            placeholder="Wybierz pakiet"
            group-label="label"
            group-values="packages"
            :group-select="false"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
        )
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.pack_name1}}
                .pull-right {{props.option.id}}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{ props.option.pack_name1 }}
                    small.pull-right {{ props.option.id }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectPackageAll',
        components: {VueMultiSelect},
        props: {
            package: {
                type: Number | String,
                required: true,
                default: 0
            },
            index: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                packages: [],
                selected: {}
            }
        },
        watch: {
            package() {
                this.setSelected()
            }
        },
        mounted() {
            try {
                API.get('search/packageById', API.id(Math.random())).then(res => {
                    this.group(res.data)

                    this.setSelected()
                })
            } catch(error) {
                console.log(error)
            }
        },
        computed: {

        },
        methods: {
            group(data) {
                data.forEach((_package) => {
                    let first_letter = _package.pack_name1[0],
                        found = false

                    this.packages.forEach((grouped) => {
                        if(grouped.label === first_letter) {
                            grouped.packages.push(_package)

                            found = true
                        }
                    })

                    if(!found) {
                        this.packages.push({
                            label: first_letter,
                            packages: [_package]
                        })
                    }
                })
            },
            setSelected() {
                this.packages.forEach((_package) => {
                    _package.packages.forEach((group) => {
                        if (group.id == this.package) {
                            this.selected = group
                        }
                    })
                })
            },
            updateSelected(_package) {
                if(typeof this.index !== 'undefined') {
                    _package.index = this.index
                }

                this.selected = _package
                this.$emit('selected', _package)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: 0.5;
    }
</style>
