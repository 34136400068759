<template lang="pug">
    .btn.btn-default(:title="'catalog.offer.favorite.title.SHOW'|translate"
        :disabled="!favorites.length" @click="dialog = true")
        i.fa.fa-heart.mr3
        span {{favorites.length}}

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiCatalogOfferFavoritesDialog(
                :favorites="favorites"
                :selected="selected"
                :department-id="departmentId"

                @close="dialog = false")
</template>

<script>
    import OptiCatalogOfferFavoritesDialog
        from '@/js/app/vue/components/Catalog/Offer/Favorites/OptiCatalogOfferFavoritesDialog'

    export default {
        name: 'OptiCatalogOfferFavorites',
        components: {
            OptiCatalogOfferFavoritesDialog
        },
        props: {
            favorites: {
                type: Array,
                required: true
            },
            selected: {
                type: Array,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>