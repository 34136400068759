<template lang="pug">
    .panel.panel-transparent
        .panel-heading
            i.fa.fa-clock-o.fa-fw.text-muted.mr3
            span.text-muted {{'dashboard.simple.EVENTS'|translate}}

        OptiNotificationsItem(v-for="notification in notifications" :key="notification.id"
            :notification="notification")

        .clearfix.text-center(style="background-color:transparent;padding:5px 10px")
            span.btn.btn-default(@click="toggle()")
                span(v-show="!show_all") {{'dashboard.notifications.toggle.ALL'|translate}}
                span(v-show="show_all") {{'dashboard.notifications.toggle.10'|translate}}

        OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Broadcast from '../../../../Broadcast'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiNotificationsItem from '@/js/app/vue/components/Dashboard/Simple/Notification/OptiNotificationsItem'

    export default {
        name: 'OptiNotifications',
        components: {
            OptiNotificationsItem,
            OptiDimmer
        },
        data() {
            return {
                notifications: [],

                channel_name: 'department-notifications-' + this.$uac.user.activeDepartmentId,

                show_all: false,

                loading: true
            }
        },
        mounted() {
            this.load()

            Broadcast.Echo.channel(this.channel_name).listen('.updated', () => {
                this.load()
            })
        },
        destroyed() {
            Broadcast.Echo.channel(this.channel_name).unsubscribe()
        },
        methods: {
            load() {
                this.loading = true

                API.get('dashboard/notifications/' + (this.show_all ? 1 : 0), API.id(Math.random())).then(res => {
                    this.notifications = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dashboard.notifications.notify.error.LOAD')
                })
            },
            toggle() {
                this.show_all = !this.show_all

                this.load()
            }
        }
    }
</script>