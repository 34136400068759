<template lang="pug">
    .row
        .col-md-8.col-md-offset-2.col-sm-10.col-sm-offset-1(v-show="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'supply.list.HEADER'|translate}}

                .table-responsive
                    table.table.table-condensed.table-striped.middle
                        thead
                            tr
                                th #
                                th {{'supply.list.table.SUPPLYNAME'|translate}}
                                th {{'supply.list.table.PERSON'|translate}}
                                th {{'supply.list.table.EXPECTED'|translate}}
                                th {{'supply.list.table.RECEIVED'|translate}}
                                th
                                th
                        tbody
                            tr(v-for="(item, index) in supply")
                                td {{index + 1}}
                                td {{item.name}}
                                td {{item.contact_person}} {{item.contact_phone}}
                                td {{item.expected}}
                                td {{item.received}}
                                td
                                    OptiButton(v-show="item.expected > 0"
                                        @click.native="$state.go('app.warehouse.supply.expected', {supplier_id: item.supplier_id})"
                                        ) {{'supply.list.table.EXPECTED'|translate}}
                                td
                                    OptiButton(@click.native="$state.go('app.warehouse.supply.received', {supplier_id: item.supplier_id})"
                                        ) {{'supply.list.table.RECEIVED'|translate}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSupplyList',
        components: {
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                supply: [],

                loading: false
            }
        },
        mounted() {
            this.loading = true

            API.get('warehouse/supply/list').then(res => {
                this.supply = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('supply.list.notify.error.LOAD')
            })
        }
    }
</script>