<template lang="pug">
    a.link(:title="'default.title.PREVIEW'|translate" @click="open = true")
        i.fa.fa-camera.mr3
        slot

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogImage(:url="url" @closed="open = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogImage from '@/js/app/vue/components/Button/Dialogs/OptiDialogImage'

    export default {
        name: 'OptiButtonImage',
        components: {
            OptiButton,
            OptiDialogImage
        },
        props: {
            url: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>