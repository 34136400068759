<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            form#form-reclamations.form-horizontal.form-validate(name="formReclamation" @submit.prevent="addReclamation")
                .col-md-offset-2.col-md-8
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.CREATEDBY'|translate}}
                        .col-sm-8.form-control-static {{reclamation.employee}} ({{reclamation.departmentName}})
                    .form-group
                        label.col-sm-4.control-label.star-required(for="expected") {{'reclamations.details.CONSIDERDATE'|translate}}
                        .col-sm-8
                            OptiDate#expected(:value="reclamation.expected" @update-date="d => reclamation.expected = d")
                            OptiValidate(f="expected" :data="$v.reclamation.expected")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label {{'reclamations.details.declarant.PATIENT'|translate}}
                        .col-sm-8.form-control-static
                            a.link(:title="'reclamations.details.title.PATIENT'|translate"
                                :href="$state.href('app.patient.detail', {id: reclamation.clientId})" target="_blank"
                                ) {{reclamation.clientName}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.declarant.ADDRESS'|translate}}
                        .col-sm-8.form-control-static {{reclamation.clientStreet}}, {{reclamation.clientPostal}} {{reclamation.clientCity}}
                    .form-group
                        label.col-sm-4.control-label(for="phone") {{'reclamations.details.declarant.PHONE'|translate}}
                        .col-sm-8
                            input#phone.form-control(name="phone" v-model="reclamation.clientPhone")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="clientCellphone") {{'reclamations.details.declarant.CELLPHONE'|translate}}
                        .col-sm-8
                            input#clientCellphone.form-control(name="clientCellphone" v-model="reclamation.clientCellphone" required)
                            OptiValidate(f="clientCellphone" :data="$v.reclamation.clientCellphone")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label {{'reclamations.details.TASKID'|translate}}
                        .col-sm-8.form-control-static
                            a.link(:title="'reclamations.details.title.TASK'|translate"
                                :href="$state.href('app.task.detail', {id: reclamation.productId})" target="_blank"
                                ) {{reclamation.productId}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.FINISHEDTASK'|translate}}
                        .col-sm-8.form-control-static {{reclamation.sellDate}}
                    .form-group
                        label.col-sm-4.control-label {{'reclamations.details.PRICING'|translate}}
                        .col-sm-8.form-control-static {{reclamation.productPrice|currency}}
                    .form-group
                        label.col-sm-4.control-label(for="reclamation_evidence") {{'reclamations.details.EVIDENCE'|translate}}
                        .col-sm-8
                            OptiSelectEnum#reclamation_evidence(enum="ReclamationEvidence"
                                :value="reclamation.refType" @update-enum="updateEvidence")
                    .form-group
                        label.col-sm-4.control-label(for="refId") {{'reclamations.details.EVIDENCEID'|translate}}
                        .col-sm-8(:class="{'form-control-static': !reclamation.refType}")
                            span(v-show="!reclamation.refType") {{reclamation.refId|receipt}}
                            input#refId.form-control(name="refId" v-show="reclamation.refType" v-model="reclamation.refId")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label.star-required(for="description") {{'reclamations.details.DESCRIPTION'|translate}}
                        .col-sm-8
                            textarea#description.form-control(name="description" v-model="reclamation.description" required)
                            OptiValidate(f="description" :data="$v.reclamation.description")
                    .form-group
                        label.col-sm-4.control-label(for="salon_comments") {{'reclamations.details.SALONCOMMENTS'|translate}}
                        .col-sm-8
                            textarea#salon_comments.form-control(name="salon_comments" v-model="reclamation.salon_comments")

                    .form-group(style="padding-top:15px")
                        label.col-sm-4.control-label.star-required(for="noticed") {{'reclamations.details.DEFECTDATE'|translate}}
                        .col-sm-8
                            OptiDate#noticed(:value="reclamation.noticed" @update-date="d => reclamation.noticed = d")
                            OptiValidate(f="noticed" :data="$v.reclamation.noticed")

                    .form-group(style="padding-top:15px")
                        label.col-xs-12(for="demand1") {{'reclamations.details.DEMAND'|translate}}
                    .form-group
                        .col-xs-10
                            OptiSelectEnum#demand1(enum="ReclamationDemand1"
                                :value="reclamation.demand1" @update-enum="d => reclamation.demand1 = d")
                        .col-xs-2.text-center
                            //.btn.btn-default(:title="'reclamations.details.title.TIP'|translate"
                            //    :class="{'active': demand.option1}" @click="demand.option1 = !demand.option1")
                            //    i.fa.fa-question
                    .form-group(v-show="demand.option1")
                        .col-xs-12.text-expressive {{'reclamations.details.tips.TIPS1'|translate}}.
                    .form-group
                        .col-sm-10
                            OptiSelectEnum#demand2(enum="ReclamationDemand2"
                                :value="reclamation.demand2" @update-enum="d => reclamation.demand2 = d")
                        .col-xs-2.text-center
                            //.btn.btn-default(:title="'reclamations.details.title.TIP'|translate"
                            //    :class="{'active': demand.option2}" @click="demand.option2 = !demand.option2")
                            //    i.fa.fa-question
                    .form-group(v-show="demand.option2")
                        .col-xs-12.text-expressive
                            div {{'reclamations.details.tips.TIPS2'|translate}}
                            ul
                                li {{'reclamations.details.tips.TIPS3'|translate}},
                                li {{'reclamations.details.tips.TIPS4'|translate}},
                                li {{'reclamations.details.tips.TIPS5'|translate}}.

                OptiReclamationServices(type="task" :product-type="1"
                    :services="services" @update-services-invalid="b => services_invalid = b")
                .clearfix

                .col-md-10.col-md-offset-2
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiButtonSave(:disabled="$v.reclamation.$invalid || services_invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiReclamationServices from '@/js/app/vue/components/Reclamations/OptiReclamationServices'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationTaskAdd',
        components: {
            OptiDate,
            OptiSelectEnum,
            OptiValidate,
            OptiReclamationServices,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            if(Object.keys(this.$state.params.task).includes('id')) {
                window.localStorage.setItem('reclamation', JSON.stringify(this.$state.params.task))
            }

            return {
                task: JSON.parse(window.localStorage.getItem('reclamation')) || {},
                reclamation: {},
                services: {},
                services_invalid: true,

                demand: {
                    option1: true,
                    option2: true
                },
                loading: true
            }
        },
        validations: {
            reclamation: {
                expected: {
                    required
                },
                clientCellphone: {
                    required
                },
                description: {
                    required
                },
                noticed: {
                    required
                }
            }
        },
        mounted() {
            API.get('patient/' + this.task.patientId).then(res => {
                this.task.patient = res.data

                this.parse()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('reclamations.notify.error.LOAD')
            })
        },
        methods: {
            parse() {
                this.reclamation = {
                    departmentId: this.$uac.user.activeDepartmentId,
                    departmentName: this.$uac.user.departmentName,
                    employee: this.$uac.user.username,
                    clientId: this.task.patient.id,
                    clientName: this.task.patient.firstName + ' ' + this.task.patient.lastName,
                    clientStreet: this.task.patient.street,
                    clientCity: this.task.patient.city,
                    clientPostal: this.task.patient.zipCode,
                    clientPhone: this.task.patient.phoneNumber,
                    clientCellphone: this.task.patient.cellPhoneNumber,
                    expected: moment().add(14, 'days').format('YYYY-MM-DD'),
                    productId: this.task.id,
                    sellDate: this.task.finished,
                    productPrice: this.task.registry.pricing,
                    refType: 0,
                    refId: '',
                    description: '',
                    noticed: moment().format('YYYY-MM-DD'),
                    demand1: 0,
                    demand2: 0,
                    salon_comments: ''
                }

                this.updateEvidence(this.reclamation.refType)
            },

            updateEvidence(t) {
                this.reclamation.refType = t

                this.reclamation.refId = this.reclamation.refType ? '' : this.task.registry.bill.receipt
            },

            addReclamation() {
                if(moment(this.reclamation.expected).diff(moment(), 'days') < 0) {
                    this.$notify.error('reclamations.notify.error.BACKDATE')
                } else if(moment(this.reclamation.expected).diff(moment(), 'days') >= 14) {
                    this.$notify.error('reclamations.notify.error.AFTER14')
                } else {
                    this.loading = true
                    this.reclamation.services = this.services

                    API.post('tasks/reclamations/create', this.reclamation).then(() => {
                        this.$state.go('app.tasks.reclamations.accepted')

                        this.$notify.success('reclamations.notify.CREATE')
                    }).finally(() => {
                        this.loading = false
                    }).catch(error => {
                        if(error.response.status === 460) {
                            this.$notify.error(error.response.data.message, false)
                        }

                        this.$notify.error('reclamations.notify.error.CREATE')
                    })
                }
            }
        }
    }
</script>
