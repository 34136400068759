<template lang="pug">
    .row
        form.form-validate(name="formRegeneration" @submit.prevent="addRegeneration" v-if="!loading && regeneration")
            .col-sm-4
                .form-group(v-if="regeneration.source")
                    label {{'regeneration.table.SOURCE'|translate}}
                    div
                        OptiEnum.mr3(enum="RegenerationSource" :id="regeneration.source")
                        OptiRefId(section="regeneration" :type-id="regeneration.source" :id="regeneration.source_id")
                .form-group
                    label.star-required(for="patient") {{'regeneration.table.PATIENT'|translate}}
                    OptiSelectPatient(:id="'patient'" :required="true" :init-result="regeneration.patient"
                        :disabled="patient_disabled" @selected="updatePatient")
                    OptiValidate(f="patient" :data="$v.regeneration.patient")
                .form-group(v-if="!regeneration.product.id")
                    label.star-required(for="product_name") {{'regeneration.table.PRODUCT'|translate}}
                    input#product_name.form-control(:placeholder="'regeneration.table.PRODUCT'|translate"
                        v-model="regeneration.product_name" name="product_name" :disabled="!!regeneration.source")
                    OptiValidate(f="product_name" :data="$v.regeneration.product_name")
                .form-group(v-if="regeneration.product.id")
                    label.star-required(for="product_name") {{'regeneration.table.PRODUCT'|translate}}
                    OptiProduct(style="display:block" :product="regeneration.product") {{regeneration.product.name}}
                .form-group
                    label.star-required(for="product_brand") {{'regeneration.table.BRAND'|translate}}
                    input#product_brand.form-control(:placeholder="'regeneration.table.BRAND'|translate"
                        v-model="regeneration.product_brand" name="product_brand"
                        :disabled="!!(regeneration.source && regeneration.product_brand)")
                    OptiValidate(f="product_brand" :data="$v.regeneration.product_brand")
                .form-group
                    label.star-required(for="product_type") {{'regeneration.table.PRODUCTTYPE'|translate}}
                    OptiSelectEnum#product_type(enum="TaskFrameType" name="product_type"
                        :value="regeneration.product_type"
                        :disabled="!!(regeneration.source === 1 && regeneration.product_type)"
                        @update-enum="v => regeneration.product_type = v")
                    OptiValidate(f="product_type" :data="$v.regeneration.product_type")
                .form-group
                    label.star-required(for="account_price") {{'regeneration.table.ACCOUNT'|translate}}
                    OptiNumber.form-control.text-right#account_price(
                        :header="'regeneration.table.ACCOUNT'|translate"
                        :value="regeneration.account_price" :float="true"
                        @confirmed="value => regeneration.account_price = value") {{regeneration.account_price|currency}} zł
                    OptiValidate(f="account_price" :data="$v.regeneration.account_price")
                .form-group(v-show="allow_discount")
                    label(for="discount") {{'regeneration.table.DISCOUNT'|translate}}
                    OptiNumber.form-control.text-right#discount(
                        :header="'regeneration.table.DISCOUNT'|translate"
                        :value="regeneration.discount" :float="true"
                        @confirmed="value => regeneration.discount = value") {{regeneration.discount|currency}} zł
                .form-group
                    label.star-required(for="date_expected") {{'regeneration.table.EXPECTED'|translate}}
                    OptiDate#date_expected(name="date_expected" :value="regeneration.date_expected" :required="true"
                        @update-date="d => regeneration.date_expected = d")
                    OptiValidate(f="date_expected" :data="$v.regeneration.date_expected")
                .form-group.text-right
                    label
                        .checkbox.c-checkbox.checkbox-primary.inline
                            input(type="checkbox" name="rodo" v-model="rodo")
                            span.fa.fa-check(style="margin-left:0px")
                        span {{'regeneration.RODO'|translate}}
                .form-group
                    OptiButtonSave(:disabled="payment_invalid || $v.regeneration.$invalid || !regeneration.patient.id || !regeneration.damages[0].obj.id")

            .col-sm-8
                .row
                    .col-md-6
                        .form-group
                            label.star-required(for="descriptions") {{'regeneration.table.DESCRIPTION'|translate}}
                            textarea#descriptions.form-control(style="height:120px;resize:none" :placeholder="'regeneration.table.DESCRIPTION'|translate"
                                v-model="regeneration.descriptions" name="descriptions")
                            OptiValidate(f="descriptions" :data="$v.regeneration.descriptions")

                        .form-group
                            .row(v-for="(photo, key) in $v.regeneration.photo_descriptions.$each.$iter" :key="key")
                                .col-xs-6
                                    div
                                        OptiUpload(
                                            :id="'regenerations-descriptions-' + key"
                                            prefix="regenerations_descriptions"

                                            :file="photo.photo.$model"
                                            :picture="true"

                                            @update-loading="l => photo.loading = l"
                                            @update-file="p => photo.photo.$model = p")
                                        OptiValidate(:f="'regenerations-descriptions-' + key" :data="photo.photo")
                                .col-xs-6.text-center
                                    OptiButton(type="danger"
                                        style="display:block;margin-bottom:5px"
                                        v-show="regeneration.photo_descriptions.length !== 1"
                                        @click.native="deletePhoto('descriptions', key)") {{'regeneration.notes.DELETE'|translate}}
                                        template(#icon)
                                            i.fa.fa-trash

                                    OptiButton(type="success"
                                        style="display:block"
                                        v-show="key == regeneration.photo_descriptions.length - 1"
                                        :disabled="!photo.photo.$model"
                                        @click.native="addPhoto('descriptions')") {{'regeneration.notes.ADD'|translate}}
                                        template(#icon)
                                            i.fa.fa-plus

                    .col-md-6
                        .form-group
                            label(for="comments") {{'regeneration.table.COMMENTS'|translate}}
                            textarea#comments.form-control(
                                :placeholder="'regeneration.table.COMMENTS'|translate"
                                :class="{ 'updateOn': 'blur' }"
                                v-model="regeneration.comments"
                                name="comments")

                        .form-group
                            .row(v-for="(photo, key) in $v.regeneration.photo_comments.$each.$iter" :key="key")
                                .col-xs-6
                                    div
                                        OptiUpload(
                                            :id="'regenerations-comments-' + key"
                                            prefix="regenerations_comments"

                                            :file="photo.photo.$model"
                                            :picture="true"

                                            @update-loading="l => photo.loading = l"
                                            @update-file="p => photo.photo.$model = p")
                                        OptiValidate(:f="'regenerations-comments-' + key" :data="photo.photo")
                                .col-xs-6.text-center
                                    OptiButton(type="danger"
                                        style="display:block;margin-bottom:5px"
                                        v-show="(regeneration.photo_comments.length !== 1 && photo.photo.$model) || regeneration.photo_comments.length > 1"
                                        @click.native="deletePhoto('comments', key)") {{'regeneration.notes.DELETE'|translate}}
                                        template(#icon)
                                            i.fa.fa-trash
                                    OptiButton(type="success"
                                        style="display:block"
                                        v-show="key == regeneration.photo_comments.length - 1"
                                        :disabled="!photo.photo.$model"
                                        @click.native="addPhoto('comments')") {{'regeneration.notes.ADD'|translate}}
                                        template(#icon)
                                            i.fa.fa-plus

                    .col-xs-12
                        OptiRegenerationNotes(:notes="regeneration.notes" @update-notes="updateNotes" @delete-note="deleteNote")

                    .col-xs-12
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-navicon.mr3
                                span {{'regeneration.damages.HEADING'|translate}}

                            .panel-body
                                .row(v-for="(damage, key) in $v.regeneration.damages.$each.$iter" :key="key")
                                    .col-xs-6
                                        .form-group
                                            label.star-required(:for="'type' + key") {{'regeneration.damages.TYPE'|translate}}
                                            select.form-control(:id="'type' + key" :name="'type' + key" v-model="damage.obj.$model")
                                                option(v-for="d in damages" :key="d.id" :value="d") {{d.name}}
                                            OptiValidate(:f="'type' + key" :data="damage.obj.id")
                                            .text-info.bold(v-if="damage.obj.$model.id")
                                                div {{'regeneration.LIST'|translate}} {{getAssignedSpecialists(damage.obj.$model.specialists)}}.
                                                div {{'dictionary.regenerationtype.price.CENTRAL'|translate}}: {{damage.obj.$model.price_from_central|currency}}.
                                                div {{'dictionary.regenerationtype.price.SPECIALIST'|translate}}: {{damage.obj.$model.price_from_specialist|currency}}.
                                                div(v-if="$uac.permission('regenerations.manager')") {{'dictionary.regenerationtype.price.WHOLESALE'|translate}}: {{damage.obj.$model.price_wholesale|currency}}.

                                    .col-xs-6.text-center(style="padding-top:25px")
                                        OptiButton(type="danger" v-if="regeneration.damages.length !== 1 && key >= 0" :disabled="!!regeneration.regeneration_from"
                                            @click.native="deleteDamage(key)") {{'regeneration.damages.DELETE'|translate}}
                                            template(#icon)
                                                i.fa.fa-trash
                            .panel-footer.text-right(v-if="regeneration.damages")
                                OptiButton(type="success" :disabled="!regeneration.damages[regeneration.damages.length - 1].obj.id || !!regeneration.regeneration_from"
                                    @click.native="addDamage()") {{'regeneration.damages.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus

                    .col-xs-12
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-navicon.mr3
                                span {{'regeneration.PAYMENTACCOUNT'|translate}}

                            .panel-body
                                form.form.form-horizontal
                                    OptiPayment(
                                        :payments="regeneration.account_payment"
                                        :value="regeneration.account_price"
                                        :required="true"
                                        @invalid="updatePaymentInvalid")
        .col-xs-12
            OptiDimmer(:active="loading")

</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiRegenerationNotes from '@/js/app/vue/components/Regenerations/OptiRegenerationNotes'
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRegenerationAdd',
        components: {
            OptiDimmer,
            OptiPayment,
            OptiRegenerationNotes,
            OptiUpload,
            OptiButton,
            OptiValidate,
            OptiButtonSave,
            OptiDate,
            OptiNumber,
            OptiSelectEnum,
            OptiSelectPatient,
            OptiProduct,
            OptiRefId,
            OptiEnum
        },
        data() {
            return {
                regeneration: {
                    patient: {},
                    product_type: '',
                    difficulty: 0,
                    account_price: 1,
                    retail_price: 0,
                    wholesale_price: 0,
                    discount: 0,
                    source: 0,
                    source_id: 0,
                    damages: [{
                        obj: {
                            id: null
                        }
                    }],
                    photo_descriptions: [],
                    photo_comments: [],
                    account_payment: [],
                    notes: [],
                    product: {},
                    date_expected: ''
                },
                patient_disabled: false,
                rodo: false,
                payment_invalid: true,
                damages: [],
                allow_discount: false,
                loading: true
            }
        },
        validations: {
            regeneration: {
                patient: {
                    required
                },
                product_name: {
                    required
                },
                product_brand: {
                    required
                },
                product_type: {
                    noZero
                },
                account_price: {
                    noZero
                },
                date_expected: {
                    required
                },
                descriptions: {
                    required
                },
                photo_descriptions: {
                    required,

                    $each: {
                        photo: {
                            required
                        }
                    }
                },
                photo_comments: {
                    required,

                    $each: {
                        photo: {
                            required: requiredIf(function () {
                                if (this.regeneration.photo_comments && this.regeneration.photo_comments.length > 1) {
                                    return required
                                }
                                return false
                            })
                        }
                    }
                },
                damages: {
                    required,

                    $each: {
                        obj: {
                            id: {
                                required
                            }
                        }
                    }
                }
            }
        },
        mounted() {
            this.loadDamages()

            if(this.$state.params.extra) {
                this.regeneration.patient = this.$state.params.extra.patient
                this.regeneration.product = this.$state.params.extra.product
                this.regeneration.product_name = this.$state.params.extra.product_name
                this.regeneration.product_brand = this.$state.params.extra.product_brand
                this.regeneration.product_type = this.$state.params.extra.product_type
                this.regeneration.source = this.$state.params.extra.source
                this.regeneration.source_id = this.$state.params.extra.source_id

                if(Object.keys(this.$state.params.extra.patient).indexOf('id') > -1) {
                    this.patient_disabled = true
                }
            }

            if(this.$uac.user.departmentType === 3 || this.$uac.permission('regenerations.admin')) {
                this.allow_discount = true
            }

            this.addPhoto('descriptions')
            this.addPhoto('comments')
        },
        methods: {
            goAddRegeneration() {
                this.loading = true

                API.post('regenerations/add', this.regeneration).then(() => {
                    this.$state.go('app.regenerations.department')

                    this.$notify.success('regeneration.notify.ADD')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('regeneration.notify.error.ADD')
                })
            },
            addRegeneration() {
                let prefix = 'regeneration.notify.error.',
                    info = []

                if(this.regeneration.date_expected < moment().format('YYYY-MM-DD')) {
                    info.push('EXPECTED')
                }

                if(!this.rodo) {
                    info.push('RODO')
                }

                info.forEach(i => {
                    this.$notify.error(prefix + i)
                })

                if(!info.length) {
                    this.goAddRegeneration()
                }
            },
            loadDamages() {
                API.get('regenerations/damages').then(res => {
                    this.damages = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('regeneration.damages.notify.error.LOAD')
                })
            },
            updatePatient(patient) {
                this.regeneration.patient = patient
            },
            updatePaymentInvalid(invalid) {
                this.payment_invalid = invalid
            },
            getAssignedSpecialists(specialists) {
                let _tab = []

                specialists.forEach(specialist => {
                    _tab.push(specialist.first_name + ' ' + specialist.last_name)
                })

                return _tab.join(', ')
            },
            addDamage() {
                this.regeneration.damages.push({
                    obj: {
                        id: null
                    }
                })
            },
            deleteDamage(key) {
                this.regeneration.damages.splice(key, 1)
            },
            updateNotes(note, note_photo) {
                this.regeneration.notes.push({
                    note: note,
                    photo: note_photo,
                    user: {
                        id: this.$uac.user.id,
                        firstName: this.$uac.user.username
                    },
                    specialist: {},
                    userRead: {},
                    specialistRead: {}
                })
            },
            deleteNote(index) {
                this.regeneration.notes.splice(index, 1)
            },
            addPhoto(type) {
                this.regeneration['photo_' + type].push({
                    photo: '',
                    loading: 'hide'
                })
            },
            deletePhoto(type, key) {
                if(this.regeneration.photo_comments.length === 1 && type === 'comments' && !key) {
                    this.regeneration['photo_comments'][0].photo = ''
                } else {
                    this.regeneration['photo_' + type].splice(key, 1)
                }
            }
        }
    }
</script>