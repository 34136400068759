(function (scope) {
    function OptiSocket(host, options, callback) {
        var settings = {
            debug: false,
            logger: function (o) {},
            department: 0,
            uuid: undefined,
            reconnecting: function (trying) {},
            lsKey: 'websocket-uuid',
            heartbeatInterval: 1000 * 30,
            userId: 0,
            uuidSetter: false
        }

        if (!options)
            options = {}

        for (var key in settings) {
            if (typeof options[key] !== 'undefined')
                this[key] = options[key]
            else
                this[key] = settings[key]
        }

        if (!this.uuid && localStorage && localStorage.getItem(this.lsKey) !== null) {
            this.uuid = localStorage.getItem(this.lsKey)
        }
        if (location.hash && location.hash.indexOf('/') === -1) {
            this.uuid = location.hash.replace('#', '');
            if (localStorage) {
                localStorage.setItem(this.lsKey, this.uuid)
            }
        }

        if (this.uuidSetter) {
            this.uuid = this.uuidSetter(this.lsKey, this.uuid)
        }

        var $this = this
        var listeners = {}
        var heartbeat = false;
        this.startHearbeat = function () {
            this.stopHeartbeat()
            if (this.heartbeatInterval) {
                message.ping()
                heartbeat = setInterval(function () {
                    if ($this.socket.readyState !== WebSocket.CLOSED) {
                        message.ping()
                    }
                }, this.heartbeatInterval)
            }
        }
        this.stopHeartbeat = function () {
            if (heartbeat) {
                clearInterval(heartbeat)
                heartbeat = false
            }
        }

        this.host = host
        this.socket = new ReconnectingWebSocket(host, null, {
            debug: this.debug,
            // Czekaj 1 sekundę przed ponownym połączeniem
            reconnectInterval: 1000,
            // Z każdą kolejną nieudaną próbą połączenia wydłużaj następny przestój o 10% (1 sekunda, 1.1 sekundy itd)
            reconnectDecay: 1.1,
            // Maksymalny czas opóźnienia ponownego połączenia (10sekund)
            maxReconnectInterval: 1000 * 10
        })

        this.socket.addEventListener('message', function (e) {
            var received = JSON.parse(e.data)

            if (!$this.uuid) {
                $this.uuid = received.uuid
                if (localStorage) {
                    localStorage.setItem(this.lsKey, received.uuid)
                }
            }

            $this.logger(received)
            $this.emit(received.type, received.payload)
        })

        this.socket.addEventListener('connecting', function (e) {
            $this.reconnecting(true)
            $this.stopHeartbeat()
        })

        this.emit = function (type, payload) {
            if (!listeners[type])
                return true

            for (var i = 0; i < listeners[type].length; i++) {
                var listener = listeners[type][i]
                if (listener(payload) === false) {
                    return false
                }
            }

            return true
        }

        var listen = function (type, func) {
            if (!listeners[type]) {
                listeners[type] = []
            }

            listeners[type].push(func)
        }
        var message = {
            send: function (type, payload) {
                $this.socket.send(JSON.stringify({
                    type: type,
                    department: $this.department,
                    host: window.location.hostname,
                    uuid: $this.uuid,
                    payload: payload
                }))
            },
            ping: function () {
                message.send('ping', $this.userId)
            },
            preload: function () {
                message.send('hello', true)
            },
            fake: function (type, payload) {
                $this.emit(type, payload)
            },
            getConfig: function (config) {
                message.send('config-get', config)
            },
            saveConfig: function (config) {
                if (config) {
                    message.send('config-save', config)
                }
            },
            displays: function () {
                message.send('displays', true)
            }
        }

        var initialized = false
        this.socket.addEventListener('open', function () {
            if (!initialized) {
                callback(message, listen)
                initialized = true
            }

            $this.reconnecting(false)
            $this.startHearbeat()
        })

        return this.socket
    }

    scope.OptiSocket = OptiSocket
})(window);
