<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-edit.mr3
            span {{'product.producent.overwrite.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .table-responsive
                table.table.table-striped.middle
                    thead
                        tr
                            th {{'product.producent.overwrite.table.FIELD'|translate}}
                            th {{'product.producent.overwrite.table.OLD_VALUE'|translate}}
                            th(style="width:400px") {{'product.producent.overwrite.table.NEW_VALUE'|translate}}

                    tbody
                        tr(v-for="overwrite in overwrites")
                            td {{overwrite.field}}
                            td
                                .form-control(disabled) {{overwrite.old_value}}
                            td
                                OptiSelectAdding(
                                    :options="options[overwrite.field]"
                                    :option="overwrite.new_value"

                                    @selected="d => overwrite.new_value = d")

                        tr(v-show="!overwrites.length")
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        template(#footer)
            OptiButton.pull-left(type="default" @click.native="$emit('closed')") {{'default.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButton.pull-right(type="success" text="product.producent.overwrite.save"
                @confirmed="save") {{'default.SAVE'|translate}}
                template(#icon)
                    i.fa.fa-save

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectAdding from '@/js/app/vue/components/Select/OptiSelectAdding'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogProductProducentOverwrite',
        components: {
            OptiDialog,
            OptiSelectAdding,
            OptiButton,
            OptiDimmer
        },
        props: {
            productId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                test: {},
                overwrites: [],
                options: {},

                loading: true
            }
        },

        mounted() {
            API.get('warehouse/product/' + this.productId + '/producent/overwrite').then(res => {
                this.overwrites = res.data.overwrites
                this.options = res.data.options
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$emit('closed')

                this.$notify.error('product.producent.overwrite.notify.error.LOAD')
            })
        },
        methods: {
            save() {
                this.loading = true

                API.post('warehouse/product/overwrites/save', {
                    product_id: this.productId,
                    overwrites: this.overwrites
                }).then(() => {
                    this.$emit('saved')
                }).finally(() => {
                    this.loading = false

                    this.$emit('closed')
                }).catch(() => {
                    this.$notify.error('product.producent.overwrite.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 1000px;

        > .modal-body {
            padding: 0;
            overflow: unset !important;

            > .table-responsive {
                overflow: unset !important;

                > .table {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>