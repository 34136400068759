<template lang="pug">
    .chart-tooltip(v-if="isVisible" :style="cssPositions" ref="tooltipBody")
        .chart-tooltip__header(v-if="hasHeader")
            slot(name="header" :header="{ tooltip: model }")
        .chart-tooltip__body
            slot(name="default" :body="{ tooltip: model }")
        .chart-tooltip__footer(v-if="hasFooter")
            slot(name="footer" :footer="{ tooltip: model }")
</template>

<script>
    export default {
        name: 'OptiChartTooltip',
        props: {
            model: {
                type: Object,
                required: false,
            },
            chartData: {
                type: Object,
                required: false
            },
            chart: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                currentHeight: 0,
                currentWidth: 0
            }
        },
        watch: {
            isVisible(newValue, oldValue) {
                if (newValue) {
                    this.updateDimensions()
                }
            },
            model(newValue, oldValue) {
                if (newValue && newValue.length) {
                    this.updateDimensions()
                }
            }
        },
        methods: {
            updateDimensions() {
                this.$nextTick(() => {
                    let tooltip = this.$refs.tooltipBody.getBoundingClientRect()
                    this.currentWidth = tooltip.width
                    this.currentHeight = tooltip.height
                })
            }
        },
        computed: {
            cssPositions() {
                if (!this.model) return ''
                if (!this.isVisible) return ''

                let x = this.model.caretX
                let y = this.model.caretY

                if (this.chart) {
                    let position = this.chart.$refs.canvas.getBoundingClientRect()

                    x += position.left + window.pageXOffset - window.scrollX
                    y += position.top + window.pageYOffset - window.scrollY

                    if (x + this.currentWidth > window.innerWidth) {
                        x = x - this.currentWidth
                    }
                    if (y + this.currentHeight > window.innerHeight) {
                        y = y - this.currentHeight
                    }
                }

                return `top: ${y}px; left: ${x}px;`
            },
            isVisible() {
                return this.model && this.model.opacity && this.model.opacity !== 0
            },
            hasHeader() {
                return !!this.$scopedSlots.header
            },
            hasFooter() {
                return !!this.$scopedSlots.footer
            },
            dataPoints() {
                if (!this.chartData) return []

                return this.model.dataPoints
            },
            items() {
                let points = this.dataPoints
                if (!points || !points.length) return []

                return points.map(item => {
                    return {
                        dataPoint: item,
                        data: this.chartData.datasets[item.datasetIndex].data[item.index]
                    }
                })
            },
            firstItem() {
                return this.items[0]
            }
        }
    }
</script>

<style scoped lang="less">
    .chart-tooltip {
        position: fixed;
        z-index: 1000;
        pointer-events: none;
    }

    .chart-tooltip__header {
        padding: .5rem;
    }

    .chart-tooltip__body {
        padding: .5rem;
    }

    .chart-tooltip__footer {
        padding: .5rem;
    }
</style>
