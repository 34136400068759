<template lang="pug">
    OptiDialog.prescription-task
        template(#header)
            i.icon.icon-settings.mr3
            span {{'prescription.TASKS'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .dialog-contents
                .btn-glasses
                    .row
                        .col-xs-6.text-center
                            a.btn.btn-lg.btn-purple(@click="prescriptionToTask(1)")
                                i.icon.icon-settings
                                div {{'tasks.type.SVNEAR'|translate}}
                        .col-xs-6.text-center
                            a.btn.btn-lg.btn-purple(@click="prescriptionToTask(2)")
                                i.icon.icon-settings
                                div {{'tasks.type.SVFAR'|translate}}
                    .row(v-show="prescription.leAdd || prescription.reAdd")
                        .col-xs-6.text-center
                            a.btn.btn-lg.btn-purple(@click="prescriptionToTask(3)")
                                i.icon.icon-settings
                                div {{'tasks.type.BIFOCAL'|translate}}
                        .col-xs-6.text-center
                            a.btn.btn-lg.btn-purple(@click="prescriptionToTask(4)")
                                i.icon.icon-settings
                                div {{'tasks.type.PROGRESIVE'|translate}}

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButton(type="danger" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferPreviewDialog
        from '@/js/app/vue/components/Catalog/Offer/Preview/OptiCatalogOfferPreviewDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsPrescriptionDialogTask',
        components: {
            OptiDialog,
            OptiButton,
            OptiCatalogOfferPreviewDialog,
            OptiDimmer
        },
        props: {
            prescription: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            generate(type) {
                this.loading = true

                API.get('prescription/task/' + this.prescription.id + '/' + type + '/0').then(res => {
                    this.$state.go('app.task.detail', {
                        id: res.data
                    })
                }).finally(() => {
                    this.loading = false

                    this.$emit('closed')
                }).catch(() => {
                    this.$notify.error('prescription.notify.ERRORTASK')
                })
            },
            prescriptionToTask(type) {
                if([1, 2].includes(this.prescription.source) && this.prescription.check.status > 1) {
                    if(confirm(this.$filters.translate('prescription.foreign.confirm.allowtask.MESSAGE'))) {
                        this.generate(type)
                    }
                } else {
                    this.generate(type)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .prescription-task {
        /deep/ .modal-container {
            max-width: 500px;
        }
    }
</style>