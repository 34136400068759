<template lang="pug">
    .panel.panel-primary(v-show="[1, 2].includes(prescription.source)")
        .panel-heading
            i.fa.fa-check.mr3
            span {{'prescription.foreign.HEADING'|translate}}
        .panel-body
            .form-group
                label.col-md-4.col-sm-3.control-label(for="checkStatus") {{'prescription.foreign.STATUS'|translate}}
                .col-md-8.col-sm-9
                    select#checkStatus.form-control(v-model="prescription.check.status" name="checkStatus"
                        @change="changeStatus()")
                        option(v-for="c in checkStatus" :value="c.id" :key="c.id") {{c.name|translate}}
        .table-responsive
            table.table.table-striped.text-center.middle
                thead
                    tr
                        th {{'prescription.DIST'|translate}}
                        th Visus
                        th C/Z
                        th BinVisus
                tbody
                    tr
                        td
                            button.btn.btn-info(type="button" :disabled="prescription.check.status !== 1"
                                @click="dialog.dist.r = true") {{'prescription.RIGHT'|translate}}

                            MountingPortal(v-if="dialog.dist.r" mountTo="#vue-modal" append)
                                OptiPatientsPrescriptionsSurveysDist(
                                    :prescription="prescription"
                                    side="right"
                                    @confirmed="updatePrescriptionCheck"
                                    @cancelled="dialog.dist.r = false")
                        td
                            span.form-control(disabled) {{prescription.check.dist_r_visus}}
                        td
                            OptiEnum.form-control(enum="InterviewRedGreen" :id="prescription.check.dist_r_cz"
                                :disabled="true")
                        td(rowspan="2")
                            span.form-control(disabled) {{prescription.check.dist_binvisus}}
                    tr
                        td
                            button.btn.btn-info(type="button" :disabled="prescription.check.status !== 1"
                                @click="dialog.dist.l = true") {{'prescription.LEFT'|translate}}

                            MountingPortal(v-if="dialog.dist.l" mountTo="#vue-modal" append)
                                OptiPatientsPrescriptionsSurveysDist(
                                    :prescription="prescription"
                                    side="left"
                                    @confirmed="updatePrescriptionCheck"
                                    @cancelled="dialog.dist.l = false")
                        td
                            span.form-control(disabled) {{prescription.check.dist_l_visus}}
                        td
                            OptiEnum.form-control(enum="InterviewRedGreen" :id="prescription.check.dist_l_cz"
                                :disabled="true")
                    tr
                        th
                            button.btn.btn-info(type="button" :disabled="prescription.check.status !== 1"
                                @click="dialog.near = true") {{'optometrist.acuity.NEAR'|translate}}

                            MountingPortal(v-if="dialog.near" mountTo="#vue-modal" append)
                                OptiPatientsPrescriptionsSurveysNear(
                                    :prescription="prescription"

                                    @confirmed="updatePrescriptionCheck"
                                    @cancelled="dialog.near = false")
                        th Visus
                        th
                        th BinVisus
                    tr
                        th {{'prescription.RIGHT'|translate}}
                        td
                            span.form-control(disabled) {{prescription.check.near_r_visus}}
                        td
                        td(rowspan="2")
                            span.form-control(disabled) {{prescription.check.near_binvisus}}
                    tr
                        th {{'prescription.LEFT'|translate}}
                        td
                            span.form-control(disabled) {{prescription.check.near_l_visus}}
                        td
</template>

<script>
    import {EnumPrescriptionCheckStatus} from '@/js/app/vue/enums/Enums'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'

    import OptiPatientsPrescriptionsSurveysDist
        from '@/js/app/vue/components/Patients/Prescriptions/Surveys/OptiPatientsPrescriptionsSurveysDist'
    import OptiPatientsPrescriptionsSurveysNear
        from '@/js/app/vue/components/Patients/Prescriptions/Surveys/OptiPatientsPrescriptionsSurveysNear'

    export default {
        name: 'OptiPatientsDetailsPrescriptionCheckVisus',
        components: {
            OptiEnum,

            OptiPatientsPrescriptionsSurveysDist,
            OptiPatientsPrescriptionsSurveysNear
        },
        props: {
            prescription: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    dist: {
                        r: false,
                        l: false
                    },
                    near: false
                },

                checkStatus: EnumPrescriptionCheckStatus.simpleItems()
            }
        },
        mounted() {
            this.checkStatus.shift()
        },
        methods: {
            updatePrescriptionCheck(check) {
                this.dialog.dist.r = false
                this.dialog.dist.l = false
                this.dialog.near = false

                this.$emit('update-prescription-check', check)
            },

            changeStatus() {
                if(this.prescription.check.status !== 1) {
                    this.prescription.check.dist_r_visus = '-'
                    this.prescription.check.dist_l_visus = '-'
                    this.prescription.check.dist_r_cz = '0'
                    this.prescription.check.dist_l_cz = '0'
                    this.prescription.check.dist_binvisus = '-'

                    this.prescription.check.near_r_visus = '-'
                    this.prescription.check.near_l_visus = '-'
                    this.prescription.check.near_binvisus = '-'
                }
            }
        }
    }
</script>
