<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.general.measurement_app.HEADING'|translate}}

        #measurement-app-department
            span.mr3 {{'settings.general.measurement_app.FOR_DEPARTMENT'|translate}}:
            span.bold {{$uac.getUser().departmentName}}.

        form.panel-body
            .page-header(style="margin-top:0") {{'settings.general.measurement_app.type.GENERAL'|translate}}
            ul
                li(v-for="m in measurement_apps")
                    .radio.c-radio.c-radio-nofont
                        label(:class="{'star-required': m.id === 2}")
                            input(type="radio" name="measurement_app"
                                v-model="measurement_app.general.selected" :value="m.id")
                            span
                            | {{m.name|translate}}

            .page-header {{'settings.general.measurement_app.type.TEMPORARY_TASK'|translate}}
            ul
                li(v-for="m in measurement_apps")
                    .radio.c-radio.c-radio-nofont
                        label(:class="{'star-required': m.id === 2}")
                            input(type="radio" name="measurement_app_task_temporary"
                                v-model="measurement_app.task_temporary.selected" :value="m.id")
                            span
                            | {{m.name|translate}}

        small.warn * - {{'settings.general.measurement_app.ALL_TOOLTIP'|translate}}.

        .panel-footer.text-right
            OptiButtonSave(:disabled="(measurement_app.general.current === measurement_app.general.selected && measurement_app.task_temporary.current === measurement_app.task_temporary.selected) || loading"
                @click.native="save")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumDepartmentMeasurementApp} from '@/js/app/vue/enums/Enums'

    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsGeneralMeasurementApp',
        components: {
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                measurement_apps: EnumDepartmentMeasurementApp.simpleItems(),

                measurement_app: {
                    general: {},
                    task_temporary: {}
                },

                loading: true
            }
        },
        mounted() {
            API.get('settings/general/measurement-app').then(res => {
                this.measurement_app = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.general.measurement_app.notify.error.LOAD')
            })
        },
        methods: {
            save() {
                this.loading = true

                API.get('settings/general/measurement-app/' + this.measurement_app.general.selected + '/' +
                    this.measurement_app.task_temporary.selected + '/save').then(res => {
                    this.measurement_app = res.data

                    this.$notify.success('settings.general.measurement_app.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.general.measurement_app.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    #measurement-app-department {
        text-align: center;
        padding: 15px 5px 0;
    }

    ul {
        list-style-type: none;
        padding-left: 10px;

        > li:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    small.warn {
        display: block;
        margin: 0 5px 5px;
    }
</style>