<template lang="pug">
  .panel.panel-primary
    .panel-heading
      i.fa.fa-navicon.mr3
      span {{'regeneration.PAYMENT'|translate}}

    .panel-body
      .row
        .col-xs-12
          form.form.form-horizontal
            OptiPayment(
              :payments="paymentDone.payment"
              :value="price"

              :required="true"

              @invalid="i => $emit('invalid', 'payment', i)")

            .form-group
              label.col-sm-4.control-label.star-required(for="receipt") {{'sale.offstock.table.head.NORECEIPT'|translate}}
              .col-sm-8
                OptiReceipt#receipt(
                  :model="paymentDone.receipt"

                  :required="true"

                  @invalid="i => $emit('invalid', 'receipt', i)")

            .form-group
              .col-sm-4.col-sm-offset-4
                label
                  .checkbox.c-checkbox.checkbox-primary.inline
                    label
                      input(type="checkbox" name="isShipmentToClient" v-model="shipment.isShipmentToClient")
                      span.fa.fa-check
                  span {{'regeneration.SHIPMENTATCUSTOMERCOST'|translate}}

            .form-group(v-if="shipment.isShipmentToClient")
              label.col-sm-4.control-label {{'regeneration.table.SHIPMENTCOST'|translate}}
              .col-sm-8
                OptiNumber#shipment_cost(:header="'regeneration.table.SHIPMENTCOST'|translate" :value="shipment.shipment_cost"
                    @confirmed="confirmCost" :float="true") {{shipment.shipment_cost|currency}}

</template>

<script>
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiReceipt from '@/js/app/vue/components/Register/Receipt/OptiReceipt'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber.vue'

    export default {
        name: 'OptiRegenerationDetailsPayment',
        components: {
          OptiNumber,
            OptiPayment,
            OptiReceipt
        },
        props: {
            paymentDone: {
                type: Object,
                required: true
            },
            price: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
              shipment: {
                isShipmentToClient: false,
                shipment_cost: 0
              }
            }
        },
        methods: {
            confirmCost(value) {
                this.shipment.shipment_cost = value
                this.$emit('shipment_cost', value)
            }
        }
    }
</script>
