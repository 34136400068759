<template lang="pug">
    .directive-prescription(style="position:relative")
        .panel(v-if="prescription"
            :class="{'panel-primary': color.class.primary, 'panel-success': color.class.success, 'dialog-prescription': isDialog}")
            .panel-heading(style="cursor:pointer" v-show="!isDialog" @click="toggle = !toggle")
                i.fa.fa-file-text-o.mr3
                span {{prescription.typeName|translate}} ({{prescription.id}})
                span.pull-right(v-show="prescription.examinDate !== '-0001-11-30'") {{prescription.examinDate|stringTime}}

            .panel-body(v-show="toggle || isDialog")
                .row
                    .col-md-6.col-sm-12.col-md-push-6(:class="{'reset-push': isDialog}")
                        .row(v-show="isDialog")
                            .col-xs-6 {{'prescription.CREATEDAT'|translate}}
                            .col-xs-6 {{prescription.examinDate}}
                        .row(v-show="prescription.interviewId > 0")
                            .col-xs-6 {{'prescription.INTERVIEWID'|translate}}
                            .col-xs-6
                                a.link.mr3(:href="$state.href('app.optometrist.details', {id: prescription.interviewId})"
                                    :title="'default.REFERENCETYPE'|translate") {{prescription.interviewId}}
                                small {{prescription.interview_type_name|translate|lower}}
                                small(style="display:block" v-show="prescription.from_recommended_visit") ({{'prescription.recommended.INFO'|translate}})
                        .row(v-show="prescription.doctorLastName")
                            .col-xs-6 {{'prescription.DOCTOR'|translate}}
                            .col-xs-6 {{prescription.doctorFirstName}} {{prescription.doctorLastName}}
                        .row
                            .col-xs-6 {{'prescription.SOURCE'|translate}}
                            .col-xs-6 {{prescription.sourceName|translate}}
                        .row(v-show="prescription.check.status")
                            .col-xs-6 {{'prescription.foreign.STATUS'|translate}}
                            .col-xs-6.bold
                                span.warn(v-show="prescription.check.status !== 1") {{prescription.check.statusName|translate}}
                                a.text-success(style="cursor:pointer" v-show="prescription.check.status === 1"
                                    @click="dialog.survey_history = true") {{prescription.check.statusName|translate}}

                        .row.btn-glasses(v-show="!isDialog && !is_sun_saler")
                            .col-xs-12
                                .row
                                    .col-xs-6(v-show="offers.length")
                                        a.btn.btn-purple(style="color:gold" @click="dialog.offer = true"
                                            :disabled="(!prescription.reCylinder && prescription.reAxis !== 'brak') || (!prescription.leCylinder && prescription.leAxis !== 'brak') || block")
                                            i.fa.fa-briefcase
                                            div {{'prescription.OFFER'|translate}}

                                            MountingPortal(v-if="dialog.offer" mountTo="#vue-modal" append)
                                                OptiPatientsPrescriptionDialogOffer(
                                                    :prescription="prescription"
                                                    :offers="offers"
                                                    :offer-id="offerId"

                                                    @closed="dialog.offer = false")

                                    .col-xs-6(v-show="prescription.typeName !== 'prescription.LENSES'")
                                        a.btn.btn-purple(@click="dialog.task = true"
                                            :disabled="(!prescription.reCylinder && prescription.reAxis !== 'brak') || (!prescription.leCylinder && prescription.leAxis !== 'brak') || block")
                                            i.icon.icon-settings
                                            div {{'prescription.TASKS'|translate}}

                                            MountingPortal(v-if="dialog.task" mountTo="#vue-modal" append)
                                                OptiPatientsPrescriptionDialogTask(
                                                    :prescription="prescription"

                                                    @closed="dialog.task = false")
                                    .col-xs-6(v-show="own")
                                        a.btn.btn-purple(:href="$state.href('app.patient.prescription-edit', {patientId: prescription.patientId, prescriptionId: prescription.id})"
                                            :disabled="block")
                                            i.icon.icon-pencil
                                            div {{'prescription.EDIT'|translate}}
                                    .col-xs-6(v-show="prescription.logs.length")
                                        a.btn.btn-purple(@click="dialog.history = true")
                                            i.icon.icon-book-open
                                            div {{'prescription.HISTORY'|translate}}

                                            MountingPortal(v-if="dialog.history" mountTo="#vue-modal" append)
                                                OptiPatientsPrescriptionDialogHistory(:logs="prescription.logs"
                                                    @closed="dialog.history = false")
                                    .col-xs-6(v-show="prescription.check && prescription.check.logs && prescription.check.logs.length")
                                        a.btn.btn-purple(@click="dialog.survey_history = true")
                                            i.icon.icon-book-open
                                            div {{'prescription.SURVEYHISTORY'|translate}}

                                            MountingPortal(v-if="dialog.survey_history" mountTo="#vue-modal" append)
                                                OptiPatientsPrescriptionDialogSurveyHistory(:check-id="prescription.check.id"
                                                    @closed="dialog.survey_history = false")
                                    .col-xs-6(v-show="prescription.interviewId < 1")
                                        OptiButton.hide(:id="'contact-lens-' + prescription.id" text="prescription.contactlens.confirm"
                                            @confirmed="goContactLens")
                                        label.btn.btn-purple(:for="'contact-lens-' + prescription.id" :disabled="block")
                                            i.icon.icon-eye
                                            div {{'optometrist.contactlens.CONTACTLENS'|translate}}
                                    .col-xs-6(v-show="prescription.interviewId < 1")
                                        a.btn.btn-purple(@click="dialog.covd = true")
                                            i.icon.icon-pencil
                                            div {{'prescription.covd.BTN'|translate}}

                                            MountingPortal(v-if="dialog.covd" mountTo="#vue-modal" append)
                                                OptiOptometristsDetailsSummaryDialogCOVD(
                                                    :questionnaire="prescription.questionnaire"

                                                    @confirmed="updateQuestionnaire"
                                                    @cancelled="dialog.covd = false")

                        .row(style="margin-top:5px" v-if="!isDialog && points !== -1")
                            .col-xs-12
                                OptiButton(type="info" :class="{'active': toggleCOVD}" @click.native="toggleCOVD = !toggleCOVD"
                                    ) COVD {{points|points}}
                                    template(#icon)
                                        i.fa.fa-question
                            .col-xs-12(style="padding-right:0" v-show="toggleCOVD && points > 0")
                                ul(style="list-style-type:none;padding-left:0px")
                                    li(style="padding-top:5px" v-for="questionnaire in questions" :key="questionnaire.id"
                                        v-show="questionnaire.pivot.option")
                                        span.mr3 {{questionnaire.pl}} -
                                        OptiEnum(style="font-size:.9em;font-style:italic;text-transform:lowercase"
                                            enum="InterviewQuestionnaireOption" :id="questionnaire.pivot.option")

                        .row(v-show="prescription.uploads.length && !isDialog")
                            .col-xs-12
                                .panel
                                    .panel-body
                                        h4 {{'prescription.UPLOADSCAN'|translate}}
                                        .table-responsive
                                            table.table
                                                tbody
                                                    tr(v-for="upload in prescription.uploads")
                                                        td
                                                            OptiButtonImage(:url="'prescription/' + upload.name"
                                                                ) {{upload.name}}

                    .col-md-6.col-sm-12.col-md-pull-6(:class="{'reset-push': isDialog}")
                        .row(v-show="prescription.reCylinder * prescription.leCylinder < 0")
                            .col-xs-12
                                .alert.alert-warning
                                    i.fa.fa-warning.mr3
                                    span {{'prescription.notify.warning.DIFFRENTSIGNCYLINDER'|translate}}
                        .row(v-show="(!prescription.reCylinder && prescription.reAxis !== 'brak') || (!prescription.leCylinder && prescription.leAxis !== 'brak')")
                            .col-xs-12
                                .alert.alert-warning
                                    i.fa.fa-warning.mr3
                                    span {{'prescription.notify.warning.AXISWITHOUTCYLINDER'|translate}}

                        .row
                            .col-xs-12
                                .table-responsive
                                    table.table.table-presenter.table-striped
                                        thead
                                            tr
                                                th(style="width:20%")
                                                th {{'prescription.RIGHT'|translate}}
                                                th {{'prescription.LEFT'|translate}}
                                        tbody
                                            tr
                                                th {{'prescription.SPHERE'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('reSphere')}") {{prescription.reSphere|plus}}
                                                td(:class="{'warn': prescription.changes.includes('leSphere')}") {{prescription.leSphere|plus}}
                                            tr
                                                th {{'prescription.CYLINDER'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('reCylinder')}") {{prescription.reCylinder|plus}}
                                                td(:class="{'warn': prescription.changes.includes('leCylinder')}") {{prescription.leCylinder|plus}}
                                            tr(v-show="prescription.reCylinder || prescription.leCylinder")
                                                th {{'prescription.AXIS'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('reAxis')}") {{prescription.reAxis}}
                                                td(:class="{'warn': prescription.changes.includes('leAxis')}") {{prescription.leAxis}}
                                            tr
                                                th {{'prescription.ADDICTION'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('reAdd')}") {{prescription.reAdd|number(2)}}
                                                td(:class="{'warn': prescription.changes.includes('leAdd')}") {{prescription.leAdd|number(2)}}

                                            tr(v-show="prescription.rePrism || prescription.lePrism")
                                                td.text-center(colspan="3")
                                                    b.mr3 {{'prescription.prism.METHOD'|translate}}
                                                    span(v-show="!prescription.prism_mode") {{'prescription.prism.mode.HORIZONTALVERTICAL'|translate}}
                                                    span(v-show="prescription.prism_mode") {{'prescription.prism.mode.RESULT'|translate}}
                                            tr
                                                th {{'prescription.PRISM'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('rePrism')}") {{prescription.rePrism|number(2)}}
                                                td(:class="{'warn': prescription.changes.includes('lePrism')}") {{prescription.lePrism|number(2)}}
                                            tr(v-show="prescription.rePrism || prescription.lePrism")
                                                th {{'prescription.PRISMANGLE'|translate}}
                                                td(:class="{'warn': prescription.changes.includes('rePrismAngle')}") {{prescription.rePrismAngle}}
                                                td(:class="{'warn': prescription.changes.includes('lePrismAngle')}") {{prescription.lePrismAngle}}
                                            tr(v-show="prescription.rePrism || prescription.lePrism")
                                                th {{'prescription.prism.HORIZONTAL'|translate}}
                                                td
                                                    span.mr3 {{prism.r.horizontal.power|number(2)}}
                                                    small {{'prescription.prism.side.' + prism_side[prism.r.horizontal.side]|translate}}
                                                td
                                                    span.mr3 {{prism.l.horizontal.power|number(2)}}
                                                    small {{'prescription.prism.side.' + prism_side[prism.l.horizontal.side]|translate}}
                                            tr(v-show="prescription.rePrism || prescription.lePrism")
                                                th {{'prescription.prism.VERTICAL'|translate}}
                                                td
                                                    span.mr3 {{prism.r.vertical.power|number(2)}}
                                                    small {{'prescription.prism.side.' + prism_side[prism.r.vertical.side]|translate}}
                                                td
                                                    span.mr3 {{prism.l.vertical.power|number(2)}}
                                                    small {{'prescription.prism.side.' + prism_side[prism.l.vertical.side]|translate}}

                                            tr
                                                th(rowspan="2") {{'prescription.PDDISTANCE'|translate}}
                                                td(colspan="2" :class="{'warn': prescription.changes.includes('rePdDist') || prescription.changes.includes('lePdDist')}") {{prescription.pdDist|number(2)}}
                                            tr
                                                td.text-center(:class="{'warn': prescription.changes.includes('rePdDist')}") {{prescription.rePdDist|number(2)}}
                                                td(:class="{'warn': prescription.changes.includes('lePdDist')}") {{prescription.lePdDist|number(2)}}
                                            tr
                                                th(rowspan="2") {{'prescription.PDNEAR'|translate}}
                                                td(colspan="2" :class="{'warn': prescription.changes.includes('rePdNear') || prescription.changes.includes('lePdNear')}") {{prescription.pdNear|number(2)}}
                                            tr
                                                td.text-center(:class="{'warn': prescription.changes.includes('rePdNear')}") {{prescription.rePdNear|number(2)}}
                                                td(:class="{'warn': prescription.changes.includes('lePdNear')}") {{prescription.lePdNear|number(2)}}

                                            tr(v-show="prescription.check.status === 1")
                                                th
                                                th.text-center(colspan="2") {{'prescription.DIST'|translate}}
                                            tr(v-show="prescription.check.status === 1")
                                                th Visus
                                                td(:class="{'warn': prescription.changes.includes('distRVisus')}") {{prescription.check.dist_r_visus}}
                                                td(:class="{'warn': prescription.changes.includes('distLVisus')}") {{prescription.check.dist_l_visus}}
                                            tr(v-show="prescription.check.status === 1")
                                                th C/Z
                                                td(:class="{'warn': prescription.changes.includes('distRCz')}")
                                                    OptiEnum(enum="InterviewRedGreen" :id="prescription.check.dist_r_cz")
                                                td(:class="{'warn': prescription.changes.includes('distLCz')}")
                                                    OptiEnum(enum="InterviewRedGreen" :id="prescription.check.dist_l_cz")
                                            tr(v-show="prescription.check.status === 1")
                                                th BinVisus
                                                td.text-center(colspan="2" :class="{'warn': prescription.changes.includes('distBinvisus')}") {{prescription.check.dist_binvisus}}
                                            tr(v-show="prescription.check.status === 1")
                                                th
                                                th(style="text-align:center" colspan="2") {{'prescription.NEAR'|translate}}
                                            tr(v-show="prescription.check.status === 1")
                                                th Visus
                                                td(:class="{'warn': prescription.changes.includes('nearRVisus')}") {{prescription.check.near_r_visus}}
                                                td(:class="{'warn': prescription.changes.includes('nearLVisus')}") {{prescription.check.near_l_visus}}
                                            tr(v-show="prescription.check.status === 1")
                                                th BinVisus
                                                td.text-center(colspan="2" :class="{'warn': prescription.changes.includes('nearBinvisus')}") {{prescription.check.near_binvisus}}

                                            tr(v-show="prescription.comments")
                                                th {{'prescription.COMMENTS'|translate}}
                                                td(colspan="2" style="text-align:left" :class="{'warn': prescription.changes.includes('comments')}"
                                                    v-html="$options.filters.nl2br(prescription.comments)")
                                            tr(v-show="prescription.suggestions")
                                                th {{'prescription.SUGGESTIONS'|translate}}
                                                td(colspan="2" style="text-align:left"  v-html="$options.filters.nl2br(prescription.suggestions)")

        OptiDimmer(:active="!prescription || loading.covd")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {initPrescriptionPrism} from '@/js/app/vue/helpers/PrescriptionUpdatePrism'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonImage from '@/js/app/vue/components/Button/OptiButtonImage'
    import OptiOptometristsDetailsSummaryDialogCOVD
        from '@/js/app/vue/components/Optometrists/Details/Summary/Dialogs/OptiOptometristsDetailsSummaryDialogCOVD'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    import OptiPatientsPrescriptionDialogOffer
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/OptiPatientsPrescriptionDialogOffer'
    import OptiPatientsPrescriptionDialogTask
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/OptiPatientsPrescriptionDialogTask'
    import OptiPatientsPrescriptionDialogHistory
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/OptiPatientsPrescriptionDialogHistory'
    import OptiPatientsPrescriptionDialogSurveyHistory
        from '@/js/app/vue/components/Patients/Prescriptions/Dialogs/OptiPatientsPrescriptionDialogSurveyHistory'

    export default {
        name: 'OptiPatientsPrescription',
        components: {
            OptiButton,
            OptiButtonImage,
            OptiOptometristsDetailsSummaryDialogCOVD,
            OptiEnum,
            OptiDimmer,

            OptiPatientsPrescriptionDialogOffer,
            OptiPatientsPrescriptionDialogTask,
            OptiPatientsPrescriptionDialogHistory,
            OptiPatientsPrescriptionDialogSurveyHistory
        },
        props: {
            id: {
                type: Number,
                required: false,
                default: 0
            },
            data: {
                type: Object,
                required: false,
                default: null
            },
            offers: {
                type: Array,
                required: false,
                default: () => []
            },
            offerId: {
                type: Number,
                required: false,
                default: 0
            },

            isDialog: {
                type: Boolean,
                required: false,
                default: false
            },
            block: {
                type: Boolean,
                required: false,
                default: true
            },
            own: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                prescription: null,
                prism: {
                    r: {
                        horizontal: {
                            power: 0,
                            side: 0
                        },
                        vertical: {
                            power: 0,
                            side: 2
                        }
                    },
                    l: {
                        horizontal: {
                            power: 0,
                            side: 0
                        },
                        vertical: {
                            power: 0,
                            side: 2
                        }
                    }
                },

                toggle: false,
                toggleCOVD: false,

                color: {
                    type: {
                        'prescription.REFRACTION': 'primary',
                        'prescription.LENSES': 'success'
                    },
                    class: {
                        primary: false,
                        success: false
                    }
                },
                prism_side: ['NOSAL', 'TEMPLE', 'UP', 'DOWN'],

                questions: [],
                points: -1,

                noneIdInterview: this.$filters.translate('default.NA'),

                dialog: {
                    offer: false,
                    task: false,
                    history: false,
                    survey_history: false,
                    covd: false
                },

                is_sun_saler: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
                loading: {
                    covd: false
                }
            }
        },
        mounted() {
            if(this.id) {
                API.get('prescriptions/' + this.id).then(res => {
                    this.setData(res.data)
                }).catch(() => {
                    this.$notify.error('prescription.notify.ERRORDATA')
                })
            } else {
                this.setData(this.data)
            }
        },
        methods: {
            setData(prescription) {
                this.prescription = prescription

                this.prescription.interviewName =
                    this.prescription.interviewId > 0 ? this.prescription.interviewId : this.noneIdInterview

                this.color.class[this.color.type[this.prescription.typeName]] = true

                this.toggle = !!this.prescription.show

                if(this.prescription.historySurvey) {
                    this.dialog.survey_history = true
                }

                this.loadQuestionnaire()

                initPrescriptionPrism(this.prescription, this.prism)

                this.$emit('get-uploads', this.prescription.uploads)
            },

            loadQuestionnaire() {
                if(!this.isDialog) {
                    if(this.prescription.interviewId < 1) {
                        this.loading.covd = true

                        API.get('prescription/covd/' + this.prescription.id).then(res => {
                            this.prescription.questionnaire = res.data

                            this.countPoints('prescription')
                        }).finally(() => {
                            this.loading.covd = false
                        }).catch(() => {
                            this.$notify.error('prescription.covd.notify.error.LOAD')
                        })
                    } else if(this.prescription.interview.questionnaire) {
                        this.countPoints('interview')
                    }
                }
            },
            updateQuestionnaire(questionnaire) {
                this.dialog.covd = false
                this.loading.covd = true

                API.post('prescription/covd/save', {
                    prescription_id: this.prescription.id,
                    questions: questionnaire.questions
                }).then(res => {
                    this.prescription.questionnaire = res.data.questionnaire
                    this.prescription.logs = res.data.logs

                    this.countPoints('prescription')

                    this.$emit('reload-covd')

                    this.$notify.success('prescription.covd.notify.SAVE')
                }).finally(() => {
                    this.loading.covd = false
                }).catch(() => {
                    this.$notify.error('prescription.covd.notify.error.SAVE')
                })
            },
            countPoints(covd_from) {
                this.questions = covd_from === 'interview' ? this.prescription.interview.questionnaire :
                    this.prescription.questionnaire
                this.points = 0

                this.questions.forEach(questionnaire => {
                    if(questionnaire.pivot.option === -1) {
                        this.points = -1
                    } else {
                        this.points += questionnaire.pivot.option
                    }
                })
            },

            goContactLens() {
                API.get('optometrist/contactlens/' + this.prescription.id).then(res => {
                    this.$notify.success('prescription.contactlens.notify.CREATED')

                    this.$state.go('app.optometrist.details', {
                        id: res.data
                    })
                }).catch(() => {
                    this.$notify.error('prescription.contactlens.notify.error.CREATED')
                })
            }
        }
    }
</script>
