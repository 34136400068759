<template lang="pug">
    div
        OptiUsersTrainingScheduleNavigation(style="margin-bottom:15px"
            :department="department"
            :view="view"
            :holidays="holidays"

            :loading="loading"

            @update-data="load"
            @update-department="load")

        .row
            .col-xs-12
                table.table.table-month.text-center.table-schedule.table-mark-past(v-show="!loading")
                    thead
                        tr
                            th {{'schedule.directive.view.header.WEEK'|translate}}
                            th(v-for="day in days") {{day}}
                    tbody
                        tr(v-for="(week, key) in events" :class="key % 2 ? 'mark-even' : 'mark-odd'")
                            td.bg-separator.middle {{week.week}}
                            td(v-for="(data, date) in week.data" :style="getMonthStyle(week, data)"
                                :class="{'is-past': data.past, 'is-today': data.today}")
                                .label-month-day
                                    span.pull-left.warn(style="max-width:50%;font-size:.8em;text-align:left" v-if="holidays[date]") {{holidays[date]|translate}}
                                    span.pull-left(style="color:navy;font-size:.8em" v-if="specialEvents[date]") {{specialEvents[date]}}
                                    span.pull-right
                                        span.text-muted.mr3(v-show="view.type === 'quarter'") {{monthName(date)}}
                                        span {{data.day}}
                                    .clearfix

                                .bg-separator(v-for="training in data.trainings" :class="{'is_cancelled': training.status === 4}")
                                    a.text-center(:title="'user.training.schedule.link.TRAINING'|translate"
                                        :href="$state.href('app.user.training.details', {id: training.id})")
                                        .main-info
                                            .text-expressive.bold(style="font-size:20px") {{training.name}}
                                            .text-expressive {{training.day}} {{'user.training.schedule.DAYS'|translate}}
                                            .text-muted-on-dark
                                                div
                                                    i.fa.fa-clock-o.mr3
                                                    span {{training.time}}
                                                div(style="display:flex;justify-content:space-around")
                                                    div(:class="{'animated flash infinite warn': training.status !== 4 && !training.users_count.without_cancelled}")
                                                        i.fa.fa-users.mr3
                                                        span {{training.users_count.without_cancelled}}
                                                        span(style="margin-left:3px"
                                                            v-show="training.users_count.without_cancelled !== training.users_count.all"
                                                            ) ({{training.users_count.all}})
                                                    div(v-show="training.notes_count")
                                                        i.fa.fa-file.text-warning.mr3
                                                        span {{training.notes_count}}

                                                .warn(v-show="(training.status === 0 || training.status === 1) && training.users_count.without_cancelled < training.max"
                                                    ) {{'user.training.FREE'|translate}}: {{training.max - training.users_count.without_cancelled}}

                                        .position-absolute(v-show="training.status === 4")
                                            .label-cancelled.warn
                                                i.fa.fa-remove.mr3
                                                span {{'user.training.status.CANCELLED'|translate}}

                                    .users
                                        .month-event-user(v-for="user in training.users"
                                            :title="'user.training.schedule.link.SCHEDULE'|translate"
                                            :class="user.classes" @click="goToSchedule(date, user)") {{user.user_abbr}}

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiUsersTrainingScheduleNavigation
        from '@/js/app/vue/components/Users/Trainings/Schedule/OptiUsersTrainingScheduleNavigation'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersTrainingSchedule',
        components: {
            OptiDimmer,
            OptiUsersTrainingScheduleNavigation
        },
        data() {
            let department_disabled = [49, 60, 86, 149, 392, 402] //tak samo jak w OptiUsersTrainingScheduleNavigation

            return {
                view: {
                    type: this.$state.params.view,
                    start: moment(this.$state.params.date).set({hour: 0, minute: 0, second: 0}),
                    end: moment(this.$state.params.date).set({hour: 23, minute: 59, second: 59}),
                    cancelled: false,
                    label: {
                        main: moment().format('DD-MM-YYYY'),
                        sub: moment().format('dddd')
                    }
                },
                department: {
                    id: this.$state.params.department_id === '0' ?
                        department_disabled.includes(this.$uac.user.activeDepartmentId) ? 91 : this.$uac.user.activeDepartmentId
                        : department_disabled.includes(parseInt(this.$state.params.department_id)) ? 91 : parseInt(this.$state.params.department_id)
                },

                holidays: {},
                specialEvents: {},
                events: [],

                days: [],
                loading: true
            }
        },
        mounted() {
            this.days = this.getDaysLabel()

            this.load()
        },
        methods: {
            load() {
                this.loading = true

                this.specialEvents = BudgetHelper.getSpecialEvents(this.view.start)

                API.post('trainings/schedule', {
                    start: this.view.start.format('YYYY-MM-DD'),
                    end: this.view.end.format('YYYY-MM-DD'),
                    department_id: this.department.id,
                    cancelled: this.view.cancelled
                }, API.id(Math.random())).then(res => {
                    this.holidays = res.data.holidays
                    this.events = res.data.events
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('user.training.notify.error.LIST')
                })
            },

            monthName(date) {
                return moment(date).format('MMMM')
            },
            getMonthStyle(week, item) { //TODO: to jest DRY występuje w innym miejscu
                if(this.view.type === 'month') {
                    return {}
                }

                if(!week.bordering) return {}

                let style = {}

                if(item.day > 20) {
                    style['border-bottom'] = '3px solid black'
                } else {
                    style['border-top'] = '3px solid black'
                }

                if(item.day === 1) {
                    style['border-left'] = '3px solid black'
                }

                return style
            },

            getDaysLabel() {
                let days = [],
                    date = this.view.start.clone().startOf('week')

                for(let i = 0; i < 7; ++i) {
                    days.push(date.format('ddd'))
                    date.add(1, 'day')
                }

                return days
            },

            goToSchedule(date, user) {
                if(user.schedule_department_id === -1) {
                    this.$notify.error('user.training.schedule.notify.error.NOACCESSSCHEDULE')
                } else if(user.schedule_department_id) {
                    this.$state.go('app.schedule.calendar', {
                        department_id: user.schedule_department_id,
                        view: 'week',
                        date: moment(date).startOf('week').format('YYYY-MM-DD'),
                        budget: '0'
                    })
                } else {
                    this.$notify.error('user.training.schedule.notify.error.NOSCHEDULE')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    td > .bg-separator {
        display: block;
        margin-bottom: 15px;
        border: 2px solid transparent;

        > a.text-center {
            display: block;
            position: relative;
            color: inherit;
            text-decoration: none !important;
        }

        &.is_cancelled:not(:hover) {
            > a.text-center {
                > .main-info {
                    opacity: .2;
                }

                .position-absolute {
                    position: absolute;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    > .label-cancelled {
                        > i.fa.fa-remove {
                            display: block;
                            font-size: 40px;
                        }

                        > span {
                            font-size: 20px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        > .users {
            max-height: 0;
            transition: max-height .75s ease;
            overflow: hidden;

            > .month-event-user {
                cursor: pointer;

                &:first-child {
                    margin-top: 10px !important;
                }

                &.cancelled {
                    opacity: .35 !important;
                    text-decoration: line-through;
                }
            }
        }

        &:hover {
            border: 2px solid #aaa;

            > .users {
                max-height: 1000px;
                transition: max-height 2s ease;
            }
        }
    }
</style>