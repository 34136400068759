<template lang="pug">
    .row(v-if="bonuses && bonusTableVisible")
        .col-lg-4.col-lg-offset-8.col-md-6.col-md-offset-6
            div(style="padding:15px")
                table.table.table-condensed.table-striped.table-bordered.table-xs.text-center
                    tbody
                        tr
                            th {{'bonus.LENSESBONUS'|translate}}
                            td {{contactLensesBonus}}%

            div(style="padding:15px")
                table.table.table-condensed.table-striped.table-bordered.table-xs.text-center.middle
                    tbody
                        tr
                            th.item(rowspan="2") #
                            th.item(rowspan="2") {{'bonus.table.TURNOVER'|translate}}
                            th.item(colspan="2") {{'bonus.table.percent.HEADING'|translate}}
                        tr
                            th.item {{'bonus.table.percent.TASK'|translate}}
                            th.item {{'bonus.table.percent.SALE'|translate}}
                        tr(v-for="(bonus, index) in orderedPercent")
                            td.item {{index + 1}}
                            td.item {{bonus.turnoverAbove}}
                            td.item {{bonus.percent_task}}%
                            td.item {{bonus.percent_sale}}%
</template>
<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'BonusShow2',
        props: {
            departmentId: {
                type: Number,
                required: true
            },

            currentQuarter: {
                type: Boolean,
                required: true
            },
            bonusTableVisible: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                bonuses: false
            }
        },
        async mounted() {
            try {
                let {data} = await API.get('dictionary/bonus/show/' + this.departmentId)

                this.bonuses = data.bonuses
            } catch(e) {
                this.$notify.error('bonus.notify.error.LOAD_PRICE_CAPS')
            }
        },
        computed: {
            orderedPercent() {
                return _.orderBy(this.bonuses[+ this.currentQuarter].items, 'turnoverAbove')
            },
            contactLensesBonus() {
                return this.bonuses[+ this.currentQuarter].contact_lenses_bonus
            }
        }
    }
</script>