<template lang="pug">
    .opti-select-snapshot
        VueMultiSelect(
            :options="dates"
            :value="date"
            :clearable="false"
            label="week"
            @input="updateSelected"
            placeholder="Wybierz zakres"
            group-label="year"
            group-values="dates"
            :group-select="false"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.week}}
                .pull-right.text-muted {{type === 'from' ? props.option.start : props.option.end}}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{props.option.week}}
                    small.pull-right {{props.option.start}} - {{props.option.end}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'

    export default {
        name: 'OptiSelectSnapshot',
        components: {VueMultiSelect},
        props: {
            type: {
                type: String,
                required: true
            },
            opposite: {
                type: Object,
                required: true,
                default: {}
            },
            yearBefore: {
                type: Boolean,
                required: false,
                default: false
            },
            date: {
                type: Object,
                required: true,
                default: {}
            }
        },
        watch: {
           opposite() {
                this.filter()
           }
        },
        data() {
            return {
                dates: []
            }
        },
        mounted() {
            let session = sessionStorage.getItem('snapshots-weeks')

            if(session) {
                this.parse(JSON.parse(session))
            } else {
                API.get('reports/snapshots/weeks', API.id(Math.random())).then(res => {
                    sessionStorage.setItem('snapshots-weeks', JSON.stringify(res.data))

                    this.parse(res.data)
                }).catch(() => {
                    this.$notify.error('snapshots.notify.error.SELECTWEEKS')
                })
            }
        },
        methods: {
            select(weeks) {
                if(this.yearBefore) {
                    let part = weeks[0].week.split('/'),
                        before = (parseInt(part[0]) - 1) + '/' + part[1]

                    weeks.forEach((week) => {
                        if(week.week === before) {
                            this.$emit('selected', week)
                        }
                    })
                } else {
                    this.$emit('selected', weeks[0])
                }
            },
            group(data) {
                data.forEach((date) => {
                    let year = date.week.substring(0, 4),
                        found = false

                    this.dates.forEach((grouped) => {
                        if(grouped.year === year) {
                            grouped.dates.push(date)

                            found = true
                        }
                    })

                    if(!found) {
                        this.dates.push({
                            year: year,
                            dates: [date]
                        })
                    }
                })
            },
            filter() {
                if(this.dates.length) {
                    this.dates.forEach((grouped) => {
                        grouped.dates.forEach((date) => {
                            date.$isDisabled = this.type === 'from' ? date.id > this.opposite.id : date.id < this.opposite.id
                        })
                    })
                }
            },
            parse(weeks) {
                this.select(weeks)
                this.group(weeks)
                this.filter()

                this.$emit('weeks', this.dates)
            },
            updateSelected(date) {
                this.$emit('selected', date)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: 0.5;
    }
</style>
