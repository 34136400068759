import pl from '../../../../../public/lang/pl_PL.json'
import en from '../../../../../public/lang/en_EN.json'

const languages = {
    pl_PL: pl,
    en_EN: en
}

window.languages = languages

export default languages
