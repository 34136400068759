<template lang="pug">
    OptiButton#opti-button-sms(type="primary" :disabled="disabled" @click.native="toggleSMS(true)") {{'sms.dialog.SENDSMS'|translate}}
        template(#icon)
            i.fa.fa-phone

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogSms(
                :patient="patient"
                :tags="tags"

                @sent="$emit('sent')"
                @closed="toggleSMS(false)")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogSms from '@/js/app/vue/components/Button/Dialogs/OptiDialogSms'

    export default {
        name: 'OptiButtonSms',
        components: {
            OptiButton,
            OptiDialogSms
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },

            patient: {
                type: Object,
                required: true
            },
            tags: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {

        },
        methods: {
            toggleSMS(bool) {
                this.open = bool
            }
        }
    }
</script>