<template lang="pug">
    .row
        .col-sm-7(v-show="!loading")
            form.form-validate(@submit.prevent="selectProduct")
                input#productFromBarcode.form-control(style="margin-bottom:10px"
                    :placeholder="'orders.wz.add.product.PLACEHOLDER'|translate"
                    :name="productFromBarcode" v-model="productFromBarcode")
        .col-sm-5.text-right(style="margin-bottom:10px" v-show="!loading")
            OptiButton(type="success" @click.native="addRow") {{'orders.wz.add.ADDROW'|translate}}
                template(#icon)
                    i.fa.fa-plus

        .col-xs-12(v-show="!loading")
            .panel.panel-primary
                .panel-heading(v-show="wzId")
                    i.fa.fa-edit.mr3
                    span {{'orders.wz.breadcrumb.EDIT'|translate}} {{'orders.wz.breadcrumb.ID'|translate|lower}} \#{{wzId}}
                .panel-heading(v-show="!wzId")
                    i.fa.fa-navicon.mr3
                    span {{'orders.wz.add.POSITIONS'|translate}}

                table.table.table-condensed.table-striped.middle
                    thead
                        tr
                            th #
                            th {{'orders.wz.head.PRODUCTID'|translate}}
                            th
                                label.star-required(style="margin-bottom:0") {{'orders.wz.head.PRODUCT'|translate}}
                            th {{'orders.wz.head.PRICE'|translate}}
                            th
                                label.star-required(style="margin-bottom:0") {{'orders.wz.head.QUANTITY'|translate}}
                            th {{'orders.wz.head.COMMENTS'|translate}}
                            th
                    tbody
                        template(v-for="(item, index) in wz.items")
                            tr
                                td.text-right {{index + 1}}
                                td.text-right {{item.product.id}}
                                td
                                    OptiSelectProduct(:product="item.product" :index="index"
                                        @selected="updateProduct(index, $event)")
                                td {{item.product.priceWholesale|currency}}
                                td
                                    OptiNumber(:header="'orders.wz.add.TYPEQ'|translate" :disabled="!item.product.id"
                                        :value="item.quantity" @confirmed="v => item.quantity = v") {{item.quantity}}
                                td
                                    input.form-control(:disabled="!item.product.id" v-model="item.comments")
                                td
                                    button.btn.btn-danger(:disabled="!wz.items[1]" @click="removeRow(wz.items, index)")
                                        i.fa.fa-trash
                            tr(v-if="!wz.items[0].product.id")
                                td(colspan="2")
                                td.warn.bold(colspan="6") {{'orders.wz.add.validate.product.ATLEASTONE'|translate}}
                            tr(v-if="!wz.items[index].product.id")
                                td(colspan="2")
                                td.warn.bold(colspan="6") {{'orders.wz.add.validate.product.REQUIRED'|translate}}
                            tr(v-if="wz.items[index].product.stock < 1")
                                td(colspan="2")
                                td.warn.bold(colspan="6") {{'orders.wz.add.validate.product.NOSTOCK'|translate}}

        .col-md-8.col-md-offset-4.col-xs-12.col-xs-offset-0(v-if="!loading")
            form.form.form-horizontal(@submit.prevent="goSave")
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiSwitchToggleButton(:checked="wz.otherDepartment" @input="t => wz.otherDepartment = t")
                            template(#left) {{'orders.wz.add.switch.DEPARTMENT'|translate}}
                            template(#right) {{'orders.wz.add.switch.OTHERS'|translate}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="department") {{'orders.wz.add.DEPARTMENT'|translate}}
                    .col-sm-8(v-show="!wz.otherDepartment")
                        OptiSelectDepartment#department(:reduce="department => department.id" :simple="true"
                            :department-id="wz.department_id" @selected="d => wz.department_id = d"
                            :show-all="$uac.user.activeDepartmentId === 49")
                    .col-sm-8(v-show="wz.otherDepartment")
                        textarea#department.form-control(v-model="wz.department_name")
                .form-group
                    label.col-sm-4.control-label(for="comments") {{'orders.wz.add.COMMENTS'|translate}}
                    .col-sm-8
                        textarea#comments.form-control(v-model="wz.comments")
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="disabled")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiSwitchToggleButton from '@/js/app/vue/components/Button/OptiSwitchToggleButton'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiOrdersWzAddEdit',
        components: {
            OptiButton,
            OptiSelectProduct,
            OptiNumber,
            OptiSwitchToggleButton,
            OptiSelectDepartment,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            wzId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                productFromBarcode: null,
                wz: {
                    otherDepartment: false,
                    department_id: 49,
                    department_name: '',
                    items: [{
                        product: {},
                        quantity: 1,
                        corrected: 1,
                        comments: ''
                    }]
                },

                disabled: true,
                loading: true
            }
        },
        mounted() {
            if(this.wzId) {
                API.get('warehouse/order/wz/details/' + this.wzId).then(res => {
                    this.wz = res.data

                    Vue.set(this.wz, 'otherDepartment', this.wz.department_id === 49 && this.wz.department_name !== 'Magazyn')
                }).finally(() => {
                    this.disabled = false
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.wz.notify.error.LOAD')
                })
            } else {
                this.loading = false
            }
        },
        methods: {
            selectProduct() {
                API.post('warehouse/order/wz/productFromBarcode', {
                    productFromBarcode: this.productFromBarcode
                }).then(res => {
                    if(res.data) {
                        if(!this.wz.items[0].product.id) {
                            this.wz.items.splice(0, 1)
                        }

                        let wasAdded = false
                        this.wz.items.forEach(item => {
                            if(item.product.id === res.data.id) {
                                item.quantity += 1
                                item.corrected += 1
                                this.productFromBarcode = ''
                                this.disabled = false
                                wasAdded = true
                            }
                        })

                        if(!wasAdded) {
                            this.wz.items.unshift({
                                product: res.data,
                                quantity: 1,
                                comments: '',
                                corrected: 1
                            })

                            this.productFromBarcode = ''
                            this.disabled = false
                        }
                    } else {
                        this.productFromBarcode = ''

                        this.$notify.error('orders.wz.add.validate.product.NOEXISTS')
                    }
                })
            },

            removeRow(items, index) {
                if(!this.wz.items[index].product.id) {
                    this.disabled = false
                }

                this.wz.items.splice(index, 1)
            },
            addRow() {
                this.disabled = true

                this.wz.items.unshift({
                    product: {},
                    quantity: 1,
                    corrected: 1,
                    comments: ''
                })
            },

            updateProduct(idx, product) {
                this.wz.items[product.index].product = product

                this.disabled = false
            },

            goCreate() {
                this.loading = true

                API.post('warehouse/order/wz/create', this.wz).then(res => {
                    this.$state.go('app.warehouse.order.wz.details', {
                        id: res.data
                    })

                    this.$notify.success('orders.wz.notify.CREATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.wz.notify.error.CREATE')
                })
            },
            goEdit() {
                this.loading = true

                API.post('warehouse/order/wz/edit', this.wz).then(res => {
                    this.$state.go('app.warehouse.order.wz.details', {
                        id: res.data
                    })

                    this.$notify.success('orders.wz.notify.EDIT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.wz.notify.error.EDIT')
                })
            },
            goSave() {
                this.loading = true

                if(this.wzId) {
                    this.goEdit()
                } else {
                    this.goCreate()
                }
            }
        }
    }
</script>