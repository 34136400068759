import {required, email, numeric} from 'vuelidate/lib/validators'

export default {
    supplier: {
        name: {
            required
        },
        fullName: {
            required
        },
        countryId: {
            required
        },
        city: {
            required
        },
        zipCode: {
            required
        },
        street: {
            required
        },
        contactPerson: {
            required
        },
        contactPhone: {
            required,
            numeric
        },
        contactEmail: {
            required,
            email
        },
        clientId: {
            required,
            numeric
        },
        ourContactPerson: {
            required
        },
        ourContactPhone: {
            required,
            numeric
        },
        ourContactEmail: {
            required,
            email
        },
        homepage: {
            required
        },
        NIP: {
            numeric,
            required
        }
    }
}