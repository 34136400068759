<template lang="pug">
    OptiDialog(:class="{'fullwidth': uploads.length}")
        template(#header)
            i.fa.fa-file-text-o.mr3
            span {{'patient.breadcrumb.PRESCRIPTION'|translate}} {{id}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                div(:class="{'col-xs-5': uploads.length, 'col-xs-12': !uploads.length}")
                    OptiPatientsPrescription(:id="id" :is-dialog="true" @get-uploads="u => uploads = u")
                .col-xs-7(v-show="uploads.length")
                    a(style="display:block;width:100%" v-for="upload in uploads" :href="'prescription/' + upload.name|upload" target="_blank"
                        :title="'default.title.PREVIEW'|translate")
                        img(style="width:100%" :src="'prescription/' + upload.name|upload")

        template(#footer)
            OptiButton.pull-right(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiPatientsPrescription from '@/js/app/vue/components/Patients/Prescriptions/OptiPatientsPrescription'

    export default {
        name: 'OptiDialogPrescription',
        components: {
            OptiDialog,
            OptiButton,
            OptiPatientsPrescription
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                uploads: []
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        width: 450px;

        .panel {
            border: none;

            > .panel-body {
                padding: 0;
            }
        }
    }
</style>