<template lang="pug">
    .opti-select-references-from-task
        VueMultiSelect(
            id="opti-select-references-from-task"
            :options="options"
            :value="referenceTmp.result"
            :clearable="false"
            label="name"
            :placeholder="'tasks.left.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"
            @input="r => $emit('selected', r)"
            :loading="loading"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            @search-change="loadData"
            :disabled="false")
                template(slot="singleLabel" slot-scope="props")
                    span {{props.option.id}}
                    .pull-right {{props.option.name}}
                template(slot="option" slot-scope="props")
                    span {{props.option.id}}
                    .pull-right {{props.option.name}}
                template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiTaskDetailsSummarySelectReferences',
        components: {VueMultiSelect},
        props: {
            taskId: {
                type: Number,
                required: true
            },
            referenceTmp: {
                type: Object,
                required: true
            },
            options: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                queue: null,

                loading: false
            }
        },
        methods: {
            async loadData(search) {
                clearTimeout(this.queue)

                this.queue = setTimeout(() => {
                    if(parseInt(this.referenceTmp.type) > 0 && search && search.length >= 3) {
                        this.loading = true

                        API.get('references/prompt/' + this.referenceTmp.type + '/' + search + '/' + this.taskId).then(res => {
                            this.$emit('update-options', res.data)
                        }).finally(() => {
                            this.loading = false
                        }).catch(() => {
                            this.$notify.error('tasks.left.notify.ERRORCONNECTIONPROMPT')
                        })
                    }
                }, 1500)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
