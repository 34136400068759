<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-print.mr3
            span {{'prescription.SOURCE'|translate}}

        .panel-body
            .form-group
                label.col-md-4.col-sm-3.control-label(for="source") {{'default.TYPE'|translate}}
                .col-md-8.col-sm-9
                    select#source.form-control(:disabled="!prescription.source || disabled"
                        v-model="prescription.source" @change="updateSource")
                        option(v-for="source in sources" :value="source.id") {{source.name|translate}}

            .form-group(v-show="!prescription.source")
                label.col-md-4.col-sm-3.control-label(for="interviewId") {{'prescription.INTERVIEWID'|translate}}
                .col-md-8.col-sm-9.form-control-static
                    a.link(:href="$state.href('app.optometrist.details', {id: prescription.interviewId})"
                        :title="'default.REFERENCETYPE'|translate") {{prescription.interviewId}}

            .form-group(v-show="prescription.source")
                label.col-md-4.col-sm-3.control-label(for="file") {{'default.FILE'|translate}}
                .col-md-8.col-sm-9
                    OptiUpload(v-for="(file, index) in prescription.files" :key="index"
                        :id="'prescription-' + index"

                        prefix="prescription"
                        :picture="true"
                        :disabled-delete="file && file.startsWith('prescription/')"

                        :file="file"

                        @update-loading="l => updateFileLoading(index, l)"
                        @update-file="f => updateFile(index, f)")
</template>

<script>
    import {EnumPrescriptionSource} from '@/js/app/vue/enums/Enums'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'

    export default {
        name: 'OptiPatientsDetailsPrescriptionSource',
        components: {
            OptiSelectEnum,
            OptiUpload
        },
        props: {
            prescription: {
                type: Object,
                required: true
            },
            loading: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                sources: EnumPrescriptionSource.simpleItems()
            }
        },
        mounted() {
            this.$nextTick(() => {
                if(!this.disabled && !location.hash.includes('interview-')) {
                    this.sources.shift()
                }
            })
        },
        methods: {
            updateSource() {
                this.prescription.doctorId = 0

                this.prescription.doctorFirstName = ''
                this.prescription.doctorLastName = ''
                this.prescription.doctorNpwz = ''
                this.prescription.doctorContact = ''
            },

            updateFileLoading(index, loading) {
                this.$set(this.loading.files, index, loading)
            },
            updateFile(index, file) {
                if(file) {
                    this.$set(this.prescription.files, index, file)
                } else {
                    this.prescription.files.splice(index, 1)
                    this.loading.files.splice(index, 1)
                }

                if(!this.prescription.files.filter(f => !f).length) {
                    this.prescription.files.push(null)
                    this.loading.files.push(false)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    div[id^="prescription-"]:not(:last-child) {
        margin-bottom: 15px;
    }
</style>
