<template lang="pug">
    .curve-info
        span(v-show="!loading")
            span.bold.mr3(style="font-size:1.3em" :title="'catalog.curve.SELECTION_STATUS'|translate") {{selection}}
            small.text-muted.mr3(v-show="xx" :title="'catalog.curve.XX'|translate") (XX)
            span.mr3
            span(:title="'catalog.curve.TITLE'|translate")
                i.fa.fa-crop.mr3
                span {{curve}}

        i.fa.fa-cog.fa-spin(v-show="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiCurveInfo',
        props: {
            productId: {
                type: Number,
                required: true
            },
            attributes: {
                type: Array,
                required: false
            },
            selection: {
                type: String,
                required: false
            },
            xx: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                curve: '-',

                loading: true
            }
        },
        mounted() {
            if(typeof this.attributes !== 'undefined') {
                this.getCurveFromAttributes()
            } else {
                this.getCurveFromServer()
            }
        },
        methods: {
            getCurveFromAttributes() {
                this.curve = '-'

                this.attributes.forEach(attribute => {
                    if(attribute.attributeId === 31) {
                        this.curve = attribute.value
                    }
                })

                this.loading = false
            },
            getCurveFromServer() {
                API.get('warehouse/products/curve/' + this.productId, API.id(Math.random())).then(res => {
                    this.curve = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.curve.notify.error.LOAD')
                })
            }
        }
    }
</script>