<template lang="pug">
    .row(style="margin-left:0;margin-right:0")
        .col-sm-6(style="padding-left:7.5px;padding-right:7.5px")
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'sidebar.shipments.OUTCOMING'|translate}}
                .col-xs-12.col-sm-6.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.shipments.outcoming.ready')")
                            h2.mt0 {{dashboard.shipData.outComing.ready || 0}}
                            .mb0.text-muted {{'shipments.status.READY'|translate}}
                .col-xs-12.col-sm-6.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.shipments.outcoming.sent')")
                            h2.mt0 {{dashboard.shipData.outComing.sent || 0}}
                            .mb0.text-muted {{'shipments.status.SENT'|translate}}

        .col-sm-6(style="padding-left:7.5px;padding-right:7.5px")
            .action-div.clearfix
                .panel.panel-transparent.action-div-container
                    h4.text-center {{'sidebar.shipments.INCOMING'|translate}}
                .col-xs-12.col-sm-6.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.shipments.incoming.ready')")
                            h2.mt0 {{dashboard.shipData.inComing.ready || 0}}
                            .mb0.text-muted {{'shipments.status.READY'|translate}}
                .col-xs-12.col-sm-6.p-sm
                    .panel.widget.action-panel
                        a.panel-body.text-center(:href="$state.href('app.warehouse.shipments.incoming.sent')")
                            h2.mt0 {{dashboard.shipData.inComing.sent || 0}}
                            .mb0.text-muted {{'shipments.status.SENT'|translate}}
</template>

<script>
    export default {
        name: 'OptiDashboardSimpleShipping',
        components: {

        },
        props: {
            dashboard: {
                type: Object,
                required: true
            }
        }
    }
</script>