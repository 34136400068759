<template lang="pug">
    div
        .row(v-show="!loading")
            .col-xs-12
                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th {{'patient.covd.DATE'|translate}}
                                th {{'patient.covd.SOURCE'|translate}}
                                th {{'patient.covd.ANSWERS'|translate}}
                                th {{'patient.covd.POINTS'|translate}}
                                th

                        tbody
                            tr(v-for="questionnaire in covd")
                                td {{questionnaire.date}}
                                td
                                    a.link(v-show="questionnaire.ref_type === 'interview'" target="_blank"
                                        :href="$state.href('app.optometrist.details', {id: questionnaire.ref_id})"
                                        ) {{'optometrist.breadcrumb.INTERVIEW'|translate}} {{questionnaire.ref_id}}

                                    OptiButtonPrescription(v-if="questionnaire.ref_type === 'prescription'"
                                        :id="questionnaire.ref_id")
                                td
                                    div(v-for="question in questionnaire.questions")
                                        span.mr3 {{question.question}} -
                                        i(style="font-size:.9em;text-transform:lowercase") {{question.answer}}
                                td(:class="{warn: questionnaire.points > 20}")
                                    span {{questionnaire.points|points}}
                                    i.fa.fa-exclamation-circle(style="margin-left:3px" v-show="questionnaire.points > 20")
                                td

                            tr.text-center.text-muted(v-show="!covd.length")
                                td(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonPrescription from '@/js/app/vue/components/Button/OptiButtonPrescription'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsCOVD',
        components: {
            OptiButtonPrescription,
            OptiDimmer
        },
        data() {
            return {
                covd: [],

                loading: true
            }
        },
        mounted() {
            API.get('covd/patientId/' + this.$state.params.id).then(res => {
                this.covd = res.data

                this.$emit('update-count', 'covd', this.covd.length)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('patient.covd.notify.error.LOAD')
            })
        }
    }
</script>
