<template lang="pug">
    .multiselect-multiple
        VueMultiSelect(
            v-model="value"
            :options="options"
            :multiple="true"
            :tagging="true"
            :close-on-select="false"
            :preserve-search="true"

            :placeholder="'default.SELECT_OR_TYPE'|translate"
            :selected-label="'default.SELECTED'|translate"
            :select-label="'default.ADD'|translate"
            :deselect-label="'default.REMOVE'|translate"

            :custom-label="customLabel"
            track-by="name"
            :preselect-first="false"
            :disabled="disabled"

            @input="p => $emit('selected', p)")

            span.text-muted(slot="noResult")
                i.fa.fa-exclamation-circle.mr3
                span {{'default.notify.EMPTYSEARCH'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import helper from '@/js/app/vue/components/Select/Multi/helper'

    export default {
        name: 'OptiMultiSelect',
        components: {
            VueMultiSelect
        },
        props: {
            lang: {
                type: String,
                required: false,
                default: ''
            },

            options: {
                type: Array,
                required: true
            },
            option: {
                type: Array,
                required: true
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                value: this.option
            }
        },
        methods: {
            customLabel({name}) {
                return helper.multiselectCustomLabel(this.lang, name)
            }
        }
    }
</script>