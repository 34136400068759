<template lang="pug">
    a.link(@click="open = true")
        slot
        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogProduct(:product="product" @closed="open = false")
</template>

<script>
    import OptiDialogProduct from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiDialogProduct'

    export default {
        name: 'OptiProduct',
        components: {
            OptiDialogProduct
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        }
    }
</script>