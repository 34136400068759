<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiLayoutRangeDelete',
        components: {
            OptiDimmer
        },
        mounted() {
            if(confirm(this.$filters.translate('layout.ranges.actions.delete.MESSAGE'))) {
                API.delete('layout/ranges/action/' +  this.$state.params.id).then(() => {
                    this.$state.go('app.layout.ranges')

                    this.$notify.success('layout.ranges.notify.DELETE')
                }).catch(() => {
                    this.$notify.error('layout.ranges.notify.error.DELETE')
                })
            } else {
                this.$state.go('app.layout.ranges')
            }
        }
    }
</script>