<template lang="pug">
    div(style="position:relative")
        .row
            .col-sm-6
                .table-responsive
                    table
                        tr
                            th {{'settings.offer.translations.colors.stats.FILLED'|translate}}
                            td {{stats.filled}}
                        tr
                            th {{'settings.offer.translations.colors.stats.INACTIVE'|translate}}
                            td(:class="{'text-animated-alert': stats.inactive > 0}") {{stats.inactive}}
                        tr
                            th(style="padding-right:10px") {{'settings.offer.translations.colors.stats.NOT_FILLED'|translate}}
                            td(:class="{'text-animated-alert': stats.not_filled > 0}") {{stats.not_filled}}

            .col-sm-6.text-right
                .btn-group
                    .btn.btn-default(:class="{'active': mode === 'all'}" @click="setMode('all')"
                        ) {{'settings.offer.translations.colors.toggle.ALL'|translate}}
                    .btn.btn-default(:class="{'active': mode === 'inactive'}" @click="setMode('inactive')"
                        ) {{'settings.offer.translations.colors.toggle.ONLY_INACTIVE'|translate}}
                    .btn.btn-default(:class="{'active': mode === 'empty'}" @click="setMode('empty')"
                        ) {{'settings.offer.translations.colors.toggle.ONLY_EMPTY'|translate}}

            .col-xs-12
                table.table.table-striped.middle
                    thead
                        tr
                            th Id
                            th {{'settings.offer.translations.colors.table.NAME'|translate}}
                            th {{'settings.offer.translations.colors.table.CODE'|translate}}
                            th {{'settings.offer.translations.colors.table.TRANSLATION'|translate}}
                            th
                            th

                    tbody
                        tr(v-for="(color, key) in colors_filtered" :key="color.id"
                            :class="{'bg-separator': key % 2}")
                            td
                                a.link(:class="{'text-animated-alert': !color.active || !color.description_init}"
                                    @click="dialog = color") {{color.id}}
                            td
                                a.link(:class="{'text-animated-alert': !color.active || !color.description_init}"
                                    @click="dialog = color") {{color.name}}
                            td
                                a.link(:class="{'text-animated-alert': !color.active || !color.description_init}"
                                    @click="dialog = color") {{color.code}}
                            td(style="width:300px")
                                input.form-control.text-center(:disabled="!!(typing_color_id && typing_color_id !== color.id)"
                                    v-model.lazy="color.description")
                            td.text-center(style="width:70px")
                                OptiAutoSave(endpoint="settings/offer/translations/colors/save" fields="description"
                                    :model="color" :key="color.id" @saved="colorAfterSaved(color)")
                            td.text-center(style="width:50px")
                                label.btn.btn-danger(v-show="!color.active" :for="'delete_color_' + color.id")
                                    i.fa.fa-trash

                                OptiButton.hide(:id="'delete_color_' + color.id"
                                    text="settings.offer.translations.colors.delete" @confirmed="del(color.id)")
                                    template(#icon)
                                        i.fa.fa-trash

                        tr(v-show="!colors_filtered.length")
                            td.text-muted.text-center(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span(v-if="mode === 'all'") {{'default.table.EMPTY'|translate}}
                                span(v-else-if="mode === 'empty'") {{'settings.offer.translations.colors.notify.NO_EMPTY_COLORS'|translate}}
                                span(v-else-if="mode === 'inactive'") {{'settings.offer.translations.colors.notify.NO_INACTIVE_COLORS'|translate}}

            OptiProductsListDialog(v-if="dialog"
                endpoint="settings/offer/translations/colors/get-products"
                :params="dialog"

                @close="dialog = null")
                template(#header)
                    i.fa.fa-paint-brush.mr3
                    span.mr30 {{'settings.offer.translations.card.FRAMES_COLORS'|translate}}

                    span.mr30 Id - {{dialog.id}}
                    span.mr30 {{'settings.offer.translations.colors.table.NAME'|translate}} - {{dialog.name}}
                    span.mr30
                        span.mr3 {{'settings.offer.translations.colors.table.CODE'|translate}} -
                        span(v-if="dialog.code") {{dialog.code}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}
                    span
                        span.mr3 {{'settings.offer.translations.colors.table.TRANSLATION'|translate}} -
                        span(v-if="dialog.description") {{dialog.description}}
                        span.warn(v-else) {{'default.NA'|translate|lower}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiAutoSave from '@/js/app/vue/components/OptiAutoSave'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsOfferTranslationsDetailsFramesColors',
        components: {
            OptiAutoSave,
            OptiButton,
            OptiProductsListDialog,
            OptiDimmer
        },
        data() {
            return {
                colors: [],

                mode: 'empty',
                dialog: null,
                loading: true
            }
        },
        mounted() {
            API.get('settings/offer/translations/colors/0', API.id(Math.random())).then(res => {
                this.parseColor(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('settings.offer.translations.colors.notify.error.LOAD')
            })
        },
        methods: {
            parseColor(colors) {
                this.colors = colors

                this.colors.forEach(color => this.$set(color, 'description_init', color.description))
            },
            colorAfterSaved(color) {
                setTimeout(() => {
                    color.description_init = color.description

                    this.$emit('color-was-updated')
                }, 2000)
            },
            del(id) {
                this.loading = true

                API.delete('settings/offer/translations/colors/delete/' + id).then(res => {
                    this.parseColor(res.data)

                    this.$notify.success('settings.offer.translations.colors.notify.DELETE')
                }).finally(() => {
                    this.$emit('color-was-updated')

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('settings.offer.translations.colors.notify.error.DELETE')
                })
            },
            setMode(mode) {
                this.loading = true

                setTimeout(() => {
                    this.mode = mode
                }, 50)

                setTimeout(() => {
                    this.loading = false
                }, 2000)
            }
        },
        computed: {
            stats() {
                let filled = this.colors.filter(c => c.description_init).length,
                    inactive = this.colors.filter(c => !c.active).length,
                    not_filled = this.colors.length - filled

                this.$emit('update-count', inactive + not_filled)

                return {
                    filled: filled,
                    inactive: inactive,
                    not_filled: not_filled
                }
            },
            colors_filtered() {
                return this.colors.filter(c => this.mode === 'all' || (this.mode === 'inactive' && !c.active) ||
                    (this.mode === 'empty' && !c.description_init))
            },
            typing_color_id() {
                let typing_color_ids = this.colors.filter(c => c.description_init !== c.description)

                return typing_color_ids.length ? typing_color_ids[0].id : 0
            }
        }
    }
</script>