<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span.mr3 {{'catalog.product.report.reclamation.HEADING'|translate}}
            small {{'catalog.product.report.reclamation.LEGEND'|translate}}

        .row
            .col-md-6
                .table-responsive
                    table.table.table-striped.middle
                        caption.bg-separator.text-expressive.text-center
                            i.icon.icon-eyeglasses.mr3
                            span.mr3 {{'catalog.product.report.reclamation.reclamations.TASKS'|translate}}
                            span.badge(v-show="reclamations.tasks.length") {{reclamations.tasks.length}}
                        thead
                            tr
                                th.text-right {{'catalog.product.report.ID'|translate}}
                                th {{'catalog.product.report.DATE'|translate}}
                                th {{'catalog.product.report.VALUE'|translate}}
                                th {{'catalog.product.report.CREATEDBY'|translate}}
                        tbody
                            tr(v-for="task_reclamation in reclamations.tasks.slice(0, all.reclamations.tasks ? reclamations.tasks.length : limit)")
                                td.text-right
                                    a.link(v-if="!hide" :href="$state.href('app.tasks.reclamation.details', {id: task_reclamation.id})" target="_blank") {{task_reclamation.id}}
                                    span(v-else) {{task_reclamation.id}}
                                td {{task_reclamation.date}}
                                td {{task_reclamation.value|currency}}
                                td {{task_reclamation.created_by}}

                            tr(v-show="!reclamations.tasks.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot(v-show="reclamations.tasks.length > limit")
                            tr
                                td.text-center(colspan="7")
                                    a.link(v-show="!all.reclamations.tasks" @click="all.reclamations.tasks = true") {{'default.table.show.ALL'|translate}}
                                    a.link(v-show="all.reclamations.tasks" @click="all.reclamations.tasks = false") {{'default.table.show.ONLY'|translate}} {{limit}} {{'default.table.show.ROWS'|translate}}

            .col-md-6
                .table-responsive
                    table.table.table-striped.middle
                        caption.bg-separator.text-expressive.text-center
                            i.fa.fa-shopping-cart.mr3
                            span.mr3 {{'catalog.product.report.reclamation.reclamations.SALES'|translate}}
                            span.badge(v-show="reclamations.sales.length") {{reclamations.sales.length}}
                        thead
                            tr
                                th.text-right {{'catalog.product.report.ID'|translate}}
                                th {{'catalog.product.report.DATE'|translate}}
                                th {{'catalog.product.report.VALUE'|translate}}
                                th {{'catalog.product.report.CREATEDBY'|translate}}
                        tbody
                            tr(v-for="sale_reclamation in reclamations.sales.slice(0, all.reclamations.sales ? reclamations.sales.length : limit)")
                                td.text-right
                                    a.link(v-if="!hide" :href="$state.href('app.sale.reclamation.details', {id: sale_reclamation.id})" target="_blank") {{sale_reclamation.id}}
                                    span(v-else) {{sale_reclamation.id}}
                                td {{sale_reclamation.date}}
                                td {{sale_reclamation.value|currency}}
                                td {{sale_reclamation.created_by}}

                            tr(v-show="!reclamations.sales.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot(v-show="reclamations.sales.length > limit")
                            tr
                                td.text-center(colspan="7")
                                    a.link(v-show="!all.reclamations.sales" @click="all.reclamations.sales = true") {{'default.table.show.ALL'|translate}}
                                    a.link(v-show="all.reclamations.sales" @click="all.reclamations.sales = false") {{'default.table.show.ONLY'|translate}} {{limit}} {{'default.table.show.ROWS'|translate}}

            .col-md-6(v-if="$uac.permission('products.reports')")
                .table-responsive
                    table.table.table-striped.middle
                        caption.bg-separator.text-expressive.text-center
                            i.icon.icon-magic-wand.mr3
                            span.mr3 {{'catalog.product.report.reclamation.REGENERATIONS'|translate}}
                            span.badge(v-show="regenerations.length") {{regenerations.length}}
                        thead
                            tr
                                th.text-right {{'catalog.product.report.ID'|translate}}
                                th {{'catalog.product.report.DATE'|translate}}
                                th {{'catalog.product.report.VALUE'|translate}}
                                th {{'catalog.product.report.CREATEDBY'|translate}}
                        tbody
                            tr(v-for="regeneration in regenerations.slice(0, all.regenerations ? regenerations.length : limit)")
                                td.text-right
                                    a.link(v-if="!hide" :href="$state.href('app.regeneration.details', {id: regeneration.id})" target="_blank") {{regeneration.id}}
                                    span(v-else) {{regeneration.id}}
                                td {{regeneration.date}}
                                td {{regeneration.value|currency}}
                                td {{regeneration.created_by}}

                            tr(v-show="!regenerations.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot(v-show="regenerations.length > limit")
                            tr
                                td.text-center(colspan="7")
                                    a.link(v-show="!all.regenerations" @click="all.regenerations = true") {{'default.table.show.ALL'|translate}}
                                    a.link(v-show="all.regenerations" @click="all.regenerations = false") {{'default.table.show.ONLY'|translate}} {{limit}} {{'default.table.show.ROWS'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductDetailsReportReclamationsRegenerations',
        components: {
            OptiDimmer
        },
        data() {
            return {
                reclamations: {
                    tasks: [],
                    sales: [],
                },
                regenerations: [],

                limit: 5,
                all: {
                    reclamations: {
                        tasks: false,
                        sales: false
                    },
                    regenerations: false
                },

                hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/product/' + this.$state.params.id + '/report-reclamations-regenerations', API.id(Math.random())).then(res => {
                this.reclamations.tasks = res.data.reclamations.tasks
                this.reclamations.sales = res.data.reclamations.sales
                this.regenerations = res.data.regenerations
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.product.report.reclamation.notify.error.LOAD')
            })
        }
    }
</script>