<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            OptiButtonHistory(data="return" :id="sendback.id")

            OptiButtonPrint.pull-right(
                :title="'returns.GOPRINTTITLE'|translate"
                :disabled="!sendback.items.length"

                :id="sendback.id"
                url="warehouse/return/print") {{'returns.GOPRINT'|translate}}

            OptiButton.pull-right(style="margin-right:10px" type="success" :title="'returns.ACCOMPLISH'|translate"
                :disabled="!sendback.items.length" v-show="!sendback.status && permission.returns"
                text="returns.accomplish.confirm" @confirmed="accomplishReturn") {{'returns.ACCOMPLISH'|translate}}
                template(#icon)
                    i.fa.fa-check

        .col-xs-12(style="margin-top:10px" v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'returns.breadcrumb.RETURN'|translate}}
                    span \#{{sendback.id}}
                    .pull-right \#{{sendback.date}}
                .table-responsive
                    table.table.table-condensed.table-striped.middle
                        thead
                            tr
                                th #
                                th {{'returns.items.head.PRODUCTID'|translate}}
                                th {{'returns.items.head.PRODUCTNAME'|translate}}
                                th {{'returns.items.head.PRICE'|translate}}
                                th(v-show="permission.wholesale_prices") {{'returns.items.head.PRICEWHOLESALE'|translate}}
                                th {{'returns.items.head.RETURNED'|translate}}
                                th {{'returns.items.head.RECEIVED'|translate}}
                                th {{'returns.items.head.VALUE'|translate}}
                                th {{'returns.items.head.COMMENTS'|translate}}
                                th
                        tbody
                            tr(v-for="(item, key) in sendback.items")
                                td.text-right {{sendback.items.length - key}}
                                td.text-right
                                    OptiProduct(:product="item.product") {{item.productId}}
                                td
                                    OptiProduct(:product="item.product") {{item.productSymbol}}
                                    small(style="display:block") {{item.productName}}
                                td.text-right {{item.price|currency}}
                                td.text-right(v-show="permission.wholesale_prices") {{item.wholesale_price|currency}}
                                td.text-right {{item.quantity}}
                                td.text-right
                                    OptiNumber(style="width:100px" v-show="!sendback.status && permission.returns"
                                        :header="$filters.translate('returns.items.head.RECEIVED') + ' ' + item.productSymbol"
                                        :value="item.delivered" @confirmed="delivered => sendback.items[key].delivered = delivered"
                                        ) {{item.delivered}}
                                    span(v-show="sendback.status || !permission.returns") {{item.delivered}}
                                td.text-right {{item.price * item.quantity|currency}}
                                td(v-html="$options.filters.nl2br(item.comments)")
                                td.text-center
                                    a(:href="$state.href('app.catalog.product', {id: item.productId, departmentId: sendback.customerId})"
                                        :title="'returns.items.title.PRODUCT'|translate")
                                        i.fa.fa-2x.fa-fw.fa-info-circle
                        tfoot.text-expressive(v-if="sendback.items.length > 1 || (sendback.quantity && sendback.value === 0.01)")
                            tr
                                th.text-right(:colspan="permission.wholesale_prices ? 5 : 4") {{'returns.items.SUM'|translate}}
                                th.text-right {{sendback.quantity}}
                                th.text-right
                                    span(v-show="sendback.status") {{sendback.quantityCorrected}}
                                th.text-right {{sendback.value|currency}}
                                th.text-right
                                th(colspan="2")

        .col-md-8.col-md-offset-4.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-default(style="border-top-width:1px")
                    .panel-container
                        .row(v-show="sendback.user.id")
                            .col-sm-6 {{'returns.items.ORDERBY'|translate}}
                            .col-sm-6
                                span.mr3 {{sendback.user.firstName}} {{sendback.user.lastName}}
                                span(v-show="sendback.department.id") ({{sendback.department.name}})
                        .row
                            .col-sm-6 {{'returns.items.STATUS'|translate}}
                            .col-sm-6 {{sendback.statusName|translate}}
                        .row(v-show="permission.wholesale_prices")
                            .col-sm-6 {{'returns.items.PRICEWHOLESALE'|translate}}
                            .col-sm-6 {{sendback.wholesaleValue|currency}}
                        .row(v-show="sendback.comments")
                            .col-sm-6 {{'returns.COMMENTS'|translate}}
                            .col-sm-6(v-html="$filters.nl2br(sendback.comments)")
                        .row(v-show="shipment_exists")
                            .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                            .col-sm-6
                                OptiShipmentNotice(:type="2" :id="sendback.id"
                                    @shipment-exists="bool => shipment_exists = bool")
            .text-right(style="margin-bottom:25px" v-show="!sendback.status && permission.returns")
                OptiButton(type="danger" text="returns.cancel.confirm" @confirmed="goCancel") {{'returns.cancel.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-remove

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSendbackDetails',
        components: {
            OptiButtonHistory,
            OptiButton,
            OptiButtonPrint,
            OptiProduct,
            OptiNumber,
            OptiShipmentNotice,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                sendback: null,
                shipment_exists: false,

                permission: {
                    returns: this.$uac.permission('warehouse.returns'),
                    wholesale_prices: this.$uac.permission('returns.wholesale_prices')
                },

                loading: true
            }
        },
        mounted() {
            API.get('warehouse/return/' + this.$state.params.id).then(res => {
                this.getData(res.data)

                if(!this.sendback.status) {
                    this.sendback.items.forEach(item => {
                        item.delivered = item.quantity
                    })
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('returns.notify.error.LOAD')
            })
        },
        methods: {
            getData(sendback) {
                this.sendback = sendback

                this.$emit('status-name-was-changed', this.sendback.statusName)
            },
            goCancel() {
                this.loading = true

                API.get('warehouse/return/cancel/' + this.$state.params.id).then(res => {
                    this.getData(res.data)

                    this.$notify.success('returns.notify.CANCEL')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('returns.notify.error.CANCEL')
                })
            },
            accomplishReturn() {
                let delivered = 0
                this.sendback.items.forEach(item => {
                    delivered += item.delivered
                })

                if(!delivered) {
                    this.goCancel()

                    return true
                }

                this.loading = true

                API.post('warehouse/return/accomplish/' + this.$state.params.id, {
                    'items': this.sendback.items
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('returns.notify.ACCOMPLISH')
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.response.status === 412) {
                        this.$notify.error(error.response.data.message, false)
                    } else {
                        this.$notify.error('returns.notify.error.ACCOMPLISH')
                    }
                })
            },
        }
    }
</script>