<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-money.mr3
            span {{'catalog.product.properties.OMNIBUS'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            div(v-if="last_special_price.id")
                .panel-body.text-center
                    .row
                        .col-sm-12 {{'catalog.product.properties.omnibus.notify.PROMOTION_PRICE'|translate}}
                        .col-sm-4
                            a.link(:href="$state.href('app.prices.edit', {id: last_special_price.id})"
                                target="_blank") \#{{last_special_price.id}}
                        .col-sm-4
                            i.fa.fa-calendar.mr3
                            span {{last_special_price.date}}
                        .col-sm-4
                            i.fa.fa-money.mr3
                            span {{last_special_price.price|currency}}

                .panel-body.text-center
                    .row
                        .col-xs-12 {{'catalog.product.properties.omnibus.notify.INFO'|translate}}

                        .col-sm-6
                            i.fa.fa-calendar.mr3
                            span {{dates.end}}
                        .col-sm-6
                            i.fa.fa-calendar.mr3
                            span {{dates.start}}

                .table-responsive(v-if="list.length")
                    table.table.table-striped
                        thead
                            tr
                                th.text-right #
                                th {{'catalog.product.properties.omnibus.CHANGE_PRICE_ID'|translate}}
                                th {{'catalog.product.properties.omnibus.DATE'|translate}}
                                th {{'catalog.product.properties.omnibus.PRICE'|translate}}
                        tbody
                            tr(v-for="(price_change, key) in list"
                                :class="{'bg-separator': price_change.id === min.id}")
                                td.text-right {{list.length - key}}
                                td
                                    a.link(:href="$state.href('app.prices.change', {id: price_change.id})"
                                        target="_blank") {{price_change.id}}
                                td {{price_change.start_date}}
                                td.text-right(:class="{'bold': price_change.id === min.id}") {{price_change.price|currency}}

                            tr.text-center.text-muted(v-show="!list.length")
                                td(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                .panel-body.text-center(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span.mr3 {{'catalog.product.properties.omnibus.notify.NO_LIST'|translate}}
                    b {{last_special_price.price_before|currency}}

            .panel-body.text-center(v-else)
                i.fa.fa-exclamation-circle.mr3
                span {{'catalog.product.properties.omnibus.notify.NO_OMNI_PRICE'|translate}}

            OptiDimmer(:active="loading")

        template(#footer)
            OptiButton(@click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogProductOmnibus',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer
        },
        props: {
            productId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                last_special_price: {
                    id: 0,
                    date: '',
                    price: 0
                },
                dates: {
                    start: '',
                    end: ''
                },
                list: [],
                min: [],

                loading: true
            }
        },

        mounted() {
            API.get('warehouse/product/' + this.productId + '/omnibus/list').then(res => {
                this.last_special_price = res.data.last_special_price
                this.dates = res.data.dates
                this.list = res.data.list
                this.min = res.data.min
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('catalog.product.properties.omnibus.notify.error.LOAD_LIST')
            })
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 550px;

        > .modal-body {
            padding: 0;

            .table {
                margin-bottom: 0;
            }
        }
    }
</style>