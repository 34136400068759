<template lang="pug">
    .panel-grid
        div(v-if="patient")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButton(type="primary" @click.native="$state.go('app.patients.schedule', {department_id: $uac.user.activeDepartmentId, patient: patient})"
                        ) {{'patient.APPOINTMENT'|translate}}
                        template(#icon)
                            i.fa.fa-calendar
                .col-md-4.text-center
                    OptiButtonHistory(data="patient" :id="patient.id")
                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
                    OptiButton(type="primary" @click.native="$state.go('app.patient.edit', {id: patient.id})"
                        ) {{'patient.EDITPATIENT'|translate}}
                        template(#icon)
                            i.fa.fa-edit
            .row
                .col-xs-12
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'patient.DETAILSPATIENT'|translate}}

                        .panel-body
                            .alert.alert-danger(v-show="patient.country.id === 171 && patient.city && !patient.teryt.name")
                                i.fa.fa-exclamation-circle.mr3
                                span.bold.mr3 {{patient.city}}
                                span {{'patient.notify.error.CITYNOEXISTS'|translate}}

                            .alert.alert-danger(v-show="block")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'patient.NOPHONE'|translate}}

                            OptiPatientVisitsRefuse(:patient-id="patient.id")

                            .row
                                .col-sm-6 {{'patient.ID'|translate}}
                                .col-sm-6 {{patient.id}}
                            .row
                                .col-sm-6 {{'patient.FIRSTNAME'|translate}}
                                .col-sm-6 {{patient.firstName}}
                            .row
                                .col-sm-6 {{'patient.LASTNAME'|translate}}
                                .col-sm-6 {{patient.lastName}}
                            .row
                                .col-sm-6 {{'patient.STATUS'|translate}}
                                .col-sm-6(:class="{'warn': patient.status > 2, 'bold': patient.status > 2}") {{patient.statusName|translate}}
                            .row
                                .col-sm-6 {{'patient.STREET'|translate}}
                                .col-sm-6 {{patient.street}}
                            .row
                                .col-sm-6 {{'patient.ZIPCODE'|translate}}
                                .col-sm-6 {{patient.zipCode}}
                            .row
                                .col-sm-6 {{'patient.CITY'|translate}}
                                .col-sm-6 {{patient.city}}
                            .row
                                .col-sm-6 {{'patient.COUNTRY'|translate}}
                                .col-sm-6 {{patient.country.pl}}
                            .row
                                .col-sm-6 {{'patient.PESEL'|translate}}
                                .col-sm-6 {{patient.pesel}}
                            .row
                                .col-sm-6 {{'patient.BIRTHDATE'|translate}}
                                .col-sm-6 {{patient.birthdate}}
                            .row
                                .col-sm-6 {{'patient.CELLPHONENUMBER'|translate}}
                                .col-sm-6
                                    span.mr3(v-show="patient.cellPhoneNumberCountry.phoneCode && patient.cellPhoneNumber"
                                        ) (+{{patient.cellPhoneNumberCountry.phoneCode}})
                                    span {{patient.cellPhoneNumber|phone}}
                            .row
                                .col-sm-6 {{'patient.PHONENUMBER'|translate}}
                                .col-sm-6 {{patient.phoneNumber}}
                            .row
                                .col-sm-6 {{'patient.EMAIL'|translate}}
                                .col-sm-6 {{patient.email}}
                            .row
                                .col-sm-6 {{'patient.SEX'|translate}}
                                .col-sm-6
                                    OptiEnum(enum="PatientSex" :id="patient.sex")
                            .row
                                .col-sm-6 {{'patient.AGEGROUP'|translate}}
                                .col-sm-6
                                    OptiEnum(enum="PatientAgeGroup" :id="patient.ageGroup")
                            .row
                                .col-sm-6 {{'patient.MAAGREEMENT'|translate}}
                                .col-sm-6
                                    i.fa.fa-check(v-show="patient.maAgreement")
                            .row
                                .col-sm-6 {{'patient.OCCUPATION'|translate}}
                                .col-sm-6 {{patient.occupation}}
                            .row
                                .col-sm-6 {{'patient.MAMEASURMENT'|translate}}
                                .col-sm-6
                                    i.fa.fa-check(v-show="patient.maMeasurment")
                            .row
                                .col-sm-6 {{'patient.MAYEAR'|translate}}
                                .col-sm-6
                                    i.fa.fa-check(v-show="patient.maYear")
                            .row
                                .col-sm-6 {{'patient.MATASK'|translate}}
                                .col-sm-6
                                    i.fa.fa-check(v-show="patient.maTask")
                            .row(v-show="patient.source")
                                .col-sm-6 {{'patient.SOURCE'|translate}}
                                .col-sm-6 {{patient.source}}
                            .row
                                .col-sm-6 {{'patient.COMMENTS'|translate}}
                                .col-sm-6(v-html="$options.filters.nl2br(patient.comments)")
                            .row
                                .col-sm-6 {{'patient.DEPARTMENT'|translate}}
                                .col-sm-6 {{patient.department.name}}
                            .row
                                .col-sm-6 {{'patient.LASTMA'|translate}}
                                .col-sm-6 {{patient.maLast}}
                            .row
                                .col-sm-6 {{'patient.LASTACTIVITY'|translate}}
                                .col-sm-6 {{patient.updated_at}}
                            .row(v-show="patient.contactPlus.length")
                                .col-sm-6 {{'patient.contactplus.info.HEADING'|translate}}
                                .col-sm-6
                                    a.link(style="margin-right:5px" v-for="contactPlus in patient.contactPlus"
                                        :title="'patient.contactplus.info.TITLE'|translate"
                                        :href="$state.href('app.patients.contact-plus-add', {id: contactPlus.id})" target="_blank"
                                        ) {{contactPlus.id}}

        OptiDimmer(:active="!patient")
</template>

<script>
    import OptiButton from '../../Button/OptiButton'
    import OptiDimmer from '../../Blocks/OptiDimmer'
    import OptiEnum from '../../OptiEnum'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiPatientVisitsRefuse from '@/js/app/vue/components/Patients/OptiPatientVisitsRefuse'

    export default {
        name: 'OptiPatientsDetailsMain',
        components: {
            OptiPatientVisitsRefuse,
            OptiButtonHistory,
            OptiEnum,
            OptiButton,
            OptiDimmer
        },
        props: {
            patient: {
                type: Object | null,
                required: true
            },
            block: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
