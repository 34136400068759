import {i18n} from '@/js/app/vue/i18n-setup'

export default {
    multiselectCustomLabel(prefix, name) {
        if(name.includes('lang:')) {
            let part_names = name.split('lang:')

            return part_names[0] + ' (' + i18n.t(part_names[1]) + ')'
        }

        return prefix ? i18n.t(prefix + '.' + name) : name
    }
}