<template lang="pug">
    .panel-checkbox-salon
        OptiDimmer(:active="loading")
        .panel.panel-primary(style="margin-bottom:0" v-show="!loading")
            .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
                i.fa.fa-navicon.mr3
                span {{'departments.breadcrumb.DEPARTMENTS'|translate}}
                .pull-right
                    .label.label-danger.mr3 {{count}}
                    i.fa(:class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")

            .panel-body(style="padding:0 15px" v-show="toggle || previewClosed")
                .row.bg-separator(v-show="previewClosed || toggle")
                    .col-xs-12.col-sm-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.jawro_loox.checked" name="jawro_loox"
                                    @change="filterChanged()" :disabled="!filter.jawro_loox.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.jawro_loox.count}") {{'departments.filter.JAWROLOOX'|translate}}
                    .col-xs-12.col-sm-5
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.franchisee_loox.checked" name="franchisee_loox"
                                    @change="filterChanged()" :disabled="!filter.franchisee_loox.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.franchisee_loox.count}") {{'departments.filter.FRANCHISEELOOX'|translate}}
                    .col-xs-12.col-sm-3(style="padding-left:0")
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.cooperating.checked" name="cooperating"
                                    @change="filterChanged()" :disabled="!filter.cooperating.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.cooperating.count}") {{'departments.filter.COOPERATING'|translate}}
                .row.bg-separator(style="border-bottom:1px solid" v-show="previewClosed || toggle")
                    .col-xs-12.col-sm-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.jawro_sunloox.checked" name="jawro_sunloox"
                                    @change="filterChanged()" :disabled="!filter.jawro_sunloox.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.jawro_sunloox.count}") {{'departments.filter.JAWROSUNLOOX'|translate}}
                    .col-xs-12.col-sm-5
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.franchisee_sunloox.checked" name="franchisee_sunloox"
                                    @change="filterChanged()" :disabled="!filter.franchisee_sunloox.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.franchisee_sunloox.count}") {{'departments.filter.FRANCHISEESUNLOOX'|translate}}
                    .col-xs-12.col-sm-3
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="filter.all.checked" name="all"
                                    @change="filterChanged()" :disabled="!filter.all.count")
                                span.fa.fa-check(style="margin-left:0px")
                            span(:class="{'strikethrough': !filter.all.count}") {{'departments.filter.ALL'|translate}}

                .row.row-departments(v-show="toggle")
                    div(v-for="(department, index) in departments" :class="[attr.rowClass, {'bg-separator': !small && index % 2}]")
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="department.checked" :name="'department' + index"
                                    @change="setDepartment()" :disabled="department.id === attr.lock")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{department.name}}

        div(v-if="required")
            div(v-for="error in Object.keys(required.$error)")
                label.warn(v-show="error === 'departments'") {{'departments.validate.REQUIRED'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCheckboxDepartment',
        components: {
            OptiDimmer
        },
        props: {
            ids: {
                type: Array,
                required: true
            },
            required: {
                type: Object,
                required: false
            },

            lock: {
                type: Boolean,
                required: false,
                default: false
            },
            all: {
                type: Boolean,
                required: false,
                default: false
            },
            small: {
                type: Boolean,
                required: false,
                default: false
            },
            previewClosed: {
                type: Boolean,
                default: false
            },
            startOpened: {
                type: Boolean,
                default: true
            },

            warehouse: {
                type: Boolean,
                required: false,
                default: false
            },
            recentlyClosed: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                attr: {
                    lock: this.lock ? this.$uac.user.activeDepartmentId === 149 ? 91 : this.$uac.user.activeDepartmentId : null,
                    all: this.all,
                    rowClass: this.small ? 'col-xs-6' : 'col-xs-12'
                },

                filter: {
                    jawro_loox: { // type - 1, logo - 4
                        checked: false,
                        count: 0
                    },
                    jawro_sunloox: { // type - 1, logo - 5
                        checked: false,
                        count: 0
                    },
                    franchisee_loox: { // type - 3, logo - 4
                        checked: false,
                        count: 0
                    },
                    franchisee_sunloox: { // type - 3, logo - 5
                        checked: false,
                        count: 0
                    },
                    cooperating: { // type - 4
                        checked: false,
                        count: 0
                    },
                    all: {
                        checked: false,
                        count: 0
                    }
                },

                departments: [],
                count: 0,

                toggle: this.startOpened,
                loading: true
            }
        },
        mounted() {
            this.setRequired([])

            API.get('checkbox/salon/' + (this.recentlyClosed ? 1 : 0) + '/' + (this.warehouse ? 1 : 0)).then(res => {
                this.departments = res.data

                this.setCount()

                this.watch_ids()

                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        methods: {
            setRequired(ids) {
                if(this.required) {
                    this.required.$setValidity('departments', !!ids.length)
                }
            },

            setCheckedCount() {
                this.count = 0

                this.departments.forEach(department => {
                    if(department.checked) {
                        ++this.count
                    }
                })
            },
            setFilters() {
                let jawro_loox = true,
                    jawro_sunloox = true,
                    franchisee_loox = true,
                    franchisee_sunloox = true,
                    cooperating = true,
                    all = true

                if(!this.filter.jawro_loox.count) {
                    jawro_loox = false
                }

                if(!this.filter.jawro_sunloox.count) {
                    jawro_sunloox = false
                }

                if(!this.filter.franchisee_loox.count) {
                    franchisee_loox = false
                }

                if(!this.filter.franchisee_sunloox.count) {
                    franchisee_sunloox = false
                }

                if(!this.filter.cooperating.count) {
                    cooperating = false
                }

                if(!this.filter.all.count) {
                    all = false
                }

                this.departments.forEach(department => {
                    if(!department.checked) {
                        if(department.type === 1 && department.logo === 4) {
                            jawro_loox = false
                        } else if(department.type === 1 && department.logo === 5) {
                            jawro_sunloox = false
                        } else if(department.type === 3 && department.logo === 4) {
                            franchisee_loox = false
                        } else if(department.type === 3 && department.logo === 5) {
                            franchisee_sunloox = false
                        } else if(department.type === 4) {
                            cooperating = false
                        }

                        all = false
                    }
                })

                this.filter.jawro_loox.checked = jawro_loox
                this.filter.jawro_sunloox.checked = jawro_sunloox
                this.filter.franchisee_loox.checked = franchisee_loox
                this.filter.franchisee_sunloox.checked = franchisee_sunloox
                this.filter.cooperating.checked = cooperating
                this.filter.all.checked = all
            },
            filterChanged(from_watch) {
                let ids = []

                let all_checked_except_all = this.filter.jawro_loox.checked && this.filter.jawro_sunloox.checked &&
                    this.filter.franchisee_loox.checked && this.filter.franchisee_sunloox.checked &&
                    this.filter.cooperating.checked

                if(this.filter.all.count === this.count) {
                    if (!all_checked_except_all) {
                        this.filter.all.checked = false
                    } else if (!this.filter.all.checked) {
                        this.filter.jawro_loox.checked = this.filter.jawro_sunloox.checked =
                            this.filter.franchisee_loox.checked = this.filter.franchisee_sunloox.checked =
                                this.filter.cooperating.checked = false
                    }
                }

                this.count = 0

                this.departments.forEach(department => {
                    if((this.filter.jawro_loox.checked && department.type === 1 && department.logo === 4) ||
                        (this.filter.jawro_sunloox.checked && department.type === 1 && department.logo === 5) ||
                        (this.filter.franchisee_loox.checked && department.type === 3 && department.logo === 4) ||
                        (this.filter.franchisee_sunloox.checked && department.type === 3 && department.logo === 5) ||
                        (this.filter.cooperating.checked && department.type === 4) || this.filter.all.checked ||
                        this.attr.lock === department.id) {
                        department.checked = true

                        ids.push(department.id)
                        ++this.count
                    } else {
                        department.checked = false
                    }
                })

                if(!from_watch) { // inaczej nieskończona pętla będzie
                    this.$emit('department-ids', ids)
                }

                this.setFilters()

                return ids
            },
            setCount() {
                this.departments.forEach(department => {
                    if(department.type === 1 && department.logo === 4) {
                        ++this.filter.jawro_loox.count
                    } else if(department.type === 1 && department.logo === 5) {
                        ++this.filter.jawro_sunloox.count
                    } else if(department.type === 3 && department.logo === 4) {
                        ++this.filter.franchisee_loox.count
                    } else if(department.type === 3 && department.logo === 5) {
                        ++this.filter.franchisee_sunloox.count
                    } else if(department.type === 4) {
                        ++this.filter.cooperating.count
                    }

                    ++this.filter.all.count
                })
            },
            watch_ids() {
                let watch = this.$watch('ids', new_ids => {
                    let ids = [...this.ids]

                    if(typeof new_ids !== 'undefined') {
                        this.departments.forEach(department => {
                            if(ids.includes(department.id)) {
                                department.checked = true
                            } else if(department.id === this.attr.lock) {
                                department.checked = true
                                ids.push(department.id)
                            }
                        })

                        this.setFilters()
                        this.setCheckedCount()
                        this.setRequired(ids)

                        if(this.attr.all) {
                            this.filter.all.checked = true
                            ids = this.filterChanged(true)
                        }

                        setTimeout(() => {
                            watch()

                            this.$emit('department-ids', ids)
                        }, 1)
                    }
                }, {
                    immediate: true
                })
            },
            setDepartment() {
                let ids = []

                this.departments.forEach(department => {
                    if(department.checked) {
                        ids.push(department.id)
                    }
                });

                this.setFilters()
                this.setCheckedCount()
                this.setRequired(ids)

                this.$emit('department-ids', ids)
            }
        }
    }
</script>
