import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueI18nFilter from 'vue-i18n-filter'
import axios from 'axios'
import languages from './i18n-langs'

Vue.use(VueI18n)
Vue.use(VueI18nFilter)

export const i18n = new VueI18n({
    locale: 'empty',
    fallbackLocale: 'pl_PL',
    messages: {}
})

const loadedLanguages = []

function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            //return import(/* webpackChunkName: "./lang-[request]" */ `@/../../public/lang/${lang}.json`).then(messages => {
            //    i18n.setLocaleMessage(lang, messages)
            //    loadedLanguages.push(lang)
            //    return setI18nLanguage(lang)
            //})
            i18n.setLocaleMessage(lang, languages[lang])
            loadedLanguages.push(lang)
            return Promise.resolve(setI18nLanguage(lang))
        }
        return Promise.resolve(setI18nLanguage(lang))
    }
    return Promise.resolve(lang)
}
