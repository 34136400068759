<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right(rowspan="2") #
                    th(style="border-right:1px solid #aaa" rowspan="2") {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th.text-center(style="border-right:1px solid #aaa") {{'prescription.RIGHT'|translate}}
                    th.text-center(style="border-right:1px solid #aaa") {{'prescription.LEFT'|translate}}
                    th.text-center(rowspan="2") BinVisus
                    th(v-show="edit" rowspan="2") {{'optometrist.history.table.head.TIME'|translate}}
                tr
                    th.text-center(style="border-right:1px solid #aaa") Visus
                    th.text-center Visus
            tbody
                tr(v-for="(log, index) in survey.data")
                    td.text-right {{index + 1}}
                    td(style="border-right:1px solid #aaa") {{log.description}}
                    td.text-center(style="border-right:1px solid #aaa"
                        :class="{'bold-black': log.changed.r && log.changed.r.indexOf('visus') > -1}") {{getByKey(log.parsed.r, 'Visus')}}
                    td.text-center(style="border-right:1px solid #aaa"
                        :class="{'bold-black': log.changed.l && log.changed.l.indexOf('visus') > -1}") {{getByKey(log.parsed.l, 'Visus')}}
                    td.text-center(:class="{'bold-black': log.changed.length && log.changed.indexOf('binVisus') > -1}") {{log.parsed.binVisus}}
                    td.text-right(style="border-left:1px solid #aaa" v-show="edit")
                        OptiOptometristsDetailsSurveyHistoryTime(:action="log.action"
                            :scope="log.time_scope" :diff="log.time_diff")
</template>

<script>
    import {OptometristsDetailsSurveyHistoryHelper}
        from '@/js/app/vue/components/Optometrists/Details/SurveyHistory/Helper/OptometristsDetailsSurveyHistoryHelper'

    import OptiOptometristsDetailsSurveyHistoryTime
        from '@/js/app/vue/components/Optometrists/Details/OptiOptometristsDetailsSurveyHistoryTime'

    export default {
        name: 'OptiOptometristsDetailsSurveyHistoryContactLensCorrection',
        components: {
            OptiOptometristsDetailsSurveyHistoryTime
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                edit: this.$uac.permission('optometrist.survey_history_time')
            }
        },
        mounted() {

        },
        methods: {
            getByKey(object, key) {
                return OptometristsDetailsSurveyHistoryHelper.getByKey(object, key)
            }
        }
    }
</script>