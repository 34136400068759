import store from './store'
import Fingerprint2 from 'fingerprintjs2'
import Broadcast from './Broadcast'
import {EventBus} from '../_bridge/EventBus'
import Vue from 'vue'
import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'

let fingerprint = {
    hash: false,
    components: false
}

new Fingerprint2().get((hash, components) => {
    fingerprint.hash       = hash
    fingerprint.components = components
})

class UserAccessControl {
    constructor() {
        this.check()

        window.color = this.updateColor
        window.toggleTheme = () => {
            let el = document.documentElement
            let att = el.getAttribute('data-theme')
            const arr = {
                'dark': 'light',
                'light': 'dark'
            }
            el.setAttribute('data-theme', arr[att])
        }
    }

    getPayload() {
        console.warn('DEPRECATED uac.getPayload()')
    }

    getUser() {
        return store.getters['user/getUser']
    }

    get user() {
        return this.getUser()
    }

    get fingerprint() {
        return fingerprint
    }

    permission(name) {
        //console.warn(`DEPRECATED uac.permission('${name}') USE hasPermission(${name})`)
        return this.hasPermission(name)
    }

    permissionExtra(name, department_id) {
        //console.warn(`DEPRECATED uac.permission('${name}') USE hasPermissionExtra(${name})`)
        return this.hasPermissionExtra(name, department_id)
    }

    hasPermission(name) {
        return store.getters['user/hasPermission'](name)
    }

    hasPermissionExtra(name, department_id) {
        return store.getters['user/hasPermissionExtra'](name, department_id)
    }

    groupPermission(name) {
        return store.getters['user/groupPermission'](name)
    }

    hasRole(name) {
        return store.getters['user/hasRole'](name)
    }

    hasAnyRole(names) {
        return store.getters['user/hasAnyRoles'](names)
    }

    isAuthenticated() {
        return store.getters['user/isAuthenticated']
    }

    login(username, password) {
        return store
            .dispatch('user/login', {login: username, password: password})
            .then(response => {
                this.reset()
                this.afterLogin()
                window.location.href = '/'
            })
    }

    logout() {
        return store
            .dispatch('user/logout')
            .then(response => {
                window.location.href = '/'
            })
    }

    relogin(userId) {
        return store
            .dispatch('user/loginAs', userId)
            .then(response => {
                this.reset()
                this.afterLogin()
                window.location.href = '/'
            })
    }

    check() {
        let promise = store.dispatch('user/check')

        promise
            .then(response => {
                if(response.data.user) {
                    this.unsubscribe()
                    this.subscribe()

                    if (this.isAuthenticated()) {
                        this.updateColorByInt(response.data.user.dark)
                        this.afterLogin()
                    }
                } else {
                    Vue.$router.push('page.login')
                }
            })
            .catch(err => {
                Vue.$router.push('page.login')
            })

        return promise
    }

    changeDepartment(departmentId) {
        return store
            .dispatch('user/changeDepartment', departmentId)
            .then(response => {
                this.reset()
                this.afterLogin()
                window.location.href = '/'
            })
    }

    subscribe() {
        if(!this.isAuthenticated()) {
            return
        }

        let Echo = Broadcast.Echo

        let departmentId = this.getUser().activeDepartmentId
        let userId       = this.getUser().id

        let departmentChannel = `department-${departmentId}`
        let userChannel       = `user-${userId}`

        let rootEmit = (name, data, type = 'broadcast') => {
            EventBus.$emit('ng:'+type, {name: name, data: data})
        }

        Echo.channel(departmentChannel)
            .listen('.status-notify', e => {
                if (e.data === null) {
                    store.dispatch('dashboard/fetch')
                } else {
                    store.commit('dashboard/setData', e.data)
                }
            })

        Echo.channel(userChannel)
            .listen('.alert-notify', e => {
                //rootEmit('refreshUnreadAlert', e)
                store.dispatch('dashboard/fetchAlerts')
            })
            .listen('.user-relogin', e => {
                setTimeout(() => {
                    if (e.department_id !== this.getUser().activeDepartmentId) {
                        rootEmit('notify', {
                            message: 'user.login.notify.REFRESHRELOG',
                            status: 'success',
                            timeout: 10000
                        }, 'emit')

                        window.location.href = '/'
                    }
                }, 100);
            })
            .listen('.user-notify', e => rootEmit('notify', {translate: false, message: e.message, status: e.status, timeout: 10000, custom:e.custom}, 'emit'))
            .listen('.changelog', e => rootEmit('changelog', {current: e.id, previous: 0}, 'emit'))
            .listen('.download-file', e => rootEmit('downloadable', {name: e.path, error: e.error, status: false}, 'emit'))
            .listen('.message-notify', e => {
                //rootEmit('refreshUnreadMessages', e)
                store.dispatch('dashboard/fetchMessages')
            })
    }

    unsubscribe() {
        let channels = Broadcast.Echo.connector.channels

        _.forEach(channels, channel => {
            channel.unsubscribe()
        })
    }

    updateColorByInt(colorId) {
        switch (colorId) {
            case 2:
                return this.updateColor('bieda')

            case 1:
                return this.updateColor('dark')

            case 0:
            default:
                return this.updateColor('light')
        }
    }

    updateColor(color) {
        let html = document.documentElement
        html.setAttribute('data-theme', color)
    }

    reset() {
        store.commit('offer/resetState')

        // TODO: zastanowic sie z tymi co ponizej
        localStorage.removeItem('ngStorage-receipts')
    }

    afterLogin() {
        store.dispatch('dashboard/fetchAll')

        //EventBus.$emitAndApply('ng:broadcast', {
        //    name: 'dashboard.fetch'
        //})

        setTimeout(() => {
            OptometristsDisplay.init()
        }, 1000)
    }

    truncateStorage(date) {
        const keyName = 'ngStorage-truncate'

        let storedDate = localStorage.getItem(keyName)
        if (storedDate === null) {
            localStorage.setItem(keyName, date)
        }

        if (localStorage.getItem(keyName) <= date) {
            localStorage.clear()
        }

        localStorage.setItem(keyName, (new Date()).getTime().toString())
    }
}

export const UAC = new UserAccessControl()
