<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {DownloadingHelper} from '@/js/app/vue/components/Downloading/DownloadingHelper'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDownloadingDetails',
        components: {
            OptiDimmer
        },
        mounted() {
            API.get('download/action/' + this.$state.params.id).then(res => {
                DownloadingHelper.openUri(res.data)

                this.$state.go(this.$state.params.from === 'dashboard' ? 'app.dashboard' : 'app.downloads.table')
            }).catch(() => {
                this.$notify.error('download.notify.error.LOAD')
            })
        }
    }
</script>