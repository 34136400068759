class AgeGroupClass {
    constructor() {

    }

    calculate(data) {
        let leftYear = data ? moment().year() - moment(data).year() : 0,
            old = 0

        if((0 <= leftYear) && (leftYear < 10)) {
            old = 1
        } else if((10 <= leftYear) && (leftYear < 20)) {
            old = 2
        } else if(((20 <= leftYear) && (leftYear < 30))) {
            old = 3
        } else if((30 <= leftYear) && (leftYear < 40)) {
            old = 4
        } else if((40 <= leftYear) && (leftYear < 50)) {
            old = 5
        } else if((50 <= leftYear) && (leftYear < 60)) {
            old = 6
        } else if(leftYear >= 60) {
            old = 7
        }

        return {
            age: leftYear,
            group: old
        }
    }
}

export const AgeGroup = new AgeGroupClass()