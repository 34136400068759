<template lang="pug">
    OptiButton(v-if="permission" size="lg" @click.native="open = true") {{'default.REPORT'|translate}}
        template(#icon)
            i.fa.fa-file-excel-o

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogReportRate(
                :type="type"

                @closed="open = false")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialogReportRate from '@/js/app/vue/components/Button/Dialogs/OptiDialogReportRate'

    export default {
        name: 'OptiButtonReportRate',
        components: {
            OptiButton,
            OptiDialogReportRate
        },
        props: {
            type: {
                type: String,
                required: true,
                validator: v => ['offers', 'temporary_tasks'].includes(v)
            }
        },
        data() {
            return {
                permission: this.$uac.permission('rate.report'),

                open: false
            }
        }
    }
</script>