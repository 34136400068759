<template lang="pug">
    .row(v-if="statuses")
        .col-xs-12
            .col-xs-12(style="border-top:1px solid")
                .flex-wrap
                    h4 {{'tasks.STATUSES'|translate}}

                    OptiButtonFilter(style="margin-right:10px"
                        :selected="statuses.all"
                        :title="'default.ALL'|translate"

                        @click="toggleAllStatuses")

                    OptiButtonFilter.mr3(v-for="(key, index) in orderOfKeys" :key="index"

                        :selected="statuses[key]"
                        :title="key === 'not_selected' ? 'Brak' : key.toUpperCase()"

                        @click="updateStatus(key)")

                    OptiSortFilter.pull-right(
                        :sorting="sorting"

                        @sorting="$emit('sorting')")
</template>

<script>
    import OptiButtonFilter from '@/js/app/vue/components/Button/OptiButtonFilter'
    import OptiSortFilter from '@/js/app/vue/components/Catalog/Filters/OptiSortFilter'

    export default {
        name: 'OptiProductStatusFilter',
        components: {
            OptiButtonFilter,
            OptiSortFilter
        },
        props: {
            statuses: {
                type: Object,
                required: false
            },
            sorting: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                orderOfKeys: ['mh', 'pi1', 'pi', 'ac', 'po', 'not_selected']
            }
        },
        methods: {
            toggleAllStatuses() {
                this.statuses.all = !this.statuses.all

                this.$emit('allStatuses')
            },
            updateStatus(key) {
                this.statuses[key] = !this.statuses[key]

                key === 'not_selected' ? this.$emit('empty') : this.$emit('selectStatus', this.statuses[key])
            }
        }
    }
</script>