<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButtonHistory(data="coupon" :id="coupon.id")
                .col-md-4.text-center
                    OptiButton(type="info" v-show="$uac.permission('sale.coupon.edit')"
                        @click.native="$state.go('app.coupon.edit', {id: coupon.id})"
                        ) {{'coupons.action.EDIT'|translate}}
                        template(#icon)
                            i.fa.fa-edit
                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg

        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        .pull-left
                            i.fa.fa-navicon.mr3
                            span {{'coupons.breadcrumb.COUPON'|translate}}
                        .pull-right \#{{coupon.id}}
                        .clearfix
                    .row
                        .col-sm-6 {{'coupons.table.PRODUCT'|translate}}
                        .col-sm-6
                            OptiProduct(:product="coupon.product") {{coupon.product.name}}
                    .row
                        .col-sm-6 {{'coupons.table.VALUE'|translate}}
                        .col-sm-6 {{coupon.value|currency}}
                    .row
                        .col-sm-6 {{'coupons.table.STATUS'|translate}}
                        .col-sm-6 {{coupon.status_name|translate}}
                    .row
                        .col-sm-6 {{'coupons.table.BEGIN'|translate}}
                        .col-sm-6 {{coupon.begin_date}}
                    .row
                        .col-sm-6 {{'coupons.table.EXPIRATION'|translate}}
                        .col-sm-6 {{coupon.expiration_date}}
                    .row(ng-hide="coupon.end_date === '0000-00-00'")
                        .col-sm-6 {{'coupons.table.END'|translate}}
                        .col-sm-6 {{coupon.end_date}}
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="17" :id="coupon.id" @shipment-exists="shipmentExists")
                    .row
                        .col-sm-6 {{'coupons.table.DEPARTMENT'|translate}}
                        .col-sm-6 {{coupon.department.name}}
                    .row
                        .col-sm-6 {{'coupons.table.USER'|translate}}
                        .col-sm-6 {{coupon.user.firstName}} {{coupon.user.lastName}}
                    .row(v-show="coupon.created_at")
                        .col-sm-6 {{'coupons.table.CREATEDAT'|translate}}
                        .col-sm-6 {{coupon.created_at}}
                    .row(v-show="coupon.sale_id && coupon.sale_id !== 0")
                        .col-sm-6 {{'coupons.table.SALE'|translate}}
                        .col-sm-6
                            a.link(:href="$state.href('app.sale.details', {id: coupon.sale_id})"
                                :title="'default.REFERENCETYPE'|translate") {{coupon.sale_id}}
                    .row(v-show="coupon.utilize_sale_id && coupon.utilize_sale_id !== 0")
                        .col-sm-6 {{'coupons.USED'|translate}}
                        .col-sm-6
                            span.mr3 {{'coupons.used.SALE'|translate}}
                            a.link(:href="$state.href('app.sale.details', {id: coupon.utilize_sale_id})"
                                :title="'default.REFERENCETYPE'|translate") {{coupon.utilize_sale_id}}
                    .row(v-show="coupon.payment.refDocType === 5")
                        .col-sm-6 {{'coupons.USED'|translate}}
                        .col-sm-6
                            span.mr3 {{'coupons.used.TASK'|translate}}
                            a.link(:href="$state.href('app.task.detail', {id: coupon.payment.refDocId})"
                                :title="'default.REFERENCETYPE'|translate") {{coupon.payment.refDocId}}
                    .row(v-show="coupon.payment.refDocType === 8")
                        .col-sm-6 {{'coupons.USED'|translate}}
                        .col-sm-6
                            span.mr3 {{'coupons.used.SALEOFFSTOCK'|translate}}
                            a.link(:href="$state.href('app.sale-offstock.details', {id: coupon.payment.refDocId})"
                                :title="'default.REFERENCETYPE'|translate") {{coupon.payment.refDocId}}
        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCouponsDetails',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiProduct,
            OptiShipmentNotice,
            OptiDimmer
        },
        data() {
            return {
                coupon: {},

                shipment_exists: false,

                loading: true
            }
        },
        mounted() {
            API.get('coupon/details/' + this.$state.params.id).then(res => {
                this.coupon = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('coupons.notify.error.LOAD')
            })
        },
        methods: {
            shipmentExists(shipment_exists) {
                this.shipment_exists = shipment_exists
            }
        }
    }
</script>