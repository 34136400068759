import {i18n} from '@/js/app/vue/i18n-setup'

class TaskMeasurementTitleClass {
    title(app, type, measurement, side) {
        if(app === 'VisuReal') {
            return this.visuReal(measurement, type, side)
        } else if(app === 'JawroVisu') {
            return this.jawroVisu(measurement, type, side)
        }

        return ''
    }

    visuReal(measurement, type, side) {
        return this.getTitle(type, measurement.rawLensDesign === 'Środek źrenicy' ? 1 : 0, measurement.rawLensDesign,
            measurement.lensType === 'Progresywne' || measurement.lensDesign === 'Środek źrenicy' ? 1 : 0, measurement.lensDesign,
            measurement['rawBt' + side.toUpperCase()], measurement['bt' + side.toUpperCase()], measurement.wpa)
    }

    jawroVisu(measurement, type, side) {
        return this.getTitle(type, measurement.raw_method, i18n.t(measurement.raw_method_name), measurement.method, i18n.t(measurement.method_name),
            measurement['raw_bt_' + side], measurement['bt_' + side], measurement.pa)
    }

    getTitle(type, rawMethodId, rawMethodName, methodId, methodName, rawBt, bt, pa) {
        if(typeof rawBt === 'undefined') {
            return ''
        } else if(type === 'raw' || rawMethodId === methodId) {
            return this.numberFormat(rawBt) + ' - ' + i18n.t('tasks.measurement.title.RAW') + ' ' + rawMethodName.toLowerCase() + '.'
        } else if(type === 'main' && rawMethodId !== methodId) {
            return this.numberFormat(bt) + ' - ' + i18n.t('tasks.measurement.title.CONVERTED') + ' ' +
                methodName.toLowerCase() + '. ' + this.stringCalculate(rawMethodId, methodId, rawBt, pa) + '.'
        }

        return ''
    }

    stringCalculate(rawMethodId, methodId, rawBt, pa) {
        let bt = rawMethodId === 1 ? rawBt - 0.5 * pa : rawBt + 0.5 * pa

        return this.numberFormat(bt) + ' = ' + this.numberFormat(rawBt) + ' ' + (rawMethodId === 1 ? '-' : '+') + ' 0,5 * ' +
            this.numberFormat(pa) + ' (PA)'
    }

    numberFormat(value) {
        return value.toFixed(1).toString().replace('.', ',')
    }
}

export const TaskMeasurementTitle = new TaskMeasurementTitleClass()