<template lang="pug">
    .opti-select-brand
        VueMultiSelect(
            id="category-warehouse"
            :options="categories"
            :value="category"
            :clearable="false"
            label="name"
            placeholder="Wybierz kategorię"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"
            @input="updateSelected"
            group-label="type"
            group-values="categories"
            :group-select="false")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option.name }}
                small.pull-right {{ props.option.short_name }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{ props.option.name }}
                    small.pull-right {{ props.option.short_name }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectCategoryWarehouse',
        components: {VueMultiSelect},
        props: {
            category: {
                type: Object,
                required: true,
                default: {}
            }
        },
        data() {
            return {
                categories: []
            }
        },
        mounted() {
            this.categories = [{
                type: 'Produkt',
                categories: []
            }, {
                type: 'Soczewki',
                categories: []
            }]

            API.get('search/categories/warehouse').then(res => {
                res.data.forEach(category => {
                    this.categories[category.type].categories.push(category)
                })
            }).catch(e => {
                console.log(e)
            })
        },
        methods: {
            updateSelected(category) {
                this.$emit('selected', category)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
