<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.mr3(:class="'fa-' + dialog.icon[type]")
            span {{'carts.header.' + type|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            OptiProductInfo(:product="product")

        template.text-right(#footer)
            OptiButton(:type="dialog.color[type]" @click.native="$emit('confirmed')") {{'carts.confirm.add.YES'|translate}}
                template(#icon)
                    i.fa(:class="'fa-' + dialog.icon[type]")

            OptiButton(@click.native="$emit('closed')") {{'carts.confirm.add.NO'|translate}}
                template(#icon)
                    i.fa.fa-remove

</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'

    export default {
        name: 'OptiCartDialogProductConfirm',
        components: {
            OptiProductInfo,
            OptiDialog,
            OptiButton
        },
        props: {
            type: {
                type: String,
                required: true
            },
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: {
                    color: {
                        sale: 'success',
                        order: 'primary',
                        sendback: 'warning'
                    },
                    icon: {
                        sale: 'shopping-cart',
                        order: 'truck',
                        sendback: 'archive'
                    }
                }
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 450px;
    }
</style>