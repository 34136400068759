<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-book.mr3
            span {{'default.HISTORY'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="closed()")

        template(#body)
            #search-panel.row
                .col-sm-6.col-sm-offset-6
                    .input-group
                        input.form-control(v-model="search" :disabled="loading"
                            :placeholder="'topbar.search.PLACEHOLDER'|translate"
                            @keyup.enter="load(true)")
                        span.input-group-addon(style="cursor:pointer" @click="load(true)" :disabled="loading")
                            i.fa.fa-search

            .table-responsive
                table.table.table-presenter.table-striped
                    thead
                        tr
                            th #
                            th {{'default.history.CHANGE'|translate}}
                            th {{'default.history.USER'|translate}}
                            th {{'default.history.DATE'|translate}}
                    tbody
                        tr(v-for="(log, index) in logs")
                            td.text-right(NOWRAP) {{count - ((paginator.page - 1) * 10 + index)}}
                            td(v-html="log.description")
                            td(NOWRAP) {{log.user.lastName}} {{log.user.firstName}}
                            td(NOWRAP) {{log.created_at|noYearSek}}
                        tr(v-show="!logs.length")
                            td.text-center.text-muted(colspan="4")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        template(#footer)
            OptiPaginator(
                :paginator="paginator"
                @changed-page="changedPage")

            OptiButton(type="success" @click.native="closed()") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator'

    export default {
        name: 'OptiDialogHistory',
        components: {
            OptiDimmer,
            OptiDialog,
            OptiButton: () => import('../../Button/OptiButton'),
            OptiPaginator
        },
        props: {
            endpoint: {
                type: String,
                required: false
            },

            data: {
                type: String,
                required: false
            },
            id: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                paginator: {
                    pages: 1,
                    page: 1
                },

                search: '',

                logs: [],
                count: 0,

                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load(is_search) {
                this.loading = true
                this.logs = []

                let url = 'history/logs'

                if(typeof this.endpoint !== 'undefined') {
                    url = this.endpoint
                }

                if(is_search) {
                    this.paginator.page = 1
                }

                API.post(url, {
                    type: this.data,
                    id: this.id,
                    page: this.paginator.page,
                    search: this.search
                }).then(res => {
                    this.paginator.pages = res.data.pages

                    this.logs = res.data.data
                    this.count = res.data.count
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('default.notify.error.HISTORY')
                })
            },
            changedPage(page) {
                this.paginator.page = page
                this.load()
            },

            closed() {
                this.$emit('closed')
            }
        }
    }
</script>

<style lang="less" scoped>
    #search-panel {
        margin: 10px 5px;
    }

    /deep/ .modal-container {
        max-width: 100%;

        .modal-body {
            padding: 0;
        }
    }
</style>