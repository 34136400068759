<template lang="pug">
    div
        .table-preview(v-if="noIlog || difference" v-html="htmlTable" @click="dialog = true")

        .empty(v-if="!noIlog && !difference" @click="dialog = true")
            i.fa.fa-exclamation-circle.mr3
            span {{'tasks.admin.compareilog.table.NODIFF'|translate}}

        MountingPortal(v-if="dialog" mountTo="#vue-modal" append)
            OptiTaskCompareDialog(
                :htmlTable="htmlTable"

                @closed="dialog = false")

</template>

<script>
    import OptiTaskCompareDialog
        from '@/js/app/vue/components/Tasks/Compare/Dialogs/OptiTaskCompareDialog'

    export default {
        name: 'OptiTaskComparePreview',
        components: {
            OptiTaskCompareDialog
        },
        props: {
            htmlTable: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                difference: this.htmlTable.includes('bg-warning'),
                noIlog: this.htmlTable.includes('no-ilog'),

                dialog: false
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .col-lg-6 {
        margin-bottom: 10px;
    }

    .empty {
        cursor: pointer;
        text-align: center;
        padding: 5px 0;
    }

    .table-preview {
        cursor: pointer;

        /deep/ table {
            th, td {
                padding: 2px !important;
                font-size: .9em;
                font-weight: 400;
            }

            .bg-warning td {
                cursor: pointer;
            }

            > tbody > tr:not([class="bg-warning"]) {
                display: none;
            }
        }
    }
</style>
