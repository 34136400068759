<template lang="pug">
    .row
        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        .pull-left
                            i.fa.fa-navicon.mr3
                            span {{'inventory.form.TITLE'|translate}}
                        .pull-right \#{{inventory.id}}
                        .clearfix
                    .row
                        .col-sm-6 {{'inventory.form.CREATEDAT'|translate}}
                        .col-sm-6 {{inventory.date}}
                    .row
                        .col-sm-6 {{'inventory.form.CREATEDBY'|translate}}
                        .col-sm-6
                            span.mr3 {{inventory.user.firstName}} {{inventory.user.lastName}}
                            span(v-show="inventory.department.id") ({{inventory.department.name}})
                    .row
                        .col-sm-6 {{'inventory.form.SUNGLASSES'|translate}}
                        .col-sm-6 {{inventory.quantity_sunglasses}}
                    .row
                        .col-sm-6 {{'inventory.form.SYSTEMSUNGLASSES'|translate}}
                        .col-sm-6
                            span.mr3 {{inventory.stock_sunglasses}}
                            span(v-show="inventory.sent_sunglasses") (-{{inventory.sent_sunglasses}})
                    .row
                        .col-sm-6 {{'inventory.form.FRAMES'|translate}}
                        .col-sm-6 {{inventory.quantity_glasses}}
                    .row
                        .col-sm-6 {{'inventory.form.SYSTEMFRAMES'|translate}}
                        .col-sm-6
                            span.mr3 {{inventory.stock_glasses}}
                            span(v-show="inventory.sent_frames") (-{{inventory.sent_frames}})
                    .row
                        .col-sm-6 {{'inventory.form.WHEN'|translate}}
                        .col-sm-6
                            OptiEnum(enum="InventoryAction" :id="inventory.action")
                    .row(v-show="inventory.comments")
                        .col-sm-6 {{'inventory.form.ATTENTIONS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(inventory.comments)")
                    .row
                        .col-sm-6 {{'inventory.table.CORRECT'|translate}}
                        .col-sm-6(:class="{'warn': !correct, 'text-success': correct}"
                            ) {{correct ? 'inventory.correct.OK' : 'inventory.correct.ERROR'|translate}}

        .col-xs-12(v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span.mr3 {{'inventory.history.CHANGE1'|translate}}
                    span.mr3(v-show="depositories.date") {{depositories.date}}
                    span.mr3(v-show="!depositories.date") {{'inventory.history.BEGIN'|translate}}
                    span {{'inventory.history.CHANGE2'|translate}} {{inventory.date}}

                .table-responsive
                    table.table.table-striped
                        thead
                            tr
                                th #
                                th {{'catalog.product.stockhistory.CHANGESTATE'|translate}}
                                th {{'catalog.product.stockhistory.PRODUCT'|translate}}
                                th {{'catalog.product.stockhistory.TYPECHANGE'|translate}}
                                th {{'catalog.product.stockhistory.IDDOC'|translate}}
                                th {{'catalog.product.stockhistory.DETAIL'|translate}}
                                th {{'catalog.product.stockhistory.DATECHANGE'|translate}}
                        tbody
                            tr(v-for="(log, key) in depositories.logs")
                                td.text-right {{depositories.logs.length - key}}
                                td.text-right {{log.change}}
                                td
                                    OptiProduct(:product="log.depository.product") {{log.depository.product.name}}
                                td {{log.referenceTypeName|translate}}
                                td.text-right
                                    OptiRefId(section="product-history" :type-id="log.referenceType" :id="log.referenceId")
                                td {{log.description}}
                                td {{log.created|noYearSek}}
                            tr(v-show="!depositories.logs.length")
                                td.text-center(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}
                        tfoot(v-show="depositories.logs.length > 1")
                            tr
                                td
                                td.text-right {{sum}}
                                td(colspan="5")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryQuickDetails',
        components: {
            OptiEnum,
            OptiProduct,
            OptiRefId,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                inventory: {},
                depositories: {},
                correct: false,
                sum: 0,

                loading: true
            }
        },

        mounted() {
            API.get('warehouse/inventory/quick/' + this.$state.params.id).then(res => {
                this.inventory = res.data.inventory
                this.depositories = res.data.depositories

                this.correct = (this.inventory.quantity_sunglasses === (this.inventory.stock_sunglasses - this.inventory.sent_sunglasses)) &&
                    (this.inventory.quantity_glasses === (this.inventory.stock_glasses - this.inventory.sent_frames))

                this.depositories.logs.forEach(log => {
                    this.sum += parseInt(log.change)
                })
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('inventory.notify.error.DETAILS')
            })
        },
        methods: {

        }
    }
</script>
