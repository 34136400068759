class TaskAssemblyClass {
    constructor() {
        this.lenses = {
            left: [27, 28],
            right: [29, 30],
            both: [40, 41]
        }

        this.frame = [26, 31]
    }

    blockFrame(assemblyId) {
        return this.lenses.left.concat(this.lenses.right, this.lenses.both).includes(assemblyId)
    }

    blockPack(assemblyId) {
        return this.frame.concat(this.lenses.right, this.lenses.left, this.lenses.both).includes(assemblyId)
    }

    blockBothLens(assemblyId) {
        return this.frame.includes(assemblyId)
    }
}

export const TaskAssembly = new TaskAssemblyClass()