<template lang="pug">
    .row
        OptiDimmer(:active="active")
        .col-sm-8
            form.panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'prices.DETAILS'|translate}} \#{{promotion.id}}

                table.table.table-striped.table-responsive.middle
                    tbody
                        tr
                            th.text-left {{'prices.pricetochange.CHANGEID'|translate}}
                            td {{promotion.id}}
                            td
                        tr
                            th.text-left {{'prices.pricetochange.START_DATE'|translate}}
                            td
                                OptiDate#start_date(
                                    :value="promotion.start_date"
                                    @update-date="d => promotion.start_date = d"
                                    :disabled="promotion.status === 4 || promotion.status === 5"
                                )
                            td
                        tr.form-group
                            th.text-left {{'prices.pricetochange.STATUS'|translate}}
                            th.warn(v-show="promotion.status === 1") {{'prices.status.NOTACCEPTED'|translate}}
                            th(v-show="promotion.status === 2" style="color:green") {{'prices.status.ACCEPTED'|translate}}
                            th(v-show="promotion.status === 3" style="color:green") {{'prices.status.ACTIVE'|translate}}
                            th(v-show="promotion.status === 4" style="color:green") {{'prices.status.COMPLETED'|translate}}
                            th.warn(v-show="promotion.status === 5") {{'prices.status.CANCELLED'|translate}}
                            td
                                OptiButton(
                                    v-if="promotion.status === 1"
                                    type="success"
                                    text="prices.accept.confirm"
                                    @confirmed="acceptChange"
                                ) {{'prices.accept.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
                                OptiButton(
                                    v-if="promotion.status === 2"
                                    type="danger"
                                    text="prices.cancel.confirm"
                                    @confirmed="cancelChange"
                                ) {{'prices.cancel.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-remove
                        tr
                            th.text-left {{'prices.pricetochange.TYPE'|translate}}
                            td(v-show="promotion.type === 4") Zmiana cen + zmiana statusu produktu na PO
                            td(v-show="promotion.type === 3") Promocja specjalna
                            td(v-show="promotion.type === 2") Zmiana cen
                            td(v-show="promotion.type === 1") Promocja
                            td

                        tr.text-center.warn(v-show="promotion.manually")
                            td(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'prices.pricetochange.MANUALLY'|translate}}

                .panel-footer
                    OptiButton(@click.native="back") {{'prices.pricetochange.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-angle-left

                    OptiButtonSave.pull-right(v-show="[1, 2, 3].includes(promotion.status)"

                        @click.native="update") {{'prices.pricetochange.SAVE'|translate}}

        .col-xs-12
            AngularComponent(:component="changeList")

        .col-xs-12(v-if="promotion.status === 1")
            .form-group
                .col-sm-3.text-left
                    OptiButton(
                        @click.native="goToExcel"
                    ) {{'prices.import.HEADING'|translate}}
                        template(#icon)
                            i.fa.fa-file-excel-o
                .col-sm-9.text-right
                    OptiButton(
                        type="primary"
                        @click.native="addNewPriceChange"
                    ) {{'prices.prices.NEW_PRODUCT'|translate}}
                        template(#icon)
                            i.fa.fa-plus
                    OptiButton(
                        type="danger"
                        style="margin-left:3px"
                        text="prices.deleteAll.confirm"
                        @confirmed="deleteAll"
                    ) {{'prices.deleteAll.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-remove

        .col-xs-12.text-left(v-if="(promotion.status === 2 && promotion.tickets_printed === 0) || (promotion.status === 3 && promotion.tickets_printed === 0) || (promotion.status === 4 && promotion.tickets_printed === 0)")
            .form-group
                OptiButton(
                    @click.native="createTickets"
                ) {{'prices.prices.PREPARE_TICKETS'|translate}}
                    template(#icon)
                        i.fa.fa-barcode

        .col-xs-12.text-left(v-if="promotion.tickets_printed === 1")
            .form-group
                OptiButton(
                    @click.native="ticketsList"
                ) {{'ticket.table.LIST'|translate}}
                    template(#icon)
                        i.fa.fa-barcode
                OptiButton(
                    v-if="promotion.old_tickets_printed === 0"
                    @click.native="createOldTickets"
                    style="margin-left:3px"
                ) {{'prices.prices.PREPARE_OLD_TICKETS'|translate}}
                    template(#icon)
                        i.fa.fa-barcode
</template>

<script>
import AngularComponent from "@/js/app/_bridge/components/AngularComponent";
import {API} from '@/js/app/vue/api'
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
import OptiDate from "@/js/app/vue/components/OptiDate";
import OptiValidate from "@/js/app/vue/components/OptiValidate";
import OptiButton from "@/js/app/vue/components/Button/OptiButton";
import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

export default {
    name: "OptiProductPriceChangeEdit",
    components: {OptiButton, OptiButtonSave, OptiValidate, OptiDate, OptiDimmer, AngularComponent},
    data() {
        return {
            promoId: this.$state.params.id,
            promotion: {
                start_date: '',
                type: '',
                status: '',
                tickets_printed: ''
            },
            active: false
        }
    },
    mounted() {
        this.active = true
        API.get('prices/edit/' + this.$state.params.id).then(res => {
            this.promotion = res.data
        })
        if (!this.$state.params.page) {
            this.$state.go('app.prices.edit.table', {
                id: this.$state.params.id,
                page: '1'
            })
        }
        this.active = false
    },
    computed: {
        changeList() {
            return {
                template: `<base-table end-point="api/prices/edit/${this.promoId}/changesList" ref="app.prices.edit.table" header="prices.pricetochange.LIST"></base-table>`,
                // template: `<div data-ui-view="" data-autoscroll="false"></div>`,
                $ctrl: {}
            }
        }
    },
    methods: {
        acceptChange() {
            this.active = true
            API.post('prices/edit/' + this.$state.params.id + '/acceptChange', {
                status: this.promotion.status
            }).then( res => {
                this.promotion = res.data
                this.active = false
            })
        },
        cancelChange() {
            this.active = true
            API.post('prices/edit/' + this.$state.params.id + '/cancelChange', {
                status: this.promotion.status
            }).then(res => {
                this.promotion = res.data
                this.active = false
            })
        },
        back() {
            this.$state.go('app.prices.list')
        },
        update() {
            this.active = true
            API.post('prices/edit/' + this.$state.params.id, {
                start_date: this.promotion.start_date,
                status: this.promotion.status,
            }).then( res => {
                this.promotion = res.data
                this.active = false
            })
        },
        goToExcel() {
            API.post('prices/edit/' + this.$state.params.id + '/importView').then(
                this.$state.go('app.prices.import', {
                    id: this.$state.params.id
                })
            )
        },
        deleteAll() {
            API.get('prices/edit/' + this.$state.params.id + '/deleteAll').then( () => {
                this.active = true
                window.location.reload(true)
            })
        },
        addNewPriceChange() {
            API.get('prices/edit/' + this.$state.params.id + '/addNewPriceChange').then( res => {
                    this.$state.go('app.prices.change', {
                        id: res.data
                    })
                })
        },
        createTickets() {
            this.active = true
            API.get('prices/edit/' + this.$state.params.id + '/createTickets').then( () => {
                this.$state.go('app.warehouse.tickets.table')
            })
        },
        ticketsList() {
            this.$state.go('app.warehouse.tickets.table')
        },
        createOldTickets() {
            this.active = true
            API.post('prices/edit/' + this.$state.params.id + '/createOldTickets').then( () => {
                this.$state.go('app.warehouse.tickets.table')
            })
        }
    }
}
</script>

<style scoped>

</style>