<template lang="pug">
    .opti-select-employee-group-by-department
        VueMultiSelect(
            :options="users"
            :value="user"
            :clearable="false"
            label="name"
            :placeholder="'search.user.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            group-label="name"
            group-values="users"
            :group-select="false"
            :searchable="true"
            ref="select"
            @input="updateSelected")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
                small.pull-right {{props.option.department_login}}
                div(style="font-style:italic" v-if="props.option.exchange")
                    span {{'search.user.exchange.LAST'|translate}} \#{{props.option.exchange.id}}
                    span , {{'search.user.exchange.WEEK'|translate}} {{props.option.exchange.date}}
                    span , {{'search.user.exchange.TO'|translate}} {{props.option.exchange.destination}}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{props.option.$groupLabel}}
                span(v-if="!props.option.$isLabel")
                    span {{props.option.name}}
                    small.pull-right {{props.option.department_login}}
                div(style="font-style:italic" v-if="props.option.exchange")
                    span {{'search.user.exchange.LAST'|translate}} \#{{props.option.exchange.id}}
                    span , {{'search.user.exchange.WEEK'|translate}} {{props.option.exchange.date}}
                    span , {{'search.user.exchange.TO'|translate}} {{props.option.exchange.destination}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectEmployeeGroupByDepartment',
        components: {VueMultiSelect},
        props: {
            user: {
                type: Object,
                required: true,
                default: {}
            },
            mode: {
                type: String,
                required: false,
                default: 'normal'
            },
            index: {
                type: Number,
                required: false
            },
            disabledGroupDepartment: {
                type: Number,
                required: false,
                default: 0
            },
            disabledUsers: {
                type: Array,
                required: false,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                users: []
            }
        },
        watch: {
            disabledGroupDepartment(disabledGroupDepartment) {
                if(disabledGroupDepartment) {
                    this.users.forEach(department => {
                        department.users.forEach(user => {
                            user.$isDisabled = user.group_id === disabledGroupDepartment
                        })
                    })
                }
            }
        },
        mounted() {
            API.get('search/user/group-department/' + this.mode, API.id(Math.random())).then(res => {
                this.users = res.data

                this.setDisabledUsers()
            }).catch(() => {
                this.$notify.error('search.user.notify.error.LOAD')
            })
        },
        methods: {
            setDisabledUsers() {
                if(this.disabledUsers.length) {
                    this.users.forEach(department => {
                        department.users.forEach(user => {
                            user.$isDisabled = this.disabledUsers.includes(user.id)
                        })
                    })
                }
            },
            updateSelected(user) {
                if(typeof this.index !== 'undefined') {
                    user.index = this.index
                }

                this.$emit('selected', user)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
