<template lang="pug">
    button.btn.btn.btn-labeled.btn-success(type="submit" :disabled="disabled")
        span.btn-label
            span.fa.fa-save
        span {{'default.SAVE'|translate}}
</template>

<script>
    export default {
        name: 'OptiButtonSave',
        props: {
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>
