<template lang="pug">
    div
        .row(v-if="!loading.main")
            .col-xs-12
                .table-responsive(v-show="Object.keys(tasks).length")
                    table#table-patient-task.table
                        thead
                            tr
                                th.text-center Id
                                th
                                    div {{'tasks.PRICE'|translate}}
                                    div {{'tasks.ACCOUNT'|translate}}
                                th {{'tasks.DATE'|translate}}
                                th
                                    div {{'tasks.WHO'|translate}}
                                    div {{'tasks.PRESCRIPTION'|translate}}
                                th {{'tasks.FRAME'|translate}}
                                th {{'tasks.TYPE'|translate}}
                        tbody
                            template(v-for="(status, status_id) in tasks")
                                tr(style="cursor:pointer" @click="goToggle(status)")
                                    td(colspan="6")
                                        i.fa.fa-gear.mr3
                                        span {{status.name|translate}} ({{status.data.length}})
                                template(v-for="(task, index) in status.data")
                                    tr.border-top(:class="{'bg-separator': index % 2}" v-show="status.toggle")
                                        td.center(style="font-size:1.1em" rowspan="4")
                                            a.link(:href="$state.href('app.task.detail', {id: task.id})") {{task.id}}
                                        td {{task.price|currency}}
                                        td
                                            span.bold.mr3(v-show="task.started" :title="'tasks.date.STARTED'|translate") {{'tasks.date.short.S'|translate}}:
                                            span {{task.started|stringTimeWithoutYear}}
                                        td.text-center(style="vertical-align:middle" rowspan="2") {{task.accepted}}
                                        td.text-center.middle(rowspan="4")
                                            OptiProduct(v-if="task.product.id" :product="task.product")
                                                img(style="width:150px" :src="'thumb/product/ids/' + task.product.id + '.jpg'")
                                        td.text-center(style="vertical-align:middle" rowspan="2") {{task.type|translate}}

                                    tr(:class="{'bg-separator': index % 2}" v-show="status.toggle")
                                        td {{task.account|currency}}
                                        td
                                            span.bold.mr3(v-show="task.expected" :title="'tasks.date.EXPECTED'|translate") {{'tasks.date.short.E'|translate}}:
                                            span {{task.expected|stringTimeWithoutYear}}

                                    tr(:class="{'bg-separator': index % 2}" v-show="status.toggle")
                                        td
                                            OptiTaskIcon(v-for="(bool, attribute) in task.attributes" :key="attribute"
                                                :name="attribute" :id="task.from_service_contract_id")
                                            OptiTaskIcon(v-show="task.satisfaction" name="satisfaction")
                                            OptiTaskIcon(v-show="task.has_service_contract" name="sc")
                                            OptiTaskIcon(v-show="task.pack_id" name="pakiet")
                                            OptiTaskIcon(style="font-size:22px" v-show="task.notes.length" name="notes")
                                            OptiTaskIcon(style="font-size:22px" v-show="task.product.type === 0" name="sun")
                                            i.fa.fa-crop.warn(style="font-size:22px" v-show="task.dbl === 0"
                                                :title="'tasks.attributes.NOSHAPE'|translate")
                                        td
                                            span.bold.mr3(v-show="task.finished" :title="'tasks.date.FINISHED'|translate") {{'tasks.date.short.F'|translate}}:
                                            span {{task.finished|stringTimeWithoutYear}}
                                        td.text-center(style="vertical-align:middle" rowspan="2")
                                            OptiButtonPrescription(:id="task.prescription_id")
                                        td.center(rowspan="2")
                                            a(:href="$state.href('app.task.detail', {id: task.id})")
                                                i.fa.fa-info-circle.fa-2x.fa-fw

                                    tr.border-bottom(:class="{'bg-separator': index % 2}" v-show="status.toggle")
                                        td.text-center(colspan="2")
                                            label(:class="{'invisible': status_id !== '10'}")
                                                .checkbox.c-checkbox.checkbox-primary.inline(style="margin-bottom:5px")
                                                    label
                                                        input(type="checkbox" name="previous_lens"
                                                            v-model="task.previous_lens" @change="checkPreviousLens(index)")
                                                        span.fa.fa-check
                                                span {{'previouslens.CURRENT'|translate}}

                OptiDimmer(:active="loading.save")

            .col-xs-12(v-show="!Object.keys(tasks).length")
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.notify.patient.NOTASK'|translate}}

        OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Vue from 'vue'

    import OptiTaskIcon from '@/js/app/vue/components/Tasks/OptiTaskIcon'
    import OptiButtonPrescription from '@/js/app/vue/components/Button/OptiButtonPrescription'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiPatientsDetailsTasks',
        components: {
            OptiTaskIcon,
            OptiButtonPrescription,
            OptiProduct,
            OptiDimmer
        },
        data() {
            return {
                tasks: {},

                previous_lenses_updated_at: null,

                loading: {
                    main: true,
                    save: false
                }
            }
        },
        mounted() {
            API.get('task/patientId/' + this.$state.params.id).then(res => {
                this.getData(res)
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('tasks.notify.patient.ERRORTASK')
            })
        },
        methods: {
            getData(res) {
                this.tasks = res.data.tasks
                this.previous_lenses_updated_at = res.data.previous_lenses_updated_at

                this.$emit('update-count', 'tasks', res.data.count)
            },
            goToggle(status) {
                status.toggle = !status.toggle

                this.loading.main = true
                Vue.nextTick(() => {
                    this.loading.main = false
                })
            },
            checkPreviousLens(index) {
                this.loading.save = true

                let checked = this.tasks[10]['data'][index]

                if(checked.previous_lens) {
                    let to_uncheck_type = checked.type_id === 1 ? [1] : [2, 3, 4]

                    this.tasks[10]['data'].forEach((task, key) => {
                        if(index !== key && to_uncheck_type.includes(task.type_id)) {
                            task.previous_lens = false
                        }
                    })
                }

                let _results = {
                    patient_id: parseInt(this.$state.params.id),
                    previous_lenses_updated_at: this.previous_lenses_updated_at,
                    near: 0,
                    far: 0
                }

                this.tasks[10]['data'].forEach(task => {
                    if(task.previous_lens) {
                        _results[task.type_id === 1 ? 'near' : 'far'] = task.id
                    }
                })

                API.post('task/previous-lenses/save', _results).then(res => {
                    let open = []

                    _.forEach(this.tasks, (status, status_id) => {
                        if(status.toggle) {
                            open.push(status_id)
                        }
                    })

                    this.getData(res)

                    open.forEach(status_id => {
                        this.goToggle(this.tasks[status_id])
                    })

                    this.$emit('reload-previous-lenses', res.data.patient)
                }).finally(() => {
                    this.loading.save = false
                }).catch(error => {
                    if(error.response.status === 460) {
                        this.$notify.warning(error.response.data.message, false)

                        this.$emit('reload-all')
                    } else {
                        this.$notify.error('tasks.notify.patient.previouslenses.NOSAVE')
                    }
                })
            }
        }
    }
</script>
