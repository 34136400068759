<template lang="pug">
    .panel.panel-default
        .panel-body(style="position: relative")
            OptiDimmer(:active="$asyncComputed.chartData.updating")
            div.text-center.buttons(v-if="$asyncComputed.chartData.success")
                .btn-group
                    label.btn(
                        v-for="(translated, key) in names"
                        :for="'key_' + key + random"
                        :class="colorRadios(key)"
                    ) {{ translated }}
                        input(
                            type='radio'
                            style='display: none'
                            :id="'key_' + key + random"
                            @input="updateKey($event.target.value)"
                            :value="key"
                            :name="'key_radios' + random"
                        )
                OptiSwitchToggleButton(style="margin-top:10px" :checked="includeAccessories" @input="toggleAccessories")
                    template(#right) Akcesoria
            div(style="position: relative")
                TreeMapChart(
                    :chart-data="chartData"
                    :options="options"
                    :styles="styles"
                    ref="chart"
                    v-if="$asyncComputed.chartData.success"
                )
                OptiChartTooltip(
                    :model="tooltipModel"
                    :chart-data="chartData"
                    :chart="$refs.chart"
                    v-if="$asyncComputed.chartData.success"
                )
                    template(#header="{header}")
                        div {{header.tooltip.title[0]}}
                    template(#default="{body}")
                        table.chart-tooltip__table
                            tr.no-brake(v-for="row in body.tooltip.body[0].lines" :class="colorRadios(row.k, true)")
                                td(v-html="row.key")
                                td.text-right.monospace(v-html="row.val")
</template>

<script>
    import {API} from '../../api'
    import TreeMapChart from './Base/TreeMapChart'
    import _ from 'lodash'
    import OptiButton from '../Button/OptiButton'
    import OptiDimmer from '../Blocks/OptiDimmer'
    import OptiChartTooltip from './Base/OptiChartTooltip'
    import OptiSwitchToggleButton from '../Button/OptiSwitchToggleButton'
    import {randomString} from '../../helpers/Utils'

    export default {
        name: 'OptiChartSnapshotTreemap',
        components: {OptiSwitchToggleButton, OptiChartTooltip, OptiDimmer, OptiButton, TreeMapChart},
        props: {
            weekFrom: {
                type: undefined,
                default: false
            },
            weekTo: {
                type: undefined,
                default: false
            }
        },
        mounted() {
        },
        data() {
            return {
                tooltipModel: null,
                includeAccessories: false,
                currentKey: false,
                random: randomString(),
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {
                            title(item, data) {
                                let first = item[0]
                                return data.datasets[first.datasetIndex].data[first.index].g
                            },
                            label: (item, data) => {
                                let dataset  = data.datasets[item.datasetIndex]
                                let dataItem = dataset.data[item.index]
                                let obj      = dataItem._data
                                let lines    = []

                                _.forEach(this.names, (val, key) => {
                                    let count = 0
                                    let v = obj.children.reduce((prev, curr) => {
                                        if (curr[key]) {
                                            count++
                                        }

                                        return prev + curr[key]
                                    }, 0)

                                    if (key === 'turnout_per_place' || key === 'stock_rotation') {
                                        v = v / count
                                    }

                                    v = this.humanValue(v, key)

                                    lines.push({
                                        k: key,
                                        key: this.humanName(key),
                                        val: v
                                    })
                                })

                                return lines
                            }
                        },
                        enabled: false,
                        custom: tooltip => {
                            //console.log(tooltip)
                            this.tooltipModel = tooltip
                        }
                    }
                },
                styles: {
                    position: 'relative',
                    //height: '300px'
                },
            }
        },
        computed: {
            names() {
                let arr = {
                    layout_to: 'Facing (koniec)',
                    stock: 'Magazyn (koniec)',
                    sold_quantity: 'Sprzedano',
                    wholesale_value: 'Magazyn hurt',
                    purchase_value: 'Magazyn ZHC',
                    selling: 'Detal po rabacie',
                    turnout_department: 'Marża Salon',
                    turnout_central: 'Marża ZHC',
                    turnout_per_place: 'Mar/m',
                    stock_rotation: 'Ost. zapas'
                }

                if (!this.$uac.isAuthenticated() || !this.$uac.user.activeRole.admin) {
                    delete arr.purchase_value
                    delete arr.turnout_central
                }

                if (!this.$uac.user.activeRole.admin && this.$uac.user.activeRole.id !== 16) {
                    delete arr.turnout_per_place
                    delete arr.stock_rotation
                }

                return arr
            }
        },
        methods: {
            updateKey(name) {
                this.chartData.datasets[0].key = name
                this.$refs.chart.update()
                this.setKey(name)
            },

            humanName(name) {
                return (this.names[name] || name).replace(' ', '&nbsp;')
            },

            humanValue(value, key) {
                return this.$filters.currency(value).replace(',00', '').trim().replace(' ', '&nbsp;')
            },

            colorRadios(key, defaultEmpty) {
                if (!this.chartData || !this.chartData.datasets || !this.chartData.datasets[0] || !this.chartData.datasets[0].key) return defaultEmpty ? [] : ['btn-default', 'disabled']
                if (key === this.chartData.datasets[0].key) return ['btn-purple', 'active']
                return defaultEmpty ? [] : ['btn-default']
            },

            toggleAccessories(checked) {
                this.includeAccessories = checked
            },

            setKey(key) {
                if (key) {
                    this.currentKey = key
                }
            },

            getKey() {
                return this.currentKey
            }
        },
        asyncComputed: {
            chartData: {
                async get() {
                    if (!this.weekFrom || !this.weekTo) {
                        return {}
                    }

                    let {data} = await API.post('reports/snapshots/chart', {
                        weekFrom: this.weekFrom,
                        weekTo: this.weekTo,
                        includeAccessories: this.includeAccessories
                    }, API.id(this.random))

                    let dataset             = data.datasets[0]
                    dataset.fontColor       = 'black'
                    dataset.backgroundColor = ctx => {
                        let item = ctx.dataset.data[ctx.dataIndex]

                        if (item.g === 'Sun') return '#0F2080' // '#85C0F9'
                        if (item.g === 'Frame') return '#F5793A' // '#0F2080'
                        if (item.g === 'Other') return '#A95AA1'

                        return 'rgba(200, 200, 200, 0.5)'
                    }
                    dataset.borderWidth     = 0
                    dataset.spacing         = 1

                    let key = this.getKey()
                    if (key) {
                        dataset.key = key
                    } else {
                        this.setKey(dataset.key)
                    }

                    return data
                },
                watch: ['weekFrom', 'weekTo', 'includeAccessories']
            }
        }
    }
</script>

<style scoped lang="less">
    .buttons {
        margin-bottom: 10px;
    }

    .chart-tooltip {
        background-color: @body-bg;
        border-radius: .5rem;
        xfont-size: .8em;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .chart-tooltip /deep/ .chart-tooltip__header {
        font-weight: bold;
        text-align: center;
    }

    .chart-tooltip /deep/ .chart-tooltip__table {
        border: 0;

        & td {
            border: 1px solid @table-border-color;
            padding: @table-cell-padding / 2;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }

    .no-brake {
        word-break: keep-all;
        text-wrap: none;
    }
</style>
