<template lang="pug">
    .opti-select-snapshot
        VueMultiSelect(
            :options="dates"
            :value="date"
            :clearable="false"
            label="label"
            @input="updateSelected"
            :placeholder="'user.exchange.add.week.PLACEHOLDER'|translate"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="start"
        )
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.label}}
                .pull-right.text-muted {{props.option.start}} - {{props.option.end}}
            template(slot="option" slot-scope="props")
                span {{props.option.label}}
                small.pull-right {{props.option.start}} - {{props.option.end}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import HolidayHelper from '@/js/app/vue/helpers/HolidayHelper'

    export default {
        name: 'OptiSelectWeek',
        components: {VueMultiSelect},
        props: {
            date: {
                type: Object,
                required: true,
                default: {}
            },
            autoSelect: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                dates: []
            }
        },
        mounted() {
            for(let i = 1; i <= 20; ++i) {
                let week = moment().add(i, 'w')

                this.dates.push({
                    label: week.format('W/GGGG'),
                    start: week.startOf('w').format('YYYY-MM-DD'),
                    end: week.endOf('w').format('YYYY-MM-DD'),
                    days: this.fillDays(week.startOf('w'))
                })
            }

            if(this.autoSelect) {
                this.updateSelected(this.dates[0])
            }
        },
        methods: {
            fillDays(start) {
                let days = [],
                    date = start.clone()

                for (let i = 0; i < 7; i++) {
                    let day = date.format('YYYY-MM-DD')

                    days.push({
                        date: date,
                        holiday: HolidayHelper.getHolidaysByDate(parseInt(date.format('GGGG')), true)[day] || false,
                        label: {
                            short: date.format('ddd'),
                            long: date.format('ddd, Do MMM'),
                            day: day
                        }
                    })

                    date.add(1, 'day')
                }

                return days
            },
            updateSelected(date) {
                this.$emit('selected', date)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: 0.5;
    }
</style>
