<template lang="pug">
    div
        .warnings.warn(v-show="question.difference.in_db_not_in_question.length")
            i.fa.fa-exclamation-circle.mr3
            span {{'settings.offer.questions.difference.IN_DB_NOT_IN_QUESTION'|translate}}
            span
                .btn-offer-attribute(v-for="in_db_not_in_question in question.difference.in_db_not_in_question"
                    @click="$emit('open-dialog', {attribute_ids: question.ids, value: in_db_not_in_question})")
                    span.text-animated-alert(:title="in_db_not_in_question ? in_db_not_in_question : $filters.translate('settings.offer.questions.difference.NO_TRANSLATE')"
                        ) "{{in_db_not_in_question ? in_db_not_in_question : $filters.translate('settings.offer.questions.difference.NO_TRANSLATE')}}"
            span .

        .warnings.warn(v-show="question.difference.in_question_not_in_db.length")
            i.fa.fa-exclamation-circle.mr3
            span.mr3 {{'settings.offer.questions.difference.IN_QUESTION_NOT_IN_DB'|translate}}
            span
                span.offer-join-comma(v-for="in_question_not_in_db in question.difference.in_question_not_in_db")
                    span.text-animated-alert(:title="in_question_not_in_db") "{{in_question_not_in_db}}"
            span .
</template>

<script>
    export default {
        name: 'OptiSettingsOfferDifferenceQuestion',
        props: {
            question: {
                type: Object,
                required: true
            }
        }
    }
</script>