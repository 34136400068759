<template lang="pug">
    #task-payment.same-color
        .row
            #formPayment.form-horizontal.form-validate(name="formPayment")
                .row.without-margin
                    .col-xs-12
                        .form-group
                            label.col-sm-3.control-label(for="frame") {{'tasks.FRAME'|translate}}
                            label.col-sm-5.control-label(for="frame")
                                small
                                    OptiProduct(v-if="task.product.id" :product="task.product") {{task.frame.partName}}
                                    span(v-else) {{task.frame.partName}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        #frame.form-control.text-right(disabled) {{task.frame.price|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12
                        .form-group
                            label.col-sm-3.control-label(for="lenses") {{task.packId ? 'tasks.pack.PACKAGE' : 'tasks.LENSES'|translate}}
                            label.col-sm-5.control-label(for="lenses")
                                small
                                    a.link(style="white-space:normal" :title="'default.title.LENSES'|translate"
                                        v-if="task.hoya_lens_category_id || task.rLensPriceGroup.categoryId"
                                        :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.rLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                        target="_blank") {{task.rightLens.partName}}
                                    span(v-else) {{task.rightLens.partName}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        #lenses.form-control.text-right(disabled) {{task.packId ? (task.rightLens.price + task.leftLens.price) : task.rightLens.price|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12(v-show="!task.packId")
                        .form-group
                            label.col-sm-3.control-label(for="lensesLeft")
                            label.col-sm-5.control-label(for="lensesLeft")
                                small
                                    a.link(style="white-space:normal" :title="'default.title.LENSES'|translate"
                                        v-if="task.hoya_lens_category_id || task.lLensPriceGroup.categoryId"
                                        :href="$state.href('app.catalog.category', {id: task.hoya_lens_category_id ? task.hoya_lens_category_id : task.lLensPriceGroup.categoryId, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                                        target="_blank") {{task.leftLens.partName}}
                                    span(v-else) {{task.leftLens.partName}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        #lensesLeft.form-control.text-right(disabled) {{task.leftLens.price|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12(v-show="task.lensColor.id")
                        .form-group
                            label.col-sm-3.control-label(for="coloration")
                            label.col-sm-5.control-label
                                small.mr3 {{task.lensColor.translatedName}}
                                small.mr3 - {{saturationTranslate[task.lensColor.category]}}
                                small - {{task.coveringSaturation}}%
                                small.text-info(style="display:block" v-show="task.lensColor.id && task.packId === 86"
                                    ) {{'tasks.pack.saturation.PACKCOLOR'|translate}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        #coloration.form-control.text-right(disabled) {{task.coveringPrice|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12(v-for="service in task.services.filter(s => s.hoya_option_id)" :key="service.id")
                        .form-group
                            label.col-sm-3.control-label
                            label.col-sm-5.control-label
                                small {{service.name}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        .form-control.text-right(disabled) {{service.pivot.price|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12(v-show="task.assemblyId")
                        .form-group
                            label.col-sm-3.control-label(for="assemblyPrice") {{'services.SERVICE'|translate}}
                            label.col-sm-5.control-label(for="assemblyPrice")
                                small {{task.taskAssembly.name}}
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        #assemblyPrice.form-control.text-right(disabled) {{task.taskAssembly.price|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    OptiTaskDetailsPaymentTaskExtras(
                        :services="task.services"

                        :task-extra-us-info="taskExtraUsInfo"

                        :block="block"
                        :pending="pending"

                        @payment-task-extras-was-updated="$emit('payment-was-updated')")

                    .col-xs-12
                        .form-group.border-top-separate.padding-top
                            label.col-sm-3.control-label(for="pricingWithoutDiscount") {{'tasks.registry.TOTAL'|translate}}
                            .col-sm-offset-5.col-sm-4
                                .row
                                    .col-xs-9
                                        #pricingWithoutDiscount.form-control.text-bold.text-right(disabled
                                            ) {{task.registry.pricingWithoutDiscount|currency}}
                                    .col-xs-3.text-input
                                        i.fa.text-bold =

                    .col-xs-12
                        .form-group
                            label.col-sm-3.control-label(for="marketingAction") {{'tasks.left.PROMOTION'|translate}}
                            .col-sm-5

                            .col-sm-4
                                .row
                                    .col-xs-9
                                        select#marketingAction.form-control.text-expressive(name="promotion"
                                            v-if="!block" :disabled="task.fromServiceContract.id"
                                            v-model="task.marketingActionId" @change="$emit('payment-was-updated')")
                                            option(v-for="p in promotions" :value="p.id") {{p.name}}
                                        span.form-control.text-left.text-expressive(disabled v-else) {{task.marketing.name || 'Brak'}}
                                    .col-xs-3.text-input

                    .col-xs-12
                        .form-group
                            label.col-sm-3.control-label(for="discount") {{'tasks.registry.DISCOUNT'|translate}}
                            .col-sm-3
                                OptiEnum.form-control(disabled enum="TaskRegistryDiscount" :id="task.registry.discountType")
                            .col-sm-2(style="padding-left:0;padding-right:0")
                                OptiNumber#discountPercent(:header="'tasks.registry.DISCOUNTVALUE'|translate"
                                    :disabled="block || forSc || task.marketingActionId === 185" :pending="pending"
                                    :value="task.registry.discountPercent" @confirmed="d => updateDiscountValue('percent', d)"
                                    ) {{task.registry.discountPercent|number(2)}}%
                            .col-sm-4
                                .row
                                    .col-xs-9
                                        OptiNumber#discountAmount(:class="{'star-info': discountPercentWithShipping}"
                                            :header="'tasks.registry.DISCOUNTVALUE'|translate"
                                            :disabled="block || forSc || task.marketingActionId === 185" :pending="pending"
                                            :value="task.registry.discountAmount" @confirmed="d => updateDiscountValue('amount', d)"
                                            ) {{task.registry.discountAmount|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-minus

                    .col-xs-12(v-show="discountPercentWithShipping")
                        .form-group
                            .col-xs-9.col-xs-offset-3.color-blue
                                i.star-info.mr3
                                span - {{'tasks.registry.notify.DISCOUNT_PERCENT_WITH_SHIPPING'|translate}}

                    .col-xs-12
                        .form-group.border-top-separate.padding-top
                            label.col-sm-3.control-label(for="pricing") {{'tasks.registry.PRICING'|translate}}
                            .col-sm-offset-5.col-sm-4
                                .row
                                    .col-xs-9
                                        #pricing.form-control.text-bold.text-right(disabled
                                        ng-class="{'warn': task.registry.pricing < 0}") {{task.registry.pricing|currency}}
                                    .col-xs-3.text-input
                                        i.fa.text-bold =

                    .col-xs-12
                        .form-group
                            label.col-sm-3.control-label(for="hasServiceContract") {{'tasks.US'|translate}}
                            .col-sm-5
                                .row
                                    .col-xs-3
                                        .checkbox.c-checkbox.checkbox-primary
                                            label
                                                input(type="checkbox" name="hasServiceContract"
                                                    :disabled="(block && task.status !== 9) || [2, 4].includes(task.frame.state) || forSc || [26, 27, 29, 31, 41].includes(task.assemblyId)"
                                                    v-model="task.registry.hasServiceContract" @change="$emit('payment-was-updated')")
                                                span.fa.fa-check
                                    .col-xs-9(style="padding-right:0" v-show="task.registry.hasServiceContract")
                                        .form-control.text-right(disabled :class="{'warn': task.registry.pricing * 0.8 < 0}"
                                            ) {{task.registry.pricing * 0.8|currency}}
                            .col-sm-4(v-show="task.registry.hasServiceContract")
                                .row
                                    .col-xs-9
                                        .form-control.text-right(disabled :class="{'warn': task.registry.serviceContractPrice < 0}"
                                            ) {{task.registry.serviceContractPrice|currency}}
                                    .col-xs-3.text-input
                                        i.fa.fa-plus

                    .col-xs-12(v-show="!block || task.status === 9 || task.registry.hasServiceContract")
                        .form-group.border-top-separate.padding-top
                            label.col-sm-3.control-label(for="amountToPay") {{'tasks.registry.AMOUNTTOPAY'|translate}}
                            .col-sm-offset-5.col-sm-4
                                .row
                                    .col-xs-9
                                        #amountToPay.form-control.text-bold.text-right(disabled
                                            :class="{'warn': task.registry.amountToPay < 0}"
                                            ) {{task.registry.amountToPay|currency}}
                                    .col-xs-3.text-input
                                        i.fa.text-bold =

                    .col-xs-12(v-if="notice.account")
                        .form-group(style="margin-bottom:0")
                            .col-sm-8.text-right
                                OptiReceiptNotice(:data="notice.account.all" :deposit="true")
                            .col-sm-4.text-right
                                .row
                                    .col-xs-9(style="padding-right:30px")
                                        OptiReceiptNoticeValue(v-if="task.registry.deposit[0].type.id !== 3"
                                            :data="notice.account.all")

                    .col-xs-12
                        form#formDeposit.form-horizontal.form-validate(name="formDeposit")
                            .form-group(style="margin-bottom:0")
                                OptiPayment(
                                    :payments="task.registry.deposit"
                                    :value="task.registry.account"
                                    :task-id="task.id"

                                    header="payment.directive.DEPOSIT"
                                    type="account"

                                    :notice="notice.account"

                                    :edit="true"
                                    :disabled="block || putBack"
                                    :pending="pending"

                                    :amount-to-pay="task.registry.amountToPay"

                                    @payment-was-updated="$emit('payment-was-updated')"
                                    @invalid="i => $emit('invalid', 'deposit', i)"
                                    @pending="p => $emit('pending', p)")
                                    .col-sm-4
                                        .row
                                            .col-xs-9
                                                OptiNumber#account.text-right(:header="'tasks.registry.ACCOUNT'|translate" :float="true"
                                                    :disabled="block || putBack || goCouponInputDepositDisabled" :pending="pending"
                                                    :value="task.registry.deposit[0].amount" @confirmed="updateAccount"
                                                    ) {{task.registry.deposit[0].amount|currency}}
                                            .col-xs-3.text-input
                                                i.fa.fa-minus

                    .col-xs-12(v-if="notice.rest")
                        .form-group.border-top-separate.padding-top(style="margin-bottom:0")
                            .col-sm-8.text-right
                                OptiReceiptNotice(:data="notice.rest.all" :rest="true")
                            .col-sm-4.text-right
                                .row
                                    .col-xs-9(style="padding-right:30px")
                                        OptiReceiptNoticeValue(v-if="task.registry.rest[0].type.id !== 3"
                                            :data="notice.rest.all")

                    .col-xs-12
                        form#formRest.form-horizontal.form-validate(name="formRest")
                            .form-group(:class="{'border-top-separate': !notice.rest, 'padding-top': !notice.rest}")
                                OptiPayment(
                                    :payments="task.registry.rest"
                                    :value="task.registry.restToPay"
                                    :task-id="task.id"

                                    header="payment.directive.REST"
                                    type="rest"

                                    :notice="notice.rest"

                                    :edit="true"
                                    :disabled="task.status !== 9"
                                    :required="task.status === 9"
                                    :pending="pending"

                                    :rest-to-pay="task.registry.restToPay"

                                    @payment-was-updated="$emit('payment-was-updated')"
                                    @invalid="i => $emit('invalid', 'rest', i)"
                                    @pending="p => $emit('pending', p)")
                                    .col-sm-4
                                        .row
                                            .col-xs-9
                                                #restToPay.form-control.text-bold.text-right(disabled
                                                    :class="{'warn': task.registry.restToPay < 0}"
                                                    ) {{task.registry.restToPay|currency}}
                                            .col-xs-3.text-input
                                                i.fa.text-bold =
                                            .col-xs-12
                            .form-group
                                label.col-sm-3.control-label(for="bill") {{'tasks.registry.BILL'|translate}}
                                .col-sm-5(style="padding-right:0")
                                    OptiReceipt#bill(v-if="task.status !== 0 && task.status !== 10"
                                        :model="task.registry.bill"
                                        :task-id="task.id"
                                        :department-id="task.departmentId"

                                        :disabled="task.status !== 9"
                                        :required="task.status === 9"

                                        @receipt-was-updated="$emit('payment-was-updated')"
                                        @invalid="i => $emit('invalid', 'bill', i)")
                                    .form-control(v-else disabled) {{task.registry.bill.receipt}}

                    .col-xs-12.text-right(v-show="task.status === 9")
                        OptiButton(style="margin-bottom:10px;margin-right:10px" type="success"
                            :disabled="!(valid.bill && valid.rest)" @click.native="$emit('go-done')"
                            ) {{'tasks.action.received.GODONE'|translate}}
                            template(#icon)
                                i.fa.fa-check
</template>

<script>
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiTaskDetailsPaymentTaskExtras
        from '@/js/app/vue/components/Tasks/Details/Payment/OptiTaskDetailsPaymentTaskExtras'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiReceipt from '@/js/app/vue/components/Register/Receipt/OptiReceipt'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskDetailsPayment',
        components: {
            OptiProduct,
            OptiTaskDetailsPaymentTaskExtras,
            OptiEnum,
            OptiNumber,
            OptiReceiptNotice,
            OptiReceiptNoticeValue,
            OptiPayment,
            OptiReceipt,
            OptiButton
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            promotions: {
                type: Array,
                required: true
            },
            notice: {
                type: Object,
                required: true
            },
            valid: {
                type: Object,
                required: true
            },
            saturationTranslate: {
                type: Object,
                required: true
            },
            taskExtraUsInfo: {
                type: Boolean,
                required: true
            },
            discountPercentWithShipping: {
                type: Boolean,
                required: true
            },

            putBack: {
                type: Boolean,
                required: true
            },
            forSc: {
                type: Boolean,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },
            pending: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            updateDiscountValue(type, value) {
                this.task.registry.discountType = value ? type : 'none'

                this.task.registry['discount' + type.charAt(0).toUpperCase() + type.slice(1)] = value

                this.$emit('payment-was-updated')
            },
            updateAccount(account) {
                this.task.registry.deposit[0].amount = account

                this.$emit('payment-was-updated')
            }
        },
        computed: {
            goCouponInputDepositDisabled() {
                if(this.task.registry.accountPaymentType === 2 && this.task.registry.deposit.length &&
                    Object.keys(this.task.registry.deposit[0].ccType).includes('id') &&
                    this.task.registry.deposit[0].ccType.id === 8) {
                    return true
                }

                return false
            }
        }
    }
</script>