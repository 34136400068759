<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-calculator.mr3
            span {{header}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row.mt
                .col-xs-3.pr-sm
                    button.btn.btn-default.btn-block.btn-lg.text-bold(:class="{active: inner.sign === '+'}"
                        v-show="minus" @click='setSign("+")')
                        i.fa.fa-plus
                .col-xs-3.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg.text-bold(:class="{active: inner.sign === '-'}"
                        v-show="minus" @click='setSign("-")')
                        i.fa.fa-minus
                .col-xs-3.pl-sm
                    .form-control.form-control-calculator.text-right
                        span(:class="{'bg-selected': first_add}") {{inner.sign}}{{inner.value1}}
                .col-xs-3.pl-sm
                    .form-control.form-control-calculator.text-right(v-show="inner.value1 !== 'brak'")
                        span(:class="{'bg-selected': first_add}") {{inner.value2}}

            .row.mt(v-show="minus && !inner.sign")
                .col-xs-12.text-center.warn
                    i.fa.fa-exclamation-circle.mr3
                    span {{'default.validate.PLUSMINUS'|translate}}

            .row.mt
                .col-xs-3.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("7")') 7
                .col-xs-3.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("8")') 8
                .col-xs-3.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("9")') 9
                .col-xs-3.pl-sm
                    button.btn.btn-primary.btn-block.btn-lg(@click='add2("00")'
                        :class="{active: inner.value2 === '00'}") 00
            .row.mt
                .col-xs-3.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("4")') 4
                .col-xs-3.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("5")') 5
                .col-xs-3.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("6")') 6
                .col-xs-3.pl-sm
                    button.btn.btn-primary.btn-block.btn-lg(@click='add2("25")'
                        :class="{active: inner.value2 === '25'}") 25
            .row.mt
                .col-xs-3.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("1")') 1
                .col-xs-3.pr-sm.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("2")') 2
                .col-xs-3.pl-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("3")') 3
                .col-xs-3.pl-sm
                    button.btn.btn-primary.btn-block.btn-lg(@click='add2("50")'
                        :class="{active: inner.value2 === '50'}") 50

            .row.mt
                .col-xs-3.pr-sm
                    button.btn.btn-default.btn-block.btn-lg(@click='add("0")') 0
                .col-xs-3.pr-sm.pl-sm
                    button.btn.btn-warning.btn-block.btn-lg.text-bold(@click='clear()') C
                .col-xs-3.pl-sm
                    button.btn.btn-info.btn-block.btn-lg(@click='backspace()')
                        i.fa.fa-arrow-left
                .col-xs-3.pl-sm
                    button.btn.btn-primary.btn-block.btn-lg(@click='add2("75")'
                        :class="{active: inner.value2 === '75'}") 75

        template(#footer)
            OptiButton(type="success" :disabled="disabled" @click.native="confirmed") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(@click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiDialogNumberPrescription',
        components: {
            OptiButton,
            OptiDialog

        },
        props: {
            header: {
                type: String,
                default: ''
            },

            minus: {
                type: Boolean,
                required: false,
                default: true
            },
            none: {
                type: Number | Boolean,
                required: false,
                default: false
            },

            value: {
                type: Number | String,
                required: true
            }
        },
        data() {
            return {
                first_add: true,
                inner: {
                    value1: this.none !== false && this.none === this.value ? 'brak' :
                        Math.abs(parseInt(this.value)).toString(),
                    value2: this.none !== false && this.none === this.value ? '00' :
                        (this.value.toString().includes('.') ?
                            (this.value.toString().split('.')[1] === '5' ? '50' :
                                this.value.toString().split('.')[1]) : '00'),
                    sign: !this.minus || (this.none !== false && this.none === this.value) || !this.value ?
                        null : (this.value > 0 ? '+' : '-'),
                    dot: false
                }
            }
        },

        mounted() {
            window.addEventListener('keydown', this.keyPress)
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyPress)
        },

        methods: {
            firstTime() {
                if(this.first_add) {
                    this.inner.value1 = 0
                    this.inner.value2 = '00'
                    this.inner.sign = null
                    this.inner.dot = false

                    this.first_add = false
                }
            },
            setSign(sign) {
                if(this.minus) {
                    this.firstTime()

                    this.inner.sign = sign
                }
            },
            add(num) {
                this.firstTime()

                if(!parseFloat(this.inner.value1)) {
                    this.inner.value1 = num
                } else if(this.inner.value1.length === 2) {
                    this.inner.value1 = this.inner.value1[0] + num
                } else {
                    this.inner.value1 += num
                }
            },
            add2(num2) {
                this.firstTime()

                this.inner.value2 = num2
            },
            clear() {
                this.firstTime()

                this.inner.value1 = 0
                this.inner.value2 = '00'
                this.inner.sign = null
                this.inner.dot = false
            },
            backspace() {
                this.firstTime()

                if(this.inner.value2 !== '00') {
                    this.inner.value2 = '00'
                } else {
                    let str = this.inner.value1.toString()
                    this.inner.value1 = str.substring(0, str.length - 1)

                    if(typeof this.inner.value1 === 'undefined' || this.inner.value1 === '') {
                        this.inner.value1 = 0
                    }
                }

                if(!parseInt(this.inner.value1) && !parseInt(this.inner.value2)) {
                    this.inner.sign = null
                }

                this.inner.dot = false
            },

            keyPress(e) {
                let key = e.which

                if(key >= 96 && key <= 106) {
                    key -= 48
                }

                switch(key) {
                    case 48: // 0
                    case 49: // 1
                    case 50: // 2
                    case 51: // 3
                    case 52: // 4
                    case 53: // 5
                    case 54: // 6
                    case 55: // 7
                    case 56: // 8
                    case 57: // 9
                        if(this.inner.dot) {
                            switch(key) {
                                case 48: // 0
                                    this.add2('00')

                                    break

                                case 50: // 2
                                    this.add2('25')

                                    break

                                case 53: // 5
                                    this.add2('50')

                                    break

                                case 55: // 7
                                    this.add2('75')
                            }
                        } else {
                            this.add((key - 48).toString())
                        }

                        break

                    case 107: // plus
                    case 187:
                        this.setSign('+')

                        break

                    case 109:
                    case 189: // minus
                        this.setSign('-')

                        break

                    case 8:
                        this.backspace()

                        break

                    case 46:
                        this.clear()

                        break

                    case 13:  // enter
                        if(!this.disabled) {
                            this.confirmed()
                        }

                        break

                    case 188:  // comma
                    case 190:  // dot
                    case 108:  // dot (numpad)
                    case 110:  // dot (windows)
                        this.inner.dot = true
                }
            },

            confirmed() {
                let value = parseFloat(this.inner.value1 + '.' + this.inner.value2)

                if(this.inner.sign === '-') {
                    value *= -1
                }

                this.$emit('confirmed', value)
            }
        },
        computed: {
            disabled() {
                return (this.minus && !this.inner.sign) || this.inner.value1 === 'brak'
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 420px;
    }
</style>