<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'specification.breadcrumb.CREATE'|translate}}

                .panel-body
                    form.form-horizontal
                        .form-group
                            label.col-sm-4.control-label(for="department-id") Salon
                            .col-sm-8
                                OptiSelectDepartment#department-id(
                                    :department-id="departmentId"
                                    :reduce="department => department.id"

                                    @selected="d => departmentId = d")
                        .form-group
                            label.col-sm-4.control-label(for="date_from") {{'specification.create.FROM'|translate}}
                            .col-sm-8
                                OptiDate#date_from(:value="date_from" @update-date="d => date_from = d")
                        .form-group
                            label.col-sm-4.control-label(for="date_to") {{'specification.create.TO'|translate}}
                            .col-sm-8
                                OptiDate#date_to(:value="date_to" @update-date="d => date_to = d")
                        .form-group
                            .col-sm-8.col-sm-offset-4
                                .row
                                    .col-xs-6
                                        label(for="year") Rok
                                        select#year.form-control(name="year" v-model="year" @change="clearDate")
                                            option(v-for="year in years" :value="year") {{year}}
                                    .col-xs-6
                                        label(for="month") Miesiąc
                                        select#month.form-control(name="month" v-model="month" :disabled="!year" @change="updateDate")
                                            option(v-for="month in months" :value="month") {{month}}

                        .form-group
                            .col-xs-12
                                button.btn.btn-default(type="button" @click="showAll()" :disabled="!departmentId") {{'specification.table.sales.ALL'|translate}}

                                button.btn.btn-default.pull-right(type="button" @click="showSales()" :disabled="!date_from || !date_to || !departmentId") Podgląd

                .panel-footer.text-right
                    OptiButton(type="success" @click.native="create" :disabled="!sales.length") {{'specification.table.sales.CREATE'|translate}}
                        template(#icon)
                            i.fa.fa-save

                OptiDimmer(:active="loading")

        .col-xs-12(v-if="this.table")
            table.table.table-striped
                thead
                    tr
                        th.text-center #
                        th.text-center ID sprzedaży
                        th.text-left Data
                        th.text-left Netto
                        th.text-left Brutto
                        th.text-left Po rabacie
                        th.text-left Sztuk w sprzedaży

                tbody
                    tr(v-for="(sale, index) in sales")
                        td.text-right {{index + 1}}
                        td.text-center {{sale.SALE_ID}}
                        td.text-left {{sale.SALE_DATE}}
                        td.text-left {{sale.ITEMS_WHOLESALE_PRICE|currency}}
                        td.text-left {{sale.ITEMS_VALUE|currency}}
                        td.text-left {{sale.ITEMS_VALUE_AFTER_DISCOUNT|currency}}
                        td.text-left {{sale.ITEMS_QUANTITY}}

                tfoot(v-show="!sales.length")
                    tr
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: "OptiSaleSpecificationCreate",
        components: {
            OptiSelectDepartment,
            OptiDate,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                departmentId: null,
                date_from: '',
                date_to: '',
                month: null,
                year: null,
                table: false,
                sales: [],

                loading: false
            }
        },
        methods: {
            async showSales() {
                this.sales = []

                try {
                    this.loading = true
                    let {data} = await API.post('specifications/sales/getSales', {
                        departmentId: this.departmentId,
                        date_from: this.date_from,
                        date_to: this.date_to
                    })
                    this.loading = false
                    this.table = true
                    this.sales = data
                } catch(e) {
                    console.log(e)
                }

                return this.sales
            },
            async create() {
                this.loading = true

                let response = await API.post('specifications/sales/saveSpecification', {
                    sales: this.sales
                })
                this.loading = false
                this.$notify.success('Nowa specyfikacja została utworzona.')
                return this.$state.go('app.specifications.salesDetails', {
                    id: response.data
                })
            },
            async showAll() {
                this.sales = []

                try {
                    this.loading = true
                    let {data} = await API.post('specifications/sales/getAllSales', {
                        departmentId: this.departmentId
                    })
                    this.loading = false
                    this.table = true
                    this.sales = data
                } catch (e) {
                    console.log(e)
                }
                return this.sales
            },
            updateDate() {
                let month = this.month
                let year = this.year

                const date = moment().year(year).month(month).format('YYYY-MM-DD')
                this.date_from = moment(date).startOf('month').format('YYYY-MM-DD')
                this.date_to = moment(date).endOf('month').format('YYYY-MM-DD')
            },
            clearDate() {
                this.date_from = ''
                this.date_to = ''
                this.month = null
            }
        },
        computed: {
            years() {
                const year = new Date().getFullYear()

                return Array.from({length: year - 2015}, (value, index) => 2016 + index)
            },
            months() {
                return Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MMMM')})
            }
        }
    }
</script>

<style scoped>
    button {
        margin: 5px
    }
</style>