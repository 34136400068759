<template lang="pug">
    a.link(v-if="data" :class="{'warn': data.status > 2 && $uac.permission('patients.status')}"
        :title="'sale.offstock.table.action.DETAILPATIENT'|translate" target="_blank" :href="patientLink()")
        span {{data.firstName}} {{data.lastName}} ({{data.id}})
        span(style="margin-left:3px" v-show="data.status > 2 && $uac.permission('patients.status')") ({{data.statusName|translate}})
        span(style="margin-left:3px" v-if="data.loyality && data.loyality.typeId") - {{'sale.offstock.add.CARD'|translate}} {{data.loyality.typeTranslate|translate}}
</template>

<script>
    export default {
        name: 'OptiPatient',
        props: {
            data: {
                required: true
            }
        },
        methods: {
            patientLink() {
                return this.$state.href('app.patient.detail', {
                    id: typeof this.data !== 'undefined' ? this.data.id : 0
                })
            }
        }
    }
</script>
