<template lang="pug">
    #navigation-budget-edit.col-md-4.text-center
        #date(v-show="['day', 'agenda'].includes(view.type)")
            OptiDate(:value="date" @update-date="d => date = d")

        .input-group(v-show="view.type === 'week'")
            select.form-control(v-model="week")
                option(v-for="m in select.weeks" :value="m") {{m}}
            span.input-group-addon W
            select.select-year.form-control(v-model="year" @change="changeWeekYear")
                option(v-for="y in select.years" :value="y") {{y}}

        #month.input-group(v-show="view.type === 'month'")
            select.form-control(v-model="month")
                option(v-for="(m, k) in select.months" :value="k") {{m}}
            span.input-group-addon
            select.select-year.form-control(v-model="year")
                option(v-for="y in select.years" :value="y") {{y}}

        .input-group(v-show="view.type === 'quarter'")
            select.form-control(v-model="quarter")
                option(v-for="q in select.quarters" :value="q") {{q}}
            span.input-group-addon Q
            select.select-year.form-control(v-model="year")
                option(v-for="y in select.years" :value="y") {{y}}

        #btn-accept.btn.btn-success(@click="accept")
            i.fa.fa-check
</template>

<script>
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiDate from '@/js/app/vue/components/OptiDate'

    export default {
        name: 'OptiScheduleNavigationEdit',
        components: {
            OptiDate
        },
        props: {
            view: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                date: this.view.start.format('YYYY-MM-DD'),
                year: this.view.start.isoWeekYear(),
                week: this.view.start.isoWeek(),
                month: this.view.start.month(),
                quarter: BudgetHelper.getQuarterByWeek(this.view.start),

                select: {
                    weeks: _.range(1, 53 + 1),
                    months: _.range(0, 11 + 1).map((_,i) => moment().month(i).format('MMMM')),
                    quarters: _.range(1, 4 + 1),
                    years: _.range(moment().isoWeekYear() - 3, moment().isoWeekYear() + 1 + 1)
                }
            }
        },
        mounted() {
            if(['month', 'quarter'].includes(this.view.type)) {
                this.year = this.view.start.clone().add(10, 'd').isoWeekYear()
            }
        },
        methods: {
            changeWeekYear() {
                if(BudgetHelper.isW53(this.year)) {
                    if(this.select.weeks.length === 52) {
                        this.select.weeks.push(53)
                    }
                } else {
                    if(this.week === 53) {
                        this.week = 1
                    }

                    if(this.select.weeks.length === 53) {
                        this.select.weeks.pop()
                    }
                }
            },
            accept() {
                switch(this.view.type) {
                    case 'day':
                    case 'agenda':
                        this.view.start = moment(this.date)

                        break

                    case 'week':
                        this.view.start = moment().isoWeekYear(this.year).isoWeek(this.week)

                        break

                    case 'month':
                        this.view.start = moment().isoWeekYear(this.year).month(this.month)

                        break

                    case 'quarter':
                        this.view.start = moment().isoWeekYear(this.year)
                            .week(BudgetHelper.weeks_by_quarter[this.quarter][0]).startOf('w')
                }

                if(this.view.start.isValid()) {
                    this.$emit('accepted')
                } else {
                    this.$notify.error('schedule.notify.error.INVALIDDATE')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
     #navigation-budget-edit {
        display: flex;
        justify-content: center;

        > #date {
            display: inline-block;
            width: 160px;
        }

         .input-group {
             width: 220px;

             &#month > .input-group-addon {
                 padding: 0;
                 border: 0;
             }
         }

         #btn-accept {
             margin-left: 15px;
         }
    }
</style>