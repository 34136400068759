<template lang="pug">
    .col-xs-12(:data-invalid="invalid")
        .panel.panel-primary.reclamation-service(v-if="!loading && ![3, 4, 5].includes(productType)")
            .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
                i.fa.fa-navicon.mr3
                span {{'sale.reclamation.DEPARTMENTATTENSION'|translate}}
                i.fa.pull-right(style="padding-top:3px" :class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")

            .panel-body(v-for="reclamationServiceType in reclamationServiceTypes" :key="reclamationServiceType.id"
                v-show="toggle && reclamationServiceType.id && services[reclamationServiceType.id].length && filterEnum(reclamationServiceType.id)")
                .row
                    .col-xs-12.bold(style="padding-top:10px;padding-bottom:5px;font-size:20px")
                        i.fa.fa-wrench.mr3
                        span {{reclamationServiceType.name|translate}}

                .row(v-for="(service, key) in services[reclamationServiceType.id]" :key="key")
                    .col-sm-6
                        .form-group
                            label.col-xs-12.control-label.star-required(style="text-align:left"
                                :for="'reclamationService' + reclamationServiceType.id + 'key' + key"
                                ) {{'sale.reclamation.service.SERVICE'|translate}}
                            .col-xs-12
                                VueMultiSelect(
                                    :id="'reclamationService' + reclamationServiceType.id + 'key' + key"
                                    :options="service.options"
                                    :value="service.service"
                                    :clearable="false"
                                    label="id"
                                    :placeholder="'sale.reclamation.service.PLACEHOLDER'|translate"
                                    :allow-empty="false"
                                    :showLabels="false"
                                    :showNoResults="false"
                                    ref="select_reclamation_service"
                                    track-by="id"
                                    @input="s => service.service = s")
                                    template(slot="singleLabel" slot-scope="props")
                                        span {{props.option.name}}
                                    template(slot="option" slot-scope="props")
                                        span {{props.option.name}}
                                        small.pull-right {{props.option.type_name|translate}}
                                    template(slot="noOptions") {{'search.EMPTY'|translate}}
                                label.warn(:for="'reclamationService' + reclamationServiceType.id + 'key' + key"
                                    v-show="!service.service") {{'validation.formValidate.required'|translate}}
                        .form-group(v-show="reclamationServiceType.id !== 6")
                            label.col-xs-12.star-required(
                                :for="'reclamationServicePicture' + reclamationServiceType.id + 'key' + key"
                                ) {{'sale.reclamation.PICTURE'|translate}}
                            .col-xs-12
                                OptiUpload(
                                    :id="'reclamationServicePicture' + reclamationServiceType.id + 'key' + key"
                                    :picture="true"
                                    :prefix="type + '_reclamation_service'"

                                    :file="service.picture"

                                    @update-file="p => service.picture = p"
                                    @update-loading="l => service.loading = l")
                                label.warn(:for="'reclamationServicePicture' + reclamationServiceType.id + 'key' + key"
                                    v-show="!service.picture") {{'validation.formValidate.required'|translate}}

                    .col-sm-6
                        .form-group
                            label.col-xs-12.control-label.star-required(style="text-align:left"
                                :for="'reclamationServiceDescription' + reclamationServiceType.id + 'key' + key"
                                 :class="{'star-required': service.reclamation_service_id}") {{'sale.reclamation.service.DESCRIPTION'|translate}}
                            .col-xs-12
                                textarea.form-control(:id="'reclamationServiceDescription' + reclamationServiceType.id + 'key' + key"
                                    :name="'reclamationServiceDescription' + reclamationServiceType.id + 'key' + key" rows="4"
                                    v-model="service.description")
                                label.warn(:for="'reclamationServiceDescription' + reclamationServiceType.id + 'key' + key"
                                    v-show="!service.description") {{'validation.formValidate.required'|translate}}
                        .form-group
                            .col-xs-12.text-right
                                OptiButton(type="danger" v-show="services[reclamationServiceType.id].length > 1"
                                    @click.native="removeService(reclamationServiceType.id, key)") {{'sale.reclamation.service.DELETE'|translate}}
                                    template(#icon)
                                        i.fa.fa-minus

                                OptiButton(style="margin-left:15px" type="success" v-show="parseInt(key) === Object.keys(services[reclamationServiceType.id]).length - 1"
                                    :disabled="!service.service || (service.service && (!service.description || (service.type !== 6 && !service.picture)))"
                                    @click.native="addService(reclamationServiceType.id)") {{'sale.reclamation.service.ADD'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EnumReclamationServiceType} from '../../enums/Enums'
    import Vue from 'vue'

    import VueMultiSelect from 'vue-multiselect'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReclamationServices',
        components: {
            VueMultiSelect,
            OptiUpload,
            OptiButton
        },
        props: {
            edit: {
                type: Boolean,
                required: false,
                default: false
            },

            type: {
                type: String,
                required: true
            },
            productType: {
                type: Number,
                required: true
            },
            services: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                reclamationServiceTypes: EnumReclamationServiceType.simpleItems(),
                reclamationService: [],

                toggle: false,
                loading: true
            }
        },
        watch: {
            productType() {
                this.reset()
            }
        },
        mounted() {
            let type_tab = {
                task: 1,
                sale: 2
            }

            let tab_id = type_tab[this.type]

            API.get('dictionary/reclamation-service/action').then(res => {
                this.reclamationService = []

                res.data.forEach(service => {
                    if(([0, 3, tab_id]).indexOf(service.reclamation_type) > -1) {
                        if(([95, 96, 97, 98, 99, 100, 101]).indexOf(service.id) > -1) { // Brak zastrzeżeń
                            this.reclamationService.unshift(service)
                        } else {
                            this.reclamationService.push(service)
                        }
                    }
                })

                if(this.edit) {
                    _.forEach(this.services, (services, type_id) => {
                        services.forEach(service => {
                            service.options = this.filterOptions(parseInt(type_id))
                        })
                    })
                } else {
                    this.reset()
                }

            }).finally(() => {
                this.loading = false
            }).catch(() => {

            })
        },
        methods: {
            filterOptions(type_id) {
                let filtered = []

                this.reclamationService.forEach(item => {
                    if(item.type === type_id) {
                        filtered.push(item)
                    }
                })

                return filtered
            },
            reset() {
                this.reclamationServiceTypes.forEach(reclamationServiceType => {
                    if(reclamationServiceType.id) {
                        if([0, 1].includes(this.productType) || (this.productType === 2 && [4, 5].includes(reclamationServiceType.id))) {
                            Vue.set(this.services, reclamationServiceType.id, [{
                                options: this.filterOptions(reclamationServiceType.id),
                                service: null,
                                type: reclamationServiceType.id,
                                description: '',
                                picture: null,
                                loading: false
                            }])
                        } else {
                            Vue.set(this.services, reclamationServiceType.id, [])
                        }
                    }
                })
            },

            filterEnum(typeId) {
                let _return = false

                this.reclamationService.forEach(reclamationService => {
                    if(reclamationService.id && reclamationService.type === typeId) {
                        _return = true
                    }
                })

                return _return
            },

            addService(type_id) {
                this.services[type_id].push({
                    options: this.filterOptions(type_id),
                    service: null,
                    type: type_id,
                    description: '',
                    picture: null,
                    loading: false
                })
            },
            removeService(type_id, service_id) {
                this.services[type_id].splice(service_id, 1)
            }
        },
        computed: {
            invalid() { // niestety zagnieżdżony $each w vuelidate nie działa
                let _return = false

                if([3, 4, 5].includes(this.productType)) {
                    this.$emit('update-services-invalid', _return)

                    return _return
                }

                _.forEach(this.services, (services, type_id) => {
                    services.forEach(service => {
                        if(this.filterEnum(parseInt(type_id)) && this.services[parseInt(type_id)].length && !(service.service && service.description && (parseInt(type_id) === 6 || (service.picture && !service.loading)))) {
                            _return = true
                        }
                    })
                })

                this.$emit('update-services-invalid', _return)

                return _return
            }
        }
    }
</script>
