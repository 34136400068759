import {API} from '@/js/app/vue/api'
import {helpers} from 'vuelidate/lib/validators'

function paymentCouponIf(bool, task_id) {
    let timeout = [],
        previous = []

    return helpers.withParams({
        bool: bool
    }, async function(value, payment) {
        if(this.disabled) {
            return true
        }

        let ref = helpers.ref(bool, this, payment), //potrzebne dla OptiPayment.vue
            index = this.payments.map(p => p.__ob__.dep.id).findIndex(p => p === payment.__ob__.dep.id)

        if(typeof ref === 'undefined') {
            ref = bool
        }

        if(ref && previous[index] && previous[index].value === value) {
            return previous[index]._return
        }

        previous[index] = {
            value: value,
            _return: true
        }

        if(!ref || !value || ![11, 12].includes(value.toString().length)) {
            this.coupon_data[index] = {}

            if(payment.type.id === 2 && payment.ccType.id === 8 && this.type !== 'rest') {
                payment.amount = 0
            }

            previous[index]._return = true
            return true
        }

        return new Promise(resolve => {
            if(timeout[index]) {
                clearTimeout(timeout[index])
            }

            timeout[index] = setTimeout(() => {
                API.get('validate/coupon/' + task_id + '/' + value, API.id(Math.random())).then(res => {
                    this.coupon_data[index] = res.data

                    if(res.data.success) {
                        if(this.type === 'account' && !index && payment.type.id === 2 && payment.ccType.id === 8 &&
                            res.data.success > parseFloat(this.amountToPay)) {
                            if(payment.amount !== parseFloat(this.amountToPay)) {
                                payment.amount = parseFloat(this.amountToPay)

                                this.$notify.warning('coupons.validate.EXCESS')
                            }
                        } else if(this.type === 'rest' && !index && payment.type.id === 2 && payment.ccType.id === 8 &&
                            res.data.success < this.restToPay) {
                            payment.cardNumber = ''
                            payment.amount = 0

                            this.$notify.error('coupons.validate.LOWTASK')
                        } else {
                            payment.amount = res.data.success
                        }

                        previous[index]._return = true
                        resolve(true)
                    } else {
                        payment.amount = 0

                        previous[index]._return = false
                        resolve(false)
                    }
                }).catch(() => {
                    this.coupon_data[index] = {
                        type: 'message',
                        text: 'Wystąpił błąd przy sprawdzaniu bonu podarunkowego!'
                    }

                    payment.amount = 0

                    previous[index]._return = false
                    resolve(false)
                })
            }, 300)
        })
    })
}

function paymentMixRequiredMinTwoTypePayments() {
    return helpers.withParams({ }, function(value, payment) {
        let index = this.payments.map(p => p.__ob__.dep.id).findIndex(p => p === payment.__ob__.dep.id)

        return index || this.payments[0].type.id !== 3 || (this.payments.length > 2 && this.payments[0].type.id === 3)
    })
}

function paymentValueDifference() {
    return helpers.withParams({ }, function(value, payment) {
        let index = this.payments.map(p => p.__ob__.dep.id).findIndex(p => p === payment.__ob__.dep.id),
            sum = 0

        this.payments.forEach(p => {
            sum += p.amount
        })

        this.diff = _.round(this.value, 2) - _.round(sum, 2)

        return this.type === 'account' || this.payments[0].type.id !== 3 || index || !this.diff
    })
}

export {paymentCouponIf, paymentMixRequiredMinTwoTypePayments, paymentValueDifference}