<template lang="pug">
    .row
        .col-md-4
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.content.title.TRANSFERSTOSEND'|translate}}

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th #
                                th Id
                                th {{'shipments.new.content.PRODUCT'|translate}}
                                th
                        tbody
                            tr(v-for="(transfer, key) in ingredients.transfers" :key="transfer.id" v-show="!transfer.added")
                                td {{ingredients.transfers.length - key}}
                                td
                                    OptiRefId(section="shipment-item" :type-id="13" :id="transfer.id")
                                td
                                    span {{transfer.name}}
                                    small(style="display:block" v-show="transfer.refType")
                                        OptiEnum.mr3(enum="TransferDocumentType" :id="transfer.refType")
                                        OptiRefId(section="transfer" :type-id="transfer.refType" :id="transfer.refId")
                                td.text-right
                                    button.btn.btn-success(type="button" :title="'shipments.new.content.title.item.ADD'|translate"
                                        :disabled="receiver.type !== 0 || (department.id && department.id !== transfer.destinationSalonId)"
                                        @click="addTransfer(transfer)")
                                        i.fa.fa-plus-square
                            tr(v-show="isAllAdded('transfers')")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.content.title.REGENERATIONDEPARTMENT'|translate}}

                .table-responsive
                    table.table.table-striped.middle
                        thead
                            tr
                                th #
                                th Id
                                th {{'shipments.new.content.PRODUCT'|translate}}
                                th
                        tbody
                            tr(v-for="(regeneration, key) in ingredients.regenerations" :key="regeneration.id" v-show="!regeneration.added")
                                td {{ingredients.regenerations.length - key}}
                                td
                                    OptiRefId(section="shipment-item" :type-id="15" :id="regeneration.id")
                                td
                                    span {{regeneration.product_name}}
                                    small(style="display:block") {{regeneration.patient_name}}
                                td.text-right
                                    button.btn.btn-success(type="button" :title="'shipments.new.content.title.item.ADD'|translate"
                                        :disabled="!(receiver.type === 0 && (!department.id || receiver.address.id === 49))"
                                        @click="addRegeneration(regeneration)")
                                        i.fa.fa-plus-square
                            tr(v-show="isAllAdded('regenerations')")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

        .col-md-8
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.content.title.OTHER'|translate}}
                .panel-body
                    form.form-horizontal(@submit.prevent="addCustomItem")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="specifyname") {{'shipments.new.content.other.SPECIFYNAME'|translate}}
                            .col-sm-9
                                input#specifyname.form-control(v-model="customItem.name" name="specifyname" required)
                                OptiValidate(f="specifyname" :data="$v.customItem.name")
                        .form-group
                            label.col-sm-3.control-label(for="description") {{'shipments.new.content.other.DESCRIPTION'|translate}}
                            .col-sm-9
                                textarea#description.form-control(v-model="customItem.description")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="quantity") {{'shipments.new.content.other.QUANTITY'|translate}}
                            .col-sm-9
                                OptiNumber#quantity(:header="'shipments.new.content.other.QUANTITY'|translate"
                                    :value="customItem.quantity" @confirmed="q => customItem.quantity = q"
                                    :required="true") {{customItem.quantity}}
                                OptiValidate(f="quantity" :data="$v.customItem.quantity")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="val") {{'shipments.new.content.other.VALUE'|translate}}
                            .col-sm-9
                                OptiNumber#val(:header="'shipments.new.content.other.VALUE'|translate"
                                    :value="customItem.val" @confirmed="v => customItem.val = v"
                                    :float="true" :required="true") {{customItem.val|currency}}
                                OptiValidate(f="val" :data="$v.customItem.val")
                        .form-group
                            .col-sm-offset-3.col-sm-9
                                OptiButtonSave(:disabled="$v.customItem.$invalid")

            .row
                .col-md-6
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'shipments.new.content.title.RECLAMATIONTASKSACCEPTED'|translate}}

                        .table-responsive
                            table.table.table-stripted.middle
                                thead
                                    tr
                                        th #
                                        th Id
                                        th {{'shipments.new.content.CLIENT'|translate}}
                                        th
                                tbody
                                    tr(v-for="(reclamation, key) in ingredients.reclamations_to_task" :key="reclamation.id" v-show="!reclamation.added")
                                        td {{ingredients.reclamations_to_task.length - key}}
                                        td
                                            OptiRefId(section="shipment-item" :type-id="12" :id="reclamation.id")
                                        td {{reclamation.clientName}}
                                        td.text-right
                                            button.btn.btn-success(type="button"
                                                :disabled="!(receiver.type === 0 && (!department.id || receiver.address.id === 49))"
                                                @click="addReclamationTask(reclamation)")
                                                i.fa.fa-plus-square
                                    tr(v-show="isAllAdded('reclamations_to_task')")
                                        td.text-center.text-muted(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}

                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'shipments.new.content.title.RECLAMATIONSALEACCEPTED'|translate}}

                        .table-responsive
                            table.table.table-striped.middle
                                thead
                                    tr
                                        th #
                                        th Id
                                        th {{'shipments.new.content.CLIENT'|translate}}
                                        th
                                tbody
                                    tr(v-for="(reclamation, key) in ingredients.reclamations_to_sale" :key="reclamation.id" v-show="!reclamation.added")
                                        td {{ingredients.reclamations_to_sale.length - key}}
                                        td
                                            OptiRefId(section="shipment-item" :type-id="14" :id="reclamation.id")
                                        td {{reclamation.client}}
                                        td.text-right
                                            button.btn.btn-success(type="button"
                                                :disabled="!(receiver.type === 0 && (!department.id || receiver.address.id === 49))"
                                                @click="addReclamationSale(reclamation)")
                                                i.fa.fa-plus-square
                                    tr(v-show="isAllAdded('reclamations_to_sale')")
                                        td.text-center.text-muted(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}

                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'shipments.new.content.title.RETURNSTOSEND'|translate}}

                        .table-responsive
                            table.table.table-stripted.middle
                                thead
                                    tr
                                        th #
                                        th Id
                                        th {{'shipments.new.content.QUANTITY'|translate}}
                                        th {{'shipments.new.content.VALUE'|translate}}
                                        th
                                tbody
                                    tr(v-for="(_return, key) in ingredients.returns" :key="_return.id" v-show="!_return.added")
                                        td {{ingredients.returns.length - key}}
                                        td
                                            OptiRefId(section="shipment-item" :type-id="2" :id="_return.id")
                                        td {{_return.quantity}}
                                        td {{_return.value|currency}}
                                        td.text-right
                                            button.btn.btn-success(type="button"
                                                :disabled="!(receiver.type === 0 && (!department.id || receiver.address.id === 49))"
                                                @click="addReturn(_return)")
                                                i.fa.fa-plus-square
                                    tr(v-show="isAllAdded('returns')")
                                        td.text-center.text-muted(colspan="100")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'default.table.EMPTY'|translate}}

                .col-md-6
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'shipments.new.content.title.TASKRECEIVED'|translate}}

                        .table-responsive
                            table.table.table-striped.middle
                                thead
                                    tr
                                        th #
                                        th Id
                                        th {{'shipments.new.content.PATIENT'|translate}}
                                        th
                                tbody
                                    tr(v-show="!rows.tasks.received")
                                        td.text-center(colspan="4")
                                            a.link(style="display:block" :title="'default.table.show.allrow.ALL'|translate"
                                                @click="rows.tasks.received = true")
                                                i.fa.fa-arrow-down.mr3
                                                span {{'default.table.show.allrow.ALL'|translate}}
                                    tr(v-show="rows.tasks.received")
                                        td.text-center(colspan="4")
                                            a.link(style="display:block" :title="'default.table.show.allrow.HIDE'|translate"
                                                @click="rows.tasks.received = false")
                                                i.fa.fa-arrow-up.mr3
                                                span {{'default.table.show.allrow.HIDE'|translate}}
                                    tr(v-for="(task, key) in ingredients.tasks" :key="task.id" v-show="!task.added && rows.tasks.received")
                                        td {{ingredients.tasks.length - key}}
                                        td
                                            OptiRefId(section="shipment-item" :type-id="4" :id="task.id")
                                        td {{task.patient.firstName}} {{task.patient.lastName}}
                                        td.text-right
                                            button.btn.btn-success(type="button"
                                                :disabled="!task.status && receiver.address.id !== 49"
                                                @click="addTask(task)")
                                                i.fa.fa-plus-square

                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'shipments.new.content.title.TASKCANCELLED'|translate}}

                        .table-responsive
                            table.table.table-striped.middle
                                thead
                                    tr
                                        th #
                                        th Id
                                        th {{'shipments.new.content.PATIENT'|translate}}
                                        th
                                tbody
                                    tr(v-show="!rows.tasks.cancelled")
                                        td.text-center(colspan="4")
                                            a.link(style="display:block" :title="'default.table.show.allrow.ALL'|translate"
                                                @click="rows.tasks.cancelled = true")
                                                i.fa.fa-arrow-down.mr3
                                                span {{'default.table.show.allrow.ALL'|translate}}
                                    tr(v-show="rows.tasks.cancelled")
                                        td.text-center(colspan="4")
                                            a.link(style="display:block" :title="'default.table.show.allrow.HIDE'|translate"
                                                @click="rows.tasks.cancelled = false")
                                                i.fa.fa-arrow-up.mr3
                                                span {{'default.table.show.allrow.HIDE'|translate}}
                                    tr(v-for="(task, key) in ingredients.tasks_cancelled" :key="task.id" v-show="!task.added && rows.tasks.cancelled")
                                        td {{ingredients.tasks_cancelled.length - key}}
                                        td
                                            OptiRefId(section="shipment-item" :type-id="4" :id="task.id")
                                        td {{task.patient.firstName}} {{task.patient.lastName}}
                                        td.text-right
                                            button.btn.btn-success(type="button"
                                                :disabled="!task.status && receiver.address.id !== 49"
                                                @click="addTask(task)")
                                                i.fa.fa-plus-square
</template>

<script>
    import Vue from 'vue'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiShipmentCreateStepContent',
        components: {
            OptiRefId,
            OptiEnum,
            OptiNumber,
            OptiValidate,
            OptiButtonSave
        },
        props: {
            shipment: {
                type: Object,
                required: true
            },
            ingredients: {
                type: Object,
                required: true
            },
            department: {
                type: Object,
                required: true
            },
            receiver: {
                type: Object,
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            let prefixLanguage = 'shipments.new.content.description.'

            return {
                language: {
                    _return: this.$filters.translate(prefixLanguage + 'RETURN'),
                    reclamation_task: this.$filters.translate(prefixLanguage + 'RECLAMATIONTASK'),
                    reclamation_sale: this.$filters.translate(prefixLanguage + 'RECLAMATIONSALE'),
                    transfer: this.$filters.translate(prefixLanguage + 'TRANSFER'),
                    regeneration: this.$filters.translate(prefixLanguage + 'REGENERATION'),
                    task: this.$filters.translate(prefixLanguage + 'TASK'),
                    product: this.$filters.translate(prefixLanguage + 'PRODUCT'),
                    products: this.$filters.translate(prefixLanguage + 'PRODUCTS'),
                    products2: this.$filters.translate(prefixLanguage + 'PRODUCTS2'),
                    value: this.$filters.translate(prefixLanguage + 'VALUE')
                },

                customItem: {
                    name: '',
                    description: '',
                    quantity: 0,
                    val: 0
                },

                rows: {
                    tasks: {
                        received: false,
                        cancelled: false
                    }
                }
            }
        },
        validations: {
            customItem: {
                name: {
                    required
                },
                quantity: {
                    noZero
                },
                val: {
                    noZero
                }
            }
        },
        mounted() {

        },
        methods: {
            isAllAdded(type) {
                let _return = true

                if(typeof this.ingredients[type] !== 'undefined') {
                    this.ingredients[type].forEach(t => {
                        if(!t.added) {
                            _return = false
                        }
                    })
                }

                return _return
            },

            addTransfer(transfer) {
                if(transfer.added) return

                let value = transfer.customer_product !== null ? parseFloat(transfer.customer_product.price) : 0

                this.items.push({
                    id: transfer.id,
                    name: this.language.transfer + ' #' + transfer.id,
                    description: transfer.name + (transfer.refType === 5 ? (' - ' + this.language.task + ' #' + transfer.refId) : ''),
                    quantity: 1,
                    value: value,
                    type: 13,
                    destinationSalonId: transfer.destinationSalonId
                })

                Vue.set(transfer, 'added', true)

                this.shipment.suggested_value += value

                this.$emit('update-item')
            },
            addRegeneration(regeneration) {
                if(regeneration.added) return

                this.items.push({
                    id: regeneration.id,
                    name: this.language.regeneration + ' #' + regeneration.id,
                    description: regeneration.product_name + ' (' + regeneration.patient_name + ')',
                    quantity: 1,
                    value: regeneration.value,
                    type: 15
                })

                Vue.set(regeneration, 'added', true)

                this.shipment.suggested_value += parseFloat(regeneration.value)

                this.$emit('update-item')
            },
            addReclamationTask(reclamation) {
                if(reclamation.added) return

                this.items.push({
                    id: reclamation.id,
                    name: this.language.reclamation_task + ' #' + reclamation.id,
                    description: reclamation.clientName + ' (' + reclamation.productName + ')',
                    quantity: 1,
                    value: reclamation.productPrice,
                    type: 12
                })

                Vue.set(reclamation, 'added', true)

                this.shipment.suggested_value += parseFloat(reclamation.productPrice)

                this.$emit('update-item')
            },
            addReclamationSale(reclamation) {
                if (reclamation.added) return

                this.items.push({
                    id: reclamation.id,
                    name: this.language.reclamation_sale + ' #' + reclamation.id,
                    description: reclamation.client + ' (' + reclamation.product_relation.name + ')',
                    quantity: 1,
                    value: reclamation.product_relation.price_calculated,
                    type: 14
                })

                Vue.set(reclamation, 'added', true)

                this.shipment.suggested_value += parseFloat(reclamation.product_relation.price_calculated)

                this.$emit('update-item')
            },
            addReturn(_return) {
                if(_return.added) return

                this.items.push({
                    id: _return.id,
                    name: this.language._return + ' #' + _return.id,
                    description: _return.quantity + ' ' +
                        (_return.quantity === 1 ? this.language.product :
                            ([2, 3, 4].includes(_return.quantity) ? this.language.products : this.language.products2)) +
                        ' ' + this.language.value + ' ' + this.$filters.currency(_return.value),
                    quantity: _return.quantity,
                    value: _return.value,
                    type: 2
                })

                Vue.set(_return, 'added', true)

                this.shipment.suggested_value += parseFloat(_return.value)

                this.$emit('update-item')
            },
            addTask(task) {
                if(task.added) return

                this.items.push({
                    id: task.id,
                    name: this.language.task + ' #' + task.id,
                    description: task.patient.firstName + ' ' + task.patient.lastName +
                        ' (' + task.framePartName + ', ' +
                        (task.rlPartName === task.llPartName ? task.rlPartName : (task.llPartName + ', ' + task.rlPartName)) + ')',
                    quantity: 1,
                    value: task.pricing,
                    type: 4,
                    status: task.status
                })

                Vue.set(task, 'added', true)

                this.shipment.suggested_value += parseFloat(task.pricing)

                this.$emit('update-item')
            },
            addCustomItem() {
                this.items.push({
                    id: 0,
                    name: this.customItem.name,
                    description: this.customItem.description,
                    quantity: this.customItem.quantity,
                    value: this.customItem.val,
                    type: -1
                })

                this.shipment.suggested_value += parseFloat(this.customItem.val)

                this.customItem = {
                    name: '',
                    description: '',
                    quantity: 0,
                    val: 0
                }

                this.$emit('update-item')
            }
        }

    }
</script>