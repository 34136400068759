<template lang="pug">
	.row
		.col-xs-12
			OptiDimmer(:active="loading")
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogPricesConfirm',
        components: {
            OptiDimmer
        },
        data() {
            return {
                loading: true
            }
        },
        mounted() {
            if(confirm(this.$filters.translate('catalog.lenses.LENSES_PRICE_RAPORT.MESSAGE'))) {
                let path = 'hoya_prices/aktualne_ceny_' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name:  path,
                        status: true
                    }
                })

                API.post('catalog/hoya/getLensPrices', {
                    path: path
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.lenses.ERROR')
                })
            }

            this.$state.previous()
        }
    }
</script>