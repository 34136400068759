<template lang="pug">
	.row
		.col-md-offset-2.col-md-8(v-if="!loading")
			form.form-horizontal.form-validate(name="formDoctor" @submit.prevent="editDoctor")
				.form-group
					label.col-sm-4.control-label.star-required(for="first_name") {{'dictionary.doctor.table.FIRSTNAME'|translate}}
					.col-sm-8
						input#first_name.form-control(:placeholder="'dictionary.doctor.table.FIRSTNAME'|translate"
							v-model="doctor.first_name" name="first_name")
						OptiValidate(f="first_name" :data="$v.doctor.first_name")
				.form-group
					label.col-sm-4.control-label.star-required(for="last_name") {{'dictionary.doctor.table.LASTNAME'|translate}}
					.col-sm-8
						input#last_name.form-control(:placeholder="'dictionary.doctor.table.LASTNAME'|translate"
							v-model="doctor.last_name" name="last_name")
						OptiValidate(f="last_name" :data="$v.doctor.last_name")
				.form-group
					label.col-sm-4.control-label(for="type" v-model="doctor.type") {{'dictionary.doctor.table.TYPE'|translate}}
					.col-sm-8
						OptiSelectEnum#type(enum="DoctorType"
							:value="doctor.type" @update-enum="v => doctor.type = v")
				.form-group
					label.col-sm-4.control-label(for="pwz" style="cursor:help" :title="'dictionary.doctor.title.PWZ'|translate"
					) {{'dictionary.doctor.table.PWZ'|translate}}
					.col-sm-8
						input#pwz.form-control(:placeholder="'dictionary.doctor.table.PWZ'|translate"
							v-model="doctor.pwz" name="pwz")
				.form-group
					label.col-sm-4.control-label(for="cellphonenumber") {{'dictionary.doctor.table.PHONE'|translate}}
					.col-sm-8
						OptiCellPhoneInput#cellphonenumber(:placeholder="'dictionary.doctor.table.PHONE'|translate"
							:country="doctor.cellPhoneCountry"
							:phone="doctor.cellphonenumber"

							@country="c => doctor.cellPhoneCountry = c"
							@phone="p => doctor.cellphonenumber = p")
						OptiValidate(f="cellphonenumber" :data="$v.doctor.cellphonenumber")
				.form-group(style="padding-top:30px")
					.col-sm-8.col-sm-offset-4
						span.fa.fa-home
						span {{'dictionary.doctor.table.ADDRESS'|translate}}
				.form-group
					label.col-sm-4.control-label(for="address") {{'dictionary.doctor.table.STREET'|translate}}
					.col-sm-8
						input#address.form-control(:placeholder="'dictionary.doctor.table.STREET'|translate"
							v-model="doctor.address" name="address")
				.form-group
					label.col-sm-4.control-label(for="zip_code") {{'dictionary.doctor.table.ZIPCODE'|translate}}
					.col-sm-8
						input#zip_code.form-control(:placeholder="'dictionary.doctor.table.ZIPCODE'|translate"
							v-model="doctor.zip_code" name="zip_code")
				.form-group
					label.col-sm-4.control-label(for="city") {{'dictionary.doctor.table.CITY'|translate}}
					.col-sm-8
						OptiSelectCity(
							:city="doctor.city"
							@selected="c => doctor.city = c")
				.form-group(style="padding-top:30px")
					label.col-sm-4.control-label(for="comments") {{'dictionary.doctor.table.COMMENTS'|translate}}
					.col-sm-8
						textarea#comments.form-control(v-model="doctor.comments" name="comments")
				.form-group
					.col-xs-12
						OptiButton(type="danger" @click.native="$state.go('app.dictionary.doctors.table')"
							) {{'default.BACK'|translate}}
							template(#icon)
								i.fa.fa-backward
						OptiButtonSave.pull-right(:disabled="$v.doctor.$invalid")

		.col-md-offset-2.col-md-8
			OptiDimmer(:active="loading")
</template>
<script>
	import {API} from '../../../api'
	import {minLength, required} from 'vuelidate/lib/validators'

	import OptiValidate from '../../OptiValidate'
	import OptiDimmer from '../../Blocks/OptiDimmer'
	import OptiButtonSave from '../../Button/OptiButtonSave'
	import OptiButton from '../../Button/OptiButton'
	import OptiSelectCity from '../../Select/OptiSelectCity'
	import OptiCellPhoneInput from '../../Inputs/OptiCellPhoneInput'
	import OptiSelectEnum from '../../Select/OptiSelectEnum'

	export default {
		name: "OptiDictionaryDoctorEdit",
		components: {
			OptiValidate,
			OptiDimmer,
			OptiButtonSave,
			OptiButton,
			OptiSelectCity,
			OptiCellPhoneInput,
			OptiSelectEnum
		},
		data() {
			return {
				doctor: {},
				loading: true
			}
		},
		validations: {
			doctor: {
				first_name: {
					required
				},
				last_name: {
					required
				},
				cellphonenumber: {
					minLength: minLength(8)
				}
			}
		},
		mounted() {
			API.get('dictionary/doctor/action/' + this.$state.params.id).then(res => {
				this.doctor = res.data
			}).finally(() => {
				this.loading = false
			}).catch(() => {
				this.$notify.error('dictionary.doctor.notify.error.LOAD')
			})
		},
		methods: {
			editDoctor() {
				this.loading = true

				API.put('dictionary/doctor/action', this.doctor).then(() => {
					this.$notify.success('dictionary.doctor.notify.EDIT')

					this.$state.go('app.dictionary.doctors.table')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('dictionary.doctor.notify.error.EDIT')
				})
			}
		}
	}
</script>