<template lang="pug">
    .editor
        .panel.panel-default
            .panel-body
                .schedule-day-painter
                    OptiDimmer(:active="loading")
                    .overflower.text-left(v-if="!loading && isFuture && select_optometrists.length")
                        .col-sm-6
                            .row.bg-hover(style="padding: 10px 0")
                                .col-xs-12
                                    label(:for="'patient_id' + officeId") {{'patient.schedule.patients.PATIENT'|translate}}
                                .col-xs-10
                                    OptiSelectPatient(
                                        :id="'patient_id' + officeId"
                                        :init-result="patient"
                                        @selected="selectedPatient")
                                .col-xs-2
                                    .btn.btn-danger(:disabled="!patient.id" @click="deletePatient")
                                        i.fa.fa-trash
                            .row
                                .col-xs-12
                                    label(:for="'first_name' + officeId")
                                        span {{'patient.schedule.patients.FIRSTNAME'|translate}}
                                        small.warn(style="padding-left:3px" v-show="!patient.id && !patient_first_name") {{'patient.schedule.notify.REQUIRED'|translate}}
                                .col-xs-12
                                    input.form-control(:id="'first_name' + officeId" name="patient_first_name"
                                        v-model="patient_first_name" :disabled="patient.id")
                            .row.bg-hover(style="padding: 10px 0")
                                .col-xs-12
                                    label(:for="'last_name' + officeId")
                                        span {{'patient.schedule.patients.LASTNAME'|translate}}
                                        small.warn(style="padding-left:3px" v-show="!patient.id && !patient_last_name") {{'patient.schedule.notify.REQUIRED'|translate}}
                                .col-xs-12
                                    input.form-control(:id="'last_name' + officeId" name="patient_last_name"
                                        v-model="patient_last_name" :disabled="patient.id")
                            .row
                                .col-xs-12
                                    label(:for="'phone' + officeId")
                                        span {{'patient.schedule.patients.PHONE'|translate}}
                                        small.warn(style="padding-left:3px" v-show="!patient.id && !patient_phone") {{'patient.schedule.notify.REQUIRED'|translate}}
                                .col-xs-12
                                    input.form-control(:id="'phone' + officeId" name="patient_phone"
                                        v-model="patient_phone" :disabled="patient.id")

                        .col-sm-6
                            .row.bg-hover(style="padding: 10px 0")
                                .col-xs-12
                                    label(:for="'optometrist' + officeId") {{'patient.schedule.patients.OPTOMETRIST'|translate}}
                                .col-xs-12
                                    select.form-control(:id="'optometrist' + officeId" model="optometrist" v-model="optometrist")
                                        option(v-for="optometrist in select_optometrists" :value="optometrist.id"
                                            :key="optometrist.id") {{optometrist.name}}
                            .row
                                div(:class="{'col-xs-12': type !== 1, 'col-xs-6': type === 1}")
                                    label(for="type") {{'patient.schedule.patients.TYPE'|translate}}
                                .col-xs-6(v-show="type === 1")
                                    label(for="contact_lens") {{'patient.schedule.patients.CONTROLVISIT'|translate}}
                                div(:class="{'col-xs-12': type !== 1, 'col-xs-6': type === 1}")
                                    OptiSelectEnum#type(enum="PatientWaitingType" :value="type" @update-enum="updateType"
                                        :disabled="!!(!visit.id && $state.params.contact_lens)")
                                .col-xs-6(v-show="type === 1")
                                    select.form-control#contact_lens(v-model="control_visit_id"
                                        :disabled="!!(!visit.id && $state.params.contact_lens)")
                                        option(v-for="control_visit_id in control_visit_interviews") {{control_visit_id}}

                            .row.bg-hover(style="padding:11px 0")
                                .col-xs-12
                                    label {{'patient.schedule.patients.DATE'|translate}}
                                .col-xs-12(style="display:flex;justify-content:space-between")
                                    .btn.btn-default(:disabled="disabled.time.prev" @click="goTimePrev")
                                        i.fa.fa-arrow-left
                                    div(style="padding-top:6px") {{date}}
                                    .btn.btn-default(:disabled="disabled.time.next" @click="goTimeNext")
                                        i.fa.fa-arrow-right
                            .row
                                .col-xs-12
                                    label(for="type") {{'patient.schedule.patients.TIME'|translate}}

                                .col-xs-12.btn-group(style="display:flex;justify-content:space-between" v-show="!officeType")
                                    label.btn.btn-default(style="border-radius:3px" :for="'to' + officeId + '30'"
                                        :class="{'btn-purple active': time == 30}") 30min
                                    input.hidden(:id="'to' + officeId + '30'" type="radio" v-model="time"
                                        value="30" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'to' + officeId + '60'"
                                        :class="{'btn-purple active': time == 60}" :disabled="visit.space < 1") 1h
                                    input.hidden(:id="'to' + officeId + '60'" type="radio" v-model="time"
                                        value="60" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'to' + officeId + '90'"
                                        :class="{'btn-purple active': time == 90}" :disabled="visit.space < 2") 1,5h
                                    input.hidden(:id="'to' + officeId + '90'" type="radio" v-model="time"
                                        value="90" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'to' + officeId + '120'"
                                        :class="{'btn-purple active': time == 120}" :disabled="visit.space < 3") 2h
                                    input.hidden(:id="'to' + officeId + '120'" type="radio" v-model="time"
                                        value="120" @change="parseDate")

                                .col-xs-12.btn-group(style="display:flex;justify-content:space-between" v-show="officeType")
                                    label.btn.btn-default(style="border-radius:3px" :for="'tm' + officeId + '15'"
                                        :class="{'btn-purple active': time == 15}") 15min
                                    input.hidden(:id="'tm' + officeId + '15'" type="radio" v-model="time"
                                        value="15" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'tm' + officeId + '30'"
                                        :class="{'btn-purple active': time == 30}" :disabled="visit.space < 1") 30min
                                    input.hidden(:id="'tm' + officeId + '30'" type="radio" v-model="time"
                                        value="30" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'tm' + officeId + '45'"
                                        :class="{'btn-purple active': time == 45}" :disabled="visit.space < 2") 45min
                                    input.hidden(:id="'tm' + officeId + '45'" type="radio" v-model="time"
                                        value="45" @change="parseDate")

                                    label.btn.btn-default(style="border-radius:3px" :for="'tm' + officeId + '60'"
                                        :class="{'btn-purple active': time == 60}" :disabled="visit.space < 3") 1h
                                    input.hidden(:id="'tm' + officeId + '60'" type="radio" v-model="time"
                                        value="60" @change="parseDate")

                        .col-xs-12
                            .row.bg-hover(style="padding-top:10px")
                                .col-xs-12
                                    label(:for="'comments' + officeId") {{'patient.schedule.patients.COMMENTS'|translate}}
                                .col-xs-12
                                    textarea.form-control(:id="'comments' + officeId" rows="3" model="comments" v-model="comments")

                    .overflower(v-if="!loading && !isFuture")
                        .row
                            .col-xs-12
                                i.fa.fa-info-circle(style="padding-right:3px") <!-- później do usunięcia ten padding -->
                                span {{'patient.schedule.notify.NONE'|translate}}

                    .overflower(v-if="!loading && isFuture && !select_optometrists.length")
                        .row
                            .col-xs-12
                                i.fa.fa-info-circle(style="padding-right:3px") <!-- później do usunięcia ten padding -->
                                span {{'patient.schedule.notify.EMPTY'|translate}}

            .panel-footer.text-right
                OptiButton.pull-left(style="margin-right:30px" v-show="visit.id && isFuture"
                    text="patient.schedule.cut" @confirmed="onCut") {{'patient.schedule.cut.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-cut

                OptiButton.pull-left(style="margin-right:30px" v-show="!visit.id && checkClipboard() && isFuture"
                    text="patient.schedule.paste" @confirmed="onPaste") {{'patient.schedule.paste.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-paste

                OptiButton(style="margin-right:30px" type="success" v-show="select_optometrists.length && isFuture"
                    :disabled="disabledSave()" @click.native="save") {{'patient.schedule.patients.actions.SAVE'|translate}}
                    template(#icon)
                        i.fa.fa-save

                OptiButton(type="danger" style="margin-right:30px" v-show="select_optometrists.length && isFuture && visit.id"
                    text="patient.schedule.patients.actions.delete" @confirmed="goToDelete"
                    ) {{'patient.schedule.patients.actions.delete.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-remove

                OptiButton(@click.native="close") {{'patient.schedule.patients.actions.CANCEL'|translate}}
                    template(#icon)
                        i.fa.fa-remove
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiSelectEnum from '../../../Select/OptiSelectEnum'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiSelectPatient from '@/js/app/vue/components/Select/OptiSelectPatient'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    const CLIPBOARD = 'schedule-patients-clipboard'

    export default {
        name: 'OptiPatientsScheduleDayEditor',
        components: {
            OptiDimmer,
            OptiSelectEnum,
            OptiPatient,
            OptiSelectPatient,
            OptiButton
        },
        props: {
            officeId: {
                type: Number,
                required: true
            },
            officeType: {
                type: Number,
                required: true
            },
            optometrists: {
                type: Array,
                required: true
            },
            visit: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                patient: {},
                patient_first_name: '',
                patient_last_name: '',
                patient_phone: '',

                select_optometrists: [],
                optometrist: 0,
                type: 0,
                comments: '',
                date: '',
                time: this.officeType ? 15 : 30,

                control_visit_interviews: [],
                control_visit_id: this.$state.params.contact_lens ? this.$state.params.contact_lens : 0,

                disabled: {
                    time: {
                        prev: this.disabledTimePrev(),
                        next: this.disabledTimeNext()
                    }
                },

                isFuture: false,

                loading: false
            }
        },
        watch: {
            visit() {
                this.updateListOptometrists()
                this.time = this.officeType ? 15 : 30
                this.parseDate()

                if(!this.visit.id && this.$state.params.patient) {
                    this.selectedPatient(Object.assign({}, this.$state.params.patient))

                    if(this.$state.params.contact_lens) {
                        this.type = 1
                    }
                }

                this.disabled.time.prev = this.disabledTimePrev()
                this.disabled.time.next = this.disabledTimeNext()

                this.markLoading()
            }
        },
        mounted() {
            this.updateListOptometrists()

            if(this.visit.id) {
                this.parseDataToEdit(this.visit)
            } else {
                if(this.$state.params.patient) {
                    this.selectedPatient(Object.assign({}, this.$state.params.patient))
                } else if(this.$state.params.doctor && this.officeType === 1) {
                    this.selectedPatient(Object.assign({}, this.$state.params.doctor.patient))

                    this.comments = 'Odesłanie do specjalisty z wywiadu <a class="link" href="#/app/optometrist/details/' +
                        this.$state.params.doctor.interview_id + '#interview">' + this.$state.params.doctor.interview_id + '</a>\n' +
                        (this.$state.params.doctor.comments ? this.$state.params.doctor.comments + '\n\n' : '') +
                        'Powód\n' + this.$state.params.doctor.reason
                }

                if(this.$state.params.contact_lens) {
                    this.type = 1
                }

                this.parseDate()
            }

            this.disabled.time.prev = this.disabledTimePrev()
            this.disabled.time.next = this.disabledTimeNext()

            window.addEventListener('keydown', this.keyPress)

            this.updateVisitControlInterviews()

            this.markLoading()
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyPress)
        },
        methods: {
            parseDataToEdit(visit, mode) {
                if(typeof visit.patient.id !== 'undefined') {
                    this.selectedPatient(Object.assign({}, visit.patient))
                } else {
                    this.patient = {}
                    this.patient_first_name = visit.patient_first_name
                    this.patient_last_name = visit.patient_last_name
                    this.patient_phone = visit.patient_phone
                }

                this.optometrist = visit.user.id
                this.type = visit.type
                this.comments = visit.comments

                let start = moment(visit.start),
                    end = moment(visit.end)

                this.isFuture = start.isSameOrAfter(moment(), 'd')

                this.date = start.format('YYYY-MM-DD HH:mm') + ' - ' + end.format('HH:mm')
                this.time = moment.duration(end.diff(start)).asMinutes()

                if(mode) {
                    let start = moment(this.visit.start),
                        end = start.clone().add(this.time, 'm')

                    this.isFuture = start.isSameOrAfter(moment(), 'd')

                    this.date = start.format('YYYY-MM-DD HH:mm') + ' - ' + end.format('HH:mm')
                }
            },
            updateListOptometrists() {
                this.select_optometrists = []

                this.optometrists.forEach(optometrist => {
                    optometrist.data.forEach(part => {
                        if(typeof part.type !== 'undefined' && moment(this.visit.start).isBetween(part.start, part.end, null, '[)')) {
                            this.select_optometrists.push({
                                id: optometrist.id,
                                name: optometrist.name + (optometrist.department ? ' (' + optometrist.department + ')' : '')
                            })
                        }
                    })
                })

                if(this.select_optometrists.length === 1) {
                    this.optometrist = this.select_optometrists[0].id
                }
            },
            parseDate() {
                let start = moment(this.visit.start)

                this.isFuture = start.isSameOrAfter(moment(), 'd')

                this.date = start.format('YYYY-MM-DD HH:mm') + ' - ' + start.add(this.time, 'minutes').format('HH:mm')
            },
            markLoading() {
                this.loading = true

                setTimeout(() => {
                    this.loading = false
                }, 300)
            },

            updateVisitControlInterviews() {
                if(!_.isEmpty(this.patient) && this.type === 1) {
                    API.get('patients/schedule/control-visit-interviews/' + this.patient.id).then(res => {
                        this.control_visit_interviews = res.data
                    }).catch(() => {
                        this.$notify.error('patient.schedule.patients.notify.error.CONTROLVISIT')
                    })
                } else {
                    this.control_visit_interviews = []
                }
            },
            selectedPatient(patient) {
                this.patient = patient

                this.patient_first_name = patient.firstName
                this.patient_last_name = patient.lastName
                this.patient_phone = patient.cellPhoneNumber ? this.$filters.number(patient.cellPhoneNumber, 0) : ''

                this.updateVisitControlInterviews()
            },
            deletePatient() {
                this.patient = {}

                this.patient_first_name = ''
                this.patient_last_name = ''
                this.patient_phone = ''

                this.markLoading()
            },
            disabledSave() {
                return !this.optometrist ||  (typeof this.patient.id === 'undefined' &&
                    !(this.patient_first_name && this.patient_last_name && this.patient_phone))
            },
            save(mode) {
                this.loading = true

                API.post('patients/schedule/calendar/add', {
                    id: this.visit.id,
                    patient_id: typeof this.patient.id === 'undefined' ? 0 : this.patient.id,
                    patient_first_name: this.patient_first_name,
                    patient_last_name: this.patient_last_name,
                    patient_phone: this.patient_phone,

                    office_id: this.officeId,

                    date_from: this.visit.start,
                    time: this.time,

                    type: this.type,
                    comments: this.comments,
                    optometrist: this.optometrist,
                    interview_id: this.control_visit_id,
                    ref_interview_id: this.$state.params.doctor ? this.$state.params.doctor.interview_id : 0
                }).then(res => {
                    this.$emit('update-visits', res.data)

                    if(mode) {
                        this.$storage.remove(CLIPBOARD)

                        this.$notify.success('patient.schedule.paste.PASTED')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.SAVE')
                })
            },
            goToDelete() {
                this.loading = true

                API.post('patients/schedule/calendar/delete', {
                    id: this.visit.id,
                }).then(res => {
                    this.$emit('update-visits', res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.DELETE')
                })
            },
            close() {
                this.$emit('closed')
            },

            checkPrevNextOptometrists(date) {
                let exists = false

                this.optometrists.forEach(optometrist => {
                    for(let i = 0; i < optometrist.data.length; ++i) {
                        let part = optometrist.data[i]

                        if(typeof part.type !== 'undefined' && date.isBetween(part.start, part.end, null, '[)')) {
                            exists = true

                            break
                        }
                    }
                })

                return exists
            },
            disabledTimePrev() {
                let date = moment(this.visit.start)

                if(date.hours() === 6 && !date.minutes()) {
                    return true
                }

                return !this.checkPrevNextOptometrists(date.add((this.officeType ? 15 : 30) * -1, 'm'))
            },
            disabledTimeNext() {
                let date = moment(this.visit.end)

                if(!date.hours() && !date.minutes()) {
                    return true
                }

                return !this.checkPrevNextOptometrists(date)
            },
            goTimePrev() {
                this.$emit('navi-time', -1)
            },
            goTimeNext() {
                this.$emit('navi-time', 1)
            },
            keyPress(event) {
                if(event.key === 'ArrowLeft' && !this.disabledTimePrev()) {
                    this.goTimePrev()
                } else if(event.key === 'ArrowRight' && !this.disabledTimeNext()) {
                    this.goTimeNext()
                }
            },
            updateType(type) {
                this.type = type

                this.updateVisitControlInterviews()
            },

            checkClipboard() {
                if(this.select_optometrists.length &&  this.$storage.has(CLIPBOARD)) {
                    let visit = this.$storage.get(CLIPBOARD),
                        start = moment(visit.start),
                        end = moment(visit.end),
                        space = end.diff(start, 'minutes') / 30

                    if(this.visit.space + 1 >= space) {
                        return true
                    }
                }

                return false
            },
            onCut() {
                this.$storage.set(CLIPBOARD, _.cloneDeep(this.visit))

                this.loading = true

                API.post('patients/schedule/calendar/delete', {
                    id: this.visit.id,
                }).then(res => {
                    this.$emit('update-visits', res.data)

                    this.$notify.success('patient.schedule.cut.CUT')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.schedule.notify.error.DELETE')
                })
            },
            checkOptometrist() {
                let _return = false

                this.select_optometrists.forEach(optometrist => {
                    if(optometrist.id === this.optometrist) {
                        _return = true
                    }
                })

                return _return
            },
            onPaste() {
                this.parseDataToEdit(this.$storage.get(CLIPBOARD), true)

                if(this.checkOptometrist()) {
                    this.save(true)
                } else {
                    this.optometrist = null

                    this.markLoading()

                    this.$notify.warning('patient.schedule.paste.OPTOMETRIST')
                }
            }
        }
    }
</script>