<template lang="pug">
    .panel.panel-primary
        .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
            i.fa.fa-navicon.mr3
            span {{'optometrist.autorefraktometr.AUTOREFRAKTOMETR'|translate}}
            .pull-right
                i.fa(:class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")
        .row
            .col-xs-12
                .row(v-show="toggle")
                    .col-xs-12(style="padding:10px 0 0 30px")
                        i.fa.fa-file-text.mr3
                        span {{'optometrist.autorefraktometr.KERATOMETRY'|translate}}
                .row(v-show="toggle")
                    .col-md-6
                        .table-responsive
                            table.table.table-striped.text-center.middle
                                tbody
                                    tr
                                        th {{'prescription.RIGHT'|translate}}
                                        th mm
                                        th D
                                        th AXS
                                    tr
                                        th
                                            span R
                                            sub 1
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_r_r1_mm === -999}"
                                                :header="'R1 mm (' + $filters.translate('prescription.RIGHT') + ')'" :float="true" :none="-999"
                                                :value="autorefraktometr.k_r_r1_mm" @confirmed="v => updateKeratometry('k_r_r1_mm', v)"
                                                :disabled="disabled") {{autorefraktometr.k_r_r1_mm|number(2)|none(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_r_r1_d === -999}"
                                                :header="'R1 D (' + $filters.translate('prescription.RIGHT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_r_r1_d" @confirmed="v => updateKeratometry('k_r_r1_d', v)"
                                                :disabled="disabled") {{autorefraktometr.k_r_r1_d|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_r_r1_axs === 'brak'}"
                                                :header="'R1 ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                                                :value="autorefraktometr.k_r_r1_axs" @confirmed="v => updateKeratometry('k_r_r1_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_r_r1_axs}}
                                    tr
                                        th
                                            span R
                                            sub 2
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_r_r2_mm === -999}"
                                                :header="'R2 mm (' + $filters.translate('prescription.RIGHT') + ')'" :float="true" :none="-999"
                                                :value="autorefraktometr.k_r_r2_mm" @confirmed="v => updateKeratometry('k_r_r2_mm', v)"
                                                :disabled="disabled") {{autorefraktometr.k_r_r2_mm|number(2)|none(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_r_r2_d === -999}"
                                                :header="'R2 D (' + $filters.translate('prescription.RIGHT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_r_r2_d" @confirmed="v => updateKeratometry('k_r_r2_d', v)"
                                                :disabled="disabled") {{autorefraktometr.k_r_r2_d|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_r_r2_axs === 'brak'}"
                                                :header="'R2 ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                                                :value="autorefraktometr.k_r_r2_axs" @confirmed="v => updateKeratometry('k_r_r2_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_r_r2_axs}}
                                    tr
                                        th AVE
                                        td
                                            span.form-control(:class="{'its-error': avg(autorefraktometr.k_r_r1_mm, autorefraktometr.k_r_r2_mm) === -999}"
                                                disabled) {{avg(autorefraktometr.k_r_r1_mm, autorefraktometr.k_r_r2_mm)|number(2)|none(-999)}}
                                        td
                                            span.form-control(:class="{'its-error': avg_cyl(autorefraktometr.k_r_r1_d, autorefraktometr.k_r_r2_d) === -999}"
                                                disabled) {{avg_cyl(autorefraktometr.k_r_r1_d, autorefraktometr.k_r_r2_d)|plus(-999)}}
                                        td
                                    tr
                                        th CYL
                                        td
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_r_cyl === -999}"
                                                :header="$filters.translate('prescription.CYLINDER') + ' D (' + $filters.translate('prescription.RIGHT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_r_cyl" @confirmed="v => updateKeratometry('k_r_cyl', v)"
                                                :disabled="disabled") {{autorefraktometr.k_r_cyl|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_r_axs === 'brak'}"
                                                v-show="autorefraktometr.k_r_cyl && autorefraktometr.k_r_cyl !== -999"
                                                :header="$filters.translate('prescription.CYLINDER') + ' ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                                                :value="autorefraktometr.k_r_axs" @confirmed="v => updateKeratometry('k_r_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_r_axs}}

                    .col-md-6
                        .table-responsive
                            table.table.table-striped.text-center.middle
                                tbody
                                    tr
                                        th {{'prescription.LEFT'|translate}}
                                        th mm
                                        th D
                                        th AXS
                                    tr
                                        th
                                            span R
                                            sub 1
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_l_r1_mm === -999}"
                                                :header="'R1 mm (' + $filters.translate('prescription.LEFT') + ')'" :float="true" :none="-999"
                                                :value="autorefraktometr.k_l_r1_mm" @confirmed="v => updateKeratometry('k_l_r1_mm', v)"
                                                :disabled="disabled") {{autorefraktometr.k_l_r1_mm|number(2)|none(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_l_r1_d === -999}"
                                                :header="'R1 D (' + $filters.translate('prescription.LEFT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_l_r1_d" @confirmed="v => updateKeratometry('k_l_r1_d', v)"
                                                :disabled="disabled") {{autorefraktometr.k_l_r1_d|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_l_r1_axs === 'brak'}"
                                                :header="'R1 ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                                                :value="autorefraktometr.k_l_r1_axs" @confirmed="v => updateKeratometry('k_l_r1_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_l_r1_axs}}
                                    tr
                                        th
                                            span R
                                            sub 2
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_l_r2_mm === -999}"
                                                :header="'R2 mm (' + $filters.translate('prescription.LEFT') + ')'" :float="true" :none="-999"
                                                :value="autorefraktometr.k_l_r2_mm" @confirmed="v => updateKeratometry('k_l_r2_mm', v)"
                                                :disabled="disabled") {{autorefraktometr.k_l_r2_mm|number(2)|none(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_l_r2_d === -999}"
                                                :header="'R2 D (' + $filters.translate('prescription.LEFT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_l_r2_d" @confirmed="v => updateKeratometry('k_l_r2_d', v)"
                                                :disabled="disabled") {{autorefraktometr.k_l_r2_d|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_l_r2_axs === 'brak'}"
                                                :header="'R2 ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                                                :value="autorefraktometr.k_l_r2_axs" @confirmed="v => updateKeratometry('k_l_r2_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_l_r2_axs}}
                                    tr
                                        th AVE
                                        td
                                            span.form-control(:class="{'its-error': avg(autorefraktometr.k_l_r1_mm, autorefraktometr.k_l_r2_mm) === -999}"
                                                disabled) {{avg(autorefraktometr.k_l_r1_mm, autorefraktometr.k_l_r2_mm)|number(2)|none(-999)}}
                                        td
                                            span.form-control(:class="{'its-error': avg_cyl(autorefraktometr.k_l_r1_d, autorefraktometr.k_l_r2_d) === -999}"
                                                disabled) {{avg_cyl(autorefraktometr.k_l_r1_d, autorefraktometr.k_l_r2_d)|plus(-999)}}
                                        td
                                    tr
                                        th CYL
                                        td
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.k_l_cyl === -999}"
                                                :header="$filters.translate('prescription.CYLINDER') + ' D (' + $filters.translate('prescription.LEFT') + ')'" :none="-999"
                                                :value="autorefraktometr.k_l_cyl" @confirmed="v => updateKeratometry('k_l_cyl', v)"
                                                :disabled="disabled") {{autorefraktometr.k_l_cyl|plus(-999)}}
                                        td
                                            OptiNumber(:class="{'its-error': autorefraktometr.k_l_axs === 'brak'}"
                                                v-show="autorefraktometr.k_l_cyl && autorefraktometr.k_l_cyl !== -999"
                                                :header="$filters.translate('prescription.CYLINDER') + ' ' + $filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                                                :value="autorefraktometr.k_l_axs" @confirmed="v => updateKeratometry('k_l_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.k_l_axs}}

        .row
            .col-md-6
                .row
                    .col-xs-12(style="padding:10px 0 5px 30px")
                        i.fa.fa-file-text.mr3
                        span {{'optometrist.breadcrumb.section.REFRACTION'|translate}}
                        button.btn.btn-info(style="padding:2px 10px;margin:0 0 2px 7px"
                            type="button" :class="{'btn-warning active': help}" @click="help = !help") {{'default.HELP'|translate}}
                    .col-xs-12(v-show="help")
                        OptiOptometristsTips(style="padding-left:15px" :data="tips")
                    .col-xs-12
                        .table-responsive
                            table.table.table-striped.text-center.middle
                                tbody
                                    tr
                                        th
                                        th SPH
                                        th CYL
                                        th(v-show="refractionAxisExists") AXS
                                        th ES
                                        th PD
                                    tr
                                        th {{'prescription.RIGHT'|translate}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.r_r_sph === -999}"
                                                :header="$filters.translate('prescription.SPHERE') + ' (' + $filters.translate('prescription.RIGHT') + ')'" :none="-999"
                                                :value="autorefraktometr.r_r_sph" @confirmed="v => updateRefraction('r_r_sph', v)"
                                                :disabled="disabled") {{autorefraktometr.r_r_sph|plus(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.r_r_cyl === -999}"
                                                :header="$filters.translate('prescription.CYLINDER') + ' (' + $filters.translate('prescription.RIGHT') + ')'" :none="-999"
                                                :value="autorefraktometr.r_r_cyl" @confirmed="v => updateRefraction('r_r_cyl', v)"
                                                :disabled="disabled") {{autorefraktometr.r_r_cyl|plus(-999)}}
                                        td(v-show="refractionAxisExists")
                                            OptiNumber(:class="{'its-error': autorefraktometr.r_r_axs === 'brak'}"
                                                v-show="autorefraktometr.r_r_cyl && autorefraktometr.r_r_cyl !== -999"
                                                :header="$filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.RIGHT') + ')'"
                                                :value="autorefraktometr.r_r_axs" @confirmed="v => updateRefraction('r_r_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.r_r_axs}}
                                        td
                                            span.form-control(:class="{'its-error': autorefraktometr.r_r_es === -999}"
                                                disabled) {{autorefraktometr.r_r_es|plus(-999)}}
                                        td(rowspan="2")
                                            OptiNumber(:class="{'its-error': autorefraktometr.r_pd === -999}"
                                                header="PD" :float="true" :none="-999"
                                                :value="autorefraktometr.r_pd" @confirmed="v => updateRefraction('r_pd', v)"
                                                :max="99" :disabled="disabled") {{autorefraktometr.r_pd|number(2)|none(-999)}}
                                    tr
                                        th {{'prescription.LEFT'|translate}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.r_l_sph === -999}"
                                                :header="$filters.translate('prescription.SPHERE') + ' (' + $filters.translate('prescription.LEFT') + ')'" :none="-999"
                                                :value="autorefraktometr.r_l_sph" @confirmed="v => updateRefraction('r_l_sph', v)"
                                                :disabled="disabled") {{autorefraktometr.r_l_sph|plus(-999)}}
                                        td
                                            OptiNumberPrescription(:class="{'its-error': autorefraktometr.r_l_cyl === -999}"
                                                :header="$filters.translate('prescription.CYLINDER') + ' (' + $filters.translate('prescription.LEFT') + ')'" :none="-999"
                                                :value="autorefraktometr.r_l_cyl" @confirmed="v => updateRefraction('r_l_cyl', v)"
                                                :disabled="disabled") {{autorefraktometr.r_l_cyl|plus(-999)}}
                                        td(v-show="refractionAxisExists")
                                            OptiNumber(:class="{'its-error': autorefraktometr.r_l_axs === 'brak'}"
                                                v-show="autorefraktometr.r_l_cyl && autorefraktometr.r_l_cyl !== -999"
                                                :header="$filters.translate('prescription.AXIS') + ' (' + $filters.translate('prescription.LEFT') + ')'"
                                                :value="autorefraktometr.r_l_axs" @confirmed="v => updateRefraction('r_l_axs', v)"
                                                :max="180" :disabled="disabled") {{autorefraktometr.r_l_axs}}
                                        td
                                            span.form-control(:class="{'its-error': autorefraktometr.r_l_es === -999}"
                                                disabled) {{autorefraktometr.r_l_es|plus(-999)}}
            .col-md-6
                .row
                    .col-xs-12(style="padding:10px 0 5px 15px")
                        i.fa.fa-file-text.mr3
                        span {{'optometrist.autorefraktometr.PRINT'|translate}}
                    .col-sm-6(style="padding-bottom:10px")
                        a(v-if="autorefraktometr.image" :title="'optometrist.autorefraktometr.TITLE'|translate"
                            :href="autorefraktometr.image|upload" target="_blank")
                            img.img-rounded(style="width:100%" :src="autorefraktometr.image|upload")
                    .col-sm-6.text-center
                        OptiButton(style="margin-top:5px;margin-bottom:5px" type="danger"
                            v-show="autorefraktometr.image" :disabled="disabled"
                            @click.native="updatePhoto('')") {{'optometrist.disease.photo.REMOVE'|translate}}
                            template(#icon)
                                i.fa.fa-remove
                        div(style="margin-top:5px;margin-bottom:5px")
                            OptiButton(type="info" :disabled="disabled" @click.native="dialog_ocr = true"
                                ) {{'optometrist.autorefraktometr.ocr.TITLE'|translate}}
                                template(#icon)
                                    i.fa.fa-indent

                            MountingPortal(v-if="dialog_ocr" mountTo="#vue-modal" append)
                                OptiOptometristsDetailsRefractionDialogOCR(
                                    :image="autorefraktometr.image"
                                    @data="updateOCR"
                                    @photo="updatePhoto"
                                    @closed="dialog_ocr = false")
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiOptometristsDetailsRefractionDialogOCR
        from '@/js/app/vue/components/Optometrists/Details/Refraction/Dialogs/OptiOptometristsDetailsRefractionDialogOCR'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'

    export default {
        name: 'OptiOptometristsDetailsRefractionAutorefraktometr',
        components: {
            OptiNumberPrescription,
            OptiNumber,
            OptiButton,
            OptiOptometristsDetailsRefractionDialogOCR,
            OptiOptometristsTips
        },
        props: {
            autorefraktometr: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        },
        data() {
            let prefix = 'optometrist.tips.autorefraktometr.'

            return {
                toggle: false,
                help: false,

                dialog_ocr: false,

                tips: {
                    info: [
                        prefix + 'info.' + 1,
                        prefix + 'info.' + 2,
                        prefix + 'info.' + 3,
                    ],
                    instruction: [
                        prefix + 'instruction.' + 1,
                        prefix + 'instruction.' + 2,
                        prefix + 'instruction.' + 3
                    ],
                    procedure: [
                        prefix + 'procedure.' + 1
                    ]
                }
            }
        },
        mounted() {

        },
        methods: {
            avg(val1, val2) {
                if(val1 === -999 || val2 === -999) {
                    return -999
                }

                return (val1 + val2) / 2
            },
            avg_cyl(val1, val2) {
                if(val1 === -999 || val2 === -999) {
                    return -999
                }

                let avg = (val1 + val2) / 2,
                    rest = Math.round((avg % 0.25) * 100) / 100

                if(rest) {
                    return avg + 0.25 - rest
                }

                return avg
            },

            updateKeratometry(col, val) {
                this.autorefraktometr[col] = val

                if(col === 'k_r_cyl' && !this.autorefraktometr[col]) {
                    this.autorefraktometr.k_r_axs = 'brak'
                } else if(col === 'k_l_cyl' && !this.autorefraktometr[col]) {
                    this.autorefraktometr.k_l_axs = 'brak'
                }

                this.$emit('update-autorefraktometr', this.autorefraktometr)
            },
            updateRefractionES(side) {
                let sph = this.autorefraktometr['r_' + side + '_sph'],
                    cyl = this.autorefraktometr['r_' + side + '_cyl']

                if(sph === -999 || cyl === -999) {
                    this.autorefraktometr['r_' + side + '_es'] = -999
                } else if(cyl * 50 % 1 === 0) {
                    this.autorefraktometr['r_' + side + '_es'] = sph + cyl / 2
                } else {
                    this.autorefraktometr['r_' + side + '_es'] = sph + (cyl + 0.25) / 2
                }
            },
            updateRefraction(col, val) {
                this.autorefraktometr[col] = val

                if(col === 'r_r_cyl' && !this.autorefraktometr[col]) {
                    this.autorefraktometr.r_r_axs = 'brak'
                } else if(col === 'r_l_cyl' && !this.autorefraktometr[col]) {
                    this.autorefraktometr.r_l_axs = 'brak'
                }

                if(['r_r_sph', 'r_r_cyl'].includes(col)) {
                    this.updateRefractionES('r')
                } else if(['r_l_sph', 'r_l_cyl'].includes(col)) {
                    this.updateRefractionES('l')
                }

                this.$emit('update-autorefraktometr', this.autorefraktometr)
            },
            updateOCR(data, photo) {
                this.autorefraktometr.r_r_sph = data[0]
                this.autorefraktometr.r_r_cyl = data[1]
                this.autorefraktometr.r_r_axs = data[2]

                this.autorefraktometr.r_l_sph = data[3]
                this.autorefraktometr.r_l_cyl = data[4]
                this.autorefraktometr.r_l_axs = data[5]

                this.autorefraktometr.r_pd = data[6]

                this.updateRefractionES('r')
                this.updateRefractionES('l')

                this.updatePhoto(photo)
            },
            updatePhoto(photo) {
                this.autorefraktometr.image = photo

                this.$emit('update-autorefraktometr', this.autorefraktometr)

                this.dialog_ocr = false
            }
        },
        computed: {
            refractionAxisExists() {
                return (this.autorefraktometr.r_r_cyl && this.autorefraktometr.r_r_cyl !== -999) ||
                    (this.autorefraktometr.r_l_cyl && this.autorefraktometr.r_l_cyl !== -999)
            }
        }
    }
</script>

<style lang="less" scoped>
    span.form-control {
        text-align: center;
    }
</style>