<template lang="pug">
    span.warn(v-show="special") {{special}}
</template>

<script>
    export default {
        name: 'OptiProductSpecialPercent',
        components: {

        },
        props: {
            customerProduct: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {
            special() {
                let cp = this.customerProduct
                if(!cp.specialPrice
                    || (cp.specialPrice && Number(cp.specialPrice.before) === 0.00)
                    || (Number(cp.specialPrice.value) === Number(cp.specialPrice.before))) {
                    return false
                }

                let percent = (parseFloat(cp.specialPrice.value) * 100) / cp.specialPrice.before
                percent = 100 - (Math.round(percent / 10) * 10)

                return '(-' + percent + '%)'
            }
        }
    }
</script>