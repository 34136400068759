<template lang="pug">
    .row
        OptiDimmer(:active="active")
        .col-xs-12
            form.panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'pack.new.ADD'|translate}}
                .panel-body
                    .row
                        .col-sm-4
                            .form-group
                                label {{'pack.table.PACK_NAME'|translate}}
                                input#pack_name1.form-control(v-model="packproduct.pack_name1" name="pack_name1")
                            .form-group
                                label {{'pack.table.DELIVERY_TIME'|translate}}
                                input#delivery_time.form-control(v-model="packproduct.delivery_time" name="delivery_time")
                            .form-group
                                label {{'pack.edit.PRODUCT_ID'|translate}}
                                input#product_id.form-control(v-model="packproduct.product_id" name="product_id")
                            .form-group
                                label {{'pack.new.PRICE_WO'|translate}}
                                input#price_wo.form-control(v-model="packproduct.price_wo" name="price_wo")
                            .form-group
                                label {{'pack.new.LINK'|translate}}
                                input#link.form-control(v-model="packproduct.link" name="link")
                        .col-sm-4
                            .form-group
                                label {{'pack.table.SPHERE'|translate}}
                                select#sphere.form-control(v-model="packproduct.sphere" name="sphere")
                                    option(value=1) 1
                                    option(value=2) 2
                                    option(value=3) 3
                            .form-group
                                label {{'pack.new.PACK'|translate}}
                                input#pack.form-control(v-model="packproduct.pack" name="pack")
                            .form-group
                                label {{'pack.table.PRICE'|translate}}
                                input#price.form-control(v-model="packproduct.price" name="price")
                            .form-group
                                label {{'pack.new.PACK_NAME2'|translate}}
                                input#pack_name2.form-control(v-model="packproduct.pack_name2" name="pack_name2")
                            .form-group
                                label {{'pack.edit.PRIORITY'|translate}}
                                input#priority.form-control(v-model="packproduct.priority" name="priority")
                        .col-sm-4
                            .form-group
                                label {{'pack.new.PRODUCT_NAME'|translate}}
                                input#product_name.form-control(v-model="packproduct.product_name" name="product_name")
                            .form-group
                                label {{'pack.new.PRODUCT_MFCT'|translate}}
                                select#product_mfct.form-control(v-model="packproduct.product_mfct" name="product_mfct")
                                    option(value="Hoya" selected=true) Hoya
                                    option(value="Essilor") Essilor
                                    option(value="jawro") Jawro
                                    option(value="Rodenstock") Rodenstock
                            .form-group
                                label {{'pack.edit.PRODUCT_CODE'|translate}}
                                input#product_code.form-control(v-model="packproduct.product_code" name="product_code")
                            .form-group
                                label {{'pack.new.LOCAL_PROD'|translate}}
                                input#local_prod.form-control(v-model="packproduct.local_prod" name="local_prod")
                            .form-group
                                label {{'pack.table.TYPE'|translate}}
                                select#type.form-control(v-model="packproduct.type" name="type")
                                    option(value="0") Jednoogniskowa
                                    option(value="2") Progresywna
                                    option(value="3") Biurowa
                    .col-xs-12
                        label {{'pack.new.DETAILS2'|translate}}
                    .col-xs-12 
                        .form-group
                            label(v-for="det in details ")
                                img.mr3(style="width:90px;margin-bottom:15px; cursor:pointer"
                                    :src="'img/task/pack/' + det.id + '.png'" @click="det.enabled = !det.enabled"
                                    v-bind:style="[det.enabled ? {opacity:1} : {opacity:0.15}]" v-model="det.id")
                    .col-xs-12
                        label {{'pack.new.SPHERES'|translate}}
                        .panel.panel-primary
                            table.table.table-striped.table-responsive
                                caption {{'pack.new.SPHERES'|translate}}
                                thead
                                    tr
                                        th.text-left #
                                        th.text-left {{'pack.table.ID'|translate}}
                                        th.text-left {{'pack.edit.FI'|translate}}
                                        th.text-left {{'pack.edit.SPHERE_FROM'|translate}}
                                        th.text-left {{'pack.edit.SPHERE_TO'|translate}}
                                        th.text-left {{'pack.edit.CYLINDER_FROM'|translate}}
                                        th.text-left {{'pack.edit.CYLINDER_TO'|translate}}
                                        th.text-left {{'pack.edit.TOP'|translate}}
                                        th.text-left {{'pack.edit.DOWN'|translate}}
                                        th.text-left {{'pack.edit.ADD_FROM'|translate}}
                                        th.text-left {{'pack.edit.ADD_TO'|translate}}
                                        th.text-left {{'pack.edit.ADD_EXCEPT'|translate}}
                                        th
                                tbody
                                    tr(v-for="(sphere, index) in packproduct.packSphere")
                                        td.text-center {{index + 1}}
                                        td.text-left
                                        td
                                            input.form-control(v-model="sphere.fi" name="fi")
                                        td
                                            input.form-control(v-model="sphere.sphere_from" name="sphere_from")
                                        td
                                            input.form-control(v-model="sphere.sphere_to" name="sphere_to")
                                        td
                                            input.form-control(v-model="sphere.cylinder_from" name="cylinder_from")
                                        td
                                            input.form-control(v-model="sphere.cylinder_to" name="cylinder_to")
                                        td
                                            select.form-control(v-model="sphere.top" name="top" style="width:90px")
                                                option(value="0") {{'pack.edit.STRAIGHT'|translate}}
                                                option(value="1") {{'pack.edit.INCLINE'|translate}}
                                        td
                                            select.form-control(v-model="sphere.down" name="down" style="width:90px")
                                                option(value="0") {{'pack.edit.STRAIGHT'|translate}}
                                                option(value="1") {{'pack.edit.INCLINE'|translate}}
                                        td
                                            input.form-control(v-model="sphere.add_from" name="add_from")
                                        td
                                            input.form-control(v-model="sphere.add_to" name="add_to")
                                        td
                                            input.form-control(v-model="sphere.add_except" name="add_except")
                                        td
                                            OptiButton(
                                                type="danger"
                                                @click.native="removeRow(index)"
                                            ) {{'pack.edit.DELETE'|translate}}
                                                template(#icon)
                                                    i.fa.fa-trash
                    .col-xs-12
                        .form-group
                            OptiButton(
                                type="default"
                                style="width:100%"
                                @click.native="addRow()"
                            ) {{'pack.edit.ADD'|translate}}
                                template(#icon)
                                    i.fa.fa-plus
                    .col-xs-12
                        .form-gourp.text-right
                            OptiButton(
                                type="success"
                                :disabled="!packproduct.packSphere.length || !details"
                                @click.native="addPack()"
                            ) {{'pack.edit.SAVE'|translate}}
                                template(#icon)
                                    i.fa.fa-save
                            OptiButton(
                                style="margin-left:3px"
                                type="danger"
                                @click.native="goBack()"
                            ) {{'pack.edit.CANCEL'|translate}}
                                template(#icon)
                                    i.fa.fa-remove
</template>

<script>
import {API} from '@/js/app/vue/api'
import _ from 'lodash'
import OptiButton from "@/js/app/vue/components/Button/OptiButton";
import OptiDialog from "@/js/app/vue/components/OptiDialog";
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";

export default {
    name: "OptiPackAdd",
    components: {OptiDimmer, OptiDialog, OptiButton},
    data() {
        return {
            packproduct: {
                id: 0,
                pack_name1: '',
                type: 0,
                product_id: 0,
                price_wo: 0,
                delivery_time: 0,
                sphere: 1,
                pack: "",
                price: 0,
                pack_name2:"",
                priority: 0,
                product_name: "",
                product_mfct: "Hoya",
                product_code: "",
                local_prod: "",
                link: 0,
                packSphere: []
            },
            details: [],
            active: true
        }
    },
    mounted() {
        API.get('packs/add').then(res => {
            this.$set(this, 'details', _.map(res.data, item => {
                item.enabled = false
                return item
            }))
        }).finally ( () => {
            this.active = false
        })
    },
    methods: {
        addRow() {
            this.packproduct.packSphere.push({
                id: 0,
                fi: 0,
                sphere_from: 0,
                sphere_to: 0,
                cylinder_from: 0,
                cylinder_to: 0,
                top: 0,
                down: 0,
                color: '',
                add_from: 0,
                add_to: 0,
                add_except: ''
            })
        },
        removeRow(index){
            this.packproduct.packSphere.splice(index, 1)
        },
        goBack() {
            return this.$state.go('app.packs.list')
        },
        addPack() {
            this.active = true
            API.post('packs/add', {
                pack_name1: this.packproduct.pack_name1,
                type: this.packproduct.type,
                product_id: this.packproduct.product_id,
                price_wo: this.packproduct.price_wo,
                delivery_time: this.packproduct.delivery_time,
                sphere: this.packproduct.sphere,
                pack: this.packproduct.pack,
                price: this.packproduct.price,
                pack_name2: this.packproduct.pack_name2,
                priority: this.packproduct.priority,
                product_name: this.packproduct.product_name,
                product_mfct: this.packproduct.product_mfct,
                product_code: this.packproduct.product_code,
                local_prod: this.packproduct.local_prod,
                link: this.packproduct.link,
                packSphere: this.packproduct.packSphere,
                details: this.details.filter(item => item.enabled)
            }).then(this.$state.go('app.packs.list'))
        }
    }
}
</script>

<style scoped>

</style>