<template lang="pug">
    select.form-control(:id="id" v-model="model" :disabled="disabled")
        option(v-for="d in data" :value="d.id") {{d.translate()}}
</template>

<script>
    export default {
        name: 'OptiSelectEnum',
        props: {
            enum: {
                type: String,
                required: true
            },
            value: {
                type: Number | String,
                required: true
            },

            index: {
                type: Number,
                required: false
            },
            id: {
                type: String,
                default: 'opti-select-enum'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            let vm = this

            return {
                data: [],

                get model() {
                    return vm.value
                },
                set model(value) {
                    if(typeof vm.index !== 'undefined') {
                        vm.$emit('update-enum', [vm.index, value])
                    } else {
                        vm.$emit('update-enum', value)
                    }
                }
            }
        },
        mounted() {
            this.loadEnum()
        },
        methods: {
            async loadEnum() {
                let enums = await import('../../enums/Enums')

                this.data = enums[`Enum${this.enum}`]
            }
        }
    }
</script>
