<template lang="pug">
    span
        a.link.warn(v-if="data.receiptId && data.firstValue != data.lastValue" :href="receiptLink()"
            :title="'default.notify.receipt.TITLECHANGERECEIPT'|translate")
            i.fa.fa-exclamation-circle.mr3
            span {{text|translate}} {{data.user}}
        .bold.warn(v-if="!data.receiptId && data.firstValue != data.lastValue")
            i.fa.fa-exclamation-circle.mr3
            span {{text|translate}} {{data.user}}
</template>

<script>
    export default {
        name: 'optiReceiptNotice',
        components: {

        },
        props: {
            data: {
                type: Object,
                required: true
            },
            deposit: {
                type: Boolean,
                required: false,
                default: false
            },
            rest: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                text: 'default.notify.receipt.CHANGERECEIPT'
            }
        },
        mounted() {
            if(this.deposit) {
                this.text = 'default.notify.receipt.CHANGEACCOUNT'
            } else if(this.rest) {
                this.text = 'default.notify.receipt.CHANGEREST'
            }
        },
        methods: {
            receiptLink() {
                return this.$state.href('app.register.detail', {
                    id: this.data.receiptId
                })
            }
        }
    }
</script>
