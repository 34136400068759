<template lang="pug">
    .opti-select-downloading
        VueMultiSelect(
            :value="download"
            label="name"
            track-by="id"
            :options="downloading"
            :placeholder="'search.downloading.PLACEHOLDER'|translate"
            :showLabels="false"
            :disabled="disabled"

            @input="d => $emit('selected', d)")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
            template(slot="option" slot-scope="props")
                span {{props.option.name}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}

        a.link(v-show="download.id" @click="openDownloadLink(download)")
            i.fa.fa-paperclip.mr3
            span {{'default.ATTACHMENT'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {DownloadingHelper} from '@/js/app/vue/components/Downloading/DownloadingHelper'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectDownload',
        components: {VueMultiSelect},
        props: {
            download: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                downloading: []
            }
        },
        created() {
            API.get('search/downloading', API.id(Math.random())).then(res => {
                this.downloading = res.data
            }).catch(() => {
                this.$notify.error('search.downloading.notify.error.LOAD')
            })
        },
        methods: {
            openDownloadLink(download) {
                DownloadingHelper.openUri(download)
            }
        }
    }
</script>

<style lang="less" scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
