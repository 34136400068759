<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formPermissionExtra" @submit.prevent="addPermissionExtra")
                .form-group
                    label.col-sm-4.control-label.star-required(for="department") {{'user.permission-extra.table.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department(
                            :department-id="permission_extra.department.id" @selected="d => permission_extra.department = d")
                        OptiValidate(f="department" :data="$v.permission_extra.department.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="user") {{'user.permission-extra.table.USER'|translate}}
                    .col-sm-8
                        OptiSelectEmployeeGroupByDepartment#user(mode="central"
                            :user="permission_extra.user" @selected="u => permission_extra.user = u")
                        OptiValidate(f="user" :data="$v.permission_extra.user.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="permission") {{'user.permission-extra.table.PERMISSION'|translate}}
                    .col-sm-8
                        OptiSelectEnum#permission(enum="PermissionExtra"
                            :value="permission_extra.permission" @update-enum="p => permission_extra.permission = p")
                        OptiValidate(f="permission" :data="$v.permission_extra.permission")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.permission_extra.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero} from '@/js/vue.validators.js'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiSelectEmployeeGroupByDepartment
        from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiSelectEnum from '../../Select/OptiSelectEnum'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUsersPermissionExtraAdd',
        components: {
            OptiSelectDepartment,
            OptiSelectEmployeeGroupByDepartment,
            OptiSelectEnum,
            OptiButtonSave,
            OptiValidate,
            OptiDimmer
        },
        data() {
            return {
                permission_extra: {
                    department: {
                        id: this.$uac.user.activeDepartmentId
                    },
                    user: {
                        id: 0
                    },
                    permission: 0
                },

                loading: false
            }
        },
        validations: {
            permission_extra: {
                department: {
                    id: {
                        noZero
                    }
                },
                user: {
                    id: {
                        noZero
                    }
                },
                permission: {
                    noZero
                }
            }
        },
        methods: {
            addPermissionExtra() {
                this.loading = true

                API.post('permission-extras/add', this.permission_extra).then(() => {
                    this.$state.go('app.users.permission-extras.list')

                    this.$notify.success('user.permission-extra.notify.ADD')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('user.permission-extra.notify.error.ADD')
                })
            }
        }
    }
</script>