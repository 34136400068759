import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class SurveyLogsClass {
    constructor() {

    }

    save(interview_id, survey_id, type, type_sub, action, side, description, editable, values) {
        API.post('optometrist/survey/save', {
            interview_id: interview_id,
            id: survey_id,
            type: type,
            type_sub: type_sub,
            action: action,
            side: parseInt(side) < 50 ? 'r' : (parseInt(side) > 50 ? 'l' : ''),
            description: description,
            editable: editable,
            values: values
        }).catch(() => {
            Notify.error('optometrist.logs.notify.error.SURVEYSAVE')
        })
    }
}

export const SurveyLogs = new SurveyLogsClass()