export function weekNumbersFromQuarter(quarter) {
    switch(quarter) {
        case 1:
            return [1, 13]
        case 2:
            return [14, 26]
        case 3:
            return [27, 39]
        case 4:
            return [40, 52]
        default:
            return [1,1]
    }
}