<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-dropbox.mr3
            span {{'catalog.product.sum.HEADING'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .table-responsive
                table.table.table-striped
                    thead
                        tr
                            th.text-right #
                            th {{'catalog.product.sum.DEPARTMENT'|translate}}
                            th {{'catalog.product.sum.QUANTITY'|translate}}
                    tbody
                        tr(v-for="(department, key) in sumDepartments")
                            td.text-right {{sumDepartments.length - key}}
                            td
                                div {{department.department_name}}
                                OptiProductLayoutInfo(
                                    :with-class="false"
                                    :id="parseInt($state.params.id)"
                                    :department="department.department_id")

                            td {{department.stock}}
                    tfoot.bold
                        tr
                            td.text-right(colspan="2") {{'catalog.product.sum.SUM'|translate}}
                            td {{sumDepartmentsSigma}}

        template(#footer)
            OptiButton(@click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductLayoutInfo from "@/js/app/vue/components/Warehouse/Products/OptiProductLayoutInfo.vue";

    export default {
        name: 'OptiDialogProductSumDepartments',
        components: {
            OptiProductLayoutInfo,
            OptiDialog,
            OptiButton
        },
        props: {
            sumDepartments: {
                type: Array,
                required: true
            },
            sumDepartmentsSigma: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 550px;

        > .modal-body {
            padding: 0;

            .table {
                margin-bottom: 0;
            }
        }
    }
</style>