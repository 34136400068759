<template lang="pug">
	.row
		.col-xs-12(v-if="!loading" style="margin-bottom:15px")
			OptiButtonHistory(data="contact_lenses_facing" :id="contactLenses.id")

		.col-sm-6(v-if="!loading")
			form.panel.panel-primary
				.panel-heading
					i.fa.fa-navicon.mr3
					span {{'contactLenses.breadcrumb.EDIT'|translate}} {{contactLenses.productId}}

				.table-responsive
					table.table.table-striped
						tbody
							tr
								th.text-left {{'contactLenses.table.PRODUCT_ID'|translate}}
								td
									input.form-control#product_id(v-model="contactLenses.productId" :disabled="contactLenses")
							tr
								th.text-left {{'contactLenses.table.PRODUCT_NAME'|translate}}
								td
									input.form-control#product_name(v-model="contactLenses.product.name" :disabled="contactLenses")
							tr
								th.text-left {{'contactLenses.table.DEPARTMENT'|translate}}
								td
									input.form-control#department_id(v-model="contactLenses.department.name" :disabled="contactLenses")
							tr
								th.text-left {{'contactLenses.table.FACING'|translate}}
								td
									OptiNumber#facing(:header="'contactLenses.table.FACING'|translate"
										:value="contactLenses.facing"
										@confirmed="value => contactLenses.facing = value") {{contactLenses.facing}}
							tr
								th.text-left {{'contactLenses.table.LAST_UPDATE'|translate}}
								td
									input.form-control#updated(v-model="contactLenses.updatedAt" :disabled="contactLenses")
							tr
								th.text-left {{'contactLenses.table.STOCK'|translate}}
								td
									input.form-control#depository(v-model="sum" :disabled="contactLenses")
							tr
								td
									OptiButton(type="info" @click.native="$state.go('app.contactLenses.list')") {{'contactLenses.lenses.BACK'|translate}}
										template(#icon)
											i.fa.fa-angle-left

								td
									OptiButton.pull-right(type="success" @click.native="updateFacing") {{'contactLenses.lenses.SAVE'|translate}}
										template(#icon)
											i.fa.fa-plus

		.col-sm-6(v-if="!loading")
			form.panel.panel-primary
				.panel-heading
					i.fa.fa-camera.mr3
					span {{'prices.prices.PRODUCT_PHOTO'|translate}}

				.panel-body
					img.mr3(style="margin-bottom:15px; width:100%" :src="'https://salony.eopti.pl/thumb/product/ids/' + contactLenses.productId + '.jpg'")

		.col-xs-12
			OptiDimmer(:active="loading")
</template>

<script>
	import {API} from '@/js/app/vue/api'

	import OptiButton from '@/js/app/vue/components/Button/OptiButton.vue'
	import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber.vue'
	import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
	import OptiButtonHistory from "@/js/app/vue/components/Button/OptiButtonHistory.vue";

	export default {
		name: 'OptiLayoutContactLensesEdit',
		components: {
			OptiButtonHistory,
			OptiDimmer,
			OptiNumber,
			OptiButton
		},
		data() {
			return {
				contactLenses: [],
				dep_id: 0,
				sum: 0,
				loading: true
			}
		},
		mounted() {
			API.get('contactLenses/facing/edit/' + this.$state.params.id).then(res => {
				this.contactLenses = res.data.contactLenses
				this.dep_id = this.contactLenses.departmentId
				this.sum = this.contactLenses.product.depository.stock + this.contactLenses.product.depository.ordered + this.contactLenses.product.depository.expected
			}).finally(() => {
				this.loading = false
			}).catch(() => {
				this.$notify.error('contactLenses.notify.error.LOAD')
			})
		},
		methods: {
			updateFacing() {
				this.loading = true

				API.post('contactLenses/facing/edit/' + this.$state.params.id + '/update', {
					'facing': this.contactLenses.facing
				}).then(() => {
					API.get('contactLenses/facing/edit/' + this.$state.params.id).then(res => {
						this.contactLenses = res.data.contactLenses
						this.sum = this.contactLenses.product.depository.stock + this.contactLenses.product.depository.ordered + this.contactLenses.product.depository.expected
					})

					this.$notify.success('contactLenses.notify.UPDATED')
				}).finally(() => {
					this.loading = false
				}).catch(() => {
					this.$notify.error('contactLenses.notify.error.UPDATED')
				})
			}
		}
	}
</script>