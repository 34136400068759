<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-download.mr3
            span.mr3 {{'tasks.ipad.LOADMEASUREMENT'|translate}}
            span(v-show="measurementApp === 2") JawroVisu

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .table-responsive
                table.table.table-presenter.table-striped.middle
                    thead
                        tr
                            th #
                            th {{'default.date.LABEL'|translate}}
                            th
                    tbody
                        tr(v-for="(measurement, key) in measurements")
                            td.text-right {{measurements.length - key}}
                            td
                                span {{measurement.updated_at}}
                                small.text-success(style="display:block"
                                    v-show="measurement.measurement_id === measurementId") ({{'default.DOWNLOADED'|translate}})
                            td.text-right
                                OptiButton(type="green" text="default.confirm.measurement"
                                    @confirmed="$emit('update-measurement', measurement)"
                                    ) {{'default.confirm.measurement.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-sign-in

                        tr(v-show="!measurements.length")
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

        template(#footer)
            OptiButton.pull-left(type="success" @click.native="load") {{'tasks.ipad.BTNREFRESH'|translate}}
                template(#icon)
                    i.fa.fa-refresh

            OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogMeasurementJawroVisu',
        components: {
            OptiDialog,
            OptiButton: () => import('../../../Button/OptiButton'),
            OptiDimmer
        },
        props: {
            patientId: {
                type: Number,
                required: true
            },
            measurementApp: {
                type: Number,
                required: true
            },

            measurementId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                measurements: [],

                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('patient/previouslenses/measurement-jawro-visu/' + this.patientId).then(res => {
                    this.measurements = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('default.notify.error.MEASUREMENT')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;

        > .modal-body {
            padding: 0;
        }
    }
</style>