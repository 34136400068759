<template lang="pug">
    tr.separate-top
        td.row.text-center
            .col-xs-12 {{'tasks.action.offer.HEADER'|translate}}

            .col-xs-12.lenses-to-real-task
                .lenses-to-real-task-font-weight-normal {{'tasks.action.offer.CHOICE'|translate}} 1

                div(v-if="task_id_1")
                    span.lenses-to-real-task-font-weight-normal.mr3 {{'catalog.offer.task_choice.CREATED'|translate}}
                    a.link(:href="$state.href('app.task.detail', {id: task_id_1})") {{task_id_1}}
                    span .
                OptiButton(type="success" v-else-if="task.hoya_lens_id" :disabled="loading || test_measurement || no_measurement"
                    @click.native="createRealTask(1)") {{task.rightLens.partName}}
                    template(#icon)
                        i.fa.fa-eye
                .warn(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.action.offer.NOT_SELECTED'|translate}}

            .col-xs-12.lenses-to-real-task
                .lenses-to-real-task-font-weight-normal {{'tasks.action.offer.CHOICE'|translate}} 2

                div(v-if="task_id_2")
                    span.lenses-to-real-task-font-weight-normal.mr3 {{'catalog.offer.task_choice.CREATED'|translate}}
                    a.link(:href="$state.href('app.task.detail', {id: task_id_2})") {{task_id_2}}
                    span .
                OptiButton(type="success" v-else-if="task.hoya_lens_id_2" :disabled="loading || test_measurement || no_measurement"
                    @click.native="createRealTask(2)") {{task.rightLens2.partName}}
                    template(#icon)
                        i.fa.fa-eye
                .warn(v-else)
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.action.offer.NOT_SELECTED'|translate}}

            .col-xs-12.text-center.warn(v-show="test_measurement")
                i.fa.fa-exclamation-circle.mr3
                span {{'tasks.action.accepted.notify.TEST_MEASUREMENT'|translate}}

            .col-xs-12.text-center.warn(v-show="no_measurement")
                i.fa.fa-exclamation-circle.mr3
                span {{'tasks.action.accepted.notify.NO_MEASUREMENT'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsSummaryActionsOffer',
        components: {
            OptiButton,
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            offer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                task_id_1: this.findOfferTask().offer_task_id_1,
                task_id_2: this.findOfferTask().offer_task_id_2,

                loading: false
            }
        },
        methods: {
            createRealTask(number) {
                if(confirm(this.$filters.translate('tasks.action.offer.btn.MESSAGE') + ' ' +
                    this.task['rightLens' + (number === 1 ? '' : '2')].partName + '?')) {
                    this.loading = true

                    API.post('offer/task/temporary-task-to-real-task', {
                        offer_id: this.offer.id,
                        temporary_task_id: this.task.id,
                        number: number
                    }).then(res => {
                        this.$state.go('app.task.detail', {id: res.data})

                        this.$notify.success('tasks.action.offer.notify.CREATED')
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.$notify.error('tasks.action.offer.notify.error.CONVERT')
                    })
                }
            },
            findOfferTask() {
                let _return = {}

                this.offer.products.forEach(product => {
                    product.pivot.tasks.forEach(task => {
                        if(task.offer_temporary_task_id === this.task.id) {
                            _return = task
                        }
                    })
                })

                return _return
            }
        },
        computed: {
            test_measurement() {
                return !!(('from_task' in this.task.measurement && this.task.measurement.from_task) ||
                    ('from_task' in this.task.measurementJawroVisu && this.task.measurementJawroVisu.from_task))
            },
            no_measurement() {
                return !this.task.measurementId && !this.task.measurement_jawro_visu_id
            }
        }
    }
</script>

<style lang="less" scoped>
    .lenses-to-real-task {
        margin-top: 10px;
        margin-bottom: 10px;

        .lenses-to-real-task-font-weight-normal {
            font-weight: 400;
        }
    }
</style>