<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-heart.mr3
            span.mr3 {{'catalog.offer.favorite.HEADER'|translate}}
            .label.label-primary {{favorites.length}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('close')")

        template(#body)
            .row
                .col-md-4.col-sm-6.offer-product(v-for="favorite in favorites" :key="favorite.id")
                    OptiProductInfo(
                        :product="favorite"
                        :selected="selected.includes(favorite.id)"
                        :department-id="departmentId")

        template(#footer)
            OptiButton(@click.native="$emit('close')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'

    export default {
        name: 'OptiCatalogOfferFavoritesDialog',
        components: {
            OptiDialog,
            OptiButton,
            OptiProductInfo
        },
        props: {
            favorites: {
                type: Array,
                required: true
            },
            selected: {
                type: Array,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            }
        }
    }
</script>
