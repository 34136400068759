import { render, staticRenderFns } from "./OptiRegenerationNotes.vue?vue&type=template&id=6d786571&scoped=true&lang=pug&"
import script from "./OptiRegenerationNotes.vue?vue&type=script&lang=js&"
export * from "./OptiRegenerationNotes.vue?vue&type=script&lang=js&"
import style0 from "./OptiRegenerationNotes.vue?vue&type=style&index=0&id=6d786571&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d786571",
  null
  
)

export default component.exports