<template lang="pug">
    .panel.panel-primary
        .panel-heading
            i.fa.fa-edit.mr3
            span {{'prescription.ADDPRESCRIPTION'|translate}}

        .panel-body(style="padding:0")
            .table-responsive
                table.table.table-presenter.table-striped
                    tbody
                        tr
                            th
                                label(for="type") {{'default.TYPE'|translate}}
                            td(colspan="2")
                                OptiSelectEnum(enum="PrescriptionType"
                                    :value="prescription.type" @update-enum="t => prescription.type = t")
                        tr
                            th
                            th {{'prescription.RIGHT'|translate}}
                            th {{'prescription.LEFT'|translate}}
                        tr
                            th {{'prescription.SPHERE'|translate}}
                            td
                                OptiNumberPrescription.text-center(:header="$filters.translate('prescription.SPHERE') + ' ' + $filters.translate('prescription.RIGHT')"
                                    :value="prescription.reSphere" @confirmed="v => updatePower('reSphere', v)"
                                    ) {{prescription.reSphere|plus}}
                            td
                                OptiNumberPrescription.text-center(:header="$filters.translate('prescription.SPHERE') + ' ' + $filters.translate('prescription.LEFT')"
                                    :value="prescription.leSphere" @confirmed="v => updatePower('leSphere', v)"
                                    ) {{prescription.leSphere|plus}}
                        tr(v-show="prescription.reCylinder * prescription.leCylinder < 0")
                            td(colspan="3")
                                .alert.alert-warning(style="margin-bottom:3px")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'prescription.notify.warning.DIFFRENTSIGNCYLINDER'|translate}}
                        tr
                            th {{'prescription.CYLINDER'|translate}}
                            td
                                OptiNumberPrescription.text-center(:header="$filters.translate('prescription.CYLINDER') + ' ' + $filters.translate('prescription.RIGHT')"
                                    :value="prescription.reCylinder" @confirmed="v => updatePower('reCylinder', v)"
                                    ) {{prescription.reCylinder|plus}}
                            td
                                OptiNumberPrescription.text-center(:header="$filters.translate('prescription.CYLINDER') + ' ' + $filters.translate('prescription.LEFT')"
                                    :value="prescription.leCylinder" @confirmed="v => updatePower('leCylinder', v)"
                                    ) {{prescription.leCylinder|plus}}
                        tr(v-show="prescription.reCylinder || prescription.leCylinder")
                            th {{'prescription.AXIS'|translate}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('prescription.RIGHT')" :max="180"
                                    :value="prescription.reAxis" @confirmed="v => updatePower('reAxis', v)"
                                    ) {{prescription.reAxis}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.AXIS') + ' - ' + $filters.translate('prescription.LEFT')" :max="180"
                                    :value="prescription.leAxis" @confirmed="v => updatePower('leAxis', v)"
                                    ) {{prescription.leAxis}}
                        tr
                            th  {{'prescription.ADDICTION'|translate}}
                            td
                                OptiNumberPrescriptionAddiction.text-center(:header="$filters.translate('prescription.ADDICTION') + ' - ' + $filters.translate('prescription.RIGHT')"
                                    :value="prescription.reAdd" @confirmed="v => updatePower('reAdd', v)") {{prescription.reAdd|number(2)}}
                            td
                                OptiNumberPrescriptionAddiction.text-center(:header="$filters.translate('prescription.ADDICTION') + ' - ' + $filters.translate('prescription.LEFT')"
                                    :value="prescription.leAdd" @confirmed="v => updatePower('leAdd', v)") {{prescription.leAdd|number(2)}}

                        tr
                            th
                            td.text-center(colspan="2")
                                #prism-type
                                    label.control-label.mr3(style="cursor:pointer" :class="{'text-normal': prescription.prism_mode}"
                                        @click="prescription.prism_mode = false") {{'prescription.prism.mode.HORIZONTALVERTICAL'|translate}}
                                    label.switch.switch-lg.mr3
                                        input(type="checkbox" v-model="prescription.prism_mode" @change="updatePrism")
                                        span
                                    label.control-label(style="cursor:pointer" :class="{'text-normal': !prescription.prism_mode}"
                                        @click="prescription.prism_mode = true") {{'prescription.prism.mode.RESULT'|translate}}

                                    a.btn.btn-default(style="margin-left:15px" :title="'prescription.prism.LINK'|translate"
                                        href="https://wiki.eopti.pl/soczewki_okularowe:soczewki_pryzmatyczne" target="_blank")
                                        i.fa.fa-question
                        tr
                            th  {{'prescription.PRISM'|translate}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('prescription.RIGHT')"
                                    :minus="false" :float="true" :max="15" :disabled="!prescription.prism_mode"
                                    :value="prescription.rePrism" @confirmed="v => updatePower('rePrism', v)"
                                    ) {{prescription.rePrism|number(2)}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.PRISM') + ' - ' + $filters.translate('prescription.LEFT')"
                                    :minus="false" :float="true" :max="15" :disabled="!prescription.prism_mode"
                                    :value="prescription.lePrism" @confirmed="v => updatePower('lePrism', v)"
                                    ) {{prescription.lePrism|number(2)}}
                        tr(v-show="!prescription.prism_mode || prescription.rePrism || prescription.lePrism")
                            th {{'prescription.PRISMANGLE'|translate}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('prescription.RIGHT')" :max="360"
                                    :disabled="!prescription.prism_mode" :value="prescription.rePrismAngle" @confirmed="v => updatePower('rePrismAngle', v)"
                                    ) {{prescription.rePrismAngle}}
                            td
                                OptiNumber.text-center(:header="$filters.translate('prescription.PRISMANGLE') + ' - ' + $filters.translate('prescription.LEFT')" :max="360"
                                    :disabled="!prescription.prism_mode" :value="prescription.lePrismAngle" @confirmed="v => updatePower('lePrismAngle', v)"
                                    ) {{prescription.lePrismAngle}}
                        tr(v-show="!prescription.prism_mode || prescription.rePrism || prescription.lePrism")
                            th {{'prescription.prism.HORIZONTAL'|translate}}
                            td
                                div(style="display:flex;justify-content:space-around;align-content:center;align-items:center")
                                    OptiNumber.text-center(style="width:50%" :header="$filters.translate('prescription.PRISM') + ' (' + $filters.translate('prescription.prism.HORIZONTAL') + ') - ' + $filters.translate('prescription.RIGHT')"
                                        :minus="false" :float="true" :max="15" :disabled="prescription.prism_mode"
                                        :value="prism.r.horizontal.power" @confirmed="v => updatePrism('r', 'horizontal', 'power', v)"
                                        ) {{prism.r.horizontal.power|number(2)}}

                                    div(style="width:50%;margin-left:10px;white-space:nowrap")
                                        .radio.c-radio.c-radio-nofont(style="text-align:left;padding-top:5px")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.r.horizontal.side" @change="updatePrism"
                                                    name="rePrismHorizontalSide" :value="0")
                                                span
                                                | {{'prescription.prism.side.NOSAL'|translate}}

                                        .radio.c-radio.c-radio-nofont(style="text-align:left")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.r.horizontal.side" @change="updatePrism"
                                                    name="rePrismHorizontalSide" :value="1")
                                                span
                                                | {{'prescription.prism.side.TEMPLE'|translate}}
                            td
                                div(style="display:flex;justify-content:space-around;align-content:center;align-items:center")
                                    OptiNumber.text-center(style="width:50%" :header="$filters.translate('prescription.PRISM') + ' (' + $filters.translate('prescription.prism.HORIZONTAL') + ') - ' + $filters.translate('prescription.LEFT')"
                                        :minus="false" :float="true" :max="15" :disabled="prescription.prism_mode"
                                        :value="prism.l.horizontal.power" @confirmed="v => updatePrism('l', 'horizontal', 'power', v)"
                                        ) {{prism.l.horizontal.power|number(2)}}

                                    div(style="width:50%;margin-left:10px;white-space:nowrap")
                                        .radio.c-radio.c-radio-nofont(style="text-align:left;padding-top:5px")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.l.horizontal.side" @change="updatePrism"
                                                    name="lePrismHorizontalSide" :value="0")
                                                span
                                                | {{'prescription.prism.side.NOSAL'|translate}}

                                        .radio.c-radio.c-radio-nofont(style="text-align:left")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.l.horizontal.side" @change="updatePrism"
                                                    name="lePrismHorizontalSide" :value="1")
                                                span
                                                | {{'prescription.prism.side.TEMPLE'|translate}}
                        tr(v-show="!prescription.prism_mode || prescription.rePrism || prescription.lePrism")
                            th {{'prescription.prism.VERTICAL'|translate}}
                            td
                                div(style="display:flex;justify-content:space-around;align-content:center;align-items:center")
                                    OptiNumber.text-center(style="width:50%" :header="$filters.translate('prescription.PRISM') + ' (' + $filters.translate('prescription.prism.VERTICAL') + ') - ' + $filters.translate('prescription.RIGHT')"
                                        :minus="false" :float="true" :max="15" :disabled="prescription.prism_mode"
                                        :value="prism.r.vertical.power" @confirmed="v => updatePrism('r', 'vertical', 'power', v)"
                                    ) {{prism.r.vertical.power|number(2)}}

                                    div(style="width:50%;margin-left:10px;white-space:nowrap")
                                        .radio.c-radio.c-radio-nofont(style="text-align:left;padding-top:5px")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.r.vertical.side" @change="updatePrism"
                                                    name="rePrismVerticalSide" :value="2")
                                                span
                                                | {{'prescription.prism.side.UP'|translate}}

                                        .radio.c-radio.c-radio-nofont(style="text-align:left")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.r.vertical.side" @change="updatePrism"
                                                    name="rePrismVerticalSide" :value="3")
                                                span
                                                | {{'prescription.prism.side.DOWN'|translate}}
                            td
                                div(style="display:flex;justify-content:space-around;align-content:center;align-items:center")
                                    OptiNumber.text-center(style="width:50%" :header="$filters.translate('prescription.PRISM') + ' (' + $filters.translate('prescription.prism.VERTICAL') + ') - ' + $filters.translate('prescription.LEFT')"
                                        :minus="false" :float="true" :max="15" :disabled="prescription.prism_mode"
                                        :value="prism.l.vertical.power" @confirmed="v => updatePrism('l', 'vertical', 'power', v)"
                                        ) {{prism.l.vertical.power|number(2)}}

                                    div(style="width:50%;margin-left:10px;white-space:nowrap")
                                        .radio.c-radio.c-radio-nofont(style="text-align:left;padding-top:5px")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.l.vertical.side" @change="updatePrism"
                                                    name="lePrismVerticalSide" :value="2")
                                                span
                                                | {{'prescription.prism.side.UP'|translate}}

                                        .radio.c-radio.c-radio-nofont(style="text-align:left")
                                            label
                                                input(type="radio" :disabled="prescription.prism_mode"
                                                    v-model="prism.l.vertical.side" @change="updatePrism"
                                                    name="lePrismVerticalSide" :value="3")
                                                span
                                                | {{'prescription.prism.side.DOWN'|translate}}

                        tr
                            td(colspan="3" style="padding:0;border-top:0")
                                OptiPdCalculate(
                                    :show-pd-dist="true"
                                    :pd-dist="prescription.pdDist"
                                    :re-pd-dist="prescription.rePdDist"
                                    :le-pd-dist="prescription.lePdDist"

                                    :show-pd-near="true"
                                    :pd-near="prescription.pdNear"
                                    :re-pd-near="prescription.rePdNear"
                                    :le-pd-near="prescription.lePdNear"

                                    @updated="updatePD")
                        tr
                            th(rowspan="2")
                                label(for="comments") {{'prescription.COMMENTS'|translate}}
                            td(rowspan="2" colspan="2")
                                textarea#comments.form-control(style="height:100px;resize:none" v-model="prescription.comments")
                    tfoot
                        tr
                            td
                            td(colspan="2" style="text-align:left")
                                OptiButtonSave(:disabled="loading.doctors || !!loading.files.filter(l => l).length")
</template>

<script>
    import {initPrescriptionPrism, prescriptionUpdatePrism} from '@/js/app/vue/helpers/PrescriptionUpdatePrism'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiNumberPrescription from '@/js/app/vue/components/Inputs/OptiNumberPrescription'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiNumberPrescriptionAddiction from '@/js/app/vue/components/Inputs/OptiNumberPrescriptionAddiction'
    import OptiPdCalculate from '@/js/app/vue/components/Inputs/OptiPdCalculate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiPatientsDetailsPrescriptionPowers',
        components: {
            OptiSelectEnum,
            OptiNumberPrescription,
            OptiNumber,
            OptiNumberPrescriptionAddiction,
            OptiPdCalculate,
            OptiButtonSave
        },
        props: {
            prescription: {
                type: Object,
                required: true
            },
            loading: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                prism: {
                    r: {
                        horizontal: {
                            power: 0,
                            side: 0
                        },
                        vertical: {
                            power: 0,
                            side: 2
                        }
                    },
                    l: {
                        horizontal: {
                            power: 0,
                            side: 0
                        },
                        vertical: {
                            power: 0,
                            side: 2
                        }
                    }
                }
            }
        },
        mounted() {
            initPrescriptionPrism(this.prescription, this.prism)
        },
        methods: {
            updatePower(field, v) {
                this.prescription[field] = v

                switch(field) {
                    case 'reCylinder':
                    case 'leCylinder':
                        if(!v) {
                            this.prescription[field[0] + 'eAxis'] = 'brak'
                        }

                        break

                    case 'reAdd':
                    case 'leAdd':
                        if(!this.prescription[(field[0] === 'r' ? 'l' : 'r') + 'eAdd']) {
                            this.prescription[(field[0] === 'r' ? 'l' : 'r') + 'eAdd'] = v
                        }

                        break

                    case 'rePrism':
                    case 'lePrism':
                        if(!v) {
                            this.prescription[field[0] + 'ePrismAngle'] = 0
                        }

                        this.updatePrism()

                        break

                    case 'rePrismAngle':
                    case 'lePrismAngle':
                        this.updatePrism()
                }
            },

            updatePrism(side, type, field, value) {
                prescriptionUpdatePrism(this.prescription, this.prism, side, type, field, value)
            },

            updatePD(pd) {
                _.forEach(pd, (value, key) => {
                    this.prescription[key] = value
                })
            }
        }
    }
</script>
