<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-indent.mr3
            span {{'tasks.compare.COMPAREILOG'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            div(v-html="html")

        template(#footer)
            .row
                .col-xs-6.col-xs-offset-3.text-right
                    OptiButton(@click.native="$emit('closed')") {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskCompareDialog',
        components: {
            OptiDialog,
            OptiButton
        },
        props: {
            htmlTable: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                html: !this.htmlTable.contains('no-ilog') ?
                    this.htmlTable.replaceAll('col-lg-6', 'col-xs-4').replaceAll('col-xs-12', 'col-xs-4')
                        .replaceAll('compare-smaller-table', '') : this.htmlTable
            }
        }
    }
</script>