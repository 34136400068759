<template lang="pug">
    .row
        .col-md-6.col-md-offset-3(v-show="!loading")
            form.form.form-horizontal(name="formReport")
                .form-group
                    label.col-xs-12.star-required(for="text") {{'report.warehouse.sales.PRODUCTIDS'|translate}}
                    .col-xs-12
                        textarea#text.form-control(name="text" rows="15"
                            :placeholder="'report.warehouse.sales.PLACEHOLDER'|translate" v-model="form.text")
                        OptiValidate(f="text" :data="$v.form.text")
                .form-group
                    .col-xs-12
                        OptiButton(type="success" :disabled="$v.form.$invalid" @click.native="generate") {{'report.warehouse.sales.GENERATE'|translate}}
                            template(#icon)
                                i.fa.fa-upload

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReportWarehouseSales',
        components: {
            OptiValidate,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                form: {
                    text: ''
                },

                loading: false
            }
        },
        validations: {
            form: {
                text: {
                    required
                }
            }
        },
        methods: {
            generate() {
                this.loading = true

                let filename = 'sprzedaż_wg_id_' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xls'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'warehouse/sales/' + filename,
                        status: true
                    }
                })

                API.post('reports/warehouse/sales', {
                    text: this.form.text,
                    filename: filename
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('report.warehouse.sales.notify.error.GENERATE')
                })
            }
        }
    }
</script>