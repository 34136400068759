<template lang="pug">
    .col-xs-12
        .table-responsive
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tbody
                    tr.separate-top(v-show="!isOffer && (task.status === 1 || task.status === 9)")
                        td.buttons.text-center
                            OptiButton.pull-left(type="danger" v-if="task.status === 1"
                                text="tasks.action.cancelled.confirm" @confirmed="goCancelled"
                                ) {{'tasks.action.cancelled.GOCANCELLED'|translate}}
                                template(#icon)
                                    i.fa.fa-remove
                            OptiButton.pull-left(type="danger" v-if="task.status === 9"
                                @click.native="dialog.cancel_received = true") {{'tasks.action.cancelled.received.GOCANCELLED'|translate}}
                                template(#icon)
                                    i.fa.fa-remove
                                MountingPortal(v-if="dialog.cancel_received" mountTo="#vue-modal" append)
                                    OptiTaskDetailsSummaryDialogCancelReceived(
                                        @confirmed="goCancelReceived"
                                        @closed="dialog.cancel_received = false")
                            OptiButton(type="default" v-if="task.status === 1 && task.departmentId !== 392 && permission.assign_to_jacek"
                                text="tasks.action.assign.confirm" @confirmed="goAssignToJacek") {{'tasks.action.assign.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-arrow-right
                            OptiButton.pull-right(type="success" v-if="task.status === 1" :disabled="!valid.deposit || !checkLenses"
                                @click.native="goAccepted") {{'tasks.action.accepted.GOACCEPTED'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" v-if="task.status === 9"
                                :disabled="!valid.bill || !valid.rest" @click.native="$emit('go-done')"
                                ) {{'tasks.action.received.GODONE'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            .clearfix

                    tr.separate-top(v-show="task.status === 10 && !task.serviceContract.utilizedTaskId")
                        td.buttons
                            OptiDimmer(:active="loading")
                            .row(style="margin:0")
                                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                                    OptiButton(type="info" v-if="task.status === 10" @click.native="dialog.duplicate = true"
                                        ) {{'tasks.action.duplicate.GODUPLICATE'|translate}}
                                        template(#icon)
                                            i.fa.fa-copy
                                        MountingPortal(v-if="dialog.duplicate" mountTo="#vue-modal" append)
                                            OptiTaskDetailsSummaryDialogDuplicate(
                                                :task="task"

                                                @closed="dialog.duplicate = false")
                                .col-md-4.text-center
                                    OptiButton(type="info" v-if="task.serviceContract.id"
                                        :disabled="!(task.status === 10 && task.serviceContract.id && !task.fromServiceContract.id && task.serviceContract.status === 'VALID')"
                                        text="tasks.serviceContract.confirm" @confirmed="makeServiceContract"
                                        ) {{'tasks.serviceContract.confirm.BTN'|translate}}
                                        template(#icon)
                                            i.fa.fa-gears
                                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
                                    OptiButton(type="info" v-if="task.status === 10"
                                        text="tasks.action.reclamation.confirm" @confirmed="goReclamation"
                                        ) {{'tasks.action.reclamation.GORECLAMATION'|translate}}
                                        template(#icon)
                                            i.fa.fa-medkit
                                    OptiButton(type="info" v-if="task.status === 10"
                                        text="tasks.action.regeneration.confirm" @confirmed="goRegeneration"
                                        ) {{'tasks.action.regeneration.BTN'|translate}}
                                        template(#icon)
                                            i.icon.icon-magic-wand

                    tr(v-show="task.status === 9 && !(valid.bill && valid.rest)")
                        td(style="padding-left:35px")
                            a.link.warn(style="display:block" v-show="!valid.bill" :title="'tasks.action.notify.PAYMENTTITLE'|translate"
                                @click="$emit('set-active', 'payment')") {{'tasks.action.received.notify.BILL'|translate}}
                            a.link.warn(style="display:block" v-show="!valid.rest" :title="'tasks.action.notify.PAYMENTTITLE'|translate"
                                @click="$emit('set-active', 'payment')") {{'tasks.action.received.notify.REST'|translate}}

                    tr(v-show="!isOffer && task.status === 1 && !(valid.deposit && checkLenses)")
                        td(style="padding-left:35px")
                            a.link.warn(style="display:block" v-show="!valid.deposit" :title="'tasks.action.notify.PAYMENTTITLE'|translate"
                                @click="$emit('set-active', 'payment')") {{'tasks.action.accepted.notify.DEPOSIT'|translate}}
                            a.link.warn(style="display:block" v-show="!checkLenses" :title="'tasks.action.notify.LENSESTITLE'|translate"
                                @click="$emit('set-active', 'lenses')") {{'tasks.action.accepted.notify.LENSES'|translate}}

                    tr(v-show="task.status === 10 && (task.serviceContract.status === 'UTILIZED' || task.serviceContract.status === 'INVALID')")
                        td(style="padding-left:35px")
                            a.link.warn(style="display:block" v-show="task.serviceContract.status === 'UTILIZED'" :title="'default.REFERENCETYPE'|translate"
                                :href="$state.href('app.task.detail', {id: task.serviceContract.utilizedTaskId})") {{'tasks.serviceContract.notify.UTILIZED'|translate}} {{task.serviceContract.utilizedTaskId}}.
                            .warn(style="display:block" v-show="task.serviceContract.status === 'INVALID'") {{'tasks.serviceContract.notify.INVALID'|translate}}

                    tr(v-if="task.status === 5 && permission.turn_back")
                        td.row
                            .col-sm-8.col-sm-offset-2
                                OptiDimmer(:active="loading")
                                form.form.form-horizontal
                                    .form-group
                                        .col-xs-12
                                            label
                                                .checkbox.c-checkbox.checkbox-primary.inline
                                                    label
                                                        input(type="checkbox" name="turn_back_frame" v-model="turnBack.frame")
                                                        span.fa.fa-check
                                                span {{'tasks.FRAME'|translate}}
                                    .form-group
                                        .col-xs-12
                                            label
                                                .checkbox.c-checkbox.checkbox-primary.inline
                                                    label
                                                        input(type="checkbox" name="turn_back_rl" v-model="turnBack.rl")
                                                        span.fa.fa-check
                                                span {{'tasks.RL'|translate}}
                                    .form-group
                                        .col-xs-12
                                            label
                                                .checkbox.c-checkbox.checkbox-primary.inline
                                                    label
                                                        input(type="checkbox" name="turn_back_ll" v-model="turnBack.ll")
                                                        span.fa.fa-check
                                                span {{'tasks.LL'|translate}}
                                    .form-group
                                        .col-xs-12
                                            textarea.form-control(rows="4" v-model="turnBack.comments")
                                    .form-group
                                        .col-xs-6(style="text-align:right")
                                            label.form-control-static(for="pin") {{'tasks.optic.form.PIN'|translate}}
                                        .col-xs-6
                                            input#pin.form-control(v-model="turnBack.pin" autocomplete="off")
                                    .form-group
                                        .col-xs-12
                                            OptiButton(type="danger" :disabled="!turnBack.pin"
                                                text="tasks.action.broken.confirm" @confirmed="goBrokenGrindery"
                                                ) {{'tasks.action.broken.BTN'|translate}}
                                                template(#icon)
                                                    i.fa.fa-remove

                                            OptiButton.pull-right(type="success" :disabled="!turnBack.pin"
                                                text="tasks.action.grindery.confirm" @confirmed="goGrindery"
                                                ) {{'tasks.action.grindery.BTN'|translate}}
                                                template(#icon)
                                                    i.fa.fa-arrow-right

                    tr(v-if="task.status === 6 && permission.turn_back")
                        td.row
                            .col-sm-8.col-sm-offset-2
                                OptiDimmer(:active="loading")
                                form.form.form-horizontal
                                    .form-group
                                        .col-xs-12
                                            textarea.form-control(rows="4" v-model="turnBack.comments")
                                    .form-group
                                        .col-xs-6(style="text-align:right")
                                            label.form-control-static(for="pin2") {{'tasks.optic.form.PIN'|translate}}
                                        .col-xs-6
                                            input#pin2.form-control(v-model="turnBack.pin" autocomplete="off")
                                    .form-group
                                        .col-xs-12
                                            OptiButton(type="danger" :disabled="!turnBack.pin"
                                                text="tasks.action.broken.confirm" @confirmed="goBrokenControl"
                                                ) {{'tasks.action.broken.BTN'|translate}}
                                                template(#icon)
                                                    i.fa.fa-remove

                                            OptiButton.pull-right(type="success" :disabled="!turnBack.pin"
                                                text="tasks.action.control.confirm" @confirmed="goControl"
                                                ) {{'tasks.action.control.BTN'|translate}}
                                                template(#icon)
                                                    i.fa.fa-arrow-right

                    tr(v-if="allowGreenPutBack || (task.status === 2  && permission.confirm) || (task.status === 3 && task.attributes.putBack && permission.turn_back) || (task.status === 3 && permission.order && !task.has_supply)")
                        td.row
                            OptiDimmer(:active="loading")
                            .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                                OptiButton(type="danger" v-if="allowGreenPutBack"
                                    text="tasks.admin.green-put-back.confirm" @confirmed="goGreenPutBack"
                                    ) {{'tasks.admin.green-put-back.confirm.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-undo
                            .col-md-4.text-center
                                label(v-if="task.status === 2 && permission.confirm")
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" name="order_by_admin" :disabled="save_mode"
                                                v-model="task.order_by_admin" @change="$emit('summary-was-updated')")
                                            span.fa.fa-check
                                    span {{'tasks.attributes.ORDER_BY_ADMIN'|translate}}

                                label(v-if="task.status === 3 && task.attributes.putBack && permission.turn_back")
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" name="required_ilog"
                                                v-model="task.required_ilog" @change="$emit('summary-was-updated')")
                                            span.fa.fa-check
                                    span {{'tasks.admin.compareilog.REQUIREDILOG'|translate}}
                            .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg
                                OptiButton(type="success" v-if="task.status === 2 && permission.confirm"
                                    @click.native="dialog.compare = true") {{'tasks.admin.confirm.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-check

                                OptiButton(type="info" v-if="task.status === 3 && permission.order && !task.has_supply && task.manufacturerCustomId.startsWith('2')"
                                    @click.native="dialog.compare = true") {{'tasks.admin.order.BTN'|translate}}
                                    template(#icon)
                                        i.fa.fa-truck

                                MountingPortal(v-if="dialog.compare" mountTo="#vue-modal" append)
                                    OptiTaskDialogOptiHoyaCompare(
                                        :task="task"

                                        @confirmed="$emit('summary-was-updated')"
                                        @ordered="$emit('summary-was-updated')"
                                        @closed="dialog.compare = false")

                    OptiTaskDetailsSummaryActionsOffer(v-if="isOffer"
                        :task="task"
                        :offer="offer")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import validateTask from '@/js/app/vue/components/Tasks/TaskValidator'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsSummaryDialogCancelReceived
        from '@/js/app/vue/components/Tasks/Details/Summary/Dialogs/OptiTaskDetailsSummaryDialogCancelReceived'
    import OptiTaskDetailsSummaryDialogDuplicate
        from '@/js/app/vue/components/Tasks/Details/Summary/Dialogs/OptiTaskDetailsSummaryDialogDuplicate'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiTaskDialogOptiHoyaCompare
        from '@/js/app/vue/components/Tasks/Details/Summary/Dialogs/OptiTaskDialogOptiHoyaCompare'
    import OptiTaskDetailsSummaryActionsOffer
        from '@/js/app/vue/components/Tasks/Details/Summary/Actions/OptiTaskDetailsSummaryActionsOffer'

    export default {
        name: 'OptiTaskDetailsSummaryActions',
        components: {
            OptiButton,
            OptiTaskDetailsSummaryDialogCancelReceived,
            OptiTaskDetailsSummaryDialogDuplicate,
            OptiDimmer,
            OptiTaskDialogOptiHoyaCompare,
            OptiTaskDetailsSummaryActionsOffer
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },

            valid: {
                type: Object,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            },

            offer: {
                required: true
            }
        },
        data() {
            return {
                turnBack: {
                    frame: false,
                    rl: false,
                    ll: false,
                    comments: '',
                    task_id: this.task.id,
                    pin: ''
                },

                dialog: {
                    cancel_received: false,
                    duplicate: false,
                    compare: false
                },
                permission: {
                    assign_to_jacek: this.$uac.permission('tasks.assign_to_jacek'),
                    turn_back: this.$uac.permission('tasks.turn_back'),
                    green_turn_back: this.$uac.permission('tasks.green_turn_back'),
                    confirm: this.$uac.permissionExtra('confirm_tasks', this.task.departmentId) ||
                        this.$uac.permissionExtra('confirm_and_order', this.task.departmentId),
                    order: this.$uac.permissionExtra('order_lens', this.task.departmentId) ||
                        this.$uac.permissionExtra('confirm_and_order', this.task.departmentId)
                },

                save_mode: window.__env.hoya_save_mode,
                loading: false
            }
        },
        methods: {
            goCancelled() {
                this.task.status = 0

                this.$emit('summary-was-updated')
            },
            goCancelReceived(comments) {
                this.task.status = 0
                this.task.reason_of_no_receive = comments

                this.dialog.cancel_received = false
                this.$emit('summary-was-updated')
            },
            goAssignToJacek() {
                this.task.departmentId = 392

                this.$emit('summary-was-updated')
            },
            goAccepted() {
                setTimeout(() => {
                    let warnings = validateTask(this.task, 'all')

                    if(!warnings.length) {
                        if(!this.task.manufacturerCustomId && ![26, 31].includes(this.task.assemblyId)) {
                            if(confirm(this.$filters.translate('tasks.action.noilog.MESSAGE'))) {
                                this.task.status = 2

                                this.$emit('summary-was-updated')
                            }
                        } else {
                            if(confirm(this.$filters.translate('tasks.action.accepted.confirm.MESSAGE'))) {
                                this.task.status = 2

                                this.$emit('summary-was-updated')
                            }
                        }

                    } else {
                        warnings.forEach(warning => {
                            this.$notify.error(warning)
                        })
                    }
                }, 0)
            },

            makeServiceContract() {
                this.loading = true

                API.get('task/service-contract/create/' + this.task.id).then(res => {
                    if(Object.keys(res.data).includes('id')) {
                        this.$state.go('app.task.detail', {id: res.data.id, refresh: true})

                        this.$notify.success('tasks.serviceContract.notify.CREATE')
                    } else {
                        this.$notify.error('tasks.serviceContract.notify.error.CREATE')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.serviceContract.notify.error.CREATE')
                })
            },
            goReclamation() {
                this.loading = true

                API.get('tasks/reclamations/check-allow/' + this.task.id).then(res => {
                    if(res.data.allow) {
                        this.$state.go('app.tasks.reclamation.add', {
                            task: this.task
                        })
                    } else {
                        this.$notify.error('reclamations.notify.error.EXISTS')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('reclamations.notify.error.LOAD')
                })
            },
            goRegeneration() {
                this.$state.go('app.regeneration.add', {
                    extra: {
                        patient: this.task.patient,
                        product: Object.keys(this.task.product).includes('id') ? this.task.product : {},
                        product_name: this.task.frame.partName,
                        product_brand: this.task.frame.manufacturer,
                        product_type: this.task.frame.type,
                        source: 1,
                        source_id: this.task.id
                    }
                })
            },

            goBrokenGrindery() {
                this.loading = true

                API.post('task/optic/back-grindery', this.turnBack).then(res => {
                    this.$emit('summary-was-updated')

                    this.turnBack.comments = ''

                    this.$notify.success(res.data, false)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.optic.notify.error.putback.GRINDERY')
                })
            },
            goGrindery() {
                this.loading = true

                API.post('task/optic/go-grindery', this.turnBack).then(res => {
                    this.$emit('summary-was-updated')

                    this.turnBack.comments = ''

                    this.$notify.success(res.data, false)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.optic.notify.error.SAVE')
                })
            },

            goBrokenControl() {
                this.loading = true

                API.post('task/optic/back-control', this.turnBack).then(res => {
                    this.$emit('summary-was-updated')

                    this.turnBack.comments = ''

                    this.$notify.success(res.data, false)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.optic.notify.error.putback.CONTROL')
                })
            },
            goControl() {
                this.loading = true

                API.post('task/optic/go-checked', this.turnBack).then(res => {
                    this.$emit('summary-was-updated')

                    this.turnBack.comments = ''

                    this.$notify.success(res.data, false)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.optic.notify.error.CONTROL')
                })
            },

            goGreenPutBack() {
                this.loading = true

                API.get('task/go-green-put-back/' + this.task.id).then(() => {
                    this.task.status -= 1;

                    this.$emit('summary-was-updated')

                    this.$notify.success('tasks.admin.green-put-back.notify.BACK')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.admin.green-put-back.notify.error.BACK')
                })
            }
        },
        computed: {
            checkLenses() {
                return !this.task.rightLens.partName || !this.task.leftLens.partName ?
                    ![32, 33, 34, 35, 36, 37, 38, 39, 40, 41].includes(this.task.assemblyId) : true
            },
            allowGreenPutBack() {
                //to samo TaskGreenPutBackController.php:54
                return this.permission.confirm && (this.task.status === 2 || (this.task.status === 3 &&
                    ((this.task.highest_status === 3 && !this.task.has_supply) || this.permission.green_turn_back)))
            }
        }
    }
</script>