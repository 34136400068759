<template lang="pug">
    table.table.table-condensed.table-striped.text-center(style="margin-bottom:0")
        caption.text-center(style="color:#fff")
            i.fa.fa-navicon.mr3
            span {{'optometrist.autorefraktometr.AUTOREFRAKTOMETR'|translate}}
        thead
            tr
                th
                th SPH
                th CYL
                th(v-show="autorefraktometr.r.cyl || autorefraktometr.l.cyl") AXS
        tbody
            tr
                th {{'prescription.RIGHT'|translate}}
                td {{autorefraktometr.r.sph|plus(-999)}}
                td {{autorefraktometr.r.cyl|plus(-999)}}
                td(v-show="autorefraktometr.r.cyl") {{autorefraktometr.r.axs}}
            tr
                th {{'prescription.LEFT'|translate}}
                td {{autorefraktometr.l.sph|plus(-999)}}
                td {{autorefraktometr.l.cyl|plus(-999)}}
                td(v-show="autorefraktometr.l.cyl") {{autorefraktometr.l.axs}}
</template>

<script>
    export default {
        name: 'OptiOptometristsSurveysAutorefraktometr',
        props: {
            autorefraktometr: {
                type: Object,
                required: true
            }
        }
    }
</script>