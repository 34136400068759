import {API} from '@/js/app/vue/api'
import {helpers} from 'vuelidate/lib/validators'

function duplicateCatalogConstruct() {
    let timeout = [],
        previous = []

    return helpers.withParams({ }, async function(construct_id, construct) {
        if(!construct_id) {
            return true
        }

        let index = this.data.constructions.map(c => c.__ob__.dep.id).findIndex(c => c === construct.__ob__.dep.id)

        if(previous[index] && previous[index].construct_id === construct_id) {
            return previous[index]._return
        }

        previous[index] = {
            construct_id: construct_id,
            _return: true
        }

        return new Promise(resolve => {
            if(timeout[index]) {
                clearTimeout(timeout[index])
            }

            timeout[index] = setTimeout(() => {
                API.get('catalog/hoya/properties/validate/' + this.categoryId + '/' + construct_id, API.id(Math.random())).then(res => {
                    this.category_duplicate[index] = res.data

                    previous[index]._return = !res.data.id

                    resolve(!res.data.id)
                }).catch(() => {
                    previous[index]._return = false

                    resolve(false)
                })
            }, 300)
        })
    })
}

function duplicateConstructions() {
    return helpers.withParams({ }, function(construction_id) {
        return this.data.constructions.filter(c => c.id === construction_id).length === 1
    })
}

export {duplicateCatalogConstruct, duplicateConstructions}