import { Line } from 'vue-chartjs'
import {reactiveProp} from 'vue-chartjs/es/mixins'

export default {
    extends: Line,
    name: 'LineChart',
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        this.renderChart(this.chartData, this.options)
    },
    methods: {
        createGradientStroke() {
            if (!this.$refs.canvas) return false
            let ctx = this.$refs.canvas.getContext('2d')
            return ctx.createLinearGradient(0, 0, this.$refs.canvas.width, 0)
        }
    }
}
