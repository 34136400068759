<template lang="pug">
    .panel.panel-primary(style="margin-bottom:10px")
        .panel-heading
            i.fa.fa-navicon.mr3
            span.mr3 {{'eShop.order.items.HEADING'|translate}}
            span \#{{order.id}}

            .pull-right \#{{order.created_at}}

        .table-responsive
            table.table.table-striped.middle
                thead
                    tr
                        th #
                        th {{'eShop.order.items.product.ID'|translate}}
                        th {{'eShop.order.items.product.NAME'|translate}}
                        th {{'eShop.order.items.BRUTTO_PRICE'|translate}}
                        th {{'eShop.order.items.QUANTITY'|translate}}
                        th {{'eShop.order.items.TAX_RATE'|translate}}
                        th {{'eShop.order.items.TOTAL_PRICE'|translate}}

                tbody
                    tr(v-for="(item, key) in order.items")
                        td.text-right {{order.items.length - key}}
                        td.text-right
                            OptiProduct(:product="item.product") {{item.product.id}}
                        td
                            OptiProduct(:product="item.product") {{item.product.name}}
                            small(style="display:block") {{item.product.fullName}}
                        td.text-right  {{item.brutto_price|currency}}
                        td.text-right {{item.quantity}}
                        td.text-right {{item.tax|currency}} ({{item.tax_rate}}%)
                        td.text-right {{item.total_price|currency}}

                    tr(v-show="!order.items.length")
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

                tfoot
                    tr
                        td(colspan="5" style="border:0")
                        td.text-right {{'eShop.order.price.POSITION'|translate}}
                        td.text-right {{order.position_price|currency}}
                    tr
                        td(colspan="5" style="border:0")
                        td.text-right {{'eShop.order.price.SHIPMENT'|translate}}
                        td.text-right {{order.shipment_price|currency}}
                    tr.bold
                        td(colspan="5" style="border:0")
                        td.text-right {{'eShop.order.price.WITHOUT_VAT'|translate}}
                        td.text-right {{order.total_price_without_vat|currency}}
                    tr(v-for="vat in order.vats")
                        td(colspan="5" style="border:0")
                        td.text-right {{'eShop.order.price.VAT'|translate}} {{vat.tax_rate}}%
                        td.text-right {{vat.tax_price|currency}}
                    tr.bold
                        td(colspan="5" style="border:0")
                        td.text-right {{'eShop.order.price.TOTAL'|translate}}
                        td.text-right {{order.total_price|currency}}
</template>

<script>
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'

    export default {
        name: 'OptiEShopOrderDetailsProductsTable',
        components: {
            OptiProduct
        },
        props: {
            order: {
                type: Object,
                required: true
            }
        }
    }
</script>