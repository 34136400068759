class PESELClass {
    constructor() {

    }

    validate(value) {
        let reg = /^[0-9]{11}$/

        if(!value) {
            return true
        } else if(reg.test(value) === false) {
            return false
        } else {
            let dig = ("" + value).split(""),
                kontrola = (1 * parseInt(dig[0]) + 3 * parseInt(dig[1]) +
                    7 * parseInt(dig[2]) + 9 * parseInt(dig[3]) +
                    1 * parseInt(dig[4]) + 3 * parseInt(dig[5]) +
                    7 * parseInt(dig[6]) + 9 * parseInt(dig[7]) +
                    1 * parseInt(dig[8]) + 3 * parseInt(dig[9])) % 10

            if(kontrola === 0) {
                kontrola = 10
            }

            kontrola = 10 - kontrola
            return parseInt(dig[10]) === kontrola
        }
    }

    calculate(data) {
        let year = data[0] + data[1],
            month = parseInt(data[2] + data[3]),
            day = data[4] + data[5]

        if((81 <= month) && (month <= 92)) {
            month -= 80
            year = '18' + year
        } else if((21 <= month) && (month <= 32)) {
            month -= 20
            year = '20' + year
        } else if((41 <= month) && (month <= 52)) {
            month -= 40
            year = '21' + year
        } else if((61 <= month) && (month <= 72)) {
            month -= 60
            year = '22' + year
        } else {
            year = '19' + year
        }

        if (month < 10) {
            month = '0' + month
        }

        return {
            sex: parseInt(data[9]) % 2 ? 1 : 2, // 1 - woman, 2 - man
            birthdate: year + '-' + month + '-' + day
        }
    }
}

export const PESEL = new PESELClass()