<template lang="pug">
    DatePicker(show-iso-weeknumbers
        v-model="date"
        :model-config="modelConfig"
        :masks="masks"
        :attributes="attributes"

        :is-dark="dark"
        :color="dark ? 'orange' : 'blue'")
        template(v-slot="{inputValue, inputEvents, togglePopover}")
            span.input-group.input-group-date
                input.form-control(:id="id" :placeholder="'default.date.FORMAT'|translate" autocomplete="off"
                    :disabled="disabled" :value="inputValue" v-on="inputEvents")
                span.input-group-btn
                    button.btn.btn-default(type="button" :disabled="disabled" @click="togglePopover")
                        i.fa.fa-calendar
</template>

<script>
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'

    export default {
        name: 'OptiDate',
        components: {
            DatePicker
        },
        props: {
            id: {
                type: String,
                required: false,
                default: 'date-picker'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: String,
                required: true
            }
        },
        data() {
            let format = 'YYYY-MM-DD'

            return {
                dark: !!this.$uac.user.dark,

                modelConfig: {
                    type: String,
                    mask: format
                },
                masks: {
                    dayPopover: format,
                    input: format
                },
                attributes: [{
                    key: 'today',
                    dot: 'red',
                    dates: new Date(),
                    popover: {
                        label: 'dzisiaj',
                        visibility: 'hover',
                        hideIndicator: true
                    }
                }]
            }
        },

        computed: {
            date: {
                get() {
                    return this.value
                },
                set(date) {
                    this.$emit('update-date', date ? moment(date).format('YYYY-MM-DD') : '')
                }
            }
        }
    }
</script>