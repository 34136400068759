<template lang="pug">
    .row
        .col-xs-12(v-if="!loading_questions")
            .row(style="margin-bottom:10px")
                .col-xs-9
                    h4.filter-header.page-header
                        span.text-muted(style="margin-right:5px") {{index_question_frames + 1}} / {{questions_frames.length}}
                        span {{'catalog.offer.questions.' + questions_frames[index_question_frames].name + '.HEADING'|translate}}

                .col-xs-3.text-right
                    OptiButton(@click.native="$store.commit('offer/setMode', 'filters')") {{'catalog.offer.switch.FILTERS'|translate}}
                        template(#icon)
                            i.fa.fa-filter

            .row
                // TODO: index_question_frames === 4 && option_key === 'sunglasses' to eksperyment
                .col-md-4.col-sm-6(v-for="(option, option_key) in questions_frames[index_question_frames].options" :key="option.name")
                    .question-option(:class="{'with-help': option.help, 'question-option-disabled': loading_products || (index_question_frames === 4 && option_key === 'sunglasses')}")
                        label.question-name(:for="questions_frames[index_question_frames].name + '-' + option_key"
                            :class="{'question-option-true': option.selected, 'question-option-false': !option.selected}"
                            :title="'catalog.offer.questions.' + questions_frames[index_question_frames].name + '.options.' + option_key.toUpperCase()|translate"
                            @click="$store.dispatch('offer/clickOption', index_question_frames === 4 && option_key === 'sunglasses')")
                            i.fa.fa-lg.mr3(:class="{'fa-check': option.selected, 'fa-remove': !option.selected}")
                            span {{'catalog.offer.questions.' + questions_frames[index_question_frames].name + '.options.' + option_key.toUpperCase()|translate}}

                        // TODO: index_question_frames === 4 && option_key === 'sunglasses' to eksperyment
                        input.hide(:id="questions_frames[index_question_frames].name + '-' + option_key" type="checkbox"
                            :disabled="loading_products || (index_question_frames === 4 && option_key === 'sunglasses')" :checked="option.selected" @change="e => updateOption(option_key, e)")

                        .help(:title="$filters.translate('catalog.offer.title.HELP') + ' - ' + $filters.translate('catalog.offer.questions.' + questions_frames[index_question_frames].name + '.options.' + option_key.toUpperCase())"
                            :class="{'help-selected': help.index_question_frames === index_question_frames && help.option_key === option_key}"
                            @click="$store.commit('offer/setHelp', {index_question_frames: index_question_frames, option_key: option_key})")
                            i.fa.fa-lg.fa-question

            .row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg(style="margin-top:10px")
                    OptiButton(v-show="index_question_frames" :disabled="loading_products"
                        @click.native="goPreviousQuestion") {{'catalog.offer.questions.navi.PREVIOUS'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                .col-md-4.text-center(style="display:flex;justify-content:space-around;margin-top:10px")
                    OptiButton(type="success" :disabled="disabled_fetch" @click.native="$store.dispatch('offer/loadProducts')"
                        ) {{'catalog.offer.questions.FETCH'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton(type="success" v-show="index_question_frames > 0" :disabled="loading_products"
                        @click.native="$store.dispatch('offer/goFinish')") {{'catalog.offer.questions.FINISH'|translate}}
                        template(#icon)
                            i.fa.fa-check

                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg(style="margin-top:10px")
                    OptiButton(type="success" :right="true" v-show="index_question_frames + 1 !== questions_frames.length"
                        :disabled="!sth_checked || loading_products" @click.native="goNextQuestion") {{'catalog.offer.questions.navi.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                    OptiButton(type="success" :right="true" v-show="!is_last_step && index_question_frames + 1 === questions_frames.length"
                        :disabled="!sth_checked || loading_products" @click.native="$store.commit('offer/setMode', 'questions_hoya')"
                        ) {{'catalog.offer.questions.navi.GO_TO_HOYA'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

        .col-xs-12
            OptiDimmer(:active="loading_questions")
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogOfferQuestionsFrames',
        components: {
            OptiButton,
            OptiDimmer
        },
        mounted() {
            this.$store.dispatch('offer/loadQuestions')
        },
        methods: {
            updateOption(key, event) {
                this.$store.dispatch('offer/updateQuestionFramesOption', {
                    key: key,
                    bool: event.target.checked
                })
            },

            goPreviousQuestion() {
                this.$store.commit('offer/setIndexQuestionFrames', this.index_question_frames - 1)
            },
            goNextQuestion() {
                this.$store.commit('offer/setIndexQuestionFrames', this.index_question_frames + 1)
            }
        },
        computed: {
            questions_frames() {
                return this.$store.getters['offer/getQuestionsFrames']
            },
            index_question_frames() {
                return this.$store.getters['offer/getIndexQuestionFrames']
            },
            sth_checked() {
                return this.$store.getters['offer/sthChecked']
            },

            is_last_step() {
                return this.$store.getters['offer/isLastStep']
            },
            help() {
              return this.$store.getters['offer/getHelp']
            },
            disabled_fetch() {
                return this.$store.getters['offer/disabledFetch']
            },
            loading_questions() {
                return this.$store.getters['offer/getLoading']('questions')
            },
            loading_products() {
                return this.$store.getters['offer/getLoading']('products')
            }
        }
    }
</script>