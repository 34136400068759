<template lang="pug">
    .row
        .col-sm-6(v-if="!loading")
            form.panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span(v-show="priceChange.product.id") {{'prices.pricetochange.EDIT'|translate}} {{priceChange.product.id}} / {{priceChange.product.name}}
                    span(v-show="!priceChange.product.id") {{'prices.breadcrumb.product.ADD'|translate}}

                table.table.table-striped.middle
                    tbody
                        tr
                            th {{'prices.prices.PRODUCT_ID'|translate}}
                            td
                                .form-control.text-right(disabled) {{priceChange.product.id}}
                        tr
                            th {{'prices.prices.PRODUCT_NAME'|translate}}
                            td
                                OptiSelectProduct(:disabled="disabled" :product="priceChange.product" @selected="updateProduct")
                        tr
                            th {{'prices.prices.LAUNCH_PRICE'|translate}}
                            td
                              OptiNumber(:header="'prices.prices.LAUNCH_PRICE'|translate" :disabled="disabled"
                                :value="priceChange.launch_price_value" @confirmed="v => priceChange.launch_price_value = v"
                                :float="true") {{priceChange.launch_price_value|currency}}
                        tr
                            th {{'prices.prices.OMNIBUS_PRICE'|translate}}
                            td
                              OptiNumber(:header="'prices.prices.OMNIBUS_PRICE'|translate" :disabled="disabled"
                                :value="priceChange.omnibus_price_value" @confirmed="v => priceChange.omnibus_price_value = v"
                                :float="true") {{priceChange.omnibus_price_value|currency}}
                        tr
                            th {{'prices.prices.NEW_RETAIL_PRICE'|translate}}
                            td
                              OptiNumber(:header="'prices.prices.NEW_RETAIL_PRIC'|translate" :disabled="disabled"
                                :value="priceChange.new_retail_price_value" @confirmed="v => priceChange.new_retail_price_value = v"
                                :float="true") {{priceChange.new_retail_price_value|currency}}
                        tr
                            th {{'prices.prices.OLD_SPECIAL_PRICE_VALUE'|translate}}
                            td
                                OptiNumber(:header="'prices.prices.OLD_SPECIAL_PRICE_VALUE'|translate" :disabled="disabled"
                                    :value="priceChange.old_special_price_value" @confirmed="v => priceChange.old_special_price_value = v"
                                    :float="true") {{priceChange.old_special_price_value|currency}}
                        tr
                            th {{'prices.prices.NEW_SPECIAL_PRICE_VALUE'|translate}}
                            td
                                OptiNumber(:header="'prices.prices.NEW_SPECIAL_PRICE_VALUE'|translate" :disabled="disabled"
                                    :value="priceChange.new_special_price_value" @confirmed="v => priceChange.new_special_price_value = v"
                                    :float="true") {{priceChange.new_special_price_value|currency}}
                        tr
                            th {{'prices.prices.OLD_PRODUCT_PRICE'|translate}}
                            td
                                .form-control.text-right(disabled) {{priceChange.old_product_price|currency}}
                        tr
                            th {{'prices.prices.NEW_PRODUCT_PRICE'|translate}}
                            td
                                OptiNumber(:header="'prices.prices.NEW_PRODUCT_PRICE'|translate" :disabled="disabled"
                                    :value="priceChange.new_product_price" @confirmed="v => priceChange.new_product_price = v"
                                    :float="true") {{priceChange.new_product_price|currency}}
                        tr
                            th {{'prices.prices.OLD_PROD_PRODUCT_PRICE'|translate}}
                            td
                                .form-control.text-right(disabled) {{priceChange.old_prod_product_price|currency}}
                        tr
                            th {{'prices.prices.NEW_PROD_PRODUCT_PRICE'|translate}}
                            td
                                OptiNumber(:header="'prices.prices.NEW_PROD_PRODUCT_PRICE'|translate" :disabled="disabled"
                                    :value="priceChange.new_prod_product_price" @confirmed="v => priceChange.new_prod_product_price = v"
                                    :float="true") {{priceChange.new_prod_product_price|currency}}
                        tr
                            th {{'prices.prices.ZHC'|translate}}
                            td
                              OptiNumber(:header="'prices.prices.ZHC'|translate" :disabled="disabled"
                                :value="priceChange.zhc" @confirmed="v => priceChange.zhc = v"
                                :float="true") {{priceChange.zhc|currency}}
                        tr
                            th {{'prices.prices.category.CURRENT'|translate}}
                            td
                                .form-control(style="width:100%" disabled) {{priceChange.currentCatalog.name}}
                        tr
                            th {{'prices.prices.category.MOVETO'|translate}}
                            td
                                OptiSelectBrand(:disabled="disabled" :category="priceChange.destinationCatalog"
                                    @selected="c => priceChange.destinationCatalog = c")
                .panel-footer
                    OptiButton(type="danger" :disabled="disabled" text="prices.delete.confirm" @confirmed="removeProduct"
                        ) {{'prices.delete.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-remove

                    OptiButtonSave.pull-right(:disabled="disabled || !priceChange.product.id" @click.native="save")

        .col-sm-6(v-if="!loading && priceChange.product.id")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-camera.mr3
                    span {{'prices.prices.PRODUCT_PHOTO'|translate}}
                .panel-body
                    img(:src="'thumb/product/ids/' + priceChange.product.id + '.jpg'")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectBrand from '@/js/app/vue/components/Select/OptiSelectBrand'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductPriceChangeProduct',
        components: {
            OptiSelectProduct,
            OptiNumber,
            OptiButton,
            OptiSelectBrand,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                priceChange: {},

                disabled: true,
                loading: true
            }
        },
        mounted() {
            API.get('prices/edit/priceChange/' + this.$state.params.id).then(res => {
                this.priceChange = res.data

                this.$emit('update-breadcrumb', this.priceChange.promotion_id, this.priceChange.product ? 'EDIT' : 'ADD')

                if(!Object.keys(this.priceChange.product).length) {
                    this.priceChange.product = {}
                }
                if(!Object.keys(this.priceChange.currentCatalog).length) {
                    this.priceChange.currentCatalog = {
                        id: 0,
                        name: ''
                    }
                }
                if(!Object.keys(this.priceChange.destinationCatalog).length) {
                    this.priceChange.destinationCatalog = {
                        id: 0,
                        name: ''
                    }
                } else {
                    this.priceChange.destinationCatalog.type =
                        this.priceChange.destinationCatalog.parentId === 509 ? 'ramki' : 'słońce'
                }

                this.disabled = this.priceChange.promotion.status !== 1
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('prices.prices.notify.error.LOAD')
            })
        },
        methods: {
            updateProduct(product) {
                this.priceChange.product = product

                this.priceChange.currentCatalog = product.customerProduct.category
            },
            removeProduct() {
                this.loading = true

                API.get('prices/edit/priceChange/' + this.$state.params.id + '/delete').then(res => {
                    this.$notify.success('prices.prices.notify.DELETE')

                    this.$state.go('app.prices.edit', {
                        id: res.data
                    })
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('prices.prices.notify.error.DELETE')
                })
            },
            save() {
                this.loading = true

                API.post('prices/edit/priceChange/' + this.$state.params.id + '/updatePriceChange', {
                    'changes': this.priceChange
                }).then(() => {
                    this.$notify.success('prices.prices.notify.SAVE')

                    this.$state.go('app.prices.edit', {
                        id: this.priceChange.promotion_id
                    })
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('prices.prices.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .form-control {
        width: 120px;
    }

    img {
        width: 100%;
    }
</style>