<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary(style="position:relative;margin-bottom:0")
                table.table.table-striped
                    thead
                        tr.align-middle
                            th(style="width:120px") {{'dictionary.hoya.table.FVCODE'|translate}}
                            th(style="width:120px") {{'dictionary.hoya.table.ILOGCODE'|translate}}
                            th {{'dictionary.hoya.table.SHORTNAME'|translate}}
                            th
                                span {{'dictionary.hoya.table.LONGNAME'|translate}}
                                span(style="margin-left:3px" v-show="iLog_name") /
                                div(v-show="iLog_name") {{'dictionary.hoya.table.ILOGNAME'|translate}}
                            th.text-right {{'dictionary.hoya.table.HOYABRUTTO'|translate}}
                            th.text-right {{'dictionary.hoya.table.HOYANETTO'|translate}}
                            th.text-right {{'dictionary.hoya.table.CATALOG'|translate}}
                            th.text-right
                                .hoya-price-edit
                                    span.mr3 {{'dictionary.hoya.table.NETTO'|translate}}
                                    .input-group(v-if="edit")
                                        input.form-control(v-model="netto_percent" @change="changedNettoPercentAll")
                                        span.input-group-addon %
                                    span.text-muted(v-if="!edit && netto_percent") {{netto_percent}}%
                            th.text-right {{'dictionary.hoya.table.ZHC'|translate}}
                    tbody
                        tr.align-top(v-for="(item, index) in category.items" :key="index" v-show="item.active")
                            td {{item.fv_code}}
                            td {{item.ilog_code}}
                            td {{item.name_short}}
                            td
                                span {{item.name_long}}
                                div(v-show="iLog_name")
                                    small(style="white-space:pre" v-show="!item.edit && item.name_iLog"
                                        ) {{item.name_iLog.split('|').join("\r\n")}}
                            td.text-right {{item.hoya_brutto|currency}}

                            td.text-right {{item.hoya_netto|currency}}

                            td.text-right(style="border-left:solid;border-width:1px;white-space:nowrap")
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.brutto_price")
                                    input.form-control(v-if="edit" v-model="item.brutto_price"
                                        @input="item.changes.brutto_price = true")
                                    span(v-else) {{item.brutto_price|currency}}
                            td.text-right(style="white-space:nowrap")
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.netto_price")
                                    div(v-if="edit")
                                        .input-group
                                            input.form-control(v-model="item.netto_percent" @input="changedNettoPercent(index)")
                                            span.input-group-addon %
                                        .text-muted {{item.netto_price}}
                                    span(v-else)
                                        span.mr3 {{item.netto_price}}
                                        small.text-muted ({{item.netto_percent}}%)
                            td.text-right(style="white-space:nowrap")
                                .hoya-price-edit
                                    i.fa.fa-exclamation-circle.text-muted.mr3(v-show="item.changes.zhc")
                                    input.form-control(v-if="edit" v-model="item.zhc" @input="changedZhc(index)")
                                    span(v-else) {{item.zhc|currency}}

                OptiDimmer(:active="loading")
</template>

<script>
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiHoyaItemEdit',
        components: {
            OptiDimmer
        },
        props: {
            edit: {
                type: Boolean,
                required: true
            },
            category: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                iLog_name: ['polarized', 'polarized_fine', 'sensity'].includes(this.category.type) ||
                    this.category.type.startsWith('coloration_'),

                netto_percent: null,

                loading: false
            }
        },
        mounted() {
            this.setNettoPercent()
        },
        methods: {
            changedNettoPercentAll() {
                this.category.items.forEach((item, index) => {
                    if(this.netto_percent && item.active && parseInt(item.netto_percent) !== parseInt(this.netto_percent)) {
                        item.netto_percent = this.netto_percent

                        this.changedNettoPercent(index, false)
                    }
                })
            },

            changedNettoPercent(index, setNettoPercent) {
                setNettoPercent = typeof setNettoPercent === 'undefined' ? true : setNettoPercent

                this.category.items[index].changes.netto_percent = true
                this.category.items[index].changes.netto_price = true

                this.nettoPrice(index)

                if(setNettoPercent) {
                    this.setNettoPercent()
                }
            },
            changedZhc(index) {
                this.category.items[index].changes.zhc = true
                this.category.items[index].changes.netto_price = true

                this.nettoPrice(index)
            },

            nettoPrice(index) {
                this.category.items[index].netto_price = Math.ceil(
                    (this.category.items[index].zhc * (1 + this.category.items[index].netto_percent / 100)))
            },

            setNettoPercent() {
                this.netto_percent = this.category.items[0].netto_percent

                this.category.items.forEach(item => {
                    if(item.active && parseInt(item.netto_percent) !== parseInt(this.netto_percent)) {
                        this.netto_percent = null
                    }
                })
            }
        }
    }
</script>