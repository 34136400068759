<template lang="pug">
    OptiDialog
        template(#header)
            i.icon.icon-book-open.mr3
            span {{'prescription.HISTORY'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .table-responsive
                table.table.table-presenter.table-striped.middle
                    thead
                        tr
                            th #
                            th.hidden-md.hidden-sm.hidden-xs {{'default.DESCRIPTION'|translate}}
                            th {{'default.DETAILS'|translate}}
                            th {{'default.USER'|translate}}
                            th {{'default.date.LABEL'|translate}}
                    tbody
                        tr(v-for="(log, key) in logs")
                            td.text-right {{logs.length - key}}
                            td.hidden-md.hidden-sm.hidden-xs {{log.description}}
                            td(v-html="log.details")
                            td {{log.user}}
                            td {{log.date}}

        template(#footer)
            OptiButton(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiPatientsPrescriptionDialogHistory',
        components: {
            OptiDialog,
            OptiButton
        },
        props: {
            logs: {
                type: Array,
                required: true
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container > .modal-body {
        padding: 0;
    }
</style>