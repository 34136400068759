<template lang="pug">
    .row
        .col-md-8.col-md-offset-2
            .alert.alert-info
                i.fa.fa-lightbulb-o.mr3
                span {{'bugtrack.changelog.notify.REFRESH'|translate}}
                a.bold(style="text-decoration:underline;padding-left:5px;color:inherit"
                    href="czyszczenie_cache_przegladarek.pdf" target="_blank"
                    :title="'bugtrack.changelog.attachment.TITLE'|translate") ({{'bugtrack.changelog.attachment.NAME'|translate}})

        .col-xs-12.text-center(style="margin-bottom:20px")
            OptiButton(type="purple" @click.native="refresh") {{'bugtrack.changelog.notify.REFRESH_BUTTON'|translate}}
                template(#icon)
                    i.fa.fa-refresh

        .col-xs-12
            .list-group(style="margin:0" v-show="!loading")
                .list-group-item(v-for="changelog in changelogs")
                    div(style="padding-bottom:10px")
                        span.bold.text-expressive
                            i.fa.fa-calendar.mr3
                            span {{changelog.date|stringTime}}
                        .pull-right
                            .label.label-danger {{changelog.count}}

                    div(v-for="data in changelog.data")
                        div(style="padding-left:5px")
                            i.fa.fa-angle-double-up(v-show="data.type_id === 1")
                            i.fa.fa-bug(v-show="data.type_id === 2")
                            i.fa.fa-rocket(v-show="data.type_id === 3")
                            span(style="margin-left:3px") {{data.type}}
                            
                        ul(style="list-style-type:none")
                            li(style="padding:5px 0" v-for="log in data.log" :class="{'text-success': latest && log.id > latest}")
                                small.mr3 {{log.date}}
                                span
                                    span(v-show="!log.error_id") {{log.description}}
                                    a.bold(v-show="log.error_id" :title="'default.REFERENCETYPE'|translate"
                                        :class="{'text-success': latest && log.id > latest, 'link': !(latest && log.id > latest)}"
                                        :href="$state.href('app.error.details', {id: log.error_id})") {{log.description}}
            OptiDimmer(:active="loading")

        .col-xs-12.text-center(style="margin-top:20px")
            OptiPaginator(
                :paginator="paginator"
                @changed-page="load")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPaginator from '@/js/app/vue/components/OptiPaginator'

    export default {
        name: 'OptiErrorsChangelog',
        components: {
            OptiButton,
            OptiDimmer,
            OptiPaginator
        },
        props: {

        },
        data() {
            return {
                changelogs: [],
                latest: null,

                paginator: {
                    pages: 1,
                    page: 1
                },

                loading: true
            }
        },
        mounted() {
            let storage = JSON.parse(window.localStorage.getItem('ngStorage-changelog'))
            this.latest = storage !== null ? storage.previous : false

            this.load(1)
        },
        methods: {
            load(page) {
                this.loading = true
                this.paginator.page = page

                API.post('error/changelog', {
                    page: this.paginator.page
                }).then(res => {
                    this.changelogs = res.data.data

                    this.paginator.pages = res.data.pages
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('bugtrack.changelog.notify.error.LOAD')
                })
            },

            refresh() {
                window.localStorage.clear()
                window.location.reload(true)
            }
        }
    }
</script>
