<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-default
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.forecast.range.HEADING'|translate}}

                .panel-body(style="position:relative")
                    .row
                        .col-sm-6
                            .row
                                .col-sm-7
                                    OptiSelectQuarterOrSnapshot(
                                        :quarter="quarter"
                                        :week="week"

                                        @select-weeks-was-loaded="loading.select = false")

                                .col-sm-5.text-center(style="margin:10px 0")
                                    OptiButton(type="success" @click.native="getForecastData"
                                        ) {{'report.forecast.range.DOWNLOAD'|translate}}

                        .col-sm-6
                            div(v-show="$uac.user.activeRole.admin")
                                OptiCheckboxDepartment(
                                    :ids="selectedDepartmentIds"
                                    :small="true"
                                    :previewClosed="true"
                                    :startOpened="false"
                                    :all="true"

                                    :recently-closed="true"

                                    @department-ids="updateDepartmentIds")

                    OptiDimmer(:active="loading.main || loading.select")

        .col-xs-12.text-right.warn(v-show="missingWeeks")
            i.fa.fa-exclamation-circle.mr3
            span {{'report.forecast.MISSING'|translate}}: {{missingWeeks}}.

        .col-xs-12
            .panel.panel-default
                table.table.table-bordered.table-striped.table-condensed.table-vertical.middle
                    thead
                        tr
                            th.text-center(colspan="3")
                                .text-muted(v-show="label")
                                    i.fa.fa-calendar.mr3
                                    small.text-muted {{label}}
                            th.text-center(colspan="2") {{'report.forecast.table.header.costs.FIXED'|translate}}
                            th.text-center(colspan="2") {{'report.forecast.table.header.costs.VARIABLE'|translate}}
                            th.text-right(rowspan="2")
                                div ∑
                                .small.text-muted {{'report.forecast.table.header.sum.MARGIN'|translate}}
                                .small.text-muted {{'report.forecast.table.header.sum.total.FIXED'|translate}}
                                .small.text-muted {{'report.forecast.table.header.sum.total.VARIABLE'|translate}}
                        tr
                            th.text-right #
                            th {{'report.forecast.table.header.DEPARTMENT'|translate}}
                            th.text-right {{'report.forecast.table.header.MARGINTOTAL'|translate}}
                            th.text-right {{'report.forecast.table.header.TOTAL'|translate}}
                            th.text-right {{'report.forecast.table.header.TASKS'|translate}}
                            th.text-right {{'report.forecast.table.header.TOTAL'|translate}}
                            th.text-right {{'report.forecast.table.header.TASKS'|translate}}

                    tbody
                        tr(v-for="(department, index) in departments")
                            td.text-right {{index + 1}}
                            td {{department.login}}
                            template(v-if="department.turnover !== 'init' && department.turnover !== 'error'")
                                td.text-right.monospace {{department.turnover|cash|currency({fractionCount:1})}}
                                td.text-right.monospace
                                    a.link(v-show="bookkeeping_expenses" target="_blank" :href="hrefExpenses(department, 'fixed')") {{department.expenses.fixed|cash|currency({fractionCount:1})}}
                                    span(v-show="!bookkeeping_expenses") {{department.expenses.fixed| cash|currency({fractionCount:1})}}
                                td.text-right.monospace
                                    a.link(v-show="bookkeeping_expenses" target="_blank" :href="hrefExpenses(department, 'fixed', 'salary')"
                                        ) {{department.expenses.fixedEmployee|cash|currency({fractionCount:1})}}
                                    span(v-show="!bookkeeping_expenses") {{department.expenses.fixedEmployee|cash|currency({fractionCount:1})}}
                                td.text-right.monospace
                                    a.link(v-show="bookkeeping_expenses" target="_blank" :href="hrefExpenses(department, 'variable')") {{department.expenses.variable|cash|currency({fractionCount:1})}}
                                    span(v-show="!bookkeeping_expenses") {{department.expenses.variable|cash|currency({fractionCount:1})}}
                                td.text-right.monospace
                                    a.link(v-show="bookkeeping_expenses && !week.is_current" target="_blank" :href="hrefExpenses(department, 'variable', 'salary')") {{department.expenses.variableEmployee|cash|currency({fractionCount:1})}}
                                    span(v-show="!bookkeeping_expenses && !week.is_current") {{ department.expenses.variableEmployee|cash|currency({fractionCount:1})}}
                                    a.link(v-show="week.is_current" target="_blank" :href="$state.href('app.dictionary.bonus.show', {department_id: department.id})"
                                        ) {{department.expenses.variableEmployee|cash|currency({fractionCount:1})}}
                                td.text-right.monospace {{department.total|cash|currency({fractionCount:1})}}

                            template(v-if="department.turnover === 'init'")
                                td(colspan="100")
                                    i.fa.fa-spin.fa-spinner
                            template(v-if="department.turnover === 'error'")
                                td(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'report.forecast.notify.error.LOAD'|translate}}

                        tr.text-bold(v-if="departments.length > 1" style="border-top:2px solid black")
                            td.text-right(colspan="2") {{'report.forecast.table.TOTAL'|translate}}
                            td.text-right.monospace {{totals.turnover|cash|currency({fractionCount:1})}}
                            td.text-right.monospace {{totals.expenses.fixed|cash|currency({fractionCount:1})}}
                            td.text-right.monospace {{totals.expenses.fixedEmployee|cash|currency({fractionCount:1})}}
                            td.text-right.monospace {{totals.expenses.variable|cash|currency({fractionCount:1})}}
                            td.text-right.monospace {{totals.expenses.variableEmployee|cash|currency({fractionCount:1})}}
                            td.text-right.monospace {{totals.total|cash|currency({fractionCount:1})}}

                        tr(v-show="!departments.length")
                            td.text-center.text-muted(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectQuarterOrSnapshot from '@/js/app/vue/components/Select/OptiSelectQuarterOrSnapshot'
    import OptiButton from '../Button/OptiButton'
    import OptiCheckboxDepartment from '../Checkbox/OptiCheckboxDepartment'
    import OptiDimmer from '../Blocks/OptiDimmer'

    export default {
        name: 'OptiReportForecast',
        components: {
            OptiSelectQuarterOrSnapshot,
            OptiButton,
            OptiCheckboxDepartment,
            OptiDimmer
        },
        data() {
            return {
                label: null,

                week: {
                    is_current: false,

                    from: {},
                    to: {}
                },
                quarter: {
                    from: {
                        q: null,
                        y: null
                    },
                    to: {
                        q: null,
                        y: null
                    }
                },

                departments: [],
                departmentsData: {},
                selectedDepartmentIds: [],

                bookkeeping_expenses: this.$uac.permission('bookkeeping.expenses'),
                missingWeeks: 0,
                loading: {
                    main: false,
                    select: true
                },

                totals: {
                    turnover: 0,
                    expenses: {
                        fixed: 0,
                        fixedEmployee: 0,
                        variable: 0,
                        variableEmployee: 0
                    },
                    total: 0
                }
            }
        },
        filters: {
            cash(value) {
                return value / 1000
            }
        },
        methods: {
            resetTotals() {
                this.totals.turnover = 0
                this.totals.expenses.fixed = 0
                this.totals.expenses.fixedEmployee = 0
                this.totals.expenses.variable = 0
                this.totals.expenses.variableEmployee = 0
                this.totals.total = 0
            },
            setDepartmentData(department, data) {
                if(data === false) {
                    department.turnover = 'error'
                    return
                }

                department.turnover                  = data.turnover
                department.expenses.fixed            = data.info.expenses.fixed
                department.expenses.fixedEmployee    = data.info.expenses.fixedEmployee
                department.expenses.variable         = data.info.expenses.variable
                department.expenses.variableEmployee = data.info.expenses.variableEmployee

                department.total = department.turnover + department.expenses.fixed + department.expenses.variable

                this.totals.turnover += department.turnover
                this.totals.expenses.fixed += department.expenses.fixed
                this.totals.expenses.fixedEmployee += department.expenses.fixedEmployee
                this.totals.expenses.variable += department.expenses.variable
                this.totals.expenses.variableEmployee += department.expenses.variableEmployee
                this.totals.total += department.total
            },
            async getDepartments(from, to) {
                try {
                    let {data} = await API.post('reports/forecast-departments', {
                        from: from,
                        to: to
                    })

                    return data
                } catch(e) {
                    console.log(e)
                }

                return false
            },
            async getForecastData() {
                this.loading.main = true
                this.label = null

                this.resetTotals()

                try {
                    this.departments = await this.getDepartments(this.week.from.id, this.week.to.id)
                    this.$set(this, 'departmentsData', [])

                    // z możliwych zostawiamy tylko te, które były zaznaczone
                    this.departments = this.departments.filter(department => {
                        return this.selectedDepartmentIds.includes(department.id)
                    })

                    // z pozostałych zostawiamy tylko te do których użytkownik ma dostęp
                    let userDepartments = this.$uac.user.department.flatMap(dep => dep.departmentId)
                    this.departments = this.departments.filter(department => {
                        return userDepartments.includes(department.id)
                    })

                    this.label = this.week.from.start + ' - ' + this.week.to.end

                    await Promise.all(this.departments.map(async (department) => {
                        try {
                            let {data: {data, missingWeeks}} = await API.post('reports/forecast', {
                                from: this.week.from.id,
                                to: this.week.to.id,
                                departmentId: department.id
                            })

                            this.missingWeeks = missingWeeks
                            this.setDepartmentData(department, data)
                        } catch (e) {
                            this.setDepartmentData(department, false)
                        }

                        return true
                    }))
                } catch (e) {
                    console.log(e)
                }

                this.loading.main = false

                console.log('done!')
            },
            updateDepartmentIds(ids) {
                this.selectedDepartmentIds = ids
            },
            hrefExpenses(department, costs, type) {
                let search = [
                    'departmentName:=:' + department.name,
                    'date:>=:' + this.week.from.start,
                    'date:<=:' + this.week.to.end,
                    'costs:=:' + costs
                ]

                if(typeof type !== 'undefined') {
                    search.push('type:=:' + type)
                }

                return this.$state.href('app.bookkeeping.expenses', {
                    search: search
                })
            }
        }
    }
</script>
