<template lang="pug">
    .col-xs-12(v-show="recommended_visits.length")
        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'prescription.recommended.HEADING'|translate}}
            .table-responsive
                table.table.table-striped
                    thead
                        tr
                            th Id
                            th {{'prescription.recommended.table.CREATEDAT'|translate}}
                            th {{'prescription.recommended.table.CREATEDBY'|translate}}
                            th {{'prescription.recommended.table.RECOMMENDEDDOCTOR'|translate}}
                            th
                    tbody
                        template(v-for="(recommended_visit, key) in recommended_visits")
                            tr
                                td
                                    a.link(:href="$state.href('app.optometrist.details', {id: recommended_visit.id})"
                                        :title="'default.REFERENCETYPE'|translate") {{recommended_visit.id}}
                                td {{recommended_visit.date|noYearSek}}
                                td {{recommended_visit.user.lastName}} {{recommended_visit.user.firstName}}
                                td {{recommended_visit.doctor.lastName}} {{recommended_visit.doctor.firstName}}
                                td.text-center(style="vertical-align:middle" rowspan="3")
                                    OptiButton(type="success" :disabled="!recommended_visit.recommended_visit_return"
                                        @click.native="saveInterviewCommentReturn(recommended_visit)"
                                        )  {{'prescription.recommended.table.action.BACK'|translate}}
                                        template(#icon)
                                            i.fa.fa-repeat
                            tr
                                td(colspan="4")
                                    span.bold.mr3 {{'optometrist.disease.doctor.REASON'|translate}}:
                                    span {{recommended_visit.recommended_visit_reason}}
                            tr
                                td(colspan="4")
                                    form.form.form-horizontal(:name="'formRecommended_' + key")
                                        .form-group
                                            label.col-xs-12.star-required(:for="'recommended_visit_return_' + key") {{'prescription.COMMENTS'|translate}}
                                            .col-xs-12
                                                textarea.form-control(:id="'recommended_visit_return_' + key" name="recommended_visit_return"
                                                    v-model="recommended_visit.recommended_visit_return" required)
                                                label.warn(:for="'recommended_visit_return_' + key"
                                                    v-show="!recommended_visit.recommended_visit_return") {{'validation.formValidate.required'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiPatientsDetailsPrescriptionsRecommendedVisits',
        components: {
            OptiButton
        },
        data() {
            return {
                recommended_visits: []
            }
        },
        mounted() {
            API.get('patient/recommended_visit/fromPatientId/' + this.$state.params.id).then(res => {
                this.recommended_visits = res.data
            }).catch(() => {
                this.$notify.error('prescription.recommended.notify.error.RECOMMENDED_VISIT')
            })
        },
        methods: {
            saveInterviewCommentReturn(recommended_visit) {
                API.post('patient/recommended_visit/save-comment-return', {
                    interview_id: recommended_visit.id,
                    recommended_visit_return: recommended_visit.recommended_visit_return
                }).then(res => {
                    this.$state.go('app.patient.prescription-add', {
                        patientId: this.$state.params.id,
                        '#': 'interview-' + res.data.id
                    })
                }).catch(() => {
                    this.$notify.error('prescription.recommended.notify.error.SAVECOMMENT')
                })
            }
        }
    }
</script>
