<template lang="pug">
    .question-edit-option
        h5.page-header(:title="question.attributes.length ? ('default.toggle.' + (option.open ? 'HIDE': 'SHOW')) : ''|translate"
            :class="{'editable': question.attributes.length}" @click="openOption(question.attributes.length, option)")
            small.text-muted.mr3 {{keyOption + 1}}
            span {{'catalog.offer.questions.' + question.name + '.options.' + option.name.toUpperCase()|translate}}
            span.text-muted.ml3(v-show="isAll") - {{'settings.offer.questions.btn.SELECT_ALL'|translate|lower}}
            i.fa.fa-edit.ml3(v-show="option.edit_mode")
            span.warn(style="margin-left:10px" v-show="!isEqual")
                i.fa.fa-exclamation-circle.mr3
                span {{'settings.offer.questions.mode_edit.UNSAVED_CHANGED'|translate}}

            i.fa.pull-right(:class="{'fa-angle-double-up': question.attributes.length && option.open, 'fa-angle-double-down': question.attributes.length && !option.open, 'fa-ban': !question.attributes.length}")

        .row(v-if="question.attributes.length")
            .col-sm-9(v-show="option.open")
                .offer-attributes
                    .label-offer(v-for="s in selected_to_edit_or_all" :class="{'label-offer-group': option.edit_mode}")
                        .label.label-info(:title="s" @click="dialog.products = {attribute_ids: question.ids, value: s}"
                            ) {{s}}
                        .label.label-danger(:title="'settings.offer.questions.actions.DELETE'|translate"
                            v-show="option.edit_mode" @click="deleteAttribute(s)")
                            i.fa.fa-close

                    .warn(v-show="!selected_to_edit.length")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'settings.offer.questions.NO_ATTRIBUTES'|translate}}

                .offer-attributes-edit-mode(v-show="option.edit_mode")
                    OptiButton(type="success" size="sm" :disabled="isEqual || !selected_to_edit.length"
                        text="settings.offer.questions.btn.save"
                        @confirmed="$emit('save', question.name, option.name, selected_to_edit)"
                        ) {{'default.SAVE'|translate}}
                        template(#icon)
                            i.fa.fa-save

                    OptiButton(type="success" size="sm" :disabled="isAll" @click.native="dialog.add = true"
                        ) {{'settings.offer.questions.btn.ADD'|translate}}
                            template(#icon)
                                i.fa.fa-plus

                    MountingPortal(v-if="dialog.add" mountTo="#vue-modal" append)
                        OptiSettingsOfferQuestionsAttributeAddDialog(
                            :question="question"
                            :option="option"

                            :selected="selected_to_edit"

                            @add="addAttribute"
                            @close="dialog.add = false")

                    OptiButton(type="success" size="sm" :disabled="isAll" @click.native="selectAll"
                        ) {{'settings.offer.questions.btn.SELECT_ALL'|translate}}
                            template(#icon)
                                i.fa.fa-check

                    OptiButton(size="sm" :disabled="isEqual" @click.native="reset"
                        ) {{'settings.offer.questions.btn.RESET'|translate}}
                            template(#icon)
                                i.fa.fa-undo

                .ml3(v-show="diff.added.length")
                    span.text-success
                        i.fa.fa-plus.mr3
                        span {{'settings.offer.questions.logs.ADDED'|translate}}:
                    span
                        .btn-offer-attribute(v-for="added in diff.added")
                            span(:title="added" @click="dialog.products = {attribute_ids: question.ids, value: added}"
                                ) "{{added}}"
                    span .

                .ml3(v-show="diff.deleted.length")
                    span.text-danger
                        i.fa.fa-trash.mr3
                        span {{'settings.offer.questions.logs.DELETED'|translate}}:
                    span
                        .btn-offer-attribute(v-for="deleted in diff.deleted")
                            span(:title="deleted" @click="dialog.products = {attribute_ids: question.ids, value: deleted}"
                                ) "{{deleted}}"
                    span .

            .col-sm-3.text-center(v-show="option.open")
                .btn.btn-default(v-if="option.edit_mode" @click="editMode(false)")
                    i.fa.fa-ban
                    div {{'settings.offer.questions.mode_edit.OFF'|translate}}

                .btn.btn-default(v-else @click="editMode(true)")
                    i.fa.fa-edit
                    div {{'settings.offer.questions.mode_edit.ON'|translate}}

            OptiProductsListDialog(v-if="dialog.products"
                endpoint="settings/offer/questions/products"
                :params="dialog.products"

                @close="dialog.products = null")
                template(#header)
                    i.icon-eyeglasses

                    span.ml30
                        span.mr3 {{'settings.offer.questions.products.ATTRIBUTE_TYPE'|translate}}
                        span.bold {{'catalog.offer.questions.attributes.' + dialog.products.attribute_ids[0]|translate}}

                    span.ml30
                        span.mr3 {{'settings.offer.questions.products.NAME'|translate}}
                        span.bold(:class="{'warn': !dialog.products.value}") {{dialog.products.value ? dialog.products.value : $filters.translate('settings.offer.questions.difference.NO_TRANSLATE')}}

        .row(v-else)
            .col-xs-12.text-center.text-muted
                i.fa.fa-exclamation-circle.mr3
                span {{'settings.offer.questions.NOT_ALLOWED'|translate}}
</template>

<script>
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSettingsOfferQuestionsAttributeAddDialog
        from '@/js/app/vue/components/Settings/Offer/Questions/Dialogs/OptiSettingsOfferQuestionsAttributeAddDialog'
    import OptiProductsListDialog from '@/js/app/vue/components/Warehouse/Products/Dialogs/OptiProductsListDialog'

    export default {
        name: 'OptiSettingsOfferQuestionsEditAttributes',
        components: {
            OptiButton,
            OptiSettingsOfferQuestionsAttributeAddDialog,
            OptiProductsListDialog
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            option: {
                type: Object,
                required: true
            },
            keyOption: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                selected_to_edit: _.cloneDeep(this.option.attributes),

                attribute_all: ['<take_all>'],
                dialog: {
                    add: false,
                    products: false
                }
            }
        },
        methods: {
            addAttribute(attribute) {
                this.dialog.add = false

                this.selected_to_edit.push(attribute)
                this.selected_to_edit.sort()

                if(_.isEqual(this.selected_to_edit, this.question.all)) {
                    this.selected_to_edit = _.cloneDeep(this.attribute_all)
                }
            },
            deleteAttribute(attribute) {
                if(this.isAll) {
                    this.selected_to_edit = _.cloneDeep(this.question.all)
                }

                this.selected_to_edit.splice(this.selected_to_edit.indexOf(attribute), 1)
            },

            editMode(mode) {
                let other_is_edit_mode = !!this.question.options.filter(o => o.name !== this.option.name && o.edit_mode).length

                if(other_is_edit_mode) {
                    this.$notify.warning('settings.offer.questions.notify.OTHER_IS_EDIT_MODE')
                } else if(this.isEqual || confirm(this.$i18n.t('settings.offer.questions.mode_edit.CONFIRM'))) {
                    this.option.edit_mode = mode

                    this.reset()
                }
            },
            selectAll() {
                this.selected_to_edit = _.cloneDeep(this.attribute_all)
            },
            reset() {
                this.selected_to_edit = _.cloneDeep(this.option.attributes)
            },

            openOption(editable, option) {
                if(editable) {
                    option.open = !option.open
                }
            }
        },
        computed: {
            selected_to_edit_or_all() {
                return this.isAll ? this.question.all : this.selected_to_edit
            },

            isEqual() {
                return _.isEqual(this.option.attributes, this.selected_to_edit)
            },
            isAll() {
                return _.isEqual(this.selected_to_edit, this.attribute_all)
            },

            diff() {
                let old = _.isEqual(this.option.attributes, this.attribute_all) ? this.question.all : this.option.attributes,
                    _new = this.selected_to_edit_or_all

                return {
                    added: _.difference(_new, old),
                    deleted: _.difference(old, _new)
                }
            }
        }
    }
</script>