<template lang="pug">
  OptiDialog
    template(#header)
      span {{ 'Wybierz datę' | translate }}

      i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer; padding:3px", :title="'default.CLOSE' | translate", @click="$emit('close')")

    template(#body)
      .row
        .col-xs-12
          date-picker(
            v-model="selectedDate"
            :attributes="attrs"
            :is-expanded="true"
            @input="dateChanged"
            is-inline
          )
</template>

<script>
import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiDialog from '@/js/app/vue/components/OptiDialog'
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: 'OptiDateDialog',
  components: {
    OptiDialog,
    OptiButton,
    'date-picker': DatePicker
  },
  props: {

  },
  data() {
    return {
      dialog: {
        color: {
          sale: 'success',
          order: 'primary',
          sendback: 'warning'
        },
        icon: {
          sale: 'shopping-cart',
          order: 'truck',
          sendback: 'archive'
        }
      },
      selectedDate: new Date(),
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ffecb3',
          },
          dates: new Date(),
        },
      ],
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    dateChanged() {
      this.$emit('update:date', this.selectedDate);
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .modal-container {
  max-width: 450px;
}
</style>