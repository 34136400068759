<template lang="pug">
    .opti-select-hoya-lenses
        VueMultiSelect(
            :id="id"
            track-by="id"
            :clearable="false"
            :placeholder="'search.hoya.construction.PLACEHOLDER'|translate"
            :showLabels="false"
            label="name"
            :disabled="disabled"
            :options="lenses"
            :value="construct"

            @input="c => $emit('selected', c)")
            template(slot="singleLabel" slot-scope="props")
                span {{props.option.name}}
                small.pull-right {{props.option.lens_type}}
            template(slot="option" slot-scope="props")
                span {{props.option.name}}
                small.pull-right {{props.option.lens_type}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectHoyaConstruct',
        components: {
            VueMultiSelect
        },
        props: {
            id: {
                type: String,
                required: true
            },
            construct: {
                type: Object,
                required: true
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                lenses: []
            }
        },
        mounted() {
            API.get('search/hoya/lens', API.id(Math.random())).then(res => {
                this.lenses = res.data
            }).catch(() => {
                this.$notify.error('search.hoya.construction.notify.error.LOAD')
            })
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
</style>