<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-eye.mr3
            span {{'optometrist.correction.BINOCULAR'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="cancelled()")

        template(#body)
            OptiOptometristsDisplayControls
            .text-center
                .fa-3x(style="padding:40px 0")
                    i.fa.fa-eye.mr3
                    span(style="margin-right:5px") {{'optometrist.correction.BINOCULAR'|translate}}
                    button.btn.btn-lg.btn-info(type="button" :class="{'btn-warning active': help}"
                        @click="help = !help") {{'default.HELP'|translate}}

                div(style="display:flex;justify-content:space-around" v-show="!help")
                    OptiButton(type="info" @click.native="confirmed(1)") {{'optometrist.binocular.RIGHT'|translate}}
                        template(#icon)
                            i.fa.fa-circle
                    OptiButton(type="info" @click.native="confirmed(2)") {{'optometrist.binocular.LEFT'|translate}}
                        template(#icon)
                            i.fa.fa-circle

                OptiOptometristsTips(style="padding-left:15px" v-show="help" :data="tips")

        template.text-right(#footer)
            OptiButton(type="danger" @click.native="cancelled()") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {OptometristsDisplay} from '@/js/app/vue/components/Optometrists/Helpers/OptometristsDisplay'
    import {SurveyLogs} from '@/js/app/vue/components/Optometrists/Helpers/SurveyLogs'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiOptometristsTips from '@/js/app/vue/components/Optometrists/OptiOptometristsTips'
    import OptiOptometristsDisplayControls from '@/js/app/vue/components/Optometrists/OptiOptometristsDisplayControls'

    export default {
        name: 'OptiOptometristsDetailsRefractionDialogBinocular',
        components: {
            OptiDialog,
            OptiButton,
            OptiOptometristsTips,
            OptiOptometristsDisplayControls
        },
        props: {
            binocular: {
                type: Number,
                required: true
            }
        },
        data() {
            let prefix = 'optometrist.tips.binocular.'

            return {
                unique: Math.random().toString(36).substr(2, 9),

                help: false,
                tips:  {
                    info: [
                        prefix + 'info.' + 1
                    ],
                    instruction: [
                        prefix + 'instruction.' + 1
                    ],
                    procedure: [
                        prefix + 'procedure.' + 1,
                        prefix + 'procedure.' + 2
                    ]
                }
            }
        },
        mounted() {
            setTimeout(() => {
                OptometristsDisplay.message.open('/optometrist/screen/maddox')
            }, 1000)

            SurveyLogs.save(this.$state.params.id, this.unique, 'bin_ocular', '', 'start', '50%',
                'Rozpoczęto wybór', [], {eye: 0})
        },
        methods: {
            displayClose() {
                OptometristsDisplay.message.open('/optometrist/screen/transparent')
            },
            confirmed(binocular) {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'bin_ocular', '', 'end', '50%',
                    'Zakończono wybór', [], {eye: binocular})

                this.$emit('confirmed', binocular)
            },
            cancelled() {
                this.displayClose()

                SurveyLogs.save(this.$state.params.id, this.unique, 'bin_ocular', '', 'cancel', '50%',
                    'Anulowano wybór', [], {eye: 0})

                this.$emit('cancelled')
            }
        }
    }
</script>