<template lang="pug">
    .col-xs-12
        .table-responsive
            table.table.table-presenter.table-presenter-bold.table-striped.table-condensed
                tbody
                    tr
                        th
                        th(colspan="2") {{'tasks.POWER'|translate}}
                    tr
                        th
                        th {{'tasks.RIGHT'|translate}} {{'tasks.EYE'|translate}}
                        th {{'tasks.LEFT'|translate}} {{'tasks.EYE'|translate}}
                    tr(v-show="task.frame.fdp")
                        th {{'tasks.FDP'|translate}}
                        td(colspan="2") {{task.frame.fdp|number(2)}}
                    tr(v-show="task.type !== 1")
                        th(rowspan="2") {{'prescription.PDDISTANCE'|translate}}
                        td(colspan="2")
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdDist")
                    tr(v-show="task.type !== 1")
                        td.text-center
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdDistR")
                        td
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdDistL")
                    tr(v-show="task.type !== 2")
                        th(rowspan="2") {{'prescription.PDNEAR'|translate}}
                        td(colspan="2")
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdNear")
                    tr(v-show="task.type !== 2")
                        td.text-center
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdNearR")
                        td
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="pdNearL")
                    tr
                        th(v-if="task.type === 3") {{'tasks.BTBIFOCAL'|translate}}
                        th(v-else) {{'tasks.BT'|translate}}
                        td
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="btR")
                        td
                            OptiTaskDetailsMeasurementField(
                                :task="task"
                                field="btL")
                    tr
                        th(style="width:12%") {{'prescription.SPHERE'|translate}}
                        td(style="width:38%;vertical-align:middle") {{task.rightEye.sphere|plus}}
                        td(style="width:38%;vertical-align:middle") {{task.leftEye.sphere|plus}}
                    tr(v-show="task.rightEye.cylinder || task.leftEye.cylinder")
                        th {{'prescription.CYLINDER'|translate}}
                        td {{task.rightEye.cylinder|plus}}
                        td {{task.leftEye.cylinder|plus}}
                    tr(v-show="task.rightEye.axis !== 'brak' || task.leftEye.axis !== 'brak'")
                        th {{'prescription.AXIS'|translate}}
                        td {{task.rightEye.axis}}
                        td {{task.leftEye.axis}}
                    tr(v-show="task.rightEye.addition || task.leftEye.addition")
                        th {{'prescription.ADDICTION'|translate}}
                        td {{task.rightEye.addition|number(2)}}
                        td {{task.leftEye.addition|number(2)}}
                    tr(v-show="task.rightEye.support || task.leftEye.support")
                        th {{'prescription.SUPPORT'|translate}}
                        td {{task.rightEye.support|number(2)}}
                        td {{task.leftEye.support|number(2)}}
                    tr(v-show="task.rightEye.prism || task.leftEye.prism")
                        th {{'prescription.PRISM'|translate}}
                        td {{task.rightEye.prism|number(2)}}
                        td {{task.leftEye.prism|number(2)}}
                    tr(v-show="task.rightEye.prism !== 0 || task.leftEye.prism !== 0")
                        th {{'prescription.PRISMANGLE'|translate}}
                        td {{task.rightEye.prismAngle}}
                        td {{task.leftEye.prismAngle}}
</template>

<script>
    import OptiTaskDetailsMeasurementField from '@/js/app/vue/components/Tasks/OptiTaskDetailsMeasurementField'

    export default {
        name: 'OptiTaskDetailsSummaryPowers',
        components: {
            OptiTaskDetailsMeasurementField
        },
        props: {
            task: {
                type: Object,
                required: true
            }
        }
    }
</script>