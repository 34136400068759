<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th #
                    th {{'register.return.sale.table.head.ID'|translate}}
                    th {{'register.return.sale.table.head.DATE'|translate}}
                    th {{'register.return.sale.table.head.PATIENT'|translate}}
                    th {{'register.return.sale.table.head.DEPARTMENT'|translate}}
                    th {{'register.return.sale.table.head.WITHDISCOUNT'|translate}}
                    th
            tbody
                tr(v-for="(sale, index) in sales")
                    td {{sales.length - index}}
                    td
                        a.link(:title="'register.return.sale.table.SALEDETAILS'|translate"
                            @click="updateSale(sale)") {{sale.id}}
                    td {{sale.created|dateWithoutPastYear}}
                    td
                        a.link(v-if="Object.keys(sale.patient).length"
                            :title="'register.return.sale.table.PATIENTDETAILS'|translate"
                            :href="$state.href('app.patient.detail', {id: sale.patient.id})"
                            ) {{sale.patient.firstName}} {{sale.patient.lastName.substring(0, 7)}}{{sale.patient.lastName.length > 7 ? '...' : ''}}
                    td {{sale.department.login}}
                    td.text-right {{sale.valueAfterDiscount|currency}}
                    td.text-center
                        a(style="cursor:pointer" @click="updateSale(sale)"
                            :title="'register.return.sale.table.SALEDETAILS'|translate")
                            i.fa.fa-fw.fa-2x.fa-arrow-right
</template>

<script>
    export default {
        name: 'OptiSaleSearchList',
        props: {
            sales: {
                type: Array,
                required: true
            }
        },
        methods: {
            updateSale(sale) {
                this.$emit('get-sale', sale)
            }
        }
    }
</script>