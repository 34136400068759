<template lang="pug">
    .row
        .col-xs-12
            .form-horizontal
                .form-group
                    label.col-sm-2.control-label(for="barcode") {{'shipments.barcode.LABEL'|translate}}
                    .col-sm-10
                        .input-group
                            input#barcode.form-control(ref="barcode"
                                v-model="barcode.uid" name="barcode" autocomplete="off"
                                :placeholder="'shipments.barcode.PLACEHOLDER'|translate"
                                @keyup.enter="barcode_download" :disabled="input_disabled")
                            span.input-group-addon(style="cursor:pointer" @click="barcode_download")
                                i.fa.fa-check.text-success(v-show="barcode_loading === 'ok'")
                                i.fa.fa-remove.warn(v-show="barcode_loading === 'error'")
                                i.fa.fa-spin.fa-cog(v-show="barcode_loading === 'fetching'")
                                i.fa.fa-search(v-show="!barcode_loading")
        .col-md-8
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'shipments.new.content.title.OTHER'|translate}}
                .panel-body
                    form.form-horizontal(@submit.prevent="addCustomItem")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="specifyname") {{'shipments.new.content.other.SPECIFYNAME'|translate}}
                            .col-sm-9
                                input#specifyname.form-control(v-model="customItem.name" name="specifyname" required)
                                OptiValidate(f="specifyname" :data="$v.customItem.name")
                        .form-group
                            label.col-sm-3.control-label(for="description") {{'shipments.new.content.other.DESCRIPTION'|translate}}
                            .col-sm-9
                                textarea#description.form-control(v-model="customItem.description")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="quantity") {{'shipments.new.content.other.QUANTITY'|translate}}
                            .col-sm-9
                                OptiNumber#quantity(:header="'shipments.new.content.other.QUANTITY'|translate"
                                    :value="customItem.quantity" @confirmed="q => customItem.quantity = q"
                                    :required="true") {{customItem.quantity}}
                                OptiValidate(f="quantity" :data="$v.customItem.quantity")
                        .form-group
                            label.col-sm-3.control-label.star-required(for="val") {{'shipments.new.content.other.VALUE'|translate}}
                            .col-sm-9
                                OptiNumber#val(:header="'shipments.new.content.other.VALUE'|translate"
                                    :value="customItem.val" @confirmed="v => customItem.val = v"
                                    :float="true" :required="true") {{customItem.val|currency}}
                                OptiValidate(f="val" :data="$v.customItem.val")
                        .form-group
                            .col-sm-offset-3.col-sm-9
                                OptiButtonSave(:disabled="$v.customItem.$invalid")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'
    import Vue from 'vue'

    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiShippingCreateStepContent',
        components: {
            OptiRefId,
            OptiEnum,
            OptiNumber,
            OptiValidate,
            OptiButtonSave
        },
        props: {
            cartons: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                barcode: {
                    uid: ''
                },
                customItem: {
                    name: '',
                    description: '',
                    quantity: 1,
                    val: 1
                },

                input_disabled: false,
                barcode_loading: false
            }
        },
        validations: {
            customItem: {
                name: {
                    required
                },
                quantity: {
                    noZero
                },
                val: {
                    noZero
                }
            }
        },
        mounted() {

        },
        methods: {
            barcode_loading_parse(status) {
                this.barcode_loading = status

                setTimeout(() => {
                    this.barcode_loading = false
                }, 3000)
            },
            barcode_download() {
                if(parseInt(this.barcode.uid) > 0) {
                    this.input_disabled = true
                    this.barcode_loading = 'fetching'

                    API.post('shipment/barcode/add', {
                        uid: this.barcode.uid,
                        cartons: this.cartons
                    }).then(res => {
                        if(res.data.errors.length) {
                            res.data.errors.forEach(error => {
                                this.$notify.error(error, false, true)
                            })

                            this.barcode_loading_parse('error')
                        } else {
                            this.$emit('update-item', res.data.cartons)

                            this.barcode_loading_parse('ok')
                        }
                    }).finally(() => {
                        this.barcode.uid = ''

                        this.input_disabled = false
                        Vue.nextTick(() => {
                            this.$refs.barcode.focus()
                        })
                    }).catch(() => {
                        this.barcode_loading('error')
                    })
                }
            },

            addCustomItem() {
                this.cartons[this.cartons.length - 1].items.push({
                    id: 0,
                    name: this.customItem.name,
                    description: this.customItem.description,
                    quantity: this.customItem.quantity,
                    value: this.customItem.val,
                    type: -1
                })

                this.customItem = {
                    name: '',
                    description: '',
                    quantity: 1,
                    val: 1
                }

                this.$emit('update-item', this.cartons)
            }
        }

    }
</script>