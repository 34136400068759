<template lang="pug">
    .filter-button
        label.filter-name(
            :class="{'filter-option-true': selected, 'filter-option-false': !selected}"
            :title="title")

            input.hide(type="checkbox" :id="selected" :checked="selected" @change="$emit('click')")
            i.fa.fa-lg.mr3(:class="{'fa-check': selected, 'fa-remove': !selected}")
            span {{title}}
</template>

<script>
    export default {
        name: 'OptiButtonFilter',
        props: {
            selected: {
                type: Boolean,
                required: false
            },
            title: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    .filter-button  {
        @border-radius: 5px;

        display: inline-block;
        margin-bottom: 10px;
        border-radius: @border-radius;

        max-width: fit-content;

        &:hover {
            box-shadow: 0 0 1px 1px gray;
        }

        i {
            width: 25px;
            line-height: .3em;
            text-align: center;
        }

        label.filter-name {
            flex-grow: 1;
            border: 2px solid;
            border-radius: @border-radius;
            padding: 10px 15px;
            margin-bottom: 0;
            cursor: pointer;

            &.filter-option-true {
                @bg: lightgreen;
                @color: #002401;

                background: @bg;
                border-color: @color;

                i, span {
                    color: @color;
                }
            }

            &.filter-option-false {
                @bg: lightpink;
                @color: #440101;

                background: @bg;
                border-color: @color;

                i, span {
                    color: @color;
                }
            }


        }
    }
</style>