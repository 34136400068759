import {API} from '@/js/app/vue/api'

class StorageClass {
    constructor() {
        this.urls = {
            countryCode: 'countries/country-code',
            hierarchy: 'patients/hierarchy',
            doctors: 'department/doctor',

            catalogLenses: 'task/catalog/lenses-producent',

            ccTypes: 'payments'
        }
    }

    async get(name) {
        try {
            if(localStorage.getItem(name)) {
                return JSON.parse(localStorage.getItem(name))
            }

            let {data} = await API.get(this.urls[name], API.id(name + '-' + Math.random()))
            localStorage.setItem(name, JSON.stringify(data))

            return data
        } catch(e) {
            console.error('Nie ma takiego klucza ' + name + '!')
        }
    }
}

export const Storage = new StorageClass()