import { Bubble } from 'vue-chartjs'
import {reactiveProp} from "vue-chartjs/es/mixins";

export default {
    extends: Bubble,
    name: 'BubbleChart',
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        this.renderChart(this.chartData, this.options)
    }
}
