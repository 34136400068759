<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formTrainingType" @submit.prevent="add")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'user.training.table.TYPE'|translate}}
                    .col-sm-8
                        input#type.form-control(name="type" v-model="training_type.type" required)
                        OptiValidate(f="type" :data="$v.training_type.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'user.training.table.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="training_type.name" required)
                        OptiValidate(f="name" :data="$v.training_type.name")
                .form-group(style="padding-bottom:15px")
                    label.col-sm-4.control-label.star-required(for="short") {{'dictionary.trainingtype.SHORT'|translate}}
                    .col-sm-8
                        input#short.form-control(name="short" v-model="training_type.short" required)
                        OptiValidate(f="short" :data="$v.training_type.short")

                .form-group
                    label.col-sm-4.control-label.star-required(for="max") {{'user.training.table.MAX'|translate}}
                    .col-sm-8
                        OptiNumber#max(:header="'user.training.table.MAX'|translate" :value="training_type.max"
                            @confirmed="m => training_type.max = m" :required="true") {{training_type.max}}
                        OptiValidate(f="max" :data="$v.training_type.max")
                .form-group
                    label.col-sm-4.control-label.star-required(for="comments") {{'user.training.table.COMMENTS'|translate}}
                    .col-sm-8
                        AngularComponent#comments(:component="comments")
                        OptiValidate(f="comments" :data="$v.training_type.comments")

                .form-group
                    OptiDictionaryTrainingTypeDownloading(
                        :downloading="training_type.downloading"
                        :invalid-downloading="invalidDownloading")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.training_type.$invalid || invalidDownloading")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero, unique} from '@/js/vue.validators.js'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import AngularComponent from '@/js/app/_bridge/components/AngularComponent'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDictionaryTrainingTypeDownloading
        from '@/js/app/vue/components/Dictionary/TrainingTypes/OptiDictionaryTrainingTypeDownloading'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDictionaryTrainingTypeAdd',
        components: {
            OptiNumber,
            AngularComponent,
            OptiValidate,
            OptiDictionaryTrainingTypeDownloading,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                training_type: {
                    type: '',
                    name: '',
                    short: '',
                    max: 0,
                    comments: '',
                    downloading: []
                },

                loading: false
            }
        },
        validations: {
            training_type: {
                type: {
                    required,
                    unique: unique('training_types', 'type')
                },
                name: {
                    required,
                    unique: unique('training_types', 'name')
                },
                short: {
                    required,
                    unique: unique('training_types', 'short')
                },
                max: {
                    noZero
                },
                comments: {
                    required
                }
            }
        },

        methods: {
            add() {
                this.loading = true

                API.post('dictionary/training-type/add', this.training_type).then(() => {
                    this.$state.go('app.dictionary.training-types.table')

                    this.$notify.success('dictionary.trainingtype.notify.ADD')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dictionary.trainingtype.notify.error.ADD')
                })
            },

            addDownload() {
                this.training_type.downloading.push({
                    id: 0,
                    name: ''
                })
            },
            updateDownload(key, download) {
                this.$set(this.training_type.downloading, key, download)
            },
            deleteDownload(key) {
                this.training_type.downloading.splice(key, 1)
            }
        },
        computed: {
            comments() {
                let vm = this

                return {
                    template: `<textarea name="comments" ng-wig="$ctrl.comments"></textarea>`,
                    $ctrl: {
                        get comments() { return vm.training_type.comments },
                        set comments(val) { vm.training_type.comments = val }
                    }
                }
            },

            invalidDownloading() {
                let not_unique = [... new Set(this.training_type.downloading.map(d => d.id))].length !== this.training_type.downloading.length,
                    is_empty = !!this.training_type.downloading.filter(d => !d.id).length

                return not_unique || is_empty
            }
        }
    }
</script>