<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-camera.mr3
            span {{'default.PREVIEW'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .text-center
                a(:href="url|upload" target="_blank")
                    img(style="max-width:330px" :src="url|upload")

        template(#footer)
            OptiButton.pull-right(type="success" @click.native="$emit('closed')") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'

    export default {
        name: 'OptiDialogImage',
        components: {
            OptiDialog,
            OptiButton: () => import('../../Button/OptiButton')
        },
        props: {
            url: {
                type: String,
                required: true
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 400px;
    }
</style>