<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="true")

            MountingPortal(v-if="mail_id" mountTo="#vue-modal" append)
                OptiPatientEmailPreviewDialog(
                    :id="mail_id"

                    @close="$state.previous('app.alerts')")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiPatientEmailPreviewDialog
        from '@/js/app/vue/components/Patients/Emails/OptiPatientEmailPreviewDialog'

    export default {
        name: 'OptiAlertRedirect',
        components: {
            OptiDimmer,
            OptiPatientEmailPreviewDialog
        },
        data() {
            return {
                mail_id: 0
            }
        },
        mounted() {
            if([13, 14].includes(parseInt(this.$state.params.type)) && !parseInt(this.$state.params.id)) {
                this.$state.previous()

                this.$notify.error('alert.notify.NOASSIGNTOPATIENT')

                return false
            }

            switch(parseInt(this.$state.params.type)) {
                case 1:
                    this.$state.go('app.catalog.product', {id: parseInt(this.$state.params.id),
                            departmentId: parseInt(this.$state.params.department_id)},
                        {location: 'replace'})
                    break

                case 2:
                    this.$state.go('app.register.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 3:
                case 6:
                case 12:
                case 21:
                case 22:
                case 30:
                case 52:
                case 58:
                case 70:
                case 71:
                    this.$state.go('app.task.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 4:
                    this.$state.go('app.sale.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 5:
                    this.$state.go('app.sale-offstock.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 7:
                    this.$state.go('app.register.return.sale.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 8:
                    this.$state.go('app.register.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 9:
                    this.$state.go('app.register.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 10:
                    this.$state.go('app.error.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 11:
                    this.$state.go('app.test.results.details', {test_result_id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 13:
                case 14:
                    API.get('sms/get-redirect-info/' + this.$state.params.id).then(res => {
                        if(Object.keys(res.data).includes('id')) {
                            this.$state.go('app.patients.schedule', {
                                department_id: res.data.department_id,
                                view: 'day',
                                date: res.data.date_visit,
                                visit_id: res.data.id
                            }, {location: 'replace'})
                        } else {
                            if(res.data.patient_id) {
                                this.$state.go('app.patient.detail', {
                                    id: res.data.patient_id,
                                    '#': 'smses_and_emails'
                                }, {location: 'replace'})
                            } else {
                                this.$notify.warning('alert.notify.NOVISITPATIENT')

                                this.$state.previous()
                            }
                        }
                    })
                    break

                case 15:
                case 45:
                case 46:
                case 47:
                case 48:
                    this.$state.go('app.patient.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 16:
                    this.$state.go('app.catalog.product', {id: parseInt(this.$state.params.id),
                            departmentId: parseInt(this.$state.params.department_id)},
                        {location: 'replace'})
                    break

                case 17:
                    this.$state.go('app.user.edit', {id: parseInt(this.$state.params.id)})
                    break

                case 18:
                    this.$state.go('app.tasks.reclamation.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 19:
                    this.$state.go('app.sale.reclamation.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 20:
                    this.$state.go('app.sale.reclamation.details', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 23:
                    this.$state.go('app.coupon.details', {id: this.$state.params.id},
                        {location: 'replace'})
                    break

                case 24:
                case 25:
                case 26:
                case 27:
                case 55:
                    this.$state.go('app.optometrist.details', {id: this.$state.params.id},
                        {location: 'replace'})
                    break

                case 31:
                case 53:
                    this.$state.go('app.layout.request', {
                        layout_id: this.$state.params.department_id,
                        id: this.$state.params.id
                    }, {location: 'replace'})
                    break

                case 32:
                    this.$state.go('app.warehouse.transfer.detail', {id: parseInt(this.$state.params.id)},
                        {location: 'replace'})
                    break

                case 33:
                    this.$state.go('app.patients.schedule', {
                        department_id: this.$state.params.department_id,
                        view: 'day',
                        date: this.$state.params.extra_param,
                        visit_id: parseInt(this.$state.params.id)
                    }, {location: 'replace'})
                    break

                case 34:
                    this.$state.go('app.coupon.details', {id: this.$state.params.id},
                        {location: 'replace'})
                    break

                case 35:
                case 41:
                    this.$state.go('app.warehouse.order.details', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 36:
                    this.$state.go('app.warehouse.shipment.detail', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 37: this.$state.go('app.patients.contact-plus-add', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 38:
                case 40:
                    this.$state.go('app.schedule.calendar', {id: this.$state.params.id, date: this.$state.params.extra_param,
                        department_id: this.$state.params.department_id}, {location: 'replace'})
                    break

                case 39:
                case 51:
                    this.$state.go('app.user.edit', {id: parseInt(this.$state.params.id)}, {location: 'replace'})
                    break

                case 42:
                case 64:
                case 65:
                case 69:
                    this.$state.go('app.user.training.details', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 43: this.$state.go('app.user.exchange.details', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 44: this.$state.go('app.messages.view.details', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 49: this.$state.go('app.reports.forecast', null, {location: 'replace'})
                    break

                case 50: this.$state.go('app.bookkeeping.expense.edit', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 56: this.$state.go('app.catalog.stock-correction.edit', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 57:
                    switch(parseInt(this.$state.params.extra_param)) {
                        case 1: this.$state.go('app.patient.detail', {id: this.$state.params.id, '#': 'previousLenses'}, {location: 'replace'})
                            break

                        case 2: this.$state.go('app.task.detail', {id: parseInt(this.$state.params.id), '#': 'previouslenses'}, {location: 'replace'})
                            break

                        case 3: this.$state.go('app.optometrist.details', {id: this.$state.params.id, '#': 'previousglasses'}, {location: 'replace'})
                    }

                    break

                case 59:
                case 60: this.$state.go('app.warehouse.return.details', {id: this.$state.params.id}, {location: 'replace'})
                    break

                case 61:
                    if(this.$state.params.id !== '0') {
                        this.$state.go('app.catalog.dailycheck', {id: this.$state.params.id})
                    } else {
                        this.$notify.error('alert.notify.NO_FILE_EDI')

                        this.$state.previous()
                    }

                    break

                case 66:
                case 67:
                    this.mail_id = parseInt(this.$state.params.id)

                    break

                case 68:
                    this.$state.go('app.catalog.offer-temporary-task', {id: this.$state.params.id})

                    break

                default:
                    this.$state.go('app.alerts', null, {location: 'replace'})
            }
        }
    }
</script>