<template lang="pug">
    div
        i.fa.fa-lg.fa-fw.fa-save(v-show="state !== 'init'")
        i.fa.fa-lg.fa-fw.fa-spin.fa-cog(v-show="state === 'debouncing'")
        i.fa.fa-lg.fa-fw.fa-spin.fa-refresh(v-show="state === 'saving'")
        i.fa.fa-lg.fa-fw.fa-check.text-success(v-show="state === 'saved'")
        i.fa.fa-lg.fa-fw.fa-remove.warn(v-show="state === 'error'")

        .hide {{data}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiAutoSave',
        props: {
            model: {
                type: Object,
                required: true
            },
            endpoint: {
                type: String,
                required: true
            },
            fields: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                state: 'init',

                previous: null,

                timeout: null,
                delay: 2000
            }
        },
        methods: {
            finally() {
                setTimeout(() => {
                    if(['saved', 'error'].includes(this.state)) {
                        this.state = 'init'
                    }
                }, this.delay)
            },
            save() {
                this.state = 'debouncing'
                clearTimeout(this.timeout)

                this.timeout = setTimeout(() => {
                    this.state = 'saving'

                    API.post(this.endpoint, this.model).then(res => {
                        this.state = 'saved'

                        this.$emit('saved', res.data)
                    }).finally(() => {
                        this.finally()
                    }).catch(() => {
                        this.state = 'error'
                    })
                }, this.delay)

            }
        },
        computed: {
            data() {
                let _return = []

                this.fields.split(',').forEach(field => {
                    _return.push(this.model[field])
                })

                _return = _return.join(',')

                if(this.previous !== null && this.previous !== _return) {
                    this.save()
                }

                this.previous = _return

                return _return
            }
        }
    }
</script>