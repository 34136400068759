<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-info-circle.mr3
            span {{'tasks.frame.INFO'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row
                .col-xs-12
                    textarea#frame-attr-info.form-control(rows="5" name="frameAttrInfo" v-model="info")

        template(#footer)
            OptiButton.pull-left(type="success" @click.native="goSaveAttr") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(type="danger" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsFrameDialogInfo',
        components: {
            OptiDialog,
            OptiButton,
            OptiDimmer
        },
        props: {
            productId: {
                type: Number,
                required: true
            },
            frameAttrInfo: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                info: this.frameAttrInfo,

                loading: false
            }
        },
        methods: {
            goSaveAttr() {
                this.loading = true

                API.post('task/frame/info/save', {
                    productId: this.productId,
                    value: this.info
                }).then(res => {
                    this.$notify.success(res.data.info !== 'FAIL' ?
                        'tasks.frame.FRAMESAVEINFO' : 'tasks.frame.notify.ERRORINFOFRAMESAVE')

                    if(res.data.info !== 'FAIL') {
                        this.$emit('frame-attr-was-updated', res.data.info)
                    } else {
                        this.$notify.error('tasks.frame.notify.ERRORINFOFRAMESAVE')
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('tasks.frame.notify.ERRORINFOFRAMESAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 500px;
    }
</style>