<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form#form-users.form-horizontal.form-validate(name="formUser" @submit.prevent="addUser")
                fieldset
                    .form-group
                        label.col-sm-4.control-label.star-required(for="login") Login
                        .col-sm-8
                            input#login.form-control(placeholder="Login" name="login" v-model="user.login" required)
                            OptiValidate(f="login" :data="$v.user.login")

                .form-group
                    label.col-sm-4.control-label.star-required(for="password") {{'user.PASSWORD'|translate}}
                    .col-sm-8
                        input#password.form-control(type="password" :placeholder="'user.PASSWORD'|translate"
                            v-model="user.password" required)
                        OptiValidate(f="password" :data="$v.user.password")
                fieldset
                    .form-group
                        label.col-sm-4.control-label.star-required(for="confirmPassword") {{'user.CONFIRMPASSWORD'|translate}}
                        .col-sm-8
                            input#confirmPassword.form-control(type="password" :placeholder="'user.CONFIRMPASSWORD'|translate"
                                name="confirmPassword" v-model="user.confirmPassword" required)
                            OptiValidate(f="confirmPassword" :data="$v.user.confirmPassword")

                .form-group
                    label.col-sm-4.control-label.star-required(for="firstName") {{'user.FIRSTNAME'|translate}}
                    .col-sm-8
                        input#firstName.form-control(:placeholder="'user.FIRSTNAME'|translate" name="firstName"
                            v-model="user.firstName" required)
                        OptiValidate(f="firstName" :data="$v.user.firstName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="lastName") {{'user.LASTNAME'|translate}}
                    .col-sm-8
                        input#lastName.form-control(:placeholder="'user.LASTNAME'|translate" name="lastName"
                            v-model="user.lastName" required)
                        OptiValidate(f="lastName" :data="$v.user.lastName")
                .form-group
                    label.col-sm-4.control-label(for="cellPhoneNumber") {{'user.PHONE'|translate}}
                    .col-sm-8
                        input#cellPhoneNumber.form-control(:placeholder="'user.PHONE'|translate" name="phone"
                            v-model="user.cellPhoneNumber")
                .form-group
                    label.col-sm-4.control-label(for="email") E-mail
                    .col-sm-8
                        input#email.form-control(type="email" placeholder="E-mail" name="email" v-model="user.email")
                        OptiValidate(f="email" :data="$v.user.email")
                .form-group
                    label.col-sm-4.control-label.star-required(for="pin") PIN
                    .col-sm-8
                        input#pin.form-control(placeholder="PIN" v-model="user.pin" required)
                        OptiValidate(f="pin" :data="$v.user.pin")
                .form-group
                    label.col-sm-4.control-label(style="padding-top:0" for="ipad_serial_number")
                        span {{'user.ipad.HEADER'|translate}}
                        small(style="display:block") {{'user.ipad.TITLE'|translate}}
                    .col-sm-8
                        input#ipad_serial_number.form-control(:placeholder="'user.ipad.HEADER'|translate" name="ipad_serial_number"
                            v-model="user.ipad_serial_number")
                .form-group(v-if="permission")
                    label.col-sm-4.control-label(for="position") {{'user.POSITION'|translate}}
                    .col-sm-8
                        OptiSelectEnum#position(enum="UserPosition" :value="user.position" @update-enum="p => user.position = p")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_employee") {{'user.DATEEMPLOYEE'|translate}}
                    .col-sm-8
                        OptiDate#date_employee(name="dateEmployee"
                            :value="user.date_employee" @update-date="d => user.date_employee = d")
                        OptiValidate(f="date_employee" :data="$v.user.date_employee")
                .form-group
                    label.col-sm-4.control-label.star-required(for="week_h") {{'user.WEEKH'|translate}}
                    .col-sm-8
                        OptiNumber#week_h(style="text-align:left" :header="'user.WEEKH'|translate"
                            :value="user.week_h" @confirmed="h => user.week_h = h") {{user.week_h}}h
                        OptiValidate(f="week_h" :data="$v.user.week_h")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        .checkbox.c-checkbox.checkbox-primary.inline
                            label
                                input#manager_zone(type="checkbox"
                                    name="manager_zone" v-model="user.manager_zone")
                                span.fa.fa-check
                        label(for="manager_zone") {{'user.MANAGER_ZONE'|translate}}
                OptiUserAssignDepartments(
                    :groups="user.group"
                    :common-panel="true"
                    :common-role="user.defaultRole"

                    @change-common-role="r => user.defaultRole = r"
                    @validate-was-changed="b => validate_departments = b")
                .form-group
                    .col-sm-offset-4.col-sm-8(style="margin-top:10px")
                        OptiButtonSave(save :disabled="$v.user.$invalid || !validate_departments")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {UserHelper} from '@/js/app/vue/helpers/UserHelper'
    import {required, minLength, sameAs, email} from 'vuelidate/lib/validators'
    import {unique, noZero} from '@/js/vue.validators.js'

    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiUserAssignDepartments from '@/js/app/vue/components/Users/OptiUserAssignDepartments'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUserAdd',
        components: {
            OptiValidate,
            OptiSelectEnum,
            OptiDate,
            OptiNumber,
            OptiUserAssignDepartments,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                user: {},

                high_roles: {},

                validate_departments: false,
                permission: this.$uac.permission('user.assigment_to_salon'),
                loading: true
            }
        },
        validations: {
            user: {
                login: {
                    required,
                    minLength: minLength(5),
                    unique: unique('employees', 'login')
                },
                password: {
                    required,
                    minLength: minLength(5)
                },
                confirmPassword: {
                    required,
                    minLength: minLength(5),
                    sameAsPassword: sameAs('password')
                },
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                email: {
                    email,
                    unique: unique('employees', 'email')
                },
                pin: {
                    required,
                    minLength: minLength(5)
                },
                date_employee: {
                    required
                },
                week_h: {
                    noZero
                }
            }
        },
        mounted() {
            API.get('users/empty').then(res => {
                this.user = res.data

                this.high_roles = UserHelper.getHighRoles(this.user.group)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('user.notify.ERRORLOADDATA')
            })
        },
        methods: {
            addUser() {
                if(!_.isEqual(this.high_roles, UserHelper.getHighRoles(this.user.group)) && confirm(this.$filters.translate('user.high_role_confirm.MESSAGE'))) {
                    this.goToAdd(true)
                } else {
                    this.goToAdd(false)
                }
            },
            goToAdd(high_role_alert) {
                this.loading = true
                this.user.high_role_alert = high_role_alert

                API.post('users', this.user).then(() => {
                    this.$state.go('app.users.list', {
                        search: [
                            'active:=:yes'
                        ]
                    })

                    this.$notify.success('user.ADDED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('user.notify.ERRORLOADDATA')
                })
            }
        }
    }
</script>