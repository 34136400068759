<template lang="pug">
    .row
        OptiDimmer(:active="active")

        .col-xs-12.text-right(style="padding-bottom:10px" v-if="department.department_id !== 0 && bonuses.id")
            OptiButtonHistory(:id="bonuses.id" data="bonus")

        .col-md-offset-1.col-md-8
            form.form-horizontal.form-validate
                .form-group
                    label.col-sm-4.control-label.star-required {{'bonus.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment(
                            :department-id="department.department_id"
                            :simple="true"
                            @selected="updateDepartment")
                        input(type="hidden" name="department_id" v-model="department.department_id")

                .form-group
                    label.col-sm-4.control-label.star-required {{'bonus.YEAR'|translate}}
                    .col-sm-8
                        .row.text-center
                            .col-xs-3(v-for="y in select.years")
                                .radio.c-radio.c-radio-nofont(@click="setYear(y)")
                                    input(type="radio" v-model="year" name="year" :value="y")
                                    span
                                    | {{y}}

                .form-group
                    label.col-sm-4.control-label.star-required {{'bonus.QUARTER'|translate}}
                    .col-sm-8
                        .row.text-center
                            .col-xs-3(v-for="q in select.quarters")
                                .radio.c-radio.c-radio-nofont(@click="setQuarter(q)")
                                    input(type="radio" v-model="quarter" name="quarter" :value="q")
                                    span
                                    | {{q}}

        .col-md-offset-3.col-md-6
            .panel.panel-primary
                .panel-heading {{'bonus.LENSESBONUS'|translate}}

                .panel-body
                    .table-responsive
                        table.table.table-striped
                            tr
                                th {{'bonus.LENSESBONUS'|translate}}
                                td
                                    OptiNumber#contact_lenses_bonus(
                                        :header="'bonus.LENSESBONUS'|translate"
                                        :value="bonuses.contact_lenses_bonus"

                                        @confirmed="v => bonuses.contact_lenses_bonus = v") {{bonuses.contact_lenses_bonus}}%
                                td.text-right
                                    OptiButton(type="success" @click.native="updateLenses") {{'bonus.table.SAVE'|translate}}
                                        template(#icon)
                                            i.fa.fa-save

        .col-md-offset-3.col-md-6
            .panel.panel-primary
                .panel-heading {{'bonus.BONUS'|translate}}

                .table-responsive
                    table.table.table-striped.table-responsive.middle
                        thead
                            tr
                                th.text-left(rowspan="2") #
                                th.text-center(rowspan="2") {{'bonus.table.TURNOVER'|translate}}
                                th.text-center(colspan="2") {{'bonus.table.percent.HEADING'|translate}}
                                th(rowspan="2")
                            tr
                                th.text-center {{'bonus.table.percent.TASK'|translate}}
                                th.text-center {{'bonus.table.percent.SALE'|translate}}
                        tbody
                            tr(v-for="(bonus, index) in orderBonuses" :key="bonus.id")
                                td.text-center {{index + 1}}
                                td.text-center {{bonus.turnoverAbove}}
                                td.text-center {{bonus.percent_task}}
                                td.text-center {{bonus.percent_sale}}
                                td
                                    OptiButton(type="danger" @click.native="removeRow(bonus)") {{'bonus.delete.BTN'|translate}}
                                        template(#icon)
                                            i.fa.fa-trash
                            tr.form-group
                                td.text-center
                                td.text-center
                                    input.form-control(v-model="newBonusTurn" name="newBonusTurn")
                                td.text-center
                                    input.form-control(v-model="newBonusPercentTask" name="newBonusPercentTask")
                                td.text-center
                                    input.form-control(v-model="newBonusPercentSale" name="newBonusPercentSale")
                                td
                                    OptiButton(type="success" :disabled="!newBonusTurn || !newBonusPercentTask || !newBonusPercentSale"
                                        @click.native="addRow()") {{'bonus.table.SAVE'|translate}}
                                        template(#icon)
                                            i.fa.fa-save
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiBonusEdit',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiSelectDepartment,
            OptiDimmer,
            OptiNumber
        },
        data() {
            let year = moment().isoWeekYear(),
                quarter = BudgetHelper.getQuarterByWeek(moment())

            return {
                department: {
                    department_id: this.$uac.user.activeDepartmentId
                },
                year: year,
                quarter: quarter,

                select: {
                    years: _.range(year - (quarter === 4 ? 2 : 3), year + (quarter === 4 ? 2 : 1)),
                    quarters: _.range(1, 4 + 1)
                },

                bonuses: {},
                newBonusPercentTask: null,
                newBonusPercentSale: null,
                newBonusTurn: null,

                active: false
            }
        },
        computed: {
            orderBonuses() {
                return _.orderBy(this.bonuses.items, 'turnoverAbove')
            }
        },
        methods: {
            updateDepartment(department) {
                this.department.department_id = department.id
                this.updateData()
            },
            updateData(){
                this.active = true
                API.post('dictionary/bonus/getData', {
                    year: this.year,
                    quarter: this.quarter,
                    departmentId: this.department.department_id
                }).then( res => {
                    if(res.data.bonuses) {
                        this.bonuses = res.data.bonuses
                    } else {
                        this.bonuses = []
                    }
                    this.active = false
                })
            },
            setYear(x){
                this.year = x
                this.updateData()
            },
            setQuarter(x){
                this.quarter = x
                this.updateData()
            },
            updateLenses() {
                this.active = true
                API.post('dictionary/bonus/updateLenses', {
                    bonuses: this.bonuses
                }).then( () => {
                    this.$notify.success('Premie soczewek zostały zaktualizowane.')
                })
                this.active = false
            },
            removeRow(bonus) {
                this.bonuses.items.splice(this.bonuses.items.indexOf(bonus), 1)
                this.active = true
                API.post('dictionary/bonus/removeBonus', {
                    bonuses: this.bonuses,
                    year: this.year,
                    quarter: this.quarter,
                    departmentId: this.department.department_id
                })
                this.active = false
            },
            addRow() {
                this.active = true

                API.post('dictionary/bonus/addBonus', {
                    newBonusPercentTask: this.newBonusPercentTask,
                    newBonusPercentSale: this.newBonusPercentSale,
                    newBonusTurn: this.newBonusTurn,
                    year:this.year,
                    quarter:this.quarter,
                    departmentId:this.department.department_id
                }).then(() => {
                    this.newBonusTurn = ''

                    this.newBonusPercentTask = ''
                    this.newBonusPercentSale = ''

                    this.updateData()
                }).finally( () => {
                    this.active = false
                    this.$notify.success('Próg premiowy został zaktualizowany.')
                })
            }
        }
    }
</script>