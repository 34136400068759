<template lang="pug">
    .row
        .col-xs-12
            .alert.alert-danger(v-show="ranges.errors.length")
                div(v-for="error in ranges.errors")
                    i.fa.fa-exclamation-circle.mr3
                    span {{error.text}}

        .col-xs-12
            .alert.alert-warning(v-show="ranges.warnings.length")
                div(v-for="warning in ranges.warnings")
                    i.fa.fa-exclamation-circle.mr3
                    span {{warning.text}}
</template>

<script>
    export default {
        name: 'OptiTaskDetailsLensesRanges',
        props: {
            ranges: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    .alert {
        color: #000;
        margin-bottom: 10px;

        &.alert-danger {
            background: #f05050;
        }
    }
</style>