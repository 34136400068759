<template lang="pug">
    .row
        .col-xs-12(v-show="!loading")
            #btn-group-patient-detail.row
                .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                    OptiButtonHistory(data="e_shop_order" :id="order.id")
                .col-md-4.text-center

                .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg

        .col-xs-12(style="margin-bottom:10px" v-show="!loading")
            OptiEshopCustomerPanel(:customer="order.customer")

            .col-sm-4
                .line-padding
                    .bold {{'eShop.order.payment.STATUS'|translate}}
                    .form-control-static {{order.payment_status_name|translate}}
            .col-sm-4
                .line-padding
                    .bold {{'eShop.order.shipment.STATUS'|translate}}
                    .form-control-static {{order.shipment_status_name|translate}}
            .col-sm-4
                .line-padding
                    .bold {{'eShop.order.ORDER_STATUS'|translate}}
                    .form-control-static {{order.status_name|translate}}

        .col-xs-12(v-show="!loading")
            OptiEShopOrderDetailsProductsTable(:order="order")

        .col-xs-12(v-show="!loading")
            div
                .col-sm-6
                    .line-padding
                        .bold {{'eShop.payment.METHOD'|translate}}
                        .form-control-static {{order.payment_method_name|translate}}
                .col-sm-6
                    .line-padding
                        .bold {{'eShop.order.shipment.METHOD'|translate}}
                        .form-control-static {{order.shipment_method_name|translate}}
                .clearfix

            OptiEshopAddressPanel(:data="order" :background="true")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiEshopCustomerPanel from '@/js/app/vue/components/eShop/OptiEshopCustomerPanel'
    import OptiEShopOrderDetailsProductsTable from '@/js/app/vue/components/eShop/Orders/Details/OptiEShopOrderDetailsProductsTable'
    import OptiEshopAddressPanel from '@/js/app/vue/components/eShop/OptiEshopAddressPanel'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiEShopOrderDetails',
        components: {
            OptiButtonHistory,
            OptiEshopCustomerPanel,
            OptiEShopOrderDetailsProductsTable,
            OptiEshopAddressPanel,
            OptiDimmer
        },
        data() {
            return {
                order: {
                    customer: {},
                    items: []
                },

                loading: true
            }
        },
        mounted() {
            API.get('e_shop/order/' + this.$state.params.id).then(res => {
                this.order = res.data

                this.$emit('status-name-was-changed', this.order.status_name)
            }).finally(() => {
                this.loading = false
            }).catch(e => {
                if([412, 404].includes(e.response.status)) {
                    this.$notify.error(e.response.data.message, false)
                } else {
                    this.$notify.error('eShop.order.notify.error.LOAD')
                }

                this.$state.go('app.dashboard')
            })
        }
    }
</script>