<template lang="pug">
    td.correction-path
        svg.opened(height="100%")
            rect.background(x="0" y="0" width="100%" height="100%")

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'visus'}" @click="$emit('change-view', 0)" x="50" y="10")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title Visus
                        rect(fill="#e5e5e5" height="42" width="72" y="-1" x="-1")
                        g(overflow="visible" y="0" x="0" height="100%" width="100%")
                            rect(fill="url(#gridpattern)" stroke-width="0" y="0" x="0" height="100%" width="100%")

                    g
                        title Visus
                        text.bold(transform="matrix(0.813319, 0, 0, 0.686969, 1.29833, 7.6726)" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="26" y="10.14868" x="21.64927" stroke-width="0" stroke="#000" fill="#000") E
                        text.bold(transform="matrix(1.35001, 0, 0, 1, -1.57746, 0)" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="14" y="36.12073" x="4.50693" stroke-width="0" stroke="#000" fill="#000") N
                        text.bold(transform="matrix(1.14409, 0, 0, 1, -6.45531, 0)" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="14" y="35.4963" x="34.68778" stroke-width="0" stroke="#000" fill="#000") C
                        text(xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="8" y="10.31091" x="59.0406" stroke-width="0" stroke="#000" fill="#000") 0.1
                        text(xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="8" y="32.79043" x="58.83246" stroke-width="0" stroke="#000" fill="#000") 0.2

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'cz'}" @click="$emit('change-view', 1)" x="50" y="80")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title C/Z
                        rect(style="opacity:.6" x="0" y="0" width="50%" height="100%" fill="red")
                        rect(style="opacity:.6" height="100%" width="50%" x="50%" y="0" fill="green")

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'ee'}" @click="$emit('change-view', 2)" x="50" y="150")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title E/E
                        rect(style="opacity:.6" x="0" y="0" width="50%" height="100%" fill="red")
                        rect(style="opacity:.6" height="100%" width="50%" x="50%" y="0" fill="green")
                        text.bold(x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#000") E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'clock'}" @click="$emit('change-view', 3)" x="50" y="220")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title zegar
                        rect(fill="#e5e5e5" height="42" width="72" y="-1" x="-1")

                    g
                        title zegar
                        line(y2="39" x2="34.83174" y1="1" x1="34.61017" stroke="#000" stroke-width="2" transform="rotate(135, 34.721, 20)")
                        line(y2="39.84311" x2="35.04252" y1="1.84311" x1="34.82095" stroke="#000" stroke-width="2" transform="rotate(45, 34.9317, 20.8431)")
                        line(y2="39.42274" x2="35.51677" y1="2.26347" x1="35.29519" stroke="#000" stroke-width="2" transform="rotate(90, 35.406, 20.8431)")
                        line(y2="39" x2="34.83174" y1="1" x1="34.61017" stroke="#000" stroke-width="2")
                        ellipse(ry="6.05567" rx="5.9211" cy="20.79397" cx="34.5963" stroke-width="0" stroke="#e5e5e5" fill="#e5e5e5")

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'salt'}" @click="$emit('change-view', 4)" x="50" y="290")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title solniczka
                        rect(fill="#e5e5e5" height="42" width="72" y="-1" x="-1")

                    g
                        title solniczka
                        ellipse(ry="4" rx="4" cy="7.61261" cx="35.87499" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="19.8625" cx="35.87499" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="31.61239" cx="35.87499" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="25.61245" cx="25.87508" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="25.61245" cx="46.3749" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="13.36256" cx="25.37508" stroke-width="1.5" stroke="#000" fill="#000")
                        ellipse(ry="4" rx="4" cy="14.36255" cx="46.62489" stroke-width="1.5" stroke="#000" fill="#000")

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'dot'}" @click="$emit('change-view', 5)" x="50" y="360")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title kropka
                        rect(fill="#e5e5e5" height="42" width="72" y="-1" x="-1")

                    g
                        title kropka
                        ellipse(ry="4" rx="4" cy="19.8625" cx="35.87499" stroke-width="1.5" stroke="#000" fill="#000")

            svg.name(width="80" height="50" :class="{'disabled': view.id !== 'worth'}" @click="$emit('change-view', 6)" x="50" y="430")
                rect.name-btn(x="0" y="0" width="100%" height="100%" rx="3" ry="3")

                svg.icon(height="40" width="70" x="5" y="5")
                    g
                        title fuzja
                        rect(fill="#000" height="42" width="72" y="-1" x="-1")

                    g
                        title fuzja
                        rect(y="4" x="32" height="8" width="8" transform="rotate(45 34, 8.85)" fill="red")

                        path(d="m10.76957,17.67421l5.1225,0l0,-4.70939l5.25471,0l0,4.70939l5.1225,0l0,4.83094l-5.1225,0l0,4.70939l-5.25471,0l0,-4.70939l-5.1225,0l0,-4.83094z" fill="green")
                        path(d="m45.5189,17.67421l5.1225,0l0,-4.70939l5.25471,0l0,4.70939l5.1225,0l0,4.83094l-5.1225,0l0,4.70939l-5.25471,0l0,-4.70939l-5.1225,0l0,-4.83094z" fill="green")

                        ellipse(ry="5" rx="5" cy="31.61239" cx="35.87499" stroke-width="1.5" stroke="#000" fill="#fff")
</template>

<script>
    export default {
        name: 'OptiOptometristsSurveysDialogsPilotPath',
        components: {

        },
        props: {
            view: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>