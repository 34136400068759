import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import {UAC} from './UserAccessControl'
import store from './store'

class Broadcaster {
    constructor() {
        let push = store.getters['globals/env']('push')

        window.Pusher = Pusher
        this.Echo = new Echo({
            broadcaster: 'pusher',
            key: push.key,
            cluster: push.config.cluster,
            encrypted: push.config.encrypted,
            disableStats: true
        })
    }

    get legacyUser() {
        return this.Echo.channel(`user-${UAC.user.id}`)
    }

    get legacyDepartment() {
        return this.Echo.channel(`department-${UAC.user.activeDepartmentId}`)
    }

    get user() {
        return this.Echo.private(`user-${UAC.user.id}`)
    }

    get department() {
        return this.Echo.private(`department-${UAC.user.activeDepartmentId}`)
    }

    get channels() {
        return this.Echo.connector.channels
    }

    userUnbind(eventName, callback, context) {
        return this.user.subscription.unbind(eventName, callback, context)
    }

    departmentUnbind(eventName, callback, context) {
        return this.department.subscription.unbind(eventName, callback, context)
    }
}

const Instance = new Broadcaster();
export default Instance
