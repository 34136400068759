<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-heart.mr3
            span(style="margin-right: 7px") {{'catalog.offer.favorite.HEADER'|translate}}
            .label.label-primary(v-show="favorites.length") {{favorites.length}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$store.commit('offer/setDialog', {type: 'favorites', bool: false})")

        template(#body)
            .row
                .col-xs-12
                    OptiCatalogOfferChoiceProducts

                .col-xs-9
                    h4.filter-header.page-header {{'catalog.offer.favorite.HEADER'|translate}}

                .col-md-4.col-sm-6.offer-product(v-for="favorite in favorites" :key="favorite.id")
                    OptiProductInfo(
                        :product="favorite"
                        :selected="chose_products.map(c => c.product.id).includes(favorite.id)"
                        :department-id="department_id"

                        :is-offer="true")

                    .text-center
                        .btn.btn-success(:title="'catalog.offer.choice.title.SELECT'|translate"
                            :disabled="chose_products.filter(p => !p.choice_by_client).length > 2 || chose_products.map(c => c.product.id).includes(favorite.id)"
                            @click="$store.commit('offer/setChoseProduct', {product: favorite, choice_by_client: false})"
                            ) {{'catalog.offer.choice.button.CHECK'|translate}}

                        .btn.btn-default(:title="'catalog.offer.favorite.title.DELETE'|translate"
                            @click="$store.commit('offer/deleteFavorite', favorite)")
                            i.fa.fa-ban

                .col-xs-12.text-center.text-muted(v-show="!favorites.length")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'catalog.offer.favorite.notify.EMPTY'|translate}}

        template(#footer)
            OptiButton(@click.native="$store.commit('offer/setDialog', {type: 'favorites', bool: false})"
                ) {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiCatalogOfferChoiceProducts
        from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferChoiceProducts'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiCatalogOfferDialogFavorites',
        components: {
            OptiDialog,
            OptiCatalogOfferChoiceProducts,
            OptiProductInfo,
            OptiButton
        },
        mounted() {
            document.body.style.overflow = 'hidden'
        },
        destroyed() {
            document.body.style.overflow = ''
        },
        computed: {
            chose_products() {
                return this.$store.getters['offer/getChoseProducts']
            },
            favorites() {
                return this.$store.getters['offer/getFavorites']
            },

            department_id() {
                return this.$store.getters['offer/getDepartmentId']
            },
        }
    }
</script>

<style lang="less" scoped>
    i.fa.fa-heart {
        color: red;
        text-shadow: 0 0 5px #fff;
    }
</style>