<template lang="pug">
    .opti-select-trainings
        VueMultiSelect(
            :options="trainings"
            :value="training"
            label="id"
            :clearable="false"
            :placeholder="'search.training.PLACEHOLDER'|translate"
            :allowEmpty="false"
            :showLabels="false"
            :showNoResults="false"
            @input="updateSelected"
        )
            template(slot="singleLabel" slot-scope="props")
                small
                    span {{props.option.date_from}} - {{props.option.date_to}}
                    .pull-right \#{{props.option.id}}
                div {{props.option.name}}
            template(slot="option" slot-scope="props")
                small
                    span {{props.option.date_from}} - {{props.option.date_to}}
                    .pull-right \#{{props.option.id}}
                div {{props.option.name}}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import VueMultiSelect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiSelectTrainingByUser',
        components: {VueMultiSelect},
        props: {
            userId: {
                required: true
            }
        },
        data() {
            return {
                trainings: [],
                training: null
            }
        },
        mounted() {
            API.post('search/user/training', {
                user_id: this.userId
            }).then(res => {
                this.trainings = res.data
            }).catch(() => {
                this.$notify.error('search.training.notify.error.LOAD')
            })
        },
        methods: {
            updateSelected(training) {
                this.training = training
                this.$emit('selected-training', training)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .multiselect /deep/ .simple {
        width: 350px;
        right: 0;
    }
    .multiselect /deep/ .multiselect__tags {
        cursor: pointer;
    }
    .muted {
        opacity: 0.5;
    }
</style>
