<template lang="pug">
    .row
        .col-xs-12
            OptiDimmer(:active="loading")
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formDepartmentsOfficeAdd" @submit.prevent="addDepartmentsOffice")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'departments.office.table.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="name" required)
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'departments.office.table.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="OfficeType" :value="type" @update-enum="updateType")
                .form-group
                    label.col-sm-4.control-label.star-required(for="department_id") {{'departments.office.table.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department_id(
                            :departmentId="department_id"
                            :disabled-departments="disabled_departments"
                            :filter="filterOutHeadquarters"
                            @selected="updateDepartment")

                .form-group
                    .col-xs-12
                        .panel.panel-primary
                            .panel-heading
                                i.fa.fa-building.mr3
                                span {{'departments.office.departments.HEADING'|translate}}
                                .pull-right
                                    span.label.label-inverse {{departments.length}}
                            table.table.table-responsive.teable-striped.middle
                                thead
                                    tr
                                        th.text-right #
                                        th {{'departments.office.departments.NAME'|translate}}
                                        th
                                tbody
                                    tr(v-for="(department, key) in departments" :key="department.id")
                                        td.text-right {{departments.length - key}}
                                        td
                                            OptiSelectDepartment(
                                                :departmentId="department.id"
                                                :disabled-departments="disabled_departments"
                                                :index="key"
                                                :filter="filterOutHeadquarters"
                                                @selected="updateDepartments")
                                        td.text-center
                                            .btn.btn-danger(@click="deleteDepartment(key)")
                                                i.fa.fa-trash

                                    tr(v-show="!departments.length")
                                        td.text-center(colspan="3")
                                            i.fa.fa-exclamation-circle.mr3
                                            span {{'departments.office.departments.NONE'|translate}}
                                tfoot
                                    tr
                                        td.text-right(colspan="3")
                                            OptiButton(type="primary" :disabled="!departments_selected"
                                                @click.native="addDepartment") {{'departments.office.departments.ADD'|translate}}
                                                template(#icon)
                                                    span.fa.fa-plus

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="!(name && department_id)")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiSelectEnum from '../../Select/OptiSelectEnum'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment.vue'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiDepartmentsOfficeAdd',
        components: {
            OptiDimmer, OptiSelectEnum, OptiSelectDepartment, OptiButton, OptiButtonSave
        },
        data() {
            return {
                name: '',
                type: 0,
                department_id: 0,
                departments: [],

                disabled_departments: [],

                loading: false,
                departments_selected: true
            }
        },
        mounted() {

        },
        methods: {
            filterOutHeadquarters(items) {
                let headquarters = [91, 49, 149, 60, 86, 392, 402]

                return items.filter(department => headquarters.indexOf(department.id) === -1)
            },
            disabledDepartments() {
                this.disabled_departments = []

                if(this.department_id) {
                    this.disabled_departments.push(this.department_id)
                }

                this.departments.forEach(department => {
                    if(Object.keys(department).length) {
                        this.disabled_departments.push(department.id)
                    }
                })
            },
            checkDepartmentSelected() {
                this.departments_selected = true

                this.departments.forEach(department => {
                    if(!Object.keys(department).length) {
                        this.departments_selected = false
                    }
                })
            },
            changeDepartments() {
                this.disabledDepartments()
                this.checkDepartmentSelected()
            },
            updateDepartment: function(department) {
                this.department_id = department.id

                this.changeDepartments()
            },
            updateDepartments: function(department) {
                this.departments[department.index] = department

                this.changeDepartments()
            },
            deleteDepartment(key) {
                this.$delete(this.departments, key)

                this.changeDepartments()
            },
            addDepartment() {
                this.departments.push({})

                this.changeDepartments()
            },
            addDepartmentsOffice() {
                this.loading = true

                API.post('departments/office', {
                    name: this.name,
                    type: this.type,
                    department_id: this.department_id,
                    departments: this.departments
                }).then(() => {
                    this.$state.go('app.departments.offices.table')

                    this.$notify.success('departments.office.notify.ADD')
                }).catch(() => {
                    this.$notify.error('departments.office.notify.error.ADD')

                    this.loading = false
                })
            },

            updateType(type) {
                this.type = type
            }
        }
    }
</script>