<template lang="pug">
    .row
        .col-xs-12
            OptiSmileMoreOrderValidationUpload(
                @file-uploaded="fileUploaded = true"
            )

        .col-xs-12
            OptiOrderValidationsTable(
                :file-uploaded="fileUploaded"

                @page-refreshed="fileUploaded = false"
            )

</template>
<script>
    import OptiSmileMoreOrderValidationUpload
        from '@/js/app/vue/components/Warehouse/Orders/Validations/OptiSmileMoreValidationUpload.vue'
    import OptiOrderValidationsTable
        from '@/js/app/vue/components/Warehouse/Orders/Validations/OptiOrderValidationsTable.vue'
    export default {
        name: 'OptiSmileMoreValidations',
        components: {OptiSmileMoreOrderValidationUpload, OptiOrderValidationsTable},

        data() {
            return {
                fileUploaded: false
            }
        }
    }
</script>


<style scoped lang="less">

</style>