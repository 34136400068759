<template lang="pug">
.row
    .panel.panel-default
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'orders.validation.headers.UPLOAD'|translate}}
        .panel-body
            OptiUpload#smileMoreOrder(
                prefix="order_validation"

                :file="file"

                @update-loading="l => loading.file = l"
                @update-file="f => file = f"
            )
        .panel-footer.clearfix
            OptiButton.pull-right(
                :disabled="!file"
                @click.native="checkMoreOrder"
            ) {{'orders.validation.button.CHECK_ORDER'|translate}}

    OptiDimmer(:active="loading.main")
</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiUpload from '@/js/app/vue/components/OptiUpload.vue'
import OptiButton from '@/js/app/vue/components/Button/OptiButton.vue'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer.vue'
export default {
    name: 'OptiSmileMoreOrderValidationUpload',
    components: {OptiDimmer, OptiButton, OptiUpload},
    data() {
        return {
            type: null,
            loading: {
                main: false,
                file: false
            },
            file: null
        }
    },
    methods: {
        checkMoreOrder() {
            this.loading.main = true

            API.post('smileMoreOrders/validate', {
                file: this.file,
                delivery_type: 'more'
            }).then((response) => {
                window.open(response.data)
            }).then(() => {
                this.file = null
            }).then(() => {
                this.$emit('file-uploaded')
            }).catch(() => {
                this.$notify.error('Coś poszło nie tak! Sprawdź czy plik ma rozszerzenie .xlsx')
            }).finally(() => {
                this.loading.main = false
            })
        }
    }
}
</script>
<style scoped lang="less">

</style>