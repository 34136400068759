<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-plus.mr3
            span {{'layout.unit.ADD'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .form-horizontal
                .form-group
                    label.col-lg-4.control-label(for="category") {{'layout.unit.CATEGORY'|translate}}
                    .col-lg-8
                        OptiSelectBrand#category(:category="selected.category" @selected="c => selected.category = c")

                .form-group(style="margin-bottom:0")
                    label.col-lg-4.control-label(for="facing") {{'layout.unit.facing.BEGIN'|translate}} ({{object.config.min}} - {{object.config.max}})
                    .col-lg-8
                        OptiNumber#facing(:header="'layout.unit.facing.BEGIN'|translate"
                            :min="object.config.min" :max="object.config.max"
                            :value="selected.size" @confirmed="s => selected.size = s") {{selected.size}}

        template(#footer)
            OptiButton(type="success" :disabled="disabled" @click.native="$emit('confirmed', selected)") {{'default.OK'|translate}}
                template(#icon)
                    i.fa.fa-check

            OptiButton(type="danger" @click.native="$emit('closed')") {{'default.CANCEL'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiSelectBrand from '@/js/app/vue/components/Select/OptiSelectBrand'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiLayoutUnitBrandAddDialog',
        components: {
            OptiDialog,
            OptiSelectBrand,
            OptiNumber,
            OptiButton
        },
        props: {
            object: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                selected: {
                    category: {},
                    size: 0
                }
            }
        },
        mounted() {

        },
        computed: {
            disabled() {
                return !this.selected.category.id || !(this.object.config.min <= this.selected.size && this.selected.size <= this.object.config.max)
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 600px;

        > .modal-body {
            overflow: unset !important;
        }
    }
</style>