<template lang="pug">
    div
        input.form-control(:id="'coupon' + id" v-model="input" :name="'coupon' + id" maxlength="100"
            @input="changeCodes()" required)
        div(v-show="codes && codes.length")
            div {{'carts.sale.coupons.VALID'|translate}}:
            div(v-for="code in codes")
                a(:href="$state.href('app.coupon.details', {id: code})") {{code}}

        div(v-show="pending")
            i.fa.fa-gear.fa-spin.mr3
            span {{'default.PENDING'|translate}}

        div(v-show="!pending")
            label.warn(v-show="!input" :for="'coupon' + id"
                ) {{'validation.formValidate.required'|translate}} ({{q}} {{'carts.sale.coupons.QUANTITY'|translate}})
            label.warn(v-show="errors.length" :for="'coupon' + id")
                div(v-for="e in errors")
                    span.mr3(v-show="e.type") {{e.coupon}}:
                    span(v-show="e.type")
                        span(v-show="e.type === 'message'") {{e.text}}
                        a.link(v-show="e.type === 'sale'" :title="'default.REFERENCETYPE'|translate"
                            :href="$state.href('app.sale.details', {id: e.id})") {{e.message}}
                        a.link(v-show="e.type === 'task'" :title="'default.REFERENCETYPE'|translate"
                            :href="$state.href('app.task.detail', {id: e.id})") {{e.message}}
                        a.link(v-show="e.type === 'saleoffstock'" :title="'default.REFERENCETYPE'|translate"
                            :href="$state.href('app.sale-offstock.details', {id: e.id})") {{e.message}}
                    span(v-show="!e.type") {{'coupons.validate.DEFAULT'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'

    export default {
        name: 'OptiCartSaleCoupon',
        components: {

        },
        props: {
            id: {
                type: Number,
                required: true
            },
            productId: {
                type: Number,
                required: true
            },
            quantity: {
                type: Number,
                required: true
            },

            comments: {
                type: String,
                required: true
            },
            codes: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                input: '',

                timeout: null,
                delay: 2000,

                pending: false,

                errors: []
            }
        },
        mounted() {
            this.input = this.comments
        },
        methods: {
            getCoupons() {
                //TODO: przerobić tak by było jedna API!!!

                API.post('validate/coupon/count', {
                    code: this.input,
                    product_id: this.productId,
                    count: this.quantity
                }).then(res => {
                    if(res.data.validates) {
                        this.$emit('valid-codes', this.input)
                    } else {
                        this.errors.push({
                            type: null
                        })
                    }
                }).catch(() => {
                    this.$notify.error('default.notify.ERRORDBMATCH')
                })
            },
            changeCodes() {
                this.pending = true

                clearTimeout(this.timeout)

                this.timeout = setTimeout(() => {
                    if(this.input) {
                        API.post('validate/coupon', {
                            code: this.input,
                            product_id: this.productId,
                            count: this.quantity
                        }).then(res => {
                            this.errors = res.data

                            if(!this.errors.length) {
                                this.getCoupons()
                            }
                        }).finally(() => {
                            this.pending = false
                        }).catch(() => {
                            this.errors = []

                            this.$notify.error('default.notify.ERRORDBMATCH')
                        })
                    } else {
                        this.pending = false
                        this.errors = []
                    }
                }, this.delay)
            }
        },
        computed: {
            q() {
                this.changeCodes()

                return this.quantity
            }
        }
    }
</script>

<style scoped lang="less">

</style>