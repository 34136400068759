<template lang="pug">
    .row
        .col-xs-12
            .row(style="margin-bottom:10px")
                .col-sm-9
                    h4.filter-header.page-header
                        span.mr3(style="font-size:inherit") {{'catalog.offer.photos.HEADING'|translate}}
                        OptiPatient(:data="patient")

            .row(style="position:relative")
                .col-sm-6.product(style="margin-bottom:15px" :class="{'col-md-4': chose_products.length < 4, 'col-md-3': chose_products.length === 4}"
                    v-for="(chose_product, key_product) in chose_products" :key="chose_product.product.id")
                    OptiProductInfo(:class="{'opti-product-info-small': chose_products.length === 4}"
                        :product="chose_product.product"
                        :department-id="department_id"

                        :is-offer="true")

                    div(style="margin-bottom:15px" v-if="colors_header && chose_product.color")
                        div {{'catalog.lenses.properties.heading.' + colors_header|translate}}
                        div(style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis"
                            :title="chose_product.color.name_long") {{chose_product.color.name_long}}

                    OptiUpload(style="margin-top:10px;margin-bottom:10px"
                        :id="chose_product.product.id.toString()"
                        prefix="offer_frame_photo"
                        :picture="true"

                        :file="chose_product.photo.filename"

                        @update-file="f => $store.commit('offer/setPhoto', {key_product: key_product, type: 'filename', value: f})"
                        @update-loading="l => $store.commit('offer/setPhoto', {key_product: key_product, type: 'loading', value: l})")

                    .text-center.warn(style="margin-top:15px" v-show="chose_product.choice_by_client")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'catalog.offer.choice.byClient.HEADER'|translate}}

            .row
                .col-sm-6.text-center-xs(style="margin-bottom:10px")
                    OptiButton(@click.native="$store.commit('offer/setMode', 'choice')"
                        ) {{'catalog.offer.photos.navi.BACK_TO_CHOICE'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                .col-sm-6.text-right.text-center-xs(style="margin-bottom:10px")
                    OptiButton(type="success" :disabled="loading"
                        text="catalog.offer.photos.confirm" @confirmed="$store.dispatch('offer/finish')"
                        ) {{'catalog.offer.photos.confirm.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-save
</template>

<script>
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'

    export default {
        name: 'OptiCatalogOfferChoice',
        components: {
            OptiPatient,
            OptiButton,
            OptiProductInfo,
            OptiUpload
        },
        computed: {
            patient() {
                return this.$store.getters['offer/getPatient']
            },

            chose_products() {
                return this.$store.getters['offer/getChoseProducts']
            },

            colors_header() {
                return this.$store.getters['offer/getColorsHeader']
            },

            department_id() {
                return this.$store.getters['offer/getDepartmentId']
            },

            loading() {
                return this.$store.getters['offer/getLoadingPhoto']
            }
        }
    }
</script>