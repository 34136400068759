<template lang="pug">
    .row
        .col-sm-6(style="margin-bottom:15px")
            OptiSelectDepartment(
                :department-id="department_id"
                :reduce="department => department.id"
                :disabled="$uac.user.department.length === 1"
                :filter="filterOutHeadquarters"

                @selected="updateDepartment")
        .col-sm-6(style="margin-bottom:15px")
            OptiSelectEmployeeByDepartment(
                :department-id="department_id"
                :reduce="user => user.id"
                :disabled="!permission.view_others"

                @selected="updateUser")

        .col-xs-12
            OptiTableEmployeeBonus(
                :department-id="department_id"
                :user-id="user_id"
                :current-quarter="current_quarter"

                @update-current-quarter="c => current_quarter = c")

        .col-xs-12
            OptiChartEmployeeCorrectedReachedBudget(
                :department-id="department_id"
                :user-id="user_id"
                :date="date")

        .col-xs-12.text-right(style="padding-bottom:10px" v-if="permission.manager")
            OptiButtonToggle(:enum="'BudgetQuarterName'" :model="quarter" @change="loadQuarter")
            select.form-control(style="display:inline-block;margin-left:20px;width:120px" v-model="year")
                option(v-for="y in years()") {{y}}
        .col-xs-12(v-if="permission.manager")
            OptiChartDepartmentBudget(:department-id="department_id" :year="year" :quarter="quarter")

</template>

<script>
    import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiSelectEmployeeByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeByDepartment'
    import OptiButtonToggle from '@/js/app/vue/components/Button/OptiButtonToggle'

    import OptiTableEmployeeBonus from '@/js/app/vue/components/Table/OptiTableEmployeeBonus'
    import OptiChartEmployeeCorrectedReachedBudget from '@/js/app/vue/components/Chart/OptiChartEmployeeCorrectedReachedBudget'

    import OptiChartDepartmentBudget from '@/js/app/vue/components/Chart/OptiChartDepartmentBudget'

    export default {
        name: 'BonusShow',
        components: {
            OptiSelectDepartment,
            OptiSelectEmployeeByDepartment,
            OptiButtonToggle,

            OptiTableEmployeeBonus,
            OptiChartEmployeeCorrectedReachedBudget,

            OptiChartDepartmentBudget
        },
        data() {
            let headquarters = [91, 49, 149, 60, 86, 392],
                year = parseInt(moment().format('GGGG'))

            return {
                headquarters: headquarters,
                department_id: parseInt(!headquarters.includes(this.$state.params.department_id) ?
                    this.$state.params.department_id : 0),
                user_id: 0,

                year: year,
                quarter: BudgetHelper.getQuarterByWeek(moment()),

                current_quarter: true,

                years() {
                    return [
                        year - 1,
                        year,
                        year + 1
                    ]
                },

                permission: {
                    manager: this.$uac.permission('schedule.manager'),
                    view_others: this.$uac.permission('schedule.view_others')
                }
            }
        },
        methods: {
            updateDepartment(id) {
                if(this.department_id !== id) {
                    this.$state.go('app.dictionary.bonus.show', {
                        department_id: id
                    })
                }
            },
            updateUser(user_id) {
                this.user_id = user_id
            },

            async loadQuarter(quarter) {
                this.quarter = parseInt(quarter)
            },

            filterOutHeadquarters(items) {
                return items.filter(department => !this.headquarters.includes(department.id))
            }
        },
        computed: {
            date() {
                if(!this.current_quarter) {
                    return BudgetHelper.changeQuarter(moment(), '-').start
                }

                return moment()
            }
        }
    }
</script>
