import {API} from '@/js/app/vue/api'
import Notify from '@/js/app/vue/helpers/Notify'

class ContactLensCalculateClass {
    constructor() {

    }

    init(interview, correction, contactLens) {
        this.interview = interview

        this.correction = correction

        this.contactLens = contactLens
    }

    round(power) {
        if(!power || power % 0.25 === 0) {
            return power
        }

        let rest = power % 0.25

        if(rest > 0) {
            if(rest >= 0.125) {
                return power + (0.25 - rest)
            } else {
                return power - rest
            }
        } else {
            rest = Math.abs(rest)

            if(rest >= 0.125) {
                return power - (0.25 - rest)
            } else {
                return power + rest
            }
        }
    }

    sph(sph, cyl) {
        if(!this.interview.contactLens.toric) { // jak sferyczne to es
            if(cyl * 50 % 1 === 0) {
                sph = sph + cyl / 2
            } else {
                sph = sph + (cyl + 0.25) / 2
            }
        }

        return this.round(sph / (1 + -0.011 * sph))
        //to jest wartość BVD 11 wzięta ze strony https://coopervision.pl/specjalista/narzedzia-i-kalkulatory/optiexpert/optiexpert-web#/calculator
    }

    cyl(sph, cyl) {
        let plus = {
                sph: 0,
                cyl: 0
            },
            minus = {
                sph: 0,
                cyl: 0
            }

        if(cyl === 0 || !this.interview.contactLens.toric) {
            return 0
        } else if(cyl > 0) {
            plus.sph = sph
            plus.cyl = cyl

            minus.sph = plus.sph + cyl
            minus.cyl *= -1

            plus.sph = this.sph(plus.sph, plus.cyl)
            minus.sph = this.sph(minus.sph, minus.cyl)

            return minus.sph - plus.sph
        } else if(cyl < 0) {
            minus.sph = sph
            minus.cyl = cyl

            plus.sph = minus.sph + cyl
            plus.cyl *= -1

            minus.sph = this.sph(minus.sph, minus.cyl)
            plus.sph = this.sph(plus.sph, plus.cyl)

            return plus.sph - minus.sph
        }
    }

    postPowers() {
        let r_type = null,
            l_type = null

        if(this.interview.contactLens.r_type === 2) {
            r_type = 'near'
        } else if(this.interview.contactLens.r_type === 3) {
            r_type = 'dist'
        }

        if(this.interview.contactLens.l_type === 2) {
            l_type = 'near'
        } else if(this.interview.contactLens.l_type === 3) {
            l_type = 'dist'
        }

        return {
            toric: this.interview.contactLens.toric,

            r_sph: r_type ? this.interview.contactLens.powers[r_type + '_r_sph'] : 0,
            r_cyl: r_type ? this.interview.contactLens.powers[r_type + '_r_cyl'] : 0,
            r_axs: r_type ? this.interview.contactLens.powers[r_type + '_r_axs'] : 0,

            l_sph: l_type ? this.interview.contactLens.powers[l_type + '_l_sph'] : 0,
            l_cyl: l_type ? this.interview.contactLens.powers[l_type + '_l_cyl'] : 0,
            l_axs: l_type ? this.interview.contactLens.powers[l_type + '_l_axs'] : 0
        }
    }

    set() {
        let before = {
            dist: {
                r: {
                    sph: this.interview.dist_r_sphere,
                    cyl: this.interview.dist_r_cylinder,
                    axs: this.interview.dist_r_axis
                },
                l: {
                    sph: this.interview.dist_l_sphere,
                    cyl: this.interview.dist_l_cylinder,
                    axs: this.interview.dist_l_axis
                }
            },
            near: {
                r: {
                    sph: this.interview.near_r_sphere,
                    cyl: this.interview.near_r_cylinder,
                    axs: this.interview.near_r_axis
                },
                l: {
                    sph: this.interview.near_l_sphere,
                    cyl: this.interview.near_l_cylinder,
                    axs: this.interview.near_l_axis
                }
            }
        };

        this.correction.matches = {
            dist: {
                r: {
                    sph: this.sph(before.dist.r.sph, before.dist.r.cyl),
                    cyl: this.cyl(before.dist.r.sph, before.dist.r.cyl)
                },
                l: {
                    sph: this.sph(before.dist.l.sph, before.dist.l.cyl),
                    cyl: this.cyl(before.dist.l.sph, before.dist.l.cyl)
                }
            },
            near: {
                r: {
                    sph: this.sph(before.near.r.sph, before.near.r.cyl),
                    cyl: this.cyl(before.near.r.sph, before.near.r.cyl)
                },
                l: {
                    sph: this.sph(before.near.l.sph, before.near.l.cyl),
                    cyl: this.cyl(before.near.l.sph, before.near.l.cyl)
                }
            }
        }

        if(!this.interview.contactLens.toric) {
            this.interview.contactLens.r_rotation = 0
            this.interview.contactLens.l_rotation = 0

            this.interview.contactLens.powers.dist_r_cyl = 0
            this.interview.contactLens.powers.dist_r_axs = 'brak'
            this.interview.contactLens.powers.dist_l_cyl = 0
            this.interview.contactLens.powers.dist_l_axs = 'brak'

            this.interview.contactLens.powers.near_r_cyl = 0
            this.interview.contactLens.powers.near_r_axs = 'brak'
            this.interview.contactLens.powers.near_l_cyl = 0
            this.interview.contactLens.powers.near_l_axs = 'brak'
        }

        return new Promise((resolve, reject) => {
            API.post('optometrists/contact-lens/products', this.postPowers(), API.id(Math.random())).then(res => {
                this.contactLens.product = res.data

                let product_r_exists = false,
                    product_l_exists = false

                this.contactLens.product.r.forEach(lens => {
                    if(lens.id === this.interview.contactLens.r_product_id) {
                        product_r_exists = true
                    }
                })

                this.contactLens.product.l.forEach(lens => {
                    if(lens.id === this.interview.contactLens.l_product_id) {
                        product_l_exists = true
                    }
                })

                if(!product_r_exists) {
                    this.interview.contactLens.r_product_id = 0
                    this.interview.contactLens.r_product_name = ''
                }

                if(!product_l_exists) {
                    this.interview.contactLens.l_product_id = 0
                    this.interview.contactLens.l_product_name = ''
                }

                resolve({
                    interview: this.interview,
                    correction: this.correction,
                    contactLens: this.contactLens
                })
            }).catch(() => {
                Notify.error('optometrist.contactlens.lens.notify.error.LOADPRODUCT')

                reject()
            })
        })
    }
}

export const ContactLensCalculate = new ContactLensCalculateClass()