<template lang="pug">
.row(v-if="$asyncComputed.contactPlus.success")
    OptiDimmer(:active="active")
    .col-xs-12(style="margin-bottom:10px")
        OptiButtonHistory(
            data="contactPlus"
            :id="contactPlus.contactPlus.id"
        )
    .col-md-6.col-md-offset-3
        .clearfix
        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'patient.contactplus.ADDNEW'|translate}}
            .table-responsive
                table.table.table-striped
                    tbody
                        tr
                            th.text-right {{'patient.contactplus.PATIENTID'|translate}}
                            td
                                a.link(:href="$state.href('app.patient.detail',{id: contactPlus.contactPlus.patient.id})") {{contactPlus.contactPlus.patient.id}}
                        tr
                            th.text-right {{'patient.contactplus.PATIENT'|translate}}
                            td
                                a.link(:href="$state.href('app.patient.detail',{id: contactPlus.contactPlus.patient.id})") {{contactPlus.contactPlus.patient.firstName}} {{contactPlus.contactPlus.patient.lastName}}
                        tr
                            th.text-right {{'patient.contactplus.DEPARTMENT'|translate}}
                            td {{contactPlus.contactPlus.department.name}}
                        tr
                            th.text-right {{'patient.contactplus.CREATEDAT'|translate}}
                            td {{contactPlus.contactPlus.created_at}}
                        tr
                            th.text-right {{'patient.contactplus.END'|translate}}
                            td {{contactPlus.contactPlus.contract_end_date}}
                        tr
                            th.text-right {{'patient.contactplus.STATUS'|translate}}
                            td(v-show="contactPlus.contactPlus.status === 0") {{'patient.contactplus.status.EDITABLE'|translate}}
                            td(v-show="contactPlus.contactPlus.status === 1") {{'patient.contactplus.status.ACTIVE'|translate}}
                            td(v-show="contactPlus.contactPlus.status === 2") {{'patient.contactplus.status.COMPLETED'|translate}}
                        tr
                            th.text-right
                                label(for="cycle") {{'patient.contactplus.CYCLE'|translate}}
                            td
                                select.form-control#cycle(name ="cycle" v-model="contactPlus.contactPlus.cycle"
                                    :disabled="contactPlus.contactPlus.status !== 0" required)
                                    option(value="7") 1 tydzień
                                    option(value="14") 2 tygodnie
                                    option(value="21") 3 tygodnie
                                    option(value="30") 1 miesiąc
                                    option(value="45") 1,5 miesiąca
                                    option(value="60") 2 miesiące
                                    option(value="90") 3 miesiące
                                    option(value="120") 4 miesiące
                                label.warn(for="cycle" style="display:block"
                                    v-show="!contactPlus.contactPlus.cycle") {{'validation.formValidate.required'|translate}}
                        tr(v-show="contactPlus.contactPlus.status === 2")
                            th.text-right
                                label(for="comments") {{'patient.contactplus.REASON'|translate}}
                            td
                                textarea#comments.form-control(name="comments" v-model="contactPlus.contactPlus.comment"
                                    disabled="contactPlus.contactPlus.status === 2")
    .col-xs-12
        .panel.panel-primary
            .panel-heading
                i.fa.fa-navicon.mr3
                span {{'patient.contactplus.PRODUCTS'|translate}}
            table.table.table-striped.middle
                thead
                    tr
                        th #
                        th {{'patient.contactplus.ID'|translate}}
                        th {{'patient.contactplus.PRODUCTNAME'|translate}}
                        th {{'patient.contactplus.QTY'|translate}}
                        th {{'patient.contactplus.PRICE'|translate}}
                        th(colspan="5")
                tbody(table-empty)
                    tr(v-for="(detail, index) in contactPlus.contactPlus.details")
                        td {{index+1}}
                        td
                            input.form-control#product_id(v-model = "detail.product.id"  style="width:80px" disabled="detail" name="product_id")
                            label.warn(for="product_id" style="display:block"
                                v-show="!detail.product.id") {{'validation.formValidate.required'|translate}}
                        td
                            OptiSelectContactLenses(
                                :product="detail.product"
                                :index="index"
                                :disabled="contactPlus.contactPlus.status !== 0"
                                @selected="updateProduct(index, $event)"
                            )
                            label.warn(for="product" style="display:block"
                                v-show="!detail.product.id") {{'validation.formValidate.required'|translate}}
                        td
                            input#quantity.form-control(v-model="detail.quantity" name="quantity"
                                :disabled="contactPlus.contactPlus.status !== 0" style="width:70px")
                            label.warn(for="quantity" style="display:block"
                                v-show="!validateRow(detail) || detail.quantity === 0") {{'validation.formValidate.required'|translate}}
                        td
                            input#price.form-control(v-model="detail.price" name="price" disabled="detail" style="width:80px")
                            label.warn(for="price" style="display:block"
                                v-show="detail.price === 0") {{'validation.formValidate.required'|translate}}
                        td.text-right(v-show="detail.product.id === 32439 || detail.product.id === 25360") L:
                        td
                            input#lefteye.form-control(:disabled="contactPlus.contactPlus.status !== 0"
                                style="width:95px" v-model ="detail.left_eye" name="left_eye"
                                v-show="detail.product.id === 32439 || detail.product.id === 25360")
                        td.text-right(v-show="detail.product.id === 32439 || detail.product.id === 25360") P:
                        td
                            input#righteye.form-control(:disabled="contactPlus.contactPlus.status !== 0"
                                style="width:95px" v-model="detail.right_eye" name="right_eye"
                                v-show="detail.product.id === 32439 || detail.product.id === 25360")
                        td(v-show="detail.product.id !== 32439 && detail.product.id !== 25360" colspan="2")
                        td.text-right(v-show="contactPlus.contactPlus.status !== 0")
                        td.text-right(v-show="contactPlus.contactPlus.status === 0")
                            OptiButton(
                                @click.native="removeRow(index)"
                                type="danger"
                            ) {{'patient.contactplus.DELETE'|translate}}
                                template(#icon)
                                    i.fa.fa-trash
                tfoot(v-show="contactPlus.contactPlus.status === 0")
                        tr
                            td(colspan="100")
                                OptiButton(
                                    style="width:100%"
                                    @click.native="addRow"
                                ) {{'patient.contactplus.ADDPRODUCT'|translate}}
                                    template(#icon)
                                        i.fa.fa-plus
            .panel-footer
                .row
                    .col-sm-8
                        OptiButton(
                            @click.native="goBack"
                        ) {{'patient.contactplus.BACK'|translate}}
                            template(#icon)
                                i.fa.fa-angle-left
                        OptiButtonPrint(
                            url="patient/contact-plus/print"
                            :id="contactPlus.contactPlus.id"
                            v-show="contactPlus.contactPlus.status === 1"
                            style="margin-left:2px"
                        ) {{'patient.contactplus.PRINT'|translate}}
                    .col-sm-4.text-right
                        OptiButton(
                            type="danger"
                            v-show="contactPlus.contactPlus.status === 1"
                            @click.native="close_contract = true"
                        ) {{'patient.contactplus.COMPLETE'|translate}}
                            template(#icon)
                                i.fa.fa-remove
                            MountingPortal(v-if="close_contract" mountTo="#vue-modal" append)
                                OptiContactPlusDialogReasonCancel(
                                    @confirmed ="closeContract"
                                    @closed="close_contract = false"
                                )
                        OptiButton(
                            type="success"
                            v-show="contactPlus.contactPlus.status === 0"
                            :disabled="contactPlus.contactPlus.details.length < 1 || disable || !contactPlus.contactPlus.cycle"
                            text="patient.contactplus.acceptContract.confirm"
                            @confirmed="acceptContract"
                        ) {{'patient.contactplus.ACCEPT'|translate}}
                            template(#icon)
                                i.fa.fa-save
                        OptiButton(
                            type="default"
                            v-show="contactPlus.contactPlus.status === 1"
                            text="patient.contactplus.edit.confirm"
                            @confirmed="editContract"
                            style="margin-left:2px"
                        ) {{'patient.contactplus.action.EDIT'|translate}}
                            template(#icon)
                                i.fa.fa-save
</template>

<script>
import {API} from '@/js/app/vue/api'
import OptiButtonHistory from "@/js/app/vue/components/Button/OptiButtonHistory";
import OptiSelectContactLenses from "@/js/app/vue/components/Select/OptiSelectContactLenses";
import OptiButton from "@/js/app/vue/components/Button/OptiButton";
import OptiButtonPrint from "@/js/app/vue/components/Button/OptiButtonPrint";
import OptiDimmer from "@/js/app/vue/components/Blocks/OptiDimmer";
import OptiContactPlusDialogReasonCancel
    from "@/js/app/vue/components/Patients/ContactPlus/OptiContactPlusDialogReasonCancel";

export default {
    name: "OptiContactPlusAdd",
    components: {
        OptiContactPlusDialogReasonCancel,
        OptiDimmer,
        OptiButtonPrint,
        OptiSelectContactLenses,
        OptiButtonHistory,
        OptiButton
    },
    data() {
        return {
            active: false,
            details: {},
            disable: false,
            close_contract: false
        }
    },
    asyncComputed: {
        async contactPlus() {
            try {
                this.active = true
                let {data} = await API.get('patient/contact-plus/edit/' + this.$state.params.id)
                this.active = false
                return data
            } catch (e) {
                console.log(e)
            }
        }
    },
    methods: {
        validateRow(x) {
            let disable = false
            for(let i = 0; i < this.contactPlus.contactPlus.details.length; i++){
                if(this.contactPlus.contactPlus.details[i].quantity === 0 ||
                    this.contactPlus.contactPlus.details[i].price === 0){
                    disable = true
                    break
                }
            } return x.quantity !==0
        },
        addRow() {
            this.contactPlus.contactPlus.details.push({
                id: 0,
                product: {},
                product_id: 0,
                product_name: '',
                quantity: 0,
                price: 0,
                left_eye: '',
                right_eye: ''
            })
        },
        removeRow(index){
            this.contactPlus.contactPlus.details.splice(index, 1)
        },
        updateProduct(idx, product) {
            this.contactPlus.contactPlus.details[product.index].product = product
            this.contactPlus.contactPlus.details[product.index].quantity = 1
            this.contactPlus.contactPlus.details[product.index].price = product.price - (product.price * 0.1)
        },
        goBack() {
            this.$state.go('app.patients.contact-plus')
        },
        acceptContract() {
            this.active = true
            API.post('patient/contact-plus/edit/' + this.$state.params.id, {
                'details': this.contactPlus.contactPlus.details,
                'cycle': this.contactPlus.contactPlus.cycle
            }).then( () => {
                API.post('patient/contact-plus/edit/' + this.$state.params.id + '/accept').then( () => {
                    API.get('patient/contact-plus/edit/' + this.$state.params.id).then( res => {
                        this.contactPlus = res.data
                    })
                })
            }).finally( () => {
                this.active=false
            }).catch(() => {
                this.$notify.error('patient.contactplus.notify.error.ACCEPT')
            })
        },
        editContract() {
            this.active = true
            API.post('patient/contact-plus/edit/' + this.$state.params.id + '/editLenses').then( () => {
                API.get('patient/contact-plus/edit/' + this.$state.params.id).then( res => {
                    this.contactPlus = res.data
                })
            }).finally( () => {
                this.active = false
            }).catch( () => {
                this.notify.error('patient.contactplus.notify.error.ACCEPT')
            })
        },
        closeContract(reason) {
            this.active = true
            API.post('patient/contact-plus/' + this.$state.params.id + '/delete', {
                id: this.contactPlus.contactPlus.id,
                comment: reason
            }).then( () => {
                this.$notify.success('patient.contactplus.notify.DELETE')
            }).finally( () => {
                this.close_contract = false
                this.active = false
                API.get('patient/contact-plus/edit/' + this.$state.params.id).then( res => {
                    this.contactPlus = res.data
                })
            }).catch(()=>{
                this.$notify.error('patient.contactplus.notify.error.DELETE')
            })
        }
    }
}
</script>

<style scoped>

</style>