<template lang="pug">
    .table-responsive
        table.table.table-striped
            thead
                tr
                    th.text-right(rowspan="2") #
                    th(rowspan="2" style="border-right:1px solid #aaa") {{'optometrist.history.table.head.DESCRIPTION'|translate}}
                    th.text-center(style="border-right: 1px solid #aaa") {{'prescription.RIGHT'|translate}}
                    th.text-center(style="border-right: 1px solid #aaa") {{'prescription.LEFT'|translate}}
                    th.text-center(rowspan="2") BinVisus
                    th {{'optometrist.history.table.head.TIME'|translate}}
                tr
                    th.text-center(style="border-right:1px solid #aaa") Visus
                    th.text-center Visus
            tbody
                tr(v-for="(log, key) in survey.data")
                    td.text-right {{key + 1}}
                    td(style="border-right:1px solid #aaa") {{log.description}}
                    td.text-center(style="border-right:1px solid #aaa"
                        :class="{'bold-black': log.changed.length && log.changed.r.includes('visus')}") {{log.parsed.r[0].value}}
                    td.text-center(style="border-right:1px solid #aaa"
                        :class="{'bold-black': log.changed.length && log.changed.l.includes('visus')}") {{log.parsed.l[0].value}}
                    td.text-center(:class="{'bold-black': log.changed.length && log.changed.includes('binVisus')}") {{log.parsed.binVisus}}
                    td.text-right
                        span(v-show="log.action !== 'start'") {{log.time_diff|secondsToHuman}}
</template>

<script>
    export default {
        name: 'OptiPatientsPrescriptionDialogsSurveyNear',
        components: {

        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>