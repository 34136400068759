<template lang="pug">
    div
        .row
            .col-sm-6.text-center(style="margin-bottom:10px")
                img#pagnelli(style="width:85%" :src="'img/offer/pagnelli.png'")
            .col-sm-6(style="margin-bottom:10px")
                div(style="display:inline-block;margin-top:5px")
                    label.switch.switch-lg.mr3
                        input#english-version(type="checkbox" v-model="english_version")
                        span
                    label.control-label(style="cursor:pointer" for="english-version"
                        :class="{'text-normal': !english_version}") English ver.

                OptiButton.pull-right(:title="'catalog.offer.reset.TITLE'|translate" text="catalog.offer.reset"
                    @confirmed="$store.commit('offer/resetState');$store.dispatch('offer/loadQuestions')") {{'catalog.offer.reset.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-undo

        OptiCatalogOfferHeader(
            :selected="$store.getters['offer/getSelected']"
            :is-skipped="$store.getters['offer/isSkipped']"
            :mode="mode")

        OptiCatalogOfferQuestionsFrames(v-show="mode === 'questions_frames'")
        OptiCatalogOfferQuestionsHoya(v-show="mode === 'questions_hoya'")

        OptiCatalogOfferChoice(v-show="mode === 'choice'")

        OptiCatalogOfferPhotos(v-show="mode === 'photos'")

        OptiCatalogOfferFilters(v-show="mode === 'filters'")

        div(style="margin-top:30px" v-show="mode !== 'photos'")
            OptiCatalogOfferHelp(v-show="help")

            OptiCatalogOfferProducts(v-show="!help")

        OptiCatalogOfferDialogChoiceByClient(v-if="dialog_by_client")

        OptiDimmer(:active="loading")
</template>

<script>
    import {loadLanguageAsync} from '@/js/app/vue/i18n-setup'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferHeader from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeader'
    import OptiCatalogOfferQuestionsFrames from '@/js/app/vue/components/Catalog/Offer/Questions/OptiCatalogOfferQuestionsFrames'
    import OptiCatalogOfferQuestionsHoya
        from '@/js/app/vue/components/Catalog/Offer/Questions/OptiCatalogOfferQuestionsHoya'
    import OptiCatalogOfferChoice from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferChoice'
    import OptiCatalogOfferPhotos from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferPhotos'
    import OptiCatalogOfferFilters from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferFilters'
    import OptiCatalogOfferHelp from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferHelp'
    import OptiCatalogOfferProducts from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferProducts'
    import OptiCatalogOfferDialogChoiceByClient
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogChoiceByClient'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogOffer',
        components: {
            OptiButton,
            OptiCatalogOfferHeader,
            OptiCatalogOfferQuestionsFrames,
            OptiCatalogOfferQuestionsHoya,
            OptiCatalogOfferChoice,
            OptiCatalogOfferPhotos,
            OptiCatalogOfferFilters,
            OptiCatalogOfferHelp,
            OptiCatalogOfferProducts,
            OptiCatalogOfferDialogChoiceByClient,
            OptiDimmer
        },
        data() {
            return {
                default_language: this.$uac.user.lang + '_' + this.$uac.user.lang.toUpperCase()
            }
        },
        beforeMount() {
            this.$store.dispatch('offer/init')

            loadLanguageAsync(this.english_version ? 'en_EN' : this.default_language)
        },
        destroyed() {
            loadLanguageAsync(this.default_language)
        },
        computed: {
            english_version: {
                get() {
                    return this.$store.getters['offer/getEnglishVersion']
                },
                set(bool) {
                    this.$store.commit('offer/setEnglishVersion', bool)
                }
            },
            mode() {
                return this.$store.getters['offer/getMode']
            },
            help() {
              return this.$store.getters['offer/getHelp']
            },
            dialog_by_client() {
                return this.$store.getters['offer/getDialog']('by_client')
            },
            loading() {
                return this.$store.getters['offer/getLoading']('main')
            }
        }
    }
</script>