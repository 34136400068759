<template lang="pug">
    .row
        .col-xs-12(style="padding-bottom:15px" v-if="!loading")
            OptiButtonHistory(data="user" :id="user.id")
        .clearfix

        .col-md-offset-2.col-md-8(v-if="!loading")
            form#form-users.form-horizontal.form-validate(name="formUser" @submit.prevent="editUser")
                fieldset
                    .form-group
                        label.col-sm-4.control-label.star-required(for="login") Login
                        .col-sm-8
                            input#login.form-control(placeholder="Login" :disabled="!permission.edit"
                                name="login" v-model="user.login" required)
                            OptiValidate(f="login" :data="$v.user.login")

                .form-group
                    label.col-sm-4.control-label(for="password" :class="{'star-required': password_required}"
                        ) {{'user.PASSWORD'|translate}}
                    .col-sm-8
                        input#password.form-control(type="password" :placeholder="'user.PASSWORD'|translate"
                            name="password" v-model="user.password"
                            @input="passwordRequired" :required="password_required")
                        OptiValidate(f="password" :data="$v.user.password")
                .form-group
                    label.col-sm-4.control-label(for="confirmPassword" :class="{'star-required': password_required}"
                        ) {{'user.CONFIRMPASSWORD'|translate}}
                    .col-sm-8
                        input#confirmPassword.form-control(type="password" :placeholder="'user.CONFIRMPASSWORD'|translate"
                            name="confirmPassword"  v-model="user.confirmPassword"
                            @input="passwordRequired" :required="password_required")
                        OptiValidate(f="confirmPassword" :data="$v.user.confirmPassword")
                fieldset
                    .form-group(v-if="!permission.assign_to_department")
                        label.col-sm-4.control-label(for="oldPassword" :class="{'star-required': password_required}"
                            ) {{'user.OLDPASSWORD'|translate}}
                        .col-sm-8
                            input#oldPassword.form-control(type="password" :placeholder="'user.OLDPASSWORD'|translate"
                                name="oldPassword" v-model="user.oldPassword"
                                @input="passwordRequired" :required="password_required")
                            OptiValidate(f="oldPassword" :data="$v.user.oldPassword")

                .form-group
                    label.col-sm-4.control-label.star-required(for="firstName") {{'user.FIRSTNAME'|translate}}
                    .col-sm-8
                        input#firstName.form-control(:placeholder="'user.FIRSTNAME'|translate" name="firstName"
                            :disabled="!permission.edit && !permission.edit_own" v-model="user.firstName" required)
                        OptiValidate(f="firstName" :data="$v.user.firstName")
                .form-group
                    label.col-sm-4.control-label.star-required(for="lastName") {{'user.LASTNAME'|translate}}
                    .col-sm-8
                        input#lastName.form-control(:placeholder="'user.LASTNAME'|translate" name="lastName"
                            :disabled="!permission.edit && !permission.edit_own" v-model="user.lastName" required)
                        OptiValidate(f="lastName" :data="$v.user.lastName")
                .form-group
                    label.col-sm-4.control-label(for="cellPhoneNumber") {{'user.PHONE'|translate}}
                    .col-sm-8
                        input#cellPhoneNumber.form-control(:placeholder="'user.PHONE'|translate"
                            name="phone" v-model="user.cellPhoneNumber")
                .form-group
                    label.col-sm-4.control-label(for="email") E-mail
                    .col-sm-8
                        input#email.form-control(type="email" placeholder="E-mail"
                            :disabled="!permission.edit && !permission.edit_own" name="email" v-model="user.email")
                        OptiValidate(f="email" :data="$v.user.email")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        .checkbox.c-checkbox.checkbox-primary.inline
                            label
                                input#active(type="checkbox" :disabled="!permission.edit && !permission.edit_own"
                                    name="active" v-model="user.active")
                                span.fa.fa-check
                        label(for="active") {{'default.ACTIVE'|translate}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="pin") PIN
                    .col-sm-8
                        input#pin.form-control(placeholder="PIN" name="pin" v-model="user.pin" required)
                        OptiValidate(f="pin" :data="$v.user.pin")
                .form-group
                    label.col-sm-4.control-label(style="padding-top:0" for="ipad_serial_number")
                        span {{'user.ipad.HEADER'|translate}}
                        small(style="display:block") {{'user.ipad.TITLE'|translate}}
                    .col-sm-8
                        input#ipad_serial_number.form-control(:placeholder="'user.ipad.HEADER'|translate"
                            :disabled="!permission.edit && !permission.edit_own"
                            name="ipad_serial_number" v-model="user.ipad_serial_number")
                .form-group(v-if="permission.assign_to_department")
                    label.col-sm-4.control-label(for="position") {{'user.POSITION'|translate}}
                    .col-sm-8
                        OptiSelectEnum#position(enum="UserPosition" :disabled="!permission.edit && !permission.edit_own"
                            :value="user.position" @update-enum="p => user.position = p")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_employee") {{'user.DATEEMPLOYEE'|translate}}
                    .col-sm-8
                        OptiDate#date_employee(name="dateEmployee" :disabled="!permission.edit && !permission.edit_own"
                            :value="user.date_employee" @update-date="d => user.date_employee = d")
                        OptiValidate(f="date_employee" :data="$v.user.date_employee")
                .form-group
                    label.col-sm-4.control-label(for="dismissed") {{'user.DATEDISMISSED'|translate}}
                    .col-sm-8
                        OptiDate#dismissed(name="dateDismissed" :disabled="!permission.edit && !permission.edit_own"
                            :value="user.dismissed" @update-date="d => user.dismissed = d")
                .form-group
                    label.col-sm-4.control-label.star-required(for="week_h") {{'user.WEEKH'|translate}}
                    .col-sm-8
                        OptiNumber#week_h(style="text-align:left" :header="'user.WEEKH'|translate"
                            :disabled="!permission.edit && !permission.edit_own"
                            :value="user.week_h" @confirmed="h => user.week_h = h") {{user.week_h}}h
                        OptiValidate(f="week_h" :data="$v.user.week_h")
                .form-group
                    .col-sm-offset-4.col-sm-8
                        .checkbox.c-checkbox.checkbox-primary.inline
                            label
                                input#manager_zone(type="checkbox" :disabled="!permission.edit"
                                    name="manager_zone" v-model="user.manager_zone")
                                span.fa.fa-check
                        label(for="manager_zone") {{'user.MANAGER_ZONE'|translate}}

                OptiUserAssignDepartments(:groups="user.group" @validate-was-changed="b => validate_departments = b")

                .form-group
                    .col-sm-offset-4.col-sm-8(style="margin-top:10px"
                        v-if="permission.edit || permission.edit_own || ($uac.user.id === user.id)")
                        OptiButtonSave(:disabled="$v.user.$invalid || !validate_departments")

        .col-md-offset-2.col-md-8(v-if="!loading && !render_training")
            OptiUsersEditTrainings(:user-id="user.id")

        .col-md-offset-2.col-md-8(v-if="!loading && (permission.tests || ($uac.user.id === user.id))")
            OptiUsersEditTests(:user-id="user.id")

        .col-md-offset-2.col-md-8(v-if="!loading && (permission.documents || ($uac.user.id === user.id))")
            OptiUsersEditDocuments(:user-id="user.id" :documents="user.documents" @update-documents="updateDocuments")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {UserHelper} from '@/js/app/vue/helpers/UserHelper'
    import {required, requiredIf, minLength, sameAs, email} from 'vuelidate/lib/validators'
    import {unique, noZero} from '@/js/vue.validators.js'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiUserAssignDepartments from '@/js/app/vue/components/Users/OptiUserAssignDepartments'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiUsersEditTrainings from '@/js/app/vue/components/Users/Edit/OptiUsersEditTrainings'
    import OptiUsersEditTests from '@/js/app/vue/components/Users/Edit/OptiUsersEditTests'
    import OptiUsersEditDocuments from '@/js/app/vue/components/Users/Edit/OptiUsersEditDocuments'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiUserEdit',
        components: {
            OptiButtonHistory,
            OptiValidate,
            OptiSelectEnum,
            OptiDate,
            OptiNumber,
            OptiUserAssignDepartments,
            OptiButtonSave,
            OptiUsersEditTrainings,
            OptiUsersEditTests,
            OptiUsersEditDocuments,
            OptiDimmer
        },
        data() {
            return {
                user: {},

                high_roles: {},

                render_training: false,
                validate_departments: false,
                password_required: false,
                permission: {
                    edit: this.$uac.permission('user.edit'),
                    edit_own: this.$uac.permission('user.edit_own'),
                    assign_to_department: this.$uac.permission('user.assigment_to_salon'),
                    tests: this.$uac.permission('tests.results'),
                    documents: this.$uac.permission('user.documents')
                },
                loading: true
            }
        },
        validations() {
            return {
                user: {
                    login: {
                        required,
                        minLength: minLength(5),
                        unique: unique('employees', 'login', this.$state.params.id, 'EMPLOYEE_ID')
                    },
                    password: {
                        required: requiredIf(function() { return this.password_required }),
                        minLength: minLength(5)
                    },
                    confirmPassword: {
                        required: requiredIf(function() { return this.password_required }),
                        minLength: minLength(5),
                        sameAsPassword: sameAs('password')
                    },
                    oldPassword: {
                        required: requiredIf(function() { return this.password_required && !this.permission.assign_to_department }),
                        minLength: minLength(5)
                    },
                    firstName: {
                        required
                    },
                    lastName: {
                        required
                    },
                    email: {
                        email,
                        unique: unique('employees', 'email', this.$state.params.id, 'EMPLOYEE_ID')
                    },
                    pin: {
                        required,
                        minLength: minLength(5)
                    },
                    date_employee: {
                        required
                    },
                    week_h: {
                        noZero
                    }
                }
            }
        },
        mounted() {
            API.get('users/' + this.$state.params.id).then(res => {
                this.user = res.data

                this.high_roles = UserHelper.getHighRoles(this.user.group)

                this.$set(this.user, 'password', '')
                this.$set(this.user, 'confirmPassword', '')
                this.$set(this.user, 'oldPassword', '')
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('user.notify.ERRORLOADDATA')
            })
        },
        methods: {
            passwordRequired() {
                this.password_required = !!this.user.password || !!this.user.confirmPassword || !!this.user.oldPassword
            },

            updateDocuments(documents) {
                this.user.documents = documents

                this.render_training = true
                setTimeout(() => {
                    this.render_training = false
                }, 1000)
            },

            editUser() {
                if(!_.isEqual(this.high_roles, UserHelper.getHighRoles(this.user.group)) && confirm(this.$filters.translate('user.high_role_confirm.MESSAGE'))) {
                    this.goToEdit(true)
                } else {
                    this.goToEdit(false)
                }
            },
            goToEdit(high_role_alert) {
                this.loading = true
                this.user.high_role_alert = high_role_alert

                API.put('users', this.user).then(() => {
                    if(this.user.id === this.$uac.user.id) {
                        this.$uac.relogin(this.user.id)
                    } else {
                        this.$state.go('app.users.list', {
                            search: [
                                'active:=:yes'
                            ]
                        })
                    }

                    this.$notify.success('user.UPDATED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('user.notify.ERRORLOADDATA')
                })
            }
        }
    }
</script>