<template lang="pug">
    .opti-select-product
        VueMultiSelect(
            :options="products"
            :value="product"
            :clearable="false"
            label="name"
            :placeholder="'search.product.PLACEHOLDER'|translate"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            ref="select"
            track-by="id"
            @input="updateSelected"
            :loading="loading"
            group-label="type"
            group-values="products"
            :group-select="false"
            :internal-search="false"
            :searchable="true"
            @search-change="loadData"
            :disabled="disabled")
            template(slot="singleLabel" slot-scope="props")
                span {{ props.option.name }}
                small.pull-right {{ props.option.id }}
            template(slot="option" slot-scope="props")
                span(v-if="props.option.$isLabel") {{ props.option.$groupLabel }}
                span(v-if="!props.option.$isLabel")
                    span {{ props.option.name }}
                    small.pull-right {{ props.option.id }}
            template(slot="noOptions") {{'search.EMPTY'|translate}}
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {UAC} from '@/js/app/vue/UserAccessControl'

    import VueMultiSelect from 'vue-multiselect'

    export default {
        name: 'OptiSelectProduct',
        components: {VueMultiSelect},
        props: {
            product: {
                type: Object,
                required: false,
                default() { {} }
            },
            departmentId: {
                type: Number,
                required: false,
                default: UAC.user.activeDepartmentId
            },
            index: {
                type: Number,
                required: false
            },
            autoSelect: {
                type: Boolean,
                required: false,
                default: false
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                queue: null,
                products: []
            }
        },
        methods: {
            reset() {
                this.products = [{
                    type: 'Produkt z id',
                    products: []
                }, {
                    type: 'Produkty z nazwy',
                    products: []
                }, {
                    type: 'Produkty z kodu kreskowego',
                    products: []
                }]
            },
            async loadData(data) {
                clearTimeout(this.queue)

                if(data.length >= 3) {
                    this.loading = true

                    this.queue = setTimeout(() => {
                        try {
                            API.post('search/product', {
                                name: data,
                                department_id: this.departmentId
                            }).then(res => {
                                this.reset()

                                res.data.forEach((product) => {
                                    if(data == product.id) {
                                        this.products[0].products.push(product)
                                    } else if(data == product.barcode) {
                                        this.products[2].products.push(product)
                                    } else {
                                        this.products[1].products.push(product)
                                    }
                                })

                                if(this.autoSelect && res.data.length === 1) {
                                    this.$emit('selected', res.data[0])
                                }

                                this.loading = false
                            })
                        } catch (error) {
                            console.log(error)

                            this.loading = false
                        }
                    }, 2000)
                }
            },
            updateSelected(product) {
                if(typeof this.index !== 'undefined') {
                    product.index = this.index
                }

                this.$emit('selected', product)
            }
        }
    }
</script>

<style scoped>
    .multiselect,
    .multiselect /deep/ .multiselect__input,
    .multiselect /deep/ .multiselect__single {
        font-size: 14px;
    }
    .muted {
        opacity: 0.5;
    }
    .small {
        font-size: 10px;
    }
</style>
