<template lang="pug">
    .row
        .col-xs-12
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-video-camera.mr3
                    span {{'catalog.edit.movies.HEADING'|translate}}

                .panel-body
                    form.form-horizontal(@submit.prevent="save")
                        .form-group.form-group-nested(v-for="(movie, index) in $v.movies.$each.$iter" :key="index" :class="{'bg-separator': index % 2}")
                            .col-xs-12
                                .form-group
                                    label.col-md-4.control-label.star-required(:for="'upload-movie-' + index") {{movie.label.$model}}
                                    .col-md-8
                                        OptiUpload(
                                            :id="'upload-movie-' + index"

                                            prefix="catalog_movie"

                                            :file="movie.video.$model"

                                            @update-file="i => movie.video.$model = i")
                                        OptiValidate(f="upload-movie" :data="movie.video")

                            .col-xs-12(v-if="movie.video.$model")
                                video(style="width:100%" controls :src="'upload/' + movie.video.$model")

                        .form-group.text-center.text-muted(style="padding-top:10px" v-show="!movies.length")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'default.table.EMPTY'|translate}}

                .panel-footer.text-right
                    OptiButtonSave(:disabled="$v.movies.$invalid" @click.native="save")

                OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiUpload from '@/js/app/vue/components/OptiUpload'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCatalogCategoryFormMovies',
        components: {
            OptiUpload,
            OptiNumber,
            OptiValidate,
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        props: {
            catalogId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                movies: [],

                loading: true
            }
        },
        validations: {
            movies: {
                $each: {
                    label: {

                    },
                    video: {
                        required
                    }
                }
            }
        },
        watch: {
            catalogId() {
                this.load()
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('catalog/movies/' + this.catalogId).then(res => {
                    this.$set(this, 'movies', res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.edit.movies.notify.error.LOAD')
                })
            },
            validate() {
                let _return = true

                this.movies.forEach(movie => {
                    if(movie.video && !movie.video.split('.').pop().toLowerCase().startsWith('mp4')) {
                        this.$notify.error('catalog.edit.movies.notify.error.NOMP4')

                        _return = false
                    }
                })

                return _return
            },
            save() {
                if(!this.validate()) {
                    return false
                }

                this.loading = true

                API.post('catalog/movies/save', {
                    category_id: this.catalogId,
                    movies: this.movies
                }).then(res => {
                    this.$set(this, 'movies', res.data)

                    this.$notify.success('catalog.edit.movies.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('catalog.edit.movies.notify.error.SAVE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .panel-body {
        padding: 0 15px;

        .form-group-nested {
            padding-top: 15px;
            margin-bottom: 0;

            .priority-inline {
                display: inline-block;
                width: 50%;
            }
        }
    }
</style>