<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.fa.fa-dropbox.mr3
            span {{'inventory.dialog.BTN'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row(style="height:91%")
                .col-xs-4.bg-separator(style="height:100%" v-if="!loading.main")
                    div(style="padding:10px")
                        .form.form-horizontal
                            .form-group
                                .col-xs-12
                                    select.form-control(v-model="category")
                                        optgroup(v-for="(categories, label) in categoriesParse" :key="label" :label="label")
                                            option(v-for="category in categories" :key="category.id" :value="category"
                                                ) {{category.name + ' (szt. ' + category.stock + ', jest: ' + category.inv + ', błedne wiersze: ' + category.err + ') '}}

                        form.form.form-horizontal(style="padding-top:10px" name="formBarcode" @submit.prevent="submitBarcode")
                            .form-group
                                .col-xs-12
                                    input#product.form-control(v-model="barcode.product" name="product"
                                        :placeholder="'search.product.PLACEHOLDER'|translate" required)
                                    label.warn(for="product" v-show="!barcode.product") {{'validation.formValidate.required'|translate}}
                            .form-group.text-expressive
                                .col-sm-4(v-for="i in [-1, 1, 10]")
                                    .radio.c-radio.c-radio-nofont
                                        label.bold
                                            input(type="radio" v-model="barcode.quantity" name="quantity" :value="i")
                                            span
                                            | {{i}}
                                .col-sm-7(style="padding-top:15px")
                                    .radio.c-radio.c-radio-nofont
                                        label.bold
                                            input(type="radio" v-model="barcode.quantity" name="quantity" :value="100")
                                            span
                                            | {{'inventory.ANOTHERVALUE'|translate}}:
                                .col-sm-5(style="padding-top:15px")
                                    OptiNumber#quantity(:header="'inventory.ANOTHERVALUE'|translate" :disabled="barcode.quantity !== 100"
                                        :value="barcode.another" @confirmed="v => barcode.another = v" :minus="true") {{barcode.another}}

                    div(style="padding-left:10px" v-if="product.id && !loading.product")
                        OptiProductInfo.text-expressive(style="margin-bottom:0" :product="product")

                        table.text-right.text-expressive(style="width:100%")
                            tr
                                td(style="padding:3px") {{summary.stock}}
                                td(style="padding:3px") /
                                td(style="padding:3px") {{summary.ordered}}
                                td(style="padding:3px") /
                                td(style="padding:3px") {{summary.expected}}
                                td.text-center.bg-separator(rowspan="3" style="width:70%")
                                    i.fa.fa-5x.fa-check-circle.text-success(v-show="summary.inv_stock === summary.stock")
                                    i.fa.fa-5x.fa-minus-circle.warn(v-show="summary.inv_stock !== summary.stock")
                            tr
                                td(style="padding:3px") {{summary.inv_stock}}
                                td(style="padding:3px") /
                                td(style="padding:3px") 0
                                td(style="padding:3px") /
                                td(style="padding:3px") 0
                            tr(style="border-top:1px solid #000")
                                td(style="padding:3px" ng-class="{'warn bold': summary.inv_stock - summary.stock !== 0}") {{summary.inv_stock - summary.stock}}
                                td(style="padding:3px") /
                                td(style="padding:3px") {{summary.ordered - 0}}
                                td(style="padding:3px") /
                                td(style="padding:3px") {{summary.expected - 0}}

                    .text-center.warn(v-show="error && !loading.product")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'inventory.notify.error.NOPROD'|translate}}

                    OptiDimmer(:active="loading.product")

                    span.alert.alert-purple.animated.bounceInLeft(style="position:absolute;bottom:25px;margin:5px 5px 15px"
                        v-if="info.product.id")
                        i.fa.fa-info-circle.mr3
                        span.mr3 {{'inventory.info.UPDATE'|translate}}
                        OptiProduct.mr3(:product="info.product") {{info.product.name}}
                        span {{'inventory.info.BY'|translate}} {{info.user_name}}.

                #table-products.col-xs-8(style="height:100%;overflow:auto;font-size:1.2em" ref="tableProducts" v-show="!loading.main")
                    table.table.middle
                        thead
                            tr
                                th.text-center(style="font-size:16px" colspan="5") {{category.name}}
                            tr
                                th.text-right {{'inventory.dialog.table.ID'|translate}}
                                th {{'inventory.dialog.table.PRODUCT'|translate}}
                                th {{'inventory.dialog.table.PRICE'|translate}}
                                th {{'inventory.dialog.table.STATE'|translate}}
                                th
                        tbody
                            tr(v-for="detail in category.details" :ref="'product-' + detail.product.id"
                                :class="{'bg-primary': product.id === detail.product.id}")
                                td.text-right
                                    OptiProduct(:product="detail.product") {{detail.product.id}}
                                td
                                    OptiProduct(:product="detail.product") {{detail.product.name}}
                                td {{detail.product.price|currency}}
                                td
                                    table.text-right
                                        tr
                                            td(style="padding:3px") {{detail.stock}}
                                            td(style="padding:3px") /
                                            td(style="padding:3px") {{detail.ordered}}
                                            td(style="padding:3px") /
                                            td(style="padding:3px") {{detail.expected}}
                                        tr
                                            td(style="padding:3px") {{detail.inv_stock}}
                                            td(style="padding:3px") /
                                            td(style="padding:3px") 0
                                            td(style="padding:3px") /
                                            td(style="padding:3px") 0
                                        tr(style="border-top:1px solid #000")
                                            td(style="padding:3px" :class="{'warn bold': detail.inv_stock - detail.stock !== 0}") {{detail.inv_stock - detail.stock}}
                                            td(style="padding:3px") /
                                            td(style="padding:3px") {{detail.ordered - 0}}
                                            td(style="padding:3px") /
                                            td(style="padding:3px") {{detail.expected - 0}}
                                td.text-center.bg-expressive
                                    i.fa.fa-5x.fa-check-circle.text-success(v-show="!(detail.inv_stock - detail.stock)")
                                    i.fa.fa-5x.fa-minus-circle.warn(v-show="detail.inv_stock - detail.stock")
                            tr(v-show="category.details && !category.details.length")
                                td.text-center(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                .col-xs-12(style="padding-top:50px")
                    OptiDimmer(:active="loading.main")

        template.text-right(#footer)
            OptiButton(type="danger" @click.native="$emit('closed')") {{'inventory.dialog.CLOSE'|translate}}
                template(#icon)
                    i.fa.fa-remove
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Broadcast from '@/js/app/vue/Broadcast'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiInventoryDetailsDialog',
        components: {
            OptiDialog,
            OptiProduct,
            OptiProductInfo,
            OptiNumber,
            OptiButton,
            OptiDimmer
        },
        props: {
            inventoryId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                categories: {},
                category: {},

                product: {},
                barcode: {
                    product: null,
                    quantity: 1,
                    another: 0,
                    inventory_id: this.inventoryId
                },
                summary: {},
                error: false,
                info: {
                    product: {},
                    user_name: ''
                },

                channel_name: 'inventory-' + this.inventoryId,

                loading: {
                    main: true,
                    product: false
                }
            }
        },
        mounted() {
            API.get('warehouse/inventory/category/' + this.inventoryId).then(res => {
                this.categories = res.data
                this.category = this.categories[0]
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('inventory.notify.error.DETAILS')
            })

            Broadcast.Echo.channel(this.channel_name).listen('.category-update', data => {
                this.subscribe(data)
            })
        },
        destroyed() {
            Broadcast.Echo.channel(this.channel_name).unsubscribe()
        },
        methods: {
            subscribe(data) {
                if(this.$uac.user.id !== data.user.id) {
                    this.categories.forEach((category, category_key) => {
                        category.details.forEach((detail, detail_key) => {
                            if(data.inventoryDetail.id === detail.id) {
                                this.categories[category_key].details[detail_key] = data.inventoryDetail

                                this.updateCategory(category_key)

                                this.info = {
                                    product: {},
                                    user_name: ''
                                }

                                setTimeout(() => {
                                    this.info.product = detail.product
                                    this.info.user_name = data.user.firstName + ' ' + data.user.lastName
                                }, 200)
                            }
                        })
                    })
                }
            },

            reset() {
                this.barcode.product = null
                this.product = {}
                this.summary = {}
                this.error = true

                this.loading.product = false
            },
            updateCategory(category_key) {
                this.categories[category_key].inv = 0
                this.categories[category_key].err = 0

                this.categories[category_key].details.forEach(detail => {
                    this.categories[category_key].inv += detail.inv_stock
                    this.categories[category_key].err += detail.stock !== detail.inv_stock ? 1 : 0
                })
            },
            submitBarcode() {
                this.loading.product = true

                API.post('warehouse/inventory/barcode', this.barcode).then(res => {
                    if(!res.data) {
                        this.reset()
                    } else {
                        this.product = res.data.product
                        this.summary = res.data.summary

                        this.categories.forEach((category, category_key) => {
                            if(this.product.customerProduct.category.id === category.id) {
                                this.category = category
                            }

                            angular.forEach(category.details, (detail, detail_key) => {
                                if(res.data.summary.id === detail.id) {
                                    this.categories[category_key].details[detail_key] = res.data.summary

                                    this.updateCategory(category_key)
                                }
                            })
                        })

                        this.barcode.product = null
                        this.error = false

                        window.scrollTo(0, 0)
                        this.$refs.tableProducts.scrollTop = 0

                        setTimeout(() => {
                            let top = this.$refs['product-' + this.product.id][0].offsetTop

                            if(typeof top !== 'undefined') {
                                this.$refs.tableProducts.scrollTop = top
                            }
                        }, 1000)
                    }
                }).finally(() => {
                    this.loading.product = false
                }).catch(() => {
                    this.reset()

                    this.$notify.error('inventory.notify.error.NOPROD')
                })
            }
        },
        computed: {
            categoriesParse() {
                let _return = {}

                this.categories.forEach(category => {
                    if(!Object.keys(_return).includes(category.type)) {
                        _return[category.type] = []
                    }

                    _return[category.type].push(category)
                })

                return _return
            }
        }
    }
</script>