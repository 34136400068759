<template lang="pug">
    .dimmer(v-if="active")
        .backdrop
        .loader
            i.fa.fa-spin.fa-cog.fa-3x
</template>

<script>
    export default {
        name: 'OptiDimmer',
        props: {
            active: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style scoped lang="less">
    @keyframes slide {
        0% {transform:translateX(-100%);}
        100% {transform:translateX(100%);}
    }

    .dimmer {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @text-color;
        opacity: .3;
        overflow: hidden;
    }

    .backdrop:after {
        content:'';
        top:0;
        transform:translateX(100%);
        width:100%;
        height:100%;
        position: absolute;
        z-index:1;
        animation: slide 2s infinite;

        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
    }

    .loader {
        color: @text-color;
        opacity: 0.9;
        text-shadow: 1px 0 0 @body-bg, -1px 0 0 @body-bg, 0 1px 0 @body-bg, 0 -1px 0 @body-bg, 1px 1px @body-bg, -1px -1px 0 @body-bg, 1px -1px 0 @body-bg, -1px 1px 0 @body-bg;
    }
</style>
