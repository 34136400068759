<template lang="pug">
    .row
        .col-xs-12(v-if="!loading")
            .row
                .col-sm-4.col-xs-12.text-center-xs(style="padding:5px 15px")
                    OptiButton(type="info" @click.native="dialog_history = true") {{'default.HISTORY'|translate}}
                        template(#icon)
                            i.fa.fa-book

                        MountingPortal(v-if="dialog_history" mountTo="#vue-modal" append)
                            OptiOrdersDialogHistory(:logs="order.logs" @closed="dialog_history = false")

                .col-sm-4.col-xs-12.text-center(style="padding:5px 15px")
                    OptiButton(type="success" :title="'orders.action.accept.TITLE'|translate"
                        :disabled="!order.items.length"  v-show="permission.orders_admin && order.status === 1"
                        text="orders.action.accept.text" @confirmed="goAccept") {{'orders.action.accept.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-check

                    OptiButton(type="success" :disabled="!order.items.length" v-show="order.status === 3"
                        :title="'orders.action.done.TITLE'|translate" text="orders.action.done.text" @confirmed="goDone"
                        ) {{'orders.action.done.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-check

                .col-sm-4.col-xs-12.text-center-xs.text-right(style="padding:5px 15px")
                    OptiButton(type="success" :disabled="!order.items.length" v-show="order.status === 4"
                        :title="'orders.action.sale.TITLE'|translate" text="orders.action.sale.text" @confirmed="goToSale"
                        ) {{'orders.action.sale.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-shopping-cart

                    OptiButton(type="danger" v-show="permission.orders_admin && order.status === 1"
                        :title="'orders.action.cancel.TITLE'|translate"
                        text="orders.action.cancel.text" @confirmed="goCancel") {{'orders.action.cancel.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-remove

                    OptiButton(type="success" v-show="permission.orders_admin && order.status === 2 && !order.wz.id"
                        text="orders.wz.action.create.confirm" @confirmed="goToWZCreate") {{'orders.wz.action.create.confirm.BTN'|translate}}
                        template(#icon)
                            i.fa.fa-file

        .col-xs-12(style="margin-top:10px" v-if="!loading")
            .panel.panel-primary
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'orders.breadcrumb.ORDER'|translate}} \#{{order.id}}
                    .pull-right \#{{order.date}}

                .table-responsive
                    table.table.table-condensed.table-striped.middle
                        thead
                            tr
                                th #
                                th {{'orders.items.head.PRODUCTID'|translate}}
                                th {{'orders.items.head.NAME'|translate}}
                                th {{'orders.items.head.PRICE'|translate}}
                                th {{'orders.items.head.QUANTITY'|translate}}
                                th {{'orders.items.head.VALUE'|translate}}
                                th {{'orders.items.head.COMMENTS'|translate}}
                                th
                        tbody
                            tr(v-for="(item, key) in order.items")
                                td.text-right {{order.items.length - key}}
                                td.text-right
                                    OptiProduct(:product="item.product") {{item.productId}}
                                td
                                    OptiProduct(:product="item.product") {{item.productSymbol}}
                                    small(style="display:block") {{item.product.fullName}}
                                td.text-right {{item.price|currency}}
                                td.text-right
                                    span(v-show="order.status === 1")
                                        span(v-show="!permission.orders_admin") {{item.quantity}}
                                        span(v-show="permission.orders_admin")
                                            OptiNumber(:header="'orders.items.head.QUANTITY'|translate"
                                                :value="item.quantity" @confirmed="q => updateQuantity(key, q)") {{item.quantity}}
                                    span(v-show="order.status !== 1") {{item.quantity}}
                                td.text-right {{item.price * item.quantity|currency}}
                                td(v-html="$options.filters.nl2br(item.comments)")
                                td.text-center
                                    a(:href="$state.href('app.catalog.product', {id: item.productId, departmentId: order.departmentId})"
                                        :title="'sale.items.table.action.DETAILS'|translate")
                                        i.fa.fa-2x.fa-fw.fa-info-circle

                            tr(v-show="!order.items.length")
                                td.text-center.text-muted(colspan="100")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'default.table.EMPTY'|translate}}

                        tfoot.text-expressive(v-show="order.items.length > 1 || (order.quantity && order.value === 0.01)")
                            tr
                                th.text-right(colspan="4") {{'orders.items.SUM'|translate}}
                                th.text-right {{order.quantity}}
                                th.text-right {{order.value|currency}}
                                th(colspan="2")

        .col-md-8.col-md-offset-4.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-default
                    .row(v-show="order.user.id")
                        .col-sm-6 {{'orders.items.ORDERBY'|translate}}
                        .col-sm-6 {{order.user.firstName}} {{order.user.lastName}}
                    .row(v-show="order.user.id")
                        .col-sm-6 {{'orders.items.FORCUSTOMER'|translate}}
                        .col-sm-6 {{order.department.name}}
                    .row
                        .col-sm-6 {{'orders.items.STATUS'|translate}}
                        .col-sm-6 {{order.statusName|translate}}
                    .row(v-show="order.comments")
                        .col-sm-6 {{'orders.items.COMMENTS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(order.comments)")
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="1" :id="order.id" @shipment-exists="s => shipment_exists = s")
                    .row(v-if="permission.smile_list && order.smileDetails[0] && order.smileDetails[0].shipment.id")
                        .col-sm-6 {{order.delivery_type}}
                        .col-sm-6
                            a.link(:href="$state.href('app.warehouse.smile.details', {id: order.smileDetails[0].shipment.id})"
                                ) {{order.smileDetails[0].shipment.id}}
                    .row(v-show="permission.orders_admin && order.wz.id")
                        .col-sm-6 {{'orders.WZ'|translate}}
                        .col-sm-6
                            a.link(:href="$state.href('app.warehouse.order.wz.details', {id: order.wz.id})") {{order.wz.id}}

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiOrdersDialogHistory from '@/js/app/vue/components/Warehouse/Orders/Details/OptiOrdersDialogHistory'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiOrdersDetails',
        components: {
            OptiButton,
            OptiOrdersDialogHistory,
            OptiProduct,
            OptiNumber,
            OptiShipmentNotice,
            OptiDimmer
        },
        data() {
            return {
                order: {},
                shipment_exists: false,

                permission: {
                    orders_admin: this.$uac.permission('orders.admin'),
                    smile_list: this.$uac.permission('smile.list')
                },
                dialog_history: false,
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/order/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('orders.items.notify.error.LOAD')
            })
        },
        methods: {
            getData(order) {
                this.order = order

                if(this.order.smileDetails.length) {
                    this.order.delivery_type = this.order.smileDetails[0].shipment.delivery ? 'More' : 'Smile'
                }

                this.$emit('update-breadcrumb', this.order.statusName)
            },

            updateQuantity(key, quantity) {
                this.order.items[key].quantity = quantity

                let sum_quantity = 0,
                    sum_value = 0

                this.order.items.forEach(item => {
                    sum_quantity += parseInt(item.quantity)
                    sum_value += parseInt(item.quantity) * parseFloat(item.price)
                });

                this.order.quantity = sum_quantity
                this.order.value = sum_value
            },

            goAccept() {
                this.loading = true

                API.post('warehouse/order/accept', {
                    order: this.order
                }).then(res => {
                    this.getData(res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.items.notify.error.LOAD')
                })
            },
            goDone() {
                this.loading = true

                API.post('warehouse/order/done', {
                    id: this.order.id
                }).then(res => {
                    this.getData(res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.items.notify.error.LOAD')
                })
            },
            goCancel() {
                this.loading = true

                API.post('warehouse/order/cancel', {
                    id: this.order.id
                }).then(res => {
                    this.getData(res.data)
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.items.notify.error.LOAD')
                })
            },

            goToWZCreate() {
                this.loading = true

                API.get('warehouse/order/wz/create/' + this.order.id).then(res => {
                    this.$state.go('app.warehouse.order.wz.details', {
                        id: res.data
                    })

                    this.$notify.success('orders.wz.notify.CREATE')
                }).finally(() => {
                    window.localStorage.removeItem('ngStorage-create_wz_checkbox')

                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.wz.notify.error.CREATE')
                })
            },
            goToSale() {
                this.loading = true

                API.post('carts/sale/add', this.order).then(() => {
                    this.$store.dispatch('carts/fetch')

                    this.$store.dispatch('carts/setActive', 0)
                    EventBus.$emitAndApply('ng:emit', {
                        name: 'toggleOffsideState'
                    })

                    this.$notify.success('carts.notify.SAVEMULTI')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('orders.notify.error.SENTTOSALE')
                })
            }
        }
    }
</script>